import React, { Component } from 'react';
import { Timestamp } from 'components';
import PhotoThumb from '../PhotoThumb';

export default class NapActivity extends Component {
  render() {
    const { activity } = this.props;
    const {
      data: { start_time: startTime, end_time: endTime }
    } = activity;
    let text = 'Slept from';
    if (!startTime) {
      text = 'Ended nap at';
    } else if (!endTime) {
      text = 'Started nap at';
    }

    return (
      <div className="activity__nap">
        <div className="activity__paragraph">
          {text}
          {startTime && <Timestamp timestamp={startTime} showDate={false} />}
          {startTime && endTime && 'to'}
          {endTime && <Timestamp timestamp={endTime} showDate={false} />}
          .&nbsp;
          {activity.data.desc}
        </div>
        <PhotoThumb photoUrl={activity.photo_url} />
      </div>
    );
  }
}
