import { Store } from 'redux';
import { RootState } from 'store';

export default function initializeFlash(store: Store<RootState>) {
  return (text: string, kind = 'success', linkName: string, linkTarget: string) => {
    store.dispatch({
      type: 'FLASH_CREATE',
      payload: {
        kind,
        text,
        linkName,
        linkTarget
      }
    });
  };
}
