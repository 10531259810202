import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { IntercomAPI } from 'react-intercom';

class SlideWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSlided: true
    };
  }

  componentDidMount() {
    IntercomAPI('hide');
    IntercomAPI('update', {
      hide_default_launcher: true
    });
  }

  componentWillUnmount() {
    IntercomAPI('update', {
      hide_default_launcher: false
    });
  }

  @bind
  unmountModal() {
    this.setState({
      isSlided: false
    });
  }

  render() {
    const { onUnmount, children } = this.props;
    const { isSlided } = this.state;

    return (
      <CSSTransition appear in={isSlided} timeout={300} classNames="modal--slide" unmountOnExit onExited={onUnmount}>
        {children}
      </CSSTransition>
    );
  }
}

export default SlideWrapper;
