import React, { Component } from 'react';
import { connect } from 'react-redux';
import Flash from './Flash';
import './style.scss';

class Flashes extends Component {
  render() {
    const { flashes } = this.props;

    return (
      <div className="flashes">
        {Object.keys(flashes).map(key => (
          <Flash flash={flashes[key]} key={key} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  flashes: state.flashes.data
});

export default connect(mapStateToProps)(Flashes);
