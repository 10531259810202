import React, { Component } from 'react';
import classNames from 'classnames';
import { Icon } from 'components';
import './style.scss';

//TODO: think about removing if not useful
class ContentToggler extends Component {
  constructor() {
    super();
    this.state = {
      active: false
    };
  }

  @bind
  toggleState() {
    this.setState({
      active: !this.state.active
    });
  }

  render() {
    const { title, children, className } = this.props;
    const { active } = this.state;

    const contentTogglerCN = classNames({
      'content-toggler': true,
      'content-toggler--active': active,
      [className]: Boolean(className)
    });

    return (
      <div className={contentTogglerCN} onClick={this.toggleState}>
        <Icon name="view" size={20} />
        {active ? (
          <span className="content-toggler__content">{children}</span>
        ) : (
          <span className="content-toggler__placeholder">{title || 'Learn more'}</span>
        )}
      </div>
    );
  }
}

export default ContentToggler;
