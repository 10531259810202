import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UploadFile from '../common/UploadFile';
import Steps from '../ModalController/Steps';
import RosterSubmission from './RosterSubmission';
import './style.scss';

class UploadRosterFile extends Component {
  static propTypes = {
    onClose: PropTypes.func,

    data: PropTypes.shape({
      uploadSettings: PropTypes.shape({
        strategy: PropTypes.oneOf(['s3']).isRequired,
        s3Key: PropTypes.string,
        acceptedMIME: PropTypes.arrayOf(PropTypes.string),
        acceptedExts: PropTypes.arrayOf(PropTypes.string),
        maxFileSize: PropTypes.number,
        skipSuccess: PropTypes.bool,
        successTitle: PropTypes.string,
        successDesc: PropTypes.string,
        onBack: PropTypes.func,
        onClose: PropTypes.func,
        onSuccess: PropTypes.func
      })
    })
  };

  static defaultProps = {
    data: {
      uploadSettings: {}
    }
  };

  _steps = null;

  constructor() {
    super();
    this.state = {
      fileURL: ''
    };
  }

  @bind
  bindSteps(node) {
    this._steps = node;
  }

  @bind
  onUploadSuccess(file) {
    this.setState({ fileURL: file.url });
    if (this._steps) {
      this._steps.goToStep(1);
    }
    return Promise.resolve();
  }

  @bind
  onSubmit() {
    this._steps.goToStep(0, { inProgress: false, progress: 100 });
  }

  render() {
    const settings = this.props.data.uploadSettings || {};
    const { fileURL } = this.state;

    return (
      <Steps ref={this.bindSteps}>
        <Steps.Item>
          <UploadFile
            {...settings}
            onSuccess={this.onUploadSuccess}
            onClose={this.props.onClose}
            successTitle="Done! Roster is uploaded."
            successDesc="We will automatically import the roster file you provided in Procare Template format. Importing will take few mins, and you will receive an email once complete."
          />
        </Steps.Item>

        <Steps.Item hidden>
          <RosterSubmission onClose={this.props.onClose} fileURL={fileURL} onSubmit={this.onSubmit} />
        </Steps.Item>
      </Steps>
    );
  }
}

export default UploadRosterFile;
