import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ButtonV2 } from 'components';
import './style.scss';

class PayByConfirmBar extends Component {
  constructor() {
    super();
    this.state = {
      hideBar: false
    };
  }

  UNSAFE_componentWillMount() {
    const { billingAccountStatus } = this.props;
    req.billingStats();

    if (billingAccountStatus === 'pending' || billingAccountStatus === 'unverified') {
      req.verificationStatus().then(() => req.profile());
    } else {
      req.profile();
    }
  }

  @bind
  showActivateModal() {
    Actions.showModal('PayByConfirm').then(result => {
      if (result) {
        this.closeBar();
      }
    });
  }

  @bind
  closeBar() {
    window.localStorage.setItem('payByConfirmClosed', true);
    this.setState({ hideBar: true });
  }

  render() {
    const { billingGateway, stats, billingAccountStatus, billingFeePayerStatus } = this.props;
    if (
      this.state.hideBar ||
      window.localStorage.getItem('payByConfirmClosed') ||
      billingAccountStatus !== 'verified' ||
      billingGateway !== 'stripe' ||
      stats.paid ||
      billingFeePayerStatus !== 'unverified'
    ) {
      return null;
    }

    return (
      <div className="pay-by-confirm-bar__container">
        <div className="pay-by-confirm-bar">
          <div className="pay-by-confirm-bar__text">
            Please confirm "school" or "parent" pays for online transaction fee.
          </div>
          <div>
            <ButtonV2 secondary label="Confirm" onClick={this.showActivateModal} />
            {/* <ActionButton className="pay-by-confirm-bar__close" iconName="close" size={14} onClick={this.closeBar} /> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  stats: state.billingStats.data,
  billingFeePayerStatus: state.currentUser.data.current_school.billing_fee_payer_status,
  billingGateway: state.currentUser.data.current_school.billing_gateway,
  billingAccountStatus: state.currentUser.data.current_school.billing_account_status
});

export default withRouter(connect(mapStateToProps)(PayByConfirmBar));
