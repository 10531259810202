import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Form, TooltipTrigger } from 'components';
import './style.scss';

class EditStaff extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      isLoading: false,
      formValues: { ...this.props.data }
    };
  }

  @bind
  submit(values) {
    const { data, onClose } = this.props;

    const requestPayload = {
      id: data.id,
      teacher: {
        name: values.name,
        email: values.email,
        phone: values.phone,
        section_id: values.roomId || null,
        is_admin: values.is_admin,
        pin: values.pin
      }
    };

    this.setState({ isLoading: true });
    req
      .updateStaff(requestPayload)
      .then(() => {
        this.setState({ isLoading: false });
        Actions.showFlash('Staff profile has been updated');
        onClose();
      })
      .catch(err => {
        Actions.reportError('There was error updating staff information', err);
        this.setState({ isLoading: false });
      });
  }

  @bind
  updateFormValues(values) {
    this.setState({ formValues: values });
  }

  render() {
    const { formValues } = this.state;
    const { data, rooms, currentUserID } = this.props;
    const isMe = data.id === currentUserID;

    const emailCN = classNames({
      'form-input--pending': data.email && !data.is_signed_up,
      'form-input--verified': data.email && data.is_signed_up
    });

    const emailInput = (
      <Form.Input
        name="email"
        type="email"
        label="Email Address"
        placeholder="Email Address"
        className={emailCN}
        defaultValue={data.email}
        disabled={data.is_signed_up}
        required={formValues.is_admin}
      />
    );

    const adminCheckbox = (
      <Form.Checkbox
        type="circle"
        reversed
        label="Admin Access"
        name="is_admin"
        className="edit-staff__is-admin"
        defaultValue={data.is_admin}
        disabled={isMe}
      />
    );

    return (
      <div className="edit-staff">
        <div className="modal__header">
          <span className="modal__header-title">Edit Staff</span>
        </div>

        <div className="modal__container">
          <Form
            validateOn="submit"
            onSubmit={this.submit}
            onChange={this.updateFormValues}
            isLoading={this.state.isLoading}
          >
            <div className="form__row form__row--justified">
              <Form.Input
                name="name"
                label="Name"
                placeholder="Full Name (required)"
                required
                defaultValue={data.name}
              />

              <Form.Select
                label="Room"
                type="radio"
                name="roomId"
                title="Select Room"
                defaultValue={data.section_id || undefined}
                enableClear
              >
                {rooms.map(room => (
                  <Form.Select.Item key={room.id} value={room.id} label={room.name} />
                ))}
              </Form.Select>
            </div>

            <div className="form__row form__row--justified">
              <div className="form__row--half">
                {data.is_signed_up ? (
                  <TooltipTrigger side="left" tooltip="User Signed Up">
                    {emailInput}
                  </TooltipTrigger>
                ) : (
                  emailInput
                )}
              </div>

              <div className="form__row--half">
                <Form.Input
                  defaultValue={data.pin}
                  label="Clock In-Out Pin"
                  name="pin"
                  mask="9999"
                  placeholder="Pin range 0001-0999"
                  pattern="0[0-9]{3}"
                  required
                />
              </div>
            </div>

            {isMe ? (
              <TooltipTrigger
                className="edit-staff__tooltip--admin"
                side="right"
                tooltip="Can’t change while logged in"
              >
                {adminCheckbox}
              </TooltipTrigger>
            ) : (
              adminCheckbox
            )}

            <div className="form__row form__row--actions">
              <Form.Submit label="Save changes" />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  currentUserID: state.currentUser.data.teacher.id
});

export default connect(mapStateToProps)(EditStaff);
