import React, { Component } from 'react';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class MessageStep extends Component {
  @bind
  onSelectType(type) {
    this.props.onSelectType(type);
    this.props.context.nextStep();
  }

  render() {
    return (
      <div className="modal__wrapper">
        <div className="modal__header modal__header--bordered">
          <span className="modal__header-title">New Message</span>
        </div>
        <div className="modal__container new-message__form">
          <div className="new-message__type">
            <div className="new-message__type-subtitle">Choose your message type</div>

            <div className="path-selector">
              <div className="path-selector__option" onClick={() => this.onSelectType('general')}>
                <div className="path-selector__option-inner">
                  Send Message Notification
                  <div className="path-selector__option-inner__desc">
                    Parents will receive a push notification and a message in their app inbox
                  </div>
                </div>
              </div>
              <div className="path-selector__option" onClick={() => this.onSelectType('newsletter')}>
                <div className="path-selector__option-inner">
                  Send Email Newsletter
                  <div className="path-selector__option-inner__desc">
                    You can customize and send formatted email to parents and staff
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(MessageStep);
