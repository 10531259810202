import React, { useState } from 'react';

import { ButtonV2 } from '../../components';

function AccordionItem({ title, thumbnail, children }) {
  const [expanded, setExpanded] = useState(false);
  const cn = `accordion-item ${expanded ? 'accordion-item--expanded' : ''}`;
  return (
    <div key={title} className={cn}>
      <div className="accordion-item__toggle" onClick={() => setExpanded(!expanded)}>
        <img src={thumbnail} />
        <h3>{title}</h3>
        <div>
          <ButtonV2 icon iconName={expanded ? 'triangle-up' : 'triangle-down'} iconSize={16} />
        </div>
      </div>
      {expanded && <div className="accordion-item__content">{children}</div>}
    </div>
  );
}

export default AccordionItem;
