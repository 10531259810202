const PREMIUM_FEATURES = {
  free: [
    'Sign In-Out Kiosk',
    'Attendance Reports',
    'Parent Billing',
    'Live Ratios',
    'Recurring Payments',
    'Tuition Invoices',
    'Newsletters',
    'Meal Plans'
  ],
  home: [
    'Lesson Planning',
    'Milestone Assessments',
    'Staff Messaging',
    'Staff Timecards',
    'Advanced Reporting',
    'Online Registration Form',
    'Family Registration',
    'Phone Support'
  ],
  center: ['Leads']
};

export default PREMIUM_FEATURES;
