import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormConstructorField from './FormConstructorField';
import NewFieldDropdown from './NewFieldDropdown';
import './style.scss';

class FormConstructorV2 extends Component {
  static propTypes = {
    fields: PropTypes.array,
    description: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    fields: []
  };

  addField(field) {
    const nextFields = [...this.props.fields, field];
    this.handleChange(nextFields);
  }

  updateField(fieldIndex, fieldData) {
    const { fields } = this.props;

    const nextFields = [...fields.slice(0, fieldIndex), fieldData, ...fields.slice(fieldIndex + 1)];

    this.handleChange(nextFields);
  }

  deleteField(fieldIndex) {
    const { fields } = this.props;

    const nextFields = [...fields.slice(0, fieldIndex), ...fields.slice(fieldIndex + 1)];

    this.handleChange(nextFields);
  }

  handleChange(nextFields) {
    if (!this.props.onChange) {
      return;
    }

    this.props.onChange(nextFields);
  }

  @bind
  openAddFieldModal(type) {
    const formPayload = {
      actionType: 'create',
      fieldType: type,
      totalFields: this.props.fields.length,
      customAttributesEnabled: !!this.props.customAttributesKeeper,
      customAttributesKeeper: this.props.customAttributesKeeper,
      customAttributeIdsAlreadyBound: this.props.fields.map(field => field.options?.customAttributeId).filter(Boolean)
    };

    Actions.showModal('CreateFormFieldV2', formPayload).then(field => {
      if (field) {
        this.addField(field);
      }
    });
  }

  @bind
  openEditFieldModal(fieldId) {
    if (!fieldId) {
      return;
    }

    const { fields } = this.props;
    const fieldIndex = fields.findIndex(f => f.id === fieldId);
    if (fieldIndex === -1) {
      return;
    }

    const formPayload = {
      actionType: 'edit',
      fieldObj: this.props.fields[fieldIndex],
      totalFields: this.props.fields.length,
      customAttributesEnabled: !!this.props.customAttributesKeeper,
      customAttributesKeeper: this.props.customAttributesKeeper,
      customAttributeIdsAlreadyBound: this.props.fields
        .filter(field => field.id !== fieldId)
        .map(field => field.options?.customAttributeId)
        .filter(Boolean)
    };

    Actions.showModal('CreateFormFieldV2', formPayload).then(fieldData => {
      if (!fieldData) {
        return;
      }
      this.updateField(fieldIndex, fieldData);
    });
  }

  @bind
  openDeleteFieldModal(fieldId) {
    if (!fieldId) {
      return;
    }

    const { fields } = this.props;
    const fieldIndex = fields.findIndex(f => f.id === fieldId);
    if (fieldIndex === -1) {
      return;
    }

    const formPayload = {
      title: 'Delete Field',
      description: 'Are you sure you want to delete this field?'
    };

    Actions.showModal('Confirmation', formPayload).then(res => {
      if (!res) {
        return;
      }

      this.deleteField(fieldIndex);
    });
  }

  renderFields() {
    return this.props.fields
      .filter(f => f.type !== 'button')
      .map(field => (
        <FormConstructorField
          key={field.id}
          id={field.id}
          type={field.type}
          removable={field.removable}
          options={field.options}
          onEdit={this.openEditFieldModal}
          onDelete={this.openDeleteFieldModal}
        />
      ));
  }

  render() {
    return (
      <div className="form-constructor">
        <div className="form-constructor__fields">{this.renderFields()}</div>

        <NewFieldDropdown onSelect={this.openAddFieldModal} />

        {this.props.description && <div className="form-constructor__description">{this.props.description}</div>}
      </div>
    );
  }
}

export default FormConstructorV2;
