import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ItemForm from './ItemForm';
import List from './List';
import { ButtonV2 } from 'components';
import './style.scss';

class AttendanceItemList extends Component {
  static propTypes = {
    actionType: PropTypes.oneOf(['create', 'edit']),
    data: PropTypes.array,
    onUpdate: PropTypes.func
  };

  static defaultProps = {
    actionType: 'create',
    data: []
  };

  constructor(props) {
    super(props);

    this.state = {
      items: props.data || [],
      editableItemIndex: props.data && props.data.length === 0 ? 0 : undefined,
      isUpdatingFromProps: true
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.isUpdatingFromProps && nextProps.data !== prevState.items) {
      return {
        items: nextProps.data || [],
        editableItemIndex: nextProps.data && nextProps.data.length === 0 ? 0 : undefined,
        isUpdatingFromProps: true
      };
    }
    return null;
  }

  addNewItem = () => {
    this.setState({ editableItemIndex: this.state.items.length });
  };

  editItem = itemIndex => {
    this.setState({ editableItemIndex: itemIndex });
  };

  cancelEditing = () => {
    this.setState({ editableItemIndex: undefined });
  };

  saveItem = itemIndex => newItem => {
    this.setState(prevState => {
      const newItems = [...prevState.items];

      newItems[itemIndex] = {
        ...newItems[itemIndex],
        ...newItem,
        rate: Number(newItem.rate),
        late_fee: Number(newItem.late_fee),
        kind: 'attendance'
      };

      return {
        items: newItems,
        editableItemIndex: undefined,
        isUpdatingFromProps: false
      };
    }, this.handleUpdate);
  };

  removeItem = itemIndex => {
    const { actionType } = this.props;
    let newItems;

    if (actionType === 'edit') {
      newItems = [...this.state.items];
      newItems[itemIndex] = {
        ...newItems[itemIndex],
        _destroy: true
      };
    } else if (actionType === 'create') {
      newItems = [...this.state.items.slice(0, itemIndex), ...this.state.items.slice(itemIndex + 1)];
    }

    this.setState(
      { items: newItems, editableItemIndex: newItems.length > 0 ? undefined : 0, isUpdatingFromProps: false },
      this.handleUpdate
    );
  };

  handleUpdate = () => {
    this.props.onUpdate(this.state.items);
  };

  render() {
    const { editableItemIndex, items } = this.state;
    const numHiddenItems = items.filter(item => item._destroy).length;
    const hasVisibleItems = !!(items.length - numHiddenItems);
    const editableItem = items[editableItemIndex];

    return (
      <div className="attendance-items">
        <div className="attendance-items__container">
          {editableItemIndex === undefined && hasVisibleItems ? (
            <List items={items} onEdit={this.editItem} onRemove={this.removeItem} />
          ) : (
            <ItemForm
              isListEmpty={!hasVisibleItems}
              index={editableItemIndex}
              item={editableItem}
              onSave={this.saveItem(editableItemIndex || numHiddenItems)}
              onCancel={this.cancelEditing}
            />
          )}
        </div>

        {hasVisibleItems && editableItemIndex === undefined && (
          <ButtonV2
            tertiary
            className="attendance-items__add-item"
            label="Add another charge"
            onClick={this.addNewItem}
            data-cy="add-another-charge"
          />
        )}
      </div>
    );
  }
}

export default AttendanceItemList;
