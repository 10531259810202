import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Steps from 'modals/ModalController/Steps';
import { Placeholder } from 'components';
import Step1 from './Step1';
import Step2 from './Step2';

import './style.scss';

class ShowInvoice extends Component {
  static propTypes = {
    invoice: PropTypes.object,
    kidNames: PropTypes.string,
    print: PropTypes.bool
  };

  static defaultProps = {
    invoice: {},
    print: false
  };

  constructor(...args) {
    super(...args);
    this.state = {
      isLoading: true,
      isSending: false,
      invoice: {},
      breakdown: {}
    };
  }

  UNSAFE_componentWillMount() {
    req.school();
    const promise = this.props.data.invoice
      ? this.props.data.fromParent
        ? req.parentViewInvoice({ id: this.props.data.invoice.id })
        : req.viewInvoice({ id: this.props.data.invoice.id })
      : req.previewInvoice({ id: this.props.data.billingPlanId });

    promise.then(invoice => {
      this.setState({
        isLoading: false,
        invoice,
        breakdown: {}
      });
    });
  }

  @bind
  showBreakdown(breakdown) {
    this.setState({ breakdown });
  }

  @bind
  sendInvoice() {
    const { familyId, parentInvoiceId } = this.props.data;

    this.setState({ isSending: true });
    req.sendInvoiceReminder({ id: familyId, parent_invoice_id: parentInvoiceId }).then(() => {
      this.setState({ isSending: false });
      this.props.onClose(true);
    });
  }

  render() {
    const { isLoading, invoice, breakdown } = this.state;
    const { onClose, data } = this.props;
    const previewMode = !data.invoice;

    if (isLoading) {
      return <Placeholder.NoResult className="show-invoice" isLoading={isLoading} />;
    }

    return (
      <Steps>
        <Steps.Item>
          <Step1
            showBreakdown={this.showBreakdown}
            invoice={invoice}
            onClose={onClose}
            students={data.students}
            isSending={this.state.isSending}
            hasSendInvoice={data.sendInvoice}
            sendInvoice={this.sendInvoice}
            showPrint={!!data.invoice}
            title={previewMode ? 'Preview of Next Invoice' : 'View Invoice'}
          />
        </Steps.Item>
        <Steps.Item>
          <Step2 breakdown={breakdown} />
        </Steps.Item>
      </Steps>
    );
  }
}

export default ShowInvoice;
