import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollBars from 'react-custom-scrollbars';
import { ActionButton, ButtonV2, RichEditor } from 'components';
import './style.scss';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

const froalaConfig = {
  height: 150,
  toolbarSticky: false,

  toolbarButtons: {
    moreRich: {
      buttons: ['insertLink']
    },
    moreMisc: {
      buttons: ['undo', 'redo', 'html']
    }
  }
};

class AddPolicies extends Component {
  static propTypes = {
    schoolClass: PropTypes.object,
    onSave: PropTypes.func
  };

  static defaultProps = {
    schoolClass: {}
  };

  constructor(props) {
    super(props);
    const policies = props.schoolClass.policy_attributes;

    this.state = {
      policies: {
        behavior: policies.behavior || '',
        liberality: policies.liberality || '',
        refunds: policies.refunds || '',
        photo_release: policies.photo_release || ''
      }
    };
  }

  @bind
  handleChange() {
    this.props.onChange(this.state.policies);
  }

  updatePolicy(policyName) {
    return value => {
      this.setState(
        {
          policies: { ...this.state.policies, [policyName]: value }
        },
        this.handleChange
      );
    };
  }

  updateLiability = this.updatePolicy('liberality');
  updateBehavior = this.updatePolicy('behavior');
  updateRefunds = this.updatePolicy('refunds');
  updatePhoto = this.updatePolicy('photo_release');

  render() {
    const { edit, submitLoading } = this.props;
    const { behavior, liberality, refunds, photo_release } = this.state.policies;

    return (
      <div className="add-policies">
        <div className="modal__header modal__header--bordered">
          <ActionButton iconName="back" className="add-pricing-details__back" onClick={this.props.context.prevStep} />
          <span className="modal__header-title add-class-info__title">{edit ? 'Edit' : 'Add'} Policies</span>
          <span className="modal__header-steps">
            <span>Step 3</span> / 3
          </span>
        </div>

        <div className="modal__container">
          <ScrollBars autoHeight autoHeightMin={468}>
            <div className="add-policies__policy">
              <div className="label label--small">Behavior Policy</div>

              <RichEditor config={froalaConfig} value={behavior} onChange={this.updateBehavior} />
            </div>

            <div className="add-policies__policy">
              <div className="label label--small">Liability Waiver</div>

              <RichEditor config={froalaConfig} value={liberality} onChange={this.updateLiability} />
            </div>

            <div className="add-policies__policy">
              <div className="label label--small">Refunds & Cancellation</div>

              <RichEditor config={froalaConfig} value={refunds} onChange={this.updateRefunds} />
            </div>

            <div className="add-policies__policy">
              <div className="label label--small">Photo Release</div>

              <RichEditor config={froalaConfig} value={photo_release} onChange={this.updatePhoto} />
            </div>
          </ScrollBars>
        </div>

        <div className="modal__controls">
          <ButtonV2 label="Save" onClick={this.props.onSave} isLoading={submitLoading} />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(AddPolicies);
