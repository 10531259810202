import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, ActionButton } from 'components';
import { injectStripe } from 'react-stripe-elements';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

export class Step3Bank extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isLoading: false
    };
  }

  @bind
  verifyMethod() {
    const { match, history } = this.props;
    const splits = match.url.split('/');

    splits.pop();
    history.push(splits.join('/') + '/verify_method');
  }

  @bind
  handleSubmit(data) {
    const { isSchool, onSubmit, autopayEnabled } = this.props;
    const reqMethod = isSchool ? 'addSchoolPaymentMethod' : 'addPaymentMethod';

    this.setState({ isLoading: true });

    return this.props.stripe
      .createToken('bank_account', {
        account_holder_type: data.account_holder_type,
        routing_number: data.routing_number,
        account_number: data.account_number,
        account_holder_name: data.account_holder_name,
        country: data.country,
        currency: 'usd'
      })
      .then(({ token, error }) => {
        if (error) {
          throw error;
        }

        if (onSubmit) {
          return onSubmit({
            token: token.id,
            token_type: 'bank_account'
          });
        }

        return req[reqMethod]({
          token: token.id,
          token_type: 'bank_account',
          auto_pay: autopayEnabled === true
        }).then(() => {
          Actions.showFlash('Bank added successfully');
          this.setState({ isLoading: false });

          if (isSchool) {
            this.props.goToList();
          } else {
            this.verifyMethod();
          }
        });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        Actions.reportError('There was problem with adding payment method', err);
      });
  }

  render() {
    const { prevStep } = this.props.context;
    const { constants } = this.props;
    const { isLoading } = this.state;

    return (
      <Form className="table payments-panel" onSubmit={this.handleSubmit} isLoading={isLoading}>
        <div className="table__header payments-panel__header">
          <ActionButton iconName="back" size={20} onClick={prevStep} />
          <div className="payments-panel__title">Add Bank Account</div>
        </div>

        <div className="table__body">
          <div className="table__row payments-panel__body">
            <div className="payments-panel__content">
              <div className="form__row">
                <Form.Input name="account_holder_name" placeholder="Enter Name" label="Account Holder Name" required />
              </div>

              <div className="form__row form__row--justified">
                <Form.Input placeholder="Routing Number" label="Routing Number" name="routing_number" required />
                <Form.Select name="account_holder_type" label="Account Holder Type" defaultValue="individual" required>
                  <Form.Select.Item label="Corporation" value="company" />
                  <Form.Select.Item label="Individual" value="individual" />
                </Form.Select>
              </div>

              <div className="form__row form__row--justified">
                <Form.Input label="Account Number" placeholder="Account Number" name="account_number" required />
                <Form.Select name="country" label="Country" defaultValue="US" required>
                  {Object.keys(constants.countries).map(c => (
                    <Form.Select.Item label={constants.countries[c]} value={c} key={c} />
                  ))}
                </Form.Select>
              </div>
            </div>
          </div>

          <div className="table__row table__row--footer payments-panel__footer">
            <Form.Submit className="button--large" label="Add Bank Account" />
          </div>
        </div>
      </Form>
    );
  }
}
const mapStateToProps = state => ({
  constants: state.constants.data
});

const enhance = compose(withContext(ModalControllerStepsContext), injectStripe, withRouter, connect(mapStateToProps));

export default enhance(Step3Bank);
