export const COLOR_PICKER_COLORS = [
  '#00c1f2',
  '#f99e1c',
  '#4ebe9e',
  '#f15c3b',
  '#a19f9d',
  '#58ba50',
  '#af529f',
  '#87ccdd',
  '#febe10',
  '#ef456f'
];
