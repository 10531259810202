// Reference: src/modals/SelectDocumentRecipients/index.js

import React, { useState } from 'react';
import { ButtonV2 } from 'components';
import SelectPersonList from 'modals/common/SelectPersonList';

const AssignRoomStaffModal = ({ data, onClose }) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const updateSelectedStaffIds = selectedStaffIds => {
    setSelectedIds(selectedStaffIds);
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      if (typeof data.onSubmit === 'function') {
        await data.onSubmit(selectedIds);
      }

      if (typeof onClose === 'function') {
        onClose(selectedIds);
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="select-document-recipients__students">
      <div className="modal__header modal__header--bordered">
        <div className="modal__header-title">Select Staff</div>
      </div>

      <div className="modal__container">
        <SelectPersonList
          persons={data.staff}
          allPersons={data.staff}
          selectedIds={selectedIds}
          onSelect={updateSelectedStaffIds}
          listHeight={438}
          type="teacher"
        />
      </div>

      <div className="modal__controls">
        <ButtonV2
          label="Assign Staff"
          onClick={handleSubmit}
          isLoading={isSaving}
          data-cy="continue"
          disabled={!selectedIds.length}
        />
      </div>
    </div>
  );
};

export default AssignRoomStaffModal;
