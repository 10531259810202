import React, { Component } from 'react';
import PhotoThumb from '../PhotoThumb';

export default class BathroomActivity extends Component {
  render() {
    const { activity } = this.props;
    let description = `${activity.data.type} | ${activity.data.sub_type}. ${activity.data.desc}`;

    if (activity.data.type === 'Diaper') {
      description = 'Diaper';
      if (activity.data.diaper_size) {
        description += ` (size ${activity.data.diaper_size})`;
      }

      if (activity.data.diaper_cream) {
        description += ', cream applied';
      }

      description += ` | ${activity.data.sub_type}. ${activity.data.desc}`;
    }

    return (
      <div className="activity__bathroom">
        <div className="activity__paragraph">{description}</div>
        <PhotoThumb photoUrl={activity.photo_url} />
      </div>
    );
  }
}
