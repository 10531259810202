import React, { useState } from 'react';
import { DatePicker, Icon } from 'components';
import './style.scss';
import moment from 'moment';

function ConnectQuickBooks(props) {
  const [date, setDate] = useState(moment().toDate());
  const {
    data: { link }
  } = props;

  const handleContinue = () => {
    const userId = localStorage.getItem('kinderlime-user');
    const currentSchoolId = localStorage.getItem('kinderlime-current-school');

    localStorage.setItem(`${userId}_${currentSchoolId}_quickbooks`, moment(date).format('YYYY-MM-DD'));
  };

  return (
    <div className="modal__wrapper quickbooks">
      <div className="modal__header">
        <div className="modal__header-title">Quickbooks</div>
      </div>
      <div className="modal__container">
        <div className="quickbooks__description">
          Seamlessly pass accounting information from Procare to Quickbooks including:
        </div>
        <div className="quickbooks__features">
          <div className="quickbooks__features-item">
            <div className="quickbooks__features-icon">
              <Icon width={19} height={19} name="lead" />
            </div>
            Customers
          </div>
          <div className="quickbooks__features-item">
            <div className="quickbooks__features-icon">
              <Icon width={19} height={19} name="invoice" />
            </div>
            Invoices
          </div>
          <div className="quickbooks__features-item">
            <div className="quickbooks__features-icon">
              <Icon width={19} height={19} name="receive-payment" />
            </div>
            Payments Received
          </div>
        </div>
        <div className="form__row quickbooks__sync-description">
          Procare data from this date forward will sync to Quickbooks. Ensure that you have not already logged invoices
          and payments for this time period in Quickbooks, as this may result in duplicate records.
        </div>
        <div className="form__row quickbooks__start-date">
          <DatePicker
            value={date}
            name="start_date"
            placeholder="Select a Starting Date"
            onChange={setDate}
            disableDays={day => {
              const momentDay = moment(day);
              const currentMoment = moment();

              return momentDay.isAfter(currentMoment) || momentDay.diff(currentMoment, 'month') < -6;
            }}
          />
          <span className="quickbooks__start-date-sub">(Allowed range is 6 months from current date)</span>
        </div>
        <div className="form__row quickbooks__disclaimer">
          Disclaimer: Once the integration is initiated the data sync cannot be undone. If you are already using
          Quickbooks, you may see duplicate customers when syncing data from Procare. This is often the result of
          differing casing, spacing or spelling.
        </div>
        <div className="form__row form__row--actions">
          <a href={link} className="button quickbooks__continue" onClick={handleContinue}>
            Continue
          </a>
        </div>
      </div>
    </div>
  );
}

export default ConnectQuickBooks;
