import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, TooltipTrigger, ButtonV2, Placeholder } from 'components';
import moment from 'moment';
import STATES from 'constants/states';
import '../ActivateSchool/style.scss';
import { ZIP_NAME } from 'constants/paymentFieldNames';

const VERIFICATION_MAP = {
  'legal_entity.dob.day': 'dob',
  'legal_entity.dob.month': 'dob',
  'legal_entity.dob.year': 'dob',
  'legal_entity.first_name': 'first_name',
  'legal_entity.last_name': 'last_name',
  'legal_entity.id_number': 'personal_id_number',
  'verification.document': 'verification_document'
};

class VerifySchool extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      countryCode: null,
      disableContinue: false,
      loaded: false,
      errorMsg: '',
      saving: false,
      formErrors: {},
      defaultValues: {}
    };
  }

  UNSAFE_componentWillMount() {
    req
      .verificationStatus()
      .then(resp => {
        const formErrors = {};
        resp.verification.fields_needed.forEach(f => {
          formErrors[VERIFICATION_MAP[f]] = ['Not Verified'];
        });

        const entity = resp.legal_entity || {};

        this.setState({
          loaded: true,
          formErrors: {
            ...this.state.formErrors,
            ...formErrors
          },
          countryCode: entity.address ? entity.address.country : undefined,
          disableContinue: entity.address.country !== 'US',
          defaultValues: {
            type: resp.legal_entity_type,
            school_name: entity.name,
            address: entity.address ? entity.address.line1 : undefined,
            country: entity.address ? entity.address.country : undefined,
            state: entity.address ? entity.address.state : undefined,
            zip: entity.address ? entity.address.postal_code : undefined,
            city: entity.address ? entity.address.city : undefined,
            first_name: entity.first_name,
            last_name: entity.last_name,
            dob: entity.dob ? new Date(entity.dob.year, entity.dob.month - 1, entity.dob.day) : undefined
          }
        });
      })
      .catch(err => {
        this.setState({
          loaded: true,
          errorMsg: err.message || 'There was problem loading verification status.'
        });
      });
  }

  @bind
  onChange(value) {
    if (value.country !== this.state.countryCode && value.country) {
      const code = value.country;
      const newState = {
        countryCode: value.country,
        disableContinue: false
      };
      if (code !== 'US') {
        newState.disableContinue = true;
      }

      this.setState(newState);
    }
  }

  @bind
  onSubmit(data) {
    if (moment().diff(data.dob, 'years') < 15) {
      Actions.showFlash('Adminstrator must be at least 15 years old', 'alert');
      return;
    }

    this.setState({ formErrors: {}, saving: true });
    req
      .verifySchool({
        legal_entity_type: this.state.defaultValues.type,
        legal_entity: {
          address: {
            country: data.country,
            city: data.city,
            line1: data.address,
            postal_code: data.zip,
            state: STATES[data.country] ? data.state : undefined
          },
          name: data.school_name,
          dob: data.dob,
          first_name: data.first_name,
          last_name: data.last_name,
          ssn_last_4:
            data.country === 'US' ? data.personal_id_number.substr(data.personal_id_number.length - 4) : undefined,
          id_number: data.personal_id_number
        },
        verification_document: data.verification_document
      })
      .then(() => {
        req.profile();
        this.setState({ saving: false });
        this.props.onClose();
      })
      .catch(err => {
        this.setState({ saving: false });
        Actions.reportError('There was problem verifying your account', err);
      });
  }

  render() {
    const { constants, data } = this.props;
    const { formErrors, disableContinue, countryCode, saving, errorMsg, loaded, defaultValues } = this.state;

    return (
      <div className="school-activate">
        <div className="modal__wrapper">
          <div className="modal__header modal__header--bordered">
            <span className="modal__header-title">{data && data.fromUpdate ? 'Update' : 'Verify'} Your Account</span>
          </div>
          {errorMsg || !loaded ? (
            <Placeholder.NoResult
              className="modal__container school-activate__form school-activate__loading"
              isLoading={!loaded}
            >
              {errorMsg}
            </Placeholder.NoResult>
          ) : (
            <Form
              className="modal__container school-activate__form"
              onChange={this.onChange}
              onSubmit={this.onSubmit}
              errors={formErrors}
              isLoading={saving}
              validateOn="submit"
            >
              <div className="school-activate__legend">School / Account Details</div>
              <div className="school-activate__desc">
                Your business name and phone number will appear on your customers' credit card statements.
              </div>
              <div className="form__row form__row--justified">
                <Form.Input
                  defaultValue={defaultValues.school_name}
                  placeholder="School Name"
                  name="school_name"
                  required
                />
                <Form.Input
                  defaultValue={defaultValues.phone}
                  placeholder="School Phone Number"
                  name="phone"
                  mask="(999) 999-9999"
                  required
                />
              </div>
              <div className="form__row">
                <Form.Input defaultValue={defaultValues.address} placeholder="Address Line" name="address" required />
              </div>
              <div className="form__row form__row--justified">
                <Form.Select name="country" defaultValue={defaultValues.country} title="Country" required>
                  {Object.keys(constants.countries).map(c => (
                    <Form.Select.Item label={constants.countries[c]} value={c} key={c} />
                  ))}
                </Form.Select>
                <Form.Select
                  name="state"
                  defaultValue={defaultValues.state}
                  title="State"
                  disabled={!STATES[countryCode]}
                  required={!!STATES[countryCode]}
                >
                  {STATES[countryCode] &&
                    STATES[countryCode].map(s => (
                      <Form.Select.Item label={s.name} value={s.abbreviation} key={s.abbreviation} />
                    ))}
                </Form.Select>
              </div>
              <div className="form__row form__row--justified">
                <Form.Input
                  defaultValue={defaultValues.zip}
                  placeholder={ZIP_NAME[countryCode] || 'Zip'}
                  name="zip"
                  required
                />
                <Form.Input defaultValue={defaultValues.city} placeholder="City" name="city" required />
              </div>
              <div className="school-activate__legend">Administrator Legal Details</div>
              <div className="form__row form__row--justified">
                <Form.Input
                  defaultValue={defaultValues.first_name}
                  placeholder="First Name"
                  name="first_name"
                  required
                />
                <Form.Input defaultValue={defaultValues.last_name} placeholder="Last Name" name="last_name" required />
              </div>
              <div className="form__row form__row--justified">
                <Form.DatePicker
                  className="school-activate__form-dob"
                  name="dob"
                  dropup
                  placeholder="Administrator DOB"
                  yearsToShow={80}
                  defaultValue={defaultValues.dob}
                />
                <Form.Input
                  defaultValue={defaultValues.personal_id_number}
                  placeholder={countryCode === 'CA' ? 'Social Insurance Number' : 'SSN Number (###-##-####)'}
                  name="personal_id_number"
                  mask="999-99-9999"
                  pattern="^[0-9]{3}-[0-9]{2}-[0-9]{4}$"
                  required
                />
              </div>
              <div className="form__row form__row--justified">
                <Form.FileInput className="verify-doc" name="verification_document" maxSize={5} required>
                  <div className="verify-doc__content">
                    <img className="verify-doc__img" src="/assets/images/id-icon.png" />
                    Please upload a valid photo ID to verify your identity using a JPG or PNG that is less than 5MB.
                  </div>
                  <div className="school-activate__help">
                    Why is this necessary
                    <TooltipTrigger
                      tooltip="You are setting up a Merchant Account, which allows you to securely and safely process transactions, your identity verification is required by our payment gateway."
                      side="center"
                      className="school-activate__tooltip"
                    >
                      <ButtonV2 icon iconName="tooltip" iconSize={18} />
                    </TooltipTrigger>
                  </div>
                </Form.FileInput>
              </div>
              {disableContinue && (
                <div className="school-activate__warning">
                  <div className="school-activate__warning-text">
                    <span className="school-activate__warning-small">
                      If you want to process payments outside "United States", please contact us at&nbsp;
                      <span className="school-activate__warning-strong">1-877-679-0950.</span>
                    </span>
                  </div>
                </div>
              )}
              <div className="form__row form__row--actions">
                <Form.Submit label="Update Account & Verify" disabled={disableContinue} />
              </div>
            </Form>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  constants: state.constants.data,
  currentSchool: state.currentUser.data.current_school
});

export default connect(mapStateToProps)(VerifySchool);
