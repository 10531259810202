import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withContext, { FormContext } from 'hocs/withContext';

class Hidden extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    disableInvalid: PropTypes.bool
  };

  UNSAFE_componentWillMount() {
    const initialValue = this.props.defaultValue || '';
    const validations = {};
    this.props.context.init(this.props.name, initialValue, validations);
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  @bind
  handleChange(val) {
    this.props.context.update(this.props.name, val);
  }

  setData(val) {
    this.props.context.update(this.props.name, val);
  }

  render() {
    const { values } = this.props.context;
    const { name } = this.props;
    return <input name={name} type="hidden" value={values[name] || ''} onChange={this.handleChange} />;
  }
}

export default withContext(FormContext)(Hidden);
