import moment from 'moment';
import get from 'lodash/get';
import { isFree as isFreeSchool } from './index';

export function isAdmin(state) {
  return get(state, 'currentUser.data.teacher.is_admin', false);
}

export function isFree(state) {
  return isFreeSchool(state.currentUser.data.current_school);
}

export function isCorporate(state) {
  return get(state, 'currentUser.data.current_school.corp_school');
}

export function isAnyAutopayFailed(state) {
  return get(state, 'paymentMethods.data', []).some(p => p.auto_pay_failed);
}

export function canUsePremium(state) {
  const currentSchool = state.currentUser.data.current_school;
  const difference =
    currentSchool &&
    currentSchool.trial_period_end_date &&
    moment(currentSchool.trial_period_end_date).diff(moment(), 'days');
  const trialExpired = difference < 0;
  return !isFree(state) || !trialExpired;
}

export function getCurrentSchool(state, selector) {
  const pathname = window.location.pathname;

  const baseSelector =
    // Legacy registration
    pathname.startsWith('/register/')
      ? 'schoolRegForm.data.school'
      : // Advanced registration
      pathname.startsWith('/form/')
      ? 'registrationForm.data.school'
      : // Signed-in user
        'currentUser.data.current_school';

  if (selector) {
    return get(state, [baseSelector, selector].join('.'));
  }

  return get(state, baseSelector);
}

export function getStripePubKey(state) {
  return get(state, 'constants.data.billing.stripe_publishable_key');
}

export function getStripeCustomPubKey(state) {
  return get(state, 'constants.data.billing.stripe_custom_publishable_key');
}
