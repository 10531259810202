import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ActionButton } from 'components';
import validationText from '../validationText';
import './style.scss';
import withContext, { FormContext } from 'hocs/withContext';

class FileInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
    mimeTypes: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    maxSize: PropTypes.number // in MegaBytes
  };

  static defaultProps = {
    disabled: false,
    required: false,
    mimeTypes: ['image/x-png', 'image/jpeg'],
    maxSize: 1
  };

  constructor(props) {
    super(props);
    this.state = {
      filename: '',
      hasFile: false
    };
  }

  UNSAFE_componentWillMount() {
    this.init();
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  @bind
  init() {
    const validations = this.initValidations();
    this.props.context.init(this.props.name, '', validations);
  }

  @bind
  initValidations() {
    const { required, disabled } = this.props;
    const validations = {};

    if (!disabled) {
      if (required) {
        validations.isNotEmpty = null;
      }
    }

    return validations;
  }

  @bind
  handleChange(e) {
    const { maxSize } = this.props;
    const reader = new FileReader();
    const file = e.target.files[0];

    e.preventDefault();

    reader.onloadend = () => {
      this.setState({
        hasFile: true,
        filename: file.name
      });

      this.props.context.update(this.props.name, reader.result);
    };

    if (file) {
      if (file.size > maxSize * 1024 * 1024) {
        Actions.showFlash(`File size should be smaller than ${maxSize}MB`, 'alert');
        return;
      }
      reader.readAsDataURL(file);
    }
  }

  @bind
  handleRemove() {
    this.props.context.update(this.props.name, '');
    this.setState({ hasFile: false, filename: '' });
  }

  getValidationText() {
    const { name } = this.props;
    const { errors } = this.props.context;

    if (errors[name]) {
      return errors[name].join('; ');
    }

    const { validations } = this.props.context;

    if (!validations[name]) {
      return '';
    }

    return Object.keys(validations[name])
      .filter(type => validations[name][type] === false)
      .map(type => {
        if (typeof validationText[type] === 'function') {
          return validationText[type](this.props[type]);
        }

        return validationText[type];
      })
      .join('; ');
  }

  render() {
    const { className, mimeTypes, children, disabled } = this.props;
    const { hasFile, filename } = this.state;
    const invalidText = this.getValidationText();

    return (
      <div className={cx(className, 'form-fileinput')}>
        <div className="form-fileinput__inner">
          {children && <div className="form-fileinput__desc">{children}</div>}

          {hasFile ? (
            <div className="form-fileinput__field">
              <span className="form-fileinput__field-filename">{filename}</span>
              <ActionButton iconName="close" size={15} disabled={disabled} onClick={this.handleRemove} />
            </div>
          ) : (
            <div className="form-fileinput__field">
              <span className="button button--secondary form-fileinput__field-button">Choose File</span>
              <input
                className="form-fileinput__field-input"
                type="file"
                value=""
                onChange={this.handleChange}
                accept={mimeTypes}
              />
            </div>
          )}
        </div>

        {invalidText && <div className="form-fileinput__validation-text">{invalidText}</div>}
      </div>
    );
  }
}

export default withContext(FormContext)(FileInput);
