import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Steps from 'modals/ModalController/Steps';
import SelectStudents from './SelectStudents';
import SelectStaff from './SelectStaff';
import './style.scss';

class SelectNewsletterRecipients extends PureComponent {
  static propTypes = {
    data: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  steps = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      selectedKidIds: props.data.kidIds || [],
      selectedStaffIds: props.data.staffIds || []
    };
  }

  @bind
  updateSelectedKidIds(selectedKidIds) {
    this.setState({ selectedKidIds });
  }

  @bind
  updateSelectedStaffIds(selectedStaffIds) {
    this.setState({ selectedStaffIds });
  }

  @bind
  openSelectKids() {
    this.steps.current.goToStep('select-kids');
  }

  @bind
  openSelectStaff() {
    this.steps.current.goToStep('select-staff');
  }

  @bind
  handleSubmit() {
    this.props.onClose({ kidIds: this.state.selectedKidIds, staffIds: this.state.selectedStaffIds });
  }

  render() {
    const { selectedKidIds, selectedStaffIds } = this.state;

    return (
      <div className="select-newsletter-recipients">
        <Steps ref={this.steps}>
          <Steps.Item name="select-kids">
            <SelectStudents
              selectedKidIds={selectedKidIds}
              onSelect={this.updateSelectedKidIds}
              onSubmit={this.handleSubmit}
              onSelectStaff={this.openSelectStaff}
            />
          </Steps.Item>

          <Steps.Item hidden name="select-staff">
            <SelectStaff
              selectedStaffIds={selectedStaffIds}
              onSelect={this.updateSelectedStaffIds}
              onSubmit={this.handleSubmit}
              onBack={this.openSelectKids}
            />
          </Steps.Item>
        </Steps>
      </div>
    );
  }
}

export default SelectNewsletterRecipients;
