import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'components';
import './style.scss';

function CheckrCandidateAction({ data, onClose, packages = [] }) {
  const [isLoading, setIsLoading] = useState(false);

  const { candidateId, actionType } = data;

  useEffect(() => {
    req.getCheckrPackages();
  }, []);

  const handleStart = async values => {
    setIsLoading(true);
    let action, payloadKey, successMessage, errorMessage;

    if (actionType === 'invite') {
      action = 'inviteCheckrCandidate';
      payloadKey = 'invitation';
      successMessage = 'Re-invited successfully';
      errorMessage = 'Unable to re-invite';
    } else {
      action = 'reportCheckrCandidate';
      payloadKey = 'report';
      successMessage = 'Report generated successfully';
      errorMessage = 'Unable to generate report';
    }

    try {
      await req[action]({
        [payloadKey]: {
          package: values.package,
          candidate_id: candidateId
        }
      });
      Actions.showFlash(successMessage);
      onClose(false);
    } catch (e) {
      Actions.reportError(errorMessage, e);
      setIsLoading(false);
    }
  };

  const renderPackage = value => {
    let price = '';

    if (!isNaN(Number(value.price))) {
      price = value.price / 100;
      price = ` $(${price % 1 !== 0 ? price.toFixed(2) : price})`;
    }

    return `${value.name}${price}`;
  };

  return (
    <div className="modal__wrapper checkr-candidate-action">
      <div className="modal__header">
        <div className="modal__header-title">{actionType === 'invite' ? 'Re-Invite Staff' : 'Report Staff'}</div>
      </div>
      <div className="modal__container">
        <div className="checkr-candidate-action__description">Do you want to re-invite staff?</div>
        <Form onSubmit={handleStart}>
          <div className="form__row">
            <Form.Select name="package" title={'Select package'} type="radio" label="Package" required>
              {packages.map(p => (
                <Form.Select.Item key={p.id} value={p.slug} label={renderPackage(p)} />
              ))}
            </Form.Select>
          </div>
          <div className="form__row form__row--actions">
            <Form.Submit label={actionType === 'invite' ? 'Re-Invite' : 'Report'} disabled={isLoading} />
          </div>
        </Form>
      </div>
    </div>
  );
}

CheckrCandidateAction.propTypes = {
  data: PropTypes.shape({
    candidateId: PropTypes.string.isRequired,
    actionType: PropTypes.oneOf(['invite', 'report'])
  })
};

const mapStateToProps = state => ({
  packages: state.checkrPackages.data
});

export default connect(mapStateToProps)(CheckrCandidateAction);
