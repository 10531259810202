import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Steps from 'modals/ModalController/Steps';
import InvoiceDetails from './InvoiceDetails';
import SubmitInvoice from './SubmitInvoice';
import SelectKids from './SelectKids';
import ManagePresets from '../ManagePresets';
import './style.scss';

class CreateInvoice extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  _items = null;

  constructor(props) {
    super(props);

    this.state = {
      presetKind: 'preset',
      parent_invoice: null,
      kid_ids: [],
      familyKids: [],
      isSaving: false,
      isAutopostSaving: false,
      currentSubFamily: this.getDefaultSubFamily(),
      fromFamily: get(props, 'data.fromFamily', false)
    };

    if (this.state.fromFamily) {
      const familyKids = get(props, 'data.kids', []);

      this.state.familyKids = familyKids;
      this.state.kid_ids = this.state.currentSubFamily
        ? this.getSubFamilyKidIds(this.state.currentSubFamily)
        : familyKids.map(kid => kid.id);
    }
  }

  getDefaultSubFamily() {
    const subFamilies = get(this.props, 'data.subFamilies', []);
    let subFamilyId = get(this.props, 'data.subFamilyId');

    if (!subFamilyId && subFamilies.length) {
      subFamilyId = subFamilies[0].id;
    }

    return subFamilyId;
  }

  getSubFamilyKidIds(subFamilyId = this.state.currentSubFamily) {
    const subFamilies = get(this.props, 'data.subFamilies', []);
    return get(
      subFamilies.find(sf => sf.id === subFamilyId),
      'kid_ids',
      []
    );
  }

  getSubFamilyKids(subFamilyId = this.state.currentSubFamily) {
    const kidIds = this.getSubFamilyKidIds(subFamilyId);
    return this.state.familyKids.filter(kid => kidIds.includes(kid.id));
  }

  UNSAFE_componentWillMount() {
    req.school();
    const payload = {
      filters: {
        billing_plan: {
          kind: 'all',
          assigned: 'all'
        }
      }
    };
    if (this.props.data.kids) {
      req.students();
      if (this.state.fromFamily) {
        const { familyId: family_id, subFamilies } = this.props.data;
        if (subFamilies.length) {
          req.studentsByFamily({ family_id }).then(familyKids => {
            this.setState({
              familyKids
            });
          });
        }
      }
    } else {
      req.billingPlanStudents(payload); // without subfamilies
      req.billingPlanSubFamilyStudents(payload); // with subfamilies
    }
    if (this.props.data.isQuickAction) {
      req.rooms();
      req.tags();
    }
  }

  @bind
  removeKid(kidId) {
    const nextKidIds = this.state.kid_ids.filter(id => id !== kidId);
    this.setState({ kid_ids: nextKidIds });
  }

  @bind
  onSubFamilyChange(nextSubFamilyId) {
    this.setState({ currentSubFamily: nextSubFamilyId, kid_ids: this.getSubFamilyKidIds(nextSubFamilyId) });
  }

  @bind
  submit(auto_post) {
    const { subFamilyStudentHash } = this.props;
    const { currentSubFamily } = this.state;
    const requestPayload = {
      kid_ids: [],
      sub_families: [],
      parent_invoice: {
        ...this.state.parent_invoice,
        auto_post
      }
    };

    this.state.kid_ids.forEach(id => {
      if (currentSubFamily) {
        requestPayload.sub_families.push({
          kid_id: id,
          sub_family_id: currentSubFamily
        });
      } else if (subFamilyStudentHash[id]) {
        const kidId = get(subFamilyStudentHash, `${id}.studentId`);
        const subFamilyId = get(subFamilyStudentHash, `${id}.subFamilyId`);

        requestPayload.sub_families.push({
          kid_id: kidId,
          sub_family_id: subFamilyId
        });
      } else {
        requestPayload.kid_ids.push(id);
      }
    });

    const saveField = auto_post ? 'isAutopostSaving' : 'isSaving';

    this.setState({ [saveField]: true });
    req
      .createInvoice(requestPayload)
      .then(() => {
        Actions.showFlash('Invoice is successfully created');
        this.setState({ isSaving: false });
        this.props.onClose(true);
      })
      .catch(err => {
        Actions.reportError('Unable to create invoice', err);
        this.setState({ [saveField]: false });
      });
  }

  @bind
  openManagePresets(presetKind) {
    if (this._items) {
      this.setState({ presetKind }, () => this._items.goToStep('managePresets'));
    }
  }

  @bind
  openInvoiceDetails() {
    if (this._items) {
      this._items.goToStep(0);
    }
  }

  @bind
  updateField(fieldName) {
    return value => {
      this.setState({ [fieldName]: value });
    };
  }

  getKids() {
    const { currentSubFamily } = this.state;
    return currentSubFamily ? this.getSubFamilyKids() : get(this.props, 'data.kids', []);
  }

  render() {
    const { data, students, onClose } = this.props;
    const {
      parent_invoice,
      kid_ids,
      isSaving,
      isAutopostSaving,
      presetKind,
      fromFamily,
      currentSubFamily,
      familyKids
    } = this.state;

    return (
      <Steps ref={node => (this._items = node)}>
        <Steps.Item>
          <InvoiceDetails
            data={parent_invoice}
            isAutopostSaving={isAutopostSaving}
            onUpdate={this.updateField('parent_invoice')}
            onManagePresets={this.openManagePresets}
            onSubFamilyChange={this.onSubFamilyChange}
            students={this.getKids()}
            currentSubFamily={currentSubFamily}
            subFamilies={get(data, 'subFamilies', [])}
            onClose={onClose}
          />
        </Steps.Item>

        {(!familyKids.length || this.getKids().length > 1) && (
          <Steps.Item>
            <SelectKids
              defaultSelected={kid_ids}
              onUpdate={this.updateField('kid_ids')}
              students={data.kids ? this.getKids() : students}
              fromFamily={fromFamily}
            />
          </Steps.Item>
        )}

        <Steps.Item>
          <SubmitInvoice
            data={{
              parent_invoice,
              students: kid_ids.map(id =>
                data.kids ? data.kids.find(k => k.id === id) : students.find(s => s.id === id)
              )
            }}
            removeKid={this.removeKid}
            onSubmit={this.submit}
            isSaving={isSaving}
            isAutopostSaving={isAutopostSaving}
            subFamily={data.subFamily}
          />
        </Steps.Item>

        <Steps.Item hidden name="managePresets">
          <ManagePresets data={{ kind: presetKind }} onBack={this.openInvoiceDetails} />
        </Steps.Item>
      </Steps>
    );
  }
}

const mapStateToProps = (state, props) => {
  const subFamilyStudentHash = {};
  let allStudents = [];

  if (props.data && props.data.kids) {
    allStudents = state.students.data;
  } else {
    if (!state.billingPlanStudents.loading && !state.billingPlanSubFamilyStudents.loading) {
      allStudents = [...state.billingPlanStudents.data];

      state.billingPlanSubFamilyStudents.data.forEach(student => {
        if (student.sub_families) {
          const subFamilyStudents = student.sub_families.map(sf => {
            const hash = `${student.id}_${sf.id}`;
            subFamilyStudentHash[hash] = {
              studentId: student.id,
              subFamilyId: sf.id
            };
            return {
              ...student,
              subFamily: {
                ...sf
              },
              id: hash
            };
          });

          allStudents = allStudents.concat(subFamilyStudents);
        }
      });
    }
  }

  return {
    students: allStudents,
    subFamilyStudentHash
  };
};

export default connect(mapStateToProps)(CreateInvoice);
