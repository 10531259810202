import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'components';
import SelectMealItemsList from './SelectMealItemsList';
import MEAL_KINDS from 'constants/mealKinds.js';
import get from 'lodash/get';
import './style.scss';

class AddMeal extends Component {
  static propTypes = {
    mealItems: PropTypes.array,
    data: PropTypes.object
  };

  static defaultProps = {
    mealItems: [],
    data: {}
  };

  _steps = null;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isNew: !props.data.meal,
      selectedItemIds: this.getMealItemsIds(get(props.data, 'meal.meal_items', []))
    };
  }

  getMealItemsIds(mealItems = []) {
    return mealItems.map(item => item.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mealItems !== this.props.mealItems) {
      const itemIds = this.props.mealItems.map(m => m.id);

      this.setState({
        selectedItemIds: this.state.selectedItemIds.filter(i => itemIds.includes(i))
      });
    }
  }

  @bind
  createMealItem(name) {
    return req.addMealLabel({ label: { name } }).then(mealItem => {
      Actions.showFlash(`A new meal item "${name}" is added`);
      this.setState({ selectedItemIds: [...this.state.selectedItemIds, mealItem.id] });
    });
  }

  @bind
  async removeMealItem(id, name) {
    const shouldBeUpdated = await Actions.showModal('Confirmation', {
      title: 'Delete Meal Item',
      description:
        'Deleting this meal item will remove it from the list of available meals. Are you sure you want to delete this item?',
      yesButton: 'Yes, Delete',
      yesButtonProps: { secondary: false },
      noButtonProps: { secondary: true }
    });
    if (shouldBeUpdated) {
      return req.deleteMealLabel({ id }, { id }).then(() => {
        Actions.showFlash(`Meal item "${name}" has been deleted`);
      });
    }
  }

  @bind
  updateSelectedItemIds(ids) {
    this.setState({ selectedItemIds: ids });
  }

  @bind
  async submit(values) {
    const { selectedItemIds, isNew } = this.state;
    const { kind } = values;
    const { date, sectionId } = this.props.data;

    const request = isNew ? req.createMeal : req.updateMeal;
    const requestPayload = isNew
      ? {
          meal: {
            kind,
            meal_item_ids: selectedItemIds
          },
          date,
          section_id: sectionId
        }
      : {
          id: get(this.props.data, 'meal.id', ''),
          meal: {
            kind,
            meal_item_ids: selectedItemIds
          }
        };

    this.setState({ loading: true });

    try {
      const addedMeal = await request(requestPayload);
      Actions.showFlash('Meal saved!');
      this.props.onClose(addedMeal);
    } catch (err) {
      Actions.reportError('Unable to save meal', err);
      this.setState({ loading: false });
    }
  }

  render() {
    const { mealItems } = this.props;
    const { selectedItemIds, isNew, loading } = this.state;
    const kind = get(this.props.data, 'meal.kind', undefined);

    return (
      <div className="add-meal">
        <div className="modal__header">
          <div className="modal__header-title add-meal__header">
            <span className="add-meal__title">{isNew ? 'Add' : 'Edit'} Meals</span>
          </div>
        </div>
        <Form validate="onSubmit" onSubmit={this.submit}>
          <div className="modal__container add-meal__container">
            <div className="add-meal__row">
              <Form.Select
                name="kind"
                title="Select Type"
                type="radio"
                label="Choose Meal Type"
                defaultValue={kind}
                data-cy="kind"
                required
              >
                {Object.keys(MEAL_KINDS).map(m_kind => (
                  <Form.Select.Item key={m_kind} value={m_kind} label={MEAL_KINDS[m_kind]} />
                ))}
              </Form.Select>
            </div>
            <div className="add-meal__row">
              <div className="add-meal__row-label">Add Meal Items</div>
              <SelectMealItemsList
                meals={mealItems}
                selectedMealIds={selectedItemIds}
                onChange={this.updateSelectedItemIds}
                onCreate={this.createMealItem}
                onRemove={this.removeMealItem}
              />
            </div>
          </div>
          <div className="modal__controls add-meal__controls">
            <Form.Submit
              data-cy={`${isNew ? 'save' : 'update'}-meal`}
              label={isNew ? 'Add' : 'Update'}
              isLoading={loading}
            />
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  mealItems: state.mealLabels.data
});

export default connect(mapStateToProps)(AddMeal);
