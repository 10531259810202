import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';
import Steps from 'modals/ModalController/Steps';
import MessageStep from './MessageStep';
import SelectStudentsStep from './SelectStudentsStep';
import './style.scss';

class ShareMealPlan extends Component {
  stepsRef = React.createRef();

  static propTypes = {
    data: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  static getDefaultSubject(week) {
    return `Weekly Meal Plan [${moment(week[0]).format('MMM D')} - ${moment(week[1]).format('MMM D')}]`;
  }

  static getDefaultContent(schoolName) {
    const content = `Dear Parent,
Please find the meal plan attached for the week.
    
Thanks
${schoolName}`;

    return content;
  }

  constructor(props) {
    super(props);

    this.state = {
      roomId: props.data.roomId || '',
      selectedStudentIds: [],
      message: {
        subject: ShareMealPlan.getDefaultSubject(props.data.week),
        content: ShareMealPlan.getDefaultContent(props.schoolName)
      },
      filters: {
        search: undefined,
        roomId: undefined
      },
      isSaving: false
    };
  }

  UNSAFE_componentWillMount() {
    req.students();
    req.rooms();
  }

  @bind
  updateFilters(filters) {
    this.setState({
      filters: { ...this.state.filters, ...filters },
      selectedCount: 0,
      selectedIds: {}
    });
  }

  @bind
  updateSelectedStudents(studentIds) {
    this.setState({ selectedStudentIds: studentIds });
  }

  @bind
  goToMessageStep() {
    this.stepsRef.current.goToStep('message');
  }

  @bind
  goToStudentsStep(message) {
    this.setState({ message });
    this.stepsRef.current.goToStep('students');
  }

  @bind
  async sendMessage() {
    const { message, selectedStudentIds, roomId } = this.state;
    const { week } = this.props.data;
    this.setState({ isSaving: true });

    try {
      await req.mealPlanShare({
        kid_ids: selectedStudentIds,
        subject: message.subject,
        content: message.content,
        section_id: roomId,
        date_from: moment(week[0]).format('YYYY-MM-DD'),
        date_to: moment(week[1]).format('YYYY-MM-DD')
      });

      Actions.showFlash('Meal Plan has been shared');
      this.props.onClose(true);
    } catch (e) {
      Actions.reportError('Unable to share plan', e);
      this.setState({ isSaving: false });
    }
  }

  render() {
    const { rooms, students } = this.props;
    const { message, selectedStudentIds, filters } = this.state;

    return (
      <div className="share-meal">
        <Steps ref={this.stepsRef}>
          <Steps.Item name="message">
            <MessageStep message={message} onSubmit={this.goToStudentsStep} />
          </Steps.Item>

          <Steps.Item name="students">
            <SelectStudentsStep
              rooms={rooms}
              students={students}
              filters={filters}
              selectedStudents={selectedStudentIds}
              onSelect={this.updateSelectedStudents}
              onFiltersChange={this.updateFilters}
              onSubmit={this.sendMessage}
              isSaving={this.state.isSaving}
            />
          </Steps.Item>
        </Steps>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  students: state.students.data,
  schoolName: get(state, 'currentUser.data.current_school.name', '')
});

export default connect(mapStateToProps)(ShareMealPlan);
