import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Avatar, StudentList } from 'components';
import { renderAddress } from 'lib/utils';

class InvoicePreviewHeader extends Component {
  static propTypes = {
    receiptNumber: PropTypes.string,
    students: PropTypes.array
  };

  static defaultProps = {
    receiptNumber: '',
    students: []
  };

  render() {
    const { school, constants, students, receiptNumber } = this.props;

    return (
      <div className="invoice-preview-card__header">
        <div className="invoice-preview-card__school">
          <Avatar
            className="avatar--square invoice-preview-card__school-logo"
            url={school.profile_pic_url}
            type="logo"
          />

          <div className="invoice-preview-card__school-info">
            <div className="invoice-preview-card__school-info-name">{school.name}</div>

            <div className="invoice-preview-card__school-info-address">
              {renderAddress(school, constants.countries)}
            </div>

            {school.federal_tax_id && (
              <div className="invoice-preview-card__school-info-ein">EIN #: {school.federal_tax_id}</div>
            )}
          </div>
        </div>

        <div className="invoice-preview-card__invoice-number">{receiptNumber ? `#${receiptNumber}` : ''}</div>

        {!!students.length && (
          <div className="invoice-preview-card__students">
            <span className="invoice-preview-card__students-text">Billed For </span>
            <StudentList students={students} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  school: state.school.data,
  constants: state.constants.data
});

export default connect(mapStateToProps)(InvoicePreviewHeader);
