import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ScrollBars from 'react-custom-scrollbars';
import moment from 'moment';
import uniqWith from 'lodash/uniqWith';
import differenceBy from 'lodash/differenceBy';
import trim from 'lodash/trim';
import toLower from 'lodash/toLower';
import includes from 'lodash/includes';
import some from 'lodash/some';

import { plural } from 'lib/utils';
import { ButtonV2, Checkbox, Filter, Form, PageNav, Preloader, TooltipTrigger } from 'components';
import { Tooltip } from 'components/StudentList';
import PersonCard from 'modals/common/SelectPersonList/PersonCard';
import {
  ACTIVE_STATUS,
  CURRENT_YEAR_RANGE,
  LAST_3_MONTH_RANGE,
  LAST_6_MONTH_RANGE,
  PREVIOUS_YEAR_RANGE,
  statusAllowsRange
} from '../../components/Filter/Items/RegistrationStatus';

import './style.scss';

const PER_PAGE = 18;

const sanitizeForSearch = string => toLower(trim(string));

/**
 * @name SelectNewsletterPeople
 * @description This modal component allows a user to select child recipients
 * for a particular newsletter.
 * @summary Newsletters can save a list of people with it. It's possible that the user is selecting
 * additional people for the newsletter. For this reason, we take in a list of previously selected
 * people in `props.data` and maintain them separately. In this way, if selection is cleared, we can
 * revert to the previously selected people.
 * When the user is finished and the model is closed, we return all selected people back to the parent
 * including previously and newly selected people.
 * @attention Because we're using arrays to hold people in state, whenever we modify an array, we
 * return a new array so react state updates.
 * There are several collections of people that are maintained here:
 * - this.props.data.allPeople: This is a static collection of active people that represents the
 * starting set of people to choose from. Once the filters re-query, we'll switch to that set.
 * - `queriedPeople`: This is populated by the starting set of people and will hold the results of
 * the filter ui requerying the backend.
 * - `filteredPeople`: This holds queried people that have been locally filtered by the name field.
 * - `pagedPeople`: This holds a single page of filtered people.
 * - `selectedPeople`: This holds the people that have been selected by the user.
 */
class SelectNewsletterPeople extends PureComponent {
  static propTypes = {
    currentSchool: PropTypes.object,
    rooms: PropTypes.array,
    tags: PropTypes.array
  };

  static defaultProps = {
    currentSchool: {},
    rooms: [],
    tags: []
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: {},
      page: 1,
      queriedPeople: [],
      filteredPeople: [],
      pagedPeople: [],
      selectedPeople: []
    };
  }

  async UNSAFE_componentWillMount() {
    /*
    Previously selected people are handed in and we save them to state to allow
    for additional people to be added to the list. This way, if the selection is
    cleared, we still have the originally selected.
    */
    const queriedPeople = this.props.data.allPeople.filter(p => this.hasEmail(p));
    const filteredPeople = queriedPeople;
    const pagedPeople = filteredPeople.slice(0, PER_PAGE);
    const selectedPeople = this.props.data.selectedPeople;

    this.setState({
      page: 1,
      queriedPeople,
      filteredPeople,
      pagedPeople,
      selectedPeople,
      allPeopleSelected: filteredPeople.every(p =>
        selectedPeople.find(selected => selected.id === p.id) ? true : false
      )
    });

    if (this.props.data.onLoadComplete) {
      this.props.data.onLoadComplete();
    }
  }

  @bind
  async updateFilters(filters) {
    if (this.props.data.type !== 'student') {
      // Filtering has only been implemented for students.
      throw 'NOT IMPLEMENTED EXCEPTION';
    }

    this.setState({ loading: true });

    if (this.filtersHaveChanged(filters)) {
      const {
        section_id,
        tag_names,
        registration_status: { status, range }
      } = filters;

      const payload = {
        filters: {
          kid: {
            registration_status: status || ACTIVE_STATUS,
            tag_names: tag_names
          }
        }
      };

      if (section_id) {
        payload.filters.kid.current_section_id = section_id;
      }

      if (statusAllowsRange(status) && range) {
        let from = '';
        let to = moment();
        switch (range) {
          case LAST_3_MONTH_RANGE:
            from = moment().subtract(3, 'months');
            break;

          case LAST_6_MONTH_RANGE:
            from = moment().subtract(6, 'months');
            break;

          case PREVIOUS_YEAR_RANGE:
            from = moment()
              .subtract(1, 'years')
              .startOf('year');
            to = moment()
              .subtract(1, 'years')
              .endOf('year');
            break;

          case CURRENT_YEAR_RANGE:
            from = moment().startOf('year');
            break;
        }

        payload.filters.kid.status_changed_at = {
          gte: from.format('YYYY-MM-DD'),
          lte: to.format('YYYY-MM-DD')
        };
      }

      await this.query(payload);
    }

    // Filtering by title is handled locally.
    if (filters.title) {
      this.filterByTitle(filters.title);
    }

    this.setState({ filters, loading: false });
  }

  @bind
  filtersHaveChanged(filters) {
    // If any of the filter ui has changed, or if they've cleared the search field, consider it changed.
    const prevFilters = this.state.filters;
    const hasChanged =
      (prevFilters.title !== filters.title && !filters.title) ||
      prevFilters.section_id !== filters.section_id ||
      prevFilters.tag_names !== filters.tag_names ||
      prevFilters.registration_status?.status != filters.registration_status?.status ||
      prevFilters.registration_status?.range != filters.registration_status?.range;
    return hasChanged;
  }

  @bind
  hasEmail(person) {
    return person.email || (person.parents && person.parents.some(p => p.email));
  }

  @bind
  filterByTitle(title) {
    if (!title) {
      return;
    }

    const searchTerm = sanitizeForSearch(title);

    const selectedPeople = this.props.data.selectedPeople;
    const filteredPeople = this.state.queriedPeople.filter(
      people =>
        includes(sanitizeForSearch(people.name), searchTerm) ||
        some(people.parents, parent => includes(sanitizeForSearch(parent.name), searchTerm))
    );
    const pagedPeople = filteredPeople.slice(0, PER_PAGE);

    this.setState({
      page: 1,
      filteredPeople,
      pagedPeople,
      allPeopleSelected: filteredPeople.every(p =>
        selectedPeople.find(selected => selected.id === p.id) ? true : false
      )
    });
  }

  @bind
  async query(filters) {
    if (this.props.data.type !== 'student') {
      // Querying has only been implemented for students.
      throw 'NOT IMPLEMENTED EXCEPTION';
    }

    /* 
    All previously selected people should be reset to the parent's previously
    selected people. Selected people do not get saved to the newsletter until
    the dialog is closed, so a requery from filter changes will reset the selection.
    */
    const selectedPeople = this.props.data.selectedPeople;

    let queriedPeople = await req.studentsWithoutCache(filters);
    queriedPeople = queriedPeople.filter(p => this.hasEmail(p));
    const filteredPeople = queriedPeople;
    const pagedPeople = filteredPeople.slice(0, PER_PAGE);

    this.setState({
      page: 1,
      queriedPeople,
      filteredPeople,
      pagedPeople,
      allPeopleSelected: filteredPeople.every(p =>
        selectedPeople.find(selected => selected.id === p.id) ? true : false
      )
    });
  }

  @bind
  changePage(nextPage) {
    this.setState(prevState => ({
      page: nextPage,
      pagedPeople: prevState.filteredPeople.slice(PER_PAGE * (nextPage - 1), PER_PAGE * nextPage)
    }));
  }

  @bind
  onClose() {
    this.props.onClose({ selectedPeople: this.state.selectedPeople });
    this.setState({ filters: {} });
  }

  @bind
  onSelectPerson(personId) {
    const filteredPeople = this.state.filteredPeople;
    let selectedPeople = this.state.selectedPeople;

    if (selectedPeople.find(selected => selected.id === personId)) {
      selectedPeople = selectedPeople.filter(s => s.id !== personId);
    } else {
      const person = filteredPeople.find(p => p.id === personId);
      selectedPeople = [...selectedPeople, person];
    }

    this.setState({
      selectedPeople,
      allPeopleSelected: filteredPeople.every(p =>
        selectedPeople.find(selected => selected.id === p.id) ? true : false
      )
    });
  }

  @bind
  toggleSelectAll() {
    if (this.state.allPeopleSelected) {
      /*
      If the user de-selects all people, it should only be for the currently filtered people.
      We still need to keep the previously selected people, but not any that are in the 
      currently filtered people.
      */
      this.setState({
        selectedPeople: differenceBy(this.state.selectedPeople, this.state.filteredPeople, 'id'),
        allPeopleSelected: false
      });
    } else {
      this.setState({
        selectedPeople: uniqWith(
          [...this.state.selectedPeople, ...this.state.filteredPeople],
          (p1, p2) => p1.id === p2.id
        ),
        allPeopleSelected: true
      });
    }
  }

  @bind
  renderFilters() {
    const { filters, status, loading } = this.state;

    const FILTER_CONFIG = {
      section_id: { type: 'room' },
      tag_names: { type: 'tags' },
      registration_status: {
        type: 'registration-status',
        singleValue: true,
        shouldIgnore: value => {
          return value.status && value.status === 'active';
        }
      }
    };

    return (
      <Filter
        key={status}
        filters={FILTER_CONFIG}
        onSubmit={this.updateFilters}
        defaultValues={filters}
        className={'select-newsletter-people--filter'}
      >
        <div>
          <Form.Input
            defaultValue={filters.title}
            name="title"
            className="input--search"
            placeholder="Search by Student or Parent Name"
          />

          {loading && (
            <div className="select-newsletter-people__loading">
              <Preloader />
            </div>
          )}
        </div>
      </Filter>
    );
  }

  @bind
  renderSelectedPersonCount(people) {
    if (this.props.data.type === 'student') {
      return people.length == 0 ? 'NO STUDENTS SELECTED' : `${plural(people.length, 'STUDENT')} SELECTED`.toUpperCase();
    } else {
      return people.length == 0 ? 'NO STAFF SELECTED' : `${people.length} STAFF SELECTED`.toUpperCase();
    }
  }

  @bind
  getSelectionState() {
    return this.state.allPeopleSelected ? 'checked' : this.state.selectedPeople.length > 0 ? 'partial' : 'unchecked';
  }

  @bind
  renderPeople(people) {
    return people.map(p => {
      const personSelected = this.state.selectedPeople.find(selected => selected.id === p.id);
      return (
        <PersonCard
          key={`${p.id}`}
          id={p.id}
          name={p.name}
          color={p.color}
          type={'kid'}
          status={p.is_admin ? 'admin' : undefined}
          picURL={p.profile_pic_url}
          onSelect={this.onSelectPerson}
          selected={personSelected}
        />
      );
    });
  }

  render() {
    const page = this.state.page;
    const length = this.state.filteredPeople.length;
    const selectionState = this.getSelectionState();
    const checked = ['checked', 'partial'].includes(selectionState);
    const showDot = selectionState === 'partial';

    const tooltip =
      this.props.data.type === 'student'
        ? 'Only recipients with at least 1 parent with an email are displayed on this selection list.'
        : 'Only recipients with an email are displayed on this selection list.';

    return (
      <div className="select-newsletter-people">
        <div className="select-newsletter-people__people">
          <div className="modal__header modal__header--bordered">
            <div className="modal__header-title">
              Select {this.props.data.type === 'student' ? 'Students' : 'Staff'}
            </div>
            {this.props.data.type === 'student' && (
              <div className="select-newsletter-people__people__people-filter">{this.renderFilters()}</div>
            )}
            <div className="select-newsletter-people__people__tooltip">
              <h3>NOTE</h3>: {tooltip}
            </div>
            <div className="select-newsletter-people__people__people-header">
              <div className="select-newsletter-people__people__people-select">
                <Checkbox
                  checked={checked}
                  dot={showDot}
                  onChange={this.toggleSelectAll}
                  label={this.state.allPeopleSelected ? 'SELECT NONE' : 'SELECT ALL'}
                />
                <span className="select-newsletter-people__people__people-count">
                  {this.state.selectedPeople.length == 0 ? (
                    this.renderSelectedPersonCount(this.state.selectedPeople)
                  ) : (
                    <TooltipTrigger tooltip={<Tooltip students={this.state.selectedPeople} />} maxHeight={110}>
                      {this.renderSelectedPersonCount(this.state.selectedPeople)}
                    </TooltipTrigger>
                  )}
                </span>
              </div>
              <div className="select-newsletter-people__people__people-page">
                <PageNav
                  page={page}
                  total={length}
                  perPage={PER_PAGE}
                  onPrev={() => this.changePage(page - 1)}
                  onNext={() => this.changePage(page + 1)}
                />
              </div>
            </div>
          </div>
          <div className="modal__container">
            <ScrollBars autoHeight autoHeightMin={400}>
              <div className="select-person-list__cards">
                {this.state.pagedPeople.length > 0 ? (
                  this.renderPeople(this.state.pagedPeople)
                ) : (
                  <div className="select-person-list__no-results">
                    No {this.props.data.type === 'student' ? 'students' : 'staff'} found.
                  </div>
                )}
              </div>
            </ScrollBars>
          </div>
          <div className="modal__controls">
            <ButtonV2 label="Done" onClick={this.onClose} data-cy="done" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentSchool: state.currentUser.data.current_school,
  rooms: state.rooms.data,
  tags: state.tags.data
});

export default connect(mapStateToProps)(SelectNewsletterPeople);
