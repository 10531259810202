export const fetchPlaidLinkToken = () => {
  return req
    .plaidCreateLinkToken()
    .then(link_token => {
      if (link_token) {
        return link_token;
      }
      throw new Error('No link_token returned from API');
    })
    .catch(error => {
      throw error;
    });
};
