const initialState = {
  data: {}
};

const flashes = (state = { ...initialState }, action) => {
  switch (action.type) {
    case 'FLASH_CREATE': {
      const flashesList = { ...state.data };
      const id = Date.now().toString();
      // permit `id` inside every flash to make easy find it in store
      flashesList[id] = { ...action.payload, id: id, kind: action.payload.kind || 'message' };
      return { data: flashesList };
    }

    case 'FLASH_DELETE': {
      const flashesList = { ...state.data };
      delete flashesList[action.payload.id];
      return { data: flashesList };
    }

    case 'FLASH_RESET':
      return { ...initialState };

    default:
      return state;
  }
};

export default flashes;
