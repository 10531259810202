import React, { Fragment } from 'react';
import { Form } from 'components';

function Student({ rooms }) {
  return (
    <Fragment>
      <div className="form__row form__row--justified">
        <Form.Input
          label="First Name"
          placeholder="First Name"
          name="first_name"
          data-cy="first-name"
          required
          asterisk
        />
        <Form.Input label="Last Name" placeholder="Last Name" name="last_name" />
      </div>
      <div className="form__row form__row--justified">
        <Form.Select label="Room" data-cy="room" name="current_section_id" title="Select Room" required asterisk>
          {rooms.map(room => {
            return <Form.Select.Item key={room.id} value={room.id} label={room.name} />;
          })}
        </Form.Select>
      </div>
    </Fragment>
  );
}

export default Student;
