import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ActionButton } from 'components';

class Item extends PureComponent {
  static propTypes = {
    file: PropTypes.object,
    onDelete: PropTypes.func
  };

  @bind
  handleDelete() {
    this.props.onDelete(this.props.file.id);
  }

  render() {
    const { file } = this.props;
    const fileName = file.name || file.title;

    return (
      <div className="file-upload-field__item">
        <a href={file.file_url} target="_blank" className="file-upload-field__item-name" title={fileName}>
          {fileName}
        </a>

        <ActionButton
          size={12}
          iconName="close"
          className="file-upload-field__item-delete-btn"
          onClick={this.handleDelete}
        />
      </div>
    );
  }
}

export default Item;
