import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Form, Icon, TabView, ActionButton, ButtonV2, InfiniteScroll, Preloader } from 'components';
import PhotoCollection from './PhotoCollection';
import throttle from 'lodash/throttle';
import './style.scss';

const PER_PAGE = 50;

class ChoosePhoto extends Component {
  static propTypes = {
    photos: PropTypes.array,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    submitLabel: PropTypes.string,
    multiple: PropTypes.bool,
    onBack: PropTypes.func,
    onSelect: PropTypes.func,
    onSubmit: PropTypes.func
  };

  static defaultProps = {
    photos: [],
    submitLabel: 'Continue'
  };

  constructor(props) {
    super(props);

    this.state = {
      // Photos from the API
      photos: [],
      sent: true,
      page: 1
    };

    this.prevFilters = {};
    this.getPhotos = throttle(this.getPhotos, 500);
  }

  componentDidMount() {
    this.getPhotos();
  }

  @bind
  makeRequestPayload(filters = {}) {
    return {
      page: this.state.page,
      per_page: PER_PAGE,

      filters: {
        photo: {
          section_id: filters.section_id,
          tag_names: filters.tag_names,
          kid_ids: filters.kid_id ? [filters.kid_id] : undefined,
          sent: this.state.sent
        }
      }
    };
  }

  @bind
  async getPhotos(filters = this.prevFilters) {
    let response;

    try {
      response = await req.schoolPhotos(this.makeRequestPayload(filters));
    } catch (e) {
      Actions.reportError('Unable to retrieve photos', e);
      return;
    }

    const nextPhotos = response.photos;

    if (nextPhotos.length === 0) {
      if (this.state.page > 1) {
        this.setState({ page: this.state.page - 1 });
      }

      return;
    }

    this.setState({ photos: this.state.photos.concat(nextPhotos) });
  }

  @bind
  getNextPhotos() {
    if (this.state.photos.length % PER_PAGE === 0) {
      this.setState({ page: this.state.page + 1 }, this.getPhotos);
    }
  }

  @bind
  handleFiltersChange(filters) {
    this.prevFilters = filters;
    this.setState({ page: 1, photos: [] }, () => this.getPhotos(filters));
  }

  @bind
  handleTabChange(tabIndex) {
    this.setState({ page: 1, photos: [], sent: tabIndex === 0 }, this.getPhotos);
  }

  renderSentFilters() {
    const { kids, tags, rooms } = this.props;

    return (
      <Form className="choose-photo__filters" onChange={this.handleFiltersChange}>
        <div className="form__row">
          <Form.Typeahead
            name="kid_id"
            className="input--search"
            placeholder="Search Students"
            options={kids.map(kid => ({ id: kid.id, label: kid.name }))}
          />

          <Form.Select type="radio" name="section_id" title="All Rooms" enableClear>
            {rooms.map(r => (
              <Form.Select.Item key={r.id} value={r.id} label={r.name} />
            ))}
          </Form.Select>

          <Form.Select type="checkbox" name="tag_names" title="All Tags" enableClear>
            {tags.map(t => (
              <Form.Select.Item key={t.id} value={t.name} label={t.name} />
            ))}
          </Form.Select>
        </div>
      </Form>
    );
  }

  renderUnsentFilters() {
    const { rooms } = this.props;

    return (
      <Form className="choose-photo__filters" onChange={this.handleFiltersChange}>
        <div className="form__row">
          <Form.Select type="radio" name="section_id" title="Room" enableClear>
            {rooms.map(r => (
              <Form.Select.Item key={r.id} value={r.id} label={r.name} />
            ))}
          </Form.Select>
        </div>
      </Form>
    );
  }

  renderContent() {
    const { photos } = this.state;
    const { multiple, loading, selected, onSelect } = this.props;

    if (photos.length === 0 && !loading) {
      return this.renderPlaceholder();
    }

    return (
      <React.Fragment>
        <div className="choose-photo__preloader">
          <Preloader />
        </div>

        <Scrollbars className="choose-photo__scroll">
          <InfiniteScroll loading={loading} onEndReach={this.getNextPhotos}>
            <PhotoCollection multiple={multiple} photos={photos} selected={selected} onSelect={onSelect} />
          </InfiniteScroll>
        </Scrollbars>
      </React.Fragment>
    );
  }

  renderPlaceholder() {
    return (
      <div className="choose-photo__placeholder">
        <Icon name="image" className="choose-photo__placeholder-icon" size={54} color="#ddd" />

        <div className="choose-photo__placeholder-text">No photos</div>
      </div>
    );
  }

  render() {
    const { sent } = this.state;
    const { selected, submitLabel, onSubmit, onBack, loading } = this.props;

    const className = classNames('choose-photo', {
      'choose-photo--loading': loading
    });

    return (
      <div className={className}>
        <div className="modal__header">
          <ActionButton iconName="back" onClick={onBack} hidden={!onBack} />

          <div className="modal__header-title">Choose Photo</div>
        </div>

        <div className="choose-photo__content">
          <TabView activeTabIndex={sent ? 0 : 1} onChange={this.handleTabChange}>
            <TabView.Tab title="Sent (Tagged)">
              {this.renderSentFilters()}
              {this.renderContent()}
            </TabView.Tab>

            <TabView.Tab title="Unsent">
              {this.renderUnsentFilters()}
              {this.renderContent()}
            </TabView.Tab>
          </TabView>
        </div>

        {onSubmit && (
          <div className="modal__controls">
            <ButtonV2 label={submitLabel} disabled={selected === undefined} onClick={onSubmit} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  kids: state.students.data,
  tags: state.tags.data,
  rooms: state.rooms.data,
  photos: state.schoolPhotos.data.photos,
  loading: state.schoolPhotos.loading
});

export default connect(mapStateToProps)(ChoosePhoto);
