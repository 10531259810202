export default [
  '😀 Cheerful',
  '😶 Shy',
  '😠 Angry',
  '😭 Tearful',
  '😓 Anxious',
  '😊 Smiling',
  '😏 Mischievous',
  '🤒 Sick',
  '😨 Fearful',
  '\u{1F616} Frustrated',
  '😂 Joyful',
  '😌 Relieved',
  '🤕 Injured',
  '😬 Grimacing',
  '😇 Angelic',
  '\u{1F605} Embarrassed',
  '😲 Surprised',
  '🏃 Antsy',
  '😳 Flushed',
  '😒 Uncooperative',
  '😎 Cool',
  '🙁 Upset',
  '🤐 Not talkative',
  '\u{1F913} Attentive',
  '\u{1F389} Celebrating',
  '🤗 Loving',
  '😞 Sad',
  '😫 Tired',
  '😁 Beaming',
  '😘 Giving Kiss',
  '🤔 Thoughtful',
  '😢 Crying',
  '🙂 Content',
  '😋 Enjoying Food',
  '😴 Sleepy',
  '😐 Neutral',
  '😧 Worried',
  '😕 Confused',
  '🙃 Silly',
  '\u{1F624} Annoyed'
];
