import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import omit from 'lodash/omit';
import { TextInput, Icon, ButtonV2, TagItem, SelectList } from 'components';
import history from 'lib/history';
import cx from 'classnames';
import './style.scss';

class AddMilestone extends PureComponent {
  static propTypes = {
    data: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      search: '',
      selectedStandards: get(props, 'data.selectedStandards', []),
      selectedMeasures: get(props, 'data.selectedMeasures', {}),
      selectedIndicators: get(props, 'data.selectedIndicators', []),
      withIndicators: get(props, 'data.withIndicators', false)
    };
  }

  componentDidMount() {
    req.milestoneStandards();
    if (this.state.withIndicators) req.milestoneIndicators();
  }

  @bind
  changeSearch(search) {
    this.setState({ search });
  }

  @bind
  handleItemSelect(item) {
    const { selectedStandards, selectedMeasures, selectedIndicators, withIndicators } = this.state;
    const { milestoneIndicators } = this.props;

    if (withIndicators) {
      if (item.isStandard) {
        return;
      } else if (item.isMeasure) {
        if (item.isSelected) {
          this.setState({
            selectedMeasures: selectedMeasures.filter(m => m.id !== item.id),
            selectedIndicators: selectedIndicators.filter(i => i.measure_id !== item.id)
          });
        } else {
          this.setState({
            selectedMeasures: [...selectedMeasures, item],
            selectedIndicators: uniqBy([...selectedIndicators, ...(milestoneIndicators[item.id] || [])], 'id')
          });
        }
      } else {
        if (item.isSelected) {
          this.setState({
            selectedMeasures: selectedMeasures.filter(m => m.id !== item.measure_id),
            selectedIndicators: selectedIndicators.filter(i => i.id !== item.id)
          });
        } else {
          const newSelectedIndicators = [...selectedIndicators, item];
          const measureId = item.measure_id;
          const allIndicatorsSelected =
            milestoneIndicators[measureId].length ===
            newSelectedIndicators.filter(i => i.measure_id === measureId).length;
          this.setState({
            selectedMeasures: allIndicatorsSelected ? [...selectedMeasures, item.measure] : [...selectedMeasures],
            selectedIndicators: newSelectedIndicators
          });
        }
      }
    } else {
      if (item.isStandard) {
        this.setState({
          selectedStandards: uniqBy([...selectedStandards, item], 'id'),
          selectedMeasures: {
            ...selectedMeasures,
            [item.id]: item.measures
          }
        });
      } else {
        this.setState({
          selectedStandards: uniqBy([...selectedStandards, item.standard], 'id'),
          selectedMeasures: {
            ...selectedMeasures,
            [item.standard.id]: [...(selectedMeasures[item.standard.id] || []), item]
          }
        });
      }
    }
  }

  @bind
  deleteStandard(standardId) {
    const { selectedMeasures, selectedStandards } = this.state;

    this.setState({
      selectedMeasures: omit(selectedMeasures, [standardId]),
      selectedStandards: selectedStandards.filter(s => s.id !== standardId)
    });
  }

  @bind
  goToManage() {
    const shouldBeRedirected = window.confirm('You will be redirected to page to manage milestones. Are you sure?');

    if (shouldBeRedirected) {
      this.props.onCloseAll();
      history.push('/learning/plans/milestones');
    }
  }

  @bind
  onSubmit() {
    this.props.onClose({
      selectedMeasures: this.state.selectedMeasures,
      selectedStandards: this.state.selectedStandards,
      selectedIndicators: this.state.selectedIndicators
    });
  }

  @bind
  renderListItem(t) {
    if (t.isStandard) {
      return (
        <div
          className={cx('add-milestone-body__list-item', 'add-milestone-body__list-item--standard', {
            'add-milestone-body__list-item--selected': t.isSelected,
            'add-milestone-body__list-item--unselectable': this.state.withIndicators
          })}
        >
          <TagItem tag={{ id: t.id, name: t.short_name }} />
          {t.name}
        </div>
      );
    }

    return (
      <div
        className={cx(
          'add-milestone-body__list-item',
          `add-milestone-body__list-item--${t.isMeasure ? 'measure' : 'indicator'}`,
          { 'add-milestone-body__list-item--selected': t.isSelected }
        )}
      >
        {t.isSelected && (
          <Icon name="tick-1" size={10} color="#30bdef" className="add-milestone-body__list-item__icon" />
        )}
        {t.name && <div className="add-milestone-body__list-item__name">{t.name}</div>}
        {t.description}
      </div>
    );
  }

  renderCategories() {
    const { milestoneStandards, milestoneIndicators } = this.props;
    const { selectedMeasures, selectedIndicators, withIndicators } = this.state;
    const { search } = this.state;
    const items = [];

    if (withIndicators) {
      milestoneStandards.forEach(standard => {
        const temp = [];

        if (standard.name.toLowerCase().indexOf(search) > -1) {
          temp.push({
            ...standard,
            isStandard: true
          });
        }

        standard.measures.forEach(measure => {
          if (measure.description.toLowerCase().indexOf(search) > -1) {
            temp.push({
              ...measure,
              isMeasure: true,
              isSelected: !!selectedMeasures.find(m => m.id === measure.id),
              standard
            });
          }

          (milestoneIndicators[measure.id] || []).forEach(indicator => {
            if (indicator.description.toLowerCase().indexOf(search) > -1) {
              temp.push({
                ...indicator,
                isIndicator: true,
                isSelected: !!selectedIndicators.find(i => i.id === indicator.id),
                standard,
                measure
              });
            }
          });
        });

        items.push(...temp);
      });
    } else {
      milestoneStandards.forEach(standard => {
        const temp = [];

        standard.measures.forEach(measure => {
          const selectedMeasuresForStandard = selectedMeasures[standard.id] || [];

          if (
            !selectedMeasuresForStandard.find(m => m.id === measure.id) &&
            measure.description.toLowerCase().indexOf(search) > -1
          ) {
            temp.push({ ...measure, isStandard: false, standard });
          }
        });

        if (temp.length && standard.name.toLowerCase().indexOf(search) > -1) {
          temp.unshift({ ...standard, isStandard: true });
        }

        items.push(...temp);
      });
    }

    if (items.length === 0) {
      return <div className="add-milestone-body__no-result">No result found</div>;
    }

    return <SelectList items={items} renderItem={this.renderListItem} onSelect={this.handleItemSelect} />;
  }

  renderSelected() {
    const { withIndicators, selectedStandards, selectedMeasures, selectedIndicators } = this.state;
    const { milestoneIndicators } = this.props;
    const selectedCount = withIndicators
      ? selectedMeasures.filter(m => !milestoneIndicators[m.id]).length + selectedIndicators.length
      : selectedStandards.length;

    if (!selectedCount) return <div className="add-milestone-body__no-result">No milestones selected</div>;

    if (withIndicators) {
      return <div className="add-milestone-body__selected-count">{`${selectedCount} Selected`}</div>;
    } else {
      return (
        <div className="add-milestone-body__selected-labels">
          {selectedStandards.map(standard => {
            const tag = {
              name: `${standard.short_name} (${(selectedMeasures[standard.id] || []).length})`,
              id: standard.id
            };
            return <TagItem key={standard.id} tag={tag} onDelete={this.deleteStandard} />;
          })}
        </div>
      );
    }
  }

  render() {
    const { search } = this.state;
    const { loading } = this.props;

    return (
      <div className="add-milestone">
        <div className="modal__header">
          <div className="modal__header-title add-milestone__header">
            <span className="add-milestone__title">Add Milestone</span>
          </div>
        </div>

        <div className="modal__container add-milestone__container">
          <div className="add-milestone-body">
            <div className="add-milestone-body__header">
              <div className="add-milestone-body__search">
                <Icon size={18} name="search" className="add-milestone-body__search-icon" />

                <TextInput
                  type="text"
                  value={search}
                  className="add-milestone-body__search-input"
                  placeholder="Search Milestone"
                  onChange={this.changeSearch}
                />
              </div>
            </div>

            <div className="add-milestone-body__inner">
              {this.renderSelected()}
              <div className="add-milestone-body__list">{this.renderCategories()}</div>
            </div>
          </div>

          <div className="help-link">
            <span className="help-link__content">
              Click on
              <span className="help-link__anchor" onClick={this.goToManage}>
                MANAGE
              </span>
              to manage the Milestones list.
            </span>
          </div>
        </div>

        <div className="modal__controls add-milestone__controls">
          <ButtonV2 label="Submit" onClick={this.onSubmit} disabled={loading} />
        </div>
      </div>
    );
  }
}

const getIndicatorsMap = (indicators = []) => {
  return indicators.reduce((map, indicator) => {
    const measureId = indicator.measure_id;
    if (typeof map[measureId] === 'undefined') map[measureId] = [];
    map[measureId].push(indicator);
    return map;
  }, {});
};

const mapStateToProps = (state, props) => ({
  milestoneStandards: state.milestoneStandards.data,
  milestoneIndicators: get(props, 'data.withIndicators', false) ? getIndicatorsMap(state.milestoneIndicators.data) : {},
  loading: state.milestoneStandards.loading
});

export default connect(mapStateToProps)(AddMilestone);
