import Room from './Room';
import Tags from './Tags';
import Select from './Select';
import DatePicker from './DatePicker';
import DateRange from './DateRange';
import CheckboxGroup from './CheckboxGroup';
import Checkbox from './Checkbox';
import SignFilter from './SignFilter';
import LessonCategory from './LessonCategory';
import MilestoneCategories from './MilestoneCategories';
import Typeahead from './Typeahead';
import AgeFilter from './AgeFilter';
import DateFilter from './DateFilter';
import HorizontalRule from './HorizontalRule';
import { FilterItemRegistrationStatus } from './RegistrationStatus';

export default {
  room: Room,
  tags: Tags,
  select: Select,
  'multi-select': Select,
  'date-range': DateRange,
  'date-picker': DatePicker,
  'lesson-category': LessonCategory,
  'milestone-categories': MilestoneCategories,
  checkbox: Checkbox,
  'checkbox-group': CheckboxGroup,
  typeahead: Typeahead,
  'sign-filter': SignFilter,
  'age-filter': AgeFilter,
  'date-filter': DateFilter,
  'registration-status': FilterItemRegistrationStatus,
  hr: HorizontalRule
};
