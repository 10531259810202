import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ActionButton, ButtonV2, Form, TooltipPortalTrigger } from 'components';
import { Scrollbars } from 'react-custom-scrollbars';
import get from 'lodash/get';
import ReportFields from '../ReportFields';

function ReportStep({ data, onPrev, schools, webSettings, currentSchool, selectedIds, onSelect, isSaving, onSubmit }) {
  const selectedSchools = useMemo(() => schools.filter(s => selectedIds.includes(s.id)), [schools, selectedIds]);
  const [savingSelections, setSavingSelections] = useState(false);
  const handleSaveSelections = async () => {
    setSavingSelections(true);
    try {
      await req.updateProfile({
        user: {
          web_settings: {
            ...webSettings,
            [currentSchool.id]: {
              ...get(webSettings, currentSchool.id, {}),
              reportSchoolSelection: selectedIds
            }
          }
        }
      });
      Actions.showFlash('Your selections have been saved');
    } catch (e) {
      Actions.reportError('Your selections are unable to be saved', e);
    }
    setSavingSelections(false);
  };

  return (
    <div className="report-corp">
      <div className="modal__header modal__header--bordered">
        <span className="modal__header-title">
          <ActionButton iconName="back" className="modal__header-back" onClick={onPrev} />
          {data.summary_title || 'Select Month'}
        </span>
        <span className="modal__header-steps">
          <span>Step 2</span> / 2
        </span>
      </div>

      <Form validateOn="submit" onSubmit={onSubmit} isLoading={isSaving} className="modal__container">
        <div className="report-corp__content">
          {data.form_data?.length > 0 && (
            <div className="report-corp__month">
              {data.description && <div className="report-corp__month-desc">{data.description}</div>}
              <ReportFields data={data} />
            </div>
          )}
          <div className="report-corp__list">
            <div className="report-corp__list-title">{selectedIds.length} selected</div>
            <div className="report-corp__list-body">
              <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={380}>
                {selectedSchools.map(s => (
                  <div key={s.id} className="report-corp__list-item">
                    <TooltipPortalTrigger
                      side="center"
                      tooltip={<div className="report-corp__list-item__tooltip">{s.name}</div>}
                    >
                      <div className="report-corp__list-item__name">{s.name}</div>
                    </TooltipPortalTrigger>
                    <ActionButton iconName="delete" onClick={() => onSelect([s.id], false)} />
                  </div>
                ))}
              </Scrollbars>
            </div>
            <div className="report-corp__list-footer">
              <ButtonV2
                type="button"
                tertiary
                isLoading={savingSelections}
                label="Save Selections"
                onClick={handleSaveSelections}
              />
            </div>
          </div>
        </div>
        <div className="form__row form__row--actions">
          <Form.Submit label="Generate Report" disabled={selectedIds.length === 0} />
        </div>
      </Form>
    </div>
  );
}

ReportStep.propTypes = {
  onPrev: PropTypes.func,
  onMonthChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onSelect: PropTypes.func,
  isSaving: PropTypes.bool,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  month: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  schools: PropTypes.array
};

export default ReportStep;
