import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ButtonV2 } from 'components';
import NewsletterType from './NewsletterType';
import './style.scss';

function NewsletterTypeSelect({ history, onClose, data }) {
  const [type, setType] = useState('dnd');
  const isTemplate = data && data.isTemplate;

  const handleContinue = () => {
    onClose();
    if (type === 'dnd') {
      history.push('/parent-connection/newsletters/compose', { isTemplate });
    } else {
      history.push('/parent-connection/newsletters/simple', { isTemplate });
    }
  };

  return (
    <div className="modal__wrapper newsletter-type-select">
      <div className="modal__header">
        <div className="subheader">{isTemplate ? 'Create Newsletter Template' : 'Create Newsletter'}</div>
      </div>

      <div className="modal__container">
        <div className="newsletter-type-select__title">Select your editing experience</div>
        <div className="newsletter-type-select__subtitle">
          Choose to create a template using our Drag-n-Drop Editor or the Custom HTML Editor.
        </div>

        <div className="newsletter-type-select__body">
          <NewsletterType
            title="Drag-n-Drop Editor"
            icon="newsletter-template"
            onClick={() => setType('dnd')}
            checked={type === 'dnd'}
            data-cy="dnd"
          >
            Select modules like text blocks or images and use drag & drop design tools.
          </NewsletterType>
          <NewsletterType
            title="Custom HTML Editor"
            icon="newsletter-code"
            onClick={() => setType('code')}
            checked={type === 'code'}
            data-cy="custom"
          >
            Add HTML, edit the code of your newsletter. No drag & drop functionality available.
          </NewsletterType>
        </div>
      </div>

      <div className="modal__controls">
        <ButtonV2 label="Cancel" secondary onClick={onClose} />
        <ButtonV2 label="Continue" onClick={handleContinue} data-cy="continue" />
      </div>
    </div>
  );
}

export default withRouter(NewsletterTypeSelect);
