import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ActionButton } from 'components';
import './style.scss';

class UpgradeBrowserPanel extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
  };

  @bind
  hide() {
    this.props.onClose();
  }

  render() {
    const className = cx('upgrade-browser-panel', {
      'upgrade-browser-panel--hidden': !this.props.open
    });

    return (
      <div className={className}>
        <div className="upgrade-browser-panel__inner">
          <div className="upgrade-browser-panel__message">
            You are using old Internet Explorer browser which may not display the website correctly. Try using other
            browsers
          </div>

          <ActionButton className="upgrade-browser-panel__close-btn" iconName="close" onClick={this.hide} size={12} />
        </div>
      </div>
    );
  }
}

export default UpgradeBrowserPanel;
