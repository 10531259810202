import React, { Component } from 'react';
import { Form } from 'components';
import './style.scss';

class ChangePlan extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isLoading: false
    };
  }

  render() {
    return (
      <div className="change-plan">
        <div className="modal__header">
          <span className="modal__header-title">Change Plan</span>
          <div className="modal__header-note">Are you sure you want to change this plan?</div>
        </div>

        <div className="modal__container">
          <Form validateOn="submit" onSubmit={this.submit} isLoading={this.state.isLoading}>
            <div className="form__row form__row--text">
              <span className="accent">If Yes,</span> please fill the required fileds to proceed with.
            </div>

            <div className="form__row form__row--justified">
              <Form.Input name="name" placeholder="Your Name" required />
              <Form.Input name="phone" placeholder="Phone Number" required />
            </div>

            <div className="form__row form__row--actions">
              <Form.Submit label="Submit" />
            </div>
          </Form>
        </div>
      </div>
    );
  }

  @bind
  submit(values) {
    const { onClose } = this.props;
    this.setState({ isLoading: true });
    req
      .changePlan(values)
      .then(() => {
        Actions.showFlash('Request has been sent', 'success');
        this.setState({ isLoading: false });
        onClose();
      })
      .catch(() => {
        Actions.showFlash('Something went wrong, try later.', 'warning');
        this.setState({ isLoading: false });
      });
  }
}

export default ChangePlan;
