import React from 'react';
import { ActionButton, TooltipTrigger } from 'components';
import './style.scss';

const CorporateIcon = ({ ...props }) => {
  return (
    <TooltipTrigger className="corporate-icon" side="center" tooltip="Corporate Linked" {...props}>
      <ActionButton iconName="copyright" size={14} />
    </TooltipTrigger>
  );
};

export default CorporateIcon;
