import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getTenantApiUrlFromState } from 'lib/auth';
import { ButtonV2, TextInput, RichEditor, InlineEditor, SelectGroup } from 'components';

class SendEmailForm extends Component {
  static propTypes = {
    message: PropTypes.shape({
      subject: PropTypes.string,
      content: PropTypes.string
    }),

    isEdit: PropTypes.bool,
    currentUser: PropTypes.object,
    isSavingDraft: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func,
    onSendTest: PropTypes.func,
    isSaving: PropTypes.bool,
    fromAutomation: PropTypes.bool,
    children: PropTypes.node
  };

  static defaultProps = {
    message: {
      subject: '',
      content: '',
      replyTo: ''
    }
  };

  constructor(props) {
    super(props);

    const { currentUser } = props;
    const { name: schoolName, sender_email: schoolEmail } = currentUser.current_school;

    this.emailOptions = [{ label: `${currentUser.name} - ${currentUser.email}`, value: currentUser.email }];
    if (schoolEmail) {
      this.emailOptions.unshift({ label: `${schoolName} - ${schoolEmail}`, value: schoolEmail });
    }

    this.state = {
      subject: props.message.subject,
      content: props.message.content,
      groupEntity: props.message.groupEntity,
      isSubjectValid: true,
      isReplyToValid: true,
      isContentValid: true,
      awsLoaded: false,
      // Arbitrary email addresses are only allowed when a school email is not set.
      // When a school email is set, we only respect the message's existing replyTo if it
      // matches an existing dropdown option
      replyTo:
        (this.emailOptions.length === 1 || this.emailOptions.find(o => o.value === props.message.replyTo)
          ? props.message.replyTo
          : null) || this.emailOptions[0].value
    };
  }

  componentDidMount() {
    req.awsSignature({ key: 'enrollment_messages' }).then(awsSignature => {
      this.setState({ awsLoaded: true, froalaConfig: this.getFroalaConfig(awsSignature) });
    });
  }

  getS3Config(awsSignature) {
    return {
      bucket: awsSignature.bucket,
      region: awsSignature.region,
      keyStart: awsSignature.key_start,
      params: {
        acl: awsSignature.acl,
        AWSAccessKeyId: awsSignature.access_key_id,
        policy: awsSignature.policy,
        signature: awsSignature.signature
      }
    };
  }

  getFroalaConfig(awsSignature) {
    const { apiUrl, authToken } = this.props;
    const s3Config = this.getS3Config(awsSignature);

    return {
      height: 245,
      toolbarSticky: false,
      imageUploadToS3: s3Config,
      fileUploadToS3: s3Config,
      videoUploadToS3: s3Config,
      imageMaxSize: 1024 * 1024 * 100, // 100MB
      fileMaxSize: 1024 * 1024 * 100, // 100MB
      videoMaxSize: 1024 * 1024 * 100, // 100MB
      imageManagerLoadURL: `${apiUrl}/school/photos`,
      imageManagerLoadParams: { token: authToken },
      toolbarButtons: {
        moreMisc: {
          buttons: ['undo', 'redo', 'html']
        }
      }
    };
  }

  @bind
  updateSubject(subject) {
    this.setState({ subject, isSubjectValid: true });
  }

  @bind
  updateContent(content) {
    this.setState({ content, isContentValid: true });
  }

  @bind
  updateReplyTo(replyTo) {
    this.setState({ replyTo, isReplyToValid: true });
  }

  @bind
  validate() {
    const isSubjectValid = Boolean(this.state.subject);
    const isContentValid = Boolean(this.state.content);
    const isReplyToValid = this.props.fromTemplate || Boolean(this.state.replyTo);

    this.setState({ isSubjectValid, isContentValid, isReplyToValid });
    return isSubjectValid && isContentValid && isReplyToValid;
  }

  @bind
  async handleSendTest() {
    if (!this.validate()) {
      return;
    }

    const { subject, content, replyTo } = this.state;
    const { currentUser } = this.props;

    const shouldSendTest = await Actions.showModal('Confirmation', {
      title: 'Send Test Email',
      description: `Test email will be sent to ${currentUser.email} with sample data.`,
      yesButton: 'Send',
      noButton: 'Cancel'
    });

    if (shouldSendTest) {
      this.props.onSendTest({ subject, content, replyTo });
    }
  }

  @bind
  handleContinue() {
    const { subject, content, replyTo } = this.state;

    if (this.validate()) {
      this.props.onSubmit({ subject, content, replyTo });
    }
  }

  @bind
  handleSaveTemplate() {
    const { subject, content } = this.state;

    if (this.validate()) {
      this.props.onSaveTemplate({ subject, content });
    }
  }

  @bind
  handleSaveDraft() {
    const { subject, content, replyTo } = this.state;

    if (this.validate()) {
      this.props.onSaveDraft({ subject, content, replyTo });
    }
  }

  render() {
    const {
      subject,
      content,
      isSubjectValid,
      isReplyToValid,
      isContentValid,
      replyTo,
      awsLoaded,
      groupEntity,
      froalaConfig
    } = this.state;
    const {
      isSaving,
      isSavingDraft,
      isSavingTemplate,
      fromTemplate,
      fromLead,
      fromAutomation,
      children,
      kid
    } = this.props;

    return (
      <div className="modal__container communication-send-msg__edit-msg-form">
        {fromLead && kid && (
          <div className="communication-send-msg__edit-msg-to">
            <span className="communication-send-msg__edit-msg-to-label">To:</span>
            <span className="communication-send-msg__edit-msg-to-name">{kid.name}</span>
          </div>
        )}

        <div className="form__row">
          <div className={cx('form-input', { 'form-input--invalid': !isSubjectValid })}>
            <div className="form-input__field">
              <TextInput name="subject" placeholder="Add Title" value={subject} onChange={this.updateSubject} />
            </div>

            {!isSubjectValid && (
              <div className="form-input__validation-text" data-cy="subject-empty-error">
                Cannot be empty
              </div>
            )}
          </div>
        </div>

        {!fromTemplate && (
          <div
            className={cx('communication-send-msg__edit-msg-reply', {
              'communication-send-msg__edit-msg-reply--invalid': !isReplyToValid
            })}
            data-cy="reply-to"
          >
            <span className="communication-send-msg__edit-msg-reply-label">Reply To:</span>
            {this.emailOptions.length === 1 ? (
              <>
                <span className="communication-send-msg__edit-msg-reply-email">{replyTo}</span>
                <InlineEditor.Input
                  placeholder="Enter Email"
                  onChange={this.updateReplyTo}
                  defaultValue={replyTo}
                  type="email"
                />
              </>
            ) : (
              <span className="communication-send-msg__edit-msg-reply-email">
                <SelectGroup
                  type="radio"
                  className="select-recipients-box__reply-email-select"
                  checked={replyTo}
                  onChange={this.updateReplyTo}
                >
                  {this.emailOptions.map(({ label, value }) => (
                    <SelectGroup.Item key={value} value={value} label={label} />
                  ))}
                </SelectGroup>
              </span>
            )}

            {!isReplyToValid && (
              <div className="communication-send-msg__edit-msg-reply-validation">Cannot be empty</div>
            )}
          </div>
        )}

        <div className="form__row communication-send-msg__edit-msg-froala">
          <div className={cx('form-input', { 'form-input--invalid': !isContentValid })}>
            <div className="form-input__field" data-cy="message-text-editor-field">
              <RichEditor
                name="message"
                placeholder="Write a message"
                value={content}
                config={froalaConfig}
                loading={!awsLoaded}
                onChange={this.updateContent}
              />
            </div>
          </div>

          {!isContentValid && (
            <div
              className="form-input__validation-text communication-send-msg__edit-msg-err"
              data-cy="content-empty-error"
            >
              Cannot be empty
            </div>
          )}
        </div>

        {children}

        {this.props.onSendTest && (
          <div>
            <ButtonV2
              tertiary
              className="communication-send-msg__edit-msg-test"
              label="Send as a Test Email"
              onClick={this.handleSendTest}
              data-cy="send-as-test"
            />
          </div>
        )}

        {!groupEntity && (
          <div className="modal__controls">
            {fromTemplate && (
              <ButtonV2
                label="Save Template"
                onClick={this.handleSaveTemplate}
                disabled={isSavingTemplate}
                data-cy="save-template"
              />
            )}
            {!fromTemplate && !fromLead && !fromAutomation && (
              <ButtonV2
                secondary
                label="Save as Draft"
                onClick={this.handleSaveDraft}
                isLoading={isSavingDraft}
                disabled={isSavingDraft}
                data-cy="save-as-draft"
              />
            )}
            {!fromTemplate && !fromAutomation && (
              <ButtonV2
                label={fromLead ? 'Send' : 'Continue'}
                onClick={this.handleContinue}
                disabled={fromLead ? isSaving : isSavingDraft}
                data-cy={fromLead ? 'send' : 'continue'}
              />
            )}
            {fromAutomation && (
              <ButtonV2 label="Save" onClick={this.handleContinue} isLoading={isSaving} data-cy="save" />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser.data,
  awsSignature: state.awsSignature.data,
  authToken: state.currentUser.data.auth_token,
  apiUrl: getTenantApiUrlFromState(state)
});

export default connect(mapStateToProps)(SendEmailForm);
