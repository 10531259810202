import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ActionButton, ButtonV2, Preloader, Form } from 'components';
import { applyFilters } from 'lib/utils';
import FilterBar from './FilterBar';
import SelectPersonList from '../SelectPersonList';
import './style.scss';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class SelectKids extends Component {
  static propTypes = {
    defaultSelected: PropTypes.array,
    // if action runs on the next step
    onUpdate: PropTypes.func,
    // if action runs immediately
    onSubmit: PropTypes.func,
    // title of the modal
    title: PropTypes.string,
    // subtitle of the modal below filter
    subtitle: PropTypes.string,
    // button label
    label: PropTypes.string,
    // custom condition for kids filtering
    loading: PropTypes.bool,
    isContinueLoading: PropTypes.bool,
    students: PropTypes.array,
    validations: PropTypes.object,
    disableInvalid: PropTypes.bool,
    noResultText: PropTypes.string,
    showSelectSignIn: PropTypes.bool,
    selectType: PropTypes.oneOf(['checkbox', 'radio']),
    isOptional: PropTypes.bool, // whether to disable button when no kid is selected
    onUpdateFilters: PropTypes.func
  };

  static defaultProps = {
    defaultSelected: [],
    title: 'Select Students',
    label: 'Continue',
    customCondition: {},
    loading: false,
    showSelectSignIn: false,
    students: [],
    isContinueLoading: false
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: {
        tagIds: [],
        search: '',
        roomId: '',
        isSignedIn: undefined
      },

      selectedKids: []
    };

    if (props.customCondition) {
      this.state.filters = {
        ...this.state.filters,
        [props.customCondition.paramKey]: props.customCondition.paramValue
      };
    }

    if (props.defaultSelected) {
      this.state.selectedKids = [...props.defaultSelected];
    }
  }

  get isAllSelectedSignedIn() {
    const { selectedKids } = this.state;
    const { students } = this.props;
    return selectedKids.length > 0 && selectedKids.length === students.filter(s => s.is_signed_in).length;
  }

  @bind
  updateFilters(values) {
    this.setState({
      filters: { ...values, isSignedIn: values.isSignedIn || undefined }
    });
    if (this.props.onFilter) {
      this.props.onFilter(values);
    }
  }

  @bind
  save() {
    this.props.onUpdate && this.props.onUpdate(this.state.selectedKids);
    this.props.context?.nextStep && !this.props.finalStep
      ? this.props.context.nextStep()
      : this.props.onSubmit && this.props.onSubmit(this.state.selectedKids);
  }

  @bind
  goBack() {
    this.props.onUpdate(this.state.selectedKids);
    this.props.context?.prevStep();
  }

  @bind
  updateSelectedKids(selectedKids) {
    this.setState({ selectedKids });
  }

  render() {
    const { filters, selectedKids } = this.state;
    const {
      rooms,
      students,
      tags,
      title,
      label,
      customCondition,
      loading,
      subtitle,
      isContinueLoading,
      validations,
      disableInvalid,
      noResultText,
      steps,
      selectType,
      isOptional,
      showSelectSignIn,
      renderPersonCard
    } = this.props;
    const { currentStep, totalSteps } = this.props.context || {};

    const filteredStudents = applyFilters(students, filters, customCondition.method);

    return (
      <div className="modal__wrapper select-kids">
        <div className="modal__header modal__header--bordered">
          {totalSteps && currentStep > 1 && (
            <ActionButton iconName="back" className="select-kids__back-button" onClick={this.goBack} />
          )}

          <div className="modal__header-title">{title}</div>

          {currentStep && (
            <div className="modal__header-steps">
              <span>Step {currentStep}</span> / {steps || totalSteps}
            </div>
          )}

          <div className="modal__filter-bar">
            <FilterBar
              rooms={rooms}
              tags={tags}
              onUpdate={this.updateFilters}
              customControl={
                showSelectSignIn ? (
                  <div className="select-kids__select-signed-in">
                    <Form.Checkbox name="isSignedIn" label="Show signed-in students only" />
                  </div>
                ) : (
                  customCondition.control
                )
              }
            />
          </div>

          {subtitle && <div className="modal__header-subtitle">{subtitle}</div>}
        </div>

        <div className="select-kids__container">
          {loading ? (
            <div className="select-kids__loading">
              <Preloader large />
            </div>
          ) : (
            <SelectPersonList
              persons={filteredStudents}
              allPersons={students}
              selectedIds={selectedKids}
              onSelect={this.updateSelectedKids}
              validations={validations}
              noResultText={noResultText}
              disableInvalid={disableInvalid}
              selectType={selectType}
              renderPersonCard={renderPersonCard}
            />
          )}
        </div>

        <div className="modal__controls">
          <ButtonV2
            className="select-kids__submit"
            label={label}
            onClick={this.save}
            disabled={!isOptional && selectedKids.length === 0}
            isLoading={isContinueLoading}
            data-cy="submit"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  tags: state.tags.data
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));
export default enhance(SelectKids);
