import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { ActionButton, Icon } from 'components';
import './style.scss';
import { Link } from 'components';

class Flash extends Component {
  static propTypes = {
    kind: PropTypes.oneOf(['success', 'warning', 'alert', 'message']),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  };

  static defaultProps = {
    kind: 'message'
  };

  static FADE_IN = 10; // + transition time (css)
  static FADE_OUT = 200;
  static DELAY = 5000;

  constructor() {
    super();
    this.state = { visible: false };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ visible: true });
      this.setTimer();
    }, Flash.FADE_IN);
  }

  get messages() {
    const text = this.props.flash.text;
    return Array.isArray(text) ? text : [text];
  }

  setTimer() {
    // clear any existing timer
    this._timer !== null ? clearTimeout(this._timer) : null;

    // hide after `delay` milliseconds
    this._timer = setTimeout(() => {
      this.hideFlash();
      this._timer = null;
    }, Flash.DELAY * this.messages.length);
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
  }

  @bind
  hideFlash() {
    const { flash, deleteFlash } = this.props;
    this.setState({ visible: false });
    setTimeout(() => {
      deleteFlash(flash.id);
    }, Flash.FADE_OUT);
  }

  render() {
    const { flash } = this.props;

    const flashCN = classNames({
      flash: true,
      ['flash--' + flash.kind]: Boolean(flash.kind),
      'flash--visible': this.state.visible,
      'flash--bulleted': this.messages.length > 1
    });

    return (
      <div className={flashCN}>
        <Icon name={flash.kind} size={24} />
        {this.messages.map((message, i) => (
          <span key={i} dangerouslySetInnerHTML={{ __html: message }} />
        ))}
        {flash.linkTarget && (
          <Link to={flash.linkTarget} className="flash__link">
            {flash.linkName}
          </Link>
        )}
        <ActionButton size={14} iconName="close" onClick={this.hideFlash} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  flashes: state.flashes.data
});

const mapDispatchToProps = dispatch => ({
  deleteFlash: id => dispatch({ type: 'FLASH_DELETE', payload: { id } })
});

export default connect(mapStateToProps, mapDispatchToProps)(Flash);
