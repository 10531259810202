import React, { Component } from 'react';
import { Icon } from 'components';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class ChooseTypes extends Component {
  @bind
  selectType(type) {
    this.props.context.goToStep(type);
  }

  render() {
    return (
      <div className="modal__wrapper">
        <div className="modal__header modal__header--bordered">
          <span className="modal__header-title">Add Actions</span>
          <span className="modal__header-steps">
            <span>Step 1</span> / 2
          </span>
        </div>
        <div className="modal__container add-automation add-automation__choose-type">
          <div className="modal__container add-automation__choose-type__title">Choose your action type</div>

          <div className="path-selector path-selector--horizontal">
            <div className="path-selector__option" onClick={() => this.selectType('send_email')}>
              <div className="path-selector__option-inner">
                <Icon size={24} color="#30bdef" name="message" />
                SEND EMAIL
              </div>
            </div>

            <div className="path-selector__option" onClick={() => this.selectType('create_task')}>
              <div className="path-selector__option-inner">
                <Icon size={24} color="#30bdef" name="task" />
                CREATE TASK
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(ChooseTypes);
