export default plans => {
  return plans.map(plan => {
    if (plan.kind === 'tuition') {
      return {
        ...plan,
        plan_invoice_items: plan.plan_invoice_items.map(i => {
          return {
            ...i,
            ledger_id: i.ledger ? i.ledger.id : undefined,
            description: i.description || i.desc
          };
        })
      };
    } else {
      return plan;
    }
  });
};
