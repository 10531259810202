import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import { ActionButton } from 'components';
import PhotoActivityThumb from '../PhotoActivityThumb';
import './style.scss';

class PhotoActivityPanel extends Component {
  static propTypes = {
    uploaded: PropTypes.bool,
    photos: PropTypes.array,
    selectedPhotoIds: PropTypes.array,
    onOpenGallery: PropTypes.func,
    onOpenUpload: PropTypes.func
  };

  static defaultProps = {
    selectedPhotoIds: []
  };

  @bind
  openGallery() {
    const { onOpenGallery } = this.props;

    if (onOpenGallery) {
      onOpenGallery();
    }
  }

  @bind
  openUpload() {
    const { onOpenUpload } = this.props;

    if (onOpenUpload) {
      onOpenUpload();
    }
  }

  renderEmptyPanel() {
    return (
      <div className="photo-activity-panel__empty">
        <span className="photo-activity-panel__open-btn" onClick={this.openUpload} data-cy="upload-photos">
          Upload Photos
        </span>

        <span className="photo-activity-panel__empty-divider">or</span>

        <span className="photo-activity-panel__open-btn" onClick={this.openGallery}>
          Select Photos
        </span>
      </div>
    );
  }

  renderPhotos() {
    const { photos, selectedPhotoIds, uploaded } = this.props;
    const photoHash = {};

    photos.forEach(p => {
      photoHash[p.id] = p;
    });

    const renderedPhotos = selectedPhotoIds
      .filter(i => photoHash[i])
      .map(p => <PhotoActivityThumb key={p} url={uploaded ? photoHash[p].data : photoHash[p].thumb_url} />);

    return (
      <div className="photo-activity-panel__photos">
        <Scrollbars className="photo-activity-panel__photos-scroll">
          <div className="photo-activity-panel__photos-list">{renderedPhotos}</div>
        </Scrollbars>
      </div>
    );
  }

  render() {
    const { selectedPhotoIds, uploaded } = this.props;
    const isEmpty = selectedPhotoIds.length === 0;
    const title = isEmpty ? 'Photos' : `Photos (${selectedPhotoIds.length} selected)`;
    const content = isEmpty ? this.renderEmptyPanel() : this.renderPhotos();

    return (
      <div className="photo-activity-panel">
        <div className="photo-activity-panel__header">
          <div className="photo-activity-panel__title">{title}</div>

          <ActionButton
            className="photo-activity-panel__manage-btn"
            onClick={this.openGallery}
            size={12}
            iconName="manage"
            title="Manage"
            hidden={isEmpty || uploaded}
          />

          <ActionButton
            className="photo-activity-panel__manage-btn"
            onClick={this.openUpload}
            size={12}
            iconName="upload-1"
            title="Upload more"
            hidden={!uploaded}
          />
        </div>

        {content}
      </div>
    );
  }
}

export default PhotoActivityPanel;
