import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import { ActionButton, StudentCard, Form } from 'components';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

// select staff and further actions

const OTHER_VALUE = 'other';

class BulkSignActions_Step3 extends Component {
  constructor() {
    super();
    this.state = {
      showExtraName: false, // when stuff is 'other'
      transferToNewRoom: false // when need to transfer students in new room on sign-out operation
    };
  }

  @bind
  configureForm(values) {
    switch (this.props.action) {
      case 'in':
        this.setState({ showExtraName: values.staffId === OTHER_VALUE });
        break;
      case 'out':
        this.setState({
          transferToNewRoom: values.transferToNewRoom,
          showExtraName: values.staffId === OTHER_VALUE
        });
    }
  }

  @bind
  deleteStudent(id) {
    return () => {
      const { selectedStudents, updateStudents } = this.props;
      const index = selectedStudents.indexOf(id);
      const newSelectedStudents = [...selectedStudents.slice(0, index), ...selectedStudents.slice(index + 1)];
      updateStudents(newSelectedStudents);
    };
  }

  @bind
  submit(values) {
    const { staff } = this.props;
    const hour = moment(values.time, 'hh:mm A').get('hour');
    const minute = moment(values.time, 'hh:mm A').get('minute');

    const newValues = {
      ...values,
      date: moment(values.date)
        .set({ hour, minute })
        .format()
    };

    if (values.staffId !== OTHER_VALUE) {
      const obj = staff.find(s => s.id === values.staffId);
      newValues.type = 'Teacher';
      newValues.staffName = obj ? obj.name : undefined;
    } else {
      newValues.staffId = undefined;
      newValues.type = undefined;
    }

    this.props.submit(newValues);
  }

  render() {
    const { students, staff, rooms, selectedStudents, action, isBatchLoading, defaultValues } = this.props;
    const { transferToNewRoom, showExtraName } = this.state;
    const actionName = transferToNewRoom ? 'Transfer' : `Sign ${action}`;
    const isPastDate = moment(defaultValues.report_date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');

    return (
      <div className="modal__wrapper">
        <div className="modal__header modal__header--bordered">
          <ActionButton iconName="back" className="modal__header-back" onClick={() => this.props.context.prevStep()} />
          <span className="modal__header-title">Sign-{action} Attendance</span>
          <span className="modal__header-steps">
            <span>Step 3</span> / 3
          </span>
        </div>
        <div className="modal__container">
          <Form onChange={this.configureForm} onSubmit={this.submit} validateOn="submit" isLoading={isBatchLoading}>
            <div className="form__row form__row--justified bulk-sign__actions">
              <div className="bulk-sign__params">
                <div className="form__row form__row--text">
                  Please fill the required fields to <span>Sign-{action}</span> the selected students
                </div>

                {action === 'in' && (
                  <div className="form__row">
                    <Form.Select name="toRoom" type="radio" title="To room" data-cy="toRoom" required>
                      {rooms.map(r => (
                        <Form.Select.Item key={r.id} label={r.name} value={r.id} />
                      ))}
                    </Form.Select>
                  </div>
                )}

                <div className="form__row">
                  <Form.Select name="staffId" type="radio" title={`Signed ${action} by`} data-cy="toStaffId" required>
                    {staff.map(r => (
                      <Form.Select.Item key={r.id} label={r.name} value={r.id} />
                    ))}
                    <Form.Select.Item
                      key={OTHER_VALUE}
                      label="Other"
                      value={OTHER_VALUE}
                      className="select-group__item--accent"
                    />
                  </Form.Select>
                </div>

                {showExtraName && (
                  <div className="form__row">
                    <Form.Input name="staffName" placeholder="Staff Name" data-cy="staffName" required />
                  </div>
                )}

                {action === 'out' && (
                  <div className="form__row">
                    <Form.Checkbox
                      name="transferToNewRoom"
                      defaultValue={transferToNewRoom}
                      label="Transfer them to different room"
                      data-cy="transferToNewRoom"
                    />
                  </div>
                )}

                {action === 'out' && transferToNewRoom && (
                  <div className="form__row">
                    <Form.Select name="toRoom" type="radio" title="To room" data-cy="toRoom" required>
                      {rooms.map(r => (
                        <Form.Select.Item key={r.id} label={r.name} value={r.id} />
                      ))}
                    </Form.Select>
                  </div>
                )}

                <div className="form__row form__row--justified">
                  <Form.DatePicker
                    name="date"
                    placeholder="Select Date"
                    defaultValue={moment(defaultValues.report_date).toDate()}
                    disabled
                  />
                  <Form.TimeInput name="time" defaultValue={moment().format('hh:mm A')} required />
                </div>
              </div>
              <div className="bulk-sign__list">
                <div className="bulk-sign__list-title">
                  <span>{selectedStudents.length}</span> selected
                </div>
                <div className="bulk-sign__list-content">
                  <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={285}>
                    {students
                      .filter(s => {
                        return selectedStudents.indexOf(s.id) > -1;
                      })
                      .map((student, index) => {
                        const assignedRoom = rooms.find(
                          room => room.id === (student.signed_in_section_id || student.current_section_id)
                        );
                        let assignedRoomName = `${student.signed_in_section_id ? 'Signed in to ' : 'Assigned to '} ${
                          assignedRoom?.name
                        }`;
                        return (
                          <StudentCard
                            checkable={false}
                            action={this.deleteStudent(student.id)}
                            actionIcon="delete"
                            key={`student-${index}`}
                            student={student}
                            roomName={assignedRoomName}
                          />
                        );
                      })}
                  </Scrollbars>
                </div>
              </div>
            </div>

            {isPastDate && (
              <div className="bulk-sign__warning">
                You are signing-in students for an older date -{' '}
                {moment(defaultValues.report_date).format('MMM D, YYYY')}
              </div>
            )}

            <div className="form__row form__row--actions">
              <Form.Submit label={actionName} data-cy={action === 'out' ? 'signOut' : 'signIn'} />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isBatchLoading: state.batch.loading
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(BulkSignActions_Step3);
