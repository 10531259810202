import moment from 'moment';
import { MAX_EFFECTIVE_DOSES } from './';

const squashDoses = doses => {
  if (doses.length < 3) {
    return doses;
  }

  if (doses[0].defaults.type === 'fixed') {
    return [{ ...doses[0], effectiveDoses: MAX_EFFECTIVE_DOSES }];
  }

  for (let i = 1; i < doses.length; i++) {
    if (doses[i].defaults.type === 'in') {
      let numTimes = 1;

      for (let j = i + 1; j < doses.length; j++) {
        if (
          doses[i].defaults.type !== doses[j].defaults.type ||
          doses[i].defaults.in !== doses[j].defaults.in ||
          doses[i].defaults.inType !== doses[j].defaults.inType
        ) {
          break;
        }

        numTimes++;
      }

      if (numTimes > 1) {
        doses[i].defaults = { ...doses[i].defaults, type: 'repeats', numTimes: numTimes };
        doses[i].effectiveDoses = numTimes;
        doses.splice(i + 1, numTimes - 1);
      }
    }
  }

  return doses.map((dose, i) => ({ ...dose, number: i + 1 }));
};

const createDefaults = dose => {
  if (dose.due_in !== null && dose.due_in !== undefined) {
    if (dose.dose_number === 1) {
      return { type: 'within', within: dose.due_in };
    }

    if (dose.due_in > 0) {
      if (dose.due_in % 365 === 0) {
        return { type: 'in', in: dose.due_in / 365, inType: 'Years' };
      }
      if (dose.due_in % 30 === 0) {
        return { type: 'in', in: dose.due_in / 30, inType: 'Months' };
      }
      if (dose.due_in % 7 === 0) {
        return { type: 'in', in: dose.due_in / 7, inType: 'Weeks' };
      }
    }

    return { type: 'in', in: dose.due_in, inType: 'Days' };
  }
  if (dose.fixed_date !== null && dose.fixed_date !== undefined) {
    return { type: 'fixed', fixed: moment(dose.fixed_date).toDate() };
  }
  if (dose.month_due !== null && dose.month_due !== undefined) {
    return { type: 'age', age: dose.month_due };
  }

  if (dose.dose_number === 1) {
    return { type: 'age' };
  } else {
    return { type: 'in' };
  }
};

export const createDoses = existingVaccine => {
  if (!existingVaccine) {
    return [{ id: 1, number: 1, effectiveDoses: 1, defaults: { type: 'age' } }];
  }

  return squashDoses(
    existingVaccine.vaccine_doses.map((dose, i) => ({
      id: i + 1,
      number: i + 1,
      effectiveDoses: 1,
      defaults: createDefaults(dose)
    }))
  );
};
