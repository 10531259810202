import React, { useRef, forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { Form } from 'components';
import Attachment from './Attachment';
import SelectRecipientsBox from './SelectRecipientsBox';
import Recipient from './Recipient';

// ForwardRef is used to call <Form> validations and submit from outside:
// Source: https://stackoverflow.com/a/37950970/699972
function FormSend({ forwardedRef, document, kind, kids, staff, leads, onEditClick, onSubmit }) {
  let tabIndex = 0;
  const [isRecipientsValid, setIsRecipientsValid] = useState(true);

  const [formData, setFormData] = useState({});
  const formRef = useRef(null);

  useEffect(() => {
    const formValues = get(formRef, 'current.state.values');
    const recipientsData = {
      kid_ids: get(formData, 'kid_ids') || (document && document.kid_ids),
      teacher_ids: get(formData, 'teacher_ids') || (document && document.teacher_ids),
      registration_ids: get(formData, 'registration_ids') || (document && document.registration_ids)
    };

    let noExpiration = get(formValues, 'no_expiration', false);
    if (document && document.title && !formValues.title) {
      formRef.current.updateField('title', document.title);
    }
    if (document && document.expiry_date === null && document.status === 'draft') {
      noExpiration = true;
      formRef.current.updateField('no_expiration', noExpiration);
    }

    setFormData({
      ...formData,
      ...formValues,
      ...recipientsData,
      no_expiration: noExpiration
    });
  }, [forwardedRef, document]);

  const onFormUpdate = values => {
    if (!values.no_expiration && values.expiry_date < values.reminder_date) {
      formRef.current.updateField('reminder_date', values.expiry_date);
    }

    setFormData({
      ...formData,
      ...values
    });
  };

  const onRecipientsChange = (students, staff, leads) => {
    setIsRecipientsValid(true);
    setFormData({
      ...formData,
      kid_ids: students,
      teacher_ids: staff,
      registration_ids: leads
    });
  };

  const validateRecipients = () => {
    const { kid_ids, teacher_ids, registration_ids } = formData;
    const combined = [...kid_ids, ...teacher_ids, ...registration_ids].filter(Boolean);
    if (kind === 'resend') {
      setIsRecipientsValid(true);
      return true;
    } else if (kind === 'send' && combined.length < 1) {
      setIsRecipientsValid(false);
      return false;
    } else {
      setIsRecipientsValid(true);
      return true;
    }
  };

  const validateAllForms = () => {
    formRef.current.validateForm();
    const isFormValid = formRef.current.isFormValid();
    const isRecipientsValid = validateRecipients();

    return [isFormValid, isRecipientsValid].every(Boolean);
  };

  const submitForm = () => {
    formRef.current.validateForm();
    if (validateAllForms() && typeof onSubmit === 'function') {
      let payload = { ...formData };

      if (payload.expiry_date === undefined) {
        payload.expiry_date = null;
      }

      if (payload.reminder_date === undefined) {
        payload.reminder_date = null;
      }

      if (payload.no_expiration) {
        delete payload.no_expiration;
        payload.expiry_date = null;
      }

      onSubmit(payload);
    }
  };

  useImperativeHandle(forwardedRef, () => ({
    validateForm: validateAllForms,
    submitForm: submitForm
  }));

  if (!document || isEmpty(document) || !formData) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="documents__send-form">
        <Form ref={formRef} validateOn="submit" onChange={onFormUpdate}>
          <div className="form__legend">
            <div className="form__legend-label">Summary</div>

            <p className="form__legend-text">
              Provide a name for your document. This name will display on the recipient notification and document list
              under the Student, Staff, and Leads profiles.
            </p>
          </div>

          <div className="form__row form__row--justified mb-16">
            <Form.Input
              label="Document Name"
              placeholder="Document Name"
              name="title"
              defaultValue={document.title}
              autoFocus
              tabIndex={++tabIndex}
              required
            />
          </div>

          <p className="form__label mb-8">Send To</p>
          {document.attachable ? (
            <Recipient data={document.attachable} />
          ) : (
            <React.Fragment>
              <p className="form__legend-text mb-16">
                Choose who should receive your document by selecting a group of recipients, then click Add Recipient to
                select individuals. Student, Staff, and Leads can be added to the same document by selecting the first
                group, adding the individuals, then choosing the next group.
              </p>

              <SelectRecipientsBox
                kids={kids}
                selectedKids={document.kid_ids}
                staff={staff}
                selectedStaff={document.teacher_ids}
                leads={leads}
                selectedLeads={document.registration_ids}
                onChange={onRecipientsChange}
                error={!isRecipientsValid && 'At least one recipient must be selected'}
              />
            </React.Fragment>
          )}

          <div className="mb-20" />

          <Attachment
            onEdit={() => {
              typeof onEditClick === 'function' && onEditClick();
            }}
            attachments={[
              {
                file_url: document.file_url,
                title: document.title,
                id: document.id,
                _uncommitted: true,
                sharing_enabled: false
              }
            ]}
          />

          <div className="mb-24" />

          <div className="form__legend">
            <div className="form__legend-label">Options</div>

            <p className="form__legend-text">
              If needed, choose a document expiration date, or select the no expiration date option. Use the Send
              Reminder On field to have the system automatically send a reminder email to the recipient to complete the
              document.
            </p>
          </div>

          <div className="form__row form__row--justified form__row--short">
            <div className="documents-send-form__expiration-date">
              <Form.DatePicker
                clearable
                className="mb-8"
                label="Expiration Date"
                data-cy="expiry_date"
                name="expiry_date"
                required={kind === 'send' && !formData.no_expiration}
                disablePast
                disabled={formData.no_expiration}
                defaultValue={document.expiry_date && moment(document.expiry_date).toDate()}
              />
              <Form.Checkbox name="no_expiration" label="No expiration date" />
            </div>
            <Form.DatePicker
              clearable
              label="Send Reminder On"
              data-cy="reminder_date"
              name="reminder_date"
              disableDays={day => {
                const disable = true;
                const enable = false;

                const currentDate = moment(day).toDate();
                const expiryDate = get(formData, 'expiry_date');
                const noExpiration = get(formData, 'no_expiration');

                const isToday = moment().isSame(currentDate, 'day');
                const isPast = moment(currentDate).isBefore();
                const isAfterExpiryDate = moment(currentDate).isAfter(expiryDate, 'day');

                // Disabling dates logic
                switch (true) {
                  case isToday:
                    return enable;
                  case isPast:
                    return disable;
                  case isAfterExpiryDate && !noExpiration:
                    return disable;
                  default:
                    return enable;
                }
              }}
              required={kind === 'send'}
              defaultValue={document.reminder_date && moment(document.reminder_date).toDate()}
            />
          </div>

          <div className="mb-16" />

          <div className="form__legend">
            <div className="form__legend-label">Message</div>
            <p className="form__legend-text">
              Add message you would like to include in the document request. A link to the document and instructions
              will automatically be included.
            </p>
          </div>

          <div className="form__row form__row--justified -mt-8">
            <Form.Textarea
              data-cy="message"
              placeholder="Add description here..."
              name="description"
              tabIndex={++tabIndex}
              defaultValue={document.description}
            />
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  kids: state.students.data,
  staff: state.staff.data,
  leads: state.allEnrollments.data.map(lead => ({ ...lead, name: [lead.first_name, lead.last_name].join(' ') }))
});

const ConnectedFormSend = connect(mapStateToProps)(FormSend);
export default forwardRef((props, ref) => <ConnectedFormSend {...props} forwardedRef={ref} />);
