import React from 'react';
import { usePlaidLink } from 'react-plaid-link';
const PlaidLink = ({ children, className, linkToken, onSuccess, onExit }) => {
  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess,
    onExit
  });
  return (
    <div onClick={() => ready && open()} className={className}>
      {children}
    </div>
  );
};
export default PlaidLink;
