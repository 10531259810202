const createFetchReducer = (reducerName, initialState) => (state = { ...initialState }, action) => {
  switch (action.type) {
    case `${reducerName}_FETCH`:
      return { ...state, loading: true };

    case `${reducerName}_FETCH_SUCCESS`:
      return { ...state, loading: false, data: action.payload };

    case `${reducerName}_FETCH_FAILURE`:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default createFetchReducer;
