const createUpdateTenantReducer = (reducerName, initialState) => {
  return (state = { ...initialState }, action) => {
    switch (action.type) {
      case `${reducerName}_UPDATE_TENANT_SUCCESS`: {
        return { ...state, data: action.payload };
      }

      default:
        return state;
    }
  };
};

export default createUpdateTenantReducer;
