import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, ActionButton } from 'components';
import moment from 'moment';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class ActivityScheduleStep extends Component {
  static propTypes = {
    activity: PropTypes.object,
    onSave: PropTypes.func
  };

  static defaultProps = {};

  @bind
  saveActivity(values) {
    if (this.props.onSave) {
      this.props.onSave(values);
      this.props.context.nextStep();
    }
  }

  render() {
    const { activity, onSave, title } = this.props;
    const { currentStep, totalSteps } = this.props.context;
    const activityTime =
      typeof activity.activity_time === 'string' && activity.activity_time.length < 10
        ? activity.activity_time
        : Helpers.formatTime(activity.activity_time || new Date());

    return (
      <div className="modal__wrapper create-recurring-activity__new-activity">
        <div className="modal__header modal__header--bordered create-recurring-activity__header">
          <ActionButton iconName="back" className="modal__back" onClick={this.props.context.prevStep} />
          <span className="modal__header-title">{title}</span>

          <div className="modal__header-steps">
            <span>Step {currentStep}</span> / {totalSteps}
          </div>
        </div>

        <Form className="create-recurring-activity__form" onSubmit={this.saveActivity} onChange={onSave}>
          <div className="create-recurring-activity__section">
            <div className="create-recurring-activity__section-header">Configure auto activity schedule and time</div>

            <div className="create-recurring-activity__section-body">
              <div className="create-recurring-activity__inline">
                <div className="create-recurring-activity__inline-label">REPEAT DAYS:</div>
                <Form.WeekdayPicker name="days" defaultValue={activity.days || ['M', 'T', 'W', 'Th', 'F']} required />
              </div>

              <div className="create-recurring-activity__inline create-recurring-activity__inline--time">
                <div className="create-recurring-activity__inline-label">REPEAT TIME:</div>
                <Form.TimeInput name="activity_time" required defaultValue={activityTime} />
              </div>
            </div>

            <div className="create-recurring-activity__helper">
              Note: Activities will be automatically created only if students are signed-in during this time. If you
              already use "Sign In-Out" everyday from our mobile app, then no additional action needed.
            </div>
          </div>

          <div className="create-recurring-activity__section create-recurring-activity__section--schedule">
            <div className="create-recurring-activity__section-header">
              Auto activity end date (Leave blank if you prefer not to end)
            </div>

            <div className="create-recurring-activity__section-body">
              <div className="create-recurring-activity__inline create-recurring-activity__inline--time">
                <div className="create-recurring-activity__inline-label">END DATE</div>
                <Form.DatePicker
                  name="ends_at"
                  clearable
                  defaultValue={activity.ends_at ? moment(activity.ends_at).toDate() : undefined}
                />
              </div>
            </div>
          </div>

          <div className="form__row--actions create-recurring-activity__actions">
            <Form.Submit label="Continue" />
          </div>
        </Form>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(ActivityScheduleStep);
