import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './style.scss';

class ActivityIcon extends Component {
  render() {
    const { type } = this.props;
    const className = cx('activity-icon', `activity-icon__${type}`);
    return <div className={className} />;
  }
}

ActivityIcon.propTypes = {
  type: PropTypes.string.isRequired
};

export default ActivityIcon;
