import React, { Component } from 'react';
import PhotoThumb from '../PhotoThumb';

export default class LearningActivity extends Component {
  render() {
    const { activity } = this.props;
    return activity?.activiable ? (
      <div className="activity__learning">
        <div className="activity__paragraph">
          {(Array.isArray(activity.activiable)
            ? activity.activiable[0]
            : activity.activiable
          )?.learning_activity_categories
            ?.map(category => category.value)
            .join(' | ')}
        </div>

        {activity.comment && <div className="activity__paragraph">{activity.comment}</div>}
        <PhotoThumb photoUrl={activity.photo_url} />
      </div>
    ) : (
      <div>No activity data</div>
    );
  }
}
