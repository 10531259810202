import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Form } from 'components';
import './style.scss';

class Registration extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isLoading: false
    };
  }

  @bind
  requestCode() {
    const { currentUser } = this.props;

    req
      .resendParentInvite({ email: currentUser.email })
      .then(() => {
        Actions.showFlash('Please check your email for invite code');
      })
      .catch(err => {
        Actions.reportError('Requesting invite code failed', err);
      });
  }

  render() {
    return (
      <div className="modal__wrapper registration-modal">
        <div className="modal__header registration-modal__header">
          <div className="registration-modal__icon-wrapper">
            <Icon name="lock" size={40} />
          </div>
        </div>
        <div className="modal__container">
          <div className="registration-modal__title">Enter Your 10 Character Code</div>
          <div className="registration-modal__description">
            Input the code you received <br /> in email to get started
          </div>
          <Form
            className="registration-modal__form"
            validateOn="submit"
            onSubmit={this.submit}
            isLoading={this.state.isLoading}
          >
            <div className="form__row">
              <Form.Input type="text" name="code" placeholder="" required />
            </div>

            <div className="registration-modal__request">
              No Invite Code?&nbsp;
              <span className="registration-modal__request-link" onClick={this.requestCode}>
                Request
              </span>
            </div>

            <div className="form__row">
              <Form.Submit label="Submit" className="button--large" />
            </div>
          </Form>
        </div>
      </div>
    );
  }

  @bind
  submit(values) {
    const { onClose, resetStore, replaceUser } = this.props;
    this.setState({ isLoading: true });
    req
      .parentRegister({ code: values.code })
      .then(res => {
        replaceUser(res);
        resetStore({ exclude: ['CURRENT_USER', 'CONSTANTS'] });
        return req.constants();
      })
      .then(() => {
        Actions.showFlash('A new child is added');
        onClose(true);
      })
      .catch(err => {
        Actions.reportError('Registration code is invalid.', err);
        this.setState({ isLoading: false });
      });
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser.data
});

const mapDispatchToProps = dispatch => ({
  resetStore: payload => dispatch({ type: 'REDUX_STORE_RESET', payload }),
  replaceUser: user => dispatch({ type: 'CURRENT_USER_SET', payload: user })
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
