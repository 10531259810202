import React, { useState } from 'react';
import { ButtonV2 } from 'components';

function DisableKinderSystems({ onClose, data }) {
  const [loading, setLoading] = useState(false);

  const disableIntegration = () => {
    setLoading(true);
    req
      .deleteSyncSettings({
        syncSettingId: data.syncSettingId
      })
      .then(() => {
        Actions.showFlash('The Kindersystems integration has been successfully disabled.');
        onClose({ updated: true });
      })
      .catch(error => {
        console.error('Error updating settings', error);
        Actions.showFlash('Error disabling Kindersystems.', 'alert');
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="modal__wrapper connect-intellikids">
      <div className="modal__header">
        <span className="modal__header-title">Disable Integration</span>
      </div>
      <div className="modal__container">
        <p className="instructions">{data.instruction}</p>
        <div className="form__row form__row--actions">
          <ButtonV2 data-cy="no" secondary label="No" onClick={() => onClose(false)} />
          <ButtonV2 data-cy="yes" label="Yes, Disable" onClick={disableIntegration} disabled={loading} />
        </div>
      </div>
    </div>
  );
}

export default DisableKinderSystems;
