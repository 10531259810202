import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import map from 'lodash/map';
import { withRouter } from 'react-router-dom';
import CircularProgressbar from 'react-circular-progressbar';
import { ButtonV2, Icon, ActionButton } from 'components';
import S3Uploader from 'lib/S3Uploader';
import './style.scss';

class UploadFile extends Component {
  static propTypes = {
    title: PropTypes.string,
    progress: PropTypes.number,
    inProgress: PropTypes.bool,
    parent: PropTypes.bool,
    multiple: PropTypes.bool,
    strategy: PropTypes.oneOf(['s3', 'local']).isRequired,
    s3Key: PropTypes.string,
    // TODO: Re-enable later, disabled due to empty MIME values on Windows for .csv/.xlsx files
    // acceptedMIME:   PropTypes.arrayOf(PropTypes.string),
    acceptedExts: PropTypes.arrayOf(PropTypes.string),
    maxFileSize: PropTypes.number,
    skipSuccess: PropTypes.bool,
    successTitle: PropTypes.string,
    successDesc: PropTypes.string,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func
  };

  static defaultProps = {
    title: 'Upload File',
    progress: 0,
    inProgress: false,
    multiple: false,
    acceptedMIME: [],
    acceptedExts: [],
    maxFileSize: 10,
    skipSuccess: false,
    successTitle: 'Done! The file is uploaded'
  };

  _fileInput = null;

  constructor(props) {
    super(props);

    this.state = {
      files: [],
      progress: props.progress,
      inProgress: props.inProgress,
      failed: false,
      dragOver: false
    };
  }

  componentDidMount() {
    this.preventCloseOnDrop();
  }

  preventCloseOnDrop() {
    const modal = document.querySelector('.modal');

    if (modal) {
      modal.addEventListener('dragover', e => {
        e.preventDefault();
      });

      modal.addEventListener('drop', e => {
        e.preventDefault();
      });
    }
  }

  @bind
  updateUploadProgress(e, progress) {
    const currentFileProgress = Math.floor(e.loaded / e.total) * 100;
    const nextFailed = isNaN(currentFileProgress);

    this.setState({ progress, failed: nextFailed });
  }

  isValidSize(file) {
    return file.size <= this.props.maxFileSize * 1024 * 1024;
  }

  isValidMIME(file) {
    return this.props.acceptedMIME.includes(file.type);
  }

  isValidExt(file) {
    const extIndex = file.name.lastIndexOf('.');
    const ext = file.name.slice(extIndex).toLowerCase();
    const acceptedExts = this.props.acceptedExts.map(ext => ext.toLowerCase());

    return acceptedExts.includes(ext);
  }

  validateFiles(files) {
    const { maxFileSize, acceptedExts } = this.props;
    const validations = {
      ext: true,
      size: true,
      MIME: true
    };

    for (const file of files) {
      const isValid = {
        ext: this.isValidExt(file),
        size: this.isValidSize(file),
        MIME: this.isValidMIME(file)
      };

      if (!isValid.ext && validations.ext) {
        validations.ext = false;
      }

      if (!isValid.size && validations.size) {
        validations.size = false;
      }

      // TODO: Re-enable later, disabled due to empty MIME values on Windows for .csv/.xlsx files
      // if (!isValid.MIME && validations.MIME) {
      //   validations.MIME = false;
      // }
    }

    if (!validations.ext) {
      Actions.showFlash(`Only ${acceptedExts.join(', ')} files are accepted`, 'alert');
    }

    if (!validations.size) {
      Actions.showFlash(`File size should be smaller than ${maxFileSize}MB`, 'alert');
    }

    return validations.ext && validations.MIME && validations.size;
  }

  initializeUpload(files) {
    const filesList = Array.from(files);
    const isValid = this.validateFiles(filesList);

    if (!isValid) {
      return;
    }

    this.setState({ files: filesList }, () => {
      const results = [];

      for (const file of filesList) {
        const reader = new FileReader();

        reader.onloadend = e => {
          results.push(e.target.result);

          if (results.length === filesList.length) {
            this.uploadFiles(filesList, results);
          }
        };

        switch (this.props.strategy) {
          case 's3':
            reader.readAsBinaryString(file);
            break;

          case 'local':
            reader.readAsDataURL(file);
        }

        this.setState({ inProgress: true });
      }
    });
  }

  uploadFiles(filesList, results) {
    switch (this.props.strategy) {
      case 's3':
        this.uploadToS3(filesList);
        break;

      case 'local':
        this.uploadLocal(filesList, results);
        break;
    }
  }

  uploadToS3(filesList) {
    const { s3Key, parent } = this.props;

    const s3Config = {
      getSignature: parent ? req.awsSignatureParent : req.awsSignature,
      key: s3Key,
      onProgress: this.updateUploadProgress
    };

    S3Uploader.upload(filesList, s3Config)
      .then(this.handleComplete)
      .catch(this.handleFailure);
  }

  uploadLocal(filesList, results) {
    // Fake percent is used to keep the UI consistent,
    // because no uploading actually happens.
    const FAKE_PERCENT_DIFF = 25;

    const timer = setInterval(() => {
      this.setState(
        ({ progress }) => ({ progress: progress + FAKE_PERCENT_DIFF }),
        () => {
          if (this.state.progress === 100) {
            clearInterval(timer);
            this.props.onSuccess(filesList, results);
          }
        }
      );
    }, 175);
  }

  @bind
  handleUploadButtonClick() {
    if (this._fileInput) {
      this._fileInput.click();
    }
  }

  @bind
  handleFailure() {
    this.setState({ failed: true });
  }

  @bind
  handleComplete(uploadedFiles) {
    // The timer is used to finish animation without a blink
    setTimeout(() => {
      const fileUploadPromises = [];

      if (!this.props.onSuccess) {
        this.setState({ inProgress: false });
        return;
      }

      this.props
        .onSuccess(this.props.multiple ? uploadedFiles : uploadedFiles[0])
        .then(() => {
          this.setState({ inProgress: false });
        })
        .catch(err => {
          Actions.reportError('Something went wrong', err);
          this.setState({ inProgress: false, failed: true });
        });

      Promise.all(fileUploadPromises)
        .then(() => {
          this.setState({ inProgress: false });
        })
        .catch(() => {
          this.setState({ inProgress: false, failed: true });
        });
    }, 380);
  }

  @bind
  handleInputUpload(e) {
    e.preventDefault();
    this.initializeUpload(e.target.files);
  }

  @bind
  handleDragOver(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ dragOver: true });
  }

  @bind
  handleDragLeave(e) {
    e.preventDefault();
    this.setState({ dragOver: false });
  }

  @bind
  handleDropUpload(e) {
    e.preventDefault();

    if (e.dataTransfer.items) {
      const files = map(e.dataTransfer.items, i => i.getAsFile());
      this.initializeUpload(files);
    }
  }
  @bind
  handleClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  @bind
  bindInput(node) {
    this._fileInput = node;
  }

  renderUploader() {
    const { acceptedExts, acceptedMIME, multiple } = this.props;
    const acceptedTypes = acceptedMIME.join(', ') + ', ' + acceptedExts.join(', ');

    const iconCN = cn('upload-file__drop-icon', {
      'upload-file__drop-icon--over': this.state.dragOver
    });

    return (
      <div
        className="upload-file__drop"
        data-cy="dropzone"
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDropUpload}
      >
        <Icon name="upload" className={iconCN} size={70} />

        <div className="upload-file__title">
          <span className="upload-file__upload-btn" onClick={this.handleUploadButtonClick}>
            <input
              data-cy="upload-file-input"
              ref={this.bindInput}
              type="file"
              className="upload-file__input"
              onChange={this.handleInputUpload}
              accept={acceptedTypes}
              multiple={multiple}
            />
            Choose a file
          </span>

          <span> or Drop a file here</span>
        </div>
      </div>
    );
  }

  renderProgress() {
    return (
      <div className="upload-file__progress" data-cy="file-upload-spinner">
        <CircularProgressbar percentage={this.state.progress.toFixed(0)} />
      </div>
    );
  }

  renderSuccessMessage() {
    const { successTitle, successDesc } = this.props;

    return (
      <div className="upload-file__success" data-cy="file-upload-success">
        <Icon className="upload-file__success-icon" name="tick" size={70} />

        <div className="upload-file__success-title">{successTitle}</div>

        {successDesc && <div className="upload-file__success-description">{successDesc}</div>}
      </div>
    );
  }

  renderFailureMessage() {
    return (
      <div className="upload-file__failure">
        <Icon className="upload-file__failure-icon" name="clear-circle" size={70} />

        <div className="upload-file__failure-title">Error</div>

        <div className="upload-file__failure-description">Unable to upload the file. Try again later.</div>
      </div>
    );
  }

  renderContent() {
    const { progress, inProgress, failed } = this.state;

    if (failed) {
      return this.renderFailureMessage();
    }

    if (inProgress) {
      return this.renderProgress();
    }

    if (!inProgress && progress === 100) {
      if (this.props.skipSuccess) {
        this.handleClose();
      }

      return this.renderSuccessMessage();
    }

    return this.renderUploader();
  }

  render() {
    const { progress } = this.state;
    const { title, onBack, className } = this.props;
    const btnDisabled = progress > 0 && progress < 100;

    const uploadFileCN = cn({
      'upload-file': true,
      [className]: Boolean(className)
    });

    return (
      <div className={uploadFileCN}>
        <div className="modal__header modal__header--bordered upload-file__header">
          {onBack && <ActionButton className="upload-file__back" onClick={onBack} iconName="back" />}

          <div className="modal__header-title">{title}</div>
          <ActionButton className="modal__close" size={12} onClick={this.handleClose} iconName="close" />
        </div>

        <div className="upload-file__container">{this.renderContent()}</div>

        <div className="modal__controls upload-file__actions">
          <ButtonV2 label="Close" disabled={btnDisabled} onClick={this.handleClose} />
        </div>
      </div>
    );
  }
}

export default withRouter(UploadFile);
