import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';

class Icon extends Component {
  static propTypes = {
    name: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    resizeable: PropTypes.bool,
    tooltip: PropTypes.string,
    'data-cy': PropTypes.string
  };

  static defaultProps = {
    name: 'add',
    size: 14,
    tooltip: ''
  };

  constructor() {
    super();

    this.state = {
      icon: null
    };
  }

  UNSAFE_componentWillMount() {
    this.setIcon();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.name !== this.props.name) {
      this.setIcon();
    }
  }

  async setIcon() {
    const { name } = this.props;

    let icon;

    try {
      icon = (await import(/* webpackMode: "eager" */ `assets/icons/${name}.svg`)).default;
    } catch (e) {
      icon = null;
    }

    this.setState({ icon });
  }

  render() {
    const { className, size, color, onClick, width, height, resizeable, tooltip, 'data-cy': dataCY } = this.props;
    const { icon } = this.state;

    const style = {};

    if (color) {
      style.fill = color;
    }

    if (!resizeable) {
      if (size) {
        style.width = size + 'px';
        style.minWidth = size + 'px'; // needed for flex parent to prevent icon is shrinking
        style.height = size + 'px';
      }

      if (width) {
        style.width = width + 'px';
        style.minWidth = width + 'px';
      }

      if (height) {
        style.height = height + 'px';
      }
    }

    const iconColor = color || '#007faa';
    let coloredIcon = icon;

    if (color && icon) {
      coloredIcon = icon.replace('<svg ', `<svg fill="${iconColor}" `);
    }

    const content = { __html: coloredIcon };

    const iconCN = classNames('icon', {
      [className]: Boolean(className)
    });

    return (
      <div
        className={iconCN}
        style={style}
        dangerouslySetInnerHTML={content}
        onClick={onClick}
        data-cy={dataCY}
        title={tooltip}
      />
    );
  }
}

export default Icon;
