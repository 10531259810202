import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'components';
import IncidentActivity from 'components/ActivityList/Activity/ActivityBody/Incident';
import Signature from 'modals/common/ActivityForms/Signature';
import './style.scss';

const IncidentSignReport = props => {
  const activity = props.data.activity;
  const [formValues, setFormValues] = useState({});

  const close = result => {
    props.onClose(result);
  };

  const onFormChange = values => {
    setFormValues(values);
  };

  const isFormValid = () => {
    return formValues.signature && formValues.signature.trim().length >= 3;
  };

  const signReport = () => {
    const { signature, signatureDataUrl } = formValues;

    if (!signature) {
      Actions.showFlash('Signature is required.', 'alert');
      return;
    }
    if (signature.trim().length < 3) {
      Actions.showFlash('Signature requires at least 3 characters.', 'alert');
      return;
    }

    req
      .updateParentSignature({
        incidentId: activity.activiable.id,
        kidId: activity.kid_ids[0],
        signature: {
          signature_file: signatureDataUrl
        }
      })
      .then(response => {
        Actions.showFlash('Incident report signed successfully');
        close({ signature: response.signature });
      })
      .catch(error => {
        if (error.response.status === 404) {
          Actions.showFlash(error.response.data.errors[0]);
          close({ signature: error.response.data.signature });
        } else {
          Actions.reportError('There was a problem signing the incident report', error);
          close();
        }
      });
  };

  return (
    <div className="incident-sign-report">
      <div className="modal__header modal__header--bordered">
        <div className="modal__header-title">Incident Report From {props.schoolName}</div>
      </div>
      <div className="incident-sign-report modal__container">
        <div className="form_row">
          <label>Staff present:</label>
          {activity.staff_present_name}
        </div>
        <div className="form_row">
          <IncidentActivity activity={activity} />
        </div>
        <div className="form__row">
          <Form className="form__body" onChange={onFormChange}>
            <Signature label="Parent Signature" />
          </Form>
        </div>
        <div className="form__row form__row--actions incident-sign-report--actions">
          <Button className="button" onClick={signReport} label="Done" disabled={!isFormValid()} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    schoolName: state.currentUser.data.current_school.name
  };
};

export default connect(mapStateToProps)(IncidentSignReport);
