import Handler from './Handler';

class Request {
  static create(config) {
    return Request.createRequestHandlers(config);
  }

  static createRequestHandlers(config) {
    const handlers = {};
    const appendHandler = (routeName, routeData) => {
      handlers[routeName] = Handler.create(routeData, {
        name: routeName,
        fallbackApiUrl: config.fallbackApiUrl,
        store: config.store,
        getHeaders: config.getHeaders,
        handleError: config.handleError
      });
    };

    const authRouteNames = Object.keys(config.authRoutes);
    authRouteNames.forEach(routeName => appendHandler(routeName, config.authRoutes[routeName]));

    const apiRouteNames = Object.keys(config.apiRoutes);
    apiRouteNames.forEach(routeName => appendHandler(routeName, config.apiRoutes[routeName]));

    return handlers;
  }
}

export default Request;
