import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InlineEditor, ButtonV2, ActionButton } from 'components';
import get from 'lodash/get';
import { IntercomAPI } from 'react-intercom';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class TuitionExpressStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schoolName: get(props, 'currentUser.current_school.name', ''),
      yourName: get(props, 'currentUser.name', ''),
      enrollment: get(props, 'data.enrollment', ''),
      facilityType: get(props, 'data.facility_type', ''),
      email: get(props, 'currentUser.email', ''),
      phone: get(props, 'currentUser.phone', ''),
      submitting: false
    };
  }

  @bind
  handleChange(field, value) {
    this.setState({ [field]: value });
  }

  @bind
  goToFirstStep() {
    this.props.context.goToStep(0);
  }

  @bind
  submit() {
    const { yourName, email, phone, enrollment, facilityType } = this.state;
    this.setState({ submitting: true });

    req
      .teRequestSignup({
        name: yourName,
        phone,
        email,
        enrollment: Number(enrollment),
        school_type: facilityType
      })
      .then(() => {
        this.setState({ submitting: false });
        IntercomAPI('trackEvent', 'te_setup');
        Actions.showFlash('Thank you and your request is submitted');
        this.props.context.goToStep('thankyou');
      })
      .catch(err => {
        this.setState({ submitting: false });
        Actions.reportError('There was problem adding bank account', err);
      });
  }

  render() {
    const { yourName, schoolName, email, phone, submitting } = this.state;

    return (
      <div className="modal__wrapper">
        <ActionButton iconName="back" className="school-activate__te-back" onClick={this.goToFirstStep} />
        <div className="modal__container school-activate__te">
          <div className="school-activate__good school-activate__good--te">
            <img src="/assets/images/clap.png" className="school-activate__good-img" />
            <div className="school-activate__good-text">
              <span className="school-activate__good-text__title">Good News!</span>
              <br />
              For your account we can provide lower rates
              <br />
              (CC:&nbsp;2.75%, ACH:&nbsp;$1) &amp; 1 business day payout timeline.
              <br />
              Fill in details below and our support will reach out to you.
            </div>
          </div>
          <div className="airy-list">
            <div className="airy-list__item">
              <div className="airy-list__item-title">Your Name</div>
              <div className="airy-list__item-description">
                <span>{yourName}</span>
                <InlineEditor.Input onChange={v => this.handleChange('yourName', v)} defaultValue={yourName} />
              </div>
            </div>
            <div className="airy-list__item">
              <div className="airy-list__item-title">School Name</div>
              <div className="airy-list__item-description">
                <span>{schoolName}</span>
                <InlineEditor.Input onChange={v => this.handleChange('schoolName', v)} defaultValue={schoolName} />
              </div>
            </div>
            <div className="airy-list__item">
              <div className="airy-list__item-title">Email</div>
              <div className="airy-list__item-description">
                <span>{email}</span>
                <InlineEditor.Input onChange={v => this.handleChange('email', v)} defaultValue={email} />
              </div>
            </div>
            <div className="airy-list__item">
              <div className="airy-list__item-title">Phone Number</div>
              <div className="airy-list__item-description">
                <span>{phone}</span>
                <InlineEditor.Input onChange={v => this.handleChange('phone', v)} defaultValue={phone} />
              </div>
            </div>
          </div>
          <ButtonV2 className="button--large" label="Confirm & Submit" onClick={this.submit} isLoading={submitting} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser.data
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(TuitionExpressStep);
