import React from 'react';
import { Form } from 'components';
import moment from 'moment';
import get from 'lodash/get';

function ReportMonth({ name = 'report_month', formOptions }) {
  return (
    <div className="form__row">
      <Form.MonthPicker
        disableFuture
        disableCurrentMonth={get(formOptions, 'disableCurrentMonth', false)}
        name={name}
        defaultValue={[
          moment()
            .subtract(1, 'month')
            .startOf('month')
            .toDate(),
          moment()
            .subtract(1, 'month')
            .endOf('month')
            .toDate()
        ]}
      />
    </div>
  );
}

export default ReportMonth;
