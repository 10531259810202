import React from 'react';
import { ContextMenuItem, Link } from 'components';
import { ContextMenu, ScheduleLarge } from 'components';
import moment from 'moment';
import { convertSessionsToSchedule } from 'lib/utils';

function ProgramListRow({ program, includeWeekend, includeSchedule, renderContextMenu }) {
  const schedule = convertSessionsToSchedule(program.schedule);

  return (
    <div className="table__row">
      <div className="table__cell">
        <Link to={`/my-school/programs/${program.id}`}>{program.name}</Link>
      </div>
      <div className="table__cell table__cell--s">
        {program.starts_at && moment(program.starts_at).format('M/D/YY')}
        {program.starts_at && ' - '}
        {program.ends_at && moment(program.ends_at).format('M/D/YY')}
      </div>

      {includeSchedule && (
        <div className="table__cell table__cell--schedule">
          <ScheduleLarge timeSchedule={schedule} includeWeekend={includeWeekend} />
        </div>
      )}

      <div className="table__cell table__cell--xxs table__cell--actions">
        {renderContextMenu ? (
          renderContextMenu(program)
        ) : (
          <ContextMenu>
            <ContextMenuItem label="View Profile" link={`/my-school/programs/${program.id}`} />
          </ContextMenu>
        )}
      </div>
    </div>
  );
}

export default ProgramListRow;
