import get from 'lodash/get';
class SchoolRegistrationSerializer {
  static deserialize(schoolRegJSON) {
    // Endpoint now conditionally returns Advanced Registration form
    if (schoolRegJSON.form) {
      return {
        redirect: true,
        form_id: schoolRegJSON.form.id
      };
    }

    const registrationSetting = schoolRegJSON.registration_setting;

    return {
      form: registrationSetting.json_form || {},

      payment: {
        enabled: registrationSetting.is_fees_enabled,
        amount: get(registrationSetting, 'fees.0.amount'),
        description: get(registrationSetting, 'fees.0.title')
      },

      share: {
        enabled: registrationSetting.enabled,
        slug: registrationSetting.slug
      },

      school: registrationSetting.school || {}
    };
  }

  static serialize(schoolRegObj) {
    const serializedObj = {};

    if (schoolRegObj.form) {
      serializedObj.json_form = schoolRegObj.form;
    }

    if (schoolRegObj.share) {
      serializedObj.enabled = schoolRegObj.share.enabled;
    }

    if (schoolRegObj.payment) {
      serializedObj.is_fees_enabled = schoolRegObj.payment.enabled;
      serializedObj.fees =
        schoolRegObj.payment.amount === undefined
          ? undefined
          : [
              {
                title: 'Standard Registration',
                amount: String(schoolRegObj.payment.amount)
              }
            ];
    }

    return { registration_setting: serializedObj };
  }
}

export default SchoolRegistrationSerializer;
