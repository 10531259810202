import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Checkbox, Icon } from 'components';
import './style.scss';

class PayerCheckbox extends Component {
  static propTypes = {
    icon: PropTypes.string,
    label: PropTypes.node,
    description: PropTypes.node
  };

  render() {
    const { className, icon, label, description, blur, ...otherProps } = this.props;

    const payerLabel = (
      <div className="payer-checkbox__label">
        <Icon name={icon} size={30} />
        <div className="payer-checkbox__label-title">{label}</div>
        <div className="payer-checkbox__label-desc">{description}</div>
      </div>
    );

    return (
      <Checkbox
        {...otherProps}
        className={cx('payer-checkbox', className, { 'payer-checkbox--blur': blur })}
        label={payerLabel}
      />
    );
  }
}

export default PayerCheckbox;
