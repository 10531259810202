import React from 'react';
import { Form } from 'components';
import moment from 'moment';

function ReportRange({ formOptions }) {
  const maxRangeMonth = formOptions?.max_range_month;

  return (
    <div className="form__row form__row--justified">
      <Form.DatePicker
        disableFuture
        name="report_from"
        defaultValue={moment()
          .subtract(1, 'month')
          .startOf('month')
          .toDate()}
      />
      <Form.DatePicker
        disableFuture
        name="report_to"
        defaultValue={moment()
          .subtract(1, 'month')
          .endOf('month')
          .toDate()}
        rangeStart="report_from"
        maxRangeMonth={maxRangeMonth}
      />
    </div>
  );
}

export default ReportRange;
