import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { DatePicker as PlainDatePicker } from 'components';
import validationText from '../validationText';
import './style.scss';
import withContext, { FormContext } from 'hocs/withContext';

class DatePicker extends Component {
  static propTypes = {
    // Required to create form data properly, should be unique
    name: PropTypes.string,

    // A default value
    defaultValue: PropTypes.any,

    // If provided, it will render a label above the field
    label: PropTypes.string,

    // Side where tooltip should be rendered, left or right
    side: PropTypes.oneOf(['left', 'right']),

    // If true, no invalid styles are applied to the field
    // Provided validations will still work, it only disables the styling
    disableInvalid: PropTypes.bool,

    // Disabled style
    disabled: PropTypes.bool,

    // Custom classname
    className: PropTypes.string,

    placeholder: PropTypes.string,

    disableType: PropTypes.bool,

    disableFuture: PropTypes.bool,

    disablePast: PropTypes.bool,

    disableDays: PropTypes.func,

    // Linked range start input name (e.g. `report_from`)
    rangeStart: PropTypes.string,

    // Linked range end
    rangeEnd: PropTypes.string,

    maxRangeMonth: PropTypes.number,

    // if true, displays on top of input field
    dropup: PropTypes.bool,

    clearable: PropTypes.bool,

    yearsToShow: PropTypes.number,

    allowUnmount: PropTypes.bool,

    tabIndex: PropTypes.number,

    asterisk: PropTypes.bool,

    fieldRef: PropTypes.func
  };

  static defaultProps = {
    allowUnmount: true
  };

  UNSAFE_componentWillMount() {
    const { name, defaultValue, type } = this.props;
    let initialValue;

    if (defaultValue) {
      switch (type) {
        case 'weekly': {
          let momentValue = moment(defaultValue);

          initialValue = [momentValue.startOf('isoWeek').toDate(), momentValue.endOf('isoWeek').toDate()];
          break;
        }

        case 'biweekly': {
          let momentValue = moment(defaultValue);

          initialValue = [
            momentValue.startOf('isoWeek').toDate(),
            momentValue
              .add(1, 'week')
              .endOf('isoWeek')
              .toDate()
          ];
          break;
        }

        case 'daily':
        default:
          initialValue = defaultValue;
          break;
      }
    }

    this.props.context.init(name, initialValue, this.initValidations());
  }

  componentWillUnmount() {
    if (this.props.allowUnmount) {
      this.props.context.unmount(this.props.name);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.required !== prevProps.required) {
      this.props.context.updateValidations(this.props.name, this.initValidations());
    }
  }

  initValidations() {
    const { name, required, rangeStart, rangeEnd, maxRangeMonth } = this.props;

    // These operations create a validation object's structure
    // and send it to Form component.
    const validations = {};

    if (required) {
      validations.isNotEmpty = null;
    }

    if (rangeStart) {
      validations.isGreaterThanStart = null;
      this.props.context.link(name, rangeStart); // save linked input name (e.g. `report_from`) to use it in validations
    }

    if (rangeEnd) {
      validations.isLesserThanEnd = null;
      this.props.context.link(name, rangeEnd);
    }

    if (maxRangeMonth) {
      validations.maxRangeMonth = maxRangeMonth;
    }

    return validations;
  }

  @bind
  handleChange(value) {
    let result = null;

    if (!value) {
      this.props.context.update(this.props.name, undefined);
      return;
    }

    if (value instanceof Array) {
      result = [moment(value[0]).toDate(), moment(value[1]).toDate()];
    } else {
      result = moment(value).toDate();
    }

    this.props.context.update(this.props.name, result);
  }

  /**
   * Get a text for an invalid field to render.
   *
   * @return {string} - a text which represents a validation error
   */
  getValidationText() {
    const { name } = this.props;
    const { errors, validations } = this.props.context;

    if (errors[name]) {
      return errors[name].join('; ');
    }

    if (!validations[name]) {
      return '';
    }

    return Object.keys(validations[name])
      .filter(type => validations[name][type] === false)
      .map(type => {
        if (typeof validationText[type] === 'function') {
          return validationText[type](this.props[type]);
        }

        return validationText[type];
      })
      .join('; ');
  }

  render() {
    const { values } = this.props.context;
    const {
      name,
      side,
      label,
      dropup,
      type,
      disableInvalid,
      disabled,
      className,
      placeholder,
      clearable,
      disableType,
      disablePast,
      disableFuture,
      disableDays,
      yearsToShow,
      tabIndex,
      'data-cy': dataCY,
      asterisk,
      fieldRef
    } = this.props;
    const invalidText = this.getValidationText();

    const formDateCN = classNames({
      'form-date': true,
      'form-date--invalid': Boolean(invalidText) && !disableInvalid,
      'form-date--disabled': disabled,
      [className]: Boolean(className)
    });

    return (
      <div className={formDateCN} ref={fieldRef}>
        {label && (
          <label className="form__label">
            {label} {asterisk && <span className="form__asterisk">*</span>}
          </label>
        )}

        <PlainDatePicker
          side={side}
          onChange={this.handleChange}
          value={values[name]}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          disableType={disableType}
          disableFuture={disableFuture}
          disablePast={disablePast}
          disableDays={disableDays}
          dropup={dropup}
          yearsToShow={yearsToShow}
          clearable={clearable}
          tabIndex={tabIndex}
          data-cy={dataCY}
        />

        {invalidText && !disableInvalid && (
          <div className="form-input__validation-text" data-cy={`${dataCY}-invalid`}>
            {invalidText}
          </div>
        )}
      </div>
    );
  }
}

export default withContext(FormContext)(DatePicker);
