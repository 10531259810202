import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectGroup } from 'components';
import { v4 } from 'uuid';
import { connect } from 'react-redux';
import FormFieldType from 'constants/FormFieldType';
import OptionForm from './OptionForm';
import descriptions from './descriptions';
import './style.scss';

const FieldTypes = [
  FormFieldType.TextInput,
  FormFieldType.Checkbox,
  FormFieldType.Select,
  FormFieldType.DatePicker,
  FormFieldType.TextBox,
  FormFieldType.Divider,
  FormFieldType.FileUpload
];

class CreateFormField extends Component {
  static propTypes = {
    data: PropTypes.shape({
      actionType: PropTypes.oneOf(['create', 'edit']).isRequired,

      totalFields: PropTypes.number.isRequired,

      // Should be passed if actionType is 'create'
      fieldType: PropTypes.oneOf(FieldTypes),

      // Must be passed if actionType is 'edit'
      fieldObj: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.oneOf(FieldTypes),
        removable: PropTypes.bool,
        customAttributeOptions: PropTypes.shape({
          assignable: PropTypes.bool,
          placeholder: PropTypes.string
        }),
        options: PropTypes.object
      }),

      // Assign to custom attribute is enabled
      customAttributesEnabled: PropTypes.bool,
      customAttributesKeeper: PropTypes.oneOf(['enrollment', 'enrollment_carer'])
    })
  };

  static defaultProps = {
    data: {
      type: FormFieldType.TextInput,
      actionType: 'create'
    }
  };

  static getInitialState(props) {
    if (props.data.actionType === 'create') {
      return {
        type: props.data.fieldType || FormFieldType.TextInput
      };
    }

    return {
      type: props.data.fieldObj.type
    };
  }

  // Defines what fields should occupy the whole row
  static getFullWidth(type, options) {
    switch (type) {
      case FormFieldType.TextInput:
        return options.multiline;

      case FormFieldType.Divider:
        return true;

      case FormFieldType.TextBox:
        return true;

      case FormFieldType.FileUpload:
        return true;

      default:
        return false;
    }
  }

  constructor(props) {
    super(props);

    this.state = CreateFormField.getInitialState(props);
  }

  UNSAFE_componentWillMount() {
    if (this.props.data.customAttributesEnabled) {
      req.customAttributes();
    }
  }

  makeFieldObject(type, options) {
    const { totalFields, actionType, fieldObj: prevFieldObj } = this.props.data;

    const fieldObj = {
      id: actionType === 'create' ? v4() : prevFieldObj.id,
      type,
      options,
      removable: prevFieldObj ? prevFieldObj.removable : undefined,
      customAttributeOptions: prevFieldObj ? prevFieldObj.customAttributeOptions : undefined
    };

    fieldObj.options.fullWidth = CreateFormField.getFullWidth(type, fieldObj.options, totalFields);
    fieldObj.options.label = type !== FormFieldType.Divider ? fieldObj.options.label || 'Unnamed field' : undefined;

    return fieldObj;
  }

  @bind
  changeType(type) {
    this.setState({ type });
  }

  @bind
  handleSubmit(values) {
    const { type } = this.state;
    const fieldObj = this.makeFieldObject(type, values);
    this.props.onClose(fieldObj);
  }

  render() {
    const { type } = this.state;
    const { actionType = 'create', fieldObj, customAttributesEnabled, customAttributesKeeper } = this.props.data;
    const titleText = `${actionType === 'create' ? 'Create New' : 'Edit Form'} Field`;
    const options = fieldObj ? fieldObj.options : {};
    const customAttributeOptions = fieldObj ? fieldObj.customAttributeOptions : true;

    return (
      <div className="create-form-field">
        <div className="modal__header modal__header--bordered create-form-field__header">
          <div className="modal__header-title">{titleText}</div>

          <div className="create-form-field__type">
            <div className="form__label create-form-field__section-label">1. Choose field type</div>

            <SelectGroup
              type="radio"
              title="Field type"
              data-cy="field-type"
              checked={type}
              disabled={actionType === 'edit'}
              className="create-form-field__type-select"
              onChange={this.changeType}
            >
              <SelectGroup.Item
                value={FormFieldType.TextInput}
                label="Short answer"
                icon="fc-text-input"
                iconType="svg"
              />
              <SelectGroup.Item value={FormFieldType.Checkbox} label="Checkbox" icon="fc-checkbox" iconType="svg" />
              <SelectGroup.Item value={FormFieldType.Select} label="Dropdown" icon="fc-select" iconType="svg" />
              <SelectGroup.Item
                value={FormFieldType.DatePicker}
                label="Date picker"
                icon="fc-date-picker"
                iconType="svg"
              />
              <SelectGroup.Item value={FormFieldType.TextBox} label="Paragraph" icon="fc-text-box" iconType="svg" />
              <SelectGroup.Item value={FormFieldType.Divider} label="Line Divider" icon="fc-divider" iconType="svg" />
              <SelectGroup.Item
                value={FormFieldType.FileUpload}
                label="File Upload"
                icon="fc-file-upload"
                iconType="svg"
              />
            </SelectGroup>
          </div>
        </div>

        <OptionForm
          key={type}
          type={type}
          actionType={actionType}
          options={options}
          onSubmit={this.handleSubmit}
          customAttributeOptions={customAttributeOptions}
          customAttributesKeeper={customAttributesKeeper}
          customAttributes={
            customAttributesEnabled
              ? this.props.customAttributes.filter(attr => attr.keeper === customAttributesKeeper)
              : undefined
          }
          description={descriptions[type]}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  customAttributes: state.customAttributes.data
});

export default connect(mapStateToProps)(CreateFormField);
