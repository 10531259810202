import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTenantApiUrlFromState } from 'lib/auth';
import { RichEditor, Form, Preloader } from 'components';

const MB = 1024 * 1024;

const FROALA_CONFIG = {
  height: 150,
  toolbarSticky: false,
  placeholderText: 'Enter content here. Use the formatting options above to stylize your message or add a link.',
  toolbarButtons: {
    moreMisc: {
      buttons: ['undo', 'redo', 'html']
    }
  },
  fileUpload: true,
  imageMaxSize: 100 * MB,
  fileMaxSize: 100 * MB,
  videoMaxSize: 100 * MB,
  linkAlwaysBlank: true
};

const TextBoxPlaceholder = () => (
  <div className="text-box-placeholder">
    <Preloader large />
  </div>
);

class TextBoxOptions extends Component {
  static propTypes = {
    additionalOptions: PropTypes.object,
    onChange: PropTypes.func
  };

  static defaultProps = {
    additionalOptions: {
      value: ''
    }
  };

  state = {
    awsLoaded: false,
    froalaConfig: {}
  };

  componentDidMount() {
    req.awsSignature({ key: 'documents' }).then(() => {
      this.setState({ awsLoaded: true, froalaConfig: this.getFroalaConfig() });
    });
  }

  getS3Config(awsSignature) {
    return {
      bucket: awsSignature.bucket,
      region: awsSignature.region,
      keyStart: awsSignature.key_start,
      params: {
        acl: awsSignature.acl,
        AWSAccessKeyId: awsSignature.access_key_id,
        policy: awsSignature.policy,
        signature: awsSignature.signature
      }
    };
  }

  getFroalaConfig() {
    const { apiUrl, authToken } = this.props;
    const s3Config = this.getS3Config(this.props.awsSignature);

    return {
      ...FROALA_CONFIG,
      imageManagerLoadURL: `${apiUrl}/school/photos`,
      imageUploadToS3: s3Config,
      fileUploadToS3: s3Config,
      videoUploadToS3: s3Config,
      imageManagerLoadParams: { token: authToken }
    };
  }

  @bind
  handleChange(value) {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ value });
    }
  }

  render() {
    const { additionalOptions, options } = this.props;
    const { froalaConfig, awsLoaded } = this.state;

    return (
      <React.Fragment>
        <div className="create-form-field__label">Text</div>

        {awsLoaded ? (
          <RichEditor value={additionalOptions.value} config={froalaConfig} onChange={this.handleChange} />
        ) : (
          <TextBoxPlaceholder />
        )}

        <Form.Checkbox
          name="hasCheckbox"
          data-cy="hasCheckbox"
          label="Add Confirmation Checkbox (optional)"
          defaultValue={!!options.hasCheckbox}
        />

        <Form.Input
          name="checkboxLabel"
          data-cy="checkboxLabel"
          defaultValue={options.checkboxLabel || ''}
          label="Checkbox Label"
          placeholder="I've read the above & agree"
          required={options.hasCheckbox}
          disabled={!options.hasCheckbox}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  awsSignature: state.awsSignature.data,
  authToken: state.currentUser.data.auth_token,
  apiUrl: getTenantApiUrlFromState(state)
});

export default connect(mapStateToProps)(TextBoxOptions);
