import React, { Component } from 'react';
import { connect } from 'react-redux';
import StudentRow from './StudentRow';
import { ButtonV2 } from 'components';
import './style.scss';

class AddStudent extends Component {
  static createNewStudent = () => ({
    first_name: '',
    last_name: '',
    room_id: '',
    parent_name: ''
  });

  constructor() {
    super();

    this.state = {
      students: [],
      newStudent: AddStudent.createNewStudent()
    };
  }

  addStudent() {
    const { students, newStudent } = this.state;

    const isValid = this.validateStudent(newStudent);

    if (!isValid) {
      return;
    }

    this.setState({
      students: [...students, newStudent],
      newStudent: AddStudent.createNewStudent()
    });
  }

  updateStudent(updatedStudent, studentIndex) {
    if (studentIndex === null) {
      this.setState({ newStudent: updatedStudent });
    } else {
      const { students } = this.state;

      const newStudents = [...students.slice(0, studentIndex), updatedStudent, ...students.slice(studentIndex + 1)];

      this.setState({ students: newStudents });
    }
  }

  removeStudent(studentIndex) {
    const { students } = this.state;

    const newStudents = [...students.slice(0, studentIndex), ...students.slice(studentIndex + 1)];

    this.setState({ students: newStudents });
  }

  validateStudent(student) {
    let isValid = true;

    if (!student.first_name || !student.last_name || !student.room_id) {
      isValid = false;
    }

    return isValid;
  }

  submit() {
    const { students } = this.state;
    const { onClose } = this.props;

    let isDataValid = true;

    let data = [];

    for (let student of students) {
      if (!this.validateStudent(student)) {
        isDataValid = false;
        break;
      }

      const entry = {
        first_name: student.first_name,
        last_name: student.last_name,
        current_section_id: student.room_id
      };

      if (student.parent_name) {
        entry.parent = {
          name: student.parent_name
        };
      }

      data.push(entry);
    }

    if (!isDataValid) {
      return;
    }

    req.addStudents({ kids: data }).then(onClose);
  }

  renderRows() {
    const { students, newStudent } = this.state;
    const { rooms } = this.props;

    const rows = students.map((student, index) => (
      <StudentRow
        key={`student-row-${index}`}
        id={index}
        student={student}
        rooms={rooms}
        onRemove={this.removeStudent}
        onUpdate={this.updateStudent}
      />
    ));

    rows.push(
      <StudentRow
        isNew
        key={`student-row-${students.length}`}
        id={students.length}
        student={newStudent}
        rooms={rooms}
        onAdd={this.addStudent}
        onUpdate={this.updateStudent}
      />
    );

    return rows;
  }

  render() {
    const { isLoading } = this.props;
    const { students } = this.state;

    let isDataValid = true;

    for (let student of students) {
      if (!this.validateStudent(student)) {
        isDataValid = false;
        break;
      }
    }

    return (
      <div className="add-student">
        <div className="modal__header">
          <span className="modal__header-title">Add Students</span>
        </div>

        <div className="modal__container">
          <div className="add-student__list">{this.renderRows()}</div>
        </div>

        <ButtonV2
          className="add-student__submit-button"
          label="Save information"
          onClick={this.submit}
          disabled={students.length === 0 || isLoading || !isDataValid}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  isLoading: state.students.loading
});

export default connect(mapStateToProps)(AddStudent);
