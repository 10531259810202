import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './DateLegendItem.scss';

function DateLegendItem({ date }) {
  const className = cx('weekly-calendar__dates-item', {
    'weekly-calendar__dates-item--today': date.isSame(new Date(), 'day')
  });

  return (
    <div className={className}>
      <div className="weekly-calendar__dates-item__inner">
        <div className="weekly-calendar__dates-item__title">{date.format('DD')}</div>
        <div className="weekly-calendar__dates-item__subtitle">{date.format('ddd')}</div>
      </div>
    </div>
  );
}

DateLegendItem.propTypes = {
  date: PropTypes.object
};

export default DateLegendItem;
