import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, ActionButton, Icon } from 'components';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { formatNumber } from 'lib/utils';
import CAPTCHA_ACTIONS from 'constants/captchaActions';
import { IntercomAPI } from 'react-intercom';
import './style.scss';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class EnrollStep3 extends Component {
  static propTypes = {
    plaidToken: PropTypes.string,
    plaidAccountId: PropTypes.string,
    plan: PropTypes.object.isRequired,
    bankName: PropTypes.string,
    paymentMethod: PropTypes.string,
    subscriptionInfo: PropTypes.object,
    coupon: PropTypes.object
  };

  constructor(...args) {
    super(...args);
    this.state = {
      isSaving: false
    };
  }

  get isRecaptchaNotLoaded() {
    return !this.props.googleReCaptchaProps.executeRecaptcha;
  }

  @bind
  async handleSubmit(data) {
    const { plaidToken, paymentMethod, plaidAccountId, plan, coupon } = this.props;
    const { executeRecaptcha } = this.props.googleReCaptchaProps;

    let requestObj = {
      subscription: {
        plan_id: plan.id,
        quantity: 1,
        coupon_id: coupon ? coupon.coupon_id : undefined
      }
    };
    let promise;

    this.setState({ isSaving: true });

    const captchaToken = await executeRecaptcha(CAPTCHA_ACTIONS.ENROLL);

    if (paymentMethod === 'plaid') {
      promise = req.newSubscription({
        ...requestObj,
        token_type: 'plaid',
        plaid_account_id: plaidAccountId,
        token: plaidToken,
        recaptcha_token: captchaToken
      });
    } else {
      promise = this.props.stripe.createToken(data).then(({ token, error }) => {
        if (error) {
          throw error;
        }

        return req.newSubscription({
          ...requestObj,
          token_type: 'card',
          token: token.id,
          recaptcha_token: captchaToken
        });
      });
    }

    try {
      await promise;
      await req.profile();
      this.setState({ isSaving: false });
      this.props.context.nextStep();
    } catch (err) {
      this.setState({ isSaving: false });

      if (err.response?.status === 403) {
        this.props.onClose();

        await Actions.showModal('Alert', {
          title: 'At this time, we are unable to proceed forward.',
          message: 'Please chat with Customer Support for further assistance with your Procare Online subscription.',
          label: 'Okay'
        });

        IntercomAPI(
          'showNewMessage',
          'I am having trouble subscribing for my Online account. Can you please assist me with this process?'
        );
      } else {
        Actions.reportError('There was problem with subscription', err);
      }
    }
  }

  renderCardForm() {
    return (
      <div className="table__row">
        <div className="enroll__payment-form">
          <div className="enroll__payment-form__title">Add the Card Details</div>

          <div className="form__row">
            <Form.Input name="name" placeholder="Enter Name" required />
          </div>

          <div className="form__row">
            <div className="form-input">
              <CardElement />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBank() {
    const { bankName } = this.props;
    return (
      <div className="enroll__payment-bank">
        <Icon className="enroll__payment-bank__icon" name="bank" size={60} />
        <div className="enroll__payment-bank__description">
          Your {bankName} account is connected with us. Click on&nbsp;
          <span className="enroll__payment-bank__description-strong">"Make Payment"</span>
          &nbsp;to finish the subscription process.
        </div>
      </div>
    );
  }

  render() {
    const { plan, paymentMethod, subscriptionInfo } = this.props;
    const { isSaving } = this.state;

    return (
      <Form className="modal__wrapper enroll enroll__payment" onSubmit={this.handleSubmit} isLoading={isSaving}>
        <div className="modal__header">
          <ActionButton iconName="back" size={20} onClick={this.props.context.prevStep} />
          <span className="modal__header-title">Enroll</span>
          <span className="modal__header-steps">
            <span>step 3</span> / 3
          </span>
        </div>
        <div className="modal__container">
          <div className="enroll__pricing">
            <div className="enroll__pricing-top">
              <span className="enroll__pricing-price">
                ${subscriptionInfo ? subscriptionInfo.net_payable : formatNumber(plan.amount / 100)}
              </span>
              <span className="enroll__pricing-desc">The total amount you need to pay</span>
            </div>
            <div className="enroll__payment-bottom">
              {paymentMethod === 'card' && this.renderCardForm()}
              {paymentMethod === 'plaid' && this.renderBank()}
            </div>
          </div>
        </div>

        <div className="modal__controls">
          <span className="enroll__term">
            By signing-up with us you are agreeing to our
            <a
              href="https://www.procaresoftware.com/terms-of-use/procare-solutions-terms-of-use/"
              target="_blank"
              className="enroll__term-link"
            >
              {' '}
              Terms & Conditions
            </a>
          </span>
          <Form.Submit label="Make Payment" disabled={this.isRecaptchaNotLoaded} />
        </div>
      </Form>
    );
  }
}

const enhance = compose(withContext(ModalControllerStepsContext), withGoogleReCaptcha, injectStripe);

export default enhance(EnrollStep3);
