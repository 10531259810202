import React, { Component } from 'react';
import { connect } from 'react-redux';
import { capitalize } from 'lib/utils';
import { Form, Checkbox, SelectGroup, Icon } from 'components';

import './style.scss';

class AddSibling extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isLoading: false,
      formType: this.props.procareSyncEnabled ? 'existing' : 'new',
      regStatus: 'active'
    };
  }

  @bind
  onFormTypeChange(formType) {
    this.setState({ formType });
  }

  @bind
  addNew(values) {
    const { onClose } = this.props;
    const { studentId } = this.props.data;

    this.setState({ isLoading: true });

    req
      .addStudent({ kid: values })
      .then(res => {
        const requestData = {
          id: studentId,
          sibling_id: res.id
        };

        return req.addStudentLink(requestData);
      })
      .then(() => req.student({ id: studentId, include_programs_with_kid_schedule: true }))
      .then(() => Actions.showFlash('Successfully linked!'))
      .then(() => {
        this.setState({ isLoading: false });
        onClose();
      })
      .catch(err => {
        Actions.reportError('There was problem adding a sibling', err);

        this.setState({ isLoading: false });
      });
  }

  @bind
  async addExisting(values) {
    const { onClose, onToggle } = this.props;
    const { studentId } = this.props.data;

    const requestData = {
      id: studentId,
      sibling_id: values.sibling.id
    };

    this.setState({ isLoading: true });
    onToggle();

    try {
      const confirmationModalResult = await Actions.showModal('Confirmation', {
        title: 'Add Sibling',
        description: (
          <div>
            Are you sure you want to link <strong>{values.sibling.label}</strong> as a sibling?
            <br />
            Linking siblings will merge billing ledgers and <strong>cannot be undone.</strong>
          </div>
        ),
        yesButton: 'Yes, Add Sibling',
        wideModal: true
      });

      if (confirmationModalResult) {
        await req.addStudentLink(requestData);
        await req.student({ id: studentId });
        await Actions.showFlash('Successfully linked!');
        this.setState({ isLoading: false });
        onClose();
      } else {
        onToggle();
        this.setState({ isLoading: false });
      }
    } catch (err) {
      Actions.reportError('There was a problem adding a sibling', err);
      this.setState({ isLoading: false });
    }
  }

  @bind
  onRegStatusChange(regStatus) {
    this.setState({ regStatus });
  }

  @bind
  loadStudents(query) {
    if (query === '') {
      return Promise.resolve([]);
    }

    const requestPayload = {
      filters: {
        kid: { name: query, registration_status: this.state.regStatus }
      }
    };
    const { data } = this.props;
    const { siblingIds } = data;

    return req.students(requestPayload).then(result => {
      return result
        .filter(kid => kid.id !== data.studentId && siblingIds.indexOf(kid.id) === -1)
        .map(kid => ({
          id: kid.id,
          label: kid.name
        }));
    });
  }

  render() {
    const { rooms, tags, data, statuses, procareSyncEnabled } = this.props;
    const { formType, isLoading } = this.state;

    return (
      <div className="add-sibling">
        <div className="modal__header">
          <span className="modal__header-title">Add Sibling</span>
          <span className="modal__header-description-non-italic">
            All parents and pickups of sibling will be added to <strong>{data.studentName}</strong>
          </span>
        </div>

        <div className="modal__container">
          {!procareSyncEnabled && (
            <div className="add-sibling__type">
              <Checkbox
                label="New Student"
                checked={formType === 'new'}
                type="circle"
                onChange={() => this.onFormTypeChange('new')}
                disabled={isLoading}
              />
              <Checkbox
                label="Choose Existing Student"
                checked={formType === 'existing'}
                type="circle"
                onChange={() => this.onFormTypeChange('existing')}
                disabled={isLoading}
              />
            </div>
          )}

          {formType === 'existing' && (
            <Form onSubmit={this.addExisting} onChange={this.handleChangeExisting} isLoading={isLoading}>
              <div className="form__row">
                <Form.Typeahead
                  key={this.state.regStatus}
                  required
                  name="sibling"
                  placeholder="Student Name (min 3 letters)"
                  minCharacters={3}
                  loadOptions={this.loadStudents}
                  passInputValue={true}
                />
              </div>
              <div className="form__row">
                <SelectGroup
                  required
                  type="radio"
                  name="registration_status"
                  title="Select Student Status"
                  checked={this.state.regStatus}
                  onChange={this.onRegStatusChange}
                  className="add-sibling__reg-status"
                >
                  {Object.keys(statuses).map(s => (
                    <SelectGroup.Item key={s} value={s} label={statuses[s]}>
                      {capitalize(s)}
                    </SelectGroup.Item>
                  ))}
                </SelectGroup>
              </div>
              <div className="add-sibling__note add-sibling__note--box">
                <Icon name="alert" color="#febe10" size={20} />
                <span className="add-sibling__note--title">
                  Linking siblings will merge billing ledgers and cannot be undone.
                </span>
              </div>
              <div className="add-sibling__footer form__row--actions">
                <Form.Submit label="Continue" />
              </div>
            </Form>
          )}

          {formType === 'new' && (
            <Form onSubmit={this.addNew} isLoading={isLoading}>
              <div className="form__row">
                <Form.Input name="first_name" tabIndex={1} placeholder="First Name" required label="First Name" />
              </div>
              <div className="form__row">
                <Form.Input name="last_name" tabIndex={2} placeholder="Last Name" label="Last Name" />
              </div>
              <div className="form__row">
                <Form.Select
                  name="current_section_id"
                  type="radio"
                  title="Select Room"
                  tabIndex={3}
                  required
                  defaultValue={rooms.length ? rooms[0].id : undefined}
                  label="Select Room"
                >
                  {rooms.map(room => (
                    <Form.Select.Item key={room.id} value={room.id} label={room.name} />
                  ))}
                </Form.Select>
              </div>
              <div className="form__row">
                <Form.Select
                  name="tag_ids"
                  type="checkbox"
                  title="Select Tags (optional)"
                  multipleSelectionText="tags applied"
                  tabIndex={4}
                  enableClear
                  label="Select Tags"
                >
                  {tags.map(tag => (
                    <Form.Select.Item key={tag.id} value={tag.id} label={tag.name} />
                  ))}
                </Form.Select>
              </div>
              <div className="add-sibling__note add-sibling__note--box">
                <Icon name="alert" color="#febe10" size={20} />
                <span className="add-sibling__note--title">
                  Linking siblings will merge billing ledgers and cannot be undone.
                </span>
              </div>
              <div className="add-sibling__footer form__row--actions">
                <Form.Submit label="Save" />
              </div>
            </Form>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  tags: state.tags.data,
  statuses: state.constants.data.kid_registration_statuses,
  procareSyncEnabled: state.currentUser.data.current_school.pe_enabled
});

export default connect(mapStateToProps)(AddSibling);
