import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import findIndex from 'lodash/findIndex';
import ItemList from '../ItemList';

class DropdownForm extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    field: PropTypes.object
  };

  static defaultProps = {
    onChange: () => undefined,
    field: null
  };

  constructor(props) {
    super(props);

    if (!props.field.items.length) {
      this.onAddItem();
    }
  }

  @bind
  createItem(value = '', title = '') {
    return {
      id: uuid(),
      value,
      title
    };
  }

  @bind
  onAddItem() {
    const { onChange } = this.props;
    const { items } = this.props.field;
    const newItem = this.createItem();
    onChange({ items: [...items, newItem] });
  }

  @bind
  onChangeItem(id, key, value) {
    const { items } = this.props.field;
    const { onChange } = this.props;
    const itemIndex = findIndex(items, { id });
    const updatedItem = {
      ...items[itemIndex],
      [key]: value
    };

    onChange({
      items: [...items.slice(0, itemIndex), updatedItem, ...items.slice(itemIndex + 1)]
    });
  }

  @bind
  onDeleteItem(id) {
    const { items } = this.props.field;
    const { onChange } = this.props;
    if (items.length <= 1) {
      return;
    }

    const itemIndex = findIndex(items, { id });
    onChange({
      items: [...items.slice(0, itemIndex), ...items.slice(itemIndex + 1)]
    });
  }

  render() {
    const { items } = this.props.field;

    return <ItemList items={items} onAdd={this.onAddItem} onRemove={this.onDeleteItem} onChange={this.onChangeItem} />;
  }
}

export default DropdownForm;
