import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonV2, TooltipPortalTrigger } from 'components';
import './style.scss';

class ContextMenu extends Component {
  static propTypes = {
    label: PropTypes.string,
    trigger: PropTypes.any,
    iconName: PropTypes.string,
    iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hidden: PropTypes.bool,
    raised: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    secondary: PropTypes.bool
  };

  static defaultProps = {
    iconName: 'ellipsis',
    iconSize: 32,
    hidden: false,
    raised: false
  };

  @bind
  renderMenu(closeTooltip) {
    const items = React.Children.map(this.props.children, child => {
      if (!child) {
        return;
      }

      const childOnClick = value => {
        if (child.props.onClick) {
          child.props.onClick(value);
        }

        if (!child.props.disableCloseOnClick) closeTooltip();
      };

      return (
        <child.type
          {...child.props}
          onClick={childOnClick}
          closetooltip={child.props.disableCloseOnClick ? closeTooltip : undefined}
        />
      );
    });

    return <ul className="tooltip-content__inner">{items}</ul>;
  }

  render() {
    const {
      iconName,
      iconSize,
      label,
      hidden,
      raised,
      className,
      disabled,
      secondary,
      trigger,
      ...otherProps
    } = this.props;

    if (hidden) {
      return null;
    }

    const cn = classNames('context-menu', {
      'context-menu--raised': raised === true,
      'context-menu--disabled': disabled,
      [className]: Boolean(className)
    });

    const tooltipCN = classNames('context-menu-tooltip', {
      'context-menu-tooltip--raised': raised === true,
      'context-menu-tooltip--disabled': disabled
    });

    return (
      <TooltipPortalTrigger
        disabled={disabled}
        side="right"
        white
        triggerOn="click"
        {...otherProps}
        className={cn}
        portalClassName={tooltipCN}
        renderTooltip={this.renderMenu}
      >
        {trigger || (
          <ButtonV2
            disabled={disabled}
            primary={raised && !secondary}
            secondary={raised && secondary}
            icon={!raised && !!iconName}
            iconName={!raised ? iconName : undefined}
            iconSize={iconSize}
            label={label}
            toggle="by-tooltip"
          />
        )}
      </TooltipPortalTrigger>
    );
  }
}

export default ContextMenu;
