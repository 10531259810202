import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'components';

const HorizontalRule = ({ layout }) => {
  return (
    <Form.Row layout={layout}>
      <hr style={{ width: '100%' }} />
    </Form.Row>
  );
};

HorizontalRule.propTypes = {
  layout: PropTypes.object
};

export default HorizontalRule;
