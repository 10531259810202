import React, { Component } from 'react';
import { Icon, ActionButton } from 'components';
import Notification from 'lib/Notification';
import './style.scss';

class CalendarBar extends Component {
  constructor() {
    super();
    this.state = {
      show: false
    };
  }

  async componentDidMount() {
    const isEnabled = await Notification.checkPermission();
    this.setState({ show: !isEnabled });
  }

  @bind
  closeBar() {
    window.localStorage.setItem('calendarBarClosed', true);
    this.setState({ show: false });
  }

  render() {
    if (!this.state.show || window.localStorage.getItem('calendarBarClosed')) {
      return null;
    }

    return (
      <div className="calendar-bar">
        <div className="calendar-bar__text">
          <Icon size={12} name="bell" />
          Enable browser notifications for alerts 15 minutes before an event starts
          <ActionButton className="calendar-bar__close" iconName="close" size={12} onClick={this.closeBar} />
        </div>
      </div>
    );
  }
}

export default CalendarBar;
