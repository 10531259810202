import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StudentCard } from 'components';
import withContext, { BulkSendParentInviteContext } from 'hocs/withContext';

class Item extends Component {
  static propTypes = {
    kid: PropTypes.object,
    checked: PropTypes.bool,
    onCheck: PropTypes.func
  };

  static defaultProps = {
    kid: {},
    checked: false
  };

  @bind
  toggle() {
    this.props.onCheck(this.props.kid.id);
  }

  render() {
    const { kid, checked } = this.props;
    const { customItemOptions } = this.props.context || {};

    return (
      <StudentCard
        className="select-kids__item"
        student={kid}
        checked={checked}
        onClick={this.toggle}
        disableCheck={customItemOptions && !customItemOptions.method(kid)}
        roomName={customItemOptions && customItemOptions.label(kid)}
      />
    );
  }
}

export default withContext(BulkSendParentInviteContext)(Item);
