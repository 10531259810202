import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import './style.scss';
import { withRouter } from 'react-router-dom';

class ContextMenuItem extends Component {
  static propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    link: PropTypes.string,
    'data-cy': PropTypes.string
  };

  static defaultProps = {};

  @bind
  handleClick() {
    const { onClick, link, value, history } = this.props;

    if (onClick) {
      onClick(value);
    }
    if (link) {
      history.push(link);
    }
  }

  render() {
    const { icon, label, className, disabled, 'data-cy': dataCY } = this.props;

    const itemClassName = cx('context-menu-item', {
      'context-menu-item--disabled': disabled,
      [className]: Boolean(className)
    });

    return (
      <li className={itemClassName} onClick={this.handleClick} data-cy={dataCY}>
        {icon && <Icon name={icon} className="context-menu-item__icon" />}

        <span className="context-menu-item__label">{label}</span>
      </li>
    );
  }
}

export default withRouter(ContextMenuItem);
