const createBatchDeleteReducer = (reducerName, initialState) => (state = { ...initialState }, action) => {
  switch (action.type) {
    case `${reducerName}_BATCH_DELETE`:
      return { ...state, loading: true, removeItemIds: action.payload.ids };

    case `${reducerName}_BATCH_DELETE_SUCCESS`: {
      const collectionName = action.options?.collectionName;
      const data = collectionName
        ? {
            ...state.data,
            total: state.data.total - 1,
            [collectionName]: state.data[collectionName].filter(entry => {
              return state.removeItemIds.indexOf(entry.id) === -1;
            })
          }
        : state.data.filter(entry => {
            return state.removeItemIds.indexOf(entry.id) === -1;
          });

      return {
        ...state,
        loading: false,
        data
      };
    }

    case `${reducerName}_BATCH_DELETE_FAILURE`:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default createBatchDeleteReducer;
