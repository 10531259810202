import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TextInput, Icon, ActionButton, ButtonV2, Preloader, TagItem, SelectList } from 'components';
import { getErrorText } from 'lib/utils';
import './style.scss';

class SelectMealItemsList extends Component {
  static propTypes = {
    meals: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ),

    selectedMealIds: PropTypes.arrayOf(PropTypes.string),

    onChange: PropTypes.func,

    onCreate: PropTypes.func, // Must return a promise

    onRemove: PropTypes.func
  };

  static defaultProps = {
    meals: [],
    selectedMealIds: []
  };

  static filterByTerm(items = [], propName, term) {
    if (!term) {
      return items;
    }

    term = term.toLowerCase().trim();

    return items.filter(
      i =>
        i[propName]
          .toLowerCase()
          .trim()
          .indexOf(term) >= 0
    );
  }

  static buildMealsMap(meals) {
    const map = {};

    meals.forEach(t => {
      map[t.id] = t;
    });

    return map;
  }

  constructor(props) {
    super(props);

    this._mealsMap = SelectMealItemsList.buildMealsMap(props.meals);
    this._filteredMeals = [];

    this.state = {
      search: '',
      selectedItemIndex: 0,
      loading: false,
      deleteId: undefined // used to disable deleteing item
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.meals !== this.props.meals) {
      this._mealsMap = SelectMealItemsList.buildMealsMap(this.props.meals);
    }

    if (this._filteredMeals.length === 0 && this.state.selectedItemIndex !== -1) {
      this.setState({ selectedItemIndex: -1 });
    }
  }

  @bind
  changeSearch(text) {
    this.setState({ search: text });
  }

  @bind
  selectMeal(meal) {
    if (this.props.selectedMealIds.indexOf(meal.id) !== -1 || meal.id === this.state.deleteId) {
      return;
    }

    this.setState({ loading: true }, () => {
      this.props.onChange(this.props.selectedMealIds.concat([meal.id]));
      this.setState({ loading: false });
    });
  }

  @bind
  deselectMeal(mealId) {
    this.props.onChange(this.props.selectedMealIds.filter(id => id !== mealId));

    if (this.state.selectedItemIndex === -1) {
      this.state.selectedItemIndex = 0;
    }
  }

  @bind
  createMeal() {
    this.setState({ loading: true }, async () => {
      try {
        await this.props.onCreate(this.state.search);
        this.setState({ loading: false, search: '' });
      } catch (e) {
        Actions.reportError('Unable to create a meal item:\n\n' + getErrorText(e.response.data), e);
        this.setState({ loading: false });
      }
    });
  }

  @bind
  removeMeal(id) {
    this.setState({ deleteId: id }, async () => {
      try {
        await this.props.onRemove(id, this._mealsMap[id].name);
        this.setState({ deleteId: undefined });
      } catch (e) {
        Actions.reportError('Unable to remove a meal item:\n\n' + getErrorText(e.response.data), e);
        this.setState({ deleteId: undefined });
      }
    });
  }

  @bind
  renderListItem(t) {
    const deleting = this.state.deleteId === t.id;
    return (
      <div
        className={cx('select-meals-list__list-item', { 'select-meals-list__list-item--deleting': deleting })}
        key={t.id}
      >
        {t.name}
        {deleting ? (
          <Preloader small className="select-meals-list__list-item__loading" />
        ) : (
          <ActionButton
            size={14}
            iconName="delete"
            onClick={event => {
              event.stopPropagation();
              this.removeMeal(t.id);
            }}
            disabled={deleting}
            className="select-meals-list__list-item__delete-button"
          />
        )}
      </div>
    );
  }

  renderMeals() {
    const selectedMealItemsMap = {};
    this.props.selectedMealIds.forEach(id => {
      selectedMealItemsMap[id] = true;
    });

    const mealItems = (this._filteredMeals = SelectMealItemsList.filterByTerm(
      this.props.meals,
      'name',
      this.state.search
    ).filter(t => !selectedMealItemsMap[t.id]));

    if (mealItems.length === 0) {
      return <div className="select-meals-list__no-meals-label">No meal items found</div>;
    }

    return <SelectList items={mealItems} renderItem={this.renderListItem} onSelect={this.selectMeal} />;
  }

  renderSelectedMeals() {
    return this.props.selectedMealIds.map(id => (
      <TagItem key={id} tag={this._mealsMap[id]} onDelete={this.deselectMeal} />
    ));
  }

  render() {
    const { search, loading } = this.state;
    const { meals, selectedMealIds } = this.props;
    const isSelected = selectedMealIds.length !== 0;
    const isNewMeal = meals.findIndex(m => m.name.toLowerCase().trim() === search.toLowerCase().trim()) === -1;

    return (
      <div className="select-meals-list">
        <div className="select-meals-list__header">
          <div className="select-meals-list__search">
            <Icon size={18} name="search" className="select-meals-list__search-icon" />

            <TextInput
              type="text"
              value={search}
              className="select-meals-list__search-input"
              placeholder="Select / Add Meal Items"
              onChange={this.changeSearch}
            />
          </div>
        </div>

        <div className="select-meals-list__inner">
          {isSelected ? (
            <div className="select-meals-list__selected-meals">{this.renderSelectedMeals()}</div>
          ) : (
            <div className="select-meals-list__no-meals-label">No meals selected</div>
          )}

          <div className="select-meals-list__list">{this.renderMeals()}</div>

          {search.length > 0 && isNewMeal && (
            <div className="select-meals-list__new-meal">
              <ButtonV2 label={`Add meal "${search}"`} onClick={this.createMeal} disabled={loading} />

              {loading && <Preloader small className="select-meals-list__loading" />}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SelectMealItemsList;
