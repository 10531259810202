import React, { Component } from 'react';
import moment from 'moment';
import { isDiscount } from 'lib/utils';
import omit from 'lodash/omit';
import InvoiceDetails from '../CreateInvoice/InvoiceDetails';
import ManagePresets from '../ManagePresets';
import Steps from '../ModalController/Steps';
import '../CreateInvoice/style.scss';

class EditInvoice extends Component {
  _steps = null;

  constructor(props) {
    super(props);

    this.state = {
      presetKind: 'preset',
      isSaving: false,
      isAutopostSaving: false,
      data: undefined
    };
  }

  @bind
  bindSteps(node) {
    this._steps = node;
  }

  @bind
  updateData(data) {
    this.setState({ data });
  }

  @bind
  submit(data) {
    const saveField = data.auto_post ? 'isAutopostSaving' : 'isSaving';

    this.setState({ [saveField]: true });
    req
      .editInvoice({
        id: this.props.data.transactable.id,
        parent_invoice: {
          ...data,
          parent_invoice_items_attributes: data.parent_invoice_items_attributes.map(p =>
            omit(p, ['_discount', '_discountType', '_base'])
          )
        },
        kid_id: this.props.data.transactable.kid_id
      })
      .then(() => {
        this.setState({ [saveField]: false });
        this.props.onClose(true);
        if (data.auto_post) {
          Actions.showFlash('Invoice is updated and sent');
        } else {
          Actions.showFlash('Invoice is updated');
        }
      })
      .catch(err => {
        this.setState({ [saveField]: false });
        Actions.reportError('Something went wrong', err);
      });
  }

  @bind
  openInvoiceDetails() {
    if (this._steps) {
      this._steps.goToStep(0);
    }
  }

  @bind
  openManagePresets(presetKind) {
    if (this._steps) {
      this.setState({ presetKind }, () => this._steps.goToStep(1));
    }
  }

  getData() {
    let data = null;

    if (this.props.data) {
      const {
        due_date,
        invoice_start_date,
        invoice_end_date,
        parent_invoice_items,
        mode,
        notes
      } = this.props.data.transactable;

      data = {
        mode,
        notes,
        due_date: due_date ? moment(due_date).toDate() : undefined,
        invoice_start_date: invoice_start_date ? moment(invoice_start_date).toDate() : undefined,
        invoice_end_date: invoice_end_date ? moment(invoice_end_date).toDate() : undefined,
        parent_invoice_items_attributes: parent_invoice_items.map(item => ({
          id: item.id,
          kind: item.kind,
          _discount: isDiscount(item.kind) ? -item.price : '',
          _discountType: '$',
          description: item.description,
          price: item.price,
          quantity: item.quantity,
          kid_id: item.kid_id
        }))
      };
    }

    return data;
  }

  render() {
    const { isSaving, isAutopostSaving, presetKind } = this.state;
    const { students } = this.props.data;
    const data = this.getData();

    return (
      <Steps ref={this.bindSteps}>
        <Steps.Item>
          <InvoiceDetails
            data={this.state.data || data}
            students={students}
            actionType="edit"
            onUpdate={this.updateData}
            onSubmit={this.submit}
            onClose={this.props.onClose}
            isLoading={isSaving}
            isAutopostSaving={isAutopostSaving}
            onManagePresets={this.openManagePresets}
          />
        </Steps.Item>

        <Steps.Item hidden>
          <ManagePresets data={{ kind: presetKind }} onBack={this.openInvoiceDetails} />
        </Steps.Item>
      </Steps>
    );
  }
}

export default EditInvoice;
