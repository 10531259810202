export function calculateFeeAndTotal(amount, feeBreakdown) {
  if (!feeBreakdown) {
    return { fee: 0.0, total: amount };
  }

  const fees = feeBreakdown.split(' + ');
  let fee = 0;

  if (fees[0][0] !== '$') {
    // Percentage fee
    fee = (amount * parseFloat(fees[0])) / 100;

    if (fees.length > 1) {
      // Fixed fee
      fee += parseFloat(fees[1].slice(1));
    }
  } else {
    // Fixed fee
    fee += parseFloat(fees[0].slice(1));
  }

  if (isNaN(fee)) {
    throw new Error('Unexpected fee breakdown format');
  }

  fee = +fee.toFixed(2);

  return {
    fee,
    total: +(amount + fee).toFixed(2)
  };
}
