import React, { Component } from 'react';
import { ActionButton, ButtonV2, Checkbox, Avatar, Form } from 'components';
import { connect } from 'react-redux';
import { applyFilters, isSubfamilyAllowed } from 'lib/utils';
import get from 'lodash/get';
import SelectPersonList from '../../common/SelectPersonList';
import FilterBar from '../../common/SelectKids/FilterBar';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class SelectKids extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        tagIds: [],
        search: '',
        roomId: '',
        with_subfamilies: false
      },

      selectedStudents: []
    };

    const { defaultSelected } = props;
    if (defaultSelected && defaultSelected.length) {
      this.state.selectedStudents = [...defaultSelected];
      this.state.filters.with_subfamilies = !!props.students.find(s => s.id === defaultSelected[0]).subFamily;
    }
  }

  static renderPersonCard({ person, selected, onSelect }) {
    if (!person) return null;

    const { name, id, profile_pic_url, color, subFamily } = person;
    const handleSelect = () => onSelect(id);

    return (
      <div className="person-card" key={id}>
        <Checkbox className="person-card__checkbox" checked={selected} onChange={handleSelect} />

        <div className="person-card__info" onClick={handleSelect}>
          <Avatar className="person-card__avatar" url={profile_pic_url} color={color} size="small" name={name} />

          <div className="person-card__text">
            <div className="person-card__name">{name}</div>
            {subFamily && <div className="create-invoice__person-card__subfamily-name">{subFamily.name}</div>}
          </div>
        </div>
      </div>
    );
  }

  static customConditions(student, filters) {
    const matchWithSubFamilies = filters.with_subfamilies ? !!student.subFamily : !student.subFamily;
    return matchWithSubFamilies;
  }

  @bind
  updateFilters(nextFilters) {
    const nextState = {
      filters: nextFilters
    };

    if (nextFilters.with_subfamilies !== this.state.filters.with_subfamilies) {
      nextState.selectedStudents = [];
    }

    this.setState(nextState);
  }

  @bind
  updateSelectedStudents(selectedStudents) {
    this.setState({
      selectedStudents
    });
  }

  @bind
  onUpdate() {
    const { selectedStudents } = this.state;
    this.props.onUpdate(selectedStudents);
    this.props.context.nextStep();
  }

  render() {
    const { students, step, totalSteps, rooms, tags, subFamily, fromFamily, subFamiliesEnabled } = this.props;
    const { selectedStudents, filters } = this.state;

    const filteredStudents = applyFilters(students, filters, SelectKids.customConditions);

    return (
      <div className="modal__wrapper">
        <div className="modal__header modal__header--bordered">
          <ActionButton iconName="back" size={20} onClick={this.props.context.prevStep} />
          <div className="modal__header-title">Select Students {subFamily && `- ${subFamily.name}`}</div>
          <div className="modal__header-steps">
            <span>Step {step}</span> / {totalSteps}
          </div>

          <div className="modal__filter-bar">
            <FilterBar
              rooms={rooms}
              tags={tags}
              onUpdate={this.updateFilters}
              customControl={
                !fromFamily && subFamiliesEnabled ? (
                  <div className="create-invoice__custom-filters">
                    <Form.Checkbox
                      className="create-invoice__with-subfamilies"
                      type="slider"
                      name="with_subfamilies"
                      defaultValue={filters.with_subfamilies}
                      label="Show Multifamily"
                    />
                  </div>
                ) : (
                  undefined
                )
              }
            />
          </div>
        </div>

        <div className="modal__container create-invoice__select-kids">
          <SelectPersonList
            key={this.state.filters.with_subfamilies}
            selectedIds={selectedStudents}
            persons={filteredStudents}
            allPersons={students}
            onSelect={this.updateSelectedStudents}
            renderPersonCard={SelectKids.renderPersonCard}
          />
        </div>

        <div className="modal__controls">
          <ButtonV2 label="Continue" onClick={this.onUpdate} disabled={selectedStudents.length === 0} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  tags: state.tags.data,
  subFamiliesEnabled: isSubfamilyAllowed(get(state, 'currentUser.data.current_school', {}))
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(SelectKids);
