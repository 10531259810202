export default {
  nap_activity: 'nap',
  meal_activity: 'meal',
  note_activity: 'notes',
  incident_activity: 'incident',
  photo_activity: 'photo',
  video_activity: 'video',
  bottle_activity: 'bottle',
  mood_activity: 'mood',
  medication_activity: 'medication',
  bathroom_activity: 'bathroom',
  sign_in_activity: 'sign-in',
  sign_out_activity: 'sign-out',
  transfer_activity: 'transfer',
  absent_activity: 'absent',
  learning_activity: 'learning',
  name_to_face_activity: 'name to face',
  observation_activity: 'observation'
};
