import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import validationText from '../validationText';
import { ScheduleSelect as ScheduleSelectComponent } from 'components';
import './style.scss';
import withContext, { FormContext } from 'hocs/withContext';

class ScheduleSelect extends Component {
  static propTypes = {
    layout: PropTypes.string,
    asterisk: PropTypes.bool
  };

  UNSAFE_componentWillMount() {
    const initialValue = this.props.defaultValue || [];
    const validations = {};

    if (this.props.required) {
      validations.isNotEmpty = null;
    }

    this.props.context.init(this.props.name, initialValue, validations);
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  getValidationText() {
    const { name } = this.props;
    const { values, validations, errors } = this.props.context;

    if (errors[name]) {
      return errors[name].join('; ');
    }

    let invalidText = '';

    if (validations[name] && validations[name].isNotEmpty === false && values[name].length === 0) {
      invalidText = validationText.isNotEmpty;
    }

    return invalidText;
  }

  @bind
  handleChange(val) {
    this.props.context.update(this.props.name, val);
  }

  render() {
    const { name, label, className, layout, asterisk } = this.props;
    const invalidText = this.getValidationText();
    const inputCN = cx(
      'form-schedule-select',
      {
        'form-schedule-select--invalid': Boolean(invalidText)
      },
      className
    );

    return (
      <div className={inputCN}>
        {label && (
          <label className="form__label" htmlFor={name}>
            {label} {asterisk && <span className="form__asterisk">*</span>}
          </label>
        )}

        <div>
          <ScheduleSelectComponent
            layout={layout}
            values={this.props.context.values[name]}
            onChange={this.handleChange}
          />
        </div>

        {invalidText && <div className="form-input__validation-text">{invalidText}</div>}
      </div>
    );
  }
}

export default withContext(FormContext)(ScheduleSelect);
