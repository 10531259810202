import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ActionButton, PersonInfoCard, SignedUpLabel, CustomAttribute } from 'components';
import isNil from 'lodash/isNil';
import { formatPhoneNumber } from 'lib/utils';
import useLaunchDarkly from 'hooks/useLaunchDarkly';
import './style.scss';

function ParentInfoCard({
  parent,
  studentId,
  onChange,
  onDelete,
  onInvite,
  hideAction,
  allowInvite,
  customAttributes = [],
  hasContactPermission = true,
  relationTypes = [],
  allowManageRelationTypes = true,
  hasViewRelationPermission = true
}) {
  const [deleting, setDeleting] = useState(false);
  const flags = useLaunchDarkly();

  const deleteParent = async () => {
    const shouldBeDeleted = await Actions.showModal('Confirmation', {
      title: 'Delete Parent',
      description: 'Are you sure you want to delete this parent?',
      yesButton: 'Yes, Delete',
      yesButtonProps: {
        danger: true,
        secondary: false
      },
      noButtonProps: {
        secondary: true
      }
    });

    if (!shouldBeDeleted) {
      return;
    }

    setDeleting(true);

    try {
      await onDelete(parent.id);
      Actions.showFlash('Parent was deleted successfully');
    } catch (e) {
      Actions.reportError('Unable to delete parent', e);
      setDeleting(false);
      return;
    }
  };

  const menuItems = [];
  if (onDelete) {
    menuItems.push({
      onClick: deleteParent,
      label: 'Delete Parent'
    });
  }

  const handleUpdate = async data => {
    try {
      await onChange(parent.id, data);
    } catch (e) {
      Actions.reportError('There was problem editing parent', e);
    }
  };

  const handleUpdateField = field => data => {
    return handleUpdate({
      [field]: data
    });
  };

  const handleUpdateCustomField = attrId => data => {
    return handleUpdate({
      custom_attributes: {
        ...parent.custom_attributes,
        [attrId]: isNil(data) ? null : data
      }
    });
  };

  const handleUpdateRelationField = parent => relationTypeId => {
    if (!relationTypeId) {
      return;
    }
    const studentRelation = getStudentRelation(parent);
    return handleUpdate({
      carer_kid_relations_attributes: [
        {
          id: studentRelation?.id,
          kid_id: studentId,
          relation_type_id: relationTypeId
        }
      ]
    });
  };

  const noAction = deleting || hideAction;

  const getRelationInputProps = () => {
    const inputProps = {
      options: relationTypes.map(relation => ({
        id: relation.id,
        label: relation.name
      })),
      disableFilter: true
    };

    if (allowManageRelationTypes) {
      inputProps.action = (
        <ActionButton
          iconName="gear"
          className="parent-info-card__relation-manage-btn"
          title="Manage Relation"
          size={12}
          color={'#31bdef'}
          onClick={() => Actions.showModal('ManageRelationTypes', { studentId })}
        />
      );
    }

    return inputProps;
  };

  const getPinInputProps = () => {
    return {
      name: 'Confirmation',
      data: {
        title: 'Generate New PIN',
        description:
          'Generating a new PIN will deactivate the current PIN. Are you sure you want to create a new PIN for this Parent/Guardian?',
        noButton: 'Cancel',
        yesButton: 'Generate',
        yesButtonProps: {
          secondary: false
        },
        noButtonProps: {
          secondary: true
        }
      }
    };
  };

  const handleUpdatePin = async confirmed => {
    if (confirmed) {
      try {
        await req.regenerateCarerPin({ id: parent.id });
        await req.student({ id: studentId });
        Actions.showFlash('Sign-In PIN updated successfully');
      } catch (err) {
        Actions.reportError('Unable to update Sign-In PIN', err);
      }
    }
  };

  const getStudentRelation = parent => {
    return parent.carer_kid_relations.find(relation => relation.kid_id === studentId);
  };

  const getStudentRelationType = parent => {
    const studentRelation = getStudentRelation(parent);
    if (!studentRelation) {
      return null;
    }

    return relationTypes.find(relationType => relationType.id === studentRelation.relation_type_id);
  };

  return (
    <PersonInfoCard className="parent-info-card">
      <PersonInfoCard.Head
        name={parent.name}
        photo={parent.profile_pic_url}
        person={parent}
        onChange={handleUpdate}
        menuProps={{ hidden: noAction }}
        menuItems={menuItems}
        hideAction={hideAction}
      >
        <SignedUpLabel
          email={parent.email}
          isSignedUp={parent.is_signed_up}
          isInvited={parent.is_invited}
          onSend={noAction && !allowInvite ? undefined : onInvite}
        />
      </PersonInfoCard.Head>
      <PersonInfoCard.Row
        label="Email"
        type="Input"
        hideAction={!hasContactPermission || hideAction}
        value={parent.email}
        onChange={handleUpdateField('email')}
        placeholder="-"
      />
      <PersonInfoCard.Row
        label="Phone"
        type="Input"
        hideAction={!hasContactPermission || hideAction}
        value={parent.mobile_phone ? formatPhoneNumber(parent.mobile_phone) : undefined}
        onChange={handleUpdateField('mobile_phone')}
        placeholder="-"
      />
      {hasViewRelationPermission && (
        <PersonInfoCard.Row
          label="Relation"
          type="Typeahead"
          hideAction={hideAction}
          inputProps={getRelationInputProps()}
          value={getStudentRelationType(parent)?.name}
          onChange={handleUpdateRelationField(parent)}
          placeholder="-"
        />
      )}
      <PersonInfoCard.Row
        label="Sign In Pin"
        type="Modal"
        inputProps={getPinInputProps()}
        onChange={handleUpdatePin}
        value={parent.pin}
      />
      <PersonInfoCard.Row
        label="Emergency"
        type="Boolean"
        inputProps={{
          disabled: !hasContactPermission || hideAction
        }}
        value={parent.emergency_contact}
        onChange={handleUpdateField('emergency_contact')}
      />
      {customAttributes.map(attr => (
        <CustomAttribute
          key={attr.id}
          type={attr.field_type}
          className="person-info-card__row"
          name={attr.name}
          extra={attr.data}
          description={attr.description}
          value={parent.custom_attributes[attr.id]}
          onChange={handleUpdateCustomField(attr.id)}
        />
      ))}
    </PersonInfoCard>
  );
}

ParentInfoCard.propTypes = {
  hideAction: PropTypes.bool,
  customAttributes: PropTypes.array,
  hasContactPermission: PropTypes.bool,
  parent: PropTypes.object,
  onChange: PropTypes.func,
  onInvite: PropTypes.func,
  relationTypes: PropTypes.array,
  allowManageRelationTypes: PropTypes.bool,
  hasViewRelationPermission: PropTypes.bool
};

export default ParentInfoCard;
