import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, ButtonV2, ActionButton } from 'components';
import get from 'lodash/get';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class TransactionFeeStep extends Component {
  @bind
  onContinue() {
    if (this.props.data.payment_gateway === 'stripe') {
      this.props.context.nextStep();
    } else {
      this.props.context.goToStep('te');
    }
  }

  @bind
  prevStep() {
    if (this.props.data.payment_gateway === 'stripe') {
      this.props.context.goToStep(0);
    } else {
      this.props.context.prevStep();
    }
  }

  getFee() {
    const {
      data: { payment_gateway: gateway, parent_payment_fee_paid_by: paidBy },
      feeBreakdown
    } = this.props;

    if (gateway === 'stripe') {
      if (paidBy === 'parent_payment_fee_paid_by_parent') {
        return get(feeBreakdown, 'stripe.paid_by_parent', {});
      } else {
        return get(feeBreakdown, 'stripe.paid_by_school', {});
      }
    }

    return get(feeBreakdown, 'te.paid_by_school', {});
  }

  render() {
    const {
      data: { payment_gateway: gateway, parent_payment_fee_paid_by: paidBy },
      title
    } = this.props;
    const fee = this.getFee();

    return (
      <div className="modal__wrapper">
        <div className="modal__header modal__header--bordered">
          <ActionButton iconName="back" className="school-activate__back" onClick={this.prevStep} />
          <span className="modal__header-title">{title}</span>
        </div>
        <div className="modal__container school-activate__form">
          {gateway === 'te' && (
            <div className="school-activate__good">
              <img src="/assets/images/clap.png" className="school-activate__good-img" />
              <div className="school-activate__good-text">
                <span className="school-activate__good-text__title">Good News!</span>
                &nbsp;For your account we can provide the best transaction fee &amp; payout timeline in the childcare
                market.
              </div>
            </div>
          )}

          {gateway === 'stripe' && (
            <div className="school-activate__good">
              <img src="/assets/images/clap.png" className="school-activate__good-img" />
              <div className="school-activate__good-text">
                Below are transaction fees depending on the payment source.
              </div>
            </div>
          )}

          {fee.cc && (
            <div className="school-activate__transaction-fee">
              <Icon name="credit-card" size={36} color="#CCCCCC" />
              <div className="school-activate__transaction-fee__content school-activate__transaction-fee__content--bordered">
                <div className="school-activate__transaction-fee__content-title">CREDIT CARD</div>
                <div className="school-activate__transaction-fee__content-desc">Transaction fees is {fee.cc}</div>
                {paidBy === 'parent_payment_fee_paid_by_school' && (
                  <div className="school-activate__transaction-fee__content-helper">
                    NOTE: You can qualify for even lower rates based on your enrollment count.
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="school-activate__transaction-fee">
            <Icon name="ach-plain" size={36} color="#CCCCCC" />
            <div className="school-activate__transaction-fee__content">
              <div className="school-activate__transaction-fee__content-title">ACH / BANK</div>
              <div className="school-activate__transaction-fee__content-desc">Transaction fees is {fee.ach}</div>
              {paidBy === 'parent_payment_fee_paid_by_school' && (
                <div className="school-activate__transaction-fee__content-helper">
                  NOTE: You can qualify for even lower rates based on your enrollment count.
                </div>
              )}
            </div>
          </div>

          <div className="school-activate__warning">
            <div className="school-activate__warning-text">
              <span className="school-activate__warning-small">
                Payout timeline for ACH/Bank: 2 business days, Credit Card: 1 business day.
                <br />
                If you have any questions, please call&nbsp;
                <span className="school-activate__warning-strong">(877) 679-0950.</span>
              </span>
            </div>
          </div>
          <div className="form__row form__row--actions">
            <ButtonV2 label="Continue" onClick={this.onContinue} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  feeBreakdown: state.feeBreakdown.data
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(TransactionFeeStep);
