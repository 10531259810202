import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Tab extends Component {
  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    completed: PropTypes.bool,
    passId: PropTypes.bool,
    passOnChange: PropTypes.bool,
    onTabChange: PropTypes.func,
    _ref: PropTypes.any
  };

  render() {
    const { children: c, id, passId, passOnChange, onTabChange, _ref } = this.props;

    if (Array.isArray(c)) {
      return <React.Fragment>{c}</React.Fragment>;
    }

    const props = { ...c.props };
    if (passOnChange) {
      props.onTabChange = onTabChange;
    }

    if (passId) {
      props.id = id;
    }

    return <c.type ref={_ref} {...props} />;
  }
}

export default Tab;
