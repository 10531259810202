import React, { Component } from 'react';
import { Form } from 'components';

class Dropdown extends Component {
  render() {
    const { id, label, type, className, items } = this.props;

    return (
      <Form.Select name={id} type={type} label={label} className={className} title={label}>
        {items.map((item, index) => (
          <Form.Select.Item label={item.title} value={item.value} key={`item_${index}`} />
        ))}
      </Form.Select>
    );
  }
}

export default Dropdown;
