import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextInput, SelectGroup } from 'components';

class Filter extends PureComponent {
  static propTypes = {
    filters: PropTypes.shape({
      search: PropTypes.string,
      roomId: PropTypes.string,
      state: PropTypes.string
    }),

    rooms: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func.isRequired
  };

  @bind
  updateSearch(search) {
    this.props.onChange({ search });
  }

  @bind
  updateRoomId(roomId) {
    this.props.onChange({ roomId });
  }

  @bind
  updateStateId(stateId) {
    this.props.onChange({ stateId });
  }

  render() {
    const { rooms, filters } = this.props;

    return (
      <div className="form--inline">
        <div className="students__filter-bar-search">
          <TextInput placeholder="Search Student Name" value={filters.search} onChange={this.updateSearch} />
        </div>

        {rooms && (
          <SelectGroup
            title="Select Room"
            type="radio"
            className="students__filter-bar-select"
            checked={filters.roomId}
            onChange={this.updateRoomId}
          >
            <SelectGroup.Item value="" label="All Rooms" isDefault />

            {rooms.map(r => (
              <SelectGroup.Item key={r.id} value={r.id} label={r.name} />
            ))}
          </SelectGroup>
        )}
      </div>
    );
  }
}

export default Filter;
