import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Item extends PureComponent {
  static propTypes = {
    // An item key name
    name: PropTypes.string,

    // A display label
    label: PropTypes.string,

    // An amount
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    // A progress bar value (0..100). If omitted, progress bar is not rendered
    progress: PropTypes.number,

    // Is item active
    active: PropTypes.bool,

    // Custom class name
    className: PropTypes.string,

    // On click handler
    onClick: PropTypes.func
  };

  @bind
  handleClick() {
    if (!this.props.onClick || !this.props.name) {
      return;
    }

    this.props.onClick(this.props.name);
  }

  render() {
    const { amount, label, progress, active, style } = this.props;

    const className = classNames('stats-bar__item', this.props.className, {
      'stats-bar__item--active': active,
      [`stats-bar__item--${style}`]: style !== undefined
    });

    return (
      <div className={className} onClick={this.handleClick}>
        <div className="stats-bar__item-amount">{amount}</div>

        <div className="stats-bar__item-label">{label}</div>

        {typeof progress === 'number' && (
          <div className="stats-bar__progress">
            <div className="stats-bar__progress-inner" style={{ width: `${progress}%` }} />
          </div>
        )}
      </div>
    );
  }
}

export default Item;
