import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { formatNumber } from 'lib/utils';
import { Form, Preloader, ActionButton, ButtonV2, TooltipTrigger } from 'components';
import './style.scss';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

const TEXTS = {
  home: {
    title: 'Home',
    desc: 'The simpleset offering for home childcare providers'
  },
  center: {
    title: 'Essentials',
    desc: 'Enabling centers big and small to manage day to day operations and engage with families'
  },
  pro: {
    title: 'Enterprise',
    desc: 'Enabling centers big and small to manage day to day operations and engage with families'
  }
};

class EnrollStep1 extends Component {
  static propTypes = {
    subscriptionPlans: PropTypes.array,
    setPlan: PropTypes.func,
    setCoupon: PropTypes.func,
    category: PropTypes.string.isRequired,
    plan: PropTypes.object,
    coupon: PropTypes.object
  };

  constructor() {
    super();
    this.state = {
      isVerifying: false
    };
  }

  @bind
  verifyCoupon(values) {
    const { coupon } = values;
    this.setState({ isVerifying: true });
    req
      .verifyCoupon({ subscription: { coupon_id: coupon } })
      .then(resp => {
        this.props.setCoupon(resp.coupon);
        this.setState({ isVerifying: false });
      })
      .catch(err => {
        this.setState({ isVerifying: false });
        Actions.reportError('Something went wrong', err);
      });
  }

  getBillingCycle(coupon) {
    if (coupon.desc) return coupon.desc;

    if (coupon.repeating === 'forever' || !coupon.number_of_months) {
      return 'each billing cycle';
    } else if (coupon.repeating === 'once' || coupon.number_of_months === 1) {
      return 'first billing cycle';
    } else {
      return `${coupon.number_of_months} billing cycles`;
    }
  }

  render() {
    const { coupon, category, subscriptionPlans, setPlan } = this.props;
    const { isVerifying } = this.state;

    return (
      <div className="modal__wrapper enroll">
        <div className="modal__header">
          <span className="modal__header-title">Enroll</span>
          <span className="modal__header-steps">
            <span>Step 1</span> / 3
          </span>
        </div>
        <div className="modal__container">
          <div className={cx('enroll__newpricing', `enroll__newpricing--${category}`)}>
            <div className="enroll__newpricing-header">
              <div className="enroll__newpricing-header__title">{TEXTS[category].title}</div>
              <div className="enroll__newpricing-header__desc">{TEXTS[category].desc}</div>
            </div>
            <div className="enroll__newpricing-content">
              {subscriptionPlans
                .sort((p1, p2) => p1.amount - p2.amount)
                .map((p, index, plans) => {
                  const isLast = index + 1 === plans.length;
                  const prevKids = index !== 0 ? parseInt(plans[index - 1].metadata.kids) + 1 : 0;

                  let kidsRange;
                  if (isLast && plans.length > 1) {
                    kidsRange = p.metadata.kids;
                  } else {
                    kidsRange = p.metadata.kids === 'unlimited' ? 'UNLIMITED' : `${prevKids}-${p.metadata.kids}`;
                  }

                  return (
                    <div key={p.id} className="enroll__newpricing__item" onClick={() => setPlan(p)}>
                      <div className="enroll__newpricing__item-count">{kidsRange}</div>
                      <div className="enroll__newpricing__item-children">CHILDREN</div>
                      <div className="enroll__newpricing__item-price">${formatNumber(p.amount / 100)}/month</div>
                      {/* {NORMAL_PRICES[category] && (
                        <div className="enroll__newpricing__item-normal-price">{`$${NORMAL_PRICES[category][index]}/month`}</div>
                      )} */}

                      {/* <Checkbox type="circle" checked={p.id === plan.id} /> */}
                    </div>
                  );
                })}
            </div>
          </div>

          {coupon ? (
            <div className="enroll__coupon">
              <div className="enroll__coupon-applied">
                {coupon.percent_off ? `${coupon.percent_off}% ` : `$${coupon.amount_off} `}
                discount applied on {this.getBillingCycle(coupon)}. Discounted price is shown at checkout.
              </div>
              <TooltipTrigger side="center" tooltip="Remove Coupon" className="enroll__coupon-clear">
                <ActionButton iconName="close-circle" size={14} onClick={() => this.props.setCoupon(null)} />
              </TooltipTrigger>
            </div>
          ) : (
            <div className="enroll__coupon">
              <div className="enroll__coupon-label">Apply promo code</div>
              <Form className="enroll__coupon-form" onSubmit={this.verifyCoupon}>
                <Form.Input
                  name="coupon"
                  placeholder="Type your code here"
                  actionName={isVerifying ? <Preloader small /> : 'APPLY'}
                  actionType="submit"
                  actionDisabled={isVerifying}
                  required
                  disableInvalid
                />
              </Form>
            </div>
          )}

          {/* <div className="enroll__warning">
            <div className="enroll__warning-text">
              <span className="enroll__warning-small">
                Do you want to subscribe to our
                <span className="enroll__strong"> YEARLY PLAN </span>
                and
                <span className="enroll__strong"> SAVE 20% </span>
                on your billing? Do you have more than 100 children?
              </span>
            </div>

            <div className="enroll__warning-call">
              <Icon name="call" size={19} />
              <div className="enroll__warning-call__text">
                <div className="enroll__warning-small">Call us on</div>
                <div className="enroll__warning-strong">(877) 679-0950.</div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="modal__controls">
          <ButtonV2 label="Continue" onClick={this.props.context.nextStep} />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(EnrollStep1);
