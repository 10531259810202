import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'components';
import './style.scss';

const Status = ({ iconName, iconSize, color, textColor, label, className }) => {
  return (
    <div className={classNames('status', className)}>
      {iconName ? (
        <Icon name={iconName} color={color} size={iconSize} className="status__icon" />
      ) : (
        <div className="status__indicator" style={{ backgroundColor: color }} />
      )}

      <div className="status__label" style={{ color: textColor }}>
        {label}
      </div>
    </div>
  );
};

Status.propTypes = {
  color: PropTypes.string,
  textColor: PropTypes.string,
  label: PropTypes.string,
  iconName: PropTypes.string,
  iconSize: PropTypes.number,
  amount: PropTypes.number,
  className: PropTypes.string
};

export default Status;
