import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonV2, Checkbox, Typeahead } from 'components';

import './style.scss';

const SIBLING_TYPE_NEW = 'new';
const SIBLING_TYPE_EXISTING = 'existing';

function AddEnrollmentSibling({ data, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [siblingType, setSiblingType] = useState(SIBLING_TYPE_EXISTING);
  const [existingLead, setExistingLead] = useState(undefined);
  const kid = data.kid;
  const id = kid.id;
  const siblingIds = kid.siblings.map(s => s.id);

  const loadLeads = query => {
    if (query === '') {
      return Promise.resolve([]);
    }

    const requestPayload = {
      filters: {
        enrollment: { name: query }
      }
    };

    return req.enrollmentsTypeahead(requestPayload).then(result => {
      return result.enrollments
        .filter(e => e.id !== kid.id && siblingIds.indexOf(e.id) === -1)
        .map(e => ({
          id: e.id,
          label: `${e.first_name} ${e.last_name}`
        }));
    });
  };

  const handleAddExisting = async () => {
    const requestData = {
      id,
      sibling_id: existingLead
    };

    setIsLoading(true);

    try {
      await req.linkSiblingEnrollment(requestData);
      Actions.showFlash('Successfully linked!');
      onClose(true);
    } catch (err) {
      Actions.reportError('There was problem adding a sibling', err);
    }
    setIsLoading(false);
  };

  const handleCreateNew = async () => {
    try {
      const createdEnrollment = await Actions.showModal(
        'NewEnroll',
        {
          kid: {
            carers: [...kid.carers]
          },
          fromLinkSibling: true
        },
        { type: 'slide' }
      );
      if (createdEnrollment) {
        await req.linkSiblingEnrollment({
          id: kid.id,
          sibling_id: createdEnrollment.id
        });
        onClose(true);
      }
    } catch (err) {
      // do nothing
    }
  };

  return (
    <div className="add-enrollment-sibling">
      <div className="modal__header">
        <span className="modal__header-title">Add Lead Sibling</span>
      </div>

      <div className="modal__container">
        <div className="add-enrollment-sibling__select-type">
          <Checkbox
            label="Choose Existing Lead"
            checked={siblingType === SIBLING_TYPE_EXISTING}
            type="circle"
            onChange={() => setSiblingType(SIBLING_TYPE_EXISTING)}
            disabled={isLoading}
            data-cy="choose"
          />
          <Checkbox
            label="Create New Lead"
            checked={siblingType === SIBLING_TYPE_NEW}
            type="circle"
            onChange={() => setSiblingType(SIBLING_TYPE_NEW)}
            disabled={isLoading}
            data-cy="create"
          />
        </div>

        <div className="add-enrollment-sibling__choose-existing">
          <Typeahead
            value={existingLead}
            disabled={siblingType === SIBLING_TYPE_NEW}
            label="Choose Lead"
            placeholder="Lead Name (min 3 letters)"
            minCharacters={3}
            loadOptions={loadLeads}
            onChange={setExistingLead}
          />
        </div>

        <div className="add-enrollment-sibling__footer form__row form__row--actions">
          <ButtonV2
            onClick={siblingType === SIBLING_TYPE_NEW ? handleCreateNew : handleAddExisting}
            label={siblingType === SIBLING_TYPE_NEW ? 'continue' : 'save'}
            disabled={isLoading || (!existingLead && siblingType === SIBLING_TYPE_EXISTING)}
            data-cy={siblingType === SIBLING_TYPE_NEW ? 'continue' : 'save'}
          />
        </div>
      </div>
    </div>
  );
}

AddEnrollmentSibling.propTypes = {
  data: PropTypes.shape({
    kid: PropTypes.object.isRequired
  })
};

export default AddEnrollmentSibling;
