import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { CSSTransition } from 'react-transition-group';

import './style.scss';

const ANIMATION_DURATION = 150;

function ContextSubMenu({ label, icon, children, closeTooltip, 'data-cy': dataCY }) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const renderItems = () => {
    const items = React.Children.map(children, child => {
      if (!child) {
        return;
      }

      const childOnClick = value => {
        if (child.props.onClick) {
          child.props.onClick(value);
        }

        if (!child.props.disableCloseOnClick && closeTooltip) closeTooltip();
      };

      return (
        <child.type
          {...child.props}
          className={cx(child.props.className, 'context-submenu__list-item')}
          onClick={childOnClick}
        />
      );
    });

    return (
      <CSSTransition timeout={ANIMATION_DURATION} in={!isCollapsed} unmountOnExit classNames="context-submenu__list">
        <ul className="context-submenu__list">{items}</ul>
      </CSSTransition>
    );
  };

  const onClick = () => setIsCollapsed(!isCollapsed);

  const cn = cx('context-submenu', `context-submenu--items-${React.Children.count(children)}`, {
    'context-submenu--collapsed': isCollapsed
  });

  return (
    <div data-cy={dataCY} className={cn}>
      <div className="context-submenu__label" onClick={onClick}>
        {icon && <Icon name={icon} className="context-submenu__label-icon" />}
        {label}
        <Icon className={'context-submenu__label-arrow'} name={isCollapsed ? 'chevron-down' : 'chevron-up'} />
      </div>
      {renderItems()}
    </div>
  );
}

ContextSubMenu.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string
};

export default ContextSubMenu;
