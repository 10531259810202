import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonV2 } from 'components';
import SelectTagsList from './SelectTagsList';
import './style.scss';

class SelectTags extends Component {
  static propTypes = {
    kid: PropTypes.object,
    tags: PropTypes.array,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onOpenManage: PropTypes.func
  };

  static defaultProps = {
    kid: {},
    tags: []
  };

  render() {
    const { onOpenManage } = this.props;

    return (
      <div className="select-tags">
        <div className="modal__header">
          <div className="modal__header-title select-tags__header">
            <span className="select-tags__title">{this.props.kid.tag_ids.length === 0 ? 'Add Tags' : 'Edit Tags'}</span>

            <ButtonV2 tertiary className="select-tags__manage-btn" label="Manage Tags" onClick={onOpenManage} />
          </div>
        </div>

        <div className="modal__container select-tags__container">
          <SelectTagsList
            tags={this.props.tags}
            selectedTagIds={this.props.selectedTagIds}
            onChange={this.props.onChange}
            onCreate={this.props.onCreate}
          />
        </div>

        <div className="modal__controls select-tags__controls">
          <ButtonV2 label="Submit" onClick={this.props.onSubmit} isLoading={this.props.loading} data-cy="submit" />
        </div>
      </div>
    );
  }
}

export default SelectTags;
