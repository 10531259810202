import React, { Fragment, useRef, useState } from 'react';
import { Button, Form, Preloader } from 'components';
import Steps from 'modals/ModalController/Steps';
import { syncTypes } from 'lib/utils';
import './style.scss';

function ConnectKinderSystems(props) {
  const [loading, setLoading] = useState(false);
  const [groupCode, setGroupCode] = useState('');
  const [providerIds, setProviderIds] = useState([]);
  const [providerIdToNameMap, setProviderIdToNameMap] = useState({});
  const [providers, setProviders] = useState([]);
  const [agencyId, setAgencyId] = useState([]);
  const [agencyIds, setAgencyIds] = useState({});
  const [agencies, setAgencies] = useState([]);
  const [ksState, setKsState] = useState('');
  const [configs, setConfigs] = useState([]);

  const stepsRef = useRef(null);

  const showLoading = () => {
    return (
      loading && (
        <div className="info-list__item kindersystems__loader">
          <Preloader />
        </div>
      )
    );
  };

  const gotoGroupCode = () => {
    stepsRef.current.goToStep('groupCode');
  };

  const gotoKsStatePicker = () => {
    stepsRef.current.goToStep('ksState');
  };

  const gotoProvider = () => {
    setLoading(true);
    req
      .kinderSystemsProviders({ password: groupCode, state: ksState })
      .then(result => {
        if (result.providers.length > 0) {
          const providerMap = result.providers.reduce((map, provider) => {
            map[provider.provider_id] = provider.provider_name;
            return map;
          }, {});
          setProviderIdToNameMap(providerMap); // Store the provider ID to name mapping
          setProviders(result.providers);
          stepsRef.current.goToStep('provider');
        } else {
          Actions.showFlash(
            'GroupCode is invalid. Please try again. If the problem persists contact KinderSystems.',
            'alert'
          );
          stepsRef.current.goToStep('groupCode');
        }
        setLoading(false);
      })
      .catch(error => {
        Actions.reportError('There was an error loading providers.', error);
        setLoading(false);
      });
  };

  const fetchAgencyDataSequentially = async () => {
    setLoading(true);

    try {
      const providerIdsStrings = providerIds.map(id => String(id));

      const result = await req.kinderSystemsAgenciesAll({
        password: groupCode,
        state: ksState,
        provider_ids: providerIdsStrings
      });

      if (result.agencies && result.agencies.length > 0) {
        const newAgencyIds = {};
        let combinedAgencies = [];

        combinedAgencies = result.agencies;

        result.agencies.forEach(agency => {
          if (!newAgencyIds[agency.provider_id]) {
            newAgencyIds[agency.provider_id] = [];
          }

          newAgencyIds[agency.provider_id].push(agency.agency_id);
        });

        setAgencyIds(newAgencyIds);
        setAgencies(combinedAgencies);
      }
    } catch (error) {
      console.error('Error fetching agencies', error);
    }

    setLoading(false);
    stepsRef.current.goToStep('agency');
  };

  const gotoAgency = () => {
    fetchAgencyDataSequentially();
  };

  const connect = () => {
    setLoading(true);

    const parsedAgencyIds = {};
    for (const providerId in agencyIds) {
      parsedAgencyIds[providerId] = agencyIds[providerId].map(agencyIdWithIndex => {
        const idString = String(agencyIdWithIndex);
        const [actualAgencyId] = idString.split('-');
        return actualAgencyId;
      });
    }

    const providersObject = providerIds.reduce((acc, providerId) => {
      acc[providerId] = parsedAgencyIds[providerId] || [];
      return acc;
    }, {});

    req
      .createAllSyncSettings({
        syncType: syncTypes.kinderSystems,
        password: groupCode,
        providers: providersObject,
        state: ksState
      })
      .then(() => {
        Actions.showFlash('Authentication is successful');
        setLoading(false);
        props.onClose({ connected: true });
      })
      .catch(e => {
        Actions.reportError(
          'GroupCode is invalid. Please try again. If the problem persists contact KinderSystems.',
          e
        );
        setLoading(false);
      });
  };

  const handleForm = value => {
    if (value.groupCode) setGroupCode(value.groupCode);
    if (value.providerId) setProviderIds(value.providerId);
    if (value.agencyId) {
      setAgencyId(value.agencyId);
    }
    if (value.ksState) setKsState(value.ksState);
  };

  const introStep = () => (
    <Fragment>
      <div className="form__row kindersystems__connect">
        <img className="" src="/assets/images/marketplace/KinderSystems-Connect.png" />
      </div>
      <div className="form__row form__row--actions kindersystems--instruction">
        <Button className="button" onClick={goToPullActiveConfigs} label="Continue" />
        <div>Please contact KinderSystems to request Group Code.</div>
        <div>
          <a href="mailto:info@kindersystems.com">Info@KinderSystems.com</a>
        </div>
      </div>
    </Fragment>
  );

  const goToPullActiveConfigs = () => {
    fetchActiveKinderSystemsConfigs();
  };

  const fetchActiveKinderSystemsConfigs = async () => {
    setLoading(true);

    try {
      const result = await req.kinderSystemsConfigurations();
      if (result.configurations && result.configurations.length > 0) {
        setConfigs(result.configurations);
      } else {
        // If no active KS Configs, show a warning toast
        Actions.showFlash('There are no KinderSystems connection options currently.', 'warning');
      }
    } catch (error) {
      console.error('Error fetching configurations', error);
      Actions.showFlash(
        'There was an error retrieving the active KinderSystems states. Please try again or contact support if it persists.',
        'warning'
      );
    } finally {
      setLoading(false);
      gotoKsStatePicker();
    }
  };

  const ksStatePickerStep = () => (
    <Form onChange={handleForm} validateOn="change">
      <div className="form__row">
        <Form.Select
          name="ksState"
          title="Select State"
          type="radio"
          label="State"
          enableClear={false}
          defaultValue={ksState}
          required
        >
          {configs.map(state => (
            <Form.Select.Item key={state.name} value={state.name} label={state.name} />
          ))}
        </Form.Select>
      </div>
      <div className="form__row form__row--actions kindersystems--actions">
        <Button className="button" onClick={gotoGroupCode} label="Continue" disabled={!ksState} />
      </div>
    </Form>
  );

  const groupCodeStep = () => (
    <Form onChange={handleForm} validateOn="change">
      <div className="form__row">
        <Form.Input
          name="groupCode"
          label="Input Group Code"
          placeholder="GROUP CODE"
          defaultValue={groupCode}
          required
          autoFocus
        />
      </div>
      <div className="form__row form__row--actions kindersystems--actions">
        {!loading && (
          <Fragment>
            <Button className="button--secondary" onClick={gotoKsStatePicker} label="Previous" />
            <Button className="button" onClick={gotoProvider} label="Continue" disabled={!groupCode} />
          </Fragment>
        )}
        {showLoading()}
      </div>
    </Form>
  );

  const providerStep = () => {
    return (
      <Form key="providerForm" onChange={handleForm} validateOn="change">
        <div className="form__row">
          <Form.Select
            name="providerId"
            title="Select Provider"
            type="checkbox"
            label="Provider"
            key="providerId"
            multipleSelectionText="Selected providers"
            enableClear={true}
            defaultValue={providerIds}
            required
          >
            {providers.map(provider => (
              <Form.Select.Item
                key={provider.provider_id}
                value={provider.provider_id}
                label={provider.provider_name}
              />
            ))}
          </Form.Select>
        </div>
        <div className="form__row form__row--actions kindersystems--actions">
          {!loading && (
            <Fragment>
              <Button className="button--secondary" onClick={gotoGroupCode} label="Previous" />
              <Button className="button" onClick={gotoAgency} label="Continue" disabled={!providerIds.length} />
            </Fragment>
          )}
          {showLoading()}
        </div>
      </Form>
    );
  };

  const agencyStep = () => {
    return (
      <Form id="agencyForm" key="agencyForm" onChange={handleForm} validateOn="change">
        <div className="form__row">
          <Form.Select
            name="agencyId"
            title="Select Agencies"
            type="checkbox"
            label="Agency"
            key="agencyId"
            multipleSelectionText="Selected agencies"
            enableClear={true}
            data-cy="agencyId"
            required
          >
            {agencies.map((agency, index) => {
              const providerName = providerIdToNameMap[agency.provider_id] || 'Unknown Provider';
              return (
                <Form.Select.Item
                  key={`${agency.agency_id}-${index}`}
                  value={`${agency.agency_id}-${index}`}
                  label={`${agency.agency_name} (Provider - ${providerName})`}
                />
              );
            })}
          </Form.Select>
        </div>
        <div className="form__row form__row--actions kindersystems--actions">
          {!loading && (
            <Fragment>
              <Button className="button--secondary" onClick={gotoProvider} label="Previous" />
              <Button className="button" onClick={connect} label="Connect" disabled={!agencyId.length} />
            </Fragment>
          )}
          {showLoading()}
        </div>
      </Form>
    );
  };

  return (
    <div className="modal__wrapper kindersystems">
      <div className="modal__header">
        <div className="modal__header-title kindersystems__title--justified">Connect to KinderSystems</div>
      </div>
      <div className="modal__container">
        <Steps ref={stepsRef}>
          <Steps.Item name="intro">{introStep()}</Steps.Item>
          <Steps.Item name="ksState">{ksStatePickerStep()}</Steps.Item>
          <Steps.Item name="groupCode">{groupCodeStep()}</Steps.Item>
          <Steps.Item name="provider">{providerStep()}</Steps.Item>
          <Steps.Item name="agency">{agencyStep()}</Steps.Item>
        </Steps>
      </div>
    </div>
  );
}

export default ConnectKinderSystems;
