export const PERMISSION_MAP = {
  analytics: {
    label: 'Analytics',
    isCorpOnly: true,
    disabledCorp: {
      admin: ['read'],
      nonAdmin: ['read']
    }
  },
  kids: {
    label: 'Students',
    disabled: {
      admin: ['none'],
      nonAdmin: ['none']
    }
  },
  kids_addresses: {
    label: 'Student Address',
    disabled: {
      admin: [],
      nonAdmin: []
    }
  },
  sections: {
    label: 'Rooms',
    disabled: {
      admin: ['none'],
      nonAdmin: ['none']
    },
    disabledCorp: {
      admin: ['none'],
      nonAdmin: ['none']
    }
  },
  programs: {
    label: 'Programs',
    featureFlag: 'enable_programs',
    enabledBySchoolSetting: school => school.enable_programs
  },
  carers: {
    label: 'Parents/Pickup',
    disabled: {
      admin: ['none'],
      nonAdmin: ['none']
    }
  },
  carers_info: {
    label: 'Parent Contact',
    disabled: {
      admin: [],
      nonAdmin: []
    },
    tooltip: 'Email & Phone contact of parents'
  },
  teachers: {
    label: 'Staff',
    disabled: {
      nonAdmin: ['none', 'read', 'write']
    }
  },
  teachers_scheduling: {
    label: 'Staff Scheduling',
    disabled: {
      admin: [],
      nonAdmin: []
    },
    featureFlag: 'staff_schedule_enabled',
    enabledBySchoolSetting: school => school.staff_schedule_enabled
  },
  billing: { label: 'Billing' },
  accounting_total: {
    label: 'Accounting Total',
    disabled: {
      admin: ['read'],
      nonAdmin: ['read']
    }
  },
  teachers_siso: {
    label: 'Staff Timecard'
  },
  teachers_messaging: {
    label: 'Staff Messaging',
    disabled: {
      admin: [],
      nonAdmin: []
    }
  },
  newsletters: {
    label: 'Newsletters'
  },
  meals: {
    label: 'Meals',
    disabled: {
      admin: ['none'],
      nonAdmin: ['none']
    }
  },
  assessments: {
    label: 'Assessments'
  },
  // parent_relation: {
  //   label: 'Parent Relation',
  //   disabled: {
  //     admin: [],
  //     nonAdmin: []
  //   }
  // },
  parents_messaging: {
    label: 'Parent/Staff Messaging',
    disabled: {
      admin: ['read'],
      nonAdmin: ['read']
    },
    tooltip:
      'Staff with Full Access to Staff/Parent Messaging will be limited to see messages in their Primary and Additional rooms'
  },
  lead_crm: { label: 'Leads' },
  documents: {
    label: 'Documents',
    disabledCorp: {
      admin: ['read'],
      nonAdmin: ['read']
    }
  },
  school_calendars: {
    label: 'Calendar',
    disabled: {
      nonAdmin: []
    }
  },
  reports: {
    label: 'Reports',
    disabled: {
      nonAdmin: ['read'],
      admin: ['read']
    },
    disabledCorp: {
      admin: ['read'],
      nonAdmin: ['read']
    }
  },
  school_settings: {
    label: 'School Settings',
    disabled: {
      nonAdmin: ['none', 'read', 'write']
    }
  },
  school_access: {
    label: 'School Access',
    disabledCorp: {
      admin: ['none'],
      nonAdmin: ['none']
    }
  }
  // subscription: {
  //   label: 'Subscription',
  //   disabled: {
  //     nonAdmin: ['none', 'read', 'write']
  //   }
  // }
};

export const PERMISSION_VALUES = {
  none: 'No Access',
  read: 'Read',
  write: 'Full Access'
};

export const PERMISSIONS = Object.keys(PERMISSION_MAP).filter(p => !PERMISSION_MAP[p].isCorpOnly);

export const CORP_PERMISSIONS = [
  'analytics',
  'sections',
  'teachers',
  'billing',
  'lead_crm',
  'reports',
  'documents',
  'school_access'
];

export const SCHOOL_PERMISSIONS = ['school_access'];
