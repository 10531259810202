import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonV2 } from 'components';
import moment from 'moment';
import './style.scss';

class CopyWeekSchedule extends Component {
  static propTypes = {
    data: PropTypes.shape({
      week: PropTypes.array
    })
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  @bind
  async submit(values) {
    const { fromWeek, toWeek } = values;
    const payload = {
      from_week: {
        date_from: moment(fromWeek[0])
          .toLocaleString(),
        date_to: moment(fromWeek[1])
          .toLocaleString()
      },
      to_week: {
        date_from: moment(toWeek[0])
          .toLocaleString(),
        date_to: moment(toWeek[1])
          .toLocaleString()
      }
    };

    this.setState({ loading: true });

    try {
      await req.copyShifts(payload);
      Actions.showFlash('Weekly Schedule Copied');
      this.props.onClose({ week: values.toWeek });
    } catch (e) {
      Actions.reportError('Unable to copy weekly schedule', e);
      this.setState({ loading: false });
    }
  }

  render() {
    const { onClose } = this.props;
    const { week } = this.props.data;
    const { loading } = this.state;

    return (
      <div className="copy-week-schedule">
        <div className="modal__header">
          <div className="modal__header-title">Copy Week Schedule</div>
          <div className="copy-week-schedule__description">Schedule will be copied from one week to another.</div>
        </div>

        <div className="modal__container">
          <Form onSubmit={this.submit}>
            <div className="form__row form__row--justified">
              <Form.DatePicker type="weekly" name="fromWeek" label="From" defaultValue={week[0]} required />
              <Form.DatePicker
                type="weekly"
                name="toWeek"
                label="To"
                defaultValue={moment(week[0]).add(1, 'week')}
                required
              />
            </div>

            <div className="modal__controls">
              <ButtonV2 type="button" label="Cancel" secondary onClick={onClose} />
              <Form.Submit label="Submit" isLoading={loading} />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default CopyWeekSchedule;
