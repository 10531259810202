import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActionButton, ButtonV2, ImageCropper } from 'components';
import './style.scss';

class CropPhoto extends Component {
  static propTypes = {
    data: PropTypes.shape({
      src: PropTypes.string,
      onBack: PropTypes.func,
      onSubmit: PropTypes.func
    }),
    aspect: PropTypes.number,
    minWidth: PropTypes.number
  };

  static defaultProps = {
    aspect: 1,
    minWidth: 600
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  @bind
  submit(...args) {
    this.setState({ loading: true }, () => {
      this.props.onSubmit(...args);
    });
  }

  @bind
  renderTitle() {
    const { onBack } = this.props;

    return (
      <div className="modal__header modal__header-bordered">
        {onBack && <ActionButton iconName="back" onClick={onBack} />}

        <div className="modal__header-title">Crop Photo</div>
      </div>
    );
  }

  @bind
  renderControls({ onSave }) {
    return (
      <div className="modal__controls">
        <ButtonV2 onClick={onSave} label="Submit" isLoading={this.state.loading} />
      </div>
    );
  }

  render() {
    const { src, minWidth, aspect } = this.props;

    return (
      <ImageCropper
        imgDataURL={src}
        blob
        width={minWidth}
        height={minWidth / aspect}
        className="modal__wrapper crop-photo"
        round={false}
        onSave={this.submit}
        renderTitle={this.renderTitle}
        renderControls={this.renderControls}
      />
    );
  }
}

export default CropPhoto;
