import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';

class Checkbox extends Component {
  static propTypes = {
    size: PropTypes.oneOf(['small', 'regular']),
    type: PropTypes.oneOf(['square', 'circle', 'slider']),
    checked: PropTypes.bool,
    dot: PropTypes.bool,
    reversed: PropTypes.bool,
    disabled: PropTypes.bool,
    classNames: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    'data-cy': PropTypes.string
  };

  static defaultProps = {
    size: 'regular',
    type: 'square'
  };

  getClassName() {
    const { props } = this;

    return classNames({
      checkbox: true,
      'checkbox--small': props.size === 'small',
      'checkbox--circle': props.type === 'circle',
      'checkbox--slider': props.type === 'slider',
      'checkbox--checked': props.checked,
      'checkbox--dot': props.dot,
      'checkbox--reversed': props.reversed && props.label !== undefined,
      'checkbox--disabled': props.disabled,
      [props.className]: Boolean(props.className)
    });
  }

  @bind
  handleClick() {
    const { onChange, checked, disabled } = this.props;

    if (onChange !== undefined && !disabled) {
      onChange(!checked);
    }
  }

  render() {
    const { tabIndex, label, required, 'data-cy': dataCY } = this.props;
    const checkboxCN = this.getClassName();

    return (
      <div className={checkboxCN} onClick={this.handleClick} tabIndex={tabIndex} data-cy={dataCY}>
        <div className="checkbox__input" />
        {label && (
          <div className="checkbox__label">
            {label}
            {required && <span className="checkbox__label-required">*</span>}
          </div>
        )}
      </div>
    );
  }
}

export default Checkbox;
