import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { applyFilters } from 'lib/utils';
import { ActionButton, ButtonV2 } from 'components';
import moment from 'moment';
import SelectPersonList from 'modals/common/SelectPersonList';
import Filter from './Filter';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class SelectEnrollmentStep extends Component {
  static propTypes = {
    enrollments: PropTypes.array,
    filters: PropTypes.object,
    selectedEnrollments: PropTypes.array,
    isSaving: PropTypes.bool,
    onSelect: PropTypes.func,
    onFiltersChange: PropTypes.func,
    onSubmit: PropTypes.func
  };

  static filterByCustomConditions(kid, filters) {
    let matchesState = false;
    if (!filters.stateId || kid.state_id === filters.stateId) {
      matchesState = true;
    }

    let matchesPriority = false;
    if (!filters.priority || kid.priority === filters.priority) {
      matchesPriority = true;
    }

    let matchesSource = false;
    if (!filters.source_id || kid.source_id === filters.source_id) {
      matchesSource = true;
    }

    let matchesAge = false;
    if (filters.age) {
      if (kid.dob !== null) {
        const { option, months } = filters.age;
        const diff = moment().diff(moment(kid.dob), 'months');

        switch (option) {
          case 'less': {
            matchesAge = diff < parseInt(months[0]);
            break;
          }
          case 'greater': {
            matchesAge = diff > parseInt(months[0]);
            break;
          }
          case 'range': {
            matchesAge = diff > parseInt(months[0]) && diff < parseInt(months[1]);
            break;
          }
        }
      }
    } else {
      matchesAge = true;
    }

    return matchesSource && matchesPriority && matchesState && matchesAge;
  }

  constructor(props) {
    super(props);

    this.validations = {};

    if (props.kind === 'email') {
      this.validations['No Email'] = kid => {
        return kid.carers.filter(p => p.email).length === 0;
      };
    }

    if (props.kind === 'sms') {
      this.validations['No Mobile'] = kid => {
        return kid.carers.filter(p => p.mobile_phone).length === 0;
      };
    }
  }

  filterEnrollments(enrollments, filters) {
    return applyFilters(
      enrollments.map(e => ({
        ...e,
        name: `${e.first_name} ${e.last_name}`
      })),
      filters,
      SelectEnrollmentStep.filterByCustomConditions
    );
  }

  render() {
    const {
      leadSources,
      filters,
      enrollments,
      selectedEnrollments,
      isSaving,
      onSelect,
      onFiltersChange,
      onSubmit
    } = this.props;
    const filteredEnrollments = this.filterEnrollments(enrollments, filters);

    return (
      <div className="modal__wrapper communication-send-msg__enrollments">
        <div className="modal__header modal__header--bordered">
          <ActionButton iconName="back" size={20} onClick={this.props.context.prevStep} />
          <span className="modal__header-title">Select Leads</span>
          <span className="modal__header-steps">
            <span>Step 2</span> / 2
          </span>

          <div className="communication-send-msg__enrollments-filter">
            <Filter leadSources={leadSources} filters={filters} onChange={onFiltersChange} />
          </div>
        </div>

        <div className="modal__container">
          <SelectPersonList
            persons={filteredEnrollments}
            allPersons={enrollments}
            selectedIds={selectedEnrollments}
            onSelect={onSelect}
            listHeight={455}
            type="kid"
            validations={this.validations}
            disableInvalid={true}
          />
        </div>

        <div className="modal__controls">
          <ButtonV2
            label="Send Message"
            onClick={onSubmit}
            isLoading={isSaving}
            disabled={isSaving || selectedEnrollments.length === 0}
          />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(SelectEnrollmentStep);
