import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TooltipTrigger, ButtonV2 } from 'components';
import './style.scss';

class StageFilter extends Component {
  static propTypes = {
    stages: PropTypes.array,
    enrollmentStats: PropTypes.object,
    value: PropTypes.string,
    onChange: PropTypes.func
  };

  @bind
  renderStages() {
    const { value, stages, onChange, enrollmentStats, hideEnrolled } = this.props;
    const filterEnrolledStatuses = hideEnrolled ? stages.filter(stage => stage.kind != 'enrolled') : stages;

    return (
      <div className="tooltip-content__inner">
        <div className="stage-filter__list">
          {filterEnrolledStatuses.map(s => {
            const itemCN = cx('stage-filter__item', {
              'stage-filter__item--selected': value === s.id
            });

            return (
              <div
                data-cy={`filter-stage-item filter-stage-item-${s.id}`}
                className={itemCN}
                key={s.id}
                onClick={() => onChange(value === s.id ? undefined : s.id)}
              >
                <div className="stage-filter__item-stat">{enrollmentStats[s.id] || 0}</div>
                <div className="stage-filter__item-title">{s.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const { value } = this.props;
    const btnCN = cx('stage-filter__btn', {
      'stage-filter__btn--no-filter': !value
    });

    return (
      <TooltipTrigger
        white
        side="center"
        triggerOn="click"
        className="stage-filter"
        renderTooltip={this.renderStages}
        tooltipClassName="stage-filter__tooltip"
      >
        <ButtonV2 icon iconName="lead-stages" className={btnCN} iconSize="18" data-cy="filter-stage" />
      </TooltipTrigger>
    );
  }
}

const mapStateToProps = state => ({
  stages: state.leadStages.data,
  enrollmentStats: state.enrollmentStats.data
});

export default connect(mapStateToProps)(StageFilter)