import React, { useRef } from 'react';
import cx from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

function HorizontalScroll({ className, children, ...otherProps }) {
  const shadowRefs = useRef({});
  const tableRef = useRef(null);
  const contentWrapperRef = useRef(null);
  const scrollBarsRef = useRef(null);

  const handleUpdate = values => {
    const { scrollLeft, scrollWidth, clientWidth } = values;
    const shadowLeftOpacity = (20 - scrollLeft) * (1 / 20);
    const rightScrollLeft = scrollWidth - clientWidth;
    const shadowRightOpacity = (1 / 20) * (20 - (rightScrollLeft - scrollLeft));
    const tableContentHeight = tableRef.current.offsetHeight;
    if (shadowRefs.current) {
      shadowRefs.current.left.style.cssText = `opacity: ${shadowLeftOpacity}; height: ${tableContentHeight}px;`;
      shadowRefs.current.right.style.cssText = `opacity: ${shadowRightOpacity}; height: ${tableContentHeight}px;`;
    }
    scrollBarsRef.current.trackHorizontal.style.top = `${tableContentHeight - 8}px`;
  };

  return (
    <div className="dynamic-talbe__shadows-wrapper">
      <Scrollbars
        className={cx('dynamic-table__wrapper', {
          [`${className}__wrapper`]: className
        })}
        onUpdate={handleUpdate}
        autoHide={false}
        autoHeight
        autoHeightMax={'auto'}
        style={{ width: '100%' }}
        ref={scrollBarsRef}
      >
        <div ref={contentWrapperRef} className={'dynamic-table__content-wrapper'}>
          <div ref={tableRef} className={cx('table', 'dynamic-table', className)} {...otherProps}>
            {children}
          </div>
        </div>
      </Scrollbars>
      <div
        className="dynamic-table__shadow dynamic-table__shadow--left"
        ref={node => (shadowRefs.current.left = node)}
      />
      <div
        className="dynamic-table__shadow dynamic-table__shadow--right"
        ref={node => (shadowRefs.current.right = node)}
      />
    </div>
  );
}

export default HorizontalScroll;
