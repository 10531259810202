import React, { Component } from 'react';
import PhotoThumb from '../PhotoThumb';

export default class IncidentActivity extends Component {
  render() {
    const { activity } = this.props;
    const incident = activity.activiable || {
      injury_nature: 'Nature: ?',
      first_aid: 'First aid: ?'
    };

    return (
      <div className="activity__incident">
        <div className="activity__paragraph">
          <label>Nature of the incident:</label>
          {incident.injury_nature}
        </div>
        {incident.first_aid && (
          <div className="activity__paragraph">
            <label>First Aid administered:</label>
            {incident.first_aid}
          </div>
        )}
        <div className="activity__paragraph">
          <label>Parent Notified:</label>
          {incident.notify_parent ? 'Yes' : 'No'}
        </div>
        {incident.other_comments && (
          <div className="activity__paragraph">
            <label>Additional Notes:</label>
            {incident.other_comments}
          </div>
        )}
        <PhotoThumb photoUrl={activity.photo_url} />
      </div>
    );
  }
}
