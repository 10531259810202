import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { TooltipTrigger, PageNav, ActionButton } from 'components';
import { Tooltip } from 'components/TooltipTrigger';
import './style.scss';

class ViewSisoAlertLogs extends Component {
  constructor() {
    super();
    this.state = {
      page: 1
    };
  }

  UNSAFE_componentWillMount() {
    this.requestData();
  }

  onPageChange(page) {
    this.setState({ page }, this.requestData);
  }

  @bind
  requestData() {
    const { sisoAlert } = this.props.data;
    const { page } = this.state;

    req.sisoAlertLogs({ id: sisoAlert.id, page: page, per_page: 10 });
  }

  @bind
  deleteLog(log) {
    const { sisoAlert } = this.props.data;

    req.deleteSisoAlertLog({ alertId: sisoAlert.id, id: log.id }, { id: log.id });
  }

  renderRoomTooltip(roomIds) {
    const rooms = roomIds.map(id => this.props.rooms.find(room => room.id === id)).filter(room => !!room);
    return <Tooltip items={rooms} />;
  }

  renderLogs() {
    const { logs } = this.props;

    return logs.map(log => {
      const room = this.props.rooms.find(room => room.id === log.section_id);
      return (
        <div className="table__row" key={log.id}>
          <div className="table__cell table__cell--xs">{moment(log.sent_at).format('MMM D, YYYY (ddd)')}</div>

          <div className="table__cell table__cell--xs">{Helpers.formatTime(log.sent_at)}</div>

          <div className="table__cell table__cell--xs">
            {log.students_count} / {log.staff_count}
          </div>

          <div className="table__cell table__cell--xs">{room && room.name}</div>

          <div className="table__cell table__cell--xxs table__cell--action">
            <ActionButton iconName="delete" size={12} onClick={() => this.deleteLog(log)} />
          </div>
        </div>
      );
    });
  }

  render() {
    const { sisoAlert } = this.props.data;
    const { total, perPage, isLoading, logs } = this.props;
    const { page } = this.state;

    return (
      <div className="view-siso-alert-logs">
        <div className="modal__header">
          <span className="modal__header-title">Alert Logs</span>
          <div className="modal__header-desc">
            <span className="view-siso-alert-logs__name">{sisoAlert.name}</span>
            <span className="view-siso-alert-logs__header-key">STUDENT RATIO:</span>
            <span className="view-siso-alert-logs__value">{sisoAlert.students_count} / 1</span>
            <span className="view-siso-alert-logs__header-key">ROOMS :</span>
            <span className="view-siso-alert-logs__value">
              <TooltipTrigger side="center" tooltip={this.renderRoomTooltip(sisoAlert.section_ids)}>
                {sisoAlert.section_ids.length}
              </TooltipTrigger>
            </span>
          </div>
        </div>

        <div className="modal__container">
          <div className="header header--small">
            <div className="header__inner">Showing {logs.length} results</div>

            <PageNav
              page={page}
              total={total}
              perPage={perPage}
              loading={isLoading}
              onPrev={() => this.onPageChange(page - 1)}
              onNext={() => this.onPageChange(page + 1)}
            />
          </div>

          <div className="table__body view-siso-alert-logs__table">
            <div className="table__row view-siso-alert-logs-headrow">
              <div className="table__cell table__cell--xs">Date</div>

              <div className="table__cell table__cell--xs">Time</div>

              <div className="table__cell table__cell--xs">Student : Staff</div>

              <div className="table__cell table__cell--xs">Room</div>

              <div className="table__cell table__cell--xxs table__cell--action"></div>
            </div>
            {this.renderLogs()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  logs: state.sisoAlertLogs.data.alert_logs,
  isLoading: state.sisoAlertLogs.loading,
  total: state.sisoAlertLogs.data.total,
  perPage: state.sisoAlertLogs.data.per_page
});

export default connect(mapStateToProps)(ViewSisoAlertLogs);
