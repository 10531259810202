import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TooltipTrigger } from 'components';

class ScheduleDay extends PureComponent {
  static propTypes = {
    day: PropTypes.string,

    value: PropTypes.shape({
      am: PropTypes.bool,
      pm: PropTypes.bool
    }),

    onChange: PropTypes.func.isRequired,
    validTimes: PropTypes.array
  };

  static defaultProps = {
    day: '',
    value: { am: false, pm: false },
    validTimes: null
  };

  @bind
  selectDay() {
    const { day, value, validTimes } = this.props;

    if (validTimes) {
      if (!validTimes.includes('am') && !validTimes.includes('pm')) {
        return;
      }
      if (validTimes.includes('am') && !validTimes.includes('pm')) {
        this.selectAM();
        return;
      }
      if (validTimes.includes('pm') && !validTimes.includes('am')) {
        this.selectPM();
        return;
      }
    }

    const nextValue = !(value.am && value.pm);
    this.props.onChange(day, { am: nextValue, pm: nextValue });
  }

  @bind
  selectAM() {
    const { day, value, validTimes } = this.props;
    if (validTimes && !validTimes.includes('am')) {
      return;
    }

    this.props.onChange(day, { ...value, am: !value.am });
  }

  @bind
  selectPM() {
    const { day, value, validTimes } = this.props;
    if (validTimes && !validTimes.includes('pm')) {
      return;
    }

    this.props.onChange(day, { ...value, pm: !value.pm });
  }

  @bind
  getClassName() {
    const { value, validTimes, timesToShow } = this.props;

    return classNames('schedule-select__day', {
      'schedule-select__day--selected': value.am || value.pm,
      'schedule-select__day--selected-am': value.am,
      'schedule-select__day--selected-pm': value.pm,
      'schedule-select__day--disabled': validTimes && !validTimes.includes('am') && !validTimes.includes('pm'),
      'schedule-select__day--disabled-am': validTimes && !validTimes.includes('am'),
      'schedule-select__day--disabled-pm': validTimes && !validTimes.includes('pm'),
      'schedule-select__day--hidden-am': timesToShow && !timesToShow.includes('am'),
      'schedule-select__day--hidden-pm': timesToShow && !timesToShow.includes('pm')
    });
  }

  render() {
    const { day, validTimes } = this.props;
    const className = this.getClassName();

    const isDayDisabled = validTimes && !validTimes.includes('am') && !validTimes.includes('pm');
    const isAmDisabled = validTimes && !validTimes.includes('am');
    const isPmDisabled = validTimes && !validTimes.includes('pm');

    return (
      <div className={className}>
        <TooltipTrigger tooltip="Student is not scheduled on this day" side="center" disabled={!isDayDisabled}>
          <div className="schedule-select__day-name" onClick={this.selectDay}>
            {day.toLowerCase()}
          </div>

          <div className="schedule-select__day-period-group">
            <div className="schedule-select__day-period-am">
              <TooltipTrigger
                tooltip="Student is not scheduled for this time"
                side="center"
                disabled={!isAmDisabled || isDayDisabled}
              >
                <div onClick={this.selectAM}>AM</div>
              </TooltipTrigger>
            </div>

            <div className="schedule-select__day-period-pm">
              <TooltipTrigger
                tooltip="Student is not scheduled for this time"
                side="center"
                disabled={!isPmDisabled || isDayDisabled}
              >
                <div onClick={this.selectPM}>PM</div>
              </TooltipTrigger>
            </div>
          </div>
        </TooltipTrigger>
      </div>
    );
  }
}

export default ScheduleDay;
