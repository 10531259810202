import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Steps from 'modals/ModalController/Steps';
import EditMsgStep from './EditMsgStep';
import EditSmsStep from './EditSmsStep';
import SelectEnrollmentStep from './SelectEnrollmentStep';
import SelectTemplateStep from './SelectTemplateStep';
import './style.scss';

class CommunicationSendMsg extends Component {
  static propTypes = {
    data: PropTypes.shape({
      kind: PropTypes.oneOf(['email', 'sms']),
      fromTemplate: PropTypes.bool,
      fromLead: PropTypes.bool,
      message: PropTypes.shape({
        id: PropTypes.string,
        subject: PropTypes.string,
        content: PropTypes.string,
        reply_to: PropTypes.string
      }),
      enrollment: PropTypes.object
    })
  };

  static defaultProps = {
    data: {
      message: {}
    }
  };

  stepsRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      message: {
        subject: get(props, 'data.message.subject', ''),
        content: get(props, 'data.message.content', ''),
        replyTo: get(props, 'data.message.reply_to', ''),
        groupEntity: get(props, 'data.message.group_entity', false)
      },

      filters: {
        search: undefined,
        roomId: undefined,
        stateId: undefined
      },

      selectedEnrollmentIds: [],
      isSaving: false,
      isSavingTemplate: false,
      isSavingDraft: false
    };

    const {
      data: { enrollment }
    } = props;

    if (enrollment && enrollment.id) {
      this.state.selectedEnrollmentIds = [enrollment.id];
    }
  }

  UNSAFE_componentWillMount() {
    req.allEnrollments();
    req.rooms();
    req.leadStages();
    req.leadSources();
  }

  @bind
  updateFilters(filters) {
    this.setState({
      filters: { ...this.state.filters, ...filters },
      selectedCount: 0,
      selectedIds: {}
    });
  }

  @bind
  updateSelectedEnrollments(enrollmentIds) {
    this.setState({ selectedEnrollmentIds: enrollmentIds });
  }

  @bind
  goToMessageStep(template) {
    this.stepsRef.current.goToStep('message');

    if (template) {
      this.setState({ message: template });
    }
  }

  @bind
  goToEnrollmentsStep(message) {
    this.setState({ message });
    this.stepsRef.current.goToStep('enrollments');
  }

  @bind
  goToTemplateStep() {
    this.stepsRef.current.goToStep('template');
  }

  get kind() {
    return this.props.data.kind || get(this.props.data, 'message.kind') || 'email';
  }

  @bind
  async sendMessage() {
    const { message, selectedEnrollmentIds } = this.state;
    this.setState({ isSaving: true });
    const id = get(this.props.data, 'message.id', undefined);

    const performRequest = id ? req.enrollmentsMessageUpdate : req.enrollmentsMessageSave;

    try {
      const response = await performRequest({
        id,
        message: {
          enrollment_ids: selectedEnrollmentIds,
          subject: message.subject,
          content: message.content,
          kind: this.kind,
          reply_to: message.replyTo
        },
        send: true
      });

      Actions.showFlash('Message has been sent');
      this.props.onClose(response);
    } catch (e) {
      Actions.reportError('Unable to send message', e);
      this.setState({ isSaving: false });
    }
  }

  @bind
  async saveDraft({ subject, content, replyTo }) {
    this.setState({ isSavingDraft: true });

    const id = get(this.props.data, 'message.id', undefined);
    const performRequest = id ? req.enrollmentsMessageUpdate : req.enrollmentsMessageSave;

    try {
      await performRequest({
        id,

        message: {
          subject,
          content,
          kind: this.kind,
          reply_to: replyTo
        },
        send: false
      });

      Actions.showFlash('Message has been saved as draft');
      this.props.onClose();
    } catch (e) {
      Actions.reportError('Unable to save message', e);
      this.setState({ isSavingDraft: false });
    }
  }

  @bind
  async sendTest({ subject, content, replyTo }) {
    this.setState({ isSavingDraft: true });
    const id = get(this.props.data, 'message.id', undefined);

    try {
      await req.enrollmentsMessageTest({
        id,

        message: {
          subject,
          content,
          kind: this.kind,
          reply_to: replyTo
        }
      });

      Actions.showFlash('Test message has been sent');
    } catch (e) {
      Actions.reportError('Unable to send message', e);
    }
    this.setState({ isSavingDraft: false });
  }

  @bind
  async saveTemplate({ subject, content }) {
    this.setState({ isSavingTemplate: true });

    const id = get(this.props.data, 'message.id', undefined);
    const performRequest = id ? req.updateEnrollmentsMessageTemplate : req.addEnrollmentsMessageTemplate;
    const kind = this.props.data.kind || get(this.props.data, 'message.kind') || 'email';

    try {
      await performRequest({
        id,
        message: {
          subject,
          content,
          kind
        },
        template: true
      });

      Actions.showFlash('Template saved');
      this.props.onClose();
    } catch (e) {
      Actions.reportError('Unable to save template', e);
      this.setState({ isSavingTemplate: false });
    }
  }

  render() {
    const { leadSources, allEnrollments, data, stages } = this.props;
    const { message, selectedEnrollmentIds, filters, isSaving, isSavingDraft, isSavingTemplate } = this.state;
    const EditStep = this.kind === 'sms' ? EditSmsStep : EditMsgStep;
    const enrolledStatus = stages.find(stage => stage.kind === 'enrolled') || '';
    const filteredEnrollments = data.hideEnrolled
      ? allEnrollments.filter(enrollment => enrollment.state_id != enrolledStatus.id)
      : allEnrollments;

    return (
      <div className="communication-send-msg">
        <Steps ref={this.stepsRef}>
          <Steps.Item name="message">
            <EditStep
              message={message}
              isEdit={Boolean(get(this.props, 'data.message.id'))}
              onSubmit={
                data.fromLead
                  ? message => this.setState({ message, isSaving: true }, this.sendMessage)
                  : this.goToEnrollmentsStep
              }
              onChooseTemplate={this.goToTemplateStep}
              onSaveDraft={this.saveDraft}
              onSaveTemplate={this.saveTemplate}
              onSendTest={this.sendTest}
              isSaving={isSaving}
              isSavingDraft={isSavingDraft}
              isSavingTemplate={isSavingTemplate}
              fromTemplate={data.fromTemplate}
              fromLead={data.fromLead}
            />
          </Steps.Item>

          {!data.fromLead && (
            <Steps.Item name="enrollments">
              <SelectEnrollmentStep
                leadSources={leadSources}
                enrollments={filteredEnrollments}
                filters={filters}
                kind={this.kind}
                selectedEnrollments={selectedEnrollmentIds}
                onSelect={this.updateSelectedEnrollments}
                onFiltersChange={this.updateFilters}
                onSubmit={this.sendMessage}
                isSaving={this.state.isSaving}
              />
            </Steps.Item>
          )}

          <Steps.Item hidden name="template">
            <SelectTemplateStep kind={this.kind} onSelect={this.goToMessageStep} onBack={this.goToMessageStep} />
          </Steps.Item>
        </Steps>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  leadSources: state.leadSources.data,
  allEnrollments: state.allEnrollments.data,
  stages: state.leadStages.data
});

export default connect(mapStateToProps)(CommunicationSendMsg);
