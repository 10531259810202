import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ActionButton } from 'components';
import history from 'lib/history';
import './style.scss';

const GUSTO_SHOW_LIMIT = 4;

class GustoBar extends Component {
  constructor() {
    super();
    this.state = {
      showCount: localStorage.getItem('gusto_bar_show_count') * 1 || 0,
      hideBar: localStorage.getItem('gusto_bar_hide') || false
    };

    localStorage.setItem('gusto_bar_show_count', this.state.showCount + 1);
  }

  @bind
  closeBar() {
    this.setState({ hideBar: true });
    localStorage.setItem('gusto_bar_hide', true);

    Actions.showModal('Alert', {
      title: 'Connect Gusto',
      message: "You can connect with Gusto anytime by going to 'School Settings -> Marketplace'",
      label: 'Okay'
    });
  }

  @bind
  alreadyHaveGusto() {
    localStorage.setItem('gusto_bar_show_count', GUSTO_SHOW_LIMIT + 1);
    history.push('/settings/marketplace');
  }

  @bind
  getStarted() {
    const { utmCampaign } = this.props;
    localStorage.setItem('gusto_bar_show_count', GUSTO_SHOW_LIMIT + 1);
    window.open(`https://gusto.com/go/bd/procare?utm_source=procare&utm_campaign=${utmCampaign}`);
  }

  render() {
    const { gusto, isLoading, isAdmin } = this.props;
    const { showCount, hideBar } = this.state;

    if (isLoading || gusto.enabled || hideBar || !isAdmin) {
      return null;
    }

    return (
      <div className="gusto-bar">
        <div className="gusto-bar__content">
          <img className="gusto-bar__image" src="/assets/images/marketplace/Gusto.png" width="100" />
          <div className="gusto-bar__text">Send Staff Timecard directly to Payroll with Gusto!</div>
        </div>
        <div className="gusto-bar__content">
          <a onClick={this.getStarted} className="button gusto-bar__connect">
            Get Started
          </a>
          <a onClick={this.alreadyHaveGusto} className="button gusto-bar__connect">
            Already Have Gusto
          </a>
          {showCount > GUSTO_SHOW_LIMIT && (
            <ActionButton className="gusto-bar__close" iconName="close" size={10} onClick={this.closeBar} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: state.currentUser.data.teacher && state.currentUser.data.teacher.is_admin,
  gusto: state.gusto.data,
  isLoading: state.gusto.loading
});

export default connect(mapStateToProps)(GustoBar);
