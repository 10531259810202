import React, { Component } from 'react';
import { Icon, ButtonV2 } from 'components';
import '../ActivateSchool/style.scss';

class ActivateSuccess extends Component {
  render() {
    const { title } = this.props.data;

    return (
      <div className="school-activate">
        <div className="school-activate__thankyou">
          <div className="school-activate__thankyou-img">
            <Icon name="tick" size={55} />
          </div>
          <div className="school-activate__thankyou-title">{title}</div>
          <div className="school-activate__thankyou-button">
            <ButtonV2 label="OKAY" onClick={this.props.onClose} />
          </div>
        </div>
      </div>
    );
  }
}

export default ActivateSuccess;
