const createBasicReducer = (reducerName, initialState) => (state = { ...initialState }, action) => {
  switch (action.type) {
    case `${reducerName}_SET`:
      return { data: action.payload, loading: false };

    case 'REDUX_STORE_RESET':
    case `${reducerName}_RESET`:
      if (action.payload && action.payload.exclude instanceof Array && action.payload.exclude.includes(reducerName)) {
        return state;
      }

      return { ...initialState };

    default:
      return state;
  }
};

export default createBasicReducer;
