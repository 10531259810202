import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'components';

// Source and inspiration: src/modals/EditStaffClockIn/index.js
const StaffPresentSelect = ({ staff, allStaff, activity, currentUser, staffRequired }) => {
  let defaultValue = null;
  if (activity.staff_present_id === null) {
    defaultValue = null;
  } else if (activity.staff_present_id === undefined) {
    defaultValue = currentUser;
  } else {
    defaultValue = activity.staff_present_id;
  }

  let staffOptions = [...staff];
  if (activity.staff_present_id != null && staff.find(s => s.id === activity.staff_present_id) == null) {
    const staffItem = allStaff.find(s => s.id === activity.staff_present_id);
    staffItem != null && staffOptions.push(staffItem);
  }

  return (
    <div className="form__row form__row--justified pr-5">
      <Form.Select name="staff_present" defaultValue={defaultValue} title="Staff Present" label="Staff Present">
        {!staffRequired && <Form.Select.Item value={null} label="None" />}
        {staffOptions.map(stf => (
          <Form.Select.Item key={stf.id} label={stf.name} value={stf.id} />
        ))}
      </Form.Select>
    </div>
  );
};

const mapStateToProps = state => ({
  staff: state.staff.data,
  allStaff: state.allStaff.data,
  currentUser: state.currentUser.data.teacher && state.currentUser.data.teacher.id,
  staffSelectEnabled: state.currentUser.data.current_school
    ? state.currentUser.data.current_school.daily_activity_staff_name_enabled
    : false
});

// Connect to Form level context passing the redux context
// Source: https://stackoverflow.com/questions/37123203/context-not-being-passed-through-redux-connect
export default connect(mapStateToProps, null, null, { pure: false })(StaffPresentSelect);
