import React, { useRef, useState } from 'react';
import { Form, Preloader, ButtonV2 } from 'components';
import Steps from 'modals/ModalController/Steps';
import './style.scss';

function UpdateKinderSystems({ onClose, data }) {
  const { syncSettingId, providers, agencies, selectedProviders } = data;
  const [loading, setLoading] = useState(false);
  const initialSelectedProviderIds = selectedProviders.map(provider => provider.provider_id);
  const [selectedProviderIds, setSelectedProviderIds] = useState(initialSelectedProviderIds);
  const [selectedAgencyIds, setSelectedAgencyIds] = useState({});
  const [currentStep, setCurrentStep] = useState('provider');

  const stepsRef = useRef(null);

  const updateSettings = () => {
    setLoading(true);

    const parsedAgencyIds = {};
    Object.keys(selectedAgencyIds).forEach(providerId => {
      const agenciesOrEmptyArray = Array.isArray(selectedAgencyIds[providerId]) ? selectedAgencyIds[providerId] : [];
      parsedAgencyIds[providerId] = agenciesOrEmptyArray.map(agencyIdWithIndex => {
        const [actualAgencyId] = agencyIdWithIndex.split('-');
        return actualAgencyId;
      });
    });

    const providersPayload = selectedProviderIds.reduce((acc, providerId) => {
      const strProviderId = providerId.toString();
      acc[strProviderId] = parsedAgencyIds[strProviderId] || [];
      return acc;
    }, {});

    req
      .updateSyncSettings({
        syncSettingId,
        providers: providersPayload
      })
      .then(() => {
        Actions.showFlash('Settings updated successfully');
        data.refreshData();
        onClose({ updated: true });
      })
      .catch(error => {
        console.error('Error updating settings', error);
      })
      .finally(() => setLoading(false));
  };

  const providerStep = () => (
    <Form key="providerForm" onChange={values => setSelectedProviderIds(values.providerId)} validateOn="change">
      <div className="form__row">
        <Form.Select
          name="providerId"
          title="Select Provider"
          type="checkbox"
          label="Provider"
          key="providerId"
          multipleSelectionText="Selected providers"
          enableClear={true}
          defaultValue={selectedProviderIds}
          required
        >
          {providers?.length > 0 ? (
            providers.map(provider => (
              <Form.Select.Item
                key={provider.provider_id}
                value={provider.provider_id}
                label={provider.provider_name}
              />
            ))
          ) : (
            <div>No providers available</div>
          )}
        </Form.Select>
      </div>
      <div className="form__row form__row--actions">
        {!loading && (
          <>
            <ButtonV2 secondary onClick={() => onClose(false)} label="Close" />
            <ButtonV2
              onClick={() => {
                stepsRef.current.goToStep('agency');
                setCurrentStep('agency');
              }}
              label="Continue"
              disabled={!selectedProviderIds.length}
            />
          </>
        )}
        {loading && <Preloader />}
      </div>
    </Form>
  );

  const agencyStep = () => {
    const initialSelectedAgencies = selectedProviders.reduce((acc, provider) => {
      provider.agencies.forEach(agency => {
        const providerIdStr = provider.provider_id.toString();
        if (!acc[providerIdStr]) {
          acc[providerIdStr] = [];
        }
        acc[providerIdStr].push(`${agency.agency_id}-${providerIdStr}`);
      });
      return acc;
    }, {});

    const getDefaultSelectedAgencies = () => {
      const defaultSelectedAgencies = Object.entries(initialSelectedAgencies).flatMap(([providerId, agencyIds]) => {
        if (selectedProviderIds.includes(parseInt(providerId))) {
          return agencyIds;
        }
        return [];
      });

      return defaultSelectedAgencies;
    };

    return (
      <Form
        id="agencyForm"
        key="agencyForm"
        onChange={values => {
          const newSelectedAgencyIds = { ...selectedAgencyIds };
          values.agencyId.forEach(value => {
            const [agencyId, providerId] = value.split('-');
            if (newSelectedAgencyIds[providerId]) {
              if (!newSelectedAgencyIds[providerId].includes(agencyId)) {
                newSelectedAgencyIds[providerId].push(agencyId);
              }
            } else {
              newSelectedAgencyIds[providerId] = [agencyId];
            }
          });
          setSelectedAgencyIds(newSelectedAgencyIds);
        }}
        validateOn="change"
      >
        <div className="form__row">
          <Form.Select
            name="agencyId"
            title="Select Agencies"
            type="checkbox"
            label="Agency"
            key="agencyId"
            multipleSelectionText="Selected agencies"
            enableClear={true}
            required
            defaultValue={getDefaultSelectedAgencies()}
          >
            {agencies && selectedProviderIds.length > 0 ? (
              agencies
                .filter(agency => selectedProviderIds.includes(parseInt(agency.provider_id)))
                .map((agency, index) => {
                  const matchingProvider = providers.find(
                    provider => provider.provider_id.toString() === agency.provider_id.toString()
                  );
                  const providerName = matchingProvider ? matchingProvider.provider_name : 'Unknown Provider';
                  return (
                    <Form.Select.Item
                      key={`${agency.agency_id}-${index}`}
                      value={`${agency.agency_id}-${agency.provider_id}`}
                      label={`${agency.agency_name} (Provider - ${providerName})`}
                    />
                  );
                })
            ) : (
              <div>No agencies available</div>
            )}
          </Form.Select>
        </div>
        <div className="form__row form__row--actions">
          {!loading && (
            <>
              <ButtonV2
                secondary
                onClick={() => {
                  stepsRef.current.goToStep('provider');
                  setCurrentStep('provider');
                }}
                label="Back"
              />
              <ButtonV2 onClick={updateSettings} label="Update" disabled={!Object.keys(selectedAgencyIds).length} />
            </>
          )}
          {loading && <Preloader />}
        </div>
      </Form>
    );
  };

  return (
    <div className="modal__wrapper kindersystems">
      <div className="modal__header">
        <div className="modal__header-title">{currentStep === 'provider' ? 'Change Provider' : 'Change Agency'}</div>
      </div>
      <div className="modal__container">
        <Steps ref={stepsRef}>
          <Steps.Item name="provider">{providerStep()}</Steps.Item>
          <Steps.Item name="agency">{agencyStep()}</Steps.Item>
        </Steps>
      </div>
    </div>
  );
}

export default UpdateKinderSystems;
