import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ActionButton } from 'components';
import './style.scss';

const ManageWindow = ({
  title,
  className,
  addIcon,
  addLabel,
  addDisabled,
  onAdd,
  onBack,
  children,
  'data-cy': dataCY
}) => (
  <div className={classNames('manage-window', className)}>
    <div className="modal__header">
      {onBack && <ActionButton iconName="back" onClick={onBack} />}

      <div className="modal__header-title">{title}</div>
      {onAdd && addLabel && (
        <ActionButton
          className="manage-window__add-btn"
          iconName={addIcon}
          title={addLabel}
          onClick={onAdd}
          disabled={addDisabled}
          data-cy={dataCY}
        />
      )}
    </div>

    <div className="manage-window__container">{children}</div>
  </div>
);

ManageWindow.propTypes = {
  title: PropTypes.string,
  addIcon: PropTypes.string,
  addLabel: PropTypes.string,
  addDisabled: PropTypes.bool,
  onAdd: PropTypes.func,
  onBack: PropTypes.func,
  'data-cy': PropTypes.string
};

export default ManageWindow;
