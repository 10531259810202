import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonV2, Avatar, StudentList } from 'components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { renderAddress } from 'lib/utils';
import currency from 'lib/currency';
import { getPaymentModeDescription } from 'lib/utils/billing';

import './style.scss';

const printPayment = () => {
  window.stop();
  window.print();
};

const ShowPayment = ({ payment = {}, students }) => {
  const {
    amount,
    fee_amount,
    description,
    receipt_number,
    payment_mode,
    payment_method_sub_kind,
    transaction_date,
    fee_paid_by
  } = payment;

  const school = useSelector(state => state.school.data);
  const constants = useSelector(state => state.constants.data);
  const isCreditCard = payment_mode === 'card' && payment_method_sub_kind === 'credit';
  const isFeePaidByParent = fee_paid_by === 'fee_paid_by_parent';
  const isFeePresent = typeof fee_amount === 'number';
  const paymentMode = getPaymentModeDescription(payment_mode, payment_method_sub_kind, payment);
  const paymentDate = transaction_date && moment(transaction_date).format('MMM D, YYYY');
  const total = currency.getPrice(isFeePresent ? amount + fee_amount : amount);

  useEffect(() => {
    req.school();
  }, []);

  return (
    <div className="show-payment">
      <div className="modal__header">
        <div className="modal__header-title">View Payment Receipt</div>
      </div>

      <div className="modal__container">
        <div className="header header--small show-payment__header mt-4">
          <ButtonV2 tertiary onClick={printPayment} label="Print" />
        </div>
        <div className="payment-preview-card">
          <div className="payment-preview-card__header">
            <div className="payment-preview-card__school">
              <Avatar
                className="avatar--square payment-preview-card__school-logo"
                url={school.profile_pic_url}
                type="logo"
              />

              <div className="payment-preview-card__school-info">
                <div className="payment-preview-card__school-info-name">{school.name}</div>

                <div className="payment-preview-card__school-info-address">
                  {renderAddress(school, constants.countries)}
                </div>

                {school.federal_tax_id && (
                  <div className="payment-preview-card__school-info-ein">EIN #: {school.federal_tax_id}</div>
                )}
              </div>
            </div>

            <div className="payment-preview-card__payment-number">{receipt_number ? `#${receipt_number}` : ''}</div>

            {!!students.length && (
              <div className="payment-preview-card__students">
                <span className="payment-preview-card__students-text">Payment For </span>
                <StudentList students={students} />
              </div>
            )}
          </div>

          <div className="payment-preview-card__content">
            <div className="payment-preview-card__date">
              Payment date:
              <span className="payment-preview-card__date-value">{paymentDate}</span>
            </div>

            <div className="payment-preview-card__payment-table table">
              <div className="table__body">
                <div className="table__row payment-preview-card__headrow">
                  <div className="table__cell">Description</div>

                  <div className="table__cell table__cell--xxs table__cell--actions">Amount</div>
                </div>

                <div className="table__row">
                  <div className="table__cell payment-preview-card__description-cell">
                    <span className="payment-preview-card__description">{description}</span>

                    {paymentMode && <span className="payment-preview-card__payment-mode">{paymentMode}</span>}
                  </div>

                  <div className="table__cell table__cell--xxs table__cell--actions">{currency.getPrice(amount)}</div>
                </div>

                {isCreditCard && isFeePaidByParent && (
                  <div className="table__row">
                    <div className="table__cell payment-preview-card__description-cell">
                      <span className="payment-preview-card__fee-description">
                        Card Processing Fee{!isFeePresent && '*'}
                      </span>
                    </div>

                    <div className="table__cell table__cell--xxs table__cell--actions">
                      {isFeePresent ? currency.getPrice(fee_amount) : '--'}
                    </div>
                  </div>
                )}

                <div className="table__row payment-preview-card__total">
                  <div className="table__cell" />

                  <div className="table__cell table__cell--xs table__cell--actions">
                    <span className="payment-preview-card__total-title">Total Amount</span>

                    <span className="payment-preview-card__total-amount">{total}</span>
                  </div>
                </div>

                {isCreditCard && isFeePaidByParent && !isFeePresent && (
                  <div className="payment-preview-card__fee-disclaimer">
                    *Unable to display fees for transactions made before processing fees were recorded.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ShowPayment.propTypes = {
  payment: PropTypes.object,
  print: PropTypes.bool
};

export default ShowPayment;
