import React, { Component } from 'react';
import { Timestamp } from 'components';
import get from 'lodash/get';
import PhotoThumb from '../PhotoThumb';

export default class SignOutActivity extends Component {
  render() {
    const { activity } = this.props;
    return (
      <div className="activity__signout">
        <div className="activity__paragraph">
          {`Signed-Out from ${get(activity, 'activiable.section.name')} @`}
          <Timestamp timestamp={activity.activiable && activity.activiable.sign_out_time} showDate={false} />
        </div>
        <PhotoThumb photoUrl={activity.photo_url} />
      </div>
    );
  }
}
