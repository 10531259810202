import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { applyFiltersLeads } from 'lib/utils';
import { ButtonV2, Filter, Form, Preloader } from 'components';
import SelectPersonList from 'modals/common/SelectPersonList';
import './style.scss';

class SelectLeads extends Component {
  static propTypes = {
    leads: PropTypes.array,
    data: PropTypes.object
  };

  static defaultProps = {
    leads: [],
    data: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: {},
      selectedLeadIds: props.data.selectedLeadIds || [],
      isLoading: true
    };

    req.rooms();
    req.leadStages();
    req.allEnrollments().then(() => this.setState({ isLoading: false }));
  }

  @bind
  updateFilters(filters) {
    this.setState({ filters });
  }

  @bind
  updateSearch(search) {
    this.updateFilters({ ...this.props.filters, search });
  }

  @bind
  updateSelectedLeads(selectedLeadIds) {
    this.setState({ selectedLeadIds });
  }

  @bind
  onSubmit() {
    this.props.onClose(this.state.selectedLeadIds);
  }

  get leads() {
    return applyFiltersLeads(this.props.leads, this.state.filters);
  }

  renderFilter() {
    const { filters } = this.state;
    const { leadStages } = this.props;
    const stageMap = leadStages.reduce(
      (prev, s) => ({
        ...prev,
        [s.id]: s.name
      }),
      {}
    );

    const filterConfig = {
      priority: {
        type: 'hidden-select',
        options: {
          none: 'None',
          low: 'Low',
          medium: 'Medium',
          high: 'High'
        }
      },
      roomId: { type: 'room' },
      age: { type: 'age-filter', label: 'Age', singleValue: true },
      expectedDate: {
        type: 'date-range',
        label: 'Expected Start Date',
        appliedLabel: { from: 'Expected Start From', to: 'Expected Start To' },
        useName: true
      },
      creationDate: {
        type: 'date-range',
        label: 'Lead Creation Date',
        appliedLabel: { from: 'Lead Creation From', to: 'Lead Creation To' },
        useName: true
      },
      stageId: {
        type: 'select',
        title: 'Status',
        label: 'Status',
        options: {
          '': 'All Statuses',
          ...stageMap
        }
      }
    };
    const defaultValues = {
      search: filters.search,
      priority: filters.priority,
      roomId: filters.section_id,
      expectedDate: [filters.expectedDate_from, filters.expectedDate_to],
      creationDate: [filters.creationDate_from, filters.creationDate_to],
      age: filters.age,
      stageId: filters.stageId
    };

    return (
      <div className="reg-list__filter-inner">
        <Filter
          key={`${filters.state_id}${filters.priority}`}
          wide
          filters={filterConfig}
          defaultValues={defaultValues}
          onSubmit={this.updateFilters}
        >
          <Form.Input name="search" placeholder="Search by Student or Parent Name" defaultValue={filters.search} />
        </Filter>
      </div>
    );
  }

  render() {
    const { data } = this.props;
    const { validations, disableInvalid } = data;
    const { isLoading, selectedLeadIds } = this.state;

    return (
      <div className="select-document-recipients__students">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Select Leads</div>
          <div className="select-document-recipients__students-filter">{this.renderFilter()}</div>
        </div>

        <div className="modal__container">
          {isLoading ? (
            <div className="select-leads__loading">
              <Preloader large />
            </div>
          ) : (
            <SelectPersonList
              persons={this.leads}
              allPersons={this.leads}
              selectedIds={selectedLeadIds}
              onSelect={this.updateSelectedLeads}
              disableInvalid={disableInvalid}
              validations={validations}
              listHeight={400}
              type="lead"
              noResultText="No lead found by filter"
              isPaginated={true}
            />
          )}
        </div>

        <div className="modal__controls">
          <ButtonV2 label="Continue" onClick={this.onSubmit} isLoading={isLoading} data-cy="continue" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  leads: state.allEnrollments.data.map(lead => ({ ...lead, name: [lead.first_name, lead.last_name].join(' ') })),
  leadStages: state.leadStages.data
});

export default connect(mapStateToProps)(SelectLeads);
