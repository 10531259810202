export const FILE_TYPES = [
  '.pdf',
  '.csv',
  '.xls',
  '.xlsx',
  '.doc',
  '.docx',
  '.txt',
  '.png',
  '.bmp',
  '.jpg',
  '.jpeg',
  '.ppm'
];

export const MIME_TYPES = [
  'application/pdf',
  'text/csv',
  'application/vnd.ms-excel',
  'application/msword',
  'text/plain',
  'image/png',
  'image/jpeg',
  'image/bmp',
  'image/x-ppm'
];

export const UPLOAD_MEDIA_TYPES = [
  'application/msword',
  'application/pdf',
  'application/vnd.apple.keynote',
  'application/vnd.apple.numbers',
  'application/vnd.apple.pages',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'audio/aac',
  'audio/mp3',
  'audio/mpeg',
  'audio/ogg',
  'audio/wav',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/x-ppm',
  'text/*',
  'text/csv',
  'text/plain',
  'video/*',
  'video/mp4',
  'video/mpeg'
];
