import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Row = ({ layout = {}, children }) => {
  const className = cx('form__row', {
    [`form__row--${layout.width}`]: layout.width
  });

  return <div className={className}>{children}</div>;
};

Row.propTypes = {
  layout: PropTypes.shape({
    width: PropTypes.string
  }),
  children: PropTypes.node
};

export default Row;
