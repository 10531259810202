import { withLDConsumer as consumer } from 'launchdarkly-react-client-sdk';

const customContext = currentUser => ({
  tenancy: (process.env.TENANCY || 'shared').toLowerCase(),
  user_role: currentUser.current_role,
  school_id: currentUser.current_school?.id,
  school_name: currentUser.current_school?.name,
  school_pe_enabled: currentUser.current_school?.pe_enabled,
  school_state: currentUser.current_school?.state,
  school_facility_type: currentUser.current_school?.facility_type,
  school_is_corp_school: currentUser.current_school?.corp_school,
  subscription_is_active: currentUser.current_school?.subscription?.is_active,
  subscription_category: currentUser.current_school?.subscription_category,
  subscription_plan: currentUser.current_school?.subscription_plan,
  created_at: currentUser.current_school?.created_at
});

export const withLDConsumer = () => consumer();

export const LDSchoolContext = currentUser => ({
  key: currentUser.current_school?.id,
  email: currentUser.current_school?.sender_email,
  custom: {
    ...customContext(currentUser),
    user_role: 'school'
  }
});

export const identifyLDSchool = (LDClient, currentUser) =>
  LDClient && currentUser && LDClient.identify(LDSchoolContext(currentUser));
