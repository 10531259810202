import React from 'react';
import { Form } from 'components';

const CheckboxOptions = ({ options = {} }) => (
  <React.Fragment>
    <Form.Input
      name="checkboxLabel"
      label="Checkbox label"
      placeholder="Checkbox label"
      defaultValue={options.checkboxLabel}
    />
    {/*<Form.Select name="checked" label="Default value" title="Select default value">*/}
    {/*<Form.Select.Item value={true} label="Checked" />*/}
    {/*<Form.Select.Item value={false} label="Not checked" />*/}
    {/*</Form.Select>*/}
  </React.Fragment>
);

export default CheckboxOptions;
