import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { applyFilters } from 'lib/utils';
import { ActionButton, ButtonV2 } from 'components';
import SelectPersonList from 'modals/common/SelectPersonList';
import Filter from './Filter';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class SelectStudentsStep extends Component {
  static propTypes = {
    rooms: PropTypes.array,
    students: PropTypes.array,
    filters: PropTypes.object,
    selectedStudents: PropTypes.array,
    isSaving: PropTypes.bool,
    onSelect: PropTypes.func,
    onFiltersChange: PropTypes.func,
    onSubmit: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.validations = {
      'No Email': kid => kid.parents.filter(p => p.email).length === 0
    };
  }

  filterStudents(students, filters) {
    return applyFilters(
      students.map(e => ({
        ...e,
        name: `${e.first_name} ${e.last_name}`
      })),
      filters
    );
  }

  render() {
    const { rooms, filters, students, selectedStudents, isSaving, onSelect, onFiltersChange, onSubmit } = this.props;
    const filteredStudents = this.filterStudents(students, filters);

    return (
      <div className="modal__wrapper share-meal__students">
        <div className="modal__header modal__header--bordered">
          <ActionButton iconName="back" size={20} onClick={this.props.context.prevStep} />
          <span className="modal__header-title">Select Students</span>
          <span className="modal__header-steps">
            <span>Step 2</span> / 2
          </span>

          <div className="share-meal__students-filter">
            <Filter rooms={rooms} filters={filters} onChange={onFiltersChange} />
          </div>
        </div>

        <div className="modal__container">
          <SelectPersonList
            persons={filteredStudents}
            allPersons={students}
            selectedIds={selectedStudents}
            onSelect={onSelect}
            listHeight={455}
            type="kid"
            validations={this.validations}
            disableInvalid={false}
          />
        </div>

        <div className="modal__controls">
          <ButtonV2
            label="Send Email"
            onClick={onSubmit}
            isLoading={isSaving}
            disabled={isSaving || selectedStudents.length === 0}
            data-cy="send"
          />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(SelectStudentsStep);
