import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Avatar, Icon, Preloader } from 'components';

class Row extends PureComponent {
  static propTypes = {
    school: PropTypes.object,
    isCurrent: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func
  };

  @bind
  handleClick() {
    const { isCurrent, onClick, school } = this.props;

    if (isCurrent) {
      return;
    }

    if (onClick) {
      onClick(school);
    }
  }

  render() {
    const { school, isCurrent, loading } = this.props;

    const rowClassName = classNames('select-school__row', {
      'select-school__row--current': isCurrent
    });

    return (
      <div className={rowClassName} onClick={this.handleClick}>
        <div className="select-school__row-image">
          <Avatar type="logo" url={school.profile_pic_url} />
        </div>

        <div className="select-school__row-name">{school.name}</div>

        {loading ? (
          <div className="select-school__row-preloader">
            <Preloader />
          </div>
        ) : (
          <Icon className="select-school__row-change" name="tick-1" />
        )}
      </div>
    );
  }
}

export default Row;
