import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import pick from 'lodash/pick';
import { Form, WeeklyCalendar, SelectGroup, Icon, ActionButton, ButtonV2, Typeahead } from 'components';
import moment from 'moment';
import './style.scss';

const OPTIONS = [
  { value: 'kid', label: 'Parent' },
  { value: 'enrollment', label: 'Lead' }
];
const loadLeads = query => {
  const requestPayload = {
    filters: {
      enrollment: { name: query }
    }
  };

  return req.enrollmentsTypeahead(requestPayload).then(result => {
    return result.enrollments.map(e => ({
      id: e.id,
      label: `${e.first_name} ${e.last_name}`
    }));
  });
};

const makeInitialState = data => {
  if (data && data.id) {
    return {
      ...pick(data, ['id', 'starts_at', 'ends_at', 'kind', 'all_day', 'title', 'description']),
      date: moment(data.starts_at).format('YYYY-MM-DD')
    };
  }

  const startTime =
    data && data.startsAt
      ? moment(data.startsAt).toDate()
      : moment()
          .startOf('hour')
          .toDate();

  return {
    title: '',
    description: '',
    date: moment(startTime).format('YYYY-MM-DD'),
    starts_at: startTime,
    ends_at: moment(startTime)
      .add(1, 'hour')
      .toDate(),
    kind: data && data.kind ? data.kind : 'kid',
    all_day: false
  };
};

function EditCalendarEvent({ data, currentUser, currentSchool, students, rooms, procareSyncEnabled, onClose }) {
  const leadsRef = useRef(null);
  const [events, setEvents] = useState([]);
  const [saving, setSaving] = useState(false);
  const [kidIds, setKidIds] = useState(data && data.kids ? data.kids.map(k => k.id) : []);
  const [selectedLeads, setSelectedLeads] = useState(
    data && data.registrations
      ? data.registrations.map(r => ({ id: r.id, label: `${r.first_name} ${r.last_name}` }))
      : []
  );
  const [sectionIds, setSectionIds] = useState((data && data.section_ids) || []);
  const [payload, setPayload] = useState(makeInitialState(data));

  const handleChangeKids = async () => {
    const result = await Actions.showModal('SelectStudents', { selected: kidIds });
    if (result) {
      setKidIds(result);
    }
  };

  const handleChangeLead = (id, _2, fullLead) => {
    if (fullLead) {
      if (!selectedLeads.find(s => s.id === id)) {
        setSelectedLeads([...selectedLeads, fullLead]);
      }

      if (leadsRef.current) {
        setTimeout(() => {
          leadsRef.current.onClear();
          leadsRef.current.onBlur(leadsRef.current);
        }, 50);
      }
    }
  };

  const handleAddSection = id => {
    if (sectionIds.indexOf(id) === -1) {
      setSectionIds([id, ...sectionIds]);
    }
  };

  const handleRemoveLead = fullLead => {
    setSelectedLeads(selectedLeads.filter(s => s.id !== fullLead.id));
  };

  const handleRemoveStudent = fullStudent => {
    setKidIds(kidIds.filter(s => s !== fullStudent.id));
  };

  const handleRemoveRoom = fullRoom => {
    setSectionIds(sectionIds.filter(s => s !== fullRoom.id));
  };

  const checkKind = kind => {
    if (kind === 'enrollment' && !Helpers.checkFeatureAccess('lead-crm-premium')) {
      Helpers.showPremiumModal(true, 'center');
      return false;
    }

    return true;
  };

  const handleChange = values => {
    const date = moment(values.date).format('YYYY-MM-DD');
    setPayload({
      ...payload,
      ...pick(values, ['kind', 'all_day', 'title']),
      date,
      starts_at: moment(`${date} ${values.start_time}`, 'YYYY-MM-DD H:mm A').toDate(),
      ends_at: moment(`${date} ${values.end_time}`, 'YYYY-MM-DD H:mm A').toDate()
    });
  };

  const handleSubmit = async values => {
    const date = moment(values.date).format('YYYY-MM-DD');
    const kind = values.kind || (data ? data.kind : 'kid');

    const sender = values.sender || `${currentUser.name} <${currentUser.email}>`;

    const requestPayload = {
      id: payload.id || undefined,
      event: {
        title: values.title,
        sender,
        kind,
        all_day: values.all_day,
        description: values.description,
        ...(values.all_day
          ? {
              starts_at: moment(values.date)
                .startOf('day')
                .format('YYYY-MM-DD H:mm A')
            }
          : {
              starts_at: `${date} ${values.start_time}`,
              ends_at: `${date} ${values.end_time}`
            }),
        ...(kind === 'kid'
          ? {
              section_ids: sectionIds,
              kid_ids: kidIds
            }
          : {
              registration_ids: selectedLeads.map(s => s.id),
              section_ids: []
            })
      }
    };

    try {
      setSaving(true);
      let resp;
      if (payload.id) {
        resp = await req.updateSchoolEvent(requestPayload);
      } else {
        resp = await req.addSchoolEvent(requestPayload);
      }
      Actions.showFlash('Event has been saved');
      onClose(resp);
    } catch (e) {
      Actions.reportError('There was problem creating event', e);
      setSaving(false);
    }
  };

  useEffect(() => {
    const load = async () => {
      try {
        setEvents([]);
        const resp = await req.loadSchoolEvents({
          filters: {
            event: {
              date_from: moment(payload.date).format('YYYY-MM-DD'),
              date_to: moment(payload.date).format('YYYY-MM-DD')
            }
          }
        });
        setEvents(resp.filter(r => r.id !== payload.id).map(r => ({ ...r, inactive: true })));
      } catch (e) {
        // do nothing
      }
    };

    load();
  }, [payload.date]);

  useEffect(() => {
    if (data && data.isQuickAction) {
      req.students();
      req.rooms();
    }
  }, []);

  const currentEvent = {
    id: 'current',
    inactive: false,
    ...payload,
    title: payload.title || 'New Event'
  };

  const allDayEvents = events.filter(e => e.all_day);
  const normalEvents = events.filter(e => !e.all_day);

  if (currentEvent.all_day) {
    allDayEvents.unshift(currentEvent);
  } else {
    normalEvents.unshift(currentEvent);
  }

  return (
    <div className="edit-event">
      <div className={`modal__header modal__header--bordered edit-event__header--${payload.kind}`}>
        <div className="modal__header-title">{payload.id ? 'Edit Event' : 'New Event'}</div>
        <ActionButton
          iconName="close"
          onClick={onClose}
          size={16}
          className="edit-event__close"
          data-cy="modal-close"
        />
      </div>

      <div className="modal__container">
        <Form isLoading={saving} onSubmit={handleSubmit} onChange={handleChange}>
          <div className="edit-event__body">
            <div className="edit-event__form">
              {(!data || !data.kind) && !procareSyncEnabled && (
                <div className="form__row">
                  <div className="form-input">
                    <label className="form__label">Event Type</label>
                    <div className="edit-event__form-kind">
                      <Form.RadioGroup
                        className="edit-event__form-kind"
                        beforeChange={checkKind}
                        name="kind"
                        type="circle"
                        options={OPTIONS}
                        defaultValue={payload.kind}
                      />
                      {!Helpers.checkFeatureAccess('lead-crm-premium') && !procareSyncEnabled && (
                        <Icon name="star" size={18} color="#b079c4" />
                      )}
                    </div>
                  </div>
                </div>
              )}

              {currentSchool.sender_email && (
                <div className="form__row">
                  <Form.Select
                    name="sender"
                    label="Sender"
                    defaultValue={`${currentSchool.name} <${currentSchool.sender_email}>`}
                  >
                    <Form.Select.Item
                      label={[currentSchool.name, currentSchool.sender_email].join(' - ')}
                      value={`${currentSchool.name} <${currentSchool.sender_email}>`}
                      key={currentSchool.sender_email}
                    />
                    <Form.Select.Item
                      selected={true}
                      label={[currentUser.name, currentUser.email].join(' - ')}
                      value={`${currentUser.name} <${currentUser.email}>`}
                      key={currentUser.email}
                    />
                  </Form.Select>
                </div>
              )}

              <div className="form__row">
                <Form.Input data-cy="title" name="title" label="Event Name" defaultValue={payload.title} required />
              </div>

              <div className="edit-event__subtitle">Invite Attendees</div>
              {payload.kind === 'enrollment' && (
                <div className="form__row">
                  <div className="form-input">
                    <Typeahead
                      ref={leadsRef}
                      placeholder="Search and add leads"
                      resetOnBlur
                      loadOptions={loadLeads}
                      minCharacters={3}
                      onChange={handleChangeLead}
                      value={null}
                      hideClear
                    />

                    <div className="edit-event__attendees">
                      {selectedLeads.map(s => (
                        <div key={s.id} className="edit-event__attendees-item" onClick={() => handleRemoveLead(s)}>
                          {s.label}
                          <ActionButton size={10} iconName="close" className="edit-event__attendees-item__close" />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {payload.kind === 'kid' && (
                <Fragment>
                  <div className="form__row form__row--justified">
                    <div className="form-input">
                      <SelectGroup
                        type="radio"
                        name="section_ids"
                        title="Choose Entire Room"
                        onChange={handleAddSection}
                      >
                        {rooms.map(room => (
                          <SelectGroup.Item key={room.id} value={room.id} label={room.name} />
                        ))}
                      </SelectGroup>
                    </div>
                    <div className="edit-event__kidbutton-container">
                      <ButtonV2
                        secondary
                        label="Add Students"
                        onClick={handleChangeKids}
                        className="edit-event__kidbutton"
                      />
                    </div>
                  </div>
                  <div className="edit-event__attendees edit-event__attendees--kids">
                    {rooms
                      .filter(s => sectionIds.includes(s.id))
                      .map(s => (
                        <div key={s.id} className="edit-event__attendees-item" onClick={() => handleRemoveRoom(s)}>
                          <ActionButton size={12} iconName="room" className="edit-event__attendees-item__room" />
                          <span>{s.name}</span>
                          <ActionButton size={10} iconName="close" className="edit-event__attendees-item__close" />
                        </div>
                      ))}
                    {students
                      .filter(s => kidIds.includes(s.id))
                      .map(s => (
                        <div key={s.id} className="edit-event__attendees-item" onClick={() => handleRemoveStudent(s)}>
                          {s.name}
                          <ActionButton size={10} iconName="close" className="edit-event__attendees-item__close" />
                        </div>
                      ))}
                  </div>
                </Fragment>
              )}

              <div className="form__row">
                <Form.DatePicker label="Date" name="date" defaultValue={moment(payload.starts_at).toDate()} required />
                <Form.TimeInput
                  label="Start Time"
                  name="start_time"
                  defaultValue={Helpers.formatTime(payload.starts_at)}
                  required={!payload.all_day}
                  disabled={payload.all_day}
                />
                <Form.TimeInput
                  label="End Time"
                  name="end_time"
                  isAfter="start_time"
                  defaultValue={Helpers.formatTime(payload.ends_at)}
                  required={!payload.all_day}
                  disabled={payload.all_day}
                />
              </div>

              <Form.Checkbox
                className="edit-event__form-allday"
                label="ALL DAY"
                name="all_day"
                defaultValue={payload.all_day}
              />

              <div className="form__row">
                <Form.Textarea
                  name="description"
                  label="Event Details"
                  defaultValue={payload.description}
                  className="edit-event__form-description"
                />
              </div>
            </div>

            <div className="edit-event__calendar-wrapper">
              <div className="edit-event__calendar-label">{moment(payload.starts_at).format('MMM D, YYYY')}</div>
              <div className="edit-event__calendar">
                <div className="weekly-calendar__alldays-item">
                  {allDayEvents.map(i => (
                    <WeeklyCalendar.AllDayEventItem key={i.id} event={i} active={false} />
                  ))}
                </div>
                <div className="edit-event__calendar-weekday">
                  <WeeklyCalendar.HourLegend />
                  <WeeklyCalendar.Weekday date={moment(payload.starts_at)} events={normalEvents} />
                </div>
              </div>
            </div>
          </div>

          <div className="modal__controls">
            <Form.Submit label="Save & Send" data-cy="save" />
          </div>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  students: state.students.data,
  procareSyncEnabled: state.currentUser.data.current_school.pe_enabled,
  currentSchool: state.currentUser.data.current_school,
  currentUser: state.currentUser.data
});

export default connect(mapStateToProps)(EditCalendarEvent);
