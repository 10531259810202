import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form } from 'components';
import './style.scss';

class EditKidClockIn extends Component {
  static propTypes = {
    students: PropTypes.array,
    rooms: PropTypes.array,
    staff: PropTypes.array,
    onClose: PropTypes.func,
    isLoading: PropTypes.bool,
    data: PropTypes.shape({
      student: PropTypes.object,
      sisoData: PropTypes.object
    })
  };

  static defaultProps = {
    data: {}
  };

  constructor() {
    super();

    this.state = {
      formValues: {},
      formErrors: {},
      isLoading: false
    };
  }

  @bind
  updateFormValues(values) {
    this.setState({ formValues: values });
  }

  @bind
  submit(values) {
    const { data, staff } = this.props;
    const date = moment(values.date).format('YYYY-MM-DD');

    const signedInStaff = staff.find(s => s.id === values.signed_in_by);
    const signedOutStaff = staff.find(s => s.id === values.signed_out_by);

    const requestPayload = {
      id: data.sisoData.id,

      siso_kid: {
        section_id: values.section_id,
        signed_in_by:
          values.signed_in_by === 'other' ? values.signed_in_by_other : signedInStaff ? signedInStaff.name : undefined,
        signed_out_by:
          values.signed_out_by === 'other'
            ? values.signed_out_by_other
            : signedOutStaff
            ? signedOutStaff.name
            : undefined,
        signed_in_by_id: signedInStaff ? signedInStaff.id : undefined,
        signed_out_by_id: signedOutStaff ? signedOutStaff.id : undefined,
        signed_in_by_type: values.signed_in_by === 'other' ? undefined : 'Teacher',
        signed_out_by_type: values.signed_out_by === 'other' ? undefined : 'Teacher',
        sign_in_time: `${date} ${values.sign_in_time}`,
        sign_out_time: values.sign_out_time ? `${date} ${values.sign_out_time}` : undefined
      }
    };

    this.setState({ isLoading: true });

    req
      .editKidClockIn(requestPayload)
      .then(() => {
        this.setState({ isLoading: false });
        this.props.onClose(true);
      })
      .catch(err => this.setState({ formErrors: err.response.data.form_errors, isLoading: false }));
  }

  render() {
    const { data, staff, students, rooms, isLoading } = this.props;
    const { formValues, formErrors } = this.state;
    const isSignedInByOther =
      data.sisoData.signed_in_by_type === 'Carer' || !staff.some(s => s.name === data.sisoData.signed_in_by);
    const isSignedOutByOther =
      Boolean(data.sisoData.signed_out_by) &&
      (data.sisoData.signed_out_by_type === 'Carer' || !staff.some(s => s.name === data.sisoData.signed_out_by));

    return (
      <div>
        <div className="modal__header">
          <span className="modal__header-title">Edit Sign In-Out</span>
        </div>

        <div className="modal__container">
          <Form
            validateOn="submit"
            onInit={this.updateFormValues}
            onChange={this.updateFormValues}
            onSubmit={this.submit}
            errors={formErrors}
            isLoading={this.state.isLoading}
            className="edit-sign-in"
          >
            <div className="form__row form__row--justified">
              <Form.Select
                label="Student Name"
                name="kid_id"
                title="Select Student"
                defaultValue={(data.student && data.student.id) || null}
                disabled
                required
              >
                {students.map(student => (
                  <Form.Select.Item key={student.id} label={student.name} value={student.id} />
                ))}
              </Form.Select>
              <Form.Select
                label="Room"
                name="section_id"
                title="Select Room"
                defaultValue={data.sisoData.section_id}
                required
              >
                {rooms.map(room => (
                  <Form.Select.Item key={room.id} value={room.id} label={room.name} />
                ))}
              </Form.Select>
            </div>

            <div className="form__row form__row--justified">
              <Form.Select
                label="Signed-In by"
                name="signed_in_by"
                title="Select Staff Name"
                defaultValue={
                  data.sisoData.signed_in_by ? (isSignedInByOther ? 'other' : data.sisoData.signed_in_by_id) : undefined
                }
                required
              >
                {staff.map((s, index) => (
                  <Form.Select.Item key={`${s.name}_${index}`} value={s.id} label={s.name} />
                ))}
                <Form.Select.Item value="other" label="Other" className="select-group__item--accent" />
              </Form.Select>
              <Form.Select
                label="Signed-Out by"
                name="signed_out_by"
                title="Select Staff Name"
                defaultValue={
                  data.sisoData.signed_out_by
                    ? isSignedOutByOther
                      ? 'other'
                      : data.sisoData.signed_out_by_id
                    : undefined
                }
                required={formValues.sign_out_time || data.sisoData.signed_out_by}
                enableClear
              >
                {staff.map((s, index) => (
                  <Form.Select.Item key={`${s.name}_${index}`} value={s.id} label={s.name} />
                ))}
                <Form.Select.Item value="other" label="Other" className="select-group__item--accent" />
              </Form.Select>
            </div>

            <div className="form__row form__row--justified">
              <Form.Input
                name="signed_in_by_other"
                placeholder="Signed-In By Name"
                defaultValue={!isSignedInByOther ? '' : data.sisoData.signed_in_by}
                hidden={!isSignedInByOther && formValues.signed_in_by !== 'other'}
                required
              />

              <Form.Input
                name="signed_out_by_other"
                placeholder="Signed-Out By Name"
                defaultValue={!isSignedOutByOther ? '' : data.sisoData.signed_out_by}
                hidden={!isSignedOutByOther && formValues.signed_out_by !== 'other'}
                required
              />
            </div>

            <div className="form__row">
              <Form.DatePicker
                label="Date"
                name="date"
                disabled
                defaultValue={moment(data.sisoData.sign_in_time).toDate()}
              />
            </div>

            <div className="form__row form__row--justified">
              <Form.TimeInput
                label="Signed-In Time"
                name="sign_in_time"
                placeholder="12:00"
                defaultValue={data.sisoData.sign_in_time ? Helpers.formatTime(data.sisoData.sign_in_time) : new Date()}
                required
                autoPeriod
              />
              <Form.TimeInput
                label="Signed-Out Time"
                name="sign_out_time"
                placeholder="12:00"
                required={formValues.signed_out_by}
                defaultValue={data.sisoData.sign_out_time ? Helpers.formatTime(data.sisoData.sign_out_time) : undefined}
                isAfter="sign_in_time"
                autoPeriod
              />
            </div>

            <div className="form__row form__row--actions">
              <Form.Submit data-cy="save" label="Save changes" disabled={isLoading} />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  students: state.students.data,
  staff: state.staff.data,
  rooms: state.rooms.data,
  isLoading: state.sisoKidsDaily.loading || state.sisoKidsSignins.loading || state.editKidClockIn.loading
});

export default connect(mapStateToProps)(EditKidClockIn);
