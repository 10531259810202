import React, { Component } from 'react';
import { connect } from 'react-redux';
import Steps from 'modals/ModalController/Steps';
import { getActivateSchoolBillingMethod } from 'lib/utils';
import ConfirmSchoolInfoStep from './ConfirmSchoolInfoStep';
import FeePayerStep from './FeePayerStep';
// import BasicInfoStep from './BasicInfoStep';
import TransactionFeeStep from './TransactionFeeStep';
import BankInfoStep from './BankInfoStep';
import ThankyouStep from './ThankyouStep';
import TuitionExpressStep from './TuitionExpressStep';
import './style.scss';

class ActivateSchool extends Component {
  constructor(props) {
    super(props);

    const { data, currentSchool } = props;
    const billingMethod = getActivateSchoolBillingMethod(currentSchool); // te or stripe
    let startStep = 0;

    if (data && data.fromUpdate) {
      startStep = 3;
    } else if (data && data.fromReg) {
      startStep = billingMethod === 'stripe' ? 3 : 0;
    } else if (billingMethod === 'stripe') {
      startStep = 1;
    }

    this.state = {
      school_name: currentSchool.name || '',
      country: 'US',
      account_holder_type: 'company',
      payment_gateway: billingMethod,
      startStep,
      parent_payment_fee_paid_by:
        props.data && props.data.fromUpdate
          ? currentSchool.parent_payment_fee_paid_by
          : 'parent_payment_fee_paid_by_parent' // props.currentSchool.parent_payment_fee_paid_by
    };
  }

  componentDidMount() {
    req.schoolFeeBreakdown();
    this.checkFreeSchool();
  }

  @bind
  checkFreeSchool() {
    const { onClose } = this.props;
    if (!Helpers.checkFeatureAccess('billing', false)) {
      Helpers.showPremiumModal(true);
      onClose();
      return false;
    }

    return true;
  }

  @bind
  updateData(data) {
    this.setState({ ...data });
  }

  render() {
    const { data, currentSchool, onClose } = this.props;
    const { startStep } = this.state;
    const title = data && data.fromUpdate ? 'Update your Billing Account' : 'Setup your Billing Account';
    const byParentDisabled = currentSchool.parent_payment_fee_paid_by === 'parent_payment_fee_paid_by_school';

    return (
      <div className="school-activate">
        <Steps startStep={startStep}>
          <Steps.Item>
            <ConfirmSchoolInfoStep
              checkFreeSchool={this.checkFreeSchool}
              title={title}
              data={this.state}
              onUpdate={this.updateData}
            />
          </Steps.Item>

          <Steps.Item name="fee-payer">
            <FeePayerStep
              title={title}
              data={this.state}
              onUpdate={this.updateData}
              checkFreeSchool={this.checkFreeSchool}
              byParentDisabled={byParentDisabled}
            />
          </Steps.Item>

          <Steps.Item name="transaction-fee">
            <TransactionFeeStep title={title} data={this.state} onUpdate={this.updateData} />
          </Steps.Item>

          {/* <Steps.Item name="basic-info">
            <BasicInfoStep title={title} data={this.state} onUpdate={this.updateData} fromReg={data && data.fromReg} />
          </Steps.Item> */}

          <Steps.Item name="basic-info">
            <BankInfoStep
              title={title}
              data={this.state}
              onUpdate={this.updateData}
              fromReg={data && data.fromReg}
              onClose={onClose}
            />
          </Steps.Item>

          <Steps.Item name="thankyou">
            <ThankyouStep data={this.state} showTick={this.state.payment_gateway === 'te'} />
          </Steps.Item>

          <Steps.Item hidden name="te">
            <TuitionExpressStep data={this.state} onClose={onClose} />
          </Steps.Item>
        </Steps>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentSchool: state.currentUser.data.current_school
});

export default connect(mapStateToProps)(ActivateSchool);
