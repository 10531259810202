import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { applyFilters, convertSessionsToSchedule, isScheduleCompatibleWithSessions } from 'lib/utils';
import { Avatar, ScheduleLarge, SelectGroup, Placeholder, ButtonV2 } from 'components';
import SelectPersonList from 'modals/common/SelectPersonList';
import PersonFilter from './PersonFilter';
import PersonCard from './PersonCard';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/orderBy';
import './style.scss';

const AssignProgramStudentsModal = ({ data, onClose }) => {
  const { onSubmit } = data;

  useEffect(() => {
    req.students({
      // Filter is temporarily being used on the backend to bypass problematic caching
      filters: { kid: { include_program: true } },
      include_programs: true,
      enabled_programs: true
    });
  }, []);

  const isLoading = useSelector(state => state.students.loading);
  const students = useSelector(state => state.students.data);
  const program = useSelector(state => state.program.data);
  const [filters, setFilters] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [roomId, setRoomId] = useState(program.sections.length === 1 ? program.sections[0].id : null);

  // default selection is students who are assigned to this program but not to any program room
  useEffect(() => {
    if (!isLoading) {
      const assignedStudentIds = students
        .filter(
          student =>
            student.programs?.find(p => p.id === program.id) &&
            student.program_sections?.find(ps => ps.program_id === program.id)?.section_id === (roomId || undefined)
        )
        .map(student => student.id);
      setSelectedIds(assignedStudentIds);
    }
  }, [students, roomId]);

  const updateFilters = filters => {
    setFilters(filters);
  };

  // sort students first by whether they're in the program, then by name
  const filteredStudents = React.useMemo(() => {
    if (!isLoading) {
      const assignedStudentIds = program.kids?.map(k => k.id) ?? [];
      const isAssigned = person => (assignedStudentIds.includes(person.id) ? 1 : -1);
      return applyFilters(students, filters).sort(
        (a, b) => isAssigned(b) - isAssigned(a) || a.name.localeCompare(b.name)
      );
    }
  }, [students, filters]);

  const submit = async () => {
    setIsSaving(true);
    try {
      await onSubmit({ roomId, studentIds: selectedIds });
      onClose(true);
    } finally {
      setIsSaving(false);
    }
  };

  const roomMap = keyBy(program.sections, 'id');

  const renderPersonCard = ({ person, type, onSelect, selected, validationError, disableInvalid }) => {
    const isEnrolled = person.programs?.find(p => p.id === program.id) !== undefined;
    const roomId = person.program_sections?.find(ps => ps.program_id === program.id)?.section_id;
    const roomName = roomId && roomMap[roomId] ? roomMap[roomId].name : 'Not Assigned';

    return (
      <PersonCard
        key={person.id}
        id={person.id}
        name={person.name}
        roomName={isEnrolled ? roomName : undefined}
        color={person.color}
        type={type}
        status={person.is_admin ? 'admin' : undefined}
        picURL={person.profile_pic_url}
        onSelect={onSelect}
        selected={selected}
        validationError={validationError}
        disableInvalid={disableInvalid}
      />
    );
  };

  const schedule = program.program_sessions ? convertSessionsToSchedule(program.program_sessions) : {};

  const validations = {
    'Incompatible Schedule': p => !isScheduleCompatibleWithSessions(p.time_schedule, program.program_sessions)
  };

  if (isLoading) {
    return <Placeholder.NoResult className="show-invoice" isLoading={isLoading} />;
  }

  return (
    <div className="assign-program-students select-document-recipients__students">
      <div className="modal__header modal__header--bordered">
        <div className="modal__header-title">Enroll Students</div>
        <div className="assign-program-students__header">
          <div>
            <div className="assign-program-students__header-label">Program</div>
            <div className="assign-program-students__header-program">
              <Avatar className="room__avatar avatar--small" name={program.name} />
              <span className="assign-program-students__name">{program.name}</span>
            </div>
          </div>
          <div>
            <div className="assign-program-students__header-label">Program room</div>
            <div className="assign-program-students__header-rooms">
              {program.sections.length <= 1 ? (
                <React.Fragment>
                  {program.sections.length == 1 && (
                    <Avatar className="room__avatar avatar--small" name={program.sections[0].name} />
                  )}
                  <span className="assign-program-students__name">
                    {program.sections.length == 1 ? program.sections[0].name : 'Not Assigned'}
                  </span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <SelectGroup
                    name={'room'}
                    type="radio"
                    title="Select Room"
                    checked={roomId}
                    onChange={val => setRoomId(val)}
                    onClear={() => setRoomId(null)}
                  >
                    {sortBy(program.sections, 'name').map(section => (
                      <SelectGroup.Item key={section.id} value={section.id} label={section.name} />
                    ))}
                  </SelectGroup>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="mr-32">
            <div className="assign-program-students__header-label">Program schedule</div>
            <div className="assign-program-students__header-schedule">
              <ScheduleLarge timeSchedule={schedule} />
            </div>
          </div>
        </div>

        <div className="select-document-recipients__students-filter">
          <div className="assign-program-students__filter-label">
            Select students that will be enrolled in this program and program room.
          </div>
          <PersonFilter filters={filters} onChange={updateFilters} />
        </div>
      </div>

      <div className="modal__container">
        <SelectPersonList
          persons={filteredStudents}
          allPersons={filteredStudents}
          selectedIds={selectedIds}
          onSelect={selectedIds => setSelectedIds(selectedIds)}
          listHeight={275}
          type={data.type}
          disableInvalid={true}
          validations={validations}
          renderPersonCard={renderPersonCard}
        />
      </div>

      <div className="modal__controls">
        <ButtonV2 label="Cancel" secondary onClick={onClose} />
        <ButtonV2 label="Enroll" onClick={submit} isLoading={isSaving} data-cy="continue" />
      </div>
    </div>
  );
};

export default AssignProgramStudentsModal;
