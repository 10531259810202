import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'components';

function BooleanEditor({ onChange, defaultValue, type = 'slider', disabled }) {
  const [value, setValue] = useState(defaultValue);

  const handleChange = newValue => {
    setValue(newValue);
    return onChange(newValue);
  };

  return <Checkbox type={type} onChange={handleChange} checked={value} disabled={disabled} />;
}

BooleanEditor.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.bool,
  type: PropTypes.string
};

export default BooleanEditor;
