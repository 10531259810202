import React, { Component } from 'react';
import { Icon } from 'components';
import './style.scss';

class Header extends Component {
  render() {
    const { title, children, className, icon, iconSize } = this.props;

    return (
      <div className={`header ${className || ''}`}>
        <div className="header__inner">
          {icon && (
            <div className="header__inner--icon">
              <Icon name={icon} size={iconSize || 18} />
            </div>
          )}
          {title}
        </div>

        <div className="header__actions">{children}</div>
      </div>
    );
  }
}

export default Header;
