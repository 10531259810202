import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';
import FormInput from '../Input';
import FormSelect from '../Select';
import FormDatePicker from '../DatePicker';
import FormCheckbox from '../Checkbox';

function FormCustomAttribute({ attr, ...props }) {
  const baseProps = {
    ...props,
    name: props.name || attr.id,
    label: attr.name,
    placeholder: attr.description
  };
  const options = get(attr, 'data.options', []);

  switch (attr.field_type) {
    case 'date':
      return (
        <FormDatePicker
          {...baseProps}
          defaultValue={props.defaultValue ? moment(props.defaultValue).toDate() : undefined}
        />
      );
    case 'string':
      return <FormInput {...baseProps} />;
    case 'decimal':
      return <FormInput type="number" {...baseProps} />;
    case 'boolean':
      return <FormCheckbox {...baseProps} />;
    case 'integer':
      return <FormInput mask="9999999999999" {...baseProps} />;
    case 'list':
      return (
        <FormSelect type="radio" {...baseProps} defaultValue={props.defaultValue ? props.defaultValue[0] : undefined}>
          {options.map(r => (
            <FormSelect.Item key={r.id} value={r.id} label={r.name} />
          ))}
        </FormSelect>
      );
    default:
      throw new Error('Unknown custom field');
  }
}

FormCustomAttribute.propTypes = {
  attr: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    field_type: PropTypes.string,
    data: PropTypes.any
  })
};

export default FormCustomAttribute;
