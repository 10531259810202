import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ButtonV2, TextInput } from 'components';
import { validateEmail } from 'lib/utils';
import { getCurrentSchool } from 'lib/utils/selectors';

import './style.scss';

class SenderEmailSettingForm extends Component {
  static propTypes = {
    currentSchool: PropTypes.object
  };

  constructor(...args) {
    super(...args);
    this.state = {
      senderEmail: this.props.currentSchool.sender_email
    };
  }

  @bind
  inputChanged(value) {
    this.setState({ senderEmail: value });
  }

  isEmail(value) {
    return value && validateEmail(value);
  }

  @bind
  saveSetting() {
    if (!this.isEmail(this.state.senderEmail)) {
      Actions.reportError('Invalid email');
      return;
    }

    const payload = {
      school: {
        sender_email: this.state.senderEmail
      }
    };

    req
      .updateSchool(payload)
      .then(() => req.profile())
      .then(() => {
        Actions.showFlash('Settings updated successfully');
        this.props.onClose();
      })
      .catch(error => {
        Actions.reportError('Error saving email', error);
      });
  }

  render() {
    return (
      <div className="modal__wrapper email-setting">
        <div className="modal__header email-setting__header">
          <div className="subheader">School Email</div>
          <span className="modal__header-note">
            Change the email used for school communication in leads, newsletters and event invites.
          </span>
        </div>

        <div className="modal__container email-setting__content">
          <label>EMAIL</label>
          <TextInput value={this.state.senderEmail} onChange={this.inputChanged} className="email-setting__input" />
          <div className="email-setting__warning">
            IMPORTANT: Emails that have been set for reoccuring or past messages such as Lead Automation and scheduled
            Newsletters will not be updated. You can still update the email for these manually.
          </div>
        </div>

        <div className="modal__controls">
          <ButtonV2 onClick={this.saveSetting} label="Save" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentSchool: getCurrentSchool(state)
});

export default connect(mapStateToProps)(SenderEmailSettingForm);
