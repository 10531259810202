import React, { Fragment } from 'react';
import { Form } from 'components';

const ADMIN = 'admin';
const NON_ADMIN = 'non-admin';

function Staff() {
  return (
    <Fragment>
      <div className="form__row form__row--justified">
        <Form.Input label="Name" placeholder="Name" name="name" data-cy="name" required asterisk />
        <div className="create-person__staff-email">
          <Form.Input label="Email" placeholder="Email" name="email" />
          <Form.Checkbox
            className="create-person__send-invite"
            defaultValue={true}
            name="send_invite"
            label="Invite to Procare"
          />
        </div>
      </div>
      <div className="form__row  form__row--justified">
        <Form.Select label="Role" name="is_admin" defaultValue={NON_ADMIN}>
          <Form.Select.Item value={ADMIN} label={'Admin'} />
          <Form.Select.Item value={NON_ADMIN} label={'Non-Admin'} />
        </Form.Select>
      </div>
    </Fragment>
  );
}

Staff.ADMIN = ADMIN;

export default Staff;
