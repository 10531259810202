import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Form, SelectGroup } from 'components';
import { RECURRING_ACTIVITIES } from 'constants/activityTypes';
import { getActivityIconPath } from 'lib/utils';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class ActivityInfoStep extends Component {
  static propTypes = {
    FormComponent: PropTypes.func,
    constants: PropTypes.object,
    activity: PropTypes.object,
    onSave: PropTypes.func,
    onNameChange: PropTypes.func,
    onTypeChange: PropTypes.func
  };

  static defaultProps = {};

  @bind
  saveActivity(values) {
    if (this.props.onSave) {
      this.props.onSave(values);
      this.props.context.nextStep();
    }
  }

  @bind
  updateActivityType(type) {
    if (this.props.onTypeChange) {
      this.props.onTypeChange(type);
    }
  }

  @bind
  updateActivityName(value) {
    this.props.onNameChange(value);
  }

  render() {
    const { type, FormComponent, constants, activity, onSave, title, forceStaff } = this.props;
    const { currentStep, totalSteps } = this.props.context;
    return (
      <div className="create-recurring-activity__new-activity">
        <div className="modal__header create-recurring-activity__header">
          <div className="create-recurring-activity__header-title">
            <span className="modal__header-title">{title}</span>

            <div className="modal__header-steps">
              <span>Step {currentStep}</span> / {totalSteps}
            </div>
          </div>
          <div className="modal__header-subtitle">
            Automatically create activities and notify parents on fixed schedule and time during the day.
          </div>
        </div>

        <div className="create-recurring-activity__type">
          <div className="create-recurring-activity__section-header">Provide details about activity</div>

          <div className="form__row form__row--justified">
            <div className="form-input">
              <label className="form__label" htmlFor="name">
                Enter Title
              </label>
              <div className="form-input__field">
                <TextInput
                  name="name"
                  placeholder="eg: Monday - meals"
                  value={activity.name}
                  onChange={this.updateActivityName}
                />
              </div>
            </div>

            <div className="form-select">
              <label className="form__label" htmlFor="type">
                Choose Activity Type
              </label>

              <SelectGroup
                name="type"
                type="radio"
                title="Activity Type"
                checked={type}
                className="form-select__field"
                onChange={this.updateActivityType}
              >
                {RECURRING_ACTIVITIES.map(a => (
                  <SelectGroup.Item key={a.id} value={a.id} label={a.label} icon={getActivityIconPath(a.id)} />
                ))}
              </SelectGroup>
            </div>
          </div>
        </div>

        <Form className="create-recurring-activity__form" key={type} onSubmit={this.saveActivity} onChange={onSave}>
          <FormComponent constants={constants} activity={activity} useDate={false} forceStaff={forceStaff} />

          <div className="form__row--actions create-recurring-activity__actions">
            <Form.Submit label="Continue" disabled={!activity.name} />
          </div>
        </Form>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(ActivityInfoStep);
