import React from 'react';
import { Avatar, ButtonV2 } from 'components';
import './style.scss';
import classNames from 'classnames';

function ProgramRoomFailure({ onClose, data }) {
  const { program, badSectionIds } = data;

  const renderAvatarContainer = (name, id) => {
    const cn = classNames('program-room-failure__avatar-container-name', {
      'program-room-failure__avatar-container-name--alert': badSectionIds.includes(id)
    });

    return (
      <div className="program-room-failure__avatar-container" key={id}>
        <Avatar className="room__avatar avatar--small" name={name} />
        <span className={cn}>{name}</span>
      </div>
    );
  };

  return (
    <div className="modal__wrapper program-room-failure">
      <div className="modal__header">
        <div className="subheader">Remove Program Room</div>
        <div className="program-room-failure__list-container">
          <div className="program-room-failure__list">
            <div className="program-room-failure__list-header">Program</div>
            {renderAvatarContainer(program.name)}
          </div>
          <div className="program-room-failure__list">
            <div className="program-room-failure__list-header">Program Room</div>
            {program.sections.map(section => renderAvatarContainer(section.name, section.id))}
          </div>
        </div>
        <div className="program-room-failure__description">
          <p>This program room cannot be removed as it has active staff and students assigned to it.</p>
          <p>Please reassign staff and students to another room or not assigned before deleting this program room.</p>
        </div>
      </div>

      <div className="modal__controls">
        <ButtonV2 onClick={() => onClose()} label="Ok" />
      </div>
    </div>
  );
}

export default ProgramRoomFailure;
