import React from 'react';
import moment from 'moment';

const TransactionRow = ({ data }) => {
  const { date, mode, sub_mode, amount, kind } = data;

  const caps = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const paymentType = () => {
    switch (true) {
      case kind.includes('refund'):
        return 'Refund:';
      case kind.includes('payment'):
        return 'Payment:';
    }
  };

  const paymentSubType = () => {
    switch (mode) {
      case 'bank_account':
        return ' ACH Bank';
      case 'card':
        return ` ${caps(sub_mode)} ${caps(mode)}`;
    }
  };

  const formattedDate = moment(date).format('MMMM DD, YYYY');

  return (
    <div className="table__row">
      <div className="table__cell table__cell--xs">{formattedDate}</div>
      <div className="table__cell table__cell--xs">
        <span>
          <strong>{paymentType()}</strong>
          {paymentSubType()}
        </span>
      </div>
      <div className="table__cell table__cell--xs">
        <span>
          <strong>${amount}</strong>
        </span>
      </div>
      <div className="table__cell table__cell--xs">
        <strong>In Process</strong>
      </div>
    </div>
  );
};

export default TransactionRow;
