import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'components';

const FilterItemLessonCategory = ({ lessonCategories = [], name, defaultValue, label = 'Lesson Category' }) => (
  <div className="form__row">
    <Form.Select
      name={name}
      title={label}
      type="radio"
      label={label}
      defaultValue={defaultValue}
      enableClear
      data-cy={name}
    >
      <Form.Select.Item value="" label="All Lesson Categories" isDefault />
      {lessonCategories.map(category => (
        <Form.Select.Item key={category.id} value={category.id} label={category.name} />
      ))}
    </Form.Select>
  </div>
);

FilterItemLessonCategory.propTypes = {
  label: PropTypes.string,
  lessonCategories: PropTypes.array,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string
};

const mapStateToProps = state => ({
  lessonCategories: state.lessonCategories.data
});

export default connect(mapStateToProps, null, null, { pure: false })(FilterItemLessonCategory);
