import React, { Component } from 'react';
import { Form } from 'components';

class Datepicker extends Component {
  render() {
    const { id, label, className } = this.props;

    return <Form.DatePicker name={id} label={label} className={className} />;
  }
}

export default Datepicker;
