import React from 'react';
import { TooltipTrigger, ButtonV2 } from 'components';
import './style.scss';

const CorporateManagedIcon = ({ ...props }) => {
  return (
    <TooltipTrigger className="corporate-managed-icon" side="center" tooltip="Corporate Managed" {...props}>
      <ButtonV2 icon iconName="corp-setting" iconSize={16} />
    </TooltipTrigger>
  );
};

export default CorporateManagedIcon;
