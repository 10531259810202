import React, { Component } from 'react';
import PhotoThumb from '../PhotoThumb';

export default class AbsentActivity extends Component {
  render() {
    const { activity } = this.props;
    return activity?.activiable ? (
      <div className="activity__absent">
        <div className="activity__paragraph">{activity.activiable.note}</div>
        <PhotoThumb photoUrl={activity.photo_url} />
      </div>
    ) : (
      <div>No activity data</div>
    );
  }
}
