import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ContextMenu, ContextMenuItem } from 'components';
import FormFieldType from 'constants/FormFieldType';

class NewFieldDropdown extends Component {
  static propTypes = {
    onSelect: PropTypes.func
  };

  static defaultProps = {};

  render() {
    const { onSelect } = this.props;

    return (
      <ContextMenu
        secondary
        raised
        iconName="add-dark"
        iconSize={18}
        side="left"
        className="form-constructor__new-field"
        label="Add field"
        data-cy="add-field"
      >
        <ContextMenuItem value={FormFieldType.TextInput} label="Short Answer" onClick={onSelect} />
        <ContextMenuItem value={FormFieldType.Checkbox} label="Checkbox" onClick={onSelect} />
        <ContextMenuItem value={FormFieldType.Select} label="Dropdown" onClick={onSelect} />
        <ContextMenuItem value={FormFieldType.DatePicker} label="Date Picker" onClick={onSelect} />
        <ContextMenuItem value={FormFieldType.TextBox} label="Paragraph" onClick={onSelect} />
        <ContextMenuItem value={FormFieldType.Divider} label="Line Divider" onClick={onSelect} />
        <ContextMenuItem value={FormFieldType.FileUpload} label="File Upload" onClick={onSelect} />
      </ContextMenu>
    );
  }
}

export default NewFieldDropdown;
