import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { applyFilters } from 'lib/utils';
import { ButtonV2 } from 'components';
import SelectPersonList from 'modals/common/SelectPersonList';
import StaffFilter from './StaffFilter';

class SelectStaff extends Component {
  static propTypes = {
    staff: PropTypes.array,
    rooms: PropTypes.array,
    onSelect: PropTypes.func,
    isSaving: PropTypes.bool,
    selectedStaffIds: PropTypes.array
  };

  static defaultProps = {
    staff: []
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: {}
    };
  }

  @bind
  updateFilters(filters) {
    this.setState({ filters });
  }

  get staff() {
    return applyFilters(this.props.staff, this.state.filters);
  }

  render() {
    const { rooms, onSubmit, onSelect, isSaving, selectedStaffIds } = this.props;
    const { filters } = this.state;

    return (
      <div className="select-document-recipients__students">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Select Staff</div>
          <div className="select-document-recipients__students-filter">
            <StaffFilter rooms={rooms} filters={filters} onChange={this.updateFilters} />
          </div>
        </div>

        <div className="modal__container">
          <SelectPersonList
            persons={this.staff}
            allPersons={this.staff}
            selectedIds={selectedStaffIds}
            onSelect={onSelect}
            listHeight={438}
            type="teacher"
          />
        </div>

        <div className="modal__controls">
          <ButtonV2 label="Continue" onClick={onSubmit} isLoading={isSaving} data-cy="continue" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  staff: state.staff.data,
  rooms: state.rooms.data
});

export default connect(mapStateToProps)(SelectStaff);
