import React from 'react';
import { ButtonV2 } from 'components';
import './style.scss';

function SurchargeInformation({ onClose }) {
  return (
    <div className="modal__wrapper surcharge-information">
      <div className="modal__container surcharge-information__content">
        <h1>What is a surcharge</h1>
        <p>
          A credit card surcharge is an additional fee added to cover the cost of processing credit card transactions.
        </p>
        <p>
          In some cases you can avoid surcharges by using a Debit Card. Debit Cards are identifiable by the label
          "Debit" printed on either the top or bottom right corner of your card.{' '}
        </p>
        <img className="surcharge-information__debit-card" src="/assets/images/debit-card.png" />
      </div>
      <div className="surcharge-information__footer">
        <ButtonV2 onClick={onClose} secondary label="Close" />
      </div>
    </div>
  );
}

export default SurchargeInformation;
