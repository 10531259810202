import moment from 'moment';
import { getDuration } from './utils';
import history from './history';
import get from 'lodash/get';

const CHECK_EVENT_INTERVAL = 10 * 60 * 1000;
const NOTIFY_INTERVAL = 60 * 1000;
const NOTIFY_BEFORE = 15; // mins

class Notification {
  events = [];

  checkPermission() {
    if (!window.Notification) {
      return Promise.resolve(false);
    }

    try {
      return window.Notification.requestPermission()
        .then(perm => {
          return perm === 'granted';
        })
        .catch(() => false);
    } catch (e) {
      if (e instanceof TypeError) {
        return new Promise(resolve => {
          window.Notification.requestPermission(perm => {
            resolve(perm === 'granted');
          });
        });
      }

      return Promise.resolve(false);
    }
  }

  @bind
  async loadEvents(teacherId) {
    const resp = await req.loadSchoolEvents({
      filters: {
        event: {
          date_from: moment().format('YYYY-MM-DD'),
          date_to: moment().format('YYYY-MM-DD'),
          teacher_id: teacherId
        }
      }
    });

    this.events = resp.filter(r => !r.all_day);
  }

  @bind
  checkEvents() {
    const eventsToNotify = this.events.filter(e => {
      return moment().diff(e.starts_at, 'minute') === -NOTIFY_BEFORE;
    });

    eventsToNotify.forEach(event => {
      this.notify(event);
    });
  }

  @bind
  notify(event) {
    const { starts_at: startDate, ends_at: endDate } = event;
    const type = event.kind === 'student' ? 'Parent' : 'Lead';
    const title = `[${type}], ${event.title}`;
    const diff = moment(endDate).diff(startDate, 'minute');
    const hours = diff / 60;
    let duration = getDuration(hours).toLowerCase();
    const time = `${moment(startDate).format('h:mm A')} - ${moment(endDate).format('h:mm A')} (${duration})`;
    const options = {
      body: `${time}\n${event.description || ''}`,
      icon: 'https://schools.procareconnect.com/assets/images/favicons/favicon-96X96.png'
    };

    const notification = new window.Notification(title, options);
    notification.onclick = function() {
      history.push('/calendar');
    };
    return notification;
  }

  @bind
  startInterval(currentUser) {
    const teacherId = get(currentUser, 'teacher.id');

    if (!teacherId) return;

    const permissions = get(currentUser, 'mergedPermissions', {});

    if (permissions['school_calendars'] === 'none') return;

    this.loadEvents(teacherId);

    this.loadInterval = setInterval(() => {
      this.loadEvents(teacherId);
    }, CHECK_EVENT_INTERVAL);

    this.notifyInterval = setInterval(() => {
      this.checkEvents();
    }, NOTIFY_INTERVAL);
  }

  @bind
  clearInterval() {
    if (this.loadInterval) {
      clearInterval(this.loadInterval);
      this.loadInterval = null;
    }

    if (this.notifyInterval) {
      clearInterval(this.notifyInterval);
      this.notifyInterval = null;
    }
  }
}

export default new Notification();
