const DIGIT = '9';
const ALPHA = 'A';
const ALPHANUM = 'S';

export function toPattern(value, pattern) {
  const patternChars = pattern.replace(/\W/g, ''),
    output = pattern.split(''),
    values = value.toString().replace(/\W/g, ''),
    charsValues = values.replace(/\W/g, ''),
    outputLength = output.length;

  let i,
    index = 0;

  for (i = 0; i < outputLength; i++) {
    // Reached the end of input
    if (index >= values.length) {
      if (patternChars.length == charsValues.length) {
        return output.join('');
      } else {
        break;
      }
    }
    // Remaining chars in input
    else {
      if (
        (output[i] === DIGIT && values[index].match(/[0-9]/)) ||
        (output[i] === ALPHA && values[index].match(/[a-zA-Z]/)) ||
        (output[i] === ALPHANUM && values[index].match(/[0-9a-zA-Z]/))
      ) {
        output[i] = values[index++];
      } else if (output[i] === DIGIT || output[i] === ALPHA || output[i] === ALPHANUM) {
        return output.slice(0, i).join('');
      }
    }
  }
  return output.join('').substr(0, i);
}
