import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonV2 } from 'components';
import cx from 'classnames';
import Recipient from '../../common/Recipient';
import './style.scss';

// Source: src/screens/teacher/NewsletterPreview/SelectRecipientsBox/index.js
// const SelectRecipientsBox = ({ kids, staff, loading, selectedKidIds, selectedStaffIds, onChange }) => {
const SelectRecipientsBox = ({
  kids = [],
  selectedKids = [],
  staff = [],
  selectedStaff = [],
  leads = [],
  selectedLeads = [],
  loading,
  onChange,
  error
}) => {
  const [recipientType, setRecipientType] = useState();
  const [selectedKidIds, setSelectedKidIds] = useState(selectedKids);
  const [selectedStaffIds, setSelectedStaffIds] = useState(selectedStaff);
  const [selectedLeadIds, setSelectedLeadIds] = useState(selectedLeads);

  const addRecipient = async () => {
    let kidIds = [];
    let staffIds = [];
    let leadIds = [];

    const recipientToIndexMap = {
      students: 0,
      staff: 1,
      leads: 2
    };

    if (loading) return;

    try {
      const result = await Actions.showModal('SelectDocumentRecipients', {
        startStep: recipientToIndexMap[recipientType],
        kidIds: selectedKidIds,
        staffIds: selectedStaffIds,
        leadIds: selectedLeadIds
      });

      if (!result) {
        return;
      }

      setSelectedKidIds(result.kidIds);
      setSelectedStaffIds(result.staffIds);
      setSelectedLeadIds(result.leadIds);
      kidIds = result.kidIds;
      staffIds = result.staffIds;
      leadIds = result.leadIds;
    } catch (e) {
      Actions.reportError('Unable to select recipients', e);
      return;
    }

    if (!onChange) {
      return;
    }

    onChange(kidIds, staffIds, leadIds);
  };

  const renderItems = () => {
    const kidsIds = {};
    const staffIds = {};
    const leadIds = {};
    selectedKidIds.forEach(i => (kidsIds[i] = true));
    selectedStaffIds.forEach(i => (staffIds[i] = true));
    selectedLeadIds.forEach(i => (leadIds[i] = true));

    const renderedEls = [];

    kids.forEach(k => {
      if (!kidsIds[k.id]) {
        return;
      }

      renderedEls.push(<Recipient key={k.id} data={k} />);
    });

    staff.forEach(k => {
      if (!staffIds[k.id]) {
        return;
      }

      renderedEls.push(<Recipient key={k.id} data={k} suffix="Staff" />);
    });

    leads.forEach(k => {
      if (!leadIds[k.id]) {
        return;
      }

      renderedEls.push(<Recipient key={k.id} data={k} suffix="Lead" />);
    });

    return !!renderedEls.length && <div className="documents-select-recipients-box__items">{renderedEls}</div>;
  };

  return (
    <React.Fragment>
      <div className="documents-select-recipients-box" data-cy="select-recipients">
        <div className="documents-select-recipients-box__inner">
          {/* <div className="documents-select-recipients-box__title">
            Send to<span className="documents-select-recipients-box__asterisk">*</span>:
          </div> */}

          {/* <div className="documents-select-recipients-box__hint">{this.getHintText()}</div> */}
          <div className="documents-select-recipients-box__controls">
            <Form
              onChange={({ send_to }) => {
                setRecipientType(send_to);
              }}
            >
              <Form.RadioGroup
                // className="foo"
                // beforeChange={checkKind}
                data-cy="select-recipients"
                name="send_to"
                type="circle"
                options={[
                  { value: 'students', label: 'Students' },
                  { value: 'staff', label: 'Staff' },
                  { value: 'leads', label: 'Leads' }
                ]}
                defaultValue="students"
              />
            </Form>
            <ButtonV2
              secondary
              label="Add Recipient"
              className={cx('ml-24', 'documents-select-recipients-box__button', {
                'documents-select-recipients-box__button--disabled': loading
              })}
              onClick={addRecipient}
              data-cy="add-recipient"
            />
          </div>
          {renderItems()}
        </div>
      </div>
      {!!error && (
        <div className="form-input__validation-text" data-cy="select-recipients-invalid">
          {error}
        </div>
      )}
    </React.Fragment>
  );
};

SelectRecipientsBox.propTypes = {
  kids: PropTypes.array,
  staff: PropTypes.array,
  leads: PropTypes.array,
  loading: PropTypes.bool,
  onChange: PropTypes.func
};

export default SelectRecipientsBox;
