import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ManageController from 'modals/common/ManageItems/ManageController';
import ManageWindow from 'modals/common/ManageItems/ManageWindow';
import ManageList from 'modals/common/ManageItems/ManageList';
import ProgressItem from './ProgressItem';
import './style.scss';

class ManageProgress extends PureComponent {
  static propTypes = {
    milestoneProgresses: PropTypes.array
  };

  static defaultProps = {
    milestoneProgresses: []
  };

  componentDidMount() {
    req.milestoneProgresses();
  }

  @bind
  async addProgress(progress) {
    try {
      await req.addMilestoneProgress({ progress });
      Actions.showFlash('Progress has been added');
      return Promise.resolve();
    } catch (e) {
      await Actions.reportError('Unable to create progress', e);
      return Promise.reject();
    }
  }

  @bind
  async updateProgress(id, progress) {
    try {
      await req.updateMilestoneProgress({ id, progress });
      Actions.showFlash('Progress has been updated');
      return Promise.resolve();
    } catch (e) {
      Actions.reportError('Unable to update progress', e);
      return Promise.reject();
    }
  }

  @bind
  async deleteProgress(id) {
    const result = await Actions.showModal('Confirmation', {
      title: 'Delete Progress',
      description: 'Are you sure you want to delete that progress?',
      yesButton: 'Yes',
      noButton: 'No',
      yesButtonProps: { secondary: false },
      noButtonProps: { secondary: true }
    });

    if (!result) {
      return;
    }

    try {
      await req.deleteMilestoneProgress({ id }, { id });
      Actions.showFlash('Progress has been deleted');
      return Promise.resolve();
    } catch (e) {
      Actions.reportError('Unable to delete progress', e);
      return Promise.reject();
    }
  }

  @bind
  async replaceProgressItems(nextItems) {
    const prevItems = this.props.milestoneProgresses;
    const progressItemIds = nextItems.map(i => i.id);

    try {
      this.props.setProgressOrder(nextItems);
      await req.reorderMilestoneProgresses({ progress_ids: progressItemIds });
      return Promise.resolve();
    } catch (e) {
      Actions.reportError('Unable to reorder progress items', e);
      this.props.setProgressOrder(prevItems);
      return Promise.reject();
    }
  }

  render() {
    const { milestoneProgresses } = this.props;

    return (
      <div className="manage-progress">
        <ManageController
          items={milestoneProgresses}
          onAdd={this.addProgress}
          onUpdate={this.updateProgress}
          onReplace={this.replaceProgressItems}
          onDelete={this.deleteProgress}
        >
          {props => (
            <ManageWindow
              className="manage-progress__window"
              title="Manage Progress"
              addIcon="manage"
              addLabel="Add Progress"
              onAdd={props.onNew}
            >
              <ManageList {...props} sortable ItemComponent={ProgressItem} />
            </ManageWindow>
          )}
        </ManageController>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  milestoneProgresses: state.milestoneProgresses.data
});

const mapDispatchToProps = dispatch => ({
  setProgressOrder: progresses => {
    dispatch({ type: 'MILESTONE_PROGRESSES_SET', payload: progresses });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageProgress);
