import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEqual, get } from 'lodash';
import { getSyncLockedText } from 'lib/utils';
import { ActionButton, EditPopup, Icon, TooltipTrigger } from 'components';
import { connect } from 'react-redux';
import { v4 } from 'uuid';

import './style.scss';

class GenericEditor extends Component {
  static propTypes = {
    defaultValue: PropTypes.any,
    onToggle: PropTypes.func,
    onSubmit: PropTypes.func, // Must return a promise
    children: PropTypes.func.isRequired,
    activeEditorId: PropTypes.string,
    setActiveEditorId: PropTypes.func,
    disabled: PropTypes.bool,
    locked: PropTypes.bool,
    lockedText: PropTypes.string,
    peSource: PropTypes.string,
    // Optional method for manual control
    isOpen: PropTypes.bool,
    onOpenStateChange: PropTypes.func
  };

  popup = null;

  constructor(props) {
    super(props);

    this.id = v4();

    this.state = {
      value: props.defaultValue,
      open: false,
      loading: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const toggled = prevState.open !== this.state.open;

    if (toggled) {
      if (this.props.onToggle) {
        this.props.onToggle(this.state.open);
      }

      if (this.state.open) {
        this.addEventListeners();
        this.listenResize();
      } else {
        this.removeEventListeners();
      }
    }

    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ value: this.props.defaultValue }, () => {
        this.props.setActiveEditorId(this.id);
      });
    }
  }

  componentWillUnmount() {
    if (this.props.activeEditorId === this.id) {
      this.props.setActiveEditorId(undefined);
    }
  }

  addEventListeners() {
    window.addEventListener('resize', this.listenResize);
    document.addEventListener('keydown', this.listenEnter);
  }

  removeEventListeners() {
    window.removeEventListener('resize', this.listenResize);
    document.removeEventListener('keydown', this.listenEnter);
  }

  @bind
  listenResize() {
    if (this.popup === null) {
      return;
    }

    const rect = this.popup.container.getBoundingClientRect();
    const screenWidth = document.body.getBoundingClientRect().width;

    if (rect.x + rect.width > screenWidth) {
      this.popup.container.style.left = document.body.clientWidth / 2 - this.popup.clientWidth / 2;
    }
  }

  @bind
  listenEnter(e) {
    if (e.code !== 'Enter' || this.state.loading) {
      return;
    }

    this.submit();
  }

  @bind
  open() {
    this.setState({ value: this.props.defaultValue }, () => {
      this.toggleOpen(true);
      this.props.setActiveEditorId(this.id);
    });
  }

  @bind
  close() {
    if (this.state.loading) {
      return;
    }

    this.toggleOpen(false);
    this.props.setActiveEditorId(undefined);
    this.props.onOpenStateChange?.(false);
  }

  toggleOpen(isOpen) {
    this.setState({ open: isOpen });
  }

  @bind
  updateValue(value) {
    this.setState({ value });
  }

  @bind
  submit() {
    if (isEqual(this.props.defaultValue, this.state.value)) {
      this.close();
      return;
    }

    this.setState({ loading: true }, () => {
      this.props
        .onSubmit(this.state.value)
        .then(() => {
          this.setState({ loading: false }, () => {
            this.close();
          });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  }

  @bind
  bindPopup(node) {
    this.popup = node;
  }

  renderLockTooltip() {
    const tooltipContentClassName = classNames('generic-editor__tooltip-lock-content', {
      [`${this.props.className}__tooltip-lock-content`]: Boolean(this.props.className)
    });

    return (
      <div className={tooltipContentClassName}>{this.props.lockedText || getSyncLockedText(this.props.peSource)}</div>
    );
  }

  render() {
    const { activeEditorId, disabled, locked, children: renderControl, onOpenStateChange } = this.props;
    const { value, loading, isSmallScreen } = this.state;

    const isControlledEditor = !!onOpenStateChange;
    const isOpen = isControlledEditor ? this.props.isOpen : this.state.open;

    const btnClassName = classNames('generic-editor__edit-btn', {
      [`${this.props.className}__edit-btn`]: Boolean(this.props.className)
    });

    const popupClassName = classNames('generic-editor__popup', {
      'generic-editor--right': isSmallScreen,
      [`${this.props.className}__popup`]: Boolean(this.props.className)
    });

    const isEnabled = activeEditorId === this.id || activeEditorId === undefined;

    if (locked) {
      const tooltipClassName = classNames('generic-editor__tooltip-lock', {
        [`${this.props.className}__tooltip-lock`]: Boolean(this.props.className)
      });

      const iconClassName = classNames('generic-editor__lock-icon', {
        [`${this.props.className}__lock-icon`]: Boolean(this.props.className)
      });

      return (
        <TooltipTrigger className={tooltipClassName} tooltip={this.renderLockTooltip()}>
          <Icon size={11} className={iconClassName} name="lock-2" color="#CCCCCC" />
        </TooltipTrigger>
      );
    }

    return (
      <React.Fragment>
        <ActionButton
          disabled={!isEnabled}
          hidden={disabled || isControlledEditor}
          className={btnClassName}
          iconName="edit"
          size={14}
          onClick={this.open}
        />

        <EditPopup
          ref={this.bindPopup}
          className={popupClassName}
          onCancel={this.close}
          onSubmit={this.submit}
          loading={loading}
          visible={isOpen}
        >
          {renderControl({ value, loading, onChange: this.updateValue })}
        </EditPopup>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeEditorId: state.profileEditors.activeEditorId,
  peSource: get(state.currentUser, 'data.current_school.pe_source')
});

const mapDispatchToProps = dispatch => ({
  setActiveEditorId(activeEditorId) {
    dispatch({
      type: 'SET_ACTIVE_EDITOR_ID',
      payload: { activeEditorId }
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GenericEditor);
