import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';

class TextInput extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyDown: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    tabIndex: PropTypes.number,
    disabled: PropTypes.bool,
    autoComplete: PropTypes.string,
    multiline: PropTypes.bool,
    defaultValue: PropTypes.string,
    'data-cy': PropTypes.string
  };

  static defaultProps = {
    type: 'text',
    multiline: false
  };

  input = null;

  @bind
  handleChange(e) {
    if (!this.props.onChange) {
      return;
    }

    const numValue = Number(e.target.value);

    if (this.props.type === 'number') {
      const { min, max } = this.props;

      if (Number.isNaN(numValue) || numValue < min) {
        this.props.onChange(String(min));
        return;
      }

      if (numValue > max) {
        this.props.onChange(String(max));
        return;
      }
    }

    this.props.onChange(e.target.value);
  }

  @bind
  bindInput(node) {
    this.input = node;
  }

  @bind
  focus() {
    if (this.input) {
      this.input.selectionStart = this.input.selectionEnd = this.input.value.length;
      this.input.focus();
    }
  }

  render() {
    const { props } = this;

    const inputCN = classNames({
      input: true,
      'input--textarea': props.multiline,
      [props.className]: Boolean(props.className)
    });

    const textInputProps = {
      className: inputCN,
      id: props.name,
      autoFocus: props.autoFocus,
      value: props.value,
      defaultValue: props.defaultValue,
      placeholder: props.placeholder,
      onChange: this.handleChange,
      onFocus: props.onFocus,
      onBlur: props.onBlur,
      disabled: props.disabled,
      onKeyPress: props.onKeyPress,
      onKeyDown: props.onKeyDown,
      onClick: props.onClick,
      autoComplete: props.autoComplete,
      tabIndex: props.tabIndex,
      ref: this.bindInput,
      min: props.min,
      max: props.max,
      'data-cy': props['data-cy']
    };

    // only enable numeric inputs
    if (props.type === 'number') {
      if (props.decimal) {
        textInputProps.step = 0.01;
        textInputProps.pattern = 'd+((.)d{1,2})?';
      } else {
        textInputProps.pattern = '[0-9]*';
      }
    }

    return props.multiline ? <textarea {...textInputProps} /> : <input type={props.type} {...textInputProps} />;
  }
}

export default TextInput;
