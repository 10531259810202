import React, { Component } from 'react';
import PhotoThumb from '../PhotoThumb';

export default class MealActivity extends Component {
  render() {
    const { activity } = this.props;
    return (
      <div className="activity__meal">
        <div className="activity__paragraph">
          {`${activity.data.type} | ${activity.data.quantity}. ${activity.data.desc}`}
        </div>
        <PhotoThumb photoUrl={activity.photo_url} />
      </div>
    );
  }
}
