import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonV2 } from 'components';
import { ImageCropper } from 'components';
import './style.scss';

class AvatarCrop extends Component {
  static propTypes = {
    data: PropTypes.shape({
      imgDataURL: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      aspect: PropTypes.number
    }),

    onClose: PropTypes.func,
    onReject: PropTypes.func
  };

  @bind
  renderTitle({ width, height }) {
    return (
      <div className="modal__header">
        <div className="subheader">
          Crop Photo ({width}x{height})
        </div>
      </div>
    );
  }

  @bind
  renderControls({ onSave }) {
    const { onReject } = this.props;

    return (
      <div className="modal__controls">
        <ButtonV2 secondary label="Cancel" onClick={onReject} />
        <ButtonV2 label="Save" onClick={onSave} />
      </div>
    );
  }

  render() {
    const { onClose, data } = this.props;

    return (
      <ImageCropper
        {...data}
        className="modal__wrapper avatar-crop"
        round={data.width === data.height}
        scaled
        onSave={onClose}
        renderTitle={this.renderTitle}
        renderControls={this.renderControls}
      />
    );
  }
}

export default AvatarCrop;
