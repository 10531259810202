import Nap from './Nap';
import Meal from './Meal';
import Bathroom from './Bathroom';
import Incident from './Incident';
import Bottle from './Bottle';
import Medication from './Medication';
import Mood from './Mood';
import Note from './Note';
import Photo from './Photo';
import SignIn from './SignIn';
import SignOut from './SignOut';
import Video from './Video';
import Absent from './Absent';
import Learning from './Learning';
import NameToFace from './NameToFace';
import Observation from './Observation';
import Transfer from './Transfer';

export default {
  nap_activity: Nap,
  meal_activity: Meal,
  bathroom_activity: Bathroom,
  incident_activity: Incident,
  bottle_activity: Bottle,
  medication_activity: Medication,
  mood_activity: Mood,
  note_activity: Note,
  photo_activity: Photo,
  sign_in_activity: SignIn,
  sign_out_activity: SignOut,
  video_activity: Video,
  absent_activity: Absent,
  learning_activity: Learning,
  name_to_face_activity: NameToFace,
  observation_activity: Observation,
  transfer_activity: Transfer
};
