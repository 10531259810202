const initialState = {
  activeEditorId: undefined
};

const profileEditors = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SET_ACTIVE_EDITOR_ID':
      return { activeEditorId: action.payload.activeEditorId };

    default:
      return state;
  }
};

export default profileEditors;
