import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SelectKind extends Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired
  };

  render() {
    const { onSelect, totalSteps } = this.props;

    return (
      <div className="modal__wrapper create-billing-plan__select-kind">
        <div className="modal__header">
          <span className="modal__header-steps">
            <span>Step 1</span> / {totalSteps}
          </span>
        </div>
        <div className="modal__container">
          <div className="subheader subheader--accent">
            Which Plan
            <br />
            do you want to create?
          </div>
          <div className="path-selector">
            <div className="path-selector__option">
              <div className="path-selector__option-inner" onClick={() => onSelect('tuition')}>
                Tuition Plan
                <div className="path-selector__option-inner__desc">(plan with fixed rates)</div>
              </div>
            </div>
            <div className="path-selector__option">
              <div className="path-selector__option-inner" onClick={() => onSelect('attendance')}>
                Attendance Plan
                <div className="path-selector__option-inner__desc">(dynamic rates based on sign in/out)</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectKind;
