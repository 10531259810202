import React, { Component } from 'react';

class PhotoThumb extends Component {
  @bind
  zoomIn(photoUrl) {
    return () =>
      Actions.showModal('PhotoViewer', { photos: [{ main_url: photoUrl }], index: 0 }, { className: 'modal--full' });
  }

  render() {
    const { photoUrl } = this.props;
    if (!photoUrl) {
      return null;
    }

    return <img src={photoUrl} className="activity__photo-img" onClick={this.zoomIn(photoUrl)} />;
  }
}

export default PhotoThumb;
