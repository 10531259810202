import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'components';

const FilterItemTags = ({ name, tags = [], defaultValue, useIdAsValue = false }) => (
  <div className="form__row">
    <Form.Select
      name={name}
      type="checkbox"
      title="Tags"
      label="Tags"
      multipleSelectionText="tags applied"
      defaultValue={defaultValue}
      data-cy={name}
      enableClear
    >
      {tags.map(tag => (
        <Form.Select.Item key={tag.id} value={useIdAsValue ? tag.id : tag.name} label={tag.name} />
      ))}
    </Form.Select>
  </div>
);

FilterItemTags.propTypes = {
  tags: PropTypes.array,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.array,
  useIdAsValue: PropTypes.bool
};

const mapStateToProps = state => ({
  tags: state.tags.data
});

export default connect(mapStateToProps, null, null, { pure: false })(FilterItemTags);
