import React from 'react';
import PropTypes from 'prop-types';
import history from 'lib/history';
import classNames from 'classnames';
import { Icon } from 'components';
import './style.scss';

export const ButtonV2 = ({
  id,
  label,
  type = 'button',
  onClick,
  secondary,
  tertiary,
  icon,
  className,
  disabled,
  hidden,
  tabIndex,
  iconName,
  iconSize,
  link,
  linkTarget,
  success,
  isLoading,
  ariaLabel,
  'data-cy': dataCY
}) => {
  if (hidden) {
    return null;
  }

  const buttonCN = classNames({
    buttonv2: true,
    'buttonv2--primary': !secondary && !tertiary && !icon,
    'buttonv2--secondary': secondary,
    'buttonv2--tertiary': tertiary && !secondary,
    'buttonv2--icononly': icon && !secondary && !tertiary,
    'buttonv2--success': success,
    [className]: Boolean(className)
  });

  const clickHandler = e => {
    if (onClick) {
      onClick(e);
    }
    if (link) {
      if (linkTarget || (typeof link === 'string' && link.startsWith('http'))) {
        window.open(link, linkTarget || '_self');
      } else {
        history.push(link);
      }
    }
  };

  return (
    <button
      id={id}
      type={type}
      className={buttonCN}
      onClick={clickHandler}
      disabled={disabled || isLoading}
      tabIndex={tabIndex}
      data-cy={dataCY}
      aria-label={ariaLabel}
    >
      {icon && <Icon className="buttonv2__icon" name={iconName} size={iconSize} />}
      {!icon && <span className="buttonv2__label">{label}</span>}
    </button>
  );
};

ButtonV2.propTypes = {
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  success: PropTypes.bool,
  icon: PropTypes.bool,
  iconName: PropTypes.string,
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  linkTarget: PropTypes.string
};

export default ButtonV2;
