import React from 'react';
import PropTypes from 'prop-types';
import { InlineEditor } from 'components';

function DecimalEditor({ onChange, ...props }) {
  const handleChange = value => {
    return onChange(value ? Number(value) : undefined);
  };

  return <InlineEditor.Input type="number" onChange={handleChange} {...props} />;
}

DecimalEditor.propTypes = {
  onChange: PropTypes.func
};

export default DecimalEditor;
