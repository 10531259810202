import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollBars from 'react-custom-scrollbars';
import SchoolCard from './SchoolCard';
import differenceWith from 'lodash/differenceWith';
import { Checkbox, TooltipTrigger } from 'components';
import { plural } from 'lib/utils';
import './style.scss';

class SelectSchoolList extends Component {
  static propTypes = {
    schools: PropTypes.array,
    selectedIds: PropTypes.array,
    onSelect: PropTypes.func,
    selectType: PropTypes.oneOf(['checkbox', 'radio']),
    noResultText: PropTypes.string,
    listHeight: PropTypes.number // Passed to Scrollbars
  };

  static defaultProps = {
    schools: [],
    selectedIds: [],
    listHeight: 285,
    selectType: 'checkbox'
  };

  selectedSchoolIds = {};

  constructor(props) {
    super(props);

    this.selectedSchoolIds = this.buildSchoolsHash(props.schools);

    props.selectedIds.forEach(id => {
      this.selectedSchoolIds[id] = true;
    });
  }

  get selectionState() {
    const { selectedIds, schools } = this.props;
    const isSubset = (smaller, larger) => smaller.every(val => larger.includes(val));

    const renderedIds = schools.map(s => s.id);
    const outOfRenderIds = differenceWith(selectedIds, renderedIds, (selectedId, schoolId) => schoolId === selectedId);

    if (selectedIds.length === 0) {
      return 'unchecked';
    } else if (selectedIds.length === renderedIds.length && isSubset(selectedIds, renderedIds)) {
      return 'checked';
    }
    // some selectedIds out of rendered scope
    else if (outOfRenderIds.length > 0) {
      // Show dotted checkbox
      return 'partial';
    } else {
      return 'unchecked';
    }
  }

  get allFilteredSelected() {
    const { selectedIds, schools } = this.props;
    const selectedSchools = schools.filter(s => selectedIds.find(id => id === s.id));
    return selectedSchools.length === schools.length && selectedSchools.length !== 0;
  }

  buildSchoolsHash(schools) {
    const hash = {};

    for (let school of schools) {
      hash[school.id] = false;
    }

    return hash;
  }

  buildSelectedArray() {
    return Object.keys(this.selectedSchoolIds).filter(id => this.selectedSchoolIds[id]);
  }

  @bind
  toggleSchool(schoolId) {
    const { selectType, schools } = this.props;
    const val = this.selectedSchoolIds[schoolId];
    if (selectType === 'radio') {
      this.selectedSchoolIds = this.buildSchoolsHash(schools);
    }
    this.selectedSchoolIds[schoolId] = !val;
    this.handleUpdate();
  }

  @bind
  toggleAll() {
    const { schools } = this.props;

    if (schools.length === 0) {
      return;
    }

    if (!this.allFilteredSelected) {
      schools.forEach(s => {
        this.selectedSchoolIds[s.id] = true;
      });
    } else {
      this.selectedSchoolIds = this.buildSchoolsHash(schools);
    }

    this.handleUpdate();
  }

  handleUpdate() {
    const idsArray = this.buildSelectedArray();

    if (this.props.onSelect) {
      this.props.onSelect(idsArray);
    }
  }

  getSortedSchools() {
    const { schools } = this.props;

    return schools.sort((school, nextSchool) => school.name.localeCompare(nextSchool.name));
  }

  renderTooltip() {
    const { selectedIds, schools } = this.props;
    const selectedSchools = selectedIds
      .map(id => schools.find(school => school.id === id))
      .filter(s => s !== undefined);

    return (
      <ul className="select-school-list__tooltip">
        {selectedSchools.map(school => (
          <li key={school.id}>{school.name}</li>
        ))}
      </ul>
    );
  }

  renderSchools() {
    const sortedSchools = this.getSortedSchools();

    return sortedSchools.map(s => {
      return (
        <SchoolCard
          key={s.id}
          id={s.id}
          name={s.name}
          picURL={s.profile_pic_url}
          onSelect={this.toggleSchool}
          selected={this.selectedSchoolIds[s.id]}
        />
      );
    });
  }

  getSelectedMessage() {
    const { selectedIds } = this.props;

    return `${plural(selectedIds.length, 'school')} selected`;
  }

  render() {
    const { selectedIds, selectType, listHeight, noResultText, schools } = this.props;
    const count = schools.length;

    return (
      <div className="select-school-list">
        {selectType === 'checkbox' && (
          <div className="select-school-list__header">
            <Checkbox
              className="select-school-list__select-all"
              checked={['checked', 'partial'].includes(this.selectionState)}
              dot={this.selectionState === 'partial'}
              onChange={this.toggleAll}
              disabled={count === 0}
              label="Select All"
            />

            <div className="select-school-list__selected">
              {selectedIds.length ? (
                <TooltipTrigger
                  tooltip={this.renderTooltip()}
                  maxHeight={110}
                  tooltipTriggerClassName="select-school-list__tooltip-trigger"
                >
                  {this.getSelectedMessage()}
                </TooltipTrigger>
              ) : (
                this.getSelectedMessage()
              )}
            </div>
          </div>
        )}

        <ScrollBars autoHeight autoHeightMin={listHeight}>
          <div className="select-school-list__cards">
            {count > 0 ? (
              this.renderSchools()
            ) : (
              <div className="select-school-list__no-results">{noResultText || 'No school found by filter.'}</div>
            )}
          </div>
        </ScrollBars>
      </div>
    );
  }
}

export default SelectSchoolList;
