import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TextInput } from 'components';
import validationText from '../validationText';
import './style.scss';
import withContext, { FormContext } from 'hocs/withContext';

class Textarea extends Component {
  static propTypes = {
    // Required to create form data properly, should be unique
    name: PropTypes.string.isRequired,

    // A default value
    defaultValue: PropTypes.string,

    // If provided, it will render a label above the field
    label: PropTypes.string,

    // If true, no invalid styles are applied to the field
    // Provided validations will still work, it only disables the styling
    disableInvalid: PropTypes.bool,

    asterisk: PropTypes.bool,

    'data-cy': PropTypes.string
  };

  UNSAFE_componentWillMount() {
    const initialValue = this.props.defaultValue || '';

    // These operations create a validation object's structure
    // and send it to Form component.
    const validations = {};

    if (this.props.required) {
      validations.isNotEmpty = null;
    }

    this.props.context.init(this.props.name, initialValue, validations);
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  /**
   * Update value of a field
   *
   * @param {string} val
   */
  @bind
  handleChange(val) {
    this.props.context.update(this.props.name, val);
  }

  /**
   * Update the validation state on blur
   */
  @bind
  handleBlur() {
    if (this.props.context.validateOn !== 'submit') {
      this.props.context.validate(this.props.name);
    }
  }

  /**
   * Get a text for an invalid field to render.
   *
   * @return {string} - a text which represents a validation error
   */
  getValidationText() {
    const { name } = this.props;
    const { validations, errors } = this.props.context;

    if (errors[name]) {
      return errors[name].join('; ');
    }

    if (!validations[name]) {
      return '';
    }

    return Object.keys(validations[name])
      .filter(type => validations[name][type] === false)
      .map(type => {
        if (typeof validationText[type] === 'function') {
          return validationText[type](this.props[type]);
        }

        return validationText[type];
      })
      .join('; ');
  }

  render() {
    const { values } = this.props.context;
    const { name, label, placeholder, disableInvalid, className, asterisk, 'data-cy': dataCY } = this.props;
    const invalidText = this.getValidationText();

    const formInputCN = classNames({
      'form-textarea': true,
      'form-textarea--invalid': Boolean(invalidText) && !disableInvalid,
      [className]: Boolean(className)
    });

    return (
      <div className={formInputCN}>
        {label && (
          <label className="form__label" htmlFor={name}>
            {label} {asterisk && <span className="form__asterisk">*</span>}
          </label>
        )}

        <div className="form-textarea__field">
          <TextInput
            multiline
            value={values[name] || ''}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            placeholder={placeholder}
            data-cy={dataCY}
          />
        </div>

        {invalidText && !disableInvalid && <div className="form-textarea__validation-text">{invalidText}</div>}
      </div>
    );
  }
}

export default withContext(FormContext)(Textarea);
