import React, { Fragment } from 'react';
import { Form } from 'components';

function Lead({ leadStages, leadSources }) {
  return (
    <Fragment>
      <div className="form__row create-person__lead-label">Student Information</div>
      <div className="form__row form__row--justified">
        <Form.Input
          label="First Name"
          placeholder="First Name"
          name="first_name"
          data-cy="first_name"
          required
          asterisk
        />
        <Form.Input label="Last Name" placeholder="Last Name" name="last_name" data-cy="last_name" required asterisk />
      </div>
      <div className="form__row form__row--justified">
        <Form.DatePicker
          className="create-person__lead-dob"
          label="Date of Birth"
          name="dob"
          required
          asterisk
          data-cy="dob"
        />
        <Form.Select
          label="Lead Status"
          title="Lead Status"
          name="state_id"
          required
          asterisk
          data-cy="state_id"
          defaultValue={(leadStages.find(s => s.kind === 'pending') || {})['id']}
        >
          {leadStages.map(s => (
            <Form.Select.Item key={s.id} value={s.id} label={s.name} />
          ))}
        </Form.Select>
      </div>
      <div className="create-person__lead-separator" />
      <div className="form__row create-person__lead-label">Parent 1</div>
      <div className="form__row form__row--justified">
        <Form.Input
          label="Parent First Name"
          placeholder="First Name"
          name="parent_first_name"
          data-cy="parent_first_name"
          required
          asterisk
        />
        <Form.Input
          label="Parent Last Name"
          placeholder="Last Name"
          name="parent_last_name"
          data-cy="parent_last_name"
          required
          asterisk
        />
      </div>
      <div className="form__row  form__row--justified">
        <Form.Input
          label="Parent Email"
          type="email"
          placeholder="Email"
          name="parent_email"
          data-cy="parent_email"
          required
          asterisk
        />
        <Form.Input
          label="Parent Phone"
          placeholder="Phone Number"
          name="parent_phone"
          data-cy="parent_phone"
          mask="999-999-999999999"
        />
      </div>
      <div className="form__row form__row--justified">
        <Form.Select
          label="How did you hear about us?"
          name="source_id"
          data-cy="source_id"
          title="Select Source"
          required
          asterisk
        >
          {leadSources.map(({ id, name }) => (
            <Form.Select.Item key={id} value={id} label={name} />
          ))}
        </Form.Select>
      </div>
    </Fragment>
  );
}

export default Lead;
