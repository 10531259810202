import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Form, ActionButton } from 'components';
import FormFieldType, { TypeToCustomAttribute } from 'constants/FormFieldType';
import TextInputOptions from './TextInputOptions';
import SelectOptions from './SelectOptions';
import TextBoxOptions from './TextBoxOptions';

class OptionForm extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    options: PropTypes.object,
    actionType: PropTypes.oneOf(['create', 'edit']),
    customAttributes: PropTypes.array,
    onSubmit: PropTypes.func
  };

  static defaultProps = {
    type: undefined,
    actionType: 'create',
    options: {}
  };

  static defaultValues = {
    customAttributeId: null
  };

  static optionFormTypes = [
    FormFieldType.TextInput,
    FormFieldType.Checkbox,
    FormFieldType.Select,
    FormFieldType.DatePicker,
    FormFieldType.TextBox,
    FormFieldType.FileUpload
  ];

  constructor(props) {
    super(props);

    this.state = {
      updatedValues: {
        ...OptionForm.defaultValues,
        ...props.options
      },
      additionalOptions: {
        [props.type]: this.getAdditionalOptions(props.type, props.options)
      }
    };

    this.formRef;
  }

  getAdditionalOptions(type, options) {
    switch (type) {
      case FormFieldType.Select:
        return { items: options.items };

      case FormFieldType.TextBox:
        return { value: options.value };

      default:
        return {};
    }
  }

  getTypeFormComponent(type) {
    switch (type) {
      case FormFieldType.TextInput:
        return TextInputOptions;

      case FormFieldType.Checkbox:
        return null;

      case FormFieldType.Select:
        return SelectOptions;

      case FormFieldType.TextBox:
        return TextBoxOptions;

      case FormFieldType.DatePicker:
        return null;

      default:
        return null;
    }
  }

  shouldDisplayForm(type) {
    return OptionForm.optionFormTypes.includes(type);
  }

  canSubmit(type) {
    switch (type) {
      case FormFieldType.TextBox: {
        const textboxOptions = this.state.additionalOptions.textbox;

        if (!textboxOptions) {
          return false;
        }

        return typeof textboxOptions.value === 'string' && textboxOptions.value.length > 0;
      }

      default:
        return true;
    }
  }

  isCustomAttrsAllowed() {
    return Boolean(TypeToCustomAttribute[this.props.type] && this.props.customAttributes);
  }

  @bind
  handleSubmit(values) {
    if (!this.props.onSubmit) {
      return;
    }

    const additionalOptions = this.state.additionalOptions[this.props.type] || {};

    this.props.onSubmit({
      ...values,
      ...additionalOptions
    });
  }

  @bind
  handleUpdate(values) {
    const { updatedValues, additionalOptions } = this.state;
    const { type, customAttributes, actionType } = this.props;
    const nextState = {
      updatedValues: {
        ...updatedValues,
        ...values
      }
    };

    if (this.isCustomAttrsAllowed() && updatedValues.customAttributeId !== values.customAttributeId) {
      const attribute = customAttributes.find(attr => attr.id === values.customAttributeId);
      if (actionType === 'create') {
        this.formRef.updateField('label', get(attribute, 'name', ''));
      }
      if (FormFieldType.Select === type) {
        const attributeSelectItems = get(attribute, 'data.options', []).map(option => ({
          value: option.id,
          label: option.name
        }));
        this.formRef.updateField('type', 'radio');
        nextState.updatedValues.items = attributeSelectItems;
        nextState.additionalOptions = {
          ...additionalOptions,
          [type]: {
            items: attributeSelectItems
          }
        };
      }
    }

    this.setState(nextState);
  }

  @bind
  updateAdditionalOptions(type) {
    return value => {
      this.setState({
        additionalOptions: {
          ...this.state.additionalOptions,
          [type]: value
        }
      });
    };
  }

  @bind
  addCustomAttribute() {
    Actions.showModal('AddCustomAttribute', {
      presetKeeper: this.props.customAttributesKeeper
    });
  }

  render() {
    const { type, options, actionType, customAttributes, description, customAttributeOptions } = this.props;
    const { additionalOptions, updatedValues } = this.state;
    const shouldDisplayForm = this.shouldDisplayForm(type);
    const ComponentOptionForm = this.getTypeFormComponent(type);
    const buttonText = `${actionType === 'create' ? 'Create' : 'Update'} Field`;
    const canSubmit = this.canSubmit(type);
    const displayCustomAttributes = this.isCustomAttrsAllowed();

    return (
      <Form
        ref={node => (this.formRef = node)}
        className="create-form-field__options"
        onSubmit={this.handleSubmit}
        onChange={this.handleUpdate}
      >
        {shouldDisplayForm && (
          <React.Fragment>
            {displayCustomAttributes && (
              <div className="create-form-field__custom-attribute">
                <div className="form__label create-form-field__section-label">2. Assign Field to Lead Data</div>
                <div className="create-form-field__description">
                  Choose custom attribute where you want the data to be assigned
                </div>
                <Form.Select
                  data-cy={'choose-attribute'}
                  name="customAttributeId"
                  title="Choose attribute"
                  defaultValue={options.customAttributeId || null}
                  disabled={get(customAttributeOptions, 'assignable') === false}
                  action={
                    <ActionButton
                      data-cy={'create-custom-attribute'}
                      onClick={this.addCustomAttribute}
                      iconName="plus"
                      className="create-form-field__custom-attribute__add"
                      title="Create New Attribute"
                      size={14}
                    />
                  }
                >
                  <Form.Select.Item
                    label={get(customAttributeOptions, 'placeholder', 'Not Selected')}
                    isDefault
                    value={null}
                  />
                  {customAttributes
                    .filter(attr => attr.field_type === TypeToCustomAttribute[type])
                    .map(attr => (
                      <Form.Select.Item key={attr.id} label={attr.name} value={attr.id} />
                    ))}
                </Form.Select>
              </div>
            )}
            <div className="create-form-field__options-inner">
              <div className="form__label create-form-field__section-label">
                {displayCustomAttributes ? '3' : '2'}. Field Details
              </div>
              <div className="create-form-field__description">{description}</div>
              <Form.Input
                data-cy="label"
                name="label"
                label="Field Label"
                placeholder="Field label"
                defaultValue={options.label}
              />
              {ComponentOptionForm && (
                <ComponentOptionForm
                  options={updatedValues}
                  additionalOptions={additionalOptions[type]}
                  customAttributeId={updatedValues.customAttributeId}
                  onChange={this.updateAdditionalOptions(type)}
                />
              )}

              {type !== FormFieldType.TextBox && (
                <Form.Checkbox
                  data-cy="required"
                  name="required"
                  label="This is a required field"
                  defaultValue={options.required}
                />
              )}
            </div>
          </React.Fragment>
        )}

        <div className="modal__controls">
          <Form.Submit label={buttonText} disabled={!canSubmit} data-cy="submit" />
        </div>
      </Form>
    );
  }
}

export default OptionForm;
