import React from 'react';
import PropTypes from 'prop-types';
import { CODE_TO_DAY } from 'constants/dayCodes';
import ScheduleItem from './ScheduleItem';
import './style.scss';

function Schedule({ schedule, placeholder = 'No schedule' }) {
  const values = Object.keys(schedule || {})
    .map(k => {
      return { day: CODE_TO_DAY[k], periods: schedule[k] };
    })
    .filter(v => !v.periods || v.periods.length !== 0);

  const isEmpty = values.length === 0;

  if (isEmpty) {
    return <div className="info-list__item-value info-list__item-value--empty">{placeholder}</div>;
  }

  return values.map(v => <ScheduleItem key={v.day} day={v.day} periods={v.periods} />);
}

Schedule.propTypes = {
  schedule: PropTypes.object,
  placeholder: PropTypes.string
};

export default Schedule;
