import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonV2 } from 'components';
import cx from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import Question from './Question';

const MAX_QUESTIONS_COUNT = 5;

function QuestionList({ questions, onUpdate, onRemove, onAdd, onEdit, defaultEditId = null }) {
  const items = questions.filter(question => !question._destroy);

  const [editId, setEditId] = useState(defaultEditId);
  const [displayWarning, setDisplayWarning] = useState(false);
  const scrollbarRef = useRef(null);
  const prevItemsCount = useRef(items.length);

  useEffect(() => {
    if (prevItemsCount.current < items.length) {
      setEditId(items[items.length - 1].id);
    }
    prevItemsCount.current = items.length;
  });

  const handleEditStart = id => {
    // only one question editing allowed
    if (editId !== null) {
      setDisplayWarning(true);
      return;
    }

    onEdit(id);
    setEditId(id);
  };

  const handleEditEnd = () => {
    onEdit(null);
    setDisplayWarning(false);
    setEditId(null);
  };

  const handleFocusQuestion = questionRef => {
    if (scrollbarRef.current && questionRef.offsetTop) {
      scrollbarRef.current.scrollTop(questionRef.offsetTop);
    }
  };

  return (
    <div className="kiosk-form__section kiosk-form__questions">
      <Scrollbars className="kiosk-form__questions-scroll" autoHeight autoHeightMax={420} ref={scrollbarRef}>
        <div className="kiosk-form__questions-list">
          {items.map(question => (
            <Question
              key={question.id}
              data={question}
              isEdit={editId === question.id}
              displayWarning={displayWarning && editId === question.id}
              onUpdate={onUpdate}
              onRemove={onRemove}
              onEditStart={handleEditStart}
              onEditEnd={handleEditEnd}
              focusQuestion={handleFocusQuestion}
              // hideCancel={items.length === 1 && question._new}
            />
          ))}
          {items.length < MAX_QUESTIONS_COUNT && (
            <ButtonV2
              tertiary
              className={cx('kiosk-form__questions-add', {
                'kiosk-form__questions-add--disabled': !!editId
              })}
              disabled={!!editId}
              label="Add Question"
              onClick={onAdd}
              data-cy={'add-question'}
            />
          )}
        </div>
      </Scrollbars>
    </div>
  );
}

QuestionList.propTypes = {
  questions: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired
};

export default QuestionList;
