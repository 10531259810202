import React from 'react';
import { connect } from 'react-redux';
import { Preloader } from 'components';
import './style.scss';

const Cloak = ({ visible }) => {
  if (!visible) {
    return null;
  }

  return (
    <div className="cloak">
      <Preloader large />
      <span className="cloak__text">Loading...</span>
    </div>
  );
};

const mapStateToProps = state => ({
  visible: state.cloak.isVisible
});

export default connect(mapStateToProps)(Cloak);
