import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import './style.scss';

const SCROLL_THROTTLE_DELAY = 200;
const LOAD_THROTTLE_DELAY = 500;

class InfiniteScroll extends PureComponent {
  static propTypes = {
    // A distance in pixels before reaching the end of the scroll box, where onEndReach should fire
    threshold: PropTypes.number,

    // A callback which is called when the end is reached
    onEndReach: PropTypes.func,

    // Is content loading (should be passed to avoid extra requests if content is being requested)
    loading: PropTypes.bool,

    // Any content which is passed inside
    children: PropTypes.any,

    // Custom class name
    className: PropTypes.string,

    // Delay in ms for firing handleScroll
    scrollDelay: PropTypes.number,

    hasMore: PropTypes.bool
  };

  static defaultProps = {
    threshold: 0,
    hasMore: true,
    loading: false,
    scrollDelay: SCROLL_THROTTLE_DELAY
  };

  // A previous scroll value used to detect scroll direction
  prevScrollTop = 0;

  infiniteScrollContainer = React.createRef();

  constructor(props) {
    super(props);

    this.handleScroll = throttle(this.handleScroll, props.scrollDelay);
    this.onEndReach = throttle(this.onEndReach, LOAD_THROTTLE_DELAY);
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll, { capture: true });
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll, { capture: true });
  }

  @bind
  handleScroll(e) {
    const container = e.target;
    let node = this.infiniteScrollContainer.current;
    let isScrollParent = false;

    while (node !== null) {
      if (container === node) {
        isScrollParent = true;
        break;
      }

      node = node.parentNode;
    }

    if (!isScrollParent) {
      return;
    }

    const isEndReached = this.isEndReached(container, this.props.threshold);
    const isScrollingDown = e.target.scrollTop > this.prevScrollTop;

    if (isEndReached && isScrollingDown && !this.props.loading && this.props.hasMore) {
      this.onEndReach();
    }

    this.prevScrollTop = e.target.scrollTop;
  }

  @bind
  onEndReach() {
    if (this.props.onEndReach) {
      this.props.onEndReach();
    }
  }

  isEndReached(container, threshold) {
    return container.scrollHeight <= container.offsetHeight + container.scrollTop + threshold;
  }

  render() {
    const className = classNames('infinite-scroll', this.props.className);

    return (
      <div ref={this.infiniteScrollContainer} className={className}>
        {this.props.children}
      </div>
    );
  }
}

export default InfiniteScroll;
