import React, { useRef, useState } from 'react';
import get from 'lodash/get';
import Steps from 'modals/ModalController/Steps';
import ChooseType from './ChooseType';
import CreateTask from './CreateTask';
import SendEmail from './SendEmail';
import ChooseTemplate from 'modals/CommunicationSendMsg/SelectTemplateStep';
import './style.scss';

const STEPS = {
  create_task: 1,
  send_email: 2
};

function AddAutomationAction({ data, onClose }) {
  const stepsRef = useRef(null);
  const [template, setTemplate] = useState(null);

  const goToEmailStep = msgTemplate => {
    setTemplate(msgTemplate);
    stepsRef.current.goToStep('send_email');
  };

  const startStep = STEPS[get(data, 'action.kind')] || 0;

  return (
    <Steps ref={stepsRef} startStep={startStep}>
      <Steps.Item name="choose-type">
        <ChooseType />
      </Steps.Item>
      <Steps.Item name="create_task">
        <CreateTask data={data} onClose={onClose} />
      </Steps.Item>
      <Steps.Item name="send_email">
        <SendEmail data={data} template={template} onClose={onClose} />
      </Steps.Item>
      <Steps.Item name="msg-template">
        <ChooseTemplate kind="email" onSelect={goToEmailStep} onBack={goToEmailStep} />
      </Steps.Item>
    </Steps>
  );
}

export default AddAutomationAction;
