import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TextInput, Typeahead, ActionButton, SelectGroup, Icon } from 'components';
import currency from 'lib/currency';
import { getDiscountPrice } from 'lib/utils';
import history from 'lib/history';

class DiscountItem extends Component {
  static propTypes = {
    editMode: PropTypes.string,
    data: PropTypes.object,
    removable: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.number,
    onUpdate: PropTypes.func,
    onRemove: PropTypes.func,
    tabIndex: PropTypes.number,
    onClose: PropTypes.func,
    noSubsidiesAlert: PropTypes.bool
  };

  static defaultProps = {
    data: {
      description: '',
      price: '',
      _discount: '',
      _base: '',
      _discountType: '$'
    },
    disabled: false,
    removable: false,
    onUpdate: () => undefined,
    onRemove: () => undefined
  };

  state = {
    touched: false
  };

  get presets() {
    return this.props.presets.filter(p => p.kind === this.props.editMode);
  }

  @bind
  updateDescriptionTypeahead(optionId) {
    const { id, data, onUpdate } = this.props;
    const selectedPreset = this.presets.find(p => p.id === optionId);
    const nextDescription = selectedPreset ? selectedPreset.title : '';
    const nextId = selectedPreset && data.kind === 'ledger' ? selectedPreset.id : undefined;

    this.setState({ touched: true });
    onUpdate(id, { ...data, description: nextDescription, ledger_id: nextId });
  }

  @bind
  updateValue(field, value) {
    const { id, data, onUpdate } = this.props;

    if (!/^(\-)?\d{0,7}(\.\d{0,2})?$/.test(value)) {
      return;
    }

    onUpdate(id, { ...data, [field]: value });
  }

  @bind
  updateDiscountType(value) {
    const { id, data, onUpdate } = this.props;
    onUpdate(id, { ...data, _discountType: value });
  }

  @bind
  remove() {
    const { id, onRemove } = this.props;

    onRemove(id);
  }

  @bind
  getOptions() {
    return this.presets.map(p => ({ id: p.id, label: p.title }));
  }

  @bind
  getAction() {
    const { onManagePresets, editMode } = this.props;
    if (editMode === 'ledger') return undefined;

    return (
      <ActionButton
        onClick={() => onManagePresets(editMode)}
        iconName="gear"
        className="invoice-item-list__preset-manage-btn"
        title={`Manage ${editMode === 'discount' ? 'Discounts' : 'Subsidy Discounts'}`}
        size={14}
      />
    );
  }

  @bind
  handleCreateSubsidy() {
    history.push('/billing/subsidies');
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  }

  render() {
    const { data, disabled, removable, tabIndex, noSubsidiesAlert } = this.props;
    const { touched } = this.state;

    const priceCN = cx('invoice-item-list__item-price', {
      'invoice-item-list__item-price--empty': !Number(data.price)
    });

    const descCN = cx('invoice-item-list__item-discount-desc', {
      'invoice-item-list__item-discount-desc--invalid': touched && !data.description
    });

    const itemCN = cx('table__cell table__cell--l invoice-item-list__item-description', {
      'invoice-item-list__item-description--alert': noSubsidiesAlert
    });

    return data._destroy ? null : (
      <React.Fragment>
        <div className={itemCN}>
          <div className="invoice-item-list__item-discount">
            <TextInput
              value={data._discount || ''}
              onChange={v => this.updateValue('_discount', v)}
              placeholder="0"
              disabled={disabled}
              tabIndex={tabIndex}
            />
            <SelectGroup
              className="invoice-item-list__item-discount__mode"
              type="radio"
              checked={data._discountType || '$'}
              onChange={this.updateDiscountType}
              disabled={disabled}
              tabIndex={tabIndex}
            >
              <SelectGroup.Item value="%" label="%" />
              <SelectGroup.Item value="$" label="$" />
            </SelectGroup>
          </div>
          {data._discountType === '%' && <span className="invoice-item-list__item-discount-of">Of</span>}
          {data._discountType === '%' && (
            <TextInput
              className="invoice-item-list__item-discount-base"
              value={data._base || ''}
              onChange={v => this.updateValue('_base', v)}
              placeholder="0"
              tabIndex={tabIndex}
            />
          )}
          <Typeahead
            type="radio"
            title="Description"
            className={descCN}
            action={this.getAction()}
            options={this.getOptions()}
            onChange={this.updateDescriptionTypeahead}
            placeholder="Add Description"
            defaultInputValue={data.description}
            disableHighlight
            hideNoMatch
            disabled={disabled}
            tabIndex={tabIndex}
          />
          {noSubsidiesAlert && (
            <div className="invoice-item-list__item-discount-alert">
              <Icon size={16} name="alert" />
              You do not have any subsidies.
              <ActionButton
                onClick={this.handleCreateSubsidy}
                className="invoice-item-list__item-discount-alert-link"
                title="Create new subsidy"
              />
            </div>
          )}
        </div>

        <div className="table__cell table__cell--xs invoice-item-list__amount-text">
          <div className={priceCN}>
            <span className="invoice-item-list__item-price--negative">
              {currency.getPrice(-getDiscountPrice(data))}
            </span>
          </div>
        </div>

        <div className="table__cell table__cell--xxs table__cell--actions invoice-item-list__item-actions">
          <ActionButton
            iconName="delete"
            size={14}
            className="invoice-item-list__item-delete-btn"
            onClick={this.remove}
            disabled={!removable || disabled}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default DiscountItem;
