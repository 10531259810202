import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getSyncLockedText } from 'lib/utils';
import { ActionButton, Icon, TooltipTrigger } from 'components';

class EmptyEditor extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    locked: PropTypes.bool
  };

  renderLockTooltip() {
    const tooltipContentClassName = classNames('generic-editor__tooltip-lock-content', {
      [`${this.props.className}__tooltip-lock-content`]: Boolean(this.props.className)
    });

    return <div className={tooltipContentClassName}>{getSyncLockedText(this.props.peSource)}</div>;
  }

  render() {
    const { disabled, locked, className, onClick } = this.props;

    const btnClassName = classNames('generic-editor__edit-btn', {
      [`${className}__edit-btn`]: Boolean(className)
    });

    if (locked) {
      const tooltipClassName = classNames('generic-editor__tooltip-lock', {
        [`${className}__tooltip-lock`]: Boolean(className)
      });

      const iconClassName = classNames('generic-editor__lock-icon', {
        [`${className}__lock-icon`]: Boolean(className)
      });

      return (
        <TooltipTrigger className={tooltipClassName} tooltip={this.renderLockTooltip()}>
          <Icon size={11} className={iconClassName} name="lock-2" color="#CCCCCC" />
        </TooltipTrigger>
      );
    }

    return <ActionButton hidden={disabled} className={btnClassName} iconName="edit" size={14} onClick={onClick} />;
  }
}

export default EmptyEditor;
