export default [
  { label: 'Nap', id: 'nap_activity' },
  { label: 'Meal', id: 'meal_activity' },
  { label: 'Notes', id: 'note_activity' },
  { label: 'Incident', id: 'incident_activity' },
  { label: 'Photo', id: 'photo_activity' },
  { label: 'Video', id: 'video_activity' },
  { label: 'Bottle', id: 'bottle_activity' },
  { label: 'Mood', id: 'mood_activity' },
  { label: 'Medication', id: 'medication_activity' },
  { label: 'Bathroom', id: 'bathroom_activity' },
  { label: 'Sign In', id: 'sign_in_activity' },
  { label: 'Sign Out', id: 'sign_out_activity' },
  { label: 'Transfer', id: 'transfer_activity' },
  { label: 'Absent', id: 'absent_activity' },
  { label: 'Learning', id: 'learning_activity' },
  { label: 'Name To Face', id: 'name_to_face_activity' },
  { label: 'Observation', id: 'observation_activity' }
];

export const APPROVAL_ACTIVITY_TYPES = [
  { label: 'Photo', id: 'photo_activity' },
  { label: 'Incident', id: 'incident_activity' },
  { label: 'Medication', id: 'medication_activity' },
  { label: 'Observation', id: 'observation_activity' },
  { label: 'Absent', id: 'absent_activity' },
  { label: 'Meal', id: 'meal_activity' },
  { label: 'Learning', id: 'learning_activity' },
  { label: 'Bottle', id: 'bottle_activity' },
  { label: 'Bathroom', id: 'bathroom_activity' },
  { label: 'Nap', id: 'nap_activity' },
  { label: 'Notes', id: 'note_activity' },
  { label: 'Mood', id: 'mood_activity' },
  { label: 'Video', id: 'video_activity' }
];

export const CREATABLE_ACTIVITY_TYPES = [
  { label: 'Nap', id: 'nap_activity' },
  { label: 'Meal', id: 'meal_activity' },
  { label: 'Notes', id: 'note_activity' },
  { label: 'Incident', id: 'incident_activity' },
  { label: 'Bottle', id: 'bottle_activity' },
  { label: 'Photo', id: 'photo_activity' },
  { label: 'Mood', id: 'mood_activity' },
  { label: 'Medication', id: 'medication_activity' },
  { label: 'Bathroom', id: 'bathroom_activity' },
  { label: 'Learning', id: 'learning_activity' },
  { label: 'Name To Face', id: 'name_to_face_activity' },
  { label: 'Observation', id: 'observation_activity' }
];

export const RECURRING_ACTIVITIES = [
  { label: 'Nap', id: 'nap_activity' },
  { label: 'Meal', id: 'meal_activity' },
  { label: 'Bottle', id: 'bottle_activity' },
  { label: 'Bathroom', id: 'bathroom_activity' }
];

export const SIGNABLE_ACTIVITIES = ['incident_activity'];
