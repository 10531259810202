import React from 'react';
import { ButtonV2, Checkbox } from 'components';
import './style.scss';

function Step1({ data, onToggle, nextStep }) {
  return (
    <div className="select-students-modal">
      <div className="modal__header modal__header--bordered">
        <span className="modal__header-title  select-students-modal__header-title">
          Send Portfolio to Parents
          <span className="select-students-modal__steps">
            <em>Step 1</em> / 2
          </span>
        </span>

        <div className="select-students-modal__header-subtitle">
          Parents will receive an email with an attached PDF of their child's portfolio
        </div>
      </div>

      <div className="modal__container select-students-modal__body">
        <div>
          <Checkbox
            type="square"
            size="small"
            name="withProgressOnly"
            label="Only include Assessments with an assigned Progress"
            checked={data.withProgressOnly}
            onChange={onToggle}
          />
        </div>
        <p className="pt-16 w-3/4 leading-tight">
          <strong>IMPORTANT: </strong>Children that have assessments but have not been marked with a progress will
          produce an empty PDF.
        </p>
      </div>

      <div className="modal__controls">
        <ButtonV2 label="Continue" onClick={nextStep} data-cy="filter-trigger" />
      </div>
    </div>
  );
}

export default Step1;
