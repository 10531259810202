import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ButtonV2, Avatar } from 'components';
import './style.scss';

class ChangeRegStatus extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isSaving: false
    };
  }

  @bind
  changeStatus() {
    const { data } = this.props;
    const payload = {
      id: data.id,
      enrollment: {
        state_id: data.state_id
      }
    };

    this.setState({ isSaving: true });
    req
      .updateEnrollmentItem(payload)
      .then(() => {
        Actions.showFlash('Account status changed.');
        this.setState({ isSaving: false });
        this.props.onClose(true);
      })
      .catch(e => {
        Actions.reportError('Unable to update status', e);
        this.setState({ isSaving: false });
      });
  }

  render() {
    const { data, onClose, leadStages } = this.props;
    const { isSaving } = this.state;
    const kid = data.kid;
    const kidName = kid.name || `${kid.first_name} ${kid.last_name}`;
    const stage = leadStages.find(s => s.id === data.state_id);

    return (
      <div className="modal__wrapper change-reg-status">
        <div className="modal__container">
          <div className="change-reg-status__kid">
            <Avatar
              name={kidName}
              url={kid.profile_pic_url}
              tooltipText={kidName}
              color={kid.color}
              initials={kid.initials}
            />
            <div className="change-reg-status__kid-name">{kidName}</div>
          </div>
          <div className="change-reg-status__title">Change Account Status</div>
          <div className="change-reg-status__message">Do you want to change status to "{stage && stage.name}"?</div>
        </div>
        <div className="modal__controls">
          <ButtonV2 onClick={onClose} secondary label="Cancel" />
          <ButtonV2 onClick={this.changeStatus} label="Yes, Change" isLoading={isSaving} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  leadStages: state.leadStages.data
});

export default connect(mapStateToProps)(ChangeRegStatus);
