import React from 'react';
import { Form } from 'components';

const CheckboxOptions = ({ options = {}, disabled }) => (
  <React.Fragment>
    <Form.Input
      name="checkboxLabel"
      label="Checkbox label"
      placeholder="Checkbox label"
      defaultValue={options.checkboxLabel}
      disabled={disabled}
    />
  </React.Fragment>
);

export default CheckboxOptions;
