import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ActionButton, Form } from 'components';
import moment from 'moment';
import range from 'lodash/range';
import { plural } from 'lib/utils';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';
import TaskForm from '../CreateCrmTask/TaskForm';
import SelectWhen from './SelectWhen';
import './style.scss';

function CreateTask({ data, onClose, context: { prevStep } }) {
  const [saving, setSaving] = useState(false);

  const handleSubmit = async values => {
    setSaving(true);
    const id = get(data, 'action.id');
    const { days, due_in, ...otherValues } = values;
    const payload = {
      ruleId: data.ruleId,
      automation_action: {
        kind: 'create_task',
        days: parseInt(days, 10),
        data: {
          task: {
            ...otherValues,
            due_date: values.due_date ? moment(values.due_date).format('YYYY-MM-DD') : undefined,
            days: due_in ? parseInt(due_in, 10) : undefined
          }
        }
      }
    };

    try {
      if (id) {
        await req.updateAutomationAction({
          id,
          ...payload
        });
      } else {
        await req.createAutomationAction(payload);
      }
      setSaving(false);
      Actions.showFlash('Action saved');

      onClose();
    } catch (e) {
      setSaving(false);
      Actions.reportError('Unable to save action', e);
    }
  };

  const task = get(data, 'action.data.task', {});
  const fromRule = data.fromRule || false;

  return (
    <div className="modal__wrapper">
      <div className="modal__header">
        <span className="modal__header-title">
          <ActionButton iconName="back" className="modal__header-back" onClick={prevStep} />
          Create Task
        </span>
        <span className="modal__header-steps">
          <span>Step 2</span> / 2
        </span>
      </div>
      <div className="modal__container add-automation add-automation__action">
        <TaskForm onSubmit={handleSubmit} data={task} saving={saving} fromRule={fromRule}>
          <div className="form__row  form__row--justified">
            <Form.Select
              name="due_in"
              type="radio"
              title="Choose"
              label="Task Due (after creation)"
              defaultValue={get(task, 'days', 0)}
            >
              <Form.Select.Item key="due_in_none" label={'None'} value="" isDefault />
              {range(1, 8).map(day => (
                <Form.Select.Item key={`due_in_${day}`} label={plural(day, 'day')} value={day} />
              ))}
            </Form.Select>
            <SelectWhen
              formContext
              text="Task will be created between 7 - 9 AM on the day"
              defaultDays={get(data, 'action.days', 0)}
            />
          </div>
        </TaskForm>
      </div>
    </div>
  );
}

CreateTask.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func
};

export default withContext(ModalControllerStepsContext)(CreateTask);
