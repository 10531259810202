import React, { Component } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { Placeholder } from 'components';
import './style.scss';

class TuitionExpressCard extends Component {
  static propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func
  };

  static defaultProps = {
    data: {
      uriMethod: 'teAccountUri'
    }
  };

  constructor(...args) {
    super(...args);
    this.state = {
      teraSetting: null,
      teraLoading: true
    };
  }

  componentDidMount() {
    const {
      data: { uriMethod }
    } = this.props;

    this.setState({ teraLoading: true });
    req[uriMethod]()
      .then(teraSetting => {
        this.setState({ teraLoading: false, teraSetting }, () => {
          this.initializeTera();
        });
      })
      .catch(e => {
        this.setState({ teraLoading: false, teraSetting: null });
        Actions.reportError('There was problem getting Tuition Express token', e);
      });
  }

  @bind
  onTeraLoaded() {
    this.setState({ teraLoading: false });
  }

  @bind
  initializeTera() {
    const { TeraUrl, Token, ProfileID } = this.state.teraSetting;
    this.tera = new window.tera({
      Target: 'payment_container',
      TeraUrl,
      Token,
      ProfileID,
      Style: `${process.env.PUBLIC_PATH}/assets/tera.css`,
      Height: 500,
      OnLoaded: this.onTeraLoaded,
      OnSuccess: this.handleSubmit,
      OnResponse: noop,
      OnDecline: console.log,
      OnError: console.log,
      OnValidation: console.log,
      SaveOnly: true
    });
    this.tera.Initialize(9.99);
  }

  @bind
  handleSubmit(data) {
    const { onClose } = this.props;

    onClose(data);
  }

  render() {
    const { teraSetting, teraLoading } = this.state;

    return (
      <div className="modal__wrapper tuition-express-modal">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Add Card Info</div>
        </div>

        <div className="modal__container">
          <div id="payment_container" />
          {(!teraSetting || teraLoading) && (
            <Placeholder.NoResult isLoading image="" className="te-payments__placeholder">
              <div className="no-payments__title">There was problem getting Tuition Express token.</div>
            </Placeholder.NoResult>
          )}
        </div>
      </div>
    );
  }
}

export default TuitionExpressCard;
