import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox, Avatar, ActionButton } from 'components';
import './style.scss';

class StudentCard extends Component {
  static propTypes = {
    checkable: PropTypes.bool,
    checked: PropTypes.bool,
    disableCheck: PropTypes.bool
  };

  static defaultProps = {
    checkable: true,
    disableCheck: false
  };

  render() {
    const { student, roomName, onClick, checked, checkable, disableCheck, action, actionIcon, className } = this.props;

    const cardCN = classNames(
      {
        'student-card': true,
        'student-card--checkable': checkable,
        'student-card--actionable': Boolean(action)
      },
      className
    );

    return (
      <div className={cardCN} onClick={disableCheck ? undefined : onClick}>
        {checkable && <Checkbox checked={checked} disabled={disableCheck} />}
        <Avatar
          url={student.profile_pic_url}
          name={student.name}
          color={student.color}
          className="student-card__avatar avatar--small"
        />
        <div className="student-card__info">
          <div className="student-card__full-name">{student.name}</div>
          {roomName && <span className="student-card__room">{roomName}</span>}
        </div>
        {action && <ActionButton iconName={actionIcon} onClick={action} />}
      </div>
    );
  }
}

export default StudentCard;
