import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'components';

class TabViewHeader extends Component {
  static propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.object),
    disabled: PropTypes.bool,
    activeTabIndex: PropTypes.number,
    onTabChange: PropTypes.func,
    showIndices: PropTypes.bool
  };

  static defaultProps = {
    tabs: []
  };

  @bind
  handleChange(index) {
    const { onTabChange, disabled } = this.props;

    if (!onTabChange) {
      return;
    }

    return () => {
      if (!disabled) {
        onTabChange(index);
      }
    };
  }

  renderTabs() {
    const { tabs, activeTabIndex, showIndices } = this.props;

    return tabs.map((t, index) => {
      const className = classNames('tab-view__header-tab', {
        'tab-view__header-tab--active': activeTabIndex === index,
        'tab-view__header-tab--completed': t.props.completed
      });

      return (
        <div
          key={t.title + String(index)}
          className={className}
          onClick={this.handleChange(index)}
          data-cy={'tab-header-item'}
        >
          {showIndices && (
            <div className="tab-view__header-tab-index">
              {t.props.completed ? (
                <Icon name="tick" className="tab-view__header-tab-tick" />
              ) : (
                <span>{index + 1}</span>
              )}
            </div>
          )}

          <span className="tab-view__header-tab-title">{t.props.title}</span>
        </div>
      );
    });
  }

  render() {
    return <div className="tab-view__header">{this.renderTabs()}</div>;
  }
}

export default TabViewHeader;
