import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectStaffCommon from '../common/SelectStaff';
import './style.scss';

class SelectStaff extends Component {
  static propTypes = {
    kids: PropTypes.array,
    data: PropTypes.object
  };

  static defaultProps = {
    kids: [],
    data: {}
  };

  @bind
  handleSubmit(kidIds) {
    this.props.onClose(kidIds);
  }

  render() {
    const { data, staff } = this.props;

    return (
      <div className="select-students">
        <SelectStaffCommon
          defaultSelected={data.selected}
          staff={staff}
          validations={data.validations}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  staff: state.staff.data
});

export default connect(mapStateToProps)(SelectStaff);
