import React, { useContext, useEffect, useRef, useState } from 'react';
import currency from 'lib/currency';
import { Form, Preloader, StudentList } from 'components';
import { useSelector } from 'react-redux';
import { calculateFeeAndTotal } from 'modals/ReceivePayment/utils';
import md5 from 'crypto-js/md5';
import { ModalControllerStepsContext } from 'hocs/withContext';

const TE_CONTAINER_ID = 'receive-payment-te-container';

function Step2({ cardPresentState, onClose }) {
  const context = useContext(ModalControllerStepsContext);
  const { kids, payment, accountBalance } = cardPresentState;
  const [formValues, setFormValues] = useState({
    presentationType: 'in_person',
    cardType: 'debit'
  });
  const [totals, setTotals] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const currentSchool = useSelector(state => state.currentUser.data.current_school);
  const isFeePaidByParent = currentSchool.parent_payment_fee_paid_by === 'parent_payment_fee_paid_by_parent';
  const feeBreakdown = useSelector(state => state.feeBreakdown.data);
  const fees = isFeePaidByParent ? feeBreakdown?.te?.paid_by_parent : null;
  const selectedFee = formValues.cardType === 'credit' && fees?.cc;

  const teraRef = useRef(null);

  // Overview of request sequence:

  // FE generates an order ID, notifies BE of payment intent
  //   BE creates a in-progress transaction record with this ID
  // FE initiates payment via TE with the same ID
  // FE attempts to PATCH the BE record with response from TE
  //   If this request errors or times out, we still show the user
  //   "success" and leave it up to BE to sync with TE since they
  //   can match their order IDs
  const orderId = useRef(md5(payment.family_id + Date.now().toString()).toString()).current;

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([initializeTera(), req.schoolFeeBreakdown()]);
      setIsLoading(false);
    };
    fetchData();

    return () => {
      removeTera();
      setIsLoading(true);
    };
  }, []);

  useEffect(() => {
    if (teraRef.current) {
      teraRef.current.SetFormValues({
        Request_TransactionEntrySource: formValues.presentationType === 'phone' ? 'Telephone' : 'InPerson'
      });
    }
  }, [teraRef.current, formValues.presentationType]);

  const initializeTera = async () => {
    const teSettings = await req.teAccountUri();
    const { TeraUrl, Token, ProfileID } = teSettings;

    return new Promise(resolve => {
      teraRef.current = new window.tera({
        Target: TE_CONTAINER_ID,
        TeraUrl,
        Token,
        ProfileID,
        Style: `${process.env.PUBLIC_PATH}/tera-cpt.css`,
        Height: 500,
        OnLoaded: resolve,
        OnSubmit: onTeSubmit,
        OnSuccess: token => onTeCompleted(token, true),
        OnDecline: token => onTeCompleted(token, false),
        OnError: () => {
          resolve();
          onTeError();
        },
        OnCancel: () => context.prevStep(),
        RemoveSave: true,
        ShowCancel: true,
        SubmitButtonText: 'Submit Payment',
        CancelButtonText: 'Back',
        SchoolID: currentSchool.id,
        OrderID: orderId,
        TransactionEntrySource: 'InPerson'
      });
      // Setting the amount here because TE introduced a bug that causes
      // SetAmount to be ignored if it's called too quickly after the
      // OnLoaded event. If we ever default card type to credit, will
      // need to refactor to have fee data here
      teraRef.current.Initialize(payment.amount);
    });
  };

  const removeTera = () => {
    teraRef.current = null;
    const teNode = document.getElementById(TE_CONTAINER_ID);
    if (teNode) {
      teNode.innerHTML = '';
    }
  };

  useEffect(() => {
    let totals = calculateFeeAndTotal(payment.amount, selectedFee);
    setTotals(totals);

    if (teraRef.current && !isLoading) {
      teraRef.current.SetAmount(totals.total, totals.fee);
    }
  }, [selectedFee, teraRef.current, isLoading]);

  // Called when user clicks submit
  // Payment will only proceed if SubmitOk() is called
  const onTeSubmit = async () => {
    try {
      setIsSaving(true);
      // We're in a closure from when Tera mounted, fresh state is not available
      const formValues = document.getElementById(TE_CONTAINER_ID).dataset;
      // Create in-progress transaction record that BE can use to sync with TE
      const response = await req.receivePayment({
        parent_payment: {
          ...payment,
          mode: 'Card',
          sub_mode: formValues.cardType,
          te_order_id: orderId,
          state: 'state_under_process'
        }
      });
      formValues.parentPaymentId = response.parent_payment.id;
      teraRef.current.SubmitOk();
    } catch (err) {
      Actions.reportError('Unable to initiate payment. Please try again later.', err);
      setIsSaving(false);
    }
  };

  const onTeError = () => {
    context.prevStep();
    Actions.showModal('Alert', {
      title: 'Tuition Express Error',
      message: 'Unable to initiate transaction at this time. Please try again later.',
      label: 'Ok'
    });
  };

  const onTeCompleted = async (token, isSuccess) => {
    try {
      removeTera(); // reduce flicker of Tera's default behavior
    } catch (error) {
      console.debug(error);
    }

    try {
      const formValues = document.getElementById(TE_CONTAINER_ID).dataset;
      await req.editPayment({
        id: formValues.parentPaymentId,
        parent_payment: {
          payment_method_sub_kind: formValues.cardType,
          presentation_type: formValues.presentationType,
          skip_notification: payment.skip_notification,
          te_response: JSON.parse(token)
        }
      });
    } catch {
      // Failing this BE request only means the transaction log will continue showing
      // a pending payment until they sync with TE
    }

    Actions.showFlash(isSuccess ? 'Payment has been received' : 'Payment has failed', isSuccess ? 'success' : 'alert');
    onClose(isSuccess);
  };

  return (
    <div className="receive-payment-step2">
      <div className="modal__header">
        <div className="subheader">Add Card Details</div>
        <span className="modal__header-note">
          <StudentList students={kids} />
        </span>
        {accountBalance !== undefined && (
          <div className="mt-8">
            Account Balance: <strong>{currency.getPrice(accountBalance)}</strong>
          </div>
        )}
      </div>
      <div className="receive-payment-step2-body">
        {!isLoading && (
          <>
            <div className="receive-payment-step2-border-fix" />
            <Form onChange={setFormValues} className="receive-payment-step2__form">
              <div className="form__radio-group__wrapper">
                <label className="form__label">
                  Card Presentation Type
                  <span className="form__asterisk">*</span>
                </label>
                <Form.RadioGroup
                  className="reports-modal__checkbox-group"
                  name="presentationType"
                  type="circle"
                  options={[
                    { value: 'in_person', label: 'In person' },
                    { value: 'phone', label: 'Over the phone' }
                  ]}
                  defaultValue={formValues.presentationType}
                />
              </div>
              <div className="form__radio-group__wrapper mt-16">
                <label className="form__label">
                  Card Type
                  <span className="form__asterisk">*</span>
                </label>
                <Form.RadioGroup
                  className="reports-modal__checkbox-group"
                  name="cardType"
                  type="circle"
                  options={[
                    { value: 'debit', label: 'Debit' },
                    { value: 'credit', label: 'Credit' }
                  ]}
                  defaultValue={formValues.cardType}
                />
              </div>
              {isFeePaidByParent && (
                <>
                  <div className="receive-payment-step2__fee-breakdown">(Processing Fee: {fees.cc})</div>
                  <div className="receive-payment-step2__totals">
                    <div>
                      Card Processing Fee: <strong>{currency.getPrice(totals.fee)}</strong>
                    </div>
                    <div className="ml-16">
                      Total: <strong>{currency.getPrice(totals.total)}</strong>
                    </div>
                  </div>
                </>
              )}
            </Form>
          </>
        )}
        <div
          id={TE_CONTAINER_ID}
          data-card-type={formValues.cardType}
          data-presentation-type={formValues.presentationType}
        />
        {isLoading && <Preloader large center />}
      </div>
      {isSaving && (
        <div className="receive-payment-step2__overlay">
          <Preloader large center />
        </div>
      )}
    </div>
  );
}

export default Step2;
