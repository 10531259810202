import React from 'react';
import PropTypes from 'prop-types';
import { Preloader } from 'components';
import cx from 'classnames';
import './style.scss';

const NoResultPlaceholder = ({ image = 'penguin', className, children, isLoading = false, hasBorder = true }) => {
  const CN = cx('no-result-placeholder', className, {
    'no-result-placeholder--border': hasBorder
  });

  if (isLoading) {
    return (
      <div className={CN}>
        <Preloader large />
      </div>
    );
  }

  return (
    <div className={CN}>
      <div className="no-result-placeholder__desc">{children}</div>
      {image && (
        <img
          className="no-result-placeholder__img"
          src={`/assets/images/placeholders/no-result/${image}.png`}
          alt={image}
        />
      )}
    </div>
  );
};

NoResultPlaceholder.proptypes = {
  image: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  hasBorder: PropTypes.bool
};

export default NoResultPlaceholder;
