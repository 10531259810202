import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Preloader } from 'components';
import moment from 'moment';
import Column from './Column';
import './style.scss';

const SAT_INDEX = 6;
const SUN_INDEX = 7;

class WeekGrid extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    loadingDate: PropTypes.string,
    loading: PropTypes.bool,
    showWeekend: PropTypes.bool,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    renderItem: PropTypes.func,
    renderPlaceholder: PropTypes.func,
    addButtonOptions: PropTypes.object,
    readOnly: PropTypes.bool
  };

  static defaultProps = {
    data: {}
  };

  prepareData(data, showWeekend) {
    const columnsData = [];

    if (!data || !data.plans) {
      return columnsData;
    }

    const dates = Object.keys(data.plans);

    for (let i = 0; i < dates.length; i++) {
      const dateStr = dates[i];
      const weekday = moment(dateStr, 'YYYY-MM-DD').isoWeekday();

      if (!showWeekend && (weekday === SAT_INDEX || weekday === SUN_INDEX)) {
        continue;
      }

      columnsData.push({ dateStr, items: data.plans[dateStr] });
    }

    return columnsData;
  }

  renderColumns() {
    const {
      data,
      loadingDate,
      showWeekend = false,
      onAdd,
      onDelete,
      renderItem,
      renderPlaceholder,
      addButtonOptions,
      readOnly,
      className
    } = this.props;
    const columnsData = this.prepareData(data, showWeekend);

    return columnsData.map(c => (
      <Column
        className={className}
        key={c.dateStr}
        dateStr={c.dateStr}
        items={c.items}
        onAdd={onAdd}
        onDelete={onDelete}
        loading={loadingDate === c.dateStr}
        readOnly={readOnly}
        renderItem={renderItem}
        renderPlaceholder={renderPlaceholder}
        addButtonOptions={addButtonOptions}
      />
    ));
  }

  render() {
    return (
      <div className="week-grid">
        {this.renderColumns()}

        {this.props.loading && (
          <div className="week-grid__loading">
            <Preloader large />
          </div>
        )}
      </div>
    );
  }
}

export default WeekGrid;
