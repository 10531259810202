import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import moment from 'moment';
import DateLegendItem from './DateLegendItem';
import HourLegend from './HourLegend';
import Weekday from './WeekDay';
import AllDayEventItem from './AllDayEventItem';
import EventPanel from './EventPanel';
import { getEventsForDate } from './utils';
import './style.scss';

function WeeklyCalendar({ showWeekend, startDate, events, allowCreate }) {
  const [active, setActive] = useState(null);
  const dayCount = showWeekend ? 7 : 5;
  const normalEvents = events.filter(e => !e.all_day);
  const allDayEvents = events.filter(e => e.all_day);

  useEffect(() => {
    const section = document.getElementById('main-section');
    const scrollListener = () => {
      setActive(null);
    };

    section.addEventListener('scroll', scrollListener);

    return () => {
      section.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return (
    <div className="weekly-calendar">
      <div className="weekly-calendar__dates">
        {range(dayCount).map(i => (
          <DateLegendItem key={`weekly-date-${i}`} date={moment(startDate).add(i, 'days')} />
        ))}
      </div>
      <div className="weekly-calendar__alldays">
        {range(dayCount).map(i => {
          const weekdate = moment(startDate).add(i, 'days');
          const items = getEventsForDate(allDayEvents, weekdate);

          return (
            <div className="weekly-calendar__alldays-item" key={`allday_${i}`}>
              {items.map(i => (
                <AllDayEventItem key={i.id} event={i} onSelect={setActive} />
              ))}
            </div>
          );
        })}
      </div>
      <div className="weekly-calendar__days">
        <HourLegend />
        {range(dayCount).map(i => {
          const weekdate = moment(startDate).add(i, 'days');
          return (
            <Weekday
              key={`weekday-${i}`}
              date={weekdate}
              events={getEventsForDate(normalEvents, weekdate)}
              onSelect={setActive}
              allowCreate={allowCreate}
            />
          );
        })}
      </div>
      {active && <EventPanel event={active.event} anchor={active.anchor} onClose={() => setActive(null)} />}
    </div>
  );
}

WeeklyCalendar.propTypes = {
  events: PropTypes.array,
  showWeekend: PropTypes.bool,
  allowCreate: PropTypes.bool,
  startDate: PropTypes.any,
  endDate: PropTypes.any
};

WeeklyCalendar.Weekday = Weekday;
WeeklyCalendar.AllDayEventItem = AllDayEventItem;
WeeklyCalendar.HourLegend = HourLegend;

export default WeeklyCalendar;
