import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import v4 from 'uuid/v4';
import { TextInput, Icon, ButtonV2, Checkbox } from 'components';
import AnswerList from './AnswerList';

const SELECTION_MULTIPLE = 'multiple';
const SELECTION_SINGLE = 'single';

const MIN_REQUIRED_ANSWERS = 2;

const DEFAULT_VALIDATION = {
  value: true,
  answers: true
};

function createAnswer(value = '') {
  return { id: v4(), value };
}

function getAnswersState(answers = []) {
  if (answers.length === 0) return [createAnswer(), createAnswer()];

  return answers.map(answer => createAnswer(answer));
}

function isNew({ _new, value, answers }) {
  return _new && value === '' && answers.length === 0;
}

function Question({ data, onUpdate, onRemove, isEdit, onEditStart, onEditEnd, focusQuestion, displayWarning }) {
  const [selection, setSelection] = useState(data.selection || SELECTION_SINGLE);
  const [value, setValue] = useState(data.value || '');
  const [answers, setAnswers] = useState(getAnswersState(data.answers));
  const [validation, setValidation] = useState({ ...DEFAULT_VALIDATION });
  const elementRef = useRef(null);

  useEffect(() => {
    // reset state on start/stop editing
    setValue(data.value || '');
    setAnswers(getAnswersState(data.answers));
    setSelection(data.selection || SELECTION_SINGLE);
    setValidation({ ...DEFAULT_VALIDATION });
  }, [isEdit]);

  useEffect(() => {
    // focus question
    if ((displayWarning || (isEdit && isNew(data))) && elementRef.current) {
      focusQuestion(elementRef.current);
    }
  }, [displayWarning, isEdit]);

  useEffect(() => {
    // reset validation
    if (!isValid(validation)) {
      setValidation({ ...DEFAULT_VALIDATION });
    }
  }, [value, answers]);

  const handleEdit = () => {
    onEditStart(data.id);
  };

  const handleDelete = e => {
    e.stopPropagation();
    onRemove(data.id);
  };

  const addAnswer = () => {
    setAnswers([...answers, createAnswer()]);
  };

  const removeAnswer = id => {
    setAnswers(answers.filter(answer => answer.id !== id));
  };

  const changeAnswer = (id, value) => {
    const answerIndex = answers.findIndex(answer => answer.id === id);

    setAnswers([...answers.slice(0, answerIndex), { id, value }, ...answers.slice(answerIndex + 1)]);
  };

  const handleCancel = () => {
    onEditEnd();

    if (isNew(data)) {
      onRemove(data.id);
    }
  };

  const handleApply = () => {
    const nextValidation = validate();

    if (!isValid(nextValidation)) {
      setValidation(nextValidation);
      return;
    }

    onUpdate(data.id, {
      id: data.id,
      value,
      selection,
      answers: answers.filter(answer => !!answer.value.trim().length).map(answer => answer.value)
    });
    onEditEnd();
  };

  const handleSelectionChange = isChecked => {
    const nextSelection = isChecked ? SELECTION_MULTIPLE : SELECTION_SINGLE;

    setSelection(nextSelection);
  };

  const validate = () => {
    // console.log(answers);
    return {
      value: !!value.trim().length,
      answers: answers.filter(answer => !!answer.value.trim().length).length >= MIN_REQUIRED_ANSWERS // at least 2 options
    };
  };

  const isValid = validations => {
    return Object.keys(validations).every(validation => validations[validation]);
  };

  return (
    <div
      className={cx('kiosk-form__questions-item', 'kiosk-form__question', {
        'kiosk-form__question--draft': isEdit
      })}
      ref={elementRef}
      onClick={!isEdit ? handleEdit : undefined}
      data-cy={`question-item-${data.id}`}
    >
      {!isEdit ? (
        <React.Fragment>
          <div className="kiosk-form__question-title" data-cy={'question-title'}>
            {data.value}
          </div>
          <div className="kiosk-form__question-actions">
            <ButtonV2
              onClick={handleDelete}
              className="kiosk-form__question-actions-icon"
              icon
              iconSize={24}
              iconName="delete"
              data-cy={'delete-question'}
            />
            <ButtonV2
              className="kiosk-form__question-actions-icon kiosk-form__question-actions-icon--edit"
              icon
              iconSize={24}
              iconName="chevron-up-circle"
              data-cy={'edit-question'}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="kiosk-form__question-section">
            <div className="kiosk-form__question-title">What is your question?</div>
            <div
              className={cx('kiosk-form__question-field', {
                ['kiosk-form__question-field--invalid']: !validation.value
              })}
            >
              <TextInput
                multiline
                rows={4}
                value={value}
                onChange={setValue}
                placeholder=""
                data-cy={'question-value'}
              />
            </div>
          </div>

          <div className="kiosk-form__question-section">
            <div className="kiosk-form__question-title">What are your multiple choice answers?</div>
            <div
              className={cx('kiosk-form__question-field', {
                ['kiosk-form__question-field--invalid']: !validation.answers
              })}
            >
              <AnswerList answers={answers} onAdd={addAnswer} onRemove={removeAnswer} onChange={changeAnswer} />
              {!validation.answers && (
                <div className="kiosk-form__question-field-validation" data-cy={'question-answers-validation'}>
                  At least 2 answers should be set
                </div>
              )}
            </div>
          </div>

          <div className="kiosk-form__question-section">
            <Checkbox
              onChange={handleSelectionChange}
              checked={selection === SELECTION_MULTIPLE}
              label="Allow selecting multiple answers"
              data-cy={'question-multiple-answers'}
            />
          </div>

          <div className="kiosk-form__question-footer">
            {displayWarning && (
              <div className="kiosk-form__question-warning" data-cy="question-alert">
                <Icon name="alert" size={24} className="kiosk-form__question-warning-icon" />
                <div className="kiosk-form__question-warning-text">
                  <b>Changes will be lost.</b>
                  <br />
                  <span>
                    Select <b>APPLY</b> to save. <b>CANCEL</b> to continue.
                  </span>
                </div>
              </div>
            )}
            <ButtonV2
              secondary
              label="Cancel"
              className="kiosk-form__question-button kiosk-form__question-cancel"
              onClick={handleCancel}
              data-cy={'question-cancel'}
            />
            <ButtonV2
              secondary
              label="Apply"
              className="kiosk-form__question-button kiosk-form__question-apply"
              onClick={handleApply}
              data-cy={'question-apply'}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

Question.propTypes = {
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  onEditStart: PropTypes.func.isRequired,
  onEditEnd: PropTypes.func.isRequired,
  displayWarning: PropTypes.bool.isRequired,
  focusQuestion: PropTypes.func.isRequired
};

export default Question;
