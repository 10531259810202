import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ActionButton, ButtonV2 } from 'components';
import { connect } from 'react-redux';
import { applyFilters } from 'lib/utils';
import SelectPersonList from 'modals/common/SelectPersonList';
import StudentFilter from './StudentFilter';
import './style.scss';

class SelectStudents extends PureComponent {
  static propTypes = {
    kids: PropTypes.array,
    rooms: PropTypes.array,
    tags: PropTypes.array,
    filters: PropTypes.object,
    onFiltersUpdate: PropTypes.func,
    selectedIds: PropTypes.array,
    onSelect: PropTypes.func,
    title: PropTypes.string,
    buttonText: PropTypes.string,
    StepSlot: PropTypes.element,
    onBack: PropTypes.func,
    onSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool
  };

  static defaultProps = {
    kids: [],
    rooms: [],
    tags: [],
    selectedKidIds: [],
    title: 'Select Students',
    buttonText: 'Continue'
  };

  constructor(props) {
    super(props);

    this.validations = {
      'No Email': kid => {
        return kid.parents.filter(p => p.email).length === 0;
      }
    };

    this.state = {
      filters: props.filters || {},
      selectedIds: props.selectedIds || []
    };
  }

  @bind
  updateFilters(filters) {
    this.setState({ filters });

    const { onFiltersUpdate } = this.props;
    if (typeof onFiltersUpdate === 'function') {
      onFiltersUpdate(filters);
    }
  }

  @bind
  updateSelectedIds(ids) {
    this.setState({ selectedIds: ids });

    const { onSelect } = this.props;
    if (typeof onSelect === 'function') {
      onSelect(ids);
    }
  }

  get kids() {
    return applyFilters(this.props.kids, this.state.filters);
  }

  renderBackButton() {
    if (typeof this.props.onBack !== 'function') return null;
    return <ActionButton iconName="back" className="modal__header-back" onClick={this.props.onBack} />;
  }

  renderStepSlot() {
    const { StepSlot } = this.props;
    if (!StepSlot) return null;

    return (
      <span className="select-students-modal__steps">
        <StepSlot />
      </span>
    );
  }

  render() {
    const { rooms, tags, title, buttonText, isSubmitting, onSubmit } = this.props;
    const { filters } = this.state;

    return (
      <div className="select-students-modal">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title select-students-modal__header-title">
            {this.renderBackButton()}
            {title}
            {this.renderStepSlot()}
          </div>

          <div>
            <StudentFilter rooms={rooms} tags={tags} filters={filters} onChange={this.updateFilters} />
          </div>
        </div>

        <div className="modal__container">
          <SelectPersonList
            persons={this.kids}
            allPersons={this.kids}
            selectedIds={this.state.selectedIds}
            onSelect={this.updateSelectedIds}
            listHeight={438}
            type="kid"
            validations={this.validations}
            disableInvalid={false}
          />
        </div>

        <div className="modal__controls">
          <ButtonV2
            disabled={!this.state.selectedIds.length}
            label={buttonText}
            onClick={onSubmit}
            isLoading={isSubmitting}
            data-cy="continue"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  kids: state.students.data,
  rooms: state.rooms.data,
  tags: state.tags.data
});

export default connect(mapStateToProps)(SelectStudents);
