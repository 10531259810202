import React, { Component } from 'react';

class PhotoActivity extends Component {
  @bind
  zoomIn(photo) {
    return () => Actions.showModal('PhotoViewer', { photos: [photo], index: 0 }, { className: 'modal--full' });
  }

  render() {
    const {
      activity: { activiable: photo },
      activity
    } = this.props;
    return (
      <div className="activity__photo">
        <div className="activity__paragraph">{activity.comment}</div>
        {photo.main_url && <img src={photo.main_url} className="activity__photo-img" onClick={this.zoomIn(photo)} />}
      </div>
    );
  }
}

export default PhotoActivity;
