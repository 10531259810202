import React, { Component } from 'react';
import { TooltipTrigger, ActionButton, Icon } from 'components';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import './style.scss';

const monthShortNames = moment.monthsShort();
const monthFullNames = moment.months();

class MonthPicker extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    defaultValue: PropTypes.any,
    'data-cy': PropTypes.string
  };

  tooltip = null;

  constructor(props) {
    super(props);

    const date = Array.isArray(props.defaultValue) ? props.defaultValue[0] : props.defaultValue;

    this.state = {
      month: props.defaultValue && date ? moment(date).get('month') : null,
      year: moment(date).get('year')
    };
  }

  @bind
  changeMonth(month, isDisabled) {
    if (isDisabled) {
      return;
    }

    this.setState({ month }, () => {
      this.handleChange();
    });
    this.tooltip.toggleTooltip(false);
  }

  @bind
  setPrevYear() {
    this.setState({ year: this.state.year - 1 }, () => {
      this.handleChange();
    });
  }

  @bind
  setNextYear() {
    const { month, year } = this.state;
    let nextMonth = month;
    let nextYear = year + 1;

    if (this.isMonthDisabled(year + 1, month)) {
      if (year + 1 === moment().get('year')) {
        if (this.props.disableCurrentMonth) {
          nextMonth = moment().get('month') - 1;

          if (nextMonth < 0) {
            nextYear = year;
            nextMonth = 11;
          }
        } else {
          nextMonth = moment().get('month');
        }
      } else {
        nextMonth = 0;
      }
    }

    this.setState(
      {
        year: nextYear,
        month: nextMonth
      },
      () => {
        this.handleChange();
      }
    );
  }

  @bind
  setValue(month, year) {
    this.setState({ month, year }, () => {
      this.handleChange();
    });
  }

  @bind
  isMonthDisabled(currentYear, currentMonthIndex) {
    const { disableFuture, disableCurrentMonth, disablePast } = this.props;

    const checkedMonth = moment({ year: currentYear, month: currentMonthIndex });
    const currentMonth = moment().startOf('month');

    return (
      // Check if the future months should be disabled
      (disableFuture && checkedMonth.isAfter(currentMonth)) ||
      // Check if the current month should be disabled
      (disableCurrentMonth && checkedMonth.isSame(currentMonth)) ||
      // Check if past months should be disabled
      (disablePast && checkedMonth.isBefore(currentMonth))
    );
  }

  renderRow(months, offset) {
    const currentMonth = this.state.month;

    return months.map((month, index) => {
      const currentMonthIndex = offset + index;
      const isDisabled = this.isMonthDisabled(this.state.year, currentMonthIndex);

      const cellCN = cx('month-picker__cell', {
        'month-picker__cell--active': currentMonth === currentMonthIndex,
        'month-picker__cell--disabled': isDisabled
      });

      return (
        <div className={cellCN} key={month} onClick={() => this.changeMonth(currentMonthIndex, isDisabled)}>
          {month}
        </div>
      );
    });
  }

  handleChange() {
    const { month, year } = this.state;

    if (month !== null) {
      this.props.onChange([
        moment([year, month, 1])
          .startOf('month')
          .toDate(),
        moment([year, month, 1])
          .endOf('month')
          .toDate()
      ]);
    } else {
      this.props.onChange(undefined);
    }
  }

  @bind
  onClear(e) {
    e.stopPropagation();
    this.setValue(null, this.state.year);
  }

  @bind
  isNextYearButtonDisabled() {
    if (!this.props.disableFuture) return false;
    return (
      this.state.year === moment().get('year') ||
      (this.state.year === moment().get('year') - 1 && this.props.disableCurrentMonth && moment().get('month') === 0)
    );
  }

  @bind
  isPastYearButtonDisabled() {
    if (!this.props.disablePast) return false;
    return (
      this.state.year === moment().get('year') ||
      (this.state.year === moment().get('year') - 1 && this.props.disableCurrentMonth && moment().get('month') === 0)
    );
  }

  @bind
  renderContent() {
    const { 'data-cy': dataCY } = this.props;
    const { year } = this.state;
    const rows = [];

    for (let i = 0; i < monthShortNames.length; i += 3) {
      rows.push(
        <div className="month-picker__row" data-cy={`${dataCY}-${i}`} key={i}>
          {this.renderRow(monthShortNames.slice(i, i + 3), i)}
        </div>
      );
    }

    return (
      <div className="month-picker__content">
        <div className="month-picker__year">
          <ActionButton
            iconName="chevron-down"
            className="month-picker__year-arrow-left"
            size={12}
            onClick={this.setPrevYear}
            disabled={this.isPastYearButtonDisabled()}
            data-cy={`${dataCY}-prev`}
          />

          <div className="month-picker__year-value">{year}</div>

          <ActionButton
            iconName="chevron-down"
            className="month-picker__year-arrow-right"
            size={12}
            onClick={this.setNextYear}
            disabled={this.isNextYearButtonDisabled()}
            data-cy={`${dataCY}-next`}
          />
        </div>

        <div className="month-picker__months">{rows}</div>
      </div>
    );
  }

  render() {
    const { month, year } = this.state;
    const { disabled, clearable, 'data-cy': dataCY, displayDateRange } = this.props;
    const CN = cx('month-picker__trigger', {
      'month-picker__trigger--placeholder': month === null
    });

    let monthLabel;
    if (month !== null) {
      if (displayDateRange) {
        monthLabel = `${moment([year, month, 1]).format('MMM D')} - ${moment([year, month, 1])
          .endOf('month')
          .format('MMM D')}`;
      } else {
        monthLabel = `${monthFullNames[month]}, ${year}`;
      }
    } else {
      monthLabel = 'Select Month';
    }

    return (
      <TooltipTrigger
        noArrow
        white
        className="month-picker"
        triggerOn="click"
        renderTooltip={this.renderContent}
        ref={node => (this.tooltip = node)}
        disabled={disabled}
        keepOpenDisabled
      >
        <div data-cy={dataCY} className={CN}>
          <div className="month-picker__label">{monthLabel}</div>

          {clearable && !!month && (
            <ActionButton iconName="close-circle" size={14} className="month-picker__icon" onClick={this.onClear} />
          )}
          {(!clearable || !month) && <Icon name="calendar" className="month-picker__icon" size={18} />}
        </div>
      </TooltipTrigger>
    );
  }
}

export default MonthPicker;
