export const CREATE_STAFF = 'create-staff';

export const CREATE_STUDENT = 'create-student';

export const CREATE_EVENT = 'create-event';

export const CREATE_LEAD = 'create-lead';

export const CREATE_INVOICE = 'create-invoice';

export const DEFAULT_QUICK_ACTIONS_MAP = {
  [CREATE_STAFF]: true,
  [CREATE_STUDENT]: true,
  [CREATE_EVENT]: true,
  [CREATE_LEAD]: true,
  [CREATE_INVOICE]: true
};

export default [
  {
    label: 'Add Staff',
    value: CREATE_STAFF,
    permission: 'teachers',
    hideOnProcareSync: true
  },
  {
    label: 'Add Student',
    value: CREATE_STUDENT,
    permission: 'kids',
    hideOnProcareSync: true
  },
  {
    label: 'Add Calendar Event',
    value: CREATE_EVENT,
    permission: 'school_calendars'
  },
  {
    label: 'Add Lead',
    value: CREATE_LEAD,
    permission: 'lead_crm'
  },
  {
    label: 'Create Invoice',
    value: CREATE_INVOICE,
    permission: 'billing'
  }
];
