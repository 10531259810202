import React, { Component } from 'react';
import { ButtonV2 } from 'components';
import GeneralForm from './GeneralForm';
import NewsletterForm from './NewsletterForm';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

const NEWSLETTER_DESCRIPTION = 'You can customize and send formatted email to parents and staff';
const GENERAL_DESCRIPTION = 'Parents will receive a push notification and a message in their app inbox';

class MessageStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMessageValid: true,
      isSubjectValid: true
    };
  }

  @bind
  validate() {
    const newState = {
      isMessageValid: !!this.props.message,
      isSubjectValid: this.props.messageType !== 'newsletter' || !!this.props.subject
    };

    this.setState(newState);
    return newState.isMessageValid && newState.isSubjectValid;
  }

  @bind
  onContinue() {
    const { messageType, sendSMS, message } = this.props;
    if (this.validate()) {
      if (messageType !== 'newsletter' && sendSMS && message.length > 300) {
        Actions.showFlash('You can send only up to 300 characters via SMS.', 'alert');
        return;
      }

      this.props.context.nextStep();
    }
  }

  @bind
  saveDraft() {
    if (this.validate()) {
      this.props.saveDraft();
    }
  }

  render() {
    const { messageType, isSaving } = this.props;
    const { isMessageValid, isSubjectValid } = this.state;

    return (
      <div className="modal__wrapper">
        <div className="modal__header modal__header--bordered">
          <span className="modal__header-title new-message__title">New Message</span>
          <span className="modal__header-steps">
            <span>step 1</span> / 2
          </span>

          <div className="new-message__description">
            {messageType === 'newsletter' ? NEWSLETTER_DESCRIPTION : GENERAL_DESCRIPTION}
          </div>
        </div>

        {messageType === 'newsletter' ? (
          <NewsletterForm {...this.props} isSubjectValid={isSubjectValid} isMessageValid={isMessageValid} />
        ) : (
          <GeneralForm {...this.props} isMessageValid={isMessageValid} />
        )}

        <div className="modal__controls">
          {messageType === 'newsletter' && (
            <ButtonV2 secondary label="Save as template" onClick={this.saveDraft} isLoading={isSaving} />
          )}
          <ButtonV2 label="Continue" data-cy="continue" onClick={this.onContinue} />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(MessageStep);
