import React from 'react';
import { Icon } from 'components';

const Thankyou = () => (
  <div className="enroll__thankyou">
    <div className="enroll__thankyou-img">
      <Icon name="tick" size={55} />
    </div>
    <div className="enroll__thankyou-title">Thank you for subscribing!</div>
  </div>
);

export default Thankyou;
