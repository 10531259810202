import React, { useState } from 'react';
import moment from 'moment';
import { Form } from 'components';
import NAMETOFACE_TYPES from 'constants/nametofaceTypes';
import TEXT_CAPTIONS from './constants';
import CheckTypeForm from './CheckTypeForms';
import StaffPresentSelect from './StaffPresentSelect';

const NameToFaceActivityForm = ({ activity, useDate = true, constants }) => {
  const [checkType, setCheckType] = useState(activity.data.title);
  const handleCheckTypeChange = (_, value) => {
    setCheckType(value);
  };

  return (
    <div className="form__body">
      {useDate && (
        <div className="form__row form__row--datetime">
          <Form.DatePicker label="Date" name="date" defaultValue={moment(activity.activity_time).toDate()} required />

          <Form.TimeInput label="Time" name="time" defaultValue={Helpers.formatTime(activity.activity_time)} required />
        </div>
      )}
      <div className="form__row">
        <Form.Select
          name="title"
          defaultValue={activity.data.title}
          title="Check Type"
          label="Check Type"
          data-cy="check-type"
          required
          onChange={handleCheckTypeChange}
        >
          {Object.keys(NAMETOFACE_TYPES).map(type => (
            <Form.Select.Item label={type} value={type} key={type} />
          ))}
        </Form.Select>
      </div>

      <StaffPresentSelect activity={activity} />

      <CheckTypeForm checkType={checkType} value={activity.data.sleep_position} constants={constants} />

      <div className="form__row">
        <Form.Textarea
          name="description"
          defaultValue={activity.data.desc}
          placeholder="Description"
          label="Description"
        />
      </div>
      <div className="form__row">{TEXT_CAPTIONS.staffOnlyForce}</div>
    </div>
  );
};

export default NameToFaceActivityForm;
