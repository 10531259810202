import React, { Component } from 'react';
import { Form, StudentList } from 'components';
import get from 'lodash/get';
import './style.scss';

class AddCredit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      familyKids: get(props, 'data.students', []),
      isSplitFamily: Boolean(get(this.props, 'data.subFamilies', []).length),
      formValues: {
        description: get(props, 'data.transactable.description', ''),
        amount: get(props, 'data.transactable.amount', '')
      }
    };

    if (this.state.isSplitFamily) {
      this.state.currentSubFamily = this.getDefaultSubFamily();
    }
  }

  componentDidMount() {
    if (this.state.isSplitFamily) {
      const { family_id } = this.props.data;

      req.studentsByFamily({ family_id }).then(familyKids => {
        this.setState({
          familyKids
        });
      });
    }
  }

  getDefaultSubFamily() {
    let subFamilyId = get(this.props, 'data.subFamilyId');

    if (!subFamilyId) {
      subFamilyId = this.props.data.subFamilies[0].id;
    }

    return subFamilyId;
  }

  @bind
  change(values) {
    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        ...values
      },
      currentSubFamily:
        values.sub_family_id && prevState.currentSubFamily !== values.sub_family_id
          ? values.sub_family_id
          : prevState.currentSubFamily
    }));
  }

  getKids() {
    const { familyKids, currentSubFamily, isSplitFamily } = this.state;

    if (!isSplitFamily || !currentSubFamily) return familyKids;

    const subFamilies = get(this.props, 'data.subFamilies', []);
    const subFamily = subFamilies.find(sf => sf.id === currentSubFamily) || {};
    const kidIds = subFamily.kid_ids || [];

    return familyKids.filter(k => kidIds.includes(k.id));
  }

  @bind
  submit(values) {
    const { family_id, transactable } = this.props.data;
    if (transactable) {
      this.setState({ isLoading: true });
      req
        .editCredit({
          id: transactable.id,
          parent_credit: {
            description: values.description,
            amount: Number(values.amount),
            notes: values.notes
          }
        })
        .then(() => {
          this.setState({ isLoading: false });
          Actions.showFlash('Credit successfully updated');
          this.props.onClose(true);
        })
        .catch(err => {
          console.log(err);
          this.setState({ isLoading: false });
          Actions.showFlash('There was a problem updating the credit', 'error');
        });
      return;
    }

    const parentCredit = {
      description: values.description,
      amount: Number(values.amount),
      notes: values.notes,
      family_id,
      sub_family_id: values.sub_family_id
    };

    this.setState({ isLoading: true });
    req
      .addCredit({ parent_credit: parentCredit })
      .then(() => {
        this.setState({ isLoading: false });
        Actions.showFlash('Credit successfully added');
        this.props.onClose(true);
      })
      .catch(err => {
        console.log(err);
        this.setState({ isLoading: false });
        Actions.showFlash('There was a problem adding the credit', 'error');
      });
  }

  render() {
    const { isLoading, isSplitFamily, formValues } = this.state;
    const { transactable, subFamilies, amount } = this.props.data;
    const defaultValues = transactable || {};
    const title = transactable ? 'Edit Credit' : 'Add Credit';

    return (
      <Form
        className="modal__wrapper add-credit"
        onChange={this.change}
        onSubmit={this.submit}
        validateOn="submit"
        isLoading={isLoading}
      >
        <div className="modal__header">
          <div className="subheader">{title}</div>
          {isSplitFamily && !transactable && (
            <div className="form__row add-credit__choose-subfamily">
              <div className="add-credit__choose-subfamily-label">Choose family</div>
              <Form.Select
                className="add-credit__choose-subfamily-select"
                name="sub_family_id"
                title="Choose Family"
                defaultValue={this.getDefaultSubFamily()}
                required
              >
                {subFamilies.map(sf => (
                  <Form.Select.Item key={sf.id} value={sf.id} label={sf.name} />
                ))}
              </Form.Select>
            </div>
          )}
          <span className="modal__header-note">
            <StudentList students={this.getKids()} />
          </span>
        </div>

        <div className="modal__container">
          <div className="form__row">
            <Form.Input
              label="Amount"
              placeholder={amount || '0'}
              className="add-credit__amount"
              name="amount"
              type="amount"
              defaultValue={defaultValues.amount}
              amount
              required
              asterisk
            />
          </div>

          <Form.Textarea
            label="Description"
            placeholder="Add description"
            name="description"
            className="add-credit__description"
            defaultValue={defaultValues.description}
            required
            asterisk
          />

          <Form.Input
            placeholder="Add optional internal note"
            name="notes"
            className="add-credit__notes form-input--highlight"
            defaultValue={defaultValues.notes}
          />

          <div className="form__row form__row--actions">
            <Form.Submit
              label="Submit"
              disabled={!formValues.description || !formValues.amount} // Button is disabled if fields are empty
            />
          </div>
        </div>
      </Form>
    );
  }
}

export default AddCredit;
