import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonV2, ActionButton } from 'components';
import SelectLabelsList from './SelectLabelsList';
import './style.scss';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class SelectLabels extends Component {
  static propTypes = {
    labels: PropTypes.array,
    onChange: PropTypes.func,
    onClose: PropTypes.func
  };

  static defaultProps = {
    labels: []
  };

  @bind
  onSubmit() {
    const { onClose, selectedLabelIds } = this.props;
    onClose({ selectedLabelIds });
  }

  render() {
    const { title } = this.props;

    return (
      <div className="select-labels">
        <div className="modal__header">
          <div className="modal__header-title select-labels__header">
            <span className="select-labels__title">{title}</span>

            <ActionButton
              size={16}
              className="select-labels__manage-btn"
              iconName="manage"
              title="Manage Labels"
              onClick={() => this.props.context.goToStep('manage')}
            />
          </div>
        </div>

        <div className="modal__container select-labels__container">
          <SelectLabelsList
            labels={this.props.labels}
            selectedLabelIds={this.props.selectedLabelIds}
            onChange={this.props.onChange}
            onCreate={() => this.props.context.goToStep('manage')}
          />
        </div>

        <div className="modal__controls select-labels__controls">
          <ButtonV2 label="Submit" onClick={this.onSubmit} />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(SelectLabels);
