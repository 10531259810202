import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'components';
import moment from 'moment';
import GenericEditor from '../GenericEditor';
import './style.scss';

class DateEditor extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    locked: PropTypes.bool,
    disablePast: PropTypes.bool
  };

  getDefaultDate() {
    const { defaultValue } = this.props;

    if (defaultValue) {
      const date = moment(defaultValue, 'YYYY-MM-DD');
      return date.isValid() ? date.toDate() : new Date();
    }

    return undefined;
  }

  @bind
  submit(value) {
    const dateValue = value ? moment(value).format('YYYY-MM-DD') : '';
    return Promise.resolve(this.props.onChange(dateValue));
  }

  render() {
    const { locked, disablePast } = this.props;

    return (
      <GenericEditor
        className="date-editor"
        onSubmit={this.submit}
        defaultValue={this.getDefaultDate()}
        locked={locked}
      >
        {({ value, onChange }) => (
          <DatePicker
            value={value}
            onChange={onChange}
            className="date-editor__date"
            clearable
            disablePast={disablePast}
          />
        )}
      </GenericEditor>
    );
  }
}

export default DateEditor;
