import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollBars from 'react-custom-scrollbars';
import { PageNav } from 'components';
import Row from './Row';

class List extends Component {
  static propTypes = {
    data: PropTypes.array,
    page: PropTypes.number,
    total: PropTypes.number,
    perPage: PropTypes.number,
    onPageChange: PropTypes.func
  };

  static defaultProps = {
    data: [],
    page: 1,
    total: 1,
    perPage: 1
  };

  renderRows() {
    const { data } = this.props;

    if (data.length) {
      return data.map(rowData => <Row key={rowData.id} data={rowData} />);
    } else {
      return <div className="table__row--empty">No information</div>;
    }
  }

  render() {
    const { page, total, perPage, onPageChange, loading } = this.props;
    const rows = this.renderRows();

    return (
      <div className="roster-kids__container">
        {total > 0 && (
          <div className="header header--small">
            <div className="header__inner">Showing {rows.length} results</div>

            <PageNav
              page={page}
              total={total}
              perPage={perPage}
              onPrev={() => onPageChange(page - 1)}
              onNext={() => onPageChange(page + 1)}
              loading={loading}
            />
          </div>
        )}

        <ScrollBars autoHeight autoHeightMin={330}>
          <div className="table">
            <div className="table__header">
              <div className="table__cell">ID</div>
              <div className="table__cell">First name</div>
              <div className="table__cell">Middle name</div>
              <div className="table__cell">Last name</div>
              <div className="table__cell">Status</div>
            </div>
            <div className="table__body">{rows}</div>
          </div>
        </ScrollBars>
      </div>
    );
  }
}

export default List;
