import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormFieldType from 'constants/FormFieldType';
import cx from 'classnames';
import moment from 'moment';
import { capitalize } from 'lib/utils';
import './style.scss';
import { Link } from 'components';

const ignoredFieldTypes = [FormFieldType.Divider];

class FormSummary extends Component {
  static propTypes = {
    title: PropTypes.string,
    itemName: PropTypes.string,
    fields: PropTypes.array,
    values: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    'data-cy': PropTypes.string
  };

  static defaultProps = {
    fields: {},
    values: {}
  };

  getValue(field, values) {
    if (!field) {
      return undefined;
    }

    const value = values[field.id];

    switch (field.type) {
      case FormFieldType.Checkbox:
        return value ? 'Yes' : 'No';
      case FormFieldType.TextBox: {
        if (!field.options.hasCheckbox) {
          return undefined;
        }

        return value === true ? 'Yes' : value === false ? 'No' : '';
      }

      case FormFieldType.DatePicker:
        return value ? moment(value).format('MMM Do, YYYY') : '—';

      case FormFieldType.Select: {
        const { items = [], type } = field.options;

        if (type === 'radio') {
          const item = items.find(i => i.value === value);
          return item ? item.label : undefined;
        }

        if (type === 'checkbox') {
          return items
            .filter(i => (value || []).includes(i.value))
            .map(i => i.label)
            .join(', ');
        }

        break;
      }

      case FormFieldType.FileUpload: {
        if (!Array.isArray(value) || value.length === 0) return undefined;
        return (
          <ul className="form-summary__item-value__list">
            {value.map(file => (
              <li className={cx('form-summary__item-value__list-item', 'form-summary__item-value__list-item--file')}>
                <Link to={file.file_url} target="_blank">
                  {file.name}
                </Link>
              </li>
            ))}
          </ul>
        );
      }

      case FormFieldType.TextInput:
      default:
        return value;
    }
  }

  getDataArray(fields, values) {
    return fields
      .filter(
        f =>
          Object.prototype.hasOwnProperty.call(values, f.id) &&
          (!ignoredFieldTypes.includes(f.type) || (f.type === FormFieldType.TextBox && f.options.hasCheckbox))
      )
      .map(f => {
        return { id: f.id, label: f.options.label, value: this.getValue(f, values), multiline: f.options.multiline };
      });
  }

  renderItems() {
    const { fields, values, itemName } = this.props;

    if (!Array.isArray(values)) {
      return this.getDataArray(fields, values)
        .map(this.renderItem)
        .filter(item => item !== null);
    }

    return values
      .map(v => this.getDataArray(fields, v))
      .map((v, index) => (
        <React.Fragment key={itemName + index}>
          {values.length > 1 && (
            <div className="form-summary__item-header">
              {capitalize(itemName)} #{index + 1}
            </div>
          )}

          {v.map(this.renderItem)}
        </React.Fragment>
      ));
  }

  renderItem(i, index) {
    if (i.value === '' || i.value === undefined) {
      return null;
    }

    return (
      <div key={i.id || `item_${index}`} className={cx('form-summary__item', `form-summary__item--${i.id}`)}>
        <div className="form-summary__item-label">{i.label}</div>

        <div
          className={cx('form-summary__item-value', {
            'form-summary__item-value--multiline': i.multiline
          })}
        >
          {i.value}
        </div>
      </div>
    );
  }

  render() {
    const { title, 'data-cy': dataCY } = this.props;

    const renderedItems = this.renderItems();

    if (!renderedItems.length) return null;

    return (
      <div className="form-summary" data-cy={dataCY}>
        <div className="form-summary__title">{title}</div>

        <div className="form-summary__items">{renderedItems}</div>
      </div>
    );
  }
}

export default FormSummary;
