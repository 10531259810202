import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { playYoutubeVideo } from 'lib/utils';
import './style.scss';

class PlayVideoPanel extends Component {
  static propTypes = {
    youtubeVideoURL: PropTypes.string,
    description: PropTypes.string
  };

  render() {
    const { youtubeVideoURL, description } = this.props;

    if (!youtubeVideoURL || !description) {
      return null;
    }

    return (
      <div className="play-video-panel">
        <div className="play-video-panel__inner" onClick={() => playYoutubeVideo(youtubeVideoURL)}>
          <Icon name="play" className="play-video-panel__icon" />

          <div className="play-video-panel__button">Play video</div>

          <div className="play-video-panel__description">{description}</div>
        </div>
      </div>
    );
  }
}

export default PlayVideoPanel;
