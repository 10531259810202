import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import flatMap from 'lodash/flatMap';
import Item from './Item';

class List extends Component {
  constructor(props) {
    super(props);

    this.scrollbarsRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.subFamilies.length < this.props.subFamilies.length) {
      this.scrollbarsRef.current.scrollToBottom();
    }
  }

  render() {
    const { subFamilies, family, onUpdate, onRemove, validations } = this.props;
    const selected = { carers: flatMap(subFamilies, sf => sf.carers) };

    return (
      <Scrollbars className="subfamilies__scroll" autoHeight autoHeightMax={610} ref={this.scrollbarsRef}>
        <div className="subfamilies__list">
          {subFamilies
            .filter(sf => !sf._destroy)
            .map((sf, index) => (
              <Item
                key={sf.id}
                subFamily={sf}
                indexLabel={index + 1}
                onUpdate={onUpdate(sf.id)}
                onRemove={index > 1 ? onRemove(sf.id) : undefined}
                family={family}
                selected={selected}
                validations={validations[sf.id]}
              />
            ))}
        </div>
      </Scrollbars>
    );
  }
}

export default List;
