import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SignTimeFilter from '../../SignTimeFilter';
import withContext, { FormContext } from 'hocs/withContext';

class SignFilter extends Component {
  static propTypes = {
    name: PropTypes.string,
    rooms: PropTypes.any,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.object,
    useRoom: PropTypes.bool,
    label: PropTypes.string,
    defaultPeriod: PropTypes.oneOf(['before', 'after']),
    'data-cy': PropTypes.string
  };

  UNSAFE_componentWillMount() {
    const { name, defaultValue } = this.props;
    this.props.context.init(name, defaultValue, {});
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  @bind
  handleChange(value) {
    this.props.context.update(this.props.name, value);
  }

  render() {
    const { rooms, defaultValue, label, disabled, useRoom, defaultPeriod, 'data-cy': dataCY } = this.props;

    return (
      <SignTimeFilter
        label={label}
        rooms={rooms}
        onChange={this.handleChange}
        defaultValue={defaultValue}
        defaultPeriod={defaultPeriod}
        useRoom={useRoom}
        disabled={disabled}
        data-cy={dataCY}
      />
    );
  }
}

export default withContext(FormContext)(SignFilter);
