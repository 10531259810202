import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import chunk from 'lodash/chunk';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { ButtonV2, Checkbox, Icon } from 'components';
import './style.scss';

class EditRoomAssignment extends Component {
  static propTypes = {
    // A function that closes the modal window
    onClose: PropTypes.func,

    // Additional data
    data: PropTypes.object
  };

  constructor(...args) {
    super(...args);

    const { primarySectionId, additionalSectionIds } = this.props.data;

    this.state = {
      primaryId: primarySectionId,
      additionalIds: [...additionalSectionIds],
      isLoading: false
    };
  }

  @bind
  async submit() {
    const { onClose } = this.props;
    const { staffId, successMessage = 'Updated Staff' } = this.props.data;
    const { additionalIds, primaryId } = this.state;

    this.setState({ isLoading: true });

    const payload = {
      id: staffId,
      teacher: {
        section_id: primaryId,
        secondary_section_ids: additionalIds,
        skip_invite: true // always skip invite on update
      }
    };

    try {
      await req.updateStaffItem(payload);

      Actions.showFlash(successMessage);
      onClose(true);
    } catch (err) {
      this.setState({ isLoading: false });
      Actions.reportError('There was problem updating staff', err);
    }
  }

  @bind
  onPrimaryClick(id) {
    this.setState(state => ({
      primaryId: state.primaryId === id ? null : id
    }));
  }

  @bind
  onSectionChange(sectionId) {
    const { additionalIds } = this.state;
    let nextIds;

    if (additionalIds.includes(sectionId)) {
      nextIds = additionalIds.filter(id => sectionId !== id);
    } else {
      nextIds = [...additionalIds, sectionId];
    }
    this.setState({
      additionalIds: nextIds
    });
  }

  render() {
    const { isLoading, primaryId, additionalIds } = this.state;
    const { sections, onClose } = this.props;

    return (
      <div className="edit-staff-sections">
        <div className="modal__header">
          <span className="modal__header-title">Edit Room Assignment</span>
        </div>

        <div className="modal__container">
          <div className="edit-staff-sections__description">
            To assign a <b>primary</b> room (optional) to staff member select icon{' '}
            <Icon className="edit-staff-sections__description-primary" name="room" size={26} /> while hovering over the
            room name.
            <br />
            <b>additional</b> rooms can be assigned by selecting checkboxes.
          </div>
          <div className="edit-staff-sections__options">
            <Scrollbars autoHeight autoHeightMin={0} thumbSize={48}>
              {chunk(sections, 2).map(chunk => {
                return (
                  <div className="edit-staff-sections__options-row">
                    {chunk.map(section => {
                      return (
                        <div className="edit-staff-sections__options-item">
                          <Checkbox
                            key={section.id}
                            checked={additionalIds.includes(section.id)}
                            data-cy={`check-room-${section.id}`}
                            label={section.name}
                            type={'square'}
                            disabled={isLoading}
                            onChange={() => this.onSectionChange(section.id)}
                          />
                          <Icon
                            className={cx('edit-staff-sections__options-primary', {
                              'edit-staff-sections__options-primary--selected': primaryId === section.id
                            })}
                            name={primaryId === section.id ? 'room-gradient' : 'room'}
                            size={26}
                            data-cy={`primary-room-${section.id}`}
                            onClick={() => this.onPrimaryClick(section.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Scrollbars>
          </div>
        </div>
        <div className="modal__controls">
          <ButtonV2 label={'Cancel'} secondary onClick={onClose} />
          <ButtonV2 label={'Save Changes'} data-cy={'save'} onClick={this.submit} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sections: state.rooms.data
});

export default connect(mapStateToProps)(EditRoomAssignment);
