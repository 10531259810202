import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SortableElement, SortableContainer, arrayMove } from 'react-sortable-hoc';
import Scrollbars from 'react-custom-scrollbars';
import ManageListNew from './ManageListNew';
import ManageListItem from './ManageListItem';
import './style.scss';

const SortableManageListItem = SortableElement(ManageListItem);
const SortContainer = SortableContainer(({ className, children }) => <div className={className}>{children}</div>);

class ManageList extends PureComponent {
  static propTypes = {
    // An array of items
    items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),

    // Is new item form rendered
    isNew: PropTypes.bool,

    // Id of edited item
    editItemId: PropTypes.string,

    // Create new item using data from new form
    onNewCreate: PropTypes.func,

    // Cancel new item creation, close form
    onNewCancel: PropTypes.func,

    // Display edit form of an item
    onEditStart: PropTypes.func,

    // Hide edit form of an item
    onEditEnd: PropTypes.func,

    // Write changes of item
    onUpdate: PropTypes.func,

    // Write changes of all items
    onReplace: PropTypes.func,

    // Delete an item
    onDelete: PropTypes.func,

    // A component type used for rendering a single item's content
    ItemComponent: PropTypes.any
  };

  // updateId and deleteId are used to display loading/disabled state for items
  state = {
    updateId: undefined,
    deleteId: undefined
  };

  @bind
  handleUpdate(updateId, item) {
    const updateResult = this.props.onUpdate(updateId, item);

    if (updateResult instanceof Promise) {
      updateResult
        .then(() => {
          this.setState({ updateId: undefined });
        })
        .catch(() => {
          this.setState({ updateId: undefined });
        });
    }
  }

  @bind
  handleDelete(deleteId) {
    const deleteResult = this.props.onDelete(deleteId);

    if (deleteResult instanceof Promise) {
      this.setState({ deleteId });
      deleteResult.then(() => this.setState({ deleteId: undefined }));
    }
  }

  @bind
  handleSortEnd({ oldIndex, newIndex }) {
    const prevItems = [...this.props.items];
    const nextItems = arrayMove(prevItems, oldIndex, newIndex);
    this.props.onReplace(nextItems);
  }

  renderItems() {
    const { items, sortable, ItemComponent, editItemId, onEditStart, onEditEnd } = this.props;
    const { updateId, deleteId } = this.state;
    const ItemType = sortable ? SortableManageListItem : ManageListItem;

    return items.map((i, index) => (
      <ItemType
        key={i.id}
        sortable={sortable}
        ItemComponent={ItemComponent}
        isEdit={i.id === editItemId}
        item={i}
        index={sortable ? index : undefined}
        controlsDisabled={Boolean(updateId || deleteId)}
        onEditStart={onEditStart}
        onEditEnd={onEditEnd}
        onUpdate={this.handleUpdate}
        onDelete={this.handleDelete}
        isUpdateLoading={i.id === updateId}
        isDeleteLoading={i.id === deleteId}
      />
    ));
  }

  renderItemsContainer() {
    const { sortable } = this.props;

    if (!sortable) {
      return <div className="manage-list__items">{this.renderItems()}</div>;
    }

    return (
      <SortContainer
        lockAxis="y"
        helperClass="manage-list-item__sort-helper"
        onSortStart={this.handleSortStart}
        onSortEnd={this.handleSortEnd}
        useDragHandle
      >
        {this.renderItems()}
      </SortContainer>
    );
  }

  render() {
    const { ItemComponent, isNew, onNewCreate, onNewCancel } = this.props;

    return (
      <div className="manage-list">
        {isNew && <ManageListNew ItemComponent={ItemComponent} onCreate={onNewCreate} onCancel={onNewCancel} />}

        <div className="manage-list__list">
          <Scrollbars className="manage-list__scroll">{this.renderItemsContainer()}</Scrollbars>
        </div>
      </div>
    );
  }
}

export default ManageList;
