import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LabeledCheckbox } from 'components';

class TemplateItem extends PureComponent {
  static propTypes = {
    template: PropTypes.shape({
      id: PropTypes.string,
      subject: PropTypes.string
    }),
    selected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired
  };

  static defaultProps = {
    selected: false
  };

  @bind
  handleSelect() {
    this.props.onSelect(this.props.template.id);
  }

  render() {
    const { template, selected } = this.props;

    return (
      <div className="communication-send-msg__templates-item">
        <div className="communication-send-msg__templates-item-name">
          {template.subject}
          {template.kind === 'sms' && template.content}
        </div>

        <LabeledCheckbox
          className="communication-send-msg__templates-item-checkbox"
          checked={selected}
          onChange={this.handleSelect}
        />
      </div>
    );
  }
}

export default TemplateItem;
