import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { ButtonV2 } from 'components';
import FilterBar from './FilterBar';
import List from './List';
import './style.scss';

class Roster extends Component {
  static propTypes = {
    kids: PropTypes.array
  };

  static initialState = {
    query: '',
    page: 1
  };

  constructor(props) {
    super(props);
    this.state = { ...Roster.initialState };

    this.requestData = debounce(this.requestData, 500);
  }

  UNSAFE_componentWillMount() {
    req.kidsRoster();
  }

  @bind
  updateFilters(values) {
    this.setState({ query: values.search, page: 1 }, this.requestData);
  }

  @bind
  changePage(page) {
    if (page < 1 || page >= this.props.total) {
      return;
    }

    this.setState({ page }, this.requestData);
  }

  @bind
  requestData() {
    const { query, page } = this.state;

    req.kidsRoster({
      q: query,
      page: page
    });
  }

  render() {
    const { page } = this.state;
    const { kids, total, perPage, isLoading, onClose } = this.props;

    return (
      <div className="modal__wrapper roster-kids">
        <div className="modal__header">
          <div className="modal__header-title">Student Roster</div>
          <div className="modal__filter-bar">
            <FilterBar onUpdate={this.updateFilters} />
          </div>
        </div>

        <List
          data={kids}
          page={page}
          total={total}
          perPage={perPage}
          onPageChange={this.changePage}
          loading={isLoading}
        />

        <div className="modal__controls">
          <ButtonV2 label="Close" onClick={onClose} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.kidsRoster.loading,
  perPage: state.kidsRoster.data.per_page,
  total: state.kidsRoster.data.total,
  kids: state.kidsRoster.data.roster_kids
});

export default connect(mapStateToProps)(Roster);
