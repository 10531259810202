import React from 'react';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import { Avatar, ActionButton, ContextMenu, Icon, TextInput } from 'components';

function Item({ indexLabel, subFamily, onUpdate, onRemove, family, validations = {}, selected }) {
  const handleNameChange = debounce(value => {
    onUpdate({ name: value });
  }, 300);

  const handleAdd = (id, collectionName) => {
    const nextValues = [...subFamily[collectionName]];
    nextValues.push(family[collectionName].find(item => item.id === id));
    onUpdate({ [collectionName]: nextValues });
  };

  const handleRemove = (id, collectionName) => {
    const nextValues = [...subFamily[collectionName]];
    onUpdate({ [collectionName]: nextValues.filter(item => item.id !== id) });
  };

  const renderItems = (title, type) => {
    const items = subFamily[type];
    const itemsValidation = validations[type];

    return (
      <div className="subfamily__row subfamily__row--divider">
        <span className="subfamily__row-title">{title}</span>
        <div className="subfamily__list">
          {items.length ? (
            items.map(item => (
              <div key={item.id} className="subfamily__list-item">
                <Avatar
                  size="xs"
                  name={item.name || item.first_name + ' ' + item.last_name}
                  url={item.profile_pic_url}
                  tooltipText={item.name}
                  color={item.color}
                  initials={item.initials}
                />
                <div>
                  <div>{item.name || item.first_name + ' ' + item.last_name}</div>
                  <div className="subfamily__list-item-controls">
                    <ActionButton
                      title="remove"
                      className="subfamily__remove"
                      onClick={() => handleRemove(item.id, type)}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="subfamily__row-empty-placeholder">
              {`No ${title} selected`}
              {itemsValidation && (
                <div className="subfamily__validation-text">
                  <Icon name="alert" /> {itemsValidation}
                </div>
              )}
            </div>
          )}
          {renderAddItems(type)}
        </div>
      </div>
    );
  };

  const renderKids = () => renderItems('Children', 'kids');
  const renderCarers = () => renderItems('Parents', 'carers');

  const renderAddItems = type => {
    const familyItems = family[type];
    const selectedItems = selected[type] || subFamily[type];

    if (selectedItems.length === familyItems.length) return null;

    const label = `Add ${type === 'carers' ? 'Parent' : 'Child'}`;

    return (
      <div className="subfamily__list-add">
        <ContextMenu iconName="add" iconSize={16} label={label}>
          {familyItems
            .filter(familyItem => selectedItems.findIndex(item => familyItem.id === item.id) === -1)
            .map(item => (
              <li key={item.id} className="subfamily__list-add-item" onClick={() => handleAdd(item.id, type)}>
                {item.name || item.first_name + ' ' + item.last_name}
              </li>
            ))}
        </ContextMenu>
      </div>
    );
  };

  return (
    <div className="subfamilies__list-item subfamily">
      <div className="subfamily__row subfamily__row--stretched">
        <span className="subfamily__row-title">{`family #${indexLabel}`}</span>
        <div className="subfamily__name">
          <div
            className={cx('form__row form--inline', {
              'form-input--invalid': Boolean(validations.name)
            })}
          >
            <TextInput
              name="name"
              placeholder="Family name"
              defaultValue={subFamily.name}
              onChange={handleNameChange}
            />
            {validations.name && <Icon name="alert" className="form-input__invalid-icon" />}
          </div>
          {validations.name && <div className="form-input__validation-text">{validations.name}</div>}
        </div>
      </div>
      {renderKids()}
      {renderCarers()}
      {onRemove && <ActionButton className="subfamily__delete" size={14} iconName="delete" onClick={onRemove} />}
    </div>
  );
}

export default Item;
