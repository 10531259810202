import React, { Component } from 'react';
import { ButtonV2, StudentList } from 'components';
import './style.scss';

class ToggleAutopay extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isSaving: false
    };
  }

  @bind
  onToggle() {
    const {
      data: { paymentMethod }
    } = this.props;

    this.setState({ isSaving: true });
    req
      .schoolParentUpdatePaymentMethod({
        id: paymentMethod.id,
        payment_method: {
          auto_pay: !paymentMethod.auto_pay
        }
      })
      .then(() => {
        Actions.showFlash(`AutoPay turned ${paymentMethod.auto_pay ? 'OFF' : 'ON'}`);
        this.setState({ isSaving: false });
        this.props.onClose(true);
      })
      .catch(e => {
        Actions.reportError('Unable to toggle autopay', e);
        this.setState({ isSaving: false });
      });
  }

  render() {
    const { data, onClose } = this.props;
    const { paymentMethod, students } = data;

    const { isSaving } = this.state;

    return (
      <div className="modal__wrapper toggle-autopay">
        <div className="modal__container">
          <div className="toggle-autopay__kid">
            <div className="toggle-autopay__title">Toggle AutoPay for </div>
            <StudentList students={students} showRoom />
          </div>
          <div className="toggle-autopay__message">
            {paymentMethod.auto_pay
              ? 'Do you want to turn off autopay for the family?'
              : 'Do you want to turn on autopay for the family? Payments will be automatically debited on the invoice due dates.'}
          </div>
        </div>
        <div className="modal__controls">
          <ButtonV2 onClick={onClose} secondary label="No" />
          <ButtonV2 onClick={this.onToggle} label="Yes" isLoading={isSaving} />
        </div>
      </div>
    );
  }
}

export default ToggleAutopay;
