import React, { Component } from 'react';
import PhotoThumb from '../PhotoThumb';

export default class MedicationActivity extends Component {
  render() {
    const { activity } = this.props;
    return (
      <div className="activity__medication">
        <div className="activity__paragraph">{activity.data.desc}</div>
        <PhotoThumb photoUrl={activity.photo_url} />
      </div>
    );
  }
}
