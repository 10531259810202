import moment from 'moment';

export const ALLERGY_REPORT = 'allergy_report';

export const presets = [
  {
    value: 'students_info',
    label: 'Students Info',
    filename: 'students_info'
  },
  {
    value: 'age_report',
    label: 'Age Report',
    filename: 'age'
  },
  {
    value: ALLERGY_REPORT,
    label: 'Allergy Report',
    filename: 'allergy'
  },
  {
    value: 'students_family_info',
    label: 'Students And Family Info',
    filename: 'students_family_info'
  },
  {
    value: 'custom',
    label: 'Custom (all fields)',
    filename: 'custom'
  }
];

export const TE_DATE_RANGE_TYPES = [
  {
    key: 'thisMonth',
    label: 'This Month',
    dateRange: [moment().startOf('month'), moment().endOf('month')]
  },
  {
    key: 'lastMonth',
    label: 'Last Month',
    dateRange: [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ]
  },
  {
    key: 'thisYear',
    label: 'This Year',
    dateRange: [moment().startOf('year'), moment().endOf('year')]
  },
  {
    key: 'lastYear',
    label: 'Last Year',
    dateRange: [
      moment()
        .subtract(1, 'year')
        .startOf('year'),
      moment()
        .subtract(1, 'year')
        .endOf('year')
    ]
  },
  {
    key: 'ytd',
    label: 'YTD',
    dateRange: [moment().startOf('year'), moment()]
  },
  {
    key: 'custom',
    label: 'Custom',
    dateRange: null
  }
];

export const TE_DATE_SINCE_TYPES = [
  {
    key: '30',
    label: '30 days',
    dateRange: [moment().subtract(30, 'days'), moment()]
  },
  {
    key: '60',
    label: '60 days',
    dateRange: [moment().subtract(60, 'days'), moment()]
  },
  {
    key: '90',
    label: '90 days',
    dateRange: [moment().subtract(90, 'days'), moment()]
  }
];

export default {
  staff: [
    { value: 'staff_id', label: 'Staff ID', default: true },
    { value: 'name', label: 'Name', default: true },
    { value: 'dob', label: 'DOB' },
    { value: 'email', label: 'Email' },
    { value: 'phone', label: 'Phone' },
    { value: 'address', label: 'Address' },
    { value: 'room', label: 'Room', default: true },
    { value: 'pin', label: 'Clock In-Out Pin' },
    { value: 'start_date', label: 'Hire Date' },
    { value: 'student_ratio', label: 'Allowed Student Ratio' },
    { value: 'is_admin', label: 'Is Admin', default: true },
    { value: 'is_active', label: 'Is Active' }
  ],
  student: [
    {
      value: 'id',
      label: 'Student ID',
      default: true,
      preset: ['students_info', 'age_report', 'allergy_report', 'students_family_info']
    },
    {
      value: 'first_name',
      label: 'First Name',
      default: true,
      preset: ['students_info', 'age_report', 'allergy_report', 'students_family_info']
    },
    { value: 'middle_name', label: 'Middle Name' },
    {
      value: 'last_name',
      label: 'Last Name',
      default: true,
      preset: ['students_info', 'age_report', 'allergy_report', 'students_family_info']
    },
    {
      value: 'dob',
      label: 'DOB',
      default: true,
      preset: ['students_info', 'age_report', 'allergy_report', 'students_family_info']
    },
    {
      value: 'age',
      label: 'Age',
      preset: ['age_report']
    },
    { value: 'street_address', label: 'Street Address', preset: ['students_info'] },
    { value: 'city', label: 'City', preset: ['students_info', 'students_family_info'] },
    { value: 'state', label: 'State', preset: ['students_info', 'students_family_info'] },
    { value: 'zip', label: 'Zip', preset: ['students_info', 'students_family_info'] },
    { value: 'country', label: 'Country', preset: ['students_info', 'students_family_info'] },
    {
      value: 'tags',
      label: 'Tags',
      preset: ['students_info', 'age_report', 'allergy_report', 'students_family_info']
    },
    {
      value: 'room',
      label: 'Room',
      default: true,
      preset: ['students_info', 'age_report', 'allergy_report', 'students_family_info']
    },
    {
      value: 'status',
      label: 'Status',
      preset: ['students_info', 'allergy_report', 'students_family_info']
    },
    { value: 'note', label: 'Note', preset: ['students_info', 'allergy_report'] },
    { value: 'allergy', label: 'Allergies', preset: ['students_info', 'allergy_report'] },
    { value: 'time_schedule', label: 'Schedule', preset: ['students_info', 'students_family_info'] },
    // { value: 'family_id', label: 'Family ID' },
    { value: 'medications', label: 'Medications', preset: ['students_info', 'allergy_report'] },
    { value: 'diet_restriction', label: 'Diet Restriction', preset: [] },
    // { value: 'status_changed_at', label: 'Status Changed at' },
    { value: 'admission_date', label: 'Admission Date', preset: ['students_info'] },
    { value: 'graduation_date', label: 'Graduation Date', preset: ['students_info'] },
    {
      value: 'student_id', // meta_student_id
      label: 'External Student ID',
      preset: ['students_info', 'allergy_report', 'age_report', 'students_family_info']
    }
  ],
  carer: [
    { value: 'family_id', label: 'Family ID', preset: ['students_family_info'] },
    { value: 'first_name', label: 'Carer First Name', default: true, preset: ['students_family_info'] },
    { value: 'last_name', label: 'Carer Last Name', default: true, preset: ['students_family_info'] },
    // { value: 'name', label: 'Name' },
    // { value: 'relation', label: 'Relation' },
    { value: 'actual_relation', label: 'Relation', preset: ['students_family_info'] },
    { value: 'email', label: 'Email', default: true, preset: ['students_family_info'] },
    { value: 'mobile_phone', label: 'Mobile Phone', default: true, preset: ['students_family_info'] },
    { value: 'pin', label: 'Sign In-Out Pin', preset: ['students_family_info'] },
    // { value: 'company', label: 'Company' },
    // { value: 'home_phone,', label: 'Home Phone' },
    // { value: 'phone', label: 'Phone' },
    // { value: 'meta_parent_id', label: 'Meta Parent ID' },
    { value: 'invitation_sent_at', label: 'Invitation Sent At' },
    { value: 'emergency_contact', label: 'Emergency Contact' }
    // { value: 'pin_sent_at', label: 'PIN Sent at' }
  ]
};

export const STUDENTS_FAMILY_REPORT = {
  student_information: [
    {
      value: 'id',
      label: 'Student ID',
      default: true,
      preset: ['students_info', 'age_report', 'allergy_report', 'students_family_info']
    },
    {
      value: 'first_name',
      label: 'First Name',
      default: true,
      preset: ['students_info', 'age_report', 'allergy_report', 'students_family_info']
    },
    {
      value: 'last_name',
      label: 'Last Name',
      default: true,
      preset: ['students_info', 'age_report', 'allergy_report', 'students_family_info']
    },
    {
      value: 'dob',
      label: 'DOB',
      default: true,
      preset: ['students_info', 'age_report', 'allergy_report', 'students_family_info']
    },
    { value: 'city', label: 'City', preset: ['students_info', 'students_family_info'] },
    { value: 'state', label: 'State', preset: ['students_info', 'students_family_info'] },
    { value: 'zip', label: 'Zip', preset: ['students_info', 'students_family_info'] },
    { value: 'country', label: 'Country', preset: ['students_info', 'students_family_info'] },
    {
      value: 'tags',
      label: 'Tags',
      preset: ['students_info', 'age_report', 'allergy_report', 'students_family_info']
    },
    {
      value: 'room',
      label: 'Room',
      default: true,
      preset: ['students_info', 'age_report', 'allergy_report', 'students_family_info']
    },
    {
      value: 'status',
      label: 'Status',
      preset: ['students_info', 'allergy_report', 'students_family_info']
    },
    { value: 'time_schedule', label: 'Schedule', preset: ['students_info', 'students_family_info'] },
    {
      value: 'student_id', // meta_student_id
      label: 'External Student ID',
      preset: ['students_info', 'allergy_report', 'age_report', 'students_family_info']
    }
  ],
  carer_info: [
    { value: 'family_id', label: 'Family ID', preset: ['students_family_info'] },
    { value: 'first_name', label: 'Parent First Name', default: true, preset: ['students_family_info'] },
    { value: 'last_name', label: 'Parent Last Name', default: true, preset: ['students_family_info'] },
    { value: 'actual_relation', label: 'Relation', preset: ['students_family_info'] },
    { value: 'email', label: 'Email', default: true, preset: ['students_family_info'] },
    { value: 'mobile_phone', label: 'Mobile Phone', default: true, preset: ['students_family_info'] },
    { value: 'pin', label: 'Sign In-Out Pin', preset: ['students_family_info'] },
    { value: 'emergency_contact', label: 'Emergency Contact' }
  ],
  authorized_pickup_info : [
    { value: 'family_id', label: 'Family ID', preset: ['students_family_info'] },
    { value: 'first_name', label: 'Pickup First Name', default: true, preset: ['students_family_info'] },
    { value: 'last_name', label: 'Pickup Last Name', default: true, preset: ['students_family_info'] },
    { value: 'actual_relation', label: 'Relation', preset: ['students_family_info'] },
    { value: 'mobile_phone', label: 'Mobile Phone', default: true, preset: ['students_family_info'] },
    { value: 'pin', label: 'Sign In-Out Pin', preset: ['students_family_info'] },
    { value: 'emergency_contact', label: 'Emergency Contact' }
  ]
}
