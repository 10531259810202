import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, SelectGroup, Checkbox } from 'components';

import './styles.scss';

class AgeFilter extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.object
  };

  constructor(props) {
    super(props);

    const values = props.defaultValue || {};
    const { option = 'less', months = [0] } = values;

    this.state = {
      disabled: !props.defaultValue,
      option: option,
      months: months
    };
  }

  handleChange() {
    if (this.props.onChange) {
      this.props.onChange(this.getValue());
    }
  }

  getValue() {
    const { option, months, disabled } = this.state;

    if (disabled) {
      return undefined;
    }

    return {
      option,
      months
    };
  }

  @bind
  handleOptionChange(optionValue) {
    this.setState(
      {
        option: optionValue
      },
      this.handleChange
    );
  }

  @bind
  handleMonthsChange(index) {
    return monthValue => {
      const nextMonths = [...this.state.months];
      nextMonths[index] = monthValue;
      this.setState(
        {
          months: nextMonths
        },
        this.handleChange
      );
    };
  }

  @bind
  handleDisabledChange() {
    this.setState(
      prevState => ({
        disabled: !prevState.disabled
      }),
      this.handleChange
    );
  }

  render() {
    const { months, option, disabled } = this.state;

    return (
      <div className="age-filter">
        <div className="age-filter__title">
          <Checkbox
            type="square"
            size="small"
            name="disabled"
            label="Age"
            checked={!disabled}
            onChange={this.handleDisabledChange}
          />
          <div className="age-filter__title-months">Months</div>
        </div>

        <div className="age-filter__body">
          <SelectGroup
            type="radio"
            title="Select Option"
            className="age-filter__option"
            checked={option}
            onChange={this.handleOptionChange}
            disabled={disabled}
          >
            <SelectGroup.Item key="less" value="less" label="Less Than" />
            <SelectGroup.Item key="greater" value="greater" label="Greater Than" />
            <SelectGroup.Item key="range" value="range" label="Range" />
          </SelectGroup>
          <div className="age-filter__months">
            <TextInput
              name="months_start"
              maxLength={3}
              naturalNumber
              type="number"
              min={0}
              value={months[0] || 0}
              onChange={this.handleMonthsChange(0)}
              disabled={disabled}
            />
            {option === 'range' && (
              <TextInput
                name="months_end"
                maxLength={3}
                naturalNumber
                type="number"
                min={0}
                onChange={this.handleMonthsChange(1)}
                value={months[1] || 0}
                disabled={disabled}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AgeFilter;
