import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToggleButton, Form } from 'components';

class Addon extends Component {
  static propTypes = {
    addon: PropTypes.object,
    onAdd: PropTypes.func,
    onUpdate: PropTypes.func,
    onRemove: PropTypes.func,
    isLast: PropTypes.bool
  };

  static defaultProps = {
    addon: {}
  };

  @bind
  handleUpdate(values) {
    const { addon } = this.props;
    this.props.onUpdate(addon.id, { ...addon, ...values });
  }

  @bind
  handleRemove() {
    this.props.onRemove(this.props.addon.id);
  }

  render() {
    const { addon, onAdd, isLast, id } = this.props;

    return (
      <Form className="table__row addon" onChange={this.handleUpdate}>
        <div className="table__cell table__cell--l">
          <Form.Input name="name" className="addon__name" placeholder="Addon name" defaultValue={addon.name} />
        </div>

        <div className="table__cell table__cell--s">
          <Form.Input
            name="cost"
            type="amount"
            className="addon__price"
            placeholder="100.00"
            defaultValue={addon.cost}
          />
        </div>

        <div className="table__cell table__cell--xs">
          <Form.Checkbox name="required" className="addon__required" label="Yes" defaultValue={addon.required} />
        </div>

        <div className="table__cell table__cell--xxs table__cell--actions">
          {isLast ? (
            <ToggleButton
              flat
              key={`${isLast}-${id}`}
              iconName="add-blue"
              iconSize={16}
              className="addon__add-new"
              onClick={onAdd}
            />
          ) : (
            <ToggleButton
              flat
              key={`${isLast}-${id}`}
              iconColor="#999"
              iconName="close-circle"
              iconSize={16}
              className="addon__remove"
              onClick={this.handleRemove}
            />
          )}
        </div>
      </Form>
    );
  }
}

export default Addon;
