import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';
import moment from 'moment';

const FilterItemDateRange = ({ defaultValue = [], name, useName, label = [] }) => (
  <div className="form__row">
    <div style={{ width: '100%' }}>
      {!Array.isArray(label) && <div className="form__label">{label}</div>}
      <div style={{ display: 'flex' }} className="form__row--justified">
        <Form.DatePicker
          name={useName ? `${name}_from` : 'from'}
          data-cy={'from'}
          label={Array.isArray(label) ? label[0] || 'From' : undefined}
          defaultValue={defaultValue[0] ? moment(defaultValue[0]).toDate() : undefined}
        />
        <Form.DatePicker
          name={useName ? `${name}_to` : 'to'}
          data-cy={'to'}
          label={Array.isArray(label) ? label[1] || 'To' : undefined}
          defaultValue={defaultValue[1] ? moment(defaultValue[1]).toDate() : undefined}
        />
      </div>
    </div>
  </div>
);

FilterItemDateRange.propTypes = {
  defaultValue: PropTypes.array
};

export default FilterItemDateRange;
