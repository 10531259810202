import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ActionButton, ContextMenu, ContextMenuItem, Preloader } from 'components';

const ManageTableItem = ({
  item,
  ItemComponent,
  isEdit,
  renderCustom,
  menu,
  itemProps,
  isUpdateLoading,
  isDeleteLoading,
  onEditStart,
  onEditEnd,
  onUpdate,
  onDelete
}) => {
  const [editedItem, setEditedItem] = useState(undefined);
  const [disableCreate, setDisableCreate] = useState(false);

  const handleChange = (newItem) => {
    setEditedItem((prev) => {
      if (newItem.title !== prev?.title) {
        setDisableCreate(false);
      }
      return { ...prev, ...newItem };
    });
  };

  const updateItem = async () => {
    try {
      await onUpdate(item.id, { ...editedItem });
      setEditedItem(undefined);
    } catch (e) {
      const formErrors = e.response?.data?.form_errors;
      if (formErrors && formErrors.title) {
        setDisableCreate(true);
      }
    }
  };

  const editItemStart = () => {
    setEditedItem({ ...item });
    onEditStart(item.id);
  };

  const deleteItem = () => {
    onDelete(item.id);
  };

  const getDefaultMenuItems = () => [
    { key: 'edit', label: 'Edit', onClick: editItemStart },
    { key: 'delete', label: 'Delete', onClick: deleteItem }
  ];

  const renderContent = () => {
    if (ItemComponent) {
      return (
        <ItemComponent
          key={item.id}
          item={item}
          editedItem={editedItem}
          isEdit={isEdit}
          onEditStart={editItemStart}
          onEditEnd={onEditEnd}
          onChange={handleChange}
          onDelete={deleteItem}
          renderMenu={renderCustom ? renderMenu : undefined}
          renderControls={renderCustom ? renderControls : undefined}
          {...itemProps}
        />
      );
    }
  };

  const renderControls = () => (
    <div className="manage-table-item__controls">
      {isUpdateLoading ? (
        <div className="manage-table-item__preloader">
          <Preloader small />
        </div>
      ) : (
        <ActionButton square size={14} iconName="tick-1" onClick={updateItem} disabled={disableCreate} />
      )}
      <ActionButton square size={14} iconName="close" onClick={onEditEnd} disabled={isUpdateLoading} />
    </div>
  );

  const renderMenu = (options) => {
    const menuItems = menu || getDefaultMenuItems();

    return (
      <ContextMenu className="manage-table-item__menu">
        {menuItems
          .filter((i) => (options && i.key ? options[i.key] : true))
          .map((i) => (
            <ContextMenuItem key={i.label} {...i} />
          ))}
      </ContextMenu>
    );
  };

  if (renderCustom) {
    return renderContent();
  }

  return (
    <div className="table__row manage-table-item">
      {renderContent()}
      <div className="table__cell table__cell--xs table__cell--actions">
        {isEdit ? renderControls() : renderMenu()}
      </div>
    </div>
  );
};

ManageTableItem.propTypes = {
  item: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  ItemComponent: PropTypes.any,
  isEdit: PropTypes.bool,
  renderCustom: PropTypes.bool,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      value: PropTypes.any,
      onClick: PropTypes.func
    })
  ),
  itemProps: PropTypes.object,
  isUpdateLoading: PropTypes.bool,
  isDeleteLoading: PropTypes.bool,
  onEditStart: PropTypes.func,
  onEditEnd: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func
};

export default ManageTableItem;
