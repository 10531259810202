import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, TooltipTrigger } from 'components';
import { plural, capitalize } from 'lib/utils';
import moment from 'moment';
import { RegistrationStatusServices } from './Items/RegistrationStatus';

class AppliedItem extends Component {
  static propTypes = {
    rooms: PropTypes.array,
    tags: PropTypes.array,

    value: PropTypes.any,
    extra: PropTypes.object,
    config: PropTypes.object,
    onCancel: PropTypes.func,
    readonly: PropTypes.bool
  };

  static defaultProps = {
    tags: [],
    rooms: [],

    value: null,
    config: {},
    extra: {},
    onCancel: () => undefined,
    readonly: false
  };

  getValue(value, index) {
    const { rooms, tags, lessonCategories, milestoneCategories, config, extra } = this.props;

    switch (config.type) {
      case 'room': {
        const room = rooms.find(room => room.id === value);
        return room && room.name;
      }
      case 'lesson-category': {
        const category = lessonCategories.find(category => category.id === value);
        return category && category.name;
      }
      case 'milestone-categories': {
        const category = milestoneCategories.find(category => category.id === value);
        return category && category.name;
      }
      case 'tags': {
        const tag = tags.find(tag => tag.id === value);
        return tag ? tag.name : value;
      }
      case 'multi-select':
      case 'hidden-select':
      case 'checkbox-group':
      case 'select': {
        return config.options[value];
      }
      case 'typeahead': {
        const options = config.loadOptions && extra.options ? extra.options : config.options;
        const option = (options || []).find(o => o.id === value);
        return option && option.label;
      }
      case 'date-picker': {
        return moment(value).format('YYYY-MM-DD');
      }
      case 'date-range': {
        if (index === 0) {
          const fromLabel = config.appliedLabel && config.appliedLabel.from ? config.appliedLabel.from : 'From';
          return `${fromLabel}: ${moment(value).format('YYYY-MM-DD')}`;
        }

        const toLabel = config.appliedLabel && config.appliedLabel.to ? config.appliedLabel.to : 'To';
        return `${toLabel}: ${moment(value).format('YYYY-MM-DD')}`;
      }
      case 'date-filter': {
        return `${moment(value.dateRange[0]).format('MMM D')} - ${moment(value.dateRange[1]).format('MMM D')}`;
      }
      case 'checkbox': {
        if (config.shortName === 'With Activities') {
          return value ? 'With Activities' : 'Without Activities';
        }

        return `${config.shortName}`;
      }
      case 'sign-filter':
        {
          if (index === 'section_id') {
            const room = rooms.find(room => room.id === value);
            return room && `${config.label} Room: ${room.name}`;
          } else if (index === 'before' || index === 'after') {
            return `${config.label} ${index}: ${value}`;
          } else if (index === 'never') {
            return `${config.label}: Never`;
          }
        }
        break;
      case 'age-filter': {
        const optionLabel = value.option !== 'range' ? `${value.option} Than` : 'Range';
        const valueText =
          value.option !== 'range'
            ? `${plural(value.months[0], 'month')}`
            : `from ${value.months[0]} to ${plural(value.months[1], 'month')}`;

        return `Age ${capitalize(optionLabel)}: ${valueText}`;
      }
      case 'registration-status': {
        return RegistrationStatusServices.getAppliedTextFromFormValue(this.props.value);
      }
    }
  }

  renderItem(v, index) {
    const { onCancel, readonly } = this.props;
    if (v === null || v === undefined) return null;

    return (
      <span
        data-cy={`filter-applied-item-${v}-${index}`}
        key={`value_${v}_${index}`}
        className={`applied-filters__item ${readonly ? 'readonly' : ''}`}
        onClick={() => !readonly && onCancel(v, index)}
      >
        {this.getValue(v, index)}
        {!readonly && <Icon name="close" size={10} />}
      </span>
    );
  }

  renderInner() {
    const { value, config } = this.props;
    let parsedValue = value;

    if (typeof value === 'object' && !Array.isArray(value) && !config.singleValue) {
      return Object.keys(value).map(index => this.renderItem(value[index], index));
    } else if (!Array.isArray(value)) {
      parsedValue = [value];
    }

    return parsedValue.map((v, index) => this.renderItem(v, index));
  }

  render() {
    const { tooltip } = this.props;

    return tooltip ? <TooltipTrigger tooltip={tooltip}>{this.renderInner()}</TooltipTrigger> : this.renderInner();
  }
}

const mapStateToProps = state => {
  return {
    rooms: state.rooms.data,
    tags: state.tags.data,
    lessonCategories: state.lessonCategories.data,
    milestoneCategories: state.milestoneCategories.data
  };
};

export default connect(mapStateToProps)(AppliedItem);
