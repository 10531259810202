import React from 'react';
import { Preloader } from 'components';

const FroalaPreloader = () => (
  <div className="froala-preloader">
    <Preloader large center />
  </div>
);

export default FroalaPreloader;
