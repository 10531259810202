const KINDER_SYSTEMS = 'kinder_systems';
const SCW = 'scw';
const DESKTOP = 'procare_v2';

export const SYNC_TYPES_NAMES = {
  [KINDER_SYSTEMS]: 'KinderSystems',
  [SCW]: 'SchoolCare Works',
  [DESKTOP]: 'Procare Desktop'
};

export const SYNC_TYPES_COLORS = {
  [SCW]: '#00818A',
  [DESKTOP]: '#02956B'
};

export default {
  KINDER_SYSTEMS,
  SCW,
  DESKTOP
};
