import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, ActionButton, ButtonV2 } from 'components';
import findIndex from 'lodash/findIndex';
import AddonList from './AddonList';
import './style.scss';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class AddPricingDetails extends Component {
  static propTypes = {
    schoolClass: PropTypes.object,
    onChange: PropTypes.func
  };

  static defaultProps = {
    pricingDetails: {
      addons: []
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      price: 0,
      addons: []
    };

    const addons = props.schoolClass.addons_attributes;

    if (addons.length && addons.length > 0) {
      for (const addon of addons) {
        this.state.addons.push(this.getAddon(addon));
      }
    } else {
      this.state.addons.push(this.getAddon());
    }
  }

  @bind
  handleUpdate() {
    this.props.onChange({
      price: this.state.price,
      addons_attributes: this.state.addons.map(a => {
        return {
          ...a,
          cost: Number(a.cost)
        };
      })
    });
  }

  @bind
  getAddon(data = {}) {
    return {
      id: data.id || this.state.addons.length + 1,
      name: data.name || '',
      cost: data.cost,
      required: data.required || false,
      _destroy: data._destroy,
      _new: data.id === undefined
    };
  }

  @bind
  addAddon() {
    this.setState({ addons: [...this.state.addons, this.getAddon()] }, this.handleUpdate);
  }

  @bind
  removeAddon(id) {
    const { addons } = this.state;

    if (addons.length <= 1) {
      return;
    }

    let nextAddons;
    const addonIndex = addons.findIndex(a => a.id === id);
    const addon = addons[addonIndex];

    if (addon._new) {
      nextAddons = [...addons.slice(0, addonIndex), ...addons.slice(addonIndex + 1)];
    } else {
      nextAddons = [...addons.slice(0, addonIndex), { ...addon, _destroy: true }, ...addons.slice(addonIndex + 1)];
    }

    this.setState({ addons: nextAddons }, this.handleUpdate);
  }

  @bind
  updateAddon(id, addon) {
    const { addons } = this.state;
    const addonIndex = findIndex(addons, { id });

    this.setState(
      {
        addons: [...addons.slice(0, addonIndex), addon, ...addons.slice(addonIndex + 1)]
      },
      this.handleUpdate
    );
  }

  @bind
  updatePrice({ price }) {
    this.setState({ price }, this.handleUpdate);
  }

  render() {
    const { addons } = this.state;
    const { schoolClass, edit } = this.props;

    return (
      <div className="add-pricing-details">
        <div className="modal__header modal__header--bordered">
          <ActionButton iconName="back" className="add-pricing-details__back" onClick={this.props.context.prevStep} />
          <span className="modal__header-title add-class-info__title">{edit ? 'Edit' : 'Add'} Pricing Details</span>
          <span className="modal__header-steps">
            <span>Step 2</span> / 3
          </span>
        </div>

        <div className="modal__container add-pricing-details__container">
          <div className="add-pricing-details__price">
            <div className="add-pricing-details__price-title">Base Price for the class</div>

            <Form onChange={this.updatePrice}>
              <Form.Input
                name="price"
                type="amount"
                className="add-pricing-details__price-input"
                defaultValue={schoolClass.price}
              />
            </Form>
          </div>

          <div className="add-pricing-details__addons">
            <div className="add-pricing-details__addons-title">Additional add-ons</div>

            <AddonList addons={addons} onAdd={this.addAddon} onUpdate={this.updateAddon} onRemove={this.removeAddon} />
          </div>
        </div>

        <div className="modal__controls add-pricing-details__controls">
          <ButtonV2 label="Continue" onClick={this.props.context.nextStep} />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(AddPricingDetails);
