import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ActionButton, ButtonV2 } from 'components';
import SelectPersonList from 'modals/common/SelectPersonList';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class SelectStaff extends Component {
  static propTypes = {
    staff: PropTypes.array,
    onSend: PropTypes.func,
    onSelect: PropTypes.func,
    isSaving: PropTypes.bool,
    selectedStaffIds: PropTypes.array
  };

  static defaultProps = {
    staff: []
  };

  render() {
    const { staff, onSubmit, onSelect, isSaving, selectedStaffIds } = this.props;

    return (
      <div className="select-newsletter-recipients__staff">
        <div className="modal__header">
          <ActionButton iconName="back" size={20} onClick={this.props.context.prevStep} />
          <span className="modal__header-title">Select Staff</span>
        </div>

        <div className="modal__container">
          <SelectPersonList
            persons={staff}
            allPersons={staff}
            selectedIds={selectedStaffIds}
            onSelect={onSelect}
            listHeight={438}
            type="teacher"
          />
        </div>

        <div className="modal__controls">
          <ButtonV2 label="Done" onClick={onSubmit} isLoading={isSaving} data-cy="done" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  staff: state.staff.data
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(SelectStaff);
