import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, TextBox, Divider } from 'components';
import FormFieldType from 'constants/FormFieldType';
import { parseHtmlString } from 'lib/utils';
import classNames from 'classnames';
import './style.scss';

class FormRenderer extends Component {
  static propTypes = {
    fields: PropTypes.array,
    defaultValues: PropTypes.object,
    className: PropTypes.string,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    omitFormTag: PropTypes.bool,
    schoolId: PropTypes.string
  };

  static defaultProps = {
    fields: [],
    defaultValues: {}
  };

  form = null;

  getComponent(type) {
    switch (type) {
      case FormFieldType.TextInput:
        return Form.Input;

      case FormFieldType.Checkbox:
        return Form.Checkbox;

      case FormFieldType.Select:
        return Form.Select;

      case FormFieldType.DatePicker:
        return Form.DatePicker;

      case FormFieldType.TextBox:
        return TextBox;

      case FormFieldType.Divider:
        return Divider;

      case FormFieldType.FileUpload:
        return Form.UploadFile;
    }
  }

  @bind
  getType(id) {
    const fieldObj = this.props.fields.find(f => f.id === id);
    return fieldObj && fieldObj.type;
  }

  getComponentProps(fieldObj) {
    const { id, type, options } = fieldObj;
    const { defaultValues, schoolId, onRestart, acceptedExts } = this.props;

    const commonProps = {
      label: options.label,
      defaultValue: defaultValues[id] || options.defaultValue
    };

    switch (type) {
      case FormFieldType.TextInput:
        return {
          ...commonProps,
          type: options.email ? 'email' : 'text',
          placeholder: options.placeholder,
          multiline: options.multiline
        };

      case FormFieldType.Checkbox:
        return { ...commonProps, required: options.required };

      case FormFieldType.Select:
        return {
          ...commonProps,
          type: options.type,
          title: options.title,
          children: (options.items || []).map(c => <Form.Select.Item key={c.value} value={c.value} label={c.label} />)
        };

      case FormFieldType.DatePicker:
        return {
          ...commonProps,
          placeholder: options.placeholder,
          defaultValue:
            typeof commonProps.defaultValue === 'string'
              ? moment(commonProps.defaultValue).toDate()
              : commonProps.defaultValue
        };

      case FormFieldType.TextBox:
        return {
          ...commonProps,
          value: options.value,
          isInForm: true,
          formatter: value => {
            // Make all links blank
            let result = value;

            try {
              const dom = parseHtmlString(value);

              if (dom !== null) {
                const linksCollection = dom.getElementsByTagName('a');
                if (linksCollection.length > 0) {
                  for (let i = 0; i < linksCollection.length; i++) {
                    const element = linksCollection[i];
                    const BLANK = '_blank';

                    if (element.target !== BLANK) {
                      element.target = BLANK;
                      const relValues = ['noopener', 'noreferrer'];
                      element.rel = [
                        ...element.rel.split(' ').filter(attrValue => !relValues.includes(attrValue)),
                        ...relValues
                      ]
                        .filter(item => Boolean(item))
                        .join(' ');
                    }
                  }
                }
              }

              return dom.innerHTML;
            } catch (err) {
              console.error('Unable to format textbox content');
            }
            return result;
          },
          hasCheckbox: options.hasCheckbox,
          checkboxLabel: options.checkboxLabel
        };

      case FormFieldType.FileUpload:
        return {
          ...commonProps,
          s3Key: 'enrollment_attachments',
          title: options.label,
          publicSignature: true,
          schoolId,
          onRestart,
          acceptedExts
        };
    }
  }

  @bind
  sanitize(values) {
    const sanitized = {};

    Object.keys(values).forEach(id => {
      const type = this.getType(id);
      if (type === FormFieldType.DatePicker) {
        sanitized[id] = values[id] ? moment(values[id]).format('YYYY-MM-DD') : values[id];
      } else {
        sanitized[id] = values[id];
      }
    });

    return sanitized;
  }

  @bind
  handleChange(values) {
    if (this.props.onChange) {
      this.props.onChange(this.sanitize(values));
    }
  }

  @bind
  handleSubmit(values) {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.sanitize(values));
    }
  }

  renderFields() {
    return this.props.fields.map(fieldObj => {
      const Component = this.getComponent(fieldObj.type);
      const props = this.getComponentProps(fieldObj);

      const className = classNames('form-renderer__field', {
        'form-renderer__field--full-width': fieldObj.options.fullWidth,
        'form-renderer__field--required': fieldObj.options.required
      });

      return (
        <div className={className} key={fieldObj.id}>
          <Component data-cy={`form-field-${fieldObj.id}`} name={fieldObj.id} {...props} />
        </div>
      );
    });
  }

  @bind
  bindForm(node) {
    this.form = node;
  }

  @bind
  isFormValid() {
    return this.form.isFormValid();
  }

  render() {
    const { className, omitFormTag } = this.props;
    const formRendererCN = classNames('form-renderer', className);

    if (omitFormTag) {
      return <div className={formRendererCN}>{this.renderFields()}</div>;
    }

    return (
      <Form
        ref={this.bindForm}
        className={formRendererCN}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        validateOn="change"
      >
        {this.renderFields()}
      </Form>
    );
  }
}

export default FormRenderer;
