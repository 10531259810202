import React, { useContext, useState } from 'react';
import { applyFilters } from 'lib/utils';
import { Avatar, ButtonV2 } from 'components';
import SelectPersonList from 'modals/common/SelectPersonList';
import PersonFilter from './PersonFilter';
import { useSelector } from 'react-redux';
import { ModalControllerStepsContext } from 'hocs/withContext';

const AssignProgramStaff_Step1 = ({ selectedIds, onChange, onSubmit }) => {
  const context = useContext(ModalControllerStepsContext);
  const program = useSelector(state => state.program.data);
  const staff = useSelector(state => state.staff.data);

  const [filters, setFilters] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const updateFilters = filters => {
    setFilters(filters);
  };

  // sort staff first by whether they're in the program, then by name
  const filteredStaff = React.useMemo(() => {
    const assignedStaffIds = program.teachers?.map(t => t.id) ?? [];
    const isAssigned = person => (assignedStaffIds.includes(person.id) ? 1 : -1);
    return applyFilters(staff, filters).sort((a, b) => isAssigned(b) - isAssigned(a) || a.name.localeCompare(b.name));
  }, [staff, filters]);

  const nextStep = async () => {
    if (program.sections?.length > 1 && selectedIds.length) {
      context.nextStep();
    } else {
      setIsSaving(true);
      await onSubmit();
    }
  };

  return (
    <div className="assign-program-staff-step-one">
      <div className="modal__header modal__header--bordered">
        <div className="modal__header-title">Assign Staff</div>
        {program.sections?.length > 1 && (
          <span className="modal__header-steps">
            <span>Step 1</span> / 2
          </span>
        )}
        <div>
          <div className="assign-program-staff__header-label">Program</div>
          <div className="assign-program-staff__header-program">
            <Avatar className="room__avatar avatar--small" name={program.name} />
            <span className="assign-program-staff__name">{program.name}</span>
          </div>
        </div>
        <div className="select-document-recipients__students-filter modal__header-controls">
          <PersonFilter filters={filters} onChange={updateFilters} />
        </div>
      </div>

      <div className="modal__container">
        <SelectPersonList
          persons={filteredStaff}
          allPersons={filteredStaff}
          selectedIds={selectedIds}
          onSelect={onChange}
          listHeight={315}
          type="teacher"
        />
      </div>

      <div className="modal__controls">
        <ButtonV2 label="Continue" onClick={nextStep} isLoading={isSaving} data-cy="continue" />
      </div>
    </div>
  );
};

export default AssignProgramStaff_Step1;
