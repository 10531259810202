import React, { Component } from 'react';
import { TextInput, SelectGroup, ActionButton } from 'components';

class StudentRow extends Component {
  updateField(field) {
    const { id, isNew } = this.props;

    return value => {
      const data = { ...this.props.student };
      data[field] = value;

      this.props.onUpdate(data, isNew ? null : id);
    };
  }

  updateRoom(roomId) {
    const { id, isNew } = this.props;

    const data = {
      ...this.props.student,
      room_id: roomId
    };

    this.props.onUpdate(data, isNew ? null : id);
  }

  render() {
    const { id, student, rooms, isNew } = this.props;

    return (
      <div className="add-student__row">
        <div className="add-student__row-id">{id + 1}.</div>

        <TextInput
          className="add-student__row-first-name"
          placeholder="First Name (required)"
          value={student.first_name}
          onChange={this.updateField('first_name')}
          validate={value => value.length > 0}
          validationText="Shouldn't be empty"
        />

        <TextInput
          className="add-student__row-last-name"
          placeholder="Last Name"
          value={student.last_name}
          onChange={this.updateField('last_name')}
          validate={value => value.length > 0}
          validationText="Shouldn't be empty"
        />

        <SelectGroup
          className="add-student__row-room"
          title="Room"
          type="radio"
          checked={student.room_id}
          onChange={this.updateRoom}
          validate={value => value.length > 0}
          validationText="Shouldn't be empty"
        >
          {rooms.map(room => (
            <SelectGroup.Item key={room.id} label={room.name} value={room.id} />
          ))}
        </SelectGroup>

        <TextInput
          className="add-student__row-parent-name"
          placeholder="Parent Name"
          value={student.parent_name}
          onChange={this.updateField('parent_name')}
        />

        <div className="add-student__row-actions">
          {isNew ? (
            <ActionButton
              className="add-student__row-action-button add-student__row-action-button--add"
              type="add-person"
              onClick={this.props.onAdd}
            />
          ) : (
            <ActionButton
              className="add-student__row-action-button add-student__row-action-button--remove"
              type="delete-forever"
              onClick={() => this.props.onRemove(id)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default StudentRow;
