import React, { useEffect, useRef, useState } from 'react';
import range from 'lodash/range';
import moment from 'moment';
import './HourLegend.scss';
import classNames from 'classnames';

const SLOT_SIZE = 16;

function HourLegend() {
  const [hourLegendToHide, setHourLegendToHide] = useState('');
  const [time, setTime] = useState(new Date());

  const start = moment().startOf('day');
  const ref = useRef(null);
  const text = moment(time).format('hh:mm A');
  const diff = moment(time).diff(start, 'minute');
  const top = ((SLOT_SIZE * 4) / 60) * diff;

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView();
    }
    const interval = setInterval(() => {
      setTime(new Date());
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    hideTimeLabel();
  }, [time]);

  const hideTimeLabel = () => {
    const minuteHour = moment(time).format('mm');
    const roundedUpHour = moment(time)
      .add(1, 'hour')
      .startOf('hour')
      .format('h A');
    const roundedDownHour = moment(time)
      .startOf('hour')
      .format('h A');

    if (Number(minuteHour) >= 48) {
      setHourLegendToHide(roundedUpHour);
    } else if (Number(minuteHour) >= 0 && Number(minuteHour) <= 12) {
      setHourLegendToHide(roundedDownHour);
    } else {
      setHourLegendToHide('');
    }
  };

  const createHourLabels = () =>
    range(25).map(i => ({
      label: moment()
        .startOf('day')
        .add(i, 'hours')
        .format('h A'),
      key: `hours_${i}`
    }));
  const hourLabels = createHourLabels();

  return (
    <>
      <div className="weekly-calendar__hours">
        {hourLabels.map(label => {
          const hideLabel = label.label === hourLegendToHide ? true : false;
          const legendCN = classNames('weekly-calendar__hours-item', {
            'weekly-calendar__hours-item--hidden': hideLabel
          });
          return (
            <div className={legendCN} key={label.key}>
              {label.label}
            </div>
          );
        })}
      </div>
      <div ref={ref} className="weekly-calendar__dateline" style={{ top }}>
        <div className="weekly-calendar__dateline-text">{text}</div>
        <div className="weekly-calendar__dateline-cross" />
      </div>
    </>
  );
}

export default HourLegend;
