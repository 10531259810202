import groupBy from 'lodash/groupBy';

export default function(kids) {
  const kidGroup = groupBy(kids, 'family_id');
  const families = Object.keys(kidGroup).map(familyId => ({
    kids: kidGroup[familyId],
    id: familyId
  }));

  return {
    kids,
    families
  };
}
