import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import classNames from 'classnames';
import { ButtonV2, Preloader } from 'components';

class Column extends PureComponent {
  static propTypes = {
    dateStr: PropTypes.string,
    loading: PropTypes.bool,
    items: PropTypes.array,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    renderItem: PropTypes.func.isRequired,
    renderPlaceholder: PropTypes.func,
    addButtonOptions: PropTypes.object
  };

  static defaultProps = {
    items: []
  };

  @bind
  handleAdd() {
    this.props.onAdd(this.props.dateStr);
  }

  renderItems(items = []) {
    if (items.length === 0) {
      const renderPlaceholder = get(this.props, 'renderPlaceholder', () => {
        return;
      });
      return renderPlaceholder(this.props.dateStr);
    } else {
      const Item = this.props.renderItem;
      return items.map(i => (
        <Item
          key={`${i.id}_${i.lesson_plan_id}`}
          data={i}
          readOnly={this.props.readOnly}
          dateStr={this.props.dateStr}
          onEdit={this.props.onAdd}
          onDelete={this.props.onDelete}
        />
      ));
    }
  }

  render() {
    const { dateStr, loading, items, readOnly } = this.props;
    const momentDate = moment(dateStr, 'YYYY-MM-DD');

    const className = classNames('week-grid__column', {
      [`${this.props.className}__column`]: this.props.className,
      'week-grid__column--loading': loading
    });

    return (
      <div className={className}>
        <div className="week-grid__column-date">
          <div className="week-grid__column-date-num">{momentDate.format('DD')}</div>

          <div className="week-grid__date-day">{momentDate.format('ddd')}</div>
        </div>

        <div className="week-grid__column-items">
          {this.renderItems(items)}

          {!readOnly && (
            <ButtonV2
              tertiary
              data-cy="week-grid__add-item"
              size={get(this.props, 'addButtonOptions.iconSize', 18)}
              label={`Add ${get(this.props, 'addButtonOptions.title', 'item')}`}
              className={classNames('week-grid__column-add-btn', {
                [`${this.props.className}__column-add-btn`]: this.props.className
              })}
              onClick={this.handleAdd}
            />
          )}
        </div>

        {loading && (
          <div className="week-grid__column-loading-overlay">
            <Preloader />
          </div>
        )}
      </div>
    );
  }
}

export default Column;
