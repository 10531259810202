import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonV2 } from 'components';
import { connect } from 'react-redux';
import { applyFilters } from 'lib/utils';
import SelectPersonList from 'modals/common/SelectPersonList';
import StudentFilter from './StudentFilter';

class SelectStudents extends PureComponent {
  static propTypes = {
    kids: PropTypes.array,
    rooms: PropTypes.array,
    tags: PropTypes.array,
    selectedKidIds: PropTypes.array,
    onSelect: PropTypes.func,
    onSend: PropTypes.func,
    onSelectStaff: PropTypes.func,
    isLoading: PropTypes.bool
  };

  static defaultProps = {
    kids: [],
    rooms: [],
    tags: [],
    selectedKidIds: []
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: {}
    };
  }

  @bind
  updateFilters(filters) {
    this.setState({ filters });
  }

  get kids() {
    return applyFilters(this.props.kids, this.state.filters);
  }

  render() {
    const { rooms, tags, selectedKidIds, isLoading, onSelect, onSubmit, onSelectStaff } = this.props;
    const { filters } = this.state;

    return (
      <div className="select-newsletter-recipients__students">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Select Students</div>

          <div className="select-newsletter-recipients__students-filter">
            <StudentFilter rooms={rooms} tags={tags} filters={filters} onChange={this.updateFilters} />
          </div>
        </div>

        <div className="modal__container">
          <SelectPersonList
            persons={this.kids}
            allPersons={this.kids}
            selectedIds={selectedKidIds}
            onSelect={onSelect}
            listHeight={438}
            type="kid"
          />
        </div>

        <div className="modal__controls">
          <ButtonV2
            secondary
            label="Choose Staff"
            onClick={onSelectStaff}
            isLoading={isLoading}
            data-cy="choose-staff"
          />
          <ButtonV2 label="Done" onClick={onSubmit} isLoading={isLoading} data-cy="done" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  kids: state.students.data,
  rooms: state.rooms.data,
  tags: state.tags.data
});

export default connect(mapStateToProps)(SelectStudents);
