import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Item from './Item';
import './style.scss';

class StatsBar extends PureComponent {
  static Item = Item;

  static propTypes = {
    active: PropTypes.string,
    className: PropTypes.string,
    onSelect: PropTypes.func
  };

  @bind
  handleSelect(name) {
    if (!this.props.onSelect) {
      return;
    }

    this.props.onSelect(name);
  }

  renderItems() {
    const { active, children } = this.props;

    return React.Children.map(
      children,
      c => c && <c.type {...c.props} active={active === c.props.name} onClick={this.handleSelect} />
    );
  }

  render() {
    const className = classNames('stats-bar', this.props.className, {
      'stats-bar--active': this.props.active !== undefined,
      'stats-bar--disabled': this.props.onSelect === undefined
    });

    return <div className={className}>{this.renderItems()}</div>;
  }
}

export default StatsBar;
