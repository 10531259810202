import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TextInput, Icon } from 'components';
import { toPattern } from 'lib/utils/masker';
import currency from 'lib/currency';
import capitalize from 'lodash/capitalize';
import validationText from '../validationText';
import './style.scss';
import withContext, { FormContext } from 'hocs/withContext';

const decimalRE = /^\d{0,7}(\.\d{0,2})?$/;

class Input extends Component {
  static propTypes = {
    // Required to create form data properly, should be unique
    name: PropTypes.string.isRequired,

    // "text" by default
    type: PropTypes.oneOf(['text', 'password', 'email', 'number', 'amount']),

    // A default value
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // If provided, it will render a label above the field
    label: PropTypes.string,

    // Used to display an action button
    actionName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    // A name of a method that is invoked when the action button is clicked
    actionType: PropTypes.string,

    // If true, no invalid styles are applied to the field
    // Provided validations will still work, it only disables the styling
    disableInvalid: PropTypes.bool,

    // Disabled style
    disabled: PropTypes.bool,

    actionDisabled: PropTypes.bool,

    minLength: PropTypes.number,

    tabIndex: PropTypes.number,

    maxLength: PropTypes.number,

    transform: PropTypes.func,

    pattern: PropTypes.string,

    mask: PropTypes.string,

    // if true, validate for url
    isUrl: PropTypes.bool,

    asterisk: PropTypes.bool,

    fieldRef: PropTypes.func,

    // Input value shouldn't be greater than maxValue
    maxValue: PropTypes.number,

    helperText: PropTypes.string,

    errorsWithLabels: PropTypes.bool,

    onClick: PropTypes.func,

    onType: PropTypes.func,

    'data-cy': PropTypes.string
  };

  static defaultProps = {
    type: 'text',
    actionType: 'submit',
    errorsWithLabels: false
  };

  UNSAFE_componentWillMount() {
    this.init();
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  componentDidUpdate(prevProps) {
    if (this.props.required !== prevProps.required) {
      this.props.context.updateValidations(this.props.name, this.initValidations());
    }

    if (this.props.hidden) {
      delete this.props.context.values[this.props.name];
      delete this.props.context.validations[this.props.name];
    }

    if (!this.props.hidden && prevProps.hidden) {
      this.init();
    }
  }

  @bind
  init() {
    if (this.props.hidden) {
      return;
    }

    const initialValue = this.props.defaultValue || '';

    // These operations create a validation object's structure
    // and send it to Form component.
    const validations = this.initValidations();
    this.props.context.init(this.props.name, initialValue, validations);
  }

  @bind
  initValidations() {
    const { name, equalTo, naturalNumber, type, required, disabled, minLength, pattern, isUrl } = this.props;
    const validations = {};

    if (!disabled) {
      if (required) {
        validations.isNotEmpty = null;
      }

      if (type === 'email') {
        validations.isEmail = null;
      }

      if (naturalNumber) {
        validations.isNaturalNumber = null;
      }

      if (equalTo) {
        validations.isPasswordsEqual = null;
        this.props.context.link(name, equalTo); // save linked input name to use it in validations
      }

      if (minLength !== undefined) {
        validations.minLength = minLength;
      }

      if (pattern) {
        validations.pattern = pattern;
      }

      if (isUrl) {
        validations.isUrl = null;
      }
    }

    return validations;
  }

  /**
   * Update value of a field
   *
   * @param value - a new value for a field with the name === this.props.name
   */
  @bind
  handleChange(value) {
    let updateValue = value;

    if (this.props.type === 'amount' && decimalRE.test(value)) {
      this.props.context.update(this.props.name, value);
    } else if (this.props.type !== 'amount') {
      if (this.props.maxLength && value.length > this.props.maxLength) {
        return;
      }

      if (this.props.mask) {
        updateValue = toPattern(value, this.props.mask);
      }

      if (this.props.transform) {
        updateValue = this.props.transform(value);
      }

      this.props.context.update(this.props.name, updateValue);
    }

    this.props.onType && this.props.onType(value);
  }

  /**
   * Update the validation state on blur
   */
  @bind
  handleBlur() {
    const { maxValue, name } = this.props;
    const value = this.props.context.values[name];
    const nextValue = maxValue && !isNaN(Number(value)) && value >= maxValue ? maxValue : value;

    if (this.props.type === 'amount' && value) {
      this.props.context.update(this.props.name, Number(nextValue).toFixed(2));
    }

    if (this.props.context.validateOn !== 'submit') {
      this.props.context.validate(this.props.name);
    }
  }

  /**
   * Get a text for an invalid field to render.
   *
   * @return {string} - a text which represents a validation error
   */
  getValidationText() {
    const { name, errorsWithLabels, label } = this.props;
    const { errors } = this.props.context;

    if (errors[name]) {
      let errorsArray = errors[name];
      if (errorsWithLabels && label) {
        const titledLabel = capitalize(label);
        errorsArray = errorsArray.map(error => `${titledLabel} ${error}`);
      }
      return errorsArray.join('; ');
    }

    const { validations } = this.props.context;

    if (!validations[name]) {
      return '';
    }

    return Object.keys(validations[name])
      .filter(type => validations[name][type] === false)
      .map(type => {
        if (typeof validationText[type] === 'function') {
          return validationText[type](this.props[type]);
        }

        return validationText[type];
      })
      .join('; ');
  }

  render() {
    if (this.props.hidden) {
      return null;
    }

    const { values } = this.props.context;
    const {
      name,
      disabled,
      label,
      type,
      placeholder,
      autoFocus,
      autoComplete,
      disableInvalid,
      actionName,
      actionType,
      actionDisabled,
      className,
      tabIndex,
      asterisk,
      multiline,
      decimal,
      min,
      max,
      fieldRef,
      helperText,
      onClick,
      'data-cy': dataCY
    } = this.props;
    const invalidText = this.getValidationText();

    let action;

    switch (actionType) {
      case 'submit':
        action = this.props.context.submit;
    }

    const formInputCN = classNames({
      'form-input': true,
      'form-input--disabled': disabled,
      'form-input--invalid': Boolean(invalidText) && !disableInvalid,
      'form-input--amount': type === 'amount',
      'form-input--empty': !values[name],
      'form-input--with-action': Boolean(actionName),
      [className]: Boolean(className)
    });

    const actionCN = classNames({
      'form-input__action': true,
      'form-input__action--disabled': !this.props.context.isFormValid() || actionDisabled
    });

    const isInvalid = invalidText && !disableInvalid;

    return (
      <div className={formInputCN} ref={fieldRef}>
        {label && (
          <label className="form__label" htmlFor={name}>
            {label} {asterisk && <span className="form__asterisk">*</span>}
          </label>
        )}

        <div className="form-input__field">
          {type === 'amount' && <span className="form-input__field-symbol">{currency.getSymbol()}</span>}
          <TextInput
            name={name}
            type={this.props.type}
            value={values[name] || ''}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            placeholder={placeholder}
            disabled={disabled}
            tabIndex={tabIndex}
            multiline={multiline}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            min={min}
            max={max}
            decimal={decimal}
            onClick={onClick}
            data-cy={dataCY}
          />

          {isInvalid && <Icon name="alert" className="form-input__invalid-icon" />}
          {asterisk && !label && <span className="form__asterisk--no-label">*</span>}
          {actionName && (
            <div className={actionCN} onClick={!actionDisabled ? action : undefined}>
              {actionName}
            </div>
          )}
        </div>

        {isInvalid && (
          <div className="form-input__validation-text" data-cy={dataCY ? `${dataCY}-invalid` : undefined}>
            {invalidText}
          </div>
        )}

        {helperText && !isInvalid && <div className="form-input__helper-text">{helperText}</div>}
      </div>
    );
  }
}

export default withContext(FormContext)(Input);
