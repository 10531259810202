import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './TodayCaret.scss';

const SLOT_SIZE = 16;

function TodayCaret() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const start = moment().startOf('day');
  const diff = moment(time).diff(start, 'minute');
  const top = ((SLOT_SIZE * 4) / 60) * diff - 5;

  return <div className="weekly-calendar__caret" style={{ top }} />;
}

export default TodayCaret;
