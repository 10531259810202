import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AnswerListItem from './AnswerListItem';

class AnswerList extends Component {
  static propTypes = {
    answers: PropTypes.array,
    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
    onChange: PropTypes.func
  };

  static defaultProps = {
    answers: []
  };

  @bind
  addAnswer() {
    if (this.props.onAdd) {
      this.props.onAdd();
    }
  }

  @bind
  changeAnswer(id, nextValue) {
    if (this.props.onChange) {
      this.props.onChange(id, nextValue);
    }
  }

  @bind
  removeAnswer(id) {
    const { answers, onRemove } = this.props;

    if (answers.length > 1 && onRemove !== undefined) {
      onRemove(id);
    }
  }

  renderAnswers() {
    const { answers } = this.props;

    return answers.map((answer, index) => (
      <AnswerListItem
        answer={answer}
        key={answer.id}
        isLast={index === answers.length - 1}
        onAdd={this.addAnswer}
        onChange={this.changeAnswer}
        onRemove={this.removeAnswer}
        isMaxReached={answers.length === 8}
      />
    ));
  }

  render() {
    return <div className="answer-list">{this.renderAnswers()}</div>;
  }
}

export default AnswerList;
