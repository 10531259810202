// This reducer created for Teacher SISO Daily item updates.
// It needs to be refactored to use with siso_kids patching.
// But it would be better to refactor API responce for siso
// actions to universalize and simplify it and get rid of
// collections permitting.

const createPatchSisoItemReducer = (reducerName, initialState) => (state = { ...initialState }, action) => {
  switch (action.type) {
    case `${reducerName}_PATCH_SISO_ITEM`:
      return { ...state, loading: true };

    case `${reducerName}_PATCH_SISO_ITEM_SUCCESS`: {
      const sisoItemId = action.payload.id;
      const teacherId = action.payload.teacher_id;
      const newSisoTeachers = { ...state.data.siso_teachers };
      if (newSisoTeachers[teacherId]) {
        const oldSisoItem = newSisoTeachers[teacherId].filter(item => item.id === sisoItemId)[0];
        const itemIndex = newSisoTeachers[teacherId].indexOf(oldSisoItem);
        newSisoTeachers[teacherId][itemIndex] = action.payload;
        const newData = {
          teachers: state.data.teachers,
          siso_teachers: newSisoTeachers
        };
        return { loading: false, data: newData };
      }
      return { ...state, loading: false };
    }

    case `${reducerName}_PATCH_SISO_ITEM_FAILURE`:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default createPatchSisoItemReducer;
