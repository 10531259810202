import React, { Component } from 'react';
import { ActionButton, ButtonV2, Icon } from 'components';
import './style.scss';

export default class AutopayAgreement extends Component {
  render() {
    const { prevStep, nextStep } = this.props;
    return (
      <div className="table payments-panel">
        <div className="table__header payments-panel__header">
          <ActionButton iconName="back" size={20} onClick={prevStep} />
          <div className="payments-panel__title">Choose Payment</div>
        </div>

        <div className="table__body">
          <div className="table__row payments-panel__body">
            <div className="payments-panel__content">
              <div className="payments-agree__img-container">
                <div className="payments-agree__img">
                  <Icon name="hand-point" size={32} />
                </div>
              </div>

              <div className="payments-agree__title">
                School has my authorization to do automatic debits on my account for outstanding balances.
              </div>

              <div className="payments-agree__desc">* if you have any questions, please contact your school</div>
            </div>
          </div>

          <div className="table__row table__row--footer payments-panel__footer">
            <ButtonV2 onClick={nextStep} className="button--large" label="Agree & Continue" />
          </div>
        </div>
      </div>
    );
  }
}
