import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, ActionButton } from 'components';
import getCardType from 'credit-card-type';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class Step2CC extends Component {
  static propTypes = {
    onSubmit: PropTypes.func
  };

  constructor(...args) {
    super(...args);
    this.state = {
      isLoading: false
    };
  }

  @bind
  handleSubmit(data) {
    const payload = {
      CARD_ACCOUNT: data.card_number.replace(/[\s]+/g, ''),
      CARD_CVV2: data.cvv,
      CARD_EXPIRE: data.expires.replace('/', ''),
      NAME1: data.first_name,
      NAME2: data.last_name
    };
    const { getBluepayToken, onSubmit, subKind, autopayEnabled } = this.props;
    const brands = getCardType(payload.CARD_ACCOUNT);

    this.setState({ isLoading: true });
    getBluepayToken(payload)
      .then(token => {
        if (onSubmit) {
          return onSubmit({
            token,
            brand: brands && brands.length ? brands[0].niceType : undefined,
            token_type: 'card',
            last4: payload.CARD_ACCOUNT.substr(payload.CARD_ACCOUNT.length - 4)
          });
        }

        return req
          .addPaymentMethod({
            token,
            brand: brands && brands.length ? brands[0].niceType : undefined,
            token_type: 'card',
            sub_kind: subKind,
            last4: payload.CARD_ACCOUNT.substr(payload.CARD_ACCOUNT.length - 4),
            auto_pay: autopayEnabled === true
          })
          .then(() => {
            Actions.showFlash('Card added successfully');
            this.setState({ isLoading: false });
            this.props.goToList(true);
          });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        Actions.reportError('There was problem with adding payment method', err);
      });
  }

  render() {
    const { prevStep } = this.props.context;
    const { isLoading, onSubmit } = this.state;

    return (
      <Form className="table payments-panel" onSubmit={this.handleSubmit} isLoading={isLoading}>
        <div className="table__header payments-panel__header">
          {!onSubmit && <ActionButton iconName="back" size={20} onClick={prevStep} />}
          <div className="payments-panel__title">{onSubmit ? 'Submit Registration' : 'Set up Card'}</div>
        </div>

        <div className="table__body">
          <div className="table__row payments-panel__body">
            <div className="payments-panel__content">
              <div className="form__row form__row--justified">
                <Form.Input name="first_name" placeholder="First Name" label="First Name" required />

                <Form.Input name="last_name" placeholder="Last Name" label="Last Name" required />
              </div>

              <div className="form__row form__row--justified">
                <Form.Input
                  placeholder="Card Number"
                  label="Card Number"
                  name="card_number"
                  mask="9999 9999 9999 9999"
                  required
                />
                <div className="form__row form__row--justified">
                  <Form.Input label="CVV" placeholder="CVV" name="cvv" mask="9999" required />
                  <Form.Input
                    label="Expires"
                    placeholder="MMYY"
                    name="expires"
                    pattern="^[0-9]{2}\/[0-9]{2}$"
                    mask="99/99"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="table__row table__row--footer payments-panel__footer">
            <Form.Submit className="button--large" label={onSubmit ? 'Continue' : 'Add Card'} />
          </div>
        </div>
      </Form>
    );
  }
}

export default withContext(ModalControllerStepsContext)(Step2CC);
