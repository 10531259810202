import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { applyFilters } from 'lib/utils';
import { ButtonV2 } from 'components';
import SelectPersonList from 'modals/common/SelectPersonList/WindowedPersonsList';
import LeadsFilter from './LeadsFilter';

class SelectLeads extends Component {
  static propTypes = {
    leads: PropTypes.array,
    onSelect: PropTypes.func,
    isSaving: PropTypes.bool,
    selectedLeadIds: PropTypes.array
  };

  static defaultProps = {
    leads: []
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: {}
    };
  }

  @bind
  updateFilters(filters) {
    this.setState({ filters });
  }

  get leads() {
    return applyFilters(this.props.leads, this.state.filters);
  }

  render() {
    const { onSubmit, onSelect, isSaving, selectedLeadIds } = this.props;
    const { filters } = this.state;

    return (
      <div className="select-document-recipients__students">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Select Leads</div>
          <div className="select-document-recipients__students-filter">
            <LeadsFilter filters={filters} onChange={this.updateFilters} />
          </div>
        </div>

        <div className="modal__container">
          <SelectPersonList
            persons={this.leads}
            allPersons={this.leads}
            selectedIds={selectedLeadIds}
            onSelect={onSelect}
            listHeight={438}
            type="lead"
            noResultText="No lead found by filter"
          />
        </div>

        <div className="modal__controls">
          <ButtonV2 label="Continue" onClick={onSubmit} isLoading={isSaving} data-cy="continue" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  leads: state.allEnrollments.data.map(lead => ({ ...lead, name: [lead.first_name, lead.last_name].join(' ') }))
});

export default connect(mapStateToProps)(SelectLeads);
