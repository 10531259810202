import React, { Component } from 'react';
import { ActionButton, Icon } from 'components';
import './style.scss';

class PhotoViewer extends Component {
  imageRef = React.createRef();

  constructor(...args) {
    super(...args);
    this.state = {
      currentIndex: this.props.data.index || 0
    };
  }

  componentDidMount() {
    this.disableRightClick();
  }

  disableRightClick() {
    if (this.imageRef.current) {
      this.imageRef.current.addEventListener('contextmenu', e => {
        e.preventDefault();
      });
    }
  }

  @bind
  onPrev(evt) {
    evt.stopPropagation();
    this.setState({ currentIndex: this.state.currentIndex - 1 });
  }

  @bind
  onNext(evt) {
    evt.stopPropagation();
    this.setState({ currentIndex: this.state.currentIndex + 1 });
  }

  @bind
  onDownload(evt) {
    evt.stopPropagation();
  }

  render() {
    const { data, onClose } = this.props;
    const { currentIndex } = this.state;
    const photo = data.photos[currentIndex];

    return (
      <div className="photo-viewer" onClick={onClose}>
        <div className="modal__header">
          <ActionButton iconName="back" />
          <a
            className="action-button"
            onClick={this.onDownload}
            download={photo.main_url}
            href={photo.main_url}
            target="_blank"
          >
            <Icon name="download" size={16} />
          </a>
        </div>

        <div className="modal__container">
          <img ref={this.imageRef} className="photo-viewer__photo" src={photo.main_url} alt={photo.caption} />
        </div>

        {currentIndex > 0 && (
          <ActionButton className="photo-viewer__prev" size={40} iconName="left-arrow" onClick={this.onPrev} />
        )}
        {currentIndex < data.photos.length - 1 && (
          <ActionButton className="photo-viewer__next" size={40} iconName="right-arrow" onClick={this.onNext} />
        )}
      </div>
    );
  }
}

export default PhotoViewer;
