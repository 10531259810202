import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  staffPermissions: state.currentUser.data.permissions || {},
  mergedPermissions: state.currentUser.data.mergedPermissions || {}
});

/**
 * HOC. Renders or skips component render based on user permissions.
 *
 * @param {string} name - A key of permissions object
 * @param {string|string[]} value - Permission level name or array permitted levels
 * @param {bool} shouldIgnoreSchoolPermissions - ignore school permissions
 * @return {object|false} - a Component or nothing
 */
function withPermissions(name, value, shouldIgnoreSchoolPermissions) {
  return WrappedComponent => {
    const Connectable = ({ staffPermissions, mergedPermissions }) => {
      const permissions = shouldIgnoreSchoolPermissions ? staffPermissions : mergedPermissions;

      if (Array.isArray(value)) {
        return value.includes(permissions[name]) && <WrappedComponent />;
      } else {
        return permissions[name] === value && <WrappedComponent />;
      }
    };

    const Connected = connect(mapStateToProps)(Connectable);
    return <Connected />;
  };
}

export function withReadPermissions(name, shouldIgnoreSchoolPermissions) {
  return WrappedComponent => {
    const Extended = withPermissions(name, ['read', 'write'], shouldIgnoreSchoolPermissions)(WrappedComponent);
    return Extended;
  };
}

export function withWritePermissions(name, shouldIgnoreSchoolPermissions) {
  return WrappedComponent => {
    const Extended = withPermissions(name, 'write', shouldIgnoreSchoolPermissions)(WrappedComponent);
    return Extended;
  };
}

/**
 * HOC. Extends wrapped component with a `hasPermissions` method.
 * connects to redux store to traverse `schoolPermissions` and `staffPermissions` nodes
 *
 * @param {object} WrappedComponent - A component to be extended
 * @return {object} - an Extended component
 */
export function withPermissionsChecking(WrappedComponent) {
  const Connectable = ({ staffPermissions, mergedPermissions, ...props }) => {
    /**
     * Checks if user has specific permissions.
     *
     * @param {string} name - A key of permissions object
     * @param {string|string[]} value - Permission level name or array permitted levels
     * @param {bool} shouldIgnoreSchoolPermissions - ignore school permissions
     * @return {boolean} - Boolean result
     */
    const hasPermissions = (name, value, shouldIgnoreSchoolPermissions) => {
      const permissions = shouldIgnoreSchoolPermissions ? staffPermissions : mergedPermissions;

      if (Array.isArray(value)) {
        return value.includes(permissions[name]);
      } else {
        return permissions[name] === value;
      }
    };

    const hasReadPermissions = (name, shouldIgnoreSchoolPermissions) =>
      hasPermissions(name, ['read', 'write'], shouldIgnoreSchoolPermissions);
    const hasWritePermissions = (name, shouldIgnoreSchoolPermissions) =>
      hasPermissions(name, 'write', shouldIgnoreSchoolPermissions);

    return (
      <WrappedComponent
        hasPermissions={hasPermissions}
        hasReadPermissions={hasReadPermissions}
        hasWritePermissions={hasWritePermissions}
        {...props}
      />
    );
  };

  const Connected = connect(mapStateToProps)(Connectable);
  return Connected;
}

export default withPermissions;
