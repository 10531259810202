import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Form } from 'components';

import './style.scss';

function CreateCrmNote({ data, onClose }) {
  const [saving, setSaving] = useState(false);

  const handleSubmit = async values => {
    setSaving(true);
    const id = get(data, 'note.id');
    const kidId = get(data, 'kid.id');

    try {
      let response;
      if (id) {
        response = await req.updateCrmNote({
          id,
          enrollment_id: kidId,
          note: {
            description: values.description
          }
        });
      } else {
        response = await req.createCrmNote({
          enrollment_id: kidId,
          note: {
            description: values.description
          }
        });
      }
      setSaving(false);
      Actions.showFlash('Note saved');

      onClose(response);
    } catch (e) {
      Actions.reportError('There was problem saving note', e);
      setSaving(false);
    }
  };

  const title = get(data, 'title', 'Add Note');

  return (
    <div className="create-crm-note">
      <div className="modal__header">
        <span className="modal__header-title">{title}</span>
      </div>
      <div className="modal__container">
        <Form onSubmit={handleSubmit} validateOn="submit" isLoading={saving}>
          <Form.Input
            className="create-crm-note__description"
            multiline
            name="description"
            label="Note"
            placeholder="Lead Notes here"
            defaultValue={get(data, 'note.description', '')}
            data-cy="note"
          />
          <div className="create-crm-note__footer form__row--actions">
            <Form.Submit label="Save" data-cy="submit" />
          </div>
        </Form>
      </div>
    </div>
  );
}

CreateCrmNote.propTypes = {
  data: PropTypes.object
};

export default CreateCrmNote;
