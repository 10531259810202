import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Elements } from 'react-stripe-elements';
import { getCurrentSchool } from 'lib/utils/selectors';
import Steps from 'modals/ModalController/Steps';
import Step1 from './Step1';
import Step2 from './Step2';
import Step2Bank from './Step2Bank';
import Step3Bank from './Step3Bank';
import AutopayAgreement from '../../AutopayAgreement';
import './style.scss';
import { fetchPlaidLinkToken } from 'lib/utils/plaid';

function AddPaymentMethod({ match, history, onClose, isSchool = false, onSubmit, startMethod, autopayEnabled }) {
  const [method, setMethod] = useState(startMethod || '');
  const [subKind, setSubKind] = useState('');
  const [plaidLinkToken, setPlaidLinkToken] = useState(null);
  const stepsRef = useRef(null);

  const initializePlaid = async () => {
    try {
      const token = await fetchPlaidLinkToken();
      setPlaidLinkToken(token);
    } catch (error) {
      Actions.reportError('Failed to Create Plaid Link Token', error);
    }
  };

  useEffect(() => {
    initializePlaid();
  }, []);

  const onSelectMethod = (newMethod, newSubKind = '') => {
    setMethod(newMethod);
    setSubKind(newSubKind);

    if (autopayEnabled && !isSchool) {
      stepsRef.current.goToStep('agreement');
    } else {
      stepsRef.current.nextStep();
    }
  };

  const goToList = () => {
    if (onClose) {
      onClose();
    } else {
      const splits = match.url.split('/');
      splits.pop();
      history.push(splits.join('/'));
    }
  };

  return (
    <Steps ref={stepsRef} startStep={startMethod === 'card' ? 1 : 0}>
      <Steps.Item>
        <Step1
          method={method}
          onSubmit={onSubmit}
          onSelectMethod={onSelectMethod}
          goToList={goToList}
          isSchool={isSchool}
          isRegister={!!onSubmit}
          autopayEnabled={autopayEnabled}
          plaidLinkToken={plaidLinkToken}
        />
      </Steps.Item>
      <Steps.Item>
        {method === 'card' ? (
          <Step2
            method={method}
            subKind={subKind}
            onSubmit={onSubmit}
            goToList={goToList}
            isSchool={isSchool}
            autopayEnabled={autopayEnabled}
          />
        ) : (
          <Step2Bank
            method={method}
            onSubmit={onSubmit}
            goToList={goToList}
            isSchool={isSchool}
            autopayEnabled={autopayEnabled}
            plaidLinkToken={plaidLinkToken}
            nextStep={() => stepsRef.current.nextStep()}
            prevStep={() => stepsRef.current.prevStep()}
          />
        )}
      </Steps.Item>
      <Steps.Item>
        <Elements>
          <Step3Bank
            method={method}
            onSubmit={onSubmit}
            goToList={goToList}
            isSchool={isSchool}
            autopayEnabled={autopayEnabled}
          />
        </Elements>
      </Steps.Item>
      <Steps.Item name="agreement">
        <AutopayAgreement prevStep={() => stepsRef.current.goToStep(0)} nextStep={() => stepsRef.current.goToStep(1)} />
      </Steps.Item>
    </Steps>
  );
}

AddPaymentMethod.propTypes = {
  onClose: PropTypes.func,
  isSchool: PropTypes.bool,
  onSubmit: PropTypes.func,
  startMethod: PropTypes.string,
  autopayEnabled: PropTypes.bool
};

const mapStateToProps = state => ({
  autopayEnabled: getCurrentSchool(state).auto_pay_enabled
});

export default connect(mapStateToProps)(AddPaymentMethod);
