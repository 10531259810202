import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

/**
 * ManageController is a component for handling data management for
 * "Manage Items"-like modals. It accepts a function which returns a view component
 * for items list and passes functions and flags necessary for updating data and views.
 */
class ManageController extends PureComponent {
  static propTypes = {
    // An array of items
    items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),

    // Called when an item is added to collection
    onAdd: PropTypes.func,

    // Called when an item is updated
    onUpdate: PropTypes.func.isRequired,

    // Called when all items are changed (e.g. reordering occurs)
    onReplace: PropTypes.func,

    // Called when an item is deleted
    onDelete: PropTypes.func.isRequired,

    // A function which renders item list view. Check render method for signature
    children: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      // A temp id of a new item
      newItemId: undefined,

      // A temp or actual id of an edited item
      editItemId: undefined
    };
  }

  /**
   * Initialize a new item by creating its temp id.
   */
  @bind
  initNewItem() {
    this.setState({ newItemId: v4() });
  }

  /**
   * Cancel creation of a new item.
   */
  @bind
  cancelNewItem() {
    this.setState({ newItemId: undefined });
  }

  /**
   * Call props.onAdd to add an item to items collection.
   * id is added to new item's properties. _new flag, which is added
   * to item's properties indicates that the item isn't saved on the backend yet.
   * _new is expected to exist only on such items.
   *
   * @param {object} item - A new item properties excluding id
   */
  @bind
  createNewItem(item) {
    const addResult = this.props.onAdd({ ...item, id: this.state.newItemId, _new: true });

    if (addResult instanceof Promise) {
      // Rejection here is a mean to prevent action, no need to handle
      // All network errors should be handled in props.onAdd and should not be passed further
      addResult.then(this.cancelNewItem).catch(() => {
        return;
      });
    } else {
      this.cancelNewItem();
    }

    return addResult;
  }

  /**
   * Initialize an edited item.
   *
   * @param {string} editItemId - The id of an item
   */
  @bind
  initEditItem(editItemId) {
    this.setState({ editItemId });
  }

  /**
   * Cancel editing of an item.
   */
  @bind
  endEditItem() {
    this.setState({ editItemId: undefined });
  }

  /**
   * Call props.onUpdate to change an item in items collection.
   * Should be used when the editing is over to write all changed data at once.
   * If props.onUpdate returns a Promise, updateItem waits for its resolution
   * and then disables editing. Otherwise, editing is disabled immediately
   * after props.onUpdate returns.
   *
   * @param {string} id - The id of an item
   * @param {object} nextItem - A modified item (whole item, not a patch)
   * @returns {Promise | undefined}
   */
  @bind
  updateItem(id, nextItem) {
    const updateResult = this.props.onUpdate(id, nextItem);

    if (updateResult instanceof Promise) {
      // Rejection here is a mean to prevent action, no need to handle
      // All network errors should be handled in props.onUpdate and should not be passed further
      updateResult.then(this.endEditItem).catch(() => {
        return;
      });
    } else {
      this.endEditItem();
    }

    return updateResult;
  }

  /**
   * Call props.onReplace to change whole items collection.
   *
   * @param {Array} nextItems - Next items
   */
  @bind
  replaceItems(nextItems) {
    return this.props.onReplace(nextItems);
  }

  /**
   * Call props.onDelete to delete an item from items collection.
   *
   * @param {string} id - The id of an item
   * @returns {Promise | undefined}
   */
  @bind
  deleteItem(id) {
    return this.props.onDelete(id);
  }

  render() {
    const { items, children: renderListView } = this.props;
    const { newItemId, editItemId } = this.state;

    return renderListView({
      // Items data
      items,

      // Is new item form rendered
      isNew: newItemId !== undefined,

      // Is some item edited
      isEdit: editItemId !== undefined,

      // Id of edited item
      editItemId: editItemId,

      // Show new item form
      onNew: this.initNewItem,

      // Create new item using data from new form
      onNewCreate: this.createNewItem,

      // Cancel new item creation, close form
      onNewCancel: this.cancelNewItem,

      // Display edit form of an item
      onEditStart: this.initEditItem,

      // Hide edit form of an item
      onEditEnd: this.endEditItem,

      // Update an item
      onUpdate: this.updateItem,

      // Replace all items
      onReplace: this.replaceItems,

      // Delete an item
      onDelete: this.deleteItem
    });
  }
}

export default ManageController;
