import classNames from 'classnames';
import ActionButton from 'components/ActionButton';
import React from 'react';
import { useSelector } from 'react-redux';

function ModalEditor({ name, data, className, disabled, onChange }) {
  const btnClassName = classNames('generic-editor__edit-btn', className);
  const activeEditorId = useSelector(state => state.profileEditors.activeEditorId);

  const isEnabled = activeEditorId === undefined;

  const open = () => {
    Actions.showModal(name, data).then(onChange);
  };

  return (
    <ActionButton
      disabled={!isEnabled}
      hidden={disabled}
      className={btnClassName}
      iconName="edit"
      size={14}
      onClick={open}
    />
  );
}

export default ModalEditor;
