import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Avatar, ButtonV2 } from 'components';
import './style.scss';
import sortBy from 'lodash/sortBy';

class ReassignProgram extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    isLoading: PropTypes.bool,
    data: PropTypes.object
  };

  static defaultProps = {
    data: {
      staff: null,
      student: null,
      programs: null,
      from_program_id: null,
      isAssigningStaff: false
    }
  };

  constructor(props) {
    super(props);

    const { data, allActivePrograms } = props;
    const { isAssigningStaff, staff, student, from_program_id } = data;

    this.state = {
      isLoading: false,
      formValues: {},
      rooms: allActivePrograms.find(p => p.id === from_program_id)?.sections,
      currentRoomId: isAssigningStaff
        ? staff.program_sections.find(ps => ps.program_id === from_program_id)?.section_id
        : student.program_sections.find(ps => ps.program_id === from_program_id)?.section_id
    };
  }

  @bind
  submit(values) {
    const { from_program_id, isAssigningStaff, staff, student } = this.props.data;
    const { allActivePrograms } = this.props;

    const payload = {
      from_program_id: from_program_id,
      to_program_id: values.to_program_id
    };

    let assignRequest, roomRequest;

    const sectionId = Object.prototype.hasOwnProperty.call(values, 'to_room_id')
      ? values.to_room_id
      : allActivePrograms.find(p => p.id === values.to_program_id).sections[0]?.id;

    if (isAssigningStaff) {
      assignRequest = () => req.reassignProgramStaff({ ...payload, teacher_id: staff.id });
      roomRequest = () =>
        req.batchUpdateProgramStaffRooms({
          program_id: values.to_program_id,
          teacher_section_mapping: [{ teacher_id: staff.id, section_id: sectionId ?? null }]
        });
    } else {
      assignRequest = () => req.reassignProgramStudents({ ...payload, kid_id: student.id });
      roomRequest = () =>
        req.batchUpdateProgramStudentRooms({
          program_id: values.to_program_id,
          kid_section_mapping: [{ kid_id: student.id, section_id: sectionId ?? null }]
        });
    }

    this.setState({ loading: true }, () =>
      assignRequest()
        .then(roomRequest)
        .then(() => {
          const personName = isAssigningStaff ? staff.name : student.name;
          const program = allActivePrograms.find(p => p.id === values.to_program_id);

          Actions.showFlash(
            `${personName} has been reassigned to ${program.name}`,
            'success',
            'View Program',
            `/my-school/programs/${program.id}`
          );
          this.props.onClose(true);
        })
        .catch(e => {
          Actions.reportError('There was a problem reassigning program', e);
          this.setState({ loading: false });
        })
    );
  }

  @bind
  updateFormValues(values) {
    const { allActivePrograms } = this.props;
    const { formValues } = this.state;

    const newState = {
      formValues: values
    };

    if (values.to_program_id !== formValues.to_program_id) {
      newState.rooms = allActivePrograms.find(p => p.id === values.to_program_id)?.sections;
    }

    this.setState(newState);
  }

  render() {
    const { isLoading, allActivePrograms, onClose } = this.props;
    const { staff, student, from_program_id, isAssigningStaff } = this.props.data;
    const { formValues, rooms, currentRoomId } = this.state;
    const person = isAssigningStaff ? staff : student;
    const canSubmit =
      formValues.to_program_id !== from_program_id || (rooms?.length > 1 && formValues.to_room_id !== currentRoomId);

    return (
      <div className="modal__wrapper reassign-program">
        <div className="modal__header">
          <span className="modal__header-title">Reassign Program</span>
        </div>

        <div className="modal__container">
          <Form
            validateOn="submit"
            onSubmit={this.submit}
            isLoading={this.state.isLoading}
            onInit={this.updateFormValues}
            onChange={this.updateFormValues}
          >
            <div className="form__row">
              <Avatar
                type="avatar"
                className="avatar--small"
                name={person.name}
                url={person.profile_pic_url}
                status={person.is_admin ? 'admin' : undefined}
              />

              <div className="staff__row-name">{person.name}</div>
            </div>
            <hr />
            <div className="form__row">
              <Form.Select
                name="to_program_id"
                label="Program"
                className=""
                defaultValue={from_program_id}
                data-cy="to_program_id"
              >
                {allActivePrograms.map(program => (
                  <Form.Select.Item key={program.id} value={program.id} label={program.name} />
                ))}
              </Form.Select>
            </div>

            {rooms?.length > 1 && (
              <div className="form__row">
                <Form.Select
                  name="to_room_id"
                  type="radio"
                  label="Program Room"
                  title="Select Room"
                  defaultValue={currentRoomId}
                  enableClear
                >
                  {sortBy(rooms, 'name').map(room => (
                    <Form.Select.Item key={room.id} value={room.id} label={room.name} />
                  ))}
                </Form.Select>
              </div>
            )}

            <div className="form__row form__row--actions">
              <ButtonV2 data-cy="cancel" secondary label="Cancel" onClick={() => onClose(false)} />
              <Form.Submit data-cy="submit" label="Reassign" disabled={isLoading || !canSubmit} />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allActivePrograms: state.allActivePrograms.data
});

export default connect(mapStateToProps)(ReassignProgram);
