import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActionButton, Preloader } from 'components';
import './style.scss';

const delay = 500;

class PageNav extends Component {
  static propTypes = {
    page: PropTypes.number,
    total: PropTypes.number,
    perPage: PropTypes.number,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    delay: PropTypes.number,
    isBottom: PropTypes.bool,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
    scrollToElementClass: PropTypes.string
  };

  static defaultProps = {
    page: 1,
    total: 1,
    perPage: 1,
    loading: false,
    delay: delay,
    isBottom: false
  };

  @bind
  toPrevPage() {
    const { page } = this.props;
    if (page === 1) {
      return;
    }

    this.props.onPrev();
    this.scroll();
  }

  @bind
  toNextPage() {
    const { page, total, perPage } = this.props;
    const lastEntryNumber = page * perPage;

    if (lastEntryNumber >= total) {
      return;
    }

    this.props.onNext();
    this.scroll();
  }

  scroll() {
    const { isBottom, scrollToElementClass } = this.props;
    const CN = scrollToElementClass || '.page-header';
    const tableEl = document.querySelector(CN);

    if (tableEl && isBottom) {
      tableEl.scrollIntoView();
    }
  }

  getDisplayedIndicies() {
    const { total, page, perPage } = this.props;

    if (total === 0) {
      return '0';
    }

    const lastEntryNumber = page * perPage;
    const lowerIndex = (page - 1) * perPage + 1;
    const upperIndex = lastEntryNumber >= total ? total : lastEntryNumber;

    return perPage === 1 ? `${upperIndex}` : `${lowerIndex} - ${upperIndex}`;
  }

  render() {
    const { page, total, perPage, loading, disabled, isBottom } = this.props;
    const lastEntryNumber = page * perPage;

    if (isBottom && lastEntryNumber >= total) {
      return null;
    }

    return (
      <div className="page-nav">
        {loading && (
          <div className="page-nav__loader">
            <Preloader small />
          </div>
        )}

        <div className="page-nav__count">
          {this.getDisplayedIndicies()} <span className="page-nav__delimiter">of</span> {total}
        </div>

        <ActionButton
          size={12}
          disabled={page === 1 || disabled}
          iconName="chevron-down"
          className="page-nav__arrow-left"
          onClick={this.toPrevPage}
        />

        <ActionButton
          size={12}
          disabled={lastEntryNumber >= total || disabled}
          iconName="chevron-down"
          className="page-nav__arrow-right"
          onClick={this.toNextPage}
        />
      </div>
    );
  }
}

export default PageNav;
