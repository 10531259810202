import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import UploadFile from 'modals/common/UploadFile';
import './style.scss';

const uploadFileSettings = {
  multiple: true,
  strategy: 'local',
  maxFileSize: 20,
  acceptedExts: ['.jpg', '.jpeg', '.png'],
  skipSuccess: true
};

class UploadPhotos extends Component {
  static propTypes = {
    onUpload: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired
  };

  @bind
  handleUpload(filesList, results) {
    const data = filesList.map((file, index) => {
      return { id: v4(), name: file.name, data: results[index], _file: file };
    });

    this.props.onUpload(data);
  }

  render() {
    return (
      <div className="upload-photos">
        <UploadFile {...uploadFileSettings} onSuccess={this.handleUpload} onBack={this.props.onBack} />
      </div>
    );
  }
}

export default UploadPhotos;
