import React, { useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ActionButton } from 'components';
import { isMobile } from 'lib/utils';
import PlaidLink from 'components/PlaidLink';
import { ModalControllerStepsContext } from 'hocs/withContext';

function EnrollStep2({ onUpdateMethod, plaidLinkToken }) {
  const { nextStep, prevStep } = useContext(ModalControllerStepsContext);
  const mobile = isMobile();

  const onPlaidSuccess = (publicToken, metadata) => {
    const { account_id, institution } = metadata;
    onUpdateMethod({
      paymentMethod: 'plaid',
      plaidToken: publicToken,
      plaidAccountId: account_id,
      bankName: institution.name
    });

    nextStep();

    if (mobile) {
      Actions.hideCloak();
    }
  };

  const onPlaidExit = (err, metadata) => {
    if (err) {
      Actions.reportError('There was an error adding the bank account', err);
      console.error('Plaid Exit Error:', err, metadata);
    }

    if (mobile) {
      Actions.hideCloak();
    }
  };

  const onSelectMethod = paymentMethod => {
    onUpdateMethod({ paymentMethod });
    nextStep();
  };

  const openPlaid = () => {
    if (mobile) {
      Actions.showCloak();
    }
  };

  return (
    <div className="modal__wrapper enroll">
      <div className="modal__header">
        <ActionButton iconName="back" size={20} onClick={prevStep} />
        <span className="modal__header-title">Enroll</span>
        <span className="modal__header-steps">
          <span>Step 2</span> / 3
        </span>
      </div>
      <div className="modal__container">
        <div className="payments-panel">
          <div className="payments-panel__content">
            <div className="payments-panel__subtitle">Choose your payment method to proceed</div>
            <div className="path-selector">
              <div className="path-selector__option" onClick={() => onSelectMethod('card')}>
                <div className="path-selector__option-inner">Add a Credit Card</div>
              </div>
              <PlaidLink
                linkToken={plaidLinkToken}
                onSuccess={onPlaidSuccess}
                onExit={onPlaidExit}
                onClick={openPlaid}
                className="path-selector__option"
              >
                <div className="path-selector__option-inner">
                  Connect A Bank Account
                  <div className="path-selector__option-inner__desc">Easy and secure One-Step way to add your bank</div>
                </div>
              </PlaidLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

EnrollStep2.propTypes = {
  onUpdateMethod: PropTypes.func,
  plaidLinkToken: PropTypes.string
};

const mapStateToProps = state => ({
  constants: state.constants.data
});

export default connect(mapStateToProps)(EnrollStep2);
