import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ActionButton, Link } from 'components';
import './style.scss';

const CHECKR_SHOW_LIMIT = 4;

function CheckrBar(props) {
  const [hideBar, setHideBar] = useState(localStorage.getItem('checkr_bar_hide') || false);

  const showCount = localStorage.getItem('checkr_bar_show_count') * 1 || 0;

  useEffect(() => {
    localStorage.setItem('checkr_bar_show_count', showCount + 1);
  }, []);

  const handleClose = () => {
    setHideBar(true);
    localStorage.setItem('checkr_bar_hide', true);

    Actions.showModal('Alert', {
      title: 'Connect Checkr',
      message: "You can connect with Checkr anytime by going to 'School Settings -> Marketplace'",
      label: 'Okay'
    });
  };

  const handleGetStarted = () => {
    const { checkr } = props;

    localStorage.setItem('checkr_bar_show_count', CHECKR_SHOW_LIMIT + 1);
    window.open(
      `https://partners.checkr.com/authorize/${checkr.client_id}?redirect_uri=${encodeURIComponent(
        checkr.redirect_uri
      )}`
    );
  };

  const { isAdmin, loading, checkr, permissions } = props;

  if (loading || checkr.enabled || !isAdmin || hideBar || permissions.teachers !== 'write') return null;

  return (
    <div className="checkr-bar">
      <div className="checkr-bar__content">
        <img className="checkr-bar__image" src="/assets/images/marketplace/Checkr-short-logo.png" />
        <div className="checkr-bar__description">
          <div className="checkr-bar__description-main">
            Use Checkr to run background checks for your staff with Procare
          </div>
          <div className="checkr-bar__description-sub">
            This will allow you to initiate and receive reports about your applicants
          </div>
        </div>
        <div className="checkr-bar__actions">
          <Link onClick={handleGetStarted} className="button checkr-bar__connect">
            Get Started
          </Link>
          {showCount > CHECKR_SHOW_LIMIT && (
            <ActionButton className="checkr-bar__close" iconName="close" size={10} onClick={handleClose} />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  isAdmin: state.currentUser.data.teacher && state.currentUser.data.teacher.is_admin,
  permissions: state.currentUser.data.permissions,
  checkr: state.checkr.data,
  loading: state.checkr.loading
});

export default connect(mapStateToProps)(CheckrBar);
