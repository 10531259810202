import React, { useEffect, useRef, useState } from 'react';

// Wrapped components know whether they are visible via the `isVisible` prop
// Call with runOnce = true if using for lazy loading
// Components using this HOC must set `ref={this.props.innerRef}` on their root element

const withIsVisible = (WrappedComponent, runOnce = false) => {
  return props => {
    const ref = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const observer = useRef(
      new IntersectionObserver(
        (entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              runOnce && observer.unobserve(ref.current);
            } else if (!runOnce) {
              setIsVisible(false);
            }
          });
        },
        { root: null, rootMargin: '0px', threshold: 0 }
      )
    ).current;

    useEffect(() => {
      observer.observe(ref.current);

      return () => observer.disconnect();
    }, []);

    return <WrappedComponent innerRef={ref} isVisible={isVisible} {...props} />;
  };
};

export default withIsVisible;
