import React, { Fragment } from 'react';
import { Form } from 'components';
import EMERGENCY_FIELDS from 'constants/emergencyReportFields';
import ReportCheckboxGroup from './ReportCheckboxGroup';

function EmergencyCardFields({ rooms, customAttributes, constants, values, defaultValues, onUpdateField }) {
  const customStudentFields = customAttributes
    .filter(c => c.keeper === 'kid')
    .map(c => ({ value: c.id, label: c.name, keeper: c.keeper }));
  const customParentFields = customAttributes
    .filter(c => c.keeper === 'carer')
    .map(c => ({ value: c.id, label: c.name, keeper: c.keeper }));

  return (
    <Fragment>
      <div className="form__row">
        <div className="reports-modal__checkbox-list">
          <ReportCheckboxGroup
            name="student_fields"
            label="Student"
            defaultValue={defaultValues.student_fields}
            value={values.student_fields}
            options={EMERGENCY_FIELDS.student}
            onUpdateField={onUpdateField}
          />
          <ReportCheckboxGroup
            name="family_fields"
            label="Family"
            defaultValue={defaultValues.family_fields}
            value={values.family_fields}
            options={EMERGENCY_FIELDS.family}
            onUpdateField={onUpdateField}
          />
          {customStudentFields.length > 0 && (
            <ReportCheckboxGroup
              name="custom_student_fields"
              label="Custom Fields - Student"
              showSelectAll={false}
              defaultValue={defaultValues.custom_student_fields}
              value={values.custom_student_fields}
              options={customStudentFields}
              onUpdateField={onUpdateField}
            />
          )}
          {customParentFields.length > 0 && (
            <ReportCheckboxGroup
              name="custom_parent_fields"
              label={
                <Fragment>
                  Custom Fields - Parent <small>(Select up to 3)</small>
                </Fragment>
              }
              showSelectAll={false}
              defaultValue={defaultValues.custom_parent_fields}
              value={values.custom_parent_fields}
              maxSelection={3}
              options={customParentFields}
              onUpdateField={onUpdateField}
            />
          )}
        </div>
      </div>
      <div className="form__row">
        <div className="form__radio-group__wrapper">
          <label className="form__label">Group By</label>
          <Form.RadioGroup
            className="reports-modal__checkbox-group"
            name="group_by"
            type="circle"
            options={[
              { value: 'school', label: 'School' },
              { value: 'section', label: 'Room' }
            ]}
            defaultValue={defaultValues.group_by}
          />
        </div>
        {(values.group_by || defaultValues.group_by) === 'section' && (
          <Form.Select
            name="section_ids"
            title="All Rooms"
            type="checkbox"
            multipleSelectionText="rooms"
            allSelectionItemId="all"
            defaultValue={defaultValues.section_ids}
          >
            <Form.Select.Item label="All Rooms" value="all" />
            {rooms.map(r => (
              <Form.Select.Item key={r.id} label={r.name} value={r.id} />
            ))}
          </Form.Select>
        )}
      </div>
      <div className="form__row form__row--justified">
        <Form.Select
          name="sort_by"
          label="Sort Report By"
          title="Alphabetical by First Name"
          defaultValue={defaultValues.sort_by}
        >
          <Form.Select.Item label="Alphabetical by First Name" value="alphabetically_first_name" />
          <Form.Select.Item label="Alphabetical by Last Name" value="alphabetically_last_name" />
          <Form.Select.Item label="Oldest to youngest" value="oldest_to_youngest" />
          <Form.Select.Item label="Youngest to oldest" value="youngest_to_oldest" />
        </Form.Select>
        <Form.Select name="student_status" label="Student" title="Active" defaultValue={defaultValues.student_status}>
          {Object.keys(constants.kid_registration_statuses).map(s => (
            <Form.Select.Item key={s} label={constants.kid_registration_statuses[s]} value={s} />
          ))}
        </Form.Select>
      </div>
    </Fragment>
  );
}

export default EmergencyCardFields;
