import React from 'react';
import cx from 'classnames';
import './style.scss';

const Preloader = ({ className, small, large, white, center }) => {
  const preloaderCN = cx('preloader', className, {
    'preloader--small': small,
    'preloader--large': large,
    'preloader--center': center,
    'preloader--white': white
  });

  return <img src="/assets/images/placeholders/preloader.gif" className={preloaderCN} />;
};

export default Preloader;
