import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from './Modal';
import initializeModals from './initializeModals';
import SlideWrapper from './SlideWrapper';

const getModalComponent = initializeModals();

const SLIDE_TYPE_MODAL = 'slide';

class ModalController extends Component {
  modalRef = {};

  static propTypes = {
    visible: PropTypes.bool,
    className: PropTypes.string,
    component: PropTypes.string,
    hideModal: PropTypes.func
  };

  static defaultProps = {
    visible: false,
    options: {}
  };

  @bind
  closeModal(id, data) {
    if (Actions.modalResolve) {
      // Workaround to detect SyntheticEvent
      if (data && data.nativeEvent) {
        Actions.modalResolve(id);
      } else {
        Actions.modalResolve(id, data);
      }
    }

    this.hideModal(id);
  }

  @bind
  onRejectModal(id, error) {
    Actions.modalReject(id, error);
    this.hideModal(id);
  }

  @bind
  hideModal(id) {
    const ref = this.modalRef[id];
    if (ref) {
      return ref.unmountModal();
    }
    this.props.hideModal(id);
  }

  @bind
  toggleModal(id) {
    this.props.toggleModal(id);
  }

  getModalComponent(componentName) {
    if (!componentName) {
      return null;
    }

    let ModalComponent;
    try {
      ModalComponent = getModalComponent(componentName);
    } catch (e) {
      console.warn(`[ModalController] An error occurred while loading: \`${componentName}\``);
      console.warn(e);
      ModalComponent = null;
    }

    return ModalComponent;
  }

  @bind
  withSlideWrapper(type, id) {
    return component =>
      type === SLIDE_TYPE_MODAL ? (
        <SlideWrapper
          key={id}
          ref={node => {
            this.modalRef[id] = node;
          }}
          onUnmount={() => {
            this.props.hideModal(id);
          }}
        >
          {component}
        </SlideWrapper>
      ) : (
        component
      );
  }

  renderModal(modalInfo) {
    const { isVisible, data, options, id } = modalInfo;
    const ModalComponent = this.getModalComponent(modalInfo.component);
    const dataProps = options.passDataToProps ? { ...data } : undefined;
    const withSlide = this.withSlideWrapper(options.type, id);

    return withSlide(
      <Modal
        key={id}
        className={options.className}
        visible={isVisible}
        onClose={() => this.closeModal(id)}
        noCloseIcon={options.noCloseIcon}
        type={options.type}
      >
        <ModalComponent
          data={data}
          {...dataProps}
          options={options}
          onCloseAll={() => this.props.closeAllModals()}
          onClose={data => this.closeModal(id, data)}
          onToggle={() => this.toggleModal(id)}
          onReject={data => this.onRejectModal(id, data)}
        />
      </Modal>
    );
  }

  render() {
    const { modals } = this.props;
    if (!modals.length) {
      return null;
    }

    return <React.Fragment>{modals.map(m => this.renderModal(m))}</React.Fragment>;
  }
}

const mapStateToProps = state => ({
  modals: state.modal.modals
});

const mapDispatchToProps = dispatch => ({
  closeAllModals: () => dispatch({ type: 'MODAL_RESET' }),
  hideModal: id => {
    dispatch({ type: 'MODAL_HIDE', payload: id });
  },
  toggleModal: id => {
    dispatch({ type: 'MODAL_TOGGLE', payload: id });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalController);
