export enum BillingGateway {
  TE = 'te',
  Stripe = 'stripe',
  Bluepay = 'bluepay'
}

export enum PaymentMode {
  Bank = 'bank',
  BankAccount = 'bank_account',
  Card = 'card',
  Check = 'check',
  Cash = 'cash',
  Other = 'other'
}

export class PaymentMethod {
  kind: string;
  subKind: string;
  verifiedSubKind: string;

  constructor(kind: string, subKind: string, verifiedSubKind: string) {
    this.kind = kind;
    this.subKind = subKind;
    this.verifiedSubKind = verifiedSubKind;
  }
}
