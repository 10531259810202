import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';

function MessageStep({ message = {}, ...props }) {
  return (
    <div className="modal__wrapper share-meal__edit-message">
      <div className="modal__header modal__header--bordered">
        <span className="modal__header-title share-meal__title">Share Meal Plan</span>
        <span className="modal__header-steps">
          <span>Step 1</span> / 2
        </span>
      </div>

      <div className="modal__container">
        <Form validate="onSubmit" onSubmit={props.onSubmit} className="share-meal__form">
          <div className="form__row">
            <Form.Input name="subject" defaultValue={message.subject} placeholder="Email subject" required />
          </div>

          <div className="form__row">
            <Form.Textarea
              name="content"
              defaultValue={message.content}
              className="share-meal__form__textarea"
              placeholder="Email content"
              required
            />
          </div>
          <div className="share-meal__form__description">Meal Plan will be sent as pdf attachment in email</div>

          <div className="modal__controls">
            <Form.Submit label="Continue" data-cy="continue" />
          </div>
        </Form>
      </div>
    </div>
  );
}

MessageStep.propTypes = {
  message: PropTypes.object,
  onContinue: PropTypes.func
};

export default MessageStep;
