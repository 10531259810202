import React from 'react';
import { Form } from 'components';

const NapCheckTypeform = ({ value, constants }) => {
  const sleepPositions = constants.nap_check_sleep_positions;
  return (
    <div className="form__row">
      <div className="form-input">
        <label className="form__label">
          Sleep Position <span className="form__asterisk">*</span>
        </label>
        <Form.RadioGroup
          name="sleep_position"
          title="Sleep Position"
          className="create-activity__sleep-position"
          type="circle"
          defaultValue={value}
          options={Object.keys(sleepPositions).map(position => ({
            value: position,
            label: sleepPositions[position]
          }))}
        />
      </div>
    </div>
  );
};

export default NapCheckTypeform;
