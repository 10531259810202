import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { ButtonV2 } from 'components';
import { getTotalAmount, getDiscountPrice, isDiscount } from 'lib/utils';
import currency from 'lib/currency';
import Item from './Item';
import './style.scss';

export class InvoiceItemList extends Component {
  static propTypes = {
    mode: PropTypes.string,
    data: PropTypes.array,
    type: PropTypes.oneOf(['create', 'edit']),
    disabled: PropTypes.bool,
    templateGroupEntity: PropTypes.bool,
    enablePresets: PropTypes.bool,
    onManagePresets: PropTypes.func,
    tabIndex: PropTypes.number,
    onClose: PropTypes.func
  };

  static defaultProps = {
    mode: null,
    data: [],
    type: 'create',
    templateGroupEntity: false,
    disabled: false
  };

  static createItem(type, mode, kind) {
    const newItem = {
      description: '',
      price: ''
    };

    if (type === 'edit') {
      newItem._destroy = false;
    }

    if (mode === 'attendance') {
      newItem.kind = 'attendance';
    } else {
      newItem.kind = kind || 'tuition';
    }

    return newItem;
  }

  static processInitialItems(items, type, isUpdate) {
    if (type === 'edit') {
      return items.map(item => {
        if (InvoiceItemList.isLedger(item.kind)) {
          // ledger items uneditable
          return {
            ...item,
            _readonly: isUpdate ? false : true
          };
        }

        return item;
      });
    }

    return items;
  }

  static isLedger(kind) {
    return kind === 'ledger';
  }

  constructor(props) {
    super(props);

    this.state = {
      items:
        props.data.length > 0
          ? InvoiceItemList.processInitialItems(props.data, props.type)
          : [InvoiceItemList.createItem(null, props.mode, props.franchiseBilling && 'preset')]
    };
  }

  componentDidMount() {
    if (this.props.enablePresets) {
      req.invoiceItemPresets();
      req.ledgers({
        filters: {
          ledger: {
            kind: ['subsidy']
          }
        },
        page: 1,
        per_page: 50 // should be enough
      }); // ledgers with kind=subsidy as presets
    }
    if (this.props.data.length === 0) {
      this.handleUpdate(); // update items on empty input data to make "outside" state consistent
    }
  }

  componentDidUpdate(prevProps) {
    // This is calling processInitialItems when addItem is called.
    // In order to allow Subsidy items to be added and editable initially, we need the third parameter telling that function "Let the user edit this"
    /// But we don't want these items editable after they are added, or to be filtered out when a new item is added
    if (prevProps.data !== this.props.data) {
      const readOnlyItems = this.state.items.filter(item => item._readonly);
      let newItems = InvoiceItemList.processInitialItems(this.props.data, this.props.type, true);
      if (readOnlyItems.length > 0) {
        newItems.forEach(item => {
          const isExisting = readOnlyItems.find(readOnlyItem => readOnlyItem.id === item.id);
          if (isExisting) item._readonly = true;
        });
      }
      this.setState({
        items: newItems
      });
    }
  }

  handleUpdate = isItemAdded => {
    const { items } = this.state;

    let updatedItems = items.map(item => ({
      ...item,
      description: item.description,
      price: isDiscount(item.kind) ? -getDiscountPrice(item) : Number(item.price),
      quantity: isNaN(item.quantity) ? undefined : Number(item.quantity)
    }));

    if (!isItemAdded) {
      updatedItems.filter(item => !item._readonly);
    }

    this.props.onUpdate(updatedItems);
  };

  addItem = () => {
    const { items } = this.state;
    const { mode, franchiseBilling } = this.props;

    this.setState(
      {
        items: [...items, InvoiceItemList.createItem(this.props.type, mode, franchiseBilling && 'preset')]
      },
      () => this.handleUpdate(true)
    );
  };

  updateItem = (itemIndex, updatedItem) => {
    this.setState(prevState => {
      const newItems = [...prevState.items];
      newItems[itemIndex] = updatedItem;
      return { items: newItems };
    }, this.handleUpdate);
  };

  handleRemove = itemIndex => {
    if (this.props.type === 'edit' && this.state.items[itemIndex].id !== undefined) {
      this.markForRemoval(itemIndex);
    } else {
      this.removeItem(itemIndex);
    }
  };

  removeItem = itemIndex => {
    this.setState(prevState => {
      const newItems = [...prevState.items.slice(0, itemIndex), ...prevState.items.slice(itemIndex + 1)];
      return { items: newItems };
    }, this.handleUpdate);
  };

  markForRemoval = itemIndex => {
    this.setState(prevState => {
      const nextItems = [
        ...prevState.items.slice(0, itemIndex),
        { ...prevState.items[itemIndex], _destroy: true },
        ...prevState.items.slice(itemIndex + 1)
      ];
      return { items: nextItems };
    }, this.handleUpdate);
  };

  isAddNewRowDisabled = () => {
    return this.props.disabled || this.state.items.some(item => !item.description && !isDiscount(item.kind));
  };

  renderItems = () => {
    const { items } = this.state;
    const { mode, templateGroupEntity, tabIndex, onClose, type } = this.props;

    return items.map((item, index) => (
      <Item
        key={`items-row-${index}`}
        id={index}
        mode={mode}
        data={item}
        kind={item.kind}
        templateGroupEntity={templateGroupEntity}
        onUpdate={this.updateItem}
        onRemove={this.handleRemove}
        removable={items.length > 1 || index !== 0 || item._readonly}
        disabled={this.props.disabled || item._readonly}
        onManagePresets={this.props.onManagePresets}
        tabIndex={tabIndex}
        onClose={onClose}
        type={type}
      />
    ));
  };

  render() {
    const { mode } = this.props;
    const total = getTotalAmount(this.state.items, mode);

    return (
      <div className="table invoice-item-list">
        <div className="table__header">
          {mode !== 'attendance' && (
            <div className="table__cell table__cell--xs invoice-item-list__cell-type">Type</div>
          )}

          <div
            className={cx('table__cell', {
              'table__cell--l': mode !== 'attendance',
              'table__cell--s': mode === 'attendance'
            })}
          >
            Item description
          </div>

          {mode === 'attendance' && <div className="table__cell table__cell--xs invoice-item-list__price">Price</div>}

          {mode === 'attendance' && (
            <div className="table__cell table__cell--xs invoice-item-list__quantity">Hours</div>
          )}

          <div className="table__cell table__cell--xs invoice-item-list__amount-text">Amount</div>

          <div className="table__cell table__cell--xxs" />
        </div>

        <div className="table__body">
          {this.renderItems()}

          <div className="table__row">
            <div className="table__cell table__cell--l invoice-item-add">
              <ButtonV2
                tertiary
                className="invoice-item-list__add-new"
                label="Add invoice item"
                onClick={this.addItem}
                disabled={this.isAddNewRowDisabled()}
              />
              <div className="invoice-item-list__add-new__desc">Preset Charges, Discounts & Subsidy</div>
            </div>

            <div className="table__cell table__cell--xs invoice-item-list__total">
              Total: <span className="value">{currency.getPrice(total)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  franchiseBilling: state.currentUser.data.current_school.franchise_billing_enabled
});

export default connect(mapStateToProps)(InvoiceItemList);
