import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

class Tab extends Component {
  // TODO: Consider extending with onClick prop.
  // In case if custom behavior is needed,
  // we could use div instead NavLink with onClick prop passed to it.
  static propTypes = {
    url: PropTypes.string,
    label: PropTypes.string,
    exact: PropTypes.bool,
    appendChildren: PropTypes.bool
  };

  static defaultProps = {
    exact: false,
    appendChildren: false
  };

  render() {
    const { url, label, exact, appendChildren, children } = this.props;

    if (!url) {
      return null;
    }

    const className = classNames('tabbar-tab', this.props.className);

    return (
      <NavLink to={url} exact={exact} className={className} activeClassName="tabbar-tab--active">
        {label ? <div className="navtabs__tab-label">{label}</div> : children}

        {appendChildren && children}
      </NavLink>
    );
  }
}

export default Tab;
