import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tab from './Tab';
import './style.scss';

class NavTabs extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.array // Meant to be an array of NavTabs.Tab
  };

  static Tab = Tab;

  render() {
    const className = classNames('tabbar', 'navtabs', this.props.className);

    return <div className={className}>{this.props.children}</div>;
  }
}

export default NavTabs;
