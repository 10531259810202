import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Preloader } from 'components';
import Steps from 'modals/ModalController/Steps';
import DescriptionStep from './DescriptionStep';
import ValidationStep from './ValidationStep';
import SplitStep from './SplitStep';

import './style.scss';

const PAUSED_PLAN_STATE = 'paused';

class SplitFamily extends Component {
  constructor(props) {
    super(props);

    this.state = {
      familyId: props.data.family_id,
      currentBalance: props.data.currentBalance,
      activePlans: false,
      loading: true,
      applyingCredit: false,
      pausingPlans: false,
      validationRequired: false,
      transactionsUpdateRequired: false
    };
  }

  static hasActivePlans(kids = []) {
    return !!kids.filter(kid => kid.billing_plans.some(plan => plan.state !== PAUSED_PLAN_STATE)).length;
  }

  componentDidMount() {
    Promise.all([
      req.students(),
      req.subFamilies({ family_id: this.state.familyId }),
      req.recurringPlans({ filters: { family: { id: this.state.familyId } } })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ]).then(([students, subFamilies, { kids }]) => {
      this.setState(
        {
          loading: false,
          activePlans: SplitFamily.hasActivePlans(kids)
        },
        () => {
          this.isValidationRequired();
        }
      );
    });
  }

  @bind
  async handleApplyCredit() {
    try {
      this.setState({ applyingCredit: true });
      const res = await Actions.showModal('AddCredit', {
        family_id: this.state.familyId,
        students: this.props.data.familyKids
      });

      if (res) {
        const stats = await req.familyBillingStats({ id: this.state.familyId });
        if (stats) {
          this.setState({
            currentBalance: stats.current_balance,
            transactionsUpdateRequired: true
          });
        }
      }
    } catch (err) {
      Actions.reportError('Unable to apply credit', err);
    }
    this.setState({ applyingCredit: false });
  }

  @bind
  async handlePausePlans() {
    try {
      this.setState({ pausingPlans: true });
      const res = await Actions.showModal('Confirmation', {
        title: 'Pause Plans',
        description: 'Are you sure you want to pause plans for this family?',
        yesButton: 'Yes, Pause'
      });

      if (res) {
        await req.pauseFamilyBillingPlans({ id: this.state.familyId });
        this.setState({ activePlans: false });
        Actions.showFlash('Paused billing plans successfully');
      }
    } catch (err) {
      Actions.reportError('Unable to pause plans', err);
    }
    this.setState({ pausingPlans: false });
  }

  isValidationRequired() {
    const { currentBalance, activePlans } = this.state;
    const { subFamilies } = this.props;

    this.setState({
      validationRequired: !subFamilies.length && (currentBalance > 0 || activePlans)
    });
  }

  @bind
  handleClose(data) {
    return this.props.onClose(data || this.state.transactionsUpdateRequired);
  }

  render() {
    const { subFamilies, students } = this.props;
    const { familyId, loading, currentBalance, activePlans, validationRequired } = this.state;

    return (
      <div className="split-family">
        {loading ? (
          <div className="modal__wrapper">
            <div className="modal__container">
              <div className="split-family__preloader">
                <Preloader center large />
              </div>
            </div>
          </div>
        ) : (
          <Steps>
            {!subFamilies.length && (
              <Steps.Item>
                <DescriptionStep onClose={this.handleClose} />
              </Steps.Item>
            )}

            {validationRequired && (
              <Steps.Item>
                <ValidationStep
                  familyId={familyId}
                  currentBalance={currentBalance}
                  activePlans={activePlans}
                  onApplyCredit={this.handleApplyCredit}
                  onPausePlans={this.handlePausePlans}
                  applyingCredit={this.state.applyingCredit}
                  pausingPlans={this.state.pausingPlans}
                  onClose={this.handleClose}
                />
              </Steps.Item>
            )}

            <Steps.Item>
              <SplitStep students={students} subFamilies={subFamilies} familyId={familyId} onClose={this.handleClose} />
            </Steps.Item>
          </Steps>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  subFamilies: state.subFamilies.data,
  students: state.students.data,
  recurringPlans: state.recurringPlans.data
});

export default connect(mapStateToProps)(SplitFamily);
