import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import v4 from 'uuid/v4';
import get from 'lodash/get';
import chunk from 'lodash/chunk';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import { Form, ActionButton } from 'components';
import { getSubscriptionCategory } from 'lib/utils';
import AddressField from './AddressField';
import { DAY_TO_CODE, CODE_TO_DAY } from 'constants/dayCodes';
import './style.scss';

const DEFAULT_CARER_FIELDS = [
  {
    id: v4()
  }
];

const ENTERPRISE_SUBSCRIPTION_CATEGORY = 'enterprise';

class NewEnroll extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    data: PropTypes.object
  };

  static serializeTimeSchedule(schedule = {}) {
    const result = {};

    for (const day in schedule) {
      result[DAY_TO_CODE[day]] = keys(pickBy(schedule[day], Boolean));
    }

    return result;
  }

  static deserializeTimeSchedule(schedule = {}) {
    const result = {};

    for (const code in schedule) {
      result[CODE_TO_DAY[code]] = schedule[code].reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: true
        }),
        {}
      );
    }

    return result;
  }

  constructor(props) {
    super();

    const defaultCarers = get(props, 'data.kid.carers', []);

    this.state = {
      isSaving: false,
      carerFields: defaultCarers.length
        ? defaultCarers.map(c => ({
            id: c.id
          }))
        : DEFAULT_CARER_FIELDS,
      requiredDOB: !get(props, 'data.kid.expected_birth', false),
      fromLinkSibling: get(props, 'data.fromLinkSibling', false),
      isEdit: Boolean(get(props, 'data.kid.id'))
    };

    this.formRef;
    this.formElement;
    this.requiredFieldRefs = {};
    this.tabIndex = 0;
  }

  get enrolledStateId() {
    const stage = this.props.leadStages.find(s => s.kind === 'enrolled');
    return stage && stage.id;
  }

  get parentCustomAttributes() {
    return this.props.customAttributes.filter(c => c.keeper === 'enrollment_carer');
  }

  get leadCustomAttributes() {
    return this.props.customAttributes.filter(c => c.keeper === 'enrollment');
  }

  getNotNull(object, path, defaultValue) {
    const result = get(object, path, defaultValue);
    return result === null ? defaultValue : result;
  }

  UNSAFE_componentWillMount() {
    req.rooms();
    req.customAttributes();
    req.leadSources();
  }

  @bind
  async submit(values) {
    const { data, customAttributes } = this.props;
    const kid = data && data.kid;
    const originalCarers = get(kid, 'carers', []);

    const carerFields = this.state.carerFields
      .filter((field, index) => {
        return !field._destroy || get(originalCarers, `${index}.id`) === field.id;
      })
      .map((field, index) => {
        const { id, _destroy } = field;

        if (_destroy) {
          return {
            id,
            _destroy
          };
        }

        const originalCarerFields = originalCarers[index] || {};
        const carerCustomAttributeValues = {};
        customAttributes.forEach(attr => {
          const value = values[`parent_${id}_${attr.id}`];
          if (value !== undefined) {
            carerCustomAttributeValues[attr.id] = attr.field_type === 'list' ? [value] : value;
          }
        });

        return {
          ...originalCarerFields,
          id: !this.state.fromLinkSibling ? originalCarerFields.id : undefined,
          first_name: values[`parent_${id}_first_name`],
          last_name: values[`parent_${id}_last_name`],
          email: values[`parent_${id}_email`],
          mobile_phone: values[`parent_${id}_phone`],
          custom_attributes: {
            ...carerCustomAttributeValues
          }
        };
      });

    const customAttributeValues = {};
    customAttributes.forEach(attr => {
      if (values[attr.id] !== undefined) {
        customAttributeValues[attr.id] = attr.field_type === 'list' ? [values[attr.id]] : values[attr.id];
      }
    });

    const payload = {
      enrollment: {
        first_name: values.first_name,
        last_name: values.last_name,
        dob: values.dob ? moment(values.dob).format('YYYY-MM-DD') : undefined,
        time_schedule: NewEnroll.serializeTimeSchedule(values.time_schedule),
        expected_birth: values.expected_birth,
        allergy: values.allergy,
        medications: values.medications,
        source_id: values.source_id,
        source_details: values.source_details,
        state_id: values.state_id,
        section_id: values.section_id || undefined,
        enroll_date: values.start_date ? moment(values.start_date).format('YYYY-MM-DD') : undefined,
        notes: values.notes,
        carers_attributes: carerFields,
        custom_attributes: customAttributeValues,
        ...values.address,
        region: values.address.state
      }
    };

    this.setState({ isSaving: true });
    try {
      const { isEdit } = this.state;

      let shouldEnroll;
      const originalStateId = get(kid, 'state_id');
      if (values.state_id === this.enrolledStateId && (!isEdit || originalStateId !== values.state_id)) {
        shouldEnroll = await this.enrollRegistration({
          first_name: values.first_name,
          last_name: values.last_name
        });
        if (!shouldEnroll) {
          return;
        }

        payload.enrollment.enroll_section_id = shouldEnroll.section_id;
        payload.enrollment.enroll_date = shouldEnroll.date;
      }

      let res;
      if (isEdit) {
        const stateChangeConfirmed = await this.confirmStateChange(values.state_id);
        if (!stateChangeConfirmed) {
          return;
        }

        res = await req.updateEnrollmentItem({
          id: kid.id,
          ...payload
        });
      } else {
        res = await req.addEnrollmentItem(payload);
      }

      this.props.onClose(res);
      this.setState({ isSaving: false }, () => {
        return Actions.showFlash('Enrollment Saved successfully');
      });
    } catch (err) {
      this.setState({ isSaving: false });
      Actions.reportError('There was problem saving enrollment', err);
    }
  }

  @bind
  confirmStateChange(nextStateId) {
    const originalStateId = get(this.props, 'data.kid.state_id', '');

    if (originalStateId === this.enrolledStateId && nextStateId !== originalStateId) {
      const nextStageName = this.props.leadStages.find(s => s.id === nextStateId);
      return Actions.showModal('Confirmation', {
        title: 'Already Enrolled',
        description: `Are you sure you want to change the status to ${nextStageName.name}?`
      }).then(res => {
        if (!res) {
          this.setState({ isSaving: false });
        }
        return res;
      });
    }

    return Promise.resolve(true);
  }

  @bind
  enrollRegistration(kid) {
    return Actions.showModal('EnrollRegistration', {
      kid,
      confirmationOnly: true
    }).then(regSettings => {
      if (!regSettings) {
        this.setState({ isSaving: false });
      }

      return regSettings;
    });
  }

  @bind
  renderAdditionalFields() {
    const { data } = this.props;
    const kid = data && data.kid;

    return chunk(this.leadCustomAttributes, 2).map(attrList =>
      attrList.map(attr => (
        <Form.CustomAttribute
          tabIndex={++this.tabIndex}
          key={attr.id}
          attr={attr}
          defaultValue={get(kid, `custom_attributes.${attr.id}`)}
        />
      ))
    );
  }

  @bind
  handleAddCarerFields() {
    this.setState(prevState => ({
      carerFields: [
        ...prevState.carerFields,
        {
          id: v4()
        }
      ]
    }));
  }

  @bind
  handleRemoveCarerFields(id) {
    const nextFields = [...this.state.carerFields];
    const removedIndex = nextFields.findIndex(cf => cf.id === id);
    nextFields[removedIndex]['_destroy'] = true;
    this.setState({
      carerFields: nextFields
    });
  }

  @bind
  change(values) {
    const { requiredDOB } = this.state;
    const nextState = {};

    // expecting mother
    const nextRequiredDOB = !values.expected_birth;
    if (nextRequiredDOB !== requiredDOB) {
      nextState.requiredDOB = nextRequiredDOB;
    }

    this.setState(nextState);
  }

  @bind
  validate(values, formValidations) {
    this.scrollToInvalidField(formValidations);
  }

  scrollToInvalidField(validations) {
    let validationPos = null;
    Object.keys(this.requiredFieldRefs).forEach(fieldName => {
      let isValid = validations[fieldName];
      if (!isValid) {
        let ref = this.requiredFieldRefs[fieldName];
        let top = 0;

        while (ref && ref !== this.formElement && ref !== document.body) {
          top += ref.offsetTop;
          ref = ref.parentNode;
        }

        if (ref === this.formElement) {
          validationPos = validationPos !== null ? Math.min(top, validationPos) : top;
        }
      }
    });

    if (validationPos !== null && typeof this.formElement.scroll === 'function') {
      this.formElement.scroll({
        top: validationPos,
        behavior: 'smooth'
      });
    }
  }

  renderCarerFields() {
    const { carerFields } = this.state;
    const {
      data: { kid }
    } = this.props;
    const fields = carerFields.filter(cf => !cf._destroy);
    const removeEnabled = fields.length > 1;

    return (
      <React.Fragment>
        {fields.map((field, index) => {
          const { id: fieldId } = field;
          const defaultData = get(kid, 'carers', []).find(c => c.id === fieldId) || {};

          return (
            <React.Fragment key={fieldId}>
              <div className="new-enroll__legend">
                <div className="new-enroll__legend-label">Parent {index + 1}</div>
                {removeEnabled && (
                  <div className="new-enroll__legend-actions">
                    <ActionButton
                      className="new-enroll__delete-carer"
                      size={14}
                      iconName="delete"
                      onClick={() => this.handleRemoveCarerFields(fieldId)}
                    />
                  </div>
                )}
              </div>
              <div className="form__row form__row--justified">
                <Form.Input
                  label="First Name"
                  placeholder="First Name"
                  name={`parent_${fieldId}_first_name`}
                  defaultValue={this.getNotNull(defaultData, 'first_name', '')}
                  required
                  asterisk
                  fieldRef={node => (this.requiredFieldRefs[`parent_${fieldId}_first_name`] = node)}
                  tabIndex={++this.tabIndex}
                  data-cy={`parent_${index}_first_name`}
                />

                <Form.Input
                  label="Last Name"
                  placeholder="Last Name"
                  name={`parent_${fieldId}_last_name`}
                  defaultValue={this.getNotNull(defaultData, 'last_name', '')}
                  required
                  asterisk
                  fieldRef={node => (this.requiredFieldRefs[`parent_${fieldId}_last_name`] = node)}
                  tabIndex={++this.tabIndex}
                  data-cy={`parent_${index}_last_name`}
                />
              </div>
              <div className="form__row form__row--justified">
                <Form.Input
                  label="Email"
                  type="email"
                  placeholder="Email"
                  name={`parent_${fieldId}_email`}
                  defaultValue={this.getNotNull(defaultData, 'email', '')}
                  fieldRef={node => (this.requiredFieldRefs[`parent_${fieldId}_email`] = node)}
                  required
                  asterisk
                  tabIndex={++this.tabIndex}
                  data-cy={`parent_${index}_email`}
                />

                <Form.Input
                  label="Phone"
                  placeholder="Phone Number"
                  defaultValue={this.getNotNull(defaultData, 'mobile_phone', '')}
                  name={`parent_${fieldId}_phone`}
                  mask="999-999-999999999"
                  fieldRef={node => (this.requiredFieldRefs[`parent_${fieldId}_phone`] = node)}
                  tabIndex={++this.tabIndex}
                  data-cy={`parent_${index}_phone`}
                />
              </div>
              <div className="form__row form__row--justified form__row--additional-fields">
                {chunk(this.parentCustomAttributes, 2).map(attrList =>
                  attrList.map(attr => (
                    <Form.CustomAttribute
                      key={attr.id}
                      attr={attr}
                      name={`parent_${fieldId}_${attr.id}`}
                      defaultValue={get(defaultData, `custom_attributes.${attr.id}`)}
                      tabIndex={++this.tabIndex}
                    />
                  ))
                )}
              </div>
            </React.Fragment>
          );
        })}
        <ActionButton
          className="new-enroll__add-carer"
          title="Add Parent"
          iconName="add-dark"
          size={24}
          onClick={this.handleAddCarerFields}
        />
      </React.Fragment>
    );
  }

  render() {
    const { isSaving, requiredDOB, fromLinkSibling } = this.state;
    const { rooms, data, leadStages, leadSources, constants, currentSchool } = this.props;
    const kid = data && data.kid;
    this.tabIndex = 0;

    const startDate = get(kid, 'enroll_date');
    const dob = get(kid, 'dob');
    const notes = get(kid, 'notes');
    const expectingMother = get(kid, 'expected_birth');
    const sourceDetails = get(kid, 'source_details');
    const sourceRequired = getSubscriptionCategory(currentSchool) === ENTERPRISE_SUBSCRIPTION_CATEGORY;

    return (
      <div className="new-enroll">
        <div className="modal__header modal__header--bordered">
          <span className="modal__header-title">
            {kid && !fromLinkSibling ? 'Edit Lead' : `New Lead ${fromLinkSibling ? 'Sibling' : ''}`}
          </span>
        </div>

        <div className="modal__container">
          <Form
            className="new-enroll__form"
            onChange={this.change}
            onSubmit={this.submit}
            onValidate={this.validate}
            validateOn="submit"
            isLoading={isSaving}
            ref={node => (this.formRef = node)}
            formRef={node => (this.formElement = node)}
          >
            <div className="new-enroll__legend">
              <div className="new-enroll__legend-label">Student Information</div>
            </div>

            <div className="form__row form__row--justified">
              <Form.Input
                label="First Name"
                placeholder="First Name"
                name="first_name"
                defaultValue={get(kid, 'first_name', '')}
                required
                asterisk
                tabIndex={++this.tabIndex}
                data-cy="first_name"
                fieldRef={node => (this.requiredFieldRefs.first_name = node)}
              />

              <Form.Input
                label="Last Name"
                placeholder="Last Name"
                name="last_name"
                defaultValue={get(kid, 'last_name', '')}
                required
                asterisk
                tabIndex={++this.tabIndex}
                data-cy="last_name"
                fieldRef={node => (this.requiredFieldRefs.last_name = node)}
              />
            </div>

            <div className="form__row form__row--justified">
              <div className="new-enroll__dob">
                <Form.DatePicker
                  label="Date of Birth"
                  name="dob"
                  defaultValue={dob ? moment(dob, 'YYYY-MM-DD').toDate() : undefined}
                  required={requiredDOB}
                  asterisk={requiredDOB}
                  tabIndex={++this.tabIndex}
                  fieldRef={node => (this.requiredFieldRefs.dob = node)}
                  data-cy={'dob'}
                />
                <Form.Checkbox
                  className="new-enroll__dob-expecting-mother"
                  type="square"
                  label="Expecting Mother"
                  defaultValue={expectingMother}
                  name="expected_birth"
                  tabIndex={++this.tabIndex}
                  data-cy={'expected_birth'}
                />
              </div>
              <Form.Select
                label="Room"
                title="Select Room"
                name="section_id"
                defaultValue={get(kid, 'section_id')}
                tabIndex={++this.tabIndex}
              >
                {rooms.map(r => (
                  <Form.Select.Item key={r.id} value={r.id} label={r.name} />
                ))}
              </Form.Select>
            </div>

            <div className="form__row form__row--justified">
              <Form.Input
                label="Allergy"
                placeholder="Allergy"
                name="allergy"
                defaultValue={get(kid, 'allergy', '')}
                tabIndex={++this.tabIndex}
              />

              <Form.Input
                label="Medication"
                placeholder="Medication"
                name="medications"
                defaultValue={get(kid, 'medications', '')}
                tabIndex={++this.tabIndex}
              />
            </div>

            <div className="form__row form__row--justified">
              <Form.DatePicker
                label="Expected Start Date"
                name="start_date"
                disablePast
                defaultValue={startDate ? moment(startDate, 'YYYY-MM-DD').toDate() : undefined}
                tabIndex={++this.tabIndex}
              />
            </div>

            <div className="form__row">
              <Form.ScheduleSelect
                label="Select Schedule"
                name="time_schedule"
                layout="inline"
                defaultValue={NewEnroll.deserializeTimeSchedule(get(kid, 'time_schedule', {}))}
              />
            </div>

            <AddressField
              name="address"
              defaultValue={{
                country_code: this.getNotNull(kid, 'country_code', ''),
                state: this.getNotNull(kid, 'region', ''),
                city: this.getNotNull(kid, 'city', ''),
                street_address: this.getNotNull(kid, 'street_address', ''),
                zip: this.getNotNull(kid, 'zip', '')
              }}
              countries={constants.countries}
            />

            <div className="form__row form__row--justified form__row--additional-fields">
              <Form.Select
                label="Lead Status"
                title="Lead Status"
                name="state_id"
                defaultValue={get(kid, 'state_id') || (leadStages.find(s => s.kind === 'pending') || {}).id}
                tabIndex={++this.tabIndex}
                required
                asterisk
                data-cy={'state_id'}
              >
                {leadStages.map(s => (
                  <Form.Select.Item key={s.id} value={s.id} label={s.name} />
                ))}
              </Form.Select>
              {this.renderAdditionalFields()}
            </div>
            <div className="new-enroll__separator" />
            {this.renderCarerFields()}
            <div className="new-enroll__separator" />
            <div className="form__row form__row--justified">
              <Form.Select
                label="How did you hear about us?"
                name="source_id"
                title="Select Source"
                defaultValue={this.getNotNull(kid, 'source_id', '')}
                tabIndex={++this.tabIndex}
                required={sourceRequired}
              >
                {leadSources.map(({ id, name }) => (
                  <Form.Select.Item key={id} value={id} label={name} />
                ))}
              </Form.Select>
              <Form.Input
                placeholder="Add Source Details"
                label="Source Details"
                name="source_details"
                defaultValue={sourceDetails}
                tabIndex={++this.tabIndex}
              />
            </div>

            <Form.Input
              placeholder="Add optional internal note"
              name="notes"
              multiline
              className="form-input--highlight"
              defaultValue={notes}
              tabIndex={++this.tabIndex}
            />

            <div className="new-enroll__footer form__row--actions">
              <Form.Submit label="Save" data-cy="submit" />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentSchool: state.currentUser.data.current_school,
  rooms: state.rooms.data,
  leadStages: state.leadStages.data,
  leadSources: state.leadSources.data,
  customAttributes: state.customAttributes.data,
  constants: state.constants.data
});

export default connect(mapStateToProps)(NewEnroll);
