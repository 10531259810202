const createAddReducer = (reducerName, initialState) => (state = { ...initialState }, action) => {
  switch (action.type) {
    case `${reducerName}_ADD`:
      return { ...state, loading: true };

    case `${reducerName}_ADD_SUCCESS`: {
      if (action.options && action.options.collectionName) {
        const collectionName = action.options.collectionName;

        return {
          loading: false,
          data: {
            ...state.data,
            total: state.data.total + 1,
            [collectionName]: [action.payload, ...state.data[collectionName]]
          }
        };
      }
      return { loading: false, data: [action.payload, ...state.data] };
    }

    case `${reducerName}_ADD_FAILURE`:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default createAddReducer;
