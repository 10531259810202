import React from 'react';
import { Placeholder } from 'components';

const EmptyStatePlaceholder = ({ title, description, steps, className, isLoading = false }) => {
  return (
    <Placeholder.Info title={title} isLoading={isLoading} className={className || 'empty-state'} image={false}>
      {description}
      <div className="step-placeholder-inner">
        {steps.map((step, index) => {
          if (step.iconName) {
            return (
              <Placeholder.Feature key={index} iconName={step.iconName} color={step.color}>
                {step.text}
              </Placeholder.Feature>
            );
          } else {
            return (
              <Placeholder.Step key={index} index={index + 1}>
                {typeof step === 'string' ? step : step.text}
              </Placeholder.Step>
            );
          }
        })}
      </div>
    </Placeholder.Info>
  );
};

export default EmptyStatePlaceholder;
