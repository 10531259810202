import reducers from './reducers';
import snakeCase from 'lodash/snakeCase';

const createApiReducer = reducerConfig => {
  const name = snakeCase(reducerConfig.name).toUpperCase();

  const initialState = {
    data: reducerConfig.initialState || null,
    loading: false
  };

  const reducerList = {};

  reducerConfig.types.push('basic', 'fetch');

  reducerConfig.types.forEach(type => {
    switch (type) {
      case 'basic':
        reducerList[type] = reducers.createBasicReducer(name, initialState);
        break;

      case 'fetch':
        reducerList[type] = reducers.createFetchReducer(name, initialState);
        break;

      case 'patchItem':
        reducerList[type] = reducers.createPatchItemReducer(name, initialState);
        break;

      case 'patchSisoItem':
        reducerList[type] = reducers.createPatchSisoItemReducer(name, initialState);
        break;

      case 'removeItem':
        reducerList[type] = reducers.createRemoveItemReducer(name, initialState);
        break;

      case 'add':
        reducerList[type] = reducers.createAddReducer(name, initialState);
        break;

      case 'addMultiple':
        reducerList[type] = reducers.createAddMultipleReducer(name, initialState);
        break;

      case 'batchUpdate':
        reducerList[type] = reducers.createBatchUpdateReducer(name, initialState);
        break;

      case 'batchDelete':
        reducerList[type] = reducers.createBatchDeleteReducer(name, initialState);
        break;

      case 'updateReportStatus':
        reducerList[type] = reducers.createUpdateReportStatusReducer(name, initialState);
        break;

      case 'updateTenant':
        reducerList[type] = reducers.createUpdateTenantReducer(name, initialState);
        break;

      case 'addOrPatchItem':
        reducerList[type] = reducers.createAddOrPatchItemReducer(name, initialState);
        break;
    }
  });

  return (state = { ...initialState }, action) =>
    reducerConfig.types.reduce((state, type) => reducerList[type](state, action), state);
};

const createApiReducers = apiRoutes => {
  const reducersConfig = {};

  Object.keys(apiRoutes).forEach(routeName => {
    const routeData = apiRoutes[routeName];

    if (!routeData.reducer) {
      return;
    }

    const reducers = Array.isArray(routeData.reducer) ? routeData.reducer : [routeData.reducer];

    reducers.forEach(reducer => {
      const reducerName = reducer.name;

      let reducerData = reducersConfig[reducerName];

      if (!reducerData) {
        reducerData = reducersConfig[reducerName] = {
          name: reducer.name,
          initialState: reducer.initialState,
          types: []
        };
      }

      const type = reducer.type;

      if (type && !reducerData.types.includes(type)) {
        reducerData.types.push(reducer.type);
      }
    });
  });

  const reducers = {};

  Object.keys(reducersConfig).forEach(name => {
    reducers[name] = createApiReducer(reducersConfig[name]);
  });

  return reducers;
};

export default createApiReducers;
