import React, { Component } from 'react';
import { Form } from 'components';
import { injectStripe, CardElement } from 'react-stripe-elements';

class CreditCardForm extends Component {
  componentDidMount() {
    this.props.setStripe(this.props.stripe);
  }

  render() {
    const { fee } = this.props;

    return (
      <div className="payments-panel__content">
        <div className="form__row">
          <Form.Input name="name" placeholder="Enter Name" label="Account Holder Name" required />
        </div>

        <div className="form__row">
          <div className="form-input">
            <label className="form__label">Credit Card</label>

            <CardElement />
          </div>
        </div>

        {fee && <div className="receive-payment__fee">Txn fee is ${fee}</div>}
      </div>
    );
  }
}

export default injectStripe(CreditCardForm);
