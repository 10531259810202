import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Steps from 'modals/ModalController/Steps';
import AssignProgramStaff_Step1 from './step_1';
import AssignProgramStaff_Step2 from './step_2';
import './style.scss';
import { Placeholder } from 'components';

const AssignProgramStaffModal = ({ data, onClose }) => {
  const { onSubmit } = data;

  useEffect(() => {
    req.staff({
      filters: {
        teacher: {
          status: 'active'
        }
      },
      include_sections: true,
      include_programs: true
    });
  }, []);

  const isLoading = useSelector(state => state.staff.loading);
  const staff = useSelector(state => state.staff.data);
  const program = useSelector(state => state.program.data);
  const assignedStaffIds = program.teachers?.map(t => t.id) ?? [];
  const [selectedIds, setSelectedIds] = useState(assignedStaffIds);
  const [staffRoomMap, setStaffRoomMap] = useState({});

  // 2nd step of the modal maps each selected staff member to a room, this function builds that map
  const makeStaffRoomMap = staffIds => {
    let staffRoomMap = {};
    if (isLoading) {
      return staffRoomMap;
    }

    let selectedStaff = staff.filter(s => staffIds.includes(s.id));
    for (let staffItem of selectedStaff) {
      let sectionId =
        program.sections?.length === 1
          ? program.sections[0].id
          : staffItem.program_sections?.find(i => i.program_id == program.id)?.section_id ?? null;

      staffRoomMap[staffItem.id] = sectionId;
    }

    return staffRoomMap;
  };

  useEffect(() => {
    if (!isLoading) {
      setStaffRoomMap(makeStaffRoomMap(selectedIds));
    }
  }, [staff, selectedIds]);

  const submit = async values => {
    await onSubmit(values || staffRoomMap);
    onClose(true);
  };

  if (isLoading) {
    return <Placeholder.NoResult className="show-invoice" isLoading={isLoading} />;
  }

  return (
    <div className="assign-program-staff">
      <Steps>
        <Steps.Item>
          <AssignProgramStaff_Step1 selectedIds={selectedIds} onChange={setSelectedIds} onSubmit={submit} />
        </Steps.Item>

        <Steps.Item>
          <AssignProgramStaff_Step2 staffRoomMap={staffRoomMap} onSubmit={submit} />
        </Steps.Item>
      </Steps>
    </div>
  );
};

export default AssignProgramStaffModal;
