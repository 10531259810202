import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ActionButton, Placeholder, Icon } from 'components';
import { isMobile } from 'lib/utils';
import PlaidLink from 'components/PlaidLink';

function Step1({
  onSelectMethod,
  onSubmit,
  isSchool,
  isCarer,
  currentSchool,
  feeBreakdown,
  isRegister,
  goToList,
  autopayEnabled,
  plaidLinkToken
}) {
  const mobile = isMobile();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (isCarer) {
      req.parentFeeBreakdown();
    }
  }, [isCarer]);

  const onPlaidSuccess = (publicToken, metadata) => {
    const { account_id } = metadata;
    const reqMethod = isSchool ? 'addSchoolPaymentMethod' : 'addPaymentMethod';

    setIsSaving(true);
    if (onSubmit) {
      onSubmit({
        token_type: 'plaid',
        token: publicToken,
        plaid_account_id: account_id
      });
      return;
    }

    req[reqMethod]({
      token_type: 'plaid',
      token: publicToken,
      plaid_account_id: account_id,
      auto_pay: autopayEnabled === true
    })
      .then(() => {
        Actions.showFlash('Bank added successfully');
        setIsSaving(false);
        if (mobile) {
          Actions.hideCloak();
        }
        goToList();
      })
      .catch(err => {
        Actions.reportError('There was error adding bank account', err);
        if (mobile) {
          Actions.hideCloak();
        }
        setIsSaving(false);
      });
  };

  const onPlaidExit = (err, metadata) => {
    if (err) {
      Actions.showFlash('There was error adding bank account', 'alert');
      console.error(err, metadata);
    }

    if (mobile) {
      Actions.hideCloak();
    }
  };

  const openPlaid = () => {
    if (mobile) {
      Actions.showCloak();
    }
  };

  const isMethodDisabled = method => {
    if (!isCarer) {
      return false;
    }

    const paymentMethod = currentSchool.parent_payment_method;
    return paymentMethod !== 'parent_payment_method_any' && paymentMethod !== `parent_payment_method_${method}`;
  };

  const handleSelectMethod = (method, subKind = '') => {
    if (method === 'card' && isMethodDisabled('cc')) {
      return;
    }

    if (method === 'bank' && isMethodDisabled('ba')) {
      return;
    }

    if (method === 'bank' && isSchool) {
      openPlaid();
    } else {
      onSelectMethod(method, subKind);
    }
  };

  if (isSaving) {
    return <Placeholder.NoResult isLoading={isSaving} />;
  }

  const isCreditCardEnabled = !isMethodDisabled('cc');
  const isAchEnabled = !isMethodDisabled('ba');

  const hasFee = currentSchool.parent_payment_fee_paid_by === 'parent_payment_fee_paid_by_parent';

  const paths = [];

  const achOption = (
    <PlaidLink
      linkToken={plaidLinkToken}
      onSuccess={onPlaidSuccess}
      onExit={onPlaidExit}
      key="bank"
      className="path-selector__option"
      onClick={openPlaid}
    >
      <div className="path-selector__option-inner">{isRegister ? 'Pay through Bank' : 'Add Bank Account'}</div>
    </PlaidLink>
  );

  const debitOption = (
    <div key="debit" className="path-selector__option" onClick={() => handleSelectMethod('card', 'debit')}>
      <div className="path-selector__option-inner">Add Debit Card</div>
    </div>
  );

  const ccOption = (
    <div key="cc" className="path-selector__option" onClick={() => handleSelectMethod('card', 'credit')}>
      <div className="path-selector__option-inner">
        {isRegister ? 'Pay by Credit Card' : 'Add Credit Card'}

        {isCarer && feeBreakdown.cc && hasFee && (
          <div className="path-selector__option-subheader path-selector__option-subheader--fee">
            {`Transaction fee is ${feeBreakdown.cc}`}
          </div>
        )}
      </div>
    </div>
  );

  if (isAchEnabled) {
    paths.unshift(achOption);
  }

  if (isCreditCardEnabled) {
    paths.unshift(ccOption);
    if (!isRegister && !isSchool) {
      paths.unshift(debitOption);
    }
  }

  return (
    <div className="table payments-panel">
      <div className="table__header payments-panel__header">
        <ActionButton iconName="back" size={20} onClick={goToList} />
        <div className="payments-panel__title">Choose Payment</div>
      </div>

      <div className="table__body">
        <div className="table__row payments-panel__body">
          <div className="payments-panel__content">
            <div className="path-selector">{paths}</div>
          </div>

          <div className="payments-panel__notice">
            <div className="payments-panel__notice-icon">
              <Icon name="verify" color="#8cd527" size={24} />
            </div>
            <div className="payments-panel__notice-text">
              Procare has the highest levels of PCI compliance for online payments.
              {!hasFee && (
                <div className="payments-panel__notice-desc">
                  * No Procare platform fee. School may charge a processing fee. Please check with your school.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Step1.propTypes = {
  onSelectMethod: PropTypes.func,
  onSubmit: PropTypes.func,
  isSchool: PropTypes.bool,
  isCarer: PropTypes.bool,
  currentSchool: PropTypes.object,
  isRegister: PropTypes.bool,
  goToList: PropTypes.func,
  autopayEnabled: PropTypes.bool,
  feeBreakdown: PropTypes.object,
  plaidLinkToken: PropTypes.string
};

const mapStateToProps = state => ({
  constants: state.constants.data,
  currentSchool: state.currentUser.data.current_school,
  isCarer: state.currentUser.data.current_role === 'carer',
  feeBreakdown: state.feeBreakdown.data
});

export default connect(mapStateToProps)(Step1);
