import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { capitalize } from 'lib/utils';
import { Form } from 'components';
import moment from 'moment';

/**
 * Constants representing all possible registration statuses. These are directly
 * correlated to what the backend api expects to see.
 */
const ACTIVE_STATUS = 'active',
  INACTIVE_STATUS = 'inactive',
  GRADUATE_STATUS = 'graduate',
  HOLD_STATUS = 'hold';

/**
 * Constants representing all possible registration ranges when filtering by graduate
 * or hold status. These are directly correlated to what the backend api expects to see.
 */
const LAST_3_MONTH_RANGE = 'last-3-months',
  LAST_6_MONTH_RANGE = 'last-6-months',
  CURRENT_YEAR_RANGE = 'current-year',
  PREVIOUS_YEAR_RANGE = 'previous-year';

/**
 * Mapping object to get a user friendly representation of statuses and ranges
 */
const appliedTextMap = {
  active: 'Active',
  inactive: 'Inactive',
  hold: 'On Hold',
  graduate: 'Graduated',
  'last-3-months': 'last 3 months',
  'last-6-months': 'last 6 months',
  'current-year': 'current year',
  'previous-year': 'previous year'
};

/**
 * @name statusAllowsRange
 * @description Determines if the specified status requires a range selection
 * @param {string} status Any valid registration status
 * @returns True if the specified status requires a range selection
 */
const statusRequiresRange = status => status && status !== ACTIVE_STATUS && status !== HOLD_STATUS;

/**
 * A collection of utility functions to work with registration status and ranges
 */
const RegistrationStatusServices = {
  /**
   * @name getAppliedTextFromFormValue
   * @description Our filter requires specific language when represting the current selection. This
   * method does the conversion given the specified status and range.
   * @param {object} formValue An object containing the currently selected status and range
   * @returns A user-friendly text representation of the specified status and range
   */
  getAppliedTextFromFormValue: ({ status, range }) => {
    return `${appliedTextMap[status]}${statusRequiresRange(status) ? ' within ' + appliedTextMap[range] : ''}`;
  },
  /**
   * @name buildCompositeProperty
   * @description The registration status filter contains multiple form groups, that when submitted,
   * needs to be grouped into a composite object. This method does the work.
   * @param {object} values Form values to turn into a single object.
   * @returns The composite object containing the form values, specifically status and range.
   */
  buildCompositeProperty: values => {
    return values
      ? {
          status: values['status'] || ACTIVE_STATUS,
          range: values['range']
        }
      : {
          status: ACTIVE_STATUS
        };
  }
};

const renderRange = range => {
  let from = moment();
  let to = moment();
  switch (range) {
    case LAST_3_MONTH_RANGE:
      from = moment().subtract(3, 'months');
      break;

    case LAST_6_MONTH_RANGE:
      from = moment().subtract(6, 'months');
      break;

    case PREVIOUS_YEAR_RANGE:
      from = moment()
        .subtract(1, 'years')
        .startOf('year');
      to = moment()
        .subtract(1, 'years')
        .endOf('year');
      break;

    case CURRENT_YEAR_RANGE:
      from = moment().startOf('year');
      break;
  }

  return `${from.format('MMM D, YYYY')} - ${to.format('MMM D, YYYY')}`;
};

/**
 * @name FilterItemRegistrationStatus
 * @description A react component used to render the registration status filter ui. This filter
 * follows the same pattern as the date range filter and has multiple form controls that need
 * to be bundled into a single composite object, so all values are represented in state as a
 * single object.
 * @param {object} context Object containing the default values of the selection and the label
 * @returns The react component.
 */
const FilterItemRegistrationStatus = ({ defaultValue = {}, label = 'Enrollment Status' }) => {
  const { status, range } = defaultValue;
  const [selectedStatus, setSelectedStatus] = useState(status || ACTIVE_STATUS);
  const [selectedRange, setSelectedRange] = useState(range);
  const [rangeEnabled, setRangeEnabled] = useState(statusRequiresRange(status));

  return (
    <Fragment>
      <div className="form__row">
        <div className="select-newsletter-people__registration-status">
          <Form.Select
            name="status"
            data-cy="status"
            label={label}
            defaultValue={status || ACTIVE_STATUS}
            className="select-newsletter-people__registration-status-select"
            onChange={(name, value) => {
              setSelectedStatus(value);
              setRangeEnabled(statusRequiresRange(value));
            }}
          >
            <Form.Select.Item
              key={ACTIVE_STATUS}
              value={ACTIVE_STATUS}
              label={capitalize(appliedTextMap[ACTIVE_STATUS])}
            />
            <Form.Select.Item key={HOLD_STATUS} value={HOLD_STATUS} label={capitalize(appliedTextMap[HOLD_STATUS])} />
            <Form.Select.Item
              key={GRADUATE_STATUS}
              value={GRADUATE_STATUS}
              label={capitalize(appliedTextMap[GRADUATE_STATUS])}
            />
            <Form.Select.Item
              key={INACTIVE_STATUS}
              value={INACTIVE_STATUS}
              label={capitalize(appliedTextMap[INACTIVE_STATUS])}
            />
          </Form.Select>
          <Form.Select
            name="range"
            data-cy="range"
            label={'Changed Within'}
            defaultValue={range || CURRENT_YEAR_RANGE}
            onChange={(name, value) => setSelectedRange(value)}
            disabled={!rangeEnabled}
          >
            <Form.Select.Item
              key={LAST_3_MONTH_RANGE}
              value={LAST_3_MONTH_RANGE}
              label={capitalize(appliedTextMap[LAST_3_MONTH_RANGE])}
            />
            <Form.Select.Item
              key={LAST_6_MONTH_RANGE}
              value={LAST_6_MONTH_RANGE}
              label={capitalize(appliedTextMap[LAST_6_MONTH_RANGE])}
            />
            <Form.Select.Item
              key={CURRENT_YEAR_RANGE}
              value={CURRENT_YEAR_RANGE}
              label={capitalize(appliedTextMap[CURRENT_YEAR_RANGE])}
              isDefault
            />
            <Form.Select.Item
              key={PREVIOUS_YEAR_RANGE}
              value={PREVIOUS_YEAR_RANGE}
              label={capitalize(appliedTextMap[PREVIOUS_YEAR_RANGE])}
            />
          </Form.Select>
        </div>
      </div>
      {rangeEnabled && (
        <div className="form__row">
          <div className="select-newsletter-people__registration-status__tooltip">
            Will show students whose status was changed to&nbsp;
            <span className="select-newsletter-people__registration-status__tooltip--emphasis">
              {appliedTextMap[selectedStatus]}
            </span>{' '}
            within&nbsp;
            <span className="select-newsletter-people__registration-status__tooltip--emphasis">
              {renderRange(selectedRange || CURRENT_YEAR_RANGE)}
            </span>
            .
          </div>
        </div>
      )}
    </Fragment>
  );
};

FilterItemRegistrationStatus.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.object
};

export {
  FilterItemRegistrationStatus,
  RegistrationStatusServices,
  statusRequiresRange as statusAllowsRange,
  ACTIVE_STATUS,
  INACTIVE_STATUS,
  GRADUATE_STATUS,
  HOLD_STATUS,
  LAST_3_MONTH_RANGE,
  LAST_6_MONTH_RANGE,
  CURRENT_YEAR_RANGE,
  PREVIOUS_YEAR_RANGE
};
