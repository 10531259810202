import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { Form, ButtonV2 } from 'components';
import { connect } from 'react-redux';
import { Student, Staff, Lead } from './FormFields';
import moment from 'moment';
import { capitalize } from 'lib/utils';
import { CREATE_STAFF, CREATE_STUDENT, CREATE_LEAD } from 'constants/schoolQuickActions';

import './style.scss';

const CONFIG = {
  [CREATE_STAFF]: {
    component: Staff,
    route: 'addStaff',
    text: 'staff',
    getPayload: values => ({
      teacher: {
        ...values,
        is_admin: values.is_admin === Staff.ADMIN,
        skip_invite: !values.send_invite
      }
    }),
    getLink: id => {
      return `/staff/list/${id}`;
    }
  },
  [CREATE_STUDENT]: {
    component: Student,
    route: 'addStudent',
    text: 'student',
    getPayload: values => ({
      kid: {
        ...values
      }
    }),
    getLink: id => {
      return `/my-school/students/${id}`;
    }
  },
  [CREATE_LEAD]: {
    component: Lead,
    route: 'addEnrollmentItem',
    text: 'lead',
    getPayload: values => ({
      enrollment: {
        first_name: values.first_name,
        last_name: values.last_name,
        state_id: values.state_id,
        dob: moment(values.dob).format('YYYY-MM-DD'),
        source_id: values.source_id,
        carers_attributes: [
          {
            email: values.parent_email,
            mobile_phone: values.parent_phone,
            first_name: values.parent_first_name,
            last_name: values.parent_last_name
          }
        ]
      }
    }),
    getLink: id => {
      return `/lead-crm/leads/${id}`;
    },
    isEnrolledStateId(stateId, leadStages) {
      const enrolledState = leadStages.find(s => s.kind === 'enrolled') || {};
      return enrolledState.id === stateId;
    },
    async enrollRegistration(values) {
      const isRegistered = await Actions.showModal('EnrollRegistration', {
        kid: {
          first_name: values.first_name,
          last_name: values.last_name
        },
        confirmationOnly: true
      });

      return isRegistered;
    }
  }
};

function CreatePerson({ data, rooms, leadStages, leadSources, onClose }) {
  const [isSaving, setIsSaving] = useState(false);
  const [values, setValues] = useState({});
  const action = data.action;

  useEffect(() => {
    if (action === CREATE_STUDENT) {
      req.rooms();
    }
    if (action === CREATE_LEAD) {
      req.leadStages();
      req.leadSources();
    }
  }, []);

  const getSuccessMessage = (personType, href) => {
    return renderToString(
      <div>
        {personType} successfully created
        <br />
        <a className={'create-person__flash-link'} href={href}>
          View Profile
        </a>
      </div>
    );
  };

  const submit = async values => {
    const { route, text, getPayload, getLink } = CONFIG[action];
    try {
      setIsSaving(true);

      const payload = getPayload(values);

      if (action === CREATE_LEAD && CONFIG[action].isEnrolledStateId(values.state_id, leadStages)) {
        const shouldRegister = await CONFIG[action].enrollRegistration(values, leadStages);

        if (!shouldRegister) {
          return;
        }

        payload.enrollment.enroll_section_id = shouldRegister.section_id;
        payload.enrollment.enroll_date = shouldRegister.date;
      }

      const data = await req[route](payload);
      Actions.showFlash(getSuccessMessage(capitalize(text), getLink(data.id)));
      onClose();
    } catch (err) {
      Actions.reportError(`Unable to create ${capitalize(text)}`, err);
    } finally {
      setIsSaving(false);
    }
  };

  const FormFields = CONFIG[action].component;
  const title = CONFIG[action].text;

  return (
    <div className="modal__wrapper create-person">
      <div className="modal__header">
        <span className="modal__header-title">Add {capitalize(title)}</span>
      </div>

      <div className="modal__container">
        <Form validateOn="submit" isLoading={isSaving} onInit={setValues} onChange={setValues} onSubmit={submit}>
          <FormFields rooms={rooms} leadStages={leadStages} leadSources={leadSources} values={values} />
          <div className="modal__controls create-person__footer">
            <ButtonV2 secondary label="Cancel" disabled={isSaving} onClick={onClose} />
            <Form.Submit data-cy={'submit'} label="Save" />
          </div>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  leadStages: state.leadStages.data,
  leadSources: state.leadSources.data
});

export default connect(mapStateToProps)(CreatePerson);
