import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Steps from 'modals/ModalController/Steps';
import BulkSignActions_Step1 from './step_1';
import BulkSignActions_Step2 from './step_2';
import BulkSignActions_Step3 from './step_3';
import './style.scss';

class BulkSignActions extends Component {
  static initialState = {
    selectedStudents: [],
    action: null // sign in/out
  };

  constructor() {
    super();
    this.state = { ...BulkSignActions.initialState };
  }

  UNSAFE_componentWillMount() {
    req.students().catch(err => console.log(err));
    req.rooms().catch(err => console.log(err));
    req.tags().catch(err => console.log(err));
    req.staff().catch(err => console.log(err));
  }

  @bind
  updateStudents(newSelectedStudents) {
    this.setState({ selectedStudents: newSelectedStudents });
  }

  clearFilter() {
    this.setState({ selectedStudents: [] });
  }

  @bind
  setAction(action) {
    this.setState({ action });
  }

  @bind
  sendData(data) {
    const { action, selectedStudents } = this.state;
    const { onClose } = this.props;
    let obj = {
      section_id: data.toRoom,
      kid_ids: selectedStudents
    };

    if (data.transferToNewRoom) {
      obj.transfer = true;
    }

    switch (action) {
      case 'in':
        obj = {
          ...obj,
          sign_in_time: data.date,
          signed_in_by_id: data.staffId,
          signed_in_by_type: data.type,
          signed_in_by: data.staffName
        };
        req
          .batchSignIn(obj)
          .then(() => onClose(true))
          .catch(err => Actions.reportError('There was problem signing students in.', err));
        break;
      case 'out':
        obj = {
          ...obj,
          sign_out_time: data.date,
          signed_out_by_id: data.staffId,
          signed_out_by_type: data.type,
          signed_out_by: data.staffName
        };
        req
          .batchSignOut(obj)
          .then(() => onClose(true))
          .catch(err => Actions.reportError('There was problem signing students out.', err));
        break;
    }
  }

  render() {
    const { rooms, tags, students, staff, data } = this.props;
    const { action, selectedStudents } = this.state;

    const forToday = moment(data.filters.report_date).isSame(new Date(), 'day');

    return (
      <div className="bulk-sign">
        <Steps>
          <Steps.Item>
            <BulkSignActions_Step1
              setAction={this.setAction}
              updateStudents={this.updateStudents}
              forToday={forToday}
            />
          </Steps.Item>

          <Steps.Item>
            <BulkSignActions_Step2
              rooms={rooms}
              tags={tags}
              students={students}
              action={action}
              selectedStudents={selectedStudents}
              updateStudents={this.updateStudents}
              forToday={forToday}
            />
          </Steps.Item>

          <Steps.Item>
            <BulkSignActions_Step3
              rooms={rooms}
              students={students}
              staff={staff}
              action={action}
              selectedStudents={selectedStudents}
              updateStudents={this.updateStudents}
              submit={this.sendData}
              defaultValues={data.filters}
            />
          </Steps.Item>
        </Steps>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  tags: state.tags.data,
  students: state.students.data,
  staff: state.staff.data
});

export default connect(mapStateToProps)(BulkSignActions);
