import React, { Component } from 'react';
import { Form, StudentList } from 'components';
import './style.scss';

class EditAbsentKid extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isLoading: false
    };
  }

  @bind
  async submit(values) {
    const {
      kid: { absent_notes = [] }
    } = this.props.data;

    this.setState({ isLoading: true });

    if (absent_notes.length) {
      try {
        await req.updateAbsentKidsBatch({
          range_id: absent_notes[0].range_id,
          note: values.note
        });

        this.props.onClose(true);
        Actions.showFlash('Note has been updated', 'success');
      } catch (err) {
        Actions.showFlash('Something went wrong', err);
      } finally {
        this.setState({ isLoading: false });
      }
    } else {
      Actions.showFlash('Something went wrong', 'alert');
    }
  }

  render() {
    const { kid, note } = this.props.data;

    return (
      <Form
        className="modal__wrapper edit-absent"
        onSubmit={this.submit}
        validateOn="submit"
        isLoading={this.state.isLoading}
      >
        <div className="modal__header">
          <div className="subheader">Edit Absent</div>
          <span className="modal__header-note">
            <StudentList students={[kid]} />
          </span>
        </div>

        <div className="modal__container">
          <Form.Textarea
            label="Absent Note"
            placeholder="Absent Note"
            name="note"
            className="edit-absent__description"
            defaultValue={note}
            required
          />

          <div className="form__row form__row--actions">
            <Form.Submit label="Submit" data-cy="submit" />
          </div>
        </div>
      </Form>
    );
  }
}

export default EditAbsentKid;
