const FormFieldTypes = {
  TextInput: 'textinput',
  Checkbox: 'checkbox',
  Select: 'select',
  DatePicker: 'datepicker',
  Divider: 'divider',
  TextBox: 'textbox',
  FileUpload: 'fileupload'
};

export default FormFieldTypes;

export const TypeToCustomAttribute = {
  checkbox: 'boolean',
  select: 'list',
  textinput: 'string',
  datepicker: 'date',
  textbox: 'boolean'
};

export const BackendToType = {
  short_answer: FormFieldTypes.TextInput,
  date_picker: FormFieldTypes.DatePicker,
  paragraph: FormFieldTypes.TextBox,
  dropdown: FormFieldTypes.Select,
  checkbox: FormFieldTypes.Checkbox,
  fileupload: FormFieldTypes.FileUpload,
  divider: FormFieldTypes.Divider
};

export const TypeToBackend = Object.entries(BackendToType).reduce((obj, [k, v]) => ({ ...obj, [v]: k }), {});
