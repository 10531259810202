import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Item extends Component {
  static propTypes = {
    step: PropTypes.number,
    totalSteps: PropTypes.number,
    payload: PropTypes.object,
    hidden: PropTypes.bool,
    name: PropTypes.string
  };

  render() {
    const { children, step, totalSteps, payload } = this.props;

    return (
      <div className="modal-steps__item">
        <children.type {...children.props} step={step} totalSteps={totalSteps} {...payload} />
      </div>
    );
  }
}

export default Item;
