import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ButtonV2, Checkbox, TooltipTrigger } from 'components';
import { applyFilters } from 'lib/utils';
import SelectPersonList from '../common/SelectPersonList';
import FilterBar from '../common/SelectKids/FilterBar';

import './style.scss';

const validations = {
  'No Email': kid => {
    return !kid.parents.filter(p => p.email).length > 0;
  }
};

class BulkSendSISOCode extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      filters: {
        tagIds: [],
        roomId: '',
        search: ''
      },
      sendIndividual: false,
      selectedKids: []
    };
  }

  @bind
  updateFilters(values) {
    this.setState({
      filters: values
    });
  }

  @bind
  updateSelectedKids(selectedKids) {
    this.setState({ selectedKids });
  }

  @bind
  updateSendIndividual(value) {
    this.setState({
      sendIndividual: value
    });
  }

  @bind
  submit() {
    const { selectedKids, sendIndividual } = this.state;

    this.setState({ isLoading: true });
    req
      .sendPins({ kid_ids: selectedKids, send_individual: sendIndividual })
      .then(() => {
        this.setState({ isLoading: false });
        this.props.onClose();
        Actions.showFlash('Sign In-Out Pins are emailed to the parents');
      })
      .catch(e => {
        this.setState({ isLoading: false });
        this.props.onClose();
        Actions.reportError("Sign In-Out Pins couldn't be sent, try again", e);
      });
  }

  renderSendIndividualTooltip() {
    return <div>Parents of family will be sent separate emails with only their pin</div>;
  }

  render() {
    const { filters, selectedKids, sendIndividual } = this.state;
    const { rooms, tags, students } = this.props;

    const filteredStudents = applyFilters(students, filters);

    return (
      <div className="bulk-send-siso-code">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Send Sign In-Out Pin</div>
          <div className="modal__header-controls">
            <FilterBar rooms={rooms} tags={tags} onUpdate={this.updateFilters} />
          </div>
        </div>

        <div className="modal__container bulk-send-siso-code__container">
          <SelectPersonList
            persons={filteredStudents}
            allPersons={students}
            selectedIds={selectedKids}
            onSelect={this.updateSelectedKids}
            validations={validations}
          />
          <div className="bulk-send-siso-code__individual-pin">
            <Checkbox
              label="Do not share parents pin in email"
              checked={sendIndividual}
              type="circle"
              onChange={this.updateSendIndividual}
            />
            <TooltipTrigger tooltip={this.renderSendIndividualTooltip()}>
              <ButtonV2 icon iconName="tooltip" iconSize={18} />
            </TooltipTrigger>
          </div>
        </div>

        <div className="modal__controls">
          <ButtonV2 label="Send email" onClick={this.submit} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  students: state.students.data,
  rooms: state.rooms.data,
  tags: state.tags.data
});

export default connect(mapStateToProps)(BulkSendSISOCode);
