import React from 'react';
import PropTypes from 'prop-types';
import TableContext from './Context';
import HorizontalScroll from './HorizontalScroll';
import Cell from './Cell';
import './style.scss';

function DynamicTable({ className, children, fields = {}, horizontalScroll = true, 'data-cy': dataCY }) {
  return (
    <TableContext.Provider value={fields}>
      {horizontalScroll ? (
        <HorizontalScroll className={className} data-cy={dataCY}>
          {children}
        </HorizontalScroll>
      ) : (
        <div className={className}>{children}</div>
      )}
    </TableContext.Provider>
  );
}

DynamicTable.propTypes = {
  fields: PropTypes.object.isRequired,
  horizontalScroll: PropTypes.bool
};

DynamicTable.Cell = Cell;

export default DynamicTable;
