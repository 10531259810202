import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ScheduleDay from './ScheduleDay';
import './style.scss';
import { DAY_TO_CODE, CODE_TO_DAY } from 'constants/dayCodes';

const WEEKDAYS = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

class ScheduleSelect extends PureComponent {
  static propTypes = {
    values: PropTypes.object,
    layout: PropTypes.oneOf(['inline', 'grid']),
    onChange: PropTypes.func,
    validTimes: PropTypes.object
  };

  static defaultProps = {
    values: {},
    layout: 'grid',
    validTimes: null
  };

  getClassName() {
    return classNames('schedule-select', {
      'schedule-select--inline': this.props.layout === 'inline',
      'schedule-select--grid': this.props.layout === 'grid'
    });
  }

  @bind
  handleChange(day, value) {
    if (this.props.onChange) {
      this.props.onChange({ ...this.props.values, [day]: value });
    }
  }

  @bind
  renderDays() {
    const { validTimes, timesToShow } = this.props;
    const days = timesToShow ? Object.keys(timesToShow).map(code => CODE_TO_DAY[code]) : WEEKDAYS;

    return days.map(d => (
      <ScheduleDay
        key={d}
        day={d}
        value={this.props.values[d]}
        validTimes={validTimes ? validTimes[DAY_TO_CODE[d]] || [] : null}
        timesToShow={timesToShow?.[DAY_TO_CODE[d]]}
        onChange={this.handleChange}
      />
    ));
  }

  render() {
    // prettier-ignore
    return (
      <div className={this.getClassName()}>
        {this.renderDays()}
      </div>
    );
  }
}

export default ScheduleSelect;
