import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';

const FilterItemSignFilter = ({ name, label, defaultValue, defaultPeriod, ...otherProps }) => (
  <div className="form__row form__row--text">
    <Form.SignFilter
      name={name}
      label={label}
      defaultValue={defaultValue}
      defaultPeriod={defaultPeriod}
      data-cy={name}
      {...otherProps}
    />
  </div>
);

FilterItemSignFilter.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.object,
  defaultPeriod: PropTypes.string
};

export default FilterItemSignFilter;
