import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

const copyStyles = (src, dest) => {
  Array.from(src.styleSheets).forEach(styleSheet => {
    dest.head.appendChild(styleSheet.ownerNode.cloneNode(true));
  });
  Array.from(src.fonts).forEach(font => dest.fonts.add(font));
};

const Printable = forwardRef(function Printable(props, ref) {
  const [container, setContainer] = useState(null);
  const newWindow = useRef(null);

  useImperativeHandle(ref, () => ({
    print: () => {
      setContainer(document.createElement('div'));
    }
  }));

  useEffect(() => {
    if (container) {
      newWindow.current = window.open('', '_blank');
      copyStyles(window.document, newWindow.current.document);
      newWindow.current.document.body.appendChild(container);
      newWindow.current.document.title = document.title;
      newWindow.current.window.print();

      const curWindow = newWindow.current;
      return () => curWindow.close();
    }
  }, [container]);

  return (
    <>
      {props.children}
      {container && createPortal(props.children, container)}
    </>
  );
});

export default Printable;
