import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';
import { connect } from 'react-redux';
import './style.scss';

class EditAddress extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  @bind
  submit(addressValues) {
    const {
      data: { kid, fromEnrollment }
    } = this.props;
    const nextAddress = { ...addressValues };

    if (!fromEnrollment) {
      const payload = {
        id: kid.id,
        kid: nextAddress
      };

      return this.setState({ loading: true }, () =>
        req
          .updateStudent(payload)
          .then(() => {
            Actions.showFlash('Address has been updated');
            this.props.onClose();
          })
          .catch(err => {
            Actions.reportError('Unable to update address', err);
            this.setState({ loading: false });
          })
      );
    }

    return this.props.onClose(nextAddress);
  }

  renderCountries() {
    return Object.keys(this.props.constants.countries).map(c => (
      <Form.Select.Item label={this.props.constants.countries[c]} value={c} key={c} />
    ));
  }

  render() {
    const { loading } = this.state;
    const { kid } = this.props.data;

    return (
      <div className="edit-address">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Edit Address</div>
        </div>

        <Form onSubmit={this.submit} className="modal__container">
          <div className="form__row form__row--justified">
            <Form.Input
              name="street_address"
              defaultValue={kid.street_address}
              placeholder="Address Line"
              label="Address Line"
            />
            <Form.Input name="city" defaultValue={kid.city} placeholder="City" label="City" />
          </div>

          <div className="form__row form__row--justified">
            <Form.Input name="state" defaultValue={kid.state} placeholder="State" label="State" />
            <Form.Select name="country_code" defaultValue={kid.country_code} title="Country" label="Country">
              {this.renderCountries()}
            </Form.Select>
          </div>

          <div className="form__row form__row--justified">
            <Form.Input name="zip" defaultValue={kid.zip} placeholder="Zipcode" label="Zipcode" />
          </div>

          <div className="modal__controls">
            <Form.Submit label="Save" isLoading={loading} />
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  constants: state.constants.data
});

export default connect(mapStateToProps)(EditAddress);
