import axios from 'axios';
import Qs from 'qs';
import snakeCase from 'lodash/snakeCase';
import get from 'lodash/get';
import omit from 'lodash/omit';
import moment from 'moment';
import { getRouteSpecificApiUrl, getAuthTokenFromState } from 'lib/auth';

const INNER_PARAM_RE = /:\w+/g;

const DEFAULT_CONFIG = {
  timeout: 40000
};

class RequestHandler {
  static create(routeData, config) {
    const { store, fallbackApiUrl } = config;
    const reducers = !routeData.reducer
      ? []
      : Array.isArray(routeData.reducer)
      ? routeData.reducer
      : [routeData.reducer];

    let currentPromise = null;
    let source = null;

    return (params, payload, header = {}) => {
      if (source && routeData.cancellable !== false) {
        source.cancel();
      }

      source = axios.CancelToken.source();

      const { url, params: requestParams } = RequestHandler.createURL(routeData.url, params);

      const requestOptions = {
        url,
        method: routeData.method || 'get',
        headers: {
          ...RequestHandler.getDefaultHeader(store, header),
          ...config.getHeaders(header)
        },
        paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'brackets' })
      };

      if (routeData.responseType) {
        requestOptions.responseType = routeData.responseType;
      }

      if (requestOptions.method.toLowerCase() === 'get') {
        requestOptions.params = requestParams;
      } else {
        requestOptions.data = requestParams;
      }

      if (requestOptions.url[requestOptions.url.length - 1] !== '/') {
        requestOptions.url += '/';
      }

      const performRequest = axios.create({
        ...DEFAULT_CONFIG,
        baseURL: fallbackApiUrl,
        cancelToken: source.token
      });

      const dispatchAction = ({ name = '', type = 'fetch', options } = {}, actionSuffix, payload) => {
        const actionPrefix = snakeCase(name).toUpperCase();
        const reducerType = snakeCase(type).toUpperCase();

        if (store && actionPrefix) {
          store.dispatch({
            type: `${actionPrefix}_${reducerType}` + (actionSuffix ? `_${actionSuffix}` : ''),
            payload,
            options
          });
        }
      };

      reducers.forEach(reducer => dispatchAction(reducer, null, payload));

      currentPromise = new Promise((resolve, reject) => {
        if (routeData.transformRequest) {
          if (requestOptions.method.toLowerCase() === 'get') {
            requestOptions.params = routeData.transformRequest(requestOptions.params || {});
          } else {
            requestOptions.data = routeData.transformRequest(requestOptions.data || {});
          }
        }

        const requestUrl = getRouteSpecificApiUrl(routeData);
        performRequest({ ...requestOptions, baseURL: requestUrl })
          .then(response => {
            const data = routeData.transformResponse ? routeData.transformResponse(response.data) : response.data;

            for (const reducer of reducers) {
              dispatchAction(reducer, 'SUCCESS', data);
            }

            resolve(data);

            currentPromise = null;
            source = null;
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              currentPromise = null;
              source = null;
              return;
            }

            for (const reducer of reducers) {
              dispatchAction(reducer, 'FAILURE');
            }

            if (store && config.handleError) {
              config.handleError(error);
            }

            const errorMsg = `[Req::${config.name}] Error: ${error.message}`;
            console.warn(errorMsg);

            // handles network error and timeout/cancelled errors
            if (!error.response) {
              error.response = {
                status: 0,
                data: {
                  errors: [error.message]
                }
              };
            }

            reject(error);

            currentPromise = null;
            source = null;
          });
      });

      return currentPromise;
    };
  }

  static getDefaultHeader(store, additionalParams) {
    const headers = {
      'Content-Type': 'application/json'
    };

    const state = store.getState();
    const authToken = getAuthTokenFromState(state);
    if (authToken) {
      headers['Authorization'] = authToken;
    }

    if (additionalParams.checkHistory) {
      const historyType = additionalParams.historyType || 'daily_activities';
      const historyDate = get(state, `constants.data.history_dates.${historyType}`);
      const checkDate = Array.isArray(additionalParams.checkHistory)
        ? additionalParams.checkHistory[0]
        : additionalParams.checkHistory;
      if (additionalParams.forceHistory || (historyDate && moment(checkDate).isBefore(historyDate))) {
        headers['History-Data'] = 1;
      }
    }

    return { ...headers, ...omit(additionalParams, ['checkHistory', 'historyType', 'forceHistory']) };
  }

  /**
   * Returns a URL with path vars replaced with values, and an object of params/body payload
   */
  static createURL(url, params) {
    // Can be null so default is set to empty array
    const pathVarsNames = (url.match(INNER_PARAM_RE) || []).map(p => p.replace(':', ''));

    if (pathVarsNames.length === 0) {
      return { url, params };
    }

    const pathVars = {};
    const nextParams = {};

    Object.keys(params).forEach(n => {
      if (pathVarsNames.indexOf(n) !== -1) {
        pathVars[n] = params[n];
        return;
      }

      nextParams[n] = params[n];
    });

    let nextURL = url;
    pathVarsNames.forEach(p => {
      nextURL = nextURL.replace(':' + p, pathVars[p]);
    });

    if (nextURL[nextURL.length] !== '/') {
      nextURL += '/';
    }

    return { url: nextURL, params: nextParams };
  }
}

export default RequestHandler;
