import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from '../Form';
import Checkbox from '../Checkbox';
import Scrollbars from 'react-custom-scrollbars';
import './style.scss';

class TextBox extends Component {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    isInForm: PropTypes.bool,
    hasCheckbox: PropTypes.bool,
    checkboxLabel: PropTypes.string,
    formatter: PropTypes.func
  };

  render() {
    const {
      label,
      value,
      name,
      hasCheckbox,
      checkboxLabel,
      isInForm,
      defaultValue,
      formatter,
      'data-cy': dataCY
    } = this.props;

    let contentValue = value || '';

    if (typeof formatter === 'function') {
      contentValue = formatter(contentValue);
    }

    const content = {
      __html: contentValue
    };

    const Component = isInForm ? Form.Checkbox : Checkbox;

    return (
      <div className="text-box" data-cy={dataCY}>
        <div className="text-box__label">{label}</div>

        <Scrollbars className="text-box__scroll" autoHeight autoHeightMin={60} autoHeightMax={270}>
          <div className="text-box__inner" dangerouslySetInnerHTML={content} />
          {!!hasCheckbox && (
            <div className="text-box__checkbox">
              <Component
                data-cy={`${dataCY}-checkbox`}
                name={name}
                required
                label={checkboxLabel}
                defaultValue={defaultValue}
                value={value}
              />
            </div>
          )}
        </Scrollbars>
      </div>
    );
  }
}

export default TextBox;
