import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Preloader } from 'components';

import SchoolStep from './SchoolStep';
import ReportStep from './ReportStep';

import './style.scss';

function CorpReport({ data, onSubmit, webSettings, currentSchool, groupSchoolList, isLoading, isSaving }) {
  const [selectedSchoolIds, setSelectedSchoolIds] = useState([]);
  const [step, setStep] = useState(1);
  const savedSelection = get(webSettings, `${currentSchool.id}.reportSchoolSelection`, []);
  const hasSavedSelection = savedSelection && savedSelection.length > 0;

  const onApplySelection = () => {
    setSelectedSchoolIds(savedSelection || []);
  };

  const onSelectSchools = (ids, checked) => {
    let newSchoolIds = [...selectedSchoolIds];

    ids.forEach(id => {
      if (checked && !newSchoolIds.includes(id)) {
        newSchoolIds.push(id);
      }

      if (!checked) {
        newSchoolIds = newSchoolIds.filter(s => s !== id);
      }
    });

    setSelectedSchoolIds(newSchoolIds);
  };

  const handleSubmit = values =>
    onSubmit({
      ...values,
      school_ids: selectedSchoolIds
    });

  useEffect(() => {
    req.groupSchoolList();
  }, []);

  if (isLoading) {
    return (
      <div className="report-corp">
        <div className="report-corp__preloader">
          <Preloader large />
        </div>
      </div>
    );
  }

  return (
    <div className="report-corp">
      {step === 1 ? (
        <SchoolStep
          onApplySelection={onApplySelection}
          hasSavedSelection={hasSavedSelection}
          schools={groupSchoolList}
          selectedIds={selectedSchoolIds}
          onSelect={onSelectSchools}
          onNext={() => setStep(2)}
        />
      ) : (
        <ReportStep
          data={data}
          webSettings={webSettings}
          currentSchool={currentSchool}
          schools={groupSchoolList}
          selectedIds={selectedSchoolIds}
          onSelect={onSelectSchools}
          onPrev={() => setStep(1)}
          isSaving={isSaving}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  webSettings: state.currentUser.data.web_settings || {},
  currentSchool: state.currentUser.data.current_school,
  groupSchoolList: state.groupSchoolList.data,
  isLoading: state.groupSchoolList.loading,
  isSaving: state.reports.loading
});

export default connect(mapStateToProps)(CorpReport);
