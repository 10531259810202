import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonV2 } from 'components';

class Row extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  constructor() {
    super();
    this.state = { loading: false };
  }

  @bind
  addStudent() {
    const { data } = this.props;

    this.setState({ loading: true });
    req
      .kidsRosterActivate({ id: data.id })
      .then(() => {
        Actions.showFlash('Student added');
        this.setState({ loading: false });
      })
      .catch(() => {
        Actions.showFlash('Something went wrong, try later.', 'warning');
      });
  }

  renderStatus() {
    const { data } = this.props;
    const { loading } = this.state;

    if (data.status !== 'active') {
      return <ButtonV2 secondary label="Add student" onClick={this.addStudent} isLoading={loading} />;
    } else {
      return 'Added';
    }
  }

  render() {
    const { data } = this.props;

    return (
      <div className="table__row">
        <div className="table__cell table__cell--accent">{data.student_id}</div>
        <div className="table__cell">{data.student_first_name}</div>
        <div className="table__cell">{data.student_middle_name}</div>
        <div className="table__cell">{data.student_last_name}</div>
        <div className="table__cell">{this.renderStatus()}</div>
      </div>
    );
  }
}

export default Row;
