import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ActionButton, Placeholder, Icon } from 'components';
import { isMobile } from 'lib/utils';
import PlaidLink from 'components/PlaidLink';

function Step2Bank({ isSchool, onSubmit, autopayEnabled, goToList, plaidLinkToken, nextStep, prevStep }) {
  const mobile = isMobile();
  const [isSaving, setIsSaving] = useState(false);

  const onPlaidSuccess = async (publicToken, metadata) => {
    const reqMethod = isSchool ? 'addSchoolPaymentMethod' : 'addPaymentMethod';

    setIsSaving(true);
    if (onSubmit) {
      onSubmit({
        token_type: 'plaid',
        token: publicToken,
        plaid_account_id: metadata.account_id
      });
      return;
    }

    try {
      await req[reqMethod]({
        token_type: 'plaid',
        token: publicToken,
        plaid_account_id: metadata.account_id,
        auto_pay: autopayEnabled === true
      });

      Actions.showFlash('Bank added successfully');
      setIsSaving(false);

      if (mobile) {
        Actions.hideCloak();
      }

      goToList();
    } catch (err) {
      Actions.reportError('There was error adding bank account', err);
      if (mobile) {
        Actions.hideCloak();
      }
      setIsSaving(false);
    }
  };

  const onPlaidError = (err, metadata) => {
    if (err) {
      Actions.showFlash('There was error adding bank account', 'alert');
      console.error(err, metadata);
    }

    if (mobile) {
      Actions.hideCloak();
    }
  };

  const onSelectStripe = () => {
    nextStep();
  };

  const openPlaid = () => {
    if (mobile) {
      Actions.showCloak();
    }
  };

  if (isSaving) {
    return <Placeholder.NoResult isLoading={isSaving} />;
  }

  return (
    <div className="table payments-panel">
      <div className="table__header payments-panel__header">
        <ActionButton iconName="back" size={20} onClick={prevStep} />
        <div className="payments-panel__title">Choose Bank Method</div>
      </div>

      <div className="table__body">
        <div className="table__row payments-panel__body">
          <div className="payments-panel__content">
            <div className="path-selector">
              <PlaidLink
                linkToken={plaidLinkToken}
                onSuccess={onPlaidSuccess}
                onExit={onPlaidError}
                className="path-selector__option"
                onClick={openPlaid}
              >
                <div className="path-selector__option-inner">
                  Easy one-step to link bank account
                  <div className="path-selector__option-inner__desc">(Recommended)</div>
                </div>
              </PlaidLink>
              <div className="path-selector__option" onClick={onSelectStripe}>
                <div className="path-selector__option-inner">
                  Add Bank account manually
                  <div className="path-selector__option-inner__desc">
                    Verifies account using micro-deposits and takes 1-2 days
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="payments-panel__notice">
            <div className="payments-panel__notice-icon">
              <Icon name="verify" color="#8cd527" size={24} />
            </div>
            <div className="payments-panel__notice-text">
              Procare has the highest levels of PCI compliance for online payments.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Step2Bank.propTypes = {
  isSchool: PropTypes.bool,
  autopayEnabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  goToList: PropTypes.func,
  plaidLinkToken: PropTypes.string,
  nextStep: PropTypes.func,
  prevStep: PropTypes.func
};

const mapStateToProps = state => ({
  constants: state.constants.data
});

export default connect(mapStateToProps)(Step2Bank);
