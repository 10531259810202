import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import cx from 'classnames';
import { Form, ButtonV2 } from 'components';
import get from 'lodash/get';
import pick from 'lodash/pick';
import REPORT_FIELDS, {
  presets as REPORT_FIELDS_PRESETS,
  ALLERGY_REPORT,
  TE_DATE_RANGE_TYPES,
  TE_DATE_SINCE_TYPES,
  STUDENTS_FAMILY_REPORT
} from 'constants/reportFields';
import MEAL_PROGRAM_TYPES from 'constants/mealProgramTypes';
import { EMERGENCY_CARDS_DEFAULT_VALUES } from 'constants/emergencyReportFields';
import EmergencyCardFields from './EmergencyCardFields';
import CorpReport from './CorpReport';
import './style.scss';

class RequestReport extends Component {
  static timeout = 3; // waiting time for send new request (sec)

  constructor(props) {
    super(props);
    this.state = {
      kind: props.kind,
      isSavingPreferences: false,
      isResettingPreferences: false,
      transactions_preset: null,
      transaction_type: null,
      range: 'weekly',
      report_fields_preset: props.data.kind === 'kids_family_info' ? REPORT_FIELDS_PRESETS[0].value : null,
      past_due_missing: false,

      student_fields: this.defaultEmergencyCardsPreferences.student_fields,
      family_fields: this.defaultEmergencyCardsPreferences.family_fields,
      custom_student_fields: this.defaultEmergencyCardsPreferences.custom_student_fields,
      custom_parent_fields: this.defaultEmergencyCardsPreferences.custom_parent_fields,
      group_by: this.defaultEmergencyCardsPreferences.group_by,
      sort_by: this.defaultEmergencyCardsPreferences.sort_by,
      section_ids: this.defaultEmergencyCardsPreferences.section_ids,
      student_status: this.defaultEmergencyCardsPreferences.student_status,
      sorted_rooms_section_ids: this.sortRooms || []
    };
  }

  componentDidMount() {
    req.rooms();
    req.leadStages();
    req.allLedgers();
    req.customAttributes();
    req.tags();
  }

  @bind
  onChange(values) {
    if (this.state.range !== values.range) {
      this.setState({ range: values.range });
      this.formRef.updateField('reg_hours', values.range === 'weekly' ? 40 : 8);
    }

    if (this.state.report_fields_preset !== values.report_fields_preset && values.report_fields_preset === 'custom') {
      const fieldSelections = REPORT_FIELDS.student.filter(f => f.default).map(f => f.value);
      this.formRef.updateField('fields', fieldSelections);

      const familyFieldSelections = REPORT_FIELDS.carer.filter(f => f.default).map(f => f.value);
      this.formRef.updateField('family_fields', familyFieldSelections);
    }

    this.setState({
      report_filter_type: values.report_filter ? values.report_filter.type : null,
      kind: values.kind,
      report_fields_preset: values.report_fields_preset,
      transactions_preset: values.transactions_preset,
      transaction_type: values.transaction_type,
      past_due_missing: values.past_due_missing,
      te_date_range: values.te_date_range,

      // emergency cards fields
      student_fields: values.student_fields,
      family_fields: values.family_fields,
      custom_student_fields: values.custom_student_fields,
      custom_parent_fields: values.custom_parent_fields,
      group_by: values.group_by,
      sort_by: values.sort_by,
      section_ids: values.section_ids,
      student_status: values.student_status
    });
  }

  flashMessage(res) {
    return `<strong>${res.name}</strong> report was generated.
            ${res.xls_url ? `<a href=${res.xls_url} target="_blank" download=${res.kind}>XLS</a>` : ''}
            ${res.pdf_url ? `<a href=${res.pdf_url} target="_blank" download=${res.kind}>PDF</a>` : ''}`;
  }

  @bind
  submit(values) {
    const formValues = {
      ...this.getPresetvalues(values),
      ...values
    };
    const { data, constants } = this.props;
    const obj = {
      kind: data.kind,
      hash_data: {},
      ...formValues
    };

    const dateFields = ['report_from', 'report_to', 'report_date'];
    dateFields.forEach(dateField => {
      if (obj[dateField]) {
        obj[dateField] = moment(obj[dateField]).format('YYYY-MM-DD');
      }
    });

    if (formValues.te_date_range) {
      const { key, dateRange } = TE_DATE_RANGE_TYPES.find(({ key }) => key === formValues.te_date_range);

      if (key !== 'custom') {
        obj.report_from = moment(dateRange[0]).format('YYYY-MM-DD');
        obj.report_to = moment(dateRange[1]).format('YYYY-MM-DD');
      }
    }

    if (formValues.te_date_since) {
      const { dateRange } = TE_DATE_SINCE_TYPES.find(({ key }) => key === formValues.te_date_since);

      obj.report_from = moment(dateRange[0]).format('YYYY-MM-DD');
      obj.report_to = moment(dateRange[1]).format('YYYY-MM-DD');
    }

    if (formValues.report_month) {
      delete obj.report_month;

      if (data.kind.includes('student_age')) {
        delete obj.highlight_age_ranges;
        obj.hash_data.highlight_age_ranges = formValues.highlight_age_ranges;
        const monthRange = Number(formValues.month_range);

        obj.report_from = moment(formValues.report_month[1])
          .startOf('month')
          .format('YYYY-MM-DD');

        if (monthRange === 1) {
          obj.report_to = moment(formValues.report_month[1])
            .endOf('month')
            .format('YYYY-MM-DD');
        } else {
          obj.report_to = moment(formValues.report_month[1])
            .add(monthRange - 1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD');
        }

        delete obj.month_range;
      } else {
        obj.report_from = moment(formValues.report_month[0]).format('YYYY-MM-DD');
        obj.report_to = moment(formValues.report_month[1]).format('YYYY-MM-DD');
      }
    }

    if (formValues.report_week) {
      delete obj.report_week;
      obj.report_from = moment(formValues.report_week[0]).format('YYYY-MM-DD');
      obj.report_to = moment(formValues.report_week[1]).format('YYYY-MM-DD');
    }

    if (formValues.report_filter) {
      delete obj.report_filter;
      obj.report_from = moment(formValues.report_filter.dateRange[0]).format('YYYY-MM-DD');
      obj.report_to = moment(formValues.report_filter.dateRange[1]).format('YYYY-MM-DD');
    }

    if (data.form_data.includes('transactions_preset')) {
      switch (formValues.transactions_preset) {
        case 'all': {
          obj.hash_data.transactions = ['payments', 'invoices'];
          obj.hash_data.payment_modes = [];
          break;
        }
        case 'deposit': {
          obj.hash_data.transactions = ['payments'];
          obj.hash_data.payment_modes = ['check', 'cash', 'other'];
          break;
        }
        case 'custom': {
          obj.hash_data.transactions = [];
          obj.hash_data.payment_modes = [];
          formValues.transaction_type_mode.forEach(value => {
            const [transactionType, transactionMode] = value.split(',');

            if (!obj.hash_data.transactions.includes(transactionType)) {
              obj.hash_data.transactions.push(transactionType);
            }

            if (transactionMode) {
              obj.hash_data.payment_modes = [...obj.hash_data.payment_modes, transactionMode];
            }
          });
          break;
        }
      }
    }

    if (data.form_data.includes('transaction_type')) {
      switch (formValues.transaction_type) {
        case 'all': {
          obj.hash_data.transaction_type_modes = [];
          break;
        }
        case 'custom': {
          obj.hash_data.transaction_type_modes = [...formValues.transaction_type_mode];
          break;
        }
        default: {
          obj.hash_data.transaction_type_modes = [formValues.transaction_type];
          break;
        }
      }
    }

    if (data.form_data.includes('deposit_date')) {
      obj.hash_data.deposit_date = moment(formValues.deposit_date).format('YYYY-MM-DD');
    }

    if (obj.kind === 'transaction_fees') {
      obj.kind = 'transactions';
      obj.hash_data.include_fees = true;
    }

    if (obj.kind === 'emergency_cards') {
      [
        'student_fields',
        'family_fields',
        'custom_student_fields',
        'custom_parent_fields',
        'sort_by',
        'group_by',
        'student_status'
      ].forEach(f => {
        obj.hash_data[f] = formValues[f];
        delete obj[f];
      });

      // if `all rooms` is selected, then set section_ids=[] for emergency cards
      if (obj.section_ids && obj.section_ids[0] === 'all') {
        obj.section_ids = [];
      }
    }

    if (obj.kind === 'student_age') {
      const { sorted_rooms_section_ids } = this.state;
      if (obj.section_ids && obj.section_ids[0] === 'all') {
        const ids = sorted_rooms_section_ids.map(item => item.id);
        obj.section_ids = ids;
      }
    }

    if (data.form_data.includes('single_room')) {
      const { sorted_rooms_section_ids } = this.state;
      obj.section_id = obj.section_id || (sorted_rooms_section_ids.length > 0 ? sorted_rooms_section_ids[0].id : '');
    }

    if (data.form_data.includes('payout_type')) {
      obj.report_data = [formValues.payout_type];
    }

    if (data.form_data.includes('date_based_on')) {
      obj.report_data = [formValues.date_based_on];
    }

    if (data.form_data.includes('enrollment_status')) {
      obj.report_data = formValues.enrollment_status;
    }

    if (data.form_data.includes('ledger_ids')) {
      obj.report_data = formValues.ledger_ids;
    }

    if (data.form_data.includes('te_batch_details_options')) {
      obj.report_data = [formValues.te_batch_details_options];
    }

    if (data.form_data.includes('with_progress_only')) {
      obj.hash_data.with_progress_only = formValues.with_progress_only;
      delete obj['with_progress_only'];
    }

    if (data.form_data.includes('kids_monthly_meals_types')) {
      obj.hash_data.types = formValues.kids_monthly_meals_types;
    }

    if (data.form_data.includes('meal_program_types')) {
      obj.hash_data.meal_program_types = formValues.meal_program_types;
    }

    if (data.corporate) {
      obj.hash_data.school_ids = formValues.school_ids;
    }

    if (data.form_data.includes('staff_status')) {
      obj.hash_data.status = formValues.staff_status;
    }

    if (data.form_data.includes('student_status')) {
      obj.hash_data.status = formValues.student_status;
      obj.hash_data.student_status = formValues.student_status;
    }

    if (data.form_data.includes('past_due_missing')) {
      obj.hash_data.past_due_missing = formValues.past_due_missing;
      obj.hash_data.due_within_days = formValues.due_within_days ? parseInt(formValues.due_within_days, 10) : undefined;
    }

    if (data.form_data.includes('parent_info')) {
      obj.hash_data.parent_info = formValues.parent_info;
    }

    if (data.form_data.includes('vaccine_ids')) {
      obj.hash_data.vaccine_ids = [...formValues.vaccine_ids];
    }

    if (data.form_data.includes('tags')) {
      obj.hash_data.tag_names = [...formValues.tags];
      delete obj.tags;
    }

    if (data.form_data.includes('kid_ids')) {
      obj.hash_data.kid_ids = [...formValues.kid_ids];
      delete obj.kid_ids;
    }

    if (data.form_data.includes('custom_fields')) {
      if (data.kind === 'kids_family_info') {
        const { included, excluded } = formValues.fields.reduce(
          (acc, field) => {
            if (!REPORT_FIELDS.student.find(f => f.value === field)) {
              acc.included.push(field);
            } else {
              acc.excluded.push(field);
            }
            return acc;
          },
          { included: [], excluded: [] }
        );
        obj.hash_data['custom_fields'] = included;
        obj.hash_data['fields'] = excluded;
        delete obj['fields'];
      } else {
        obj.hash_data['custom_fields'] = formValues['custom_fields']
      }
    }

    if (data.form_data.includes('custom_family_fields')) {
      const { included, excluded } = formValues.family_fields.reduce(
        (acc, field) => {
          if (!REPORT_FIELDS.carer.find(f => f.value === field)) {
            acc.included.push(field);
          } else {
            acc.excluded.push(field);
          }
          return acc;
        },
        { included: [], excluded: [] }
      );
      obj.hash_data['custom_family_fields'] = included;
      obj.hash_data['family_fields'] = excluded;
      delete obj['family_fields'];
    }

    if (data.form_data.includes('pickup_fields')) {
      obj.hash_data['pickup_fields'] = formValues.pickup_fields;
      delete obj['pickup_fields'];
    }

    ['filename', 'fields', 'family_fields', 'custom_fields', 'custom_family_fields', 'filter_col'].forEach(f => {
      if (data.kind === 'kids_family_info' && (f === 'custom_fields' || f === 'fields' || f === 'custom_family_fields' || f === 'family_fields')) {
        return;
      }
    
      if (data.form_data.includes(f)) {
        obj.hash_data[f] = formValues[f];
      }
    });

    if (data.form_data.includes('reg_range')) {
      obj.report_data = [
        {
          range: formValues.range,
          reg_hours: parseInt(formValues.reg_hours)
        }
      ];
    }

    if (data.form_data.includes('name_to_face_type')) {
      obj.report_data = [formValues.name_to_face_type];
    }

    const checkHistoryField = get(data, 'form_header.checkHistory');
    const headers = checkHistoryField
      ? {
          checkHistory: obj[checkHistoryField]
        }
      : undefined;
    if (checkHistoryField) {
      const historyDate = get(constants, 'history_dates.daily_activities');
      if (
        (moment(obj.report_from).isBefore(historyDate) && moment(obj.report_to).isAfter(historyDate)) ||
        (moment(obj.report_from).isAfter(historyDate) && moment(obj.report_to).isBefore(historyDate))
      ) {
        Actions.showFlash(
          `Please provide the dates before or after ${moment(historyDate).format('MMMM D, YYYY')}`,
          'alert'
        );
        return;
      }
    }

    req
      .createReport(obj, undefined, headers)
      .then(res => {
        this.props.onClose();
        return res;
      })
      .then(res => {
        // if report already exists it will be replaced by `add` reducer
        // otherwise we need to check its status by timer
        res.generated_at
          ? Actions.showFlash(this.flashMessage(res), 'success')
          : this.updateReportStatus({ id: res.id });
      })
      .catch(e => {
        Actions.reportError('There was problem generating report', e);
      });
  }

  updateReportStatus(obj) {
    // Update report status by timer while it is not generated.
    // if report is generated (has non-null `generated_at` param),
    // it updates reports collection by `updateReportStatus` reducer.
    setTimeout(() => {
      req.updateReportStatus(obj).then(res => {
        if (res.failed) {
          Actions.showFlash('There was problem generating report', 'error');
        } else if (res.generated_at) {
          Actions.showFlash(this.flashMessage(res), 'success');
        } else {
          this.updateReportStatus(obj);
        }
      });
    }, RequestReport.timeout * 1000);
  }

  @bind
  getPresetvalues(values) {
    const { data } = this.props;
    let presetValues = {};

    if (data.kind === 'kids_family_info') {
      const preset = values.report_fields_preset;
      if (preset) {
        presetValues['filename'] = REPORT_FIELDS_PRESETS.find(p => p.value === preset)['filename'];

        presetValues['fields'] = REPORT_FIELDS.student
          .filter(f => f.preset && f.preset.includes(preset))
          .map(f => f.value);
        presetValues['family_fields'] = REPORT_FIELDS.carer
          .filter(f => f.preset && f.preset.includes(preset))
          .map(f => f.value);

        if (preset === ALLERGY_REPORT && values['filter_allergy_medication']) {
          presetValues['filter_col'] = 'allergy';
        }
      }
    }

    return presetValues;
  }

  get defaultEmergencyCardsPreferences() {
    const { webSettings, currentSchool } = this.props;
    const preferences = get(webSettings, `${currentSchool.id}.emergencyCards`, {});

    return Object.keys(EMERGENCY_CARDS_DEFAULT_VALUES).reduce(
      (prev, key) => ({
        ...prev,
        [key]: preferences[key] || EMERGENCY_CARDS_DEFAULT_VALUES[key]
      }),
      {}
    );
  }

  get sortRooms() {
    const { rooms } = this.props;
    return rooms ? rooms.sort((a, b) => a.name.localeCompare(b.name)) : null;
  }

  @bind
  onResetPreferences() {
    const { webSettings, currentSchool } = this.props;
    this.setState({ isResettingPreferences: true });

    return req
      .updateProfile({
        user: {
          web_settings: {
            ...webSettings,
            [currentSchool.id]: {
              ...get(webSettings, currentSchool.id, {}),
              emergencyCards: {}
            }
          }
        }
      })
      .then(() => {
        Object.keys(EMERGENCY_CARDS_DEFAULT_VALUES).forEach(key =>
          this.formRef.updateField(key, EMERGENCY_CARDS_DEFAULT_VALUES[key])
        );
        Actions.showFlash('Your preferences have been reset.');
      })
      .catch(e => Actions.reportError('Your preferences were unable to be reset.', e))
      .finally(() => this.setState({ isResettingPreferences: false }));
  }

  @bind
  onSavePreferences() {
    const { webSettings, currentSchool } = this.props;
    const preferences = pick(this.state, Object.keys(EMERGENCY_CARDS_DEFAULT_VALUES));

    this.setState({ isSavingPreferences: true });
    return req
      .updateProfile({
        user: {
          web_settings: {
            ...webSettings,
            [currentSchool.id]: {
              ...get(webSettings, currentSchool.id, {}),
              emergencyCards: preferences
            }
          }
        }
      })
      .then(() => Actions.showFlash('Your preferences have been saved.'))
      .catch(e => Actions.reportError('Your preferences were unable to be saved.', e))
      .finally(() => this.setState({ isSavingPreferences: false }));
  }

  renderReportFields() {
    const { report_fields_preset } = this.state;
    const { customAttributes, data } = this.props;

    const type = data.kind === 'staff_info' ? 'staff' : 'student';
    const fields = REPORT_FIELDS[type];
    const familyFields = REPORT_FIELDS.carer;

    const studentInfoFields = STUDENTS_FAMILY_REPORT.student_information;
    const parentGuardianFields = STUDENTS_FAMILY_REPORT.carer_info;
    const authorizedPickupFields = STUDENTS_FAMILY_REPORT.authorized_pickup_info;

    if (type === 'student' && report_fields_preset !== 'custom') {
      if (report_fields_preset === 'students_family_info') {
        return (
          <div className="reports-modal__fields-container">
            <div className="form__row">
              <div className="reports-modal__preset-fields">
                <div className="reports-modal__subtitle">Fields</div>
                <div className="reports-modal__subtitle-bold">
                    Student Information
                </div>
                <div className="reports-modal__preset-fields-list">
                  {studentInfoFields
                    .map(f => f.label)
                    .join(', ')}
                </div>
              </div>
            </div>
            <div className="form__row">
              <div className="reports-modal__preset-fields">
                <div className="reports-modal__subtitle-bold">
                    Parent/Guardian Information
                </div>
                <div className="reports-modal__preset-fields-list">
                  {parentGuardianFields
                    .map(f => f.label)
                    .join(', ')}
                </div>
              </div>
            </div>
            <div className="form__row">
              <div className="reports-modal__preset-fields">
                <div className="reports-modal__subtitle-bold">
                    Authorized Pickup Information
                </div>
                <div className="reports-modal__preset-fields-list">
                  {authorizedPickupFields
                    .map(f => f.label)
                    .join(', ')}
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className="form__row">
            <div className="reports-modal__preset-fields">
            <div className="reports-modal__subtitle">Fields</div>
            <div className="reports-modal__preset-fields-list">
              {[...fields, ...familyFields]
                .filter(f => f.preset && f.preset.includes(report_fields_preset))
                .map(f => f.label)
                .join(', ')}
            </div>
          </div>
        </div>
        );
      }
    } else {
      const customFields = customAttributes
        .filter(c => c.keeper === (type === 'staff' ? 'teacher' : 'kid'))
        .map(c => ({ value: c.id, label: c.name }));
      const customFamilyFields =
        type === 'student'
          ? customAttributes.filter(c => c.keeper === 'carer').map(c => ({ value: c.id, label: c.name }))
          : [];

      return (
        <div className="form__row">
          <div className="reports-modal__checkbox-list">
            <div className="reports-modal__checkbox-list-item">
              <div className="reports-modal__subtitle">Fields</div>
              {type === 'student' ? (
                <>
                <div className="reports-modal__fields-container">
                    <div className="reports-modal__subtitle-bold">
                      Student Information
                    </div>
                    <Form.CheckboxGroup
                      className="reports-modal__checkbox-group"
                      options={[...fields, ...customFields]}
                      name="fields"
                      defaultValue={REPORT_FIELDS[type].filter(f => f.default).map(f => f.value)}
                    />
                  </div>
                  <div className="reports-modal__fields-container">
                    <div className="reports-modal__subtitle-bold">
                      Parent/Guardian Information
                    </div>
                    <Form.CheckboxGroup
                      className="reports-modal__checkbox-group"
                      options={[...familyFields, ...customFamilyFields]}
                      name="family_fields"
                      defaultValue={familyFields.filter(f => f.default).map(f => f.value)}
                    />
                  </div>
                  <div className="reports-modal__fields-container">
                    <div className="reports-modal__subtitle-bold">
                      Authorized Pickup Information
                    </div>
                    <Form.CheckboxGroup
                      className="reports-modal__checkbox-group"
                      options={authorizedPickupFields}
                      name="pickup_fields"
                      defaultValue={authorizedPickupFields.filter(f => f.default).map(f => f.value)}
                    />
                  </div>
                </>
              ) :
              <Form.CheckboxGroup
                className="reports-modal__checkbox-group"
                options={fields}
                name="fields"
                defaultValue={REPORT_FIELDS[type].filter(f => f.default).map(f => f.value)}
              />
              }
            </div>
            {(customFields.length > 0 || customFamilyFields.length > 0) && type !== 'student' && (
              <div className="reports-modal__checkbox-list-item">
                <div className="reports-modal__subtitle">Custom Fields</div>
                <Form.CheckboxGroup
                  className="reports-modal__checkbox-group"
                  options={customFields}
                  name="custom_fields"
                />
                <Form.CheckboxGroup
                  className="reports-modal__checkbox-group"
                  options={customFamilyFields}
                  name="custom_family_fields"
                />
              </div>
            )}
          </div>
        </div>
      );
    }
  }

  reportNameToId(str = '') {
    return str
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-');
  }

  render() {
    const {
      data,
      staff,
      students,
      rooms,
      tags,
      constants,
      customReports,
      allLedgers,
      isLoading,
      leadStages,
      vaccines,
      customAttributes,
      currentSchool
    } = this.props;
    const { past_due_missing, report_filter_type, range, sorted_rooms_section_ids, te_date_range } = this.state;

    const reportTitle = data.title || customReports[data.kind].label;
    const defaultValues = data.default_values || {};
    const maxRangeMonth = get(data, 'form_options.report_to.max_range_month', 6);
    const transactionFormats = [
      { value: 'transactions', label: 'Procare Format' },
      { value: 'incidental_billing', label: 'FACTS Format' }
    ];

    if (
      currentSchool.billing_gateway === 'te' &&
      currentSchool.parent_payment_fee_paid_by === 'parent_payment_fee_paid_by_parent'
    ) {
      transactionFormats.push({
        value: 'transaction_fees',
        label: 'Procare Format + Fees Collected'
      });
    }

    // corporate reports need new totally different UI
    if (data.corporate) {
      return <CorpReport data={data} onSubmit={this.submit} />;
    }

    return (
      <div>
        <div className={cx('modal__header', { 'modal__header--bordered': data.kind === 'emergency_cards' })}>
          <span className="modal__header-title">{reportTitle}</span>
          {data.description && <span className="modal__header-description">{data.description}</span>}
        </div>

        <div className={cx('modal__container reports-modal', `reports-modal--${data.kind}`)}>
          <Form
            validateOn="submit"
            ref={node => (this.formRef = node)}
            onSubmit={this.submit}
            onChange={this.onChange}
            isLoading={isLoading}
            className={cx('reports-modal__form', `reports-modal__form--${data.kind}`)}
          >
            {['kids_family_info', 'staff_info'].includes(data.kind) && (
              <div className={cx('form__row', 'form__row--report-fields')}>
                <div className="reports-modal__staff-info-report-desc">
                  Choose the fields below and generate a report
                </div>
                {data.kind === 'kids_family_info' && (
                  <div className="form__row">
                    <Form.Select
                      name="report_fields_preset"
                      title="Select preset fields"
                      defaultValue={REPORT_FIELDS_PRESETS[0].value}
                    >
                      {REPORT_FIELDS_PRESETS.map(qr => (
                        <Form.Select.Item key={qr.value} label={qr.label} value={qr.value} />
                      ))}
                    </Form.Select>
                  </div>
                )}
                {this.renderReportFields()}
              </div>
            )}
            {data.kind === 'immunization' && (
              <React.Fragment>
                <div className="form__row">
                  <Form.Select
                    label="Immunizations"
                    name="vaccine_ids"
                    title="Select Immunizations"
                    type="checkbox"
                    required
                    multipleSelectionText="immunizations"
                    defaultValue={[...vaccines.map(r => r.id)]}
                  >
                    {vaccines.map(r => (
                      <Form.Select.Item key={r.id} label={r.title} value={r.id} />
                    ))}
                  </Form.Select>
                </div>
                <Form.Checkbox
                  className="reports-modal__checkbox reports-modal__checkbox--with-dropdown"
                  name="past_due_missing"
                  label="Show only past due or missing immunizations due within next"
                  defaultValue={false}
                />
                <div className="form__row form__row--due-days">
                  <Form.Select name="due_within_days" title="Any number of days" disabled={!past_due_missing}>
                    <Form.Select.Item label="Any number of days" value="" isDefault />
                    <Form.Select.Item label="7 days" value="7" />
                    <Form.Select.Item label="30 days" value="30" />
                    <Form.Select.Item label="60 days" value="60" />
                    <Form.Select.Item label="90 days" value="90" />
                  </Form.Select>
                </div>
              </React.Fragment>
            )}
            {data.form_data.includes('te_batch_details_options') && (
              <div className="form__row">
                <Form.Select
                  name="te_batch_details_options"
                  title="Select Options"
                  type="radio"
                  defaultValue={'all_statuses'}
                  required
                >
                  {Object.keys(constants.te_batch_details_options).map(r => (
                    <Form.Select.Item key={r} label={constants.te_batch_details_options[r]} value={r} />
                  ))}
                </Form.Select>
              </div>
            )}
            {data.form_data.includes('teacher_id') && (
              <div className="form__row">
                <Form.Select name="teacher_id" title="Select staff name">
                  <Form.Select.Item label="All Staff" value="" isDefault />
                  {staff.map(s => (
                    <Form.Select.Item key={s.id} label={s.name} value={s.id} />
                  ))}
                </Form.Select>
              </div>
            )}
            {data.form_data.includes('report_date') && (
              <div className="form__row">
                <Form.DatePicker data-cy="report-date" disableFuture name="report_date" defaultValue={new Date()} />
              </div>
            )}
            {data.form_data.includes('report_filter') && (
              <div className="form__row">
                <Form.DateFilter
                  disableFuture
                  label="Choose Date"
                  name="report_filter"
                  hasCustom
                  maxRangeMonthFilter={maxRangeMonth}
                  defaultValue={{
                    type: 'monthly',
                    date: moment()
                      .startOf('month')
                      .toDate()
                  }}
                />
              </div>
            )}
            {data.form_data.includes('report_month') && (
              <div className="form__row">
                <Form.MonthPicker
                  disableFuture
                  disableCurrentMonth={get(data, 'form_options.report_month.disableCurrentMonth', false)}
                  name="report_month"
                  defaultValue={[
                    moment()
                      .subtract(1, 'month')
                      .startOf('month')
                      .toDate(),
                    moment()
                      .subtract(1, 'month')
                      .endOf('month')
                      .toDate()
                  ]}
                />
              </div>
            )}
            {data.form_data.includes('report_week') && (
              <div className="form__row">
                <Form.DatePicker
                  disableFuture
                  name="report_week"
                  type="weekly"
                  defaultValue={
                    defaultValues.report_from ||
                    moment()
                      .subtract(1, 'week')
                      .startOf('isoWeek')
                      .toDate()
                  }
                />
              </div>
            )}
            {data.form_data.includes('te_date_range') && (
              <div className="form__row form__row-flex">
                <Form.Select name="te_date_range" type="radio" defaultValue={TE_DATE_RANGE_TYPES[0].key} required>
                  {TE_DATE_RANGE_TYPES.map(({ key, label }) => (
                    <Form.Select.Item key={key} value={key} label={label} />
                  ))}
                </Form.Select>
              </div>
            )}
            {((data.form_data.includes('report_from') && data.form_data.includes('report_to')) ||
              te_date_range === 'custom') && (
              <div className="form__row form__row--justified">
                <Form.DatePicker
                  disableFuture
                  name="report_from"
                  defaultValue={
                    defaultValues.report_from ||
                    moment()
                      .subtract(1, 'month')
                      .startOf('month')
                      .toDate()
                  }
                />
                <Form.DatePicker
                  disableFuture
                  name="report_to"
                  defaultValue={
                    defaultValues.report_to ||
                    moment()
                      .subtract(1, 'month')
                      .endOf('month')
                      .toDate()
                  }
                  rangeStart="report_from"
                  maxRangeMonth={maxRangeMonth}
                />
              </div>
            )}
            {data.form_data.includes('kid_ids') && (
              <div className="form__row">
                <Form.Select
                  name="kid_ids"
                  title="Select Students"
                  defaultValue={[]}
                  type="checkbox"
                  multipleSelectionText="students selected"
                  required
                >
                  {students.map(kid => (
                    <Form.Select.Item key={kid.id} label={kid.name} value={kid.id} />
                  ))}
                </Form.Select>
              </div>
            )}
            {data.form_data.includes('single_room') && (
              <div className="form__row">
                <Form.Select name="section_id" title="Select Room">
                  {sorted_rooms_section_ids.map((r, i) => {
                    return (
                      <Form.Select.Item key={r.id} label={r.name} value={r.id} isDefault={i === 0 ? true : false} />
                    );
                  })}
                </Form.Select>
              </div>
            )}
            {data.form_data.includes('month_duration') && (
              <div className="form__row form__row--justified reports-modal__multiple-items">
                <Form.Select name="month_range" className="reports-modal__justified-item" defaultValue={'3'}>
                  <Form.Select.Item value="1" label="1 Month" />
                  <Form.Select.Item value="3" label="3 Months" />
                  <Form.Select.Item value="6" label="6 Months" />
                </Form.Select>
                <span className="reports-modal__span-text">from</span>
                <Form.MonthPicker
                  disablePast
                  name="report_month"
                  defaultValue={moment()
                    .startOf('month')
                    .toDate()}
                />
              </div>
            )}
            {data.form_data.includes('rooms') && (
              <div
                className={cx('form__row', {
                  'form__row--justified form__row--report-filters': ['staff_info'].includes(
                    data.kind
                  )
                })}
              >
                <Form.Select name="section_id" title="Select Room">
                  <Form.Select.Item value="" label="All Rooms" isDefault />
                  {rooms.map(r => (
                    <Form.Select.Item key={r.id} label={r.name} value={r.id} />
                  ))}
                </Form.Select>
              </div>
            )}
            {data.form_data.includes('multi-rooms') && (
              <div className="form__row">
                <Form.Select
                  label={
                    ['kids_family_info', 'staff_info', 'immunization', 'staff_detailed_timecard'].includes(data.kind)
                      ? 'Rooms'
                      : undefined
                  }
                  name="section_ids"
                  title="Select Rooms"
                  type="checkbox"
                  multipleSelectionText="Rooms"
                  required
                  defaultValue={[...rooms.map(r => r.id)]}
                >
                  {rooms.map(r => (
                    <Form.Select.Item key={r.id} label={r.name} value={r.id} />
                  ))}
                </Form.Select>
              </div>
            )}
            {data.form_data.includes('multi-rooms-checkbox') && (
              <div className="form__row">
                <Form.Select
                  name="section_ids"
                  title="All Rooms"
                  type="checkbox"
                  multipleSelectionText="rooms"
                  allSelectionItemId="all"
                  defaultValue={['all']}
                >
                  <Form.Select.Item label="All Rooms" value="all" />
                  {rooms.map(r => (
                    <Form.Select.Item key={r.id} label={r.name} value={r.id} />
                  ))}
                </Form.Select>
              </div>
            )}
            {data.kind === 'staff_info' && (
              <div className={cx('form__row', 'form__row--staff-status')}>
                <Form.Select name="staff_status" label="Staff Type" title="Select staff status" defaultValue="active">
                  <Form.Select.Item key="all" label="All Staff" value="all" />
                  <Form.Select.Item key="active" label="Active Staff" value="active" />
                  <Form.Select.Item key="inactive" label="Inactive Staff" value="inactive" />
                </Form.Select>
              </div>
            )}
            {data.form_data.includes('student_status') && (
              <div className={cx('form__row', 'form__row--student-status', {
                'form__row--student-status-zero-margin': ['kids_family_info'].includes(data.kind)
              })}>
                <Form.Select
                  name="student_status"
                  label="Student Type"
                  title="Select student status"
                  defaultValue="active"
                  {...get(data, 'form_options.student_status')}
                >
                  {Object.keys(constants.kid_registration_statuses).map(s => (
                    <Form.Select.Item key={s} label={constants.kid_registration_statuses[s]} value={s} />
                  ))}
                </Form.Select>
              </div>
            )}
            {data.kind === 'kids_family_info' && this.state.report_fields_preset === ALLERGY_REPORT && (
              <div className="form__row">
                <Form.Checkbox
                  className="allergy-report__filter"
                  name="filter_allergy_medication"
                  label="Show only students with allergies/medication detail"
                  defaultValue={true}
                />
              </div>
            )}
            {data.form_data.includes('tags') && (
              <div
                className={cx('form__row')}
              >
                <Form.Select
                  name="tags"
                  title="Select Tags"
                  type="checkbox"
                  label={data.kind === 'immunization' ? 'Tags' : undefined}
                >
                  {tags.map(t => (
                    <Form.Select.Item key={t.id} label={t.name} value={t.name} />
                  ))}
                </Form.Select>
              </div>
            )}
            {data.form_data.includes('kids_info_report_options') && (
              <div className="form__row">
                <Form.Select
                  name="report_data"
                  title="Select Options"
                  type="checkbox"
                  multipleSelectionText={items => {
                    return items.map(i => constants.kids_info_report_options[i]).join(', ');
                  }}
                  defaultValue={['all_fields']}
                  allSelectionItemId="all_fields"
                  required
                >
                  {Object.keys(constants.kids_info_report_options).map(r => (
                    <Form.Select.Item key={r} label={constants.kids_info_report_options[r]} value={r} />
                  ))}
                </Form.Select>
              </div>
            )}
            {data.form_data.includes('te_date_since') && (
              <div className="form__row form__row-flex">
                <Form.Select name="te_date_since" type="radio" defaultValue={TE_DATE_SINCE_TYPES[0].key} required>
                  {TE_DATE_SINCE_TYPES.map(({ key, label }) => (
                    <Form.Select.Item key={key} value={key} label={label} />
                  ))}
                </Form.Select>
              </div>
            )}
            {data.form_data.includes('kids_monthly_meals_types') && (
              <div className="form__row">
                <Form.Select
                  name="kids_monthly_meals_types"
                  title="Select Types"
                  type="checkbox"
                  defaultValue={[...constants.kids_monthly_meals_types]}
                >
                  {constants.kids_monthly_meals_types.map(type => (
                    <Form.Select.Item key={type} label={type} value={type} />
                  ))}
                </Form.Select>
              </div>
            )}
            {data.form_data.includes('meal_program_types') && (
              <div className="form__row">
                <Form.Select
                  name="meal_program_types"
                  title="CACFP Status"
                  type="checkbox"
                  defaultValue={[...MEAL_PROGRAM_TYPES.map(m => m.id)]}
                >
                  {MEAL_PROGRAM_TYPES.map(m => (
                    <Form.Select.Item key={m.id} label={m.name} value={m.id} />
                  ))}
                </Form.Select>
              </div>
            )}
            {data.form_data.includes('reg_range') && (
              <div className="form__row form__row--justified form__row-flex">
                <Form.Select
                  label="Regular Hours"
                  name="range"
                  title="Select Hour Type"
                  type="radio"
                  defaultValue="weekly"
                  required
                  className="reports-modal__regular-type"
                >
                  <Form.Select.Item value="weekly" label="Weekly" />
                  <Form.Select.Item value="daily" label="Daily" />
                </Form.Select>

                <Form.Input
                  name="reg_hours"
                  className={'reports-modal__regular-hours'}
                  maxLength={2}
                  naturalNumber
                  type="number"
                  required
                  defaultValue={40}
                />
              </div>
            )}
            {data.form_data.includes('date_based_on') && (
              <div className="form__row">
                <Form.Select name="date_based_on" type="radio" defaultValue="created_date">
                  <Form.Select.Item value="created_date" label="Invoice Date" />
                  <Form.Select.Item value="service_date" label="Service End Date" />
                </Form.Select>
              </div>
            )}
            {data.form_data.includes('aging_type') && (
              <div className="form__row">
                <Form.Select name="report_data" title="Select Type" type="checkbox" required defaultValue={['month']}>
                  <Form.Select.Item value="month" label="Monthly" />
                </Form.Select>
              </div>
            )}
            {data.kind === 'payouts' && data.form_data.includes('payout_type') && (
              <div className="form__row">
                <Form.Select name="payout_type" title="Select Status" type="radio" defaultValue="all">
                  <Form.Select.Item value="all" label="All" />
                  <Form.Select.Item value="in_transit" label="In Transit" />
                </Form.Select>
              </div>
            )}
            {data.kind === 'bank_deposit_slip' && (
              <div className="form__row">
                <Form.DatePicker
                  required
                  disableFuture
                  name="deposit_date"
                  maxRangeMonth={maxRangeMonth}
                  placeholder="Deposit Date"
                />
              </div>
            )}
            {data.form_data.includes('transaction_type') && (
              <React.Fragment>
                <div className="form__row">
                  <Form.Select name="transaction_type" title="Select Transaction Type" type="radio" defaultValue="all">
                    <Form.Select.Item value="all" label="All Transactions" />
                    <Form.Select.Item value="Cash" label="Cash" />
                    <Form.Select.Item value="Check" label="Check" />
                    <Form.Select.Item value="Other" label="Other" />
                    <Form.Select.Item value="custom" label="Custom" />
                  </Form.Select>
                </div>
                {this.state.transaction_type === 'custom' && (
                  <div className="form__row">
                    <Form.Select required name="transaction_type_mode" title="Select Transaction Types" type="checkbox">
                      <Form.Select.Item value="Cash" label="Cash" />
                      <Form.Select.Item value="Check" label="Check" />
                      <Form.Select.Item value="Other" label="Other" />
                    </Form.Select>
                  </div>
                )}
              </React.Fragment>
            )}
            {data.kind === 'name_to_face' && data.form_data.includes('name_to_face_type') && (
              <div className="form__row">
                <Form.Select name="name_to_face_type" title="Select Type" type="radio">
                  <Form.Select.Item value="" label="All Types" isDefault />
                  <Form.Select.Item value="spot_check" label="Spot Check" />
                  <Form.Select.Item value="nap_check" label="Nap Check" />
                  <Form.Select.Item value="diaper_check" label="Diaper Check" />
                  <Form.Select.Item value="health_check" label="Health Check" />
                  <Form.Select.Item value="outdoors" label="Outdoors" />
                  <Form.Select.Item value="other" label="Other" />
                </Form.Select>
              </div>
            )}
            {data.form_data.includes('transactions_preset') && (
              <React.Fragment>
                <div className="form__row">
                  <Form.Select
                    name="transactions_preset"
                    title="Select Transaction Type"
                    type="radio"
                    defaultValue="all"
                  >
                    <Form.Select.Item value="all" label="All Transactions" />
                    <Form.Select.Item value="deposit" label="Deposit Report" />
                    <Form.Select.Item value="custom" label="Custom" />
                  </Form.Select>
                </div>
                {this.state.transactions_preset === 'custom' && (
                  <div className="form__row">
                    <Form.Select
                      required
                      name="transaction_type_mode"
                      title="Select Transasction Types"
                      type="checkbox"
                    >
                      <Form.Select.Item value="invoices" label="Invoice" />
                      <Form.Select.Item value="payments,cash" label="Payment - Cash" />
                      <Form.Select.Item value="payments,check" label="Payment - Check" />
                      <Form.Select.Item value="payments,card" label="Payment - Card" />
                      <Form.Select.Item value="payments,bank_account" label="Payment - ACH" />
                      <Form.Select.Item value="payments,other" label="Payment - Other" />
                    </Form.Select>
                  </div>
                )}
                <div className="form__row">
                  <Form.Select name="kind" title="Select Kind" type="radio" defaultValue="transactions">
                    {transactionFormats.map(t => (
                      <Form.Select.Item key={t.value} value={t.value} label={t.label} />
                    ))}
                  </Form.Select>
                </div>
              </React.Fragment>
            )}
            {data.kind === 'immunization' && (
              <Form.Checkbox
                className="reports-modal__checkbox reports-modal__checkbox--standalone"
                name="parent_info"
                label="Show parent name and emails"
                defaultValue={false}
              />
            )}
            {this.state.kind === 'incidental_billing' && (
              <span className="reports-modal__desc">Can be imported to FACTS Incidental billing system</span>
            )}
            {data.kind === 'aging' && (
              <span className="reports-modal__desc">Provides balance snapshot for last 6 months</span>
            )}
            {(data.kind === 'staff_monthly_timecard' || data.kind === 'staff_siso_payroll') &&
              report_filter_type !== 'weekly' &&
              report_filter_type !== 'biweekly' &&
              range === 'weekly' && (
                <span className="reports-modal__desc">
                  Please Note: Overtime is calculated based on a Monday-Sunday work week. When selecting Monthly or
                  Custom date picker, if the selected period does not start on a Monday, the report will include
                  overtime from the previous week.
                </span>
              )}
            {data.kind === 'kids_monthly_attendance' && (
              <div className="form__row">
                <Form.Select name="kind" title="Report Format" type="radio" defaultValue="kids_monthly_attendance">
                  <Form.Select.Item value="kids_monthly_attendance" label="Procare Format (recommended)" />
                  <Form.Select.Item value="city_span" label="Cityspan Format" />
                </Form.Select>
              </div>
            )}
            {data.kind === 'ledger_transactions' && (
              <div className="form__row">
                <Form.Select type="checkbox" name="ledger_ids" title="All Ledgers" enableClear>
                  {allLedgers.map(t => (
                    <Form.Select.Item key={t.id} value={t.id} label={t.name} />
                  ))}
                </Form.Select>
              </div>
            )}
            {data.kind === 'enrollments' && (
              <div className="form__row">
                <Form.Select
                  name="enrollment_status"
                  title="Status"
                  type="checkbox"
                  required
                  data-cy="enrollment-status"
                  multipleSelectionText={items => `${items.length} status selected`}
                >
                  {leadStages.map(s => (
                    <Form.Select.Item key={s.id} value={s.id} label={s.name} />
                  ))}
                </Form.Select>
              </div>
            )}
            {data.kind === 'emergency_cards' && (
              <EmergencyCardFields
                customAttributes={customAttributes}
                constants={constants}
                rooms={rooms}
                defaultValues={this.defaultEmergencyCardsPreferences}
                values={pick(this.state, [
                  'student_fields',
                  'family_fields',
                  'custom_student_fields',
                  'custom_parent_fields',
                  'group_by'
                ])}
                onUpdateField={this.formRef?.updateField}
              />
            )}
            {data.kind === 'historic_fte' && (
              <span className="reports-modal__desc">Provides enrollment snapshot for last 12 months</span>
            )}
            {data.kind === 'student_portfolios' && (
              <div className="form__row">
                <Form.Checkbox
                  className="reports-modal"
                  name="with_progress_only"
                  label="Only include Assessments and children with an assigned Progress"
                  defaultValue={true}
                />
              </div>
            )}
            {data.kind === 'student_age' && (
              <div className="form__row">
                <Form.Checkbox
                  className="reports-modal"
                  name="highlight_age_ranges"
                  label="Include recommended evaluation and exceeded age range"
                  defaultValue={true}
                />
              </div>
            )}
            {data.kind === 'emergency_cards' ? (
              <div className="form__row form__row--actions">
                <ButtonV2
                  tertiary
                  type="button"
                  label="Reset Preferences"
                  onClick={this.onResetPreferences}
                  isLoading={this.state.isResettingPreferences}
                />
                <ButtonV2
                  secondary
                  type="button"
                  label="Save Preferences"
                  onClick={this.onSavePreferences}
                  isLoading={this.state.isSavingPreferences}
                />
                <Form.Submit
                  label="Generate"
                  data-cy="generate-report"
                  className="generate-report"
                  id={this.reportNameToId(reportTitle)}
                />
              </div>
            ) : (
              <div className="form__row form__row--actions">
                <Form.Submit
                  label="Generate report"
                  data-cy="generate-report"
                  className="generate-report"
                  id={this.reportNameToId(reportTitle)}
                />
              </div>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  constants: state.constants.data,
  currentSchool: state.currentUser.data.current_school,
  customReports: state.customReports.data,
  customAttributes: state.customAttributes.data,
  webSettings: state.currentUser.data.web_settings || {},
  students: state.students.data,
  staff: state.staff.data,
  rooms: state.rooms.data,
  tags: state.tags.data,
  vaccines: state.vaccines.data,
  leadStages: state.leadStages.data,
  allLedgers: state.allLedgers.data.ledgers,
  isLoading: state.reports.loading
});

export default connect(mapStateToProps)(RequestReport);
