import React from 'react';
import { TextInput, SelectGroup } from 'components';

const StudentFilter = ({ rooms, tags, filters, onUpdate }) => {
  const { search, roomId, tagIds } = filters;

  return (
    <div className="form--inline">
      <div className="students__filter-bar-search">
        <TextInput placeholder="Search Student Name" value={search} onChange={val => onUpdate({ search: val })} />
      </div>

      {rooms && (
        <SelectGroup
          title="Select Room"
          type="radio"
          className="students__filter-bar-select"
          value={roomId}
          checked={roomId}
          onChange={val => onUpdate({ roomId: val })}
        >
          <SelectGroup.Item value="" label="All Rooms" isDefault />
          {rooms.map(room => (
            <SelectGroup.Item key={room.id} value={room.id} label={room.name} />
          ))}
        </SelectGroup>
      )}

      {tags && (
        <SelectGroup
          type="checkbox"
          title="Select Tags"
          className="students__filter-bar-select"
          multipleSelectionText="tags applied"
          checked={tagIds}
          value={tagIds}
          onChange={val => onUpdate({ tagIds: val })}
        >
          {tags.map(tag => (
            <SelectGroup.Item key={tag.id} value={tag.id} label={tag.name} />
          ))}
        </SelectGroup>
      )}
    </div>
  );
};

export default StudentFilter;
