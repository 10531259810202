import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ButtonV2, SelectGroup, Checkbox, Placeholder, TooltipTrigger } from 'components';
import { PERMISSION_MAP, PERMISSIONS, CORP_PERMISSIONS, SCHOOL_PERMISSIONS } from 'constants/permissions';
import './style.scss';

class ChangePermissions extends Component {
  static propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      roleSaving: false,
      role: props.data.is_admin ? 'admin' : 'nonAdmin',
      permissions: {
        ...props.data.permissions
      }
    };
  }

  static defaultProps = {
    data: {}
  };

  @bind
  async handleSubmit() {
    const { staff } = this.props.data;
    const { permissions } = this.state;

    this.setState({ saving: true });
    try {
      await req.updateStaffPermissions({
        id: staff.id,
        permissions
      });
      this.props.onClose();
    } catch (e) {
      this.setState({ saving: false });
      Actions.reportError('There was problem saving permissions', e);
    }
  }

  @bind
  async handleSaveRole(role) {
    const {
      data: { staff }
    } = this.props;

    try {
      await req.updateStaffItem({
        id: staff.id,
        teacher: { is_admin: role === 'admin' }
      });
      const resp = await req.staffPermissions({ id: staff.id });
      this.setState({
        permissions: {
          ...resp
        }
      });
      return true;
    } catch (e) {
      Actions.reportError('There was problem updating role', e);
    }
  }

  @bind
  async handleChangeRole(role) {
    const shouldChangeRole = await Actions.showModal('Confirmation', {
      title: 'Warning',
      description: 'Permissions will be reset with the role change. Are you sure?',
      yesButton: 'Yes',
      noButton: 'Cancel'
    });

    if (shouldChangeRole) {
      this.setState({ roleSaving: true });
      const resp = await this.handleSaveRole(role);
      this.setState({ roleSaving: false });
      if (resp) {
        this.setState({ role });
      }
    }
  }

  @bind
  handleChangePermission(key, value) {
    this.setState({
      permissions: {
        ...this.state.permissions,
        [key]: value
      }
    });
  }

  renderRow(key) {
    const { permissions, role } = this.state;
    const { corpSchool } = this.props;
    const value = permissions[key];
    const label = PERMISSION_MAP[key].label;
    const disabledKeys = get(PERMISSION_MAP, `${key}.${corpSchool ? 'disabledCorp' : 'disabled'}.${role}`, []);
    const tooltip = PERMISSION_MAP[key].tooltip;

    return (
      <div key={key} className="change-permissions__row">
        <div className="change-permissions__row-label">
          {label}
          {tooltip && (
            <TooltipTrigger tooltip={tooltip} side="center" className="change-permissions__row-tooltip">
              <ButtonV2 icon iconName="tooltip" iconSize={16} />
            </TooltipTrigger>
          )}
        </div>
        <div className="change-permissions__row-values">
          <Checkbox
            checked={value === 'none'}
            disabled={disabledKeys.includes('none')}
            onChange={() => this.handleChangePermission(key, 'none')}
            label="No Access"
          />
          <Checkbox
            checked={value === 'read'}
            disabled={disabledKeys.includes('read')}
            onChange={() => this.handleChangePermission(key, 'read')}
            label="Read"
          />
          <Checkbox
            checked={value === 'write'}
            disabled={disabledKeys.includes('write')}
            onChange={() => this.handleChangePermission(key, 'write')}
            label="Full Access"
          />
        </div>
      </div>
    );
  }

  render() {
    const { role, saving, roleSaving } = this.state;
    const { currentSchool, corpSchool } = this.props;

    const ALL_PERMISSIONS = (corpSchool ? CORP_PERMISSIONS : PERMISSIONS).sort((a, b) =>
      PERMISSION_MAP[a].label.localeCompare(PERMISSION_MAP[b].label)
    );
    const filteredPermissions = ALL_PERMISSIONS.filter(
      key =>
        (!PERMISSION_MAP[key].enabledBySchoolSetting || PERMISSION_MAP[key].enabledBySchoolSetting(currentSchool)) &&
        !SCHOOL_PERMISSIONS.includes(key)
    );
    const schoolPermissions = ALL_PERMISSIONS.filter(
      key =>
        (!PERMISSION_MAP[key].enabledBySchoolSetting || PERMISSION_MAP[key].enabledBySchoolSetting(currentSchool)) &&
        SCHOOL_PERMISSIONS.includes(key)
    );

    return (
      <div className="modal__wrapper change-permissions">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Edit Role & Permissions</div>
        </div>

        <div className="modal__container">
          {roleSaving ? (
            <Placeholder.NoResult isLoading />
          ) : (
            <div className="change-permissions__content">
              <div className="change-permissions__role">
                <div className="change-permissions__role-label">Role: </div>
                <SelectGroup
                  type="radio"
                  className="change-permissions__role-select"
                  name="is_admin"
                  onChange={this.handleChangeRole}
                  checked={role}
                  data-cy="change-role"
                >
                  <SelectGroup.Item value="nonAdmin" label="Non Admin" />
                  <SelectGroup.Item value="admin" label="Admin" />
                </SelectGroup>
              </div>

              <div className="change-permissions__section">
                {corpSchool && <div className="change-permissions__section-title">Corporate Portal</div>}
                {filteredPermissions.map(perm => this.renderRow(perm))}
              </div>
              {corpSchool && (
                <div className="change-permissions__section">
                  <div className="change-permissions__section-title">School Permissions</div>
                  {schoolPermissions.map(perm => this.renderRow(perm))}
                </div>
              )}
            </div>
          )}

          <div className="form__row form__row--actions">
            <ButtonV2 label="Save Changes" onClick={this.handleSubmit} data-cy="save" isLoading={saving} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentSchool: state.currentUser.data.current_school,
  corpSchool: state.currentUser.data.current_school.corp_school
});

export default connect(mapStateToProps)(ChangePermissions);
