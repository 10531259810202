import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FILE_TYPES, MIME_TYPES } from 'constants/fileTypes';
import UploadFile from '../common/UploadFile';
import './style.scss';

const uploadFileProps = {
  title: 'Upload Document',
  s3Key: 'documents',
  strategy: 's3',
  maxFileSize: 50,
  acceptedMIME: MIME_TYPES,
  acceptedExts: FILE_TYPES,
  skipSuccess: true,
  closeOnSuccess: false
};

class UploadDocument extends Component {
  static propTypes = {
    data: PropTypes.shape({
      kidId: PropTypes.string,
      parent: PropTypes.bool
    }),
    onClose: PropTypes.func
  };

  static defaultProps = {
    data: {}
  };

  @bind
  upload(file) {
    return Promise.resolve(this.props.onClose({ file }));
  }

  render() {
    return (
      <UploadFile
        {...uploadFileProps}
        parent={this.props.data.parent}
        onBack={this.openList}
        onClose={this.props.onClose}
        onSuccess={this.upload}
      />
    );
  }
}

const mapStateToProps = ({ kidDocs }) => ({
  documents: kidDocs.data
});

export default connect(mapStateToProps)(UploadDocument);
