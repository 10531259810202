import React, { Component } from 'react';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, ActionButton } from 'components';
import { injectStripe, CardElement } from 'react-stripe-elements';
import './style.scss';

export class CreditCardForm extends Component {
  static propTypes = {
    isSchool: PropTypes.bool,
    goToList: PropTypes.func.isRequired,
    onSubmit: PropTypes.func
  };

  constructor(...args) {
    super(...args);
    this.state = {
      isLoading: false
    };
  }

  @bind
  handleSubmit(data) {
    const { isSchool, onSubmit, autopayEnabled, subKind, history } = this.props;
    const reqMethod = isSchool ? 'addSchoolPaymentMethod' : 'addPaymentMethod';

    const editedMethod = get(history, 'location.state.editedMethod');
    const isEdited = editedMethod != null && get(editedMethod, 'sub_kind') === subKind;

    this.setState({ isLoading: true });

    return this.props.stripe
      .createToken(data)
      .then(({ token, error }) => {
        if (error) {
          throw error;
        }

        if (onSubmit) {
          return onSubmit({
            token: token.id,
            token_type: 'card',
            brand: token.card && token.card.brand,
            last4: token.card && token.card.last4
          });
        }

        return req[reqMethod]({
          token: token.id,
          token_type: 'card',
          auto_pay: isEdited ? get(editedMethod, 'auto_pay') : autopayEnabled === true,
          sub_kind: !isSchool ? subKind : undefined
        }).then(() => {
          Actions.showFlash('Card added successfully');
          this.setState({ isLoading: false });
          this.props.goToList();
        });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        Actions.reportError('There was problem with adding payment method', err);
      });
  }

  render() {
    const { onPrevStep, onSubmit } = this.props;
    const { isLoading } = this.state;

    return (
      <Form className="table payments-panel" onSubmit={this.handleSubmit} isLoading={isLoading}>
        <div className="table__header payments-panel__header">
          {!onSubmit && <ActionButton iconName="back" size={20} onClick={onPrevStep} />}
          <div className="payments-panel__title">{onSubmit ? 'Submit Registration' : 'Set up Card'}</div>
        </div>

        <div className="table__body">
          <div className="table__row payments-panel__body">
            <div className="payments-panel__content">
              <div className="form__row">
                <Form.Input name="name" placeholder="Enter Name" label="Account Holder Name" required />
              </div>

              <div className="form__row">
                <div className="form-input">
                  <label className="form__label">Card</label>

                  <CardElement />
                </div>
              </div>
            </div>
          </div>

          <div className="table__row table__row--footer payments-panel__footer">
            <Form.Submit className="button--large" label={onSubmit ? 'Continue' : 'Add Card'} />
          </div>
        </div>
      </Form>
    );
  }
}

export default injectStripe(withRouter(CreditCardForm));
