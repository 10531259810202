const authUrl = process.env.AUTH_URL;
const fallbackUrl = process.env.API_URL;

export const API_TARGET_AUTH = 'online-auth';

export const onlineAuthEnabled = flags => flags['use-online-auth-web'];

/**
 * Routes can either target the online auth server or the current tenant's api.
 * This function returns the correct URL based on the route taking into account
 *  whether or not online auth is being used.
 * @param {*} routeData The route data object.
 * @returns A url to use for the specified route, either for auth or a tenant specific api url.
 */
export const getRouteSpecificApiUrl = routeData => {
  // If an online auth url wasn't configured, we consider it disabled and use the fallback.
  if (!authUrl) {
    return fallbackUrl;
  }

  return routeData.apiTarget === API_TARGET_AUTH ? authUrl : fallbackUrl;
};

// Method may need future update to support cross-tenancy functionality
export const getTenantApiUrlFromState = () => {
  return fallbackUrl;
};

/**
 * Auth tokens may differ depending on online auth being enabled. This function will
 *  return the correct bearer token for the current tenant or user.
 * @param {*} state object containing the tenant and current user data.
 * @returns A bearer token containing the auth token for the current tenant or user.
 */
export const getAuthTokenFromState = state => {
  const tenant = state.tenant?.data;
  const user = state.currentUser?.data;

  // Prioritize the token from the tenant if it exists, otherwise fallback to the user's token.
  const token = tenant?.auth_token || user?.auth_token;

  return token ? `Bearer ${token}` : null;
};
