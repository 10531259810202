import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ActionButton, Preloader } from 'components';

const ManageTableNew = ({ ItemComponent, renderCustom, onCreate, onCancel, onEditEnd, isUpdateLoading, itemProps }) => {
  const [newItem, setNewItem] = useState({});
  const [disableCreate, setDisableCreate] = useState(false);
  
  const handleChange = (updatedItem) => {
    setNewItem((prev) => {
      if (updatedItem.title !== prev.title) {
        setDisableCreate(false);
      }
      return { ...prev, ...updatedItem };
    });
  };

  const handleCreate = async () => {
    try {
      await onCreate(newItem);
    } catch (e) {
      const formErrors = e.response?.data?.form_errors;
      if (formErrors && formErrors.title) {
        setDisableCreate(true);
      }
    }
  };

  const renderControls = () => (
    <div className="manage-table-item__controls">
      {isUpdateLoading ? (
        <div className="manage-table-item__preloader">
          <Preloader small />
        </div>
      ) : (
        <ActionButton square size={14} iconName="tick-1" onClick={handleCreate} disabled={disableCreate} />
      )}
      <ActionButton square size={14} iconName="close" onClick={onCancel} disabled={isUpdateLoading} />
    </div>
  );

  const renderContent = () => {
    if (ItemComponent) {
      return (
        <ItemComponent
          isNew
          isEdit
          item={newItem}
          onEditEnd={onEditEnd}
          onChange={handleChange}
          renderControls={renderCustom ? renderControls : undefined}
          {...itemProps}
        />
      );
    }
    return null;
  };

  if (renderCustom) {
    return renderContent();
  }

  return (
    <div className="table__row manage-table-item">
      {renderContent()}
      <div className="table__cell table__cell--xs table__cell--actions">{renderControls()}</div>
    </div>
  );
};

ManageTableNew.propTypes = {
  ItemComponent: PropTypes.any,
  renderCustom: PropTypes.bool,
  onCreate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEditEnd: PropTypes.func,
  isUpdateLoading: PropTypes.bool,
  itemProps: PropTypes.object,
};

export default ManageTableNew;
