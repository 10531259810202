import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';

const FilterItemCheckbox = ({ name, label, defaultValue, checkBoxType }) => (
  <div className="form__row form__row--text">
    <Form.Checkbox
      type={checkBoxType || 'circle'}
      name={name}
      data-cy={name}
      label={label}
      defaultValue={defaultValue}
    />
  </div>
);

FilterItemCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.bool,
  checkBoxType: PropTypes.string
};

export default FilterItemCheckbox;
