import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from 'components';
import './style.scss';

const DIRECTIONS = ['asc', 'desc'];

const SortIcon = () => (
  <div className="order-controls__default-icon">
    <Icon size={10} name="chevron-up" className="order-controls__icon" />
    <Icon size={10} name="chevron-down" className="order-controls__icon" />
  </div>
);

function OrderControls({ value }) {
  if (!value) {
    return <SortIcon />;
  }

  return (
    <div className="order-controls-v2">
      <div className="order-controls__item">
        <Icon
          size={12}
          name={value === 'desc' ? 'arrow-down' : 'arrow-up'}
          className={cx('order-controls__icon', `order-controls__icon--${value}`)}
        />
      </div>
    </div>
  );
}

OrderControls.propTypes = {
  value: PropTypes.oneOf(DIRECTIONS),
  onChange: PropTypes.func
};

export default OrderControls;
