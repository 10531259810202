import React, { useState, useEffect } from 'react';
import Steps from 'modals/ModalController/Steps';
import { connect } from 'react-redux';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Preloader } from 'components';
import { getStripePubKey } from 'lib/utils/selectors';
import { fetchPlaidLinkToken } from 'lib/utils/plaid';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import ThankyouStep from './ThankyouStep';
import './style.scss';

function Enroll({ data, stripePubKey, subscriptionInfo, subscriptionPlans, onClose }) {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [plaidToken, setPlaidToken] = useState('');
  const [plaidAccountId, setPlaidAccountId] = useState('');
  const [bankName, setBankName] = useState('');
  const [plan, setPlan] = useState(null);
  const [coupon, setCoupon] = useState(data.coupon || null);
  const [plaidLinkToken, setPlaidLinkToken] = useState(null);

  const initializePlans = async () => {
    try {
      const result = await req.subscriptionPlans();
      if (data.category === 'pro') {
        const plan = result.pro.find(p => p.id === 'pro-unlimited-primrose_enterprise-v5') || null;
        setPlan(plan);
      } else {
        const plans = result[data.category].sort((p1, p2) => p1.amount - p2.amount);
        setPlan({ ...plans[0] });
      }
    } catch (error) {
      Actions.reportError('Failed to fetch subscription plans', error);
    }
  };

  const initializePlaid = async () => {
    try {
      const token = await fetchPlaidLinkToken();
      setPlaidLinkToken(token);
    } catch (error) {
      Actions.reportError('Failed to Create Plaid Link Token', error);
    }
  };

  useEffect(() => {
    initializePlans();
    initializePlaid();
  }, [data.category]);

  const getSubscriptionInfo = async () => {
    if (!plan) return;

    return req.subscriptionInfo({
      subscription: {
        plan_id: plan.id,
        quantity: 1, // hardcoded as 1
        coupon_id: coupon?.coupon_id
      }
    });
  };

  useEffect(() => {
    if (plan) {
      getSubscriptionInfo();
    }
  }, [plan, coupon]);

  const onUpdateMethod = methodData => {
    const { paymentMethod, plaidToken, plaidAccountId, bankName } = methodData;

    if (paymentMethod) setPaymentMethod(paymentMethod);
    if (plaidToken) setPlaidToken(plaidToken);
    if (plaidAccountId) setPlaidAccountId(plaidAccountId);
    if (bankName) setBankName(bankName);
  };

  const handleProCategoryPlans = () => {
    if (data.category === 'pro') {
      return subscriptionPlans[data.category].filter((plan)=> plan.id === 'pro-unlimited-primrose_enterprise-v5')
    } else {
      return subscriptionPlans[data.category];
    }
  }

  if (!plan) {
    return (
      <div className="enroll">
        <div className="enroll__preloader">
          <Preloader large />
        </div>
      </div>
    );
  }

  return (
    <div className="enroll">
      <GoogleReCaptchaProvider
        useEnterprise
        scriptProps={{ async: true }}
        reCaptchaKey={process.env.RECAPTCHA_SITE_KEY}
      >
        <StripeProvider apiKey={stripePubKey}>
          <Elements>
            <Steps>
              <Steps.Item>
                <Step1
                  category={data.category}
                  plan={plan}
                  subscriptionPlans={handleProCategoryPlans()}
                  coupon={coupon}
                  setPlan={setPlan}
                  setCoupon={setCoupon}
                />
              </Steps.Item>
              <Steps.Item>
                <Step2 onUpdateMethod={onUpdateMethod} plaidLinkToken={plaidLinkToken} />
              </Steps.Item>
              <Steps.Item>
                <Step3
                  plan={plan}
                  coupon={coupon}
                  paymentMethod={paymentMethod}
                  plaidToken={plaidToken}
                  plaidAccountId={plaidAccountId}
                  bankName={bankName}
                  subscriptionInfo={subscriptionInfo}
                  onClose={onClose}
                />
              </Steps.Item>
              <Steps.Item>
                <ThankyouStep />
              </Steps.Item>
            </Steps>
          </Elements>
        </StripeProvider>
      </GoogleReCaptchaProvider>
    </div>
  );
}

const mapStateToProps = state => ({
  subscriptionPlans: state.subscriptionPlans.data,
  subscriptionInfo: state.subscriptionInfo.data,
  stripePubKey: getStripePubKey(state) // stripe key for kinderlime
});

export default connect(mapStateToProps)(Enroll);
