import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import PersonInfoCardHead from './PersonInfoCardHead';
import PersonInfoCardRow from './PersonInfoCardRow';
import PersonInfoCardList from './PersonInfoCardList';
import './style.scss';

function PersonInfoCard({ children, className }) {
  return <div className={cx('person-info-card', className)}>{children}</div>;
}

PersonInfoCard.Head = PersonInfoCardHead;
PersonInfoCard.Row = PersonInfoCardRow;
PersonInfoCard.List = PersonInfoCardList;

PersonInfoCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default PersonInfoCard;
