import React from 'react';
import PropTypes from 'prop-types';
import generateUuid from 'uuid/v4';
import { TextInput, ButtonV2 } from 'components';
import './style.scss';

function ListInput({ options, onChange, placeholder }) {
  const handleAdd = () => {
    return onChange([
      ...options,
      {
        id: generateUuid(),
        name: ''
      }
    ]);
  };

  const handleRemove = optionId => {
    return onChange(options.filter(o => o.id !== optionId));
  };

  const handleChange = (optionId, value) => {
    return onChange(
      options.map(o => {
        if (o.id === optionId) {
          return {
            ...o,
            name: value
          };
        }

        return o;
      })
    );
  };

  return (
    <div className="list-input">
      {options.map(opt => (
        <div className="list-input__item" key={opt.id}>
          <TextInput
            className="list-input__item-input"
            value={opt.name}
            onChange={v => handleChange(opt.id, v)}
            placeholder={placeholder}
          />
          {options.length > 2 && (
            <ButtonV2
              icon
              iconName="delete"
              iconSize={14}
              className="list-input__delete"
              onClick={() => handleRemove(opt.id)}
            />
          )}
        </div>
      ))}
      <ButtonV2 tertiary className="list-input__add" onClick={handleAdd} label="Add Option" />
    </div>
  );
}

ListInput.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ListInput;
