import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon, TooltipTrigger } from 'components';
import './style.scss';

const Tooltip = ({ text }) => <div className="status-icon__tooltip">{text}</div>;

const StatusIcon = ({ name, size = 16, children = '', className }) => {
  const iconCN = classNames('status-icon', className, {
    [`status-icon--${name}`]: !!name
  });
  const icon = name === 'cancel' ? 'close-circle' : name;

  if (children) {
    return (
      <TooltipTrigger tooltip={<Tooltip text={children} />} side="center" white>
        <Icon className={iconCN} name={icon} size={size} />
      </TooltipTrigger>
    );
  }

  return <Icon className={iconCN} name={icon} size={size} />;
};

StatusIcon.propTypes = {
  children: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string
};

export default StatusIcon;
