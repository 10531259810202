import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ButtonV2, PayerCheckbox } from 'components';
import get from 'lodash/get';
import '../ActivateSchool/style.scss';

class PayByConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parent_payment_fee_paid_by: props.currentSchool.parent_payment_fee_paid_by || 'parent_payment_fee_paid_by_parent',
      byParentDisabled: props.currentSchool.parent_payment_fee_paid_by === 'parent_payment_fee_paid_by_school',
      isSaving: false
    };
  }

  componentDidMount() {
    req.schoolFeeBreakdown();
  }

  @bind
  onChange(parent_payment_fee_paid_by) {
    this.setState({ parent_payment_fee_paid_by });
  }

  @bind
  onSubmit() {
    const { parent_payment_fee_paid_by } = this.state;

    this.setState({ isSaving: true });
    req
      .updateSchool({
        school: {
          parent_payment_fee_paid_by
        }
      })
      .then(() => req.profile())
      .then(() => {
        this.setState({ isSaving: false });
        this.props.onClose(true);
      })
      .catch(err => {
        this.setState({ isSaving: false });
        Actions.reportError('Something went wrong', err);
      });
  }

  getSchoolDesc() {
    const { feeBreakdown } = this.props;

    const ach = get(feeBreakdown, 'stripe.paid_by_school.ach', '$0.50'),
      cc = get(feeBreakdown, 'stripe.paid_by_school.cc', '2.95% + $0.50');

    return (
      <React.Fragment>
        <div className="fee-payer__checkbox-desc__block">
          <span className="fee-payer__checkbox-desc__label">ACH/Bank</span> : {ach}
        </div>
        <div className="fee-payer__checkbox-desc__block">
          <span className="fee-payer__checkbox-desc__label">Credit Card</span> : {cc}
        </div>
        <span className="fee-payer__checkbox-desc__text">No fees paid by parent</span>
      </React.Fragment>
    );
  }

  getParentDesc() {
    const { feeBreakdown } = this.props;

    const ach = get(feeBreakdown, 'stripe.paid_by_parent.ach', '$0.95'),
      cc = get(feeBreakdown, 'stripe.paid_by_parent.cc', '2.95% + $0.95');

    return (
      <React.Fragment>
        <div className="fee-payer__checkbox-desc__block">
          <span className="fee-payer__checkbox-desc__label">ACH/Bank</span> : {ach}
        </div>
        <div className="fee-payer__checkbox-desc__block">
          <span className="fee-payer__checkbox-desc__label">Credit Card</span> : {cc}
        </div>
        <span className="fee-payer__checkbox-desc__text">No fees paid by school</span>
      </React.Fragment>
    );
  }

  render() {
    const { parent_payment_fee_paid_by, byParentDisabled, isSaving } = this.state;

    return (
      <div className="school-activate">
        <div className="modal__wrapper">
          <div className="modal__header modal__header--bordered">
            <span className="modal__header-title">Confirm Fee Payer</span>
          </div>
          <div className="modal__container fee-payer__form">
            <div className="fee-payer__legend">School or Parent</div>
            <div className="fee-payer__desc">Choose who will pay for online transaction fee.</div>

            <div className="form__row">
              <PayerCheckbox
                type="circle"
                className="fee-payer__checkbox"
                name="parent"
                icon="parent-connection"
                label="parent pays"
                description={this.getParentDesc()}
                onChange={() => this.onChange('parent_payment_fee_paid_by_parent')}
                checked={parent_payment_fee_paid_by === 'parent_payment_fee_paid_by_parent'}
                disabled={byParentDisabled}
                blur={byParentDisabled}
              />

              <PayerCheckbox
                type="circle"
                className="fee-payer__checkbox"
                name="school"
                icon="school"
                label="school pays"
                description={this.getSchoolDesc()}
                onChange={() => this.onChange('parent_payment_fee_paid_by_school')}
                checked={parent_payment_fee_paid_by === 'parent_payment_fee_paid_by_school'}
              />
            </div>

            <div className="school-activate__warning">
              <div className="school-activate__warning-text">
                <span className="school-activate__warning-small">
                  Payout timeline for ACH/Bank: 2 business days, Credit Card: 1 business day.
                  <br />
                  If you have any questions, please call&nbsp;
                  <span className="school-activate__warning-strong">(877) 679-0950.</span>
                </span>
              </div>
            </div>

            <div className="form__row form__row--actions">
              <ButtonV2
                label="Confirm"
                isLoading={isSaving}
                disabled={!parent_payment_fee_paid_by}
                onClick={this.onSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentSchool: state.currentUser.data.current_school,
  feeBreakdown: state.feeBreakdown.data || {}
});

export default connect(mapStateToProps)(PayByConfirm);
