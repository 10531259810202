import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ActionButton from 'components/ActionButton';
import Icon from 'components/Icon';
import { listenClick } from 'lib/utils';
import './style.scss';
import Portal from 'components/Portal';
import withPortalPosition from 'hocs/withPortalPosition';
import withIsVisible from 'hocs/withIsVisible';
import { compose } from 'redux';
import nextId from 'react-id-generator';
import { DropdownContext } from 'hocs/withContext';

class DropdownPortal extends Component {
  static propTypes = {
    title: PropTypes.any,
    className: PropTypes.string,
    iconSize: PropTypes.number,
    onClear: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onClick: PropTypes.func,
    tabIndex: PropTypes.number,
    disableDropdown: PropTypes.bool,
    'data-cy': PropTypes.string
  };

  static defaultProps = {
    iconSize: 14
  };

  static currentlyOpenDropdown = null;

  // Used to position the portal and detect outside clicks
  containerRef = React.createRef();

  constructor() {
    super();

    this.state = {
      isOpen: false
    };

    this.listenClick = this.listenClick.bind(this);

    // Used to traverse DOM from portal to container
    this.id = nextId();
  }

  // Add or remove listener that closes the dropdown on click
  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isOpen && this.state.isOpen) {
      setTimeout(() => document.addEventListener('click', this.listenClick), 250);
    }

    if (prevState.isOpen && !this.state.isOpen) {
      document.removeEventListener('click', this.listenClick);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.listenClick);
  }

  getDropdownContext() {
    return {
      toggle: this.toggle
    };
  }

  listenClick(e) {
    if (this.containerRef.current) {
      listenClick(e, this.containerRef.current, () => this.toggle(false));
    }
  }

  handleClick = e => {
    if (this.props.disabled) {
      return;
    }

    const { onClick } = this.props;
    const { focus, isOpen } = this.state;

    if (onClick) {
      onClick(e);
    }

    if (focus) {
      this.setState({ focus: false });
    } else {
      this.toggle(!isOpen);
    }
  };

  handleFocus = () => {
    if (this.props.tabIndex === undefined || this.props.disabled) {
      return;
    }

    const { focus } = this.state;

    if (!focus) {
      this.setState({ focus: true });
      this.toggle(true);
    }
  };

  handleBlur = e => {
    if (this.props.tabIndex === undefined || e.relatedTarget === null) {
      return;
    }

    const { focus } = this.state;

    if (focus) {
      this.setState({ focus: false });
      this.toggle(false);
    }
  };

  toggle = isOpen => {
    const nextIsOpen = typeof isOpen === 'boolean' ? isOpen : !this.state.isOpen;

    if (nextIsOpen && DropdownPortal.currentlyOpenDropdown && DropdownPortal.currentlyOpenDropdown !== this) {
      DropdownPortal.currentlyOpenDropdown.toggle(false);
    }

    DropdownPortal.currentlyOpenDropdown = nextIsOpen ? this : null;

    this.props.setGetNextPortalPosition(nextIsOpen ? this.setPortalPosition : null);

    this.setState({ isOpen: nextIsOpen }, () => {
      if (this.state.isOpen === false && this.props.onClose) {
        this.props.onClose();
      }

      if (this.state.isOpen && this.props.onOpen) {
        this.props.onOpen();
      }
    });
  };

  setPortalPosition = () => {
    if (!this.containerRef.current) {
      return;
    }

    const rect = this.containerRef.current.getBoundingClientRect();
    const position = {};

    position.left = rect.left;
    position.top = rect.top + rect.height + window.scrollY;
    position.width = rect.width;

    if (!this.props.isVisible) {
      position.display = 'none';
    }

    return position;
  };

  render() {
    const {
      title,
      className,
      portalClassName,
      disabled,
      children,
      onClear,
      tabIndex,
      iconSize,
      portalPosition,
      innerRef,
      forwardedRef,
      disableDropdown,
      'data-cy': dataCY
    } = this.props;

    const dropdownCN = classNames({
      'dropdown-portal': true,
      'dropdown-portal--open': this.state.isOpen,
      'dropdown-portal--disabled': disabled,
      [className]: Boolean(className)
    });

    const portalCN = classNames({
      'dropdown-portal-body': true,
      'dropdown-portal-body--open': this.state.isOpen,
      [portalClassName]: Boolean(portalClassName)
    });

    const clearButtonCN = classNames('dropdown-portal__header-clear', {
      'dropdown-portal__header-clear--hidden': !onClear
    });

    const containerCN = classNames('dropdown-portal__header', {
      'dropdown-portal__header--disabled-dropdown': disableDropdown
    });

    return (
      <DropdownContext.Provider value={this.getDropdownContext()}>
        <div
          id={this.id}
          className={dropdownCN}
          ref={el => {
            this.containerRef.current = el;
            innerRef.current = el; // withIsVisible
            forwardedRef.current = el;
          }}
          data-cy={dataCY}
        >
          <div
            className={containerCN}
            tabIndex={tabIndex}
            onClick={this.handleClick}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          >
            <div className="dropdown-portal__header-title">{title}</div>

            <Icon className="dropdown-portal__header-arrow" name="chevron-down" size={iconSize} />
          </div>

          <ActionButton className={clearButtonCN} iconName="clear-circle" size={iconSize} onClick={onClear} />

          <Portal>
            <div className={portalCN} style={{ ...portalPosition }} data-portal-for={this.id}>
              {children}
            </div>
          </Portal>
        </div>
      </DropdownContext.Provider>
    );
  }
}

const enhance = compose(withPortalPosition, withIsVisible);
const EnhancedDropdownPortal = enhance(DropdownPortal);

export default forwardRef((props, ref) => <EnhancedDropdownPortal {...props} forwardedRef={ref} />);
