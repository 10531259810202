import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import Addon from './Addon';

class AddonList extends Component {
  static propTypes = {
    addons: PropTypes.array,
    onAdd: PropTypes.func,
    onUpdate: PropTypes.func,
    onRemove: PropTypes.func
  };

  static defaultProps = {
    addons: []
  };

  @bind
  addAddon() {
    if (this.props.onAdd) {
      this.props.onAdd();
    }
  }

  @bind
  updateAddon(id, nextValue) {
    if (this.props.onUpdate) {
      this.props.onUpdate(id, nextValue);
    }
  }

  @bind
  removeAddon(id) {
    const { addons, onRemove } = this.props;

    if (addons.length > 1 && onRemove !== undefined) {
      onRemove(id);
    }
  }

  renderAddons() {
    const { addons } = this.props;

    return addons
      .filter(a => !a._destroy)
      .map((a, index) => (
        <Addon
          key={a.id}
          addon={a}
          isLast={addons.length - 1 === index}
          onAdd={this.addAddon}
          onUpdate={this.updateAddon}
          onRemove={this.removeAddon}
        />
      ));
  }

  render() {
    return (
      <div className="table addon-list">
        <div className="table__header">
          <div className="table__cell table__cell--l">Add-on name</div>

          <div className="table__cell table__cell--s">Price</div>

          <div className="table__cell table__cell--xs">Required</div>

          <div className="table__cell table__cell--xxs table__cell--actions" />
        </div>

        <div className="table__body">
          <Scrollbars autoHeight autoHeightMin={300}>
            {this.renderAddons()}
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default AddonList;
