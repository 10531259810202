import React, { Component } from 'react';
import moment from 'moment';
import { Form, ButtonV2 } from 'components';
import { buildURL } from 'lib/history';
import './style.scss';

class PrintQrCode extends Component {
  constructor() {
    super();
    this.state = {
      date: moment()
        .add(3, 'days')
        .toDate()
    };
  }

  @bind
  submit(values) {
    const { onClose } = this.props;
    const newDate = `${moment(values.date).format('YYYY-MM-DD')}T${values.time}`;

    if (moment(newDate, 'YYYY-MM-DDTh:mm A').isBefore(new Date())) {
      Actions.showFlash('Expiry date should be future date', 'alert');
      return;
    }

    window.open(buildURL('/qrcode', { expire: newDate }));
    onClose();
  }

  render() {
    const { date } = this.state;

    return (
      <div className="print-qrcode">
        <div className="modal__header">
          <span className="modal__header-title">Print QR Code</span>
        </div>

        <div className="modal__container">
          <Form validateOn="submit" onSubmit={this.submit}>
            <div className="form__row form__row--justified">
              <Form.DatePicker name="date" label="Expiry date" defaultValue={date} disablePast required />

              <Form.TimeInput
                name="time"
                label="Time"
                defaultValue={Helpers.formatTime(date)}
                placeholder="12:00"
                autoPeriod
              />
            </div>

            <div className="form__row form__row--actions">
              <ButtonV2 secondary label="Cancel" onClick={() => this.props.onClose()} />
              <Form.Submit data-cy="submit" label="Print" />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default PrintQrCode;
