import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Icon, LabelItem, SelectList } from 'components';
import './style.scss';

class SelectLabelsList extends Component {
  static propTypes = {
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        color: PropTypes.string
      })
    ),

    selectedLabelIds: PropTypes.arrayOf(PropTypes.string),

    onChange: PropTypes.func,

    onCreate: PropTypes.func // Must return a promise
  };

  static defaultProps = {
    labels: [],
    selectedLabelIds: []
  };

  static filterByTerm(items = [], propName, term) {
    if (!term) {
      return items;
    }

    term = term.toLowerCase().trim();
    return items.filter(i => i[propName].toLowerCase().indexOf(term) >= 0);
  }

  static buildLabelMap(labels) {
    const map = {};

    labels.forEach(t => {
      map[t.id] = t;
    });

    return map;
  }

  constructor(props) {
    super(props);

    this._labelsMap = SelectLabelsList.buildLabelMap(props.labels);
    this._filteredLabels = [];

    this.state = {
      search: '',
      selectedItemIndex: 0,
      loading: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.labels !== this.props.labels) {
      this._labelsMap = SelectLabelsList.buildLabelMap(this.props.labels);
    }

    if (this._filteredLabels.length === 0 && this.state.selectedItemIndex !== -1) {
      this.setState({ selectedItemIndex: -1 });
    }
  }

  @bind
  changeSearch(text) {
    this.setState({ search: text });
  }

  @bind
  addLabel(label) {
    if (this.props.selectedLabelIds.indexOf(label.id) !== -1) {
      return;
    }

    this.props.onChange([...this.props.selectedLabelIds, label.id]);
  }

  @bind
  deleteLabel(labelId) {
    this.props.onChange(this.props.selectedLabelIds.filter(id => id !== labelId));

    if (this.state.selectedItemIndex === -1) {
      this.state.selectedItemIndex = 0;
    }
  }

  renderListItem(t) {
    return (
      <div className="select-labels-list__list-item" key={t.id}>
        <span className="select-labels-list__list-item__color" style={{ backgroundColor: t.color }} />
        {t.name}
      </div>
    );
  }

  renderLabels() {
    const selectedLabelsMap = {};
    this.props.selectedLabelIds.forEach(id => {
      selectedLabelsMap[id] = true;
    });

    const labelItems = (this._filteredLabels = SelectLabelsList.filterByTerm(
      this.props.labels,
      'name',
      this.state.search
    ).filter(t => !selectedLabelsMap[t.id]));

    if (labelItems.length === 0) {
      return <div className="select-labels-list__no-labels-label">No labels found</div>;
    }

    return <SelectList items={labelItems} renderItem={this.renderListItem} onSelect={this.addLabel} />;
  }

  renderSelectedLabels() {
    return this.props.selectedLabelIds.map(id => (
      <LabelItem key={id} label={this._labelsMap[id]} onDelete={this.deleteLabel} />
    ));
  }

  render() {
    const { search } = this.state;
    const { selectedLabelIds } = this.props;
    const isSelected = selectedLabelIds.length !== 0;

    return (
      <div className="select-labels-list">
        <div className="select-labels-list__header">
          <div className="select-labels-list__search">
            <Icon size={18} name="search" className="select-labels-list__search-icon" />

            <TextInput
              type="text"
              value={search}
              className="select-labels-list__search-input"
              placeholder="Enter a Label"
              onChange={this.changeSearch}
            />
          </div>
        </div>

        <div className="select-labels-list__inner">
          {isSelected ? (
            <div className="select-labels-list__selected-labels">{this.renderSelectedLabels()}</div>
          ) : (
            <div className="select-labels-list__no-labels-label">No labels selected</div>
          )}

          <div className="select-labels-list__list">{this.renderLabels()}</div>
        </div>
      </div>
    );
  }
}

export default SelectLabelsList;
