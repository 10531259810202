import { useSelector } from 'react-redux';
import DatePicker from 'components/DatePicker';
import SelectGroup from 'components/SelectGroup';
import moment from 'moment';
import React, { useRef } from 'react';
import './style.scss';

// BE sends down "missing" or "due" statuses but expects only "incomplete" in payloads
const INCOMPLETE_STATUS_VALUES = ['missing', 'due', 'incomplete'];
const READONLY_STATUS_VALUES = [...INCOMPLETE_STATUS_VALUES, 'exempted'];
const mapStatusFromDisplayValue = display => (INCOMPLETE_STATUS_VALUES.includes(display) ? 'incomplete' : display);

function StudentVaccineDosesSelect({ vaccine, onChange }) {
  const allVaccines = useSelector(state => state.vaccines.data);
  const doseSchedules = allVaccines.find(v => v.id === vaccine.id).vaccine_doses;
  const originalDoseDateMap = useRef(Object.fromEntries(vaccine.vaccine_doses.map(dose => [dose.id, dose.date])))
    .current;

  const handleChange = (doseIndex, field, newValue) => {
    if (!onChange) {
      return;
    }

    const oldDose = vaccine.vaccine_doses[doseIndex];
    const newVaccine = { ...vaccine, vaccine_doses: vaccine.vaccine_doses.map(d => ({ ...d })) };
    newVaccine.vaccine_doses[doseIndex] = { ...oldDose, [field]: newValue, _changed: true };

    if (READONLY_STATUS_VALUES.includes(newVaccine.vaccine_doses[doseIndex].status)) {
      // Reset date field when the status is changed to one whose date editor is disabled
      const dose = newVaccine.vaccine_doses[doseIndex];
      dose.date = originalDoseDateMap[dose.id];
    } else {
      // Changes cascade to subsequent doses
      for (let i = doseIndex + 1; i < newVaccine.vaccine_doses.length; i++) {
        const dose = newVaccine.vaccine_doses[i];

        if (dose.status === 'completed') {
          continue;
        }

        const doseSchedule = doseSchedules.find(s => s.dose_number === dose.dose_number);
        const lastDate = newVaccine.vaccine_doses[i - 1].date;
        let newDate = dose.date;

        if (doseSchedule.due_in) {
          newDate = moment(lastDate)
            .add(doseSchedule.due_in, 'day')
            .format('YYYY-MM-DD');
        }

        if (doseSchedule.fixed_date) {
          newDate = moment(lastDate)
            .add(1, 'year')
            .format('YYYY-MM-DD');
        }

        if (newDate !== dose.date) {
          dose.date = newDate;
          dose.status = mapStatusFromDisplayValue(dose.status);
          dose._changed = true;
        }
      }
    }

    onChange(newVaccine);
  };

  return (
    <div className="student-vaccine-doses-select">
      <header>{vaccine.title}</header>
      <div className="student-vaccine-doses-select__table">
        {vaccine.vaccine_doses.map((dose, i) => (
          <div className="student-vaccine-doses-select__table-column" key={dose.id}>
            <div className="student-vaccine-doses-select__table-cell form-select">
              <label className="form__label" htmlFor="name">
                Dose #{dose.dose_number} Status
              </label>
              <SelectGroup
                className="form-select__field"
                checked={mapStatusFromDisplayValue(dose.status)}
                onChange={val => handleChange?.(i, 'status', val)}
                type="radio"
                tabIndex={0}
              >
                <SelectGroup.Item value="incomplete" label="Due" />
                <SelectGroup.Item value="scheduled" label="Scheduled" />
                <SelectGroup.Item value="completed" label="Completed" />
                <SelectGroup.Item value="exempted" label="Exempt" />
              </SelectGroup>
            </div>

            <div className="student-vaccine-doses-select__table-cell form-date">
              <label className="form__label" htmlFor="name">
                Date
              </label>
              <DatePicker
                value={moment(dose.date).toDate()}
                onChange={val => handleChange?.(i, 'date', moment(val).format('YYYY-MM-DD'))}
                className="date-range-editor__date"
                disabled={READONLY_STATUS_VALUES.includes(dose.status)}
                tabIndex={0}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StudentVaccineDosesSelect;
