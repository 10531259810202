export const registrationFormTypes = {
  lead: {
    type: 'lead',
    descriptions: {
      singular: 'Lead',
      plural: 'Leads',
      full: 'Leads'
    },
    getProfileUrl: profileId => `/lead-crm/leads/${profileId}`,
    mapIndexLabelToKeeper: {
      Student: 'enrollment',
      Parent: 'enrollment_carer'
    },
    mapIndexLabelToMapper: {
      Student: 'registration',
      Parent: 'registration_parent'
    }
  },
  student: {
    type: 'student',
    descriptions: {
      singular: 'Student',
      plural: 'Students',
      full: 'Active Students'
    },
    getProfileUrl: profileId => `/my-school/students/${profileId}`,
    mapIndexLabelToKeeper: {
      Student: 'kid',
      Parent: 'carer'
    },
    mapIndexLabelToMapper: {
      Student: 'kid',
      Parent: 'carer'
    }
  }
};
