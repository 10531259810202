import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { ActionButton, Preloader, Avatar, StudentList } from 'components';
import moment from 'moment';
import { getDuration, listenClick } from 'lib/utils';
import './EventPanel.scss';

function EventPanel({ rooms, event, anchor, onClose }) {
  const ref = useRef(null);
  const [detail, setDetail] = useState({ ...event });
  const [loaded, setLoaded] = useState(false);
  const { starts_at: startDate, ends_at: endDate, kind, title, all_day: isAllDay } = event;
  const rect = anchor.getBoundingClientRect();
  const innerWidth = window.innerWidth;
  const side = innerWidth - rect.x > 600 ? 'right' : 'left';

  const className = cx('weekly-calendar__panel', `weekly-calendar__panel--${side}`, `weekly-calendar__panel--${kind}`);
  const diff = moment(endDate).diff(startDate, 'minute');
  const hours = diff / 60;
  let duration = getDuration(hours).toLowerCase();

  const style = { top: rect.top + rect.height / 2 };

  if (side === 'left') {
    style.left = rect.x - 14 - 328;
  } else {
    style.left = rect.x + rect.width + 14;
  }

  const handleDelete = () => {
    Actions.showModal('Confirmation', {
      title: 'Delete Event',
      description: 'Are you sure you want to delete this event?'
    }).then(result => {
      if (!result) {
        return;
      }

      return req
        .deleteSchoolEvent({ id: event.id }, { id: event.id })
        .then(() => {
          Actions.showFlash('Event has been deleted');
          onClose();
        })
        .catch(e => {
          Actions.reportError('There was problem deleting event', e);
        });
    });
  };

  const handleEdit = () => {
    Actions.showModal('EditCalendarEvent', detail, { noCloseIcon: true });
  };

  useEffect(() => {
    const load = async () => {
      setLoaded(false);
      setDetail(event);
      try {
        const resp = await req.loadSchoolEventItem({ id: event.id });
        setDetail(resp);
        setLoaded(true);
      } catch (e) {
        // do nothing
      }
    };

    load();
  }, [event]);

  useEffect(() => {
    const clickListener = e => {
      if (ref.current) {
        listenClick(e, ref.current, () => {
          if (
            !e.target.classList.contains('weekly-calendar__event') &&
            !e.target.classList.contains('weekly-calendar__alldays-event')
          ) {
            onClose();
          }
        });
      }
    };

    document.addEventListener('click', clickListener);

    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, []);

  const attendees = detail.kids || detail.registrations || [];

  return (
    <div ref={ref} className={className} style={style}>
      <div className="weekly-calendar__panel-heading">
        <div className="weekly-calendar__panel-title">{title}</div>
        <div className="weekly-calendar__panel-actions">
          {loaded && <ActionButton onClick={handleEdit} iconName="edit" size={16} />}
          <ActionButton onClick={handleDelete} iconName="delete" size={16} />
        </div>
      </div>
      <div className="weekly-calendar__panel-date">
        <div>
          {moment(startDate).format('dddd, MMMM DD, YYYY')} {isAllDay && '(All Day)'}
        </div>
        {!isAllDay && (
          <div>
            {moment(startDate).format('h:mm A')} - {moment(endDate).format('h:mm A')} ({duration})
          </div>
        )}
        {detail.section_ids && (
          <div>
            {rooms
              .filter(r => detail.section_ids.includes(r.id))
              .map(r => r.name)
              .join(', ')}
          </div>
        )}
      </div>
      {!loaded && (
        <div className="weekly-calendar__panel-loader">
          <Preloader small />
        </div>
      )}
      {attendees.length > 0 && (
        <div className="weekly-calendar__panel-attendees">
          <StudentList students={attendees} avatarSize="xxs" maxNumber={5} />
        </div>
      )}
      <div className="weekly-calendar__panel-description">{detail.description}</div>
      {detail.teacher && (
        <div className="weekly-calendar__panel-teacher">
          <span className="weekly-calendar__panel-teacher-title">Organizer: </span>
          <Avatar
            type="avatar"
            size="xxs"
            name={detail.teacher.name}
            url={detail.teacher.profile_pic_url}
            status={detail.teacher.is_admin ? 'admin' : undefined}
          />
          <span className="weekly-calendar__panel-teacher-name">{detail.teacher.name}</span>
        </div>
      )}
    </div>
  );
}

EventPanel.propTypes = {
  anchor: PropTypes.object,
  event: PropTypes.object
};

const mapStateToProps = state => ({
  rooms: state.rooms.data
});

export default connect(mapStateToProps)(EventPanel);
