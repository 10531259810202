import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';

const FilterItemDatePicker = ({ name, ...otherProps }) => (
  <div className="form__row">
    <Form.DatePicker data-cy={name} name={name} {...otherProps} />
  </div>
);

FilterItemDatePicker.propTypes = {
  name: PropTypes.string.isRequired
};

export default FilterItemDatePicker;
