import React, { Component } from 'react';
import cx from 'classnames';
import { ActionButton } from 'components';
import { isIE } from 'lib/utils';

import './style.scss';

const SLIDE_TYPE_MODAL = 'slide';

class Modal extends Component {
  render() {
    const { children, visible, noCloseIcon, className, type, onClose } = this.props;

    const modalCN = cx('modal', className, {
      [`modal--${type}`]: Boolean(type),
      'modal--visible': visible,
      'modal--ie': isIE()
    });

    return (
      <div className={modalCN}>
        <div
          className={cx('modal__window', {
            [`modal__window--${type}`]: type
          })}
        >
          {children}

          {!noCloseIcon && (
            <ActionButton
              iconName="close"
              onClick={onClose}
              size={type === SLIDE_TYPE_MODAL ? 16 : 12}
              className="modal__close"
              data-cy="modal-close"
            />
          )}
        </div>
      </div>
    );
  }
}

export default Modal;
