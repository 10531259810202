export const FORBIDDEN_ROUTES = {
  free: [
    '/billing',
    '/sign-in-out',
    '/staff/time-card',
    '/staff/schedule',
    '/lead-crm',
    '/learning',
    '/reports',
    '/parent-connection',
    '/settings/marketplace',
    '/settings/details/school-attributes',
    '/staff/messages',
    '/calendar',
    '/registration'
  ],
  home: [
    '/staff/time-card',
    '/staff/schedule',
    '/lead-crm',
    '/learning',
    '/sign-in-out/ratio',
    '/settings/details/school-attributes',
    '/staff/messages',
    '/registration'
  ],
  // center: ['/lead-crm/tasks', '/lead-crm/communication'],
  center: [],
  center_crm: [],
  center_curriculum: [],
  pro: [],
  enterprise: []
};

export const FORBIDDEN_FEATURES = {
  free: ['billing', 'custom-attributes', 'staff-messaging', 'lead-crm', 'split-family', 'quick-actions'],
  home: [
    'custom-attributes',
    'activity-approval',
    'send-sms',
    'staff-messaging',
    'lead-crm',
    'split-family',
    'quick-actions'
  ],
  // center: ['lead-crm-premium'],
  center: [],
  center_crm: [],
  center_curriculum: [],
  pro: [],
  enterprise: []
};
