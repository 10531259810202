import React from 'react';
import PropTypes from 'prop-types';
import Intercom from 'react-intercom';

const IntercomComponent = ({ userId, email, name, school }) => {
  const company = school
    ? {
        id: school.id,
        name: school.name
      }
    : undefined;

  return <Intercom appID={process.env.INTERCOM_ID} user_id={userId} email={email} name={name} company={company} />;
};

IntercomComponent.propTypes = {
  userId: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string
};

export default IntercomComponent;
