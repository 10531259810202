import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PlainCheckbox from '../../Checkbox';
import withContext, { FormContext } from 'hocs/withContext';

class Radio extends Component {
  static propTypes = {
    beforeChange: PropTypes.func
  };

  static defaultProps = {};

  componentDidMount() {
    const initialValue = this.props.defaultValue || null;
    const validations = {};

    this.props.context.init(this.props.name, initialValue, validations);
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disabled !== this.props.disabled && this.props.disabled === true) {
      this.props.context.update(this.props.name, null);
    }
  }

  @bind
  handleChange(selectedValue) {
    const { name, beforeChange } = this.props;

    if (!beforeChange || beforeChange(selectedValue)) {
      this.props.context.update(name, selectedValue);
    }
  }

  render() {
    const { name, type, size, disabled, tabIndex, required, children } = this.props;
    const { values } = this.props.context;

    const renderOption = option => (
      <PlainCheckbox
        key={option.value}
        checked={values[name] === option.value}
        label={option.label}
        type={type}
        size={size}
        disabled={disabled}
        onChange={() => this.handleChange(option.value)}
        tabIndex={tabIndex}
        required={required}
        className={option.className}
      />
    );

    return children(renderOption);
  }
}

export default withContext(FormContext)(Radio);
