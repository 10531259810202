const initialState = {
  modals: []
};

const modals = (state = { ...initialState }, action) => {
  switch (action.type) {
    case 'MODAL_SHOW':
      return {
        modals: [
          ...state.modals,
          {
            component: '',
            data: null,
            options: {},
            isVisible: true,
            ...action.payload
          }
        ]
      };

    case 'MODAL_HIDE':
      return {
        modals: state.modals.filter(m => m.id !== action.payload)
      };

    case 'MODAL_RESET':
      return { ...initialState };

    case 'MODAL_TOGGLE':
      return {
        modals: state.modals.map(m => {
          if (m.id === action.payload) {
            return {
              ...m,
              isVisible: !m.isVisible
            };
          }
          return m;
        })
      };

    default:
      return state;
  }
};

export default modals;
