import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ActionButton, Preloader } from 'components';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Item from './Item';
import S3Uploader from 'lib/S3Uploader';
import { FILE_TYPES } from 'constants/fileTypes';
import CAPTCHA_ACTIONS from 'constants/captchaActions';
import './style.scss';

const MB = 1024 * 1024;
const MAX_FILE_SIZE_MB = 50;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * MB;
const MAX_FILE_COUNT = 10;

class UploadFile extends PureComponent {
  static propTypes = {
    value: PropTypes.array,
    maxFileSize: PropTypes.number,
    maxFileCount: PropTypes.number,
    acceptedExts: PropTypes.arrayOf(PropTypes.string),
    s3Key: PropTypes.string,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onBegin: PropTypes.func,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    btnTitle: PropTypes.string,
    publicSignature: PropTypes.bool,
    schoolId: PropTypes.string
  };

  static defaultProps = {
    value: [],
    onAdd: () => {
      return;
    },
    onDelete: () => {
      return;
    },
    onBegin: () => {
      return;
    },
    label: '',
    placeholder: '',
    btnTitle: 'Upload File',
    maxFileSize: MAX_FILE_SIZE,
    maxFileCount: MAX_FILE_COUNT,
    acceptedExts: FILE_TYPES,
    s3Key: 'documents',
    publicSignature: false
  };

  input = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      captchaToken: undefined
    };
  }

  get isRecaptchaNotLoaded() {
    // not allowing file to be uploaded until captcha is loaded for public signature
    return this.props.publicSignature && !this.props.googleReCaptchaProps.executeRecaptcha;
  }

  @bind
  handleUploadClick() {
    const { maxFileCount } = this.props;

    if (this.props.value.length >= maxFileCount) {
      Actions.showFlash(`Only ${maxFileCount} files can be added`, 'alert');
      return;
    }

    this.input.current.click();
  }

  getSignature() {
    const { publicSignature, schoolId } = this.props;
    return payload =>
      req[`awsSignature${publicSignature ? 'Public' : ''}`]({
        ...payload,
        school_id: publicSignature ? schoolId : undefined,
        recaptcha_token: this.state.captchaToken
      });
  }

  @bind
  async uploadFile(e) {
    const { files } = e.target;
    const { executeRecaptcha } = this.props.googleReCaptchaProps;

    if (files.length === 0) {
      return;
    }

    const reader = new FileReader();
    const file = files[0];

    const { maxFileSize, s3Key } = this.props;

    if (!file || file.size > maxFileSize) {
      Actions.showFlash(`File is too large. Max size is ${maxFileSize} MB.`, 'alert');
      return;
    }

    this.props.onBegin();

    if (this.props.publicSignature) {
      const captchaToken = await executeRecaptcha(CAPTCHA_ACTIONS.AWS_PUBLIC_SIGNATURE);
      this.setState({ captchaToken });
    }

    reader.onloadend = () =>
      this.setState({ loading: true }, () => {
        const s3Config = {
          key: s3Key,
          getSignature: this.getSignature()
        };

        S3Uploader.upload([file], s3Config)
          .then(this.handleSuccess)
          .catch(this.handleFailure);
      });

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  @bind
  handleSuccess(files) {
    const file = files[0];

    this.addFile({ name: file.name, file_url: file.url });
    this.setState({ loading: false });
  }

  @bind
  async handleFailure(err) {
    this.setState({ loading: false });

    if (err.response?.status === 403) {
      Actions.showFlash('Unable to upload files. Please try again or contact your center for assistance.', 'alert');

      // close window if recaptcha fails on file upload
      this.props.onRestart();
    } else {
      Actions.reportError('Unable to upload file', err);
    }
  }

  @bind
  addFile(file) {
    this.props.onAdd(file);
  }

  @bind
  deleteFile(id) {
    this.props.onDelete(id);
  }

  @bind
  async handleDelete(id) {
    const shouldBeDeleted = await Actions.showModal('Confirmation', {
      title: 'Delete File',
      description: 'Are you sure you want to delete this file?'
    });

    if (!shouldBeDeleted) {
      return;
    }

    this.deleteFile(id);
    Actions.showFlash('File has been deleted');
  }

  renderFiles(files = []) {
    return files.map((f, index) => {
      if (!f.id) f.id = index;
      return <Item key={f.name + String(index)} file={f} onDelete={this.handleDelete} />;
    });
  }

  @bind
  renderPlaceholder() {
    const { placeholder } = this.props;
    return placeholder ? <div className="file-upload-field__placeholder">{placeholder}</div> : null;
  }

  render() {
    const { loading } = this.state;
    const { value, label, btnTitle } = this.props;

    return (
      <div className="file-upload-field">
        {label && (
          <div className="file-upload-field__title">
            <label className="form__label">{label}</label>
          </div>
        )}

        <div className="file-upload-field__items">
          {value.length === 0 ? this.renderPlaceholder() : this.renderFiles(value)}
        </div>

        <div className="file-upload-field__add">
          <input
            ref={this.input}
            type="file"
            value=""
            accept={this.props.acceptedExts.join()}
            className="file-upload-field__input"
            onChange={this.uploadFile}
          />
          <ActionButton
            disabled={loading || this.isRecaptchaNotLoaded}
            className="file-upload-field__add-btn"
            iconName="attach-file"
            title={btnTitle}
            onClick={this.handleUploadClick}
          />
          {loading && <Preloader small className="file-upload-field__preloader" />}
        </div>
      </div>
    );
  }
}

export default withGoogleReCaptcha(UploadFile);
