import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Checkbox, SelectGroup } from 'components';

class StaffFilter extends PureComponent {
  static propTypes = {
    filters: PropTypes.shape({
      search: PropTypes.string,
      sectionIds: PropTypes.array,
      status: PropTypes.string,
      hideNoEmail: PropTypes.bool
    }),
    rooms: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func
  };

  handleUpdate(partialUpdateObj) {
    if (this.props.onChange) {
      this.props.onChange({ ...this.props.filters, ...partialUpdateObj });
    }
  }

  @bind
  updateSearch(search) {
    this.handleUpdate({ search });
  }

  @bind
  updateSectionIds(sectionIds) {
    this.handleUpdate({ sectionIds });
  }

  @bind
  updateStatus(status) {
    this.handleUpdate({ status });
  }

  @bind
  updateHideNoEmail(hideNoEmail) {
    this.handleUpdate({ hideNoEmail });
  }

  render() {
    const { rooms } = this.props;
    const { search, sectionIds, status, hideNoEmail } = this.props.filters;

    return (
      <div className="select-staff-step__filters">
        <div className="form--inline">
          <div className="w-1/2 input--search">
            <div className="form-input__field">
              <TextInput placeholder="Search" value={search} onChange={this.updateSearch} />
            </div>
          </div>

          <SelectGroup
            className="w-1/4"
            title="Select Room"
            type="checkbox"
            checked={sectionIds}
            onChange={this.updateSectionIds}
          >
            {rooms.map(r => (
              <SelectGroup.Item key={r.id} value={r.id} label={r.name} />
            ))}
          </SelectGroup>

          <SelectGroup
            className="w-1/4"
            title="Select Status"
            type="radio"
            checked={status}
            onChange={this.updateStatus}
          >
            <SelectGroup.Item value="" label="All Statuses" isDefault />
            <SelectGroup.Item value="assigned" label="Assigned" />
            <SelectGroup.Item value="clocked" label="Clocked In" />
          </SelectGroup>
        </div>
        <div className="select-staff-step__checkbox">
          <Checkbox
            type="square"
            name="hideNoEmail"
            checked={hideNoEmail}
            label="Hide staff who do not have an email listed"
            onChange={this.updateHideNoEmail}
          />
        </div>
      </div>
    );
  }
}

export default StaffFilter;
