// Reference: src/modals/SelectDocumentRecipients/index.js

import React, { useState } from 'react';
import { applyFilters } from 'lib/utils';
import { ButtonV2 } from 'components';
import SelectPersonList from 'modals/common/SelectPersonList';
import StudentFilter from './StudentFilter';

const AssignRoomStudentsModal = ({ data, onClose }) => {
  const [filters, setFilters] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const updateFilters = filters => {
    setFilters(filters);
  };

  const filteredKids = React.useMemo(() => {
    return applyFilters(data.students, filters);
  }, [data.students, filters]);

  const updateSelectedIds = selectedIds => {
    setSelectedIds(selectedIds);
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      if (typeof data.onSubmit === 'function') {
        await data.onSubmit(selectedIds);
      }

      if (typeof onClose === 'function') {
        onClose(selectedIds);
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="select-document-recipients__students">
      <div className="modal__header modal__header--bordered">
        <div className="modal__header-title">Select Students</div>

        <div className="select-document-recipients__students-filter">
          <StudentFilter filters={filters} onChange={updateFilters} />
        </div>
      </div>

      <div className="modal__container">
        <SelectPersonList
          persons={filteredKids}
          allPersons={filteredKids}
          selectedIds={selectedIds}
          onSelect={updateSelectedIds}
          listHeight={438}
          type="kid"
        />
      </div>

      <div className="modal__controls">
        <ButtonV2
          label="Add students"
          onClick={handleSubmit}
          isLoading={isSaving}
          disabled={!selectedIds.length}
          data-cy="continue"
        />
      </div>
    </div>
  );
};

export default AssignRoomStudentsModal;
