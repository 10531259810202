import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Avatar, ContextMenu, Icon, InlineEditor } from 'components';

function PersonInfoCardHead({ name, person, photo, onChange, children, menuProps, menuItems = [], hideAction }) {
  const isEmergencyContact = Boolean(person.emergency_contact);
  const hasMenu = menuItems.length > 0;

  return (
    <div className="person-info-card__head">
      <div
        className={cx('person-info-card__head-content', {
          'person-info-card__head-content--emergency': isEmergencyContact,
          'person-info-card__head-content--menu': hasMenu
        })}
      >
        <Avatar url={photo} type="avatar" />
        <div className="person-info-card__head-title">
          <div className="person-info-card__head-title__name">
            <div className="person-info-card__head-title__name-text">{name}</div>
            {!hideAction && <InlineEditor.Name onChange={onChange} defaultValue={person} />}
          </div>
          {children}
        </div>
      </div>
      <div className="person-info-card__head-action">
        {isEmergencyContact && <div className="person-info-card__head-emergency-contact" />}
        {hasMenu && (
          <ContextMenu {...menuProps}>
            {menuItems.map(({ icon, label, onClick }, index) => (
              <li key={`person-action_${index}`} onClick={onClick}>
                {icon && <Icon name={icon} />} {label}
              </li>
            ))}
          </ContextMenu>
        )}
      </div>
    </div>
  );
}

PersonInfoCardHead.propTypes = {
  name: PropTypes.string,
  photo: PropTypes.string,
  hideAction: PropTypes.bool,
  person: PropTypes.object,
  onChange: PropTypes.func,
  children: PropTypes.node,
  menuProps: PropTypes.object,
  menuItems: PropTypes.array
};

export default PersonInfoCardHead;
