import React, { Component } from 'react';
import Steps from 'modals/ModalController/Steps';
import AddPaymentStep from './AddPaymentStep';
import ConfirmStep from './ConfirmStep';
import './style.scss';

class SchoolRegistrationPayment extends Component {
  constructor() {
    super();
    this.state = {
      payment: null
    };
  }

  @bind
  onAddPayment(payment) {
    this.setState({ payment });
  }

  @bind
  onSubmit() {
    this.props.onClose(this.state.payment);
  }

  render() {
    const {
      onClose,
      data: { school, payment, kidCount, share }
    } = this.props;

    return (
      <div className="school-registration-payment">
        <Steps>
          <Steps.Item>
            <AddPaymentStep school={school} onClose={onClose} onAddPayment={this.onAddPayment} share={share} />
          </Steps.Item>

          <Steps.Item>
            <ConfirmStep price={payment.amount * kidCount} payment={this.state.payment} onSubmit={this.onSubmit} />
          </Steps.Item>
        </Steps>
      </div>
    );
  }
}

export default SchoolRegistrationPayment;
