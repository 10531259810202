import React, { useState, useEffect } from 'react';
import List from './List';
import { ButtonV2, Preloader } from 'components';
import './style.scss';

const MultiFamilyWarning = ({ data, onClose }) => {
  const [paymentsUnderProcess, setPaymentsUnderProcess] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const familyId = data.family_id;

    const multiFamilyPayload = {
      family_id: familyId,
      filters: {
        billing_transaction: { state: 'state_under_process' }
      }
    };

    req
      .familyTransactionsNoReducer(multiFamilyPayload)
      .then(res => {
        setPaymentsUnderProcess(res.billing_transactions);
      })
      .then(() => setIsLoading(false))
      .catch(error => {
        Actions.reportError('There was problem while refunding', error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="modal__wrapper multi-family-warning">
      <div className="modal__header">
        <span className="modal__header-title">
          <strong>Unable to Create Multi-Family Account</strong>
        </span>
      </div>
      {!isLoading && <List data={paymentsUnderProcess} />}
      {isLoading && <Preloader center />}
      <div className="modal__container">
        <div className="multi-family-warning__description">
          <p>This account has at least one payment in process and cannot be completed.</p>
          <p>Once the above transactions are processed, this account can be separated. </p>
        </div>
        <div className="form__row form__row--actions">
          <ButtonV2 label="Okay" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default MultiFamilyWarning;
