import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const MsgTypeIcon = ({ type = '' }) => {
  let imgSrc = '';

  switch (type) {
    case 'newsletter':
    case 'alert':
      imgSrc = `/assets/images/${type}.png`;
      break;
    default:
  }

  if (!imgSrc) {
    return null;
  }

  return <img className="msg-type-icon" src={imgSrc} alt={type} />;
};

MsgTypeIcon.propTypes = {
  type: PropTypes.string
};

export default MsgTypeIcon;
