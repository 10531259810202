import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form, ButtonV2 } from 'components';
import moment from 'moment';
import get from 'lodash/get';
import { isAdmin } from 'lib/utils/selectors';
import { calculateStaffOnly } from './calculateStaffOnly';
import ActivityForms from '../common/ActivityForms';
import buildActivityPayload from '../common/buildActivityPayload';
import EditActivityStudentList from '../CreateActivity/EditActivityStudentList';
import withLaunchDarkly from 'hocs/withLaunchDarkly';
import './style.scss';

class EditGroupedActivityForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      formValues: {},
      kidIds: props.flags['activities-feed']
        ? [...props.data.activity.activity_participants]
        : [...props.data.activity.kid_ids]
    };
  }

  @bind
  onChange(formValues) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        ...formValues
      }
    });
  }

  @bind
  onUpdateKids(kidIds) {
    this.setState({ kidIds });
  }

  @bind
  submit(values) {
    const { formValues, kidIds } = this.state;
    const {
      data: { activity, isFiltered },
      onClose,
      staffOnlyActivities,
      historyDate,
      isAdmin
    } = this.props;

    if (formValues.title === 'Nap Check' && !formValues.sleep_position) {
      Actions.showFlash('No sleep position selected', 'alert');
      return;
    }

    if (!kidIds.length) {
      Actions.showFlash('No students selected', 'alert');
      return;
    }

    const requestPayload = buildActivityPayload(activity.activity_type, {
      data: {
        ...formValues,
        ...values,
        staffOnly: calculateStaffOnly(isAdmin, staffOnlyActivities.includes(activity.activity_type), values.staffOnly)
      },
      activity: activity,
      isEditing: true
    });

    if (
      moment(activity.activity_time).isBefore(historyDate) &&
      moment(requestPayload.daily_activity.activity_time).isAfter(historyDate)
    ) {
      Actions.showFlash(`Activity time should be before ${moment(historyDate).format('YYYY-MM-DD')}`, 'alert');
      return;
    }

    if (
      moment(activity.activity_time).isAfter(historyDate) &&
      moment(requestPayload.daily_activity.activity_time).isBefore(historyDate)
    ) {
      Actions.showFlash(`Activity time should be after ${moment(historyDate).format('YYYY-MM-DD')}`, 'alert');
      return;
    }

    this.setState({ isLoading: true });

    // handle batch request payload
    requestPayload.id = activity.batch_id;
    requestPayload.daily_activity.kid_ids = kidIds;
    if (isFiltered) {
      requestPayload.kid_ids = activity.kid_ids;
    }

    return req
      .updateBatchDailyActivity(requestPayload, undefined, {
        checkHistory: activity.activity_time
      })
      .then(data => {
        this.setState({ isLoading: false });
        onClose(data.daily_activities[0]);
      })
      .catch(err => {
        this.setState({ isLoading: false });
        Actions.reportError('There was problem updating activity', err);
      });
  }

  render() {
    const { isLoading, formValues, kidIds } = this.state;
    const { data, constants, onClose, staffOnlyActivities, isAdmin, hideHeader, hideFooter } = this.props;
    const { activity, isFiltered, hideDelete } = data;
    const signatures = activity.activiable?.signatures;

    const EditForm = ActivityForms[activity.activity_type];
    const forceStaff = !isAdmin && (staffOnlyActivities.includes(activity.activity_type) || activity.is_staff_only);

    return (
      <div className="edit-activity">
        {!hideHeader && (
          <div className="modal__header">
            <span className="modal__header-title">Edit {data.title} Activity</span>
          </div>
        )}
        <Form
          className="edit-activity__form"
          onSubmit={this.submit}
          onChange={this.onChange}
          validateOn="submit"
          isLoading={isLoading}
        >
          <div className="edit-activity__form-container">
            <EditForm
              isEditing
              onChange={this.onChange}
              activity={activity}
              signatures={signatures}
              formValues={formValues}
              forceStaff={forceStaff}
              constants={constants}
            />
            <EditActivityStudentList
              value={kidIds}
              onChange={this.onUpdateKids}
              kids={data.kids}
              hideSelect={isFiltered}
              hideDelete={hideDelete}
            />
          </div>

          {!hideFooter && (
            <div className="form__row--actions">
              <ButtonV2 secondary onClick={() => onClose(null)} label="Cancel" />
              <Form.Submit label="Save" data-cy="save" />
            </div>
          )}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: isAdmin(state),
  staffOnlyActivities: state.currentUser.data.current_school.staff_only_activities,
  constants: state.constants.data.daily_activities,
  historyDate: get(state, 'constants.data.history_dates.daily_activities'),
  kids: state.students.data
});

const enhance = compose(withLaunchDarkly, connect(mapStateToProps));

export default enhance(EditGroupedActivityForm);
