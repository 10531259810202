import { Avatar, ButtonV2, Preloader, SelectGroup } from 'components';
import React, { useEffect, useState } from 'react';
import './style.scss';
import { useSelector } from 'react-redux';

const SelectRoomRegistration = ({ data, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRoomId, setSelectedRoomId] = useState();
  const rooms = useSelector(state => state.rooms.data);
  const { studentName } = data;

  useEffect(() => {
    const fetchData = async () => {
      await req.rooms({
        filters: {
          section: {
            status: 'active'
          }
        }
      });
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div className="modal__wrapper select-room-registration">
      <div className="modal__header">
        <div className="modal__header-title">Add Student from Registration</div>
      </div>
      <div className="modal__container">
        {isLoading ? (
          <div className="select-room-registration__loading">
            <Preloader large center />
          </div>
        ) : (
          <>
            <div className="form__row select-room-registration__subheader">
              <Avatar name={studentName} />
              {studentName}
            </div>
            <div className="form__row select-room-registration__body">
              The information on the student registration form will be used to create an active student profile. Do you
              want to add this student and approve their registration form?
            </div>
            <div className="form__row">
              <div className="form-select">
                <label className="form__label" htmlFor="type">
                  Room
                </label>
                <SelectGroup title="Select room" type="radio" checked={selectedRoomId} onChange={setSelectedRoomId}>
                  {rooms.map(r => (
                    <SelectGroup.Item key={r.id} value={r.id} label={r.name} />
                  ))}
                </SelectGroup>
              </div>
            </div>
            <div className="form__row form__row--actions">
              <ButtonV2 secondary label="Back" onClick={() => onClose(false)} />
              <ButtonV2 label="Yes, Add Student" onClick={() => onClose(selectedRoomId)} disabled={!selectedRoomId} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SelectRoomRegistration;
