import React from 'react';
import PropTypes from 'prop-types';
import { ActionButton } from 'components';
import currency from 'lib/currency';

const ListItem = ({
  index = 1,
  item = {
    start_time: null,
    end_time: null,
    days: [],
    section_ids: [],
    rate: undefined,
    rate_type: '',
    late_fee: undefined,
    late_fee_time: ''
  },
  onEdit,
  onRemove
}) => (
  <div className="attendance-items__item">
    <div className="row row--wide">
      <span className="attendance-items__item-title">{item.desc ? item.desc : `Charge-${index}`}</span>

      <div className="attendance-items__actions">
        <ActionButton size={14} iconName="edit" onClick={onEdit} />
        <ActionButton size={14} iconName="delete" onClick={onRemove} />
      </div>
    </div>

    <div className="attendance-items__item-description">
      Charges start at {item.start_time} & ends at {item.end_time} for {item.days.length} days in{' '}
      {item.section_ids.length} rooms. Charge rate: {currency.getPrice(item.rate)} / {item.rate_type}.
    </div>
  </div>
);

ListItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number
};

export default ListItem;
