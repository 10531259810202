import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import pick from 'lodash/pick';
import { Form } from 'components';
import { plural } from 'lib/utils';
import './style.scss';

class AddLedgerExpense extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      id: null,
      parent_invoice: null,
      kid_ids: [],
      isSaving: false,
      isEditing: !!props.data.transaction
    };

    if (props.data.transaction) {
      const { transactable } = props.data.transaction;
      this.state.id = transactable.id;
      this.state.parent_invoice = pick(transactable, [
        'amount',
        'description',
        'notes',
        'invoice_start_date',
        'invoice_end_date'
      ]);
      this.state.kid_ids = [...transactable.kid_ids];
    }

    if (props.data.students) {
      this.state.kid_ids = (props.data.students || []).map(student => student.id);
    }
  }

  @bind
  async handleSelectStudents() {
    const { kid_ids } = this.state;

    try {
      const result = await Actions.showModal('SelectLedgerStudents', { kid_ids });
      if (result) {
        this.setState({ kid_ids: result });
      }
    } catch (e) {
      // do nothing
    }
  }

  @bind
  async handleSubmit(parent_invoice) {
    const { id, isEditing, kid_ids } = this.state;
    const {
      data: { ledger },
      onClose
    } = this.props;

    try {
      this.setState({ isSaving: true, kid_ids });
      if (isEditing) {
        await req.updateLedgerPayment({
          id,
          parent_invoice: {
            ...parent_invoice,
            price: +parent_invoice.price,
            kid_ids
          }
        });
        Actions.showFlash('Updated expense successfully');
      } else {
        await req.addLedgerPayment({
          ledger_id: ledger.id,
          parent_invoice: {
            ...parent_invoice,
            price: +parent_invoice.price,
            invoice_start_date: parent_invoice.invoice_start_date || undefined,
            invoice_end_date: parent_invoice.invoice_end_date || undefined,
            kid_ids
          }
        });
        Actions.showFlash('Added expense successfully');
      }
      this.setState({ isSaving: false });

      onClose(true);
    } catch (e) {
      this.setState({ isSaving: false });
      Actions.reportError('Something went wrong', e);
    }
  }

  render() {
    const {
      data: { ledger }
    } = this.props;
    const { parent_invoice, kid_ids, isSaving, isEditing } = this.state;
    const title = isEditing ? 'Edit Expense' : 'Add Expense';
    const defaultValues = parent_invoice || {};

    return (
      <Form
        className="modal__wrapper add-ledger-expense"
        onSubmit={this.handleSubmit}
        validateOn="submit"
        isLoading={isSaving}
      >
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">{title}</div>
          <div className="modal__header-subtitle">
            For <b>{ledger.name}</b>
          </div>
        </div>

        <div className="modal__container">
          <div className="form__row">
            <div className="add-ledger-expense__amount">
              <Form.Input
                label="Amount"
                placeholder="Enter Amount eg. $100"
                name="price"
                type="amount"
                data-cy="price"
                defaultValue={defaultValues.amount}
                amount
                required
              />
            </div>
            <div className="add-ledger-expense__dates">
              <label className="form__label">Date of Service (optional)</label>
              <div className="add-ledger-expense__dates-body">
                <Form.DatePicker
                  name="invoice_start_date"
                  defaultValue={
                    defaultValues.invoice_start_date ? moment(defaultValues.invoice_start_date).toDate() : undefined
                  }
                />
                <div className="to">to</div>
                <Form.DatePicker
                  name="invoice_end_date"
                  defaultValue={
                    defaultValues.invoice_end_date ? moment(defaultValues.invoice_end_date).toDate() : undefined
                  }
                />
              </div>
            </div>
          </div>

          <div className="form__row">
            <Form.Textarea
              label="Description"
              placeholder="Add description"
              name="description"
              data-cy="description"
              defaultValue={defaultValues.description}
              required
            />
          </div>

          <a data-cy="assign-students" className="add-ledger-expense__kids" onClick={this.handleSelectStudents}>
            {!kid_ids.length ? 'Optionally Assign Students' : `${plural(kid_ids.length, 'Student')} Assigned`}
          </a>

          <Form.Input
            placeholder="Optional internal note that won't show in reports"
            name="notes"
            className="form-input--highlight"
            defaultValue={defaultValues.notes}
          />

          <div className="form__row form__row--actions">
            <Form.Submit label="Save" data-cy="submit" />
          </div>
        </div>
      </Form>
    );
  }
}

export default AddLedgerExpense;
