import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Form, ButtonV2, CorporateIcon } from 'components';
import './style.scss';

const RoomAgeSelect = ({ defaultValue, name, type, className /* either "yr" or "mo" */ }) => (
  <div
    className={classNames(className, {
      'room-age-select': true
    })}
  >
    <Form.Input
      placeholder="0"
      defaultValue={defaultValue}
      type="number"
      name={name}
      className="room-age-select__input"
      min={0}
      max={11}
    />

    <div className="room-age-select__label">{type === 'yr' ? 'yr(s)' : 'mo(s)'}</div>
  </div>
);

const CorpFormLabel = ({ children }) => (
  <div className="form__label">
    {children} <CorporateIcon className="-top-1" />
  </div>
);

const CorpInputValue = ({ children }) => (
  <div className="form-input__helper-text edit-room__corp-value">{children}</div>
);

const CorpAgesPair = ({ data }) => {
  return (
    <div className="form-input__helper-text edit-room__corp-value edit-room__corp-room-ages">
      {[data[0], 'yr(s)', data[1], 'mo(s)'].join(' ')}
    </div>
  );
};

class EditRoom extends Component {
  static propTypes = {
    data: PropTypes.shape({
      room: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        capacity: PropTypes.number,
        lower_range: PropTypes.string,
        upper_range: PropTypes.string
      })
    })
  };

  static defaultProps = {
    data: {}
  };

  static rangeToNum(yr = 0, mo = 0) {
    yr = Number(yr);
    mo = Number(mo);

    return yr + mo / 12;
  }

  static numToRange(num = 0) {
    num = Number(num);

    const yr = Math.floor(num);
    const mo = Math.round((num - yr) * 12);

    return [yr, mo];
  }

  static getDefaultRange(num) {
    if (!Number.isFinite(num)) {
      return [0, 0];
    }

    return this.numToRange(num);
  }

  constructor(...args) {
    super(...args);

    this.state = {
      isSaving: false
    };
  }

  @bind
  async submit({ lowerAgeYr, lowerAgeMo, upperAgeYr, upperAgeMo, name, capacity }) {
    const lowerAgeNum = EditRoom.rangeToNum(lowerAgeYr, lowerAgeMo);
    const upperAgeNum = EditRoom.rangeToNum(upperAgeYr, upperAgeMo);

    if (lowerAgeNum > upperAgeNum && upperAgeNum !== 0) {
      Actions.showFlash('Lower limit should be less than upper limit', 'alert');
      return;
    }

    const payload = {
      id: this.props.data.room.id,

      section: {
        ...this.props.data.room,
        name,
        capacity: capacity ? parseInt(capacity, 10) : null,
        lower_age: lowerAgeNum,
        upper_age: upperAgeNum
      }
    };

    try {
      this.setState({ isSaving: true });
      await req.updateRoom(payload);
      Actions.showFlash('Room updated successfully');
      this.setState({ isSaving: false });
      this.props.onClose();
    } catch (e) {
      Actions.reportError('Unable to update room', e);
      this.setState({ isSaving: false });
    }
  }

  render() {
    const { onClose } = this.props;
    const { room } = this.props.data;
    const { isSaving } = this.state;

    const lowerRange = EditRoom.getDefaultRange(room.lower_age);
    const upperRange = EditRoom.getDefaultRange(room.upper_age);

    return (
      <div className="edit-room">
        <div className="modal__header">
          <div className="modal__header-title">Edit Room</div>
        </div>

        <div className="modal__container edit-room__content">
          <Form onSubmit={this.submit} isLoading={isSaving}>
            <div className="form__row">
              {room.group_entity && (
                <div>
                  <CorpFormLabel>Room Name</CorpFormLabel>
                  <CorpInputValue>{room.name}</CorpInputValue>
                </div>
              )}
              <Form.Input
                name="name"
                placeholder="Room Name"
                label="Room Name"
                className={classNames({
                  'rooms__add-room-name': true,
                  'visually-hidden': room.group_entity
                })}
                required
                defaultValue={room.name}
              />
            </div>
            <div className="form__row">
              <Form.Input
                name="capacity"
                label="Room Capacity"
                placeholder="Room Capacity"
                type="number"
                naturalNumber
                helperText="Max # of students allowed"
                defaultValue={room.capacity || ''}
              />
            </div>

            <div className="form__row">
              {room.group_entity ? (
                <CorpFormLabel>Age Range</CorpFormLabel>
              ) : (
                <div className="form__label">Age Range</div>
              )}
            </div>

            <div className="edit-room__row">
              <div className="edit-room__label">Lower Age</div>

              {room.group_entity && <CorpAgesPair data={lowerRange} />}

              <div
                className={classNames({
                  'edit-room__values': true,
                  'visually-hidden': room.group_entity
                })}
              >
                <RoomAgeSelect type="yr" name="lowerAgeYr" defaultValue={lowerRange[0]} />
                <RoomAgeSelect type="mo" name="lowerAgeMo" defaultValue={lowerRange[1]} />
              </div>
            </div>

            <div
              className={classNames({
                'edit-room__row': true,
                'edit-room__row--top-margin': room.group_entity
              })}
            >
              <div className="edit-room__label">Upper Age</div>

              {room.group_entity && <CorpAgesPair data={upperRange} />}

              <div
                className={classNames({
                  'edit-room__values': true,
                  'visually-hidden': room.group_entity
                })}
              >
                <RoomAgeSelect type="yr" name="upperAgeYr" defaultValue={upperRange[0]} />
                <RoomAgeSelect type="mo" name="upperAgeMo" defaultValue={upperRange[1]} />
              </div>
            </div>

            <div className="modal__controls">
              <ButtonV2 label="Cancel" secondary onClick={() => onClose(null)} />
              <Form.Submit label="Apply" data-cy="submit" />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default EditRoom;
