import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextInput, SelectGroup } from 'components';

class StudentFilter extends PureComponent {
  static propTypes = {
    filters: PropTypes.shape({
      search: PropTypes.string,
      roomId: PropTypes.string,
      tagIds: PropTypes.arrayOf(PropTypes.string)
    }),
    rooms: PropTypes.arrayOf(PropTypes.object),
    tags: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func
  };

  handleUpdate(partialUpdateObj) {
    if (this.props.onChange) {
      this.props.onChange({ ...this.props.filters, ...partialUpdateObj });
    }
  }

  @bind
  updateSearch(search) {
    this.handleUpdate({ search });
  }

  @bind
  updateRoomId(roomId) {
    this.handleUpdate({ roomId });
  }

  @bind
  updateTagIds(tagIds) {
    this.handleUpdate({ tagIds });
  }

  render() {
    const { rooms, tags } = this.props;
    const { search, roomId, tagIds = [] } = this.props.filters;

    return (
      <div className="form--inline">
        <div className="students__filter-bar-search">
          <TextInput placeholder="Search Student Name" value={search} onChange={this.updateSearch} />
        </div>

        {rooms && (
          <SelectGroup
            title="Select Room"
            type="radio"
            className="students__filter-bar-select"
            checked={roomId}
            onChange={this.updateRoomId}
          >
            <SelectGroup.Item value="" label="All Rooms" isDefault />
            {rooms.map(r => (
              <SelectGroup.Item key={r.id} value={r.id} label={r.name} />
            ))}
          </SelectGroup>
        )}

        {tags && (
          <SelectGroup
            type="checkbox"
            title="Select Tags"
            className="students__filter-bar-select"
            multipleSelectionText="tags applied"
            checked={tagIds}
            onChange={this.updateTagIds}
          >
            {tags.map(t => (
              <SelectGroup.Item key={t.id} value={t.id} label={t.name} />
            ))}
          </SelectGroup>
        )}
      </div>
    );
  }
}

export default StudentFilter;
