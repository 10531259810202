import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';
import TaskForm from './TaskForm';
import './style.scss';

function CreateCrmTask({ data, onClose }) {
  const [saving, setSaving] = useState(false);

  const handleSubmit = async values => {
    setSaving(true);
    const id = get(data, 'task.id');

    try {
      let response;
      if (id) {
        response = await req.updateCrmTask({
          id,
          task: {
            ...values,
            due_date: values.due_date ? moment(values.due_date).format('YYYY-MM-DD') : undefined
          }
        });
      } else {
        response = await req.createCrmTask({
          task: {
            ...values,
            due_date: values.due_date ? moment(values.due_date).format('YYYY-MM-DD') : undefined,
            status: 'active'
          }
        });
      }
      setSaving(false);
      Actions.showFlash('Task saved');

      onClose(response);
    } catch (e) {
      Actions.reportError('There was problem saving task', e);
      setSaving(false);
    }
  };

  const task = get(data, 'task', {});
  const title = get(data, 'title', 'Create Task');
  const fromLead = get(data, 'fromLead', false);

  return (
    <div className="create-crm-task">
      <div className="modal__header">
        <span className="modal__header-title">{title}</span>
      </div>
      <div className="modal__container">
        <TaskForm onSubmit={handleSubmit} fromLead={fromLead} data={task} saving={saving} />
      </div>
    </div>
  );
}

CreateCrmTask.propTypes = {
  data: PropTypes.object
};

export default CreateCrmTask;
