import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';
import './style.scss';

class EditLine extends Component {
  static propTypes = {
    data: PropTypes.shape({
      title: PropTypes.string,
      name: PropTypes.string,
      text: PropTypes.string
    })
  };

  static defaultProps = {
    data: {}
  };

  @bind
  submit({ text }) {
    this.props.onClose(text);
  }

  render() {
    const { name, text } = this.props.data;
    const title = this.props.data.title || `Edit ${name || 'title'}`;

    return (
      <div className="edit-line">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">{title}</div>
        </div>

        <div className="modal__container">
          <Form onSubmit={this.submit}>
            <Form.Input name="text" label={'Change ' + name} placeholder={'Enter ' + name} defaultValue={text} />

            <div className="modal__controls">
              <Form.Submit label="Save" data-cy="save" />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default EditLine;
