import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ButtonV2 } from 'components';
import Item from './Item';
import './style.scss';

function NewsletterTypeSelect({ history, onClose, data }) {
  const [type, setType] = useState('template');
  const isTemplate = data && data.isTemplate;

  const handleContinue = () => {
    onClose();
    if (type === 'template') {
      history.push('/documents/compose', { isTemplate });
      // history.push('/documents/create', { isTemplate });
    } else {
      history.push('/documents/upload', { isTemplate });
    }
  };

  return (
    <div className="modal__wrapper document-create-type-select">
      <div className="modal__header">
        <div className="subheader">Create a document</div>
      </div>

      <div className="modal__container">
        <div className="document-create-type-select__subtitle">
          Start by choosing a template or uploading a PDF file.
        </div>

        <div className="document-create-type-select__body">
          <Item
            title="Use a template"
            icon="newsletter-template"
            onClick={() => setType('template')}
            checked={type === 'template'}
            data-cy="template"
          >
            Select from a library of documents.
          </Item>
          <Item
            title="Upload PDF File"
            icon="upload"
            onClick={() => setType('upload')}
            checked={type === 'upload'}
            data-cy="upload"
          >
            Import local file.
            <br />
          </Item>
        </div>
      </div>

      <div className="modal__controls">
        <ButtonV2 label="Cancel" secondary onClick={onClose} />
        <ButtonV2 label="Continue" onClick={handleContinue} data-cy="continue" />
      </div>
    </div>
  );
}

export default withRouter(NewsletterTypeSelect);
