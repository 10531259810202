import React, { PureComponent } from 'react';
import { isMobile } from 'lib/utils';
import PropTypes from 'prop-types';
import { Filter, Form } from 'components';

const getConfig = labels => ({
  section_id: { type: 'room' },
  label_ids: {
    type: 'multi-select',
    formType: 'checkbox',
    enableClear: true,
    multipleSelectionText: 'labels',
    title: 'Select Labels',
    label: 'Labels',
    options: labels.reduce((options, label) => {
      options[label.id] = label.name;
      return options;
    }, {})
  },
  lesson_category_id: { type: 'lesson-category' }
});

class FilterBar extends PureComponent {
  static propTypes = {
    filters: PropTypes.object,
    onChange: PropTypes.func,
    labels: PropTypes.array
  };

  static defaultProps = {
    filters: {},
    labels: []
  };

  render() {
    const mobile = isMobile();

    return (
      <Filter
        className="add-lesson__filters"
        filters={getConfig(this.props.labels)}
        onSubmit={this.props.onChange}
        side={mobile ? 'right' : undefined}
        noArrow={mobile}
      >
        <Form.Input name="name" placeholder="Search Lessons" />
      </Filter>
    );
  }
}

export default FilterBar;
