import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Steps from '../ModalController/Steps';
import SelectKids from '../common/SelectKids';
import MarkAbsentSaveStep from './MarkAbsentSaveStep';
import { plural } from 'lib/utils';
import './style.scss';

class MarkAbsent extends Component {
  constructor() {
    super();
    this.state = {
      kids: [],
      isSaving: false
    };
  }
  componentDidMount() {
    req.rooms();
    req.tags();
  }

  @bind
  nextStep() {
    this.steps.goToStep(1);
  }

  @bind
  bindSteps(node) {
    this.steps = node;
  }

  @bind
  updateKids(kids) {
    this.setState({ kids });
  }

  @bind
  onSubmit(values) {
    this.setState({ isSaving: true });

    req
      .markAbsent({
        kid_ids: this.state.kids,
        date_to: moment(values.to_date).format('YYYY-MM-DD'),
        date_from: moment(values.from_date).format('YYYY-MM-DD'),
        note: values.note
      })
      .then(() => {
        Actions.showFlash(`${plural(this.state.kids.length, 'student')} marked as absent`);
        this.setState({ isSaving: false });
        this.props.onClose(true);
      })
      .catch(err => {
        this.setState({ isSaving: false });
        Actions.reportError('There was problem saving enrollment', err);
      });
  }

  render() {
    const { kids } = this.props;

    return (
      <div className="mark-absent">
        <Steps ref={this.bindSteps}>
          <Steps.Item>
            <SelectKids
              onSubmit={this.nextStep}
              onUpdate={this.updateKids}
              students={kids}
              defaultSelected={this.state.kids}
            />
          </Steps.Item>

          <Steps.Item>
            <MarkAbsentSaveStep
              students={kids}
              updateStudents={this.updateKids}
              selectedStudents={this.state.kids}
              onSubmit={this.onSubmit}
              isSaving={this.state.isSaving}
            />
          </Steps.Item>
        </Steps>
      </div>
    );
  }
}

const mapStateToProps = ({ students }) => ({
  kids: students.data
});

export default connect(mapStateToProps)(MarkAbsent);
