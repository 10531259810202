import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'components';

class LeadsFilter extends PureComponent {
  static propTypes = {
    filters: PropTypes.shape({
      search: PropTypes.string
    }),
    onChange: PropTypes.func
  };

  handleUpdate(partialUpdateObj) {
    if (this.props.onChange) {
      this.props.onChange({ ...this.props.filters, ...partialUpdateObj });
    }
  }

  @bind
  updateSearch(search) {
    this.handleUpdate({ search });
  }

  render() {
    const { search } = this.props.filters;

    return (
      <div className="form--inline">
        <div className="w-1/2 input--search">
          <div className="form-input__field">
            <TextInput placeholder="Search" value={search} onChange={this.updateSearch} />
          </div>
        </div>
      </div>
    );
  }
}

export default LeadsFilter;
