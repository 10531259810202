import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';
import InputFields from './InputFields';
import './style.scss';

class PreviewForm extends Component {
  static propTypes = {
    rowsA: PropTypes.array,
    rowsB: PropTypes.array,
    onChange: PropTypes.func,
    children: PropTypes.node
  };

  static defaultProps = {
    rowsA: [],
    rowsB: [],
    onChange: () => undefined,
    children: null
  };

  renderColumn(columnData) {
    return columnData.map(row => {
      // NOTE for now we are using only one field per row
      const field = row.fields[0];
      if (!field || !InputFields[field.fieldType]) {
        return <div key={`row_${row.id}`} className="preview-form__field--skeleton" />;
      }

      const Field = InputFields[field.fieldType];
      return (
        <div className="form__row" key={`row_${row.id}`}>
          <Field {...field} />
        </div>
      );
    });
  }

  render() {
    const { onChange, children, rowsA, rowsB, className } = this.props;

    return (
      <Form className={className} onChange={onChange}>
        <div className="preview-form">
          <div className="preview-form__column">{this.renderColumn(rowsA)}</div>
          <div className="preview-form__column">{this.renderColumn(rowsB)}</div>
        </div>
        {children}
      </Form>
    );
  }
}

export default PreviewForm;
