import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import cx from 'classnames';

import { Timestamp, ActionButton, TooltipTrigger, Button, ButtonV2, Icon, Preloader } from 'components';
import { SIGNABLE_ACTIVITIES } from 'constants/activityTypes';
import { buildSignatureContext } from 'lib/signableActivity';
import { plural } from 'lib/utils';
import Reactions from 'components/Reactions';
import ActivityIcon from './ActivityIcon';
import ActivityBody from './ActivityBody';
import ActivityStudents from './ActivityStudents';
import ACTIVITY_TEXTS from './activityTexts';
import { PDFActions } from 'screens/teacher/Documents/common';

import './style.scss';

function ActivityV2(props) {
  const {
    activity: originalActivity,
    allStudents,
    staff,
    approve,
    updateActivityData = () => undefined,
    remove = () => undefined,
    isFiltered,
    isStaff = true,
    hideDelete,
    currentUser,
    staffNameEnabled,
    incidentDigitalSignaturesEnabled,
    isCarer,
    sharedObservationsEnabled,
    sharedObservationsEnabledChangedAt,
    firstId,
    isAdmin
  } = props;

  const [approved, setApproved] = useState(originalActivity.approval_status === 'approved');
  const [justApproved, setJustApproved] = useState(false);
  const [updatedActivity, setUpdatedActivity] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [batchActivityKids, setBatchActivityKids] = useState([]);

  const currentActivity = updatedActivity || originalActivity;
  const originalKidIdsRef = useRef([...originalActivity.activity_participants]);

  const isSettingsStaffNameEnabled = staffNameEnabled;

  const staffId = currentActivity.activity_staff[0];
  const matchedStaff = Array.isArray(staff) && staff.length > 0 ? staff.find(person => person.id === staffId) : null;
  const staffPresentName = matchedStaff ? matchedStaff.name : '';

  const editActivity = batch => {
    const clonedActivity = { ...currentActivity };

    if (batch) {
      clonedActivity.activity_participants = batchActivityKids;
    }

    const formData = {
      title: ACTIVITY_TEXTS[clonedActivity.activity_type],
      activity: clonedActivity,
      kids: allStudents
        ? clonedActivity.activity_participants.map(id => allStudents.find(s => s.id === id)).filter(s => !!s)
        : [],
      isFiltered,
      hideDelete
    };

    if (SIGNABLE_ACTIVITIES.includes(clonedActivity.activity_type) && incidentDigitalSignaturesEnabled) {
      formData.signatures = clonedActivity.activiable?.signatures;
    }

    Actions.showModal('EditActivity', formData).then(newUpdatedActivity => {
      if (newUpdatedActivity) {
        let finalActivity = newUpdatedActivity;
        if (batch) {
          finalActivity = {
            ...newUpdatedActivity,
            activity_participants: originalKidIdsRef.current
          };
        }

        setUpdatedActivity(finalActivity);
        if (finalActivity.approval_status === 'approved') {
          setApproved(true);
        } else {
          setApproved(false);
        }
        updateActivityData(originalActivity, finalActivity);

        Actions.showFlash('Activity has been updated');
      }
    });
  };

  const printReport = () => {
    setDownloading(true);

    const request = isCarer ? req.printIncidentParentReport : req.printIncidentStaffReport;

    request({
      incidentId: currentActivity.activiable.id,
      kidId: currentActivity.activity_participants[0]
    })
      .then(pdfData => {
        PDFActions.open(pdfData);
        setDownloading(false);
      })
      .catch(error => {
        Actions.reportError('There was an error retrieving the pdf.', error);
        setDownloading(false);
      });
  };

  const approveActivity = disableConfirmation => {
    approve(currentActivity, disableConfirmation)
      .then(newActivity => {
        if (updatedActivity && newActivity) {
          updatedActivity.id = newActivity.id;
          setUpdatedActivity(updatedActivity);
          setApproved(true);
          setJustApproved(true);
        } else {
          setApproved(true);
          setJustApproved(true);
        }
      })
      .catch(() => undefined);
  };

  const removeActivity = batch => {
    const clonedActivity = { ...currentActivity };

    if (batch) {
      clonedActivity.activity_participants = batchActivityKids;
    }

    Actions.showModal('Confirmation', {
      title: 'Delete Activity',
      description: (
        <div>
          Are you sure you want to delete this activity for{' '}
          <strong>{clonedActivity.activity_participants.length}</strong>&nbsp;
          {plural(clonedActivity.activity_participants.length, 'student', false)}?
        </div>
      ),
      yesButton: 'Yes, Delete'
    }).then(result => {
      if (result) {
        remove(clonedActivity);
      }
    });
  };

  const isLessonAndLearning = (type, activity) => {
    return (
      type === 'learning_activity' && (activity.activiable?.lesson_id || !activity.activiable?.learning_activity_name)
    );
  };

  const signIncident = () => {
    Actions.showModal('IncidentSignReport', {
      activity: originalActivity
    }).then(result => {
      if (result && result.signature) {
        const localCurrentActivity = { ...currentActivity };

        if (localCurrentActivity.activiable) {
          localCurrentActivity.activiable.signature = result.signature;
        }

        setUpdatedActivity(localCurrentActivity);

        updateActivityData(originalActivity, localCurrentActivity);
      }
    });
  };

  const reportIncident = () => {
    Actions.showModal(incidentDigitalSignaturesEnabled ? 'IncidentReportSignature' : 'IncidentReport', {
      activity: originalActivity
    }).then(result => {
      if (result) {
        if (result.signatures) {
          const localCurrentActivity = { ...currentActivity };
          localCurrentActivity.activiable.signatures = result.signatures;
          setUpdatedActivity(localCurrentActivity);
        }

        if (result.requestParentSignature) {
          approveActivity(true);
        }
      }
    });
  };

  const getActivityTitle = (type, activity) => {
    if (isLessonAndLearning(type, activity)) {
      return 'Lesson & Learning';
    }

    if (type === 'learning_activity') {
      return activity.activiable.learning_activity_name.value;
    }

    return ACTIVITY_TEXTS[type];
  };

  const renderDownload = () => {
    if (downloading) {
      return <Preloader small />;
    }
    return (
      <a href="#" onClick={printReport}>
        Download
      </a>
    );
  };

  const renderReportStatus = (isCarerView, activity) => {
    const signatureContext = buildSignatureContext(activity);

    if (isCarerView) {
      return (
        signatureContext.hasCarerSignedTheReport && (
          <div className="report-info">
            {`Incident Report - Signed ${moment(signatureContext.signedCarerSignature.signed_at).format(
              'MMM D, YYYY'
            )}`}
            {renderDownload()}
          </div>
        )
      );
    } else if (signatureContext.signedCarerSignature) {
      return (
        <div>
          <div className="report-info">
            Report signed on {moment(signatureContext.signedCarerSignature.signed_at).format('MMM D, YYYY')}
            {renderDownload()}
          </div>
        </div>
      );
    } else if (signatureContext.unsignedCarerSignature) {
      return (
        <div className="report-info">
          Signature requested on {moment(signatureContext.unsignedCarerSignature.requested_at).format('MMM D, YYYY')}
          {renderDownload()}
        </div>
      );
    } else {
      return (
        <div className="report-info">
          <span>Report created on {moment(activity.activity_date).format('MMM D, YYYY')}</span>
          {renderDownload()}
        </div>
      );
    }
  };

  const showApproveButton = (staffView, wasApproved, handleApprove, activityType, activity) => {
    switch (activityType) {
      case 'observation_activity':
        return (
          sharedObservationsEnabled &&
          moment(activity.activity_time).isSameOrAfter(
            moment(sharedObservationsEnabledChangedAt).set({
              second: 0,
              millisecond: 0
            })
          ) &&
          !wasApproved &&
          !!handleApprove &&
          staffView
        );
      case 'name_to_face_activity':
        return false;
      default:
        return staffView && !wasApproved && !!handleApprove;
    }
  };

  const renderContextMenu = () => {
    const signatureContext = buildSignatureContext(currentActivity);

    const canEdit =
      isStaff &&
      !['sign_in_activity', 'sign_out_activity', 'absent_activity', 'transfer_activity'].includes(
        currentActivity.activity_type
      );

    const isReadOnly =
      incidentDigitalSignaturesEnabled &&
      SIGNABLE_ACTIVITIES.includes(currentActivity.activity_type) &&
      signatureContext.hasCarerSignatures;

    const canDelete = isStaff;

    const isMultiStudentBatch = batchActivityKids.length > 1;

    return (
      <ul className="tooltip-content__inner">
        {canEdit && isMultiStudentBatch && <div className="individual-actions">Individual Student Activity</div>}
        {canEdit && !isReadOnly && (
          <li data-cy="activity-edit" onClick={() => editActivity(false)}>
            Edit
          </li>
        )}
        {canEdit && isReadOnly && (
          <li aria-disabled>
            <div>Edit</div>
            <TooltipTrigger
              tooltip="You are unable to edit since the report was sent for a parent signature."
              noArrow
              className="disable-edit-tooltip"
            >
              <ButtonV2 icon iconName="tooltip" iconSize={14} />
            </TooltipTrigger>
          </li>
        )}
        {canDelete && (
          <li data-cy="activity-delete" onClick={() => removeActivity(false)}>
            Delete
          </li>
        )}
        {isMultiStudentBatch && (
          <>
            <div className="batch-actions">Batch Student Activities</div>
            {canEdit && !isReadOnly && (
              <li data-cy="activity-edit" onClick={() => editActivity(true)}>
                Edit All
              </li>
            )}
            {canEdit && isReadOnly && (
              <li aria-disabled>
                <div>Edit All</div>
                <TooltipTrigger
                  tooltip="You are unable to edit since the report was sent for a parent signature."
                  noArrow
                >
                  <ButtonV2 icon iconName="tooltip" iconSize={14} />
                </TooltipTrigger>
              </li>
            )}
            {canDelete && (
              <li data-cy="activity-delete" onClick={() => removeActivity(true)}>
                Delete All
              </li>
            )}
          </>
        )}
        {SIGNABLE_ACTIVITIES.includes(currentActivity.activity_type) && signatureContext.canCreateReport(isAdmin) && (
          <li data-cy="activity-report" onClick={reportIncident}>
            {incidentDigitalSignaturesEnabled ? 'Create Report' : 'Report'}
          </li>
        )}
        {incidentDigitalSignaturesEnabled &&
          SIGNABLE_ACTIVITIES.includes(currentActivity.activity_type) &&
          signatureContext.canDownloadReport(currentUser.current_role) && (
            <li data-cy="activity-report" onClick={printReport}>
              {renderDownload()}
            </li>
          )}
      </ul>
    );
  };

  const renderTooltipContent = () => {
    return (
      <div className="activity__tooltip-content">
        Edits to Group Activities approved via the Approve All Button must be made on the Activity Feed page.
      </div>
    );
  };

  const type = currentActivity.activity_type;
  const Body = ActivityBody[type];
  const lessonAndLearn = isLessonAndLearning(type, currentActivity);

  const students = allStudents
    ? currentActivity.activity_participants.map(id => allStudents.find(s => s.id === id)).filter(s => s)
    : [];

  if (!Body) {
    return null;
  }

  const isMultiStudentBatch = batchActivityKids.length > 1;
  const signatureContext = buildSignatureContext(currentActivity);
  const canCreateReport = SIGNABLE_ACTIVITIES.includes(type) && signatureContext.canCreateReport(isAdmin);

  return (
    <div className="activity" data-cy="activity">
      <div className="activity__type">
        {isStaff && !approved && <div className="activity__staff-icon" />}
        <ActivityIcon type={type} />
        {isStaff && !approved && <div className="activity__type__staff">STAFF</div>}
      </div>

      <div className="activity__body">
        <div className="activity__header">
          <div className="activity__header-type">
            <div className="activity__title">{getActivityTitle(type, currentActivity)}</div>
            {isSettingsStaffNameEnabled && staffPresentName && (
              <div className="activity__staff-name">{staffPresentName}</div>
            )}
          </div>
          <div
            className={cx('activity__timestamp', {
              'activity__timestamp--stuff': isStaff && !lessonAndLearn
            })}
          >
            <Timestamp timestamp={currentActivity.activity_time} showDate={false} />
          </div>
          {isStaff && (
            <div className="activity__options">
              {currentActivity.isEditable === false ? (
                <TooltipTrigger
                  renderTooltip={renderTooltipContent}
                  tooltipClassName="tooltip--activity-content"
                  side="right"
                  triggerOn="hover"
                >
                  <ActionButton
                    data-cy="activity-actions"
                    className="approve-all-button"
                    iconName="ellipsis"
                    size={28}
                    disabled
                  />
                </TooltipTrigger>
              ) : (
                <TooltipTrigger
                  onOpen={() => {
                    if (currentActivity.activity_participants.length === 1) {
                      setBatchActivityKids(currentActivity.activity_participants);
                    }
                  }}
                  white
                  renderTooltip={renderContextMenu}
                  side="right"
                  tooltipClassName={`tooltip--context-menu ${isMultiStudentBatch && 'is-batch'}`}
                  triggerOn="click"
                  disabled={currentActivity.isEditable === false}
                >
                  <ActionButton data-cy="activity-actions" iconName="ellipsis" size={28} />
                </TooltipTrigger>
              )}
            </div>
          )}
        </div>

        <ActivityStudents students={students} firstId={firstId} />

        <div className="activity__detail">
          <Body activity={currentActivity} isSettingsStaffNameEnabled={isSettingsStaffNameEnabled} />
        </div>

        {justApproved && approved && (
          <div className="activity__footer">
            <div className="activity__approved">
              <Icon name="verify" color="#72C734" size={17} /> &nbsp;Approved
            </div>
          </div>
        )}

        <div className="activity__footer">
          {showApproveButton(isStaff, approved, approve, type, currentActivity) && !approved && (
            <ButtonV2 secondary label="Approve" onClick={() => approveActivity()} />
          )}
          {canCreateReport && (
            <ButtonV2
              secondary
              label={incidentDigitalSignaturesEnabled ? 'Create Report' : 'Report'}
              onClick={reportIncident}
            />
          )}
          {SIGNABLE_ACTIVITIES.includes(type) &&
            incidentDigitalSignaturesEnabled &&
            signatureContext.canDownloadReport(currentUser.current_role) &&
            renderReportStatus(isCarer, currentActivity)}

          {SIGNABLE_ACTIVITIES.includes(type) &&
            incidentDigitalSignaturesEnabled &&
            isCarer &&
            signatureContext.hasRequestForCarerSignature &&
            !signatureContext.hasCarerSignedTheReport && (
              <Button label="Sign Incident" onClick={signIncident} className="sign-incident" />
            )}
        </div>

        <Reactions activity={currentActivity}>
          <div className="activity__reactions-container">
            <Reactions.Emojis />
          </div>
        </Reactions>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  currentUser: state.currentUser.data,
  staffNameEnabled: state.currentUser.data.current_school
    ? state.currentUser.data.current_school.daily_activity_staff_name_enabled
    : false,
  incidentDigitalSignaturesEnabled: state.currentUser.data.current_school.incident_digital_signatures_enabled,
  isCarer: state.currentUser.data.current_role === 'carer',
  sharedObservationsEnabled: state.currentUser.data.current_school.shareable_observation_activity_enabled,
  sharedObservationsEnabledChangedAt:
    state.currentUser.data.current_school.shareable_observation_activity_enabled_changed_at
});

export default connect(mapStateToProps)(ActivityV2);
