import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import { TooltipTrigger, ActionButton, Avatar, Checkbox } from 'components';
import { fullName } from 'lib/utils';
import './style.scss';

class FamilySelector extends Component {
  static propTypes = {
    options: PropTypes.array,
    value: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    options: []
  };

  renderFamilies(onClose) {
    const { options, value, onChange } = this.props;

    return options.map(family => {
      const familyCN = classNames({
        'family-selector__item': true,
        'family-selector__item--selected': family.id === value
      });
      const { kids } = family;

      const handleClick = () => {
        onChange(family.id);
        if (typeof onClose === 'function') {
          onClose();
        }
      };

      return (
        <li key={family.id} className={familyCN} onClick={handleClick}>
          <span className="family-selector__item-name">{fullName(kids[0])}</span>
          {kids.length > 1 && <span className="family-selector__kid-tag">+{kids.length - 1}</span>}
          <div className="family-selector__item-checkbox">
            <Checkbox type="circle" checked={family.id === value} />
          </div>
        </li>
      );
    });
  }

  @bind
  renderTooltip(onClose) {
    return (
      <Scrollbars autoHeight autoHeightMax={300}>
        <ul className="tooltip-content__inner">{this.renderFamilies(onClose)}</ul>
      </Scrollbars>
    );
  }

  renderKidsTooltip(students) {
    return students.map(student => (
      <div className="family-selector__tooltip-item" key={student.id}>
        {student.name}
      </div>
    ));
  }

  render() {
    const { options, value } = this.props;
    const selectedFamily = options.find(option => option.id === value);
    const { kids } = selectedFamily;

    return (
      <div className="family-selector">
        <div className="family-selector__kid">
          <div className="family-selector__kid-avatar">
            <Avatar
              className="avatar--small"
              name={fullName(kids[0])}
              url={kids[0].profile_pic_url}
              color={kids[0].color}
            />
          </div>
          {options.length > 1 ? (
            <TooltipTrigger
              white
              className="tooltip--context-menu family-selector__tooltip"
              triggerOn="click"
              side="left"
              renderTooltip={this.renderTooltip}
            >
              <span className="family-selector__kid-name">{fullName(kids[0])}</span>
              {kids.length > 1 && (
                <TooltipTrigger tooltip={this.renderKidsTooltip(kids)} side="right">
                  <span className="family-selector__kid-tag">+{kids.length - 1}</span>
                </TooltipTrigger>
              )}
              <ActionButton size={14} iconName="chevron-down" className="family-selector__arrow" />
            </TooltipTrigger>
          ) : (
            <div>
              <span className="family-selector__kid-name">{fullName(kids[0])}</span>
              {kids.length > 1 && (
                <TooltipTrigger tooltip={this.renderKidsTooltip(kids)} side="right">
                  <span className="family-selector__kid-tag">+{kids.length - 1}</span>
                </TooltipTrigger>
              )}
            </div>
          )}

          <span className="family-selector__kid-family">Family</span>
        </div>
      </div>
    );
  }
}

export default FamilySelector;
