import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SortableHandle } from 'react-sortable-hoc';
import { ActionButton, ContextMenu, ContextMenuItem, Preloader, Icon, CorporateSettingIcon } from 'components';

const DragHandle = SortableHandle(() => (
  <Icon name="drag-handle" color="#ccc" size="24" className="manage-list-item__drag-handle" />
));

class ManageListItem extends PureComponent {
  static propTypes = {
    item: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
    ItemComponent: PropTypes.any,
    controlsDisabled: PropTypes.bool,
    sortable: PropTypes.bool,
    menu: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.string,
        value: PropTypes.any,
        onClick: PropTypes.func
      })
    ),
    isEdit: PropTypes.bool,
    isUpdateLoading: PropTypes.bool,
    isDeleteLoading: PropTypes.bool,
    onEditStart: PropTypes.func,
    onEditEnd: PropTypes.func,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      editedItem: undefined
    };
  }

  @bind
  handleChange(editedItem) {
    this.setState({ editedItem: { ...this.state.editedItem, ...editedItem } });
  }

  @bind
  updateItem() {
    this.props.onUpdate(this.props.item.id, { ...this.state.editedItem });
    this.setState({ editItem: undefined });
  }

  @bind
  editItem() {
    this.setState({ editedItem: { ...this.props.item } }, () => {
      this.props.onEditStart(this.props.item.id);
    });
  }

  @bind
  deleteItem() {
    this.props.onDelete(this.props.item.id);
  }

  getDefaultMenuItems() {
    return [
      { label: 'Edit', onClick: this.editItem },
      { label: 'Delete', onClick: this.deleteItem }
    ];
  }

  renderControls() {
    const { isUpdateLoading } = this.props;

    const preloader = (
      <div className="manage-list-item__preloader">
        <Preloader small />
      </div>
    );

    return (
      <div className="manage-list-item__controls">
        {isUpdateLoading ? (
          preloader
        ) : (
          <ActionButton
            square
            size={14}
            iconName="tick-1"
            onClick={this.updateItem}
            data-cy="update-manage-list-item"
          />
        )}
        <ActionButton square size={14} iconName="close" onClick={this.props.onEditEnd} disabled={isUpdateLoading} />
      </div>
    );
  }

  renderMenu() {
    const item = this.props.item;
    const menuItems = this.props.menu || this.getDefaultMenuItems();
    const { controlsDisabled } = this.props;

    return item.group_entity ? (
      <div className="manage-list-item__corporate-setting-icon">
        <CorporateSettingIcon />
      </div>
    ) : (
      <ContextMenu disabled={controlsDisabled} className="manage-table-item__menu">
        {menuItems.map(i => (
          <ContextMenuItem key={i.label} {...i} />
        ))}
      </ContextMenu>
    );
  }

  render() {
    const { item, sortable, isEdit, ItemComponent, onEditStart, onEditEnd } = this.props;

    return (
      <div className="manage-list-item">
        {sortable && !isEdit && <DragHandle />}

        <div className="manage-list-item__content">
          <ItemComponent
            item={item}
            editedItem={this.state.editedItem}
            isEdit={isEdit}
            onEditStart={onEditStart}
            onEditEnd={onEditEnd}
            onChange={this.handleChange}
          />
        </div>

        {isEdit ? this.renderControls() : this.renderMenu()}
      </div>
    );
  }
}

export default ManageListItem;
