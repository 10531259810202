import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TextInput } from 'components';
import get from 'lodash/get';

import './style.scss';
import withContext, { FormContext } from 'hocs/withContext';

class SearchTermsInput extends Component {
  static defaultProps = {
    defaultValue: {}
  };

  constructor(props) {
    super(props);

    const { defaultValue, terms } = props;
    const { term = '', value = '' } = defaultValue;
    const searchTerm = get(
      terms.find(st => st.value === term),
      'label',
      ''
    );

    this.state = {
      term,
      value,
      search: searchTerm ? [`@${searchTerm}`, value].join(' ') : value,
      isFocused: false,
      highlightIndex: 0
    };

    this.inputRef;
    this.ref;
    this.searchTermsMap = terms.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.label]: curr.value
      };
    }, {});

    this.handleChange = debounce(this.handleChange, 500);
  }

  UNSAFE_componentWillMount() {
    const { name } = this.props;
    const { term, value } = this.state;

    this.props.context.init(name, { term, value }, {});
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  handleChange() {
    const { value, term } = this.state;

    this.props.context.update(this.props.name, { value, term });
  }

  @bind
  handleSearchChange(searchValue) {
    const nextState = {
      search: searchValue,
      value: '',
      term: '',
      highlightIndex: 0
    };
    const searchByTerm = this.isTermSearch(searchValue);

    if (searchByTerm) {
      nextState.value = searchByTerm.value;
      nextState.term = this.searchTermsMap[searchByTerm.term] || null;

      this.setState(nextState, () => {
        if (this.state.term && this.state.value) this.handleChange();
      });
    } else {
      nextState.value = searchValue;
      this.setState(nextState, this.handleChange);
    }
  }

  isTermSearch(searchStr) {
    const regexp = /^@(\w+){0,1}(\b\s+){0,1}(\w.*){0,1}/;
    const match = regexp.exec(searchStr);

    if (match === null) return false;

    return {
      term: match[1] || '',
      value: match[3] || ''
    };
  }

  @bind
  handleBlur() {
    this.setState({
      isFocused: false,
      highlightIndex: 0
    });
  }

  @bind
  handleFocus() {
    this.setState({
      isFocused: true,
      highlightIndex: 0
    });
  }

  @bind
  handleTermSelect(term) {
    return e => {
      e.preventDefault();
      this.selectTerm(term);
    };
  }

  selectTerm({ value, label }) {
    this.setState(
      {
        search: `@${label} `,
        value: '',
        term: value
      },
      this.inputRef.focus
    );
  }

  @bind
  onKeyDown(e) {
    const { search, value, term, isFocused, highlightIndex } = this.state;
    const { terms } = this.props;

    if (!isFocused || (value && term !== null) || (!value && term) || !search.length) return null;

    const searchByTerm = this.isTermSearch(search);
    let allowedTerms = terms;
    if (searchByTerm.term) allowedTerms = allowedTerms.filter(st => st.label.indexOf(searchByTerm.term) === 0);

    if (!allowedTerms.length) return;

    if (e.keyCode === 38) {
      // arrow up
      this.setState({
        highlightIndex: highlightIndex - 1 >= 0 ? highlightIndex - 1 : 0
      });
      e.nativeEvent.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
    } else if (e.keyCode === 40) {
      // arrow down
      this.setState({
        highlightIndex: highlightIndex + 1 >= allowedTerms.length ? allowedTerms.length - 1 : highlightIndex + 1
      });
      e.nativeEvent.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
    } else if (e.keyCode === 13) {
      // enter
      const selectedTerm = allowedTerms[highlightIndex % allowedTerms.length];
      this.selectTerm(selectedTerm);
      e.nativeEvent.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
    }
  }

  renderHelpPopup() {
    const { search, value, term, isFocused, highlightIndex } = this.state;
    const { terms } = this.props;

    if (!isFocused || (value && term !== null) || (!value && term)) return null;

    let content = null;

    if (!search.length) {
      content = (
        <div className="search-terms-input__help-message">
          Refine your search using
          <div className="search-terms-input__terms-list">
            {terms.map(term => (
              <b>@{term.label}</b>
            ))}
          </div>
        </div>
      );
    } else {
      const searchByTerm = this.isTermSearch(search);
      let allowedTerms = terms;
      if (searchByTerm.term) allowedTerms = allowedTerms.filter(st => st.label.indexOf(searchByTerm.term) === 0);

      if (allowedTerms.length) {
        content = (
          <ul className="keywords">
            {allowedTerms.map((term, index) => (
              <li
                key={term.value}
                className={cx('keywords__item', {
                  'keywords__item--selected': highlightIndex === index
                })}
                onMouseDown={this.handleTermSelect(term)}
              >
                @{term.label}
              </li>
            ))}
          </ul>
        );
      } else {
        content = (
          <div className="search-terms-input__help-message">
            Invalid search term
            <span />
          </div>
        );
      }
    }

    return (
      <div className="search-terms-input__help" data-cy="filter-search-help">
        <div className="search-terms-input__help-content">{content}</div>
      </div>
    );
  }

  render() {
    const { search } = this.state;
    const { placeholder } = this.props;

    return (
      <div
        className="search-terms-input"
        ref={node => (this.ref = node)}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      >
        <div className="form-input input--search">
          <div className="form-input__field">
            <TextInput
              name="kid_name"
              placeholder={placeholder || 'Search'}
              className="input--search"
              value={search}
              onChange={this.handleSearchChange}
              ref={node => (this.inputRef = node)}
              autoComplete={'off'}
              onKeyDown={this.onKeyDown}
            />
          </div>
        </div>
        {this.renderHelpPopup()}
      </div>
    );
  }
}

export default withContext(FormContext)(SearchTermsInput);
