import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import { Badge, TooltipTrigger } from 'components';
import './style.scss';

const DEFAULT_LINES = 1;
const DEFAULT_DELAY = 200;

class BadgeHeightList extends PureComponent {
  static propTypes = {
    lines: PropTypes.number,
    delay: PropTypes.number
  };

  static defaultProps = {
    lines: DEFAULT_LINES,
    delay: DEFAULT_DELAY
  };

  static getAbsoluteWidth(el) {
    const styles = window.getComputedStyle(el);
    const margin = parseFloat(styles.marginLeft) + parseFloat(styles.marginRight);

    return Math.ceil(el.offsetWidth + margin);
  }

  static getAbsoluteHeight(el) {
    const styles = window.getComputedStyle(el);
    const margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);

    return Math.ceil(el.offsetHeight + margin);
  }

  containerRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      showing: 0
    };

    this.recalculateShowing = throttle(this.recalculateShowing, this.props.delay);
  }

  componentDidMount() {
    this.calculateShowing();
    this.addEventListeners();
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  @bind
  addEventListeners() {
    window.addEventListener('resize', this.recalculateShowing);
  }

  @bind
  removeEventListeners() {
    window.removeEventListener('resize', this.recalculateShowing);
  }

  getChildren() {
    return React.Children.toArray(this.props.children);
  }

  @bind
  calculateShowing() {
    const container = this.containerRef.current;

    if (container === null) {
      return;
    }

    const badges = container.querySelectorAll('.badge');

    if (badges.length === 0) {
      return;
    }

    const badgeHeight = BadgeHeightList.getAbsoluteHeight(badges[0]);
    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;

    const { lines } = this.props;
    const actualLines = Math.round(containerHeight / badgeHeight); // Assuming that containerHeight is bigger than badgeHeight

    if (actualLines <= lines) {
      return;
    }

    let i = 0;
    let totalWidth = 0;
    const reservedWidth = 80;
    const allowedWidth = containerWidth * lines - reservedWidth;

    while (totalWidth <= allowedWidth) {
      const nextWidth = totalWidth + BadgeHeightList.getAbsoluteWidth(badges[i]);

      if (nextWidth >= allowedWidth) {
        break;
      }

      totalWidth = nextWidth;
      i += 1;
    }

    this.setState({ showing: i });
  }

  @bind
  recalculateShowing() {
    this.setState({ showing: 0 }, this.calculateShowing);
  }

  renderChildren() {
    const { showing } = this.state;
    const children = this.getChildren();

    return showing === 0 ? children : children.slice(0, showing);
  }

  renderHorizontalTrack() {
    return <div />;
  }

  renderHiddenBadge() {
    const children = this.getChildren();
    const hidden = children.length - this.state.showing;

    if (this.state.showing === 0) {
      return null;
    }

    const items = (
      <Scrollbars autoHeight renderTrackHorizontal={this.renderHorizontalTrack} autoHeightMax={200}>
        <div className="badge-height-list__tooltip-content">{children.slice(-hidden)}</div>
      </Scrollbars>
    );

    return (
      <TooltipTrigger tooltip={items} side="right" white tooltipClassName="badge-height-list__tooltip">
        <Badge children={`+${hidden}`} />
      </TooltipTrigger>
    );
  }

  render() {
    const className = classNames('badge-height-list', this.props.className);

    return (
      <div ref={this.containerRef} className={className}>
        {this.renderChildren()}
        {this.renderHiddenBadge()}
      </div>
    );
  }
}

export default BadgeHeightList;
