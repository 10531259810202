import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectKids from '../common/SelectKids';

class SelectLedgerStudents extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      kid_ids: []
    };

    if (props.data.kid_ids) {
      this.state.kid_ids = [...props.data.kid_ids];
    }
  }

  UNSAFE_componentWillMount() {
    req.students();
  }

  @bind
  handleSubmit(value) {
    this.props.onClose(value);
  }

  render() {
    const { students } = this.props;
    const { kid_ids } = this.state;

    return (
      <SelectKids
        title="Select Students (optional)"
        subtitle="No emails or ledger transaction is sent to parents. This is only for your records."
        label="Select"
        defaultSelected={kid_ids}
        students={students}
        onSubmit={this.handleSubmit}
        finalStep
        isOptional
      />
    );
  }
}

const mapStateToProps = state => ({
  students: state.students.data
});

export default connect(mapStateToProps)(SelectLedgerStudents);
