import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from 'components';
import './style.scss';
import useLaunchDarkly from 'hooks/useLaunchDarkly';
import OrderControlsV2 from '../OrderControlsV2';

const DIRECTIONS = ['asc', 'desc'];

function OrderControls({ value, onChange }) {
  const flags = useLaunchDarkly();
  if (flags['online-table-sorting']) {
    return <OrderControlsV2 value={value} />;
  }

  return (
    <div className="order-controls">
      {DIRECTIONS.map(direction => (
        <div className="order-controls__item" key={direction}>
          <Icon
            size={8}
            name={direction === 'asc' ? 'picker-up' : 'picker-down'}
            className={cx('order-controls__icon', `order-controls__icon--${direction}`, {
              'order-controls__icon--active': value === direction
            })}
            onClick={() => onChange(direction)}
          />
        </div>
      ))}
    </div>
  );
}

OrderControls.propTypes = {
  value: PropTypes.oneOf(DIRECTIONS),
  onChange: PropTypes.func
};

export default OrderControls;
