import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, ActionButton, ButtonV2, TooltipTrigger } from 'components';
import moment from 'moment';
import STATES from 'constants/states';
import { ZIP_NAME } from 'constants/paymentFieldNames';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class BasicInfoStep extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      countryCode: 'US',
      disableContinue: false
    };
  }

  @bind
  prevStep() {
    this.props.context.goToStep(1);
  }

  @bind
  onChange(value) {
    if (value.country !== this.state.countryCode && value.country) {
      const code = value.country;
      const newState = {
        countryCode: value.country,
        disableContinue: false
      };

      if (code !== 'US') {
        newState.disableContinue = true;
      }

      this.setState(newState);
    }
  }

  @bind
  onSubmit(data) {
    if (moment().diff(data.dob, 'years') < 15) {
      Actions.showFlash('Adminstrator must be at least 15 years old', 'alert');
      return;
    }
    this.props.onUpdate(data);
    this.props.context.nextStep();
  }

  render() {
    const { data, constants, title, fromReg } = this.props;
    const { disableContinue, countryCode } = this.state;

    return (
      <div className="modal__wrapper">
        <div className="modal__header modal__header--bordered">
          {!fromReg && <ActionButton iconName="back" className="school-activate__back" onClick={this.prevStep} />}
          <span className="modal__header-title">{title}</span>
        </div>
        <Form
          className="modal__container school-activate__form"
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          validateOn="submit"
        >
          <div className="school-activate__legend">School / Account Details</div>
          <div className="school-activate__desc">
            Your business name and phone number will appear on your customers' credit card statements.
          </div>
          <div className="form__row form__row--justified">
            <Form.Input defaultValue={data.school_name} placeholder="School Name" name="school_name" required />
            <Form.Input
              defaultValue={data.phone}
              placeholder="School Phone Number"
              name="phone"
              mask="(999) 999-9999"
              required
            />
          </div>
          <div className="form__row">
            <Form.Input defaultValue={data.address} placeholder="Address Line" name="address" required />
          </div>
          <div className="form__row form__row--justified">
            <Form.Select name="country" defaultValue={data.country} title="Country" required>
              {Object.keys(constants.countries).map(c => (
                <Form.Select.Item label={constants.countries[c]} value={c} key={c} />
              ))}
            </Form.Select>
            <Form.Select
              name="state"
              defaultValue={data.state}
              title="State"
              disabled={!STATES[countryCode]}
              required={!!STATES[countryCode]}
            >
              {STATES[countryCode] &&
                STATES[countryCode].map((
                  s // for now only use in US
                ) => <Form.Select.Item label={s.name} value={s.abbreviation} key={s.abbreviation} />)}
            </Form.Select>
          </div>
          <div className="form__row form__row--justified">
            <Form.Input defaultValue={data.zip} placeholder={ZIP_NAME[countryCode] || 'Zip'} name="zip" required />
            <Form.Input defaultValue={data.city} placeholder="City" name="city" required />
          </div>
          <div className="school-activate__legend">Administrator Legal Details</div>
          <div className="form__row form__row--justified">
            <Form.Input defaultValue={data.first_name} placeholder="First Name" name="first_name" required />
            <Form.Input defaultValue={data.last_name} placeholder="Last Name" name="last_name" required />
          </div>
          <div className="form__row form__row--justified">
            <Form.DatePicker
              className="school-activate__form-dob"
              name="dob"
              dropup
              placeholder="Administrator DOB"
              yearsToShow={80}
              defaultValue={data.dob}
            />
            <Form.Input
              defaultValue={data.personal_id_number}
              placeholder={countryCode === 'CA' ? 'Social Insurance Number' : 'SSN Number (###-##-####)'}
              name="personal_id_number"
              mask="999-99-9999"
              pattern="^[0-9]{3}-[0-9]{2}-[0-9]{4}$"
              required={['US', 'CA'].includes(countryCode)}
              disabled={!['US', 'CA'].includes(countryCode)}
            />
          </div>
          <div className="school-activate__desc school-activate__desc--ssn">
            We use the last four digits to verify your identity.
          </div>
          <div className="form__row form__row--justified">
            <Form.FileInput className="verify-doc" name="verification_document" maxSize={5} required>
              <div className="verify-doc__content">
                <img className="verify-doc__img" src="/assets/images/id-icon.png" />
                Please upload a valid photo ID to verify your identity using a JPG or PNG that is less than 5MB.
              </div>
              <div className="school-activate__help">
                Why is this necessary
                <TooltipTrigger
                  tooltip="You are setting up a Merchant Account, which allows you to securely and safely process transactions, your identity verification is required by our payment gateway."
                  side="center"
                  className="school-activate__tooltip"
                >
                  <ButtonV2 icon iconName="tooltip" iconSize={18} />
                </TooltipTrigger>
              </div>
            </Form.FileInput>
          </div>

          <div className="school-activate__warning">
            <div className="school-activate__warning-text">
              <span className="school-activate__warning-small">
                Payout timeline for ACH/Bank: 2 business days, Credit Card: 1 business day.
                <br />
                If you have any questions, please call&nbsp;
                <span className="school-activate__warning-strong">(877) 679-0950.</span>
              </span>
            </div>
          </div>

          {disableContinue && (
            <div className="school-activate__warning">
              <div className="school-activate__warning-text">
                <span className="school-activate__warning-small">
                  If you want to process payments in "{constants.countries[countryCode]}", please contact us at&nbsp;
                  <span className="school-activate__warning-strong">(877) 679-0950.</span>
                </span>
              </div>
            </div>
          )}
          <div className="form__row form__row--actions">
            <Form.Submit label="Continue" disabled={!['US'].includes(countryCode)} />
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  constants: state.constants.data
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(BasicInfoStep);
