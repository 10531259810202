import { getSubscriptionAccessLevel } from 'lib/utils';
import { FORBIDDEN_ROUTES } from 'constants/subscriptionAccess';

const checkRouteAccess = store => (route, checkTrial = true) => {
  const state = store.getState();
  const currentSchool = state.currentUser ? state.currentUser.data.current_school : null;

  if (!currentSchool || !route) {
    return true;
  }

  const acc = getSubscriptionAccessLevel(currentSchool, checkTrial);
  const routes = FORBIDDEN_ROUTES[acc] || [];

  return routes.every(r => route.indexOf(r) !== 0);
};

export default checkRouteAccess;
