import React, { useContext } from 'react';
import { ButtonV2, Icon, ActionButton } from 'components';
import { ModalControllerStepsContext } from 'hocs/withContext';

function DescriptionStep({ onClose }) {
  const context = useContext(ModalControllerStepsContext);
  return (
    <div className="modal__wrapper">
      <div className="modal__header">
        <span className="modal__header-title">Multifamily Transactions</span>
        <ActionButton iconName="close" onClick={() => onClose()} size={12} className="modal__close" />
      </div>
      <div className="modal__container split-family__description">
        <div className="split-family__description-row">
          You are starting the process of creating separate accounts for your child or children’s billing accounts.
        </div>
        <div className="split-family__description-row">
          <div className="split-family__description-row-icon">
            <Icon color="#999999" name="split-managing" size={32} />
          </div>
          Split Families allows centers to manage their students with flexibility using tools that ensure the children
          will continue receiving the highest level of care.
        </div>
        <div className="split-family__description-row">
          <div className="split-family__description-row-icon">
            <Icon color="#999999" name="split-autopay" size={32} />
          </div>
          Parents from both families can each make payments to the center
        </div>
        <div className="split-family__description-row">
          <div className="split-family__description-row-icon">
            <Icon color="#999999" name="split-family" size={24} />
          </div>
          Parents have privacy with visibility only into their own balance
        </div>
        <div className="split-family__description-row">
          <div className="split-family__description-row-icon">
            <Icon color="#999999" name="split-communication" size={32} />
          </div>
          Parents have the ability to communicate with centers and know their messages will not be shared with their
          child's additional families
        </div>
      </div>
      <div className="modal__controls">
        <ButtonV2 label="next" onClick={context.nextStep} />
      </div>
    </div>
  );
}

export default DescriptionStep;
