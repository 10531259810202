import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonV2, Preloader } from 'components';
import SelectPersonList from '../common/SelectPersonList';
import './style.scss';

class AddKidsToClass extends Component {
  static propTypes = {
    data: PropTypes.object,
    kids: PropTypes.array,
    loading: PropTypes.bool,
    onClose: PropTypes.func
  };

  static defaultProps = {
    data: { id: undefined },
    kids: [],
    loading: false
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedKidIds: [],
      submitLoading: false
    };
  }

  componentDidMount() {
    req.students();
  }

  @bind
  handleSelect(selectedKidIds) {
    this.setState({ selectedKidIds });
  }

  @bind
  submit() {
    this.setState({ submitLoading: true }, () => {
      req
        .addKidsToClass({ id: this.props.data.id, kid_ids: this.state.selectedKidIds })
        .then(() => {
          this.setState({ submitLoading: false });
          this.props.onClose();
        })
        .catch(e => {
          this.setState({ submitLoading: false });
          Actions.reportError('Something went wrong', e);
        });
    });
  }

  render() {
    const { selectedKidIds, submitLoading } = this.state;
    const { kids, loading } = this.props;

    return (
      <div className="add-kids-to-class">
        <div className="modal__header">
          <div className="modal__header-title">Add Students to Class</div>
        </div>

        <div className="modal__container add-kids-to-class__container">
          {loading ? (
            <div className="add-kids-to-class__preloader">
              <Preloader large />
            </div>
          ) : (
            <SelectPersonList
              type="kid"
              listHeight={320}
              persons={kids}
              allPersons={kids}
              selectedIds={selectedKidIds}
              onSelect={this.handleSelect}
            />
          )}
        </div>

        <div className="modal__controls">
          <ButtonV2 label="Add Students" onClick={this.submit} isLoading={submitLoading} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ students }) => ({
  kids: students.data,
  loading: students.loading
});

export default connect(mapStateToProps)(AddKidsToClass);
