import React, { Component } from 'react';
import Steps from '../ModalController/Steps';
import SelectKids from '../common/SelectKids';
import DistributeFundsStep from './DistributeFundsStep';
import SummaryStep from './SummaryStep';
import './style.scss';

class ReceiveSubsidyPayment extends Component {
  constructor() {
    super();
    this.state = {
      kids: [],
      sum: undefined,
      notes: '',
      selectedKids: [],
      funds: {},
      isSaving: false
    };
  }

  static getTotal(values) {
    const result = Object.values(values).reduce((sum, item) => {
      return sum + Number(item) * 100;
    }, 0);

    return result / 100;
  }

  async componentDidMount() {
    try {
      const kids = await req.subsidyKidsWithBalanceDue({
        ledger_id: this.props.data.subsidy.id,
        filters: { kid: { ledger_balance_due: true } }
      });

      this.setState({
        kids
      });
    } catch (error) {
      Actions.reportError('Unable to get kids with due balance', error);
    }
  }

  @bind
  nextStep() {
    this.steps.goToStep(1);
  }

  @bind
  bindSteps(node) {
    this.steps = node;
  }

  @bind
  updateField(field) {
    return value => {
      this.setState({
        [field]: value
      });
    };
  }

  @bind
  updateSelectedKids(value) {
    // re-organize funds object if array of selected kids was updated
    const funds = {};
    value.forEach(kidId => {
      const previousFunds = this.state.funds[kidId];
      if (previousFunds) {
        funds[kidId] = previousFunds;
      }
    });
    this.setState({
      selectedKids: value,
      funds
    });
  }

  @bind
  updateFunds(kidId, value) {
    return this.setState({
      funds: {
        ...this.state.funds,
        [kidId]: value
      }
    });
  }

  @bind
  async onSave() {
    const {
      data: { subsidy },
      onClose
    } = this.props;
    const { selectedKids, funds, notes } = this.state;
    this.setState({ isSaving: true });

    try {
      const payment = await req.addSubsidyPayment({
        ledger_id: subsidy.id,
        parent_payments: selectedKids.map(kidId => ({
          amount: Number(funds[kidId]),
          description: '',
          kid_id: kidId,
          notes
        }))
      });
      Actions.showFlash('Added payment successfully');
      onClose(payment);
    } catch (error) {
      this.setState({ isSaving: false });
      Actions.reportError('Unable to add payment', error);
    }
  }

  render() {
    const { kids, selectedKids, funds, notes, isSaving, sum } = this.state;

    return (
      <div className="add-subsidy-invoice">
        <Steps ref={this.bindSteps}>
          <Steps.Item>
            <SelectKids
              onSubmit={this.nextStep}
              onUpdate={this.updateSelectedKids}
              students={kids}
              defaultSelected={selectedKids}
            />
          </Steps.Item>

          <Steps.Item>
            <DistributeFundsStep
              students={kids}
              selectedKids={selectedKids}
              values={funds}
              onUpdateFunds={this.updateFunds}
              notes={notes}
              onUpdateNotes={this.updateField('notes')}
              sum={sum}
              onUpdateSum={this.updateField('sum')}
              fundsSum={ReceiveSubsidyPayment.getTotal(funds)}
            />
          </Steps.Item>

          <Steps.Item>
            <SummaryStep
              students={kids}
              selectedKids={selectedKids}
              values={funds}
              onSave={this.onSave}
              isSaving={isSaving}
              fundsSum={ReceiveSubsidyPayment.getTotal(funds)}
            />
          </Steps.Item>
        </Steps>
      </div>
    );
  }
}

export default ReceiveSubsidyPayment;
