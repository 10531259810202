import React from 'react';
import { useSelector } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import { getStripePubKey } from 'lib/utils/selectors';
import StripeForm from 'screens/common/Billing/Stripe/AddPaymentMethod';
import { AddPaymentMethod as BluepayForm } from 'screens/common/Billing/Bluepay/AddPaymentMethod';
import { AddPaymentMethod as TuitionExpressForm } from 'screens/common/Billing/TuitionExpress/AddPaymentMethod';

const RegistrationFormPaymentMethod = ({ onClose, data }) => {
  const stripePubKey = useSelector(state => getStripePubKey(state));
  const { school, slug } = data;

  const handleConnectUri = () => req.registrationsTeAccountUri({ slug });

  const renderGatewayForm = () => {
    if (school.billing_gateway === 'bluepay') {
      return (
        <BluepayForm
          startMethod="card"
          pubKey={school.billing_publishable_key}
          billingAccountId={school.billing_account_id}
          onClose={onClose}
          onSubmit={onClose}
        />
      );
    }

    if (school.billing_gateway === 'te') {
      return (
        <TuitionExpressForm onClose={onClose} onSubmit={onClose} startMethod="card" onConnectUri={handleConnectUri} />
      );
    }

    return (
      <StripeProvider apiKey={stripePubKey} options={{ stripeAccount: school.billing_account_id }}>
        <StripeForm onClose={onClose} onSubmit={onClose} startMethod="card" />
      </StripeProvider>
    );
  };

  return <div className="school-registration-payment">{renderGatewayForm()}</div>;
};

export default RegistrationFormPaymentMethod;
