import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'components';
import PropTypes from 'prop-types';
import { Avatar, TooltipTrigger } from 'components';
import './style.scss';

export const Tooltip = ({ students }) => (
  <ul className="student-list-component__tooltip">
    {students.map(kid => (
      <li key={kid.id}>
        <span>{kid.name || kid.first_name + ' ' + kid.last_name}</span>
        {kid.registration_status && kid.registration_status !== 'active' && (
          <span className="capitalize"> ({kid.registration_status})</span>
        )}
      </li>
    ))}
  </ul>
);

const StudentList = ({
  students = [],
  maxNumber = 1,
  showRoom = false,
  withStudentLink = false,
  rooms,
  avatarSize
}) => {
  let roomName = '';

  if (showRoom && students.length) {
    const room = rooms.find(r => r.id === students[0].current_section_id);

    if (room) {
      roomName = room.name;
    }
  }

  return (
    <div className="student-list-component">
      {students.slice(0, maxNumber).map(kid => {
        const KidComponent = withStudentLink ? Link : 'div';

        return (
          <KidComponent
            key={`student_list_${kid.id}`}
            className="student-list-component__kid"
            to={withStudentLink ? `/my-school/students/${kid.id}` : undefined}
          >
            <Avatar
              name={kid.name || kid.first_name + ' ' + kid.last_name}
              url={kid.profile_pic_url}
              tooltipText={kid.name}
              color={kid.color}
              initials={kid.initials}
              size={avatarSize}
            />
            <div className="student-list-component__firstkid">
              <div className="student-list-component__firstkid-name">
                {kid.name || kid.first_name + ' ' + kid.last_name}
              </div>
              {roomName && <div className="student-list-component__firstkid-room">{roomName}</div>}
            </div>
          </KidComponent>
        );
      })}

      {students.length > maxNumber && (
        <TooltipTrigger tooltip={<Tooltip students={students.slice(maxNumber)} />} maxHeight={110}>
          <div className="plus-x">+{students.length - maxNumber}</div>
        </TooltipTrigger>
      )}
    </div>
  );
};

StudentList.propTypes = {
  students: PropTypes.array,
  showRoom: PropTypes.bool,
  maxNumber: PropTypes.number,
  withStudentLink: PropTypes.bool
};

const mapStateToProps = state => ({
  rooms: state.rooms.data
});

export default connect(mapStateToProps)(StudentList);
