export default {
  M: 'Monday',
  T: 'Tuesday',
  W: 'Wednesday',
  Th: 'Thursday',
  F: 'Friday',
  S: 'Saturday',
  Su: 'Sunday'
};

export const DAY_TO_CODE = {
  MON: 'M',
  TUE: 'T',
  WED: 'W',
  THU: 'Th',
  FRI: 'F',
  SAT: 'S',
  SUN: 'Su'
};

export const CODE_TO_DAY = {
  M: 'MON',
  T: 'TUE',
  W: 'WED',
  Th: 'THU',
  F: 'FRI',
  S: 'SAT',
  Su: 'SUN'
};

export const DAY_CODES = ['M', 'T', 'W', 'Th', 'F'];
export const ALL_DAY_CODES = ['M', 'T', 'W', 'Th', 'F', 'S', 'Su'];

// Back-end uses 0 = Sunday for programs :(
export const PROGRAM_DAY_CODES = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'];
