const removeItem = (collection, id) => {
  const itemIndex = collection.indexOf(collection.filter(item => item.id === id)[0]);
  const newData = [...collection.slice(0, itemIndex), ...collection.slice(itemIndex + 1)];
  return newData;
};

const createRemoveItemReducer = (reducerName, initialState) => (state = { ...initialState }, action) => {
  switch (action.type) {
    case `${reducerName}_REMOVE_ITEM`:
      return { ...state, loading: true, removeItemId: action.payload.id };

    case `${reducerName}_REMOVE_ITEM_SUCCESS`: {
      const itemId = state.removeItemId;
      if (action.options && action.options.collectionName) {
        const collectionName = action.options.collectionName;
        return {
          loading: false,
          data: {
            ...state.data,
            total: state.data.total - 1,
            [collectionName]: removeItem(state.data[collectionName], itemId)
          }
        };
      }

      return { loading: false, data: removeItem(state.data, itemId) };
    }

    case `${reducerName}_REMOVE_ITEM_FAILURE`:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default createRemoveItemReducer;
