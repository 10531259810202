const EMERGENCY_REPORT_FIELDS = {
  student: [
    { value: 'profile_pic_url', label: 'Photo', default: true },
    { value: 'first_name', label: 'First Name', default: true },
    { value: 'last_name', label: 'Last Name', default: true },
    { value: 'dob', label: 'DOB', default: false },
    { value: 'age', label: 'Age', default: false },
    { value: 'full_address', label: 'Address', default: false },
    { value: 'room_name', label: 'Room', default: false },
    { value: 'status', label: 'Status', default: false },
    { value: 'allergy', label: 'Allergies', default: true },
    { value: 'time_schedule', label: 'Schedule', default: false },
    { value: 'medications', label: 'Medication', default: true },
    { value: 'diet_restriction', label: 'Dietary Restrictions', default: false },
    { value: 'physicians', label: 'Physician', default: true },
    { value: 'physician_phone', label: 'Physician Phone', default: true },
    { value: 'physician_email', label: 'Physician Email', default: false },
    { value: 'physician_type', label: 'Physician Type', default: true }
  ],
  family: [
    { value: 'first_name', label: 'First Name', default: true },
    { value: 'last_name', label: 'Last Name', default: true },
    { value: 'actual_relation', label: 'Relation', default: true },
    { value: 'email', label: 'Email', default: false },
    { value: 'phone', label: 'Phone', default: true },
    { value: 'authorized_pickups', label: 'All Pickups', default: true },
    { value: 'relation', label: 'Pickup Type', default: false },
    { value: 'emergency_contact', label: 'Emergency Contact', default: true }
  ]
};

const studentDefaultSelected = EMERGENCY_REPORT_FIELDS.student.filter(f => f.default).map(f => f.value);
const familyDefaultSelected = EMERGENCY_REPORT_FIELDS.family.filter(f => f.default).map(f => f.value);

export const EMERGENCY_CARDS_DEFAULT_VALUES = {
  student_fields: studentDefaultSelected,
  family_fields: familyDefaultSelected,
  custom_student_fields: [],
  custom_parent_fields: [],
  group_by: 'school',
  sort_by: 'alphabetically_first_name',
  section_ids: ['all'],
  student_status: 'active'
};

export default EMERGENCY_REPORT_FIELDS;
