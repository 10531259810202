import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, ButtonV2 } from 'components';
import './style.scss';

class DownloadStatement extends Component {
  static propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func
  };

  static defaultProps = {
    data: {
      family_id: undefined,
      sub_family_id: undefined,
      subFamilies: [],
      // Can be "teacher" or "parent"
      type: undefined
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      formValues: {},
      loading: false
    };
  }

  componentDidMount() {
    const { type } = this.props.data;

    if (type === 'teacher') req.subFamilies({ family_id: this.props.data.family_id });
  }

  @bind
  submit({ from_date, to_date, sub_family_id }) {
    const { family_id, type = 'teacher' } = this.props.data;

    let action = null;
    switch (type) {
      case 'teacher':
        action = req.downloadStatementTeacher;
        break;

      case 'parent':
        action = req.downloadStatementParent;
        break;
    }

    this.setState({ loading: true });
    action({
      from_date: moment(from_date[0]).format('YYYY-MM-DD'),
      to_date: moment(to_date[0]).format('YYYY-MM-DD'),
      family_id,
      sub_family_id: sub_family_id || this.props.data.sub_family_id
    }).then(res => {
      const newWindow = window.open('about:blank');
      if (newWindow) {
        newWindow.location.replace(res.pdf);
      } else {
        window.location.href = res.pdf;
      }
      this.props.onClose();
    });
  }

  @bind
  emailStatement(e) {
    e.preventDefault();

    const { formValues } = this.state;
    const { data } = this.props;

    const payload = {
      family_id: data.family_id,
      sub_family_id: formValues.sub_family_id || data.sub_family_id,
      from_date: moment(formValues.from_date[0]).format('YYYY-MM-DD'),
      to_date: moment(formValues.to_date[0]).format('YYYY-MM-DD')
    };

    this.setState({ loading: true });
    req
      .emailStatement(payload)
      .then(() => {
        Actions.showFlash('Statement is sent to parents');
        this.props.onClose();
      })
      .catch(err => {
        this.setState({ loading: false });
        Actions.reportError('Unable to email statement.', err);
      });
  }

  @bind
  handleFormUpdate(values) {
    this.setState({ formValues: values });
  }

  getDefaultNextDate() {
    return moment(new Date())
      .add(1, 'M')
      .toDate();
  }

  getSubFamilies() {
    const { type } = this.props.data;

    return type === 'teacher' ? this.props.subFamilies : this.props.data.subFamilies;
  }

  renderSubFamilies() {
    const { type } = this.props.data;
    const subFamilies = this.getSubFamilies();

    if (subFamilies.length === 0 || (type === 'parent' && subFamilies.length === 1)) return null;

    const selectItems = [];
    let defaultSubFamilyId = null;
    if (type === 'teacher') {
      selectItems.push(<Form.Select.Item value={null} label="All Families" />);
    } else {
      defaultSubFamilyId = subFamilies[0].id;
    }
    subFamilies.forEach(sf => {
      selectItems.push(<Form.Select.Item key={sf.id} value={sf.id} label={sf.name} />);
    });

    return (
      <div className="form__row download-statement__subfamily-row">
        <div className="download-statement__subfamily-label">Family</div>
        <Form.Select
          className="download-statement__subfamily-select"
          name="sub_family_id"
          required={type === 'parent'}
          defaultValue={defaultSubFamilyId}
        >
          {selectItems.map(selectItem => selectItem)}
        </Form.Select>
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    const {
      data: { type }
    } = this.props;

    return (
      <Form
        className="modal__wrapper download-statement"
        onInit={this.handleFormUpdate}
        onChange={this.handleFormUpdate}
        onSubmit={this.submit}
      >
        <div className="modal__header">
          <div className="subheader download-statement__title">Statement</div>
        </div>

        <div className="modal__container">
          <div className="download-statement__form">
            {this.renderSubFamilies()}
            <div className="form__row form__row--justified">
              <Form.MonthPicker
                className="download-statement__date-picker"
                name="from_date"
                label="Start Date"
                defaultValue={new Date()}
              />

              <Form.MonthPicker
                className="download-statement__date-picker"
                name="to_date"
                label="End Date"
                rangeStart="from_date"
                maxRangeMonth={12}
                defaultValue={this.getDefaultNextDate()}
              />
            </div>

            <div className="download-statement__description">Up to 12 months</div>
          </div>
        </div>

        <div className="modal__controls">
          {type !== 'parent' && (
            <ButtonV2 isLoading={loading} secondary label="Email Parents" onClick={this.emailStatement} />
          )}
          <Form.Submit isLoading={loading} label="View" />
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  subFamilies: state.subFamilies.data
});

export default connect(mapStateToProps)(DownloadStatement);
