import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typeahead, SelectGroup, ButtonV2 } from 'components';
import Geocode from 'react-geocode';
import { renderAddress } from 'lib/utils';
import GeofenceMap from './GeofenceMap';
import './style.scss';

Geocode.setApiKey(process.env.GOOGLE_API_KEY);
Geocode.setLanguage('en');

class Geofence extends Component {
  static propTypes = {
    data: PropTypes.shape({
      title: PropTypes.string,
      name: PropTypes.string,
      text: PropTypes.string
    })
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);
    this.state = {
      address: renderAddress(props.currentSchool),
      addressValue: '',
      isSaving: false,
      hasDefaultLocation: !!props.currentSchool.latitude,
      lat: props.currentSchool.latitude || 37.0902,
      lng: props.currentSchool.longitude || 95.7129,
      radius: props.currentSchool.geo_distance || 100
    };
  }

  componentDidMount() {
    if (!this.state.hasDefaultLocation) {
      this.loadGeocode(renderAddress(this.props.currentSchool)).then(result => {
        if (result.length) {
          this.handleChangeAddress(result[0].id);
        }
      });
    } else {
      this.loadLocation(this.state.lat, this.state.lng);
    }
  }

  @bind
  handleChangeAddress(json) {
    try {
      const location = JSON.parse(json);
      this.setState({ addressValue: json, ...location });
    } catch (e) {
      // do nothing
    }
  }

  @bind
  handleChangeLocation({ lat, lng }) {
    this.setState({ lat, lng });
    this.loadLocation(lat, lng);
  }

  @bind
  handleChangeRadius(radius) {
    this.setState({ radius: Number(radius) });
  }

  @bind
  loadLocation(lat, lng) {
    Geocode.fromLatLng(lat, lng).then(resp => {
      if (resp.results.length > 0) {
        const address = resp.results[0].formatted_address;
        this.setState({
          address
        });

        if (this.typeahead) {
          this.typeahead.setTypeInput(address);
        }
      }
    });
  }

  @bind
  loadGeocode(address) {
    return Geocode.fromAddress(address).then(
      resp => {
        return resp.results.map(r => ({
          id: JSON.stringify({
            ...r.geometry.location,
            address: r.formatted_address
          }),
          label: r.formatted_address
        }));
      },
      () => {
        return [];
      }
    );
  }

  @bind
  async handleSave() {
    const { lat, lng, radius } = this.state;
    this.setState({ isSaving: true });

    try {
      await req.updateSchoolSettings({ name: 'geo_siso_enabled', value: true });
      await req.updateSchool({
        school: {
          latitude: lat,
          longitude: lng,
          geo_distance: radius
        }
      });
      await req.profile();
      Actions.showFlash('Geofence settings saved successfully');
      this.setState({ isSaving: false });
      this.props.onClose(true);
    } catch (e) {
      this.setState({ isSaving: false });
      Actions.reportError('There was problem saving geofence settings', e);
    }
  }

  renderContent() {
    const { address, lat, lng, radius, hasDefaultLocation } = this.state;

    return (
      <div className="modal__container">
        <div className="form__row geofence__inputs">
          <div className="form-select">
            <label className="form__label">Address</label>
            <Typeahead
              type="radio"
              ref={node => (this.typeahead = node)}
              placeholder="Enter School Address"
              defaultInputValue={address}
              loadOptions={this.loadGeocode}
              onChange={this.handleChangeAddress}
              resetOnBlur={false}
              minCharacters={3}
            />
          </div>
          <div className="form-select">
            <label className="form__label">Geo Distance</label>
            <SelectGroup type="radio" title="Select Radius" checked={radius} onChange={this.handleChangeRadius}>
              <SelectGroup.Item value={25} label="25 meters" />
              <SelectGroup.Item value={50} label="50 meters" />
              <SelectGroup.Item value={100} label="100 meters" />
              <SelectGroup.Item value={200} label="200 meters" />
            </SelectGroup>
          </div>
        </div>

        <div className="geofence__map">
          <GeofenceMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '400px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            hasDefaultLocation={hasDefaultLocation}
            address={address}
            lat={lat}
            lng={lng}
            radius={radius}
            onChange={this.handleChangeLocation}
          />
        </div>
      </div>
    );
  }

  render() {
    const { isSaving } = this.state;
    const { checked } = this.props.data;

    return (
      <div className="geofence">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Setup Curbside (GPS) Location</div>
          <div className="modal__header-subtitle">
            Setup geo-fence circular region around your school so that parents can Sign In-Out their children
            contactless through the app
          </div>
        </div>
        {this.renderContent()}
        <div className="modal__controls">
          <ButtonV2
            label={checked ? 'Update Geo-fence' : 'Enable Geo-fence'}
            onClick={this.handleSave}
            isLoading={isSaving}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentSchool: state.currentUser.data.current_school
});

export default connect(mapStateToProps)(Geofence);
