import React, { Component } from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import { Form, ButtonV2 } from 'components';
import QuestionList from './QuestionList';
import v4 from 'uuid/v4';
import './style.scss';

const SELECTION_SINGLE = 'single';
const DESCRIPTIONS = {
  parent: 'Configure the form that parents will see during every pickup or drop-off.',
  staff: 'Configure the form that staff will see during every clock-in or clock-out.'
};
const TITLES = {
  parent: 'Parent Kiosk Form',
  staff: 'Staff Kiosk Form'
};

class KioskForm extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  static defaultProps = {
    data: {
      kiosk_form: {}
    }
  };

  static createQuestion(id, value = '', selection = SELECTION_SINGLE, answers = []) {
    const isNew = !id;

    return {
      id: id || v4(),
      value,
      selection,
      answers,
      _new: isNew
    };
  }

  constructor(props) {
    super(props);

    const kioskForm = props.data.kiosk_form;
    let questions = [];
    let isQuestionEditing = null;

    if (kioskForm.questions && kioskForm.questions.length) {
      questions = kioskForm.questions.map(({ id, question, selection, answers }) =>
        KioskForm.createQuestion(id, question, selection, answers)
      );
    } else {
      const defaultQuestion = KioskForm.createQuestion();
      isQuestionEditing = defaultQuestion.id;
      questions = [defaultQuestion];
    }

    this.state = {
      questions,
      formValues: {},
      isLoading: false,
      isQuestionEditing
    };
  }

  @bind
  updateQuestion(id, data) {
    const { questions } = this.state;
    const questionIndex = findIndex(questions, { id });

    this.setState({
      questions: [
        ...questions.slice(0, questionIndex),
        { ...questions[questionIndex], ...data },
        ...questions.slice(questionIndex + 1)
      ]
    });
  }

  @bind
  addQuestion() {
    const newQuestion = KioskForm.createQuestion();
    this.setState({
      isQuestionEditing: newQuestion.id,
      questions: [...this.state.questions, newQuestion]
    });
  }

  @bind
  removeQuestion(id) {
    const { questions } = this.state;

    this.setState({
      questions: questions.map(question => ({
        ...question,
        _destroy: question.id === id ? true : question._destroy
      }))
    });
  }

  @bind
  onQuestionEdit(editId) {
    this.setState({
      isQuestionEditing: editId
    });
  }

  @bind
  updateForm(formValues) {
    this.setState({ formValues });
  }

  @bind
  submit() {
    const { kiosk_form: kioskForm, loadFuncName, updateFuncName } = this.props.data;
    const { formValues, questions } = this.state;

    if (questions.length > 5) {
      Actions.reportError('Maximum 5 questions');
    }

    const questionAttributes = questions
      .filter(question => !question._new || !question._destroy)
      .map(question => {
        const { id, value, answers, selection, _destroy, _new } = question;

        return {
          id: !_new ? id : undefined,
          selection,
          question: value,
          answers,
          _destroy
        };
      });

    const requestPayload = {
      question: null,
      answers: null,
      siso_mode: formValues.siso_mode,
      selection: null,
      start_at: formValues.start_at,
      end_at: formValues.end_at,
      enabled: true,
      questions_attributes: questionAttributes
    };

    this.setState({ isLoading: true }, () => {
      req[updateFuncName]({ kiosk_form: requestPayload })
        .then(() => {
          req[loadFuncName]();
          Actions.showFlash(`Kiosk Form is ${kioskForm.enabled ? 'updated' : 'enabled'}`);
          this.props.onClose();
        })
        .catch(err => {
          Actions.reportError('Unable to save Kiosk Form', err);
          this.setState({ isLoading: false });
        });
    });
  }

  render() {
    const { isLoading, isQuestionEditing, questions } = this.state;
    const { kiosk_form: kioskForm, target } = this.props.data;
    const defaultStart = target === 'staff' ? '8:00 AM' : '9:00 AM';
    const defaultEnd = target === 'staff' ? '8:00 PM' : '2:00 PM';

    return (
      <div className="kiosk-form">
        <div className="modal__header kiosk-form__header">
          <div className="modal__header-title kiosk-form__title">{TITLES[target]}</div>

          <div className="kiosk-form__description">{DESCRIPTIONS[target]}</div>
        </div>

        <div className="modal__container kiosk-form__container">
          <Form onInit={this.updateForm} onChange={this.updateForm}>
            <QuestionList
              onAdd={this.addQuestion}
              onUpdate={this.updateQuestion}
              onRemove={this.removeQuestion}
              questions={questions}
              onEdit={this.onQuestionEdit}
              defaultEditId={isQuestionEditing}
            />

            <div className="kiosk-form__section">
              <div className="kiosk-form__label">When should the form be displayed?</div>

              <div className="kiosk-form__when-to-show">
                <span>Show the form every</span>

                <Form.Select
                  name="siso_mode"
                  defaultValue={kioskForm.siso_mode || 'signin'}
                  className="kiosk-form__status"
                  data-cy="siso_mode"
                >
                  <Form.Select.Item value="signin" label={target === 'staff' ? 'Clock-In' : 'Sign-In'} />
                  <Form.Select.Item value="signout" label={target === 'staff' ? 'Clock-Out' : 'Sign-Out'} />
                </Form.Select>

                <span>between</span>

                <Form.TimeInput
                  data-cy={'start_at'}
                  name="start_at"
                  defaultValue={kioskForm.start_at || defaultStart}
                />

                <span>to</span>

                <Form.TimeInput data-cy={'end_at'} name="end_at" defaultValue={kioskForm.end_at || defaultEnd} />
              </div>
            </div>
          </Form>
        </div>

        <div className="modal__controls">
          <ButtonV2
            isLoading={isLoading}
            disabled={!!isQuestionEditing}
            label="Enable Form"
            data-cy={'enable-form'}
            onClick={this.submit}
          />
        </div>
      </div>
    );
  }
}

export default KioskForm;
