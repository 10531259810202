import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import range from 'lodash/range';
import TodayCaret from './TodayCaret';
import EventItem from './EventItem';
import './WeekDay.scss';

function Weekday({
  date,
  events,
  allowCreate,
  onSelect = () => {
    return;
  },
  showCaret = true
}) {
  const isToday = date.isSame(new Date(), 'day');
  const handleSelect = (event, systemEvent) => {
    onSelect({
      anchor: systemEvent.target,
      event
    });
  };
  const handleCreate = index => {
    if (!allowCreate) {
      return;
    }

    const startsAt = date
      .startOf('day')
      .add(15 * index, 'minute')
      .format();

    Actions.showModal('EditCalendarEvent', { startsAt }, { noCloseIcon: true });
  };
  const cn = clsx('weekly-calendar__weekday-slots', {
    'weekly-calendar__weekday-slots--today': isToday
  });

  return (
    <div className="weekly-calendar__weekday">
      <div className={cn}>
        {range(4 * 24).map(i => (
          <div
            className="weekly-calendar__weekday-slots__item"
            key={`${date.format()}_${i}`}
            onClick={() => handleCreate(i)}
          />
        ))}
      </div>
      {events.map(e => (
        <EventItem key={e.id} event={e} allEvents={events} onClick={evt => handleSelect(e, evt)} />
      ))}
      {isToday && showCaret && <TodayCaret />}
    </div>
  );
}

Weekday.propTypes = {
  allowCreate: PropTypes.bool,
  showCaret: PropTypes.bool,
  date: PropTypes.object,
  events: PropTypes.array,
  allDayEvents: PropTypes.array,
  onSelect: PropTypes.func
};

export default Weekday;
