import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'components';
import './style.scss';

class CreateSisoAlert extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    data: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isEdit: !!(props.data && props.data.alert)
    };
  }

  UNSAFE_componentWillMount() {
    req.rooms();
    req.staff();
  }

  @bind
  submit(values) {
    const { isEdit } = this.state;
    const payload = {
      alert: {
        ...values,
        section_ids: values.section_ids ? [values.section_ids] : [],
        students_count: parseInt(values.students_count, 10)
      }
    };

    if (isEdit) {
      payload.id = this.props.data.alert.id;
    }

    this.setState({ isLoading: true });

    const promise = isEdit ? req.updateSisoAlert(payload, { id: payload.id }) : req.addSisoAlert(payload);

    promise
      .then(() => {
        Actions.showFlash('Alert saved successfully');
        this.setState({ isLoading: false });
        this.props.onClose();
      })
      .catch(err => {
        this.setState({ isLoading: false });

        Actions.reportError('There was problem saving alert', err);
      });
  }

  render() {
    const { data, rooms, staffs } = this.props;
    const { isEdit, isLoading } = this.state;
    const sisoAlert = (data && data.alert) || {};

    const startTime = sisoAlert.start_time || '07:00 AM';
    const endTime = sisoAlert.end_time || '06:00 PM';

    return (
      <div className="modal__wrapper create-siso-alert">
        <div className="modal__header modal__header--bordered create-siso-alert__header">
          <div className="create-siso-alert__header-title">
            <span className="modal__header-title">{isEdit ? 'Edit' : 'Create'} Ratio Alert</span>
          </div>

          <div className="modal__header-desc">
            Monitor your student to staff ratios at all times. You will need to use Student Sign-In and Staff Clock-In
            feature to track ratios.
          </div>
        </div>

        <Form className="create-siso-alert__form" onSubmit={this.submit} isLoading={isLoading}>
          <div className="create-siso-alert__section">
            <div className="create-siso-alert__section-header">Set Student:Staff Ratio</div>

            <div className="create-siso-alert__section-body">
              <div className="create-siso-alert__inline">
                <div className="create-siso-alert__inline-label create-siso-alert__inline-label--center">
                  Number of Students
                </div>
                <Form.Input
                  data-cy="students-count"
                  defaultValue={sisoAlert.students_count}
                  placeholder="ex: 6"
                  type="number"
                  required
                  className="create-siso-alert__student-count"
                  name="students_count"
                />
                &nbsp;&nbsp;per staff
              </div>
              <div className="create-siso-alert__desc">
                Alert will be sent if you have more students per staff or more staff per students
              </div>
            </div>

            <div className="create-siso-alert__section">
              <div className="create-siso-alert__section-header">Configure your Alert</div>

              <div className="create-siso-alert__section-body">
                <div className="create-siso-alert__inline">
                  <div className="create-siso-alert__inline-label">Room to Monitor</div>
                  <Form.Select
                    data-cy="section-ids"
                    name="section_ids"
                    title="Select Room"
                    type="radio"
                    required
                    defaultValue={sisoAlert.section_ids ? sisoAlert.section_ids[0] : undefined}
                    enableClear
                  >
                    {rooms.map(room => (
                      <Form.Select.Item key={room.id} value={room.id} label={room.name} />
                    ))}
                  </Form.Select>
                </div>

                <div className="create-siso-alert__inline">
                  <div className="create-siso-alert__inline-label">Days</div>
                  <Form.WeekdayPicker
                    name="schedule_days"
                    defaultValue={sisoAlert.schedule_days || ['M', 'T', 'W', 'Th', 'F']}
                    required
                  />
                </div>

                <div className="create-siso-alert__inline">
                  <div className="create-siso-alert__inline-label">Time Range</div>
                  <Form.TimeInput name="start_time" required defaultValue={startTime} />
                  <span className="create-siso-alert__time-to">to</span>
                  <Form.TimeInput name="end_time" required defaultValue={endTime} />
                </div>
              </div>
            </div>

            <div className="create-siso-alert__section-header">Send Alert Notification to</div>

            <div className="create-siso-alert__section-body">
              <div className="create-siso-alert__inline">
                <div className="create-siso-alert__inline-label">Select Staff</div>

                <Form.Select
                  name="teacher_ids"
                  title="Select Staff"
                  type="checkbox"
                  multipleSelectionText="staffs selected"
                  defaultValue={sisoAlert.teacher_ids || []}
                  enableClear
                >
                  {staffs.map(staff => (
                    <Form.Select.Item key={staff.id} value={staff.id} label={staff.name} />
                  ))}
                </Form.Select>
              </div>
              <div className="create-siso-alert__desc">
                Alert will be sent as email and push notification to the staff
              </div>
            </div>

            <div className="create-siso-alert__section">
              <div className="create-siso-alert__section-header">Set Name for your Alert</div>

              <div className="create-siso-alert__section-body">
                <div className="create-siso-alert__inline">
                  <div className="create-siso-alert__inline-label">Name</div>

                  <Form.Input data-cy="name" defaultValue={sisoAlert.name} required name="name" />
                </div>
              </div>
            </div>
          </div>

          <div className="form__row--actions create-siso-alert__actions">
            <Form.Submit label="Save" data-cy="save" />
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  staffs: state.staff.data
});

export default connect(mapStateToProps)(CreateSisoAlert);
