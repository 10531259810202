import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from 'components';

class Row extends PureComponent {
  static propTypes = {
    template: PropTypes.object,
    selected: PropTypes.bool,
    onClick: PropTypes.func
  };

  @bind
  handleClick() {
    const { onClick, template } = this.props;

    if (onClick) {
      onClick(template);
    }
  }

  render() {
    const { template, selected } = this.props;

    const rowClassName = cx('select-newsletter-templates__row', {
      'select-newsletter-templates__row--current': selected
    });

    return (
      <div className={rowClassName} onClick={this.handleClick}>
        <Icon
          className="select-newsletter-templates__type-icon"
          name={template.editor_type === 'html' ? 'newsletter-code' : 'newsletter-template'}
        />

        <div className="select-newsletter-templates__row-name">{template.title}</div>

        <Icon className="select-newsletter-templates__row-change" name="tick-1" />
      </div>
    );
  }
}

export default Row;
