import React from 'react';
import PropTypes from 'prop-types';
import { Icon, ActionButton } from 'components';
import './style.scss';

function CardInfo({ token, method = 'Credit Card', onAdd, onEdit, last4 = 'XXXX' }) {
  if (token) {
    return (
      <div className="card-info card-info--has">
        <Icon name="credit-card" className="card-info__icon" size={23} />
        <div className="card-info__text">{`XXXX - XXXX - XXXX - ${last4}`}</div>
        <ActionButton onClick={onEdit} iconName="edit" className="card-info__edit" size={14} />
      </div>
    );
  }

  return (
    <div className="card-info card-info--empty" onClick={onAdd}>
      <Icon name="credit-card" className="card-info__icon" size={23} />
      <div className="card-info__text">Add {method}</div>
    </div>
  );
}

CardInfo.propTypes = {
  token: PropTypes.any,
  method: PropTypes.string,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  last4: PropTypes.string
};

export default CardInfo;
