import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ActivityForms from '../common/ActivityForms';
import Steps from '../ModalController/Steps';
import SelectKids from '../common/SelectKids';
import buildActivityPayload from '../common/buildActivityPayload';
import ActivityInfoStep from './ActivityInfoStep';
import ActivityScheduleStep from './ActivityScheduleStep';
import { createActivityObject } from 'lib/utils';
import './style.scss';

class CreateAutoActivity extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      type: 'nap_activity',
      activity: null,
      kids: [],
      isLoading: false,
      isEditing: !!props.data
    };

    this.state.activity = this.makeActivity(this.state.type);

    if (props.data && props.data.activity) {
      this.state.activity = {
        ...this.state.activity,
        ...props.data.activity,
        data: props.data.activity.activity_template
      };
      this.state.type = props.data.activity.activity_type;
      this.state.kids = props.data.activity.kid_ids;
    }
  }

  UNSAFE_componentWillMount() {
    req.students();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.type !== prevState.type) {
      this.setState({ activity: this.makeActivity(this.state.type) });
    }
  }

  makeActivity(type, values = {}) {
    const newActivity = createActivityObject(type, values);

    return {
      name: '',
      ...newActivity,
      ...this.state.activity
    };
  }

  @bind
  updateActivityType(activityType) {
    this.setState({ type: activityType });
  }

  @bind
  updateActivityName(name) {
    this.setState({
      activity: {
        ...this.state.activity,
        name
      }
    });
  }

  @bind
  updateActivity(activity) {
    const { staffOnlyActivities } = this.props;
    const { type } = this.state;

    const payload = buildActivityPayload(type, {
      data: {
        ...activity,
        staffOnly: staffOnlyActivities.includes(type) || activity.staffOnly
      }
    });

    this.setState({
      activity: {
        ...this.state.activity,
        ...payload.daily_activity,
        activity_time: this.state.activity.activity_time
      }
    });
  }

  @bind
  updateScheduleData(values) {
    this.setState({
      activity: {
        ...this.state.activity,
        ...values
      }
    });
  }

  @bind
  updateKids(kids) {
    this.setState({ kids });
  }

  @bind
  submit(kidIds) {
    const { activity, type, isEditing } = this.state;

    const requestPayload = {
      kid_ids: kidIds,
      days: activity.days,
      name: activity.name,
      activity_type: type,
      activity_time: activity.activity_time,
      ends_at: activity.ends_at ? moment(activity.ends_at).format('YYYY-MM-DD') : undefined,
      activity_template: activity.data
    };

    this.setState({ isLoading: true });

    if (isEditing) {
      req
        .updateRecurringActivity({
          ...requestPayload,
          id: activity.id
        })
        .then(() => {
          Actions.showFlash('Updated auto activity');
          this.setState({ isLoading: false });
          this.props.onClose();
        })
        .catch(err => {
          Actions.reportError('Unable to update auto activity', err);
          this.setState({ isLoading: false });
        });
    } else {
      req
        .createRecurringActivity(requestPayload)
        .then(() => {
          Actions.showFlash('Added new auto activity');
          this.setState({ isLoading: false });
          this.props.onClose();
        })
        .catch(err => {
          Actions.reportError('Unable to create auto activity', err);
          this.setState({ isLoading: false });
        });
    }
  }

  render() {
    const { type, isLoading, activity, isEditing } = this.state;
    const { constants, kids, staffOnlyActivities } = this.props;
    const ActivityForm = ActivityForms[type];
    const forceStaff = staffOnlyActivities.includes(type);

    return (
      <div className="create-activity">
        <Steps>
          <Steps.Item>
            <ActivityInfoStep
              title={isEditing ? 'Edit Auto Activity' : 'Create Auto Activity'}
              FormComponent={ActivityForm}
              activity={this.makeActivity(type, activity)}
              constants={constants}
              onTypeChange={this.updateActivityType}
              onNameChange={this.updateActivityName}
              onSave={this.updateActivity}
              type={type}
              forceStaff={forceStaff}
            />
          </Steps.Item>

          <Steps.Item>
            <ActivityScheduleStep
              title={isEditing ? 'Edit Auto Activity' : 'Create Auto Activity'}
              activity={this.makeActivity(type, activity)}
              onSave={this.updateScheduleData}
            />
          </Steps.Item>

          <Steps.Item>
            <SelectKids
              onSubmit={this.submit}
              onUpdate={this.updateKids}
              students={kids}
              defaultSelected={this.state.kids}
              label="Save & Set Active"
              isContinueLoading={isLoading}
              finalStep
            />
          </Steps.Item>
        </Steps>
      </div>
    );
  }
}

const mapStateToProps = ({ constants, students, currentUser }) => ({
  constants: constants.data.daily_activities,
  kids: students.data,
  staffOnlyActivities: currentUser.data.current_school.staff_only_activities
});

export default connect(mapStateToProps)(CreateAutoActivity);
