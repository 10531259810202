import React from 'react';
import moment from 'moment';
import { Form } from 'components';
import TEXT_CAPTIONS from './constants';
import BATHROOM_TYPES from 'constants/bathroomTypes';
import DIAPER_SIZES from 'constants/diaperSizes';
import StaffPresentSelect from './StaffPresentSelect';

const BathroomActivityForm = ({ activity, formValues = {}, useDate = true, forceStaff }) => {
  const types = BATHROOM_TYPES[formValues.type || activity.data.type] || [];
  return (
    <div className="form__body">
      {useDate && (
        <div className="form__row form__row--datetime">
          <Form.DatePicker label="Date" name="date" defaultValue={moment(activity.activity_time).toDate()} required />

          <Form.TimeInput label="Time" name="time" defaultValue={Helpers.formatTime(activity.activity_time)} required />
        </div>
      )}

      <div className="form__row form__row--justified">
        <Form.Select name="type" defaultValue={activity.data.type} title="Type" label="Type" required>
          {Object.keys(BATHROOM_TYPES).map(type => (
            <Form.Select.Item label={type} value={type} key={type} />
          ))}
        </Form.Select>

        <Form.Select
          name="sub_type"
          defaultValue={activity.data.sub_type}
          title="Sub Type"
          label="Sub Type"
          data-cy="sub-type"
          required
        >
          {types.map(type => (
            <Form.Select.Item label={type} value={type} key={type} />
          ))}
        </Form.Select>
      </div>
      {(formValues.type || activity.data.type) === 'Diaper' && (
        <div className="form__row form__row--justified">
          <Form.Select
            name="diaper_size"
            defaultValue={activity.data.diaper_size}
            title="Diaper Size"
            label="Diaper Size"
          >
            {DIAPER_SIZES.map(type => (
              <Form.Select.Item label={type} value={type} key={type} />
            ))}
          </Form.Select>
          <div className="form-input mt-30">
            <Form.Checkbox
              type="circle"
              name="diaper_cream"
              label="Diaper Cream Applied"
              defaultValue={activity.data.diaper_cream}
            />
          </div>
        </div>
      )}

      <StaffPresentSelect activity={activity} />

      <div className="form__row">
        <Form.Textarea
          name="description"
          defaultValue={activity.data.desc}
          placeholder="Description"
          label="Description"
        />
      </div>

      <div className="form__row">
        {forceStaff ? (
          TEXT_CAPTIONS.staffOnlyForce
        ) : (
          <Form.Checkbox
            type="circle"
            name="staffOnly"
            label={TEXT_CAPTIONS.staffOnly}
            defaultValue={activity.is_staff_only}
          />
        )}
      </div>
    </div>
  );
};

export default BathroomActivityForm;
