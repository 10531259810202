import React, { Component } from 'react';
import './style.scss';

class PageHeader extends Component {
  render() {
    const { title, breadcrumbs, className } = this.props;

    return (
      <div className={`page-header ${className || ''}`}>
        <div className="breadcrumbs">
          {breadcrumbs}
          <span className="page-header__inner">{title}</span>
        </div>
      </div>
    );
  }
}

export default PageHeader;
