import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Steps from 'modals/ModalController/Steps';
import Step1 from './Step1';
import Step2 from './Step2';
import './style.scss';

class EnrollRegistration extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isScheduling: false
    };
  }

  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.string,
      kid: PropTypes.object.isRequired,
      confirmationOnly: PropTypes.bool
    })
  };

  static defaultProps = {
    confirmationOnly: false
  };

  @bind
  setSchedule(isScheduling) {
    this.setState({ isScheduling });
  }

  render() {
    const { data, onClose } = this.props;
    const { kid, id, confirmationOnly } = data;

    return (
      <Steps>
        <Steps.Item>
          <Step1 kid={kid} setSchedule={this.setSchedule} />
        </Steps.Item>
        <Steps.Item>
          <Step2
            confirmationOnly={confirmationOnly}
            kid={kid}
            regId={id}
            isScheduling={this.state.isScheduling}
            onClose={onClose}
          />
        </Steps.Item>
      </Steps>
    );
  }
}

export default EnrollRegistration;
