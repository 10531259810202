import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';

function FilterItemAgeFilter({ name, defaultValue, ...otherProps }) {
  return (
    <div className="form__row">
      <Form.AgeFilter name={name} defaultValue={defaultValue} {...otherProps} />
    </div>
  );
}

FilterItemAgeFilter.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.object
};

export default FilterItemAgeFilter;
