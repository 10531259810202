import React, { useContext } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { ActionButton, ButtonV2, Preloader } from 'components';
import { ModalControllerStepsContext } from 'hocs/withContext';

function ValidationStep({
  currentBalance,
  activePlans,
  onApplyCredit,
  onPausePlans,
  applyingCredit,
  pausingPlans,
  onClose
}) {
  const context = useContext(ModalControllerStepsContext);
  const validBalance = currentBalance <= 0;
  const validPlans = !activePlans;
  const isLoading = pausingPlans || applyingCredit;

  return (
    <div className="modal__wrapper">
      <div className="modal__header">
        <span className="modal__header-title">Multifamily Transactions</span>
        <ActionButton iconName="close" onClick={() => onClose()} size={12} className="modal__close" />
      </div>
      <div className="modal__container split-family__validation">
        <div className="split-family__validation-item">
          <div className="split-family__validation-item-text">
            {`A zero balance is required to continue setting
            up split payments.

            You will be able to apply new billing plans for
            each child following the set up
            of split payments.`}
          </div>
          <div className="split-family__validation-item-action">
            {applyingCredit && <Preloader small className="split-family__validation-item-action-preloader" />}
            <ActionButton
              className={cx('split-family__validation-item-action-button', {
                'split-family__validation-item-action-button--valid': validBalance
              })}
              iconName={validBalance ? 'tick' : 'alert'}
              title={`${validBalance ? 'Applied' : 'Apply'} Credit`}
              onClick={onApplyCredit}
              disabled={validBalance}
            />
          </div>
        </div>
        <div className="split-family__validation-item">
          <div className="split-family__validation-item-text">
            {`Active billing plans must be paused or
            removed to continue setting up split payments.

            You will be able to apply new billing plans for
            each child following the set up of split
            payments.`}
          </div>
          <div className="split-family__validation-item-action">
            {pausingPlans && <Preloader small className="split-family__validation-item-action-preloader" />}
            <ActionButton
              className={cx('split-family__validation-item-action-button', {
                'split-family__validation-item-action-button--valid': validPlans
              })}
              iconName={validPlans ? 'tick' : 'alert'}
              title={validPlans ? 'Plans Paused' : 'Pause Plans'}
              onClick={onPausePlans}
              disabled={validPlans}
            />
          </div>
        </div>
      </div>
      <div className="modal__controls">
        <ButtonV2 secondary label="back" onClick={context.prevStep} />
        <ButtonV2
          label="next"
          onClick={context.nextStep}
          disabled={!validBalance || !validPlans}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  familyKids: state.familyTransactions.data.kids
});

export default connect(mapStateToProps)(ValidationStep);
