import React from 'react';
import { Form } from 'components';

import './style.scss';

function SelectSection({ sections, defaultSelected, title, description, label, onSubmit }) {
  const handleSubmit = values => {
    return onSubmit(values.section_id);
  };

  return (
    <div className="modal__wrapper select-section">
      <div className="modal__header">
        <div className="modal__header-title">{title || 'Select Room'}</div>
      </div>
      <div className="modal__container">
        <Form className="select-section__form" onSubmit={handleSubmit}>
          <Form.Select label="Select Room" defaultValue={defaultSelected} name="section_id" type="radio" required>
            {sections.map(s => (
              <Form.Select.Item key={s.id} value={s.id} label={s.name} />
            ))}
          </Form.Select>
          {description && <div className="select-section__desc">{description}</div>}
          <div className="select-section__footer form__row--actions">
            <Form.Submit label={label || 'Print'} />
          </div>
        </Form>
      </div>
    </div>
  );
}

export default SelectSection;
