import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Form, Checkbox, ButtonV2 } from 'components';
import FormFieldType, { TypeToCustomAttribute } from 'constants/FormFieldType';
import TextInputOptions from './TextInputOptions';
import SelectOptions from './SelectOptions';
import TextBoxOptions from './TextBoxOptions';
import { withPermissionsChecking } from 'hocs/withPermissions';
import withLaunchDarkly from 'hocs/withLaunchDarkly';

const BACKEND_MAPPER_NAMES = {
  email: 'email',
  allergies: 'allergy',
  medications: 'medications',
  address: 'street_address',
  city: 'city',
  leadState: 'region',
  studentState: 'state',
  zip: 'zip'
};

// The required status of the above fields (except email) can be overridden by the user
const REQUIRABLE_DEFAULT_FIELDS = Object.values(BACKEND_MAPPER_NAMES).slice(1);

class OptionForm extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    options: PropTypes.object,
    actionType: PropTypes.oneOf(['create', 'edit']),
    customAttributes: PropTypes.array,
    onSubmit: PropTypes.func
  };

  static defaultProps = {
    type: undefined,
    actionType: 'create',
    options: {}
  };

  static defaultValues = {
    customAttributeId: null,
    mapper: null
  };

  static optionFormTypes = [
    FormFieldType.TextInput,
    FormFieldType.Checkbox,
    FormFieldType.Select,
    FormFieldType.DatePicker,
    FormFieldType.TextBox,
    FormFieldType.FileUpload
  ];

  constructor(props) {
    super(props);

    this.state = {
      updatedValues: {
        ...OptionForm.defaultValues,
        ...props.options
      },
      additionalOptions: {
        [props.type]: this.getAdditionalOptions(props.type, props.options)
      },
      isCustomAttributeEnabled: props.actionType === 'create' || !!props.options.customAttributeId || !!props.mapper
    };

    this.formRef;
  }

  get isCustomAttributeReadOnly() {
    const { mapper, options } = this.props;

    return !!mapper && !options.customAttributeId;
  }

  getAdditionalOptions(type, options) {
    switch (type) {
      case FormFieldType.Select:
        return { items: options.items };

      case FormFieldType.TextBox:
        return { value: options.value };

      default:
        return {};
    }
  }

  getTypeFormComponent(type) {
    switch (type) {
      case FormFieldType.TextInput:
        return TextInputOptions;

      case FormFieldType.Checkbox:
        return null;

      case FormFieldType.Select:
        return SelectOptions;

      case FormFieldType.TextBox:
        return TextBoxOptions;

      case FormFieldType.DatePicker:
        return null;

      default:
        return null;
    }
  }

  shouldDisplayForm(type) {
    return OptionForm.optionFormTypes.includes(type);
  }

  canSubmit(type) {
    switch (type) {
      case FormFieldType.TextBox: {
        const textboxOptions = this.state.additionalOptions.textbox;

        if (!textboxOptions) {
          return false;
        }

        return typeof textboxOptions.value === 'string' && textboxOptions.value.length > 0;
      }

      default:
        return true;
    }
  }

  isCustomAttrsAllowed() {
    return Boolean(TypeToCustomAttribute[this.props.type] && this.props.customAttributes);
  }

  @bind
  handleSubmit(values) {
    if (!this.props.onSubmit) {
      return;
    }

    const additionalOptions = this.state.additionalOptions[this.props.type] || {};

    this.props.onSubmit({
      ...values,
      ...additionalOptions
    });
  }

  @bind
  handleUpdate(values) {
    const { updatedValues, additionalOptions, isCustomAttributeEnabled } = this.state;
    const { type, customAttributes } = this.props;
    const nextState = {
      updatedValues: {
        ...updatedValues,
        ...values,
        customAttributeId: isCustomAttributeEnabled ? values.customAttributeId : null
      }
    };

    if (
      this.isCustomAttrsAllowed() &&
      (updatedValues.customAttributeId || null) !== (values.customAttributeId || null)
    ) {
      const attribute = customAttributes.find(attr => attr.id === values.customAttributeId);
      this.formRef.updateField('label', get(attribute, 'name', ''));
      if (FormFieldType.Select === type) {
        const attributeSelectItems = get(attribute, 'data.options', []).map(option => ({
          value: option.id,
          label: option.name
        }));
        this.formRef.updateField('type', 'radio');
        nextState.updatedValues.items = attributeSelectItems;
        nextState.additionalOptions = {
          ...additionalOptions,
          [type]: {
            items: attributeSelectItems
          }
        };
      }
    }

    this.setState(nextState);
  }

  @bind
  updateAdditionalOptions(type) {
    return value => {
      this.setState({
        additionalOptions: {
          ...this.state.additionalOptions,
          [type]: value
        }
      });
    };
  }

  @bind
  addCustomAttribute() {
    const { type, customAttributesKeeper } = this.props;
    const { updatedValues, additionalOptions } = this.state;

    Actions.showModal('AddCustomAttribute', {
      presetKeeper: customAttributesKeeper,
      customAttribute: {
        name: updatedValues.label,
        field_type: TypeToCustomAttribute[type],
        data: {
          options:
            type === FormFieldType.Select
              ? additionalOptions[FormFieldType.Select].items?.map(option => ({
                  id: option.value,
                  name: option.label
                }))
              : undefined
        }
      },
      isFieldTypeDisabled: true
    }).then(result => {
      if (result) {
        this.formRef.updateField('customAttributeId', result.id);
      }
    });
  }

  @bind
  toggleCustomAttributeEnabled() {
    this.setState(
      {
        isCustomAttributeEnabled: !this.state.isCustomAttributeEnabled
      },
      () => this.handleUpdate(this.state.updatedValues)
    );
  }

  render() {
    const { mapper, type, options, actionType, customAttributes, description, hasWritePermissions, flags } = this.props;
    const { additionalOptions, updatedValues, isCustomAttributeEnabled } = this.state;
    const shouldDisplayForm = this.shouldDisplayForm(type);
    const ComponentOptionForm = this.getTypeFormComponent(type);
    const buttonText = `${actionType === 'create' ? 'Add' : 'Update'} Form Field`;
    const canSubmit = this.canSubmit(type);
    const displayCustomAttributes = this.isCustomAttrsAllowed();
    const isFieldSectionDisabled =
      displayCustomAttributes && isCustomAttributeEnabled && !updatedValues.customAttributeId;

    return (
      <Form
        ref={node => (this.formRef = node)}
        className="create-form-field-v2__options"
        onSubmit={this.handleSubmit}
        onChange={this.handleUpdate}
      >
        {shouldDisplayForm && (
          <React.Fragment>
            {displayCustomAttributes && (
              <div className="create-form-field-v2__custom-attribute">
                <div className="form__label create-form-field-v2__section-label">
                  2. Do you want the data saved to profile?
                </div>
                <div className="school-reg-payment__enable-form-controls">
                  <Checkbox
                    data-cy="yes"
                    type="circle"
                    label="Yes"
                    onChange={!isCustomAttributeEnabled && this.toggleCustomAttributeEnabled}
                    checked={isCustomAttributeEnabled}
                    disabled={this.isCustomAttributeReadOnly}
                  />
                  <Checkbox
                    data-cy="no"
                    type="circle"
                    label="No"
                    onChange={isCustomAttributeEnabled && this.toggleCustomAttributeEnabled}
                    checked={!isCustomAttributeEnabled}
                    disabled={this.isCustomAttributeReadOnly}
                  />
                </div>
                {isCustomAttributeEnabled && (
                  <Form.Select
                    data-cy={'choose-attribute'}
                    name="customAttributeId"
                    title={flags['online_profilefields_mapping'] ? 'Choose a profile field' : 'Choose a custom field'}
                    defaultValue={options.customAttributeId || null}
                    required={isCustomAttributeEnabled && !this.isCustomAttributeReadOnly}
                    disabled={!isCustomAttributeEnabled || this.isCustomAttributeReadOnly}
                    isActionSticky
                    action={
                      hasWritePermissions('school_settings') ? (
                        <ButtonV2
                          tertiary
                          data-cy={'create-custom-attribute'}
                          onClick={this.addCustomAttribute}
                          className="create-form-field-v2__custom-attribute__add"
                          label={flags['online_profilefields_mapping'] ? 'Create Profile Field' : 'Create Custom Field'}
                        />
                      ) : (
                        undefined
                      )
                    }
                  >
                    {this.isCustomAttributeReadOnly && (
                      <Form.Select.Item
                        label={mapper?.attribute_description || 'Not Selected'}
                        isDefault
                        value={null}
                      />
                    )}
                    {customAttributes
                      .filter(attr => attr.field_type === TypeToCustomAttribute[type])
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(attr => (
                        <Form.Select.Item key={attr.id} label={attr.name} value={attr.id} />
                      ))}
                  </Form.Select>
                )}
              </div>
            )}
            <div className="create-form-field-v2__options-inner">
              <div className="form__label create-form-field-v2__section-label">
                {displayCustomAttributes ? '3' : '2'}. Field Details
              </div>
              <div className="create-form-field-v2__description">{description}</div>
              <Form.Input
                data-cy="label"
                name="label"
                label="Field Label"
                placeholder="Field label"
                defaultValue={options.label}
              />
              {ComponentOptionForm && (
                <ComponentOptionForm
                  options={updatedValues}
                  additionalOptions={additionalOptions[type]}
                  customAttributeId={updatedValues.customAttributeId}
                  onChange={this.updateAdditionalOptions(type)}
                  disabled={isFieldSectionDisabled}
                  canRequireEmail={
                    !this.isCustomAttributeReadOnly || mapper?.attribute_name === BACKEND_MAPPER_NAMES.email
                  }
                />
              )}

              {type !== FormFieldType.TextBox && (
                <Form.Checkbox
                  data-cy="required"
                  name="required"
                  label="This is a required field"
                  defaultValue={options.required}
                  disabled={isFieldSectionDisabled && !REQUIRABLE_DEFAULT_FIELDS.includes(mapper?.attribute_name)}
                />
              )}
            </div>
          </React.Fragment>
        )}

        <div className="modal__controls">
          <Form.Submit label={buttonText} disabled={!canSubmit} data-cy="submit" />
        </div>
      </Form>
    );
  }
}

export default withPermissionsChecking(withLaunchDarkly(OptionForm));
