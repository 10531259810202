import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typeahead } from 'components';
import validationText from '../validationText';

import './style.scss';
import withContext, { FormContext } from 'hocs/withContext';

class FormTypeahead extends Component {
  static propTypes = {
    // Required to create form data properly, should be unique
    name: PropTypes.string.isRequired,

    // A default value
    defaultValue: PropTypes.string,

    // If provided, it will render a label above the field
    label: PropTypes.string,

    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    ),

    loadOptions: PropTypes.func,

    minCharacters: PropTypes.number,

    // If true, no invalid styles are applied to the field
    // Provided validations will still work, it only disables the styling
    disableInvalid: PropTypes.bool,

    'data-cy': PropTypes.string,

    disabled: PropTypes.bool,

    hideClear: PropTypes.bool,

    hideNoMatch: PropTypes.bool
  };

  UNSAFE_componentWillMount() {
    const initialValue = this.props.defaultValue || '';

    // These operations create a validation object's structure
    // and send it to Form component.
    const validations = {};

    if (this.props.required) {
      validations.isNotEmpty = null;
    }

    this.props.context.init(this.props.name, initialValue, validations);
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  /**
   * Update value of a field
   *
   * @param e - an event with new value for a field with the name === this.props.name
   */
  @bind
  handleChange(value, typeInput, option) {
    const { passInputValue } = this.props;
    if (passInputValue) {
      this.props.context.update(this.props.name, option);
    } else {
      this.props.context.update(this.props.name, value);
    }
  }

  /**
   * Update the validation state on blur
   */
  @bind
  handleBlur() {
    this.props.context.validate(this.props.name);
  }

  /**
   * Get a text for an invalid field to render.
   *
   * @return {string} - a text which represents a validation error
   */
  getValidationText() {
    const { name } = this.props;
    const { values, validations, errors } = this.props.context;

    if (errors[name]) {
      return errors[name].join('; ');
    }

    let invalidText = '';

    if (validations[name] && validations[name].isNotEmpty === false && values[name].length === 0) {
      invalidText = validationText.isNotEmpty;
    }

    return invalidText;
  }

  render() {
    const { values } = this.props.context;
    const {
      name,
      label,
      placeholder,
      disableInvalid,
      options,
      action,
      className,
      loadOptions,
      minCharacters,
      disabled,
      hideClear,
      hideNoMatch,
      'data-cy': dataCY,
      passInputValue
    } = this.props;
    const invalidText = this.getValidationText();

    const formInputCN = classNames({
      'form-input': true,
      'form-input--invalid': Boolean(invalidText) && !disableInvalid,
      [className]: Boolean(className)
    });

    const updatedValue = passInputValue && values[name] ? values[name].id : values[name];

    return (
      <div className={formInputCN}>
        {label && (
          <label className="form__label" htmlFor={name}>
            {label}
          </label>
        )}

        <Typeahead
          value={updatedValue || ''}
          options={options}
          loadOptions={loadOptions}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          placeholder={placeholder}
          minCharacters={minCharacters}
          data-cy={dataCY}
          disabled={disabled}
          hideClear={hideClear}
          action={action}
          hideNoMatch={hideNoMatch}
        />
        {invalidText && !disableInvalid && (
          <div className="form-input__validation-text" data-cy={dataCY ? `${dataCY}-invalid` : undefined}>
            {invalidText}
          </div>
        )}
      </div>
    );
  }
}

export default withContext(FormContext)(FormTypeahead);
