import React, { useContext } from 'react';
import cx from 'classnames';
import TableContext from './Context';

function Cell({ className, children, name, 'data-cy': dataCY, onClick }) {
  const displayedFields = useContext(TableContext);

  if (name && !displayedFields[name]) return null;

  return (
    <div className={cx('table__cell', className)} data-cy={dataCY} onClick={onClick}>
      {children}
    </div>
  );
}

export default Cell;
