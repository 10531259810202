import React from 'react';
import moment from 'moment';
import { Form } from 'components';
import TEXT_CAPTIONS from './constants';
import MOOD_TYPES from 'constants/moodTypes';
import StaffPresentSelect from './StaffPresentSelect';

const MoodActivityForm = ({ activity, useDate = true, forceStaff }) => {
  return (
    <div className="form__body">
      {useDate && (
        <div className="form__row form__row--datetime">
          <Form.DatePicker label="Date" name="date" defaultValue={moment(activity.activity_time).toDate()} required />

          <Form.TimeInput label="Time" name="time" defaultValue={Helpers.formatTime(activity.activity_time)} required />
        </div>
      )}

      <div className="form__row">
        <Form.Select
          name="mood"
          defaultValue={activity.data.mood}
          title="Mood"
          label="Mood"
          className="edit-activity__mood"
          data-cy="mood"
          required
        >
          {MOOD_TYPES.map(type => (
            <Form.Select.Item label={type} value={type} key={type} />
          ))}
        </Form.Select>
      </div>

      <StaffPresentSelect activity={activity} />

      <div className="form__row">
        <Form.Textarea
          name="description"
          defaultValue={activity.data.desc}
          placeholder="Description"
          label="Description"
        />
      </div>

      <div className="form__row">
        {forceStaff ? (
          TEXT_CAPTIONS.staffOnlyForce
        ) : (
          <Form.Checkbox
            type="circle"
            name="staffOnly"
            label={TEXT_CAPTIONS.staffOnly}
            defaultValue={activity.is_staff_only}
          />
        )}
      </div>
    </div>
  );
};

export default MoodActivityForm;
