import React, { Component } from 'react';
import { ActionButton, Icon } from 'components';
import './style.scss';

class VideoViewer extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      currentIndex: this.props.data.index || 0
    };
  }

  @bind
  onPrev(evt) {
    evt.stopPropagation();
    this.setState({ currentIndex: this.state.currentIndex - 1 });
  }

  @bind
  onNext(evt) {
    evt.stopPropagation();
    this.setState({ currentIndex: this.state.currentIndex + 1 });
  }

  @bind
  onDownload(evt) {
    evt.stopPropagation();
  }

  render() {
    const { data, onClose } = this.props;
    const { currentIndex } = this.state;
    const video = data.videos[currentIndex];

    return (
      <div className="video-viewer" onClick={onClose}>
        <div className="modal__header">
          <ActionButton iconName="back" />

          {!video.youtube && (
            <a
              className="action-button"
              onClick={this.onDownload}
              download={video.video_file_url}
              href={video.video_file_url}
              target="_blank"
            >
              <Icon name="download" size={16} />
            </a>
          )}
        </div>

        {video.youtube ? (
          <iframe
            width="900"
            height="600"
            src={video.video_file_url + '&amp;autoplay=1'}
            frameBorder="0"
            allow="autoplay; encrypted-media; fullscreen"
            allowFullScreen
          />
        ) : (
          <div className="modal__container">
            <video controls autoPlay>
              <source src={video.video_file_url} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          </div>
        )}

        {currentIndex > 0 && (
          <ActionButton className="video-viewer__prev" size={40} iconName="left-arrow" onClick={this.onPrev} />
        )}
        {currentIndex < data.videos.length - 1 && (
          <ActionButton className="video-viewer__next" size={40} iconName="right-arrow" onClick={this.onNext} />
        )}
      </div>
    );
  }
}

export default VideoViewer;
