import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectSectionCommon from '../common/SelectSection';

class SelectSection extends Component {
  static propTypes = {
    rooms: PropTypes.array,
    data: PropTypes.object
  };

  static defaultProps = {
    kids: [],
    data: {}
  };

  @bind
  handleSubmit(sectionId) {
    this.props.onClose(sectionId);
  }

  render() {
    const { data, rooms } = this.props;

    return (
      <div className="select-section">
        <SelectSectionCommon
          title={data.title}
          description={data.description}
          defaultSelected={data.sectionId}
          sections={rooms}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data
});

export default connect(mapStateToProps)(SelectSection);
