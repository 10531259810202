import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import omit from 'lodash/omit';
import concat from 'lodash/concat';
import { ButtonV2, TagItem } from 'components';
import './style.scss';

class MilestoneSelect extends PureComponent {
  static propTypes = {
    selectedMeasures: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    selectedIndicators: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    selectedStandards: PropTypes.array,
    onChange: PropTypes.func,
    withIndicators: PropTypes.bool
  };

  static defaultProps = {
    selectedIndicators: {},
    selectedMeasures: {},
    selectedStandards: [],
    withIndicators: false,
    onChange: () => undefined
  };

  @bind
  onDelete(id) {
    const { selectedMeasures, selectedStandards, selectedIndicators, onChange, withIndicators } = this.props;
    const newSelectedState = {};

    if (withIndicators) {
      newSelectedState['selectedIndicators'] = selectedIndicators.filter(i => i.id !== id);
      newSelectedState['selectedMeasures'] = selectedMeasures.filter(m => m.id !== id);
    } else {
      newSelectedState['selectedMeasures'] = omit(selectedMeasures, [id]);
      newSelectedState['selectedStandards'] = selectedStandards.filter(s => s.id !== id);
    }
    onChange(newSelectedState);
  }

  @bind
  addMilestone() {
    const { selectedMeasures, selectedStandards, selectedIndicators, onChange, withIndicators } = this.props;

    Actions.showModal('AddMilestone', {
      selectedMeasures,
      selectedIndicators,
      selectedStandards,
      withIndicators
    }).then(result => {
      if (result) {
        onChange({
          selectedMeasures: result.selectedMeasures,
          selectedStandards: result.selectedStandards,
          selectedIndicators: result.selectedIndicators
        });
      }
    });
  }

  renderSelectedMilestones() {
    const { selectedStandards, selectedMeasures, selectedIndicators, withIndicators } = this.props;

    if (withIndicators) {
      const milestoneTags = concat(selectedIndicators, selectedMeasures);
      return milestoneTags.map(t => {
        const tag = {
          name: `${t.name && t.name.toUpperCase()} ${t.description}`,
          id: t.id
        };
        return <TagItem key={t.id} tag={tag} onDelete={this.onDelete} />;
      });
    } else {
      return selectedStandards.map(s => {
        const standardMeasures = selectedMeasures[s.id] || [];
        const tag = {
          name: `${s.short_name} (${standardMeasures.length})`,
          id: s.id
        };
        return <TagItem key={s.id} tag={tag} onDelete={this.onDelete} />;
      });
    }
  }

  render() {
    const { selectedStandards, selectedIndicators, selectedMeasures, withIndicators } = this.props;
    const tags = concat(selectedStandards, selectedIndicators, selectedMeasures);
    return (
      <div
        className={cx('create-lesson__tags', {
          'create-lesson__tags--none': withIndicators
            ? !(selectedMeasures.length || selectedIndicators.length)
            : !selectedStandards.length
        })}
      >
        {tags?.length > 0 && (
          <div className={cx('create-lesson__tags-body create-lesson__tags-body--list')}>
            {this.renderSelectedMilestones()}
          </div>
        )}
        <div className="create-lesson__tags-add">
          <ButtonV2
            className="create-lesson__tags-add__btn"
            secondary
            label="Add Additional Milestones"
            onClick={this.addMilestone}
          />
        </div>
      </div>
    );
  }
}
export default MilestoneSelect;
