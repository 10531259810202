import React from 'react';

import { Checkbox, Icon, Link } from 'components';
import { createMaterial } from 'lib/marcoPolo';

const MATERIAL_TYPE = {
  VIDEO: 'video',
  EDUCATOR_GUIDE: 'educator_guide',
  PRINTABLE: 'printable',
  PHOTOCARD: 'photocard'
};

function Lesson({ lesson, selectedMaterial, addMaterial, removeMaterial }) {
  const handleOnChange = (checked, type, url) => {
    const material = createMaterial(lesson.id, lesson.title, type, url);
    checked ? addMaterial(material) : removeMaterial(material);
  };

  const isMaterialSelected = url => !!selectedMaterial.find(m => m.lessonId == lesson.id && m.url === url);

  const renderMaterial = (lessonId, label, type, url) => (
    <div key={url} className="lesson__content__item">
      <Checkbox onChange={e => handleOnChange(e, type, url)} checked={isMaterialSelected(url)} />
      <Link to={url} target="_blank">
        <Icon name={type === MATERIAL_TYPE.VIDEO ? 'play' : 'pdf'} size="16" />
        {label}
      </Link>
    </div>
  );

  return (
    <div className="lesson">
      <div className="lesson__description">{lesson.description}</div>
      <div className="lesson__content">
        <div className="lesson__content__videos">
          <h3>Videos</h3>
          {lesson.videoNormal && renderMaterial(lesson.id, 'English Video', MATERIAL_TYPE.VIDEO, lesson.videoNormal)}
        </div>
        <div className="lesson__content__materials">
          <h3>Additional Learning Materials</h3>
          {lesson.lessonPlan &&
            renderMaterial(lesson.id, 'Educator Guide', MATERIAL_TYPE.EDUCATOR_GUIDE, lesson.lessonPlan)}

          {lesson.printables &&
            lesson.printables.map(printable =>
              renderMaterial(lesson.id, 'Printable', MATERIAL_TYPE.PRINTABLE, printable)
            )}

          {lesson.flashCards &&
            lesson.flashCards.map(photocard =>
              renderMaterial(lesson.id, 'Photocard', MATERIAL_TYPE.PHOTOCARD, photocard)
            )}
        </div>
      </div>
    </div>
  );
}

export default Lesson;
