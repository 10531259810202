import React, { Component } from 'react';

class VideoActivity extends Component {
  @bind
  play(video) {
    return () => Actions.showModal('VideoViewer', { videos: [video], index: 0 }, { className: 'modal--full' });
  }

  render() {
    const {
      activity: { activiable: video },
      activity
    } = this.props;
    return (
      <div className="activity__video">
        <div className="activity__paragraph">{video.caption || activity.comment}</div>
        {video.main_url && (
          <div className="activity__video-thumb" onClick={this.play(video)}>
            <img src={video.main_url} className="activity__video-img" />
            <div className="activity__video-play" />
          </div>
        )}
      </div>
    );
  }
}

export default VideoActivity;
