import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Item from './Item';
import './style.scss';

class ItemList extends Component {
  static propTypes = {
    items: PropTypes.array,
    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
    onChange: PropTypes.func
  };

  static defaultProps = {
    items: []
  };

  @bind
  addItem() {
    if (this.props.onAdd) {
      this.props.onAdd();
    }
  }

  @bind
  changeItem(id, key, nextValue) {
    if (this.props.onChange) {
      this.props.onChange(id, key, nextValue);
    }
  }

  @bind
  removeItem(id) {
    const { items, onRemove } = this.props;

    if (items.length > 1 && onRemove !== undefined) {
      onRemove(id);
    }
  }

  renderItems() {
    const { items } = this.props;

    return items.map((item, index) => (
      <Item
        item={item}
        key={item.id}
        isLast={index === items.length - 1}
        onAdd={this.addItem}
        onChange={this.changeItem}
        onRemove={this.removeItem}
      />
    ));
  }

  render() {
    return (
      <div className="form-field__item-list">
        <div className="form-field__item-list__title">Items</div>
        {this.renderItems()}
      </div>
    );
  }
}

export default ItemList;
