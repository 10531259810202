import React, { Component } from 'react';
import PropTypes from 'prop-types';
import currency from 'lib/currency';
import InvoicePreviewItem from './InvoicePreviewItem';

class InvoicePreviewContent extends Component {
  static propTypes = {
    items: PropTypes.array,
    isAttendancePreview: PropTypes.bool
  };

  getTotal(items) {
    const total = items.reduce((acc, item) => acc + (item.amount === undefined ? item.price : item.amount), 0);
    return Math.round(total) === total ? total : total.toFixed(2);
  }

  @bind
  renderInvoiceItems(items) {
    return items.map((item, index) => (
      <InvoicePreviewItem
        item={item}
        key={`item-${item.description}-${item.price}-${index}`}
        showBreakdown={this.props.showBreakdown}
        isAttendancePreview={this.props.isAttendancePreview}
      />
    ));
  }

  render() {
    const { items, isAttendancePreview } = this.props;
    const hasRate = items[0] && (items[0].kind === 'attendance' || items[0].kind === 'late_fee');

    return (
      <div className="table__body">
        <div className="table__row invoice-preview-card__headrow">
          <div className="table__cell">Description</div>

          {hasRate && <div className="table__cell table__cell--xs">Rate</div>}

          <div className="table__cell table__cell--xxs table__cell--actions">Amount</div>
        </div>

        {this.renderInvoiceItems(items)}

        {!isAttendancePreview && (
          <div className="table__row invoice-preview-card__total">
            <div className="table__cell" />

            <div className="table__cell table__cell--xs table__cell--actions">
              <span className="invoice-preview-card__total-title">Total Amount</span>

              <span className="invoice-preview-card__total-amount">{currency.getPrice(this.getTotal(items))}</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default InvoicePreviewContent;
