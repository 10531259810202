import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IntercomAPI } from 'react-intercom';
import cx from 'classnames';
import { Form, Icon, ActionButton, Checkbox } from 'components';
import { ROUTING_NUMBER_NAME } from 'constants/paymentFieldNames';
import history from 'lib/history';
import { getCurrentSchool, getStripePubKey, getStripeCustomPubKey } from 'lib/utils/selectors';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class BankInfoStep extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isLoading: false
    };
  }

  @bind
  onAccountTypeChange(account_holder_type) {
    this.props.onUpdate({ account_holder_type });
  }

  @bind
  onSubmit(formData) {
    const { data, billingAccountType, stripeStandardPubKey, stripeCustomPubKey, fromReg } = this.props;
    const { routing_number, account_number, institution_number } = formData;
    const stripe = window.Stripe(billingAccountType === 'custom' ? stripeCustomPubKey : stripeStandardPubKey);
    this.props.onUpdate(formData);

    this.setState({ isLoading: true });

    stripe
      .createToken('bank_account', {
        account_holder_type: data.account_holder_type,
        routing_number,
        account_number,
        institution_number: institution_number || undefined,
        account_holder_name: formData.account_holder_name,
        country: data.country,
        currency: 'usd'
      })
      .then(bankData => {
        if (bankData.error) {
          throw bankData.error;
        }

        return req.addSchoolBillingAccount({
          token: bankData.token.id,
          country: data.country,
          channel: fromReg ? 'school_registration' : 'billing',
          legal_entity_type: data.account_holder_type,
          school: {
            parent_payment_method: 'parent_payment_method_any', // @TODO use variable in form later
            parent_payment_fee_paid_by: data.parent_payment_fee_paid_by
          }
        });
      })
      .then(() => {
        const { onClose } = this.props;

        return Actions.showModal('Confirmation', {
          title: 'Account Verification',
          description:
            'You will be redirected to account verification page to collect additional information about your business. Please confirm.',
          yesButton: 'Yes',
          noButton: 'No',
          yesButtonProps: {
            secondary: false
          },
          noButtonProps: {
            secondary: true
          }
        }).then(result => {
          if (result) {
            return req
              .connectUri({
                return_url: `${process.env.PUBLIC_PATH}/mobile/school/activate/result`,
                refresh_url: `${process.env.PUBLIC_PATH}/family/billing`
              })
              .then(data => {
                history.push('/billing/redirecting');
                window.location.href = data.url;
                onClose();
              })
              .catch(err => {
                Actions.reportError('There was problem getting account connect uri', err);
              });
          }
        });
      })
      .then(() => req.profile())
      .then(() => {
        IntercomAPI('trackEvent', 'stripe_setup');
        this.setState({ isLoading: false });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        Actions.reportError('There was problem adding bank account', err);
      });
  }

  render() {
    const { data, title } = this.props;
    const { country } = data;
    const { isLoading } = this.state;
    // const currencies = CURRENCY_MAP[country] || [];

    return (
      <div className="modal__wrapper">
        <div className="modal__header modal__header--bordered">
          <ActionButton iconName="back" className="school-activate__back" onClick={this.props.context.prevStep} />
          <span className="modal__header-title">{title}</span>
        </div>
        <Form
          className="modal__container school-activate__form"
          onSubmit={this.onSubmit}
          isLoading={isLoading}
          validateOn="submit"
        >
          <div className="school-activate__legend">Choose account type</div>
          <div className="school-activate__desc">Please enter your checking account information.</div>
          <div className="form__row">
            <Checkbox
              className={cx('school-activate__checkbox', {
                'school-activate__checkbox--checked': data.account_holder_type === 'company'
              })}
              label="Corporation"
              checked={data.account_holder_type === 'company'}
              type="circle"
              onChange={() => this.onAccountTypeChange('company')}
            />
            <Checkbox
              className={cx('school-activate__checkbox', {
                'school-activate__checkbox--checked': data.account_holder_type === 'individual'
              })}
              label="Individual"
              checked={data.account_holder_type === 'individual'}
              type="circle"
              onChange={() => this.onAccountTypeChange('individual')}
            />
          </div>
          <div className="form__row form__row--justified">
            <Form.Input
              defaultValue={data.account_holder_name}
              placeholder="Account Holder Name"
              name="account_holder_name"
              required
            />
            <Form.Input
              defaultValue={data.routing_number}
              placeholder={ROUTING_NUMBER_NAME[country]}
              name="routing_number"
              required
            />
          </div>
          <div className="form__row form__row--justified">
            <Form.Input
              defaultValue={data.account_number}
              placeholder="Account Number"
              name="account_number"
              required
            />
            <Form.Input
              defaultValue={data.confirm_account_number}
              placeholder="Confirm Account Number"
              name="confirm_account_number"
              required
            />
          </div>
          <div className="form__row form__row--justified">
            {/* <Form.Select
              name="currency"
              defaultValue={data.currency || currencies[0]}
              placeholder="Currency"
              required
            >
              {currencies.map(c => (
                <Form.Select.Item
                  label={`${c}`.toUpperCase()}
                  value={c}
                  key={c}
                />
              ))}
            </Form.Select> */}
            {country === 'CA' && (
              <Form.Input
                defaultValue={data.institution_number}
                placeholder="Institution Number"
                name="institution_number"
                required
              />
            )}
          </div>

          <div className="school-activate__desc school-activate__desc--term">
            By signing up with us, you are agreeing to our{' '}
            <a href="https://www.procaresoftware.com/terms-of-use/procare-solutions-terms-of-use/" target="_blank">
              Terms & Conditions
            </a>
          </div>

          <div className="school-activate__warning">
            <div className="school-activate__warning-text">
              <span className="school-activate__warning-small">
                Payout timeline for ACH/Bank: 2 business days, Credit Card: 1 business day.
                <br />
                If you have any questions, please call&nbsp;
                <span className="school-activate__warning-strong">(877) 679-0950.</span>
              </span>
            </div>
          </div>

          <div className="school-activate__warning">
            <div className="school-activate__warning-text">
              <Icon name="lock" size={20} />
              Procare has the highest levels of PCI compliance for online payments.
            </div>
          </div>
          <div className="form__row form__row--actions">
            <Form.Submit label="Save & Continue" />
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  billingAccountType: getCurrentSchool(state, 'billing_account_type'),
  stripeStandardPubKey: getStripePubKey(state), // standard stripe key
  stripeCustomPubKey: getStripeCustomPubKey(state) // custom stripe key
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(BankInfoStep);
