import React, { Component } from 'react';
import { ButtonV2, PayerCheckbox } from 'components';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class FeePayerStep extends Component {
  @bind
  onChange(parent_payment_fee_paid_by) {
    this.props.onUpdate({
      parent_payment_fee_paid_by
    });
  }

  @bind
  onContinue() {
    const { onUpdate, checkFreeSchool } = this.props;

    if (checkFreeSchool()) {
      onUpdate({ payment_gateway: 'stripe' });
      this.props.context.goToStep('basic-info');
    }
  }

  getSchoolDesc() {
    const { feeBreakdown } = this.props;

    const ach = get(feeBreakdown, 'stripe.paid_by_school.ach', '$0.50'),
      cc = get(feeBreakdown, 'stripe.paid_by_school.cc', '2.95% + $0.50');

    return (
      <React.Fragment>
        <div className="fee-payer__checkbox-desc__block">
          <span className="fee-payer__checkbox-desc__label">ACH/Bank</span> : {ach}
        </div>
        <div className="fee-payer__checkbox-desc__block">
          <span className="fee-payer__checkbox-desc__label">Credit Card</span> : {cc}
        </div>
        <span className="fee-payer__checkbox-desc__text">No fees paid by parent</span>
      </React.Fragment>
    );
  }

  getParentDesc() {
    const { feeBreakdown } = this.props;

    const ach = get(feeBreakdown, 'stripe.paid_by_parent.ach', '$0.95'),
      cc = get(feeBreakdown, 'stripe.paid_by_parent.cc', '2.95% + $0.95');

    return (
      <React.Fragment>
        <div className="fee-payer__checkbox-desc__block">
          <span className="fee-payer__checkbox-desc__label">ACH/Bank</span> : {ach}
        </div>
        <div className="fee-payer__checkbox-desc__block">
          <span className="fee-payer__checkbox-desc__label">Credit Card</span> : {cc}
        </div>
        <span className="fee-payer__checkbox-desc__text">No fees paid by school</span>
      </React.Fragment>
    );
  }

  render() {
    const { data, title, byParentDisabled } = this.props;

    return (
      <div className="modal__wrapper">
        <div className="modal__header modal__header--bordered">
          <span className="modal__header-title">{title}</span>
        </div>
        <div className="modal__container fee-payer__form">
          <div className="fee-payer__legend">School or Parent</div>
          <div className="fee-payer__desc">Choose who will pay for online transaction fee.</div>

          <div className="form__row">
            <PayerCheckbox
              type="circle"
              className="fee-payer__checkbox"
              name="parent"
              icon="parent-connection"
              label="parent pays"
              description={this.getParentDesc()}
              onChange={() => this.onChange('parent_payment_fee_paid_by_parent')}
              checked={data.parent_payment_fee_paid_by === 'parent_payment_fee_paid_by_parent'}
              disabled={byParentDisabled}
              blur={byParentDisabled}
            />

            <PayerCheckbox
              type="circle"
              className="fee-payer__checkbox"
              name="school"
              icon="school"
              label="school pays"
              description={this.getSchoolDesc()}
              onChange={() => this.onChange('parent_payment_fee_paid_by_school')}
              checked={data.parent_payment_fee_paid_by === 'parent_payment_fee_paid_by_school'}
            />
          </div>

          <div className="school-activate__warning">
            <div className="school-activate__warning-text">
              <span className="school-activate__warning-small">
                Payout timeline for ACH/Bank: 2 business days, Credit Card: 1 business day.
                <br />
                If you have any questions, please call&nbsp;
                <span className="school-activate__warning-strong">(877) 679-0950.</span>
              </span>
            </div>
          </div>

          <div className="form__row form__row--actions">
            <ButtonV2 label="Continue" disabled={!data.parent_payment_fee_paid_by} onClick={this.onContinue} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  feeBreakdown: state.feeBreakdown.data || {}
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(FeePayerStep);
