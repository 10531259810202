import { Avatar, ButtonV2, Form, Preloader } from 'components';
import sumBy from 'lodash/sumBy';
import times from 'lodash/times';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import Dose from './Dose';
import { createDoses } from './utils';
import './style.scss';

export const MAX_EFFECTIVE_DOSES = 6;

const AddVaccine = ({ data, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [doses, setDoses] = useState(createDoses(data.vaccine));
  const [needToScroll, setNeedToScroll] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const vaccines = useSelector(state => state.vaccines.data);
  const staff = useSelector(state => state.staff.data);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const sumEffectiveDoses = sumBy(doses, d => d.effectiveDoses);
  const scrollRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      const staff = await req.staff({
        filters: {
          teacher: {
            status: 'active'
          }
        }
      });

      if (data.vaccine?.vaccine_teachers) {
        setSelectedStaff(staff.filter(s => data.vaccine.vaccine_teachers.find(vs => vs.teacher_id === s.id)));
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const addDose = () => {
    const lastDose = doses.slice(-1)[0];
    setDoses([
      ...doses,
      { id: lastDose.id + 1, number: lastDose.number + 1, effectiveDoses: 1, defaults: { type: 'in' } }
    ]);
    setNeedToScroll(true);
  };

  const setEffectiveDoses = (id, effectiveDoses) => {
    setDoses(doses.map(dose => (dose.id === id ? { ...dose, effectiveDoses } : dose)));
  };

  const removeDose = id => {
    const newDoses = doses
      .filter(d => d.id !== id)
      .map((d, i) => ({
        ...d,
        number: i + 1
      }));
    setDoses(newDoses);
  };

  useEffect(() => {
    if (scrollRef.current && needToScroll) {
      scrollRef.current.scrollToBottom();
      setNeedToScroll(false);
    }
  }, [doses]);

  const updateFormValues = values => {
    const nameInUse = vaccines.some(
      v => v.title.trim().toLocaleLowerCase() === values['name'].trim().toLocaleLowerCase() && v.id !== data.vaccine?.id
    );

    const errors = {
      ...errors,
      name: nameInUse ? ['Name must be unique'] : null
    };

    setFormValues(values);
    setErrors(errors);
  };

  const getDosesPayload = () => {
    let lastDoseNumber = 1;
    const nextDoseAttributes = () => ({
      dose_number: lastDoseNumber++,
      month_due: null,
      fixed_date: null,
      due_in: null
    });

    return doses.flatMap(d => {
      let dueIn,
        dueInType,
        numTimes = 1,
        period;

      switch (formValues[`${d.id}_type`]) {
        case 'age':
          return { ...nextDoseAttributes(), month_due: Number(formValues[`${d.id}_age`]) };
        case 'within':
          return { ...nextDoseAttributes(), due_in: Number(formValues[`${d.id}_within`]) || 1 };
        case 'fixed':
          return times(MAX_EFFECTIVE_DOSES, i => ({
            ...nextDoseAttributes(),
            fixed_date: moment(formValues[`${d.id}_fixed`]).add(i, 'years')
          }));
        case 'in':
          dueIn = Number(formValues[`${d.id}_in`]) || 1;
          dueInType = formValues[`${d.id}_in_type`];
          break;
        case 'repeats':
          dueIn = Number(formValues[`${d.id}_repeats_in`]) || 1;
          dueInType = formValues[`${d.id}_repeats_in_type`];
          numTimes = Number(formValues[`${d.id}_repeats_times`]);
          break;
      }

      switch (dueInType) {
        case 'Days':
          period = 1;
          break;
        case 'Weeks':
          period = 7;
          break;
        case 'Months':
          period = 30;
          break;
        case 'Years':
          period = 365;
          break;
      }

      return times(numTimes, () => ({ ...nextDoseAttributes(), due_in: dueIn * period }));
    });
  };

  const submit = async () => {
    const payload = {
      id: data.vaccine?.id,
      vaccine: {
        title: formValues.name || undefined,
        optional: !formValues.required,
        notes: formValues.notes.trim(),
        vaccine_doses_attributes: getDosesPayload(),
        vaccine_reminder_attributes: {
          id: data.vaccine?.vaccine_reminder?.id,
          carers_reminder: formValues.carers_reminder,
          teachers_reminder: formValues.teachers_reminder,
          remind_before: formValues.carers_reminder ? Number(formValues.remind_before || 0) : undefined,
          repeats: formValues.repeats ? formValues.repeats_type : 'unrepeated'
        },
        vaccine_teachers_attributes: selectedStaff.map(staff => ({ teacher_id: staff.id }))
      }
    };

    if (payload.id) {
      // Updating existing vaccine

      for (let i = 0; i < data.vaccine.vaccine_doses?.length ?? 0; i++) {
        // For each existing dose...
        if (i < payload.vaccine.vaccine_doses_attributes.length) {
          // if we can map to a dose in the payload, add the ID for updating
          payload.vaccine.vaccine_doses_attributes[i].id = data.vaccine.vaccine_doses[i].id;
        } else {
          // otherwise flag for deletion
          payload.vaccine.vaccine_doses_attributes.push({ id: data.vaccine.vaccine_doses[i].id, _destroy: 1 });
        }
      }

      // Flag removed staff
      if (data.vaccine.vaccine_teachers?.length) {
        payload.vaccine.vaccine_teachers_attributes.push(
          ...data.vaccine.vaccine_teachers
            .filter(vs => !selectedStaff.find(s => s.id === vs.teacher_id))
            .map(vs => ({ id: vs.id, _destroy: 1 }))
        );
      }
    }

    try {
      setIsSaving(true);
      const result = await (payload.id ? req.updateVaccine(payload) : req.addVaccine(payload));
      onClose(result);
    } catch (err) {
      const formErrors = err.response?.data?.form_errors;

      if (formErrors) {
        setErrors(formErrors);
      }

      Actions.reportError('There was problem creating a program', err);
    } finally {
      setIsSaving(false);
    }
  };

  // When a checkbox's label contains inputs, clicking on those inputs should
  // only check the checkbox, not uncheck it
  const stopPropagation = (e, checkboxName, shouldUncheck = false) => {
    if (formValues[checkboxName] !== shouldUncheck) {
      e.stopPropagation();
    }
  };

  const addStaff = async () => {
    const selectedStaffIds = await Actions.showModal('SelectStaff', {
      selected: selectedStaff.map(s => s.id),
      validations: {
        'No Email': s => !s.email
      }
    });

    if (selectedStaffIds) {
      const nextSelectedStaff = staff.filter(s => selectedStaffIds.includes(s.id));

      setSelectedStaff(nextSelectedStaff);
    }
  };

  const parentRemindersLabel = (
    <span>
      <div>Send parents a reminder</div>
      <Form.Input
        name="remind_before"
        defaultValue={String(data.vaccine?.vaccine_reminder?.remind_before ?? '')}
        placeholder="0"
        type="number"
        className="days"
        min={0}
        onClick={e => stopPropagation(e, 'carers_reminder')}
      />
      <div>days before upcoming due dates</div>
    </span>
  );

  const staffRemindersLabel = (
    <span>
      <div>Send reminders to staff</div>
      <ButtonV2
        tertiary
        className="add-vaccine__add-btn add-vaccine__add-btn--staff"
        label="Add staff"
        onClick={e => {
          addStaff();
          stopPropagation(e, 'teachers_reminder');
        }}
      />
      {selectedStaff.length > 0 && (
        <ButtonV2
          tertiary
          className="add-vaccine__clear-btn"
          label="Clear all"
          onClick={e => {
            setSelectedStaff([]);
            stopPropagation(e, 'teachers_reminder', true);
          }}
        />
      )}
    </span>
  );

  const repeatLabel = (
    <span>
      <div>Repeats</div>
      <Form.Select
        name="repeats_type"
        defaultValue={
          ![null, undefined, 'unrepeated'].includes(data.vaccine?.vaccine_reminder?.repeats)
            ? data.vaccine.vaccine_reminder.repeats
            : 'weekly'
        }
        onClick={e => stopPropagation(e, 'repeats')}
        tabIndex={0}
      >
        {['Daily', 'Weekly', 'Monthly'].map(value => (
          <Form.Select.Item key={value} label={value} value={value.toLowerCase()} />
        ))}
      </Form.Select>
      <div>until completed</div>
    </span>
  );

  return (
    <div className="modal__wrapper add-vaccine">
      <div className="modal__header">
        <div className="modal__header-title">{data.vaccine ? 'Edit' : 'Add'} Vaccine</div>
      </div>
      <div className="modal__container">
        <Form
          onInit={updateFormValues}
          onChange={updateFormValues}
          onSubmit={submit}
          errors={errors}
          validateOn="change"
        >
          <div className="form__row">
            <Form.Input
              name="name"
              label="Vaccine"
              defaultValue={data.vaccine?.title}
              placeholder="Insert Vaccine Name"
              required
            />
          </div>
          <Form.Checkbox
            className="add-vaccine__is-required"
            name="required"
            label="Required"
            defaultValue={!data.vaccine?.optional}
          />
          <div className="form__row">
            <div style={{ width: '100%' }}>
              <div className="form__label">Schedule Doses</div>
              <div className="add-vaccine__dose-container">
                <Scrollbars autoHeight autoHeightMax={213} ref={scrollRef}>
                  {doses.map(dose => (
                    <Dose
                      key={dose.id}
                      id={dose.id}
                      number={dose.number}
                      defaults={dose.defaults}
                      formValues={formValues}
                      numAvailableDoses={MAX_EFFECTIVE_DOSES - sumEffectiveDoses + dose.effectiveDoses}
                      onDelete={() => removeDose(dose.id)}
                      onChangeEffectiveDoses={numDoses => setEffectiveDoses(dose.id, numDoses)}
                    />
                  ))}
                </Scrollbars>
              </div>
              <ButtonV2
                tertiary
                className="add-vaccine__add-btn add-vaccine__add-btn--dose"
                label="Add dose"
                onClick={addDose}
                disabled={sumEffectiveDoses >= MAX_EFFECTIVE_DOSES}
              />
            </div>
          </div>
          <div className="form__row">
            <div className="add-vaccine__send-reminder">
              <div className="form__label">Send Reminder</div>
              <div>Send reminder email for all upcoming due dates and missing doses.</div>
              <div>
                <Form.Checkbox
                  className="mr-40"
                  name="carers_reminder"
                  label={parentRemindersLabel}
                  defaultValue={data.vaccine?.vaccine_reminder?.carers_reminder ?? false}
                />
              </div>
              <div>
                <Form.Checkbox
                  className="ml-32 mr-40"
                  name="repeats"
                  label={repeatLabel}
                  defaultValue={![null, undefined, 'unrepeated'].includes(data.vaccine?.vaccine_reminder?.repeats)}
                />
              </div>
              <div>
                <Form.Checkbox
                  className="mr-40"
                  name="teachers_reminder"
                  label={staffRemindersLabel}
                  defaultValue={data.vaccine?.vaccine_reminder?.teachers_reminder ?? false}
                />
              </div>
              {isLoading && data.vaccine?.vaccine_teachers?.length > 0 ? (
                <Preloader center />
              ) : (
                selectedStaff.length > 0 && (
                  <div className="add-vaccine__staff-list">
                    {selectedStaff.map(staff => (
                      <div key={staff.id}>
                        <Avatar
                          type="avatar"
                          className="avatar--small"
                          name={staff.name}
                          url={staff.profile_pic_url}
                          status={staff.is_admin ? 'admin' : undefined}
                        />

                        <div className="person-card__text">
                          <div className="add-vaccine__staff-list-name person-card__name">{staff.name}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          </div>
          <div className="form__row">
            <Form.Textarea name="notes" placeholder="Notes/Comments" defaultValue={data.vaccine?.notes} label="Notes" />
          </div>
          <div className="form__row form__row--actions">
            <ButtonV2 secondary label="Cancel" onClick={() => onClose(false)} disabled={isSaving} />
            <Form.Submit label={data.vaccine ? 'Save' : 'Add'} disabled={isSaving || Boolean(errors.name)} />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddVaccine;
