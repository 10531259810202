import React from 'react';
import useLaunchDarkly from 'hooks/useLaunchDarkly';
import ReactionsContext from './ReactionsContext';

import './style.scss';

function Reactions({ activity, children }) {
  const flags = useLaunchDarkly();
  const enabled = flags['parent-photo-likes'];
  if (!enabled) {
    return;
  }

  const { hands = 0, smile = 0, laughing = 0, like = 0, heart = 0 } = activity?.activiable?.likes_counts || {};
  const reactions = [
    { icon: 'reaction-hands', count: hands },
    { icon: 'reaction-smile', count: smile },
    { icon: 'reaction-laughing', count: laughing },
    { icon: 'reaction-like', count: like },
    { icon: 'reaction-heart', count: heart }
  ].filter(reaction => reaction.count);

  if (!reactions.length) {
    return;
  }

  return <ReactionsContext.Provider value={reactions}>{children}</ReactionsContext.Provider>;
}

Reactions.Emojis = React.lazy(() => import('./Emojis'));

export default Reactions;
