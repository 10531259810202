import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, ColorPicker } from 'components';

const DEFAULT_COLOR = '#999';

class LabelItem extends PureComponent {
  static propTypes = {
    item: PropTypes.object,
    editedItem: PropTypes.object,
    isEdit: PropTypes.bool,
    onChange: PropTypes.func
  };

  static defaultProps = {
    item: {},
    editedItem: {}
  };

  @bind
  handleNameChange({ name }) {
    this.props.onChange({ name });
  }

  @bind
  handleChangeColor(color) {
    this.props.onChange({ color });
  }

  @bind
  setDefaultColor() {
    const { item, editedItem } = this.props;

    if (!item.color && !editedItem.color) {
      this.handleChangeColor(DEFAULT_COLOR);
    }
  }

  renderEdit() {
    const { item, editedItem } = this.props;

    return (
      <Form
        onInit={this.setDefaultColor}
        className="manage-labels__label manage-labels__label--edit"
        onChange={this.handleNameChange}
      >
        <ColorPicker
          color={editedItem.color}
          onChange={this.handleChangeColor}
          pickerClassName="manage-labels__color-picker"
        />
        <Form.Input name="name" placeholder="Label name" defaultValue={item.name} />
      </Form>
    );
  }

  renderShow() {
    const { item } = this.props;

    return (
      <div className="manage-labels__label">
        <span className="manage-labels__label-color" style={{ backgroundColor: item.color }} />
        {item.name}
      </div>
    );
  }

  render() {
    return this.props.isEdit ? this.renderEdit() : this.renderShow();
  }
}

export default LabelItem;
