import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Avatar, ButtonV2, Filter, Form, Checkbox, PageNav } from 'components';
import { plural } from 'lib/utils';

const PER_PAGE = 7;
const ALL_STATES_OPTION = 'all';
const SELECTION_LIMIT = 25;

function SchoolStep({ schools, selectedIds, onSelect, onApplySelection, hasSavedSelection, onNext }) {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    search: '',
    states: [ALL_STATES_OPTION]
  });
  const allStates = useMemo(() => {
    const stateSet = new Set();

    schools.forEach(s => {
      stateSet.add(s.state);
    });

    return [...stateSet].sort((a, b) => a.localeCompare(b));
  }, [schools]);
  const filteredSchools = useMemo(
    () =>
      schools
        .filter(s => {
          // check for search
          if (filters.search && !s.name.toLowerCase().includes(filters.search.toLowerCase())) {
            return false;
          }

          // check for state
          if (
            filters.states &&
            filters.states.length &&
            filters.states[0] !== ALL_STATES_OPTION &&
            !filters.states.includes(s.state)
          ) {
            return false;
          }

          return true;
        })
        .sort((a, b) => {
          // sort by state first
          if (!a.state && !b.state) return a.name.localeCompare(b.name);

          if (!a.state) return 1;
          if (!b.state) return -1;

          // if state is same, sort by name
          return a.state.localeCompare(b.state) || a.name.localeCompare(b.name);
        }),
    [filters, schools]
  );
  useEffect(() => {
    setPage(1);
  }, [filters]);

  const paginatedSchools = useMemo(() => filteredSchools.slice((page - 1) * PER_PAGE, page * PER_PAGE), [
    filteredSchools,
    page
  ]);
  const currentPageSchoolIds = paginatedSchools.map(s => s.id);
  const selectedIdsOnPage = selectedIds.filter(id => currentPageSchoolIds.includes(id));
  const allSelectedOnPage = selectedIdsOnPage.length === currentPageSchoolIds.length;
  const FILTER_CONFIG = {
    states: {
      type: 'multi-select',
      formType: 'checkbox',
      label: 'States',
      multipleSelectionText: 'states',
      title: 'All States',
      allSelectionItemId: 'all',
      options: {
        all: 'All States',
        ...allStates.reduce(
          (options, s) => ({
            ...options,
            [s]: s || 'No State'
          }),
          {}
        )
      }
    }
  };

  const unselectedCountOnPage = currentPageSchoolIds.length - selectedIdsOnPage.length;
  const selectAllDisabled = SELECTION_LIMIT < selectedIds.length + unselectedCountOnPage;

  const toggleAllOnPage = () => {
    onSelect(currentPageSchoolIds, !allSelectedOnPage);
  };
  const isSchoolSelected = school => selectedIds.includes(school.id);
  const toggleSchoolSelection = school => {
    if (selectedIds.length < SELECTION_LIMIT || isSchoolSelected(school)) {
      onSelect([school.id], !isSchoolSelected(school));
    }
  };

  return (
    <div className="report-corp">
      <div className="modal__header">
        <span className="modal__header-title">Select Schools</span>
        <span className="modal__header-steps">
          <span>Step 1</span> / 2
        </span>
      </div>

      <div className="modal__container">
        <Filter onSubmit={values => setFilters(values)} filters={FILTER_CONFIG}>
          <Form.Input
            name="search"
            placeholder="Search Schools"
            className="input--search"
            defaultValue={filters.search}
          />
        </Filter>

        <div className="report-corp__note">NOTE: Please choose up to 25 centers when running reports.</div>

        <div className="header header--small">
          <div className="header__inner">
            <Checkbox
              checked={allSelectedOnPage}
              disabled={selectAllDisabled}
              onChange={toggleAllOnPage}
              label="Select All On Page"
            />
            <span className="report-corp__span">{`${plural(selectedIds.length, 'school')} selected`}</span>
            <ButtonV2
              label={hasSavedSelection ? 'Apply Saved Selections' : 'No Saved Selections'}
              disabled={!hasSavedSelection}
              tertiary
              onClick={onApplySelection}
            />
          </div>

          <PageNav
            page={page}
            total={filteredSchools.length}
            perPage={PER_PAGE}
            onPrev={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)}
          />
        </div>

        <div className="table">
          <div className="table__header">
            <div className="table__cell table__cell--l table__cell-name-header">Name</div>
            <div className="table__cell table__cell--s">State</div>
          </div>
          <div className="table__body">
            {paginatedSchools.map(s => (
              <div key={s.id} className="table__row">
                <div className="table__cell table__cell--l table__cell-name">
                  <Checkbox
                    className="school-card__checkbox"
                    checked={isSchoolSelected(s)}
                    onChange={() => toggleSchoolSelection(s)}
                    label={
                      <div className="school-card__info">
                        <Avatar
                          className="school-card__avatar"
                          url={s.profile_pic_url}
                          type="select-logo"
                          name={s.name}
                        />

                        <div className="school-card__text">
                          <div className="school-card__name">{s.name}</div>
                        </div>
                      </div>
                    }
                  />
                </div>
                <div className="table__cell table__cell--s">{s.state}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="form__row form__row--actions">
          <ButtonV2 label="Continue" onClick={onNext} disabled={selectedIds.length === 0} />
        </div>
      </div>
    </div>
  );
}

SchoolStep.propTypes = {
  onNext: PropTypes.func,
  onSelect: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  schools: PropTypes.array
};

export default SchoolStep;
