import React, { Component } from 'react';
import { ButtonV2 } from 'components';
import './style.scss';

class ApproveDocumentModal extends Component {
  state = {
    isLoading: false
  };

  @bind
  performApprove() {
    this.setState({ isLoading: true });
    this.props.onClose(true);
  }

  render() {
    const { onClose } = this.props;
    const { isLoading } = this.state;

    return (
      <div className="modal__wrapper approve-document">
        <div className="modal__header">
          <div className="subheader">Approve Document</div>

          <p className="modal__header-note">Please confirm you would like to approve this document.</p>
        </div>

        <div className="modal__controls">
          <ButtonV2 onClick={onClose} secondary label="Cancel" />
          <ButtonV2 onClick={this.performApprove} label="Approve" isLoading={isLoading} />
        </div>
      </div>
    );
  }
}

export default ApproveDocumentModal;
