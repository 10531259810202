import { createApiReducers } from 'lib/req';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistCombineReducers, PersistedState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authRoutes from './authRoutes';
import apiRoutes from './apiRoutes';
import reducers from './reducers';
import { enableBatching } from 'redux-batched-actions';

const enableMiddleware = (middleware = []) => {
  if (process.env['NODE_ENV'] !== 'production') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }

  return compose(applyMiddleware(...middleware));
};

const authReducers = createApiReducers(authRoutes);
const apiReducers = createApiReducers(apiRoutes);

const rootReducer = persistCombineReducers(
  {
    key: 'kinderlime',
    storage: storage,
    blacklist: ['modal', 'flashes', 'cloak', 'profileEditors', 'launchDarkly']
  },
  {
    ...authReducers,
    ...apiReducers,
    ...reducers
  }
);

const store = createStore(enableBatching(rootReducer), {} as PersistedState, enableMiddleware());

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
