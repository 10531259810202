import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RichEditor, ButtonV2, TextInput } from 'components';
import get from 'lodash/get';
import './style.scss';
import classNames from 'classnames';

const froalaConfig = {
  height: 245,
  toolbarSticky: false,

  toolbarButtons: {
    moreRich: {
      buttons: ['insertLink']
    },
    moreMisc: {
      buttons: ['undo', 'redo']
    }
  }
};

class EditText extends Component {
  static propTypes = {
    data: PropTypes.shape({
      text: PropTypes.string.isRequired,
      useRichEditor: PropTypes.bool,
      multiline: PropTypes.bool,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      hideCloseButton: PropTypes.bool
    })
  };

  constructor(props) {
    super(props);

    this.state = {
      text: get(props, 'data.text', '')
    };
  }

  @bind
  handleTextChange(text) {
    this.setState({ text });
  }

  @bind
  submit() {
    this.props.onClose(this.state.text);
  }

  @bind
  handleClose() {
    if (this.props.data.text !== this.state.text) {
      const shouldBeClosed = window.confirm('Are you sure? All unsaved changes will be lost.');

      if (shouldBeClosed) {
        this.props.onClose();
      }

      return;
    }

    this.props.onClose();
  }

  render() {
    const { useRichEditor, multiline, title, subtitle, hideCloseButton } = this.props.data;

    const textCN = classNames('edit-text', {
      'edit-text--rich': useRichEditor
    });

    return (
      <div className={textCN}>
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">{title || 'Edit Text'}</div>
        </div>

        {subtitle && <div className="edit-text__subtitle">{subtitle}</div>}

        <div className="modal__container">
          {useRichEditor ? (
            <RichEditor value={this.state.text} config={froalaConfig} onChange={this.handleTextChange} />
          ) : (
            <TextInput value={this.state.text} multiline={multiline} onChange={this.handleTextChange} />
          )}
        </div>

        <div className="modal__controls">
          <ButtonV2 label="Save" onClick={this.submit} />
          {!hideCloseButton && <ButtonV2 label="Close" secondary onClick={this.handleClose} />}
        </div>
      </div>
    );
  }
}

export default EditText;
