import moment from 'moment';

export default (data, activity) => {
  const date = moment(data.date || undefined).format('YYYY-MM-DD');

  return {
    id: activity ? activity.id : undefined,

    daily_activity: {
      activity_time: `${date} ${data.time}`,
      data: {
        mood: data.mood,
        desc: data.description
      },
      is_staff_only: data.staffOnly
    }
  };
};
