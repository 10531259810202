import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonV2 } from 'components';
import './style.scss';

class TopNotification extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.string,
    btnLabel: PropTypes.string,
    onClick: PropTypes.func
  };

  render() {
    const { children, btnLabel, onClick } = this.props;
    const className = classNames('top-notification', this.props.className);

    return (
      <div className={className}>
        <div className="top-notification__text">{children}</div>

        {btnLabel && onClick && <ButtonV2 onClick={onClick} className="top-notification__btn" label={btnLabel} />}
      </div>
    );
  }
}

export default TopNotification;
