import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'components';
import './style.scss';

class ThankyouStep extends Component {
  UNSAFE_componentWillMount() {
    window.localStorage.removeItem('verificationClosed');
  }

  render() {
    const { billingAccountStatus, billingStatusMessage, showTick, data } = this.props;
    return (
      <div className="school-activate__thankyou">
        {(showTick || billingAccountStatus === 'verified') && (
          <div className="school-activate__thankyou-img">
            <Icon name="tick" size={55} />
          </div>
        )}
        <div className="school-activate__thankyou-title">
          {data.payment_gateway === 'te'
            ? 'Thanks for reaching out and our support will get in touch.'
            : 'Thanks for setting up online billing.'}
          &nbsp;
          <br />
          {!showTick && billingStatusMessage}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  billingAccountStatus: state.currentUser.data.current_school.billing_account_status,
  billingStatusMessage: state.currentUser.data.current_school.billing_status_message
});

export default connect(mapStateToProps)(ThankyouStep);
