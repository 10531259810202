import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './style.scss';
import TextInput from '../TextInput';

function CounterInput({ limit = 300, counter, className, showCounter, ...props }) {
  const remainder = limit - counter;
  const cn = cx('counter-input', className, {
    'counter-input--warn': showCounter && remainder <= limit / 3,
    'counter-input--error': showCounter && remainder < 0
  });

  return (
    <div className={cn}>
      <TextInput {...props} />
      <div className="counter-input__counter">
        <span>{showCounter ? `${remainder}/${limit}` : ''}</span>
      </div>
    </div>
  );
}

CounterInput.propTypes = {
  limit: PropTypes.number,
  counter: PropTypes.number,
  className: PropTypes.string,
  showCounter: PropTypes.bool
};

export default CounterInput;
