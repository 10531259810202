import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import { getStripePubKey } from 'lib/utils/selectors';
import StripeForm from 'screens/common/Billing/Stripe/AddPaymentMethod';
import { AddPaymentMethod as BluepayForm } from 'screens/common/Billing/Bluepay/AddPaymentMethod';
import { AddPaymentMethod as TuitionExpressForm } from 'screens/common/Billing/TuitionExpress/AddPaymentMethod';
import './style.scss';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';
import { compose } from 'redux';

class AddPaymentStep extends Component {
  @bind
  onSubmit(payment) {
    this.props.onAddPayment(payment);
    this.props.context.goToStep(1);
  }

  @bind
  handleConnectUri() {
    const { slug } = this.props.share;

    return req.registrationsTeAccountUri({ slug });
  }

  render() {
    const { onClose, school, stripePubKey } = this.props;

    if (school.billing_gateway === 'bluepay') {
      return (
        <BluepayForm
          startMethod="card"
          pubKey={school.billing_publishable_key}
          billingAccountId={school.billing_account_id}
          onClose={onClose}
          onSubmit={this.onSubmit}
        />
      );
    }

    if (school.billing_gateway === 'te') {
      return (
        <TuitionExpressForm
          onClose={onClose}
          onSubmit={this.onSubmit}
          startMethod="card"
          onConnectUri={this.handleConnectUri}
        />
      );
    }

    return (
      <StripeProvider apiKey={stripePubKey} options={{ stripeAccount: school.billing_account_id }}>
        <StripeForm onClose={onClose} onSubmit={this.onSubmit} startMethod="card" />
      </StripeProvider>
    );
  }
}

const mapStateToProps = state => ({
  stripePubKey: getStripePubKey(state) // stripe key for kinderlime
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(AddPaymentStep);
