import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GithubPicker from 'react-color/lib/Github';
import TooltipTrigger from '../TooltipTrigger';
import './style.scss';

const DEFAULT_COLORS = [
  '#ed5c8e',
  '#fd8661',
  '#79909d',
  '#3ca1da',
  '#2cbdc0',
  '#7ec04e',
  '#e7bb37',
  '#b8b2a8',
  '#6e89ea',
  '#f8b448',
  '#009e88',
  '#b58d71',
  '#cb64ed',
  '#fb7275',
  '#9900ef',
  '#eb1447'
];

class ColorPicker extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    color: PropTypes.string,
    children: PropTypes.node,
    pickerClassName: PropTypes.string
  };

  @bind
  renderPicker() {
    const { onChange, color, colors, pickerClassName } = this.props;

    return (
      <GithubPicker
        colors={colors || DEFAULT_COLORS}
        onChangeComplete={color => onChange(color.hex)}
        color={color}
        className={pickerClassName}
      />
    );
  }

  @bind
  renderDefaultContent() {
    const { color } = this.props;

    return <div className="color-picker__circle" style={{ backgroundColor: color }} />;
  }

  render() {
    const { children } = this.props;

    return (
      <TooltipTrigger className="color-picker" renderTooltip={this.renderPicker} triggerOn="click">
        {children || this.renderDefaultContent()}
      </TooltipTrigger>
    );
  }
}

export default ColorPicker;
