import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import currency from 'lib/currency';
import { Form } from 'components';
import { capitalize } from 'lib/utils';

class PresetItem extends PureComponent {
  static propTypes = {
    item: PropTypes.object,
    editedItem: PropTypes.object,
    kind: PropTypes.oneOf(['preset', 'discount', 'subsidy']),
    isEdit: PropTypes.bool,
    onChange: PropTypes.func
  };

  renderEdit() {
    const { item, kind, renderControls, onChange, franchiseBilling } = this.props;
    const className = classNames('table__row', 'manage-presets__item', 'manage-presets__item--edit', {
      'manage-presets__item--discount': kind !== 'preset'
    });

    return (
      <Form className={className} onInit={onChange} onChange={onChange}>
        <div className="table__cell table__cell--xl">
          {franchiseBilling ? (
            item.title
          ) : (
            <Form.Input name="title" placeholder={`${capitalize(kind)} description`} defaultValue={item.title} />
          )}
        </div>

        {kind === 'preset' && (
          <div className="table__cell table__cell--s">
            <Form.Input
              className="manage-presets__preset-amount-input"
              name="rate"
              type="amount"
              placeholder="0.00"
              defaultValue={item.rate}
            />
          </div>
        )}

        <div className="table__cell table__cell--xs table__cell--actions">{renderControls()}</div>
      </Form>
    );
  }

  renderShow() {
    const { item, kind, renderMenu, franchiseBilling } = this.props;
    const className = classNames('table__row', 'manage-presets__item', {
      'manage-presets__item--discount': kind !== 'preset'
    });

    return (
      <div className={className}>
        <div className="table__cell table__cell--xl">{item.title}</div>
        {kind === 'preset' && <div className="table__cell table__cell--s">{currency.getPrice(item.rate)}</div>}
        <div className="table__cell table__cell--xs table__cell--actions">
          {renderMenu({
            delete: !franchiseBilling,
            edit: true
          })}
        </div>
      </div>
    );
  }

  render() {
    return this.props.isEdit ? this.renderEdit() : this.renderShow();
  }
}

const mapStateToProps = state => ({
  franchiseBilling: state.currentUser.data.current_school.franchise_billing_enabled
});

export default connect(mapStateToProps)(PresetItem);
