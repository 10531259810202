import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectKids from '../common/SelectKids';
import './style.scss';

class SelectStudents extends Component {
  static propTypes = {
    kids: PropTypes.array,
    data: PropTypes.object
  };

  static defaultProps = {
    kids: [],
    data: {}
  };

  @bind
  handleSubmit(kidIds) {
    this.props.onClose(kidIds);
  }

  render() {
    const { data, kids } = this.props;

    return (
      <div className="select-students">
        <SelectKids
          defaultSelected={data.selected}
          students={kids}
          onSubmit={this.handleSubmit}
          showSelectSignIn={data.showSelectSignIn}
          validations={data.validations}
          disableInvalid={data.disableInvalid}
          label={data.label}
          isOptional={data.isOptional}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  kids: state.students.data
});

export default connect(mapStateToProps)(SelectStudents);
