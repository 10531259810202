import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Avatar } from 'components';

class PersonCard extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    picURL: PropTypes.string,
    color: PropTypes.string,
    type: PropTypes.oneOf(['kid', 'teacher']),
    validationError: PropTypes.string,
    disableInvalid: PropTypes.bool,
    selected: PropTypes.bool,
    onSelect: PropTypes.func
  };

  static defaultProps = {
    type: 'kid',
    selected: false,
    disableInvalid: true
  };

  shouldComponentUpdate(nextProps) {
    return this.props.selected !== nextProps.selected;
  }

  @bind
  handleSelect() {
    if (this.props.onSelect) {
      this.props.onSelect(this.props.id);
    }
  }

  render() {
    const { status, name, picURL, selected, color, validationError, disableInvalid, type, id, room } = this.props;
    const isInvalid = validationError !== undefined && disableInvalid;

    return (
      <div className="person-card" data-cy={`person-card-${id}`}>
        <Checkbox
          disabled={isInvalid}
          className="person-card__checkbox"
          checked={selected}
          onChange={this.handleSelect}
        />

        <div className="person-card__info" onClick={!isInvalid ? this.handleSelect : undefined}>
          <Avatar
            className="person-card__avatar"
            url={picURL}
            color={color}
            size="small"
            type={type === 'teacher' ? 'avatar' : undefined}
            status={status}
            name={name}
          />

          <div className="person-card__text">
            <div className="person-card__name">{name}</div>
            <div className="person-card__label">{room}</div>

            {validationError !== undefined && <div className="person-card__label">{validationError}</div>}
          </div>
        </div>
      </div>
    );
  }
}

export default PersonCard;
