import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';
import LEDGER_TYPES from 'constants/ledgerTypes';
import './style.scss';

class EditLedger extends Component {
  static propTypes = {
    data: PropTypes.shape({
      ledger: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string
      })
    }),
    onClose: PropTypes.func
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  @bind
  submit(values) {
    const ledgerId = this.props.data.ledger.id;
    const payload = {
      ledger: {
        ...values
      },
      id: this.props.data.ledger.id
    };

    this.setState({ isLoading: true });

    const promise = req.updateLedger(payload, { id: ledgerId });

    promise
      .then(() => {
        Actions.showFlash('Ledger saved successfully');
        this.setState({ isLoading: false });
        this.props.onClose();
      })
      .catch(err => {
        this.setState({ isLoading: false });
        Actions.reportError('There was problem saving ledger', err);
      });
  }

  render() {
    const { isLoading } = this.state;
    const { data } = this.props;

    return (
      <div className="modal__wrapper edit-ledger">
        <div className="modal__header">
          <div className="modal__header-title">Edit Ledger</div>
        </div>

        <div className="modal__container edit-ledger__content">
          <Form onSubmit={this.submit} isLoading={isLoading}>
            <div className="form__row">
              <Form.Input
                label="title"
                defaultValue={data.ledger.name}
                placeholder="Ledger Name (eg: operational expenses)"
                required
                name="name"
              />
            </div>

            <div className="form__row">
              <Form.Select
                label="Type"
                type="radio"
                required
                name="kind"
                placeholder="Select Type"
                defaultValue={data.ledger.kind}
              >
                {Object.keys(LEDGER_TYPES).map(t => (
                  <Form.Select.Item key={t} value={t} label={LEDGER_TYPES[t]} />
                ))}
              </Form.Select>
            </div>

            <div className="form__row">
              <Form.Textarea
                label="description"
                placeholder="Describe ledger details (eg: books, activities, postage)"
                name="description"
                defaultValue={data.ledger.description}
              />
            </div>

            <div className="form__row form__row--actions">
              <Form.Submit data-cy="submit" label="Submit" disabled={isLoading} />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default EditLedger;
