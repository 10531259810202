import React, { Component } from 'react';
import { ButtonV2 } from 'components';
import './style.scss';

class RejectDocumentModal extends Component {
  state = {
    isLoading: false
  };

  @bind
  rejectApproval() {
    this.setState({ isLoading: true });
    this.props.onClose(true);
  }

  render() {
    const { onClose } = this.props;
    const { isLoading } = this.state;

    return (
      <div className="modal__wrapper reject-document">
        <div className="modal__header">
          <div className="subheader">Reject & Create New Document</div>

          <p className="modal__header-note">Once rejected, you will need to create and send a new document.</p>

          <p className="modal__header-note">
            Recipients will receive a notification that the document is no longer validated and to expect a new request
            shortly.
          </p>
        </div>

        <div className="modal__controls">
          <ButtonV2 onClick={onClose} secondary label="Cancel" />
          <ButtonV2 onClick={this.rejectApproval} label="REJECT & CREATE NEW DOCUMENT" isLoading={isLoading} />
        </div>
      </div>
    );
  }
}

export default RejectDocumentModal;
