import React, { Component } from 'react';
import { updateQuery, getQuery, getPathname } from 'lib/history';
import capitalize from 'lodash/capitalize';
import mergeWith from 'lodash/mergeWith';
import merge from 'lodash/merge';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

function withQuery(name, defaultValues, storeCache = false, cacheIgnoredValues = [], uniqueIdentifier) {
  return function(WrappedComponent) {
    return class extends Component {
      constructor(...args) {
        super(...args);

        const query = getQuery() || {};
        let queryData = isObject(query[name])
          ? mergeWith({ ...defaultValues }, query[name], (objValue, srcValue) => {
              if (isArray(objValue) || isObject(objValue)) {
                return srcValue;
              }
            })
          : merge({ [name]: defaultValues }, query)[name];

        if (storeCache && !query[name]) {
          const userId = localStorage.getItem('kinderlime-user');
          const currentSchoolId = localStorage.getItem('kinderlime-current-school');
          const pathname = uniqueIdentifier || getPathname();
          let storageData = localStorage.getItem(`${userId}_${currentSchoolId}_${pathname}_${name}`);

          if (storageData === null) {
            // fallback
            storageData = localStorage.getItem(`${userId}_${pathname}_${name}`);
            if (storageData) localStorage.setItem(`${userId}_${currentSchoolId}_${pathname}_${name}`, storageData);
          }
          storageData = JSON.parse(storageData || '{}');
          if (Object.keys(storageData).length) {
            queryData = storageData;

            // ignoring some keys like date
            cacheIgnoredValues.forEach(key => {
              delete queryData[key];
            });
          }
        }
        this.state = { queryData };
      }

      @bind
      onChange(data) {
        const nextQuery = {};

        if (!data && data !== false && data !== 0) {
          nextQuery[name] = null;
        } else {
          nextQuery[name] = data;
        }

        if (storeCache) {
          const userId = localStorage.getItem('kinderlime-user');
          const currentSchoolId = localStorage.getItem('kinderlime-current-school');
          const pathname = uniqueIdentifier || getPathname();
          localStorage.setItem(`${userId}_${currentSchoolId}_${pathname}_${name}`, JSON.stringify(nextQuery[name]));
        }

        updateQuery(nextQuery);
      }

      render() {
        const capName = capitalize(name);
        const newProps = {
          [`query${capName}Data`]: this.state.queryData,
          [`on${capName}QueryChange`]: this.onChange
        };

        return <WrappedComponent {...newProps} {...this.props} />;
      }
    };
  };
}

export default withQuery;
