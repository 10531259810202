import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getErrorText, capitalize } from 'lib/utils';
import ManageController from 'modals/common/ManageItems/ManageController';
import ManageWindow from 'modals/common/ManageItems/ManageWindow';
import { ManageList, ManageListItemDefault } from 'modals/common/ManageItems/ManageList';
import { LoadingPlaceholder } from 'components';
import './style.scss';

const RelationItem = props => <ManageListItemDefault {...props} placeholder="Relation name" />;

class ManageRelationTypes extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    relationTypes: PropTypes.array,
    loading: PropTypes.bool
  };

  static defaultProps = {
    data: {},
    relationTypes: []
  };

  componentDidMount() {
    req.schoolRelationTypes();
  }

  performCreateRelation(params) {
    return req.addSchoolRelationType(params);
  }

  performUpdateRelation(params) {
    return req.updateSchoolRelationType(params);
  }

  performDeleteRelation(params) {
    return req.deleteSchoolRelationType(params, params);
  }

  @bind
  async addRelation(relation_type) {
    try {
      await this.performCreateRelation({ relation_type });
      Actions.showFlash('Relation has been added');
    } catch (e) {
      const errMsg = capitalize(getErrorText(e.response.data));
      Actions.reportError(errMsg, e);
      return Promise.reject();
    }
  }

  @bind
  async updateRelation(id, relation_type) {
    try {
      await this.performUpdateRelation({ id, relation_type });
      Actions.showFlash('Relation has been updated');
    } catch (e) {
      const errMsg = capitalize(getErrorText(e.response.data, { name: ' ' }));
      Actions.reportError(errMsg, e);
      return Promise.reject();
    }
  }

  @bind
  async deleteRelation(id) {
    const { studentId } = this.props.data;
    const shouldBeDeleted = await this.confirmDeletion();

    if (!shouldBeDeleted) {
      return;
    }

    try {
      await this.performDeleteRelation({ id });
      if (studentId) {
        await req.student({ id: studentId });
      }
      Actions.showFlash('Relation has been deleted');
    } catch (e) {
      Actions.reportError('Unable to delete relation', e);
      return Promise.reject();
    }
  }

  async confirmDeletion() {
    return Actions.showModal('Confirmation', {
      title: 'Delete Relation',
      description: 'Are you sure you want to delete this relation?',
      noButton: 'No',
      yesButton: 'Yes, Delete',
      yesButtonProps: { danger: true, secondary: false },
      noButtonProps: { secondary: true }
    });
  }

  render() {
    const { relationTypes, loading } = this.props;

    return (
      <div className="manage-relation-types">
        <ManageController
          items={relationTypes}
          onAdd={this.addRelation}
          onUpdate={this.updateRelation}
          onDelete={this.deleteRelation}
        >
          {props => (
            <ManageWindow
              className="manage-relation-types__window"
              title="Manage Relations"
              addIcon="add"
              addLabel="Add Relation"
              onAdd={props.onNew}
              data-cy="add-relation"
            >
              {relationTypes.length === 0 && !props.isNew ? (
                <LoadingPlaceholder
                  icon="milestone"
                  loading={loading}
                  title="No relations added"
                  text="Click on Add Relation to add relations"
                  className="manage-list__placeholder"
                />
              ) : (
                <ManageList {...props} ItemComponent={RelationItem} />
              )}
            </ManageWindow>
          )}
        </ManageController>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    relationTypes: state.relationTypes.data,
    loading: state.relationTypes.loading
  };
};

export default connect(mapStateToProps)(ManageRelationTypes);
