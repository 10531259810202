import React, { useContext, useState } from 'react';
import { ButtonV2, Form, Avatar } from 'components';
import { useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { ModalControllerStepsContext } from 'hocs/withContext';

const AssignProgramStaff_Step2 = ({ staffRoomMap, onSubmit }) => {
  const context = useContext(ModalControllerStepsContext);
  const program = useSelector(state => state.program.data);
  const staff = useSelector(state => state.staff.data);

  const [isSaving, setIsSaving] = useState(false);

  const submit = async fields => {
    setIsSaving(true);
    await onSubmit(fields);
  };

  return (
    <div className="assign-program-staff-step-two">
      <Form onSubmit={submit}>
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Select Room</div>
          <span className="modal__header-steps">
            <span>Step 2</span> / 2
          </span>

          <div className="assign-program-staff__subtitle">
            There are multiple rooms in this program. Select the room that the staff will be assigned to.
          </div>
        </div>

        <div className="modal__container">
          <div className="assign-program-staff__header">
            <div>
              <div className="assign-program-staff__header-label">Program</div>
              <div className="assign-program-staff__header-program">
                <Avatar className="room__avatar avatar--small" name={program.name} />
                <span className="assign-program-staff__name">{program.name}</span>
              </div>
            </div>
            <div>
              <div className="assign-program-staff__header-label">Program rooms</div>
              <div className="assign-program-staff__header-rooms">
                {program.sections.map(section => (
                  <div key={section.id}>{section.name}</div>
                ))}
              </div>
            </div>
          </div>
          <div className="assign-program-staff__program-staff">
            {Object.entries(staffRoomMap).map(([staffId, sectionId]) => {
              let staffItem = staff.find(s => s.id === staffId);

              return (
                <div className="assign-program-staff__program-staff-item" key={staffId}>
                  <div>
                    <Avatar
                      type="avatar"
                      className="avatar--small"
                      name={staffItem.name}
                      url={staffItem.profile_pic_url}
                    />
                    <span className="assign-program-staff__name">{staffItem.name}</span>
                  </div>
                  <div>
                    <Form.Select
                      name={staffId}
                      type="radio"
                      title="Select Room"
                      defaultValue={sectionId || undefined}
                      enableClear
                    >
                      {sortBy(program.sections, 'name').map(section => (
                        <Form.Select.Item key={section.id} value={section.id} label={section.name} />
                      ))}
                    </Form.Select>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="modal__controls">
          <ButtonV2 secondary label="Back" onClick={context.prevStep} disabled={isSaving} data-cy="continue" />
          <Form.Submit label="Assign Staff" isLoading={isSaving} />
        </div>
      </Form>
    </div>
  );
};

export default AssignProgramStaff_Step2;
