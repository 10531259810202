import moment from 'moment';

export function _getExactOverlappingEvents(event, allEvents) {
  const start = moment(event.starts_at).toDate();
  const end = moment(event.ends_at).toDate();

  return allEvents.filter(e => {
    const otherStart = moment(e.starts_at).toDate();
    const otherEnd = moment(e.ends_at).toDate();

    const minStart = Math.max(otherStart.valueOf(), start.valueOf());
    const diff1 = end.valueOf() - minStart;
    const diff2 = otherEnd.valueOf() - minStart;

    return diff1 > 0 && diff2 > 0;
  });
}

export function getOverlappingEvents(event, allEvents) {
  const exactOverlaps = _getExactOverlappingEvents(event, allEvents);

  let start = moment(event.starts_at);
  let end = moment(event.ends_at);

  exactOverlaps.forEach(e => {
    const otherStart = moment(e.starts_at);
    const otherEnd = moment(e.ends_at);

    if (otherStart.isBefore(start)) start = otherStart;
    if (otherEnd.isAfter(end)) end = otherEnd;
  });

  return _getExactOverlappingEvents({ ...event, starts_at: start, ends_at: end }, allEvents);
}

export function getEventOrder(event, overlaps) {
  const index = overlaps.findIndex(o => o.id === event.id);

  return index;
}

export function getEventsForDate(events, date) {
  return events.filter(e => {
    return moment(e.starts_at).isSame(date, 'day');
  });
}
