import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import DateFilter, { getDateRange } from 'components/DateFilter';
import validationText from '../validationText';
import './style.scss';
import withContext, { FormContext } from 'hocs/withContext';

class FormDateFilter extends Component {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    defaultValue: PropTypes.shape({
      type: PropTypes.string,
      date: PropTypes.any
    }),
    defaultToPresent: PropTypes.bool,
    disabled: PropTypes.bool,
    disableFuture: PropTypes.bool,
    disableDaily: PropTypes.bool,
    disableBiweekly: PropTypes.bool,
    displayDateRange: PropTypes.bool,
    disableMonthly: PropTypes.bool,
    inputRef: PropTypes.object
  };

  static defaultProps = {
    disableDaily: true,
    defaultValue: {
      type: 'monthly',
      date: moment()
        .subtract(1, 'month')
        .startOf('month')
        .toDate()
    },
    defaultToPresent: false,
    displayDateRange: false,
    disableMonthly: false
  };

  UNSAFE_componentWillMount() {
    const { defaultValue, maxRangeMonthFilter } = this.props;
    const dateRange = getDateRange(defaultValue.date, defaultValue.type);
    const validations = {};

    if (this.props.required) {
      validations.isNotEmpty = null;
    }

    if (maxRangeMonthFilter) {
      validations.maxRangeMonthFilter = maxRangeMonthFilter;
    }

    this.props.context.init(
      this.props.name,
      {
        dateRange,
        type: defaultValue.type
      },
      validations
    );
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  getValidationText() {
    const { name } = this.props;
    const { validations, errors } = this.props.context;

    if (errors[name]) {
      return errors[name].join('; ');
    }

    if (!validations[name]) {
      return '';
    }

    return Object.keys(validations[name])
      .filter(type => validations[name][type] === false)
      .map(type => {
        if (typeof validationText[type] === 'function') {
          return validationText[type](this.props[type]);
        }

        return validationText[type];
      })
      .join('; ');
  }

  @bind
  handleChange(values) {
    if (!values) {
      this.props.context.update(this.props.name, undefined);
      return;
    }

    this.props.context.update(this.props.name, values);
  }

  render() {
    const {
      className,
      name,
      label,
      defaultValue,
      disabled,
      disableFuture,
      disableDaily,
      disableBiweekly,
      hasCustom,
      inputRef,
      disableMonthly,
      displayDateRange
    } = this.props;
    const invalidText = this.getValidationText();

    return (
      <div className={cx('form-date-filter', className)}>
        {label && (
          <label className="form__label" htmlFor={name}>
            {label}
          </label>
        )}

        <div className="form-date-filter__body">
          <DateFilter
            onChange={this.handleChange}
            disabled={disabled}
            ref={inputRef}
            defaultValue={defaultValue}
            disableFuture={disableFuture}
            disableDaily={disableDaily}
            disableBiweekly={disableBiweekly}
            disableMonthly={disableMonthly}
            hasCustom={hasCustom}
            displayDateRange={displayDateRange}
          />
        </div>

        {invalidText && <div className="form-input__validation-text">{invalidText}</div>}
      </div>
    );
  }
}

export default withContext(FormContext)(FormDateFilter);
