import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Item extends Component {
  static propTypes = {
    index: PropTypes.number,
    data: PropTypes.any.isRequired,
    selected: PropTypes.bool,
    renderItem: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    onFocus: PropTypes.func
  };

  static defaultProps = {};

  @bind
  select() {
    this.props.onSelect(this.props.data);
  }

  @bind
  focus() {
    this.props.onFocus(this.props.index);
  }

  render() {
    const { data, selected, renderItem } = this.props;

    const className = classNames('select-list__item', {
      'select-list__item--highlighted': selected
    });

    return (
      <div className={className} onClick={this.select} onMouseEnter={this.focus}>
        {renderItem(data)}
      </div>
    );
  }
}

export default Item;
