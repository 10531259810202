import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ActionButton, ButtonV2, InvoicePreviewCard, StudentSideList } from 'components';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class SubmitInvoice extends Component {
  constructor() {
    super();
  }

  @bind
  handleRemove(kidId) {
    const { removeKid } = this.props;

    removeKid(kidId);
  }

  render() {
    const { data, school, constants, isSaving, isAutopostSaving, step, totalSteps, subFamily } = this.props;
    const items = data.parent_invoice.parent_invoice_items_attributes;

    return (
      <div className="modal__wrapper">
        <div className="create-invoice__header">
          <div className="create-invoice__row">
            <div className="create-invoice__header-text">
              <ActionButton iconName="back" className="modal__header-back" onClick={this.props.context.prevStep} />
              Submit Invoice {subFamily && `- ${subFamily.name}`}
            </div>

            <div className="create-invoice__header-step">{`Step ${step} / ${totalSteps}`}</div>
          </div>
        </div>

        <div className="create-invoice__container">
          <div className="label create-invoice__preview-label">Invoice Preview</div>

          <div className="create-invoice__submit">
            <InvoicePreviewCard data={data} items={items} school={school} constants={constants} />
            <StudentSideList students={data.students} onRemove={this.handleRemove} />
          </div>
        </div>

        <div className="modal__controls">
          <ButtonV2
            label="Save"
            secondary
            onClick={() => this.props.onSubmit(false)}
            isLoading={isSaving}
            disabled={!data.students.length || isAutopostSaving}
          />
          <ButtonV2
            label="Save and Send"
            onClick={() => this.props.onSubmit(true)}
            isLoading={isAutopostSaving}
            disabled={!data.students.length || isSaving}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  school: state.school.data,
  constants: state.constants.data
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(SubmitInvoice);
