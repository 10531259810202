import React, { Component } from 'react';
import classNames from 'classnames';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

// select sign in or sign out

class BulkSignActions_Step1 extends Component {
  setActionWrapper(direction) {
    this.props.setAction(direction);
    // clear students on action changing
    this.props.updateStudents([]);
    this.props.context.nextStep();
  }

  @bind
  createSignIn() {
    this.setActionWrapper('in');
  }

  @bind
  createSignOut() {
    if (!this.props.forToday) {
      return;
    }

    this.setActionWrapper('out');
  }

  render() {
    const { forToday } = this.props;

    const signOutCN = classNames('path-selector__option', {
      'path-selector__option--disabled': !forToday
    });

    return (
      <div className="modal__wrapper">
        <div className="modal__header">
          <span className="modal__header-steps">
            <span>Step 1</span> / 3
          </span>
        </div>
        <div className="modal__container">
          <div className="subheader subheader--accent">
            Which of the following action
            <br />
            do you want to take?
          </div>
          <div className="path-selector">
            <div className="path-selector__option">
              <div className="path-selector__option-inner" onClick={this.createSignIn}>
                Sign-In Attendance
              </div>
            </div>
            <div className={signOutCN}>
              <div className="path-selector__option-inner" onClick={this.createSignOut}>
                Sign-Out Attendance
                {!forToday && (
                  <div className="path-selector__option-inner__desc">
                    Students can be Signed-out only for current day
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(BulkSignActions_Step1);
