import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import Input from './Input';
import Submit from './Submit';
import Select from './Select';
import Checkbox from './Checkbox';
import Textarea from './Textarea';
import SignFilter from './SignFilter';
import AgeFilter from './AgeFilter';
import TimeInput from './TimeInput';
import DatePicker from './DatePicker';
import Typeahead from './Typeahead';
import MonthPicker from './MonthPicker';
import FileInput from './FileInput';
import WeekdayPicker from './WeekdayPicker';
import ScheduleSelect from './ScheduleSelect';
import DateFilter from './DateFilter';
import CustomAttribute from './CustomAttribute';
import UploadFile from './UploadFile';
import CheckboxGroup from './CheckboxGroup';
import RadioGroup from './RadioGroup';
import SearchTermsInput from './SearchTermsInput';
import validationMethods from './validationMethods';
import cloneDeep from 'lodash/cloneDeep';
import Radio from './Radio';
import Hidden from './Hidden';
import Row from './Row';
import './style.scss';
import { debounce } from 'lodash';
import { FormContext } from 'hocs/withContext';

/**
 * Form component
 *
 * A component that handles form creation and validation logic.
 * Contains wrappers for inputs from `components` and supposed to be used with them.
 *
 * TODO: Reduce amount of wrappers, extract reusable parts
 */
class Form extends Component {
  static Submit = Submit;
  static Input = Input;
  static Select = Select;
  static Checkbox = Checkbox;
  static Textarea = Textarea;
  static TimeInput = TimeInput;
  static SignFilter = SignFilter;
  static AgeFilter = AgeFilter;
  static DatePicker = DatePicker;
  static Typeahead = Typeahead;
  static MonthPicker = MonthPicker;
  static FileInput = FileInput;
  static WeekdayPicker = WeekdayPicker;
  static ScheduleSelect = ScheduleSelect;
  static DateFilter = DateFilter;
  static CustomAttribute = CustomAttribute;
  static UploadFile = UploadFile;
  static CheckboxGroup = CheckboxGroup;
  static RadioGroup = RadioGroup;
  static SearchTermsInput = SearchTermsInput;
  static Radio = Radio;
  static Hidden = Hidden;
  static Row = Row;

  static propTypes = {
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    onInit: PropTypes.func,
    isLoading: PropTypes.bool,
    errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    className: PropTypes.string,
    validateOn: PropTypes.oneOf(['change', 'submit']),
    formRef: PropTypes.func,
    'data-cy': PropTypes.string
  };

  static defaultProps = {
    errors: {}
  };

  constructor() {
    super();

    this.state = {
      values: {},
      validations: {},
      validationParams: {},
      linkedFields: {},
      errorsOnSubmit: null,
      isInitialized: false,
      numPendingEffects: 0
    };

    // This object stores field validation structures
    // before the form is created.
    this._fieldData = {};
    // Same, but for managing multiple fields trying to init simultaneously
    // at any point after the form is created
    this._updateQueue = [];
    this.debouncedProcessUpdateQueue = debounce(this.processUpdateQueue, 25);
  }

  getFormContext() {
    return {
      values: this.state.values,
      validations: this.state.validations,
      validationParams: this.state.validationParams,
      errors: this.props.errors,
      validateOn: this.props.validateOn,
      isLoading: this.props.isLoading,
      init: this.initializeField.bind(this),
      unmount: this.unmountField,
      update: this.updateField,
      validate: this.validateField.bind(this),
      submit: this.handleSubmit.bind(this),
      link: this.linkFields.bind(this),
      isFormValid: this.isFormValid.bind(this),
      updateValidations: this.updateValidations.bind(this),
      addPendingEffects: this.addPendingEffects.bind(this)
    };
  }

  componentDidMount() {
    this.initializeFormState();
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.props.onChange && !isEqual(this.state, nextState) && this.state.isInitialized) {
      this.props.onChange(cloneDeep(nextState.values), this.state.values);
    }
  }

  /**
   * Initialize a field
   *
   * Since this.setState is an async method, it's necessary to collect
   * all validations data and then create all field states at once.
   * That's why we collect these object into this._fieldData.
   *
   * @param {string} fieldName - A name of a new field
   * @param {string} initialValue - Initial value
   * @param {object} validations - an object which represents required validations
   */
  initializeField(fieldName, initialValue, validations) {
    if (!this.state.isInitialized) {
      this._fieldData[fieldName] = {
        initialValue,
        validations: {
          ...validations
        }
      };

      return;
    }

    // This code will be invoked if a field is added after the initialization
    this._updateQueue.push({ fieldName, initialValue, validations });
    this.debouncedProcessUpdateQueue();
  }

  processUpdateQueue() {
    let update;

    while ((update = this._updateQueue.shift())) {
      const { fieldName, initialValue, validations } = update;

      this.setState(state => ({
        values: {
          ...state.values,
          [fieldName]: initialValue
        },
        validations: {
          ...state.validations,
          [fieldName]: { ...validations }
        },
        validationParams: {
          ...state.validationParams,
          [fieldName]: { ...validations }
        }
      }));
    }
  }

  /**
   * Initialize form state using `this._fieldData`
   */
  @bind
  initializeFormState() {
    const nextState = { ...this.state, isInitialized: true };
    // This will create states for all fields
    // after all fields are initialized by `this.initializeField`.
    Object.keys(this._fieldData).forEach(key => {
      nextState.validations[key] = { ...this._fieldData[key].validations };
      nextState.validationParams[key] = { ...this._fieldData[key].validations };
      nextState.values[key] = this._fieldData[key].initialValue;
    });

    this.setState({ ...nextState, isInitialized: true }, () => {
      if (this.props.onInit) {
        this.props.onInit(this.state.values);
      }
    });
  }

  /**
   * unmountField
   * Removes value, validation, validationParams initialized when child component was mounted.
   * Called when child component is unmounted.
   * @param {string} fieldName
   */
  @bind
  unmountField(fieldName) {
    this.setState(state => {
      const nextState = {
        ...state,
        values: omit(state.values, fieldName),
        validations: omit(state.validations, fieldName),
        validationParams: omit(state.validationParams, fieldName)
      };

      if (nextState.linkedFields && nextState.linkedFields[fieldName]) {
        nextState.linkedFields = omit(nextState.linkedFields, fieldName);
      }

      return nextState;
    });
  }

  /**
   * Find an object which corresponds to an input with `name === fieldName`
   * and update its value
   *
   * @param {string} fieldName - Name of an input
   * @param {*}      value - Next value
   */
  @bind
  updateField(fieldName, value) {
    this.setState(state => {
      const { values, validations } = state;
      const fieldValidations = validations[fieldName];

      // Reset validations when value is changed
      fieldValidations &&
        Object.keys(fieldValidations).forEach(vKey => {
          fieldValidations[vKey] = null;
        });

      return {
        values: {
          ...values,
          [fieldName]: value
        },

        validations: {
          ...validations,
          [fieldName]: fieldValidations
        }
      };
    });
  }

  /**
   * Save special field name in state to allow linking (use it's value in the validations)
   *
   * @param {string} selfFieldName - Name of an input we want to validate
   * @param {string} linkedFieldName - Name of an input we want to have access like values[fieldName]
   */
  linkFields(selfFieldName, linkedFieldName) {
    const linkedFields = this.state.linkedFields || {};
    linkedFields[selfFieldName] = linkedFieldName;
    const nextState = { ...this.state, linkedFields };
    this.setState(nextState);
  }

  /**
   * Validate a value of an input
   *
   * @param {string} fieldName - Name of an input
   * @return {boolean} returns true if a field contains correct data.
   */
  validateField(fieldName) {
    const { values, validationParams, linkedFields } = this.state;
    const value = values[fieldName];
    const fieldValidations = { ...this.state.validations[fieldName] };
    const params = { linkedFields, fieldName };
    params.values = values;

    let isValid = true;

    for (let vKey of Object.keys(fieldValidations)) {
      params[vKey] = validationParams[fieldName][vKey];
      fieldValidations[vKey] = validationMethods[vKey](value, params);

      if (!fieldValidations[vKey]) {
        isValid = false;
        break;
      }
    }

    this.setState({
      validations: {
        ...this.state.validations,
        [fieldName]: fieldValidations
      }
    });

    return isValid;
  }

  /**
   * Validate data of all fields. Is invoked before onSubmit,
   * also used to disable the submit button.
   */
  isFormValid() {
    if (this.props.isLoading) {
      return false;
    }

    const { values, validationParams, linkedFields, numPendingEffects } = this.state;

    if (numPendingEffects > 0) {
      return false;
    }

    let isValid = true;

    loop: for (let fieldName of Object.keys(values)) {
      const fieldValidations = { ...this.state.validations[fieldName] };
      const params = { linkedFields, fieldName };
      params.values = values;

      for (let vKey of Object.keys(fieldValidations)) {
        params[vKey] = validationParams[fieldName][vKey];
        fieldValidations[vKey] = validationMethods[vKey](values[fieldName], params);

        if (!fieldValidations[vKey]) {
          isValid = false;
          break loop;
        }
      }
    }

    return isValid;
  }

  /**
   * Update validation state of all input fields
   */
  validateForm() {
    const { values, validationParams, linkedFields } = this.state;
    const nextValidations = {};

    Object.keys(this.state.validations).forEach(fieldName => {
      const value = values[fieldName];
      const fieldValidations = { ...this.state.validations[fieldName] };
      const params = { linkedFields, fieldName };
      params.values = values;

      for (let vKey of Object.keys(fieldValidations)) {
        params[vKey] = validationParams[fieldName][vKey];
        fieldValidations[vKey] = validationMethods[vKey](value, params);
      }

      nextValidations[fieldName] = fieldValidations;
    });

    this.setState({ validations: nextValidations });

    return nextValidations;
  }

  /**
   * A callback for a Form.Submit element. If form is valid, it invokes `this.props.onSubmit` method.
   * Is invoked when a <Form.Submit /> element is pressed.
   */
  @bind
  handleSubmit(e, submitName) {
    e && e.preventDefault();

    if (this.props.validateOn === 'submit') {
      const nextValidations = this.validateForm();
      if (this.props.onValidate) {
        this.props.onValidate(
          this.state.values,
          Object.keys(nextValidations).reduce((validations, fieldName) => {
            validations[fieldName] = Object.values(nextValidations[fieldName]).every(validation => !!validation);
            return validations;
          }, {})
        );
      }
    }

    if (this.isFormValid() && this.props.onSubmit) {
      const values = { ...this.state.values };

      if (submitName) {
        values[submitName] = true;
      }

      this.props.onSubmit(values, this.initializeFormState);
    }
  }

  @bind
  updateValidations(fieldName, nextValidations) {
    const { values, validationParams, linkedFields } = this.state;
    const value = values[fieldName];
    const fieldValidations = { ...nextValidations };
    const params = { linkedFields, fieldName };
    params.values = values;

    if (this.props.validateOn !== 'submit') {
      for (let vKey of Object.keys(fieldValidations)) {
        params[vKey] = validationParams[fieldName][vKey];
        fieldValidations[vKey] = validationMethods[vKey](value, params);
      }
    }

    this.setState(prevState => ({ validations: { ...prevState.validations, [fieldName]: fieldValidations } }));
  }

  /**
   * Gives form components the ability to force an invalid state while waiting for requests/effects to finish.
   * Form is only valid when the number of pending effects is 0, so components must clean up after themselves
   */
  @bind
  addPendingEffects(numEffects) {
    const numPendingEffects = Math.max(this.state.numPendingEffects + numEffects, 0);
    this.setState({ numPendingEffects });
  }

  render() {
    const { className, formRef, children, 'data-cy': dataCY } = this.props;

    const formCN = classNames({
      form: true,
      'form--invalid': !this.isFormValid(),
      [className]: Boolean(className)
    });

    return (
      <FormContext.Provider value={this.getFormContext()}>
        <form data-cy={dataCY} className={formCN} onSubmit={this.handleSubmit} ref={formRef}>
          {children}
        </form>
      </FormContext.Provider>
    );
  }
}

export default Form;
