import React, { Component } from 'react';
import { Icon, ButtonV2 } from 'components';
import './style.scss';

class SignedUpLabel extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isSending: false
    };
  }

  @bind
  onInvite() {
    const { onSend } = this.props;

    if (onSend) {
      this.setState({ isSending: true });
      onSend()
        .then(() => {
          this.setState({ isSending: false });
        })
        .catch(() => {
          this.setState({ isSending: false });
        });
    }
  }

  render() {
    const { isSignedUp, isInvited, onSend, email } = this.props;

    if (isSignedUp) {
      return (
        <div className="signed-up-label">
          <Icon name="email-verified" size={16} />
          <span>Signed up</span>
        </div>
      );
    } else if (email && onSend) {
      return (
        <ButtonV2
          className="signed-up-label__invite-button"
          secondary
          isLoading={this.state.isSending}
          label={isInvited ? 'Re-Invite' : 'Invite'}
          onClick={this.onInvite}
        />
      );
    } else {
      return null;
    }
  }
}

export default SignedUpLabel;
