import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './style.scss';

class StepProgressBar extends Component {
  static propTypes = {
    steps: PropTypes.array,
    currentStep: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
  };

  static defaultProps = {
    currentStep: 0,
    steps: []
  };

  @bind
  handleClick(index) {
    if (this.props.onClick) {
      this.props.onClick(index);
    }
  }

  renderSteps() {
    const { currentStep, steps } = this.props;
    const currentStepIndex = steps.findIndex(s => s.name === currentStep);

    return steps.map((step, index) => {
      const stepCN = cn('step-progress-bar__step', {
        'step-progress-bar__step--completed': index <= currentStepIndex,
        'step-progress-bar__step--current': index === currentStepIndex,
        'step-progress-bar__step--clickable': Boolean(this.props.onClick)
      });

      return (
        <React.Fragment key={index}>
          {index > 0 && <div className="step-progress-bar__line" />}

          <div className={stepCN} data-cy={`step-${index}`}>
            <div className="step-progress-bar__step-index" onClick={() => this.handleClick(index)}>
              {index + 1}
            </div>

            <div className="step-progress-bar__step-title">{step.label}</div>
          </div>
        </React.Fragment>
      );
    });
  }

  render() {
    const { className } = this.props;
    const barCN = cn('step-progress-bar', className);

    return <div className={barCN}>{this.renderSteps()}</div>;
  }
}

export default StepProgressBar;
