import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox, ActionButton } from 'components';
import moment from 'moment';
import history from 'lib/history';
import './style.scss';

class RosterSubmission extends Component {
  static propTypes = {
    fileURL: PropTypes.string,
    onClose: PropTypes.func
  };

  static defaultProps = {
    fileURL: ''
  };

  constructor() {
    super();
    this.state = {
      tagUsers: true,
      isLoading: false
    };
  }

  @bind
  toggleTagUsers() {
    this.setState({ tagUsers: !this.state.tagUsers });
  }

  @bind
  upload(values) {
    const { fileURL, onSubmit } = this.props;
    const { tagUsers } = this.state;
    this.setState({ isLoading: true });
    return req
      .uploadStudentRoster({
        import: {
          file_url: fileURL,
          tag: tagUsers ? values.tagName : undefined
        }
      })
      .then(() => {
        this.setState({ isLoading: false });
        onSubmit();
        history.push('/my-school/students');
      })
      .catch(err => {
        this.setState({ isLoading: false });
        Actions.reportError('There was problem uploading roster.', err);
      });
  }

  render() {
    const { tagUsers, isLoading } = this.state;
    const { onClose } = this.props;
    return (
      <div className="roster-submission">
        <div className="modal__header modal__header--bordered">
          <span className="modal__header-title">Upload Roster</span>
          <ActionButton iconName="close" onClick={() => onClose()} size={12} className="modal__close" />
        </div>

        <div className="modal__container">
          <Form className="roster-submission__form" validateOn="submit" onSubmit={this.upload} isLoading={isLoading}>
            <div className="form__row">
              <Checkbox
                reversed
                label="Do you want to tag students of this import?"
                checked={tagUsers}
                type="circle"
                onChange={this.toggleTagUsers}
              />
            </div>
            <div className="form__row">
              <Form.Input
                name="tagName"
                label="Tag Name"
                placeholder="Tag Name"
                required={tagUsers}
                disabled={!tagUsers}
                defaultValue={`Import ${moment().format('YYYY-MM-DD HH:mm A')}`}
              />
            </div>

            <div className="roster-submission__desc">
              We will automatically import the roster if you provided in Procare Template format. Otherwise, we will get
              in touch with you in next 24 hours.
            </div>

            <div className="form__row form__row--actions">
              <Form.Submit label="Submit" />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default RosterSubmission;
