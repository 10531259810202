import React from 'react';
import PropTypes from 'prop-types';
import { InlineEditor } from 'components';

function IntegerEditor({ onChange, ...props }) {
  const handleChange = value => {
    return onChange(value ? Number(value) : undefined);
  };

  return <InlineEditor.Input mask="999999999999999" onChange={handleChange} {...props} />;
}

IntegerEditor.propTypes = {
  onChange: PropTypes.func
};

export default IntegerEditor;
