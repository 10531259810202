import React, { Component } from 'react';
import { Form } from 'components';

class BluepayCC extends Component {
  render() {
    const { fee } = this.props;

    return (
      <div className="payments-panel__content">
        <div className="form__row form__row--justified">
          <Form.Input name="first_name" placeholder="First Name" label="First Name" required />

          <Form.Input name="last_name" placeholder="Last Name" label="Last Name" required />
        </div>

        <div className="form__row form__row--justified">
          <Form.Input
            placeholder="Card Number"
            label="Card Number"
            name="card_number"
            mask="9999 9999 9999 9999"
            required
          />
          <div className="form__row form__row--justified">
            <Form.Input label="CVV" placeholder="CVV" name="cvv" mask="9999" required />
            <Form.Input
              label="Expires"
              placeholder="MM/YY"
              name="expires"
              pattern="^[0-9]{2}\/[0-9]{2}$"
              mask="99/99"
              required
            />
          </div>
        </div>

        {fee && <div className="receive-payment__fee">Txn fee is ${fee}</div>}
      </div>
    );
  }
}

export default BluepayCC;
