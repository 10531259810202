import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { ButtonV2, TextInput } from 'components';

const MAX_CONTENT_LENGTH = 160;
const MAX_LENGTH_WARNING = "Your message can't be greater than 160 characters";
const HTML_TAGS_REGEX = /<\/?[\w\s="/.':;#-\/\?]+>/i;
const XSS_PATTERNS_REGEX = /<\s*script\s*>|<\s*\/\s*script\s*>|eval\(|\bjavascript\b|\balert\b|\bdocument\b|\bcookie\b|\bonload\b/gi;

class EditSmsStep extends PureComponent {
  static propTypes = {
    message: PropTypes.shape({
      content: PropTypes.string
    }),
    isEdit: PropTypes.bool,
    currentUserEmail: PropTypes.string,
    isSavingDraft: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    onChooseTemplate: PropTypes.func.isRequired
    // onSendTest: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      content: props.message.content || '',
      replyTo: props.message.replyTo || props.currentUserEmail,
      groupEntity: props.message.groupEntity,
      isContentValid: true,
      awsLoaded: false
    };
  }

  @bind
  updateContent(content) {
    this.setState({ content });
  }

  @bind
  validate() {
    const { content } = this.state;
    const isContentValid =
      content.length > 0 &&
      content.length <= MAX_CONTENT_LENGTH &&
      !XSS_PATTERNS_REGEX.test(content) &&
      !HTML_TAGS_REGEX.test(content);

    this.setState({ isContentValid });
    return isContentValid;
  }

  @bind
  handleContinue() {
    const { content, replyTo } = this.state;

    if (this.validate()) {
      this.props.onSubmit({ content, replyTo });
    }
  }

  @bind
  handleSaveTemplate() {
    const { content } = this.state;

    if (this.validate()) {
      this.props.onSaveTemplate({ content });
    }
  }

  @bind
  handleSaveDraft() {
    const { content, replyTo } = this.state;

    if (this.validate()) {
      this.props.onSaveDraft({ content, replyTo });
    }
  }

  render() {
    const { content, isContentValid } = this.state;

    const {
      message,
      onChooseTemplate,
      isEdit,
      fromTemplate,
      isSavingDraft,
      isSavingTemplate,
      groupEntity,
      fromLead
    } = this.props;

    return (
      <div className="communication-send-msg__edit-sms">
        <div className="modal__header modal__header--bordered">
          <span className="modal__header-title communication-send-msg__title">
            {isEdit ? 'Edit' : 'New'} SMS {fromTemplate ? 'Template' : 'Message'}
          </span>

          {!fromTemplate && !fromLead && (
            <span className="modal__header-steps">
              <span>Step 1</span> / 2
            </span>
          )}

          {!fromTemplate && (
            <ButtonV2
              tertiary
              className="communication-send-msg__edit-msg-template-btn"
              label="Choose From Template"
              onClick={onChooseTemplate}
              data-cy="choose-template"
            />
          )}
        </div>

        <div className="modal__container communication-send-msg__edit-sms-form">
          <div className="form__row">
            <div className={cx('form-input', { 'form-input--invalid': !isContentValid })}>
              <div className="form-input__field">
                <TextInput
                  multiline
                  name="content"
                  value={content}
                  placeholder="Write message here"
                  required
                  defaultValue={message.content}
                  onChange={this.updateContent}
                />
              </div>

              {!isContentValid && (
                <div className="form-input__validation-text">
                  {content.length === 0 && 'Cannot be empty'}
                  {content.length >= MAX_CONTENT_LENGTH && 'Cannot be greater than 160 characters'}
                  {XSS_PATTERNS_REGEX.test(content) && 'Cannot have xss patterns'}
                  {HTML_TAGS_REGEX.test(content) && 'Cannot have html tags'}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="communication-send-msg__edit-sms-warning">{MAX_LENGTH_WARNING}</div>

        {!groupEntity && (
          <div className="modal__controls">
            {fromTemplate && (
              <ButtonV2
                data-cy="save-template"
                label="Save Template"
                onClick={this.handleSaveTemplate}
                disabled={isSavingTemplate}
              />
            )}
            {!fromTemplate && (
              <ButtonV2
                secondary
                label="Save as Draft"
                onClick={this.handleSaveDraft}
                disabled={isSavingDraft}
                isLoading={isSavingDraft}
                data-cy="save-as-draft"
              />
            )}
            {!fromTemplate && (
              <ButtonV2
                label={fromLead ? 'Send' : 'Continue'}
                onClick={this.handleContinue}
                disabled={isSavingDraft && !isContentValid}
                data-cy="continue"
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUserEmail: state.currentUser.data.email,
  awsSignature: state.awsSignature.data,
  authToken: state.currentUser.data.auth_token
});

export default connect(mapStateToProps)(EditSmsStep);
