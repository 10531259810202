import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import range from 'lodash/range';
import { plural } from 'lib/utils';
import { SelectGroup, Form } from 'components';
import { FormContext } from 'hocs/withContext';

function SelectWhen({ formContext, days, defaultDays, onChange, text }) {
  const context = useContext(FormContext);
  const effectiveDays = formContext ? get(context, 'values.days', 0) : days;

  return (
    <div className="add-automation__action-when">
      {formContext ? (
        <Form.Select name="days" type="radio" title="Choose" label="When" defaultValue={defaultDays}>
          {range(0, 31).map(day => (
            <Form.Select.Item key={`day_${day}`} label={!day ? 'Immediate' : plural(day, 'day')} value={day} />
          ))}
        </Form.Select>
      ) : (
        <div className="form-select">
          <label className="form__label">When</label>
          <SelectGroup name="days" type="radio" title="Choose" checked={effectiveDays} onChange={onChange}>
            {range(0, 31).map(day => (
              <SelectGroup.Item key={`day_${day}`} label={!day ? 'Immediate' : plural(day, 'day')} value={day} />
            ))}
          </SelectGroup>
        </div>
      )}
      {effectiveDays > 0 && <div className="add-automation__action-when-description">{text}</div>}
    </div>
  );
}

SelectWhen.propTypes = {
  text: PropTypes.string.isRequired
};

export default SelectWhen;
