import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Preloader, Icon } from 'components';
import './style.scss';

const Button = ({
  label,
  type,
  onClick,
  wide,
  secondary,
  tertiary,
  light,
  className,
  disabled,
  danger,
  success,
  isLoading,
  tabIndex,
  star,
  iconName,
  iconSize,
  hidden,
  'data-cy': dataCY
}) => {
  const buttonCN = classNames({
    button: true,
    'button--wide': wide,
    'button--secondary': secondary,
    'button--tertiary': tertiary,
    'button--light': light,
    'button--danger': danger,
    'button--success': success,
    'button--star': star,
    'button--loading': isLoading,
    [className]: Boolean(className)
  });

  if (hidden) {
    return null;
  }

  return (
    <button
      type={type}
      className={buttonCN}
      onClick={onClick}
      disabled={isLoading || disabled}
      tabIndex={tabIndex}
      data-cy={dataCY}
    >
      {star && <Icon className="icon__star" name="premium-star" size={18} />}
      {iconName && <Icon className="button__icon" name={iconName} size={iconSize} />}
      {isLoading && <Preloader small className="button__preloader" />}
      <span className="button__label">{label}</span>
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  wide: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  danger: PropTypes.bool,
  star: PropTypes.bool,
  tabIndex: PropTypes.number,
  secondary: PropTypes.bool,
  light: PropTypes.bool,
  iconName: PropTypes.string,
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hidden: PropTypes.bool
};

export default Button;
