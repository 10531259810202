import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';

// @TODO list render
function ValueRenderer({ type, value, extra }) {
  let content = value;

  if (type === 'boolean') return null;

  if (!value) {
    return <div className="info-list__item-value info-list__item-value--empty">-</div>;
  }

  if (type === 'date') {
    content = moment(value).format('MMM D, YYYY');
  }

  if (type === 'list') {
    const opt = get(extra, 'options', []).find(o => value.includes(o.id));
    content = opt ? opt.name : '';
  }

  return <div className="info-list__item-value">{content}</div>;
}

ValueRenderer.propTypes = {
  type: PropTypes.oneOf(['string', 'list', 'integer', 'decimal', 'date', 'boolean']),
  extra: PropTypes.object,
  value: PropTypes.any
};

export default ValueRenderer;
