import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ScheduleSelect } from 'components';
import { DAY_TO_CODE, CODE_TO_DAY } from 'constants/dayCodes';
import GenericEditor from '../GenericEditor';
import './style.scss';

class ScheduleEditor extends PureComponent {
  static serialize(values) {
    const payload = {};

    for (const day of Object.keys(values)) {
      const dayCode = DAY_TO_CODE[day];
      payload[dayCode] = [];

      if (values[day].am) {
        payload[dayCode].push('am');
      }

      if (values[day].pm) {
        payload[dayCode].push('pm');
      }
    }

    return payload;
  }

  static deserialize(vObj = {}) {
    const values = {};

    for (const dayCode of Object.keys(vObj || {})) {
      const day = CODE_TO_DAY[dayCode];

      values[day] = {
        am: vObj[dayCode].indexOf('am') >= 0,
        pm: vObj[dayCode].indexOf('pm') >= 0
      };
    }

    return values;
  }

  static propTypes = {
    values: PropTypes.object,
    disabled: PropTypes.bool,
    validTimes: PropTypes.object
  };

  static defaultProps = {
    values: {},
    disabled: false,
    validTimes: null,
    timesToShow: null
  };

  @bind
  submit(values) {
    return Promise.resolve(this.props.onChange(ScheduleEditor.serialize(values)));
  }

  render() {
    const { disabled, validTimes, timesToShow } = this.props;
    const defaultValue = ScheduleEditor.deserialize(this.props.defaultValue);

    return (
      <GenericEditor
        className="inline-schedule-editor"
        onSubmit={this.submit}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        {({ value, onChange }) => (
          <ScheduleSelect
            values={value}
            validTimes={validTimes}
            timesToShow={timesToShow}
            layout="inline"
            onChange={onChange}
          />
        )}
      </GenericEditor>
    );
  }
}

export default ScheduleEditor;
