import moment from 'moment';
import Meal from './schema/Meal';
import Bottle from './schema/Bottle';
import Mood from './schema/Mood';
import Nap from './schema/Nap';
import Bathroom from './schema/Bathroom';
import Incident from './schema/Incident';
import Photo from './schema/Photo';
import Video from './schema/Video';
import Absent from './schema/Absent';
import Learning from './schema/Learning';
import NameToFace from './schema/NameToFace';
import Observation from './schema/Observation';
import Default from './schema/Default';

const ActivitySchema = {
  meal_activity: Meal,
  bottle_activity: Bottle,
  mood_activity: Mood,
  nap_activity: Nap,
  bathroom_activity: Bathroom,
  incident_activity: Incident,
  photo_activity: Photo,
  video_activity: Video,
  medication_activity: Default,
  note_activity: Default,
  absent_activity: Absent,
  learning_activity: Learning,
  name_to_face_activity: NameToFace,
  observation_activity: Observation
};

const buildActivityPayload = (type, params) => {
  if (!type) {
    return null;
  }

  const payload = ActivitySchema[type](params.data, params.daily_activity, params.isEditing);
  const activityDate = moment(
    (params.daily_activity ? params.daily_activity.activity_date : params.data.date) || undefined
  ).format('YYYY-MM-DD');

  if (params.data.flag) {
    const newActivityPayload = {
      ...payload,
      activity: {
        ...(payload.daily_activity || {}),
        approval_status: payload.daily_activity.is_staff_only ? 'approved' : 'pending'
      },
      daily_activity: undefined,
      activiable: { incident: payload.incident, learnings: payload.learning },
      teacher_ids: [params.data.staff_present],
      filters: { activity_date: activityDate },
      lookup_activity_date: activityDate
    };

    return newActivityPayload;
  } else {
    const exendedWithStaffPresentField = {
      ...payload,
      daily_activity: { ...(payload.daily_activity || {}), staff_present_id: params.data.staff_present || null },
      filters: { activity_date: activityDate },
      lookup_activity_date: activityDate
    };

    return exendedWithStaffPresentField;
  }
};

export default buildActivityPayload;
