import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import cn from 'classnames';
import './style.scss';

class PhotoActivityThumb extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    onSelect: PropTypes.func
  };

  static defaultProps = {
    photo: {},
    selected: false
  };

  @bind
  handleClick() {
    if (!this.props.onSelect) {
      return;
    }

    this.props.onSelect(this.props.id);
  }

  getClassName() {
    const { className, onSelect } = this.props;

    return cn('photo-activity-thumb', {
      'photo-activity-thumb--selected': this.props.selected,
      'photo-activity-thumb--selectable': onSelect,
      [className]: className !== undefined
    });
  }

  render() {
    const { url } = this.props;
    const className = this.getClassName();

    return (
      <div className={className} onClick={this.handleClick}>
        <div className="photo-activity-thumb__img" style={{ backgroundImage: `url(${url})` }} />
        <Icon name="tick" className="photo-activity-thumb__tick" size={21} />
      </div>
    );
  }
}

export default PhotoActivityThumb;
