import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollBars from 'react-custom-scrollbars';
import PersonCard from './PersonCard';
import differenceWith from 'lodash/differenceWith';
import { Checkbox, PageNav, TooltipTrigger } from 'components';
import { Tooltip } from 'components/StudentList';
import './style.scss';
import classNames from 'classnames';

const PER_PAGE = 18;

class SelectPersonList extends Component {
  static propTypes = {
    persons: PropTypes.array,
    allPersons: PropTypes.array,
    selectedIds: PropTypes.array,
    onSelect: PropTypes.func,
    selectType: PropTypes.oneOf(['checkbox', 'radio']),
    type: PropTypes.oneOf(['kid', 'teacher', 'lead']),
    validations: PropTypes.object,
    // disableInvalid determines if a failed validation should disallow selection
    // When it is of type object, we can have some validations that disallow
    // selection (errors) and some that allow them (warnings)
    disableInvalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    noResultText: PropTypes.string,
    listHeight: PropTypes.number, // Passed to Scrollbars
    renderPersonCard: PropTypes.func,
    resetSelection: PropTypes.func
  };

  static defaultProps = {
    persons: [],
    allPersons: [],
    selectedIds: [],
    type: 'kid',
    listHeight: 285,
    selectType: 'checkbox',
    disableInvalid: true
  };

  constructor(props) {
    super(props);

    const selectedPersonIds = this.buildPersonsHash(props.persons);
    this.enabledPersonIds = {};

    props.selectedIds.forEach(id => {
      selectedPersonIds[id] = true;
    });

    this.state = {
      enabledPersons: this.getEnabledPersons(props.persons, props.validations),
      selectedPersonIds,
      page: 1
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedIds !== this.props.selectedIds) {
      const selectedPersonIds = this.state.selectedPersonIds;
      this.props.persons.forEach(person => {
        selectedPersonIds[person.id] = this.props.selectedIds.includes(person.id);
      });
      this.setState({ selectedPersonIds });
    }
    if (prevProps.persons !== this.props.persons || prevProps.validations !== this.props.validations) {
      const enabledPersons = this.getEnabledPersons(this.props.persons, this.props.validations);
      this.setState({ enabledPersons });
    }
  }

  get selectionState() {
    const { selectedIds, persons, disableInvalid } = this.props;
    const { enabledPersons } = this.state;
    const isSubset = (smaller, larger) => smaller.every(val => larger.includes(val));

    const selectablePersons = disableInvalid ? enabledPersons : persons;
    const selectableIds = selectablePersons.map(p => p.id);
    const outOfRenderIds = differenceWith(
      selectedIds,
      selectableIds,
      (selectedId, personId) => personId === selectedId
    );

    if (selectedIds.length === 0) {
      return 'unchecked';
    } else if (selectedIds.length === selectableIds.length && isSubset(selectedIds, selectableIds)) {
      return 'checked';
    }
    // some selectedIds out of rendered scope
    else if (outOfRenderIds.length > 0) {
      // Show dotted checkbox
      return 'partial';
    } else {
      return 'unchecked';
    }
  }

  get allFilteredSelected() {
    const { selectedIds, persons, disableInvalid } = this.props;
    const { enabledPersons } = this.state;

    const selectablePersons = disableInvalid ? enabledPersons : persons;

    const selectedPersons = selectablePersons.filter(p => selectedIds.find(id => id === p.id));
    return selectedPersons.length === selectablePersons.length && selectedPersons.length !== 0;
  }

  buildPersonsHash(persons) {
    const hash = {};

    for (let person of persons) {
      hash[person.id] = false;
    }

    return hash;
  }

  buildSelectedArray() {
    return Object.keys(this.state.selectedPersonIds).filter(id => this.state.selectedPersonIds[id]);
  }

  @bind
  togglePerson(personId) {
    const { selectType, persons } = this.props;
    const val = this.state.selectedPersonIds[personId];

    let selectedPersonIds = this.state.selectedPersonIds;
    if (selectType === 'radio') {
      selectedPersonIds = this.buildPersonsHash(persons);
    }
    selectedPersonIds[personId] = !val;

    this.setState({ selectedPersonIds }, this.handleUpdate);
  }

  @bind
  toggleAll() {
    const { persons } = this.props;
    const { enabledPersons } = this.state;

    if (enabledPersons.length === 0) {
      return;
    }

    let selectedPersonIds = this.state.selectedPersonIds;

    if (!this.allFilteredSelected) {
      enabledPersons.forEach(p => {
        selectedPersonIds[p.id] = true;
      });
    } else {
      selectedPersonIds = this.buildPersonsHash(persons);
    }

    this.setState({ selectedPersonIds }, this.handleUpdate);
  }

  getEnabledPersons(persons, validations) {
    if (!this.props.disableInvalid || !validations) {
      return persons;
    }

    return persons.filter(p => {
      const isEnabled =
        Object.keys(validations).filter(
          rule => validations[rule](p) && (this.props.disableInvalid === true || this.props.disableInvalid?.[rule])
        ).length === 0;

      this.enabledPersonIds[p.id] = isEnabled;
      return isEnabled;
    });
  }

  handleUpdate() {
    const idsArray = this.buildSelectedArray();

    if (this.props.onSelect) {
      this.props.onSelect(idsArray);
    }
  }

  getDisableMessage(person, validations = {}) {
    const rulesNames = Object.keys(validations);

    if (rulesNames.length === 0) {
      return undefined;
    }

    const violatedRules = rulesNames.filter(rule => validations[rule](person));
    return violatedRules.length > 0 ? violatedRules.join(', ') : undefined;
  }

  changePage(nextPage) {
    this.setState({ page: nextPage });
  }

  renderPersons() {
    const { persons, validations, type, renderPersonCard, isPaginated, disableInvalid } = this.props;
    const { selectedPersonIds, page } = this.state;

    const personsToRender = isPaginated ? persons.slice(PER_PAGE * (page - 1), PER_PAGE * page) : persons;

    return personsToRender.map(p => {
      const disableMessage = this.getDisableMessage(p, validations);
      const isDisabled = disableInvalid && validations && !this.enabledPersonIds[p.id];

      return typeof renderPersonCard === 'function' ? (
        renderPersonCard({
          person: p,
          type,
          onSelect: this.togglePerson,
          selected: selectedPersonIds[p.id],
          validationError: disableMessage,
          disableInvalid: isDisabled
        })
      ) : (
        <PersonCard
          key={p.id}
          id={p.id}
          name={p.name}
          color={p.color}
          type={type}
          status={p.is_admin ? 'admin' : undefined}
          picURL={p.profile_pic_url}
          onSelect={this.togglePerson}
          selected={selectedPersonIds[p.id]}
          validationError={disableMessage}
          disableInvalid={isDisabled}
        />
      );
    });
  }

  getSelectedMessage() {
    const { type, selectedIds } = this.props;
    const count = selectedIds.length > 0 ? selectedIds.length : 'No';

    switch (type) {
      case 'kid':
        return `${count} STUDENT${selectedIds.length !== 1 ? 'S' : ''} SELECTED`;

      case 'teacher':
        return `${count} STAFF SELECTED`;

      case 'lead':
        return `${count} LEAD${selectedIds.length !== 1 ? 'S' : ''} SELECTED`;
    }
  }

  render() {
    const { selectedIds, selectType, listHeight, type, noResultText, persons, allPersons, isPaginated } = this.props;
    const { page } = this.state;
    const count = persons.length;
    const selectedPersons = selectedIds
      .map(id => allPersons.find(person => person.id === id))
      .filter(p => p !== undefined);

    const cn = classNames('select-person-list', { 'select-person-list--paginated': isPaginated });

    return (
      <div className={cn}>
        {selectType === 'checkbox' && (
          <div className="select-person-list__header">
            <div className="select-person-list__header-group">
              <Checkbox
                className="select-person-list__select-all"
                checked={['checked', 'partial'].includes(this.selectionState)}
                dot={this.selectionState === 'partial'}
                onChange={this.toggleAll}
                disabled={count === 0}
                label="Select All"
              />

              <div className="select-person-list__selected">
                {selectedIds.length ? (
                  <TooltipTrigger tooltip={<Tooltip students={selectedPersons} />} maxHeight={110}>
                    {this.getSelectedMessage()}
                  </TooltipTrigger>
                ) : (
                  this.getSelectedMessage()
                )}
              </div>
            </div>

            {isPaginated && (
              <div className="select-person-list__header-pagenav">
                <PageNav
                  page={page}
                  total={count}
                  perPage={PER_PAGE}
                  onPrev={() => this.changePage(page - 1)}
                  onNext={() => this.changePage(page + 1)}
                />
              </div>
            )}
          </div>
        )}

        <ScrollBars autoHeight autoHeightMin={listHeight}>
          <div className="select-person-list__cards">
            {count > 0 ? (
              this.renderPersons()
            ) : (
              <div className="select-person-list__no-results">
                {noResultText || `No ${type === 'kid' ? 'student' : 'staff'} found by filter.`}
              </div>
            )}
          </div>
        </ScrollBars>
      </div>
    );
  }
}

export default SelectPersonList;
