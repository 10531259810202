import React, { Component } from 'react';
import { ButtonV2 } from 'components';
import './style.scss';

class CancelInvoice extends Component {
  state = {
    isLoading: false
  };

  @bind
  cancelInvoice() {
    const { data } = this.props;

    this.setState({ isLoading: true });
    req
      .cancelInvoice({ id: data.id })
      .then(() => {
        Actions.showFlash('Invoice is cancelled');
        this.setState({ isLoading: false });
        this.props.onClose(true);
      })
      .catch(err => {
        Actions.reportError('Unable to cancel invoice', err);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { onClose } = this.props;
    const { isLoading } = this.state;

    return (
      <div className="modal__wrapper cancel-invoice">
        <div className="modal__header">
          <div className="subheader">Cancel Invoice</div>

          <span className="modal__header-note">Are you sure you want to cancel this invoice?</span>
        </div>

        <div className="modal__controls">
          <ButtonV2 onClick={onClose} secondary label="No" />
          <ButtonV2 onClick={this.cancelInvoice} label="Yes, Cancel" isLoading={isLoading} />
        </div>
      </div>
    );
  }
}

export default CancelInvoice;
