import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, TooltipTrigger, ButtonV2 } from 'components';
import { getPathname } from 'lib/history';
import cx from 'classnames';

import './style.scss';

class ManageDynamicTable extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    className: PropTypes.string,
    fields: PropTypes.object,
    'data-cy': PropTypes.string
  };

  static defaultProps = {
    onSubmit: () => undefined,
    fields: {}
  };

  static getPersistedFields() {
    const userId = localStorage.getItem('kinderlime-user');
    const currentSchoolId = localStorage.getItem('kinderlime-current-school');
    const pathname = getPathname();

    return JSON.parse(localStorage.getItem(`fields_${userId}_${currentSchoolId}_${pathname}`) || '{}');
  }

  constructor(props) {
    super(props);

    this.state = {
      formKey: 0,
      values: {
        ...(Object.keys(props.fields) || {}).reduce((defaultFields, fieldName) => {
          if (props.fields[fieldName].default) defaultFields[fieldName] = true;
          return defaultFields;
        }, {}),
        ...ManageDynamicTable.getPersistedFields()
      }
    };
  }

  componentDidMount() {
    this.props.onSubmit(this.state.values);
  }

  handleChange(key, value) {
    return this.setState({ [key]: value });
  }

  @bind
  onSubmit(values, closeTooltip) {
    let updatedValues = {
      ...this.state.values,
      ...values
    };

    this.setState({
      values: updatedValues
    });

    this.props.onSubmit(updatedValues);

    this.persistFields(updatedValues);

    if (typeof closeTooltip === 'function') {
      closeTooltip();
    }
  }

  @bind
  onOpen() {
    // forces re-render the form
    if (this.fieldsForm) {
      this.setState({ formKey: this.state.formKey + 1 });
    }
  }

  @bind
  persistFields(fields) {
    const userId = localStorage.getItem('kinderlime-user');
    const currentSchoolId = localStorage.getItem('kinderlime-current-school');
    const pathname = getPathname();
    localStorage.setItem(`fields_${userId}_${currentSchoolId}_${pathname}`, JSON.stringify(fields));
  }

  getDataCYattribute(checked, disabled) {
    const { 'data-cy': dataCY } = this.props;

    if (dataCY) {
      const attributes = [
        `${dataCY}-field`,
        disabled ? `${dataCY}-field-disabled` : '',
        checked ? `${dataCY}-field-checked` : ''
      ];

      return attributes.join(' ');
    }

    return '';
  }

  @bind
  renderFieldsForm(closeTooltip) {
    const { values } = this.state;
    const { fields, 'data-cy': dataCY } = this.props;

    return (
      <div className="manage-dynamic-table__tooltip-content tooltip-content__inner" key={this.state.formKey}>
        <Form ref={form => (this.fieldsForm = form)} onSubmit={values => this.onSubmit(values, closeTooltip)}>
          <div className="manage-dynamic-table__list">
            {Object.keys(fields).map(key => {
              const field = fields[key];

              return (
                <div key={key} className="manage-dynamic-table__list-item">
                  <Form.Checkbox
                    key={key}
                    label={field.label}
                    name={key}
                    disabled={!!field.disabled}
                    defaultValue={values[key]}
                    data-cy={this.getDataCYattribute(values[key], !!field.disabled)}
                  />
                </div>
              );
            })}
          </div>

          <div className="form__row form__row--actions">
            <Form.Submit label="Apply" data-cy={dataCY && `${dataCY}-apply`} />
          </div>
        </Form>
      </div>
    );
  }

  render() {
    const { className, 'data-cy': dataCY } = this.props;

    return (
      <div data-cy={dataCY} className={cx('manage-dynamic-table', className)}>
        {
          <TooltipTrigger
            white
            side="center"
            triggerOn="click"
            renderTooltip={this.renderFieldsForm}
            onOpen={this.onOpen}
            className="manage-dynamic-table__tooltip"
          >
            <ButtonV2
              className="manage-dynamic-table__toggle-btn"
              icon
              iconName="manage-table-columns"
              iconSize={16}
              data-cy={dataCY && `${dataCY}-trigger`}
            />
          </TooltipTrigger>
        }
      </div>
    );
  }
}

export default ManageDynamicTable;
