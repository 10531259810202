import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import { InfiniteScroll, Preloader, Placeholder, ActionButton, ButtonV2, Icon, TextInput } from 'components';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import TemplateItem from './TemplateItem';

class SelectTemplateStep extends PureComponent {
  static propTypes = {
    kind: PropTypes.oneOf(['sms', 'email']),
    onSelect: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      hasMore: true,
      templates: [],
      selectedId: undefined,
      loading: false,
      search: ''
    };

    this.debouncedGetData = debounce(this.getData, 500);
  }

  componentDidMount() {
    this.getData();
  }

  @bind
  async getData(page = this.state.page, search = this.state.search) {
    this.setState({ loading: true });

    const payload = {
      page,
      filters: {
        message: { subject: search, state: 'template', kind: this.props.kind }
      }
    };

    try {
      const messagesResponse = await req.enrollmentsMessageTemplates(payload);
      this.setState({
        page,
        templates: [...this.state.templates, ...messagesResponse.messages],
        hasMore: !!messagesResponse.messages.length
      });
    } catch (e) {
      Actions.reportError('Unable to download message templates', e);
    }

    this.setState({ loading: false });
  }

  @bind
  getNextPage() {
    this.getData(this.state.page + 1);
  }

  @bind
  selectTemplate(selectedId) {
    this.setState({ selectedId: this.state.selectedId === selectedId ? undefined : selectedId });
  }

  @bind
  goBack() {
    this.props.onBack();
  }

  @bind
  submit() {
    this.props.onBack(this.state.templates.find(t => t.id === this.state.selectedId));
  }

  @bind
  changeSearch(value) {
    this.setState({ page: 1, search: value, templates: [], loading: true }, this.debouncedGetData);
  }

  renderItems() {
    return this.state.templates.map(t => (
      <TemplateItem key={t.id} template={t} onSelect={this.selectTemplate} selected={t.id === this.state.selectedId} />
    ));
  }

  render() {
    const { templates, loading, hasMore, selectedId, search } = this.state;

    const className = classNames('communication-send-msg__templates', {
      'communication-send-msg__templates--loading': loading
    });

    const isSearchActive = search.trim().length > 0;

    return (
      <div className={className}>
        <div className="modal__header modal__header--bordered">
          <ActionButton iconName="back" size={20} onClick={this.goBack} />

          <div className="modal__header-title">Select Template</div>
        </div>

        <div className="communication-send-msg__templates-content">
          <div className="communication-send-msg__templates-search">
            <Icon size={18} color="#a3a3a3" name="search" className="communication-send-msg__templates-search-icon" />

            <TextInput
              type="text"
              value={search}
              className="communication-send-msg__templates-search-input"
              placeholder="Search Template"
              onChange={this.changeSearch}
            />
          </div>
          <div className="communication-send-msg__templates-scroll-container">
            {templates.length === 0 ? (
              isSearchActive ? (
                <Placeholder.NoResult image="tucker" className="conversations__placeholder">
                  We couldn't find any matches.
                </Placeholder.NoResult>
              ) : (
                <Placeholder.NoResult image="tucker2" className="conversations__placeholder">
                  No templates created
                </Placeholder.NoResult>
              )
            ) : (
              <Scrollbars className="communication-send-msg__templates-scroll">
                <InfiniteScroll
                  pageStart={0}
                  loading={loading}
                  hasMore={hasMore}
                  threshold={20}
                  onEndReach={this.getNextPage}
                  useWindow={false}
                >
                  {this.renderItems()}

                  <div className="communication-send-msg__templates-preloader">
                    <Preloader />
                  </div>
                </InfiniteScroll>
              </Scrollbars>
            )}
          </div>
        </div>
        <div className="modal__controls">
          <ButtonV2 label="Choose" onClick={this.submit} disabled={!selectedId} />
        </div>
      </div>
    );
  }
}

export default SelectTemplateStep;
