import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Steps from '../ModalController/Steps';
import AddClassInfo from './AddClassInfo';
import AddPricingDetails from './AddPricingDetails';
import Index from './AddPolicies/index';

class AddClass extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  static defaultProps = {
    data: {
      schoolClass: {},
      edit: false
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      schoolClass: {},
      classLoading: false,
      submitLoading: false
    };
  }

  componentDidMount() {
    const { edit, id } = this.props.data;

    if (edit) {
      this.setState({ classLoading: true }, () =>
        req.schoolClass({ id }).then(schoolClass => {
          this.setState({
            schoolClass: {
              ...schoolClass,
              addons: undefined,
              addons_attributes: schoolClass.addons,
              policy: undefined,
              policy_attributes: schoolClass.policy
            },
            classLoading: false
          });
        })
      );
    }
  }

  @bind
  updateClassInfo(classInfo) {
    this.setState({
      schoolClass: { ...this.state.schoolClass, ...classInfo }
    });
  }

  @bind
  updatePricingDetails(pricingDetails) {
    this.setState({
      schoolClass: {
        ...this.state.schoolClass,
        price: pricingDetails.price,
        addons_attributes: pricingDetails.addons_attributes
      }
    });
  }

  @bind
  updatePolicies(policies) {
    this.setState({
      schoolClass: { ...this.state.schoolClass, policy_attributes: policies }
    });
  }

  @bind
  submit() {
    const { edit } = this.props.data;
    const requestPayload = this.buildRequestPayload();
    const performSubmit = edit ? req.updateClass : req.createClass;
    const flashMessage = edit ? 'Class has been updated' : 'Added new class';

    this.setState({ submitLoading: true }, () =>
      performSubmit(requestPayload)
        .then(() => {
          Actions.showFlash(flashMessage);
          req.classes();
          this.setState({ submitLoading: false });
          this.props.onClose();
        })
        .catch(e => {
          Actions.reportError('Unable to update class', e);
          this.setState({ submitLoading: false });
        })
    );
  }

  buildRequestPayload() {
    const { schoolClass } = this.state;

    return {
      id: schoolClass.id,
      school_class: {
        ...schoolClass,
        max_enrollments: Number(schoolClass.max_enrollments),
        price: Number(schoolClass.price)
      }
    };
  }

  render() {
    const { schoolClass, classLoading, submitLoading } = this.state;
    const { grades } = this.props;
    const { edit } = this.props.data;

    return (
      <Steps>
        <Steps.Item>
          <AddClassInfo
            loading={classLoading}
            submitLoading={submitLoading}
            edit={edit}
            schoolClass={schoolClass}
            grades={grades}
            onChange={this.updateClassInfo}
            onSubmit={this.submit}
          />
        </Steps.Item>

        <Steps.Item>
          <AddPricingDetails edit={edit} schoolClass={schoolClass} onChange={this.updatePricingDetails} />
        </Steps.Item>

        <Steps.Item>
          <Index
            edit={edit}
            schoolClass={schoolClass}
            onChange={this.updatePolicies}
            onSave={this.submit}
            submitLoading={submitLoading}
          />
        </Steps.Item>
      </Steps>
    );
  }
}

const mapStateToProps = state => ({
  grades: state.constants.data.school_classes.grades,
  schoolClass: state.schoolClass.data
});

export default connect(mapStateToProps)(AddClass);
