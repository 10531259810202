import React from 'react';
import moment from 'moment';
import { Form } from 'components';
import TEXT_CAPTIONS from './constants';

const DefaultActivityForm = ({ activity, forceStaff }) => {
  return (
    <div className="form__body">
      <div className="form__row form__row--datetime">
        <Form.DatePicker label="Date" name="date" defaultValue={moment(activity.activity_time).toDate()} required />

        <Form.TimeInput label="Time" name="time" defaultValue={Helpers.formatTime(activity.activity_time)} required />
      </div>

      <div className="form__row">
        <Form.Textarea name="note" defaultValue={activity.activiable.note} placeholder="Note" label="Note" />
      </div>

      <div className="form__row">
        {forceStaff ? (
          TEXT_CAPTIONS.staffOnlyForce
        ) : (
          <Form.Checkbox
            type="circle"
            name="staffOnly"
            label={TEXT_CAPTIONS.staffOnly}
            defaultValue={activity.is_staff_only}
          />
        )}
      </div>
    </div>
  );
};

export default DefaultActivityForm;
