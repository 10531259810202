import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'components';

const isAllSelected = (allValues, currentValue) => allValues.length === currentValue.length;
const onToggleSelectAll = (allValues, currentValue, handler) =>
  handler(isAllSelected(allValues, currentValue) ? [] : [...allValues]);

function ReportCheckboxGroup({
  label,
  defaultValue,
  value = [],
  maxSelection,
  showSelectAll = true,
  options,
  name,
  onUpdateField
}) {
  const allValues = options.map(o => o.value);

  return (
    <div className="reports-modal__checkbox-list-item">
      <div className="reports-modal__checkbox-list-item__header">
        <div className="reports-modal__subtitle">{label}</div>
        {showSelectAll && (
          <Checkbox
            label="Select All"
            type="square"
            onChange={() => onToggleSelectAll(allValues, value, v => onUpdateField(name, v))}
            checked={isAllSelected(allValues, value)}
          />
        )}
      </div>
      <Form.CheckboxGroup
        className="reports-modal__checkbox-group"
        options={options}
        name={name}
        maxSelection={maxSelection}
        type="square"
        defaultValue={defaultValue}
      />
    </div>
  );
}

ReportCheckboxGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  showSelectAll: PropTypes.bool,
  maxSelection: PropTypes.number,
  defaultValue: PropTypes.array,
  options: PropTypes.array,
  onUpdateField: PropTypes.func
};

export default ReportCheckboxGroup;
