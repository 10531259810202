import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Steps from 'modals/ModalController/Steps';
import { isDiscount } from 'lib/utils';
import InvoiceDetails from './InvoiceDetails';
import SubmitPlan from './SubmitPlan';
import SelectKids from './SelectKids';
import SelectKind from './SelectKind';
import ManagePresets from '../ManagePresets';
import { Form } from 'components';
import moment from 'moment';
import find from 'lodash/find';
import './style.scss';

class CreateBillingPlan extends Component {
  static propTypes = {
    data: PropTypes.shape({
      action: PropTypes.oneOf(['create', 'edit']),
      plan: PropTypes.object,
      prevRequestPayload: PropTypes.object,
      kid: PropTypes.object,
      fromTemplates: PropTypes.bool
    })
  };

  static defaultProps = {
    data: {
      action: 'create',
      plan: null,
      prevRequestPayload: undefined,
      fromTemplates: false,
      kid: null
    }
  };

  static studentsWithNoAssignedPlans = kind => ({
    method: (student, filters) => {
      if (filters.no_assigned_plans) {
        return !student.billing_plans_assignment[kind];
      }

      return true;
    },
    control: <Form.Checkbox name="no_assigned_plans" label="No Assigned Plans" defaultValue={true} />,
    paramKey: 'no_assigned_plans',
    paramValue: true
  });

  _steps = null;

  constructor(props) {
    super(props);

    this.state = {
      presetKind: 'preset',
      kind: props.data.plan ? props.data.plan.kind : null,
      billing_plan: props.data.plan ? this.createPlanObject(props.data.plan, props.data.plan.kind) : {},
      kid_ids: [],
      groupEntity: props.data.plan && props.data.plan.group_entity,
      templateId: props.data.templateId,
      isAssignTemplate: !!props.data.templateId,
      isLoading: false
    };
  }

  static getKidsByPlanKind(kind) {
    const payload = {
      filters: {
        billing_plan: {
          kind,
          assigned: 'all'
        }
      }
    };
    req.billingPlanStudents(payload); // without subfamilies
    req.billingPlanSubFamilyStudents(payload); // with subfamilies
  }

  UNSAFE_componentWillMount() {
    req.school();
    req.rooms();
    req.invoiceItemPresets();
    req.billingPlanTemplates();
    req.ledgers({
      filters: {
        ledger: {
          kind: ['subsidy']
        }
      },
      page: 1,
      per_page: 100
    });
    if (this.state.isAssignTemplate) {
      CreateBillingPlan.getKidsByPlanKind(this.state.kind);
    }
  }

  @bind
  bindSteps(node) {
    this._steps = node;
  }

  updateField(fieldName) {
    return (value, cb) => {
      this.setState({ [fieldName]: value }, cb);
    };
  }

  createPlanObject(plan, selectedKind = this.state.kind) {
    const { action } = this.props.data;
    const kind = plan ? plan.kind : selectedKind;

    let planObject = null;

    if (plan) {
      planObject = {
        id: plan.id,
        name: plan.name,
        start_date: action === 'edit' || plan.start_date ? moment(plan.start_date, 'YYYY-MM-DD').toDate() : undefined,
        end_date: plan.end_date ? moment(plan.end_date, 'YYYY-MM-DD').toDate() : undefined,
        invoice_cycle: plan.invoice_cycle,
        cycle: plan.cycle,
        due_date: plan.due_date,
        invoice_date: plan.invoice_date,
        template: false,
        auto_post: plan.auto_post,
        group_entity: plan.group_entity,
        kind,
        sub_family_id: plan.sub_family_id
      };
    } else {
      planObject = {
        name: '',
        start_date: moment()
          .subtract(1, 'month')
          .startOf('month')
          .toDate(),
        end_date: undefined,
        invoice_cycle: 'previous',
        cycle: 'monthly',
        due_date: '',
        invoice_date: '',
        template: false,
        auto_post: true,
        kind,
        sub_family_id: null
      };
    }

    const itemType = kind === 'tuition' ? 'invoice' : 'charge';
    const fetchedItemsName = `plan_${itemType}_items`;
    const localItemsName = fetchedItemsName + '_attributes';

    planObject[localItemsName] = plan ? plan[fetchedItemsName] || plan[localItemsName] : [];
    planObject[localItemsName] = planObject[localItemsName].map(p => ({
      ...p,
      _discountType: '$',
      _discount: isDiscount(p.kind) ? -p.price : ''
    }));

    return planObject;
  }

  @bind
  updateSaveAsTemplate(value) {
    this.setState({
      billing_plan: { ...this.state.billing_plan, template: value }
    });
  }

  @bind
  updateSendInvoice() {
    this.setState({
      billing_plan: { ...this.state.billing_plan }
    });
  }

  @bind
  onUpdateAutoPost(value) {
    this.setState({ billing_plan: { ...this.state.billing_plan, auto_post: value } });
  }

  @bind
  removeKid(kidId) {
    const nextKidIds = this.state.kid_ids.filter(id => id !== kidId);
    this.setState({ kid_ids: nextKidIds });
  }

  @bind
  changePlanTemplate(planId) {
    if (planId === 'other') {
      this.setState({ templateId: 'other', billing_plan: this.createPlanObject() });
      return;
    }

    const planTemplate = find(this.props.billingPlanTemplates, { id: planId });

    if (planTemplate && Object.keys(planTemplate).length > 0) {
      const plan = this.createPlanObject({ ...planTemplate });
      delete plan.id;

      this.setState({
        templateId: planTemplate.id,
        billing_plan: plan
      });
    }
  }

  @bind
  selectPlanKind(kind) {
    this.setState(
      {
        kind,
        billing_plan: this.createPlanObject(null, kind),
        kid_ids: []
      },
      () => {
        CreateBillingPlan.getKidsByPlanKind(kind);
        if (this.props.data.fromTemplates) this._steps.goToStep('invoiceDetails');
        else this._steps.nextStep();
      }
    );
  }

  @bind
  submit(saveAsTemplate) {
    const { action, prevRequestPayload, plan } = this.props.data;
    const { billing_plan, templateId, kind } = this.state;

    const startDateStr = moment(billing_plan.start_date[0]).format('YYYY-MM-DD');
    const endDateStr = billing_plan.end_date
      ? moment(billing_plan.end_date[billing_plan.end_date.length - 1]).format('YYYY-MM-DD')
      : undefined;

    const planKind = kind || plan.kind;

    const requestPayload = {
      id: this.props.data.plan ? this.props.data.plan.id : undefined,
      kid_ids: [],
      sub_families: [],
      billing_plan: {
        ...billing_plan,
        kind: planKind,
        start_date: startDateStr,
        end_date: !endDateStr && plan && plan.end_date ? '' : endDateStr,
        template: templateId === 'other' ? billing_plan.template : false
      }
    };

    const { subFamilyStudentHash } = this.props;
    this.state.kid_ids.forEach(id => {
      if (subFamilyStudentHash[id]) {
        requestPayload.sub_families.push({
          kid_id: subFamilyStudentHash[id].studentId,
          sub_family_id: subFamilyStudentHash[id].subFamilyId
        });
      } else {
        requestPayload.kid_ids.push(id);
      }
    });

    const items = requestPayload.billing_plan.plan_invoice_items_attributes;

    if (planKind === 'tuition') {
      requestPayload.billing_plan.plan_invoice_items_attributes = items.map(i => {
        return {
          ...i,
          ledger: undefined,
          ledger_id: i.ledger_id || (i.ledger ? i.ledger.id : undefined),
          desc: i.description,
          description: undefined
        };
      });
    }

    this.setState({ isLoading: true });

    if (!action || action === 'create') {
      if (this.props.data.fromTemplates || saveAsTemplate) {
        req
          .createPlanTemplate(requestPayload)
          .then(() => {
            Actions.showFlash('A new plan template is created');
            this.setState({ isLoading: false });
            this.props.onClose(true);
          })
          .catch(err => {
            Actions.reportError('Something went wrong', err);
            this.setState({ isLoading: false });
          });

        return;
      }

      req
        .createBillingPlan(requestPayload)
        .then(() => {
          if (!this.props.data.fromTemplates) {
            req.recurringPlans();
          }

          Actions.showFlash('A new plan is created');
          this.setState({ isLoading: false });
          this.props.onClose(true);
        })
        .catch(err => {
          Actions.reportError('Something went wrong', err);
          this.setState({ isLoading: false });
        });
    } else if (action === 'edit') {
      if (!this.props.data.fromTemplates) {
        req
          .updateBillingPlan(requestPayload)
          .then(() => req.recurringPlans(prevRequestPayload))
          .then(() => {
            this.setState({ isLoading: false });
            Actions.showFlash('Recurring Plan successfully updated');
            this.props.onClose(true);
          })
          .catch(err => {
            Actions.reportError('Something went wrong', err);
            this.setState({ isLoading: false });
          });
      } else {
        req
          .updatePlanTemplate(requestPayload)
          .then(() => {
            this.setState({ isLoading: false });
            Actions.showFlash('Plan template was updated');
            this.props.onClose(true);
          })
          .catch(err => {
            Actions.reportError('Something went wrong', err);
            this.setState({ isLoading: false });
          });
      }
    }
  }

  @bind
  openInvoiceDetails() {
    if (this._steps) {
      this._steps.goToStep('invoiceDetails');
    }
  }

  @bind
  openManagePresets(presetKind) {
    if (this._steps) {
      this.setState({ presetKind }, () => this._steps.goToStep('managePresets'));
    }
  }

  render() {
    const {
      billing_plan,
      kid_ids,
      templateId,
      isLoading,
      isAssignTemplate,
      groupEntity,
      presetKind,
      kind
    } = this.state;
    const { action, fromTemplates, kid } = this.props.data;
    const { students } = this.props;

    return (
      <Steps ref={this.bindSteps}>
        {!isAssignTemplate && action !== 'edit' && (
          <Steps.Item name="selectKind">
            <SelectKind onSelect={this.selectPlanKind} />
          </Steps.Item>
        )}

        {!fromTemplates && action !== 'edit' && (
          <Steps.Item>
            <SelectKids
              kind={kind}
              students={students}
              defaultSelected={kid_ids}
              onUpdate={this.updateField('kid_ids')}
            />
          </Steps.Item>
        )}

        <Steps.Item name="invoiceDetails">
          <InvoiceDetails
            isLoading={isLoading}
            plan={billing_plan}
            onUpdate={this.updateField('billing_plan')}
            kind={kind}
            actionType={action}
            isAssignTemplate={isAssignTemplate}
            isTemplate={fromTemplates}
            groupEntity={groupEntity}
            onSubmit={this.submit}
            onTemplateChange={this.changePlanTemplate}
            onManagePresets={this.openManagePresets}
            templateId={action === 'edit' ? templateId || 'other' : templateId}
            kid={kid}
            onClose={this.props.onClose}
          />
        </Steps.Item>

        {!fromTemplates && (
          <Steps.Item>
            <SubmitPlan
              students={students}
              templateId={templateId}
              isLoading={isLoading}
              kind={kind}
              data={{ billing_plan, kid_ids }}
              removeKid={this.removeKid}
              onSubmit={this.submit}
              onTemplateUpdate={this.updateSaveAsTemplate}
              onUpdateAutoPost={this.onUpdateAutoPost}
            />
          </Steps.Item>
        )}

        <Steps.Item hidden name="managePresets">
          <ManagePresets data={{ kind: presetKind }} onBack={this.openInvoiceDetails} />
        </Steps.Item>
      </Steps>
    );
  }
}

const mapStateToProps = state => {
  const subFamilyStudentHash = {};
  let allStudents = [];

  if (!state.billingPlanStudents.loading && !state.billingPlanSubFamilyStudents.loading) {
    allStudents = [...state.billingPlanStudents.data];

    state.billingPlanSubFamilyStudents.data.forEach(student => {
      if (student.sub_families) {
        const subFamilyStudents = student.sub_families.map(sf => {
          const hash = `${student.id}_${sf.id}`;
          subFamilyStudentHash[hash] = {
            studentId: student.id,
            subFamilyId: sf.id
          };
          return {
            ...student,
            subFamily: {
              ...sf
            },
            id: hash
          };
        });

        allStudents = allStudents.concat(subFamilyStudents);
      }
    });
  }

  return {
    billingPlanTemplates: state.billingPlanTemplates.data,
    students: allStudents,
    subFamilyStudentHash
  };
};

export default connect(mapStateToProps)(CreateBillingPlan);
