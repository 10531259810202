import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Form, StudentList } from 'components';
import { StripeProvider, Elements } from 'react-stripe-elements';
import getCardType from 'credit-card-type';
import { getStripePubKey, getCurrentSchool } from 'lib/utils/selectors';
import currency from 'lib/currency';
import StripeCC from './StripeCC';
import BluePayCC from './BluePayCC';
import TuitionExpressCC from './TuitionExpressCC';
import './style.scss';
import { compose } from 'redux';
import withLaunchDarkly from 'hocs/withLaunchDarkly';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class Step1 extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  static defaultProps = {
    data: {
      paymentId: null,
      kidNames: '',
      isOnlineSetup: false,
      paymentMode: null,
      filters: null,
      edit: false,
      fromFamilies: false,
      isCardPresentEnabled: false
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      stripe: null,
      formErrors: {},
      familyKids: get(props, 'data.students', []),
      cardToken: null,
      isLoading: false,
      isSplitFamily: Boolean(get(props, 'data.subFamilies', []).length)
    };
  }

  componentDidMount() {
    req.schoolFeeBreakdown();

    const { getDefaultSubFamily, formValues, setFormValues } = this.props;
    const { family_id, fromFamilies } = this.props.data;

    if (fromFamilies) {
      this.setState({ isLoading: true });
      req
        .subFamilies({ family_id })
        .then(subFamilies => {
          const isSplitFamily = Boolean(subFamilies.length);
          const nextState = {
            isSplitFamily,
            isLoading: false
          };
          if (isSplitFamily) {
            setFormValues({
              ...formValues,
              sub_family_id: getDefaultSubFamily()
            });
          }
          this.setState(nextState);
        })
        .catch(e => {
          console.error(e);
          // do nothing
        });
    } else if (this.state.isSplitFamily) {
      req.studentsByFamily({ family_id }).then(familyKids => {
        this.setState({
          familyKids
        });
      });
    }
  }

  @bind
  setStripe(stripe) {
    this.setState({ stripe });
  }

  @bind
  setTeCardToken(cardToken) {
    this.setState({ cardToken });
  }

  @bind
  submit(values) {
    const { data, billingGateway, billingAccountId, setCardPresentState } = this.props;
    const { edit, isReg, registration_id, registration_kind, subFamilyId, accountBalance } = data;
    const mode = values.mode === 'CC' ? 'AutoDebit' : values.mode;

    if (values.mode === 'CC' && (!isReg || !billingAccountId) && billingGateway !== 'te') {
      Actions.showFlash('Please select mode to receive payment', 'alert');
      return;
    }

    const parentPayment = {
      description: values.description,
      amount: Number(values.amount),
      family_id: edit || isReg ? undefined : data.family_id,
      sub_family_id: values.sub_family_id || subFamilyId,
      mode: edit ? undefined : mode,
      notes: values.notes,
      check_number: values.mode === 'Check' ? values.check_number : undefined,
      skip_notification: edit || isReg ? undefined : !values.email_notification
    };

    if (values.mode === 'CPT') {
      setCardPresentState({
        payment: parentPayment,
        kids: this.getKids(),
        accountBalance
      });
      this.props.context.nextStep();
      return;
    }

    this.setState({ isLoading: true, formErrors: {} });

    let promise = Promise.resolve();

    if (values.mode === 'CC') {
      if (billingGateway === 'stripe') {
        promise = this.state.stripe
          .createToken({
            name: values.name
          })
          .then(({ token, error }) => {
            if (error) {
              this.setState({ isLoading: false });
              throw error;
            }

            Object.assign(parentPayment, {
              token: token.id,
              token_type: 'card',
              brand: token.card && token.card.brand,
              last4: token.card && token.card.last4
            });
          });
      } else if (billingGateway === 'bluepay') {
        const payload = {
          CARD_ACCOUNT: values.card_number.replace(/[\s]+/g, ''),
          CARD_CVV2: values.cvv,
          CARD_EXPIRE: values.expires.replace('/', ''),
          NAME1: values.first_name,
          NAME2: values.last_name
        };
        const brands = getCardType(payload.CARD_ACCOUNT);
        promise = this.getBluepayToken(payload).then(token => {
          Object.assign(parentPayment, {
            token,
            brand: brands && brands.length ? brands[0].niceType : undefined,
            token_type: 'card',
            last4: payload.CARD_ACCOUNT.substr(payload.CARD_ACCOUNT.length - 4)
          });
        });
      } else if (billingGateway === 'te') {
        if (!this.state.cardToken) {
          Actions.showFlash('Please add credit card info', 'alert');
          this.setState({ isLoading: false });
          return;
        }
        Object.assign(parentPayment, {
          token: this.state.cardToken,
          token_type: 'card'
        });
      }
    }

    let route = edit ? 'editPayment' : 'receivePayment';
    if (isReg) {
      route = edit ? 'editRegistrationPayment' : 'receiveRegistrationPayment';
    }

    promise
      .then(() =>
        req[route]({
          id: data.id,
          registration_id,
          registration_kind,
          parent_payment: parentPayment
        })
      )
      .then(resp => {
        Actions.showFlash(`Payment has been ${edit ? 'updated' : 'received'}`);
        this.setState({ isLoading: false });

        if (registration_id) {
          this.props.onClose(resp.parent_payment);
        } else {
          this.props.onClose(true);
        }
      })
      .catch(err => {
        Actions.reportError('Unable to receive payment', err);
        this.setState({ isLoading: false, formErrors: err.response.data.form_errors });
      });
  }

  @bind
  updateFormValues(values) {
    this.props.setFormValues(values);
  }

  @bind
  getBluepayToken(payload) {
    const { billingPubKey, billingAccountId } = this.props;
    window.BluePay.setCredentials(billingAccountId, billingPubKey);

    return new Promise((resolve, reject) => {
      window.BluePay.createToken(payload, result => {
        if (result && result.TRANS_ID) {
          resolve(result.TRANS_ID);
        } else {
          if (result && result.MESSAGE) {
            reject(new Error(result.MESSAGE));
          } else {
            reject(new Error('There was problem creating bluepay token'));
          }
        }
      });
    });
  }

  renderCC() {
    const { billingGateway, stripePubKey, billingAccountId, feeBreakdown, feePaidBy, merchantIdPresent } = this.props;
    const { cardToken } = this.state;
    const fee = get(
      feeBreakdown,
      `${billingGateway}.${feePaidBy === 'parent_payment_fee_paid_by_school' ? 'paid_by_school' : 'paid_by_parent'}.cc`,
      ''
    );

    if (billingGateway === 'te') {
      return (
        <TuitionExpressCC
          fee={fee}
          onSetToken={this.setTeCardToken}
          token={cardToken}
          merchantIdPresent={merchantIdPresent}
        />
      );
    }

    if (billingGateway === 'bluepay') {
      return <BluePayCC fee={fee} />;
    }

    return (
      <StripeProvider apiKey={stripePubKey} options={{ stripeAccount: billingAccountId }}>
        <Elements>
          <StripeCC setStripe={this.setStripe} fee={fee} />
        </Elements>
      </StripeProvider>
    );
  }

  getKids() {
    const { getSubFamilies, formValues } = this.props;
    const { familyKids, isSplitFamily } = this.state;

    if (!isSplitFamily || !formValues.sub_family_id) return familyKids;

    const subFamily = getSubFamilies().find(sf => sf.id === formValues.sub_family_id) || {};
    const kidIds = subFamily.kid_ids || [];

    return familyKids.filter(k => kidIds.includes(k.id));
  }

  render() {
    const { isLoading, formErrors, isSplitFamily } = this.state;
    const { flags, merchantIdPresent } = this.props;
    const {
      isOnlineSetup,
      amount,
      edit,
      description,
      check_number,
      paymentMode,
      notes,
      isReg,
      accountBalance,
      isCardPresentEnabled
    } = this.props.data;
    const { billingAccountId, billingGateway, getSubFamilies, getDefaultSubFamily, formValues } = this.props;
    const isSubmitDisabled = !formValues.description || (!edit && (!formValues.amount || !formValues.mode));
    const ccAvailable = (billingGateway === 'stripe' && billingAccountId) || billingGateway === 'te';
    const isCardPresentAvailable =
      isCardPresentEnabled &&
      flags['online_cms_cardpresent_operational'] &&
      billingGateway === 'te' &&
      merchantIdPresent;

    return (
      <Form
        className="modal__wrapper receive-payment-step1"
        validateOn="submit"
        errors={formErrors}
        onSubmit={this.submit}
        onChange={this.updateFormValues}
        isLoading={isLoading}
      >
        <div className="modal__header">
          <div className="subheader">{edit ? 'Edit' : 'Receive'} Payment</div>
          {isSplitFamily && !edit && (
            <div className="form__row add-credit__choose-subfamily">
              <div className="add-credit__choose-subfamily-label">Choose family</div>
              <Form.Select
                className="add-credit__choose-subfamily-select"
                name="sub_family_id"
                title="Choose Family"
                defaultValue={getDefaultSubFamily()}
                required
              >
                {getSubFamilies().map(sf => (
                  <Form.Select.Item key={sf.id} value={sf.id} label={sf.name} />
                ))}
              </Form.Select>
            </div>
          )}
          <span className="modal__header-note">
            <StudentList students={this.getKids()} />
          </span>
          {accountBalance !== undefined && (
            <div className="mt-8">
              Account Balance: <strong>{currency.getPrice(accountBalance)}</strong>
            </div>
          )}
        </div>

        <div className="modal__container">
          <div className="form__row form__row--justified">
            <Form.Input
              label="Amount"
              type="amount"
              className="receive-payment-step1__amount"
              name="amount"
              required={!edit}
              asterisk={!edit}
              amount
              autoComplete="off"
              defaultValue={edit ? amount : formValues.amount}
              placeholder={!edit && amount ? amount : '0'}
              disabled={edit && paymentMode === 'AutoDebit'}
              data-cy="amount"
            />

            <Form.Select
              label="Payment type"
              name="mode"
              title="Select payment type"
              disabled={edit}
              required={!edit}
              asterisk={!edit}
              defaultValue={formValues.mode ?? paymentMode}
              data-cy="mode"
            >
              {(isReg && ccAvailable && merchantIdPresent
                ? [<Form.Select.Item key="mode-cc" value="CC" label="Credit Card" />]
                : []
              )
                .concat(
                  isOnlineSetup
                    ? [<Form.Select.Item key="mode-online" value="AutoDebit" label="Charge Parent ACH/CC" />]
                    : []
                )
                .concat([
                  <Form.Select.Item key="mode-cash" value="Cash" label="Cash" />,
                  <Form.Select.Item key="mode-check" value="Check" label="Check" />
                ])
                .concat(isCardPresentAvailable ? [<Form.Select.Item key="mode-cpt" value="CPT" label="Card" />] : [])
                .concat([<Form.Select.Item key="mode-other" value="Other" label="Other" />])}
            </Form.Select>
          </div>

          {formValues.mode === 'AutoDebit' && (
            <div className="receive-payment-step1__note">
              Note: This mode will charge the parent ACH account or Credit card that they have on file.
            </div>
          )}

          {formValues.mode === 'CC' && ccAvailable && this.renderCC()}

          {formValues.mode === 'Check' && (
            <Form.Input
              className="receive-payment-step1__check"
              name="check_number"
              label="Check Number"
              placeholder="Enter Check Number"
              required={!edit}
              asterisk={!edit}
              disable={edit}
              defaultValue={check_number}
            />
          )}

          <Form.Textarea
            label="Description"
            placeholder="Add description"
            name="description"
            className="receive-payment-step1__description"
            defaultValue={formValues.description ?? description}
            required
            asterisk
            data-cy="description"
          />

          {!edit && !isReg && (
            <Form.Checkbox
              label="Send email notification to parents on payment"
              type="circle"
              name="email_notification"
              defaultValue={formValues.email_notification ?? true}
              className="receive-payment-step1__notification"
            />
          )}

          <Form.Input
            label="Staff only note"
            placeholder="Add optional internal note"
            name="notes"
            className="mt-16"
            defaultValue={formValues.notes ?? notes}
          />

          <div className="form__row form__row--actions">
            <Form.Submit
              label={formValues.mode === 'CPT' ? 'Add card details' : isReg ? 'Submit & Mark as paid' : 'Submit'}
              disabled={isSubmitDisabled}
              data-cy="submit"
            />
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  stripePubKey: getStripePubKey(state), // stripe key for kinderlime
  billingAccountId: getCurrentSchool(state, 'billing_account_id'),
  billingGateway: getCurrentSchool(state, 'billing_gateway'),
  billingPubKey: getCurrentSchool(state, 'billing_publishable_key'),
  feePaidBy: getCurrentSchool(state, 'parent_payment_fee_paid_by'),
  subFamilies: state.subFamilies.data,
  feeBreakdown: state.feeBreakdown.data,
  merchantIdPresent: getCurrentSchool(state, 'merchant_id_present')
});

const enhance = compose(withContext(ModalControllerStepsContext), withLaunchDarkly, connect(mapStateToProps));

export default enhance(Step1);
