import React, { Component } from 'react';
import './style.scss';
import cx from 'classnames';
import { DAY_CODES, ALL_DAY_CODES, CODE_TO_DAY } from 'constants/dayCodes';

class ScheduleLarge extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { timeSchedule, timesToShow, includeWeekend } = this.props;

    if (!timeSchedule) return null;

    const dayCodes = timesToShow
      ? Object.keys(timesToShow)
      : includeWeekend ?? (timeSchedule.S || timeSchedule.Su)
      ? ALL_DAY_CODES
      : DAY_CODES;

    return dayCodes.map(code => {
      const schedule = timeSchedule[code] || [];
      const dayName = CODE_TO_DAY[code].toLowerCase();

      const amClass = cx({
        'schedule-large-content__item': true,
        'schedule-large-content__item--active': schedule.includes('am'),
        'schedule-large-content__item--hidden': timesToShow && !timesToShow[code]?.includes('am')
      });

      const pmClass = cx({
        'schedule-large-content__item': true,
        'schedule-large-content__item--active': schedule.includes('pm'),
        'schedule-large-content__item--hidden': timesToShow && !timesToShow[code]?.includes('pm')
      });

      const dayClass = cx({
        'schedule-large__day': true,
        'schedule-large__day--active': schedule.length > 0
      });

      return (
        <div className="schedule-large" key={code}>
          <div className={dayClass}>{dayName}</div>
          <div className="schedule-large-content">
            <span className={amClass}>AM</span>
            <span className={pmClass}>PM</span>
          </div>
        </div>
      );
    });
  }
}

export default ScheduleLarge;
