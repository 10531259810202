import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';
import LEDGER_TYPES from 'constants/ledgerTypes';
import './style.scss';

class CreateLedger extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    data: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  @bind
  submit(values) {
    const payload = {
      ledger: {
        ...values
      }
    };
    const promise = req.createLedger(payload);

    this.setState({ isLoading: true });
    promise
      .then(ledger => {
        Actions.showFlash('Ledger created successfully');
        this.setState({ isLoading: false });
        this.props.onClose(ledger);
      })
      .catch(err => {
        this.setState({ isLoading: false });
        Actions.reportError('here was problem creating ledger', err);
      });
  }

  render() {
    const { isLoading } = this.state;

    return (
      <div className="modal__wrapper create-ledger">
        <div className="modal__header">
          <span className="modal__header-title">Create Ledger</span>
        </div>

        <div className="modal__container">
          <Form validateOn="submit" onSubmit={this.submit} isLoading={this.state.isLoading}>
            <div className="form__row">
              <Form.Input
                data-cy={'name'}
                label="title"
                required
                name="name"
                placeholder="Ledger Name (eg: operational expenses)"
              />
            </div>

            <div className="form__row">
              <Form.Select data-cy={'kind'} label="Type" type="radio" required name="kind" placeholder="Select Type">
                {Object.keys(LEDGER_TYPES).map(t => (
                  <Form.Select.Item key={t} value={t} label={LEDGER_TYPES[t]} />
                ))}
              </Form.Select>
            </div>

            <div className="form__row">
              <Form.Textarea
                label="description"
                title="Description"
                name="description"
                placeholder="Describe ledger details (eg: books, activities, postage)"
              />
            </div>

            <div className="form__row form__row--actions">
              <Form.Submit data-cy="submit" label="Create" disabled={isLoading} />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default CreateLedger;
