import React, { useState } from 'react';
import { ButtonV2, SelectGroup, DatePicker } from 'components';
import { capitalize, openEmptyWindow } from 'lib/utils';
import moment from 'moment';

const PERIOD_TYPES = ['daily', 'weekly'];

function PrintSISO({ data, onClose }) {
  const [selectedType, setSelectedType] = useState(PERIOD_TYPES[0]);
  const [periodValue, setPeriodValue] = useState(data.date);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    const newWindow = openEmptyWindow();
    if (!newWindow) return;

    try {
      setLoading(true);
      const date = moment(selectedType === 'daily' ? periodValue : periodValue[0]).format('YYYY-MM-DD');

      const response = await req.sisoKidsPDF({ date, kind: selectedType });
      newWindow.location.replace(response.pdf);
      onClose();
    } catch (e) {
      setLoading(false);
      Actions.reportError('Unable to get attendance PDF', e);
    }
  };

  const handleTypeChange = nextType => {
    setSelectedType(nextType);

    if (nextType === 'daily' && Array.isArray(periodValue)) {
      setPeriodValue(periodValue[0]);
    } else if (nextType === 'weekly' && !Array.isArray(periodValue)) {
      setPeriodValue([
        moment(periodValue)
          .startOf('isoWeek')
          .toDate(),
        moment(periodValue)
          .endOf('isoWeek')
          .toDate()
      ]);
    }
  };

  return (
    <div className="modal__wrapper">
      <div className="modal__header">
        <div className="modal__header-title">Print Sign-In Sheet</div>
      </div>
      <div className="modal__container">
        <div className="form__row form__row--justified">
          <div className="form-select">
            <SelectGroup
              name="period-type"
              type="radio"
              title="Choose"
              checked={selectedType}
              onChange={handleTypeChange}
            >
              {PERIOD_TYPES.map(periodType => (
                <SelectGroup.Item key={periodType} label={capitalize(periodType)} value={periodType} />
              ))}
            </SelectGroup>
          </div>
          <div className="form-date">
            <DatePicker type={selectedType} value={periodValue} onChange={setPeriodValue} />
          </div>
        </div>
      </div>
      <div className="modal__controls">
        <ButtonV2 label="submit" disabled={loading} onClick={onSubmit} />
      </div>
    </div>
  );
}

export default PrintSISO;
