import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ActionButton, ButtonV2, StudentCard, Checkbox, TooltipTrigger, Preloader } from 'components';
import { Tooltip } from 'components/StudentList';
import { applyFilters } from 'lib/utils';
import union from 'lodash/union';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import FilterBar from './FilterBar';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

// select students for operate
class BulkSignActions_Step2 extends Component {
  static initialFilters = {
    search: '',
    roomId: '',
    tagIds: [],
    isSignedIn: null
  };

  constructor(props) {
    super(props);

    const filters = {
      ...BulkSignActions_Step2.initialFilters,
      isSignedIn: this.getIsSignedIn() // sign-in list: all not signed in, sign-out list: all signed in
    };
    const filteredStudents = applyFilters(this.props.students, filters);
    this.state = {
      filteredStudents: filteredStudents,
      filteredStudentsIds: filteredStudents.map(s => s.id),
      initialFilteredCount: filteredStudents.length
    };
  }

  getIsSignedIn() {
    if (!this.props.forToday && this.props.action === 'in') {
      return undefined;
    }

    return this.props.action === 'out';
  }

  @bind
  updateFilters(values) {
    const filters = {
      ...values,
      isSignedIn: this.getIsSignedIn()
    };
    const filteredStudents = applyFilters(this.props.students, filters);
    this.setState({
      filteredStudents: filteredStudents,
      filteredStudentsIds: filteredStudents.map(s => s.id),
      initialFilteredCount: filteredStudents.length
    });
  }

  studentIsChecked(student) {
    return this.props.selectedStudents.indexOf(student.id) > -1;
  }

  toggleStudent(id) {
    const { selectedStudents, updateStudents } = this.props;
    const index = selectedStudents.indexOf(id);
    const newSelectedStudents =
      index > -1
        ? [...selectedStudents.slice(0, index), ...selectedStudents.slice(index + 1)]
        : [...selectedStudents, id];
    updateStudents(newSelectedStudents);
  }

  renderStudents() {
    const { rooms } = this.props;
    const { filteredStudents } = this.state;
    const studentsList = filteredStudents.map((student, index) => {
      const assignedRoom = rooms.find(room => room.id === (student.signed_in_section_id || student.current_section_id));
      let assignedRoomName = `${student.signed_in_section_id ? 'Signed in to ' : 'Assigned to '} ${assignedRoom?.name}`;
      return (
        <StudentCard
          key={`student-${index}`}
          student={student}
          checked={this.studentIsChecked(student)}
          onClick={() => this.toggleStudent(student.id)}
          roomName={assignedRoomName}
        />
      );
    });
    return studentsList.length ? (
      studentsList
    ) : (
      <div className="students-cards__message">No students found by filter</div>
    );
  }

  selectedCounter() {
    const { selectedStudents } = this.props;
    return selectedStudents.length > 0 ? `${selectedStudents.length} STUDENTS SELECTED` : 'NO STUDENT SELECTED';
  }

  @bind
  toggleAllVisible() {
    const { selectedStudents, updateStudents } = this.props;
    const { filteredStudentsIds } = this.state;
    const newSelectedStudents = !this.isAllVisibleChecked()
      ? union(selectedStudents, filteredStudentsIds)
      : difference(selectedStudents, filteredStudentsIds);
    updateStudents(newSelectedStudents);
  }

  isAllVisibleChecked() {
    const { filteredStudents, filteredStudentsIds } = this.state;
    // if all students (not empty list) visible by filters is selected
    return (
      filteredStudents.length > 0 &&
      filteredStudents.length === intersection(this.props.selectedStudents, filteredStudentsIds).length
    );
  }

  render() {
    const { action, rooms, tags, selectedStudents, isStudentsLoading, students } = this.props;
    const selectedPersons = selectedStudents.map(id => students.find(student => student.id === id));
    return (
      <div className="modal__wrapper">
        <div className="modal__header modal__header--bordered">
          <ActionButton iconName="back" className="modal__header-back" onClick={() => this.props.context.prevStep()} />
          <span className="modal__header-title">Sign-{action} Attendance</span>
          <span className="modal__header-steps">
            <span>Step 2</span> / 3
          </span>
          <div className="modal__header-controls">
            <FilterBar rooms={rooms} tags={tags} onUpdate={this.updateFilters} />
          </div>
        </div>

        <div className="modal__container">
          <div className="students-cards__aux">
            <Checkbox checked={this.isAllVisibleChecked()} onChange={this.toggleAllVisible} label="Select all" />
            <span className="students-cards__counter">
              {selectedPersons.length ? (
                <TooltipTrigger tooltip={<Tooltip students={selectedPersons} />} maxHeight={110}>
                  {this.selectedCounter()}
                </TooltipTrigger>
              ) : (
                this.selectedCounter()
              )}
            </span>
          </div>
          <div className="students-cards">
            {isStudentsLoading ? (
              <div className="students-cards__message">
                <Preloader />
              </div>
            ) : (
              this.renderStudents()
            )}
          </div>
        </div>
        <div className="modal__controls">
          <ButtonV2
            label="Continue"
            onClick={() => this.props.context.nextStep()}
            disabled={selectedStudents.length < 1}
            data-cy="continue"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isStudentsLoading: state.students.loading
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(BulkSignActions_Step2);
