import React, { PureComponent } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import debounce from 'lodash/debounce';
import { ButtonV2, TextInput, Icon, Preloader } from 'components';
import Row from './Row';
import './style.scss';

class SelectNewsletterTemplate extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      templates: [],
      selecting: false,
      selectedTemplate: null,
      loading: false
    };

    this.debouncedSearch = debounce(this.searchTemplates, 500);
  }

  componentDidMount() {
    this.searchTemplates();
  }

  @bind
  updateSearch(search) {
    this.setState({ search }, this.debouncedSearch);
  }

  @bind
  async searchTemplates() {
    const { search } = this.state;
    const { editorType } = this.props.data;

    this.setState({ loading: true });

    try {
      const resp = await req.listNewsletterTemplates({
        filters: {
          newsletter: {
            is_template: true,
            title: search || undefined,
            editor_type: editorType
          }
        }
      });
      this.setState({
        loading: false,
        templates: resp.newsletters.filter(t => t.editor_type === editorType)
      });
    } catch (e) {
      this.setState({ loading: false });
      Actions.reportError('There was problem loading templates', e);
    }
  }

  @bind
  async handleChoose() {
    const { selectedTemplate } = this.state;
    const { onClose } = this.props;

    const result = await Actions.showModal('Confirmation', {
      title: 'Apply Template',
      description: 'Continuing with template will overwrite the newsletter. Do you want to continue?',
      yesButton: 'Yes',
      noButton: 'No',
      yesButtonProps: { secondary: false },
      noButtonProps: { secondary: true }
    });

    if (!result) {
      return;
    }

    try {
      this.setState({ selecting: true });
      const detail = await req.newsletterTemplate({ id: selectedTemplate.id });
      this.setState({ selecting: false });
      onClose(detail);
    } catch (e) {
      Actions.reportError('There was problem loading template detail', e);
    }
  }

  @bind
  async selectTemplate(template) {
    this.setState({ selectedTemplate: template });
  }

  renderRows() {
    const { templates, selectedTemplate } = this.state;

    return templates.map(s => (
      <Row
        key={s.id}
        template={s}
        onClick={this.selectTemplate}
        selected={selectedTemplate && selectedTemplate.id === s.id}
      />
    ));
  }

  render() {
    const { loading, selectedTemplate, selecting } = this.state;
    const { onClose } = this.props;

    return (
      <div className="select-newsletter-templates">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Select Newsletter Template</div>
        </div>

        <div className="modal__container">
          <div className="select-newsletter-templates__search">
            <TextInput
              className="select-newsletter-templates__search-input"
              placeholder="Search Template"
              onChange={this.updateSearch}
            />
            <Icon name="search" className="select-newsletter-templates__search-icon" />
          </div>
          <div className="select-newsletter-templates__list-container">
            <Scrollbars autoHeight autoHeightMax={320}>
              <div className="select-newsletter-templates__list">
                {loading ? <Preloader className="select-newsletter-templates__loading" /> : this.renderRows()}
              </div>
            </Scrollbars>
          </div>
        </div>

        <div className="modal__controls">
          <ButtonV2 label="Cancel" secondary onClick={onClose} disabled={selecting} />

          <ButtonV2
            label="Choose"
            onClick={this.handleChoose}
            disabled={!selectedTemplate}
            isLoading={selecting}
            data-cy="choose"
          />
        </div>
      </div>
    );
  }
}

export default SelectNewsletterTemplate;
