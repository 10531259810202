import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextInput, SelectGroup } from 'components';

class StaffFilter extends PureComponent {
  static propTypes = {
    filters: PropTypes.shape({
      search: PropTypes.string,
      roomId: PropTypes.string
    }),
    rooms: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func
  };

  handleUpdate(partialUpdateObj) {
    if (this.props.onChange) {
      this.props.onChange({ ...this.props.filters, ...partialUpdateObj });
    }
  }

  @bind
  updateSearch(search) {
    this.handleUpdate({ search });
  }

  @bind
  updateRoomId(roomId) {
    this.handleUpdate({ roomId });
  }

  render() {
    const { rooms } = this.props;
    const { search, roomId } = this.props.filters;

    return (
      <div className="form--inline">
        <div className="w-1/2 input--search">
          <div className="form-input__field">
            <TextInput placeholder="Search" value={search} onChange={this.updateSearch} />
          </div>
        </div>

        {rooms && (
          <SelectGroup className="w-1/4" title="Select Room" type="radio" checked={roomId} onChange={this.updateRoomId}>
            <SelectGroup.Item value="" label="All Rooms" isDefault />
            {rooms.map(r => (
              <SelectGroup.Item key={r.id} value={r.id} label={r.name} />
            ))}
          </SelectGroup>
        )}
      </div>
    );
  }
}

export default StaffFilter;
