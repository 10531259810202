import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { ButtonV2, ColorPicker, Form } from 'components';
import { COLOR_PICKER_COLORS } from 'constants/colors';

import './style.scss';

class EditShift extends Component {
  static propTypes = {
    staff: PropTypes.array,
    onClose: PropTypes.func,
    data: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string,
      starts_at: PropTypes.string,
      ends_at: PropTypes.string,
      teacher_id: PropTypes.string,
      isCopy: PropTypes.bool
    })
  };

  static defaultProps = {
    staff: [],
    data: {
      id: '',
      name: '',
      color: '',
      starts_at: '',
      ends_at: '',
      teacher_id: '',
      isCopy: false
    }
  };

  constructor(...args) {
    super(...args);

    const { id, color, teacher_id } = this.props.data;
    this.state = {
      isNew: !id,
      color: color || COLOR_PICKER_COLORS[0],
      originalTeacherId: teacher_id,
      errors: {}
    };
  }

  componentDidMount() {
    req.staff();
  }

  @bind
  onChangeColor(value) {
    this.setState({ color: value });
  }

  @bind
  async submit(formValues) {
    const { isNew } = this.state;
    const { id, isCopy } = this.props.data;

    const date = moment(formValues.date).format('YYYY-MM-DD');
    const startsAt = moment(date + ' ' + formValues.starts_at, 'YYYY-MM-DD h:mm A');
    const endsAt = moment(date + ' ' + formValues.ends_at, 'YYYY-MM-DD h:mm A');
    const teacherId = formValues.teacher_id || '';

    if (startsAt.isAfter(endsAt)) {
      this.setState({
        errors: {
          ends_at: ['Start time should preceed end time.']
        }
      });
      return;
    } else {
      this.setState({ errors: {} });
    }

    const payload = {
      shift: {
        name: formValues.name,
        color: this.state.color,
        starts_at: moment(startsAt).format(),
        ends_at: moment(endsAt).format(),
        teacher_id: teacherId
      }
    };

    let updatedShift = {};
    if (isCopy || isNew) {
      updatedShift = await req.addStaffShift(payload);
    } else {
      payload.id = id;
      updatedShift = await req.editStaffShift(payload);
    }

    let result = {
      isNew: isNew || isCopy,
      isCopy,
      updatedShift,
      originalTeacherId: this.state.originalTeacherId
    };

    if (!isCopy && teacherId !== this.state.originalTeacherId) {
      result.wasReassigned = this.state.originalTeacherId !== '' && teacherId !== '';
      result.wasUnassigned = this.state.originalTeacherId !== '' && teacherId === '';
    }

    this.props.onClose(result);
  }

  @bind
  close() {
    this.props.onClose();
  }

  render() {
    const { staff } = this.props;
    const { isNew, color, errors } = this.state;
    const { isCopy, name, starts_at, ends_at, teacher_id } = this.props.data;

    const title = isCopy ? 'Copy Shift' : isNew ? 'Add Shift' : 'Edit Shift';
    const date = starts_at ? moment(starts_at).toDate() : new Date();
    const startTime = starts_at ? moment(starts_at).format('h:mm A') : '8:00 AM';
    const endTime = ends_at ? moment(ends_at).format('h:mm A') : '5:00 PM';

    return (
      <div className="edit-shift">
        <div className="modal__header">
          <span className="modal__header-title">{title}</span>
        </div>

        <div className="modal__container">
          <Form onSubmit={this.submit} validateOn="submit" errors={errors}>
            <div className="form__row form__row--justified">
              <Form.Input
                name="name"
                label="Shift Name"
                defaultValue={name}
                placeholder="Enter Shift Name"
                className="edit-shift__name"
                tabIndex={1}
              />
              <span className="edit-shift__color-picker-container" style={{ backgroundColor: color }}>
                <ColorPicker
                  name="color"
                  onChange={this.onChangeColor}
                  color={color}
                  colors={COLOR_PICKER_COLORS}
                  tabIndex={2}
                  pickerClassName="edit-shift__color-picker"
                />
              </span>
            </div>

            <div className="form__row form__row--justified">
              <Form.DatePicker
                name="date"
                label="Date"
                defaultValue={date}
                tabIndex={3}
                required
                asterisk={true}
                className="edit-shift__date"
              />
              <Form.TimeInput
                name="starts_at"
                label="Start Time"
                defaultValue={startTime}
                tabIndex={4}
                required
                asterisk={true}
                className="edit-shift__time"
              />
              <Form.TimeInput
                name="ends_at"
                label="End Time"
                defaultValue={endTime}
                tabIndex={5}
                required
                asterisk={true}
                className="edit-shift__time"
              />
            </div>
            <div className="form__row form__row--justified">
              <Form.Typeahead
                placeholder="Search by staff name"
                label="Assigned To"
                name="teacher_id"
                options={staff.map(s => ({
                  id: s.id,
                  label: s.name
                }))}
                defaultValue={teacher_id}
                tabIndex={6}
              />
            </div>
            <div className="edit-shift__footer form__row--actions">
              <ButtonV2 type="button" secondary label="Cancel" onClick={this.close} tabIndex={7} />
              <Form.Submit label={title} tabIndex={8} />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  staff: state.staff.data
});

export default connect(mapStateToProps)(EditShift);
