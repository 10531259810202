import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ScrollBars from 'react-custom-scrollbars';
import Row from './Row';
import './style.scss';

class StudentSideList extends Component {
  static propTypes = {
    students: PropTypes.array,
    onRemove: PropTypes.func
  };

  static defaultProps = {
    students: []
  };

  @memoize
  findRoom(roomId) {
    const { rooms } = this.props;
    return rooms.find(room => room.id === roomId);
  }

  renderStudents() {
    const { students, onRemove } = this.props;

    return students.map(kid => {
      const room = this.findRoom(kid.current_section_id);

      return <Row key={kid.id} kid={kid} room={room} onRemove={onRemove} />;
    });
  }

  render() {
    const studentsCount = this.props.students.length;

    return (
      <div className="student-side-list">
        <div className="student-side-list__selected">
          <span className="student-side-list__selected-value">{studentsCount}</span>
          students selected
        </div>

        <ScrollBars autoHeight autoHeightMin={300} autoHeightMax={300}>
          <div className="student-side-list__list">{this.renderStudents()}</div>
        </ScrollBars>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data
});

export default connect(mapStateToProps)(StudentSideList);
