import React from 'react';
import classNames from 'classnames';
import { Link } from 'components';
import './style.scss';

const Item = ({ link, value, title, subtitle, active, highlight, 'data-cy': dataCY }) => {
  const cn = classNames({
    'stats-bar-v2__item': true,
    'stats-bar-v2__item--highlighted': highlight,
    'stats-bar-v2__item--active': active
  });

  return (
    <Link to={link} className={cn} data-cy={dataCY} underlined={false}>
      <div className="stats-bar-v2__item-value">{value}</div>
      <div className="stats-bar-v2__item-title">{title}</div>
      {subtitle && <div className="stats-bar-v2__item-subtitle">{subtitle}</div>}
    </Link>
  );
};

const StatsBarV2 = ({ children }) => {
  return <div className="stats-bar-v2">{children}</div>;
};

StatsBarV2.Item = Item;

export default StatsBarV2;
