import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';
import GenericEditor from '../GenericEditor';
import './style.scss';

class NameFieldEditor extends Component {
  static propTypes = {
    defaultValue: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    onChange: PropTypes.func,
    locked: PropTypes.bool
  };

  static defaultProps = {
    defaultValue: {
      first_name: '',
      last_name: ''
    }
  };

  @bind
  submit(values) {
    return Promise.resolve(this.props.onChange(values));
  }

  render() {
    const { defaultValue, locked } = this.props;

    return (
      <GenericEditor className="name-field-editor" onSubmit={this.submit} defaultValue={defaultValue} locked={locked}>
        {({ value, onChange }) => (
          <Form onChange={onChange} className="name-field-editor__form">
            <Form.Input
              autoFocus
              className="name-field-editor__input"
              name="first_name"
              placeholder="First Name"
              defaultValue={value.first_name}
            />
            <Form.Input
              className="name-field-editor__input"
              name="last_name"
              placeholder="Last Name"
              defaultValue={value.last_name}
            />
          </Form>
        )}
      </GenericEditor>
    );
  }
}

export default NameFieldEditor;
