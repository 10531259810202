import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { Placeholder, ActionButton } from 'components';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class Step2Ach extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    onConnectUri: PropTypes.func
  };

  constructor(...args) {
    super(...args);
    this.state = {
      isLoading: false,
      teraSetting: null,
      teraLoading: true
    };
  }

  componentDidMount() {
    const { onConnectUri } = this.props;
    this.setState({ teraLoading: true });
    onConnectUri()
      .then(teraSetting => {
        this.setState({ teraLoading: false, teraSetting }, () => {
          this.initializeTera();
        });
      })
      .catch(e => {
        this.setState({ teraLoading: false, teraSetting: null });
        Actions.reportError('There was problem getting Tuition Express token', e);
      });
  }

  @bind
  onTeraLoaded() {
    this.setState({ teraLoading: false });
  }

  @bind
  initializeTera() {
    const { currentSchool } = this.props;
    const { TeraUrl, Token, ProfileID } = this.state.teraSetting;

    this.tera = new window.tera({
      Target: 'payment_container',
      TeraUrl,
      Token,
      ProfileID,
      Style: `${process.env.PUBLIC_PATH}/assets/tera.css`,
      Height: 500,
      OnLoaded: this.onTeraLoaded,
      OnSuccess: this.handleSubmit,
      OnResponse: noop,
      OnDecline: console.log,
      OnError: console.log,
      OnValidation: console.log,
      SaveOnly: true,
      InitialPaymentType: 'ACH',
      PresetValues: {
        Request_Postal: currentSchool.zip,
        Request_City: currentSchool.city,
        Request_Region: currentSchool.state,
        Request_Address: currentSchool.street_address,
        Request_Phone: (currentSchool.phone || '').replace(/\D/g, '')
      }
    });
    this.tera.Initialize(9.99);
  }

  @bind
  notifyMobile() {
    if (window.mobileJsInterface?.parentPaymentMethodAdded) {
      window.mobileJsInterface.parentPaymentMethodAdded();
    } else if (window.webkit?.messageHandlers?.mobileJsInterface) {
      window.webkit.messageHandlers.mobileJsInterface.postMessage('parentPaymentMethodAdded');
    }
  }

  @bind
  handleSubmit(data) {
    const token = data;
    const { onSubmit, autopayEnabled } = this.props;

    this.setState({ isLoading: true });
    if (onSubmit) {
      return onSubmit({
        token,
        token_type: 'bank_account'
      });
    }

    return req
      .addPaymentMethod({
        token,
        token_type: 'bank_account',
        auto_pay: autopayEnabled === true
      })
      .then(() => {
        this.notifyMobile();
        Actions.showFlash('ACH added successfully');
        this.setState({ isLoading: false });
        this.props.goToList(true);
      })
      .catch(err => {
        this.setState({ isLoading: false });
        const errObj = get(err, 'response.data.form_errors');
        if (errObj) {
          Actions.reportError(`${errObj.error_message} (${errObj.retref})`, err);
        } else {
          Actions.reportError('There was problem with adding payment method', err);
        }
      });
  }

  render() {
    const { prevStep } = this.props.context;
    const { teraSetting, teraLoading, onSubmit } = this.state;

    return (
      <div className="table payments-panel">
        <div className="table__header payments-panel__header">
          {!onSubmit && <ActionButton iconName="back" size={20} onClick={prevStep} />}
          <div className="payments-panel__title">{onSubmit ? 'Submit Registration' : 'Add ACH Account'}</div>
        </div>

        <div className="table__body">
          <div className="table__row payments-panel__body">
            <div className="payments-panel__content">
              <div id="payment_container" />

              {(!teraSetting || teraLoading) && (
                <Placeholder.NoResult isLoading image="" className="te-payments__placeholder">
                  <div className="no-payments__title">There was problem getting Tuition Express token.</div>
                </Placeholder.NoResult>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentSchool: state.currentUser.data.current_school
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(Step2Ach);
