import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TooltipPortalTrigger, Icon, ButtonV2 } from 'components';

import './style.scss';

const PRIORITY_VALUES = {
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  none: 'None'
};

export const PriorityTooltip = ({ value, onChange }) => (
  <ul className="tooltip-content__inner">
    {Object.keys(PRIORITY_VALUES).map(p => {
      const selected = value === p;
      const itemCN = cx('priority-filter__item', 'context-menu-item', {
        'priority-filter__item--selected': selected
      });

      return (
        <li className={itemCN} key={p} onClick={() => onChange(value === p ? undefined : p)}>
          <div className={cx('priority-filter__item-icon', `priority-filter__item-icon--${p}`)} />{' '}
          <span>{PRIORITY_VALUES[p]}</span>
          {selected && <Icon name="tick-1" color="#30bdef" />}
        </li>
      );
    })}
  </ul>
);

class PriorityFilter extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
  };

  render() {
    const { value, onChange } = this.props;
    const btnCN = cx('priority-filter__btn', {
      'priority-filter__btn--no-filter': !value
    });

    return (
      <TooltipPortalTrigger
        white
        side="center"
        triggerOn="click"
        className="priority-filter context-menu"
        tooltip={<PriorityTooltip onChange={onChange} value={value} />}
        portalClassName="priority-filter__tooltip context-menu-tooltip"
      >
        <ButtonV2 className={btnCN} icon iconName="priority" iconSize="18" data-cy="filter-priority" />
      </TooltipPortalTrigger>
    );
  }
}

export default PriorityFilter;
