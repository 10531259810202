import Info from './Info';
import NoResult from './NoResult';
import Feature from './Feature';
import Step from './Step';

export default {
  Info,
  NoResult,
  Feature,
  Step
};
