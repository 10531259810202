import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { ButtonV2, StudentCard } from 'components';
import './style.scss';

function EditActivityStudentList({ allStudents, value, isEditing, hideSelect, onChange, kids = [], hideDelete }) {
  const allKids = kids.concat(allStudents.filter(item => !kids.some(i => i.id === item.id)));
  const selectedStudents = allKids.filter(s => value.indexOf(s.id) > -1);

  const handleChange = async () => {
    const result = await Actions.showModal('SelectStudents', { showSelectSignIn: !isEditing, selected: value });
    if (result) {
      onChange(result);
    }
  };

  const handleDelete = studentId => {
    onChange(value.filter(id => id !== studentId));
  };

  return (
    <div className="edit-activity-student-list">
      <div className="edit-activity-student-list__title">
        <div>
          <span>{selectedStudents.length}</span> selected
        </div>
        {selectedStudents.length > 0 && !hideSelect && (
          <div className="edit-activity-student-list__link" onClick={handleChange}>
            Select Students
          </div>
        )}
      </div>
      {selectedStudents.length > 0 ? (
        <div className="edit-activity-student-list__content">
          <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={285}>
            {selectedStudents.map(s => {
              return (
                <StudentCard
                  key={`student-${s.id}`}
                  checkable={false}
                  student={s}
                  actionIcon={!hideDelete && selectedStudents.length != 1 ? 'delete' : undefined}
                  action={() => handleDelete(s.id)}
                />
              );
            })}
          </Scrollbars>
        </div>
      ) : (
        <div className="edit-activity-student-list__button">
          <ButtonV2 secondary label="Select Students" type="button" onClick={handleChange} data-cy="select-students" />
        </div>
      )}
    </div>
  );
}

EditActivityStudentList.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func
};

const mapStateToProps = state => ({
  allStudents: state.students.data
});

export default connect(mapStateToProps)(EditActivityStudentList);
