import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'components';
import { generateColor } from 'lib/utils';
import './style.scss';

// A regex that detects punctuation marks
const PUNCT_MARK_RE = /[!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/;

class Avatar extends Component {
  static propTypes = {
    url: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    isPrivate: PropTypes.bool,
    initials: PropTypes.string,
    onlineStatus: PropTypes.oneOf(['online', 'offline']),
    size: PropTypes.oneOf(['xxs', 'xs', 'small', 'dashboard', 'medium', 'large']),
    type: PropTypes.oneOf(['avatar', 'logo', 'select-logo']),
    status: PropTypes.oneOf(['parent', 'admin', 'other']),
    placeholder: PropTypes.string,
    tooltipText: PropTypes.node,
    notScheduled: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      isValidUrl: true
    };
  }

  static defaultProps = {
    size: 'small'
  };

  getInitials() {
    if (this.props.initials) {
      return this.props.initials;
    }

    const { name } = this.props;

    let initials = '';

    try {
      if (name) {
        initials = name
          .trim()
          .replace(PUNCT_MARK_RE, '')
          .split(/\s+/)
          .slice(0, 2)
          .map(name => name[0].toUpperCase())
          .join('');
      }
    } catch (e) {
      initials = 'NA';
    }

    return initials;
  }

  componentDidMount() {
    const { url } = this.props;

    if (url) {
      this.isValidUrl(url).then(isValidUrl => {
        this.setState({ isValidUrl });
      });
    }
  }

  getIconName() {
    switch (this.props.type) {
      case 'avatar':
        return 'person';

      case 'logo':
      case 'select-logo':
        return 'school';
    }
  }

  isValidUrl(url) {
    const img = new Image();
    img.src = url;
    return new Promise(resolve => {
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    });
  }

  render() {
    const {
      url,
      name,
      color,
      className,
      size,
      type,
      status,
      isPrivate,
      tooltipText,
      placeholder,
      onlineStatus,
      outRoom,
      notScheduled
    } = this.props;
    const { isValidUrl } = this.state;
    const initials = this.getInitials();

    const avatarCN = classNames({
      avatar: true,
      [`avatar--${size}`]: true,
      'avatar--logo': type === 'logo',
      'avatar--select-logo': type === 'select-logo',
      [className]: Boolean(className),
      'avatar--offline': onlineStatus === 'offline'
    });

    const pictureCN = classNames({
      avatar__picture: true,
      'avatar__picture--select-logo': type === 'select-logo'
    });

    const style = {
      backgroundColor:
        (url && isValidUrl) || type ? 'transparent' : color ? '#' + color : name ? generateColor(name) : 'transparent'
    };

    const initialsCN = classNames({
      avatar__initials: true,
      'avatar__initials--not-scheduled': notScheduled
    });

    return (
      <div className={avatarCN}>
        <div className={initialsCN} style={style}>
          {url && isValidUrl ? (
            <div className={pictureCN} style={{ backgroundImage: `url('${url}')` }} />
          ) : type ? (
            <div className="avatar__placeholder">
              <Icon resizeable name={this.getIconName()} />
              <span className="avatar__placeholder-tip">
                {placeholder || `Upload ${type === 'select-logo' ? 'logo' : type}`}
              </span>
            </div>
          ) : (
            <div className="avatar__initials-text">{initials}</div>
          )}
        </div>

        {isPrivate && (
          <div className="avatar__private">
            <Icon name="private" size="16" />
          </div>
        )}

        {notScheduled && (
          <div className="avatar__online-not-scheduled">
            <Icon name="Unscheduled" size={16} color="#FFF" />
          </div>
        )}

        {status && (
          <div className="avatar__status">
            <Icon name={status} size="14" />
            <div className="avatar__status-title">{status}</div>
          </div>
        )}

        {onlineStatus && !notScheduled && <div className="avatar__online-status" />}
        {outRoom && (
          <div className="avatar__out-room">
            <Icon name="out-room" size="12" />
          </div>
        )}

        {!status && tooltipText && (
          <div className="avatar__tooltip">
            <div className="avatar__tooltip-title">{tooltipText}</div>
          </div>
        )}
      </div>
    );
  }
}

export default Avatar;
