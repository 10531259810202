import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'components';
import history from 'lib/history';
import './style.scss';

class GetAppLink extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false
    };
  }

  @bind
  getAppLink(values) {
    this.setState({ isLoading: true });
    return req
      .getAppLink({
        phone_number: values.phone_number
      })
      .then(() => {
        Actions.showFlash('App link has been sent');
        this.setState({ isLoading: false });
        history.push('/dashboard');
        this.props.onClose();
      })
      .catch(err => {
        this.setState({ isLoading: false });
        Actions.reportError('There was problem getting app link.', err);
      });
  }

  render() {
    const { isLoading } = this.state;
    const { currentUser } = this.props;

    return (
      <div className="get-app-link">
        <div className="modal__header modal__header--bordered">
          <span className="modal__header-title">Send Procare App Link</span>
        </div>

        <div className="modal__container">
          <Form className="get-app-link__form" validateOn="submit" onSubmit={this.getAppLink} isLoading={isLoading}>
            <div className="form__row">
              <Form.Input
                name="phone_number"
                label="Your Mobile Number"
                placeholder="Your Mobile Number"
                mask="999-999-999999999"
                required
                defaultValue={currentUser.phone}
              />
            </div>

            <div className="form__row form__row--actions">
              <Form.Submit label="Send" />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser.data
});

export default connect(mapStateToProps)(GetAppLink);
