import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChoosePhoto from 'modals/common/ChoosePhoto';
import './style.scss';

class PhotoActivityGallery extends Component {
  static propTypes = {
    selectedPhotoIds: PropTypes.array,
    photos: PropTypes.array,
    photosLoading: PropTypes.bool,
    onChange: PropTypes.func,
    goBack: PropTypes.func
  };

  static defaultProps = {
    selectedPhotoIds: [],
    photos: []
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedPhotoIds: props.selectedPhotoIds
    };
  }

  @bind
  handleUpdate(photos) {
    this.props.onChange(photos);
  }

  @bind
  selectPhotos(selectedPhotoIds, selectedPhotos) {
    this.setState({ selectedPhotoIds }, () => this.handleUpdate(selectedPhotos));
  }

  render() {
    return (
      <ChoosePhoto
        multiple
        selected={this.state.selectedPhotoIds}
        onSelect={this.selectPhotos}
        onBack={this.props.goBack}
      />
    );
  }
}

export default PhotoActivityGallery;
