import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { capitalize, getErrorText } from 'lib/utils';
import ManageController from 'modals/common/ManageItems/ManageController';
import { ManageList, ManageListItemDefault } from 'modals/common/ManageItems/ManageList';
import ManageWindow from 'modals/common/ManageItems/ManageWindow';
import './style.scss';

const TagItem = props => <ManageListItemDefault {...props} placeholder="Tag name" />;

class ManageTagsNext extends PureComponent {
  static propTypes = {
    tags: PropTypes.array,
    onOpenTags: PropTypes.func
  };

  static defaultProps = {
    tags: []
  };

  @bind
  async updateTag(id, tag) {
    try {
      await req.updateTag({ id, tag });
      Actions.showFlash('Tag has been updated');

      return Promise.resolve();
    } catch (e) {
      const errMsg = capitalize(getErrorText(e.response.data, { name: ' ' }));
      Actions.reportError(errMsg, e);

      return Promise.reject();
    }
  }

  @bind
  async deleteTag(id) {
    const shouldBeDeleted = window.confirm('Are you sure?');

    if (!shouldBeDeleted) {
      return;
    }

    try {
      await req.deleteTag({ id }, { id });
      Actions.showFlash('Tag has been deleted');
    } catch (e) {
      Actions.reportError('Unable to delete the tag', e);
    }
  }

  render() {
    const { tags, onOpenTags } = this.props;

    return (
      <ManageController items={tags} onUpdate={this.updateTag} onDelete={this.deleteTag}>
        {props => (
          <ManageWindow className="manage-tags" title="Manage Tags" onBack={onOpenTags}>
            <ManageList {...props} ItemComponent={TagItem} />
          </ManageWindow>
        )}
      </ManageController>
    );
  }
}

export default ManageTagsNext;
