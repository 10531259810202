import { Button, DateFilter } from 'components';
import moment from 'moment';
import React, { useState } from 'react';

import './style.scss';

const AttendanceKinderSystems = ({ onClose }) => {
  const [selectedDateRange, setSelectedDateRange] = useState([
    moment()
      .weekday(1)
      .format('YYYY-MM-DD'),
    moment()
      .weekday(7)
      .format('YYYY-MM-DD')
  ]);

  const handleFilterChange = filter => {
    setSelectedDateRange(filter.dateRange);
  };

  const handleSubmit = () => {
    onClose({
      startDate: selectedDateRange[0],
      endDate: selectedDateRange[1]
    });
  };

  return (
    <div className="attendance_kindersystems">
      <div className="modal__header modal__header--bordered">
        <div className="modal__header-title">Attendance Submittal</div>
      </div>

      <div className="modal__container">
        <div className="attendance_kindersystems__body">
          <div className="attendance_kindersystems__title">Choose Date</div>
          <DateFilter
            label="Choose Date"
            disableDaily={true}
            disableBiweekly={true}
            disableFuture={true}
            defaultValue={{
              type: 'weekly'
            }}
            onChange={handleFilterChange}
          />
        </div>
      </div>

      <div className="modal__controls">
        <Button label="Submit Matched Students" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default AttendanceKinderSystems;
