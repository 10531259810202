import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox } from 'components';
import './style.scss';

class LabeledCheckbox extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    'data-cy': PropTypes.string
  };

  static defaultProps = {
    label: 'Select',
    checked: false
  };

  render() {
    const { label, checked, onChange, 'data-cy': dataCY } = this.props;

    const className = classNames('labeled-checkbox', this.props.className, {
      'labeled-checkbox--checked': checked
    });

    return (
      <div className={className} onClick={onChange}>
        <Checkbox className="labeled-checkbox__input" type="circle" checked={checked} data-cy={dataCY} />

        <div className="labeled-checkbox__label">{label}</div>
      </div>
    );
  }
}

export default LabeledCheckbox;
