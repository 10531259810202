import React from 'react';
import { Form } from 'components';

const TextInputOptions = ({ options = {}, disabled, canRequireEmail }) => (
  <React.Fragment>
    <Form.Input
      data-cy="placeholder"
      name="placeholder"
      label="Placeholder Text"
      placeholder="Placeholder text"
      defaultValue={options.placeholder}
    />
    {canRequireEmail && (
      <Form.Checkbox
        data-cy="is-email"
        name="email"
        label="Should be an Email"
        defaultValue={options.email}
        disabled={disabled}
      />
    )}
    <Form.Checkbox
      data-cy="multiline"
      name="multiline"
      label="Multiple lines allowed for the answer"
      defaultValue={options.multiline}
      disabled={disabled}
    />
  </React.Fragment>
);

export default TextInputOptions;
