import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Spacer } from 'components';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class ConfirmSchoolInfoStep extends Component {
  @bind
  onSubmit(values) {
    const { onUpdate, checkFreeSchool } = this.props;
    const { enrollment, facility_type } = values;

    if (!checkFreeSchool()) return;

    const nextState = {
      enrollment,
      facility_type,
      payment_gateway: 'te'
    };

    onUpdate(nextState);
    this.props.context.goToStep('te');
  }

  render() {
    const { data, facilityTypes, school, title } = this.props;
    const facilityType = data.facility_type || school.facility_type;
    const enrollment = data.enrollment || school.enrollment;

    return (
      <div className="modal__wrapper">
        <div className="modal__header modal__header--bordered">
          <span className="modal__header-title">{title}</span>
        </div>
        <Form
          className="modal__container school-activate__form school-activate__form--confirm-school"
          onSubmit={this.onSubmit}
          validateOn="submit"
        >
          <div className="school-activate__legend">Verify your school info</div>
          <div className="form__row form__row--justified">
            <Form.Select
              name="facility_type"
              label="Your school type"
              title="Confirm your school type"
              type="radio"
              tabIndex={1}
              required
              defaultValue={facilityType}
            >
              {Object.keys(facilityTypes).map(key => (
                <Form.Select.Item key={key} value={key} label={facilityTypes[key]} />
              ))}
            </Form.Select>
          </div>
          <div className="form__row form__row--justified">
            <Form.Input
              name="enrollment"
              label="How many active students monthly"
              tabIndex={2}
              naturalNumber
              type="number"
              required
              defaultValue={enrollment}
            />
          </div>

          <Spacer size={100} />

          <div className="form__row form__row--actions">
            <Form.Submit label="Continue" />
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  school: state.currentUser.data.current_school,
  facilityTypes: state.constants.data.facility_types
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(ConfirmSchoolInfoStep);
