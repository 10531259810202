import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';

const FilterBar = ({ rooms, statuses, tags, onUpdate, defaultValue = {}, customControl, placeholder = 'Search' }) => (
  <Form onChange={onUpdate} className="form--search">
    <div className="form__row form--inline">
      <Form.Input
        name="search"
        placeholder={placeholder}
        className="input--search"
        defaultValue={defaultValue.search}
      />

      {rooms && (
        <Form.Select name="roomId" title="Select Room" type="radio" defaultValue={defaultValue.roomId} enableClear>
          <Form.Select.Item value="" label="All Rooms" isDefault />
          {rooms.map(room => (
            <Form.Select.Item key={room.id} value={room.id} label={room.name} />
          ))}
        </Form.Select>
      )}

      {statuses && (
        <Form.Select name="registration_status" title="Select Status" defaultValue={defaultValue.registration_status}>
          {Object.keys(statuses).map(s => (
            <Form.Select.Item key={s} value={s} label={statuses[s]} />
          ))}
        </Form.Select>
      )}

      {tags && (
        <Form.Select
          name="tagIds"
          type="checkbox"
          title="Select Tags"
          multipleSelectionText="tags applied"
          defaultValue={defaultValue.tagIds}
          enableClear
        >
          {tags.map(tag => (
            <Form.Select.Item key={tag.id} value={tag.id} label={tag.name} />
          ))}
        </Form.Select>
      )}
    </div>

    {customControl && <div className="form__row form--inline form__row--text">{customControl}</div>}
  </Form>
);

FilterBar.propTypes = {
  onUpdate: PropTypes.func,
  rooms: PropTypes.array,
  statuses: PropTypes.object,
  tags: PropTypes.array,
  defaultValues: PropTypes.object,
  customControl: PropTypes.object,
  placeholder: PropTypes.string
};

export default FilterBar;
