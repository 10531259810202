import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ButtonV2, Form } from 'components';
import DAY_CODES from 'constants/dayCodes';

class ItemForm extends Component {
  static propTypes = {
    item: PropTypes.shape({
      start_time: PropTypes.string,
      end_time: PropTypes.string,
      days: PropTypes.array,
      section_ids: PropTypes.array,
      rate: PropTypes.number,
      rate_type: PropTypes.string,
      late_fee: PropTypes.number,
      late_fee_time: PropTypes.string
    }),
    index: PropTypes.number,
    rooms: PropTypes.array,
    days: PropTypes.object
  };

  static defaultProps = {
    item: {
      start_time: '',
      end_time: '',
      days: [],
      section_ids: [],
      rate: undefined,
      rate_type: '',
      late_fee: undefined,
      late_fee_time: ''
    },
    index: 0,
    rooms: [],
    days: {}
  };

  constructor() {
    super();

    this.state = {
      formValues: {},
      errors: []
    };
  }

  getDayListItems() {
    const { days } = this.props;
    const dayCodes = Object.keys(DAY_CODES);

    return dayCodes.map(day => <Form.Select.Item key={day} value={day} label={days[day]} />);
  }

  @bind
  onSave(values) {
    const { late_fee_time, start_time, end_time } = values;
    const errors = [];

    if (moment(start_time, 'hh:mm A').isAfter(moment(end_time, 'hh:mm A'))) {
      errors.push('Start time cannot be after end time');
    }

    if (late_fee_time && moment(late_fee_time, 'hh:mm A').isBefore(moment(end_time, 'hh:mm A'))) {
      errors.push('Late fee time cannot be before end time');
    }

    if (errors.length) {
      this.setState({ errors });
    } else {
      this.props.onSave(values);
    }
  }

  @bind
  updateFormValues(values) {
    this.setState({ formValues: values });
  }

  render() {
    const { rooms, item, onCancel, isListEmpty, index } = this.props;
    const { formValues, errors } = this.state;

    return (
      <Form
        className="attendance-items__form"
        onSubmit={this.onSave}
        onInit={this.updateFormValues}
        onChange={this.updateFormValues}
      >
        <div className="attendance-items__form-row">
          <Form.Input
            name="desc"
            defaultValue={item.desc}
            disableInvalid
            label={`Charge ${index + 1}`}
            placeholder="Enter Charge Name"
            className="attendance-items__form-desc"
          />
        </div>

        <div className="attendance-items__form-row">
          <span className="attendance-items__form-required">1</span>.
          <span className="attendance-items__form-text">Set pick up start time and end time</span>
          <Form.TimeInput name="start_time" defaultValue={item.start_time} required disableInvalid />
          <span className="attendance-items__form-row-text">to</span>
          <Form.TimeInput name="end_time" defaultValue={item.end_time} required disableInvalid />
        </div>

        <div className="attendance-items__form-row">
          <span className="attendance-items__form-required">2</span>.
          <span className="attendance-items__form-row-text">Pick days of week</span>
          <Form.Select
            required
            disableInvalid
            title="Select Days"
            name="days"
            type="checkbox"
            className="attendance-items__form-select"
            defaultValue={item.days}
            multipleSelectionText="days selected"
          >
            {this.getDayListItems()}
          </Form.Select>
        </div>

        <div className="attendance-items__form-row">
          <span className="attendance-items__form-required">3</span>.
          <span className="attendance-items__form-row-text">Pick the rooms your child is in</span>
          <Form.Select
            required
            disableInvalid
            title="Select Room"
            name="section_ids"
            type="checkbox"
            className="attendance-items__form-select"
            defaultValue={item.section_ids.filter(sectionId => !!rooms.find(room => room.id === sectionId))}
            multipleSelectionText="rooms selected"
          >
            {rooms.map(room => (
              <Form.Select.Item key={room.id} value={room.id} label={room.name} />
            ))}
          </Form.Select>
        </div>

        <div className="attendance-items__form-row">
          <span className="attendance-items__form-required">4</span>.
          <span className="attendance-items__form-row-text">Add a rate for charges</span>
          <Form.Input
            required
            disableInvalid
            name="rate"
            placeholder="0"
            type="amount"
            className="attendance-items__form-amount-input"
            defaultValue={item.rate ? String(item.rate) : undefined}
          />
          <div className="attendance-items__form-row-text">by</div>
          <Form.Select
            required
            disableInvalid
            title="Choose Rate"
            name="rate_type"
            className="attendance-items__form-select"
            defaultValue={item.rate_type}
          >
            <Form.Select.Item value="hourly" label="Hour" />
            <Form.Select.Item value="daily" label="Day" />
          </Form.Select>
        </div>

        <div className="attendance-items__form-row">
          <span className="attendance-items__form-row-text">5. The late pick up fee of</span>

          <Form.Input
            disableInvalid
            type="amount"
            placeholder="0"
            name="late_fee"
            required={Boolean(formValues.late_fee_time)}
            className="attendance-items__form-amount-input"
            defaultValue={item.late_fee ? String(item.late_fee) : undefined}
          />

          <div className="attendance-items__form-row-text">/ minute after</div>

          <Form.TimeInput
            required={Number(formValues.late_fee) > 0}
            disableInvalid
            name="late_fee_time"
            defaultValue={item.late_fee_time}
          />
        </div>

        {errors.map(err => (
          <div className="attendance-items__form-row form-input__validation-text" key={err}>
            {err}
          </div>
        ))}

        <div className="attendance-items__form-submit">
          <div className="attendance-items__form-submit-actions">
            {!isListEmpty && <ButtonV2 secondary label="Cancel" onClick={onCancel} />}

            <Form.Submit label={item.id ? 'Save charge' : 'Add charge'} secondary={isListEmpty} />
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  days: state.constants.data.kid_schedules
});

export default connect(mapStateToProps)(ItemForm);
