import v4 from 'uuid/v4';
import { getResponseErrorText } from 'lib/utils';
import initializeFlash from './showFlash';
import Cloak from './Cloak';

export default class Actions {
  constructor(store) {
    this.modalPromises = [];
    this.showFlash = initializeFlash(store);
    this.showModal = this.initializeModal(store);

    this.cloak = new Cloak(store);
    this.showCloak = this.cloak.show;
    this.hideCloak = this.cloak.hide;
  }

  @bind
  modalResolve(id, data) {
    const modalInfo = this.modalPromises.find(m => m.id === id);

    if (!modalInfo) {
      console.warn(`Modal ${id} not found and can not resolve with ${JSON.stringify(data)}`);
      return;
    }

    modalInfo.resolve(data);
  }

  @bind
  modalReject(id, error) {
    const modalInfo = this.modalPromises.find(m => m.id === id);

    if (!modalInfo) {
      console.warn(`Modal ${id} not found and can not reject with error`, error);
      return;
    }

    modalInfo.reject(error);
  }

  @bind
  initializeModal(store) {
    return (component, data, options = {}) => {
      const id = v4();
      const promise = new Promise((resolve, reject) => {
        this.modalPromises.push({
          id,
          resolve,
          reject
        });
      });

      store.dispatch({
        type: 'MODAL_SHOW',
        payload: {
          id,
          component,
          data,
          options
        }
      });

      return promise;
    };
  }

  @bind
  reportError(msg, err) {
    if (err) {
      this.showFlash(getResponseErrorText(err, msg), 'alert');
    } else {
      this.showFlash(msg, 'alert');
    }
  }
}
