import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import './style.scss';

class Feature extends Component {
  static propTypes = {
    iconName: PropTypes.string,
    color: PropTypes.string
  };

  render() {
    const { iconName, color, children } = this.props;

    return (
      <div className="placeholder-feature">
        <Icon name={iconName} color={color} className="placeholder-feature__icon" size={64} />

        <div className="placeholder-feature__description">{children}</div>
      </div>
    );
  }
}

export default Feature;
