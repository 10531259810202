import { Form } from 'components';
import React, { useState } from 'react';
import './style.scss';
import { registrationFormTypes } from 'constants/registration';
import useLaunchDarkly from 'hooks/useLaunchDarkly';

function CreateRegistrationForm({ onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const flags = useLaunchDarkly();
  const isPhase2b = flags['online_cms_advancedregp2b'];
  const canSubmit = Object.keys(errors).length === 0;

  const submit = async vals => {
    try {
      setIsLoading(true);
      const result = await req.createRegistrationForm({
        name: vals.name,
        registrant_type: vals.type,
        active: true
      });
      setIsLoading(false);
      onClose({ form: result?.form, continue: vals.continue });
    } catch (err) {
      setIsLoading(false);

      const formErrors = err.response?.data?.form_errors;

      if (formErrors) {
        setErrors(formErrors);
        return;
      }

      Actions.reportError('There was problem creating a registration form', err);
    }
  };

  const onChange = (values, prevValues) => {
    if (prevValues.name !== values.name) {
      setErrors({});
    }
  };

  return (
    <div className="modal__wrapper create-registration-form">
      <div className="modal__header">
        <span className="modal__header-title">Create Registration Form</span>
      </div>

      <div className="modal__container">
        <Form onSubmit={submit} onChange={onChange} errors={errors} isLoading={isLoading}>
          <div className="form__row">
            <Form.Input name="name" label="Form Name" placeholder="Enter unique form name" required asterisk />
          </div>
          {isPhase2b && (
            <div className="form__row">
              <Form.Select name="type" label="Recipient Type" title="Select recipient type" required asterisk>
                {Object.entries(registrationFormTypes).map(([key, type]) => (
                  <Form.Select.Item key={key} value={key} label={type.descriptions.full} />
                ))}
              </Form.Select>
            </div>
          )}

          <div className="form__row form__row--actions">
            <Form.Submit secondary label="Save & Exit" name="exit" disabled={!canSubmit} />
            <Form.Submit label="Continue" name="continue" disabled={!canSubmit} />
          </div>
        </Form>
      </div>
    </div>
  );
}

export default CreateRegistrationForm;
