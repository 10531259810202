import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import difference from 'lodash/difference';
import Steps from 'modals/ModalController/Steps';
import SelectTags from './SelectTags';
import ManageTags from './ManageTags';

class TagStudent extends Component {
  static propTypes = {
    tags: PropTypes.array,
    data: PropTypes.object
  };

  static defaultProps = {
    tags: [],
    data: {}
  };

  _steps = null;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      selectedTagIds: props.data.kid.tag_ids.length > 0 ? [...props.data.kid.tag_ids] : []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tags !== this.props.tags) {
      const tagIds = this.props.tags.map(t => t.id);

      this.setState({
        selectedTagIds: this.state.selectedTagIds.filter(i => tagIds.includes(i))
      });
    }
  }

  @bind
  createTag(tagName) {
    return req.addTag({ tag: { name: tagName } }).then(tag => {
      Actions.showFlash(`A new tag "${tagName}" is added`);
      this.setState({ selectedTagIds: [...this.state.selectedTagIds, tag.id] });
    });
  }

  @bind
  bindSteps(node) {
    this._steps = node;
  }

  @bind
  updateSelectedTagIds(ids) {
    this.setState({ selectedTagIds: ids });
  }

  @bind
  openManage() {
    this._steps.goToStep('manage');
  }

  @bind
  openTags() {
    this._steps.goToStep('tags');
  }

  getTagsDiff(prevSelectedTagIds, nextSelectedTagIds) {
    const addedTags = difference(nextSelectedTagIds, prevSelectedTagIds);
    const deletedTags = difference(prevSelectedTagIds, nextSelectedTagIds);

    return { added: addedTags, deleted: deletedTags };
  }

  @bind
  submit() {
    const { kid } = this.props.data;
    const tagDiff = this.getTagsDiff(kid.tag_ids, this.state.selectedTagIds);

    if (tagDiff.added.length === 0 && tagDiff.deleted.length === 0) {
      Actions.showFlash('Tags have been updated');
      this.props.onClose();
      return;
    }

    if (this.props.data.fromProfile) {
      const payload = {
        id: kid.id,
        kid: { tag_ids: this.state.selectedTagIds }
      };

      this.setState({ loading: true }, () =>
        req
          .updateStudent(payload)
          .then(() => {
            Actions.showFlash('Tags have been updated');
            this.props.onClose();
          })
          .catch(err => {
            Actions.reportError('Unable to update tags', err);
            this.setState({ loading: false });
          })
      );

      return;
    }

    const payload = {
      kid_ids: [kid.id],
      add_tag_ids: tagDiff.added,
      remove_tag_ids: tagDiff.deleted
    };

    this.setState({ loading: true }, () => {
      req
        .studentsBatchUpdate(payload)
        .then(() => {
          Actions.showFlash('Tags have been updated');
          this.props.onClose();
        })
        .catch(err => {
          this.setState({ loading: false });
          Actions.reportError('Unable to update tags', err);
        });
    });
  }

  render() {
    const { tags, data } = this.props;
    const { loading } = this.state;

    return (
      <Steps ref={this.bindSteps}>
        <Steps.Item name="tags">
          <SelectTags
            kid={data.kid}
            tags={tags}
            selectedTagIds={this.state.selectedTagIds}
            onChange={this.updateSelectedTagIds}
            onCreate={this.createTag}
            onSubmit={this.submit}
            loading={loading}
            onOpenManage={this.openManage}
            onClose={this.props.onClose}
          />
        </Steps.Item>

        <Steps.Item name="manage">
          <ManageTags tags={tags} onOpenTags={this.openTags} />
        </Steps.Item>
      </Steps>
    );
  }
}

const mapStateToProps = state => ({
  tags: state.tags.data
});

export default connect(mapStateToProps)(TagStudent);
