import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, ButtonV2 } from 'components';

class AnswerListItem extends Component {
  static propTypes = {
    answer: PropTypes.object,
    onAdd: PropTypes.func,
    onChange: PropTypes.func,
    onRemove: PropTypes.func
  };
  static defaultProps = {
    answer: {}
  };

  @bind
  handleAdd() {
    if (this.props.onAdd) {
      this.props.onAdd();
    }
  }

  @bind
  handleChange(value) {
    const { answer, onChange } = this.props;

    if (onChange) {
      onChange(answer.id, value);
    }
  }

  @bind
  handleRemove() {
    if (this.props.onRemove) {
      this.props.onRemove(this.props.answer.id);
    }
  }

  render() {
    const { answer, isLast, isMaxReached } = this.props;

    return (
      <div className="answer-list__item">
        <TextInput
          placeholder="Sample answer: Doctor's Appointment"
          value={answer.value}
          onChange={this.handleChange}
          data-cy={'answer-value'}
        />
        {isLast ? (
          <ButtonV2
            className="answer-list__add-btn"
            icon
            iconName="add-blue"
            iconSize={24}
            onClick={this.handleAdd}
            disabled={isMaxReached}
            data-cy={'add-answer'}
          />
        ) : (
          <ButtonV2
            key={isLast}
            icon
            iconName="close-circle"
            iconSize={24}
            className="answer-list__remove-btn"
            onClick={this.handleRemove}
            data-cy={'delete-answer'}
          />
        )}
      </div>
    );
  }
}

export default AnswerListItem;
