import get from 'lodash/get';
import history from 'lib/history';
import SETTINGS_ACCESS from 'constants/settingsAccess';

const checkSettingsAccess = store => route => {
  const state = store.getState();
  const currentSchool = get(state, 'currentUser.data.current_school', null);

  if (!currentSchool || !route) {
    return;
  }

  for (const feature of SETTINGS_ACCESS) {
    if (route.match(feature.pathRegEx)) {
      const isEnabled = feature.schoolSetting(currentSchool);

      if (!isEnabled) {
        Actions.reportError(`${feature.name} is disabled for this school`);
        history.push('/');
        return;
      }
    }
  }
};

export default checkSettingsAccess;
