import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StudentVaccineDosesSelect } from 'components';
import GenericEditor from '../GenericEditor';
import './style.scss';

class StudentVaccineDosesEditor extends PureComponent {
  static propTypes = {
    defaultValue: PropTypes.object,
    isOpen: PropTypes.bool,
    onOpenStateChange: PropTypes.func
  };

  static defaultProps = {
    defaultValue: {}
  };

  @bind
  submit(value) {
    return Promise.resolve(this.props.onChange(value));
  }

  render() {
    const { defaultValue, isOpen, onOpenStateChange } = this.props;

    return (
      <GenericEditor
        className="inline-student-vaccine-doses-editor"
        onSubmit={this.submit}
        defaultValue={defaultValue}
        isOpen={isOpen}
        onOpenStateChange={onOpenStateChange}
      >
        {({ value, onChange }) => <StudentVaccineDosesSelect vaccine={value} onChange={onChange} />}
      </GenericEditor>
    );
  }
}

export default StudentVaccineDosesEditor;
