import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PlainCheckbox from '../../Checkbox';
import withContext, { FormContext } from 'hocs/withContext';

class Checkbox extends Component {
  UNSAFE_componentWillMount() {
    const initialValue = this.props.defaultValue || false;
    const validations = {};

    this.props.context.init(this.props.name, initialValue, validations);
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disabled !== this.props.disabled && this.props.disabled === true) {
      this.props.context.update(this.props.name, false);
    }
  }

  @bind
  handleChange() {
    const { values } = this.props.context;
    const { name } = this.props;

    this.props.context.update(this.props.name, !values[name]);
  }

  render() {
    const { values } = this.props.context;
    const {
      className,
      name,
      label,
      type,
      size,
      disabled,
      reversed,
      tabIndex,
      required,
      'data-cy': dataCY
    } = this.props;

    return (
      <PlainCheckbox
        className={className}
        checked={values[name]}
        label={label}
        type={type}
        reversed={reversed}
        size={size}
        disabled={disabled}
        onChange={this.handleChange}
        tabIndex={tabIndex}
        required={required}
        data-cy={dataCY}
      />
    );
  }
}

export default withContext(FormContext)(Checkbox);
