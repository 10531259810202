//TODO: do not add report if it exists in collection

const createUpdateReportStatusReducer = (reducerName, initialState) => (state = { ...initialState }, action) => {
  switch (action.type) {
    case `${reducerName}_UPDATE_REPORT_STATUS_SUCCESS`: {
      if (action.payload.generated_at) {
        const reportId = action.payload.id;
        const reportsList = [...state.data];
        const oldReport = reportsList.filter(item => item.id === reportId)[0];
        const itemIndex = reportsList.indexOf(oldReport);
        reportsList[itemIndex] = action.payload;
        return { data: reportsList, loading: false };
      } else {
        return { ...state, loading: false };
      }
    }

    case `${reducerName}_UPDATE_REPORT_STATUS_FAILURE`:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default createUpdateReportStatusReducer;
