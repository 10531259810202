import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lib/utils';

import './style.scss';
import { TooltipTrigger } from 'components';

const getTypeName = type => {
  return type
    .replace(/(_|-|\s)/g, ' ')
    .split(' ')
    .map(capitalize)
    .join(' ');
};

const ActivityButton = ({ type, count = 0, lastActivityAt, onClick }) => {
  if (!type || type === 'staff_only') {
    return null;
  }

  const rawType = type;

  if (type.indexOf('_activity') !== -1) {
    type = type.replace('_activity', '');
  }

  const title = getTypeName(type);
  const iconName = type;
  const countLabel = count > 99 ? '99+' : count;
  let buttonTooltip = `${title}: ${countLabel}`;

  if (lastActivityAt) {
    buttonTooltip += `\nLast Activity: ${Helpers.formatTime(lastActivityAt)}`;
  }

  return (
    <TooltipTrigger tooltip={buttonTooltip}>
      <div className="activity-button" onClick={() => onClick(rawType)}>
        <img className="activity-button__icon" src={`/assets/images/activities/${iconName}.png`} alt={title} />

        {count > 0 && <div className="activity-button__count">{countLabel}</div>}
      </div>
    </TooltipTrigger>
  );
};

ActivityButton.propTypes = {
  type: PropTypes.string,
  count: PropTypes.number,
  lastActivityAt: PropTypes.object,
  onClick: PropTypes.func
};

export default ActivityButton;
