import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import withLaunchDarkly from 'hocs/withLaunchDarkly';
import S3Uploader from 'lib/S3Uploader';
import Steps from 'modals/ModalController/Steps';
import ChoosePhoto from '../common/ChoosePhoto';
import CropPhoto from './CropPhoto';

class NewsletterPhotoSelect extends Component {
  static propTypes = {
    photos: PropTypes.array,
    data: PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      minWidth: PropTypes.number,
      minHeight: PropTypes.number,
      aspect: PropTypes.number
    })
  };

  static defaultProps = {
    data: {}
  };

  steps = React.createRef();

  // Used to cache photos and to simplify the search by id
  indexedPhotos = {};

  constructor(props) {
    super(props);

    this.state = {
      startStep: props.data.src ? 1 : 0,
      selectedPhotoId: undefined
    };

    this.indexedPhotos = this.getPhotosHash(props.photos);
  }

  componentDidUpdate(prevProps) {
    if (
      get(prevProps.photos, '0.id') !== get(this.props.photos, '0.id') ||
      prevProps.photos.length !== this.props.photos.length
    ) {
      // Here we cache photos because the page or filters may change
      this.indexedPhotos = { ...this.indexedPhotos, ...this.getPhotosHash(this.props.photos) };
    }
  }

  getPhotosHash(photos) {
    const hash = {};

    photos.forEach(photo => {
      hash[photo.id] = photo;
    });

    return hash;
  }

  @bind
  getSrc(id) {
    const { src } = this.props.data;

    if (id && this.indexedPhotos[id]) {
      return this.indexedPhotos[id].main_url;
    }

    if (src) {
      return src;
    }

    return undefined;
  }

  @bind
  openChoose() {
    if (this.steps.current === null) {
      return;
    }

    this.steps.current.goToStep('choose');
  }

  @bind
  openCrop() {
    if (this.steps.current === null) {
      return;
    }

    this.steps.current.goToStep('crop');
  }

  @bind
  selectPhoto(id) {
    this.setState({ selectedPhotoId: id });
  }

  @bind
  submit(file) {
    const { flags } = this.props;
    const enableExpiringUrls = flags['s3-expiring-urls'];
    const s3Config = {
      getSignature: req.awsSignature,
      key: 'newsletter_photos',
      onFailure: console.error
    };

    if (enableExpiringUrls) {
      S3Uploader.upload([file], s3Config).then(files => {
        req.getExpiringUrl({ url: files[0].url }).then(expiredUrl => {
          this.props.onClose({ url: expiredUrl });
        });
      });
    } else {
      S3Uploader.upload([file], s3Config).then(files => this.props.onClose(files[0]));
    }
  }

  render() {
    const { startStep, selectedPhotoId } = this.state;
    const { data, photos } = this.props;

    return (
      <div className="photo-select">
        <Steps startStep={startStep} ref={this.steps}>
          <Steps.Item name="choose">
            <ChoosePhoto
              photos={photos}
              selected={selectedPhotoId}
              onSelect={this.selectPhoto}
              onSubmit={this.openCrop}
            />
          </Steps.Item>

          <Steps.Item name="crop">
            <CropPhoto
              src={this.getSrc(selectedPhotoId)}
              aspect={data.aspect}
              onBack={this.openChoose}
              onSubmit={this.submit}
            />
          </Steps.Item>
        </Steps>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  photos: state.schoolPhotos.data.photos
});

const enhance = compose(withLaunchDarkly, connect(mapStateToProps));
export default enhance(NewsletterPhotoSelect);
