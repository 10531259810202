import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SelectGroup } from 'components';
import { isDiscount } from 'lib/utils';
import { withPermissionsChecking } from 'hocs/withPermissions';
import RegularItem from './RegularItem';
import AttendanceItem from './AttendanceItem';
import DiscountItem from './DiscountItem';

const ItemType = {
  Tuition: 'tuition',
  Attendance: 'attendance'
};

const EditMode = {
  Tuition: 'tuition',
  Discount: 'discount',
  Subsidy: 'subsidy',
  Ledger: 'ledger',
  Preset: 'preset'
};

class Item extends Component {
  static propTypes = {
    mode: PropTypes.oneOf([ItemType.Tuition, ItemType.Attendance, '']),
    id: PropTypes.number,
    presets: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.shape({
      _destroy: PropTypes.bool,
      _mode: PropTypes.string,
      desc: PropTypes.string
    }),
    removable: PropTypes.bool,
    disabled: PropTypes.bool,
    onUpdate: PropTypes.func,
    onRemove: PropTypes.func,
    onClose: PropTypes.func,
    onManagePresets: PropTypes.func,
    tabIndex: PropTypes.number
  };

  static defaultProps = {
    data: {},
    presets: [],
    kind: EditMode.Tuition
  };

  static isLedger = kind => {
    return kind === 'ledger';
  };

  get presets() {
    return this.props.presets.filter(p => p.kind === this.props.kind);
  }

  @bind
  changeKind(kind) {
    const { id, onUpdate, data } = this.props;

    onUpdate(id, { ...data, kind, description: '', price: '', _discount: '', _base: '', _discountType: '$' });
  }

  render() {
    if (this.props.data._destroy) {
      return null;
    }

    const {
      mode,
      kind,
      onManagePresets,
      franchiseBilling,
      subsidyOption,
      presets,
      isPresetsLoading,
      hasWritePermissions,
      ...props
    } = this.props;

    if (mode === ItemType.Attendance) {
      return <AttendanceItem {...props} />;
    }

    const ItemComponent = isDiscount(this.props.data.kind) ? DiscountItem : RegularItem;
    const items = [
      <SelectGroup.Item value={EditMode.Tuition} label="New Item" disabled={franchiseBilling} />,
      <SelectGroup.Item value={EditMode.Preset} label="Preset Item" />,
      <SelectGroup.Item value={EditMode.Discount} label="Discount" />
    ];

    if (subsidyOption === 'subsidy_discounts' || subsidyOption === 'subsidy_discounts_and_accounts') {
      // discount as a subsidy
      items.push(<SelectGroup.Item value={EditMode.Subsidy} label="Subsidy Discount" />);
    }

    if (subsidyOption === 'subsidy_accounts' || subsidyOption === 'subsidy_discounts_and_accounts') {
      // ledger as a subsidy
      items.push(<SelectGroup.Item value={EditMode.Ledger} label="Subsidy" />);
    }

    return (
      <div className="table__row invoice-item-list__item">
        <div className="table__cell table__cell--xs invoice-item-list__cell-type">
          <SelectGroup
            className="invoice-item-list__type-select"
            title="Select type"
            type="radio"
            checked={kind}
            disabled={this.props.disabled}
            onChange={this.changeKind}
            tabIndex={this.props.tabIndex}
          >
            {items}
          </SelectGroup>
        </div>

        <ItemComponent
          key={kind}
          {...props}
          editMode={kind}
          onManagePresets={onManagePresets}
          tabIndex={this.props.tabIndex}
          presets={presets}
          noSubsidiesAlert={
            hasWritePermissions('billing') &&
            hasWritePermissions('subsidies') &&
            Item.isLedger(kind) &&
            !this.presets.length &&
            !isPresetsLoading
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const isPresetsLoading = state.invoiceItemPresets.loading || state.ledgers.loading;
  const presets = isPresetsLoading
    ? []
    : [
        ...state.ledgers.data.ledgers.map(ledger => ({
          ...ledger,
          title: ledger.name,
          kind: 'ledger'
        })),
        ...state.invoiceItemPresets.data.charges
      ];

  return {
    presets,
    isPresetsLoading,
    franchiseBilling: state.currentUser.data.current_school.franchise_billing_enabled,
    subsidyOption: state.currentUser.data.current_school.subsidy_option
  };
};

export default withPermissionsChecking(connect(mapStateToProps)(Item));
