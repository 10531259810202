import React from 'react';
import { ActionButton } from 'components';
import { renderAddress } from 'lib/utils';
import withContext, { FormContext } from 'hocs/withContext';

class AddressField extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.context.init(this.props.name, this.props.defaultValue || '', {});
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  @bind
  async editAddress() {
    const newAddress = await Actions.showModal('EditAddress', {
      kid: this.props.context.values[this.props.name],
      fromEnrollment: true
    });
    if (newAddress) this.props.context.update(this.props.name, newAddress);
  }

  render() {
    const address = this.props.context.values[this.props.name];

    return (
      <div className="form__row">
        <div>
          <label className="form__label">Address</label>
          <span>{renderAddress(address, this.props.countries) || 'No Address'}</span>
          <ActionButton size={14} iconName="edit" onClick={this.editAddress} className="new-enroll__edit-btn" />
        </div>
      </div>
    );
  }
}

export default withContext(FormContext)(AddressField);
