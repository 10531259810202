import React, { Component } from 'react';
import cx from 'classnames';
import { ActionButton, ButtonV2, TextInput, Avatar, PageNav, TooltipTrigger } from 'components';
import { Scrollbars } from 'react-custom-scrollbars';
import currency from 'lib/currency';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

const PER_PAGE = 30;

class DistributeFundsStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      error: null
    };
  }

  static validate(value) {
    return /^\d{0,7}(\.\d{0,2})?$/.test(value);
  }

  @bind
  updateValue(kidId) {
    return value => {
      const { onUpdateFunds } = this.props;

      if (!DistributeFundsStep.validate(value)) return;
      this.setState({
        error: null
      });
      onUpdateFunds(kidId, value);
    };
  }

  @bind
  updateSum(value) {
    if (!DistributeFundsStep.validate(value)) return;
    this.setState({
      error: null
    });
    this.props.onUpdateSum(value);
  }

  @bind
  changePage(nextPage) {
    this.setState({
      page: nextPage
    });
  }

  @bind
  onContinue() {
    const { sum, fundsSum } = this.props;
    if (sum == null || sum.length <= 0) {
      return this.setState({
        error: 'Total sum is not set'
      });
    } else if (Number(sum) <= 0) {
      return this.setState({
        error: 'Total sum should be greater than 0'
      });
    } else if (Number(sum) !== Number(fundsSum.toFixed(2))) {
      return this.setState({
        error: 'Total sum is not equal to children funds'
      });
    }

    this.props.context.nextStep();
  }

  render() {
    const { selectedKids, students, values, notes, onUpdateNotes, sum = 0, fundsSum = 0 } = this.props;
    const { page, error } = this.state;

    const remainingSum = (sum * 100 - fundsSum * 100) / 100;

    return (
      <div className="modal__wrapper distribute-funds-step">
        <div className="modal__header modal__header--bordered">
          <ActionButton iconName="back" className="modal__header-back" onClick={() => this.props.context.prevStep()} />
          <span className="modal__header-title">Distribute Funds</span>
          <span className="modal__header-steps">
            <span>Step 2</span> / 3
          </span>
        </div>

        <div className="modal__container">
          <div className="distribute-funds-step__total-capture">
            <label htmlFor="total-received">
              <span>Total received</span>{' '}
              <TooltipTrigger
                tooltip="The balance must match total to post"
                side="center"
                className="distribute-funds-step__total-capture-help"
                tooltipClassName="distribute-funds-step__total-capture-help-tooltip"
              >
                <ButtonV2 icon iconName="tooltip" iconSize={14} />
              </TooltipTrigger>
            </label>
            <div
              className={cx('distribute-funds-step__total-capture-input', {
                'form-input--invalid': Boolean(error)
              })}
            >
              <span className="distribute-funds-step__currency-symbol">{currency.getSymbol()}</span>
              <TextInput
                data-cy={'total-received'}
                name="total-received"
                placeholder="0"
                value={sum || ''}
                onChange={this.updateSum}
              />
            </div>
            {error && <div className="form-input__validation-text">{error}</div>}
          </div>
          <div className="distribute-funds-step__header">
            <div>Showing {selectedKids.length} results</div>

            <PageNav
              page={page}
              total={selectedKids.length}
              perPage={PER_PAGE}
              loading={false}
              onPrev={() => this.changePage(page - 1)}
              onNext={() => this.changePage(page + 1)}
            />
          </div>
          <div className="table">
            <div className="table__header">
              <div className="table__cell table__cell--l">Student</div>
              <div className="table__cell table__cell--xs">Invoiced</div>
              <div className="table__cell table__cell--xs">Received</div>
            </div>
            <div className="table__body">
              <Scrollbars autoHeight autoHeightMax={300}>
                {students
                  .filter(student => selectedKids.includes(student.id))
                  .slice(PER_PAGE * (page - 1), PER_PAGE * page)
                  .map(student => {
                    return (
                      <div className="table__row" key={student.id}>
                        <div className="table__cell table__cell--l">
                          <Avatar
                            name={student.first_name + ' ' + student.last_name}
                            url={student.profile_pic_url}
                            tooltipText={student.name}
                            color={student.color}
                            initials={student.initials}
                          />
                          <div className="distribute-funds-step__name">
                            <div>{student.first_name + ' ' + student.last_name}</div>
                          </div>
                        </div>
                        <div className="table__cell table__cell--xs">
                          {currency.getPrice((student.billing_stat && student.billing_stat.total_invoiced) || 0)}
                        </div>
                        <div className="table__cell table__cell--xs">
                          <div className="distribute-funds-step__amount">
                            <span className="distribute-funds-step__currency-symbol">{currency.getSymbol()}</span>
                            <TextInput
                              value={values[student.id] || ''}
                              onChange={this.updateValue(student.id)}
                              placeholder="0"
                              data-cy={`fund-${student.id}`}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Scrollbars>
            </div>
          </div>
          <div className="distribute-funds-step__total">Total: {currency.getPrice(fundsSum)}</div>
          <div className="distribute-funds-step__remaining">Remaining: {currency.getPrice(remainingSum)}</div>
          <div className="distribute-funds-step__create-notes">
            <TextInput
              placeholder="Add optional internal note"
              placeholder="Optional internal note e.g., ACH/Check Number"
              name="notes"
              value={notes}
              onChange={onUpdateNotes}
            />
          </div>
        </div>
        <div className="modal__controls">
          <ButtonV2 label="Continue" data-cy={'continue'} onClick={this.onContinue} />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(DistributeFundsStep);
