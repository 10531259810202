import React, { Component } from 'react';
import { ActionButton, ButtonV2, Checkbox, Preloader, Avatar, Form } from 'components';
import FilterBar from '../../common/SelectKids/FilterBar';
import { connect } from 'react-redux';
import { applyFilters, isSubfamilyAllowed } from 'lib/utils';
import get from 'lodash/get';
import SelectPersonList from '../../common/SelectPersonList';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class SelectKids extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        tagIds: [],
        search: '',
        roomId: '',
        with_subfamilies: false,
        no_assigned_plans: true
      },

      selectedStudents: []
    };

    const { defaultSelected, students } = props;
    if (defaultSelected.length) {
      this.state.selectedStudents = [...props.defaultSelected];
      if (students.length) {
        this.state.filters.with_subfamilies = !!students.find(s => s.id === defaultSelected[0]).subFamily;
      }
    }
  }

  static renderPersonCard({ person, selected, onSelect }) {
    if (!person) return null;

    const { name, id, picURL, color, subFamily } = person;
    const handleSelect = () => onSelect(id);

    return (
      <div className="person-card" key={id}>
        <Checkbox className="person-card__checkbox" checked={selected} onChange={handleSelect} />

        <div className="person-card__info" onClick={handleSelect}>
          <Avatar className="person-card__avatar" url={picURL} color={color} size="small" name={name} />

          <div className="person-card__text">
            <div className="person-card__name">{name}</div>
            {subFamily && <div className="create-invoice__person-card__subfamily-name">{subFamily.name}</div>}
          </div>
        </div>
      </div>
    );
  }

  @bind
  customConditions(student, filters) {
    const matchWithSubFamilies = filters.with_subfamilies ? !!student.subFamily : !student.subFamily;

    let matchNoAssignedPlans = true;
    if (filters.no_assigned_plans) {
      const { kind } = this.props;
      matchNoAssignedPlans = !student.billing_plans_assignment[kind];
    }

    return matchWithSubFamilies && matchNoAssignedPlans;
  }

  @bind
  updateFilters(nextFilters) {
    const nextState = {
      filters: nextFilters
    };

    if (nextFilters.with_subfamilies !== this.state.filters.with_subfamilies) {
      nextState.selectedStudents = [];
    }

    this.setState(nextState);
  }

  @bind
  updateSelectedStudents(selectedStudents) {
    this.setState({
      selectedStudents
    });
  }

  @bind
  onUpdate() {
    const { selectedStudents } = this.state;
    this.props.onUpdate(selectedStudents);
    this.props.context.nextStep();
  }

  @bind
  onBack() {
    this.props.onUpdate([]);
    this.props.context.prevStep();
  }

  render() {
    const { step, totalSteps, rooms, tags, loading, students, subFamiliesEnabled } = this.props;
    const { selectedStudents, filters } = this.state;

    const filteredStudents = applyFilters(students, filters, this.customConditions);

    return (
      <div className="modal__wrapper">
        <div className="modal__header modal__header--bordered">
          {step > 1 && <ActionButton iconName="back" size={20} onClick={this.onBack} />}
          <div className="modal__header-title">Select Students</div>
          <div className="modal__header-steps">
            <span>Step {step}</span> / {totalSteps}
          </div>

          <div className="modal__filter-bar">
            <FilterBar
              rooms={rooms}
              tags={tags}
              onUpdate={this.updateFilters}
              customControl={
                <div className="create-billing-plan__custom-filters">
                  {subFamiliesEnabled && (
                    <Form.Checkbox
                      className="create-billing-plan__with-subfamilies"
                      type="slider"
                      name="with_subfamilies"
                      defaultValue={filters.with_subfamilies}
                      label="Show Multifamily"
                    />
                  )}
                  <Form.Checkbox
                    className="create-billing-plan__no-assigned-plans"
                    name="no_assigned_plans"
                    label="No Assigned Plans"
                    defaultValue={filters.no_assigned_plans}
                  />
                </div>
              }
            />
          </div>
        </div>

        <div className="modal__container create-billing-plan__select-kids">
          {loading ? (
            <div className="create-billing-plan__loading">
              <Preloader large />
            </div>
          ) : (
            <SelectPersonList
              key={this.state.filters.with_subfamilies}
              selectedIds={selectedStudents}
              persons={filteredStudents}
              allPersons={students}
              onSelect={this.updateSelectedStudents}
              noResultText={
                'There are no students without a plan. Each student can have one tuition and one attendance plan'
              }
              renderPersonCard={SelectKids.renderPersonCard}
            />
          )}
        </div>

        <div className="modal__controls">
          <ButtonV2
            label="Continue"
            onClick={this.onUpdate}
            disabled={selectedStudents.length === 0}
            isLoading={loading}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.billingPlanStudents.loading || state.billingPlanSubFamilyStudents.loading,
  rooms: state.rooms.data,
  tags: state.tags.data,
  subFamiliesEnabled: isSubfamilyAllowed(get(state, 'currentUser.data.current_school', {}))
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(SelectKids);
