import moment from 'moment';

export default (data, activity) => {
  const date = moment(data.date || undefined).format('YYYY-MM-DD');

  return {
    id: activity ? activity.id : undefined,
    daily_activity: {
      activity_time: `${date} ${data.time}`,
      is_staff_only: data.staffOnly,
      data: {
        desc: ''
      }
    },
    incident: {
      is_staff_only: data.staffOnly,
      teacher_id: data.staff_present,
      injury_nature: data.injury_nature,
      first_aid: data.first_aid,
      notify_parent: data.notify_parent,
      other_comments: data.other_comments,
      time: `${date} ${data.time}`,
      signature: {
        signature_file: data.signatureDataUrl
      }
    }
  };
};
