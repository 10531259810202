const initialState = {
  isVisible: false
};

const modals = (state = { ...initialState }, action) => {
  switch (action.type) {
    case 'CLOAK_SHOW':
      return { isVisible: true };

    case 'CLOAK_HIDE':
      return { ...initialState };

    default:
      return state;
  }
};

export default modals;
