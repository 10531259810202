import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { ButtonV2, LoadingPlaceholder } from 'components';
import { goToPage } from 'lib/history';
import FilterBar from './FilterBar';
import List from './List';
import './style.scss';

class AddLesson extends PureComponent {
  static propTypes = {
    lessons: PropTypes.array
  };

  static defaultProps = {
    lessons: []
  };

  static PER_PAGE = 30;

  state = {
    page: 1,
    filters: {},
    selected: {},
    lessons: [],
    lessonsLoading: true
  };

  getDataDebounced = debounce(this.getData, 500);

  componentDidMount() {
    req.rooms();
    req.lessonLabels();

    this.getData();
  }

  @bind
  async getData(page = this.state.page, filters = this.state.filters) {
    try {
      this.setState({ lessonsLoading: true });
      const nextLessonsResponse = await req.lessons({
        page,
        per_page: AddLesson.PER_PAGE,
        filters: {
          lesson: {
            ...filters,
            section_id: filters.section_id || undefined,
            lesson_category_id: filters.lesson_category_id || undefined
          }
        }
      });
      this.setState({ lessonsLoading: false });
      if (nextLessonsResponse.lessons.length === 0) {
        return;
      }

      this.setState({ lessons: this.state.lessons.concat(nextLessonsResponse.lessons), page });
    } catch (e) {
      Actions.reportError('Unable to get lessons', e);
    }
  }

  @bind
  getNextPage() {
    this.getData(this.state.page + 1, this.state.filters);
  }

  @bind
  updateFilters(filters) {
    this.setState({ lessons: [], filters, page: 1, lessonsLoading: true }, this.getDataDebounced);
  }

  @bind
  updateSelected(selected) {
    this.setState({ selected });
  }

  @bind
  handleClose() {
    this.props.onClose(Object.keys(this.state.selected));
  }

  @bind
  handleCreateLesson() {
    Actions.showModal('Confirmation', {
      title: 'Create Lesson',
      description: 'You will be redirected to a page to create a lesson. Are you sure?',
      yesButton: 'Yes',
      noButton: 'No',
      yesButtonProps: { secondary: false },
      noButtonProps: { secondary: true }
    }).then(result => {
      if (!result) {
        return;
      }
      this.props.onClose();
      goToPage('/learning/plans/lessons/create');
    });
  }

  render() {
    const { lessons, selected, lessonsLoading } = this.state;
    const { data, labels } = this.props;

    return (
      <div className="add-lesson">
        <div className="modal__header">
          <div className="modal__header-title">Choose Lesson</div>

          <FilterBar labels={labels} onChange={this.updateFilters} />
        </div>

        {lessons.length === 0 ? (
          <LoadingPlaceholder loading={lessonsLoading} icon="roster" text="No lessons found" />
        ) : (
          <List
            lessons={lessons}
            loading={lessonsLoading}
            selected={selected}
            onAdd={this.handleCreateLesson}
            onSelect={this.updateSelected}
            onNextPage={this.getNextPage}
            hideCreateLink={data.isMobile}
            enableRowSelect={data.isMobile}
          />
        )}

        <div className="modal__controls">
          <ButtonV2
            data-cy="choose"
            label="Choose"
            disabled={Object.keys(selected).length === 0}
            onClick={this.handleClose}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lessons: state.lessons.data.lessons,
  labels: state.lessonLabels.data
});

export default connect(mapStateToProps)(AddLesson);
