import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';
import moment from 'moment';
import './style.scss';

function ScheduleNewsletter({ onClose }) {
  const handleSubmit = ({ date, time }) => {
    const currentMoment = moment();
    const dateTimeStr = `${moment(date).format('YYYY-MM-DD')} ${time}`;

    if (currentMoment.diff(moment(dateTimeStr, 'YYYY-MM-DD hh:mm A')) > 0) {
      return Actions.showFlash('Send time should be in the future', 'alert');
    }

    onClose(dateTimeStr);
  };

  return (
    <div className="modal__wrapper schedule-newsletter">
      <div className="modal__header modal__header--bordered">
        <span className="modal__header-title">Schedule Newsletter</span>
      </div>
      <div className="modal__container schedule-newsletter__container">
        <Form onSubmit={handleSubmit} validateOn="submit">
          <div className="form__row form__row--justified">
            <Form.DatePicker
              name="date"
              label="Choose Date"
              placeholder="Select Date"
              defaultValue={moment().toDate()}
              disablePast
              disableType
              required
            />
            <Form.TimeInput
              label="Choose Time"
              name="time"
              defaultValue={moment()
                .add(1, 'm')
                .format('hh:mm A')}
              required
            />
          </div>
          <div className="form__row form__row--text schedule-newsletter__hint">
            <span className="schedule-newsletter__hint-text">
              Newsletter will be sent +/-5 mins from scheduled time
            </span>
          </div>

          <div className="form__row form__row--actions">
            <Form.Submit label="Schedule" data-cy="schedule" />
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ScheduleNewsletter;
