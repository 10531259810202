import React, { Component, Fragment } from 'react';
import { Form, Preloader } from 'components';

import { capitalize } from 'lib/utils';

import './style.scss';

class ActivityDashboardSettingForm extends Component {
  constructor(...args) {
    super(...args);

    const room = this.props.data.room;
    if (room.dashboard_settings) {
      const bottle = room.dashboard_settings.find(setting => setting.activity_type === 'bottle_activity');
      const bathroom = room.dashboard_settings.find(setting => setting.activity_type === 'bathroom_activity');
      const nap = room.dashboard_settings.find(setting => setting.activity_type === 'nap_activity');

      this.state = {
        isLoading: false,
        bottle: {
          type: 'bottle',
          enabled: bottle.tile_enabled,
          enableReminder: bottle.reminders_enabled,
          interval: bottle.interval,
          repeat: bottle.repeat,
          restartInterval: bottle.restart_interval
        },
        bathroom: {
          type: 'bathroom',
          enabled: bathroom.tile_enabled,
          enableReminder: bathroom.reminders_enabled,
          interval: bathroom.interval,
          repeat: bathroom.repeat,
          restartInterval: bathroom.restart_interval
        },
        nap: {
          type: 'nap',
          enabled: nap.tile_enabled,
          enableReminder: nap.reminders_enabled,
          interval: nap.interval,
          repeat: nap.repeat,
          restartInterval: nap.restart_interval
        }
      };
    } else {
      this.state = {
        isLoading: false,
        bottle: {
          type: 'bottle',
          enabled: true,
          enableReminder: false,
          interval: 0.25,
          repeat: true
        },
        bathroom: {
          type: 'bathroom',
          enabled: true,
          enableReminder: false,
          interval: 0.25,
          repeat: true
        },
        nap: {
          type: 'nap',
          enabled: true,
          enableReminder: false,
          interval: 0.25,
          repeat: true
        }
      };
    }
  }

  @bind
  setLoading(isLoading) {
    this.setState({ isLoading });
  }

  generateIntervals() {
    // Generate 15 minute increments up to 4 hours
    let intervals = [...Array(17).keys()]
      .slice(1)
      .map(interval => interval * 0.25)
      .map(interval => {
        return {
          name: this.intervalName(interval),
          value: interval
        };
      });

    intervals = [
      {
        name: '10 mins',
        value: 0.167
      },
      ...intervals
    ];

    return intervals;
  }

  @bind
  intervalName(interval) {
    const hours = Math.floor(interval);
    const minutes = 60 * (interval % 1);

    let label = '';
    if (hours == 1) {
      label += `${hours} hr `;
    } else if (hours > 1) {
      label += `${hours} hrs `;
    }

    if (minutes > 0) {
      label += `${minutes} mins`;
    }

    return label;
  }

  @bind
  handleFormChange(event) {
    this.setState({
      bottle: {
        type: 'bottle',
        enabled: event['bottle-enable-activity'],
        enableReminder: event['bottle-enable-reminder'],
        interval: event['bottle-set-interval'],
        repeat: event['bottle-repeat'],
        restartInterval: event['bottle-enable-restart'] ? event['bottle-restart-interval'] : 0
      },
      bathroom: {
        type: 'bathroom',
        enabled: event['bathroom-enable-activity'],
        enableReminder: event['bathroom-enable-reminder'],
        interval: event['bathroom-set-interval'],
        repeat: event['bathroom-repeat'],
        restartInterval: event['bathroom-enable-restart'] ? event['bathroom-restart-interval'] : 0
      },
      nap: {
        type: 'nap',
        enabled: event['nap-enable-activity'],
        enableReminder: event['nap-enable-reminder'],
        interval: event['nap-set-interval'],
        repeat: event['nap-repeat'],
        restartInterval: event['nap-enable-restart'] ? event['nap-restart-interval'] || 0.25 : 0
      }
    });
  }

  @bind
  save() {
    this.setLoading(true);
    const { enableDashboardReminders } = this.props.data;

    if (!this.state.bottle.enabled && !this.state.bathroom.enabled && !this.state.nap.enabled) {
      this.disable();
      this.props.onClose({ dashboardEnabled: false });
      return;
    }

    const roomId = this.props.data.room.id;
    const enablePayload = {
      id: roomId,
      section: {
        dashboard_enabled: true
      }
    };

    req
      .updateRoom(enablePayload)
      .then(() => {
        const payload = {
          id: roomId,
          dashboard_settings: [
            {
              activity_type: 'bottle_activity',
              tile_enabled: this.state.bottle.enabled || false,
              reminders_enabled: (enableDashboardReminders && this.state.bottle.enableReminder) || false,
              interval: this.state.bottle.interval || 0,
              repeat: this.state.bottle.repeat || false,
              restart_interval: this.state.bottle.restartInterval || 0
            },
            {
              activity_type: 'bathroom_activity',
              tile_enabled: this.state.bathroom.enabled || false,
              reminders_enabled: (enableDashboardReminders && this.state.bathroom.enableReminder) || false,
              interval: this.state.bathroom.interval || 0,
              repeat: this.state.bathroom.repeat || false,
              restart_interval: this.state.bathroom.restartInterval || 0
            },
            {
              activity_type: 'nap_activity',
              tile_enabled: this.state.nap.enabled || false,
              reminders_enabled: (enableDashboardReminders && this.state.nap.enableReminder) || false,
              interval: this.state.nap.interval || 0,
              repeat: this.state.nap.repeat || false,
              restart_interval: this.state.nap.restartInterval || 0
            }
          ]
        };

        req
          .updateRoomDashboardSettings(payload)
          .then(() => req.room({ id: roomId }))
          .then(() => {
            Actions.showFlash('Activities Dashboard enabled');
            this.props.onClose({ dashboardEnabled: true });
          })
          .catch(error => {
            Actions.reportError('Dashboard settings were not saved', error);
            this.props.onClose({ dashboardEnabled: false });
          });
      })
      .catch(error => {
        Actions.reportError('Error enabling activity dashboard', error);
        this.props.onClose();
      });
  }

  @bind
  disable() {
    const roomId = this.props.data.room.id;

    const roomPayload = {
      id: roomId,
      section: {
        dashboard_enabled: false
      }
    };

    const reminderPayload = {
      id: roomId,
      dashboard_settings: [
        {
          activity_type: 'bottle_activity',
          tile_enabled: false,
          reminders_enabled: false,
          interval: 0,
          repeat: false,
          restart_interval: 0
        },
        {
          activity_type: 'bathroom_activity',
          tile_enabled: false,
          reminders_enabled: false,
          interval: 0,
          repeat: false,
          restart_interval: 0
        },
        {
          activity_type: 'nap_activity',
          tile_enabled: false,
          reminders_enabled: false,
          interval: 0,
          repeat: false,
          restart_interval: 0
        }
      ]
    };

    req
      .updateRoom(roomPayload)
      .then(() => {
        req
          .updateRoomDashboardSettings(reminderPayload)
          .then(() => {
            Actions.showFlash('Activities Dashboard disabled');
            this.props.onClose({ dashboardEnabled: false });
          })
          .catch(error => {
            Actions.reportError('Dashboard settings were not saved', error);
            this.props.onClose({ dashboardEnabled: false });
          });
      })
      .catch(error => {
        Actions.reportError('Dashboard settings were not saved', error);
      });
  }

  @bind
  renderAdvancedConfig(activity) {
    const intervals = this.generateIntervals();
    return (
      <div className="activity-dashboard-setting__row-advanced">
        <div className="activity-dashboard-setting__advanced-settings--fill"></div>
        <div className="activity-dashboard-setting__advanced-settings__content">
          <div className="activity-dashboard-setting__advanced-settings__enable-reminder">
            <Form.Checkbox
              type="slider"
              size="small"
              name={`${activity.type}-enable-reminder`}
              label="STARTING A NAP"
              defaultValue={activity.enableReminder}
            />
          </div>

          <div className="activity-dashboard-setting__advanced-settings__reminder">
            <div>
              Set a <strong>Nap Check</strong> reminder
            </div>
            <div>
              <Form.Select
                name={`${activity.type}-repeat`}
                defaultValue={activity.repeat || false}
                disabled={!activity.enableReminder}
              >
                {[true, false].map(value => (
                  <Form.Select.Item
                    key={value}
                    label={value ? 'Repeats every' : 'Once after'}
                    value={value}
                    isDefault={value === activity.interval}
                  />
                ))}
              </Form.Select>
            </div>
            <div>
              <Form.Select
                name={`${activity.type}-set-interval`}
                defaultValue={activity.interval || 0.25}
                disabled={!activity.enableReminder}
              >
                {intervals.map(interval => (
                  <Form.Select.Item
                    key={interval.value}
                    label={interval.name}
                    value={interval.value}
                    isDefault={interval.value === activity.interval}
                  />
                ))}
              </Form.Select>
            </div>
          </div>

          <div className="activity-dashboard-setting__advanced-settings__enable-reminder">
            <Form.Checkbox
              type="slider"
              size="small"
              name={`${activity.type}-enable-restart`}
              label="ENDING A NAP"
              defaultValue={activity.restartInterval > 0}
            />
          </div>

          <div className="activity-dashboard-setting__advanced-settings__reminder">
            Set a new Nap reminder after
            <Form.Select
              name={`${activity.type}-restart-interval`}
              defaultValue={activity.restartInterval || 0.25}
              disabled={!activity.restartInterval > 0}
            >
              {intervals.map(interval => (
                <Form.Select.Item
                  key={interval.value}
                  label={interval.name}
                  value={interval.value}
                  isDefault={interval.value === activity.restartInterval}
                />
              ))}
            </Form.Select>
          </div>
        </div>
      </div>
    );
  }

  @bind
  renderAdvancedActivity(activity) {
    const { enableDashboardReminders } = this.props.data;

    return (
      <Fragment>
        <div className="activity-dashboard-setting__row">
          <div className="activity-dashboard-setting__enable-activity">
            <Form.Checkbox
              type="square"
              size="small"
              name={`${activity.type}-enable-activity`}
              defaultValue={activity.enabled}
            />
          </div>
          <img
            className="activity-dashboard-setting__icon"
            src={`/assets/images/activities/${activity.type}.png`}
            alt={activity.type}
          />
          <div className="activity-dashboard-setting__activity-label">{capitalize(activity.type)}</div>

          {enableDashboardReminders && activity.enabled && (
            <div className="activity-dashboard-setting__enable-reminder">
              <div className="activity-dashboard-setting__enable-reminder-advanced">
                Set a reminder on by default when:
              </div>
            </div>
          )}
        </div>

        {enableDashboardReminders && activity.enabled && this.renderAdvancedConfig(activity)}
      </Fragment>
    );
  }

  @bind
  renderBasicActivity(activity) {
    const { enableDashboardReminders } = this.props.data;

    const intervals = this.generateIntervals();
    return (
      <div className="activity-dashboard-setting__row">
        <div className="activity-dashboard-setting__enable-activity">
          <Form.Checkbox
            type="square"
            size="small"
            name={`${activity.type}-enable-activity`}
            defaultValue={activity.enabled}
          />
        </div>
        <img
          className="activity-dashboard-setting__icon"
          src={`/assets/images/activities/${activity.type}.png`}
          alt={activity.type}
        />
        <div className="activity-dashboard-setting__activity-label">{capitalize(activity.type)}</div>

        {enableDashboardReminders && activity.enabled && (
          <Fragment>
            <div className="activity-dashboard-setting__enable-reminder">
              <Form.Checkbox
                type="slider"
                size="small"
                name={`${activity.type}-enable-reminder`}
                label={`Set a reminder on by default ${activity.enableReminder ? 'after' : ''}`}
                defaultValue={activity.enableReminder}
              />
            </div>

            {activity.enableReminder && (
              <div className="activity-dashboard-setting__set-interval">
                <Form.Select name={`${activity.type}-set-interval`} defaultValue={activity.interval || 0.25}>
                  {intervals.map(interval => (
                    <Form.Select.Item
                      key={interval.value}
                      label={interval.name}
                      value={interval.value}
                      isDefault={interval.value === activity.interval}
                    />
                  ))}
                </Form.Select>
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="modal__wrapper activity-dashboard-setting">
        <Form onChange={this.handleFormChange} onSubmit={this.save}>
          <div className="modal__header activity-dashboard-setting__header">
            <div className="subheader">Activities Dashboard</div>
            <span className="modal__header-note">
              Select up to 3 activities to display on your Activities Dashboard:
            </span>
          </div>

          <div className="modal__container activity-dashboard-setting__content">
            {this.renderBasicActivity(this.state.bottle)}
            {this.renderBasicActivity(this.state.bathroom)}
            {this.renderAdvancedActivity(this.state.nap)}
          </div>

          <div className="modal__controls">
            <Form.Submit label="Apply" />
            {this.state.isLoading && (
              <div className="activity-dashboard-setting__preloader">
                <div className="activity-dashboard-setting__preloader-spinner">
                  <Preloader />
                </div>
              </div>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

export default ActivityDashboardSettingForm;
