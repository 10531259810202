import React, { Component } from 'react';
import { connect } from 'react-redux';
import Steps from 'modals/ModalController/Steps';
import debounce from 'lodash/debounce';
import MessageStep from './MessageStep';
import './style.scss';
import SelectStaffStep from './SelectStaffStep';

class NewStaffMessage extends Component {
  static initialState = {
    selectedStaff: [],
    message: '',
    isSaving: false,
    isSavingDraft: false,
    isLoading: true,
    sendSMS: false,
    staff: [],
    filters: {
      search: '',
      sectionIds: []
    }
  };

  constructor(...args) {
    super(...args);
    this.state = {
      ...NewStaffMessage.initialState
    };

    this.debouncedLoadStaff = debounce(this.loadStaff, 500);
  }

  componentDidMount() {
    req.rooms();
    this.loadStaff();
  }

  @bind
  updateForm(data) {
    this.setState(data);
  }

  @bind
  updateFilters(filters) {
    this.setState(
      {
        filters: { ...this.state.filters, ...filters },
        selectedCount: 0,
        selectedIds: {}
      },
      this.debouncedLoadStaff
    );
  }

  @bind
  loadStaff() {
    const { filters } = this.state;
    const { currentUser, rooms } = this.props;
    const roomIds = filters.status ? (filters.sectionIds.length ? filters.sectionIds : rooms.map(r => r.id)) : [];

    this.setState({ isLoading: true, staff: [] });
    req
      .staffWithoutCache({
        filters: {
          teacher: {
            email_present: filters.hideNoEmail === true || undefined,
            name: filters.search,
            section_ids: filters.status === 'assigned' ? roomIds : undefined,
            clockedin_section_ids: filters.status === 'clocked' ? roomIds : undefined,
            clocked_in: filters.status === 'clocked' || undefined
          }
        }
      })
      .then(staff => {
        this.setState({ isLoading: false, staff: staff.filter(s => s.id !== currentUser.teacher.id) });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  @bind
  sendMessage() {
    const { message, selectedStaff, sendSMS } = this.state;
    const { onClose } = this.props;

    this.setState({ isSaving: true });

    return req
      .sendMessage({
        message: {
          text: message,
          teacher_ids: selectedStaff,
          send_as_sms: sendSMS
        }
      })
      .then(() => {
        this.setState({ isSaving: false });
        onClose('sent');
      })
      .catch(err => {
        Actions.reportError('Something went wrong', err);
        this.setState({ isSaving: false });
      });
  }

  @bind
  selectStaff(staffIds) {
    this.setState({ selectedStaff: staffIds });
  }

  render() {
    const { onClose, rooms } = this.props;
    const { message, staff, sendSMS, selectedStaff, filters, isLoading, isSaving } = this.state;

    return (
      <div className="new-staff-message">
        <Steps>
          <Steps.Item>
            <MessageStep sendSMS={sendSMS} message={message} onClose={onClose} onUpdateForm={this.updateForm} />
          </Steps.Item>

          <Steps.Item name="staff">
            <SelectStaffStep
              filters={filters}
              onUpdateFilters={this.updateFilters}
              staff={staff}
              sendSMS={sendSMS}
              selectedStaff={selectedStaff}
              onSelect={this.selectStaff}
              sendMessage={this.sendMessage}
              rooms={rooms}
              isLoading={isLoading}
              isSaving={isSaving}
            />
          </Steps.Item>
        </Steps>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  currentUser: state.currentUser.data
});

export default connect(mapStateToProps)(NewStaffMessage);
