import React from 'react';
import PropTypes from 'prop-types';
import { Preloader } from 'components';
import { playYoutubeVideo } from 'lib/utils';
import cx from 'classnames';
import './style.scss';

const InfoPlaceholder = ({
  image = 'no-kids-daily',
  hasBorder,
  title = '',
  className,
  children,
  isLoading = false,
  multilineTitle,
  youtubeVideoURL,
  'data-cy': dataCY
}) => {
  if (isLoading) {
    return (
      <div className={cx('info-placeholder', className)}>
        <Preloader large />
      </div>
    );
  }

  const CN = cx('info-placeholder', className, {
    'info-placeholder--noborder': !hasBorder
  });
  const placeholderTitle = !multilineTitle ? title : title.split('\\n').map(el => <div key={el}>{el}</div>);

  return (
    <div className={CN} data-cy={dataCY}>
      <div className="info-placeholder__title">{placeholderTitle}</div>
      <div className="info-placeholder__desc">{children}</div>
      <div>
        {image && (
          <img className="info-placeholder__img" src={`/assets/images/placeholders/info/${image}.png`} alt={image} />
        )}

        {youtubeVideoURL && (
          <div
            className="info-placeholder__play"
            onClick={() => {
              playYoutubeVideo(youtubeVideoURL);
            }}
          />
        )}
      </div>
    </div>
  );
};

InfoPlaceholder.proptypes = {
  hasBorder: PropTypes.bool,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string
};

export default InfoPlaceholder;
