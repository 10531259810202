import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActionButton } from 'components';
import './style.scss';

class SelectedTag extends Component {
  static propTypes = {
    tag: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),

    onDelete: PropTypes.func
  };

  static defaultProps = {
    tag: {}
  };

  @bind
  deleteTag() {
    if (this.props.onDelete) {
      this.props.onDelete(this.props.tag.id);
    }
  }

  render() {
    const { tag, onDelete } = this.props;

    if (!tag.id || !tag.name) {
      return null;
    }

    return (
      <div className="selected-tag">
        <div className="selected-tag__name">{tag.name}</div>

        {onDelete && (
          <ActionButton className="selected-tag__close-btn" iconName="close" size={9} onClick={this.deleteTag} />
        )}
      </div>
    );
  }
}

export default SelectedTag;
