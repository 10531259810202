import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Form } from 'components';
import MilestoneSelect from 'screens/teacher/Learning/CreateLesson/MilestoneSelect';
import TEXT_CAPTIONS from './constants';
import StaffPresentSelect from './StaffPresentSelect';

const ObservationActivityForm = ({ activity, useDate = true, onChange, forceStaff }) => {
  const [measures, setMeasures] = useState(activity.measures || []);
  const [indicators, setIndicators] = useState(activity.indicators || []);
  const sharedObservationsEnabled = useSelector(
    state => state.currentUser.data.current_school.shareable_observation_activity_enabled
  );
  const sharedObservationsEnabledChangedAt = useSelector(
    state => state.currentUser.data.current_school.shareable_observation_activity_enabled_changed_at
  );

  useEffect(() => {
    onChange({
      measure_ids: measures.map(m => m.id),
      indicator_ids: indicators.map(i => i.id)
    });
  }, []);

  const handleChange = ({ selectedMeasures, selectedIndicators }) => {
    setMeasures(selectedMeasures);
    setIndicators(selectedIndicators);
    onChange({
      measure_ids: selectedMeasures.map(m => m.id),
      indicator_ids: selectedIndicators.map(i => i.id)
    });
  };

  const isApprovable =
    sharedObservationsEnabled &&
    !forceStaff &&
    moment(activity.activity_time).isSameOrAfter(
      moment(sharedObservationsEnabledChangedAt).set({ second: 0, millisecond: 0 })
    );

  return (
    <div className="form__body">
      {useDate && (
        <div className="form__row form__row--datetime">
          <Form.DatePicker label="Date" name="date" defaultValue={moment(activity.activity_time).toDate()} required />

          <Form.TimeInput label="Time" name="time" defaultValue={Helpers.formatTime(activity.activity_time)} required />
        </div>
      )}

      <div className="form__row">
        <MilestoneSelect
          withIndicators
          onChange={handleChange}
          selectedMeasures={measures}
          selectedIndicators={indicators}
        />
      </div>

      <StaffPresentSelect activity={activity} />

      <div className="form__row">
        <Form.Textarea
          name="description"
          defaultValue={activity.data.desc}
          placeholder="Description"
          label="Description"
        />
      </div>

      <div className="form__row">
        {isApprovable ? (
          <Form.Checkbox
            type="circle"
            name="staffOnly"
            label={TEXT_CAPTIONS.staffOnly}
            defaultValue={activity.is_staff_only}
          />
        ) : (
          TEXT_CAPTIONS.staffOnlyForce
        )}
      </div>
    </div>
  );
};

export default ObservationActivityForm;
