import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonV2, SelectGroup } from 'components';
import get from 'lodash/get';

import './style.scss';

function RemoveEnrollmentSibling({ data, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSibling, setSelectedSibling] = useState(null);

  const id = get(data, 'kid.id', '');
  const siblings = get(data, 'kid.siblings', []);

  const onSubmit = async () => {
    const requestData = {
      id,
      sibling_id: selectedSibling
    };

    setIsLoading(true);

    try {
      await req.unlinkSiblingEnrollment(requestData);
      Actions.showFlash('Successfully unlinked!');
      onClose(true);
    } catch (err) {
      Actions.reportError('There was problem removing a sibling', err);
    }
    setIsLoading(false);
  };

  return (
    <div className="remove-enrollment-sibling">
      <div className="modal__header">
        <span className="modal__header-title">Remove Lead Sibling</span>
      </div>

      <div className="modal__container">
        <div className="remove-enrollment-sibling__choose">
          <SelectGroup type="radio" title="Select Sibling" checked={selectedSibling} onChange={setSelectedSibling}>
            {siblings.map(s => (
              <SelectGroup.Item key={s.id} value={s.id} label={`${s.first_name} ${s.last_name}`} />
            ))}
          </SelectGroup>
        </div>

        <div className="remove-enrollment-sibling__footer form__row form__row--actions">
          <ButtonV2 onClick={onSubmit} label={'save'} disabled={isLoading || !selectedSibling} />
        </div>
      </div>
    </div>
  );
}

RemoveEnrollmentSibling.propTypes = {
  data: PropTypes.shape({
    kid: PropTypes.object,
    onClose: PropTypes.func
  })
};

export default RemoveEnrollmentSibling;
