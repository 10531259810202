import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import generateUuid from 'uuid/v4';
import { Form, Checkbox, ButtonV2, ListInput } from 'components';
import KEEPERS, { KEEPER_TRANSFERS } from 'constants/customAttributeKeepers';
import TYPES from 'constants/customAttributeTypes';
import './style.scss';
import useLaunchDarkly from 'hooks/useLaunchDarkly';

const DEFAULT_LIST_OPTIONS = [
  {
    id: generateUuid(),
    name: ''
  },
  {
    id: generateUuid(),
    name: ''
  }
];

function AddCustomAttribute({ data = {}, onClose }) {
  const flags = useLaunchDarkly();
  const hasTransferFlag = flags['online_profilefields_mapping'];
  const presetKeeper = get(data, 'presetKeeper');
  const [keeper, setKeeper] = useState(presetKeeper || get(data, 'customAttribute.keeper', 'teacher'));
  const [fieldType, setFieldType] = useState(get(data, 'customAttribute.field_type'));
  const [options, setOptions] = useState(cloneDeep(get(data, 'customAttribute.data.options', DEFAULT_LIST_OPTIONS)));
  const [isLoading, setIsLoading] = useState(false);
  const [listError, setListError] = useState(false);
  const [shouldTransfer, setShouldTransfer] = useState(
    data?.customAttribute?.id
      ? !!data?.customAttribute?.transferred_to
      : hasTransferFlag
      ? !!KEEPER_TRANSFERS[keeper]
      : false
  );
  const isNew = typeof data.customAttribute?.id === 'undefined';

  function handleChangeKeeper(value) {
    // Default checkbox to true for transferrable keepers, reset to false otherwise
    if (!!KEEPER_TRANSFERS[value] !== shouldTransfer && hasTransferFlag) {
      setShouldTransfer(!shouldTransfer);
    }

    setKeeper(value);
  }

  function handleChange(values) {
    setFieldType(values.field_type);
  }

  async function onSubmit(values) {
    const request = isNew ? req.createCustomAttribute : req.updateCustomAttribute;

    const keeperName = [keeper, ...(shouldTransfer ? [KEEPER_TRANSFERS[keeper]] : [])].join('_and_');

    const payload = {
      custom_attribute: {
        keeper: keeperName,
        ...values
      }
    };

    if (values.field_type === 'list') {
      if (options.every(o => o.name)) {
        setListError(false);
      } else {
        setListError(true);
        return;
      }
      payload.custom_attribute.data = {
        options
      };
    }

    if (!isNew) payload.id = get(data, 'customAttribute.id');

    setIsLoading(true);
    try {
      const response = await request(payload);
      Actions.showFlash('Profile field saved successfully');
      onClose(response);
    } catch (e) {
      Actions.reportError('Unable to save custom field', e);
    }
    setIsLoading(false);
  }

  return (
    <div className="add-custom-attribute">
      <div className="modal__header">
        <span className="modal__header-title">
          {`${isNew ? 'Create' : 'Edit'} ${hasTransferFlag ? 'Profile Field' : 'Custom Field'}`}
        </span>
      </div>

      <Form onSubmit={onSubmit} onChange={handleChange} isLoading={isLoading}>
        <div className="modal__container">
          {(hasTransferFlag || !presetKeeper) && (
            <div className="form__row add-custom-attribute__linked-to">
              <div className="add-custom-attribute__linked-to__label">Applied To</div>
              {presetKeeper ? (
                KEEPERS[presetKeeper]
              ) : (
                <div className="add-custom-attribute__linked-to__items">
                  {Object.keys(KEEPERS).map(k => (
                    <Checkbox
                      label={KEEPERS[k]}
                      checked={keeper === k}
                      key={k}
                      type="circle"
                      onChange={() => handleChangeKeeper(k)}
                      disabled={isLoading}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
          {hasTransferFlag && KEEPER_TRANSFERS[keeper] && (
            <Checkbox
              className="add-custom-attribute__transfer-keeper"
              checked={shouldTransfer}
              onChange={setShouldTransfer}
              label={`Transfer this profile field to ${KEEPERS[KEEPER_TRANSFERS[keeper]]} Profile upon enrollment`}
            />
          )}
          <div className="form__row">
            <Form.Input
              label="Field Name"
              placeholder="Name of profile field"
              name="name"
              defaultValue={get(data, 'customAttribute.name', '')}
              tabIndex={1}
              required
              asterisk
            />
          </div>
          <div className="form__row">
            <Form.Input
              multiline
              label="Description"
              placeholder="Write a description here to clarify this field's purpose."
              name="description"
              defaultValue={get(data, 'customAttribute.description', '')}
              tabIndex={2}
            />
          </div>
          <div className="form__row">
            <Form.Select
              name="field_type"
              title="Choose field type"
              type="radio"
              label="Format"
              defaultValue={get(data, 'customAttribute.field_type')}
              disabled={!!data.isFieldTypeDisabled}
              tabIndex={3}
              required
              asterisk
              data-cy="field-type"
            >
              {Object.keys(TYPES).map(t => (
                <Form.Select.Item key={t} value={t} label={TYPES[t]} />
              ))}
            </Form.Select>
          </div>
          {fieldType === 'list' && (
            <div className="form__row">
              <div>
                <div className="form__label">Options</div>
                <ListInput options={options} onChange={setOptions} placeholder="ex. Active or Inactive" />
                {listError && <div className="form-input__validation-text">All options should not be empty</div>}
              </div>
            </div>
          )}
        </div>
        <div className="modal__controls">
          <ButtonV2 secondary label="Cancel" onClick={onClose} />
          <Form.Submit label="save" type="submit" data-cy="submit" />
        </div>
      </Form>
    </div>
  );
}

AddCustomAttribute.propTypes = {
  data: PropTypes.object
};

export default AddCustomAttribute;
