import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'components';
import moment from 'moment';
import get from 'lodash/get';
import GenericEditor from '../GenericEditor';
import './style.scss';

class DateRange extends Component {
  static propTypes = {
    defaultValue: PropTypes.array,
    onChange: PropTypes.func,
    locked: PropTypes.bool,
    disablePast: PropTypes.bool,
    disabled: PropTypes.bool
  };

  getDefaultDate(index) {
    const val = get(this.props.defaultValue, index);
    if (val) {
      const date = moment(val, 'YYYY-MM-DD');
      return date.isValid() ? date.toDate() : new Date();
    }

    return undefined;
  }

  @bind
  submit(value) {
    if (value[0] && value[1]) {
      value[0].setHours(1, 0, 0, 0);
      value[1].setHours(23, 59, 59, 999);

      if (moment(value[0]).isAfter(value[1])) {
        Actions.reportError('Start date cannot be after end date');
        return Promise.reject('Start date cannot be after end date');
      }

      if (moment(value[1]).isBefore(value[0])) {
        Actions.reportError('End date cannot be before start date');
        return Promise.reject('End date cannot be before start date');
      }
    }

    if (!value[0] && value[1]) {
      Actions.reportError('Start date cannot be empty when end date is set');
      return Promise.reject('Start date cannot be empty when end date is set');
    }

    return Promise.resolve(this.props.onChange(value));
  }

  render() {
    const { locked, disablePast, disabled } = this.props;

    return (
      <GenericEditor
        className="date-range-editor"
        onSubmit={this.submit}
        defaultValue={[this.getDefaultDate(0), this.getDefaultDate(1)]}
        locked={locked}
        disabled={disabled}
      >
        {({ value, onChange }) => (
          <div className="date-range-editor__content">
            <div className="date-range-editor__item">
              <div className="form__label">Start Date</div>
              <DatePicker
                value={value[0]}
                onChange={newVal => onChange([newVal, value[1]])}
                className="date-range-editor__date"
                clearable
                disablePast={disablePast}
              />
            </div>
            <div className="date-range-editor__item">
              <div className="form__label">End Date</div>
              <DatePicker
                value={value[1]}
                onChange={newVal => onChange([value[0], newVal])}
                className="date-range-editor__date"
                clearable
                disablePast={disablePast}
              />
            </div>
          </div>
        )}
      </GenericEditor>
    );
  }
}

export default DateRange;
