import React from 'react';
import moment from 'moment';
import { Form } from 'components';
import TEXT_CAPTIONS from './constants';
import StaffPresentSelect from './StaffPresentSelect';
import Signature from './Signature';
import { connect } from 'react-redux';

const IncidentActivityForm = ({
  activity,
  signatures,
  useDate = true,
  forceStaff,
  incidentDigitalSignaturesEnabled
}) => {
  const staffSignature = signatures?.find(sig => sig.signatory_type === 'Teacher');

  return (
    <div className="form__body">
      {useDate && (
        <div className="form__row form__row--datetime">
          <Form.DatePicker label="Date" name="date" defaultValue={moment(activity.activity_time).toDate()} required />

          <Form.TimeInput label="Time" name="time" defaultValue={Helpers.formatTime(activity.activity_time)} required />
        </div>
      )}

      <div className="form__row">
        <Form.Input
          name="injury_nature"
          data-cy="injury-nature"
          defaultValue={activity.activiable.injury_nature}
          title="Nature of Injury/Incident"
          label="Nature of Injury/Incident"
          required
        />
      </div>

      <div className="form__row">
        <Form.Input
          name="first_aid"
          defaultValue={activity.activiable.first_aid}
          title="First Aid Administered"
          label="First Aid Administered"
        />
      </div>

      <StaffPresentSelect staffRequired={true} activity={activity} />

      {incidentDigitalSignaturesEnabled && <Signature activity={activity} signatureUrl={staffSignature?.url} />}

      <div className="form__row">
        <Form.Textarea
          name="other_comments"
          defaultValue={activity.activiable.other_comments}
          placeholder="Additional Notes"
          label="Additional Notes"
        />
      </div>

      <div className="form__row">
        <Form.Checkbox
          type="circle"
          name="notify_parent"
          label="Was the parent notified?"
          defaultValue={activity.activiable.notify_parent}
        />
      </div>

      <div className="form__row">
        {forceStaff ? (
          TEXT_CAPTIONS.staffOnlyForce
        ) : (
          <Form.Checkbox
            type="circle"
            name="staffOnly"
            label={TEXT_CAPTIONS.staffOnly}
            defaultValue={activity.is_staff_only}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  incidentDigitalSignaturesEnabled: state.currentUser.data.current_school.incident_digital_signatures_enabled
});

export default connect(mapStateToProps)(IncidentActivityForm);
