import Rollbar from 'rollbar';

const ignoredErrors = [
  'Network Error',
  'Request failed with status code 401', // Most likely logged out in another tab
  'Request failed with status code 403' // Most likely changed role in another tab
];

const requireReloadErrors = [
  // When the manifest requires an update
  'Loading chunk'
];

const enableOnEnv = ['production', 'staging', 'stage2', 'stage', 'qa', 'dev'];

function matchErrors(errText, errors) {
  return errors.some(e => errText.indexOf(e) >= 0);
}

export function connectRollbar() {
  if (
    process.env.ROLLBAR_CLIENT_TOKEN &&
    enableOnEnv.includes(process.env.ROLLBAR_ENV) &&
    process.env.NODE_ENV === 'production'
  ) {
    const rollbarConfig = {
      hostWhiteList: ['procareconnect.com'],
      accessToken: process.env.ROLLBAR_CLIENT_TOKEN,
      ignoredMessages: ignoredErrors,
      captureUncaught: true,
      captureUnhandledRejections: true,
      checkIgnore: (isUncaught, args) => {
        const isReloadRequired = matchErrors(args[0], requireReloadErrors);

        if (isReloadRequired) {
          location.reload();
          return true;
        }

        return matchErrors(args[0], ignoredErrors);
      },
      payload: {
        environment: process.env.ROLLBAR_ENV,
        client: {
          javascript: {
            code_version: process.env.VERSION,
            source_map_enabled: true,
            guess_uncaught_frames: true
          }
        }
      }
    };

    Rollbar.init(rollbarConfig);
  }
}
