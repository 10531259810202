import moment from 'moment';

export default (data = {}, activity) => {
  const date = moment(data.date || undefined).format('YYYY-MM-DD');

  return {
    id: activity ? activity.id : undefined,

    daily_activity: {
      activity_time: `${date} ${data.time}`,
      data: {
        type: data.type,
        sub_type: data.sub_type || '',
        desc: data.description,
        diaper_size: data.diaper_size || '',
        diaper_cream: !!data.diaper_cream
      },
      is_staff_only: data.staffOnly
    }
  };
};
