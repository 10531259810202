export const mealProgramMap = {
  free: 'Free',
  reduced: 'Reduced',
  paid: 'Paid',
  not_claimable: 'Not Claimable'
};

export default [
  { id: 'not_applicable', name: 'N/A' },
  { id: 'free', name: 'Free' },
  { id: 'reduced', name: 'Reduced' },
  { id: 'paid', name: 'Paid' },
  { id: 'not_claimable', name: 'Not Claimable' }
];
