import { Form } from 'components';
import React, { useState } from 'react';

function CopyRegistrationForm({ data, onClose }) {
  const { form: originalForm } = data;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const defaultName = originalForm.name + ' Copy';

  const submit = async vals => {
    try {
      setIsLoading(true);
      const result = await req.copyRegistrationForm({
        id: originalForm.id,
        name: vals.name,
        active: true
      });
      setIsLoading(false);
      onClose({ form: result?.form, continue: vals.continue });
    } catch (err) {
      setIsLoading(false);

      const formErrors = err.response?.data?.form_errors;

      if (formErrors) {
        setErrors(formErrors);
        return;
      }

      Actions.reportError('There was problem creating a registration form', err);
    }
  };

  return (
    <div className="modal__wrapper create-registration-form">
      <div className="modal__header">
        <span className="modal__header-title">Copy Registration Form</span>
      </div>

      <div className="modal__container">
        <Form onSubmit={submit} errors={errors} isLoading={isLoading}>
          <div className="form__row">
            <Form.Input
              name="name"
              label="Form Name"
              defaultValue={defaultName}
              placeholder="Enter Unique Form Name"
              required
              asterisk
            />
          </div>

          <div className="form__row form__row--actions">
            <Form.Submit secondary label="Save & Exit" name="exit" />
            <Form.Submit label="Copy" name="continue" />
          </div>
        </Form>
      </div>
    </div>
  );
}

export default CopyRegistrationForm;
