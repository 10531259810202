import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'components';

const FilterItemRoom = ({ rooms = [], name, defaultValue, label = 'Room', layout }) => (
  <Form.Row layout={layout}>
    <Form.Select
      name={name}
      data-cy={name}
      title={label}
      type="radio"
      label={label}
      defaultValue={defaultValue}
      enableClear
    >
      <Form.Select.Item value="" label="All Rooms" isDefault />
      {rooms.map(room => (
        <Form.Select.Item key={room.id} value={room.id} label={room.name} />
      ))}
    </Form.Select>
  </Form.Row>
);

FilterItemRoom.propTypes = {
  label: PropTypes.string,
  rooms: PropTypes.array,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string
};

const mapStateToProps = state => ({
  rooms: state.rooms.data
});

export default connect(mapStateToProps, null, null, { pure: false })(FilterItemRoom);
