import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import merge from 'lodash/merge';
import moment from 'moment';

import { getQuery } from 'lib/history';
import { createActivityObject } from 'lib/utils';
import { isAdmin } from 'lib/utils/selectors';
import S3Uploader from 'lib/S3Uploader';
import Steps from '../ModalController/Steps';
import buildActivityPayload from '../common/buildActivityPayload';
import ChooseActivity from './ChooseActivity';
import NewActivity from './NewActivity';
import ManageLearningActivities from '../ManageLearningActivities';
import PhotoActivityGallery from '../PhotoActivityGallery';
import UploadPhotos from 'modals/CreateActivity/UploadPhotos';
import withLaunchDarkly from 'hocs/withLaunchDarkly';

import './style.scss';
import { CreateActivityContext } from 'hocs/withContext';

class CreateActivity extends Component {
  static propTypes = {
    data: PropTypes.object,
    activityTypes: PropTypes.object
  };

  static defaultProps = {
    data: {},
    activityTypes: {}
  };

  steps = null;

  constructor(props, context) {
    super(props, context);

    this.state = {
      type: props.data.type || 'nap_activity',
      activity: null,
      additionalData: props.data.additionalData || {},
      kidIds: [],
      isLoading: false,
      uploadedPhotos: [],
      selectedPhotos: props.data.selectedPhotos || []
    };

    this.state.activity = this.makeActivity(this.state.type);
  }

  getActivityContext() {
    return {
      selectedPhotos: this.state.selectedPhotos,
      uploadedPhotos: this.state.uploadedPhotos,
      updateSelectedPhotos: this.updateSelectedPhotos,
      updateAdditionalData: this.updateAdditionalData
    };
  }

  UNSAFE_componentWillMount() {
    req.students();
    req.staff();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.type !== prevState.type) {
      this.setState({ activity: this.makeActivity(this.state.type) });
    }
  }

  makeActivity(type, values = {}) {
    return createActivityObject(
      type,
      values,
      getQuery().date ? moment(getQuery().date, 'YYYY-MM-DD').toDate() : new Date()
    );
  }

  @bind
  updateActivityType(activityType) {
    this.setState({ type: activityType });
  }

  @bind
  updateActivity(activity) {
    this.setState({ activity: merge(this.state.activity, activity) });
  }

  @bind
  onUpdateKids(kidIds) {
    this.setState({ kidIds });
  }

  @bind
  updateSelectedPhotos(selectedPhotos) {
    this.setState({
      selectedPhotos,
      additionalData: { ...this.state.additionalData, photo_ids: selectedPhotos.map(p => p.id) }
    });
  }

  @bind
  updateAdditionalData(data) {
    this.setState({
      additionalData: {
        ...data
      }
    });
  }

  @bind
  updateUploadedPhotos(uploadedPhotos) {
    this.setState({ uploadedPhotos: this.state.uploadedPhotos.concat(uploadedPhotos) }, this.toNewActivity);
  }

  @bind
  toNewActivity() {
    if (this.steps === null) {
      return;
    }

    this.steps.goToStep(1);
  }

  @bind
  isStaffOnly(activityType, isAdmin, markedStaffOnly, staffOnlyActivities) {
    const { sharedObservationsEnabled } = this.props;
    switch (activityType) {
      case 'observation_activity':
        return sharedObservationsEnabled ? markedStaffOnly : true;
      default:
        return (!isAdmin && staffOnlyActivities.includes(activityType)) || markedStaffOnly;
    }
  }

  @bind
  submit(data) {
    const { additionalData, type, uploadedPhotos, kidIds } = this.state;
    const { staffOnlyActivities, isAdmin, incidentDigitalSignaturesEnabled } = this.props;
    const activity = merge(this.state.activity, data);

    if (
      data.title === 'Incident Activity' &&
      incidentDigitalSignaturesEnabled &&
      (!data.signature || data.signature.length < 3)
    ) {
      Actions.showFlash('At least 3 characters are required for a signature', 'alert');
      return;
    }

    if (data.title === 'Nap Check' && !data.sleep_position) {
      Actions.showFlash('No sleep position selected', 'alert');
      return;
    }

    if (!kidIds.length) {
      Actions.showFlash('No students selected', 'alert');
      return;
    }

    if (uploadedPhotos.length > 0) {
      this.uploadPhotos(
        uploadedPhotos.map(p => p._file),
        kidIds,
        activity
      );
      return;
    }

    const requestPayload = {
      kid_ids: kidIds,
      ...buildActivityPayload(type, {
        data: {
          ...activity,
          ...additionalData,
          staffOnly: this.isStaffOnly(type, isAdmin, activity.staffOnly, staffOnlyActivities),
          photo_ids: additionalData.photo_ids || this.state.selectedPhotos.map(p => p.id),
          flag: this.props.flags['activities-feed']
        }
      })
    };

    if (this.props.flags['activities-feed']) {
      requestPayload.activity.activity_type = type;
    } else {
      requestPayload.daily_activity.activity_type = type;
    }

    const createActivity = this.props.flags['activities-feed'] ? 'createActivityV2' : 'createActivity';

    const action = this.props.flags['activities-feed']
      ? type === 'photo_activity'
        ? 'updatePhotoActivity'
        : createActivity
      : type === 'photo_activity'
      ? 'updatePhotoActivity'
      : type === 'incident_activity'
      ? 'createIncident'
      : createActivity;

    this.setState({ isLoading: true });
    req[action](requestPayload, undefined, {
      checkHistory: this.props.flags['activities-feed']
        ? requestPayload.activity.activity_time
        : requestPayload.daily_activity.activity_time
    })
      .then(() => {
        Actions.showFlash('Added new activity');
        this.setState({ isLoading: false });
        this.props.onClose(true);
      })
      .catch(e => {
        Actions.reportError('Unable to create activity', e);
        this.setState({ isLoading: false });
      });
  }

  @bind
  uploadPhotos(photos, kidIds, activity) {
    this.setState({ isLoading: true }, () => {
      const s3Config = {
        key: 'photos',
        getSignature: req.awsSignature,
        onFailure: (err, file) => {
          Actions.reportError(`Unable to upload ${file.name}`, err);
        }
      };

      S3Uploader.upload(photos, s3Config)
        .then(files => {
          let chain = Promise.resolve();

          for (const file of files) {
            chain = chain
              .then(() => {
                return req.uploadPhoto({
                  kid_ids: kidIds,
                  photo: {
                    url: file.url,
                    caption: activity.caption,
                    is_staff_only: activity.staffOnly
                  },
                  daily_activity: {
                    staff_present_id: activity.staff_present || null
                  }
                });
              })
              .catch(err => {
                Actions.reportError(`Unable to create an activity for ${file.name}`, err);
              });
          }
        })
        .then(() => {
          Actions.showFlash('Added new activity');
          this.setState({ loading: false });
          this.props.onClose(true);
        })
        .catch(err => {
          Actions.reportError('Unable to create activity', err);
          this.setState({ isLoading: false });
        });
    });
  }

  @bind
  bindSteps(node) {
    this.steps = node;
  }

  render() {
    const { type, isLoading, activity, additionalData, kidIds } = this.state;
    const { data, constants } = this.props;

    return (
      <CreateActivityContext.Provider value={this.getActivityContext()}>
        <div className="create-activity">
          <Steps ref={this.bindSteps} startStep={data.type !== undefined ? 1 : 0}>
            <Steps.Item>
              <ChooseActivity type={type} onTypeChange={this.updateActivityType} onSave={this.updateActivity} />
            </Steps.Item>

            <Steps.Item>
              <NewActivity
                type={type}
                activity={this.makeActivity(this.state.type, {
                  ...activity,
                  ...additionalData
                })}
                kidIds={kidIds}
                constants={constants}
                onUpdateKids={this.onUpdateKids}
                onUpdate={this.updateActivity}
                onSave={this.submit}
                isSaving={isLoading}
              />
            </Steps.Item>
            <Steps.Item hidden name="gallery">
              <PhotoActivityGallery />
            </Steps.Item>

            <Steps.Item hidden name="upload_photos">
              <UploadPhotos onUpload={this.updateUploadedPhotos} onBack={this.toNewActivity} />
            </Steps.Item>
          </Steps>
        </div>
      </CreateActivityContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: isAdmin(state),
  staffOnlyActivities: state.currentUser.data.current_school.staff_only_activities,
  constants: state.constants.data.daily_activities,
  kids: state.students.data,
  incidentDigitalSignaturesEnabled: state.currentUser.data.current_school.incident_digital_signatures_enabled,
  sharedObservationsEnabled: state.currentUser.data.current_school.shareable_observation_activity_enabled
});

const enhance = compose(withLaunchDarkly, connect(mapStateToProps));
export default enhance(CreateActivity);
