import axios from 'axios';

const authToken = process.env.MARCO_POLO_API_KEY;
const baseURL = process.env.MARCO_POLO_URL;
const categoryUrl = '/v2/wl_categories';
const lessonUrl = '/v2/wl_search?index=en-gb-assets&videoType=mp4';
const topicUrl = '/v2/wl_topics';

const RESULT_PAGE_SIZE = 40;

export const MARCO_POLO_IDENTIFIER = 'marco-polo';

/** @constant
 * @type {object}
 * @description Available filters for the user to search lessons by.
 */
export const FILTER_TYPE = {
  ALL: 'all',
  CURRICULUM: 'curriculum',
  SUBJECT: 'subject',
  TOPIC: 'topic'
};

/** @function
 * @description Queries MP for available categories. Categories come in two types: curriculum and subject.
 */
export const queryCategories = async () => {
  const curriculumIdentifier = 'curriculum';
  const subjectIdentifier = 'subject_area';

  /** @function
   * @description Category results can be nested up to 3 levels deep. This function organizes the results into a standard object used by the rest of the application.
   * @returns {object} An object containing the curriculum and subject categories.
   */
  const buildCategory = result => {
    if (!result.children) {
      return [];
    }

    return result.children.map(child1 => ({
      id: child1.id,
      title: child1.title,
      path: child1.title,
      items: child1.items ? [...child1.items] : undefined,
      children: child1.children.map(child2 => ({
        id: child2.id,
        title: child2.title,
        path: `${child1.title}/${child2.title}`,
        items: child2.items ? [...child2.items] : undefined,
        children: child2.children.map(child3 => ({
          id: child3.id,
          title: child3.title,
          path: `${child1.title}/${child2.title}/${child3.title}`,
          items: child3.items ? [...child3.items] : undefined,
          children: child3.children.map(child4 => ({
            id: child4.id,
            title: child4.title,
            path: `${child1.title}/${child2.title}/${child3.title}/${child4.title}`,
            items: child4.items ? [...child4.items] : undefined
          }))
        }))
      }))
    }));
  };

  const request = axios.create({
    baseURL,
    headers: {
      Authorization: authToken
    }
  });

  const response = await request.get(categoryUrl);

  return {
    curriculum: buildCategory(response.data.result.find(result => result.category_code === curriculumIdentifier)),
    subject: buildCategory(response.data.result.find(result => result.category_code === subjectIdentifier))
  };
};

/** @function
 * @description Queries MP for available topics. Topics are the top level categories that lessons are organized by. Topic names are used to search for lessons.
 * @returns {Array} An array of topics.
 */
export const queryTopics = async () => {
  const request = axios.create({
    baseURL,
    headers: {
      Authorization: authToken
    }
  });

  const response = await request.get(topicUrl);
  const topics = response.data.result;
  return Object.getOwnPropertyNames(topics).map(label => ({
    key: topics[label],
    label
  }));
};

/** @function
 * @description Queries MP for lessons based on the provided filters.
 * @param {string} term - A search term to filter lessons by. This is a free text search that searches the lesson title and description.
 * @param {string} topic - A topic name to filter lessons by.
 * @param {Array} itemIds - An array of category ids to filter lessons by.
 * @param {number} page - The page number of results to return.
 * @returns {Array} An array of lessons.
 */
export const queryLessons = async (term, topic, itemIds, page = 0) => {
  let queryString = 'type.keyword:lesson';
  if (term) {
    queryString += ` AND ${term} `;
  }

  if (topic) {
    queryString += ` AND fieldtrip.keyword:${topic}`;
  }

  if (itemIds?.length) {
    queryString += ` AND (${itemIds.map(id => `id.keyword:${id}`).join(' OR ')})`;
  }

  const query = {
    sort: [
      {
        'title.keyword': 'asc'
      }
    ],
    query: {
      query_string: {
        query: queryString
      }
    },
    from: page * RESULT_PAGE_SIZE,
    size: RESULT_PAGE_SIZE
  };

  const request = axios.create({
    baseURL,
    headers: {
      Authorization: authToken
    }
  });

  try {
    const response = await request.post(lessonUrl, query);
    return {
      success: true,
      lessons: response.data?.result?.items || []
    };
  } catch (error) {
    return {
      success: false,
      error
    };
  }
};

export const createMaterial = (lessonId, title, type, url) => {
  const provider = MARCO_POLO_IDENTIFIER;
  return {
    provider,
    lessonId,
    title,
    type,
    url
  };
};

export const materialEquals = (m1, m2) => {
  return m1.provider === m2.provider && m1.title === m2.title && m1.type === m2.type && m1.url === m2.url;
};
