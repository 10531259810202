import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { getErrorText, capitalize } from 'lib/utils';
import ManageController from 'modals/common/ManageItems/ManageController';
import ManageWindow from 'modals/common/ManageItems/ManageWindow';
import { ManageList, ManageListItemDefault } from 'modals/common/ManageItems/ManageList';
import { LoadingPlaceholder } from 'components';
import './style.scss';

const CategoryItem = props => <ManageListItemDefault {...props} placeholder="Category name" />;

class ManageCategoriesNext extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    kind: PropTypes.oneOf(['lesson', 'milestone']),
    categories: PropTypes.array,
    categoriesLoading: PropTypes.bool
  };

  static defaultProps = {
    data: {},
    categories: []
  };

  componentDidMount() {
    req[`${this.props.kind}Categories`]();
  }

  performCreateCategory(params) {
    switch (this.props.kind) {
      case 'lesson':
        return req.addLessonCategory(params);
      case 'milestone':
        return req.addMilestoneCategory(params);
    }
  }

  performUpdateCategory(params) {
    switch (this.props.kind) {
      case 'lesson':
        return req.updateLessonCategory(params);
      case 'milestone':
        return req.updateMilestoneCategory(params);
    }
  }

  performDeleteCategory(params) {
    switch (this.props.kind) {
      case 'lesson':
        return req.deleteLessonCategory(params, params);
      case 'milestone':
        return req.deleteMilestoneCategory(params, params);
    }
  }

  @bind
  async addCategory(category) {
    try {
      await this.performCreateCategory({ category });
      Actions.showFlash('Category has been added');
      return Promise.resolve();
    } catch (e) {
      const errMsg = capitalize(getErrorText(e.response.data));
      Actions.reportError(errMsg, e);
      return Promise.reject();
    }
  }

  @bind
  async updateCategory(id, category) {
    try {
      await this.performUpdateCategory({ id, category });
      Actions.showFlash('Category has been updated');
      return Promise.resolve();
    } catch (e) {
      const errMsg = capitalize(getErrorText(e.response.data, { name: ' ' }));
      Actions.reportError(errMsg, e);
      return Promise.reject();
    }
  }

  @bind
  async deleteCategory(id) {
    const shouldBeDeleted = await this.confirmDeletion();

    if (!shouldBeDeleted) {
      return;
    }

    try {
      await this.performDeleteCategory({ id });
      Actions.showFlash('Category has been deleted');
      return Promise.resolve();
    } catch (e) {
      Actions.reportError('Unable to delete category', e);
      return Promise.reject();
    }
  }

  async confirmDeletion() {
    return Actions.showModal('Confirmation', {
      title: 'Delete Category',
      description: 'Are you sure you want to delete this category?',
      noButton: 'No',
      yesButton: 'Yes, Delete',
      yesButtonProps: { danger: true, secondary: false },
      noButtonProps: { secondary: true }
    });
  }

  render() {
    const { categories, categoriesLoading } = this.props;

    return (
      <div className="manage-categories-next">
        <ManageController
          items={categories}
          onAdd={this.addCategory}
          onUpdate={this.updateCategory}
          onDelete={this.deleteCategory}
        >
          {props => (
            <ManageWindow
              className="manage-categories-next__window"
              title="Manage Categories"
              addIcon="add"
              addLabel="Add Category"
              onAdd={props.onNew}
              data-cy="add-category"
            >
              {categories.length === 0 && !props.isNew ? (
                <LoadingPlaceholder
                  icon="milestone"
                  loading={categoriesLoading}
                  title="No categories added"
                  text="Click on Add Category to add categories"
                  className="manage-list__placeholder"
                />
              ) : (
                <ManageList {...props} ItemComponent={CategoryItem} />
              )}
            </ManageWindow>
          )}
        </ManageController>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const kind = get(ownProps, 'data.kind', 'lesson');

  return {
    kind,
    categories: state[`${kind}Categories`].data,
    categoriesLoading: state[`${kind}Categories`].loading
  };
};

export default connect(mapStateToProps)(ManageCategoriesNext);
