import React, { useEffect, useState } from 'react';
import { Avatar, Checkbox, Preloader, ButtonV2 } from 'components';
import './style.scss';
import { useSelector } from 'react-redux';

function SendReminder({ onClose, data }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(data.defaultSelectedIds.length > 0);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const staff = useSelector(state => state.staff.data);

  useEffect(() => {
    const fetchData = async () => {
      const staff = await req.staff({
        filters: {
          teacher: {
            status: 'active'
          }
        }
      });

      if (data.defaultSelectedIds.length) {
        setSelectedStaff(staff.filter(s => data.defaultSelectedIds.includes(s.id)));
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const addStaff = async () => {
    const selectedStaffIds = await Actions.showModal('SelectStaff', {
      selected: selectedStaff.map(s => s.id),
      validations: {
        'No Email': s => !s.email
      }
    });

    if (selectedStaffIds) {
      const nextSelectedStaff = staff.filter(s => selectedStaffIds.includes(s.id));

      setSelectedStaff(nextSelectedStaff);
    }
  };

  const staffRemindersLabel = (
    <span>
      <div>Send reminder to staff</div>
      <ButtonV2
        tertiary
        className="add-vaccine__add-btn add-vaccine__add-btn--staff"
        label="Add staff"
        onClick={e => {
          addStaff();
          if (isChecked) {
            e.stopPropagation();
          }
        }}
      />
      {selectedStaff.length > 0 && (
        <ButtonV2
          tertiary
          className="add-vaccine__clear-btn"
          label="Clear all"
          onClick={e => {
            setSelectedStaff([]);
            if (!isChecked) {
              e.stopPropagation();
            }
          }}
        />
      )}
    </span>
  );

  return (
    <div className="send-reminder">
      <div className="modal__header">
        <div className="modal__header-title">Send Reminder</div>
      </div>

      <div className="modal__container">
        <div className="send-reminder__subtitle">{data.subtitle}</div>
        <div>
          <Checkbox
            className="mr-40"
            name="teachers_reminder"
            label={staffRemindersLabel}
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
        </div>
        {isLoading && data.defaultSelectedIds.length > 0 ? (
          <Preloader center />
        ) : (
          selectedStaff.length > 0 && (
            <div className="add-vaccine__staff-list">
              {selectedStaff.map(staff => (
                <div key={staff.id}>
                  <Avatar
                    type="avatar"
                    className="avatar--small"
                    name={staff.name}
                    url={staff.profile_pic_url}
                    status={staff.is_admin ? 'admin' : undefined}
                  />

                  <div className="person-card__text">
                    <div className="add-vaccine__staff-list-name person-card__name">{staff.name}</div>
                  </div>
                </div>
              ))}
            </div>
          )
        )}
      </div>

      <div className="modal__controls">
        <ButtonV2 secondary label="Cancel" onClick={onClose} />
        <ButtonV2 label="Send Reminder" onClick={() => onClose(isChecked && selectedStaff.map(s => s.id))} />
      </div>
    </div>
  );
}
export default SendReminder;
