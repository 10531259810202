/* global Core */
import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle, useLayoutEffect } from 'react';
import { downloadS3File, openEmptyWindow } from 'lib/utils';
import axios from 'axios';
import WebViewer from '@pdftron/webviewer';
import { default as WebViewerOld } from '@pdftron/pdfjs-express';
import ExpressUtils from '@pdftron/pdfjs-express-utils';
import FileSaver from 'file-saver';
import md5 from 'crypto-js/md5';
import { ButtonV2, Placeholder } from 'components';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import uniqueId from 'lodash/uniqueId';
import S3Uploader from 'lib/S3Uploader';
import { FILE_TYPES } from 'constants/fileTypes';
import useLaunchDarkly from 'hooks/useLaunchDarkly';
import store from '../../../../store';

const LICENSE_KEY = process.env.PDFTRON_LICENSE_KEY;

const LICENSE_KEYS = {
  server: '0qovO9aXknfcshsFoymf',
  client: '6mtx04zAiDnEzza4npaL',
  viewer: 'LZETY8WZvBprKhsYyc58'
};
const utils = new ExpressUtils({
  serverKey: LICENSE_KEYS.server,
  clientKey: LICENSE_KEYS.client
});

const MAX_ANNOTATION_BYTES = 1000000;

export const PDFActions = {
  preview: async (fileUrl, annotationsUrl) => {
    if (!fileUrl) return;
    const newWindow = openEmptyWindow(false, 'Please wait. Preparing the file for preview...');

    if (!newWindow) return;

    if (annotationsUrl != null) {
      try {
        const mergedUrl = await getMergedPdfURL(fileUrl, annotationsUrl);
        newWindow.location = mergedUrl;
      } catch (err) {
        newWindow.close();
        Actions.reportError('Merge PDF error', err);
      }
    } else {
      newWindow.location = fileUrl;
    }
  },
  open: async pdfData => {
    if (!pdfData) {
      return;
    }

    window.open(URL.createObjectURL(pdfData));
  },
  download: async (fileUrl, annotationsUrl) => {
    if (!fileUrl) return;

    if (annotationsUrl != null) {
      try {
        Actions.showFlash('Building PDF for download...', 'warning');
        const mergedUrl = await getMergedPdfURL(fileUrl, annotationsUrl);
        mergedUrl && downloadS3File(mergedUrl);
      } catch (err) {
        Actions.reportError('Merge PDF error', err);
      }
    } else {
      downloadS3File(fileUrl);
    }
  },
  downloadDoc: async doc => {
    // Doc Interface. Should have next fields:
    // title
    // file_url
    // annotation_url
    // merged_annotation_url
    if (!isObject(doc)) {
      Actions.reportError('Doc is not matching doc interface');
      return;
    }

    if (doc.file_url == null && doc.annotation_url == null && doc.merged_annotation_url == null) {
      Actions.reportError('Docs file URL is missing');
      return;
    }

    let filename;
    if (doc.title !== undefined) {
      const re = /(?:\.([^.]+))?$/;
      let extension = (re.exec(doc.title)[1] || '').toLowerCase();
      if (!FILE_TYPES.includes(`.${extension}`)) {
        extension = null;
      }
      filename = !extension ? `${doc.title}.pdf` : doc.title;
    } else {
      filename = 'procare.pdf';
    }

    let urlToDownload;
    if (doc.merged_annotation_url != null) {
      urlToDownload = doc.merged_annotation_url;
    } else if (doc.file_url != null && doc.annotation_url == null) {
      urlToDownload = doc.file_url;
    } else if (doc.file_url != null && doc.annotation_url != null) {
      try {
        Actions.showFlash('Building PDF for download...', 'warning');
        const mergedUrl = await getMergedPdfURL(doc.file_url, doc.annotation_url);
        urlToDownload = mergedUrl;
      } catch (err) {
        Actions.reportError('Merge PDF error', err);
        return;
      }
    }

    downloadS3File(urlToDownload, filename);
  }
};

const s3Config = (school_id = 'awd') => ({
  key: 'documents',
  getSignature: payload =>
    req.awsSignaturePublic({
      ...payload,
      school_id
    }),
  onFailure: (err, file) => {
    Actions.reportError(`Unable to upload ${file.name}`, err);
  }
});

const loadScripts = async () => {
  const scriptUrls = [
    '/pdf_full/webviewer/core/webviewer-core.min.js',
    '/pdf_full/webviewer/core/pdf/PDFNet.js'
  ];

  const addedScripts = [];

  for (const url of scriptUrls) {
    if (!document.querySelector(`script[src="${url}"]`)) {
      await new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.onload = () => {
          addedScripts.push(script);
          resolve();
        };
        script.onerror = reject;
        document.head.appendChild(script);
      });
    }
  }
  return addedScripts;
};

const removeScripts = scripts => {
  scripts.forEach(script => {
    if (script.parentNode) {
      script.parentNode.removeChild(script);
    }
  });
};

const getMergedPdfURL = async (fileUrl, annotationsUrl) => {
  if (!fileUrl || !annotationsUrl) return;
  const state = store.getState();

  const flags = JSON.parse(JSON.stringify(state.launchDarkly.flags));
  const usePDFTron = flags['pdf-tron-front-end-update'];

  if (usePDFTron) {
    const addedScripts = await loadScripts();
    try {
      // work-around to only add these global scripts when needed
      Core.setWorkerPath('/pdf_full/webviewer/core');
      const { PDFNet } = Core;

      await PDFNet.initialize(LICENSE_KEY);
  
      // Create a PDFDoc from the URL
      const doc = await PDFNet.PDFDoc.createFromURL(fileUrl);
      await doc.initSecurityHandler();

      // Fetch the XFDF annotations
      const annotationsString = await axios.get(annotationsUrl).then(res => res.data);
   
      // // Merge the annotations into the PDFDoc
      await doc.mergeXFDFString(annotationsString);
  
      // Save the merged PDF to an ArrayBuffer
      const mergedArrayBuffer = await doc.saveMemoryBuffer(PDFNet.SDFDoc.SaveOptions.e_remove_unused);
  
      // Create a Blob from the ArrayBuffer
      const mergedBlob = new Blob([mergedArrayBuffer], { type: 'application/pdf' });
      const file = new File([mergedBlob], 'procare.pdf', { type: 'application/pdf' });
  
      // Upload the file to S3
      const files = await S3Uploader.upload([file], s3Config());
      const mergedUrl = get(files, '0.url');
  
      return mergedUrl;
    } catch (err) {
      Actions.reportError('PDFTron merge error: ' + err.message);
    } finally {
      if (addedScripts) {
        removeScripts(addedScripts);
      }
    }
  } else {
    try {
      const xfdf = await axios.get(annotationsUrl, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const resp = await utils
        .setFile(fileUrl)
        .setXFDF(xfdf.data)
        .merge();
  
      const mergedBlob = await resp.getBlob();
      const file = new File([mergedBlob], 'procare.pdf', { type: 'application/pdf' });
  
      const files = await S3Uploader.upload([file], s3Config());
      const mergedUrl = get(files, '0.url');
      return mergedUrl;
    } catch (err) {
      Actions.reportError('PDF.js Express merge error', err);
    }
  }
};

function PdfViewer({
  forwardedRef,
  debug,
  fileUrl,
  annotationsUrl,
  readOnly,
  schoolId,
  doDownload,
  onDownloadFinished,
  doUpload,
  onUploadFinished,
  onError,
  onAnnotationsLoaded,
  viewerIsLoaded
}) {
  const [isFileUrlPermissionsError, setIsFileUrlPermissionsError] = useState();
  const [isError, setIsError] = useState();
  const flags = useLaunchDarkly();
  const usePDFTron = flags['pdf-tron-front-end-update'];

  useLayoutEffect(() => {
    if (!!fileUrl && fileUrl !== '/files/original/missing.png') {
      (async () => {
        try {
          await axios.get(fileUrl, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
        } catch (err) {
          setIsFileUrlPermissionsError(true);
        }
      })();
    }
  }, [fileUrl]);

  const viewer = useRef(null);
  const viewerManager = useRef(null);

  const [id] = useState(uniqueId('pdfviewer-'));
  const [isLoading, setIsLoading] = useState(true);
  const [loaders, setLoaders] = useState({
    uploadToS3: false,
    mergeAndUploadToS3: false,
    mergeAndDownload: false,
    extractAnnotationsAndUploadToS3: false,
    extractAnnotationsAndDownload: false
  });

  useEffect(() => {
    if (doDownload) {
      (async () => {
        await handleDownloadFlattened();
      })();
    }
  }, [doDownload]);

  useEffect(() => {
    if (doUpload) {
      (async () => {
        // await handleDownloadFlattened();
        const annotations = await doExtractAnnotationsAndUploadToS3();

        if (typeof onUploadFinished === 'function') {
          onUploadFinished({
            fileUrl: fileUrl,
            annotationsUrl: annotations
          });
        }
      })();
    }
  }, [doUpload]);

  useEffect(() => {
    (async () => {
      await initializeWebViewer();
    })();
  }, [fileUrl]);

  const initializeWebViewer = async () => {
    if (!fileUrl) return;

    if (usePDFTron) {
      WebViewer(
        {
          path: '/webviewer',
          licenseKey: LICENSE_KEY,
          initialDoc: fileUrl,
          isReadOnly: readOnly,
          disabledElements: readOnly ? ['toolbarGroup-Annotate', 'toolbarGroup-Insert', 'toolbarGroup-Edit', 'toolbarGroup-Shapes', 'toolbarGroup-FillAndSign', 'toolbarGroup-Forms'] : ['arcToolButton', 'toolbarGroup-Edit', 'toolbarGroup-FillAndSign', 'toolbarGroup-Forms']
        },
        viewer.current
      ).then(instance => {
        console.log("WebViewer Initialized")

        viewerManager.current = instance.Core;
        const { documentViewer, annotationManager } = viewerManager.current;
        const doc = documentViewer.getDocument();
        
        axios.get(fileUrl, { responseType: 'blob' })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            documentViewer.loadDocument(blob)
              .then(() => {
                console.log('Document explicitly loaded');
              })
              .catch(error => {
                Actions.reportError('Error loading document', error.message);
                console.log({ error });
              });
          })
          .catch(error => {
            Actions.reportError('Error fetching document', error.message);
            console.log({ error });
          });


        //merges annotations to the document
        const xfdf_string = annotationManager.exportAnnotations();

        documentViewer.addEventListener('documentLoaded', async () => {
          if (annotationsUrl) {
            try {
              const response = await fetch(annotationsUrl);
              const xfdf = await response.text();
              await annotationManager.importAnnotations(xfdf);

              if (typeof onAnnotationsLoaded === 'function') {
                const hash = await getAnnotationsHash();
                onAnnotationsLoaded(hash);
              }
            } catch (error) {
              Actions.reportError('Error importing annotations:', error);
            }
          } 
          setIsLoading(false);
          if (viewerIsLoaded) {
            viewerIsLoaded();
          }
        });

        annotationManager.addEventListener('annotationChanged', async (annotations, action) => {
          if (['add', 'modify'].includes(action)) {
            const xfdf = await annotationManager.exportAnnotations();
            if (xfdf.length > MAX_ANNOTATION_BYTES) {
              alert('Unable to include the attachment. The selected attachment exceeds the maximum size of 1 MB.');
              annotationManager.deleteAnnotations(annotations);
            }
          }
        });

        documentViewer.addEventListener('loaderror', err => {
          const message = err.detail ? err.detail.message : 'PDF loading error';
          setIsError(message);
          if (typeof onError === 'function') {
            onError();
          }
        });
      }).catch((error) => {
        console.log({error})
      });
    } else {
      WebViewerOld(
        {
          path: '/pdfjsexpress',
          licenseKey: LICENSE_KEYS.viewer,
          // disabledElements: ['toolsHeader', 'selectToolButton', 'viewControlsButton', 'searchButton', 'ribbons']
          // disableFlattenedAnnotations: true
          isReadOnly: readOnly
        },
        viewer.current
      ).then(instance => {
        const { docViewer, annotManager, iframeWindow } = instance;
        viewerManager.current = instance;
        instance.loadDocument(fileUrl, { filename: 'Test' });

        docViewer.on('documentLoaded', async () => {
          await docViewer.getAnnotationsLoadedPromise();
          if (viewerIsLoaded) {
            viewerIsLoaded();
          }

          if (annotationsUrl) {
            const xfdf = await axios.get(annotationsUrl, {
              headers: {
                'Content-Type': 'application/json'
              }
            });

            if (xfdf && xfdf.data) {
              annotManager.importAnnotations(xfdf.data);

              if (typeof onAnnotationsLoaded === 'function') {
                const hash = await getAnnotationsHash();
                onAnnotationsLoaded(hash);
              }
            }
          }
          setIsLoading(false);
        });

        iframeWindow.addEventListener('loaderror', function(err) {
          if (err && err.detail && err.detail.message) {
            setIsError(`PDF loading error: ${err.detail.message}`);
          } else {
            setIsError('PDF loading error');
          }

          if (typeof onError === 'function') {
            onError();
          }
        });

        annotManager.on('annotationChanged', async (changedAnnotations, action) => {
          if (['add', 'modify'].includes(action)) {
            const xfdf = await annotManager.exportAnnotations({
              widgets: true,
              links: true,
              fields: true
            });

            if (xfdf.length > MAX_ANNOTATION_BYTES) {
              Actions.showFlash(
                'Unable to include the attachment. The selected attachment exceeds the maximum size of 1 MB.',
                'alert'
              );
              annotManager.deleteAnnotations(changedAnnotations);
            }
          }
        });
      });
      return () => {
        if (viewerManager && viewerManager.current) {
          viewerManager.current.dispose();
        }
      }
    }
  };

  const exportAnnotations = async () => {
    const { annotationManager } = viewerManager.current;
    
    if (!annotationManager) {
      return;
    }
    return await annotationManager.exportAnnotations({
      widgets: true,
      links: true,
      fields: true
    });
  };

  const mergeDocuments = async (doc, xfdf) => {
    const data = await doc.getFileData({ xfdfString: xfdf });
    const arr = new Uint8Array(data);
    return new Blob([arr], { type: 'application/pdf' });
  };

  const getAnnotationsHash = async () => {
    if (usePDFTron) {
      const xfdf = await exportAnnotations();
      return md5(xfdf).toString();
    } else {
      const { annotManager } = viewerManager.current;
      const xfdf = await annotManager.exportAnnotations({
        widgets: true,
        links: true,
        fields: true
      });
      const hash = md5(xfdf).toString();
      return hash;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const clearSavedAnnotations = () => {
    if (localStorage.getItem('savedAnnotations')) {
      localStorage.removeItem('savedAnnotations');
    }
  };

  const handleUploadToS3 = async (openInNewTab = false) => {
    if (!viewerManager.current) {
      return;
    }

    if (usePDFTron) {
      const { documentViewer } = viewerManager.current;
  
      setLoaders(prevState => {
        return { ...prevState, uploadToS3: true };
      });
  
      const fileData = await documentViewer.getDocument().getFileData();
  
      // Get the resulting blob from the merge operation
      const file = new File([fileData], 'procare.pdf', { type: 'application/pdf' });
      const files = await S3Uploader.upload([file], s3Config());
  
      setLoaders(prevState => {
        return { ...prevState, uploadToS3: false };
      });
  
      if (openInNewTab) {
        get(files, '0.url') && window.open(get(files, '0.url'));
      }
    } else {
      const { docViewer, annotManager } = viewerManager.current;

      setLoaders(prevState => {
        return { ...prevState, uploadToS3: true };
      });

      const fileData = await docViewer.getDocument().getFileData();

      // Get the resulting blob from the merge operation
      const file = new File([fileData], 'procare.pdf', { type: 'application/pdf' });
      const files = await S3Uploader.upload([file], s3Config());

      setLoaders(prevState => {
        return { ...prevState, uploadToS3: false };
      });

      if (openInNewTab) {
        get(files, '0.url') && window.open(get(files, '0.url'));
      }
    }

  };

  const isChangesMade = () => {
    if (viewerManager.current == null) return false;
    if (usePDFTron) {
      const { documentViewer } = viewerManager.current;
      const historyManager = documentViewer.getAnnotationHistoryManager();
      const canUndo = historyManager.canUndo();
      
      return canUndo;
    } else {
      const historyManager = viewerManager.current.docViewer.getAnnotationHistoryManager();
      const canUndo = historyManager.canUndo();

      return canUndo;
    }
  };

  const isLoaded = () => {
    return !isLoading;
  };

  const handleMergeAndUploadToS3 = async (openInNewTab = false) => {
    if (!viewerManager.current) {
      return;
    }
    setLoaders(prevState => {
      return { ...prevState, mergeAndUploadToS3: true };
    });

    if (usePDFTron) {
      const { documentViewer } = viewerManager.current;
  
      // Get anotations
      const xfdf = await exportAnnotations();
      const doc = documentViewer.getDocument();
  
      // Merge anoteaions
      const mergedBlob = await mergeDocuments(doc, xfdf);
  
      // Upload file
      const file = new File([mergedBlob], 'procare.pdf', { type: 'application/pdf' });
      const files = await S3Uploader.upload([file], s3Config());
  
      setLoaders(prevState => {
        return { ...prevState, mergeAndUploadToS3: false };
      });
  
      if (openInNewTab) {
        get(files, '0.url') && window.open(get(files, '0.url'));
      } else {
        return get(files, '0.url');
      }
    } else {
      const { annotManager, docViewer } = viewerManager.current;

      const xfdf = await annotManager.exportAnnotations({
        widgets: true,
        links: true,
        fields: true
      });
      const fileData = await docViewer.getDocument().getFileData();

      const resp = await utils
        .setFile(fileData)
        .setXFDF(xfdf)
        .merge();

      // Get the resulting blob from the merge operation
      const mergedBlob = await resp.getBlob();
      const file = new File([mergedBlob], 'procare.pdf', { type: 'application/pdf' });

      const files = await S3Uploader.upload([file], s3Config());

      setLoaders(prevState => {
        return { ...prevState, mergeAndUploadToS3: false };
      });

      if (openInNewTab) {
        get(files, '0.url') && window.open(get(files, '0.url'));
      } else {
        return get(files, '0.url');
      }
    }
  };

  const handleDownloadFlattened = async () => {
    if (!viewerManager.current) {
      return;
    }

    setLoaders(prevState => {
      return { ...prevState, mergeAndDownload: true };
    });

    if (usePDFTron) {
      const { documentViewer } = viewerManager.current;
  
      // Get anotations
      const xfdf = await exportAnnotations();
      const doc = documentViewer.getDocument();
  
      // Merge anoteaions
      const mergedBlob = await mergeDocuments(doc, xfdf);
  
      setLoaders(prevState => {
        return { ...prevState, mergeAndDownload: false };
      });
      FileSaver.saveAs(mergedBlob, 'myfile.pdf');
  
      if (typeof onDownloadFinished === 'function') {
        onDownloadFinished();
      }
    } else {
      const { docViewer, annotManager } = viewerManager.current;

      const xfdf = await annotManager.exportAnnotations({
        widgets: true,
        links: true,
        fields: true
      });
      const fileData = await docViewer.getDocument().getFileData();

      const resp = await utils
        .setFile(fileData)
        .setXFDF(xfdf)
        .merge();

      // Get the resulting blob from the merge operation
      const mergedBlob = await resp.getBlob();

      setLoaders(prevState => {
        return { ...prevState, mergeAndDownload: false };
      });
      FileSaver.saveAs(mergedBlob, 'myfile.pdf');

      if (typeof onDownloadFinished === 'function') {
        onDownloadFinished();
      }
    }
  };

  const doExtractAnnotationsAndUploadToS3 = async (openInNewTab = false) => {
    if (!viewerManager.current) {
      return;
    }
    setLoaders(prevState => {
      return { ...prevState, extractAnnotationsAndUploadToS3: true };
    });

    if (usePDFTron) {
      try {
        // Get anotations
        const xfdf = await exportAnnotations();
        const file = new File([xfdf], 'annotations.xfdf', { type: 'application/xml' });
        const files = await S3Uploader.upload([file], s3Config(schoolId));
    
        setLoaders(prevState => {
          return { ...prevState, extractAnnotationsAndUploadToS3: false };
        });
    
        if (!get(files, '0.url')) {
          return;
        }
    
        if (openInNewTab) {
          get(files, '0.url') && window.open(get(files, '0.url'));
        } else {
          return get(files, '0.url');
        }
      } catch (err) {
        Actions.reportError('Merge PDF error', err);
      }
    } else {
      const { annotManager } = viewerManager.current;
      const xfdf = await annotManager.exportAnnotations({
        widgets: true,
        links: true,
        fields: true
      });
      const file = new File([xfdf], 'annotations.xfdf', { type: 'application/xml' });
      const files = await S3Uploader.upload([file], s3Config(schoolId));

      setLoaders(prevState => {
        return { ...prevState, extractAnnotationsAndUploadToS3: false };
      });

      if (!get(files, '0.url')) {
        return;
      }

      if (openInNewTab) {
        get(files, '0.url') && window.open(get(files, '0.url'));
      } else {
        return get(files, '0.url');
      }
    }
  };

  const handleGetFileAndAnnotationsURLs = async () => {
    const annotations = await doExtractAnnotationsAndUploadToS3();
    return {
      annotationsURL: annotations,
      fileURL: fileUrl
    };
  };

  const handleDownloadAnnotations = async () => {
    if (!viewerManager.current) {
      return;
    }

    setLoaders(prevState => {
      return { ...prevState, extractAnnotationsAndDownload: true };
    });

    if (usePDFTron) {
      const xfdf = await exportAnnotations();
      const file = new File([xfdf], 'annotations.xfdf', { type: 'application/xml' });
  
      setLoaders(prevState => {
        return { ...prevState, extractAnnotationsAndDownload: false };
      });
      FileSaver.saveAs(file, 'annotations.xfdf');
    } else {
      const { annotManager } = viewerManager.current;
      const xfdf = await annotManager.exportAnnotations({
        widgets: true,
        links: true,
        fields: true
      });
      const file = new File([xfdf], 'annotations.xfdf', { type: 'application/xml' });
      setLoaders(prevState => {
        return { ...prevState, extractAnnotationsAndDownload: false };
      });
      FileSaver.saveAs(file, 'annotations.xfdf');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSaveAnnotations = async () => {
    if (!viewerManager.current) {
      return;
    }

    if (usePDFTron) {
      const xfdf = await exportAnnotations();
      localStorage.setItem('savedAnnotations', xfdf);
    } else {
      const { annotManager } = viewerManager.current;
      const xfdf = await annotManager.exportAnnotations({
        widgets: true,
        links: true,
        fields: true
      });
      localStorage.setItem('savedAnnotations', xfdf);
    }
  };

  const renderDebugButtons = () => {
    return (
      debug && (
        <React.Fragment>
          <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px 0' }}>
            <div style={{ margin: 10 }}>
              <ButtonV2
                isLoading={loaders.uploadToS3}
                onClick={() => handleUploadToS3(true)}
                label="Upload PDF to the Cloud"
              />
            </div>
            <div style={{ margin: 10 }}>
              <ButtonV2
                isLoading={loaders.mergeAndUploadToS3}
                onClick={() => handleMergeAndUploadToS3(true)}
                label="Merge Annotations into PDF and upload to the Cloud"
              />
            </div>
            <div style={{ margin: 10 }}>
              <ButtonV2
                isLoading={loaders.mergeAndDownload}
                onClick={handleDownloadFlattened}
                label="Merge Annotations into PDF and download"
              />
            </div>
            <div style={{ margin: 10 }}>
              <ButtonV2
                isLoading={loaders.extractAnnotationsAndUploadToS3}
                onClick={() => doExtractAnnotationsAndUploadToS3(true)}
                label="Get Annotations and upload to the Cloud"
              />
            </div>
            <div style={{ margin: 10 }}>
              <ButtonV2
                isLoading={loaders.extractAnnotationsAndDownload}
                onClick={handleDownloadAnnotations}
                label="Download Annotations"
              />
            </div>
            {/* <div style={{ margin: 10 }}>
              <ButtonV2 onClick={handleSaveAnnotations} label="Save Annotations" />
            </div>
            <div style={{ margin: 10 }}>
              <ButtonV2 onClick={clearSavedAnnotations} label="Clear Saved Annotations" />
            </div> */}
          </div>
        </React.Fragment>
      )
    );
  };

  useImperativeHandle(forwardedRef, () => ({
    getAnnotationsURL: doExtractAnnotationsAndUploadToS3,
    getAnnotationsHash,
    getMergedURL: handleMergeAndUploadToS3,
    isChangesMade,
    isLoaded,
    getFileAndAnnotationsURL: handleGetFileAndAnnotationsURLs
  }));

  if (!fileUrl) {
    return null;
  }

  if (fileUrl === '/files/original/missing.png' || isFileUrlPermissionsError) {
    return (
      <Placeholder.Info
        title={isFileUrlPermissionsError ? 'PDF file access denied' : 'PDF file is missing. Please refresh the page.'}
        image=""
        hasBorder={false}
      >
        <ButtonV2
          tertiary
          onClick={() => {
            isFileUrlPermissionsError ? history.back() : document.location.reload();
          }}
          label={isFileUrlPermissionsError ? 'Back' : 'Reload'}
        />
      </Placeholder.Info>
    );
  }

  if (isError) {
    return (
      <Placeholder.Info title={isError} image="" hasBorder={false}>
        <ButtonV2
          tertiary
          onClick={() => {
            history.back();
          }}
          label="Back"
        />
      </Placeholder.Info>
    );
  }

  return (
    <div className="webviewer__wrapper" data-cy="pdf-viewer">
      {isLoading && (
        <div data-cy="pdf-viewer-loader" className="webviewer__loader" style={{ height: 612 }}>
          <Placeholder.NoResult isLoading className="h-full" />
        </div>
      )}
      <div style={{ height: isLoading ? 0 : '100%', opacity: isLoading ? 0 : 1 }}>
        <div id={id} data-cy="pdf-viewer-webviewer" className="webviewer" style={{ height: 612 }} ref={viewer} />
        {renderDebugButtons()}
      </div>
    </div>
  );
}

export default forwardRef((props, ref) => <PdfViewer {...props} forwardedRef={ref} />);
