import axios from 'axios';

class S3Uploader {
  static fileURLRegex = /<Location>(.*?)<\/Location>/;

  static getUniqueFilename(fileName) {
    if (!fileName) {
      throw new Error('fileName should not be empty');
    }

    const extIndex = fileName.lastIndexOf('.');
    const hasExt = extIndex > 0;

    if (!hasExt) {
      return fileName + '-' + Date.now().toString();
    }

    const ext = fileName.slice(extIndex + 1);
    const base = fileName
      .slice(0, extIndex)
      .replace(/[^0-9a-zA-Z_-\s.]/g, '')
      .replace(/\s/g, '+');

    return base + '-' + Date.now().toString() + '.' + ext;
  }

  static getFileURL(resText) {
    const matchedItems = this.fileURLRegex.exec(resText);

    if (matchedItems && matchedItems.length === 2) {
      return matchedItems[1];
    }

    return '';
  }

  static getFormData(file, sigObj) {
    const formData = new FormData();

    formData.append('key', sigObj.key_start + this.getUniqueFilename(file.name));
    formData.append('AWSAccessKeyId', sigObj.access_key_id);
    formData.append('success_action_status', '201');
    formData.append('X-Requested-With', 'xhr');
    formData.append('acl', 'public-read');
    formData.append('policy', sigObj.policy);
    formData.append('signature', sigObj.signature);
    formData.append('Content-Type', file.type);
    formData.append('file', file);

    return formData;
  }

  /**
   * S3Uploader config object
   * @typedef {Object} Config
   * @property {Object[]} files - an array of file objects
   * @property {Function} getSignature - a promise to get S3 signature (Should return a promise)
   * @property {Function} onProgress - a callback for tracking progress
   * @property {Function} onFailure - a callback for handling error for a single file upload
   */

  /**
   * Upload an array of files to S3
   *
   * @param {Object[]} files
   * @param {Config} config
   * @returns {Promise<Array | Error> | undefined}
   */
  static upload(files = [], config) {
    if (files.length === 0) {
      return Promise.reject();
    }

    let chain = Promise.resolve();
    const uploadedFiles = [];

    files.forEach((file, index) => {
      chain = chain
        .then(() => config.getSignature({ key: config.key }))
        .then(sigObj => {
          return this._uploadFile(
            file,
            sigObj,
            typeof config.onProgress === 'function'
              ? e => config.onProgress(e, this._getUploadProgress(e, index, files.length))
              : undefined
          );
        })
        .then(file => uploadedFiles.push(file))
        .catch(err => {
          if (typeof config.onFailure === 'function') {
            config.onFailure(err, file);
          } else {
            throw err;
          }
        });
    });

    chain = chain.then(() => Promise.resolve(uploadedFiles)).catch(err => Promise.reject(err));
    return chain;
  }

  // Simplified upload progress finding.
  // Treats files of different sizes as equal pieces.
  static _getUploadProgress(e, index, total) {
    return (index / total) * 100 + ((e.loaded / e.total) * 100) / total;
  }

  static _uploadFile(file, sigObj, onProgress) {
    const formData = this.getFormData(file, sigObj);
    const url = `https://${sigObj.bucket}.s3.amazonaws.com/`;

    const request = {
      method: 'POST',
      data: formData,
      headers: { 'Access-Control-Allow-Origin': '*' },
      onUploadProgress: onProgress
    };

    return axios(url, request).then(res => ({ name: file.name, _file: file, url: S3Uploader.getFileURL(res.data) }));
  }
}

export default S3Uploader;
