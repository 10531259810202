const initialState = {};

const modals = (state = { ...initialState }, action) => {
  switch (action.type) {
    case 'PARENT_CLASSES_STATE_SET':
      return action.payload;

    case 'PARENT_CLASSES_STATE_RESET':
    case 'REDUX_STORE_RESET':
      return { ...initialState };

    default:
      return state;
  }
};

export default modals;
