import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Steps from 'modals/ModalController/Steps';
import SelectLabels from './SelectLabels';
import ManageLabels from './ManageLabels';

class AddLabels extends Component {
  static propTypes = {
    labels: PropTypes.array,
    data: PropTypes.object
  };

  static defaultProps = {
    labels: [],
    data: {}
  };

  _steps = null;

  constructor(props) {
    super(props);

    const selectedLabelIds = get(props, 'data.selectedLabelIds', []);

    this.state = {
      loading: false,
      selectedLabelIds
    };
  }

  componentDidMount() {
    req.lessonLabels();
  }

  @bind
  bindSteps(node) {
    this._steps = node;
  }

  @bind
  updateselectedLabelIds(ids) {
    this.setState({ selectedLabelIds: ids });
  }

  render() {
    const { labels, labelsLoading } = this.props;

    return (
      <Steps ref={this.bindSteps}>
        <Steps.Item name="labels">
          <SelectLabels
            title="Add Labels"
            labels={labels}
            selectedLabelIds={this.state.selectedLabelIds}
            onChange={this.updateselectedLabelIds}
            onClose={this.props.onClose}
          />
        </Steps.Item>

        <Steps.Item name="manage">
          <ManageLabels labels={labels} labelsLoading={labelsLoading} />
        </Steps.Item>
      </Steps>
    );
  }
}

const mapStateToProps = state => ({
  labels: state.lessonLabels.data,
  labelsLoading: state.lessonLabels.loading
});

export default connect(mapStateToProps)(AddLabels);
