import React, { Component } from 'react';
import { ButtonV2, Checkbox } from 'components';
import { openEmptyWindow } from 'lib/utils';

import './style.scss';

class ViewPortfolio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      withProgressOnly: true
    };
  }

  @bind
  async onExport() {
    const newWindow = openEmptyWindow();
    if (!newWindow) {
      return;
    }

    const { id } = this.props.data;
    const url = await req.assessmentExport({ id, with_progress_only: this.state.withProgressOnly });

    newWindow.location = url;
    this.props.onClose(this.props.data);
  }

  @bind
  async checkChanged() {
    this.setState({ withProgressOnly: !this.state.withProgressOnly });
  }

  render() {
    return (
      <div className="modal__wrapper view-profile">
        <div className="modal__header modal__header--bordered">
          <span className="modal__header-title view-profile__title">View Portfolio</span>
          <div className="view-profile__sub-title">View this child's portfolio as a PDF</div>
        </div>
        <div className="modal__container view-profile__body">
          <div>
            <div className="view-profile__checkbox-row">
              <Checkbox
                className="view-profile__checkbox"
                checked={this.state.withProgressOnly}
                onChange={this.checkChanged}
                label="Only include Assessments with an assigned Progress"
              />
            </div>
          </div>
        </div>
        <div className="modal__controls">
          <ButtonV2 primary label="View" onClick={this.onExport} />
        </div>
      </div>
    );
  }
}

export default ViewPortfolio;
