import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SelectGroup from '../../SelectGroup';
import validationText from '../validationText';
import './style.scss';
import withContext, { FormContext } from 'hocs/withContext';

class FormSelect extends Component {
  static propTypes = {
    // Required to create form data properly, should be unique
    name: PropTypes.string.isRequired,

    // "radio" by default
    type: PropTypes.oneOf(['radio', 'checkbox']),

    // A default value
    defaultValue: PropTypes.any,

    // If provided, it will render a label above the field
    label: PropTypes.string,

    // If true, no invalid styles are applied to the field
    // Provided validations will still work, it only disables the styling
    disableInvalid: PropTypes.bool,

    // A text that appears in the SelectGroup's header
    // when several items are selected
    multipleSelectionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

    enableClear: PropTypes.bool,

    tabIndex: PropTypes.number,

    allowUnmount: PropTypes.bool,

    // shows red asterisk on the top right
    asterisk: PropTypes.bool,

    'data-cy': PropTypes.string,

    onChange: PropTypes.func,

    onClick: PropTypes.func,

    disableDropdown: PropTypes.bool
  };

  static defaultProps = {
    type: 'radio',
    allowUnmount: true
  };

  UNSAFE_componentWillMount() {
    let initialValue;
    const { defaultValue } = this.props;

    switch (this.props.type) {
      case 'radio':
        initialValue = defaultValue;
        break;

      case 'checkbox':
        initialValue = defaultValue !== undefined ? defaultValue : [];
        break;
    }

    this.props.context.init(this.props.name, initialValue, this.initValidations());
  }

  componentWillUnmount() {
    if (this.props.allowUnmount) {
      this.props.context.unmount(this.props.name);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.required !== prevProps.required) {
      this.props.context.updateValidations(this.props.name, this.initValidations());
    }
  }

  initValidations() {
    const validations = {};

    if (this.props.required) {
      validations.isNotEmpty = null;
    }

    return validations;
  }

  @bind
  clear() {
    switch (this.props.type) {
      case 'radio':
        this.handleChange(undefined);
        break;

      case 'checkbox':
        this.handleChange([]);
        break;
    }
  }

  /**
   * Update value of a field
   *
   * @param value - a new value for a field with the name === this.props.name
   */
  @bind
  handleChange(value) {
    this.props.context.update(this.props.name, value);
    if (this.props.onChange) {
      this.props.onChange(this.props.name, value);
    }
  }

  /**
   * Update the validation state on close
   */
  handleClose() {
    this.props.context.validate(this.props.name);
  }

  /**
   * Get a text for an invalid field to render.
   *
   * @return {string} - a text which represents a validation error
   */
  getValidationText() {
    const { name } = this.props;
    const { validations, errors } = this.props.context;

    if (errors[name]) {
      return errors[name].join('; ');
    }

    if (!validations[name]) {
      return '';
    }

    return Object.keys(validations[name])
      .filter(type => validations[name][type] === false)
      .map(type => {
        if (typeof validationText[type] === 'function') {
          return validationText[type](this.props[type]);
        }

        return validationText[type];
      })
      .join('; ');
  }

  render() {
    const {
      children,
      title,
      multipleSelectionText,
      name,
      label,
      className,
      disabled,
      disableInvalid,
      tapable,
      type,
      enableClear,
      tabIndex,
      asterisk,
      allSelectionItemId,
      action,
      isActionSticky,
      onClick,
      disableDropdown,
      'data-cy': dataCY
    } = this.props;
    const checked = this.props.context.values[name];
    const invalidText = this.getValidationText();

    const selectCN = classNames({
      'form-select': true,
      'form-select--invalid': Boolean(invalidText),
      [className]: Boolean(className)
    });

    return (
      <div className={selectCN}>
        {label && (
          <label className="form__label" htmlFor={name}>
            {label} {asterisk && <span className="form__asterisk">*</span>}
          </label>
        )}

        <SelectGroup
          className="form-select__field"
          type={type}
          title={title}
          checked={checked}
          onChange={this.handleChange}
          multipleSelectionText={multipleSelectionText}
          tapable={tapable}
          disabled={disabled}
          onClear={enableClear ? this.clear : undefined}
          tabIndex={tabIndex}
          action={action}
          isActionSticky={isActionSticky}
          data-cy={dataCY}
          onClick={onClick}
          allSelectionItemId={allSelectionItemId}
          disableDropdown={disableDropdown}
        >
          {children}
        </SelectGroup>

        {asterisk && !label && <span className="form__asterisk--no-label">*</span>}
        {invalidText && !disableInvalid && (
          <div className="form-select__validation-text" data-cy={`${dataCY}-invalid`}>
            {invalidText}
          </div>
        )}
      </div>
    );
  }
}

const Select = withContext(FormContext)(FormSelect);
Select.Item = SelectGroup.Item;

export default Select;
