import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { MonthPicker } from 'components';
import validationText from '../validationText';
import withContext, { FormContext } from 'hocs/withContext';

class FormMonthPicker extends Component {
  static propTypes = {
    // Required to create form data properly, should be unique
    name: PropTypes.string,

    // A default value
    defaultValue: PropTypes.any,

    // If provided, it will render a label above the field
    label: PropTypes.string,

    // If true, no invalid styles are applied to the field
    // Provided validations will still work, it only disables the styling
    disableInvalid: PropTypes.bool,

    // Disabled style
    disabled: PropTypes.bool,

    // Custom class name
    className: PropTypes.string,

    allowUnmount: PropTypes.bool,

    rangeStart: PropTypes.string,
    rangeEnd: PropTypes.string,
    clearable: PropTypes.bool,
    maxRangeMonth: PropTypes.number
  };

  static defaultProps = {
    allowUnmount: true
  };

  UNSAFE_componentWillMount() {
    const { name, defaultValue, required, rangeStart, rangeEnd, maxRangeMonth } = this.props;
    const initialValue = Array.isArray(defaultValue)
      ? defaultValue
      : defaultValue
      ? [
          moment(defaultValue)
            .startOf('month')
            .toDate(),
          moment(defaultValue)
            .endOf('month')
            .toDate()
        ]
      : undefined;

    // These operations create a validation object's structure
    // and send it to Form component.
    const validations = {};

    if (required) {
      validations.isNotEmpty = null;
    }

    if (rangeStart) {
      validations.isGreaterThanStart = null;
      this.props.context.link(name, rangeStart);
    }

    if (rangeEnd) {
      validations.isLesserThanEnd = null;
      this.props.context.link(name, rangeEnd);
    }

    if (maxRangeMonth) {
      validations.maxRangeMonth = maxRangeMonth;
    }

    this.props.context.init(name, initialValue, validations);
  }

  componentWillUnmount() {
    if (this.props.allowUnmount) {
      this.props.context.unmount(this.props.name);
    }
  }

  @bind
  handleChange(value) {
    this.props.context.update(this.props.name, value);
  }

  /**
   * Get a text for an invalid field to render.
   *
   * @return {string} - a text which represents a validation error
   */
  getValidationText() {
    const { name } = this.props;
    const { errors, validations } = this.props.context;

    if (errors[name]) {
      return errors[name].join('; ');
    }

    if (!validations[name]) {
      return '';
    }

    return Object.keys(validations[name])
      .filter(type => validations[name][type] === false)
      .map(type => {
        if (typeof validationText[type] === 'function') {
          return validationText[type](this.props[type]);
        }

        return validationText[type];
      })
      .join('; ');
  }

  render() {
    const {
      label,
      disableInvalid,
      disabled,
      className,
      defaultValue,
      disableCurrentMonth,
      disableFuture,
      clearable,
      disablePast
    } = this.props;
    const invalidText = this.getValidationText();

    const formDateCN = classNames({
      'form-date': true,
      'form-date--invalid': Boolean(invalidText) && !disableInvalid,
      'form-date--disabled': disabled,
      [className]: Boolean(className)
    });

    return (
      <div className={formDateCN}>
        {label && <label className="form__label">{label}</label>}

        <MonthPicker
          onChange={this.handleChange}
          defaultValue={defaultValue}
          disabled={disabled}
          clearable={clearable}
          disableFuture={disableFuture}
          disableCurrentMonth={disableCurrentMonth}
          disablePast={disablePast}
        />

        {invalidText && !disableInvalid && <div className="form-input__validation-text">{invalidText}</div>}
      </div>
    );
  }
}

export default withContext(FormContext)(FormMonthPicker);
