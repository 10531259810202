import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonV2, ActionButton } from 'components';
import v4 from 'uuid/v4';
import List from './List';

import './style.scss';
import { ModalControllerStepsContext } from 'hocs/withContext';

const findKidsByFamilyId = (students, familyId) => {
  return students.filter(student => {
    const studentFamilyId = student.family_id || (student.parents.length > 0 && student.parents[0].family_id);
    return familyId === studentFamilyId;
  });
};

const getCarersFromKids = familyKids => {
  const carerIds = [];

  return familyKids.reduce((carers, kid) => {
    const uniqParents = kid.parents.filter(parent => {
      return !carerIds.includes(parent.id) && carerIds.push(parent.id);
    });
    return carers.concat(uniqParents);
  }, []);
};

const getInitSubFamilies = (subFamilies = [], kids, carers) => {
  let result = [];

  if (!subFamilies.length) {
    result = [
      {
        id: v4(),
        name: 'Family#1',
        kids: [...kids],
        carers: [...carers.slice(0, 1)],
        _new: true
      },
      {
        id: v4(),
        name: 'Family#2',
        kids: [...kids],
        carers: [...carers.slice(1)],
        _new: true
      }
    ];
  } else {
    subFamilies.forEach(sf => {
      result.push({
        id: sf.id,
        name: sf.name,
        kids: [...kids.filter(kid => sf.kid_ids.includes(kid.id))],
        carers: [...carers.filter(carer => sf.carer_ids.includes(carer.id))]
      });
    });
  }

  return result;
};

function SplitStep(props) {
  const { familyId, onClose } = props;
  const context = useContext(ModalControllerStepsContext);
  const isEdit = !!props.subFamilies.length;
  const kids = findKidsByFamilyId(props.students, familyId);
  const carers = getCarersFromKids(kids);

  const [subFamilies, setSubFamilies] = useState(getInitSubFamilies(props.subFamilies, kids, carers));
  const [validations, setValidations] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleAddSubFamily = () => {
    const nextSubFamilies = [
      ...subFamilies,
      { id: v4(), name: `Family#${subFamilies.length + 1}`, carers: [], kids: [], _new: true }
    ];
    setSubFamilies(nextSubFamilies);
  };

  const handleUpdateSubFamily = id => values => {
    const nextSubFamilies = [...subFamilies];
    const subFamilyIndex = nextSubFamilies.findIndex(sf => sf.id === id);
    nextSubFamilies[subFamilyIndex] = { ...nextSubFamilies[subFamilyIndex], ...values };
    setSubFamilies(nextSubFamilies);
  };

  const handleRemoveSubFamily = id => () => {
    const nextSubFamilies = [...subFamilies];
    const subFamilyIndex = nextSubFamilies.findIndex(sf => sf.id === id);
    nextSubFamilies[subFamilyIndex]['_destroy'] = true;
    setSubFamilies([...nextSubFamilies]);
  };

  const handleSave = async () => {
    const nextValidations = {};
    let isValid = true;

    subFamilies.forEach(sf => {
      if (sf._destroy) return;

      const subFamilyValidation = {};

      if (!sf.name.length) subFamilyValidation.name = 'Should not be empty';
      if (!sf.kids.length) subFamilyValidation.kids = 'Should select at least 1 child';
      if (!sf.carers.length) subFamilyValidation.carers = 'Should select at least 1 parent';

      if (Object.keys(subFamilyValidation).length) {
        nextValidations[sf.id] = subFamilyValidation;
        isValid = false;
      }
    });

    if (!isValid) return setValidations(nextValidations);

    setIsSaving(true);
    try {
      const requests = subFamilies.map(sf => {
        if (sf._destroy) {
          if (!sf._new) {
            return req.removeSubFamily({ family_id: familyId, id: sf.id });
          }
        } else {
          const action = sf._new ? 'createSubFamily' : 'updateSubFamily';
          const payload = {
            family_id: familyId,
            sub_family: {
              name: sf.name,
              carer_ids: sf.carers.map(c => c.id),
              kid_ids: sf.kids.map(k => k.id)
            }
          };
          if (!sf._new) payload.id = sf.id;

          return req[action](payload);
        }
      });

      const response = await Promise.all(requests);

      Actions.showFlash(`Multifamily successfully ${isEdit ? 'updated' : 'created'}`);

      onClose(response);
    } catch (err) {
      Actions.reportError('Unable to save multifamily', err);
    }
    setIsSaving(false);
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      const shouldDelete = await Actions.showModal('Confirmation', {
        title: 'Delete families',
        description:
          'This action will make all transactions visible to each parent on this child’s profile. Are you sure you want to delete?',
        noButton: 'No',
        yesButton: 'Yes, Delete',
        yesButtonProps: {
          danger: true,
          secondary: false
        },
        noButtonProps: {
          secondary: true
        }
      });

      if (!shouldDelete) {
        return;
      }

      await req.removeSubFamilies({ family_id: familyId });
      Actions.showFlash('Multifamily has been deleted successfully!');
      onClose(true);
    } catch (err) {
      Actions.reportError('Unable to delete multifamily', err);
    }
    setIsDeleting(false);
  };

  return (
    <div className="modal__wrapper">
      <div className="modal__header">
        <span className="modal__header-title">Multifamily</span>
        <ActionButton iconName="close" onClick={onClose} size={12} className="modal__close" />
      </div>
      <div className="modal__container subfamilies">
        <List
          subFamilies={subFamilies.filter(sf => !sf._remove)}
          onRemove={handleRemoveSubFamily}
          onUpdate={handleUpdateSubFamily}
          family={{
            kids,
            carers
          }}
          validations={validations}
        />
        {subFamilies.length < 2 && (
          <div className="subfamilies__controls">
            <div className="subfamilies__controls-add">
              <ActionButton title="Add family" iconName="add-dark" size={24} onClick={handleAddSubFamily} />
            </div>
          </div>
        )}
      </div>
      {!!props.subFamilies.length && (
        <div className="subfamilies__delete">
          Do you want to <ActionButton className="subfamilies__delete-button" title="DELETE" onClick={handleDelete} />{' '}
          multifamilies?
        </div>
      )}
      <div className="modal__controls">
        {context.currentStep > 1 && <ButtonV2 secondary label="cancel" onClick={context.prevStep} />}
        <ButtonV2 label="save" disabled={isSaving || isDeleting} onClick={handleSave} />
      </div>
    </div>
  );
}

SplitStep.propTypes = {
  students: PropTypes.array.isRequired,
  subFamilies: PropTypes.array.isRequired
};

export default SplitStep;
