import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';
import { Link as ReactLink } from 'react-router-dom';

export const Link = ({
  label,
  onClick,
  className,
  disabled,
  tabIndex,
  to,
  target = '_self',
  bold,
  underlined = true,
  children,
  download,
  'data-cy': dataCY
}) => {
  const linkCN = classNames({
    link: true,
    'link--bold': bold,
    'link--underline': underlined,
    'link--disabled': disabled,
    [className]: Boolean(className)
  });

  if (!to || (typeof to === 'string' && to.startsWith('http'))) {
    return (
      <a
        className={linkCN}
        href={to}
        target={target}
        onClick={onClick}
        tabIndex={tabIndex}
        data-cy={dataCY}
        download={download}
      >
        {label || children}
      </a>
    );
  } else {
    return (
      <ReactLink className={linkCN} to={to} target={target} onClick={onClick} tabIndex={tabIndex} data-cy={dataCY}>
        {label || children}
      </ReactLink>
    );
  }
};

Link.propTypes = {
  bold: PropTypes.bool,
  underlined: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  download: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  target: PropTypes.string
};

export default Link;
