import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardInfo } from 'components';

class TuitionExpressCC extends Component {
  static propTypes = {
    fee: PropTypes.string,
    onSetToken: PropTypes.func,
    token: PropTypes.string
  };

  @bind
  async onAddCard() {
    const { onSetToken } = this.props;

    try {
      const token = await Actions.showModal('TuitionExpressCard', { uriMethod: 'teAccountUri' });

      onSetToken(token);
    } catch (e) {
      // do nothing
    }
  }

  render() {
    const { fee, token, merchantIdPresent } = this.props;

    return (
      <div className="payments-panel__content">
        {merchantIdPresent && 
          <>
            <CardInfo token={token} onAdd={this.onAddCard} onEdit={this.onAddCard} />
            {fee && <div className="receive-payment__fee">Txn fee is ${fee}</div>}
          </>
        }
      </div>
    );
  }
}

export default TuitionExpressCC;
