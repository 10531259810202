import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import './style.scss';

class ActionButton extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    iconName: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    square: PropTypes.bool,
    circle: PropTypes.bool,
    hidden: PropTypes.bool,
    'data-cy': PropTypes.string
  };

  static defaultProps = {
    size: 18,
    hidden: false
  };

  @bind
  handleClick(e) {
    if (this.props.disabled) {
      return;
    }

    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  render() {
    const { title, iconName, size, color, className, disabled, hidden, square, circle, 'data-cy': dataCY } = this.props;

    const buttonCN = classNames({
      'action-button': true,
      'action-button--disabled': disabled,
      'action-button--square': square,
      'action-button--circle': circle,
      [className]: Boolean(className)
    });

    if (hidden || (!iconName && !title)) {
      return null;
    }

    return (
      <div
        className={buttonCN}
        onClick={this.handleClick}
        onMouseDown={e => e.preventDefault()}
        data-cy={dataCY}
        role="button"
      >
        {iconName && <Icon name={iconName} size={size} color={color} />}
        {title && <span className="action-button__title">{title}</span>}
      </div>
    );
  }
}

export default ActionButton;
