import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import Item from './Item';
import './style.scss';

class ItemList extends Component {
  static propTypes = {
    items: PropTypes.array,
    onChange: PropTypes.func
  };

  static defaultProps = {
    items: []
  };

  static createItem() {
    return { _id: v4(), value: v4(), label: '' };
  }

  constructor(props) {
    super(props);

    this.state = {
      items: props.items.length > 0 ? props.items.map(item => ({ ...item, _id: v4() })) : [ItemList.createItem()]
    };
  }

  @bind
  addItem() {
    const nextItems = [...this.state.items, ItemList.createItem()];
    this.setState({ items: nextItems }, this.handleChange);
  }

  @bind
  updateItem(id, label) {
    const itemIndex = this.state.items.findIndex(i => i._id === id);
    if (itemIndex === -1) {
      return;
    }

    const nextItems = [...this.state.items];
    nextItems[itemIndex] = {
      ...nextItems[itemIndex],
      label
    };

    this.setState({ items: nextItems }, this.handleChange);
  }

  @bind
  deleteItem(id) {
    if (this.state.items.length === 1) {
      return;
    }

    const itemIndex = this.state.items.findIndex(i => i._id === id);
    if (itemIndex === -1) {
      return;
    }

    const nextItems = [...this.state.items];
    nextItems.splice(itemIndex, 1);

    this.setState({ items: nextItems }, this.handleChange);
  }

  handleChange() {
    if (this.props.onChange) {
      this.props.onChange({ items: this.state.items.filter(i => Boolean(i.label)) });
    }
  }

  renderItems() {
    const { items } = this.state;
    const { disabled } = this.props;

    return items.map((item, index) => (
      <Item
        item={item}
        key={item._id}
        isLast={index === items.length - 1}
        onAdd={this.addItem}
        onChange={this.updateItem}
        onDelete={this.deleteItem}
        disabled={disabled}
      />
    ));
  }

  render() {
    return (
      <div className="form-field__item-list" data-cy="item-list">
        <div className="form-field__item-list__title">Add Answer Choices</div>

        {this.renderItems()}
      </div>
    );
  }
}

export default ItemList;
