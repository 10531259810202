import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonV2, Avatar } from 'components';
import { connect } from 'react-redux';
import './style.scss';

class ChangeRoom extends Component {
  static propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  @bind
  submit(values) {
    const { kid, reassign } = this.props.data;

    const payload = {
      kid_ids: [kid.id],
      current_section_id: values.current_section_id
    };

    this.setState({ loading: true }, () =>
      req
        .studentsBatchUpdate(payload)
        .then(() => {
          Actions.showFlash(reassign ? "Student's room successfully reassigned" : 'Room has been changed');
          this.props.onClose(true);
        })
        .catch(e => {
          Actions.reportError(reassign ? 'There was problem reassigning room' : 'Unable to change room', e);
          this.setState({ loading: false });
        })
    );
  }

  renderRooms() {
    return this.props.rooms.map(r => <Form.Select.Item key={r.id} value={r.id} label={r.name} />);
  }

  render() {
    const { loading } = this.state;
    const { kid, reassign } = this.props.data;

    return (
      <div className="change-room">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">{reassign ? 'Reassign' : 'Edit'} Room</div>

          <div className="change-room__kid">
            <Avatar
              name={kid.name}
              url={kid.profile_pic_url}
              initials={kid.initials}
              color={kid.color}
              className="change-room__kid-avatar"
            />

            <div className="change-room__kid-name">{kid.name}</div>
          </div>
        </div>

        <div className="modal__container">
          <Form onSubmit={this.submit}>
            <Form.Select
              required
              name="current_section_id"
              label="Room"
              title="Select Room"
              defaultValue={kid.current_section_id}
            >
              {this.renderRooms()}
            </Form.Select>

            <div className="modal__controls">
              <ButtonV2 secondary label="Cancel" onClick={() => this.props.onClose()} />
              <Form.Submit label={reassign ? 'Reassign' : 'Submit'} isLoading={loading} />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data
});

export default connect(mapStateToProps)(ChangeRoom);
