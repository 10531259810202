import modal from './modal';
import flashes from './flashes';
import cloak from './cloak';
import parentClassesState from './parentClassesState';
import profileEditors from './profileEditors';
import launchDarkly from './launchDarkly';

const reducers = {
  modal,
  flashes,
  cloak,
  parentClassesState,
  profileEditors,
  launchDarkly
};

export default reducers;
