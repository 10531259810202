import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ActionButton from 'components/ActionButton';
import Icon from 'components/Icon';
import { listenClick } from 'lib/utils';
import './style.scss';
import { DropdownContext } from 'hocs/withContext';

class Dropdown extends Component {
  static propTypes = {
    title: PropTypes.any,
    className: PropTypes.string,
    iconSize: PropTypes.number,
    onClear: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onClick: PropTypes.func,
    tabIndex: PropTypes.number,
    'data-cy': PropTypes.string
  };

  static defaultProps = {
    iconSize: 14
  };

  // A reference to the container.
  // Used to detect clicks outside of it.
  containerRef = React.createRef();

  constructor() {
    super();

    this.state = {
      isOpen: false
    };

    this.listenClick = this.listenClick.bind(this);
  }

  // Add or remove listener that closes the dropdown on click
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (!this.state.isOpen && nextState.isOpen) {
      setTimeout(() => document.addEventListener('click', this.listenClick), 250);
    }

    if (this.state.isOpen && !nextState.isOpen) {
      document.removeEventListener('click', this.listenClick);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.listenClick);
  }

  getDropdownContext() {
    return {
      toggle: this.toggle
    };
  }

  listenClick(e) {
    if (this.containerRef.current) {
      listenClick(e, this.containerRef.current, () => this.toggle(false));
    }
  }

  @bind
  handleClick(e) {
    if (this.props.disabled) {
      return;
    }

    const { onClick } = this.props;
    const { focus, isOpen } = this.state;

    if (onClick) {
      onClick(e);
    }

    if (focus) {
      this.setState({ focus: false });
    } else {
      this.toggle(!isOpen);
    }
  }

  @bind
  handleFocus() {
    if (this.props.tabIndex === undefined || this.props.disabled) {
      return;
    }

    const { focus } = this.state;

    if (!focus) {
      this.setState({ focus: true });
      this.toggle(true);
    }
  }

  @bind
  handleBlur(e) {
    if (this.props.tabIndex === undefined || e.relatedTarget === null) {
      return;
    }

    const { focus } = this.state;

    if (focus) {
      this.setState({ focus: false });
      this.toggle(false);
    }
  }

  @bind
  toggle(isOpen) {
    this.setState({ isOpen: typeof isOpen === 'boolean' ? isOpen : !this.state.isOpen }, () => {
      if (this.state.isOpen === false && this.props.onClose) {
        this.props.onClose();
      }

      if (this.state.isOpen && this.props.onOpen) {
        this.props.onOpen();
      }
    });
  }

  render() {
    const { title, className, disabled, children, onClear, tabIndex, iconSize, 'data-cy': dataCY } = this.props;

    const dropdownCN = classNames({
      dropdown: true,
      'dropdown--open': this.state.isOpen,
      'dropdown--disabled': disabled,
      [className]: Boolean(className)
    });

    const clearButtonCN = classNames('dropdown__header-clear', {
      'dropdown__header-clear--hidden': !onClear
    });

    return (
      <DropdownContext.Provider value={this.getDropdownContext()}>
        <div className={dropdownCN} ref={this.containerRef} data-cy={dataCY}>
          <div
            className="dropdown__header"
            tabIndex={tabIndex}
            onClick={this.handleClick}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          >
            <div className="dropdown__header-title">{title}</div>

            <Icon className="dropdown__header-arrow" name="chevron-down" size={iconSize} />
          </div>

          <ActionButton className={clearButtonCN} iconName="clear-circle" size={iconSize} onClick={onClear} />

          <div className="dropdown__body">{children}</div>
        </div>
      </DropdownContext.Provider>
    );
  }
}

export default Dropdown;
