import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import PlainCheckbox from '../../Checkbox';
import withContext, { FormContext } from 'hocs/withContext';

class CheckboxGroup extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      })
    )
  };

  static defaultProps = {
    options: []
  };

  componentDidMount() {
    const initialValue = this.props.defaultValue || [];
    const validations = {};

    this.props.context.init(this.props.name, initialValue, validations);
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disabled !== this.props.disabled && this.props.disabled === true) {
      this.props.context.update(this.props.name, []);
    }
  }

  getValidationText() {
    const { name } = this.props;
    const { errors } = this.props.context;

    if (errors[name]) {
      return errors[name].join('; ');
    }
  }

  @bind
  handleChange(selectedValue) {
    const { values } = this.props.context;
    const { name, maxSelection } = this.props;

    if (values[name].includes(selectedValue)) {
      this.props.context.update(
        this.props.name,
        values[name].filter(v => v !== selectedValue)
      );
    } else if (!maxSelection || values[name].length < maxSelection) {
      this.props.context.update(this.props.name, [...values[name], selectedValue]);
    }
  }

  render() {
    const { values } = this.props.context;
    const {
      className,
      name,
      options,
      type,
      size,
      disabled,
      reversed,
      tabIndex,
      required,
      'data-cy': dataCY
    } = this.props;
    const invalidText = this.getValidationText();

    return (
      <div className={cx('form-checkbox__group', className)}>
        {options.map(option => (
          <PlainCheckbox
            key={option.value}
            checked={values[name] && values[name].includes(option.value)}
            label={option.label}
            type={type}
            reversed={reversed}
            size={size}
            disabled={disabled || option.disabled}
            onChange={() => this.handleChange(option.value)}
            tabIndex={tabIndex}
            required={required}
            data-cy={dataCY}
          />
        ))}
        {invalidText && <div className="form-input__validation-text">{invalidText}</div>}
      </div>
    );
  }
}

export default withContext(FormContext)(CheckboxGroup);
