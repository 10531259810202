import React from 'react';
import { Timestamp } from 'components';
import get from 'lodash/get';
import PhotoThumb from '../PhotoThumb';

export default function TransferActivity(props) {
  const { activity } = props;
  return (
    <div className="activity__transfer">
      <div className="activity__paragraph">
        {`Transferred to ${get(activity, 'activiable.section.name')} @`}
        <Timestamp timestamp={get(activity, 'activiable.sign_in_time')} showDate={false} />
      </div>
      <PhotoThumb photoUrl={activity.photo_url} />
    </div>
  );
}
