import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ActionButton, Icon } from 'components';

export class Step1 extends Component {
  static propTypes = {
    method: PropTypes.string,
    subKind: PropTypes.string,
    onSelectMethod: PropTypes.func,
    isSchool: PropTypes.bool,
    isCarer: PropTypes.bool,
    currentSchool: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    if (this.props.isCarer) {
      req.parentFeeBreakdown();
    }
  }

  @bind
  onSelectMethod(method, subKind = '') {
    if (method === 'card' && this.isMethodDisabled('cc')) {
      return;
    }

    if (method === 'ach' && this.isMethodDisabled('ba')) {
      return;
    }

    this.props.onSelectMethod(method, subKind);
  }

  isMethodDisabled(method) {
    // @NOTE For now ACH is ALWAYS disabled on registration
    if (this.props.isRegister && method === 'ba') {
      return true;
    }

    if (!this.props.isCarer) {
      return false;
    }

    const paymentMethod = this.props.currentSchool.parent_payment_method;
    return paymentMethod !== 'parent_payment_method_any' && paymentMethod !== `parent_payment_method_${method}`;
  }

  render() {
    const { feeBreakdown, isCarer, currentSchool, isRegister } = this.props;
    const isCreditCardEnabled = !this.isMethodDisabled('cc');
    const isAchEnabled = !this.isMethodDisabled('ba');

    const hasFee = currentSchool.parent_payment_fee_paid_by === 'parent_payment_fee_paid_by_parent';

    return (
      <div className="table payments-panel">
        <div className="table__header payments-panel__header">
          <ActionButton iconName="back" size={20} onClick={this.props.goToList} />
          <div className="payments-panel__title">Choose Payment</div>
        </div>

        <div className="table__body">
          <div className="table__row payments-panel__body">
            <div className="payments-panel__content">
              <div className="path-selector">
                {!isRegister && isCreditCardEnabled && (
                  <div className="path-selector__option" onClick={this.onSelectMethod.bind(this, 'card', 'debit')}>
                    <div className="path-selector__option-inner">Add Debit Card</div>
                  </div>
                )}

                {isCreditCardEnabled && (
                  <div className="path-selector__option" onClick={this.onSelectMethod.bind(this, 'card', 'credit')}>
                    <div className="path-selector__option-inner">
                      {isRegister ? 'Pay by Credit Card' : 'Add Credit Card'}

                      {isCarer && feeBreakdown.cc && hasFee && (
                        <div className="path-selector__option-subheader path-selector__option-subheader--fee">
                          {`Transaction fee is ${feeBreakdown.cc}`}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {isAchEnabled && (
                  <div className="path-selector__option" onClick={this.onSelectMethod.bind(this, 'ach', '')}>
                    <div className="path-selector__option-inner">
                      {isRegister ? 'Pay through Bank' : 'Add ACH Account'}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="payments-panel__notice">
              <div className="payments-panel__notice-icon">
                <Icon name="verify" color="#8cd527" size={24} />
              </div>
              <div className="payments-panel__notice-text">
                Procare has the highest levels of PCI compliance for online payments.
                {!hasFee && (
                  <div className="payments-panel__notice-desc">
                    * No Procare platform fee. School may charge a processing fee. Please check with your school.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  constants: state.constants.data,
  currentSchool: state.currentUser.data.current_school,
  isCarer: state.currentUser.data.current_role === 'carer',
  feeBreakdown: state.feeBreakdown.data
});

export default connect(mapStateToProps)(Step1);
