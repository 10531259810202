import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon, ButtonV2 } from 'components';
import SelectSchoolList from 'modals/common/SelectSchoolList';
import SchoolFilter from './SchoolFilter';
import './style.scss';

class SelectCorpTeacherSchools extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: {
        search: '',
        selectedStates: []
      },
      selectedSchoolIds: props.data.selectedSchoolIds,
      saving: false
    };
  }

  @bind
  async handleSubmit() {
    const { selectedSchoolIds } = this.state;
    const { staff } = this.props.data;

    this.setState({ saving: true });
    try {
      await req.updateStaffGroupSchools({
        id: staff.id,
        school_ids: selectedSchoolIds
      });
      this.props.onClose();
    } catch (e) {
      this.setState({ saving: false });
      Actions.reportError('There was problem saving schools', e);
    }
  }

  @bind
  handleSchoolSelect(selectedSchoolIds) {
    this.setState({ selectedSchoolIds });
  }

  @bind
  updateFilters(filters) {
    this.setState({ filters });
  }

  @bind
  removeState(state) {
    const { selectedStates } = this.state.filters;

    this.updateFilters({
      ...this.state.filters,
      selectedStates: selectedStates.filter(s => s !== state)
    });
  }

  renderSelectedStates() {
    const { selectedStates } = this.state.filters;

    if (!selectedStates.length) return null;

    return (
      <div className="change-schools__selected-states-container">
        {selectedStates.map(state => (
          <span
            className="change-schools__selected-state"
            key={`state-${state}`}
            data-cy={`selected-state-${state}`}
            onClick={() => this.removeState(state)}
          >
            {state}
            <Icon name="close" size={10} />
          </span>
        ))}
      </div>
    );
  }

  get schools() {
    const { schools } = this.props.data;
    const { search, selectedStates } = this.state.filters;

    const term = (search || '').toLowerCase().trim();

    return schools.filter(school => {
      const schoolName = school.name.toLowerCase().trim();
      const schoolState = school.state.toLowerCase().trim();

      const matchesSearch = term.length === 0 || schoolName.indexOf(term) >= 0;
      const matchesState =
        !selectedStates || selectedStates.length === 0 || selectedStates.some(state => state === schoolState);

      return matchesSearch && matchesState;
    });
  }

  get stateList() {
    const { schools } = this.props.data;
    const stateList = schools.map(school => school.state.toLowerCase().trim());

    return [...new Set(stateList.filter(Boolean))];
  }

  render() {
    const { filters, selectedSchoolIds, saving } = this.state;

    return (
      <div className="modal__wrapper change-schools">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Select Schools</div>

          <div className="change-schools__schools-filter-container">
            <SchoolFilter stateList={this.stateList} filters={filters} onChange={this.updateFilters} />
          </div>

          {this.renderSelectedStates()}
        </div>

        <div className="modal__container">
          <SelectSchoolList
            schools={this.schools}
            allSchools={this.schools}
            selectedIds={selectedSchoolIds}
            onSelect={this.handleSchoolSelect}
            listHeight={438}
            type="school"
          />
        </div>

        <div className="modal__controls">
          <ButtonV2 label="Done" onClick={this.handleSubmit} isLoading={saving} data-cy="done" />
        </div>
      </div>
    );
  }
}

export default SelectCorpTeacherSchools;
