import React from 'react';
import PhotoThumb from '../PhotoThumb';

const ObservationActivity = ({ activity }) => {
  const indicatorsWithoutMeasures = activity.indicators.filter(
    i => !activity.measures?.some(m => i.measure_id === m.id)
  );
  return (
    <div className="activity__observation">
      <div className="activity__paragraph">{activity.data.desc}</div>
      <div className="activity__paragraph">
        {activity.measures?.length > 0 &&
          activity.measures.map(measure => (
            <div key={measure.id}>
              <h1>
                <em>{measure.standard.short_name}</em> {measure.standard.name}
              </h1>
              <h2>
                {measure.standard.short_name} {measure.name} {measure.description}
              </h2>
              {activity.indicators
                .filter(i => i.measure_id === measure.id)
                .map(indicator => (
                  <h3>
                    {measure.standard.short_name} {indicator.name} {indicator.description}
                  </h3>
                ))}
            </div>
          ))}
        {indicatorsWithoutMeasures.length > 0 &&
          indicatorsWithoutMeasures.map(indicator => (
            <h3>
              {indicator.name} {indicator.description}
            </h3>
          ))}
      </div>
      <PhotoThumb photoUrl={activity.photo_url} />
    </div>
  );
};

export default ObservationActivity;
