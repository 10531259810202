import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UploadFile from '../../UploadFile';
import withContext, { FormContext } from 'hocs/withContext';

class FormUploadFile extends Component {
  static propTypes = {
    name: PropTypes.string,
    defaultValue: PropTypes.array,
    label: PropTypes.string
  };

  UNSAFE_componentWillMount() {
    const { name, defaultValue } = this.props;
    this.props.context.init(name, defaultValue, []);
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  @bind
  handleChange(value) {
    this.props.context.update(this.props.name, value);
  }

  @bind
  onAdd(file) {
    const { values } = this.props.context;
    const nextFiles = (values[this.props.name] || []).concat([file]);
    this.onFinishUpload();
    this.handleChange(nextFiles);
  }

  @bind
  onDelete(id) {
    const { values } = this.props.context;

    const nextFiles = (values[this.props.name] || []).filter(f => f.id !== id);

    this.handleChange(nextFiles);
  }

  @bind
  onBeginUpload() {
    this.props.context.addPendingEffects(1);
  }

  @bind
  onFinishUpload() {
    this.props.context.addPendingEffects(-1);
  }

  render() {
    const { values } = this.props.context;
    const { name } = this.props;

    return (
      <UploadFile
        {...this.props}
        onAdd={this.onAdd}
        onDelete={this.onDelete}
        onBegin={this.onBeginUpload}
        onRestart={this.onFinishUpload}
        value={values[name] || []}
      />
    );
  }
}

export default withContext(FormContext)(FormUploadFile);
