import { getSubscriptionAccessLevel } from 'lib/utils';

const showPremiumModal = store => (shouldStay, accessLevel) => {
  const state = store.getState();
  const currentSchool = state.currentUser ? state.currentUser.data.current_school : null;

  if (!currentSchool) {
    return;
  }

  const modals = state.modal.modals;
  const isPremiumModalOpen = modals.find(m => m.isVisible && m.component === 'Premium');

  if (isPremiumModalOpen) {
    return;
  }

  Actions.showModal(
    'Premium',
    {
      shouldStay,
      accessLevel: accessLevel || getSubscriptionAccessLevel(currentSchool)
    },
    {
      className: 'modal--dark-top'
    }
  );
};

export default showPremiumModal;
