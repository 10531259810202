import React, { Component } from 'react';
import { ButtonV2 } from 'components';
import currency from 'lib/currency';
import './style.scss';

class ConfirmStep extends Component {
  render() {
    const { price, payment, onSubmit } = this.props;

    return (
      <div className="modal__wrapper school-registration-payment__payment">
        <div className="modal__header">
          <span className="modal__header-title">Confirm Payment</span>
        </div>
        <div className="modal__container">
          <div className="school-registration-payment__pricing">
            <div className="school-registration-payment__pricing-top">
              <span className="school-registration-payment__pricing-price">{currency.getPrice(price)}</span>
              <span className="school-registration-payment__pricing-desc">The total amount you need to pay</span>
            </div>
            <div className="school-registration-payment__payment-bottom">
              <div className="school-registration-payment__payment-bank">
                <div className="school-registration-payment__payment-bank__description">
                  Your {payment.token_type === 'card' ? 'Credit Card' : 'Bank Account'} is connected with us. Click
                  on&nbsp;
                  <span className="school-registration-payment__payment-bank__description-strong">
                    "Pay and Register"
                  </span>
                  &nbsp;to finish the registration process.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal__controls">
          <span className="school-registration-payment__term">
            By signing-up with us you are agreeing to our
            <a
              href="https://www.procaresoftware.com/terms-of-use/procare-solutions-terms-of-use/"
              target="_blank"
              className="school-registration-payment__term-link"
            >
              {' '}
              Terms & Conditions
            </a>
          </span>
          <ButtonV2 label="Pay and Register" onClick={onSubmit} />
        </div>
      </div>
    );
  }
}

export default ConfirmStep;
