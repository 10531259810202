import { method } from 'lodash';
import transforms from './transforms';
import SchoolRegistrationSerializer from 'lib/serializers/SchoolRegistrationSerializer';

const apiRoutes = {
  authorize: {
    url: '/auth',
    method: 'post',
    transformResponse: transforms.currentUser,
    reducer: {
      name: 'currentUser',
      initialState: {}
    }
  },

  impersonate: {
    url: '/impersonate',
    method: 'post',
    transformResponse: transforms.currentUser,
    reducer: {
      name: 'currentUser',
      initialState: {}
    }
  },

  impersonate_token: {
    url: '/impersonate_token',
    method: 'post'
  },

  selectRole: {
    url: '/auth/school',
    method: 'post',
    transformResponse: transforms.currentUser,
    reducer: {
      name: 'currentUser',
      initialState: {}
    }
  },

  requestPasswordReset: {
    url: '/passwords',
    method: 'post'
  },

  resetPassword: {
    url: '/passwords',
    method: 'patch'
  },

  parentSignUp: {
    url: '/parent/register',
    method: 'post',
    transformResponse: transforms.currentUser,
    reducer: {
      name: 'currentUser',
      initialState: {}
    }
  },

  createSchool: {
    url: '/school/register',
    method: 'post',
    transformResponse: transforms.currentUser,
    reducer: {
      name: 'currentUser',
      initialState: {}
    }
  },

  teacherSignUp: {
    url: '/teacher/register',
    method: 'post',
    transformResponse: transforms.currentUser,
    reducer: {
      name: 'currentUser',
      initialState: {}
    }
  },

  logout: {
    url: '/logout',
    method: 'delete'
  },

  changeSchool: {
    url: '/school/change',
    method: 'post',
    transformResponse: res => res.user
  },

  constants: {
    url: '/list_options',
    transformResponse: res => res.list_options,
    reducer: {
      name: 'constants',
      initialState: {}
    }
  },

  studentsExport: {
    url: '/school/kids.html'
  },

  qrcode: {
    url: 'school/siso/codes',
    method: 'post'
  },

  students: {
    url: '/school/kids',
    transformResponse: res => res.kids,
    reducer: {
      name: 'students',
      initialState: []
    }
  },

  studentsWithoutCache: {
    url: '/school/kids',
    transformResponse: res => res.kids
  },

  studentsByFamily: {
    url: '/school/kids/family',
    transformResponse: res => res.kids
  },

  studentsBatchUpdate: {
    url: '/school/kids/batch_update',
    transformResponse: res => res.kids,
    method: 'post',
    reducer: {
      name: 'students',
      type: 'batchUpdate'
    }
  },

  studentsBatchDelete: {
    url: '/school/kids/batch_delete',
    method: 'post',
    reducer: {
      name: 'students',
      type: 'batchDelete'
    }
  },

  enrollmentPdf: {
    url: 'school/kids/enrollment_pdf',
    method: 'get'
  },

  student: {
    url: '/school/kids/:id',
    transformResponse: res => res.kid,
    reducer: {
      name: 'student',
      initialState: {}
    }
  },

  updateStudent: {
    url: '/school/kids/:id',
    method: 'patch',
    transformResponse: res => res.kid,
    reducer: {
      name: 'student'
    }
  },

  updateStudentAvatar: {
    url: '/school/kids/:id',
    method: 'patch',
    transformResponse: res => res.kid
  },

  addStudentLink: {
    url: '/school/kids/:id/link',
    method: 'post'
  },

  removeStudentLink: {
    url: '/school/kids/:id/unlink',
    method: 'post'
  },

  rooms: {
    url: '/school/sections',
    transformResponse: res => res.sections,
    reducer: {
      name: 'rooms',
      initialState: []
    }
  },

  room: {
    url: '/school/sections/:id',
    transformResponse: res => res.section,
    reducer: {
      name: 'room',
      initialState: null
    }
  },

  updateRoom: {
    url: '/school/sections/:id',
    method: 'patch',
    transformResponse: res => res.section,
    reducer: {
      name: 'rooms',
      type: 'patchItem'
    }
  },

  updateRoomDashboardSettings: {
    url: '/school/sections/:id/dashboard_settings',
    method: 'patch',
    transformResponse: res => res.section,
    reducer: {
      name: 'rooms',
      type: 'patchItem'
    }
  },

  updateRoomCapacity: {
    url: '/school/sections/:id/update_capacity',
    method: 'patch',
    transformResponse: res => res.section,
    reducer: {
      name: 'rooms',
      type: 'patchItem'
    }
  },

  updateAgeRange: {
    url: '/school/sections/:id/update_age_range',
    method: 'patch',
    transformResponse: res => res.section,
    reducer: {
      name: 'rooms',
      type: 'patchItem'
    }
  },

  addRoom: {
    url: '/school/sections/',
    method: 'post',
    transformResponse: res => res.section,
    reducer: {
      name: 'rooms',
      type: 'add'
    }
  },

  deleteRoom: {
    url: '/school/sections/:id',
    method: 'delete',
    reducer: {
      name: 'rooms',
      type: 'removeItem'
    }
  },

  addRoomStaff: {
    url: '/school/sections/:id/add_staff',
    method: 'post'
  },

  schoolEvents: {
    url: '/school/events',
    transformResponse: res => res.events,
    reducer: {
      name: 'schoolEvents',
      initialState: []
    }
  },

  loadSchoolEventItem: {
    method: 'get',
    url: '/school/events/:id',
    transformResponse: res => res.event
  },

  loadSchoolEvents: {
    url: '/school/events',
    transformResponse: res => res.events
  },

  updateSchoolEvent: {
    url: '/school/events/:id',
    method: 'patch',
    transformResponse: res => res.event,
    reducer: {
      name: 'schoolEvents',
      type: 'patchItem'
    }
  },

  addSchoolEvent: {
    url: '/school/events/',
    method: 'post',
    transformResponse: res => res.event,
    reducer: {
      name: 'schoolEvents',
      type: 'add'
    }
  },

  deleteSchoolEvent: {
    url: '/school/events/:id',
    method: 'delete',
    reducer: {
      name: 'schoolEvents',
      type: 'removeItem'
    }
  },

  activateRoom: {
    url: '/school/sections/:id/activate',
    method: 'post',
    transformResponse: res => res.section,
    reducer: {
      name: 'rooms',
      type: 'removeItem'
    }
  },

  dashboard: {
    url: '/school/dashboard',
    transformResponse: res => res.counters,
    reducer: {
      name: 'dashboard',
      initialState: {}
    }
  },

  liveRoomKids: {
    url: '/school/kids/live_room',
    transformResponse: res => res.kids
  },

  liveRoomTeachers: {
    url: '/school/teachers/live_room',
    transformResponse: res => res.teachers
  },

  groupSchools: {
    url: '/school/dashboard/group_schools',
    reducer: {
      name: 'groupSchools',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        group_schools: [],
        stats: {}
      }
    }
  },

  groupSchoolList: {
    url: '/school/group_schools',
    transformResponse: res => res.group_schools,
    reducer: {
      name: 'groupSchoolList',
      initialState: []
    }
  },

  listSchools: {
    url: '/user/schools',
    method: 'get'
  },

  addStudent: {
    url: '/school/kids',
    method: 'post',
    transformResponse: res => ({ ...res.kid, sibling: res.sibling }),
    reducer: {
      name: 'students',
      type: 'add'
    }
  },

  linkStudents: {
    url: '/school/kids/:id/link',
    method: 'post',
    transformResponse: res => res.kid,
    reducer: {
      name: 'students',
      type: 'patchItem'
    }
  },

  deleteStudent: {
    url: '/school/kids/:id',
    method: 'delete',
    reducer: {
      name: 'students',
      type: 'removeItem'
    }
  },

  staff: {
    url: '/school/teachers',
    transformResponse: res => res.teachers,
    reducer: {
      name: 'staff',
      initialState: []
    }
  },

  staffPaginated: {
    url: '/school/teachers',
    reducer: {
      name: 'staffPaginated',
      initialState: {
        teachers: []
      }
    }
  },

  staffWithoutCache: {
    url: '/school/teachers',
    transformResponse: res => res.teachers
  },

  allStaff: {
    url: '/school/teachers',
    transformResponse: res => res.teachers,
    reducer: {
      name: 'allStaff',
      initialState: []
    }
  },

  staffItem: {
    url: '/school/teachers/:id',
    transformResponse: res => res.teacher,
    reducer: {
      name: 'staffItem',
      initialState: {}
    }
  },

  corpAccessSchools: {
    url: '/school/teachers/:id/corp_schools',
    transformResponse: res => res.corp_access_schools,
    reducer: {
      name: 'corpAccessSchools',
      initialState: []
    }
  },

  addStaff: {
    url: '/school/teachers',
    method: 'post',
    transformResponse: res => res.teacher,
    reducer: [
      {
        name: 'staff',
        type: 'add'
      },
      {
        name: 'staffPaginated',
        type: 'add',
        options: {
          collectionName: 'teachers'
        }
      }
    ]
  },

  updateStaff: {
    url: '/school/teachers/:id',
    method: 'patch',
    transformResponse: res => res.teacher,
    reducer: {
      name: 'staff',
      type: 'patchItem'
    }
  },

  updateStaffItem: {
    url: '/school/teachers/:id',
    method: 'patch',
    transformResponse: res => res.teacher,
    reducer: {
      name: 'staffItem'
    }
  },

  staffPermissions: {
    url: '/school/permissions/:id',
    transformResponse: res => res.permissions,
    reducer: {
      name: 'staffPermissions'
    }
  },

  updateStaffPermissions: {
    url: '/school/permissions/:id',
    method: 'patch',
    transformResponse: res => res.permissions,
    reducer: {
      name: 'staffPermissions'
    }
  },

  updateStaffGroupSchools: {
    url: '/school/teachers/:id/corp_schools/batch_update',
    method: 'post',
    transformResponse: res => res.corp_access_schools,
    reducer: {
      name: 'corpAccessSchools'
    }
  },

  updateStaffAvatar: {
    url: '/school/teachers/:id',
    method: 'patch',
    transformResponse: res => res.teacher
  },

  activateStaff: {
    url: '/school/teachers/:id/activate',
    method: 'post',
    transformResponse: res => res.teacher,
    reducer: {
      name: 'staff',
      type: 'removeItem'
    }
  },

  deleteStaff: {
    url: '/school/teachers/:id',
    method: 'delete',
    reducer: {
      name: 'staff',
      type: 'removeItem'
    }
  },

  shifts: {
    url: '/school/shifts/schedule',
    method: 'get',
    reducer: {
      name: 'shifts',
      initialState: {
        teachers: [],
        shifts: []
      }
    }
  },

  unassignedShifts: {
    url: '/school/shifts',
    method: 'get',
    transformResponse: res => res.shifts,
    reducer: {
      name: 'unassignedShifts',
      initialState: []
    }
  },

  addStaffShift: {
    url: '/school/shifts',
    method: 'post',
    transformResponse: res => res.shift
  },

  editStaffShift: {
    url: '/school/shifts/:id',
    method: 'patch',
    transformResponse: res => res.shift
  },

  deleteStaffShift: {
    url: '/school/shifts/:id',
    method: 'delete'
  },

  copyShifts: {
    url: '/school/shifts/copy',
    method: 'post'
  },

  previewShifts: {
    url: '/school/shifts/pdf',
    method: 'get',
    transformResponse: res => res.pdf
  },

  addCarer: {
    url: '/school/carers',
    method: 'post'
  },

  updateCarer: {
    url: '/school/carers/:id',
    method: 'patch'
  },

  deleteCarer: {
    url: '/school/carers/:id',
    method: 'delete'
  },

  regenerateCarerPin: {
    url: '/school/carers/:id/generate_pin',
    method: 'patch'
  },

  parentAddCarer: {
    url: '/parent/carers',
    method: 'post'
  },

  parentUpdateCarer: {
    url: '/parent/carers/:id',
    method: 'patch'
  },

  parentDeleteCarer: {
    url: '/parent/carers/:id',
    method: 'delete'
  },

  parentInviteCarer: {
    url: '/parent/carers/:id/invite',
    method: 'post'
  },

  getCarerPin: {
    url: '/school/carers/pin'
  },

  parentGetCarerPin: {
    url: '/parent/carers/pin'
  },

  plaidCreateLinkToken: {
    url: '/plaid/link_tokens',
    transformResponse: res => res.link_token,
    method: 'post'
  },

  tags: {
    url: '/school/tags',
    transformResponse: res => res.tags,
    reducer: {
      name: 'tags',
      initialState: []
    }
  },

  addTag: {
    url: '/school/tags',
    method: 'post',
    transformResponse: res => res.tag,
    reducer: {
      name: 'tags',
      type: 'add'
    }
  },

  updateTag: {
    url: '/school/tags/:id',
    method: 'patch',
    transformResponse: res => res.tag,
    reducer: {
      name: 'tags',
      type: 'patchItem'
    }
  },

  deleteTag: {
    url: '/school/tags/:id',
    method: 'delete',
    reducer: {
      name: 'tags',
      type: 'removeItem'
    }
  },

  sisoDeliveries: {
    url: '/school/siso/kids/:siso_kid_id/deliveries',
    method: 'get'
  },

  batchSignIn: {
    url: '/school/siso/kids/batch_signin',
    method: 'post',
    transformResponse: res => res.signins,
    reducer: {
      name: 'batch'
    }
  },

  batchSignOut: {
    url: '/school/siso/kids/batch_signout',
    method: 'post',
    transformResponse: res => res.signins,
    reducer: {
      name: 'batch'
    }
  },

  sisoKidsDaily: {
    url: '/school/siso/kids',
    reducer: {
      name: 'sisoKidsDaily',
      initialState: {
        kids: [],
        siso_kids: {}
      }
    }
  },

  sisoKidsPDF: {
    url: '/school/siso/kids/siso_pdf'
  },

  sisoKidsDailyExport: {
    url: '/school/siso/kids.html'
  },

  sisoKidsMonthly: {
    url: '/school/siso/kids/monthly.json',
    reducer: {
      name: 'sisoKidsMonthly',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        stats: {},
        kids: []
      }
    }
  },

  sisoKidsMonthlyExport: {
    url: '/school/siso/kids/monthly.html'
  },

  sisoKidsWeekly: {
    url: '/school/siso/kids/weekly.json',
    reducer: {
      name: 'sisoKidsMonthly',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        stats: {},
        kids: []
      }
    }
  },

  sisoKidsWeeklyExport: {
    url: '/school/siso/kids/weekly.html'
  },

  sisoKidsBiweekly: {
    url: '/school/siso/kids/biweekly',
    reducer: {
      name: 'sisoKidsMonthly',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        stats: {},
        kids: []
      }
    }
  },

  sisoKidsBiweeklyExport: {
    url: '/school/siso/kids/biweekly.html'
  },

  sisoKidsSignins: {
    url: '/school/siso/kids/signins.json',
    reducer: {
      name: 'sisoKidsSignins',
      initialState: {
        signins: [],
        stats: {}
      }
    }
  },

  // used in IndividualSignins, rename only in common with `sisoKidsSignins`
  sisoKidsSigninsExport: {
    url: '/school/siso/kids/signins.html'
  },

  sisoKidsBatchUpdate: {
    url: '/school/siso/kids/batch_update',
    method: 'post',
    transformResponse: res => res.kids,
    reducer: {
      name: 'sisoKids'
    }
  },

  sisoKidsBatchDelete: {
    url: '/school/siso/kids/batch_delete',
    method: 'post'
  },

  deleteKidClockIn: {
    url: '/school/siso/kids/:id',
    method: 'delete',
    reducer: {
      name: 'deleteKidClockIn'
    }
  },

  editKidClockIn: {
    url: '/school/siso/kids/:id',
    method: 'patch',
    reducer: {
      name: 'editKidClockIn'
    }
  },

  sisoTeachersDaily: {
    url: '/school/siso/teachers.json',
    reducer: {
      name: 'sisoTeachersDaily',
      initialState: {
        teachers: [],
        siso_teachers: {}
      }
    }
  },

  sisoTeachersDailyExport: {
    url: '/school/siso/teachers.html'
  },

  createClockIn: {
    url: '/school/siso/teachers',
    method: 'post',
    transformResponse: res => res.siso_teacher,
    reducer: {
      //TODO: rename to sisoTeachersDaily and create type 'addSisoItem' for collection update
      name: 'createClockIn'
    }
  },

  editStaffClockIn: {
    url: '/school/siso/teachers/:id',
    method: 'patch',
    transformResponse: res => res.siso_teacher,
    reducer: {
      name: 'sisoTeachersDaily',
      type: 'patchSisoItem'
    }
  },

  deleteStaffClockIn: {
    url: '/school/siso/teachers/:id',
    method: 'delete',
    reducer: {
      //TODO: rename to sisoTeachersDaily and create type 'removeSiSoItem' for collection update
      name: 'removeSisoTeachersDaily'
    }
  },

  sisoTeachersMonthly: {
    url: '/school/siso/teachers/monthly.json',
    reducer: {
      name: 'sisoTeachersMonthly',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        teachers: [],
        stats: {}
      }
    }
  },

  sisoTeachersMonthlyExport: {
    url: '/school/siso/teachers/monthly.html'
  },

  sisoTeachersBiweeklyExport: {
    url: '/school/siso/teachers/biweekly.html'
  },

  sisoTeachersWeeklyExport: {
    url: '/school/siso/teachers/weekly.html'
  },

  sisoTeachersWeekly: {
    url: '/school/siso/teachers/weekly.json',
    reducer: {
      name: 'sisoTeachersMonthly',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        teachers: [],
        stats: {}
      }
    }
  },

  sisoTeachersBiweekly: {
    url: '/school/siso/teachers/biweekly.json',
    reducer: {
      name: 'sisoTeachersMonthly',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        teachers: [],
        stats: {}
      }
    }
  },

  sisoTeachersSignins: {
    url: '/school/siso/teachers/signins.json',
    reducer: {
      name: 'sisoTeachersSignins',
      initialState: {
        signins: [],
        stats: {}
      }
    }
  },

  sisoTeachersSigninsExport: {
    url: '/school/siso/teachers/signins.html'
  },

  studentStaffRatio: {
    url: '/school/siso/ratios',
    reducer: {
      name: 'studentStaffRatio',
      initialState: {
        ratios: [],
        sections: []
      }
    }
  },

  studentStaffRatioExport: {
    url: '/school/siso/ratios.html'
  },

  roomsFTEStats: {
    url: '/school/analytics/rooms_fte_stats'
  },

  reports: {
    url: '/school/reports',
    transformResponse: res => res.reports,
    reducer: {
      name: 'reports',
      initialState: []
    }
  },

  approveMessage: {
    url: '/school/messages/:id/approve',
    method: 'post'
  },

  deleteMessage: {
    url: '/school/messages/:id',
    method: 'delete',
    reducer: {
      name: 'deleteMessage'
    }
  },

  updateReportStatus: {
    url: '/school/reports/:id',
    transformResponse: res => res.report,
    reducer: {
      name: 'reports',
      type: 'updateReportStatus'
    }
  },

  createReport: {
    url: '/school/reports',
    method: 'post',
    transformResponse: res => res.report,
    reducer: {
      name: 'reports',
      type: 'addOrPatchItem'
    }
  },

  allTransactions: {
    url: '/school/billing/billing_transactions',
    reducer: {
      name: 'allTransactions',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        billing_transactions: []
      }
    }
  },

  allTransactionsExport: {
    url: '/school/billing/billing_transactions.html'
  },

  familyBilling: {
    url: '/school/billing/families',
    reducer: {
      name: 'familyBilling',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        families: []
      }
    }
  },

  familyLeadCrmTransactions: {
    url: '/school/registrations/parent_payments/families/:id',
    reducer: {
      name: 'familyLeadCrmTransactions',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        parent_payments: [],
        parent_refunds: [],
        enrollments: []
      }
    }
  },

  familyBillingStats: {
    url: '/school/billing/families/:id',
    transformResponse: res => res.family,
    reducer: {
      name: 'familyBillingStats',
      initialState: {
        billing_stats: {}
      }
    }
  },

  familyLeadCrmStats: {
    url: '/school/registrations/parent_payments/families/:id/stats',
    reducer: {
      name: 'familyLeadCrmStats',
      initialState: {
        billing_stats: {}
      }
    }
  },

  billingStats: {
    url: '/school/billing/billing_transactions/stats',
    transformResponse: res => res.billing_stats,
    reducer: {
      name: 'billingStats',
      initialState: {}
    }
  },

  subFamilies: {
    url: '/school/billing/families/:family_id/sub_families',
    transformResponse: res => res.sub_families,
    reducer: {
      name: 'subFamilies',
      initialState: []
    }
  },

  createSubFamily: {
    url: '/school/billing/families/:family_id/sub_families',
    method: 'POST',
    transformResponse: res => res.sub_family,
    cancellable: false
  },

  updateSubFamily: {
    url: '/school/billing/families/:family_id/sub_families/:id',
    method: 'PATCH',
    transformResponse: res => res.sub_family,
    cancellable: false
  },

  removeSubFamily: {
    url: '/school/billing/families/:family_id/sub_families/:id',
    method: 'DELETE',
    transformResponse: res => res.sub_family,
    cancellable: false
  },

  removeSubFamilies: {
    url: '/school/billing/families/:family_id/sub_families/destroy_all',
    method: 'DELETE',
    transformResponse: res => res.sub_family
  },

  addSubFamilyKid: {
    url: '/school/billing/families/:family_id/sub_families/:id/add_kid',
    method: 'PUT'
  },

  removeSubFamilyKid: {
    url: '/school/billing/families/:family_id/sub_families/:id/remove_kid',
    method: 'DELETE'
  },

  addSubFamilyCarer: {
    url: '/school/billing/families/:family_id/sub_families/:id/add_carer',
    method: 'PUT'
  },

  removeSubFamilyCarer: {
    url: '/school/billing/families/:family_id/sub_families/:id/remove_carer',
    method: 'DELETE'
  },

  school: {
    url: '/school',
    transformResponse: res => res.school,
    reducer: {
      name: 'school',
      initialState: {}
    }
  },

  updateSchool: {
    url: '/school',
    method: 'patch',
    transformResponse: res => res.school,
    reducer: {
      name: 'school'
    }
  },

  schoolSettings: {
    url: '/school/settings',
    transformResponse: res => res.settings,
    reducer: {
      name: 'schoolSettings',
      initialState: []
    }
  },

  updateSchoolSettings: {
    url: '/school/settings',
    method: 'patch',
    transformResponse: res => res.settings,
    reducer: {
      name: 'schoolSettings' // API returns full list on patch, we can replace data
    }
  },

  customAttributes: {
    url: '/custom_attributes',
    transformResponse: res => res.custom_attributes,
    reducer: {
      name: 'customAttributes',
      initialState: []
    }
  },

  createCustomAttribute: {
    url: '/custom_attributes',
    method: 'post',
    transformResponse: res => res.custom_attribute,
    reducer: {
      name: 'customAttributes',
      type: 'add'
    }
  },

  updateCustomAttribute: {
    url: '/custom_attributes/:id',
    method: 'patch',
    transformResponse: res => res.custom_attribute,
    reducer: {
      name: 'customAttributes',
      type: 'patchItem'
    }
  },

  deleteCustomAttribute: {
    url: '/custom_attributes/:id',
    method: 'delete',
    reducer: {
      name: 'customAttributes',
      type: 'removeItem'
    }
  },

  activateCustomAttribute: {
    url: '/custom_attributes/:id',
    method: 'patch',
    transformResponse: res => res.custom_attribute,
    reducer: {
      name: 'customAttributes',
      type: 'removeItem'
    }
  },

  createInvoice: {
    url: '/school/billing/parent_invoices',
    method: 'post'
  },

  editInvoice: {
    url: '/school/billing/parent_invoices/:id',
    method: 'patch'
  },

  viewInvoice: {
    url: '/school/billing/parent_invoices/:id',
    transformResponse: res => res.parent_invoice
  },

  familyTransactions: {
    url: '/school/billing/billing_transactions/family',
    reducer: {
      name: 'familyTransactions',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        billing_transactions: [],
        kids: [],
        carers: []
      }
    }
  },

  familyTransactionsNoReducer: {
    url: '/school/billing/billing_transactions/family'
  },

  ledger: {
    url: '/school/ledgers/:id',
    transformResponse: res => res.ledger,
    reducer: {
      name: 'ledger',
      initialState: {}
    }
  },

  ledgers: {
    url: '/school/ledgers',
    reducer: {
      name: 'ledgers',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        ledgers: []
      }
    }
  },

  deactivatedLedgers: {
    url: '/school/ledgers',
    reducer: {
      name: 'deactivatedLedgers',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        ledgers: []
      }
    }
  },

  activateLedger: {
    url: '/school/ledgers/:id',
    method: 'patch',
    transformResponse: res => res.ledger,
    reducer: {
      name: 'deactivatedLedgers',
      type: 'removeItem',
      options: {
        collectionName: 'ledgers'
      }
    }
  },

  deactivateLedger: {
    url: '/school/ledgers/:id',
    method: 'patch',
    transformResponse: res => res.ledger,
    reducer: {
      name: 'ledgers',
      type: 'removeItem',
      options: {
        collectionName: 'ledgers'
      }
    }
  },

  allLedgers: {
    url: '/school/ledgers?lightweight=true&page=0&per_page=1000&active=true&',
    method: 'GET',
    reducer: {
      name: 'allLedgers',
      initialState: {
        ledgers: []
      }
    }
  },

  deleteLedger: {
    url: '/school/ledgers/:id',
    method: 'delete',
    reducer: {
      name: 'ledgers',
      type: 'removeItem',
      options: {
        collectionName: 'ledgers'
      }
    }
  },

  createLedger: {
    url: '/school/ledgers',
    method: 'post',
    transformResponse: res => res.ledger,
    reducer: {
      name: 'ledgers',
      type: 'add',
      options: {
        collectionName: 'ledgers'
      }
    }
  },

  updateLedger: {
    url: '/school/ledgers/:id',
    method: 'patch',
    transformResponse: res => res.ledger,
    reducer: {
      name: 'ledgers',
      type: 'patchItem',
      options: {
        collectionName: 'ledgers'
      }
    }
  },

  ledgerTransactions: {
    url: '/school/ledgers/billing_transactions',
    reducer: {
      name: 'ledgerTransactions',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        billing_transactions: []
      }
    }
  },

  ledgerTransactionKids: {
    url: '/school/ledgers/billing_transactions/:id/kids',
    method: 'GET'
  },

  subsidyKidsSummary: {
    url: '/school/ledgers/kids',
    transformResponse: data => data.kids,
    reducer: {
      name: 'subsidyKidsSummary',
      initialState: []
    }
  },

  subsidyKidsStats: {
    url: '/school/ledgers/kids/stats',
    transformResponse: data => data.stats,
    reducer: {
      name: 'subsidyKidsStats',
      initialState: {}
    }
  },

  addLedgerCredit: {
    url: '/school/ledgers/parent_payments',
    method: 'post'
  },

  updateLedgerCredit: {
    url: '/school/ledgers/parent_payments/:id',
    method: 'patch'
  },

  deleteLedgerCredit: {
    url: '/school/ledgers/parent_payments/:id',
    method: 'delete'
  },

  addLedgerPayment: {
    url: '/school/ledgers/parent_invoices',
    method: 'post'
  },

  updateLedgerPayment: {
    url: '/school/ledgers/parent_invoices/:id',
    method: 'patch'
  },

  deleteLedgerPayment: {
    url: '/school/ledgers/parent_invoices/:id',
    method: 'delete'
  },

  subsidyTransactions: {
    url: '/school/subsidies/billing_transactions',
    reducer: {
      name: 'subsidyTransactions',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        billing_transactions: []
      }
    }
  },

  subsidyKidsWithBalanceDue: {
    url: '/school/ledgers/kids',
    transformResponse: data => data.kids,
    method: 'get'
  },

  addSubsidyCredit: {
    url: '/school/subsidies/parent_credits',
    method: 'post'
  },

  updateSubsidyCredit: {
    url: '/school/subsidies/parent_credits/:id',
    method: 'patch'
  },

  deleteSubsidyCredit: {
    url: '/school/subsidies/parent_credits/:id',
    method: 'delete'
  },

  addSubsidyInvoice: {
    url: '/school/subsidies/parent_invoices',
    method: 'post'
  },

  updateSubsidyInvoice: {
    url: '/school/subsidies/parent_invoices/:id',
    method: 'patch'
  },

  deleteSubsidyInvoice: {
    url: '/school/subsidies/parent_invoices/:id',
    method: 'delete'
  },

  addSubsidyPayment: {
    url: '/school/subsidies/parent_payments',
    method: 'post'
  },

  updateSubsidyPayment: {
    url: '/school/subsidies/parent_payments/:id',
    method: 'patch'
  },

  deleteSubsidyPayment: {
    url: '/school/subsidies/parent_payments/:id',
    method: 'delete'
  },

  incidentActivities: {
    url: '/school/daily_activities/incidents',
    reducer: {
      name: 'incidentActivities',
      initialState: {
        daily_activities: []
      }
    }
  },

  pendingApprovalActivities: {
    url: '/school/daily_activities/pending_approvals/legacy',
    reducer: {
      name: 'pendingApprovalActivities',
      initialState: {
        total: 0,
        daily_activities: []
      }
    }
  },

  activiables: {
    url: '/school/daily_activities/activiables/:activiable_type',
    method: 'post',
    reducer: {
      name: 'activiables',
      initialState: {
        activiables: []
      }
    }
  },

  dailyActivities: {
    url: '/school/daily_activities',
    reducer: {
      name: 'dailyActivities',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        daily_activities: []
      }
    }
  },

  batchDailyActivities: {
    url: '/school/daily_activities/batches',
    reducer: {
      name: 'batchDailyActivities',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        daily_activities: []
      }
    }
  },

  batchDailyActivitiesV2: {
    url: '/school/daily_activities/batches',
    reducer: {
      name: 'batchDailyActivitiesV2',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        daily_activities: []
      }
    }
  },

  feedActivities: {
    url: '/school/activities',
    reducer: {
      name: 'feedActivities',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        activities: []
      }
    }
  },

  fetchBatchKidIds: {
    url: '/school/daily_activities/batches/:id/kid_ids',
    reducer: {
      name: 'fetchBatchKidIds',
      initialState: {
        kid_ids: []
      }
    }
  },

  updateBatchDailyActivity: {
    url: '/school/daily_activities/batches/:id',
    method: 'patch'
  },

  deleteBatchDailyActivity: {
    url: '/school/daily_activities/batches/:id',
    method: 'delete'
  },

  deleteDailyActivity: {
    url: '/school/activities/:id',
    method: 'delete'
  },

  updateActivity: {
    url: '/school/daily_activities/:id',
    method: 'patch',
    reducer: {
      name: 'patchDailyActivity'
    }
  },

  deleteActivity: {
    url: '/school/daily_activities/:id',
    method: 'delete',
    reducer: {
      name: 'deleteDailyActivity'
    }
  },

  recurringActivities: {
    url: '/school/recurring_daily_activities',
    reducer: {
      name: 'recurringActivities',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        recurring_daily_activities: []
      }
    }
  },

  createRecurringActivity: {
    url: '/school/recurring_daily_activities',
    method: 'post',
    transformResponse: res => res.recurring_daily_activity,
    reducer: {
      name: 'recurringActivities',
      type: 'add',
      options: {
        collectionName: 'recurring_daily_activities'
      }
    }
  },

  updateRecurringActivity: {
    url: '/school/recurring_daily_activities/:id',
    method: 'patch',
    transformResponse: res => res.recurring_daily_activity,
    reducer: {
      name: 'recurringActivities',
      type: 'patchItem',
      options: {
        collectionName: 'recurring_daily_activities'
      }
    }
  },

  deleteRecurringActivity: {
    url: '/school/recurring_daily_activities/:id',
    method: 'delete',
    reducer: {
      name: 'recurringActivities',
      type: 'removeItem',
      options: {
        collectionName: 'recurring_daily_activities'
      }
    }
  },

  reportIncident: {
    url: '/school/incidents/:id.pdf',
    responseType: 'blob'
  },

  // current user's kids
  parentKids: {
    url: '/parent/kids',
    transformResponse: res => transforms.parentKids(res.kids),
    reducer: {
      name: 'parentKids',
      initialState: {
        kids: [],
        families: []
      }
    }
  },

  parentKid: {
    url: '/parent/kids/:id',
    transformResponse: res => res.kid,
    reducer: {
      name: 'parentKid',
      initialState: {}
    }
  },

  // kid activities available in parent interface
  kidActivities: {
    url: '/parent/daily_activities',
    reducer: {
      name: 'kidActivities',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        daily_activities: []
      }
    }
  },

  // kid's stats and signins for Carer
  parentKidSignins: {
    url: '/parent/kids/signins',
    reducer: {
      name: 'parentKidSignins',
      initialState: {
        signins: [],
        stats: {}
      }
    }
  },

  parentDocs: {
    url: '/parent/documents',
    // For testing purposes: simulation of `expired` flag
    // transformResponse: res => {
    //   return {
    //     ...res,
    //     documents: (res.documents || []).map(doc => ({ ...doc, expired: true }))
    //   };
    // },
    reducer: {
      name: 'parentDocs',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        documents: []
      }
    }
  },

  parentEdocs: {
    url: '/parent/electronic_documents',
    reducer: {
      name: 'parentEdocs',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        documents: []
      }
    }
  },

  parentEdocsPermissionCheck: {
    url: '/parent/electronic_documents',
    cancellable: false
  },

  parentEdoc: {
    url: '/parent/electronic_document',
    transformResponse: res => res.document,
    reducer: {
      name: 'parentEdoc'
    }
  },

  updateParentEdoc: {
    url: '/parent/electronic_document',
    method: 'PATCH',
    transformResponse: res => res.document,
    reducer: {
      name: 'parentEdocs'
    }
  },

  createParentDoc: {
    url: '/parent/documents',
    method: 'POST',
    transformResponse: res => res.document,
    reducer: {
      name: 'parentDocs',
      type: 'add',
      options: {
        collectionName: 'documents'
      }
    }
  },

  // Join current user with existing parent
  parentRegister: {
    url: '/parent/schools',
    method: 'post',
    transformResponse: transforms.currentUser,
    reducer: {
      name: 'currentUser',
      initialState: {}
    }
  },

  addCredit: {
    url: '/school/billing/parent_credits',
    method: 'post'
  },

  editCredit: {
    url: '/school/billing/parent_credits/:id',
    method: 'patch'
  },

  cancelCredit: {
    url: '/school/billing/parent_credits/:id/cancel',
    method: 'delete'
  },

  deleteCredit: {
    url: '/school/billing/parent_credits/:id',
    method: 'delete'
  },

  receivePayment: {
    url: '/school/billing/parent_payments',
    method: 'post'
  },

  editPayment: {
    url: '/school/billing/parent_payments/:id',
    method: 'patch'
  },

  cancelPayment: {
    url: '/school/billing/parent_payments/:id/cancel',
    method: 'delete'
  },

  deletePayment: {
    url: '/school/billing/parent_payments/:id',
    method: 'delete'
  },

  profile: {
    url: '/user',
    transformResponse: transforms.currentUser,
    reducer: {
      name: 'currentUser',
      initialState: {}
    }
  },

  updateProfile: {
    url: '/user',
    method: 'patch',
    transformResponse: transforms.currentUser,
    reducer: {
      name: 'currentUser',
      initialState: {}
    }
  },

  recurringPlans: {
    url: '/school/billing/billing_plans',
    reducer: {
      name: 'recurringPlans',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        kids: []
      }
    }
  },

  recurringPlanInfo: {
    url: '/school/billing/billing_plans/info',
    method: 'post'
  },

  createBillingPlan: {
    url: '/school/billing/billing_plans',
    method: 'post'
  },

  updateBillingPlan: {
    url: '/school/billing/billing_plans/:id',
    method: 'patch'
  },

  previewInvoice: {
    url: '/school/billing/billing_plans/:id/invoice_preview',
    method: 'get',
    transformResponse: res => res.parent_invoice
  },

  recurringPlanStats: {
    url: '/school/billing/billing_plans/stats',
    transformResponse: res => res.stats,
    reducer: {
      name: 'billingPlanStats',
      initialState: {
        unassigned_tuition_count: 0,
        unassigned_attendance_count: 0
      }
    }
  },

  inviteStaff: {
    url: '/school/teachers/:id/invite',
    method: 'post'
  },

  carpoolCarers: {
    url: '/school/carpool_carers'
  },

  deleteBillingPlan: {
    url: '/school/billing/billing_plans/:id',
    method: 'delete'
  },

  billingPlanTemplates: {
    url: '/school/billing/billing_plans/templates',
    transformResponse: res => transforms.billingPlanTemplates(res.billing_plans),
    reducer: {
      name: 'billingPlanTemplates',
      initialState: []
    }
  },

  billingPlanStudents: {
    url: '/school/billing/billing_plans/kids',
    transformResponse: res => res.kids,
    reducer: {
      name: 'billingPlanStudents',
      initialState: []
    }
  },

  billingPlanSubFamilyStudents: {
    url: '/school/billing/billing_plans/sub_family_kids',
    transformResponse: res => res.kids,
    reducer: {
      name: 'billingPlanSubFamilyStudents',
      initialState: []
    }
  },

  addCarpoolCarer: {
    url: '/school/carpool_carers',
    method: 'post'
  },

  parentAddCarpoolCarer: {
    url: '/parent/carpool_carers',
    method: 'post'
  },

  parentCarpoolCarers: {
    url: '/parent/carpool_carers'
  },

  deleteCarpoolCarer: {
    url: '/school/carpool_carers/:id',
    method: 'delete'
  },

  parentDeleteCarpoolCarer: {
    url: '/parent/carpool_carers/:id',
    method: 'delete'
  },

  sectionRatios: {
    url: '/school/sections/ratios',
    transformResponse: res => res.ratios,
    reducer: {
      name: 'sectionRatios',
      initialState: []
    }
  },

  schoolGettingStarted: {
    url: '/school/dashboard/getting_started',
    transformResponse: res => res.status,
    reducer: {
      name: 'gettingStarted',
      initialState: {}
    }
  },

  schoolDashboardComplete: {
    url: '/school/dashboard/complete_onboarding',
    method: 'post'
  },

  sendPins: {
    url: '/school/kids/send_pins',
    method: 'post'
  },

  sendRegistrationCodes: {
    url: '/school/kids/send_registration_codes',
    method: 'post'
  },

  sendInvitation: {
    url: '/school/carers/send_invitation',
    method: 'post'
  },

  inviteCarer: {
    url: '/school/carers/:id/invite',
    method: 'post'
  },

  listPaymentMethods: {
    url: '/parent/billing/payment_methods',
    transformResponse: res => res.payment_methods,
    reducer: {
      name: 'paymentMethods',
      initialState: []
    }
  },

  verifySubKind: {
    url: '/parent/billing/payment_methods/:id/verify_sub_kind',
    method: 'post',
    transformResponse: res => res.payment_method,
    reducer: {
      name: 'paymentMethods',
      type: 'addOrPatchItem',
      initialState: []
    }
  },

  patchPaymentMethod: {
    url: '/parent/billing/payment_methods/:id',
    method: 'patch',
    transformResponse: res => res.payment_method,
    reducer: {
      name: 'paymentMethods',
      type: 'patchItem'
    }
  },

  addPaymentMethod: {
    url: '/parent/billing/payment_methods',
    method: 'post',
    transformResponse: res => res.payment_method,
    reducer: {
      name: 'paymentMethods',
      type: 'add'
    }
  },

  verifyPaymentMethod: {
    url: '/parent/billing/payment_methods/:id/verify',
    method: 'post'
  },

  deletePaymentMethod: {
    url: '/parent/billing/payment_methods/:id',
    method: 'delete',
    transformResponse: res => res.payment_method,
    reducer: {
      name: 'paymentMethods',
      type: 'removeItem'
    }
  },

  schoolParentUpdatePaymentMethod: {
    url: '/school/billing/payment_methods/:id',
    method: 'put'
  },

  paymentsOverview: {
    url: '/parent/billing/billing_transactions',
    reducer: {
      name: 'paymentsOverview',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        billing_transactions: [],
        kids: []
      }
    }
  },

  parentViewInvoice: {
    url: '/parent/billing/parent_invoices/:id',
    transformResponse: res => res.parent_invoice
  },

  paymentsOverviewStats: {
    url: '/parent/billing/billing_transactions/info',
    transformResponse: res => res.billing_info,
    reducer: {
      name: 'paymentsOverviewStats',
      initialState: {
        billing_stats: {}
      }
    }
  },

  parentDesktopPaymentAccounts: {
    url: '/parent/billing/te_accounts/payment_accounts',
    reducer: {
      name: 'parentDesktopPaymentAccounts',
      initialState: {}
    }
  },

  changePlan: {
    url: '/school/subscriptions/accounts/change',
    method: 'post'
  },

  subscriptionInvoices: {
    url: '/school/subscriptions/invoices',
    transformResponse: res => res.invoices,
    reducer: {
      name: 'subscriptionInvoices',
      initialState: []
    }
  },

  lastSubscriptionInvoice: {
    url: '/school/subscriptions/invoices/latest',
    transformResponse: res => res.invoice,
    reducer: {
      name: 'lastSubscriptionInvoice',
      initialState: {}
    }
  },

  downloadSubscriptionInvoice: {
    url: 'school/subscriptions/invoices/:id'
  },

  schoolPaymentMethod: {
    url: '/school/subscriptions/payment_method',
    transformResponse: res => res.source,
    reducer: {
      name: 'paymentMethod',
      initialState: {}
    }
  },

  addSchoolPaymentMethod: {
    url: '/school/subscriptions/payment_method',
    method: 'patch',
    transformResponse: res => res.source,
    reducer: {
      name: 'paymentMethod',
      initialState: {}
    }
  },

  parentMakePayment: {
    url: '/parent/billing/parent_payments',
    method: 'post'
  },

  calculateFee: {
    url: '/parent/billing/parent_payments/fee',
    method: 'post'
  },

  addBluepayAccount: {
    url: '/school/billing/bluepay_accounts',
    method: 'post'
  },

  addSchoolBillingAccount: {
    url: '/school/billing/accounts',
    method: 'post'
  },

  teAccountUri: {
    url: '/school/billing/te_accounts/connect_uri',
    method: 'post'
  },

  parentTeAccountUri: {
    url: '/parent/billing/te_accounts/connect_uri',
    method: 'post'
  },

  registrationsTeAccountUri: {
    url: '/registrations/connect_uri',
    method: 'post'
  },

  connectUri: {
    url: '/school/billing/accounts/connect_uri',
    method: 'get'
  },

  addStandardAccount: {
    url: '/school/billing/accounts/standard_connect',
    method: 'post'
  },

  verificationStatus: {
    url: '/school/billing/accounts/verification_status'
  },

  verifySchool: {
    url: '/school/billing/accounts/verify',
    method: 'post'
  },

  schoolFeeBreakdown: {
    url: 'school/billing/accounts/fee_breakdown',
    transformResponse: res => res.fee_breakdown,
    reducer: {
      name: 'feeBreakdown',
      initialState: {}
    }
  },

  parentFeeBreakdown: {
    url: 'parent/billing/parent_payments/fee_breakdown',
    transformResponse: res => res.fee_breakdown,
    reducer: {
      name: 'feeBreakdown',
      initialState: {}
    }
  },

  bluepayRequestSignup: {
    url: 'school/billing/bluepay_accounts/request_signup',
    method: 'post'
  },

  teRequestSignup: {
    url: 'school/billing/te_accounts/request_signup',
    method: 'post'
  },

  createReferral: {
    url: '/school/referrals',
    method: 'post'
  },

  mobileAuthView: {
    url: '/auth/mobile_view',
    method: 'post',
    transformResponse: transforms.currentUser,
    reducer: {
      name: 'currentUser',
      initialState: {}
    }
  },

  awsSignature: {
    url: '/school/aws_signature',
    reducer: {
      name: 'awsSignature',
      initialState: {}
    }
  },

  awsSignaturePublic: {
    url: '/school/aws_signature/signature'
  },

  awsSignatureParent: {
    url: '/parent/aws_signature'
  },

  vaccines: {
    url: '/school/vaccines',
    transformResponse: res => res.vaccines,
    reducer: {
      name: 'vaccines',
      initialState: []
    }
  },

  reorderVaccines: {
    url: '/school/vaccines/reorder',
    method: 'post'
  },

  addVaccine: {
    url: '/school/vaccines',
    method: 'post'
  },

  studentVaccineDoses: {
    url: '/school/kids/:id/vaccines',
    transformResponse: res => res.vaccines,
    reducer: {
      name: 'studentVaccineDoses',
      initialState: []
    }
  },

  updateVaccine: {
    url: '/school/vaccines/:id',
    method: 'patch'
  },

  saveStudentVaccine: {
    url: '/school/vaccines/vaccine_kids',
    method: 'post'
  },

  saveStudentVaccineDose: {
    url: '/school/vaccines/vaccine_dose_kids',
    method: 'post'
  },

  batchUpdateStudentVaccineDoses: {
    url: '/school/vaccines/vaccine_dose_kids/batch_update',
    method: 'post'
  },

  toggleKidVaccineReport: {
    url: '/school/kids/:id/toggle_vaccines_report',
    method: 'patch'
  },

  sendVaccineReminder: {
    url: '/school/vaccines/vaccine_dose_kids/send_reminder',
    method: 'post'
  },

  sendInvoiceReminder: {
    url: '/school/billing/families/:id/send_reminder',
    method: 'post'
  },

  pauseFamilyBillingPlans: {
    url: '/school/billing/families/:id/pause_plans',
    method: 'post'
  },

  issueRefund: {
    url: '/school/billing/parent_refunds',
    method: 'post'
  },

  cancelRefund: {
    url: '/school/billing/parent_refunds/:id/cancel',
    method: 'delete'
  },

  deleteRefund: {
    url: '/school/billing/parent_refunds/:id',
    method: 'delete'
  },

  cancelInvoice: {
    url: '/school/billing/parent_invoices/:id/cancel',
    method: 'delete'
  },

  deleteInvoice: {
    url: '/school/billing/parent_invoices/:id',
    method: 'delete'
  },

  subscriptionPlans: {
    url: '/school/subscriptions/plans',
    transformResponse: res => res.plans,
    reducer: {
      name: 'subscriptionPlans',
      initialState: []
    }
  },

  newSubscription: {
    url: '/school/subscriptions/accounts',
    method: 'post'
  },

  subscriptionInfo: {
    url: '/school/subscriptions/accounts/info',
    method: 'post',
    reducer: {
      name: 'subscriptionInfo'
    }
  },

  verifyCoupon: {
    url: '/school/subscriptions/accounts/verify_coupon',
    method: 'post'
  },

  photos: {
    url: '/parent/photos',
    reducer: {
      name: 'photos',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        photos: []
      }
    }
  },

  videos: {
    url: '/parent/videos',
    reducer: {
      name: 'videos',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        videos: []
      }
    }
  },

  schoolPhotos: {
    url: '/school/photos',
    reducer: {
      name: 'schoolPhotos',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        photos: []
      }
    }
  },

  deleteMedias: {
    url: '/school/medias/batch_delete',
    method: 'POST',
    reducer: {
      name: 'schoolPhotos',
      type: 'batchDelete',
      options: {
        collectionName: 'photos'
      }
    }
  },

  updatePhoto: {
    url: '/school/photos/:id',
    method: 'patch'
  },

  updatePhotoActivity: {
    url: '/school/photos',
    method: 'patch'
  },

  updateVideo: {
    url: '/school/videos/:id',
    method: 'patch'
  },

  kidsRoster: {
    url: '/school/roster_kids',
    reducer: {
      name: 'kidsRoster',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        roster_kids: []
      }
    }
  },

  kidsRosterActivate: {
    url: '/school/roster_kids/:id/activate',
    method: 'patch',
    transformResponse: res => res.roster_kid,
    reducer: {
      name: 'kidsRoster',
      type: 'patchItem',
      options: {
        collectionName: 'roster_kids'
      }
    }
  },

  rosterBatchActivate: {
    url: '/school/roster_kids/batch_activate',
    method: 'POST'
  },

  resendParentInvite: {
    url: '/parent/schools/resend',
    method: 'post'
  },

  activitySummary: {
    url: '/school/daily_activities/activity_summaries',
    reducer: {
      name: 'activitySummary',
      initialState: {
        kids: [],
        meta: {}
      }
    }
  },

  activitySummaryV2: {
    url: '/school/activities/kids_activity_summaries',
    reducer: {
      name: 'activitySummaryV2',
      initialState: {
        daily_activities: [],
        meta: {}
      }
    }
  },

  activitySummaryKid: {
    url: '/school/daily_activities/activity_summaries/kid_activity_list',
    reducer: {
      name: 'activitySummaryKid',
      initialState: {
        daily_activities: []
      }
    }
  },

  dailySummary: {
    url: '/school/daily_activities/summary',
    reducer: {
      name: 'dailySummary',
      initialState: {
        kids: [],
        summary: {}
      }
    }
  },

  dailySummaryKid: {
    url: '/school/daily_activities',
    reducer: {
      name: 'dailySummaryKid',
      initialState: {}
    }
  },

  summaryReport: {
    url: '/school/kids/:id/summary.html'
  },

  approveActivities: {
    url: '/school/daily_activities/approve',
    method: 'post'
  },

  createActivity: {
    url: '/school/daily_activities',
    method: 'post'
  },

  createActivityV2: {
    url: '/school/activities',
    method: 'post'
  },

  updateParentSignature: {
    url: '/parent/kids/:kidId/incidents/:incidentId/sign',
    method: 'patch'
  },

  updateIncidentSignatures: {
    url: '/school/incidents/:incidentId/kids/:kidId',
    method: 'put'
  },

  createIncident: {
    url: '/school/incidents',
    method: 'post'
  },

  printIncidentStaffReport: {
    url: '/school/incidents/:incidentId/kids/:kidId.pdf',
    responseType: 'blob'
  },

  printIncidentParentReport: {
    url: '/parent/kids/:kidId/incidents/:incidentId.pdf',
    responseType: 'blob'
  },

  markAbsent: {
    url: '/school/absent_kids/mark_absent',
    method: 'post'
  },

  absentKids: {
    url: '/school/absent_kids',
    transformResponse: res => res.kids,
    reducer: {
      name: 'absentKids',
      initialState: []
    }
  },

  updateAbsentKid: {
    url: '/school/absent_kids/:id',
    method: 'patch',
    cancellable: false
  },

  updateAbsentKidsBatch: {
    url: '/school/absent_kids/batch_update',
    method: 'post'
  },

  deleteAbsentKid: {
    url: '/school/absent_kids/:id',
    method: 'delete',
    cancellable: false
  },

  deleteAbsentKidsBatch: {
    url: '/school/absent_kids/batch_delete',
    method: 'post'
  },

  saveRoomsOrder: {
    url: '/school/sections/reorder',
    method: 'post'
  },

  parentKioskForm: {
    url: '/school/kiosk_form',
    transformResponse: res => res.kiosk_form,
    reducer: {
      name: 'parentKioskForm',
      initialState: {}
    }
  },

  updateParentKioskForm: {
    url: '/school/kiosk_form',
    method: 'patch'
  },

  staffKioskForm: {
    url: '/school/teacher_kiosk_form',
    transformResponse: res => res.kiosk_form,
    reducer: {
      name: 'staffKioskForm',
      initialState: {}
    }
  },

  updateStaffKioskForm: {
    url: '/school/teacher_kiosk_form',
    method: 'patch'
  },

  recurringPlanTemplates: {
    url: '/school/billing/billing_plan_templates',
    reducer: {
      name: 'recurringPlanTemplates',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        billing_plans: []
      }
    }
  },

  updatePlanTemplate: {
    url: '/school/billing/billing_plan_templates/:id',
    method: 'patch'
  },

  createPlanTemplate: {
    url: '/school/billing/billing_plan_templates',
    method: 'post'
  },

  deletePlanTemplate: {
    url: '/school/billing/billing_plan_templates/:id',
    method: 'delete'
  },

  classes: {
    url: '/school/school_classes',
    reducer: {
      name: 'classes',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        school_classes: []
      }
    }
  },

  createClass: {
    url: '/school/school_classes',
    method: 'post'
  },

  updateClass: {
    url: '/school/school_classes/:id',
    method: 'patch',
    transformResponse: res => res.school_class,
    reducer: {
      name: 'classes',
      type: 'patchItem',
      options: {
        collectionName: 'school_classes'
      }
    }
  },

  schoolClass: {
    url: '/school/school_classes/:id',
    transformResponse: res => res.school_class,
    reducer: {
      name: 'schoolClass',
      initialState: {}
    }
  },

  schoolClassKids: {
    url: '/school/school_classes/:id/kids',
    transformResponse: res => res.kids,
    reducer: {
      name: 'schoolClassKids',
      initialState: []
    }
  },

  addKidsToClass: {
    url: '/school/school_classes/:id/kids',
    method: 'post'
  },

  removeFromClass: {
    url: '/school/school_classes/:class_id/kids/:id',
    method: 'delete'
  },

  updateEnrollment: {
    url: '/school/school_classes/:class_id/kids/:id',
    method: 'patch',
    transformResponse: res => res.kid,
    reducer: {
      name: 'schoolClassKids',
      type: 'patchItem'
    }
  },

  parentClasses: {
    url: '/parent/school_classes',
    reducer: {
      name: 'parentClasses',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        school_classes: []
      }
    }
  },

  downloadStatementParent: {
    url: '/parent/billing/parent_payments/statement'
  },

  downloadStatementTeacher: {
    url: '/school/billing/parent_payments/statement'
  },

  downloadTaxStatementParent: {
    method: 'POST',
    url: '/parent/billing/parent_payments/tax_statement'
  },

  downloadTaxStatementTeacher: {
    method: 'POST',
    url: '/school/billing/parent_payments/tax_statement'
  },

  recurringPlanActions: {
    url: '/school/billing/billing_plans/bulk',
    method: 'post'
  },

  learningActivityNames: {
    url: '/school/learning_activities/names',
    transformResponse: res => res.learning_activity_names,
    reducer: {
      name: 'learningActivityNames',
      initialState: []
    }
  },

  learningActivityCategories: {
    url: '/school/learning_activities/categories',
    transformResponse: res => res.learning_activity_categories,
    reducer: {
      name: 'learningActivityCategories',
      initialState: []
    }
  },

  addLearningActivityName: {
    url: '/school/learning_activities/names',
    method: 'POST',
    transformResponse: res => res.learning_activity_name,
    reducer: {
      name: 'learningActivityNames',
      type: 'add'
    }
  },

  addLearningActivityCategory: {
    url: '/school/learning_activities/categories',
    method: 'POST',
    transformResponse: res => res.learning_activity_category,
    reducer: {
      name: 'learningActivityCategories',
      type: 'add'
    }
  },

  deleteLearningActivityName: {
    url: '/school/learning_activities/names/:id',
    method: 'DELETE',
    reducer: {
      name: 'learningActivityNames',
      type: 'removeItem'
    }
  },

  deleteLearningActivityCategory: {
    url: '/school/learning_activities/categories/:id',
    method: 'DELETE',
    reducer: {
      name: 'learningActivityCategories',
      type: 'removeItem'
    }
  },

  emailStatement: {
    url: '/school/billing/parent_payments/email_statement',
    method: 'POST'
  },

  uploadStudentRoster: {
    url: '/school/imports',
    method: 'POST'
  },

  kidDoc: {
    url: '/school/kids/:kidId/documents/:id',
    transformResponse: res => res.document
  },

  kidDocs: {
    url: '/school/kids/:kidId/documents',
    reducer: {
      name: 'kidDocs',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        documents: []
      }
    }
  },

  createKidDoc: {
    url: '/school/kids/:kidId/documents',
    method: 'POST',
    transformResponse: res => res.document,
    reducer: {
      name: 'kidDocs',
      type: 'add',
      options: {
        collectionName: 'documents'
      }
    }
  },

  updateKidDoc: {
    url: '/school/kids/:kidId/documents/:id',
    method: 'PATCH',
    transformResponse: res => res.document,
    reducer: {
      name: 'kidDocs',
      type: 'patchItem',
      options: {
        collectionName: 'documents'
      }
    }
  },

  deleteKidDoc: {
    url: '/school/kids/:kidId/documents/:id',
    method: 'DELETE',
    reducer: {
      name: 'kidDocs',
      type: 'removeItem',
      options: {
        collectionName: 'documents'
      }
    }
  },

  getAppLink: {
    url: '/invitations',
    method: 'POST'
  },

  recurringCharges: {
    url: '/school/billing/charges/recurring',
    transformResponse: res => res.charges,
    reducer: {
      name: 'recurringCharges',
      initialState: []
    }
  },

  invoiceItemPresets: {
    url: '/school/billing/charges',
    transformResponse: res => res,
    reducer: {
      name: 'invoiceItemPresets',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        charges: []
      }
    }
  },

  createInvoiceItemPreset: {
    url: '/school/billing/charges',
    method: 'POST',
    transformResponse: res => res.charge,
    reducer: {
      name: 'invoiceItemPresets',
      type: 'add',
      options: {
        collectionName: 'charges'
      }
    }
  },

  updateInvoiceItemPreset: {
    url: '/school/billing/charges/:id',
    method: 'PATCH',
    transformResponse: res => res.charge,
    reducer: {
      name: 'invoiceItemPresets',
      type: 'patchItem',
      options: {
        collectionName: 'charges'
      }
    }
  },

  deleteInvoiceItemPreset: {
    url: '/school/billing/charges/:id',
    method: 'DELETE',
    reducer: {
      name: 'invoiceItemPresets',
      type: 'removeItem',
      options: {
        collectionName: 'charges'
      }
    }
  },

  sisoAlerts: {
    url: '/school/siso/alerts',
    transformResponse: res => res.alerts,
    reducer: {
      name: 'sisoAlerts',
      initialState: []
    }
  },

  sisoAlertItem: {
    url: '/school/siso/alerts/:id',
    transformResponse: res => res.alert,
    reducer: {
      name: 'sisoAlertItem',
      initialState: {}
    }
  },

  addSisoAlert: {
    url: '/school/siso/alerts',
    method: 'post',
    transformResponse: res => res.alert,
    reducer: {
      name: 'sisoAlerts',
      type: 'add'
    }
  },

  updateSisoAlert: {
    url: '/school/siso/alerts/:id',
    method: 'patch',
    transformResponse: res => res.alert,
    reducer: {
      name: 'sisoAlerts',
      type: 'patchItem'
    }
  },

  deleteSisoAlert: {
    url: '/school/siso/alerts/:id',
    method: 'delete',
    reducer: {
      name: 'sisoAlerts',
      type: 'removeItem'
    }
  },

  sisoAlertLogs: {
    url: '/school/siso/alerts/:id/logs',
    reducer: {
      name: 'sisoAlertLogs',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        alert_logs: []
      }
    }
  },

  deleteSisoAlertLog: {
    url: '/school/siso/alerts/:alertId/logs/:id',
    method: 'delete',
    reducer: {
      type: 'removeItem',
      name: 'sisoAlertLogs',
      options: {
        collectionName: 'alert_logs'
      }
    }
  },

  deleteAllSisoAlertLogs: {
    url: '/school/siso/alerts/:id/logs/delete_all',
    method: 'post'
  },

  getGusto: {
    url: '/school/gusto',
    method: 'put',
    transformResponse: res => res.gusto,
    reducer: {
      name: 'gusto',
      initialState: {}
    }
  },

  deleteGusto: {
    url: '/school/gusto',
    method: 'delete'
  },

  updateGusto: {
    url: '/school/gusto',
    method: 'PATCH',
    transformResponse: res => res.gusto,
    reducer: {
      name: 'gusto',
      initialState: {}
    }
  },

  getCheckr: {
    url: '/school/checkr',
    transformResponse: res => res.checkr,
    reducer: {
      name: 'checkr',
      initialState: {}
    }
  },

  updateCheckr: {
    url: '/school/checkr',
    method: 'PATCH',
    transformResponse: res => res.checkr,
    reducer: {
      name: 'checkr',
      initialState: {}
    }
  },

  getCheckrPackages: {
    url: '/school/checkr/packages',
    transformResponse: res => res.packages,
    reducer: {
      name: 'checkrPackages',
      initialState: []
    }
  },

  createCheckrCandidate: {
    url: '/school/checkr/candidates',
    method: 'POST'
  },

  updateCheckrCandidate: {
    url: '/school/checkr/candidates',
    method: 'PATCH'
  },

  inviteCheckrCandidate: {
    url: '/school/checkr/invitations',
    method: 'POST'
  },

  reportCheckrCandidate: {
    url: '/school/checkr/reports',
    method: 'POST'
  },

  getQuickbook: {
    url: '/school/quick_books',
    transformResponse: res => res.quick_books,
    reducer: {
      name: 'quick_books',
      initialState: {}
    }
  },

  updateQuickbook: {
    url: '/school/quick_books',
    method: 'PATCH',
    transformResponse: res => res.quick_books,
    reducer: {
      name: 'quick_books',
      initialState: {}
    }
  },

  schoolReg: {
    url: '/school/registration_setting',
    transformRequest: SchoolRegistrationSerializer.serialize,
    transformResponse: SchoolRegistrationSerializer.deserialize,
    reducer: {
      name: 'schoolReg',
      initialState: {
        form: {},
        payment: {},
        share: {}
      }
    }
  },

  schoolRegFee: {
    url: '/school/registration_setting/fee',
    method: 'post'
  },

  updateSchoolReg: {
    url: '/school/registration_setting',
    method: 'PATCH',
    transformRequest: SchoolRegistrationSerializer.serialize,
    transformResponse: SchoolRegistrationSerializer.deserialize,
    reducer: {
      name: 'schoolReg'
    }
  },

  schoolRegForm: {
    url: '/registrations/form',
    transformResponse: SchoolRegistrationSerializer.deserialize,
    reducer: {
      name: 'schoolRegForm',
      initialState: {
        form: {},
        payment: {},
        share: {},
        school: {}
      }
    }
  },

  submitSchoolRegForm: {
    url: '/registrations',
    method: 'POST'
  },

  receiveRegistrationPayment: {
    url: '/school/registrations/parent_payments',
    method: 'POST'
  },

  registrationPayments: {
    url: '/school/registrations/parent_payments',
    reducer: {
      name: 'registrationPayments',
      initialState: {
        registration: {},
        parent_payments: [],
        parent_refunds: []
      }
    }
  },

  editRegistrationPayment: {
    url: '/school/registrations/parent_payments/:id',
    method: 'PATCH'
  },

  deleteRegistrationPayment: {
    url: '/school/registrations/parent_payments/:id',
    method: 'DELETE'
  },

  cancelRegistrationPayment: {
    url: '/school/registrations/parent_payments/:id/cancel',
    method: 'DELETE'
  },

  issueRegistrationRefund: {
    url: '/school/registrations/parent_refunds',
    method: 'POST'
  },

  enrollments: {
    url: '/school/enrollments',
    reducer: {
      name: 'enrollments',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        enrollments: []
      }
    }
  },

  enrollment: {
    url: '/school/enrollments/:id',
    transformResponse: res => res.enrollment,
    reducer: {
      name: 'enrollment',
      initialState: {}
    }
  },

  enrollmentsTypeahead: {
    url: '/school/enrollments'
  },

  enrollmentTimeline: {
    url: '/school/timeline',
    transformResponse: res => res.activities,
    reducer: {
      name: 'enrollmentTimeline',
      initialState: []
    }
  },

  allEnrollments: {
    url: '/school/enrollments/all',
    transformResponse: res => res.enrollments,
    reducer: {
      name: 'allEnrollments',
      initialState: []
    }
  },

  archivedEnrollments: {
    url: '/school/enrollments/archived',
    reducer: {
      name: 'archivedEnrollments',
      initialState: []
    }
  },

  enrollmentStats: {
    url: '/school/enrollments/stats',
    transformResponse: res => res.stats,
    reducer: {
      name: 'enrollmentStats',
      initialState: {}
    }
  },

  addEnrollmentItem: {
    url: '/school/enrollments',
    method: 'post',
    transformResponse: res => res.enrollment,
    reducer: {
      name: 'enrollments',
      type: 'add',
      options: {
        collectionName: 'enrollments'
      }
    }
  },

  updateEnrollmentItem: {
    url: '/school/enrollments/:id',
    method: 'PATCH',
    transformResponse: res => res.enrollment,
    reducer: {
      type: 'patchItem',
      name: 'enrollments',
      options: {
        collectionName: 'enrollments'
      }
    }
  },

  linkSiblingEnrollment: {
    url: 'school/enrollments/:id/link',
    method: 'POST',
    transformResponse: res => res.enrollment
  },

  unlinkSiblingEnrollment: {
    url: 'school/enrollments/:id/unlink',
    method: 'POST',
    transformResponse: res => res.enrollment
  },

  printEnrollmentItem: {
    url: '/school/enrollments/:id/pdf'
  },

  activateEnrollmentItem: {
    url: '/school/enrollments/:id/activate',
    method: 'POST',
    transformResponse: res => res.enrollment,
    reducer: {
      type: 'patchItem',
      name: 'enrollments',
      options: {
        collectionName: 'enrollments'
      }
    }
  },

  sendEnrollmentEmail: {
    url: '/school/enrollments/send_email',
    method: 'POST'
  },

  leadStages: {
    url: '/school/enrollments/states',
    transformResponse: res => res.states,
    reducer: {
      name: 'leadStages',
      initialState: []
    }
  },

  leadSources: {
    url: '/school/enrollments/sources',
    transformResponse: res => res.sources,
    reducer: {
      name: 'leadSources',
      initialState: []
    }
  },

  updateLeadStage: {
    url: '/school/enrollments/states/:id',
    method: 'patch',
    transformResponse: res => res.state,
    reducer: {
      name: 'leadStages',
      type: 'patchItem'
    }
  },

  reorderLeadStages: {
    url: '/school/enrollments/states/reorder',
    method: 'post'
  },

  addLeadStage: {
    url: '/school/enrollments/states/',
    method: 'post',
    transformResponse: res => res.state,
    reducer: {
      name: 'leadStages',
      type: 'add'
    }
  },

  deleteLeadStage: {
    url: '/school/enrollments/states/:id',
    method: 'delete',
    reducer: {
      name: 'leadStages',
      type: 'removeItem'
    }
  },

  leadPipelines: {
    url: '/school/enrollments/pipelines',
    cancellable: false
  },

  leadFunnels: {
    url: '/school/enrollments/funnels',
    cancellable: false
  },

  uploadPhoto: {
    url: '/school/photos',
    method: 'POST'
  },

  medias: {
    url: '/school/medias',
    reducer: {
      name: 'medias',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        medias: []
      }
    }
  },

  newsletters: {
    url: '/school/newsletters',
    reducer: {
      name: 'newsletters',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        newsletters: []
      }
    }
  },

  newsletterTemplates: {
    url: '/school/newsletters',
    reducer: {
      name: 'newsletterTemplates',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        newsletters: []
      }
    }
  },

  // used without reducer, only for modal search
  listNewsletterTemplates: {
    url: '/school/newsletters',
    method: 'get'
  },

  createNewsletter: {
    url: '/school/newsletters',
    method: 'POST',
    transformResponse: res => res.newsletter,
    reducer: {
      name: 'newsletters',
      type: 'add',
      options: {
        collectionName: 'newsletters'
      }
    }
  },

  updateNewsletter: {
    url: '/school/newsletters/:id',
    method: 'PATCH',
    transformResponse: res => res.newsletter,
    reducer: {
      name: 'newsletters',
      type: 'patchItem',
      options: {
        collectionName: 'newsletters'
      }
    }
  },

  deleteNewsletter: {
    url: '/school/newsletters/:id',
    method: 'DELETE',
    reducer: {
      name: 'newsletters',
      type: 'removeItem',
      options: {
        collectionName: 'newsletters'
      }
    }
  },

  deleteNewsletterTemplate: {
    url: '/school/newsletters/:id',
    method: 'DELETE',
    reducer: {
      name: 'newsletterTemplates',
      type: 'removeItem',
      options: {
        collectionName: 'newsletters'
      }
    }
  },

  copyNewsletter: {
    url: '/school/newsletters/:id/copy',
    method: 'POST',
    transformResponse: res => res.newsletter,
    reducer: {
      name: 'newsletters',
      type: 'add',
      options: {
        collectionName: 'newsletters'
      }
    }
  },

  getNewsletterPDF: {
    url: '/school/newsletters/:id/pdf',
    method: 'POST',
    transformResponse: res => res.pdf
  },

  newsletter: {
    url: '/school/newsletters/:id',
    transformResponse: res => res.newsletter,
    reducer: {
      name: 'newsletter',
      initialState: []
    }
  },

  newsletterTemplate: {
    url: '/school/newsletters/:id',
    transformResponse: res => res.newsletter
  },

  getExpiringUrl: {
    url: '/content_expire/generate_expiring_url',
    method: 'POST',
    transformResponse: res => res.expired_url
  },

  lessonLabels: {
    url: '/school/labels/lesson',
    transformResponse: res => res.labels,
    reducer: {
      name: 'lessonLabels',
      initialState: []
    }
  },

  updateLessonLabel: {
    url: '/school/labels/lesson/:id',
    method: 'patch',
    transformResponse: res => res.label,
    reducer: {
      name: 'lessonLabels',
      type: 'patchItem'
    }
  },

  addLessonLabel: {
    url: '/school/labels/lesson/',
    method: 'post',
    transformResponse: res => res.label,
    reducer: {
      name: 'lessonLabels',
      type: 'add'
    }
  },

  deleteLessonLabel: {
    url: '/school/labels/lesson/:id',
    method: 'delete',
    reducer: {
      name: 'lessonLabels',
      type: 'removeItem'
    }
  },

  newsletterRecipients: {
    url: '/school/newsletters/:id/recipients'
  },

  payouts: {
    url: '/school/billing/payouts',
    transformResponse: res => res.payouts,
    reducer: {
      name: 'payouts',
      initialState: []
    }
  },

  viewPayout: {
    url: '/school/billing/payouts/:id',
    reducer: {
      name: 'viewPayout',
      initialState: {
        payout: {},
        transactions: []
      }
    }
  },

  analyticsSiso: {
    url: '/school/analytics/siso',
    transformResponse: res => res.stats,
    cancellable: false
  },

  analyticsStats: {
    url: '/school/analytics/school_stats',
    cancellable: false,
    transformResponse: res => res.stats,
    reducer: {
      name: 'analyticsStats',
      initialState: {}
    }
  },

  analyticsActionItems: {
    url: '/school/analytics/action_items',
    transformResponse: res => res.stats,
    cancellable: false,
    reducer: {
      name: 'analyticsActionItems',
      initialState: {}
    }
  },

  analyticsLeadCrm: {
    url: '/school/analytics/lead_crm',
    transformResponse: res => res.stats,
    cancellable: false
  },

  analyticsBilling: {
    url: '/school/analytics/billing',
    transformResponse: res => res.stats,
    cancellable: false
  },

  analyticsMessages: {
    url: '/school/analytics/messages',
    transformResponse: res => res.stats,
    cancellable: false
  },

  analyticsDailyActivities: {
    url: '/school/analytics/daily_activities',
    transformResponse: res => res.stats,
    cancellable: false
  },

  analyticsDailyActivitiesByDate: {
    url: '/school/analytics/daily_activities_by_date',
    reducer: {
      name: 'analyticsDailyActivitiesByDate',
      initialState: {
        stats: []
      }
    }
  },

  analyticsMonthlyBillingStats: {
    url: '/school/analytics/monthly_billing_stats',
    reducer: {
      name: 'analyticsMonthlyBillingStats',
      initialState: {
        stats: []
      }
    }
  },

  updateSchoolAnalyticSettings: {
    url: '/school/analytics/update_settings',
    method: 'post'
  },

  milestoneStandards: {
    url: '/school/milestones/standards',
    transformResponse: res => res.standards,
    reducer: {
      name: 'milestoneStandards',
      initialState: []
    }
  },

  updateMilestoneStandard: {
    url: '/school/milestones/standards/:id',
    method: 'patch',
    transformResponse: res => res.standard,
    reducer: {
      name: 'milestoneStandards',
      type: 'patchItem'
    }
  },

  addMilestoneStandard: {
    url: '/school/milestones/standards/',
    method: 'post',
    transformResponse: res => res.standard,
    reducer: {
      name: 'milestoneStandards',
      type: 'add'
    }
  },

  markStandardProgress: {
    url: '/school/milestones/standards/:id/mark_progress',
    method: 'post'
  },

  deleteMilestoneStandard: {
    url: '/school/milestones/standards/:id',
    method: 'delete',
    reducer: {
      name: 'milestoneStandards',
      type: 'removeItem'
    }
  },

  reorderMilestoneStandards: {
    url: '/school/milestones/standards/reorder',
    method: 'post'
  },

  milestoneMeasures: {
    url: '/school/milestones/measures',
    transformResponse: res => res.measures,
    reducer: {
      name: 'milestoneMeasures',
      initialState: []
    }
  },

  updateMilestoneMeasure: {
    url: '/school/milestones/measures/:id',
    method: 'patch',
    transformResponse: res => res.measure,
    reducer: {
      name: 'milestoneMeasures',
      type: 'patchItem'
    }
  },

  addMilestoneMeasure: {
    url: '/school/milestones/measures/',
    method: 'post',
    transformResponse: res => res.measure,
    reducer: {
      name: 'milestoneMeasures',
      type: 'add'
    }
  },

  deleteMilestoneMeasure: {
    url: '/school/milestones/measures/:id',
    method: 'delete',
    reducer: {
      name: 'milestoneMeasures',
      type: 'removeItem'
    }
  },

  markMeasureProgress: {
    url: '/school/milestones/measures/:id/mark_progress',
    method: 'post'
  },

  markMeasureScale: {
    url: '/school/milestones/measures/:id/mark_scale',
    method: 'post'
  },

  reorderMilestoneMeasures: {
    url: '/school/milestones/measures/reorder',
    method: 'post'
  },

  milestonesStandards: {
    url: '/school/milestones/standards',
    transformResponse: res => res.standards,
    reducer: {
      name: 'milestonesStandards',
      initialState: []
    }
  },

  milestoneIndicators: {
    url: '/school/milestones/indicators',
    transformResponse: res => res.indicators,
    reducer: {
      name: 'milestoneIndicators',
      initialState: []
    }
  },

  updateMilestoneIndicator: {
    url: '/school/milestones/indicators/:id',
    method: 'patch',
    transformResponse: res => res.indicator,
    reducer: {
      name: 'milestoneIndicators',
      type: 'patchItem'
    }
  },

  addMilestoneIndicator: {
    url: '/school/milestones/indicators/',
    method: 'post',
    transformResponse: res => res.indicator,
    reducer: {
      name: 'milestoneIndicators',
      type: 'add'
    }
  },

  deleteMilestoneIndicator: {
    url: '/school/milestones/indicators/:id',
    method: 'delete',
    reducer: {
      name: 'milestoneIndicators',
      type: 'removeItem'
    }
  },

  markIndicatorProgress: {
    url: '/school/milestones/indicators/:id/mark_progress',
    method: 'post'
  },

  milestoneCategories: {
    url: '/school/categories/milestone',
    transformResponse: res => res.categories,
    reducer: {
      name: 'milestoneCategories',
      initialState: []
    }
  },

  addMilestoneCategory: {
    url: '/school/categories/milestone/',
    method: 'POST',
    transformResponse: res => res.category,
    reducer: {
      name: 'milestoneCategories',
      type: 'add'
    }
  },

  updateMilestoneCategory: {
    url: '/school/categories/milestone/:id',
    method: 'PATCH',
    transformResponse: res => res.category,
    reducer: {
      name: 'milestoneCategories',
      type: 'patchItem'
    }
  },

  deleteMilestoneCategory: {
    url: '/school/categories/milestone/:id',
    method: 'DELETE',
    reducer: {
      name: 'milestoneCategories',
      type: 'removeItem'
    }
  },

  lessonCategories: {
    url: '/school/lessons/categories',
    transformResponse: res => res.categories,
    reducer: {
      name: 'lessonCategories',
      initialState: []
    }
  },

  addLessonCategory: {
    url: '/school/lessons/categories/',
    method: 'POST',
    transformResponse: res => res.category,
    reducer: {
      name: 'lessonCategories',
      type: 'add'
    }
  },

  updateLessonCategory: {
    url: '/school/lessons/categories/:id',
    method: 'PATCH',
    transformResponse: res => res.category,
    reducer: {
      name: 'lessonCategories',
      type: 'patchItem'
    }
  },

  deleteLessonCategory: {
    url: '/school/lessons/categories/:id',
    method: 'DELETE',
    reducer: {
      name: 'lessonCategories',
      type: 'removeItem'
    }
  },

  milestoneProgresses: {
    url: '/school/milestones/progresses',
    transformResponse: res => res.progresses,
    reducer: {
      name: 'milestoneProgresses',
      initialState: []
    }
  },

  reorderMilestoneProgresses: {
    url: '/school/milestones/progresses/reorder',
    method: 'post'
  },

  updateMilestoneProgress: {
    url: '/school/milestones/progresses/:id',
    method: 'patch',
    transformResponse: res => res.progress,
    reducer: {
      name: 'milestoneProgresses',
      type: 'patchItem'
    }
  },

  addMilestoneProgress: {
    url: '/school/milestones/progresses/',
    method: 'post',
    transformResponse: res => res.progress,
    reducer: {
      name: 'milestoneProgresses',
      type: 'add'
    }
  },

  deleteMilestoneProgress: {
    url: '/school/milestones/progresses/:id',
    method: 'delete',
    reducer: {
      name: 'milestoneProgresses',
      type: 'removeItem'
    }
  },

  assessments: {
    url: '/school/assessments',
    reducer: {
      name: 'assessments',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        kids: [],
        assessments: {}
      }
    }
  },

  assesmentsShare: {
    url: '/school/assessments/share',
    method: 'post'
  },

  assessmentExport: {
    url: '/school/assessment/:id/pdf',
    transformResponse: res => res.pdf
  },

  kidAssessment: {
    url: '/school/assessment/:id',
    reducer: {
      name: 'kidAssessment',
      initialState: {
        standards: [],
        measures: []
      }
    }
  },

  kidAssessmentObservations: {
    url: '/school/assessment/:id/observations',
    transformResponse: res => res.observations,
    reducer: {
      name: 'kidAssessmentObservations',
      initialState: []
    }
  },

  updateKidAssessmentObservations: {
    url: '/school/assessment/:id/manage_observations',
    method: 'POST'
  },

  lesson: {
    url: '/school/lessons/:id',
    transformResponse: res => res.lesson
  },

  lessons: {
    url: '/school/lessons',
    reducer: {
      name: 'lessons',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        lessons: []
      }
    }
  },

  createLesson: {
    url: '/school/lessons',
    method: 'POST',
    transformResponse: res => res.lesson,
    reducer: {
      name: 'lessons',
      type: 'add',
      options: {
        collectionName: 'lessons'
      }
    }
  },

  updateLesson: {
    url: '/school/lessons/:id',
    method: 'PATCH',
    transformResponse: res => res.lesson,
    reducer: {
      name: 'lessons',
      type: 'patchItem',
      options: {
        collectionName: 'lessons'
      }
    }
  },

  deleteLesson: {
    url: '/school/lessons/:id',
    method: 'DELETE',
    transformResponse: res => res.lesson,
    reducer: {
      name: 'lessons',
      type: 'removeItem',
      options: {
        collectionName: 'lessons'
      }
    }
  },

  lessonPlan: {
    url: '/school/lessons/plans',
    reducer: {
      name: 'lessonPlan',
      initialState: {}
    }
  },

  lessonPlanAdd: {
    url: '/school/lessons/plans/add_lesson',
    method: 'POST'
  },

  lessonPlanDelete: {
    url: '/school/lessons/plans/remove_lesson',
    method: 'POST'
  },

  lessonPlanPDF: {
    url: '/school/lessons/plans/pdf',
    transformResponse: res => res.pdf
  },

  copyLessonPlan: {
    url: '/school/lessons/plans/copy',
    method: 'POST'
  },

  meal: {
    url: '/school/meals/:id',
    transformResponse: res => res.meal
  },

  meals: {
    url: '/school/meals',
    reducer: {
      name: 'meals',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        meals: []
      }
    }
  },

  createMeal: {
    url: '/school/meals',
    method: 'POST',
    transformResponse: res => res.meal,
    reducer: {
      name: 'meals',
      type: 'add',
      options: {
        collectionName: 'meals'
      }
    }
  },

  updateMeal: {
    url: '/school/meals/:id',
    method: 'PATCH',
    transformResponse: res => res.meal,
    reducer: {
      name: 'meals',
      type: 'patchItem',
      options: {
        collectionName: 'meals'
      }
    }
  },

  deleteMeal: {
    url: '/school/meals/:id',
    method: 'DELETE',
    transformResponse: res => res.meal,
    reducer: {
      name: 'meals',
      type: 'removeItem',
      options: {
        collectionName: 'meals'
      }
    }
  },

  mealLabels: {
    url: '/school/labels/meal',
    transformResponse: res => res.labels,
    reducer: {
      name: 'mealLabels',
      initialState: []
    }
  },

  updateMealLabel: {
    url: '/school/labels/meal/:id',
    method: 'patch',
    transformResponse: res => res.label,
    reducer: {
      name: 'mealLabels',
      type: 'patchItem'
    }
  },

  addMealLabel: {
    url: '/school/labels/meal/',
    method: 'post',
    transformResponse: res => res.label,
    reducer: {
      name: 'mealLabels',
      type: 'add'
    }
  },

  deleteMealLabel: {
    url: '/school/labels/meal/:id',
    method: 'delete',
    reducer: {
      name: 'mealLabels',
      type: 'removeItem'
    }
  },

  mealPlan: {
    url: '/school/meals/plans',
    reducer: {
      name: 'mealPlan',
      initialState: {}
    }
  },

  mealPlanAdd: {
    url: '/school/meals/plans/add_meal',
    method: 'POST'
  },

  mealPlanDelete: {
    url: '/school/meals/plans/remove_meal',
    method: 'POST'
  },

  mealPlanPDF: {
    url: '/school/meals/plans/pdf',
    transformResponse: res => res.pdf
  },

  mealPlanShare: {
    url: '/school/meals/plans/share',
    method: 'POST'
  },

  copyMealPlan: {
    url: '/school/meals/plans/copy',
    method: 'POST'
  },

  crmTasks: {
    url: '/school/enrollments/tasks',
    reducer: {
      name: 'crmTasks',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        tasks: []
      }
    }
  },

  createCrmNote: {
    url: '/school/enrollments/:enrollment_id/notes',
    method: 'POST',
    transformResponse: res => res.note
  },

  updateCrmNote: {
    url: '/school/enrollments/:enrollment_id/notes/:id',
    method: 'PATCH',
    transformResponse: res => res.note
  },

  deleteCrmNote: {
    url: '/school/enrollments/:enrollment_id/notes/:id',
    method: 'DELETE',
    transformResponse: res => res.note
  },

  createCrmTask: {
    url: '/school/enrollments/tasks',
    method: 'POST',
    transformResponse: res => res.task,
    reducer: {
      name: 'crmTasks',
      type: 'add',
      options: {
        collectionName: 'tasks'
      }
    }
  },

  updateCrmTask: {
    url: '/school/enrollments/tasks/:id',
    method: 'PATCH',
    transformResponse: res => res.task,
    reducer: {
      name: 'crmTasks',
      type: 'patchItem',
      options: {
        collectionName: 'tasks'
      }
    }
  },

  deleteCrmTask: {
    url: '/school/enrollments/tasks/:id',
    method: 'DELETE',
    transformResponse: res => res.task,
    reducer: {
      name: 'crmTasks',
      type: 'removeItem',
      options: {
        collectionName: 'tasks'
      }
    }
  },

  customReports: {
    url: '/school/custom_reports',
    transformResponse: res => res.reports,
    reducer: {
      name: 'customReports',
      initialState: {}
    }
  },

  enrollmentsMessages: {
    url: '/school/enrollments/messages',
    reducer: {
      name: 'enrollmentsMessages',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        messages: []
      }
    }
  },

  enrollmentsMessageTest: {
    url: '/school/enrollments/messages/test_email',
    method: 'POST'
  },

  enrollmentsMessageSave: {
    url: '/school/enrollments/messages',
    method: 'post',
    transformResponse: res => res.message,
    reducer: {
      name: 'enrollmentsMessages',
      type: 'add',
      options: {
        collectionName: 'messages'
      }
    }
  },

  enrollmentsMessageUpdate: {
    url: '/school/enrollments/messages/:id',
    method: 'patch',
    transformResponse: res => res.message,
    reducer: {
      name: 'enrollmentsMessages',
      type: 'patchItem',
      options: {
        collectionName: 'messages'
      }
    }
  },

  enrollmentsMessageDelete: {
    url: '/school/enrollments/messages/:id',
    method: 'DELETE',
    reducer: {
      name: 'enrollmentsMessages',
      type: 'removeItem',
      options: {
        collectionName: 'messages'
      }
    }
  },

  enrollmentMessageEnrollments: {
    url: '/school/enrollments/messages/:id/enrollments',
    method: 'GET'
  },

  enrollmentsMessageTemplates: {
    url: '/school/enrollments/messages',
    reducer: {
      name: 'enrollmentsMessageTemplates',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        messages: []
      }
    }
  },

  updateEnrollmentsMessageTemplate: {
    url: '/school/enrollments/messages/:id',
    method: 'patch',
    transformResponse: res => res.message,
    reducer: {
      name: 'enrollmentsMessageTemplates',
      type: 'patchItem',
      options: {
        collectionName: 'messages'
      }
    }
  },

  addEnrollmentsMessageTemplate: {
    url: '/school/enrollments/messages',
    method: 'post',
    transformResponse: res => res.message,
    reducer: {
      name: 'enrollmentsMessageTemplates',
      type: 'add',
      options: {
        collectionName: 'messages'
      }
    }
  },

  deleteEnrollmentsMessageTemplate: {
    url: '/school/enrollments/messages/:id',
    method: 'delete',
    reducer: {
      name: 'enrollmentsMessageTemplates',
      type: 'removeItem',
      options: {
        collectionName: 'messages'
      }
    }
  },

  enrollmentsSentMessages: {
    url: '/school/enrollments/sent_messages',
    reducer: {
      name: 'enrollmentsSentMessages',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        sent_messages: []
      }
    }
  },

  enrollmentsSentMessagesStats: {
    url: '/school/enrollments/sent_messages/stats',
    transformResponse: res => res.stats,
    reducer: {
      name: 'enrollmentsSentMessagesStats',
      initialState: {}
    }
  },

  emergencyReport: {
    url: '/school/kids/emergency_pdf'
  },

  printAllergyCards: {
    url: '/school/kids/allergy_pdf'
  },

  printEmergencyCards: {
    url: '/school/kids/emergency_cards_pdf'
  },

  automationRule: {
    url: '/school/automation/rules/:id',
    transformResponse: res => res.rule
  },

  automationRules: {
    url: '/school/automation/rules',
    transformResponse: res => res.rules,
    reducer: {
      name: 'automationRules',
      initialState: []
    }
  },

  createAutomationRule: {
    url: '/school/automation/rules',
    method: 'POST',
    transformResponse: res => res.rule,
    reducer: {
      name: 'automationRules',
      type: 'add'
    }
  },

  updateAutomationRule: {
    url: '/school/automation/rules/:id',
    method: 'PATCH',
    transformResponse: res => res.rule,
    reducer: {
      name: 'automationRules',
      type: 'patchItem'
    }
  },

  deleteAutomationRule: {
    url: '/school/automation/rules/:id',
    method: 'DELETE',
    transformResponse: res => res.rule,
    reducer: {
      name: 'automationRules',
      type: 'removeItem'
    }
  },

  automationActions: {
    url: '/school/automation/rules/:ruleId/actions',
    transformResponse: res => res.actions,
    reducer: {
      name: 'automationActions',
      initialState: []
    }
  },

  createAutomationAction: {
    url: '/school/automation/rules/:ruleId/actions',
    method: 'POST',
    transformResponse: res => res.action,
    reducer: {
      name: 'automationActions',
      type: 'add'
    }
  },

  updateAutomationAction: {
    url: '/school/automation/rules/:ruleId/actions/:id',
    method: 'PATCH',
    transformResponse: res => res.action,
    reducer: {
      name: 'automationActions',
      type: 'patchItem'
    }
  },

  deleteAutomationAction: {
    url: '/school/automation/rules/:ruleId/actions/:id',
    method: 'DELETE',
    transformResponse: res => res.action,
    reducer: {
      name: 'automationActions',
      type: 'removeItem'
    }
  },

  unapprovedConversations: {
    url: '/school/conversations/unapproved',
    reducer: {
      name: 'unapprovedConversations',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        messages: []
      }
    }
  },

  unapprovedMessageCount: {
    url: '/school/conversations/unapproved_count',
    reducer: {
      name: 'unapprovedMessageCount',
      initialState: {
        unapproved_messages_count: 0
      }
    }
  },

  conversations: {
    url: '/school/conversations',
    reducer: {
      name: 'conversations',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        unapproved_messages_count: 0,
        messages: []
      }
    }
  },

  staffInboxMessages: {
    url: '/school/conversations/staff_messages',
    reducer: {
      name: 'staffInboxMessages',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        messages: []
      }
    }
  },

  staffSentMessages: {
    url: '/school/conversations/staff_messages',
    reducer: {
      name: 'staffSentMessages',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        messages: []
      }
    }
  },

  conversation: {
    url: '/school/conversation',
    reducer: {
      name: 'conversation',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        messages: [],
        kid: {}
      }
    }
  },

  sendMessage: {
    url: '/school/conversations',
    method: 'POST'
  },

  edocsStats: {
    url: '/school/electronic_documents/stats',
    transformResponse: res => res.stats,
    method: 'GET',
    reducer: {
      name: 'edocsStats',
      initialState: {}
    }
  },

  edocsPermissionCheck: {
    url: '/school/electronic_documents/stats',
    method: 'GET'
  },

  edoc: {
    url: '/school/electronic_documents/:id',
    transformResponse: res => res.document,
    reducer: {
      name: 'edoc',
      initialState: {}
    }
  },

  signerEdoc: {
    url: '/electronic_documents',
    transformResponse: res => res.document,
    reducer: {
      name: 'signerEdoc'
    }
  },

  signerEdocSchool: {
    url: '/electronic_documents',
    transformResponse: res => res.school,
    reducer: {
      name: 'signerEdocSchool'
    }
  },

  updateSignerEdoc: {
    url: '/electronic_documents',
    method: 'patch',
    transformResponse: res => res.document,
    reducer: {
      name: 'signerEdoc',
      options: {
        collectionName: 'document'
      }
    }
  },

  createEdoc: {
    url: '/school/electronic_documents',
    method: 'post',
    transformResponse: res => res.document,
    reducer: {
      name: 'edoc'
    }
  },

  edocSendReminder: {
    url: '/school/electronic_documents/:id/send_reminder',
    method: 'post'
  },

  updateEdoc: {
    url: '/school/electronic_documents/:id',
    method: 'patch',
    transformResponse: res => res.document,
    reducer: {
      name: 'edoc'
    }
  },

  edocs: {
    url: '/school/electronic_documents',
    reducer: {
      name: 'edocs',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        documents: []
      }
    }
  },

  addEdocsDocument: {
    url: '/school/electronic_documents',
    method: 'post'
  },

  deleteEdocsDocument: {
    url: '/school/electronic_documents/:id',
    method: 'delete',
    reducer: {
      name: 'edocs',
      type: 'removeItem',
      options: {
        collectionName: 'documents'
      }
    }
  },

  teacherDocs: {
    url: '/school/teachers/:teacherId/documents',
    reducer: {
      name: 'teacherDocs',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        documents: []
      }
    }
  },

  teacherDoc: {
    url: '/school/teachers/:teacherId/documents/:id',
    transformResponse: res => res.document
  },

  createTeacherDoc: {
    url: '/school/teachers/:teacherId/documents',
    method: 'POST',
    transformResponse: res => res.document,
    reducer: {
      name: 'teacherDocs',
      type: 'add',
      options: {
        collectionName: 'documents'
      }
    }
  },

  updateTeacherDoc: {
    url: '/school/teachers/:teacherId/documents/:id',
    method: 'PATCH',
    transformResponse: res => res.document,
    reducer: {
      name: 'teacherDocs',
      type: 'patchItem',
      options: {
        collectionName: 'documents'
      }
    }
  },

  deleteTeacherDoc: {
    url: '/school/teachers/:teacherId/documents/:id',
    method: 'DELETE',
    reducer: {
      name: 'teacherDocs',
      type: 'removeItem',
      options: {
        collectionName: 'documents'
      }
    }
  },

  enrollmentDocs: {
    url: '/school/enrollments/:enrollmentId/documents',
    reducer: {
      name: 'enrollmentDocs',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        documents: []
      }
    }
  },

  enrollmentDoc: {
    url: '/school/enrollments/:enrollmentId/documents/:id',
    transformResponse: res => res.document
  },

  createEnrollmentDoc: {
    url: '/school/enrollments/:enrollmentId/documents',
    method: 'POST',
    transformResponse: res => res.document,
    reducer: {
      name: 'enrollmentDocs',
      type: 'add',
      options: {
        collectionName: 'documents'
      }
    }
  },

  updateEnrollmentDoc: {
    url: '/school/enrollments/:enrollmentId/documents/:id',
    method: 'PATCH',
    transformResponse: res => res.document,
    reducer: {
      name: 'enrollmentDocs',
      type: 'patchItem',
      options: {
        collectionName: 'documents'
      }
    }
  },

  deleteEnrollmentDoc: {
    url: '/school/enrollments/:enrollmentId/documents/:id',
    method: 'DELETE',
    reducer: {
      name: 'enrollmentDocs',
      type: 'removeItem',
      options: {
        collectionName: 'documents'
      }
    }
  },

  pinSearch: {
    url: '/pins/search'
  },

  staffPinSearch: {
    url: '/siso_teachers/pin_search'
  },

  staffPinSignin: {
    url: '/siso_teachers',
    method: 'POST'
  },

  staffPinSignOut: {
    url: '/siso_teachers/:id',
    method: 'PUT'
  },

  batchKidsSignIn: {
    url: '/siso_kids/batch_signin',
    method: 'POST'
  },

  batchKidsSignOut: {
    url: '/siso_kids/batch_signout',
    method: 'POST'
  },

  programs: {
    url: '/school/programs',
    reducer: {
      name: 'programs',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        programs: []
      }
    }
  },

  allActivePrograms: {
    url: '/school/programs',
    transformRequest: req => ({ ...req, per_page: 1000, enabled: true }),
    transformResponse: res => res.programs,
    reducer: {
      name: 'allActivePrograms',
      initialState: []
    }
  },

  program: {
    url: '/school/programs/:id',
    transformResponse: res => res.program,
    reducer: {
      name: 'program',
      initialState: null
    }
  },

  deactivatedPrograms: {
    url: '/school/programs',
    reducer: {
      name: 'deactivatedPrograms',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        programs: []
      }
    }
  },

  updateProgram: {
    url: '/school/programs/:id',
    method: 'patch',
    transformResponse: res => res.program,
    reducer: {
      name: 'programs',
      type: 'patchItem',
      options: {
        collectionName: 'programs'
      }
    }
  },

  activateProgram: {
    url: '/school/programs/:id',
    method: 'PATCH',
    transformRequest: req => ({ ...req, program: { enabled: true } }),
    transformResponse: res => res.program,
    reducer: {
      name: 'deactivatedPrograms',
      type: 'removeItem',
      options: {
        collectionName: 'programs'
      }
    }
  },

  deactivateProgram: {
    url: '/school/programs/:id/deactivate',
    method: 'POST',
    transformResponse: res => res.program,
    reducer: {
      name: 'programs',
      type: 'removeItem',
      options: {
        collectionName: 'programs'
      }
    }
  },

  batchUpdateProgramSessions: {
    method: 'POST',
    url: '/school/programs/program_sessions/batch_update'
  },

  syncPESettings: {
    url: '/school/sync_settings',
    transformResponse: res => res.sync_settings,
    reducer: {
      name: 'syncPESetting',
      initialState: {}
    }
  },

  syncPERuns: {
    url: '/school/sync_settings/:id/sync_runs',
    method: 'POST'
  },

  syncAllSettings: {
    url: '/school/sync_settings',
    transformResponse: res => res.sync_settings,
    reducer: {
      name: 'syncAllSetting',
      initialState: {}
    }
  },

  createAllSyncSettings: {
    url: '/school/sync_settings/:syncType',
    method: 'POST'
  },

  updateSyncSettings: {
    url: '/school/sync_settings/kinder_systems/:syncSettingId',
    method: 'PUT'
  },

  deleteSyncSettings: {
    url: '/school/sync_settings/kinder_systems/:syncSettingId',
    method: 'DELETE'
  },

  syncAllRuns: {
    url: '/school/sync_settings/:syncSettingId/sync_runs',
    transformResponse: res => res.sync_runs,
    reducer: {
      name: 'syncAllRuns'
    }
  },

  createAllSyncRun: {
    url: '/school/sync_settings/:syncSettingId/sync_runs',
    method: 'POST'
  },

  kinderSystemsTransmissionSummary: {
    url: '/school/sync_runs/:syncRunId/ks_transmission_receipt/transmission_summary'
  },

  kinderSystemsStudentSummaries: {
    url: '/school/sync_runs/:syncRunId/ks_transmission_receipt/student_summaries'
  },

  kinderSystemsProviders: {
    url: '/school/kinder_systems/providers/fetch',
    method: 'POST'
  },

  kinderSystemsConfigurations: {
    url: '/school/kinder_systems/configurations',
    method: 'GET'
  },

  kinderSystemsAgencies: {
    url: '/school/kinder_systems/agencies/fetch',
    method: 'POST'
  },

  kinderSystemsAgenciesAll: {
    url: '/school/kinder_systems/agencies/fetch_all',
    method: 'POST'
  },

  integratorGrantPermissions: {
    url: '/site_integrations',
    method: 'POST'
  },

  integratorDisable: {
    url: '/site_integrations/:school_id/:integrator_id',
    method: 'DELETE'
  },

  fetchIntegrators: {
    url: '/sites/:school_id/integrations'
  },

  integratorFetchMetadata: {
    url: '/integrators/:id'
  },

  createProgram: {
    url: '/school/programs',
    method: 'POST'
  },

  programStudents: {
    url: '/school/programs/kids',
    reducer: {
      name: 'programStudents',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        kids: []
      }
    }
  },

  batchUpdateProgramStudents: {
    url: '/school/programs/session_kids/batch_update',
    method: 'POST'
  },

  reassignProgramStudents: {
    url: '/school/programs/session_kids/reassign',
    method: 'POST'
  },

  batchDeleteProgramStudents: {
    url: '/school/programs/session_kids/batch_delete',
    method: 'POST'
  },

  batchUpdateProgramStudentRooms: {
    url: '/school/programs/section_kids/batch_update',
    method: 'POST'
  },

  programStaff: {
    url: '/school/programs/teachers',
    reducer: {
      name: 'programStaff',
      initialState: {
        page: 0,
        per_page: 0,
        total: 0,
        teachers: []
      }
    }
  },

  batchUpdateProgramRooms: {
    url: '/school/programs/sections/batch_update',
    method: 'POST'
  },

  batchUpdateProgramStaff: {
    url: '/school/programs/session_teachers/batch_update',
    method: 'POST'
  },

  batchDeleteProgramStaff: {
    url: '/school/programs/session_teachers/batch_delete',
    method: 'POST'
  },

  reassignProgramStaff: {
    url: '/school/programs/session_teachers/reassign',
    method: 'POST'
  },

  batchUpdateProgramStaffRooms: {
    url: '/school/programs/section_teachers/batch_update',
    method: 'POST'
  },

  programEnrollableStudents: {
    url: '/school/programs/:id/enrollable_kids',
    transformResponse: res => res.enrollable_kids,
    reducer: {
      name: 'programEnrollableStudents',
      initialState: []
    }
  },

  programEnrollableStaff: {
    url: '/school/programs/:id/enrollable_teachers',
    transformResponse: res => res.enrollable_teachers,
    reducer: {
      name: 'programEnrollableStaff',
      initialState: []
    }
  },

  parentRelationTypes: {
    url: '/parent/relation_types',
    transformResponse: res => res.relation_types,
    reducer: {
      name: 'relationTypes',
      initialState: []
    }
  },

  schoolRelationTypes: {
    url: '/school/relation_types',
    transformResponse: res => res.relation_types,
    reducer: {
      name: 'relationTypes',
      initialState: []
    }
  },

  addSchoolRelationType: {
    url: '/school/relation_types/',
    method: 'POST',
    transformResponse: res => res.relation_type,
    reducer: {
      name: 'relationTypes',
      type: 'add'
    }
  },

  updateSchoolRelationType: {
    url: '/school/relation_types/:id',
    method: 'PATCH',
    transformResponse: res => res.relation_type,
    reducer: {
      name: 'relationTypes',
      type: 'patchItem'
    }
  },

  deleteSchoolRelationType: {
    url: '/school/relation_types/:id',
    method: 'DELETE',
    reducer: {
      name: 'relationTypes',
      type: 'removeItem'
    }
  },

  programInsights: {
    url: '/school/insights/sections/:id/enrollments',
    transformResponse: res => res.section,
    reducer: {
      name: 'programInsights',
      type: 'addOrPatchItem',
      initialState: []
    },
    cancellable: false
  },

  registrationForms: {
    url: '/school/registrations/forms',
    reducer: {
      name: 'registrationForms',
      initialState: []
    }
  },

  registrationFormAdmin: {
    url: '/school/registrations/forms/:id/admin_show',
    transformResponse: res => res.form,
    reducer: {
      name: 'registrationForm',
      initialState: null
    }
  },

  registrationForm: {
    url: '/school/registrations/forms/:id',
    transformResponse: res => res.form,
    reducer: {
      name: 'registrationForm',
      initialState: null
    }
  },

  createRegistrationForm: {
    url: '/school/registrations/forms',
    method: 'POST'
  },

  updateRegistrationForm: {
    url: '/school/registrations/forms/:id',
    method: 'PATCH'
  },

  updateRegistrationFormFee: {
    url: '/school/registrations/forms/:id/fee_plans',
    method: 'POST'
  },

  schoolRegFormFee: {
    url: '/school/registrations/forms/:id/fee',
    method: 'post'
  },

  batchUpdateRegistrationFormFields: {
    url: '/school/registrations/forms/:id/fields/batch_update',
    method: 'POST'
  },

  copyRegistrationForm: {
    url: '/school/registrations/forms/:id/copy',
    method: 'POST'
  },

  registrationFormTabs: {
    url: '/school/registrations/forms/:id/tabs',
    transformResponse: res => res.tabs,
    reducer: {
      name: 'registrationFormTabs',
      initialState: null
    }
  },

  openRegistrationForm: {
    url: '/school/registrations/forms/:id/open',
    method: 'POST'
  },

  createRegistrationFormResponses: {
    url: '/school/registrations/forms/:form_id/responses/batch_create',
    method: 'POST'
  },

  batchUpdateRegistrationFormResponses: {
    url: '/school/registrations/forms/:form_id/responses/batch_update',
    method: 'POST'
  },

  registrationFormResponseSummary: {
    url: '/school/registrations/forms/:form_id/respondents/:id/summary'
  },

  submitRegistrationFormResponse: {
    url: '/school/registrations/forms/:form_id/respondents/:id/submit',
    method: 'POST'
  },

  createPaymentForRegistrationFormResponse: {
    url: '/school/registrations/forms/:form_id/respondents/:id/create_payment',
    method: 'POST'
  },

  registrants: {
    url: '/school/registrations/forms/registrants',
    reducer: {
      name: 'registrants',
      initialState: null
    }
  },

  registrantMatches: {
    url: '/school/registrations/forms/registrants/:id/matches',
    reducer: {
      name: 'registrantMatches',
      initialState: null
    }
  },

  registrantSummary: {
    url: '/school/registrations/forms/registrants/:id/summary',
    reducer: {
      name: 'registrantSummary',
      initialState: null
    }
  },

  updateRegistrant: {
    url: '/school/registrations/forms/registrants/:id',
    method: 'PATCH'
  },

  deleteRegistrant: {
    url: '/school/registrations/forms/registrants/:id',
    method: 'DELETE'
  },

  refundRegistrant: {
    url: '/school/registrations/forms/registrants/:id/refund',
    method: 'POST'
  },

  registrantReview: {
    url: '/school/registrations/forms/registrants/:id/review',
    reducer: {
      name: 'registrantReview',
      initialState: null
    }
  },

  approveRegistrant: {
    url: '/school/registrations/forms/registrants/:id/approve',
    method: 'POST'
  }
};

export default apiRoutes;
