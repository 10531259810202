const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

// transforms
// { { "TUE": { "am": true, "pm": true } }
// into
// [ { "day_index": 1, "order_index": 0 }, { "day_index": 1, "order_index": 1 } ]

export default function(schedule) {
  return Object.entries(schedule).flatMap(([day, ampm]) => {
    return Object.entries(ampm)
      .filter(([, bool]) => bool)
      ?.flatMap(([time]) => {
        return {
          day_index: days.indexOf(day),
          order_index: time == 'am' ? 0 : 1
        };
      });
  });
}
