import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import validationText from '../validationText';
import { TimeInput as PlainTimeInput } from 'components';
import './style.scss';
import withContext, { FormContext } from 'hocs/withContext';

class TimeInput extends Component {
  static propTypes = {
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,

    // If true, no invalid styles are applied to the field
    // Provided validations will still work, it only disables the styling
    disableInvalid: PropTypes.bool,

    // If provided, it will render a label above the field
    label: PropTypes.node,

    placeholder: PropTypes.string,

    // Linked input name (e.g. `sign_in_time`).
    // enforcing this component value after linked input
    isAfter: PropTypes.string,
    asterisk: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.initialValue = this.props.defaultValue || undefined;
  }

  UNSAFE_componentWillMount() {
    this.props.context.init(this.props.name, this.initialValue, this.initValidations());
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  componentDidUpdate(prevProps) {
    if (this.props.required !== prevProps.required) {
      this.props.context.updateValidations(this.props.name, this.initValidations());
    }
  }

  initValidations() {
    const validations = {};
    const { disabled, required, isAfter, name } = this.props;

    if (!disabled) {
      if (required) {
        validations.isNotEmpty = null;
      }

      if (isAfter) {
        validations.isAfterTime = null;
        this.props.context.link(name, isAfter);
      }

      validations.isTime = null;
    }

    return validations;
  }

  /**
   * Update value of a field
   *
   * @param value - a new value for a field with the name === this.props.name
   */
  @bind
  handleChange(value) {
    this.props.context.update(this.props.name, value);
  }

  /**
   * Update the validation state on blur
   */
  @bind
  handleBlur() {
    if (this.props.context.validateOn !== 'submit') {
      this.props.context.validate(this.props.name);
    }
  }

  /**
   * Get a text for an invalid field to render.
   *
   * @return {string} - a text which represents a validation error
   */
  getValidationText() {
    const { name } = this.props;
    const { errors, validations } = this.props.context;

    if (errors[name]) {
      return errors[name].join('; ');
    }

    if (!validations[name]) {
      return '';
    }

    return Object.keys(validations[name])
      .filter(type => validations[name][type] === false)
      .map(type => {
        if (typeof validationText[type] === 'function') {
          return validationText[type](this.props[type]);
        }

        return validationText[type];
      })
      .join('; ');
  }

  render() {
    const { label, disableInvalid, className, placeholder, asterisk, ...restProps } = this.props;
    const invalidText = this.getValidationText();
    const isInvalid = invalidText && !disableInvalid;

    const formTimeInputCN = classNames({
      [className]: Boolean(className),
      'form-time-input': true,
      'form-time-input--invalid': Boolean(isInvalid)
    });

    return (
      <div className={formTimeInputCN}>
        {label && (
          <label className="form__label">
            {label} {asterisk && <span className="form__asterisk">*</span>}
          </label>
        )}

        <PlainTimeInput
          onChange={this.handleChange}
          defaultValue={this.initialValue}
          onBlur={this.handleBlur}
          placeholder={placeholder}
          {...restProps}
        />

        {isInvalid && <div className="form-input__validation-text">{invalidText}</div>}
      </div>
    );
  }
}

export default withContext(FormContext)(TimeInput);
