export default (data, activity) => {
  return {
    id: activity ? activity.id : undefined,

    video: {
      caption: data.caption
    },

    daily_activity: {
      is_staff_only: data.staffOnly
    }
  };
};
