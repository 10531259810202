import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow, Circle } from 'react-google-maps';

function GeofenceMap({ lat, lng, radius, address, onChange, hasDefaultLocation }) {
  const [open, setOpen] = useState(false);

  return (
    <GoogleMap defaultZoom={hasDefaultLocation ? 17 : 14} center={{ lat, lng }}>
      <Marker
        position={{ lat, lng }}
        draggable
        onDragEnd={e => onChange({ lat: e.latLng.lat(), lng: e.latLng.lng() })}
        onClick={() => setOpen(!open)}
      >
        {open && (
          <InfoWindow onCloseClick={() => setOpen(false)}>
            <div>
              <div className="geofence__info">{address}</div>
              <div className="geofence__info-lat">
                {lat}, {lng}
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
      <Circle
        center={{ lat, lng }}
        radius={radius}
        options={{
          strokeColor: '#ff0000'
        }}
      />
    </GoogleMap>
  );
}

GeofenceMap.propTypes = {
  hasDefaultLocation: PropTypes.bool,
  lat: PropTypes.number,
  lng: PropTypes.number,
  radius: PropTypes.number,
  address: PropTypes.string,
  onChange: PropTypes.func
};

export default withScriptjs(withGoogleMap(GeofenceMap));
