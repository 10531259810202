import axios from 'axios';
import FileSaver from 'file-saver';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import { toPattern } from './masker';
import moment from 'moment';
import { PERMISSION_MAP } from 'constants/permissions';
import CHECKR_EVENT_TYPES from 'constants/checkrEventTypes';
import SCHOOL_QUICK_ACTIONS from 'constants/schoolQuickActions';
import { PROGRAM_DAY_CODES } from 'constants/dayCodes';
import { eventCategoriesMap as CHECKR_EVENT_CATEGORIES_MAP } from 'constants/checkrEventTypes';
import SYNC_TYPES, { SYNC_TYPES_COLORS } from 'constants/syncTypes';
import some from 'lodash/some';

const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
};

const validateNaturalNumber = value => {
  const numberValue = Number(value);
  return numberValue === Math.round(numberValue) && numberValue > 0;
};

const capitalize = str => {
  if (!str) {
    return '';
  }

  return str[0].toUpperCase() + str.slice(1);
};

const generateColor = str => {
  // Thanks to https://stackoverflow.com/a/16348977
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }

  return color;
};

const applyFilters = (students, filters, customCondition) => {
  const term = (filters.search || '').toLowerCase().trim();
  return students.filter(student => {
    let matchesParentsSearch = false;

    if (filters.includeParents && student.parents.length > 0 && term.length > 0) {
      const parentNames = student.parents.map(parent => parent.name.toLowerCase().trim());
      matchesParentsSearch = parentNames.filter(name => name.indexOf(term) >= 0).length > 0;
    }

    const studentName = student.name.toLowerCase().trim();
    const matchesSearch = term.length === 0 || studentName.indexOf(term) >= 0 || matchesParentsSearch;
    const matchesRoom =
      !filters.roomId || student.current_section_id === filters.roomId || student.section_id === filters.roomId;
    const matchesStatus = !filters.registration_status || student.registration_status === filters.registration_status;
    const matchesSignedStatus = filters.isSignedIn === undefined || student.is_signed_in === filters.isSignedIn;
    const matchesTags =
      !filters.tagIds ||
      filters.tagIds.length === 0 ||
      filters.tagIds.some(tagId => student.tag_ids && student.tag_ids.includes(tagId));
    const matchesCustomCondition = !customCondition || customCondition(student, filters);

    return (
      matchesSearch && matchesRoom && matchesStatus && matchesTags && matchesSignedStatus && matchesCustomCondition
    );
  });
};

const applyFiltersStaff = (staff, filters, customCondition) => {
  const term = (filters.search || '').toLowerCase().trim();

  return staff.filter(staffItem => {
    let matchesParentsSearch = false;

    const staffName = staffItem.name.toLowerCase().trim();
    const matchesSearch = term.length === 0 || staffName.indexOf(term) >= 0 || matchesParentsSearch;
    const matchesRoom =
      !filters.roomId ||
      staffItem.section_id === filters.roomId ||
      staffItem.secondary_section_ids?.includes(filters.roomId);
    const matchesCustomCondition = !customCondition || customCondition(staffItem, filters);

    return matchesSearch && matchesRoom && matchesCustomCondition;
  });
};

const applyFiltersLeads = (leads, filters, customCondition) => {
  const term = (filters.search || '').toLowerCase().trim();
  return leads.filter(lead => {
    const leadName = lead.name.toLowerCase().trim();
    const matchesSearch =
      term.length === 0 ||
      // Search checks student and parents
      leadName.indexOf(term) >= 0 ||
      lead.carers.some(c =>
        [c.first_name, c.last_name]
          .join(' ')
          .toLowerCase()
          .trim()
          .includes(term)
      );

    const matchesRoom = !filters.roomId || lead.section_id === filters.roomId;
    const matchesStage = !filters.stageId || lead.state_id === filters.stageId;
    const matchesCustomCondition = !customCondition || customCondition(lead, filters);

    let matchesAge = true;

    if (filters.age) {
      const { option, months } = filters.age;
      const ageInMonths = moment().diff(moment(lead.dob), 'months');

      switch (option) {
        case 'less': {
          matchesAge = ageInMonths < parseInt(months[0]);
          break;
        }
        case 'greater': {
          matchesAge = ageInMonths > parseInt(months[0]);
          break;
        }
        case 'range': {
          matchesAge = ageInMonths > parseInt(months[0]) && ageInMonths < parseInt(months[1]);
          break;
        }
      }
    }

    const matchesCreationFrom =
      !filters.creationDate?.[0] ||
      (lead.created_at && moment(lead.created_at).isSameOrAfter(moment(filters.creationDate[0]), 'day'));
    const matchesCreationTo =
      !filters.creationDate?.[1] ||
      (lead.created_at && moment(lead.created_at).isSameOrBefore(moment(filters.creationDate[1]), 'day'));
    const matchesExpectedFrom =
      !filters.expectedDate?.[0] ||
      (lead.enroll_date && moment(lead.enroll_date).isSameOrAfter(moment(filters.expectedDate[0]), 'day'));
    const matchesExpectedTo =
      !filters.expectedDate?.[1] ||
      (lead.enroll_date && moment(lead.enroll_date).isSameOrBefore(moment(filters.expectedDate[1]), 'day'));

    return (
      matchesSearch &&
      matchesRoom &&
      matchesStage &&
      matchesAge &&
      matchesCreationFrom &&
      matchesCreationTo &&
      matchesExpectedFrom &&
      matchesExpectedTo &&
      matchesCustomCondition
    );
  });
};

const cutString = (str, maxLength, showEllipsis = true) => {
  if (str.length <= maxLength) {
    return str;
  }

  return `${str.substr(0, maxLength)}${showEllipsis ? '...' : ''}`;
};

const renderAddress = (data, countries = {}, noPermission = false) => {
  if (!data) {
    return '';
  }

  const addressChunks = [data.street_address, data.address_line_2, data.city, data.state, data.zip];
  const country = countries[data.country_code] || data.country_code;

  if (country) {
    addressChunks.push(country);
  }

  return !noPermission ? addressChunks.filter(Boolean).join(', ') : addressChunks.find(Boolean);
};

const fullName = entity => {
  if (!entity.last_name) {
    return entity.first_name;
  }

  return `${entity.first_name} ${entity.last_name}`;
};

// NOTE: the difference between round number
// roundNumber(1.02, 1) = 1
// formatNumber(1.02, 1) = '1.0'
// roundNumber(1.102, 2) = 1.1
// formatNumber(1.102, 2) = '1.10'
// roundNumber(10000.001, 2) = 10000
// formatNumber(10000.001, 2) = '10,000.00'
const roundNumber = (n, decimals = 2) => {
  const power = 10 ** decimals;
  return Math.round(n * power) / power;
};

const formatDecimal = (n, decimals = 2) =>
  `${n % 1 !== 0 ? n.toFixed(decimals) : n}`.replace(/(\d)(?=(\d{3})+(\.|$))/g, '$1,');

const formatNumber = (num, shorten = true) => {
  if (isNaN(num)) {
    return '0';
  }

  const number = parseInt(num, 10);

  if (!shorten) {
    return formatDecimal(number);
  }

  if (Math.abs(number) < 10 * 1000) {
    return formatDecimal(number);
  } else if (Math.abs(number) < 1 * 1000 * 1000) {
    return `${formatDecimal(number / 1000)}K`;
  }

  return `${formatDecimal(number / 1000 / 1000)}M`;
};

const periodName = (type, short) => {
  switch (type) {
    case 'month':
      return short ? 'Mo' : 'Month';
    case 'year':
      return short ? 'Yr' : 'Year';
    default:
      return type;
  }
};

const listenClick = (e, containerEl, callback) => {
  let { target } = e;

  while (target && target !== containerEl && target !== document.body) {
    if (target.dataset?.portalFor) {
      target = document.getElementById(target.dataset?.portalFor) || target.parentNode;
    } else {
      target = target.parentNode;
    }
  }

  if (target && target !== containerEl) {
    callback();
  }
};

/**
 * Map hours in the floating-point format (like 2.5) to `2 Hrs 30 Mins`
 *
 * @param {number} value
 * @return {string} A formatted value
 */
const getDuration = value => {
  const hours = Math.floor(value);
  const minutes = Math.round((value - hours) * 60);

  let result = '';

  if (hours) {
    result += `${hours}\xa0${hours === 1 ? 'Hr' : 'Hrs'} `; // Non-breakable space is char 0xa0
  }

  if (minutes) {
    result += `${minutes}\xa0${minutes === 1 ? 'Min' : 'Mins'}`; // Non-breakable space is char 0xa0
  }

  if (value > 0 && !result) {
    return '1 Min';
  }

  return result.trim() || '0 Hr';
};

/**
 * Create request to printable data
 * @param requestPayload {object} - filter values
 * @param apiRoute {string} - route name
 */
const exportData = (requestPayload, apiRoute) => {
  const newWindow = window.open('about:blank');
  newWindow.document.body.innerHTML = 'please wait...';
  return req[apiRoute](requestPayload)
    .then(res => newWindow.document.write(res))
    .catch(() => newWindow.document.write('Sorry, something went wrong. Try later.'));
};

const exportPDF = (requestPayload, apiRoute, fileName = 'PDFExport.pdf') => {
  req[apiRoute](requestPayload)
    .then(res => {
      const element = document.createElement('a');
      element.setAttribute('href', 'data:application/pdf;charset=utf-8,' + encodeURIComponent(res));
      element.setAttribute('download', fileName);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    })
    .catch(() => Actions.showFlash('Sorry, something went wrong. Try later.', 'warning'));
};

const downloadS3File = async (url, filename = 'procare.pdf') => {
  try {
    const blob = await axios.get(url, {
      responseType: 'blob'
    });
    FileSaver.saveAs(blob.data, filename);
  } catch (e) {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
};

const download = (url, fileName) => {
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

const isMobile = () => {
  let check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    ) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const isTouchDevice = () => {
  return window && window.matchMedia('(pointer: coarse)').matches;
};

const generateIPAddress = () => {
  const chunks = [];

  for (let i = 0; i < 4; i++) {
    chunks.push((Math.random() * 255) | 0);
  }

  return chunks.join('.');
};

const getSubscriptionPlan = (plans, subscriptionVersion) => {
  const matches = subscriptionVersion.match(/s([0-9])$/);
  const version = matches && matches[1];

  if (!version) {
    return null;
  }

  return plans.find(p => p.id.indexOf(`v${version}`) > -1) || plans[0];
};

const isDiscount = type => {
  return ['discount', 'subsidy', 'ledger'].includes(type);
};

const getTotalAmount = (items, mode = null) => {
  if (items.length === 0) {
    return 0;
  }

  const totalPrice = items.reduce((acc, item) => {
    let rawPrice = 0;

    // Ignore prices of items marked for removal
    if (!item._destroy) {
      rawPrice = Number(mode === 'attendance' ? item.price * item.quantity : item.price);
      if (item.kind === 'discount' || item.kind === 'subsidy' || item.kind === 'ledger') {
        rawPrice = -getDiscountPrice(item);
      }
      rawPrice = isNaN(rawPrice) ? 0 : rawPrice;
    }

    return acc + rawPrice;
  }, 0);

  return Math.round(totalPrice) === totalPrice ? totalPrice : totalPrice.toFixed(2);
};

function hasParentAdded(student) {
  return !!(student.parents && student.parents.length && student.parents.some(p => p.is_signed_up));
}

function getResponseErrorText(err, defaultValue, keyMap = {}) {
  if (err.response) {
    return getErrorText(err.response.data, keyMap);
  } else {
    return err.message || defaultValue;
  }
}

function getErrorText(error, keyMap = {}) {
  const errorObj = error.form_errors || error;
  const keyFallback = key => startCase(key.split('.').pop());

  if (!errorObj) {
    return '';
  }

  if (errorObj.error_message) {
    return errorObj.error_message;
  }

  return Object.keys(errorObj)
    .map(key => {
      let name = keyMap[key] || keyFallback(key) || '';

      if (name.toLowerCase().indexOf('error') > -1) {
        name = '';
      }

      const errors = Array.isArray(errorObj[key]) ? errorObj[key] : [errorObj[key]];
      return errors.map(errText => `${name} ${errText}`.trim()).join('.\n');
    })
    .join('.\n');
}

// Thanks to https://stackoverflow.com/a/8260383
const youtubeIDRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
function getYouTubeVideoID(url) {
  const match = url.match(youtubeIDRegex);
  return match && match[7] && match[7].length == 11 ? match[7] : false;
}

const playYoutubeVideoById = videoId => {
  const videoURL = `https://www.youtube.com/embed/${videoId}?rel=0`;

  Actions.showModal(
    'VideoViewer',
    { videos: [{ video_file_url: videoURL, youtube: true }] },
    { className: 'modal--full' }
  );
};

const playYoutubeVideo = url => {
  const videoId = getYouTubeVideoID(url);
  playYoutubeVideoById(videoId);
};

const createActivityObject = (activityType, values = {}, date = new Date()) => {
  const type = activityType.replace('_activity', '');

  const activity = {
    activity_time: date,
    data: {}
  };

  switch (type) {
    case 'incident':
    case 'video':
      activity.activiable = {};
      break;

    case 'bathroom':
      activity.data.type = values.type || 'Diaper';
      activity.data.sub_type = values.sub_type;
      break;

    case 'nap':
      activity.data.start_time = date;
      activity.data.end_time = moment(date).add(1, 'h');
      break;

    case 'photo':
      activity.activiable = {};
      activity.data.photo_ids = values.photo_ids || [];
      break;

    case 'observation':
      activity.data.measure_ids = values.measure_ids || [];
      break;
  }

  return activity;
};

const getSingleValue = value => (Array.isArray(value) ? value[0] : value);

const arrayMove = (arr, oldIndex, newIndex) => {
  const tmpArr = [...arr];
  if (newIndex >= tmpArr.length) {
    var k = newIndex - tmpArr.length + 1;
    while (k--) {
      tmpArr.push(undefined);
    }
  }

  tmpArr.splice(newIndex, 0, tmpArr.splice(oldIndex, 1)[0]);
  return tmpArr;
};

const getActivityIconPath = activityId => {
  activityId = activityId.replace('_activity', '');
  return `/assets/images/activities/${activityId}.png`;
};

const isIE = () => {
  return navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0;
};

const getDiscountPrice = discountItem => {
  const discountType = discountItem._discountType || '$';
  const discountValue = Number(discountItem._discount || '0');
  const originalPrice = Number(discountItem._base || 0);

  if (!discountItem._discount && !discountItem._discountType) {
    return -discountItem.price || 0;
  }

  if (discountType === '%') {
    return Math.round(originalPrice * discountValue) / 100;
  }

  return discountValue;
};

const copyToClipboard = text => {
  const textArea = document.createElement('textarea');
  textArea.innerText = text;
  document.body.appendChild(textArea);
  textArea.select();

  try {
    document.execCommand('copy');
    Actions.showFlash('Copied to clipboard');
  } catch (e) {
    console.warn('Unable to copy', e);
  } finally {
    document.body.removeChild(textArea);
  }
};

const getIndexedItems = (items = []) => {
  const indexedItems = {};
  const length = items.length;

  for (let i = 0; i < length; i++) {
    indexedItems[items[i].id] = items[i];
  }

  return indexedItems;
};

const formatSubscriptionCategory = currentSchool => {
  const cat = get(currentSchool, 'subscription_category');
  const map = {
    home: 'Home Plan',
    center: 'Center (Essentials) Plan',
    center_curriculum: 'Essentials + Curriculum Plan',
    pro: 'Enterprise'
  };

  return map[cat] || 'Essentials';
}

const getSubscriptionCategory = currentSchool => {
  const cat = get(currentSchool, 'subscription_category');
  const map = {
    home: 'home',
    center: 'center',
    center_crm: 'center',
    center_curriculum: 'center_curriculum',
    pro: 'enterprise'
  };

  return map[cat] || 'home';
};

const getActivateSchoolBillingMethod = currentSchool => {
  return currentSchool.billing_gateway === 'stripe' ? 'stripe' : 'te';
};

const isFree = currentSchool => {
  return (
    currentSchool &&
    currentSchool.online_subscription &&
    !currentSchool.corp_school &&
    (currentSchool.subscription_plan === 'free' || (currentSchool.subscription && currentSchool.subscription.is_halted))
  );
};

const isTrialActive = currentSchool => {
  if (!currentSchool) {
    return false;
  }

  const trialEndDate = moment(currentSchool.trial_period_end_date, 'YYYY-MM-DD').endOf('day');
  return trialEndDate.isAfter(new Date());
};

const getSubscriptionAccessLevel = (currentSchool, checkTrial) => {
  if (isFree(currentSchool) && (!checkTrial || !isTrialActive(currentSchool))) return 'free';

  // return get(currentSchool, 'subscription_category');
  return getSubscriptionCategory(currentSchool);
};

const plural = (number, text, includeNumber = true) => {
  const prefix = includeNumber ? `${number} ` : '';
  const suffix = number !== 1 ? 's' : '';
  return `${prefix}${text}${suffix}`;
};

const getOrdinalSuffix = number => {
  const j = number % 10,
    k = number % 100;
  let suffix = 'th';

  if (j == 1 && k != 11) suffix = 'st';
  if (j == 2 && k != 12) suffix = 'nd';
  if (j == 3 && k != 13) suffix = 'rd';

  return `${number}${suffix}`;
};

const findGlobalIndex = (arr, filter, filteredIndex) => {
  let index = 0;
  for (let i = 0; i < arr.length; i += 1) {
    if (filter(arr[i], i)) {
      if (filteredIndex === index) {
        return i;
      }

      index += 1;
    }
  }

  return -1;
};

const mergePermissions = (schoolPermssions, staffPermissions, featureFlags = {}, shouldIgnoreSchoolPermissions) => {
  const result = {};

  if (shouldIgnoreSchoolPermissions) {
    return staffPermissions;
  }

  const p1 = schoolPermssions;
  const p2 = staffPermissions;

  Object.keys(p1).forEach(key => {
    const flagKey = PERMISSION_MAP[key] && PERMISSION_MAP[key].featureFlag;
    if (featureFlags && flagKey && featureFlags[flagKey] === false) {
      return (result[key] = 'none');
    }

    switch (true) {
      case p1[key] === 'none':
        return (result[key] = 'none');
      case p2[key] === 'none':
        return (result[key] = 'none');
      case p2[key] === 'read' && p1[key] === 'write':
        return (result[key] = 'read');
      case p2[key] === 'read' && p1[key] === 'read':
        return (result[key] = 'read');
      case p2[key] === 'write' && p1[key] === 'read':
        return (result[key] = 'read');
      case p2[key] === 'write' && p1[key] === 'write':
        return (result[key] = 'write');
    }
  });

  return result;
};

const setFaviconMode = (type = 'default') => {
  if (!['default', 'notification'].includes(type)) return null;

  try {
    const FAVICON_SIZES = [16, 32, 96];

    FAVICON_SIZES.forEach(s => {
      document.getElementById(`favicon-${s}`).href =
        type === 'notification'
          ? `/assets/images/favicons/favicon-notification-${s}x${s}.png`
          : `/assets/images/favicons/favicon-${s}x${s}.png`;
    });
  } catch (e) {
    console.warn('Unable to update favicon mode');
  }
};

const getCheckrEventsTimeline = events => {
  if (!Array.isArray(events) || !events.length) return [];

  const timeline = [];
  const CANDIDATE_CREATED_EVENT = 'candidate.created';

  events
    .sort((a, b) => {
      const diff = moment(b.created_at).diff(moment(a.created_at));

      if (diff === 0) {
        if (a.type === CANDIDATE_CREATED_EVENT) return 1;
        if (b.type === CANDIDATE_CREATED_EVENT) return -1;
      }

      return diff;
    })
    .forEach(event => {
      const eventInfo = CHECKR_EVENT_TYPES[event.type];

      if (eventInfo) {
        timeline.push({
          type: event.type,
          label: eventInfo.category === CHECKR_EVENT_CATEGORIES_MAP.report ? event.status : eventInfo.label,
          at: moment(event.created_at),
          invitation_url: event.invitation_url,
          report_url: event.report_url,
          highlight: eventInfo.highlight
        });
      }
    });

  return timeline;
};

const openEmptyWindow = (writeInBody = false, message = 'Please wait...') => {
  const newWindow = window.open('about:blank');

  if (newWindow === null) {
    Actions.showFlash('Unable to open a new window', 'alert');
    return false;
  }

  if (writeInBody) newWindow.document.body.innerHTML = message;
  else newWindow.document.write(message);

  return newWindow;
};

const previewS3Url = url => {
  const newWindow = openEmptyWindow(true);
  if (!newWindow) return;
  const isImage = url.match(/\.(jpeg|jpg|gif|png)(\?\d*)?$/) != null;

  if (isImage) {
    newWindow.document.writeln(`<img src="${url}">`);
  } else {
    newWindow.location = url;
  }
};

const formatPhoneNumber = value => {
  const valueStr = value.toString();

  if (!valueStr.length) return value;

  const values = valueStr.replace(/\W/g, ''),
    US_NUMBER_LENGTH = 10;
  return values.length === US_NUMBER_LENGTH ? toPattern(values, '(999)-999-9999') : value;
};

const getPayloadFromAgeFilter = (age = {}) => {
  const { months, option } = age;
  if (Array.isArray(months) && option) {
    let gte, lte;
    switch (option) {
      case 'less': {
        gte = moment()
          .subtract(months[0], 'months')
          .format('YYYY-MM-DD');
        break;
      }
      case 'greater': {
        lte = moment()
          .subtract(months[0], 'months')
          .format('YYYY-MM-DD');
        break;
      }
      case 'range': {
        lte = moment()
          .subtract(months[0], 'months')
          .format('YYYY-MM-DD');
        gte = moment()
          .subtract(months[1], 'months')
          .format('YYYY-MM-DD');
        break;
      }
    }

    return {
      gte,
      lte
    };
  }

  return undefined;
};

const parseHtmlString = (string = '') => {
  try {
    if (window.DOMParser) {
      const parser = new window.DOMParser();
      const doc = parser.parseFromString(string, 'text/html');
      return doc.body;
    }

    const dom = document.createElement('div');
    dom.innerHTML = string;
    return dom;
  } catch (err) {
    console.error('Unable to parse string');
    return null;
  }
};

const getPermittedQuickActions = (currentUser, currentSchool) => {
  const staffPermissions = get(currentUser, 'permissions', {});
  const schoolPermissions = get(currentSchool, 'permissions', {});
  const procareSyncEnabled = get(currentSchool, 'pe_enabled');

  return SCHOOL_QUICK_ACTIONS.filter(
    action =>
      staffPermissions[action.permission] === 'write' &&
      schoolPermissions[action.permission] === 'write' &&
      (!action.hideOnProcareSync || !procareSyncEnabled)
  );
};

const isSubfamilyAllowed = currentSchool => {
  return getSubscriptionCategory(currentSchool) !== 'home';
};

const getSubFamilies = (currentUser, familyId) => {
  const families = currentUser.families || [];
  const family = families.find(f => f.family_id === familyId);

  if (family && family.sub_family) {
    return family.sub_family;
  }

  return [];
};

const onPageVisibility = (onVisible, onHidden) => {
  function handleVisibilityChange() {
    if (document[hidden]) {
      onHidden();
    } else {
      onVisible();
    }
  }

  let hidden, visibilityChange;
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  if (typeof document.addEventListener === 'undefined' || typeof hidden === 'undefined') {
    return null;
  } else {
    // Handle page visibility change
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
  }

  return () => document.removeEventListener(visibilityChange, handleVisibilityChange); // Remove handler
};

const convertListOfNamesToString = names => {
  if (names.length < 3) {
    return names.join(' and ');
  }

  if (names.length < 4) {
    return `${names[0]}, ${names[1]} and ${names[2]}`;
  }

  const firstThree = names.slice(0, 3).join(', ');
  const remainder = names.length - 3;

  return `${firstThree} and ${remainder} more`;
};

const convertScheduleToSessions = schedule => {
  const sessions = [];

  if (!schedule) return [];

  Object.keys(schedule).forEach(day => {
    if (schedule[day].indexOf('am') > -1) {
      sessions.push({
        day_index: PROGRAM_DAY_CODES.indexOf(day),
        order_index: 0
      });
    }

    if (schedule[day].indexOf('pm') > -1) {
      sessions.push({
        day_index: PROGRAM_DAY_CODES.indexOf(day),
        order_index: 1
      });
    }
  });

  return sessions;
};

const convertSessionsToSchedule = sessions => {
  const schedule = { M: [], T: [], W: [], Th: [], F: [], S: [], Su: [] };

  if (!sessions || !sessions.length) {
    return {};
  }

  sessions
    .filter(s => s.is_enrolled !== false)
    .forEach(s => {
      const code = PROGRAM_DAY_CODES[s.day_index];

      if (s.order_index === 0) {
        schedule[code].push('am');
      } else if (s.order_index === 1) {
        schedule[code].push('pm');
      }
    });

  Object.keys(schedule).forEach(day => {
    if (!schedule[day].length) {
      delete schedule[day];
    }
  });

  return schedule;
};

const convertSessionsToValidTimes = sessions => {
  const schedule = { M: [], T: [], W: [], Th: [], F: [], S: [], Su: [] };

  if (!sessions || !sessions.length) {
    return {};
  }

  sessions
    .filter(s => s.is_eligible || s.is_enrolled)
    .forEach(s => {
      const code = PROGRAM_DAY_CODES[s.day_index];

      if (s.order_index === 0) {
        schedule[code].push('am');
      } else if (s.order_index === 1) {
        schedule[code].push('pm');
      }
    });

  Object.keys(schedule).forEach(day => {
    if (!schedule[day].length) {
      delete schedule[day];
    }
  });

  return schedule;
};

const doesSessionsIncludeWeekend = sessions => {
  return sessions.find(
    s => s.day_index === PROGRAM_DAY_CODES.indexOf('S') || s.day_index === PROGRAM_DAY_CODES.indexOf('Su')
  );
};

const isScheduleCompatibleWithSessions = (schedule, sessions) => {
  return some(sessions, session => {
    const code = PROGRAM_DAY_CODES[session.day_index];
    const time = session.order_index === 0 ? 'am' : 'pm';

    return schedule[code]?.includes(time);
  });
};

const calculateTotalShiftHours = shifts => {
  const total = shifts.reduce((prev, s) => {
    return prev + moment(s.ends_at).diff(s.starts_at, 'hour', true);
  }, 0);

  return Math.round(total);
};

const syncTypes = {
  kinderSystems: 'kinder_systems'
};

const getSyncName = source => {
  switch (source) {
    case 'scw':
      return 'SchoolCare Works';
    default:
      return 'Procare Desktop';
  }
};

const getSyncLockedText = source => {
  return source ? `Changes synced from ${getSyncName(source)}` : 'Editing restricted in Family Engagement';
};

const getSyncColor = source => SYNC_TYPES_COLORS[source] || SYNC_TYPES_COLORS[SYNC_TYPES.DESKTOP];

const getCustomDay = reportDay => {
  const dayOfWeek = reportDay.format('ddd');
  const dayMap = {
    Mon: 'M',
    Tue: 'T',
    Wed: 'W',
    Thu: 'Th',
    Fri: 'F',
    Sat: 'S',
    Sun: 'Su'
  };
  return dayMap[dayOfWeek];
};

export {
  validateEmail,
  validateNaturalNumber,
  capitalize,
  generateColor,
  applyFilters,
  applyFiltersStaff,
  applyFiltersLeads,
  cutString,
  renderAddress,
  fullName,
  roundNumber,
  formatDecimal,
  formatNumber,
  periodName,
  listenClick,
  getDuration,
  exportData,
  exportPDF,
  download,
  downloadS3File,
  previewS3Url,
  isMobile,
  isTouchDevice,
  getSubscriptionPlan,
  generateIPAddress,
  getTotalAmount,
  hasParentAdded,
  getResponseErrorText,
  getErrorText,
  getYouTubeVideoID,
  playYoutubeVideo,
  playYoutubeVideoById,
  createActivityObject,
  getSingleValue,
  arrayMove,
  getActivityIconPath,
  isIE,
  isDiscount,
  getDiscountPrice,
  copyToClipboard,
  getIndexedItems,
  isFree,
  isTrialActive,
  plural,
  findGlobalIndex,
  mergePermissions,
  setFaviconMode,
  getCheckrEventsTimeline,
  openEmptyWindow,
  getOrdinalSuffix,
  formatPhoneNumber,
  getSubscriptionAccessLevel,
  getSubscriptionCategory,
  getActivateSchoolBillingMethod,
  getPayloadFromAgeFilter,
  parseHtmlString,
  getPermittedQuickActions,
  isSubfamilyAllowed,
  getSubFamilies,
  onPageVisibility,
  convertListOfNamesToString,
  convertScheduleToSessions,
  convertSessionsToSchedule,
  convertSessionsToValidTimes,
  doesSessionsIncludeWeekend,
  isScheduleCompatibleWithSessions,
  calculateTotalShiftHours,
  syncTypes,
  getSyncName,
  getSyncLockedText,
  getSyncColor,
  getCustomDay,
  formatSubscriptionCategory
};
