import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ButtonV2, Icon, Filter, Form, InfiniteScroll, Preloader } from 'components';
import Scrollbars from 'react-custom-scrollbars';
import get from 'lodash/get';
import activityTypes from 'constants/activityTypes';
import { getActivityIconPath } from 'lib/utils';
import Activity from './Activity';

import './style.scss';

class ViewActivityObservations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: get(props, 'data.filters', {}),
      activities: [],
      selected: {},
      page: 1,
      hasMore: true,
      photoUrl: null
    };
  }

  componentDidMount() {
    const { kidId, measureId, progressId } = this.props.data;
    req.students();
    req.rooms();
    req.kidAssessmentObservations({ id: kidId, measure_id: measureId, progress_id: progressId }).then(observations => {
      const observationIds = [];
      const selected = { ...this.state.selected };

      observations.forEach(o => {
        selected[o.id] = true;
        observationIds.push(o.id);
      });

      this.setState(
        {
          activities: [...observations, ...this.state.activities],
          selected,
          observationIds
        },
        () => this.loadMoreActivities()
      );
    });
  }

  get readOnly() {
    return this.props.data.readOnly;
  }

  @bind
  onPhotoView(photoUrl) {
    this.setState({ photoUrl });
  }

  @bind
  onPhotoViewClose() {
    this.setState({ photoUrl: false });
  }

  @bind
  selectActivity(activity) {
    this.setState({
      selected: {
        ...this.state.selected,
        [activity.id]: !this.state.selected[activity.id]
      }
    });
  }

  @bind
  loadMoreActivities() {
    const { activities, filters, page } = this.state;
    const { total } = this.props;

    if (activities.length >= total && total && page !== 1) {
      return;
    }

    req
      .dailyActivities(
        {
          filters: {
            daily_activity: {
              date_to: filters.date_to ? moment(filters.date_to).format('YYYY-MM-DD') : undefined,
              staff_only: filters.staff_only || undefined,
              activity_type: filters.activity_type || undefined,
              kid_id: filters.kid_id || undefined
            },
            kid: {
              current_section_id: filters.section_id || undefined,
              tag_names: filters.tag_names || undefined
            }
          },
          page
        },
        undefined,
        {
          checkHistory: filters.date_to
        }
      )
      .then(result => {
        const { observationIds } = this.state;
        const newActivities = result.daily_activities.filter(a => observationIds.indexOf(a.id) === -1);
        this.setState({
          activities: [...activities, ...newActivities],
          hasMore: !!result.daily_activities.length,
          page: page + 1
        });
      });
  }

  @bind
  updateFilters(values) {
    const { kidAssessmentObservations } = this.props;
    this.setState(
      {
        filters: values,
        page: 1,
        activities: [...kidAssessmentObservations]
      },
      () => {
        this.loadMoreActivities();
      }
    );
  }

  @bind
  createActivity() {
    Actions.showModal('CreateActivity');
  }

  @bind
  onSubmit() {
    if (this.readOnly) {
      return this.props.onClose();
    }

    const selected = [];
    Object.keys(this.state.selected).forEach(id => {
      if (this.state.selected[id]) {
        selected.push(id);
      }
    });
    this.props.onClose(selected);
  }

  renderItems() {
    const { activities } = this.state;
    return activities.map(activity => (
      <Activity
        key={activity.id}
        selected={this.state.selected[activity.id]}
        onSelect={() => this.selectActivity(activity)}
        onPhotoView={this.onPhotoView}
        activity={activity}
        readOnly={this.readOnly}
      />
    ));
  }

  renderFilter() {
    const { kids } = this.props;
    const { filters } = this.state;
    const options = {
      '': 'All Types'
    };
    const icons = {};

    activityTypes.forEach(type => {
      options[type.id] = type.label;
      icons[type.id] = getActivityIconPath(type.id);
    });

    const FILTER_CONFIG = {
      section_id: { type: 'room' },
      tag_names: { type: 'tags' },
      activity_type: {
        type: 'select',
        title: 'All Types',
        label: 'Activity Type',
        options,
        icons
      },
      date_to: { type: 'date-picker', singleVaule: true, label: 'Activity Date' },
      staff_only: {
        type: 'checkbox',
        label: 'Show staff only activities for approval',
        shortName: 'Staff Only'
      }
    };
    const defaultValues = {
      ...filters
    };

    return (
      <Filter
        wide
        onSubmit={this.updateFilters}
        filters={FILTER_CONFIG}
        defaultValues={defaultValues}
        displayApplied={!this.readOnly}
      >
        <Form.Typeahead
          name="kid_id"
          className="input--search"
          placeholder="Search Students"
          defaultValue={defaultValues.kid_id}
          options={kids.map(kid => ({ id: kid.id, label: kid.name }))}
        />
      </Filter>
    );
  }

  render() {
    const { isLoading } = this.props;
    const { hasMore, photoUrl } = this.state;

    return (
      <div className="view-activity-observations">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">View Activity Observations</div>
        </div>

        <div className="view-activity-observations__container">
          <div className="view-activity-observations__filters">{this.renderFilter()}</div>

          <div className="view-activity-observations__activities">
            <Scrollbars className="view-activity-observations__scroll">
              <InfiniteScroll
                pageStart={0}
                loading={isLoading}
                hasMore={hasMore}
                threshold={20}
                onEndReach={this.loadMoreActivities}
                useWindow={false}
              >
                {this.renderItems()}
                {isLoading && <Preloader center />}
              </InfiniteScroll>
            </Scrollbars>
          </div>

          {!this.readOnly && (
            <div className="help-link">
              <span className="help-link__content">
                Didn't find in the list?
                <span className="help-link__anchor" onClick={this.createActivity}>
                  CREATE NEW ACTIVITY OBSERVATION
                </span>
              </span>
            </div>
          )}
        </div>

        <div className="modal__controls">
          <ButtonV2 onClick={this.onSubmit} label={this.readOnly ? 'Close' : 'Submit'} />
        </div>

        {photoUrl && (
          <div className="view-activity-observations__photoview" onClick={this.onPhotoViewClose}>
            <div className="view-activity-observations__photoview-wrapper">
              <img className="view-activity-observations__photoview-img" src={photoUrl} />
              <div className="view-activity-observations__photoview-icon">
                <Icon name="view" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  kids: state.students.data,
  rooms: state.rooms.data,
  tags: state.tags.data,
  kidAssessmentObservations: state.kidAssessmentObservations.data,
  isLoading: state.dailyActivities.loading || state.kidAssessmentObservations.loading,
  total: state.dailyActivities.data.total
});

export default connect(mapStateToProps)(ViewActivityObservations);
