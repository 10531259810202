import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import './style.scss';

class ActivityDate extends Component {
  render() {
    const { date } = this.props;
    const momentDate = moment(date);
    const text = moment().isSame(momentDate, 'day') ? 'Today' : momentDate.format('MMM D, YYYY');

    return <div className="activity-date">{text}</div>;
  }
}

ActivityDate.propTypes = {
  date: PropTypes.string.isRequired
};

export default ActivityDate;
