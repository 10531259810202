import moment from 'moment';

export default (data, activity, isEditing) => {
  const date = moment(data.date || undefined).format('YYYY-MM-DD');

  if (isEditing) {
    return {
      id: activity ? activity.id : undefined,

      daily_activity: {
        activity_time: `${date} ${data.time}`,
        comment: data.caption,
        staff_present_id: data.staff_present || null,
        is_staff_only: data.staffOnly
      }
    };
  }

  return {
    id: activity ? activity.id : undefined,

    photo: {
      caption: data.caption,
      activity_time: `${date} ${data.time}`,
      is_staff_only: data.staffOnly
    },
    daily_activity: {
      staff_present_id: data.staff_present || null
    },
    photo_ids: data.photo_ids || []
  };
};
