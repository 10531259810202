import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';

const FilterItemCheckboxGroup = ({ boxType = 'circle', name, label, defaultValue, options }) => (
  <div className="form__row form__row--text">
    <div>
      {label && (
        <label className="form__label" htmlFor={name}>
          {label}
        </label>
      )}
      <Form.CheckboxGroup
        type={boxType}
        name={name}
        data-cy={name}
        label={label}
        defaultValue={defaultValue}
        options={Object.keys(options).map(value => ({
          value,
          label: options[value]
        }))}
      />
    </div>
  </div>
);

FilterItemCheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.bool,
  options: PropTypes.object.isRequired
};

export default FilterItemCheckboxGroup;
