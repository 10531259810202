import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { ActionButton, Form } from 'components';
import './style.scss';

class EditParent extends Component {
  static propTypes = {
    // A function that closes the modal window
    onClose: PropTypes.func,

    // Additional data
    data: PropTypes.object
  };

  static defaultProps = {
    hasViewRelationPermission: true
  };

  constructor(...args) {
    super(...args);

    this.state = {
      pin: '',
      isPinLoading: false,
      isLoading: false
    };
  }

  componentDidMount() {
    req.schoolRelationTypes();
    const { isNew, fromParent } = this.props.data;
    if (isNew) {
      this.setState({ isPinLoading: true });
      const promise = fromParent ? req.parentGetCarerPin() : req.getCarerPin();
      promise
        .then(res => {
          this.setState({ pin: res.pin, isPinLoading: false });
        })
        .catch(err => {
          console.log(err.response);
        });
    }
  }

  @bind
  submit(values) {
    const { onClose } = this.props;
    const { studentId, isNew, fromParent, carer } = this.props.data;
    let requestData = null;
    let action = null;
    let message = '';

    if (isNew) {
      action = fromParent ? req.parentAddCarer : req.addCarer;
      message = 'Parent successfully added';
      requestData = {
        carer: {
          ...values,
          email: values.email.toLowerCase(),
          kid_id: studentId,
          relation: 'parent'
        }
      };
    } else {
      action = fromParent ? req.parentUpdateCarer : req.updateCarer;
      message = 'Parent successfully updated.';

      requestData = {
        id: carer.id,
        carer: {
          ...values,
          send_invite: values.send_invite
        }
      };
    }

    if (values.relationTypeId) {
      const studentRelation = this.getStudentRelation(carer);
      requestData.carer.carer_kid_relations_attributes = [
        {
          id: studentRelation?.id,
          kid_id: studentId,
          relation_type_id: values.relationTypeId
        }
      ];
    }

    this.setState({ isLoading: true });

    action(requestData)
      .then(res => {
        if (res.carer && res.carer.email_sent) {
          message += ` Invite Email was sent to ${res.carer.name}.`;
        }

        Actions.showFlash(message);
        this.setState({ isLoading: false });
        onClose(true);
      })
      .catch(err => {
        this.setState({ isLoading: false });

        Actions.reportError(`Cannot ${isNew ? 'add' : 'update'} parent`, err);
      });
  }

  @bind
  getRelationInputProps() {
    return this.props.relationTypes.map(relation => ({
      id: relation.id,
      label: relation.name
    }));
  }

  @bind
  getStudentRelation(carer) {
    if (!carer || !carer.carer_kid_relations) {
      return null;
    }

    const { studentId } = this.props.data;
    return carer.carer_kid_relations.find(relation => relation.kid_id === studentId);
  }

  @bind
  getStudentRelationType(carer) {
    const { relationTypes } = this.props;
    const studentRelation = this.getStudentRelation(carer);
    if (!studentRelation) {
      return null;
    }

    return relationTypes.find(relationType => relationType.id === studentRelation.relation_type_id);
  }

  render() {
    const { isLoading } = this.state;
    const { isNew, carer, hasViewRelationPermission, hasManageRelationPermission, fromParent } = this.props.data;

    const buttonLabel = isNew ? 'Add Parent' : 'Save Changes';

    const emailFieldCN = classNames({
      'edit-parent__email': true,
      'edit-parent__email--pending': carer && carer.email && !carer.is_signed_up,
      'edit-parent__email--verified': carer && carer.email && carer.is_signed_up
    });

    const title = isNew ? 'Add Parent' : 'Edit Parent';

    const manageBtn = !fromParent && hasManageRelationPermission && (
      <ActionButton
        iconName="gear"
        className="parent-info-card__relation-manage-btn"
        title="Manage Relation"
        size={12}
        onClick={() => Actions.showModal('ManageRelationTypes')}
      />
    );

    return (
      <div className="edit-parent">
        <div className="modal__header">
          <span className="modal__header-title">{title}</span>
        </div>

        <div className="modal__container">
          <Form onSubmit={this.submit} validateOn="submit" isLoading={isLoading}>
            <div className="form__row form__row--justified">
              <Form.Input
                label="First name"
                defaultValue={carer && carer.first_name}
                placeholder="First Name (required)"
                name="first_name"
                tabIndex={1}
                required
              />

              <Form.Input
                label="Last name"
                defaultValue={carer && carer.last_name}
                placeholder="Last Name"
                name="last_name"
                tabIndex={2}
              />
            </div>

            <div className="form__row form__row--justified">
              <Form.Input
                label="Email"
                type="email"
                name="email"
                defaultValue={carer && carer.email}
                disabled={carer && carer.is_signed_up}
                placeholder="Email Address"
                className={emailFieldCN}
                tabIndex={3}
              />

              <Form.Input
                label="Mobile Phone"
                defaultValue={carer && carer.mobile_phone}
                name="mobile_phone"
                placeholder="Phone Number"
                tabIndex={4}
              />
            </div>

            {hasViewRelationPermission && (
              <div className="form__row form__row--justified">
                <Form.Typeahead
                  name="relationTypeId"
                  title=""
                  label="Relation"
                  action={manageBtn}
                  disableFilter={true}
                  defaultValue={this.getStudentRelationType(carer)?.id}
                  options={this.getRelationInputProps()}
                />
              </div>
            )}

            {(isNew || !carer.is_signed_up) && (
              <div className="form__row edit-parent__send-invite">
                <Form.Checkbox
                  name="send_invite"
                  type="circle"
                  size="small"
                  label="By checking this option, parents will receive an email or text message invite to create a Procare parent account."
                  defaultValue={true}
                  tabIndex={5}
                />
              </div>
            )}

            {!isNew && carer.pin && (
              <div className="edit-parent__pin">
                <div className="form__label">Sign-In Pin</div>

                <div className="edit-parent__pin-container">
                  <div className="edit-parent__pin-note">
                    Parents and authorized individuals can use this code for Sign In-Out
                  </div>

                  <div className="edit-parent__pin-code">{carer.pin}</div>
                </div>
              </div>
            )}

            <div className="form__row edit-parent__emergency-contact">
              <span className="edit-parent__emergency-contact-label">Is Emergency Contact?</span>
              <Form.Checkbox type="slider" name="emergency_contact" defaultValue={false} />
            </div>

            <div className="edit-parent__footer form__row--actions">
              <Form.Submit label={buttonLabel} tabIndex={6} />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    student: state.student.data,
    relationTypes: state.relationTypes.data
  };
};

export default connect(mapStateToProps)(EditParent);
