import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';
import './style.scss';

class EditNote extends Component {
  static propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  @bind
  submit(values) {
    const { kid } = this.props.data;

    const payload = {
      id: kid.id,
      kid: { note: values.note }
    };

    this.setState({ loading: true }, () =>
      req
        .updateStudent(payload)
        .then(() => {
          Actions.showFlash('Note has been updated');
          this.props.onClose();
        })
        .catch(err => {
          Actions.reportError('Unable to update note', err);
          this.setState({ loading: false });
        })
    );
  }

  render() {
    const { kid } = this.props.data;
    const { loading } = this.state;

    return (
      <div className="edit-note">
        <div className="modal__header">
          <div className="modal__header-title">Edit Note</div>
        </div>

        <div className="modal__container">
          <Form onSubmit={this.submit}>
            <Form.Input
              multiline
              name="note"
              className="edit-note__area"
              defaultValue={kid.note}
              label="Note"
              placeholder="Add note..."
            />

            <div className="modal__controls">
              <Form.Submit label="Save" isLoading={loading} />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default EditNote;
