import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Avatar, Form } from 'components';
import './style.scss';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class Step2 extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isSaving: false
    };
  }

  UNSAFE_componentWillMount() {
    req.rooms();
  }

  @bind
  submit(values) {
    const { regId, onClose, confirmationOnly } = this.props;

    this.setState({ isSaving: true });
    if (!confirmationOnly) {
      req
        .activateEnrollmentItem({
          id: regId,
          section_id: values.section_id,
          date: moment(values.date).format('YYYY-MM-DD')
        })
        .then(() => {
          this.setState({ isSaving: false });
          req.enrollmentStats();
          onClose();
          Actions.showFlash('Enrollment has been scheduled.');
        })
        .catch(err => {
          this.setState({ isSaving: false });
          Actions.reportError('There was problem scheduling enrollment', err);
        });
    } else {
      onClose({
        section_id: values.section_id,
        date: moment(values.date).format('YYYY-MM-DD')
      });
    }
  }

  @bind
  disableDays(day) {
    const momentDay = moment(day);

    return momentDay.isBefore(moment().endOf('day'));
  }

  render() {
    const { kid, isScheduling, rooms } = this.props;
    const { isSaving } = this.state;
    const kidName = kid.name || `${kid.first_name} ${kid.last_name}`;

    return (
      <div className="modal__wrapper enroll-reg">
        <div className="modal__container">
          <div className="enroll-reg__kid">
            <Avatar
              name={kidName}
              url={kid.profile_pic_url}
              tooltipText={kidName}
              color={kid.color}
              initials={kid.initials}
            />
            <div className="enroll-reg__kid-name">{kidName}</div>
          </div>
          <div className="enroll-reg__title">{isScheduling ? 'Schedule Enroll' : 'Activate Account'}</div>
          <div className="enroll-reg__message">
            {isScheduling ? 'To schedule a date' : 'To activate'}, please fill the required field.
          </div>

          <Form className="enroll-reg__form" onSubmit={this.submit} isLoading={isSaving}>
            <div className="form__row">
              <Form.Select title="Select Room" name="section_id" data-cy="section_id" required>
                {rooms.map(r => (
                  <Form.Select.Item key={r.id} value={r.id} label={r.name} />
                ))}
              </Form.Select>
            </div>

            {isScheduling && (
              <div className="form__row">
                <Form.DatePicker
                  name="date"
                  disableDays={this.disableDays}
                  required
                  defaultValue={moment()
                    .add(1, 'day')
                    .toDate()}
                />
              </div>
            )}

            {isScheduling && (
              <div className="form__row enroll-reg__note">
                NOTE: Student will be automatically enrolled on this date
              </div>
            )}

            <div className="modal__controls enroll-reg__controls">
              <Form.Submit label={isScheduling ? 'Schedule Date' : 'Enroll Now'} data-cy="submit" />
              <div className="enroll-reg__cancel">
                OR
                <a role="button" onClick={this.props.context.prevStep}>
                  GO BACK
                </a>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(Step2);
