import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TextInput } from 'components';

class Row extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  static defaultProps = {
    data: {
      description: '',
      price: '',
      _destroy: false
    }
  };

  @bind
  updateDescription(value) {
    const { id, isNew } = this.props;

    const data = { ...this.props.data };
    data.description = value;

    this.props.onUpdate(data, isNew ? null : id);
  }

  @bind
  updatePrice(value) {
    const { isNew, id } = this.props;

    if (/\d?/.test(value)) {
      const data = { ...this.props.data };
      data.price = value;

      this.props.onUpdate(data, isNew ? null : id);
    }
  }

  render() {
    const { id, data, isNew, onCreate, onRemove } = this.props;

    const isEmpty = (!data.description || !Number(data.price)) && isNew;

    const iconCN = classNames({
      'action-icon': true,
      'action-icon--disabled': isEmpty,
      'action-icon--remove': !isNew
    });

    return data._destroy ? null : (
      <div className="table__row">
        <div className="table__cell table__cell--l">
          <TextInput value={data.description} onChange={this.updateDescription} placeholder="Add Invoice Description" />
        </div>

        <div className="table__cell table__cell--xs">
          <TextInput value={data.price} onChange={this.updatePrice} placeholder="$0" />
        </div>

        <div className="table__cell table__cell--xxs table__cell--actions">
          {isNew ? (
            <div className={iconCN} onClick={!isEmpty && onCreate}>
              +
            </div>
          ) : (
            <div className={iconCN} onClick={() => onRemove(id)}>
              –
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Row;
