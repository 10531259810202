import showPremiumModal from './showPremiumModal';
import checkRouteAccess from './checkRouteAccess';
import checkFeatureAccess from './checkFeatureAccess';
import checkSettingsAccess from './checkSettingsAccess';
import formatTime from './formatTime';

const initializeHelpers = store => ({
  showPremiumModal: showPremiumModal(store),
  checkRouteAccess: checkRouteAccess(store),
  checkFeatureAccess: checkFeatureAccess(store),
  checkSettingsAccess: checkSettingsAccess(store),
  formatTime
});

export default initializeHelpers;
