import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form } from 'components';
import { PhotoActivityPanel } from '../../PhotoActivityGallery';
import TEXT_CAPTIONS from './constants';
import StaffPresentSelect from './StaffPresentSelect';
import { compose } from 'redux';
import withContext, { CreateActivityContext, ModalControllerStepsContext } from 'hocs/withContext';

class PhotoActivityForm extends Component {
  static propTypes = {
    activity: PropTypes.object,
    photos: PropTypes.array,
    useDate: PropTypes.bool
  };

  static defaultProps = {
    photos: []
  };

  componentDidMount() {
    req.schoolPhotos();
  }

  @bind
  openGallery() {
    this.props.context?.goToStep('gallery', {
      selectedPhotoIds: this.props.activity.data.photo_ids,
      onChange: this.updatePhotos,
      goBack: () => this.props.context.goToStep(1)
    });
  }

  @bind
  openUpload() {
    this.props.context?.goToStep('upload_photos', {
      goBack: () => this.props.context.goToStep(1)
    });
  }

  @bind
  updatePhotos(selectedPhotos) {
    const { updateSelectedPhotos } = this.props.createActivityContext || {};
    updateSelectedPhotos(selectedPhotos);
  }

  render() {
    const { uploadedPhotos, selectedPhotos } = this.props.createActivityContext || {};
    const { activity, useDate = true, isEditing, forceStaff } = this.props;

    return (
      <div className="form__body">
        {useDate && (
          <div className="form__row form__row--datetime">
            <Form.DatePicker label="Date" name="date" defaultValue={moment(activity.activity_time).toDate()} required />

            <Form.TimeInput
              label="Time"
              name="time"
              defaultValue={Helpers.formatTime(activity.activity_time)}
              required
            />
          </div>
        )}

        {!isEditing && (
          <PhotoActivityPanel
            uploaded={uploadedPhotos.length > 0}
            photos={uploadedPhotos.length > 0 ? uploadedPhotos : selectedPhotos}
            selectedPhotoIds={uploadedPhotos.length > 0 ? uploadedPhotos.map(p => p.id) : activity.data.photo_ids}
            onOpenGallery={this.openGallery}
            onOpenUpload={this.openUpload}
          />
        )}

        <StaffPresentSelect activity={activity} />

        <div className="form__row">
          <Form.Input name="caption" defaultValue={activity.comment} placeholder="Caption" label="Caption" />
        </div>

        <div className="form__row">
          {forceStaff ? (
            TEXT_CAPTIONS.staffOnlyForce
          ) : (
            <Form.Checkbox
              type="circle"
              name="staffOnly"
              label={TEXT_CAPTIONS.staffOnly}
              defaultValue={activity.is_staff_only}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  photos: state.schoolPhotos.data.photos
});

const enhance = compose(
  withContext(ModalControllerStepsContext),
  withContext(CreateActivityContext, 'createActivityContext'), // not present when editing activities
  connect(mapStateToProps)
);

export default enhance(PhotoActivityForm);
