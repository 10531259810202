import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import { ActionButton, ContextMenu, Icon, StudentList, StatusIcon, TooltipTrigger } from 'components';
import currency from 'lib/currency';
import capitalize from 'lodash/capitalize';
import { goToPage } from 'lib/history';
import { Link } from 'components';
import PAYMENT_KINDS from 'constants/paymentKinds';
import PAYMENT_STATUSES from 'constants/paymentStatuses';
import PAYMENT_MODES from 'constants/paymentModes';
import PRESENTATION_TYPES from 'constants/cardPresentationTypes';
import { getPaymentModeDescription } from 'lib/utils/billing';
import './style.scss';
import { withPermissionsChecking } from 'hocs/withPermissions';

class TransactionRow extends Component {
  static propTypes = {
    data: PropTypes.object,
    unpaid: PropTypes.number,
    students: PropTypes.array,
    reload: PropTypes.func,
    showStudent: PropTypes.bool,
    fromParent: PropTypes.bool,
    showBalance: PropTypes.bool,
    isOnlineSetup: PropTypes.bool,
    franchiseBilling: PropTypes.bool,
    allowReceivePayment: PropTypes.bool,
    hasFamilyLink: PropTypes.bool
  };

  static defaultProps = {
    data: {},
    students: [],
    reload: () => undefined,
    showStudent: false,
    fromParent: false,
    showBalance: true,
    isOnlineSetup: false,
    allowReceivePayment: true,
    hasFamilyLink: false
  };

  // Open Modals

  @bind
  editInvoice() {
    Actions.showModal('EditInvoice', {
      ...this.props.data,
      students: this.props.students,
      subFamily: this.props.subFamily
    }).then(saved => {
      if (saved) {
        this.props.reload();
      }
    });
  }

  @bind
  refund() {
    const { data, students, subFamily } = this.props;
    const { mode, sub_mode, transactable } = data;
    const isCardPresentTransaction = !['other', null, undefined].includes(data.transactable.presentation_type);

    let paymentMode = '';

    switch (data.mode) {
      case 'bank':
      case 'bank_account':
        paymentMode = 'Online';
        break;

      case 'card':
        paymentMode = isCardPresentTransaction ? 'Card' : 'Online';
        break;

      case 'check':
        paymentMode = 'Check';
        break;

      case 'cash':
        paymentMode = 'Cash';
        break;

      default:
        paymentMode = 'Other';
    }

    const modalData = {
      paymentId: transactable.id,
      paymentMode,
      isPaidOnline: transactable.paid_online,
      students,
      amount: transactable.amount,
      paymentMethod: sub_mode === 'debit' ? 'Debit Card' : PAYMENT_MODES[mode],
      presentationType: PRESENTATION_TYPES[transactable.presentation_type],
      isAutoPay: transactable.auto_billing,
      isCardPresentTransaction,
      paymentModeDescription: getPaymentModeDescription(mode, sub_mode, transactable),
      subFamily
    };

    Actions.showModal('IssueRefund', modalData).then(result => {
      if (result) {
        this.props.reload();
      }
    });
  }

  @bind
  sendInvoiceReminder() {
    const { data, students, fromParent } = this.props;

    Actions.showModal(
      'ShowInvoice',
      {
        invoice: data.transactable,
        students,
        fromParent,
        familyId: this.props.data.family_id,
        sendInvoice: true,
        parentInvoiceId: data.transactable.id
      },
      {
        passDataToProps: true
      }
    ).then(result => {
      if (result) {
        this.props.reload();
        Actions.showFlash('Invoice has been sent');
      }
    });
  }

  @bind
  showOpenInvoiceModal() {
    const { data, students, fromParent } = this.props;

    if (data.kind === 'parent_invoice') {
      Actions.showModal('ShowInvoice', { invoice: data.transactable, students, fromParent }, { passDataToProps: true });
    }
  }

  @bind
  showOpenPaymentModal() {
    const { data, students } = this.props;

    if (data.kind === 'parent_payment') {
      Actions.showModal('ShowPayment', { payment: data.transactable, students }, { passDataToProps: true });
    }
  }

  @bind
  receivePayment() {
    const { data, students, reload, isOnlineSetup, unpaid, subFamily, subFamilies } = this.props;

    const subFamilyId = data.sub_family_id || (subFamily ? subFamily.id : undefined);
    const modalData = {
      students,
      family_id: data.family_id || students[0].family_id,
      isOnlineSetup: isOnlineSetup,
      amount: unpaid,
      subFamily,
      subFamilyId,
      subFamilies: !subFamily && !data.sub_family_id ? subFamilies : undefined,
      accountBalance: unpaid,
      isCardPresentEnabled: true
    };

    Actions.showModal('ReceivePayment', modalData).then(result => {
      if (result) {
        reload();
      }
    });
  }

  @bind
  editPayment() {
    const { students, data, reload, subFamily } = this.props;

    const modalData = {
      id: data.transactable.id,
      amount: data.transactable.amount,
      description: data.transactable.description,
      edit: true,
      family_id: students[0].family_id,
      check_number: data.transactable.check_number,
      isOnlineSetup: data.online_payments,
      notes: data.transactable.notes,
      students,
      subFamily
    };

    switch (data.mode) {
      case 'bank':
      case 'bank_account':
      case 'card':
        modalData.paymentMode = 'Online';
        break;

      case 'check':
        modalData.paymentMode = 'Check';
        break;

      case 'cash':
        modalData.paymentMode = 'Cash';
        break;

      default:
        modalData.paymentMode = 'Other';
    }

    Actions.showModal('ReceivePayment', modalData).then(result => {
      if (result) {
        reload();
      }
    });
  }

  @bind
  cancelInvoice() {
    const { id } = this.props.data.transactable;

    Actions.showModal('CancelInvoice', { id }).then(result => {
      if (result) {
        this.props.reload();
      }
    });
  }

  @bind
  editCredit() {
    Actions.showModal('AddCredit', {
      students: this.props.students,
      transactable: this.props.data.transactable,
      subFamily: this.props.subFamily
    }).then(result => {
      if (result) {
        this.props.reload();
      }
    });
  }

  @bind
  cancelCredit() {
    const { id } = this.props.data.transactable;
    Actions.showModal('Confirmation', {
      title: 'Cancel Credit',
      description: 'Are you sure you want to cancel this credit?',
      noButton: 'No',
      yesButton: 'Yes, Cancel',
      yesButtonProps: {
        danger: true,
        secondary: false
      },
      noButtonProps: {
        secondary: true
      }
    }).then(result => {
      if (result) {
        req.cancelCredit({ id }).then(() => {
          this.props.reload();
        });
      }
    });
  }

  @bind
  cancelPayment() {
    const { id } = this.props.data.transactable;
    Actions.showModal('Confirmation', {
      title: 'Cancel Payment',
      description: 'Are you sure you want to cancel this payment?',
      noButton: 'No',
      yesButton: 'Yes, Cancel',
      yesButtonProps: {
        danger: true,
        secondary: false
      },
      noButtonProps: {
        secondary: true
      }
    }).then(result => {
      if (result) {
        req.cancelPayment({ id }).then(() => {
          this.props.reload();
        });
      }
    });
  }

  @bind
  delete(type) {
    return () => {
      const { id } = this.props.data.transactable;
      const cap = capitalize(type);
      Actions.showModal('Confirmation', {
        title: `Delete ${cap}`,
        description: `Are you sure you want to delete this ${type}?`,
        noButton: 'No',
        yesButton: 'Yes, Delete',
        yesButtonProps: {
          danger: true,
          secondary: false
        },
        noButtonProps: {
          secondary: true
        }
      }).then(result => {
        if (result) {
          req[`delete${cap}`]({ id }).then(() => {
            this.props.reload();
          });
        }
      });
    };
  }

  // partial renders
  getDescription(isTooltip = false) {
    const { kind, transactable, mode, creator, registration_form_fee } = this.props.data;
    const { check_number, parent_invoice_items: items } = transactable;
    let description = '';

    if (registration_form_fee) {
      return transactable.description;
    }

    switch (kind) {
      case 'parent_invoice': {
        if (isTooltip && items.length > 1) {
          return (
            <ol>
              {items.map((item, index) => (
                <li key={item.id}>
                  {index + 1}. {item.description}
                </li>
              ))}
            </ol>
          );
        } else {
          return items.length > 1
            ? items[0].description + ` (+${items.length - 1})`
            : items.length === 1
            ? items[0].description
            : '';
        }
      }
      case 'parent_refund':
      case 'parent_credit':
        return transactable.description;

      case 'parent_payment':
        switch (mode) {
          case 'bank':
          case 'bank_account':
          case 'card':
            return creator ? `By ${creator.name}. Last 4: ${transactable.last4}` : `Last 4: ${transactable.last4}`;

          case 'cash':
          case 'other':
            return transactable.description;

          case 'check':
            return (check_number ? `Check number: ${check_number}. ` : '') + transactable.description;
        }
    }

    return description;
  }

  @bind
  getStatusText(kind, transactable) {
    if (kind === 'parent_invoice') {
      return this.getInvoiceStatus(transactable.is_posted, transactable.state);
    }

    if (transactable.state === 'state_failed') {
      return transactable.state_message;
    }

    return PAYMENT_STATUSES[transactable.state].text;
  }

  @bind
  getPaymentContextMenu() {
    const { data, franchiseBilling } = this.props;
    const { mode } = data;
    const { state } = data.transactable;
    const offline = mode === 'cash' || mode === 'check' || mode === 'other';

    const refundTooltip = data.transactable.refund_exists
      ? 'Refund previously issued'
      : data.transactable.state === 'state_under_process'
      ? 'Refund cannot be issued while status is in process'
      : data.transactable.state === 'state_failed'
      ? 'Refund cannot be issued for failed payments'
      : 'Refund cannot be issued for payments over 90 days';

    const sameMonth = moment().isSame(data.date, 'month');

    if (state === 'state_cancelled') {
      return (
        <ContextMenu>
          <li className="payment-context-delete" onClick={this.delete('payment')}>
            Delete
          </li>
        </ContextMenu>
      );
    }

    return (
      <ContextMenu>
        <li className="payment-context-view" onClick={this.showOpenPaymentModal}>
          View Receipt
        </li>
        <li
          className={cx('payment-context-refund', {
            'payment-context-refund--disabled': !data.transactable.refundable
          })}
          onClick={data.transactable.refundable && this.refund}
        >
          <span className="payment-context-refund__text">Issue Refund</span>
          {!data.transactable.refundable && (
            <TooltipTrigger
              side="right"
              renderTooltip={() => <div className="transaction-row__refund-tooltip">{refundTooltip}</div>}
              className="transaction-row__refund"
            >
              <Icon size={16} name="help2" className="transaction-row__refund-icon" />
            </TooltipTrigger>
          )}
        </li>

        {offline && [
          (!franchiseBilling || sameMonth) && (
            <li key="menu-edit-payment" className="payment-context-edit" onClick={this.editPayment}>
              Edit
            </li>
          ),
          <li key="menu-cancel-payment" className="payment-context-cancel" onClick={this.cancelPayment}>
            Cancel
          </li>
        ]}
      </ContextMenu>
    );
  }

  @bind
  getInvoiceContextMenu() {
    const { allowReceivePayment, fromParent, data, franchiseBilling } = this.props;
    const sameMonth = moment().isSame(data.date, 'month');

    if (fromParent) {
      return (
        <ContextMenu>
          <li className="invoice-context-view" onClick={this.showOpenInvoiceModal}>
            View
          </li>
        </ContextMenu>
      );
    }

    const { state } = data.transactable;

    if (state === 'state_cancelled') {
      return (
        !franchiseBilling && (
          <ContextMenu>
            <li className="invoice-context-delete" onClick={this.delete('invoice')}>
              Delete
            </li>
          </ContextMenu>
        )
      );
    }

    return (
      <ContextMenu>
        <li className="invoice-context-view" onClick={this.showOpenInvoiceModal}>
          View
        </li>

        {(!franchiseBilling || sameMonth) && (
          <li className="invoice-context-edit" onClick={this.editInvoice}>
            Edit
          </li>
        )}

        {(!franchiseBilling || sameMonth) && (
          <li className="invoice-context-cancel" onClick={this.cancelInvoice}>
            Cancel
          </li>
        )}

        <li className="invoice-context-send-reminder" onClick={this.sendInvoiceReminder}>
          Send Invoice
        </li>

        {allowReceivePayment && (
          <li className="invoice-context-receive-payment" onClick={this.receivePayment}>
            Receive Payment
          </li>
        )}
      </ContextMenu>
    );
  }

  @bind
  getCreditContextMenu() {
    const { fromParent, data, franchiseBilling } = this.props;
    const sameMonth = moment().isSame(data.date, 'month');

    if (fromParent) {
      return null;
    }

    const { state } = data.transactable;

    if (state === 'state_cancelled') {
      return (
        !franchiseBilling && (
          <ContextMenu>
            <li className="credit-context-delete" onClick={this.delete('credit')}>
              Delete
            </li>
          </ContextMenu>
        )
      );
    }

    if (franchiseBilling && !sameMonth) {
      return null;
    }

    return (
      <ContextMenu>
        {sameMonth && (
          <li className="credit-context-edit" onClick={this.editCredit}>
            Edit
          </li>
        )}

        <li className="credit-context-cancel" onClick={this.cancelCredit}>
          Cancel
        </li>
      </ContextMenu>
    );
  }

  @bind
  getContextMenu() {
    const {
      fromParent,
      data: { kind },
      hasWritePermissions
    } = this.props;

    if (!fromParent && !hasWritePermissions('billing')) {
      return null;
    }

    switch (kind) {
      case 'parent_refund':
        return null;

      case 'parent_credit':
        return this.getCreditContextMenu();

      case 'parent_invoice':
        return this.getInvoiceContextMenu();

      case 'parent_payment':
        return fromParent ? null : this.getPaymentContextMenu();
    }
  }

  getInvoiceStatus(isPosted, state) {
    if (state === 'state_cancelled') {
      return 'Cancelled';
    } else if (isPosted) {
      return 'Sent';
    }

    return 'Not Sent';
  }

  getAmount(data) {
    switch (data.kind) {
      case 'parent_invoice':
      case 'parent_refund':
        return data.amount;
      default:
        return -data.amount;
    }
  }

  renderDescriptionTooltip(description) {
    return <div className="transaction-row__description-tooltip__content">{description}</div>;
  }

  render() {
    const { data, students, showStudent, fromParent, showBalance, hasFamilyLink } = this.props;
    const { kind, mode, sub_mode, transactable } = data;

    // to handle v1 ill-formed data
    if (!transactable) {
      return null;
    }

    const formattedDate = moment(data.date, 'YYYY-MM-DD').format('MMM D, YYYY');
    const formattedTime = Helpers.formatTime(data.date);

    const dueDate = data.transactable.due_date
      ? moment(data.transactable.due_date, 'YYYY-MM-DD').format('MMM DD, YYYY')
      : '';

    const balanceCN = cx('table__cell', 'table__cell--xs', 'transaction-row__balance', {
      'transaction-row__balance--positive': data.balance > 0
    });

    const statusIcon = (
      <StatusIcon
        name={
          PAYMENT_STATUSES[
            kind === 'parent_invoice'
              ? this.getInvoiceStatus(transactable.is_posted, transactable.state)
              : transactable.state
          ].icon
        }
      />
    );
    const paymentMode = getPaymentModeDescription(mode, sub_mode, transactable);

    return (
      <div className="transaction-row table__row">
        <div className="table__cell table__cell--xs transaction-row__date">
          {formattedDate}
          <div className="transaction-row__date-time">{formattedTime}</div>
        </div>

        {showStudent && (
          <div className="table__cell table__cell--s">
            {hasFamilyLink ? (
              <Link to={students.length ? `/billing/family/${students[0].family_id}/` : ''} underlined={false}>
                <StudentList students={students} showRoom />
              </Link>
            ) : (
              <StudentList students={students} showRoom />
            )}
          </div>
        )}

        <div className="table__cell table__cell--xs transaction-row__payment-cell">
          <div className="transaction-row__payment">
            <div className="transaction-row__payment-kind">
              {PAYMENT_KINDS[kind] || '-'}

              {transactable.billing_plan_id && (
                <TooltipTrigger
                  side="center"
                  renderTooltip={() => 'Recurring Invoice'}
                  triggerOn="hover"
                  className="tooltip--button"
                >
                  <ActionButton
                    iconName="recurring-plan"
                    size={16}
                    onClick={() =>
                      !fromParent &&
                      goToPage('/billing/plans', {
                        filter: { search: `${students[0].first_name} ${students[0].last_name}` }
                      })
                    }
                  />
                </TooltipTrigger>
              )}
            </div>

            {mode && <div className="transaction-row__payment-mode">{paymentMode}</div>}

            {dueDate && <div className="transaction-row__payment-mode">{dueDate}</div>}
          </div>
        </div>

        <div className="table__cell table__cell--s transaction-row__description">
          <TooltipTrigger
            side="center"
            tooltip={this.renderDescriptionTooltip(this.getDescription(true) || 'No Description')}
          >
            <span className="transaction-row__description-tooltip" onClick={this.showOpenInvoiceModal}>
              {this.getDescription() || 'No Description'}
            </span>
          </TooltipTrigger>
          {!fromParent && transactable.notes && (
            <TooltipTrigger
              white
              side="center"
              renderTooltip={() => transactable.notes}
              triggerOn="click"
              className="tooltip--button"
            >
              <Icon name="sidebar-report" size={16} color="#FDD755" />
            </TooltipTrigger>
          )}
        </div>

        {!fromParent && (
          <div className="table__cell table__cell--xs transaction-row__status">
            {transactable.state_message ? (
              <TooltipTrigger
                side="center"
                renderTooltip={() => transactable.state_message}
                triggerOn="hover"
                className="tooltip--button"
              >
                {statusIcon}
              </TooltipTrigger>
            ) : (
              statusIcon
            )}
            <span className="transaction-row__status-text">
              {kind === 'parent_invoice'
                ? this.getInvoiceStatus(transactable.is_posted, transactable.state)
                : PAYMENT_STATUSES[transactable.state].text}
            </span>
          </div>
        )}

        <div
          className={cx('table__cell table__cell--xs transaction-row__amount', `amount_${this.getAmount(data) * 100}`)}
        >
          {fromParent && (
            <StatusIcon
              name={
                PAYMENT_STATUSES[
                  kind === 'parent_invoice'
                    ? this.getInvoiceStatus(transactable.is_posted, transactable.state)
                    : transactable.state
                ].icon
              }
            >
              {this.getStatusText(kind, transactable)}
            </StatusIcon>
          )}
          {currency.getPrice(this.getAmount(data))}
        </div>

        {showBalance && <div className={balanceCN}>{currency.getPrice(data.balance)}</div>}

        <div className="table__cell table__cell--xxs table__cell--actions">{this.getContextMenu()}</div>
      </div>
    );
  }
}

export default withPermissionsChecking(TransactionRow);
