import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, ActionButton, Icon, Preloader } from 'components';
import S3Uploader from 'lib/S3Uploader';
import './style.scss';

const MB = 1024 * 1024;
const MAX_FILE_SIZE_MB = 25;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * MB;

class AddAttachment extends Component {
  static propTypes = {
    onClose: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      file_url: '',
      loading: false
    };

    this.input = React.createRef();
    this.formRef;
  }

  @bind
  handleUploadClick() {
    this.input.current.click();
  }

  @bind
  uploadFile(e) {
    const { files } = e.target;

    if (files.length === 0) {
      return;
    }

    const reader = new FileReader();
    const file = files[0];

    if (!file || file.size > MAX_FILE_SIZE) {
      Actions.showFlash(`File is too large. Max size is ${MAX_FILE_SIZE_MB} MB.`, 'alert');
      return;
    }

    reader.onloadend = () =>
      this.setState({ loading: true }, () => {
        const s3Config = {
          key: 'lessons',
          getSignature: req.awsSignature
        };

        S3Uploader.upload([file], s3Config)
          .then(this.handleSuccess)
          .catch(this.handleFailure);
      });

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  @bind
  handleSuccess(files) {
    const file = files[0];

    this.setState(
      state => {
        return {
          name: state.name || file.name,
          file_url: file.url,
          loading: false
        };
      },
      () => {
        this.formRef.updateField('name', this.state.name);
      }
    );
  }

  @bind
  handleFailure(err) {
    console.log(err);
    Actions.reportError('Unable to upload file', err);
    this.setState({ loading: false });
  }

  @bind
  change(values) {
    this.setState(prevState => ({ ...prevState, ...values }));
  }

  @bind
  submit() {
    const { file_url, name } = this.state;
    this.props.onClose({ file_url, title: name });
  }

  render() {
    const { name, file_url, loading } = this.state;

    return (
      <div className="add-attachment">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Attach File</div>
        </div>

        <Form ref={node => (this.formRef = node)} onChange={this.change} isLoading={loading} onSubmit={this.submit}>
          <div className="modal__container">
            <div className="form__row">
              <Form.Input required label="Name" name="name" defaultValue={name} disabled={loading} />
            </div>
            {loading ? (
              <div className="add-attachment__loading">
                <Preloader className="add-attachment__loading-icon" small />
                <span className="add-attachment__loading-title">Uploading file</span>
              </div>
            ) : !file_url ? (
              <div className="form__row">
                <React.Fragment>
                  <input
                    ref={this.input}
                    type="file"
                    value=""
                    className="add-attachment__input"
                    onChange={this.uploadFile}
                  />
                  <ActionButton
                    disabled={loading}
                    className="add-attachment__add-btn"
                    iconName="attach-file"
                    title="Add file"
                    onClick={this.handleUploadClick}
                  />
                </React.Fragment>
              </div>
            ) : (
              <div className="add-attachment__success">
                <Icon className="add-attachment__success-icon" size={16} name="tick" />
                <span className="add-attachment__success-title">File is uploaded</span>
              </div>
            )}
          </div>

          <div className="modal__controls">
            <Form.Submit disabled={!file_url} label="Submit" />
          </div>
        </Form>
      </div>
    );
  }
}

export default AddAttachment;
