import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectGroup } from 'components';
import GenericEditor from '../GenericEditor';
import './style.scss';

class DropdownEditor extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['radio', 'checkbox']),
    placeholder: PropTypes.string,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    enableClear: PropTypes.bool,
    options: PropTypes.array,
    tapable: PropTypes.bool,
    disabled: PropTypes.bool,
    locked: PropTypes.bool,
    'data-cy': PropTypes.string,
    multipleSelectionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  };

  static defaultProps = {
    type: 'checkbox',
    placeholder: 'Select value',
    options: []
  };

  @bind
  submit(value) {
    return Promise.resolve(this.props.onChange(value));
  }

  renderOptions() {
    const { options } = this.props;

    return options.map(r => <SelectGroup.Item key={r.id} value={r.id} label={r.name} />);
  }

  render() {
    const {
      type,
      placeholder,
      defaultValue,
      tapable,
      disabled,
      locked,
      'data-cy': dataCY,
      multipleSelectionText,
      enableClear
    } = this.props;
    const className = tapable ? 'tapable-editor' : 'dropdown-editor';

    return (
      <GenericEditor
        className={className}
        onSubmit={this.submit}
        defaultValue={defaultValue}
        disabled={disabled}
        locked={locked}
      >
        {({ value, onChange }) => (
          <SelectGroup
            className={`${className}__select`}
            type={type}
            title={placeholder}
            checked={value}
            onChange={onChange}
            onClear={enableClear ? () => onChange(undefined) : undefined}
            tapable={tapable}
            data-cy={dataCY}
            multipleSelectionText={multipleSelectionText}
          >
            {this.renderOptions()}
          </SelectGroup>
        )}
      </GenericEditor>
    );
  }
}

export default DropdownEditor;
