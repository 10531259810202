import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ActivityIcon from 'components/ActivityList/Activity/ActivityIcon';
import { CREATABLE_ACTIVITY_TYPES } from 'constants/activityTypes';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class ChooseActivity extends Component {
  static propTypes = {
    onTypeChange: PropTypes.func
  };

  static defaultProps = {};

  @bind
  updateActivityType(type) {
    if (this.props.onTypeChange) {
      this.props.onTypeChange(type);
    }
    this.props.context.nextStep();
  }

  render() {
    const { currentStep } = this.props.context;

    return (
      <div className="create-activity__new-activity">
        <div className="modal__header create-activity__header">
          <div className="create-activity__header-title">
            <span className="modal__header-title">Choose Activity Type</span>

            <div className="modal__header-steps">
              <span>Step {currentStep}</span> / 2
            </div>
          </div>

          <div className="modal__header-desc">
            Share student activity with parents. Parents can view activity on mobile app.
          </div>
        </div>

        <div className="create-activity__choose">
          {CREATABLE_ACTIVITY_TYPES.map((activityType, index) => (
            <div key={`${activityType.id}_${index}`} className="create-activity__choose-item">
              <div
                key={`${activityType.id}_${index}`}
                className="create-activity__choose-item__inner"
                onClick={() => this.updateActivityType(activityType.id)}
              >
                <ActivityIcon type={activityType.id} />
                <span>{activityType.label}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(ChooseActivity);
