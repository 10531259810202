import React from 'react';
import PropTypes from 'prop-types';
import { InlineEditor, SelectGroup, Status } from 'components';
import './style.scss';

const statuses = {
  active: 'Active',
  inactive: 'Deactivated'
};

const StatusEditor = ({ value, onChange, disabled, locked, lockedText }) => {
  const renderStatuses = () => {
    return Object.keys(statuses).map(s => (
      <SelectGroup.Item
        key={s}
        value={s}
        label={statuses[s]}
        className={`status-editor__item status-editor__item--${s}`}
      >
        <Status label={statuses[s]} />
      </SelectGroup.Item>
    ));
  };

  return (
    <InlineEditor.Generic
      disabled={disabled}
      locked={locked}
      lockedText={lockedText}
      className="status-editor"
      onSubmit={onChange}
      defaultValue={value ? 'active' : 'inactive'}
    >
      {({ value, onChange }) => (
        <SelectGroup
          type="radio"
          title="Select Status"
          className="status-editor__select"
          checked={value}
          onChange={onChange}
        >
          {renderStatuses()}
        </SelectGroup>
      )}
    </InlineEditor.Generic>
  );
};

StatusEditor.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
  lockedText: PropTypes.string
};

export default StatusEditor;
