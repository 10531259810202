import React, { Component } from 'react';

class YearMonthForm extends Component {
  monthSelect = null;
  yearSelect = null;

  @bind
  handleChange() {
    const year = this.yearSelect.value;
    const month = this.monthSelect.value;

    if (this.props.onChange) {
      this.props.onChange(new Date(year, month));
    }
  }

  render() {
    const { date, localeUtils, from, to } = this.props;
    const months = localeUtils.getMonths();
    const years = [];

    for (let i = from.getFullYear(); i >= to.getFullYear(); i -= 1) {
      years.push(i);
    }

    return (
      <div className="DayPicker-Caption">
        <select
          className="datepicker__month-select"
          name="month"
          onChange={this.handleChange}
          value={date.getMonth()}
          ref={node => (this.monthSelect = node)}
        >
          {months.map((month, i) => (
            <option key={i} value={i}>
              {month}
            </option>
          ))}
        </select>

        <select
          className="datepicker__year-select"
          name="year"
          onChange={this.handleChange}
          value={date.getFullYear()}
          ref={node => (this.yearSelect = node)}
        >
          {years.map((year, i) => (
            <option key={i} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default YearMonthForm;
