import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, ButtonV2 } from 'components';
import moment from 'moment';
import TEXT_CAPTIONS from '../constants';
import StaffPresentSelect from '../StaffPresentSelect';
import './style.scss';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';
import ManageLearningActivities from 'modals/ManageLearningActivities';

class Learning extends Component {
  static propTypes = {
    useDate: PropTypes.bool,
    activity: PropTypes.object,
    names: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    useDate: true,
    names: [],
    categories: [],
    activity: {
      data: {}
    }
  };

  componentDidMount() {
    req.learningActivityNames();
    req.learningActivityCategories();
  }

  @bind
  manageNames() {
    Actions.showModal('ManageLearningActivities', {
      type: 'name'
    });
  }

  @bind
  manageCategories() {
    Actions.showModal('ManageLearningActivities', {
      type: 'category'
    });
  }

  @bind
  isLessonAndLearning(activity) {
    if (!activity.activiable) {
      return false;
    }

    return activity.activiable.lesson_id || !activity.activiable.learning_activity_name;
  }

  render() {
    const { useDate, activity, categories, names, forceStaff } = this.props;

    return (
      <div className="form__body">
        {useDate && (
          <div className="form__row form__row--datetime">
            <Form.DatePicker label="Date" name="date" defaultValue={moment(activity.activity_time).toDate()} required />

            <Form.TimeInput
              label="Time"
              name="time"
              defaultValue={Helpers.formatTime(activity.activity_time)}
              required
            />
          </div>
        )}

        {!this.isLessonAndLearning(activity) && (
          <div className="form__row form__row--justified">
            <div className="learning__manage">
              <ButtonV2 tertiary label="Manage" className="learning__manage-btn" onClick={this.manageNames} />
              <Form.Select
                name="name_id"
                defaultValue={activity.activiable ? activity.activiable.learning_activity_name.id : undefined}
                placeholder="Activity Name"
                label="Activity Name"
                title="Select Name"
                data-cy="activity-name"
                required
              >
                {names.map(n => (
                  <Form.Select.Item key={n.id} value={n.id} label={n.value} />
                ))}
              </Form.Select>
            </div>

            <div className="learning__manage">
              <ButtonV2 tertiary label="Manage" className="learning__manage-btn" onClick={this.manageCategories} />
              <Form.Select
                type="checkbox"
                name="category_ids"
                defaultValue={
                  activity.activiable ? activity.activiable.learning_activity_categories.map(c => c.id) : undefined
                }
                placeholder="Activity Category"
                label="Categories"
                title="Select Categories"
                multipleSelectionText="categories"
                enableClear
              >
                {categories.map(c => (
                  <Form.Select.Item key={c.id} value={c.id} label={c.value} />
                ))}
              </Form.Select>
            </div>
          </div>
        )}

        <StaffPresentSelect activity={activity} />

        <div className="form__row">
          <Form.Textarea name="desc" defaultValue={activity.comment} placeholder="Description" label="Description" />
        </div>

        <div className="form__row">
          {forceStaff ? (
            TEXT_CAPTIONS.staffOnlyForce
          ) : (
            <Form.Checkbox
              type="circle"
              name="staffOnly"
              label={TEXT_CAPTIONS.staffOnly}
              defaultValue={activity.is_staff_only}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ learningActivityNames, learningActivityCategories }) => ({
  names: learningActivityNames.data,
  categories: learningActivityCategories.data
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(Learning);
