import React from 'react';
import { useSelector } from 'react-redux';
import { ButtonV2 } from 'components';

function DisableIntegration({ onClose, data }) {
  const currentUser = useSelector(state => state.currentUser.data);

  const disableIntegration = async () => {
    const schoolId = currentUser?.current_school?.id;
    if (!schoolId) {
      Actions.showFlash('No school ID found', 'alert');
      onClose(false);
      return;
    }

    const payload = {
      school_id: schoolId,
      integrator_id: data.integrator_id
    };

    try {
      await req.integratorDisable(payload);
      onClose(true);
      Actions.showFlash('Integration has been disabled.');
    } catch (error) {
      Actions.showFlash('Failed to disable integration.', 'alert');
      onClose(false);
    }
  };

  return (
    <div className="modal__wrapper connect-intellikids">
      <div className="modal__header">
        <span className="modal__header-title">Disable Integration</span>
      </div>
      <div className="modal__container">
        <p className="instructions">{data.instruction}</p>
        <div className="form__row form__row--actions">
          <ButtonV2 data-cy="no" secondary label="No" onClick={() => onClose(false)} />
          <ButtonV2 data-cy="yes" label="Yes, Disable" onClick={disableIntegration} />
        </div>
      </div>
    </div>
  );
}

export default DisableIntegration;
