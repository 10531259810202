import React, { Component } from 'react';
import Item from './Item';
import './style.scss';
import { ModalControllerStepsContext } from 'hocs/withContext';

class Steps extends Component {
  static Item = Item;

  getStepsContext() {
    return {
      nextStep: this.nextStep.bind(this),
      prevStep: this.prevStep.bind(this),
      goToStep: this.goToStep.bind(this),
      currentStep: this.state.currentStep + 1,
      totalSteps: this.state.totalSteps
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      currentStep: props.startStep || 0,
      totalSteps: React.Children.toArray(props.children).filter(c => c && c.props && !c.props.hidden).length || 0,
      payload: {}
    };
  }

  nextStep() {
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  prevStep() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  goToStep(step, stepPayload) {
    if (typeof step === 'string') {
      step = this.getNamedStepIndex(step);
    }

    if (step === -1) {
      return;
    }

    this.setState({
      currentStep: step,
      payload: {
        ...this.state.payload,
        [step]: stepPayload || undefined
      }
    });
  }

  @bind
  getNamedStepIndex(stepName) {
    if (typeof stepName !== 'string' || stepName.length === 0) {
      return -1;
    }

    const steps = React.Children.toArray(this.props.children);
    return steps.findIndex(s => s && s.props && s.props.name === stepName);
  }

  getCount() {
    const { children } = this.props;
    return React.Children.toArray(children).filter(c => c && c.props && !c.props.hidden).length;
  }

  getSteps() {
    const { children } = this.props;
    const { currentStep, payload } = this.state;
    const stepPayload = payload[currentStep];

    return React.Children.toArray(children)
      .filter(c => c && c.props)
      .map(c => <c.type {...c.props} step={currentStep + 1} totalSteps={this.getCount()} payload={stepPayload} />);
  }

  render() {
    const { currentStep } = this.state;
    const steps = this.getSteps();

    return (
      <ModalControllerStepsContext.Provider value={this.getStepsContext()}>
        <div className="modal-steps">{steps.length > 0 ? steps[currentStep] : null}</div>
      </ModalControllerStepsContext.Provider>
    );
  }
}

export default Steps;
