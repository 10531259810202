import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class PlaceholderStep extends Component {
  static propTypes = {
    index: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string
  };

  static defaultProps = {
    index: 1
  };

  render() {
    const { index, title, description, children } = this.props;
    const content = description || children;

    return (
      <div className="placeholder-step">
        <div className="placeholder-step__index">{index}</div>

        {title && <div className="placeholder-step__title">{title}</div>}

        {content && <div className="placeholder-step__description">{content}</div>}
      </div>
    );
  }
}

export default PlaceholderStep;
