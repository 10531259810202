import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TooltipTrigger } from 'components';
import './style.scss';

// Thanks to https://stackoverflow.com/a/13532993
function shadeColor(col, amt) {
  var usePound = false;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  let R = parseInt(col.substring(0, 2), 16);
  let G = parseInt(col.substring(2, 4), 16);
  let B = parseInt(col.substring(4, 6), 16);

  // to make the colour less bright than the input
  // change the following three "+" symbols to "-"
  R = R + amt;
  G = G + amt;
  B = B + amt;

  if (R > 255) R = 255;
  else if (R < 0) R = 0;

  if (G > 255) G = 255;
  else if (G < 0) G = 0;

  if (B > 255) B = 255;
  else if (B < 0) B = 0;

  const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

  return (usePound ? '#' : '') + RR + GG + BB;
}

class Badge extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    style: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string
  };

  static defaultProps = {
    style: 'grey'
  };

  get className() {
    return classNames('badge', this.props.className, {
      [`badge--${this.props.style}`]: this.props.style
    });
  }

  get inlineStyle() {
    const { color } = this.props;

    if (!color) {
      return undefined;
    }

    return { color: shadeColor(color, 70), backgroundColor: shadeColor(color, -10) };
  }

  @bind
  renderTooltipContent() {
    const { description } = this.props;

    return <div className="badge__tooltip-content">{description}</div>;
  }

  render() {
    const { children, description } = this.props;

    const content = (
      <div className={this.className} style={this.inlineStyle}>
        {children && <span className="badge__text">{children}</span>}
      </div>
    );

    if (!description) {
      return content;
    }

    return (
      <TooltipTrigger
        white
        side="center"
        tooltipClassName="badge__tooltip"
        maxHeight={150}
        renderTooltip={this.renderTooltipContent}
      >
        {content}
      </TooltipTrigger>
    );
  }
}

export default Badge;
