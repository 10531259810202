import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { listenClick } from 'lib/utils';
import './style.scss';

const timeRegex = /\d{1,2}:\d{2}\s(AM|PM)/;
const timeFormat = 'h:mm A';

export const FocusedValue = {
  Hours: 'hours',
  Minutes: 'minutes',
  Period: 'period',
  None: null
};

export const KeyCode = {
  Up: 38,
  Down: 40,
  Left: 37,
  Right: 39,
  A: 65,
  P: 80
};

const Period = {
  AM: 'AM',
  PM: 'PM'
};

const focusOrder = [FocusedValue.Hours, FocusedValue.Minutes, FocusedValue.Period];

class TimeInput extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    autoPeriod: PropTypes.bool
  };

  static defaultProps = {};

  containerEl = null;
  hiddenInput = null;
  inputValue = '';

  constructor(props, context) {
    super(props, context);

    const valueObject = this.getValueObject(props.defaultValue);

    this.state = {
      // If no defaultValue is provided or the provided one is invalid
      isEmpty: valueObject === null,

      // One of FocusedValue
      focusedOn: null,

      // Actual values of the input
      values: valueObject || { hours: 12, minutes: 0, period: props.autoPeriod ? moment().format('A') : Period.AM }
    };
  }

  componentWillUnmount() {
    this.removeKeyListeners();
    this.removeClickListener();
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.getValue(this.state.values) !== this.getValue(nextState.values)) {
      this.setState({ isEmpty: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { focusedOn } = this.state;
    const prevDisabled = prevProps.disabled;
    const prevFocusedOn = prevState.focusedOn;

    if (
      (this.props.disabled && !prevDisabled) ||
      (focusedOn === FocusedValue.None && prevFocusedOn !== FocusedValue.None)
    ) {
      this.removeClickListener();
      this.removeKeyListeners();
      this.blur();

      return;
    }

    if (focusedOn !== FocusedValue.None && prevFocusedOn === FocusedValue.None) {
      this.setClickListener();
      this.setKeyListeners();
      this.focusHiddenInput();
    }

    if (this.getValue(this.state.values) !== this.getValue(prevState.values)) {
      this.handleChange();
    }
  }

  setClickListener() {
    if (this.state.focusedOn !== FocusedValue.None) {
      setTimeout(() => document.addEventListener('click', this.listenClickOutside), 250);
    }
  }

  removeClickListener() {
    document.removeEventListener('click', this.listenClickOutside);
  }

  setKeyListeners() {
    document.addEventListener('keydown', this.listenArrows);
    document.addEventListener('keydown', this.listenPeriod);
  }

  removeKeyListeners() {
    document.removeEventListener('keydown', this.listenArrows);
    document.removeEventListener('keydown', this.listenPeriod);
  }

  /**
   * Returns an object with destructed time values or null, if
   * the invalid time string is passed.
   *
   * @param {string} timeStr   A time string of the format "hh:mm A"
   * @return {Object}          An object of the structure { hours: number, minutes: number, period: string }
   */
  getValueObject(timeStr) {
    const momentValue = moment(timeStr, timeFormat);
    const parsedTime = timeRegex.test(timeStr) && momentValue.isValid() ? momentValue : null;

    if (parsedTime === null) {
      return null;
    }

    return {
      hours: Number(parsedTime.format('h')) || 12, // Since we use 12-h system, `0` is not allowed in hours
      minutes: Number(parsedTime.format('mm')),
      period: parsedTime.format('A')
    };
  }

  getTimeString(timePart) {
    if (!timePart) {
      return '00';
    }

    return timePart.toString().length === 1 ? `0${timePart}` : timePart;
  }

  getValue(values) {
    const hours = this.getTimeString(values.hours);
    const minutes = this.getTimeString(values.minutes);

    return `${hours}:${minutes} ${values.period}`;
  }

  setFocus(focusedValue) {
    if (this.props.disabled) {
      return;
    }

    this.inputValue = '';
    this.setState({ focusedOn: focusedValue });
    this.focusHiddenInput();
  }

  @bind
  focusHours() {
    this.setFocus(FocusedValue.Hours);
  }

  @bind
  focusMinutes() {
    this.setFocus(FocusedValue.Minutes);
  }

  @bind
  focusPeriod() {
    this.setFocus(FocusedValue.Period);
  }

  @bind
  blur() {
    this.inputValue = '';
    this.setState({ focusedOn: FocusedValue.None });
    this.removeKeyListeners();
    this.removeClickListener();
    this.hiddenInput.blur();
  }

  @bind
  listenClickOutside(e) {
    listenClick(e, this.containerEl, this.blur);
  }

  @bind
  listenArrows(e) {
    const { focusedOn } = this.state;

    if (e.keyCode === KeyCode.Up || e.keyCode === KeyCode.Down) {
      const addValue = e.keyCode === KeyCode.Up ? 1 : -1;

      switch (focusedOn) {
        case FocusedValue.Hours:
          this.addHours(addValue);
          break;

        case FocusedValue.Minutes:
          this.addMinutes(addValue);
          break;

        case FocusedValue.Period:
          this.changePeriod();
          break;
      }

      return;
    }

    if (e.keyCode === KeyCode.Left || e.keyCode === KeyCode.Right) {
      const currentFocusIndex = focusOrder.indexOf(focusedOn);

      if (currentFocusIndex > 0 && e.keyCode === KeyCode.Left) {
        this.setFocus(focusOrder[currentFocusIndex - 1]);
      } else if (currentFocusIndex < focusOrder.length - 1 && e.keyCode === KeyCode.Right) {
        this.setFocus(focusOrder[currentFocusIndex + 1]);
      }
    }
  }

  @bind
  listenPeriod(e) {
    if (e.keyCode === KeyCode.A) {
      this.changePeriod(Period.AM);
    }

    if (e.keyCode === KeyCode.P) {
      this.changePeriod(Period.PM);
    }
  }

  @bind
  onHiddenChange(e) {
    const { value } = e.target;

    if (/\D/g.test(value) && this.state.focusedOn !== FocusedValue.Period) {
      return;
    }

    this.inputValue = this.inputValue + value;

    switch (this.state.focusedOn) {
      case FocusedValue.Hours:
        this.changeHours(this.inputValue);
        break;

      case FocusedValue.Minutes:
        this.changeMinutes(this.inputValue);
        break;
    }
  }

  changeHours(value) {
    const { values } = this.state;
    let numValue = Number(value);

    if (numValue > 1 || value.length > 1) {
      if (numValue > 12) {
        numValue -= 12;
      }

      this.focusMinutes();
    }

    this.setState({
      values: { ...values, hours: numValue }
    });
  }

  changeMinutes(value) {
    const { values } = this.state;
    const numValue = Number(value);

    if (value.length > 1 || numValue > 59) {
      this.focusPeriod();
    }

    this.setState({
      values: { ...values, minutes: numValue > 59 ? 59 : numValue }
    });
  }

  @bind
  changePeriod(period) {
    if (this.props.disabled) {
      return;
    }

    const { values } = this.state;
    const paramPeriod =
      typeof period === 'string' && [Period.AM, Period.PM].includes(period.toUpperCase()) ? period : null;

    this.setState({
      values: {
        ...values,
        period: paramPeriod || (values.period === Period.AM ? Period.PM : Period.AM)
      }
    });
  }

  @bind
  handleChange() {
    if (!this.props.onChange) {
      return;
    }

    this.props.onChange(this.getValue(this.state.values));
  }

  addHours(value) {
    const { values } = this.state;
    let nextHours = 0;

    if (values.hours + value > 12) {
      nextHours = 1;
    } else if (values.hours + value < 1) {
      nextHours = 12;
    } else {
      nextHours = values.hours + value;
    }

    this.setState({
      values: { ...values, hours: nextHours }
    });
  }

  addMinutes(value) {
    const { values } = this.state;
    let nextMinutes = 0;

    if (values.minutes + value > 59) {
      nextMinutes = 0;
    } else if (values.minutes + value < 0) {
      nextMinutes = 59;
    } else {
      nextMinutes = values.minutes + value;
    }

    this.setState({
      values: { ...values, minutes: nextMinutes }
    });
  }

  @bind
  focusHiddenInput() {
    if (this.hiddenInput) {
      this.hiddenInput.focus();
    }
  }

  render() {
    const { values, focusedOn, isEmpty } = this.state;
    const { disabled, className } = this.props;

    const timeInputCN = classNames('time-input', {
      'time-input--focused-h': focusedOn === FocusedValue.Hours,
      'time-input--focused-m': focusedOn === FocusedValue.Minutes,
      'time-input--focused-p': focusedOn === FocusedValue.Period,
      'time-input--empty': isEmpty,
      'time-input--disabled': disabled,
      [className]: Boolean(className)
    });

    return (
      <div className={timeInputCN}>
        <input
          type="text"
          value=""
          className="time-input__hidden"
          ref={node => (this.hiddenInput = node)}
          onChange={this.onHiddenChange}
          onFocus={this.focusHiddenInput}
        />
        <div className="time-input__inner" ref={node => (this.containerEl = node)}>
          <div className="time-input__hours" onClick={this.focusHours}>
            {this.getTimeString(values.hours)}
          </div>

          <div className="time-input__delim">:</div>

          <div className="time-input__minutes" onClick={this.focusMinutes}>
            {this.getTimeString(values.minutes)}
          </div>
        </div>

        <div className="time-input__period" onClick={this.changePeriod}>
          <div className="time-input__period-value">{values.period}</div>
        </div>
      </div>
    );
  }
}

export default TimeInput;
