import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'components';
import './style.scss';

function CheckrStart({ data, onClose, packages = [] }) {
  const [isLoading, setIsLoading] = useState(false);

  const { id: teacher_id, name = '', email = '', phone, checkr_linked } = data.staff || {};
  const [firstName, ...remainingName] = name.split(' ');
  const lastName = remainingName.length ? remainingName.join(' ') : '';

  useEffect(() => {
    req.getCheckrPackages();
  }, []);

  const handleStart = async values => {
    setIsLoading(true);
    try {
      const action = checkr_linked ? 'updateCheckrCandidate' : 'createCheckrCandidate';
      await req[action]({
        teacher_id,
        package: values.package,
        candidate: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone: values.phone
        }
      });
      Actions.showFlash('Background check successfully started!');
      onClose(false);
    } catch (e) {
      Actions.reportError('Unable to start check', e);
      setIsLoading(false);
    }
  };

  const renderPackage = value => {
    let price = '';

    if (!isNaN(Number(value.price))) {
      price = value.price / 100;
      price = ` $(${price % 1 !== 0 ? price.toFixed(2) : price})`;
    }

    return `${value.name}${price}`;
  };

  return (
    <div className="modal__wrapper start-checkr">
      <div className="modal__header">
        <div className="modal__header-title">Start Background Check</div>
      </div>
      <div className="modal__container">
        <div className="start-checkr__description">
          Checkr will initiate background check for the candidate. Checkr will contact the candidate through email or
          phone for additional information.
        </div>
        <Form onSubmit={handleStart}>
          <div className="form__row">
            <Form.Input defaultValue={firstName} label="First Name" name="first_name" required asterisk />
          </div>
          <div className="form__row">
            <Form.Input defaultValue={lastName} label="Last Name" name="last_name" required asterisk />
          </div>
          <div className="form__row">
            <Form.Input defaultValue={email} label="Email" type="email" name="email" required asterisk />
          </div>
          <div className="form__row">
            <Form.Input defaultValue={phone} label="phone number" name="phone" mask="999-999-999999999" />
          </div>
          <div className="form__row">
            <Form.Select name="package" title={'Select package'} type="radio" label="Package" required>
              {packages.map(p => (
                <Form.Select.Item key={p.id} value={p.slug} label={renderPackage(p)} />
              ))}
            </Form.Select>
          </div>
          <div className="form__row form__row--actions">
            <Form.Submit title="submit" disabled={isLoading} />
          </div>
        </Form>
      </div>
    </div>
  );
}

CheckrStart.propTypes = {
  data: PropTypes.shape({
    staff: PropTypes.object.isRequired
  })
};

const mapStateToProps = state => ({
  packages: state.checkrPackages.data
});

export default connect(mapStateToProps)(CheckrStart);
