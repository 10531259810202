import Meal from './Meal';
import Bottle from './Bottle';
import Mood from './Mood';
import Nap from './Nap';
import Bathroom from './Bathroom';
import Incident from './Incident';
import Photo from './Photo';
import Video from './Video';
import Absent from './Absent';
import Learning from './Learning';
import NameToFace from './NameToFace';
import Observation from './Observation';
import defaultForm from './default';

export default {
  meal_activity: Meal,
  bottle_activity: Bottle,
  mood_activity: Mood,
  nap_activity: Nap,
  bathroom_activity: Bathroom,
  incident_activity: Incident,
  photo_activity: Photo,
  video_activity: Video,
  medication_activity: defaultForm,
  note_activity: defaultForm,
  absent_activity: Absent,
  learning_activity: Learning,
  name_to_face_activity: NameToFace,
  observation_activity: Observation
};
