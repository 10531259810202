import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'components';
import GenericEditor from '../GenericEditor';
import './style.scss';

class TypeaheadEditor extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.array,
    disabled: PropTypes.bool,
    locked: PropTypes.bool,
    disableFilter: PropTypes.bool
  };

  static defaultProps = {
    placeholder: 'Enter value',
    options: [],
    disableFilter: true
  };

  @bind
  submit(value) {
    return Promise.resolve(this.props.onChange(value));
  }

  render() {
    const { placeholder, defaultValue, disabled, locked, action, options, disableFilter } = this.props;

    return (
      <GenericEditor
        className="typeahead-editor"
        onSubmit={this.submit}
        defaultValue={defaultValue}
        disabled={disabled}
        locked={locked}
      >
        {({ value, onChange }) => (
          <Typeahead
            type="radio"
            title="Select Preset Item"
            className="typeahead-editor__preset-select"
            action={action}
            disabled={disabled}
            options={options}
            onChange={onChange}
            placeholder={placeholder}
            defaultInputValue={value}
            disableFilter={disableFilter}
            disableHighlight
            hideNoMatch
          />
        )}
      </GenericEditor>
    );
  }
}

export default TypeaheadEditor;
