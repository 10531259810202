import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ActionButton, Preloader } from 'components';

class ManageListNew extends PureComponent {
  static propTypes = {
    ItemComponent: PropTypes.any,
    onCreate: PropTypes.func,
    onCancel: PropTypes.func,
    isLoading: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      newItem: {},
      isLoading: false
    };
  }

  @bind
  handleChange(newItem) {
    this.setState({ newItem: { ...this.state.newItem, ...newItem } });
  }

  @bind
  handleCreate() {
    const createResult = this.props.onCreate(this.state.newItem);

    if (createResult instanceof Promise) {
      this.setState({ isLoading: true });
      createResult.then(() => this.setState({ isLoading: false })).catch(() => this.setState({ isLoading: false }));
    }
  }

  render() {
    const { onCancel, ItemComponent } = this.props;
    const { newItem, isLoading } = this.state;

    return (
      <div className="manage-list-new">
        <div className="manage-list-new__content">
          <ItemComponent isEdit editedItem={newItem} onChange={this.handleChange} />
        </div>

        <div className="manage-list-new__controls">
          {isLoading ? (
            <div className="manage-list-new__preloader">
              <Preloader small />
            </div>
          ) : (
            <ActionButton
              square
              size={14}
              className="manage-list-new__create-btn"
              iconName="tick-1"
              onClick={this.handleCreate}
              data-cy="add-manage-list-item"
            />
          )}
          <ActionButton square size={14} iconName="close" onClick={onCancel} />
        </div>
      </div>
    );
  }
}

export default ManageListNew;
