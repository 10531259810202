import React, { forwardRef, useContext } from 'react';

const withContext = (Context, propName = 'context') => {
  return Component =>
    forwardRef((props, ref) => {
      const contextProps = {
        [propName]: useContext(Context)
      };

      return <Component ref={ref} {...props} {...contextProps} />;
    });
};

export default withContext;

export const RootContext = React.createContext();
export const FormContext = React.createContext();
export const DropdownContext = React.createContext();
export const BulkSendParentInviteContext = React.createContext();
export const CreateActivityContext = React.createContext();
export const ModalControllerStepsContext = React.createContext();
export const BillingRecurringPlansContext = React.createContext();
