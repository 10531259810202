import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Icon } from 'components';
import classNames from 'classnames';
import { cutString } from 'lib/utils';

class CategoryItem extends PureComponent {
  static propTypes = {
    item: PropTypes.object,
    editedItem: PropTypes.object,
    isEdit: PropTypes.bool,
    onChange: PropTypes.func
  };

  renderEdit() {
    const { item: category, renderControls, onChange, sections } = this.props;

    return (
      <Form
        className="'table__row manage-categories__item manage-categories__edit"
        onInit={onChange}
        onChange={onChange}
      >
        <div className="manage-categories__name">
          <Form.Input name="name" placeholder="Description" defaultValue={category?.name} />
        </div>

        <div className="manage-categories__rooms">
          <Form.Select
            name="sections"
            title="Room Assignment Needed"
            multipleSelectionText="Rooms Applied"
            type="checkbox"
            enableClear
            defaultValue={category?.category_sections && category.category_sections.map(cs => cs.section_id)}
          >
            {sections.map(section => (
              <Form.Select.Item key={section.id} value={section.id} label={section.name} />
            ))}
          </Form.Select>
        </div>

        <div className=" table__cell--actions manage-categories__actions">{renderControls()}</div>
      </Form>
    );
  }

  renderShow() {
    const { item: category, renderMenu, sections } = this.props;
    const assignedSections = category.category_sections.map(cs => sections.find(s => s.id === cs.section_id));

    const cnWarning = classNames(
      'manage-categories__rooms',
      !assignedSections?.length ? 'manage-categories__item--warning' : ''
    );

    return (
      <div className="table_row manage-categories__item">
        <div className="manage-categories__name">{category.name}</div>
        <div className={cnWarning}>
          {!assignedSections?.length && <Icon name="alert" />}
          {!assignedSections?.length && 'Room Assignment Needed'}
          {!!assignedSections?.length && assignedSections.map(section => cutString(section?.name || '', 25)).join(', ')}
        </div>
        <div className="manage-categories__controls">
          {renderMenu({
            delete: true,
            edit: true
          })}
        </div>
      </div>
    );
  }

  render() {
    return this.props.isEdit ? this.renderEdit() : this.renderShow();
  }
}

export default CategoryItem;
