import React, { Component } from 'react';
import { connect } from 'react-redux';
import filter from 'lodash/filter';
import { Form } from 'components';
import SelectKids from '../common/SelectKids';
import './style.scss';
import { BulkSendParentInviteContext } from 'hocs/withContext';

class BulkSendParentInvite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  static validations = {
    'No Email': kid => {
      return !kid.parents.filter(p => p.email).length;
    }
  };

  getInviteContext() {
    return {
      customItemOptions: {
        method: this.parentsWithoutEmail,
        label: kid => {
          return !this.parentsWithoutEmail(kid) && <div className="select-kids__item-label">No email</div>;
        }
      }
    };
  }

  parentsWithoutEmail(kid) {
    return filter(kid.parents, p => p.email).length > 0;
  }

  @bind
  submit(values) {
    this.setState({ loading: true });

    req
      .sendRegistrationCodes({ kid_ids: values })
      .then(() => {
        Actions.showFlash('Registration Invite emailed to the parents');
        this.props.onClose();
      })
      .catch(e => {
        Actions.reportError("Registration Invites couldn't be sent, try again", e);
        this.props.onClose();
      });
  }

  static parentsNotSignedUp = {
    method: (student, filters) => {
      if (filters.parent_not_signed_up) {
        const notInvitedParents = filter(student.parents, parent => !parent.is_signed_up);
        return notInvitedParents.length > 0;
      } else {
        return true;
      }
    },
    control: (
      <Form.Checkbox
        name="parent_not_signed_up"
        defaultValue={true}
        label="Show students whose parents are not signed up yet"
      />
    ),
    paramKey: 'parent_not_signed_up',
    paramValue: true
  };

  render() {
    const { loading } = this.state;
    const { students } = this.props;

    return (
      <BulkSendParentInviteContext.Provider value={this.getInviteContext()}>
        <div className="send-parent-invite">
          <SelectKids
            title="Send Parent Invite"
            label="Send email"
            onSubmit={this.submit}
            customCondition={BulkSendParentInvite.parentsNotSignedUp}
            isContinueLoading={loading}
            students={students}
            validations={BulkSendParentInvite.validations}
            disableInvalid={false}
          />
        </div>
      </BulkSendParentInviteContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  students: state.students.data
});

export default connect(mapStateToProps)(BulkSendParentInvite);
