import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ButtonV2 from '../../ButtonV2';
import withContext, { FormContext } from 'hocs/withContext';

class Submit extends Component {
  static propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    wide: PropTypes.bool,
    disabled: PropTypes.bool,
    tabIndex: PropTypes.number,
    name: PropTypes.string,
    secondary: PropTypes.bool,
    'data-cy': PropTypes.string,
    type: PropTypes.string
  };

  render() {
    const {
      label,
      className,
      disabled,
      tabIndex,
      isLoading,
      name,
      secondary,
      'data-cy': dataCY,
      type,
      id
    } = this.props;
    const { submit, validateOn, isFormValid, isLoading: isSaving } = this.props.context;

    return (
      <ButtonV2
        id={id}
        className={className}
        label={label || 'Submit'}
        onClick={e => submit(e, name)}
        isLoading={isSaving || isLoading}
        disabled={(!isFormValid() && validateOn !== 'submit') || disabled}
        tabIndex={tabIndex}
        name={name}
        secondary={secondary}
        data-cy={dataCY}
        type={type}
      />
    );
  }
}

export default withContext(FormContext)(Submit);
