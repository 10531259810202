import React, { Component } from 'react';
import { ButtonV2 } from 'components';
import GeneralForm from './GeneralForm';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

const STAFF_DESCRIPTION = 'Staff will receive a push notification and a message in their app inbox';

class MessageStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMessageValid: true
    };
  }

  @bind
  validate() {
    const newState = {
      isMessageValid: !!this.props.message
    };

    this.setState(newState);
    return newState.isMessageValid;
  }

  @bind
  onContinue() {
    const { sendSMS, message } = this.props;

    if (this.validate()) {
      if (sendSMS && message.length > 300) {
        Actions.showFlash('You can send only up to 300 characters via SMS.', 'alert');
        return;
      }

      this.props.context.goToStep('staff');
    }
  }

  render() {
    const { isMessageValid } = this.state;

    return (
      <div className="modal__wrapper">
        <div className="modal__header modal__header--bordered">
          <span className="modal__header-title new-staff-message__title">New Message</span>
          <span className="modal__header-steps">
            <span>step 1</span> / 2
          </span>

          <div className="new-staff-message__description">{STAFF_DESCRIPTION}</div>
        </div>

        <GeneralForm {...this.props} isMessageValid={isMessageValid} />

        <div className="modal__controls">
          <ButtonV2 label="Continue" data-cy="continue" onClick={this.onContinue} />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(MessageStep);
