import findIndex from 'lodash/findIndex';

const createBatchUpdateReducer = (reducerName, initialState) => (state = { ...initialState }, action) => {
  switch (action.type) {
    case `${reducerName}_BATCH_UPDATE`:
      return { ...state, loading: true };

    case `${reducerName}_BATCH_UPDATE_SUCCESS`:
      return {
        ...state,
        loading: false,
        data: state.data.map(entry => {
          const nextStudentIndex = findIndex(action.payload, { id: entry.id });

          if (nextStudentIndex >= 0) {
            return action.payload[nextStudentIndex];
          }

          return entry;
        })
      };

    case `${reducerName}_BATCH_UPDATE_FAILURE`:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default createBatchUpdateReducer;
