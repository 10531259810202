import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './AllDayEventItem.scss';

function AllDayEventItem({
  event,
  onSelect = () => {
    return;
  }
}) {
  const { title, kind } = event;

  const className = cx('weekly-calendar__alldays-event', `weekly-calendar__alldays-event--${kind}`, {
    'weekly-calendar__alldays-event--inactive': event.inactive
  });

  return (
    <div
      className={className}
      onClick={e =>
        onSelect({
          anchor: e.target,
          event
        })
      }
    >
      {title}
    </div>
  );
}

AllDayEventItem.propTypes = {
  event: PropTypes.object,
  onSelect: PropTypes.func
};

export default AllDayEventItem;
