import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { TooltipTrigger, ActionButton, Checkbox, Icon } from 'components';
import './style.scss';

class ChangeSchool extends Component {
  static propTypes = {
    schools: PropTypes.array,
    currentSchoolId: PropTypes.string,
    onSchoolChange: PropTypes.func
  };

  static defaultProps = {
    schools: []
  };

  @bind
  changeSchool(schoolId) {
    const { currentSchoolId, onSchoolChange } = this.props;

    if (currentSchoolId !== schoolId) {
      onSchoolChange(schoolId);
    }
  }

  renderSchools() {
    const { schools, currentSchoolId } = this.props;

    return schools
      .slice(0)
      .sort((a, b) => {
        const aStr = a.name.toLowerCase();
        const bStr = b.name.toLowerCase();

        return aStr === bStr ? 0 : aStr > bStr ? 1 : -1;
      })
      .map(school => {
        const schoolCN = classNames('change-school__item', {
          'change-school__item--selected': school.id === currentSchoolId
        });

        return (
          <li key={school.id} className={schoolCN} onClick={() => this.changeSchool(school.id)}>
            {school.profile_pic_url ? (
              <img className="change-school__avatar" src={school.profile_pic_url} />
            ) : (
              <div className="change-school__avatar">
                <Icon name="school" color="#999" size={32} />
              </div>
            )}

            <div className="change-school__name">{school.name}</div>

            {school.id === currentSchoolId && <Checkbox type="circle" checked={school.id === currentSchoolId} />}
          </li>
        );
      });
  }

  renderThumbVertical(props) {
    return <div {...props} className="change-school__vertical-scroll" />;
  }

  @bind
  renderTooltip() {
    return (
      <ul className="tooltip-content__inner change-school__list">
        <Scrollbars
          autoHeight
          autoHeightMin={0}
          autoHeightMax={400}
          thumbSize={30}
          renderThumbVertical={this.renderThumbVertical}
        >
          {this.renderSchools()}
        </Scrollbars>
      </ul>
    );
  }

  render() {
    if (this.props.schools.length <= 1) {
      return null;
    }

    return (
      <TooltipTrigger
        white
        className="tooltip--context-menu change-school"
        tooltipClassName="change-school__tooltip"
        triggerOn="click"
        side="center"
        renderTooltip={this.renderTooltip}
        closeOnInsideClick
      >
        <ActionButton size={14} iconName="chevron-down" className="change-school__arrow" />
      </TooltipTrigger>
    );
  }
}

export default ChangeSchool;
