import React, { Component } from 'react';
import { Form } from 'components';
import { connect } from 'react-redux';
import './style.scss';

class BluepayActivate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  @bind
  onSubmit(data) {
    this.setState({ isLoading: true });
    req
      .addBluepayAccount({
        bluepay: {
          account_id: data.accountId,
          secret_key: data.secretKey
        }
      })
      .then(() => {
        return req.profile();
      })
      .then(() => {
        this.setState({ isLoading: false });
        Actions.showFlash('Thanks for setting up online billing.');
        this.props.onClose();
      })
      .catch(err => {
        Actions.reportError('Something went wrong', err);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      accountId,
      secretKey,
      billingUrl,
      data: { updateAccount }
    } = this.props;

    return (
      <div className="bluepay-activate">
        <div className="modal__wrapper">
          <div className="modal__header modal__header--bordered">
            <span className="modal__header-title">{updateAccount ? 'Update Your Account' : 'Setup Your Account'}</span>
          </div>
        </div>
        <div className="modal__container">
          <div className="bluepay-activate__desc">
            There are two steps you need to follow to get your bank deposit account setup with Procare through BluePay.
          </div>
          <div className="bluepay-activate__divider" />

          <div className="bluepay-activate__legend">STEP 1</div>
          <div className="bluepay-activate__desc">
            Setup BluePay account with your bank deposit account details. If you already setup with BluePay account,
            then skip to Step 2.
          </div>
          <div className="form__row form__row--actions">
            <a href={billingUrl} className="button" target="_blank">
              Setup Account
            </a>
          </div>

          <div className="bluepay-activate__divider" />

          <Form
            className="bluepay-activate__form"
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            validateOn="submit"
          >
            <div className="bluepay-activate__legend">STEP 2</div>
            <div className="bluepay-activate__desc">
              Once your BluePay account is setup, provide the access keys to connect with Procare. You can find the
              access keys once you login to BluePay.
            </div>
            <div className="form__row form__row--justified">
              <Form.Input
                defaultValue={accountId}
                label="Account ID"
                placeholder="12-digit Bluepay 2.0 Account ID"
                name="accountId"
                required
              />
              <Form.Input
                defaultValue={secretKey}
                label="Secret Key"
                placeholder="Secret Key"
                name="secretKey"
                required
              />
            </div>
            <div className="form__row form__row--actions">
              <Form.Submit label="Connect Account" />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  billingUrl: state.currentUser.data.current_school.billing_url
});

export default connect(mapStateToProps)(BluepayActivate);
