import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar, ButtonV2, Icon } from 'components';
import './style.scss';

class AddAsSibling extends Component {
  static propTypes = {
    data: PropTypes.shape({
      existingKid: PropTypes.object,
      newKid: PropTypes.object
    })
  };

  constructor(...args) {
    super(...args);
    this.state = {
      isLoading: false
    };
  }

  @bind
  linkStudents() {
    const { existingKid, newKid } = this.props.data;

    this.setState({ isLoading: true });
    req
      .linkStudents({ id: newKid.id, sibling_id: existingKid.id })
      .then(() => {
        this.setState({ isLoading: false });
        Actions.showFlash('Successfully linked!');
        this.props.onClose();
      })
      .catch(err => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { onClose } = this.props;
    const { existingKid, newKid } = this.props.data;
    const headerText = 'Do you want to link\nboth of them as siblings?';

    return (
      <div className="modal__wrapper add-as-sibling">
        <div className="modal__header">
          <div className="modal__header-title">{headerText}</div>

          <div className="modal__header-note">Since we found their last name matches</div>
        </div>

        <div className="modal__container">
          <div className="add-as-sibling__kids">
            <div className="add-as-sibling__kid">
              <div className="add-as-sibling__kid-inner">
                <Avatar size="small" initials={existingKid.initials} color={existingKid.color} />

                <div className="add-as-sibling__kid-name">{existingKid.name}</div>
              </div>

              <div className="add-as-sibling__kid-type">Existing student</div>
            </div>

            <Icon name="link" className="add-as-sibling__separator" />

            <div className="add-as-sibling__kid">
              <div className="add-as-sibling__kid-inner">
                <Avatar size="small" initials={newKid.initials} color={newKid.color} />

                <div className="add-as-sibling__kid-name">{newKid.name}</div>
              </div>

              <div className="add-as-sibling__kid-type">New student</div>
            </div>
          </div>
        </div>

        <div className="modal__controls">
          <ButtonV2 secondary label="Cancel" onClick={onClose} />
          <ButtonV2 label="Add as a sibling" onClick={this.linkStudents} isLoading={this.state.isLoading} />
        </div>
      </div>
    );
  }
}

export default AddAsSibling;
