import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Form } from 'components';
import './style.scss';

class AddURL extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      url: get(props, 'data.url', ''),
      name: get(props, 'data.name', '')
    };
  }

  @bind
  change(values) {
    this.setState({ ...values });
  }

  @bind
  submit() {
    this.props.onClose({ url: this.state.url, name: this.state.name });
  }

  render() {
    const { name, url } = this.state;

    return (
      <div className="add-url">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">{`${get(this.props, 'data.id') ? 'Edit' : 'Add'} URL`}</div>
        </div>

        <Form onChange={this.change} onSubmit={this.submit}>
          <div className="modal__container">
            <div className="form__row">
              <Form.Input label="Name" asterisk required name="name" defaultValue={name} placeholder="Link Name" />
            </div>
            <div className="form__row">
              <Form.Input
                label="Url"
                name="url"
                defaultValue={url}
                required
                asterisk
                isUrl
                placeholder="eg : https://www.mywebsite.com/resource/path"
              />
            </div>
          </div>

          <div className="modal__controls">
            <Form.Submit label="Submit" />
          </div>
        </Form>
      </div>
    );
  }
}

export default AddURL;
