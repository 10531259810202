import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import currency from 'lib/currency';
import { TextInput, ActionButton } from 'components';

class AttendanceItem extends Component {
  static propTypes = {
    data: PropTypes.object,
    removable: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.number,
    onUpdate: PropTypes.func,
    onRemove: PropTypes.func,
    tabIndex: PropTypes.number
  };

  static defaultProps = {
    data: {
      description: '',
      price: '',
      quantity: ''
    },
    disabled: false,
    removable: false,
    onUpdate: () => undefined,
    onRemove: () => undefined
  };

  @bind
  updateDescription(value) {
    const { id, data, onUpdate } = this.props;
    data.description = value;
    onUpdate(id, { ...data, description: value });
  }

  @bind
  updatePrice(value) {
    const { id, data, onUpdate } = this.props;

    if (!/^\d{0,7}(\.\d{0,2})?$/.test(value)) {
      return;
    }

    onUpdate(id, { ...data, price: value });
  }

  @bind
  updateQuantity(value) {
    const { id, data, onUpdate } = this.props;

    if (!/^\d{0,7}(\.\d{0,2})?$/.test(value)) {
      return;
    }

    onUpdate(id, { ...data, quantity: value });
  }

  @bind
  remove() {
    const { id, onRemove } = this.props;

    onRemove(id);
  }

  render() {
    const { data, removable, disabled, tabIndex } = this.props;

    const amountCN = classNames('invoice-item-list__amount', {
      'invoice-item-list__amount--empty': !Number(data.price)
    });

    return data._destroy ? null : (
      <div className="table__row">
        <div className="table__cell table__cell--s">
          <TextInput
            value={data.description}
            onChange={this.updateDescription}
            placeholder="Add invoice description"
            className="invoice-item-list__description"
            disabled={disabled}
            tabIndex={tabIndex}
          />
        </div>

        <div className="table__cell table__cell--xs invoice-item-list__price">
          <div className={amountCN}>
            <span className="invoice-item-list__amount-symbol">{currency.getSymbol()}</span>
            <TextInput
              value={data.price}
              onChange={this.updatePrice}
              placeholder="0"
              disabled={disabled}
              tabIndex={tabIndex}
            />
          </div>
        </div>

        <div className="table__cell table__cell--xs invoice-item-list__quantity">
          <TextInput
            value={data.quantity}
            onChange={this.updateQuantity}
            placeholder="0"
            disabled={disabled}
            tabIndex={tabIndex}
          />
        </div>

        <div className="table__cell table__cell--xs invoice-item-list__amount-text">
          {currency.getPrice(((data.price || 0) * (data.quantity || 0) * 100).toFixed(2) / 100)}
        </div>

        <div className="table__cell table__cell--xxs table__cell--actions">
          <ActionButton
            iconName="delete"
            size={14}
            className="invoice-item-list__delete"
            onClick={this.remove}
            disabled={!removable || disabled}
          />
        </div>
      </div>
    );
  }
}

export default AttendanceItem;
