import React from 'react';
import cx from 'classnames';
import './style.scss';

const TEXT = {
  active: 'active',
  hold: 'on hold',
  inactive: 'inactive',
  deactivated: 'deactivated',
  graduate: 'graduate'
};

const RegistrationStatus = ({ status, className }) => {
  const itemClassName = cx('reg-status', className, {
    [`reg-status--${status}`]: status
  });

  return (
    <div className={itemClassName}>
      <div className="reg-status__icon" />
      <div className="reg-status__text">{TEXT[status] || '-'}</div>
    </div>
  );
};

export default RegistrationStatus;
