import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingPlaceholder } from 'components';
import { getErrorText } from 'lib/utils';
import ManageController from 'modals/common/ManageItems/ManageController';
import ManageWindow from 'modals/common/ManageItems/ManageWindow';
import ManageList from 'modals/common/ManageItems/ManageList';
import LabelItem from './LabelItem';
import './style.scss';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class ManageLabels extends PureComponent {
  static propTypes = {
    labels: PropTypes.array,
    labelsLoading: PropTypes.bool
  };

  static defaultProps = {
    labels: []
  };

  @bind
  async addLabel(label) {
    const isNameEmpty = typeof label.name === 'string' && label.name.trim().length === 0;

    if (isNameEmpty || !label.color) {
      return Promise.reject();
    }

    try {
      await req.addLessonLabel({ label });
      Actions.showFlash('Label has been added');
      return Promise.resolve();
    } catch (e) {
      Actions.reportError('Unable to create a label:\n' + getErrorText(e.response.data), e);
      return Promise.reject();
    }
  }

  @bind
  async updateLabel(id, label) {
    const isNameEmpty = typeof label.name === 'string' && label.name.trim().length === 0;

    if (isNameEmpty || !label.color) {
      return Promise.reject();
    }

    try {
      await req.updateLessonLabel({ id, label });
      Actions.showFlash('Label has been updated');
      return Promise.resolve();
    } catch (e) {
      Actions.reportError('Unable to update the label:\n' + getErrorText(e.response.data), e);
      return Promise.reject();
    }
  }

  @bind
  async deleteLabel(id) {
    const shouldBeDeleted = await this.confirmDeletion();

    if (!shouldBeDeleted) {
      return;
    }

    try {
      await req.deleteLessonLabel({ id }, { id });
      Actions.showFlash('Label has been deleted');
      return Promise.resolve();
    } catch (e) {
      Actions.reportError('Unable to delete the label', e);
      return Promise.reject();
    }
  }

  async confirmDeletion() {
    return Actions.showModal('Confirmation', {
      title: 'Delete Label',
      description: 'Are you sure you want to delete this label?',
      noButton: 'No',
      yesButton: 'Yes, Delete',
      yesButtonProps: { danger: true, secondary: false },
      noButtonProps: { secondary: true }
    });
  }

  @bind
  goBack() {
    this.props.context.goToStep('labels');
  }

  render() {
    const { labels, labelsLoading } = this.props;

    return (
      <div className="manage-labels">
        <ManageController items={labels} onAdd={this.addLabel} onUpdate={this.updateLabel} onDelete={this.deleteLabel}>
          {props => (
            <ManageWindow
              className="manage-labels__window"
              title="Manage Labels"
              addIcon="create-tag"
              addLabel="Add new label"
              onAdd={props.onNew}
              onBack={this.goBack}
            >
              {labels.length === 0 && !props.isNew ? (
                <LoadingPlaceholder
                  icon="tag-1"
                  loading={labelsLoading}
                  title="No labels found"
                  text="Click on Add New Label to add labels"
                  className="manage-list__placeholder"
                />
              ) : (
                <ManageList {...props} ItemComponent={LabelItem} />
              )}
            </ManageWindow>
          )}
        </ManageController>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(ManageLabels);
