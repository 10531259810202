import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import './style.scss';

function TreeSelect({ children, onItemSelected, className }) {
  const [selectedLabel, setSelectedLabel] = useState('');
  const [expanded, setExpanded] = useState(false);

  const containerRef = React.createRef();

  const expandedCN = expanded ? 'expanded' : 'collapsed';

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  const handleBoundaryClick = event => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleBoundaryClick);
    return () => {
      document.removeEventListener('mousedown', handleBoundaryClick);
    };
  });

  return (
    <div ref={containerRef} className={`tree-select ${className}`}>
      <div className={`tree-select__header tree-select__header--${expandedCN}`} onClick={toggleExpansion}>
        <div className="tree-select__header__label">{selectedLabel}</div>
        <Icon className="tree-select__header__arrow" name={expanded ? 'chevron-up' : 'chevron-down'} size="14" />
      </div>
      <div className={`tree-select__children tree-select__children--${expandedCN}`}>
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            selectItem: (label, value) => {
              setSelectedLabel(label);
              toggleExpansion();
              onItemSelected && onItemSelected(value);
            }
          })
        )}
      </div>
    </div>
  );
}

TreeSelect.propTypes = {
  children: PropTypes.array,
  onItemSelected: PropTypes.func,
  className: PropTypes.string
};

export default TreeSelect;
