import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Preloader, ButtonV2 } from 'components';
import moment from 'moment';
import './style.scss';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class AddClassInfo extends Component {
  static propTypes = {
    schoolClass: PropTypes.object,
    onChange: PropTypes.func,
    loading: PropTypes.bool
  };

  static defaultProps = {
    classInfo: {},
    grades: [],
    loading: PropTypes.bool
  };

  render() {
    const { nextStep } = this.props.context;
    const { schoolClass, onChange, grades, edit, loading, submitLoading } = this.props;

    if (loading) {
      return (
        <div className="add-class-info__preloader">
          <Preloader large />
        </div>
      );
    }

    const signupStart = schoolClass.signup_start_at ? moment(schoolClass.signup_start_at).toDate() : undefined;
    const signupEnd = schoolClass.signup_end_at ? moment(schoolClass.signup_end_at).toDate() : undefined;

    return (
      <Form onChange={onChange} className="add-class-info">
        <div className="modal__header modal__header--bordered">
          <span className="modal__header-title add-class-info__title">{edit ? 'Edit' : 'Add'} Class</span>
          <span className="modal__header-steps">
            <span>Step 1</span> / 3
          </span>
        </div>

        <div className="modal__container add-class-info__container">
          <div className="add-class-info__form">
            <div className="form__row form__row--justified">
              <Form.Input
                required
                name="name"
                placeholder="Class Name"
                label="Class Name"
                disableInvalid
                defaultValue={schoolClass.name}
              />

              <div className="form__row form__row--half add-class-info__time">
                <div className="label label--small">Class Timings</div>

                <div className="form__row form__row--justified add-class-info__time-inner">
                  <Form.TimeInput name="start_time" defaultValue={schoolClass.start_time} />
                  <span className="form__row-gap">to</span>
                  <Form.TimeInput name="end_time" defaultValue={schoolClass.end_time} />
                </div>
              </div>
            </div>

            <div className="form__row form__row--justified">
              <Form.WeekdayPicker
                name="schedule_days"
                label="Class Days"
                className="add-class-info__days"
                defaultValue={schoolClass.schedule_days}
              />

              <Form.Select
                type="checkbox"
                name="grades"
                label="Grades"
                title="Select Grades"
                className="add-class-info__grades"
                defaultValue={schoolClass.grades}
              >
                {grades.map(g => (
                  <Form.Select.Item key={g} value={g} label={g} />
                ))}
              </Form.Select>
            </div>

            <div className="form__row form__row--justified">
              <div>
                <div className="label label--small">Sign up Begin & End Date</div>

                <div className="add-class-info__dates">
                  <Form.DatePicker name="signup_start_at" defaultValue={signupStart} />
                  <span className="form__row-gap">to</span>
                  <Form.DatePicker name="signup_end_at" defaultValue={signupEnd} />
                </div>
              </div>

              <Form.Input
                type="number"
                name="max_enrollments"
                label="Max Enroll"
                placeholder="Max Enroll"
                defaultValue={schoolClass.max_enrollments}
              />
            </div>

            <div className="form__row form__row--justified">
              <Form.Textarea
                name="description"
                label="More about class"
                placeholder="Write description here..."
                defaultValue={schoolClass.description}
              />
            </div>

            <div className="form__row">
              <div>
                <div className="label label--small">Allow Waitlist</div>
                <Form.Checkbox name="waitlist_enabled" label="Yes" defaultValue={schoolClass.waitlist_enabled} />
              </div>
            </div>
          </div>
        </div>

        <div className="modal__controls add-class-info__controls">
          <ButtonV2
            secondary
            label="Save & Close"
            onClick={this.props.onSubmit}
            disabled={!schoolClass.name}
            isLoading={submitLoading}
          />
          <ButtonV2
            label="Continue"
            onClick={nextStep}
            disabled={!schoolClass.name || submitLoading}
            isLoading={submitLoading}
          />
        </div>
      </Form>
    );
  }
}

export default withContext(ModalControllerStepsContext)(AddClassInfo);
