import React, { Component } from 'react';
import { ButtonV2 } from 'components';
import { connect } from 'react-redux';
import SendEmailForm from './SendEmailForm';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class EditMsgStep extends Component {
  render() {
    const { onChooseTemplate, isEdit, fromTemplate, fromLead } = this.props;
    const { currentStep, totalSteps } = this.props.context;

    return (
      <div className="modal__wrapper communication-send-msg__edit-msg">
        <div className="modal__header modal__header--bordered">
          <span className="modal__header-title communication-send-msg__title">
            {isEdit ? 'Edit' : 'New'} Email {fromTemplate ? 'Template' : 'Message'}
          </span>
          {!fromTemplate && !fromLead && (
            <span className="modal__header-steps">
              <span>Step {currentStep}</span> / {totalSteps}
            </span>
          )}

          {!fromTemplate && (
            <ButtonV2
              tertiary
              className="communication-send-msg__edit-msg-template-btn"
              label="Choose From Template"
              onClick={onChooseTemplate}
              data-cy="choose-template"
            />
          )}
        </div>

        <SendEmailForm {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  awsSignature: state.awsSignature.data,
  authToken: state.currentUser.data.auth_token
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(EditMsgStep);
