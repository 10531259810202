import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActionButton } from 'components';
import './style.scss';

class SelectedLabel extends Component {
  static propTypes = {
    label: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string
    }),

    onDelete: PropTypes.func
  };

  static defaultProps = {
    label: {}
  };

  @bind
  deleteTag() {
    if (this.props.onDelete) {
      this.props.onDelete(this.props.label.id);
    }
  }

  render() {
    const { label, onDelete } = this.props;

    if (!label.id || !label.name) {
      return null;
    }

    return (
      <div className="selected-label" style={{ backgroundColor: label.color }}>
        <div className="selected-label__name">{label.name}</div>

        {onDelete && (
          <ActionButton className="selected-label__close-btn" iconName="close" size={9} onClick={this.deleteTag} />
        )}
      </div>
    );
  }
}

export default SelectedLabel;
