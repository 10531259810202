import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import { ButtonV2, Avatar } from 'components';
import { fullName } from 'lib/utils';
import flattenDeep from 'lodash/flattenDeep';
import moment from 'moment';
import './style.scss';

class SisoKioskForm extends Component {
  static propTypes = {
    data: PropTypes.shape({
      student: PropTypes.object,
      teacher: PropTypes.object,
      signInData: PropTypes.shape({
        time: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }),
      kioskForms: PropTypes.array.isRequired,
      note: PropTypes.string
    })
  };

  renderForm(f, index) {
    return (
      <div className="siso-kiosk-forms__list-item" key={`question-${index}`}>
        <div className="siso-kiosk-forms__list-item-title">Question {index + 1}</div>
        <div className="siso-kiosk-forms__question">
          <b>Q:</b> {f.question}
        </div>
        <div className="siso-kiosk-forms__answers">
          {f.answers.map(a => (
            <div className="siso-kiosk-forms__answers-item">
              <b>A:</b> {a}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderTeacher(teacher) {
    return (
      <div className="siso-kiosk-forms__details-item">
        <div className="siso-kiosk-forms__details-item-label">STAFF</div>
        <div className="siso-kiosk-forms__details-item-person">
          <Avatar
            type="avatar"
            name={teacher.name}
            url={teacher.profile_pic_url}
            status={teacher.is_admin ? 'admin' : undefined}
          />
          <div>{teacher.name}</div>
        </div>
      </div>
    );
  }

  renderStudent(student) {
    return (
      <div className="siso-kiosk-forms__details-item">
        <div className="siso-kiosk-forms__details-item-label">STUDENT</div>
        <div className="siso-kiosk-forms__details-item-person">
          <Avatar initials={student.initials} color={student.color} url={student.profile_pic_url} />
          <div>{fullName(student)}</div>
        </div>
      </div>
    );
  }

  renderHeader() {
    const { signInData, student, teacher } = this.props.data;

    return (
      <div className="modal__header-subtitle siso-kiosk-forms__details">
        {student ? this.renderStudent(student) : this.renderTeacher(teacher)}
        {student && (
          <div className="siso-kiosk-forms__details-item">
            <div className="siso-kiosk-forms__details-item-label">SIGNED IN BY</div>
            <div className="siso-kiosk-forms__details-item-person">
              <div>{signInData.name}</div>
            </div>
          </div>
        )}
        <div className="siso-kiosk-forms__details-item">
          <div className="siso-kiosk-forms__details-item-label">{'DATE & TIME'}</div>
          <div className="siso-kiosk-forms__details-item-date">
            {moment(signInData.time).format('MM/DD/YYYY - hh:mm A')}
          </div>
        </div>
      </div>
    );
  }

  renderNotes(kioskForms) {
    const { note } = this.props.data;

    const notes = [note].concat(kioskForms.map(f => f.note)).filter(note => note);

    if (notes.length === 0) return null;

    return (
      <div className="siso-kiosk-forms__list-item" key="additional-notes">
        <div className="siso-kiosk-forms__list-item-title">Additional Notes</div>
        {notes.map(note => (
          <div key={note} className="siso-kiosk-forms__note">
            {note}
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { onClose, data } = this.props;

    const kioskForms = flattenDeep(data.kioskForms).filter(f => !!f); // flattenDeep - legacy fallback

    return (
      <div className="siso-kiosk-forms">
        <div className="modal__header">
          <div className="modal__header-title">Kiosk Questions</div>
          {this.renderHeader()}
        </div>

        <div className="modal__container">
          <Scrollbars autoHeight autoHeightMin={428}>
            <div className="siso-kiosk-forms__list">
              {kioskForms.map((f, index) => this.renderForm(f, index))}
              {this.renderNotes(kioskForms)}
            </div>
          </Scrollbars>
        </div>

        <div className="modal__controls">
          <ButtonV2 label="Close" onClick={onClose} />
        </div>
      </div>
    );
  }
}

export default SisoKioskForm;
