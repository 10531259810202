import basic from './basic';
import fetch from './fetch';
import patchItem from './patchItem';
import patchSisoItem from './patchSisoItem';
import removeItem from './removeItem';
import add from './add';
import addMultiple from './addMultiple';
import batchUpdate from './batchUpdate';
import batchDelete from './batchDelete';
import updateReportStatus from './updateReportStatus';
import addOrPatchItem from './addOrPatchItem';
import updateTenant from './updateTenant';

export const reducers = {
  createBasicReducer: basic,
  createFetchReducer: fetch,
  createPatchItemReducer: patchItem,
  createPatchSisoItemReducer: patchSisoItem,
  createAddReducer: add,
  createRemoveItemReducer: removeItem,
  createAddMultipleReducer: addMultiple,
  createBatchUpdateReducer: batchUpdate,
  createBatchDeleteReducer: batchDelete,
  createUpdateReportStatusReducer: updateReportStatus,
  createAddOrPatchItemReducer: addOrPatchItem,
  createUpdateTenantReducer: updateTenant
};

export default reducers;
