import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, TooltipTrigger } from 'components';
import { Tooltip } from 'components/StudentList';

const MAX_PER_ROW = 3;

function ActivityStudents({ students, firstId }) {
  const sortedStudents = students.sort((a, b) => {
    if (firstId && a.id === firstId) return -1;
    return a.name.localeCompare(b.name);
  });
  const avatarStudents = sortedStudents.slice(0, MAX_PER_ROW);
  const additionalStudents = sortedStudents.slice(MAX_PER_ROW);

  return (
    <div className="activity__students-container">
      <div className="activity__students">
        {avatarStudents.map(kid => (
          <Avatar
            key={kid.id}
            name={kid.name}
            url={kid.profile_pic_url}
            tooltipText={
              <div>
                <div>{kid.name}</div>
                {kid.registration_status !== 'active' && <div>({kid.registration_status})</div>}
              </div>
            }
            color={kid.color}
            initials={kid.initials}
          />
        ))}
        {additionalStudents.length > 0 && (
          <TooltipTrigger tooltip={<Tooltip students={additionalStudents} />} maxHeight={110}>
            <div className="plus-x">+{additionalStudents.length}</div>
          </TooltipTrigger>
        )}
      </div>
      {students.length === 1 && <div className="activity__students-name">{`${students[0].name}`}</div>}
      {students.length > 1 && <div className="activity__students-counter">{`${students.length} Students`}</div>}
    </div>
  );
}

ActivityStudents.propTypes = {
  students: PropTypes.array,
  firstId: PropTypes.string
};

export default ActivityStudents;
