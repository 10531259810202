import React from 'react';
import { Form } from 'components';

const DatePickerOptions = () => (
  <React.Fragment>
    <Form.DatePicker
      name="defaultValue"
      className="create-form-field__options-date"
      label="Default value"
      defaultValue={new Date()}
    />
  </React.Fragment>
);

export default DatePickerOptions;
