import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Filter as FilterComponent } from 'components';
import StageFilter from 'screens/teacher/LeadCrm/LeadList/StageFilter';
import PriorityFilter from 'screens/teacher/LeadCrm/LeadList/PriorityFilter';

class Filter extends PureComponent {
  static propTypes = {
    filters: PropTypes.shape({
      search: PropTypes.string,
      roomId: PropTypes.string,
      state: PropTypes.string,
      priority: PropTypes.string,
      age: PropTypes.object
    }),

    rooms: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func.isRequired
  };

  @bind
  updateSearch(search) {
    this.props.onChange({ search });
  }

  @bind
  updateStateId(stateId) {
    this.props.onChange({ stateId });
  }

  @bind
  updatePriority(priority) {
    this.props.onChange({ priority });
  }

  render() {
    const { filters, leadSources } = this.props;
    const sourceMap = leadSources.reduce(
      (prev, s) => ({
        ...prev,
        [s.id]: s.name
      }),
      {}
    );

    const filtersConfig = {
      roomId: { type: 'room' },
      age: { type: 'age-filter', label: 'Age', singleValue: true },
      source_id: {
        type: 'select',
        title: 'Source',
        label: 'Source',
        options: {
          '': 'All Sources',
          ...sourceMap
        }
      }
    };

    const defaultValues = {
      roomId: filters.roomId,
      age: filters.age,
      source_id: filters.source_id
    };

    return (
      <div className="form--inline">
        <FilterComponent filters={filtersConfig} defaultValues={defaultValues} onSubmit={this.props.onChange}>
          <TextInput placeholder="Search Student Name" value={filters.search} onChange={this.updateSearch} />
        </FilterComponent>

        <StageFilter onChange={this.updateStateId} value={filters.stateId} hideEnrolled />
        <PriorityFilter onChange={this.updatePriority} value={filters.priority} />
      </div>
    );
  }
}

export default Filter;
