import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'components';

function BooleanEditor({ onChange, defaultValue, ...props }) {
  const [value, setValue] = useState(defaultValue);
  const handleChange = newValue => {
    setValue(newValue);
    return onChange(newValue);
  };

  return <Checkbox type="slider" onChange={handleChange} checked={value} {...props} />;
}

BooleanEditor.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.bool
};

export default BooleanEditor;
