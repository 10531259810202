import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Form } from 'components';
import './style.scss';

class EditStudent extends Component {
  constructor() {
    super();

    this.state = { isLoading: false };
  }

  UNSAFE_componentWillMount() {
    req.tags();
  }

  @bind
  submit(formData) {
    const newFormData = { ...formData };
    this.setState({ isLoading: true });
    req
      .updateStudent({ id: this.props.data.id, kid: newFormData })
      .then(res => {
        this.setState({ isLoading: false });
        this.props.onClose();
        Actions.showFlash(`${res.name} profile saved`);
      })
      .catch(err => {
        this.setState({ isLoading: false });
        Actions.reportError('Somthing went wrong', err);
      });
  }

  @bind
  deleteStudent() {
    const { data, history } = this.props;
    const isDelete = data.registration_status === 'inactive';

    const confirmModalData = {
      title: isDelete ? 'Delete profile' : 'Make profile inactive',

      description: isDelete
        ? 'Deletion is PERMANENT and IRREVERSIBLE.\nIt will also cancel future billing plans if any. Are you sure?'
        : 'The profile will be removed from your active list. Are you sure?',

      noButton: 'Cancel',
      yesButton: isDelete ? 'Delete' : 'Yes',
      yesButtonProps: {
        danger: true,
        secondary: false
      }
    };
    this.props.onClose();

    Actions.showModal('Confirmation', confirmModalData).then(isDeleted => {
      if (isDeleted) {
        req
          .deleteStudent({ id: data.id }, { id: data.id })
          .then(() => {
            history.replace('/my-school/students');
            Actions.showFlash('Profile was ' + (data.registration_status === 'inactive' ? 'removed' : 'made inactive'));
          })
          .catch(err => {
            Actions.reportError('Something went wrong', err);
          });
      }
    });
  }

  render() {
    const { data, errors, rooms, tags, constants } = this.props;
    const { isLoading } = this.state;

    return (
      <div className="edit-student">
        <div className="modal__header">
          <span className="subheader">Edit Profile</span>
        </div>

        <div className="modal__container">
          <Form className="" onSubmit={this.submit} errors={errors} isLoading={isLoading}>
            <div className="form__row form__row--justified">
              <Form.Input
                name="first_name"
                defaultValue={data.first_name}
                placeholder="First Name"
                label="First name"
                required
              />
              <Form.Input name="last_name" defaultValue={data.last_name} placeholder="Last Name" label="Last name" />
            </div>

            <div className="form__row form__row--justified">
              {constants ? (
                <Form.Select
                  name="registration_status"
                  type="radio"
                  defaultValue={data.registration_status}
                  title="Status"
                  label="Status"
                >
                  {Object.keys(constants.kid_registration_statuses).map(s => (
                    <Form.Select.Item label={constants.kid_registration_statuses[s]} value={s} key={s} />
                  ))}
                </Form.Select>
              ) : (
                'loading statuses...'
              )}
              <Form.Select
                name="current_section_id"
                type="radio"
                defaultValue={data.current_section_id}
                required
                title="Room"
                label="Room"
              >
                {rooms.map(room => (
                  <Form.Select.Item label={room.name} value={room.id} key={room.id} />
                ))}
              </Form.Select>
            </div>

            <div className="form__row form__row--justified">
              <Form.Input
                name="student_id"
                defaultValue={data.student_id}
                placeholder="External SIS ID"
                label="External SIS ID"
              />
              <Form.DatePicker
                name="dob"
                defaultValue={data.dob ? moment(data.dob).toDate() : undefined}
                placeholder="Dob"
                label="Dob"
              />
            </div>

            <div className="form__row-divider" />

            <div className="form__row">
              {tags ? (
                <Form.Select
                  name="tag_ids"
                  type="checkbox"
                  defaultValue={data.tag_ids}
                  title="Tags"
                  label="Tags"
                  tapable
                >
                  {tags.map(t => (
                    <Form.Select.Item label={t.name} value={t.id} key={t.id} />
                  ))}
                </Form.Select>
              ) : (
                'loading tags...'
              )}
            </div>

            <div className="form__row">
              {constants ? (
                <Form.Select
                  name="schedule_days"
                  type="checkbox"
                  defaultValue={data.schedule_days}
                  title="Schedule"
                  label="Schedule"
                  tapable
                >
                  {Object.keys(constants.kid_schedules).map(d => (
                    <Form.Select.Item label={constants.kid_schedules[d]} value={d} key={d} />
                  ))}
                </Form.Select>
              ) : (
                'loading schedule days...'
              )}
            </div>

            <div className="form__row-divider" />

            <div className="form__row form__row--justified">
              <Form.Input
                name="allergy"
                defaultValue={data.allergy}
                placeholder="Leave 'blank' if no allergies"
                label="Allergy"
              />
              <Form.Input
                name="medications"
                defaultValue={data.medications}
                placeholder="Medications"
                label="Medications"
              />
            </div>

            <div className="form__row form__row--justified">
              <Form.Input
                name="street_address"
                defaultValue={data.street_address}
                placeholder="Address Line"
                label="Address Line"
              />
            </div>

            <div className="form__row form__row--justified">
              <Form.Input name="state" defaultValue={data.state} placeholder="State" label="State" />
              <Form.Input name="city" defaultValue={data.city} placeholder="City" label="City" />
            </div>

            <div className="form__row form__row--justified">
              {constants ? (
                <Form.Select name="country_code" defaultValue={data.country_code} title="Country" label="Country">
                  {Object.keys(constants.countries).map(c => (
                    <Form.Select.Item label={constants.countries[c]} value={c} key={c} />
                  ))}
                </Form.Select>
              ) : (
                'loading countries...'
              )}
              <Form.Input name="zip" defaultValue={data.zip} placeholder="Zipcode" label="Zipcode" />
            </div>

            <div className="form__row">
              <Form.Textarea name="note" defaultValue={data.note} placeholder="Note" label="Note" />
            </div>

            {data.registration_status === 'inactive' ? (
              <div className="form__row form__row--question">
                Do you want to{' '}
                <span className="form__row--question__action" onClick={this.deleteStudent}>
                  DELETE
                </span>{' '}
                the profile?
              </div>
            ) : (
              <div className="form__row form__row--question">
                Do you want to make the profile{' '}
                <span className="form__row--question__action" onClick={this.deleteStudent}>
                  INACTIVE
                </span>
                ?
              </div>
            )}

            <div className="form__row form__row--actions">
              <Form.Submit label="Save changes" />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  constants: state.constants.data,
  rooms: state.rooms.data,
  tags: state.tags.data
});

export default withRouter(connect(mapStateToProps)(EditStudent));
