import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';

const FilterItemTypeahead = ({ options = [], ...props }) => (
  <div className="form__row">
    <Form.Typeahead options={options} {...props} />
  </div>
);

FilterItemTypeahead.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array
};

export default FilterItemTypeahead;
