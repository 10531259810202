import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { InlineEditor } from 'components';

function PersonInfoRow({
  label,
  type,
  value,
  onChange,
  placeholder,
  hideAction,
  inputProps,
  renderValue: customRenderValue = t => t
}) {
  const Editor = InlineEditor[type];

  if (!Editor && type !== 'Static') {
    console.error(`Editor not found for type - "${type}"`);
    return null;
  }

  const valueCN = cx('info-list__item-value', {
    'info-list__item-value--muted': hideAction
  });

  const renderValue = () => {
    if (type === 'Boolean') return null;

    return value ? (
      <div className={valueCN}>{customRenderValue(value)}</div>
    ) : (
      <div className="info-list__item-value info-list__item-value--empty">{placeholder}</div>
    );
  };

  return (
    <div className="info-list__item person-info-card__row">
      <span>{label}</span>
      {renderValue()}
      <div className="person-info-card__row-content">
        {type !== 'Static' && !hideAction && <Editor onChange={onChange} defaultValue={value} {...inputProps} />}
      </div>
    </div>
  );
}

PersonInfoRow.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['Boolean', 'Input', 'Date', 'Static', 'Typeahead', 'Modal']),
  value: PropTypes.any,
  hideAction: PropTypes.bool,
  placeholder: PropTypes.node,
  onChange: PropTypes.func,
  renderValue: PropTypes.func,
  inputProps: PropTypes.object
};

export default PersonInfoRow;
