import moment from 'moment';

export default (data, activity) => {
  const date = moment(data.date || undefined).format('YYYY-MM-DD');

  return {
    id: activity ? activity.id : undefined,

    daily_activity: {
      activity_time: `${date} ${data.time}`,
      activiable: {
        note: data.note
      },
      data: {
        desc: ''
      },
      is_staff_only: data.staffOnly
    }
  };
};
