import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TextInput, Typeahead, ActionButton } from 'components';
import currency from 'lib/currency';

const EditMode = {
  Tuition: 'tuition',
  Preset: 'preset'
};

class RegularItem extends Component {
  static propTypes = {
    editMode: PropTypes.string,
    data: PropTypes.object,
    removable: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.number,
    onUpdate: PropTypes.func,
    onRemove: PropTypes.func,
    presets: PropTypes.arrayOf(PropTypes.object),
    onManagePresets: PropTypes.func,
    tabIndex: PropTypes.number
  };

  static defaultProps = {
    data: {
      description: '',
      price: ''
    },
    disabled: false,
    removable: false,
    presets: [],
    onUpdate: () => undefined,
    onRemove: () => undefined
  };

  state = {
    touched: false,
    localPrice: this.props.data.price || '0',
    localDescription: this.props.data.description || ''
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data.price !== this.props.data.price) {
      this.setState({ localPrice: this.props.data.price || '0' });
    }
    if (prevProps.data.description !== this.props.data.description) {
      this.setState({ localDescription: this.props.data.description || '' });
    }
  }

  get presets() {
    return this.props.presets.filter(p => p.kind === 'preset');
  }

  @bind
  updateDescription(value) {
    this.setState({ localDescription: value });

    const { id, data, onUpdate } = this.props;
    onUpdate(id, { ...data, description: value });
  }

  @bind
  updateDescriptionTypeahead(optionId) {
    const { id, data, onUpdate } = this.props;
    const selectedPreset = this.presets.find(p => p.id === optionId);
    const nextDescription = selectedPreset ? selectedPreset.title : '';
    const nextPrice = selectedPreset ? selectedPreset.rate : data.price;

    this.setState({
      touched: true,
      localPrice: nextPrice,
      localDescription: nextDescription
    });

    onUpdate(id, { ...data, description: nextDescription, price: nextPrice });
  }

  @bind
  updatePrice(value) {
    // Type negative sign on 0 value will replace with negative sign
    if (value === '0-') {
      value = '-';
    }

    // Allow negative numbers and decimal points
    if (!/^-?\d{0,7}(\.\d{0,2})?$/.test(value)) {
      return;
    }

    // If the value is just '-' or ends with '.', set it in state but don't trigger onUpdate
    if (value === '-' || value.endsWith('.')) {
      this.setState({ localPrice: value });
      return;
    }

    if (/^-?\d+\.(0|00)$/.test(value)) {
      this.setState({ localPrice: value });
      return;
    }

    // Remove leading zeros, but preserve '0', '-0', and numbers with decimal points
    let updatedPrice = value;
    if (updatedPrice !== '' && updatedPrice !== '-' && !updatedPrice.startsWith('-0') && !updatedPrice.includes('.')) {
      updatedPrice = updatedPrice.replace(/^0+/, '');
    }

    if (updatedPrice === '-0') {
      updatedPrice = '-';
    }

    if (updatedPrice === '') {
      updatedPrice = '0';
    }

    this.setState({ localPrice: updatedPrice });

    // Call onUpdate only with valid price
    const { id, data, onUpdate } = this.props;
    onUpdate(id, { ...data, price: updatedPrice });
  }

  @bind
  remove() {
    const { id, onRemove } = this.props;
    onRemove(id);
  }

  @bind
  getOptions() {
    return this.presets.map(p => ({ id: p.id, label: p.title }));
  }

  renderDescription(editMode) {
    return editMode === EditMode.Preset ? this.renderDescriptionTypeahead() : this.renderDescriptionInput();
  }

  renderDescriptionInput() {
    const { disabled, tabIndex } = this.props;
    const { localDescription } = this.state;

    return (
      <TextInput
        value={localDescription}
        onChange={this.updateDescription}
        placeholder="Add Invoice Description"
        className="invoice-item-list__description"
        disabled={disabled}
        tabIndex={tabIndex}
      />
    );
  }

  renderDescriptionTypeahead() {
    const { disabled, onManagePresets, tabIndex } = this.props;
    const { touched, localDescription } = this.state;

    const className = cx('invoice-item-list__preset-select', {
      'invoice-item-list__preset-select--invalid': touched && !localDescription
    });

    const action = (
      <ActionButton
        onClick={() => onManagePresets('preset')}
        iconName="gear"
        className="invoice-item-list__preset-manage-btn"
        title="Manage Presets"
        size={14}
      />
    );

    return (
      <Typeahead
        key={localDescription}
        type="radio"
        title="Select Preset Item"
        className={className}
        action={action}
        disabled={disabled}
        options={this.getOptions()}
        onChange={this.updateDescriptionTypeahead}
        placeholder="Add Invoice Description"
        defaultInputValue={localDescription}
        disableHighlight
        hideNoMatch
        tabIndex={tabIndex}
      />
    );
  }

  render() {
    const { editMode, removable, disabled, id, tabIndex } = this.props;
    const { localPrice } = this.state;

    const priceCN = cx('invoice-item-list__item-price', {
      'invoice-item-list__item-price--empty': !Number(localPrice)
    });

    return this.props.data._destroy ? null : (
      <React.Fragment>
        <div className="table__cell table__cell--l invoice-item-list__item-description">
          {this.renderDescription(editMode)}
        </div>

        <div className="table__cell table__cell--xs invoice-item-list__amount-text">
          <div className={priceCN}>
            <span className="invoice-item-list__item-price-sym">{currency.getSymbol()}</span>
            <TextInput
              value={localPrice}
              onChange={this.updatePrice}
              placeholder="0"
              disabled={disabled}
              tabIndex={tabIndex}
              type="text"
            />
          </div>
        </div>

        <div className="table__cell table__cell--xxs table__cell--actions invoice-item-list__item-actions">
          <ActionButton
            iconName="delete"
            size={14}
            className="invoice-item-list__item-delete-btn"
            onClick={this.remove}
            disabled={!removable || disabled || id === 0}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default RegularItem;
