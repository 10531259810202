/**
 * @typedef {Object} Signature Represents an attached signature of a Signable Activity
 * @property {String} id Unique identifier for this signature.
 * @property {String} name Name of the signer that created the signature.
 * @property {Boolean} attached Whether or not this signature is to be used on the activity
 *  for reporting. A signature could have been made available, but not attached signifying
 *  the signature doesn't need to be displayed on a report.
 * @property {Boolean} admin Whether or not this signature was created by an admin.
 * @property {String} signatory_type Type of signature that can either be a Teacher or a Carer.
 * @property {String} requested_at Date the signature was requested.
 * @property {String} signed_at Date the signature was signed. An unsigned signature represent
 *  a request for a signature.
 * @property {String} url Url to the signature data for display and reporting.
 */

/**
 * Builds a signature context based off of the specified activity
 * @typedef {Object} SignatureContext Return value
 * @property {[Signature]} signatures Signatures attached to the activiable.
 * @property {Signature} adminSignature The first available signature belonging to an admin.
 * @property {Signature} staffSignature The first available signature belonging to a teacher.
 * @property {Signature} unsignedCarerSignature The first available signature belonging to
 *  a carer that has not been signed.
 * @property {Signature} signedCarrerSignature The first available signature belonging to
 *  a carer that has been signed.
 * @property {Boolean} hasRequestForCarerSignature Determines if there are any signatures being
 *  requested from a carer that have not been signed.
 * @property {Boolean} hasCarerSignedTheReport Determines if there are any signatures being
 *  requested from a carer that have been signed.
 * @property {func} hasCarerSignatures Determines if there are any carer signatures that have
 *  been signed.
 * @property {func} canCreateReport Determines if the current user is able to
 *  create a report from the attached signatures.
 * @property {func} canDownloadreport Determines if the current user is able to
 *  download a created report.
 */

/** Creates a new SignatureContext scoped to the specified activity.
 * @param {Object} activity - A single, signable activity.
 * @returns {SignatureContext} Signature context scoped to the activity containing helpful methods.
 * @summary The specified activity is expected to be of a signable activity type that has an activiable
 *  attached, otherwise the returned value will be an empty object.
 */
export const buildSignatureContext = activity => {
  /** Retrieves all signatures from the specified activiable.
   * @param {Object} activiable The activiable containing signatures
   * @returns {[Signature]}
   * @summary Staff signatures are represented as an array, while
   *  parent signatures will always be a single object. This method will add all of them to a single array.
   */
  const getSignaturesFrom = activiable => {
    let signatures = [];
    if (!activiable) {
      return signatures;
    }

    if (activiable.signatures) {
      signatures = [...activiable.signatures];
    }

    if (activiable.signature) {
      signatures.push(activiable.signature);
    }

    return signatures;
  };

  const signatures = getSignaturesFrom(activity?.activiable);
  const carerSignatures = signatures.filter(sig => sig.signatory_type === 'Carer');
  const unsignedCarerSignature = carerSignatures.find(sig => !sig.signed_at);
  const signedCarerSignature = carerSignatures.find(sig => sig.signed_at);

  return {
    signatures,
    adminSignature: signatures.find(sig => sig.signatory_type === 'Teacher' && sig.admin),
    staffSignature: signatures.find(sig => sig.signatory_type === 'Teacher' && !sig.admin),
    unsignedCarerSignature,
    signedCarerSignature,
    hasCarerSignatures: carerSignatures.length > 0,
    hasRequestForCarerSignature: !!unsignedCarerSignature,
    hasCarerSignedTheReport: !!signedCarerSignature,
    canCreateReport: isAdmin => isAdmin && !carerSignatures.length,
    canDownloadReport: role => (role === 'carer' ? !!signedCarerSignature : true)
  };
};
