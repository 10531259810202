const initialState = {
  currentSchoolId: undefined,
  flags: []
};

const launchDarkly = (state = { ...initialState }, action) => {

  switch (action.type) {
    case 'LAUNCHDARKLY_SET':
      return { currentSchoolId: action.payload, flags: action.flags };

    case 'LAUNCHDARKLY_RESET':
    case 'REDUX_STORE_RESET':
      return { ...initialState };

    default:
      return state;
  }
};

export default launchDarkly;
