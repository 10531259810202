import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCurrentSchool } from 'lib/utils/selectors';
import Steps from 'modals/ModalController/Steps';
import Step1 from './Step1';
import Step2CC from './Step2CC';
import Step2Ach from './Step2Ach';
import AutopayAgreement from '../../AutopayAgreement';
import './style.scss';

export class AddPaymentMethod extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    onConnectUri: PropTypes.func
  };

  static defaultProps = {
    onConnectUri: () => req.parentTeAccountUri()
  };

  @bind
  bindSteps(node) {
    this._steps = node;
  }

  constructor(props) {
    super(props);
    this.state = {
      method: props.startMethod || '',
      subKind: ''
    };
  }

  @bind
  onSelectMethod(method, subKind = '') {
    this.setState({ method, subKind });

    if (this.props.autopayEnabled) {
      this._steps.goToStep('agreement');
    } else {
      this._steps.nextStep();
    }
  }

  @bind
  goToList() {
    const { match, history } = this.props;
    const splits = match.url.split('/');

    splits.pop();
    history.push(splits.join('/'));
  }

  render() {
    const { method, subKind } = this.state;
    const { onSubmit, startMethod, onConnectUri, autopayEnabled } = this.props;

    return (
      <Steps ref={this.bindSteps} startStep={startMethod === 'card' ? 1 : 0}>
        <Steps.Item>
          <Step1
            method={method}
            onSelectMethod={this.onSelectMethod}
            goToList={this.goToList}
            isRegister={!!onSubmit}
          />
        </Steps.Item>
        <Steps.Item>
          {method === 'card' ? (
            <Step2CC
              method={method}
              subKind={subKind}
              onSubmit={onSubmit}
              goToList={this.goToList}
              onConnectUri={onConnectUri}
              autopayEnabled={autopayEnabled}
            />
          ) : (
            <Step2Ach
              method={method}
              onSubmit={onSubmit}
              goToList={this.goToList}
              onConnectUri={onConnectUri}
              autopayEnabled={autopayEnabled}
            />
          )}
        </Steps.Item>
        <Steps.Item name="agreement">
          <AutopayAgreement prevStep={() => this._steps.goToStep(0)} nextStep={() => this._steps.goToStep(1)} />
        </Steps.Item>
      </Steps>
    );
  }
}

const mapStateToProps = state => ({
  autopayEnabled: getCurrentSchool(state).auto_pay_enabled,
  billingAccountId: state.currentUser.data.current_school.billing_account_id
});

export default withRouter(connect(mapStateToProps)(AddPaymentMethod));
