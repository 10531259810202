import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { InlineEditor } from 'components';

function ListEditor({ onChange, extra, defaultValue, ...props }) {
  const handleChange = v => {
    return onChange([v]);
  };

  const options = get(extra, 'options', []);

  return (
    <InlineEditor.Dropdown
      type="radio"
      onChange={handleChange}
      placeholder="Select Option"
      options={options}
      defaultValue={defaultValue ? defaultValue[0] : undefined}
      {...props}
    />
  );
}

ListEditor.propTypes = {
  defaultValue: PropTypes.array,
  onChange: PropTypes.func,
  extra: PropTypes.object
};

export default ListEditor;
