import React, { Component } from 'react';
import { Form } from 'components';

class TextInput extends Component {
  render() {
    const { id, label, type, className } = this.props;

    return <Form.Input name={id} type={type} label={label} className={className} />;
  }
}

export default TextInput;
