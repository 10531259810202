import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { LoadingPlaceholder } from 'components';
import { capitalize } from 'lib/utils';
import ManageController from 'modals/common/ManageItems/ManageController';
import ManageWindow from 'modals/common/ManageItems/ManageWindow';
import ManageTable from 'modals/common/ManageItems/ManageTable';
import { withPermissionsChecking } from 'hocs/withPermissions';
import PresetItem from './PresetItem';
import './style.scss';

class ManagePresets extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      kind: PropTypes.oneOf(['preset', 'discount', 'subsidy']).isRequired
    }).isRequired,
    presets: PropTypes.array,
    presetsLoading: PropTypes.bool,
    onBack: PropTypes.func
  };

  static defaultProps = {
    data: { kind: 'preset' },
    presets: [],
    presetsLoading: false
  };

  /**
   * Get a proper pluralized kind name for a certain item kind
   *
   * @param {"preset" | "discount" | "subsidy"} kind - an item's kind
   */
  static getPluralKind(kind) {
    return capitalize(kind) + 's';
  }

  /**
   * Get text value for a certain item kind
   *
   * @param {"preset" | "discount" | "subsidy"} kind - an item's kind
   */
  static getTextByKind(kind) {
    if (kind === 'subsidy') return 'subsidy discount';

    return kind;
  }

  /**
   * Get messages for a certain item kind
   *
   * @param {"preset" | "discount" | "subsidy"} kind - an item's kind
   */
  static getKindMessages(kind) {
    const kindText = this.getTextByKind(kind);
    const kindCapitalized = capitalize(kindText);

    return {
      title: `Manage ${this.getPluralKind(kindText)}`,
      placeholderText: `No ${this.getPluralKind(kindText)} have been added`,
      addLabel: `Add ${kindText}`,
      createSuccess: `${kindCapitalized} has been created`,
      createError: `Unable to create ${kindText}`,
      updateSuccess: `${kindCapitalized} has been updated`,
      updateError: `Unable to update ${kindText}`,
      deleteSuccess: `${kindCapitalized} has been deleted`,
      deleteError: `Unable to delete ${kindText}`
    };
  }

  constructor(props) {
    super(props);

    this.typeMessages = ManagePresets.getKindMessages(props.data.kind);
  }

  /**
   * Make payload for a certain item kind
   *
   * @param {"preset" | "discount" | "subsidy"} kind - an item's kind
   */
  makePayload(kind, values) {
    if (kind === 'subsidy' || kind === 'discount') {
      return { kind, title: values.title, rate: 0, quantity: 1 };
    }

    return { kind: 'preset', title: values.title, rate: Number(values.rate), quantity: 1 };
  }

  @bind
  async addPreset(preset) {
    const { data } = this.props;

    const noTitle = typeof preset.title !== 'string' || preset.title.trim().length === 0;
    const noRate = preset.rate !== undefined && Number(preset.rate) === 0;

    if (noTitle || noRate) {
      return Promise.reject();
    }

    try {
      await req.createInvoiceItemPreset({ charge: this.makePayload(data.kind, preset) });
      Actions.showFlash(this.typeMessages.createSuccess);
      return Promise.resolve();
    } catch (e) {
      Actions.reportError(this.typeMessages.createError, e);
      return Promise.reject(e);
    }
  }

  @bind
  async updatePreset(id, preset) {
    const { data } = this.props;
    const noTitle = typeof preset.title !== 'string' || preset.title.trim().length === 0;

    if (noTitle) {
      return Promise.reject();
    }

    try {
      await req.updateInvoiceItemPreset({ id, charge: this.makePayload(data.kind, preset) });
      Actions.showFlash(this.typeMessages.updateSuccess);
      return Promise.resolve();
    } catch (e) {
      Actions.reportError(this.typeMessages.updateError, e);
      return Promise.reject(e);
    }
  }

  @bind
  async deletePreset(id) {
    try {
      await req.deleteInvoiceItemPreset({ id }, { id });
      Actions.showFlash(this.typeMessages.deleteSuccess);
      return Promise.resolve();
    } catch (e) {
      Actions.reportError(this.typeMessages.deleteError, e);
      return Promise.reject();
    }
  }

  @bind
  renderHeader() {
    const { data } = this.props;

    return (
      <React.Fragment>
        <div className="table__cell table__cell--xl">Description</div>
        {data.kind === 'preset' && <div className="table__cell table__cell--s">Rate</div>}
      </React.Fragment>
    );
  }

  render() {
    const { presets, presetsLoading, franchiseBilling, data, onBack, hasWritePermissions } = this.props;
    const items = presets.filter(p => p.kind === data.kind);

    return (
      <div className="manage-presets">
        <ManageController
          items={items}
          onAdd={this.addPreset}
          onUpdate={this.updatePreset}
          onDelete={this.deletePreset}
        >
          {props => (
            <ManageWindow
              className="manage-presets__window"
              title={this.typeMessages.title}
              addIcon="add"
              addLabel={this.typeMessages.addLabel}
              addDisabled={props.isEdit}
              onAdd={
                !franchiseBilling && (hasWritePermissions('subsidies') || data.kind !== 'subsidy')
                  ? props.onNew
                  : undefined
              }
              onBack={onBack}
            >
              {items.length === 0 && !props.isNew ? (
                <LoadingPlaceholder text={this.typeMessages.placeholderText} loading={presetsLoading} />
              ) : (
                <ManageTable
                  {...props}
                  renderCustom
                  ItemComponent={PresetItem}
                  renderHeader={this.renderHeader}
                  itemProps={{ kind: data.kind }}
                />
              )}
            </ManageWindow>
          )}
        </ManageController>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  presets: state.invoiceItemPresets.data.charges,
  presetsLoading: state.invoiceItemPresets.loading,
  franchiseBilling: state.currentUser.data.current_school.franchise_billing_enabled
});

const enhance = compose(withPermissionsChecking, connect(mapStateToProps));

export default enhance(ManagePresets);
