import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, ActionButton } from 'components';

class Item extends Component {
  static propTypes = {
    item: PropTypes.object,
    onAdd: PropTypes.func,
    onChange: PropTypes.func,
    onRemove: PropTypes.func
  };
  static defaultProps = {
    item: {}
  };

  @bind
  handleAdd() {
    if (this.props.onAdd) {
      this.props.onAdd();
    }
  }

  @bind
  handleChangeValue(value) {
    const { item, onChange } = this.props;

    if (onChange) {
      onChange(item.id, 'value', value);
    }
  }

  @bind
  handleChangeTitle(value) {
    const { item, onChange } = this.props;

    if (onChange) {
      onChange(item.id, 'title', value);
    }
  }

  @bind
  handleRemove() {
    if (this.props.onRemove) {
      this.props.onRemove(this.props.item.id);
    }
  }

  render() {
    const { item, isLast } = this.props;

    return (
      <div className="form-field__item-list__item">
        <TextInput placeholder="Value: ex. US" value={item.value} onChange={this.handleChangeValue} />
        <TextInput placeholder="Label: ex. United States" value={item.title} onChange={this.handleChangeTitle} />
        {isLast ? (
          <ActionButton className="form-field__item-list__add-btn" iconName="add-blue" onClick={this.handleAdd} />
        ) : (
          <ActionButton
            key={isLast}
            iconName="close-circle"
            size={16}
            className="form-field__item-list__remove-btn"
            onClick={this.handleRemove}
          />
        )}
      </div>
    );
  }
}

export default Item;
