/* eslint-disable quotes */
import React, { Component } from 'react';
import cx from 'classnames';
import { ButtonV2 } from 'components';
import './style.scss';

class Alert extends Component {
  render() {
    const { data, options, onClose } = this.props;

    const modalCN = cx('modal__wrapper', 'modal__alert', {
      [options.className]: Boolean(data.className)
    });

    return (
      <div className={modalCN}>
        <div className="modal__header">
          <div className="subheader">{data.title || 'Alert'}</div>
          <span className="modal__header-note">{data.message || "There's no action for now."}</span>
        </div>

        <div className="modal__controls">
          <ButtonV2 onClick={onClose} label={data.label || 'Ok'} />
        </div>
      </div>
    );
  }
}

export default Alert;
