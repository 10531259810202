import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActionButton, Preloader, ButtonV2 } from 'components';
import SelectPersonList from 'modals/common/SelectPersonList';
import StaffFilter from './StaffFilter';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class SelectStaffStep extends Component {
  static propTypes = {
    staff: PropTypes.array,
    sendMessage: PropTypes.func,
    sendSMS: PropTypes.bool,
    onSelect: PropTypes.func,
    isSaving: PropTypes.bool,
    messageType: PropTypes.string,
    selectedStaff: PropTypes.array,
    filters: PropTypes.shape({
      search: PropTypes.string,
      sectionIds: PropTypes.array,
      status: PropTypes.string,
      hideNoEmail: PropTypes.bool
    }),
    onUpdateFilters: PropTypes.func
  };

  static defaultProps = {};

  componentWillUnmount() {
    this.props.onSelect([]);
  }

  @bind
  handleBack() {
    if (this.props.isStaff) {
      this.props.context.goToStep(1);
    } else {
      this.props.context.prevStep();
    }
  }

  render() {
    const {
      staff,
      sendMessage,
      onSelect,
      isSaving,
      isLoading,
      selectedStaff,
      isStaff,
      sendSMS,
      rooms,
      onUpdateFilters,
      filters
    } = this.props;

    return (
      <div className="modal__wrapper select-staff-step">
        <div className="modal__header">
          <div>
            <ActionButton iconName="back" size={20} onClick={this.handleBack} />
            <span className="modal__header-title">Select Staff</span>
            {isStaff ? (
              <span className="modal__header-steps">
                <span>Step 2</span> / 2
              </span>
            ) : (
              <span className="modal__header-steps">(optional)</span>
            )}
          </div>
          <StaffFilter rooms={rooms} onChange={onUpdateFilters} filters={filters} />
        </div>

        <div className="modal__container">
          {isLoading ? (
            <div className="select-staff-step__loading">
              <Preloader large />
            </div>
          ) : (
            <SelectPersonList
              persons={staff}
              allPersons={staff}
              selectedIds={selectedStaff}
              onSelect={onSelect}
              listHeight={438}
              type="teacher"
              validations={
                sendSMS
                  ? {
                      'No Mobile': s => !s.phone
                    }
                  : {
                      'No Email': s => !s.email
                    }
              }
              disableInvalid={false}
            />
          )}
        </div>

        <div className="modal__controls">
          <ButtonV2
            label="Send Message"
            disabled={selectedStaff.length === 0}
            onClick={sendMessage}
            isLoading={isSaving}
            data-cy="send"
          />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(SelectStaffStep);
