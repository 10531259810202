import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from 'components';

function NewsletterType({ onClick, checked, icon, title, children, 'data-cy': dataCY }) {
  const className = cx('document-create-type-select__item', {
    'document-create-type-select__item--selected': checked
  });

  return (
    <div className={className} onClick={onClick} data-cy={dataCY}>
      <div className="document-create-type-select__item-title">{title}</div>
      {/* <Icon name={icon} size={64} color="#30bdef" /> */}
      <Icon name={icon} size={64} color="currentColor" />
      <div className="document-create-type-select__item-description">{children}</div>
    </div>
  );
}

NewsletterType.propTypes = {
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  icon: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node
};

export default NewsletterType;
