import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'components';

function TreeSelectItem({ label, value, children, level = 0, selectItem }) {
  const [expanded, setExpanded] = useState(false);
  const expandedCN = expanded ? 'expanded' : 'collapsed';

  const hasChildren = () => !!children;

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const onSelected = () => {
    selectItem && selectItem(label, value);
  };

  return (
    <div key={label} className="tree-select__child-container">
      <div
        className={`tree-select__child__item tree-select__child__item--level${level}`}
        onClick={hasChildren() ? toggleExpanded : onSelected}
      >
        {hasChildren() && <Icon name={expanded ? 'triangle_selected' : 'triangle_unselected'} size="12" />}
        {label}
      </div>
      {!!children && (
        <div className={`tree-select__child--${expandedCN}`}>
          {React.Children.map(children, child =>
            React.cloneElement(child, {
              level: level + 1,
              selectItem
            })
          )}
        </div>
      )}
    </div>
  );
}

TreeSelectItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  children: PropTypes.array,
  level: PropTypes.number,
  selectItem: PropTypes.func
};

export default TreeSelectItem;
