import React from 'react';
import cx from 'classnames';
import noop from 'lodash/noop';
import { Timestamp, Icon } from 'components';
import ActivityIcon from 'components/ActivityList/Activity/ActivityIcon';
import ActivityBody from 'components/ActivityList/Activity/ActivityBody';
import ACTIVITY_TEXTS from 'components/ActivityList/Activity/activityTexts';

import './style.scss';

const Activity = ({ selected, onSelect, readOnly, onPhotoView, activity }) => {
  const activityWithoutPhoto =
    activity.activity_type === 'video_activity' || activity.activity_type === 'photo_activity'
      ? {
          ...activity,
          activiable: {
            ...activity.activiable,
            main_url: ''
          }
        }
      : {
          ...activity,
          photo_url: null
        };
  const selectCN = cx('activity-observation__select', {
    'activity-observation__select--selected': selected,
    'activity-observation__select--disabled': readOnly
  });
  const photoUrl =
    activity.activity_type === 'video_activity' || activity.activity_type === 'photo_activity'
      ? activity.activiable.main_url
      : activity.photo_url;
  const Body = ActivityBody[activity.activity_type];

  return (
    <div className="activity-observation">
      <div className="activity-observation__body">
        <div className="activity-observation__body-block activity-observation__body-block--type">
          <div className="activity-observation__title">Activity</div>
          <ActivityIcon type={activity.activity_type} />
          <div className="activity-observation__name">{ACTIVITY_TEXTS[activity.activity_type]}</div>
        </div>

        <div className="activity-observation__body-block activity-observation__body-block--date">
          <div className="activity-observation__title">Date</div>
          <div className="activity-observation__date">
            <Timestamp timestamp={activity.activity_time} showDate={true} alwaysShowYear={true} />
          </div>
        </div>

        <div className="activity-observation__body-block activity-observation__body-block--description">
          <div className="activity-observation__title">Description</div>
          <div className="activity-observation__description">
            <Body activity={activityWithoutPhoto} />
          </div>
        </div>

        {photoUrl ? (
          <div
            className="activity-observation__body-block activity-observation__body-block--photo"
            onClick={() => onPhotoView(photoUrl)}
          >
            <img className="activity-observation__img" src={photoUrl} />
            <div className="activity-observation__img-view">
              <Icon name="view" />
            </div>
          </div>
        ) : (
          <div className="activity-observation__body-block activity-observation__body-block--photo" />
        )}
      </div>

      <div className={selectCN} onClick={readOnly ? noop : onSelect}>
        <div className="activity-observation__select-check">
          <Icon name="check" size={selected ? 25 : 15} />
        </div>
        <span>Select</span>
      </div>
    </div>
  );
};

export default Activity;
