import React from 'react';
import { Form } from 'components';

const TextInputOptions = ({ options = {} }) => (
  <React.Fragment>
    <Form.Input
      data-cy="placeholder"
      name="placeholder"
      label="Placeholder Text"
      placeholder="Placeholder text"
      defaultValue={options.placeholder}
    />
    {/*<Form.Input name="defaultValue" label="Default value" placeholder="Enter default value" />*/}
    <Form.Checkbox data-cy="is-email" name="email" label="Should be an Email" defaultValue={options.email} />
    <Form.Checkbox
      data-cy="multiline"
      name="multiline"
      label="Multiple lines required for the answer"
      defaultValue={options.multiline}
    />
  </React.Fragment>
);

export default TextInputOptions;
