import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form } from 'components';

class ManageListItemDefault extends PureComponent {
  static propTypes = {
    item: PropTypes.object,
    className: PropTypes.string,
    valueField: PropTypes.string,
    placeholder: PropTypes.string,
    isEdit: PropTypes.bool,
    onChange: PropTypes.func
  };

  static defaultProps = {
    item: {},
    valueField: 'name'
  };

  renderEdit() {
    const { item, valueField, placeholder, onChange } = this.props;

    return (
      <Form onChange={onChange}>
        <Form.Input name={valueField} placeholder={placeholder} defaultValue={item.name} />
      </Form>
    );
  }

  renderShow() {
    return this.props.item[this.props.valueField];
  }

  render() {
    return (
      <div className={classNames('manage-list-default-item', this.props.className)}>
        {this.props.isEdit ? this.renderEdit() : this.renderShow()}
      </div>
    );
  }
}

export default ManageListItemDefault;
