import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Checkbox, CounterInput } from 'components';

class MessageStep extends Component {
  @bind
  onChange(field) {
    return val => {
      this.props.onUpdateForm({ [field]: val });
    };
  }

  @bind
  onUpdateSendSMS() {
    this.props.onUpdateForm({
      sendSMS: !this.props.sendSMS
    });
  }

  renderSMSLabel() {
    const { maxMonthlySms } = this.props;

    return (
      <div className="new-message__form__sms__label">
        <span>
          Send as Emergency SMS Alert (300 characters)
          <br />
          <small>
            Maximum <b>{maxMonthlySms}</b> SMS/month for your plan, contact us for upgrades
          </small>
        </span>
      </div>
    );
  }

  render() {
    const { sendSMS, message, isMessageValid } = this.props;

    return (
      <div className="modal__container new-message__form">
        <div className="form__row">
          <div className={cx('form-input', { 'form-input--invalid': !isMessageValid })}>
            <div className="form-input__field">
              <CounterInput
                multiline
                value={message}
                onChange={this.onChange('message')}
                className="new-message__form__textarea"
                placeholder="Write a message"
                showCounter={sendSMS}
                counter={message.length}
                limit={300}
              />
            </div>
            {!isMessageValid && <div className="form-input__validation-text">Cannot be empty</div>}
          </div>
        </div>
        <div className="form__row new-message__form__sms">
          <Checkbox onChange={this.onUpdateSendSMS} checked={sendSMS} label={this.renderSMSLabel()} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  maxMonthlySms: state.currentUser.data.current_school.max_monthly_sms
});

const enhance = compose(connect(mapStateToProps));
export default enhance(MessageStep);
