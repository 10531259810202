import GenericEditor from './GenericEditor';
import InputEditor from './InputEditor';
import BooleanEditor from './BooleanEditor';
import DropdownEditor from './DropdownEditor';
import DateEditor from './DateEditor';
import NameFieldEditor from './NameFieldEditor';
import ScheduleEditor from './ScheduleEditor';
import DateRangeEditor from './DateRangeEditor';
import EmptyEditor from './EmptyEditor';
import TypeaheadEditor from './TypeaheadEditor';
import StudentVaccineDosesEditor from './StudentVaccineDosesEditor';
import ModalEditor from './ModalEditor';

export default {
  Generic: GenericEditor,
  Input: InputEditor,
  Dropdown: DropdownEditor,
  Date: DateEditor,
  Name: NameFieldEditor,
  Schedule: ScheduleEditor,
  DateRange: DateRangeEditor,
  Empty: EmptyEditor,
  Boolean: BooleanEditor,
  Typeahead: TypeaheadEditor,
  StudentVaccineDoses: StudentVaccineDosesEditor,
  Modal: ModalEditor
};
