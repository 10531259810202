import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { Form, TooltipTrigger, ButtonV2 } from 'components';
import FilterItems from './Items';
import AppliedItem from './AppliedItem';
import './style.scss';
import { RegistrationStatusServices } from './Items/RegistrationStatus';

class Filter extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    className: PropTypes.string,
    filters: PropTypes.object,
    children: PropTypes.node,
    defaultValues: PropTypes.object,
    defaultDateFilter: PropTypes.object,
    displayApplied: PropTypes.bool,
    displayDefaultDate: PropTypes.bool,
    layout: PropTypes.object,
    customFieldConfig: PropTypes.object,
    disabled: PropTypes.bool,

    // Where to display the tooltip arrow
    // Default is 'center'
    side: PropTypes.oneOf(['left', 'right', 'center']),

    // Do not display arrow
    // Default is 'false'
    noArrow: PropTypes.bool
  };

  static defaultProps = {
    onSubmit: () => undefined,
    isLoading: false,
    children: null,
    defaultValues: {},
    defaultDateFilter: null,
    displayDefaultDate: true,
    displayApplied: true,
    disabled: false,
    side: 'center',
    noArrow: false,
    layout: {},
    customFieldConfig: {}
  };

  static isHidden({ type } = {}) {
    return type === 'hidden-select';
  }

  constructor(props) {
    super(props);
    this.state = {
      formKey: 0,
      formPaymentsKey: 0,
      values: {
        ...props.defaultValues
      },
      tempValues: {
        ...props.defaultValues
      },
      extra: {},
      disabledFields: [],
      disabledOptions: []
    };
  }

  componentDidMount() {
    this.setState({
      values: {
        ...this.props.defaultValues
      }
    });
  }

  @bind
  onChange(name, value) {
    const { customFieldConfig } = this.props;

    if (customFieldConfig) {
      this.setState(prevState => {
        const updatedTempValues = {
          ...prevState.tempValues,
          [name]: value
        };

        let updatedState = { tempValues: updatedTempValues };
        const field = customFieldConfig[name];

        if (field && field.update) {
          updatedState = {
            ...updatedState,
            ...field.update(value, updatedTempValues)
          };
        }

        return updatedState;
      });
    } else {
      this.setState(prevState => ({
        tempValues: {
          ...prevState.tempValues,
          [name]: value
        }
      }));
    }
  }

  @bind
  onSubmit(values, closeTooltip, forcedUpdate) {
    const { filters, defaultDateFilter } = this.props;
    let updatedValues = {
      ...this.state.tempValues,
      ...values
    };

    Object.keys(filters).forEach(name => {
      const configItem = filters[name];

      if (configItem.type === 'date-range') {
        const from = values[configItem.useName ? `${name}_from` : 'from'];
        const to = values[configItem.useName ? `${name}_to` : 'to'];
        const rangeName = configItem.useName ? name : 'dateRange';

        // Without this condition, the date range gets erased without user's intention
        // Case: the name in Typeahead gets updated to "sample", then values variable is { name: "sample" }
        // In that case, no "from" or "to" values are presented, hence they are erased on line 60
        const dateRangePresent = from !== undefined || to !== undefined;
        if ((this.state.values[rangeName] && dateRangePresent) || forcedUpdate) {
          updatedValues[rangeName] = [from, to];
        }
      }
      if (configItem.type === 'date-filter') {
        if (values[name] !== undefined) {
          // datefilter component expects individual date prop
          updatedValues[name].date = moment(values[name].dateRange[0]).toDate();

          // ignore attempts to filter by the same range as default
          if (defaultDateFilter) {
            const defaultStart = moment(defaultDateFilter.dateRange[0], 'YYYY-MM-DD');
            const sameDefaultStart = moment(values[name].dateRange[0]).isSame(defaultStart, 'day');
            const defaultEnd = moment(defaultDateFilter.dateRange[1], 'YYYY-MM-DD');
            const sameDefaultEnd = moment(values[name].dateRange[1]).isSame(defaultEnd, 'day');
            if (sameDefaultStart && sameDefaultEnd) {
              delete updatedValues[name];
            }
          }
        }
      }
      if (configItem.type === 'registration-status') {
        /**
         * The registration status filter contains two form fields that when submitted need to be contained
         * in a single composite object to be used later as their default values. We're updating the state
         * with a single object and then removing the original form values that were submitted.
         */
        if (values['status'] !== undefined) {
          updatedValues[name] = RegistrationStatusServices.buildCompositeProperty(values);
          delete updatedValues['status'];
          delete updatedValues['range'];
        }

        if (!updatedValues[name]) {
          updatedValues[name] = RegistrationStatusServices.buildCompositeProperty();
          delete updatedValues['status'];
          delete updatedValues['range'];
        }
      }
    });

    this.setState({
      values: updatedValues,
      tempValues: updatedValues
    });

    this.props.onSubmit(updatedValues);

    if (typeof closeTooltip === 'function') {
      closeTooltip();
    }
  }

  @bind
  onCancel(type, name, value, index) {
    const { filters, customFieldConfig } = this.props;
    switch (type) {
      case 'tags':
      case 'multi-select':
      case 'checkbox-group':
      case 'milestone-categories':
        this.onSubmit({
          ...this.state.values,
          [name]: this.state.values[name].filter(v => v !== value)
        });
        break;
      case 'room':
      case 'lesson-category':
      case 'checkbox':
      case 'select':
      case 'typeahead':
      case 'hidden-select':
      case 'date-picker':
      case 'age-filter':
      case 'date-filter':
        if (filters[name].fallbackValue) {
          return this.onSubmit({
            ...this.state.values,
            [name]: filters[name].fallbackValue
          });
        }

        this.onSubmit({
          ...this.state.values,
          [name]: filters[name].ignoredValues ? filters[name].ignoredValues[0] : undefined
        });
        break;
      case 'date-range':
        {
          const from = filters[name].useName ? `${name}_from` : 'from';
          const to = filters[name].useName ? `${name}_to` : 'to';
          if (index === 0) {
            this.onSubmit(
              {
                ...this.state.values,
                [from]: undefined
              },
              null,
              true
            );
          } else {
            this.onSubmit(
              {
                ...this.state.values,
                [to]: undefined
              },
              null,
              true
            );
          }
        }
        break;
      case 'sign-filter':
        {
          const newVal = {
            ...this.state.values[name],
            [index]: undefined
          };
          this.onSubmit({
            ...this.state.values,
            [name]: !newVal || (!newVal.before && !newVal.after && !newVal.never) ? undefined : newVal
          });
        }
        break;
      case 'registration-status':
        this.onSubmit({
          ...this.state.values,
          [name]: undefined
        });
        break;
    }

    if (customFieldConfig && customFieldConfig[name]) {
      this.setState(prevState => {
        let updatedTempValues = { ...prevState.tempValues };
        let updatedState = {};

        updatedTempValues[name] = undefined;

        const field = customFieldConfig[name];
        if (field && field.update) {
          updatedState = {
            ...updatedState,
            ...field.update(undefined, updatedTempValues)
          };
        }

        return {
          tempValues: updatedTempValues,
          ...updatedState
        };
      });
    }
  }

  @bind
  onOpen() {
    // forces re-render the form
    if (this.filterForm) {
      this.setState({ formKey: this.state.formKey + 1 });
      this.setState({ formPaymentsKey: this.state.formPaymentsKey + 1 });
    }
  }

  shouldDisplayDefaultDate() {
    return this.props.displayDefaultDate && this.props.defaultDateFilter && !this.state.values.date;
  }

  filterApplied() {
    const { filters } = this.props;
    const { values } = this.state;

    return Object.keys(values).filter(name => {
      const filterObj = filters[name];

      // Special case for has_activities to include both true and false
      if (name === 'has_activities') {
        return values[name] !== undefined;
      }

      return (
        values[name] &&
        filterObj &&
        (!filterObj.ignoredValues || filterObj.ignoredValues.indexOf(values[name]) === -1) &&
        (!filterObj.shouldIgnore || !filterObj.shouldIgnore(values[name])) &&
        (!Array.isArray(values[name]) || values[name].filter(v => v !== undefined && v !== null).length)
      );
    });
  }

  hasFilter() {
    const { filters } = this.props;
    const { values } = this.state;

    return !!this.filterApplied().filter(f => {
      const filterObj = filters[f];

      // hidden filter items rendered separately
      if (filterObj.hidden || filterObj.type.indexOf('hidden') !== -1) {
        return false;
      }

      // fallback values can't be removed, so don't count them
      // this avoids loop of showing a "Reset Filters" option that doesn't go away
      if (filterObj.fallbackValue && filterObj.fallbackValue === values[f]) {
        return false;
      }

      return true;
    }).length;
  }

  resetFilters(values) {
    this.setState({ values: values || {}, tempValues: values || {} });
  }

  @bind
  renderFilterItem(name, config) {
    const { type, hidden, formType, ...otherProps } = config;
    const { tempValues, disabledFields, disabledOptions } = this.state;
    const Item = FilterItems[type];

    if (type.indexOf('hidden') === 0 || hidden) {
      return null;
    }

    if (!Item) {
      console.warn(`${type} filter item not found`);
      return null;
    }
    const disableInput = disabledFields.includes(name);

    const itemProps = {
      key: `filter_${name}`,
      name: name,
      type: formType,
      defaultValue: tempValues[name],
      onChange: this.onChange,
      disabled: disableInput,
      disableDropdown: disableInput,
      disabledOptions: disabledOptions,
      ...otherProps
    };

    switch (type) {
      case 'typeahead': {
        if (config.loadOptions) {
          itemProps['loadOptions'] = async (...args) => {
            let options = [];
            try {
              options = await config.loadOptions(...args);
            } catch (e) {
              // do nothing
            }
            this.setState({
              extra: {
                [name]: {
                  options
                }
              }
            });
            return options;
          };
        }
        break;
      }
      case 'date-filter': {
        if (itemProps.defaultValue === undefined) {
          // prevent component from defaulting to last month (done for reporting)
          itemProps.defaultValue = {
            type: 'monthly',
            date: moment()
              .startOf('month')
              .format('YYYY-MM-DD')
          };
        }
        break;
      }
    }

    return <Item {...itemProps} />;
  }

  @bind
  renderFilterForm(closeTooltip) {
    const { filters, layout } = this.props;
    const tooltipClassName = cx('tooltip-content__inner', {
      [`tooltip-content__inner--${layout.width}`]: layout.width
    });

    return (
      <div className={tooltipClassName} key={this.state.formKey} data-cy="filter-options">
        <Form ref={form => (this.filterForm = form)} onSubmit={values => this.onSubmit(values, closeTooltip)}>
          {Object.keys(filters).map(name => {
            return this.renderFilterItem(name, filters[name]);
          })}
          <div className="form__row form__row--actions">
            <Form.Submit label="Apply" data-cy="apply" />
          </div>
        </Form>
      </div>
    );
  }

  render() {
    const {
      className,
      children,
      filters,
      wide,
      displayApplied,
      defaultDateFilter,
      side,
      noArrow,
      disabled
    } = this.props;
    const btnCN = cx('filter-bar__toggle-btn', {
      'filter-bar__toggle-btn--no-filter': !this.hasFilter(),
      'filter-bar__toggle-btn--disabled': disabled
    });
    const barCN = cx('filter-bar', className, {
      'filter-bar--wide': wide,
      'filter-bar--disabled': disabled
    });
    const hasMoreFilters = !!Object.keys(filters).filter(filterName => !Filter.isHidden(filters[filterName])).length;
    const defaultDateConfig = { type: 'date-filter', singleValue: true };

    return (
      <div className="filter" data-cy="filter">
        <div className={barCN}>
          <div className="filter-bar__main">
            <Form onChange={this.onSubmit}>{children}</Form>
          </div>

          {hasMoreFilters && (
            <TooltipTrigger
              white
              side={side}
              noArrow={noArrow}
              triggerOn="click"
              className="filter-bar__toggle"
              renderTooltip={this.renderFilterForm}
              tooltipClassName="tooltip--form-wrapper"
              onOpen={this.onOpen}
            >
              <ButtonV2
                className={btnCN}
                icon
                iconName="filter-1"
                iconSize="18"
                data-cy="filter-trigger"
                disabled={disabled}
              />
            </TooltipTrigger>
          )}
        </div>

        {displayApplied && (
          <div className="applied-filters" data-cy="filter-applied">
            {this.shouldDisplayDefaultDate() && (
              <AppliedItem
                key={'applied_default_date_range'}
                config={defaultDateConfig}
                value={defaultDateFilter}
                readonly
              />
            )}

            {this.filterApplied().map(name => {
              const { values, extra } = this.state;

              return (
                <AppliedItem
                  key={`applied_${name}`}
                  config={filters[name]}
                  value={values[name]}
                  extra={extra[name]}
                  onCancel={(value, index) => this.onCancel(filters[name].type, name, value, index)}
                  readonly={filters[name].fallbackValue === values[name]}
                  tooltip={filters[name].fallbackValue === values[name] && filters[name].fallbackTooltip}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default Filter;
