import React from 'react';
import PropTypes from 'prop-types';

function PersonInfoCardList({ children }) {
  return <div className="person-info-card__list">{children}</div>;
}

PersonInfoCardList.propTypes = {
  children: PropTypes.node
};

export default PersonInfoCardList;
