import React from 'react';
import { ActionButton } from 'components';

const LearningItem = ({ item, onDelete }) => (
  <div className="manage-activities__item">
    <div className="manage-activities__item-inner">
      <div className="manage-activities__item-name">{item.value}</div>

      <ActionButton iconName="delete" onClick={() => onDelete(item.id)} />
    </div>
  </div>
);

export default LearningItem;
