import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Form } from 'components';
import moment from 'moment';
import CRM_TASK_KINDS from 'constants/crmTaskKinds';
import './style.scss';

function TaskForm({ data, staff, onSubmit, fromRule, fromLead, saving, children }) {
  useEffect(() => {
    req.staff();
  }, []);

  const staffOptions = staff.map(s => ({
    id: s.id,
    label: s.name
  }));

  const dueDate = get(data, 'due_date', null);
  const defaultKind = 'todo' in CRM_TASK_KINDS ? 'todo' : '';

  const getDefaultLeadOption = () => {
    const assignable = data.assignable || {};
    const label =
      get(assignable, 'name', '') || `${get(assignable, 'first_name', '')} ${get(assignable, 'last_name', '')}`;

    return assignable.id
      ? [
          {
            id: assignable.id,
            label
          }
        ]
      : [];
  };

  const loadLeads = query => {
    const leadSelected = Boolean(data.assignable);
    if (query === '' && leadSelected) {
      return Promise.resolve([]);
    }

    const requestPayload = {
      filters: {
        enrollment: { name: query }
      }
    };

    return req.enrollmentsTypeahead(requestPayload).then(result => {
      return result.enrollments.map(e => ({
        id: e.id,
        label: `${e.first_name} ${e.last_name}`
      }));
    });
  };

  return (
    <Form onSubmit={onSubmit} validateOn="submit" isLoading={saving}>
      <div className="form__row">
        <Form.Input
          data-cy="title"
          label="Title"
          placeholder="Task Name"
          name="name"
          required
          defaultValue={get(data, 'name', '')}
        />
      </div>
      <div className="form__row form__row--justified">
        {!fromRule ? (
          <Form.Typeahead
            placeholder="Search (min 3 letters)"
            label="Lead"
            name="enrollment_id"
            loadOptions={loadLeads}
            minCharacters={3}
            options={getDefaultLeadOption()}
            disabled={fromLead}
            hideClear={fromLead}
            defaultValue={get(data, 'assignable.id')}
          />
        ) : (
          <Form.Typeahead
            label="Assigned To"
            name="teacher_id"
            options={staffOptions}
            defaultValue={get(data, 'teacher_id')}
          />
        )}
        <Form.Select
          label="Type"
          title="Choose Type"
          name="kind"
          required
          defaultValue={get(data, 'kind', defaultKind)}
        >
          {Object.keys(CRM_TASK_KINDS).map(k => (
            <Form.Select.Item key={k} value={k} label={CRM_TASK_KINDS[k]} />
          ))}
        </Form.Select>
      </div>
      <div className="form__row">
        <Form.Input
          label="Note"
          placeholder="Task Details here"
          name="description"
          multiline
          defaultValue={get(data, 'description', '')}
        />
      </div>
      {!fromRule && (
        <div className="form__row form__row--justified">
          <div className="form__row">
            <Form.DatePicker
              label="Due Date"
              name="due_date"
              defaultValue={dueDate ? moment(dueDate).toDate() : moment().toDate()}
              dropup={true}
              className="mr-8"
            />
            <Form.TimeInput label={<span>&nbsp;</span>} name="due_time" defaultValue={get(data, 'due_time')} />
          </div>
          <Form.Typeahead
            label="Assigned To"
            name="teacher_id"
            options={staffOptions}
            defaultValue={get(data, 'teacher.id')}
          />
        </div>
      )}
      {children}

      <div className="create-crm-task__footer form__row--actions">
        <Form.Submit label="Save" data-cy="submit" />
      </div>
    </Form>
  );
}

TaskForm.propTypes = {
  data: PropTypes.object,
  children: PropTypes.node,
  fromRule: PropTypes.bool,
  fromLead: PropTypes.bool,
  onSubmit: PropTypes.func
};

const mapStateToProps = state => ({
  staff: state.staff.data
});

export default connect(mapStateToProps)(TaskForm);
