import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'components';
import moment from 'moment-timezone';
import useLaunchDarkly from 'hooks/useLaunchDarkly';
import './style.scss';

const EditSchool = ({ data, errors, constants, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const flags = useLaunchDarkly();

  const submit = formData => {
    const newFormData = { ...formData };
    newFormData.enrollment = parseInt(newFormData.enrollment, 10);
    setIsLoading(true);

    req
      .updateSchool({ school: newFormData })
      .then(() => req.profile()) // Update current school name in the list of user's schools
      .then(() => {
        onClose();
      })
      .catch(err => {
        Actions.reportError('Unable to save school profile', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const typeaheadOptions = moment.tz
    .names()
    .map(zone => ({ id: zone, label: zone }))
    .sort((a, b) => (a.id.indexOf('US/') === 0 ? -1 : a.id > b.id ? 1 : -1));

  return (
    <div className="edit-student">
      <div className="modal__header">
        <span className="subheader">Edit School</span>
      </div>

      <div className="modal__container">
        <Form validateOn="submit" onSubmit={submit} errors={errors} isLoading={isLoading}>
          <div className="form__row form__row--justified">
            <Form.Input
              name="name"
              data-cy="name"
              defaultValue={data.name}
              placeholder="School Name"
              label="School Name"
              required
            />
            <Form.Select
              name="facility_type"
              defaultValue={data.facility_type}
              title="School Type"
              label="School Type"
              required
            >
              {!constants
                ? 'loading types...'
                : Object.keys(constants.facility_types).map(t => (
                    <Form.Select.Item label={constants.facility_types[t]} value={t} key={t} />
                  ))}
            </Form.Select>
          </div>

          <div className="form__row form__row--justified">
            <Form.Input
              data-cy="enrollment"
              name="enrollment"
              defaultValue={data.enrollment}
              placeholder="No of Enrollment"
              label="No of Enrollment"
              naturalNumber
              required
            />
            <Form.Input
              name="phone"
              data-cy="phone"
              defaultValue={data.phone}
              placeholder="Phone Number"
              label="School Phone"
              required
            />
          </div>

          <div className="form__row form__row--justified">
            <Form.Input
              name="federal_tax_id"
              defaultValue={data.federal_tax_id}
              placeholder="EIN tax"
              label="EIN tax"
            />
            <Form.Input name="web" defaultValue={data.web} placeholder="Web URL" label="Web URL" />
          </div>

          <div className="form__row form__row--justified">
            <Form.Typeahead
              name="time_zone"
              data-cy="time_zone"
              defaultValue={data.time_zone}
              title="Timezone"
              label="Timezone"
              options={typeaheadOptions}
              required
            />
            <Form.Input
              name="siso_email"
              defaultValue={data.siso_email}
              placeholder="Email"
              label="Site Director Email"
            />
          </div>

          <div className="form__row">
            <Form.Input
              name="street_address"
              defaultValue={data.street_address}
              placeholder="Address Line"
              label="Address Line"
            />
          </div>

          <div className="form__row">
            <Form.Input
              name="address_line_2"
              defaultValue={data.address_line_2}
              placeholder="Address Line 2"
              label="Address Line 2"
            />
          </div>

          <div className="form__row form__row--justified">
            <Form.Input name="city" defaultValue={data.city} placeholder="City" label="City" />
            <Form.Input name="state" defaultValue={data.state} placeholder="State/Territory" label="State/Territory" />
          </div>

          <div className="form__row form__row--justified">
            <Form.Select name="country_code" defaultValue={data.country_code} title="Country" label="Country">
              {!constants
                ? 'loading countries...'
                : Object.keys(constants.countries).map(c => (
                    <Form.Select.Item label={constants.countries[c]} value={c} key={c} />
                  ))}
            </Form.Select>
            <Form.Input name="zip" defaultValue={data.zip} placeholder="Zipcode" label="Zipcode" />
          </div>

          <div className="form__row form__row--actions">
            <Form.Submit label="Save changes" data-cy="save-changes" disabled={isLoading} />
          </div>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  constants: state.constants.data
});

export default connect(mapStateToProps)(EditSchool);
