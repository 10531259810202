import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Avatar, Status } from 'components';
import './style.scss';

class ChangeEnrollment extends Component {
  static propTypes = {
    data: PropTypes.object,
    statuses: PropTypes.object,
    onClose: PropTypes.func
  };

  static defaultProps = {
    data: {},
    statuses: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  @bind
  submit(values) {
    const { kid } = this.props.data;

    const payload = {
      kid_ids: [kid.id],
      registration_status: values.registration_status
    };

    this.setState({ loading: true }, () =>
      req
        .studentsBatchUpdate(payload)
        .then(() => {
          Actions.showFlash('Enrollment has been changed');
          this.props.onClose();
        })
        .catch(err => {
          Actions.reportError('Unable to change enrollment', err);
          this.setState({ loading: false });
        })
    );
  }

  renderEnrollments() {
    const { statuses } = this.props;

    return Object.keys(statuses).map(s => (
      <Form.Select.Item
        key={s}
        value={s}
        label={statuses[s]}
        className={`change-enrollment__item change-enrollment__item--${s}`}
      >
        <Status label={statuses[s]} />
      </Form.Select.Item>
    ));
  }

  render() {
    const { loading } = this.state;
    const { kid } = this.props.data;

    return (
      <div className="change-enrollment">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Edit Lead</div>

          <div className="change-enrollment__kid">
            <Avatar
              name={kid.name}
              url={kid.profile_pic_url}
              initials={kid.initials}
              color={kid.color}
              className="change-enrollment__kid-avatar"
            />

            <div className="change-enrollment__kid-name">{kid.name}</div>
          </div>
        </div>

        <div className="modal__container">
          <Form onSubmit={this.submit}>
            <Form.Select
              required
              name="registration_status"
              label="Lead"
              title="Select Lead Status"
              defaultValue={kid.registration_status}
            >
              {this.renderEnrollments()}
            </Form.Select>

            <div className="modal__controls">
              <Form.Submit label="Submit" isLoading={loading} />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  statuses: state.constants.data.kid_registration_statuses
});

export default connect(mapStateToProps)(ChangeEnrollment);
