import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import validationText from '../validationText';
import './style.scss';
import withContext, { FormContext } from 'hocs/withContext';

export const DATES = [
  { label: 'Mon', value: 'M' },
  { label: 'Tue', value: 'T' },
  { label: 'Wed', value: 'W' },
  { label: 'Thu', value: 'Th' },
  { label: 'Fri', value: 'F' },
  { label: 'Sat', value: 'S' },
  { label: 'Sun', value: 'Su' }
];

class WeekdayPicker extends Component {
  static propTypes = {
    tabIndex: PropTypes.number
  };

  UNSAFE_componentWillMount() {
    const initialValue = this.props.defaultValue || [];
    const validations = {};

    if (this.props.required) {
      validations.isNotEmpty = null;
    }

    this.props.context.init(this.props.name, initialValue, validations);
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  @bind
  toggleValue(val) {
    const { values } = this.props.context;
    const { name } = this.props;

    const newVal = values[name].includes(val) ? values[name].filter(v => v !== val) : [...values[name], val];

    this.props.context.update(this.props.name, newVal);
  }

  getValidationText() {
    const { name } = this.props;
    const { values, validations, errors } = this.props.context;

    if (errors[name]) {
      return errors[name].join('; ');
    }

    let invalidText = '';

    if (validations[name] && validations[name].isNotEmpty === false && values[name].length === 0) {
      invalidText = validationText.isNotEmpty;
    }

    return invalidText;
  }

  render() {
    const { className, name, label, tabIndex } = this.props;
    const invalidText = this.getValidationText();
    const checked = this.props.context.values[name] || [];

    return (
      <div className={cx('form-weekday-picker', className)}>
        {label && (
          <label className="form__label" htmlFor={name}>
            {label}
          </label>
        )}

        <div className="form-weekday-picker__body">
          {DATES.map((d, index) => {
            const CN = cx('form-weekday-picker__button', {
              'form-weekday-picker__button--selected': checked.includes(d.value)
            });
            return (
              <div key={d.value} className={CN} onClick={() => this.toggleValue(d.value)} tabIndex={tabIndex + index}>
                {d.label}
              </div>
            );
          })}
        </div>

        {invalidText && <div className="form-input__validation-text">{invalidText}</div>}
      </div>
    );
  }
}

export default withContext(FormContext)(WeekdayPicker);
