import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import classNames from 'classnames';
import moment from 'moment';
import YearMonthForm from './YearMonthForm';
import TooltipPortalTrigger from '../TooltipPortalTrigger';
import ActionButton from '../ActionButton';
import Icon from '../Icon';
import 'react-day-picker/lib/style.css';
import './style.scss';

const MILLISECONDS_IN_A_SECOND = 1000;
const SECONDS_IN_A_MINUTE = 60;
const MINUTES_IN_AN_HOUR = 60;
const HOURS_IN_A_DAY = 24;
const MILLISECONDS_IN_A_DAY = MILLISECONDS_IN_A_SECOND * SECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR * HOURS_IN_A_DAY;

class DatePicker extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['daily', 'weekly', 'biweekly', 'range']),
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    formatTitle: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    disableType: PropTypes.bool,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
    disableDays: PropTypes.func,
    side: PropTypes.oneOf(['left', 'right', 'center']),
    dropup: PropTypes.bool,
    clearable: PropTypes.bool,
    oneMonthInterval: PropTypes.bool,

    yearsToShow: PropTypes.number,
    'data-cy': PropTypes.string
  };

  static defaultProps = {
    type: 'daily',
    side: 'left',
    dropup: false,
    disableType: false,
    disableFuture: false,
    disablePast: false,
    clearable: false,
    yearsToShow: 100,
    oneMonthInterval: false
  };

  _tabRef;
  _wrapperRef;

  rangeActiveIndex = undefined;

  constructor(props) {
    super(props);

    let selectedMonth = new Date();

    if (props.value) {
      selectedMonth = props.value instanceof Array ? props.value[0] || new Date() : props.value;
    }

    this.state = {
      selectedMonth,
      isEditing: false,
      inputText: '',
      isFocused: false
    };

    this.fromMonth = moment()
      .startOf('month')
      .add(5, 'years')
      .toDate();
    this.toMonth = moment()
      .startOf('month')
      .subtract(props.yearsToShow, 'years')
      .toDate();
  }

  componentDidUpdate(prevProps) {
    const { value: prevValue } = prevProps;
    const { value: nextValue } = this.props;

    const prevDate = prevValue instanceof Array ? prevValue[0] : prevValue;
    const nextDate = nextValue instanceof Array ? nextValue[0] : nextValue;

    if (
      (prevDate instanceof Date && nextDate instanceof Date && prevDate.valueOf() !== nextDate.valueOf()) ||
      (prevDate === undefined && nextDate instanceof Date)
    ) {
      this.setState({ selectedMonth: nextDate });
    }
  }

  @bind
  onOpen() {
    if (this.props.type === 'range') {
      this.rangeActiveIndex = 0;
    }
  }

  @bind
  onClose() {
    if (this.props.type === 'range') {
      this.rangeActiveIndex = undefined;
    }

    if (this.props.onClose) {
      this.props.onClose();
    }

    if (typeof this.props.tabIndex === 'number') this.setState({ isFocused: false });
  }

  @bind
  onStartEdit(e) {
    const { type, value, disabled, disableType } = this.props;
    if (type !== 'daily' || disabled || Array.isArray(value) || disableType) {
      return;
    }
    e.stopPropagation();

    this.setState({
      isEditing: true,
      inputText: value ? moment(value).format('MM/DD/YYYY') : ''
    });
  }

  @bind
  onEditText(evt) {
    const val = evt.target.value;
    // const val = toPattern(evt.target.value, '99/99/9999');
    this.setState({ inputText: val });
  }

  @bind
  onInputKeyDown(evt) {
    if (evt.keyCode === 13) {
      this.onInputBlur();
    }
  }

  @bind
  onInputBlur() {
    const { inputText } = this.state;
    const date = moment(inputText, 'MM/DD/YYYY');

    if (date.isValid()) {
      this.changeMonth(date.toDate());
      this.props.onChange(date.toDate());
    }
    this.setState({ inputText: '', isEditing: false });
  }

  isDateDisabled(dateValue) {
    const { disableFuture, disablePast, disableDays, oneMonthInterval, onChange, value } = this.props;

    if (!dateValue) {
      this.props.onChange();
      return true;
    }
    if (!onChange) {
      // eslint-disable-next-line no-undef
      closeTooltip();
      return true;
    }
    if ((disableFuture && this.isFutureDay(dateValue)) || (disableDays && disableDays(dateValue))) {
      return true;
    }
    if (disablePast && this.isPastDay(dateValue)) {
      return true;
    }
    if (oneMonthInterval) {
      const twoYearsAgo = moment().subtract(2, 'years');
      const selectedDay = moment(dateValue);
      const disableDay = selectedDay.isBefore(twoYearsAgo);
      if (disableDay) {
        return true;
      }
      if (value instanceof Array) {
        if (selectedDay.isSameOrAfter(moment(), 'day')) {
          return true;
        }
      } else {
        if (selectedDay.isAfter(moment(), 'day')) {
          return true;
        }
      }
    }
    return false;
  }

  @bind
  changeValue(value, closeTooltip) {
    const { type, onChange, oneMonthInterval } = this.props;
    const isDisabledValue = this.isDateDisabled(value);

    if (isDisabledValue) {
      return;
    }

    let result = null;
    const isArray = Array.isArray(this.props.value);
    let shouldClose = type !== 'range' ? true : !isArray || this.rangeActiveIndex === 1;

    switch (type) {
      case 'weekly': {
        let momentValue = moment(value);
        result = [momentValue.startOf('isoWeek').toDate(), momentValue.endOf('isoWeek').toDate()];
        break;
      }

      case 'biweekly': {
        let momentValue = moment(value);
        result = [
          momentValue.startOf('isoWeek').toDate(),
          momentValue
            .add(1, 'week')
            .endOf('isoWeek')
            .toDate()
        ];
        break;
      }

      case 'range': {
        let nextRangeValue = [...this.props.value];
        nextRangeValue[this.rangeActiveIndex] = value;

        if (oneMonthInterval) {
          const startDate = moment(nextRangeValue[0]);
          const end = moment(nextRangeValue[1]);
          const endDate = value > end ? value : end;

          const differenceInMs = startDate - endDate;
          const differenceInDays = differenceInMs / MILLISECONDS_IN_A_DAY;

          if (differenceInDays > 31) {
            nextRangeValue[0] = moment(endDate)
              .subtract(31, 'days')
              .toDate();
          }
          if (differenceInDays < -31 || differenceInDays === 0) {
            const yesterday = moment()
              .subtract(1, 'days')
              .toDate();
            const newDate = moment.min(yesterday, moment(startDate).add(30, 'days'));
            nextRangeValue[1] = new Date(newDate);
          }
        }

        const shouldBeFlipped = Number(nextRangeValue[0]) >= Number(nextRangeValue[1]);
        if (shouldBeFlipped) {
          nextRangeValue = nextRangeValue.reverse();
        }

        if (this.rangeActiveIndex === 0) {
          this.rangeActiveIndex = 1;
        }

        result = nextRangeValue;
        break;
      }

      case 'daily':
      default:
        result = value;
        break;
    }

    if (this.state.selectedMonth.getUTCMonth() !== value.getUTCMonth()) {
      this.changeMonth(value);
    }

    onChange(result);

    if (shouldClose) {
      closeTooltip();
    }
  }

  @bind
  changeMonth(month) {
    this.setState({ selectedMonth: month }, () => {
      if (this.props.onMonthChange) {
        this.props.onMonthChange(month);
      }
    });
  }

  getDateRange(dateRange) {
    const { oneMonthInterval } = this.props;
    // Workaround to display week correctly.
    // Unfortunately react-day-picker accepts only javascript Date.
    // And Date object is always set to machine's local timezone :/

    let subtractDay = 0;
    if (oneMonthInterval) {
      let adjustedEndTime = moment(dateRange[1]);
      if (adjustedEndTime.isSame(moment(), 'day')) {
        subtractDay = 1;
      }
    }
    const from = moment(dateRange[0]).toDate();
    const to = !dateRange[1]
      ? from
      : moment(dateRange[1])
          .subtract(subtractDay, 'days')
          .toDate();

    return { from, to };
  }

  getTitle() {
    const { value, placeholder, formatTitle } = this.props;
    let title = '';

    if (typeof formatTitle === 'function') {
      return formatTitle({ value, placeholder });
    }

    if (value instanceof Date) {
      title = moment(this.props.value).format('MMM D, YYYY');
    } else if (value instanceof Array) {
      const dateRange = this.getDateRange(value);
      const { from, to } = dateRange;
      if (from) {
        title = moment(from).format('MMM D') + ' – ' + (to ? moment(to).format('MMM D') : '2nd date');
      } else {
        title = 'Select Dates';
      }
    } else {
      title = placeholder || 'Select Date';
    }
    return title;
  }

  isFutureDay(day) {
    return moment().isBefore(day, 'day');
  }

  isPastDay(day) {
    return moment().isAfter(day, 'day');
  }

  @bind
  onClear(e) {
    e.stopPropagation();
    this.changeValue(undefined);
  }

  @bind
  onTabFocus(e) {
    if (typeof this.props.tabIndex === 'number' && e.target === this._tabRef && !this.state.isFocused) {
      this.setState({
        isFocused: true
      });
    }
  }

  @bind
  onTabBlur(e) {
    if (typeof this.props.tabIndex === 'undefined') return;

    const { target, relatedTarget } = e;
    if (
      target === this._tabRef &&
      (relatedTarget === null || !this._wrapperRef.contains(relatedTarget)) &&
      this.state.isFocused
    ) {
      this.setState({
        isFocused: false
      });
    }
  }

  getDisabledDaysModifiers(disableDays, disableFuture, disablePast, localOffset, schoolOffset) {
    const { oneMonthInterval, value } = this.props;
    let disabledDaysModifier = [];

    if (disableDays) {
      disabledDaysModifier.push(disableDays);
    } else {
      if (disableFuture) {
        disabledDaysModifier.push(this.isFutureDay);
      }
      if (disablePast) {
        disabledDaysModifier.push(this.isPastDay);
      }
    }
    if (oneMonthInterval) {
      const today =
        value instanceof Array
          ? moment()
              .add(schoolOffset, 'minutes')
              .toDate()
          : moment()
              .add(schoolOffset, 'minutes')
              .add(1, 'days')
              .toDate();
      const twoYearsBeforeTo = moment()
        .subtract(2, 'years')
        .toDate();

      disabledDaysModifier.push(day => {
        return day < twoYearsBeforeTo || day >= today;
      });
    }
    return disabledDaysModifier;
  }

  @bind
  renderCalendar(closeTooltip) {
    const { selectedMonth } = this.state;
    const { value, disableFuture, disableDays, disablePast } = this.props;

    let selected = [value];
    const localOffset = new Date().getTimezoneOffset();
    const schoolOffset = moment.parseZone(new Date()).utcOffset();
    let disabledDaysModifier = this.getDisabledDaysModifiers(
      disableDays,
      disableFuture,
      disablePast,
      localOffset,
      schoolOffset
    );

    if (value instanceof Array) {
      const { from, to } = this.getDateRange(value);

      let adjustedFrom = moment(from)
        .add(localOffset + schoolOffset, 'minutes')
        .toDate();
      let adjustedTo = !moment(to)
        ? adjustedFrom
        : moment(to)
            .add(localOffset + schoolOffset, 'minutes')
            .toDate();
      selected = [{ from: adjustedFrom, to: adjustedTo }];
    } else {
      selected[0] = moment(value)
        .add(localOffset + schoolOffset, 'minutes')
        .toDate();
    }

    return (
      <DayPicker
        selectedDays={selected}
        onDayClick={value => this.changeValue(value, closeTooltip)}
        month={selectedMonth}
        enableOutsideDays
        fromMonth={this.fromMonth}
        toMonth={this.toMonth}
        firstDayOfWeek={1}
        disabledDays={disabledDaysModifier}
        captionElement={
          <YearMonthForm
            onChange={this.changeMonth}
            from={this.fromMonth}
            to={this.toMonth}
            disableFuture={disableFuture}
          />
        }
      />
    );
  }

  render() {
    const { className, disabled, value, side, dropup, clearable, tabIndex, 'data-cy': dataCY } = this.props;

    const { isEditing, inputText } = this.state;

    const datePickerCN = classNames({
      datepicker: true,
      'datepicker--disabled': disabled,
      'datepicker--range': this.props.type === 'range',
      [className]: Boolean(className)
    });

    const tooltipCN = classNames({
      'datepicker-tooltip': true,
      'datepicker-tooltip--dropup': dropup
    });

    const titleCN = classNames('datepicker__title', {
      'datepicker__title--has-value': Boolean(value)
    });

    const title = this.getTitle();

    return (
      <div
        className="datepicker__wrapper"
        ref={element => {
          this._wrapperRef = element;
        }}
        onFocus={this.onTabFocus}
        onBlur={this.onTabBlur}
        data-cy={dataCY}
      >
        <TooltipPortalTrigger
          white
          side={side}
          triggerOn="click"
          className={datePickerCN}
          portalClassName={tooltipCN}
          renderTooltip={this.renderCalendar}
          disabled={disabled}
          onOpen={this.onOpen}
          onClose={this.onClose}
          visible={this.state.isFocused}
          noArrow
        >
          {isEditing ? (
            <input
              type="text"
              className="datepicker__input"
              autoFocus
              data-cy={`${dataCY}-input`}
              placeholder="MM/DD/YYYY"
              onChange={this.onEditText}
              onKeyDown={this.onInputKeyDown}
              value={inputText}
              onBlur={this.onInputBlur}
            />
          ) : (
            <span data-cy={`${dataCY}-title`} className={titleCN} onClick={this.onStartEdit}>
              {title}
            </span>
          )}
          {typeof tabIndex === 'number' && (
            <div className="datepicker__tab" ref={element => (this._tabRef = element)} tabIndex={tabIndex} />
          )}

          {clearable && value && (
            <ActionButton
              iconName="close-circle"
              size={14}
              className="datepicker__icon datepicker__icon--clear"
              onClick={this.onClear}
            />
          )}
          {(!clearable || !value) && <Icon name="calendar" size={18} className="datepicker__icon" />}
        </TooltipPortalTrigger>
      </div>
    );
  }
}

export default DatePicker;
