import React, { isValidElement } from 'react';
import classNames from 'classnames';
import './style.scss';

// Source: src/screens/teacher/StaffTimeCard/DailyList/index.js
export default function DocumentsHeader({ Filter, Actions, Breadcrumbs }) {
  const isFilterPresent = isValidElement(Filter);

  const CN = classNames({
    'documents-action-box': true,
    'documents-action-box--transparent': !isFilterPresent
  });

  return (
    <div className={CN}>
      {isValidElement(Breadcrumbs) && (
        <div className="breadcrumbs" data-cy="breadcrumbs">
          {Breadcrumbs}
        </div>
      )}
      {isValidElement(Actions) && <div className="documents-action-box__actions">{Actions}</div>}
      {isFilterPresent && <div className="documents-action-box__filters">{Filter}</div>}
    </div>
  );
}
