import React from 'react';
import TransactionRow from './TransactionRow';

const List = ({ data }) => {
  const renderRows = () => {
    return data.map(rowData => {
      return <TransactionRow key={rowData.id} data={rowData} />;
    });
  };

  return (
    <div className="multi-family-warning__list">
      <div className="table">
        <div className="table__header">
          <div className="table__cell table__cell--xs">Date</div>
          <div className="table__cell table__cell--xs transaction-row__payment-cell">Type</div>
          <div className="table__cell table__cell--xs transaction-row__amount">Amount</div>
          <div className="table__cell table__cell--xs transaction-row__status">Status</div>
        </div>
        <div className="table__body table__body-colored">{renderRows()}</div>
      </div>
    </div>
  );
};

export default List;
