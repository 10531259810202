import PAYMENT_MODES from 'constants/paymentModes';
import PRESENTATION_TYPES from 'constants/cardPresentationTypes';
import { BillingGateway, PaymentMode, PaymentMethod } from 'constants/billing';

type School = Partial<{
  billing_gateway: BillingGateway;
  billing_account_type: string;
  billing_account_status: string;
  pe_enabled: boolean;
}>;

type Transactable = {
  auto_billing: string;
  paid_online: string;
  presentation_type: string;
};

export const isStripeCustom = (school: School) => {
  return school.billing_gateway === BillingGateway.Stripe && school.billing_account_type === 'custom';
};

export const checkProcareDesktopBillingSync = (school: School = {}) => {
  if (!school) return false;

  return (
    school.pe_enabled && school.billing_gateway === BillingGateway.TE && school.billing_account_status === 'verified'
  );
};

export const getPaymentSource = (paymentMode: string, transactable: Transactable) => {
  const { paid_online, auto_billing, presentation_type } = transactable;

  if (paid_online) {
    if (auto_billing) {
      return '(AutoPay)';
    } else if (paymentMode === PaymentMode.Card) {
      return '(Online)';
    }
  }

  const type = PRESENTATION_TYPES[presentation_type as keyof typeof PRESENTATION_TYPES];
  return type ? `(${type})` : '';
};

export const getPaymentModeDescription = (
  paymentMode: PaymentMode,
  paymentSubMode: string,
  transactable: Transactable
) => {
  if (!paymentMode || paymentMode === PaymentMode.Bank) {
    return undefined;
  }

  const mode = paymentSubMode === 'debit' ? 'Debit Card' : PAYMENT_MODES[paymentMode as keyof typeof PAYMENT_MODES];
  const source = getPaymentSource(paymentMode, transactable);

  return `${mode} ${source}`.trim();
};

export const paymentMethodNeedsAttention = (feePaidByParent: boolean, paymentMethod: PaymentMethod) => {
  return (
    feePaidByParent &&
    paymentMethod?.kind === 'card' &&
    paymentMethod?.subKind === 'debit' &&
    paymentMethod?.verifiedSubKind &&
    paymentMethod?.verifiedSubKind !== 'none' &&
    paymentMethod?.subKind !== paymentMethod.verifiedSubKind
  );
};
