import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonV2, Form, InvoiceItemList, StudentList, SelectGroup } from 'components';
import omit from 'lodash/omit';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class InvoiceDetails extends Component {
  static propTypes = {
    actionType: PropTypes.oneOf(['create', 'edit']),
    data: PropTypes.object,
    onUpdate: PropTypes.func,
    skipKidList: PropTypes.bool,
    isLoading: PropTypes.bool,
    isAutopostSaving: PropTypes.bool,
    students: PropTypes.array,
    subFamilies: PropTypes.array,
    onManagePresets: PropTypes.func,
    onSubFamilyChange: PropTypes.func,
    onSubmit: PropTypes.func
  };

  static defaultProps = {
    actionType: 'create',
    data: {},
    subFamilies: [],
    isLoading: false
  };

  constructor(props) {
    super(props);

    this.state = {
      dates: props.data
        ? {
            due_date: props.data.due_date,
            invoice_start_date: props.data.invoice_start_date,
            invoice_end_date: props.data.invoice_end_date
          }
        : {},

      notes: props.data ? props.data.notes : '',

      items: props.data ? props.data.parent_invoice_items_attributes : [],
      total: 0
    };
  }

  @bind
  save(auto_post) {
    const { items, dates, notes } = this.state;

    const data = {
      parent_invoice_items_attributes: items.map(i => {
        return {
          ...i,
          _mode: this.props.actionType === 'create' ? i._mode : undefined // _mode is used to maintain preset form usage after the step is changed
        };
      }),
      due_date: dates.due_date,
      invoice_start_date: dates.invoice_start_date || undefined,
      invoice_end_date: dates.invoice_end_date || undefined,
      auto_post,
      notes
    };

    if (this.props.actionType === 'create') {
      this.props.context.nextStep();
      this.props.onUpdate(data);
    } else {
      this.props.onSubmit(data);
    }
  }

  @bind
  updateDates(values) {
    this.setState({ dates: values });
  }

  @bind
  updateNotes(values) {
    this.setState({ notes: values.notes });
  }

  @bind
  updateItems(items) {
    this.setState({ items });
  }

  isIncompleteDateRange() {
    const { dates } = this.state;

    return Boolean(dates.invoice_start_date) !== Boolean(dates.invoice_end_date);
  }

  hasEmptyItems() {
    const { items } = this.state;

    return items.filter(item => !item.description || (!item.price && item.price !== 0)).length > 0;
  }

  @bind
  openManagePresets(presetKind) {
    const { items, dates, notes } = this.state;

    const data = {
      parent_invoice_items_attributes: items.map(i => {
        return {
          ...omit(i, ['_discount', '_discountType', '_base']),
          _mode: this.props.actionType === 'create' ? i._mode : undefined // _mode is used to maintain preset form usage after the step is changed
        };
      }),
      due_date: dates.due_date,
      invoice_start_date: dates.invoice_start_date || undefined,
      invoice_end_date: dates.invoice_end_date || undefined,
      notes
    };

    this.props.onUpdate(data);
    this.props.onManagePresets(presetKind);
  }

  render() {
    const { dates } = this.state;
    const {
      actionType,
      isLoading,
      isAutopostSaving,
      students,
      step,
      totalSteps,
      subFamilies,
      onSubFamilyChange,
      currentSubFamily
    } = this.props;

    const isDisabled = this.hasEmptyItems() || this.isIncompleteDateRange() || !dates.due_date;

    return (
      <div className="modal__wrapper create-invoice">
        <div className="create-invoice__header">
          <div className="create-invoice__row">
            <div className="create-invoice__header-text">{actionType === 'edit' ? 'Edit' : 'Create'} Invoice</div>

            {actionType === 'create' && (
              <div className="create-invoice__header-step">{`Step ${step} / ${totalSteps}`}</div>
            )}
          </div>

          {!!subFamilies.length && (
            <div className="form__row create-invoice__choose-subfamily">
              <div className="create-invoice__choose-subfamily-label">Choose family</div>
              <SelectGroup
                className="create-invoice__choose-subfamily-select"
                title="Choose Family"
                type={'radio'}
                checked={currentSubFamily}
                onChange={onSubFamilyChange}
              >
                {subFamilies.map(sf => (
                  <SelectGroup.Item key={sf.id} value={sf.id} label={sf.name} />
                ))}
              </SelectGroup>
            </div>
          )}

          {students && !!students.length && (
            <div className="create-invoice__students">
              <StudentList students={students} />
            </div>
          )}

          <div className="create-invoice__row">
            <Form className="create-invoice__header-form" onChange={this.updateDates}>
              <Form.DatePicker
                label="Invoice Due Date"
                name="due_date"
                defaultValue={this.state.dates.due_date}
                required
                tabIndex={1}
              />

              <Form.DatePicker
                label="Date of Service (optional)"
                name="invoice_start_date"
                className="create-invoice__service-date"
                defaultValue={this.state.dates.invoice_start_date}
                tabIndex={2}
              />
              <div className="to">to</div>
              <Form.DatePicker name="invoice_end_date" defaultValue={this.state.dates.invoice_end_date} tabIndex={3} />
            </Form>
          </div>
        </div>

        <div className="create-invoice__items">
          <div className="create-invoice__items-header">Invoice Details</div>

          <InvoiceItemList
            mode={this.props.data ? this.props.data.mode : ''}
            data={this.state.items}
            onUpdate={this.updateItems}
            onClose={this.props.onClose}
            type={actionType}
            enablePresets
            onManagePresets={this.openManagePresets}
            tabIndex={4}
            onClose={this.props.onClose}
          />
        </div>

        <Form onChange={this.updateNotes}>
          <Form.Input
            placeholder="Add optional internal note"
            name="notes"
            className="create-invoice__notes form-input--highlight"
            defaultValue={this.props.data ? this.props.data.notes : ''}
            tabIndex={5}
          />
        </Form>

        <div className="modal__controls">
          {actionType !== 'create' && (
            <ButtonV2
              label={actionType === 'create' ? 'Continue' : 'Save'}
              secondary
              disabled={isDisabled || isAutopostSaving}
              isLoading={isLoading}
              onClick={() => this.save(false)}
            />
          )}
          <ButtonV2
            label={actionType === 'create' ? 'Continue' : 'Save and Send'}
            disabled={isDisabled || isLoading}
            isLoading={isAutopostSaving}
            onClick={() => this.save(true)}
          />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(InvoiceDetails);
