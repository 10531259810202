import moment from 'moment';

export default (data, activity) => {
  const date = moment(data.date || undefined).format('YYYY-MM-DD');
  const now = moment().format('h:mm A');

  return {
    id: activity ? activity.id : undefined,

    daily_activity: {
      activity_time: `${date} ${data.time || now}`,
      comment: data.desc,
      description: data.desc,
      is_staff_only: data.staffOnly
    },

    learning: {
      learning_activity_name_id: data.name_id,
      learning_activity_category_ids: data.category_ids
    }
  };
};
