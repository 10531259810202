import { validateEmail, validateNaturalNumber, getSingleValue } from 'lib/utils';
import moment from 'moment';
import get from 'lodash/get';

const _isEmpty = v => v === undefined || v === null || v === '';

const isEmail = value => {
  return !value || validateEmail(value);
};

const isUrl = value => {
  const URL_REGEX = /^(https?:\/\/).+\.[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b/;
  return URL_REGEX.test(value);
};

const isNotEmpty = value => {
  if (Array.isArray(value)) {
    return value.length > 0;
  } else if (typeof value === 'string') {
    return value.trim().length > 0;
  } else {
    return value !== null && value !== undefined && value !== false;
  }
};

const isNaturalNumber = value => {
  return _isEmpty(value) || validateNaturalNumber(value);
};

const isPasswordsEqual = (value, params) => {
  const { values, linkedFields, fieldName } = params;
  return value === values[linkedFields[fieldName]];
};

const minLength = (value, params) => {
  const { minLength } = params;
  return !value.length || value.length >= minLength;
};

const pattern = (value, params) => {
  const re = new RegExp(params.pattern);
  return re.test(value);
};

const maxRangeMonth = (value, params) => {
  const { values, linkedFields, fieldName } = params;
  const linkedField = values[linkedFields[fieldName]];
  if (linkedField && getSingleValue(value)) {
    return (
      Math.abs(moment(getSingleValue(value)).diff(moment(getSingleValue(linkedField)), 'months')) <=
      params.maxRangeMonth
    );
  }

  return true;
};

const maxRangeMonthFilter = (value, params) => {
  if (value.type !== 'range') return true;

  return Math.abs(moment(value.dateRange[0]).diff(moment(value.dateRange[1]), 'months')) <= params.maxRangeMonthFilter;
};

const isGreaterThanStart = (value, params) => {
  const { values, linkedFields, fieldName } = params;
  return getSingleValue(value) >= getSingleValue(values[linkedFields[fieldName]]);
};

const isLesserThanEnd = (value, params) => {
  const { values, linkedFields, fieldName } = params;
  return getSingleValue(value) <= getSingleValue(values[linkedFields[fieldName]]);
};

const isAfterTime = (value, params) => {
  const { values, linkedFields, fieldName } = params;
  const startTime = get(values, `${linkedFields[fieldName]}`);
  if (value && startTime !== undefined) {
    return (
      moment(`2000-01-01 ${value}`, 'YYYY-MM-DD hh:mm A').diff(
        moment(`2000-01-01 ${startTime}`, 'YYYY-MM-DD hh:mm A')
      ) > 0
    );
  }

  return true;
};

const isTime = value => {
  return !value || /\d{1,2}:\d{2}\s{1}(AM|PM)/.test(value);
};

export {
  isEmail,
  isUrl,
  isNotEmpty,
  isNaturalNumber,
  isPasswordsEqual,
  minLength,
  pattern,
  isGreaterThanStart,
  isLesserThanEnd,
  maxRangeMonth,
  maxRangeMonthFilter,
  isTime,
  isAfterTime
};

export default {
  isEmail,
  isUrl,
  isNotEmpty,
  isNaturalNumber,
  isPasswordsEqual,
  minLength,
  pattern,
  isGreaterThanStart,
  isLesserThanEnd,
  maxRangeMonth,
  maxRangeMonthFilter,
  isTime,
  isAfterTime
};
