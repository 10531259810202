import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { Checkbox, SelectGroup, TimeInput, Dropdown } from 'components';
import { capitalize } from 'lib/utils';
import './style.scss';

class SignTimeFilter extends Component {
  static propTypes = {
    rooms: PropTypes.array,
    onChange: PropTypes.func,
    useRoom: PropTypes.bool,
    'data-cy': PropTypes.string,
    defaultPeriod: PropTypes.oneOf(['before', 'after'])
  };

  static defaultProps = {
    rooms: [],
    useRoom: true,
    defaultPeriod: 'before'
  };

  static defaultTime = {
    before: '06:00 PM',
    after: '06:00 AM'
  };

  constructor(props) {
    super(props);

    const { defaultValue, defaultPeriod } = props;
    const values = defaultValue || {};
    const period = Object.keys(values)[0] || defaultPeriod;

    this.state = {
      // Can be either `before` or `after`
      period,

      disabled: !defaultValue,

      // Section selector won't appear if `props.type` is `out`
      sectionId: values.section_id || '',

      // Time input values
      values: {
        before: values.before || SignTimeFilter.defaultTime.before,
        after: values.after || SignTimeFilter.defaultTime.after
      }
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.state, nextState);
  }

  componentDidUpdate() {
    this.handleUpdate();
  }

  @bind
  changePeriod(nextPeriod) {
    this.setState({ period: nextPeriod });
  }

  @bind
  changeDisabled() {
    this.setState({ disabled: !this.state.disabled }, this.handleUpdate);
  }

  @bind
  changeSection(nextSectionId) {
    this.setState({ sectionId: nextSectionId }, this.handleUpdate);
  }

  @bind
  updateTime(periodName) {
    return nextTimeValue => {
      this.setState({
        values: {
          ...this.state.values,
          [periodName]: nextTimeValue
        }
      });
    };
  }

  getValue() {
    const { useRoom } = this.props;
    const { disabled, period, values, sectionId } = this.state;

    if (disabled) {
      return undefined;
    }

    const value = {
      [period]: period === 'never' ? 1 : values[period]
    };

    if (sectionId && useRoom) {
      value.section_id = sectionId;
    }

    return value;
  }

  @bind
  handleUpdate() {
    if (this.props.onChange) {
      this.props.onChange(this.getValue());
    }
  }

  getTitle() {
    const { period, values } = this.state;

    if (period === 'never') {
      return 'Missed';
    }

    return `${capitalize(period)} ${values[period]}`;
  }

  render() {
    const { period, values, disabled } = this.state;
    const { rooms, useRoom, label, 'data-cy': dataCY } = this.props;

    const title = this.getTitle();

    return (
      <div className="sign-time">
        <Checkbox
          data-cy={`${dataCY}-disabled`}
          className="sign-time__checkbox"
          type="square"
          size="small"
          name="disabled"
          label={label}
          checked={!disabled}
          onChange={this.changeDisabled}
        />

        <div className="sign-time-filter">
          <Dropdown disabled={disabled} title={title} className="sign-time-filter__time" onClose={this.handleUpdate}>
            <div className="sign-time-filter__time-row">
              <Checkbox
                type="circle"
                size="small"
                name="before"
                label="Before"
                checked={period === 'before'}
                onChange={() => this.changePeriod('before')}
              />

              <TimeInput
                defaultValue={values.before}
                disabled={period !== 'before'}
                onChange={this.updateTime('before')}
              />
            </div>

            <div className="sign-time-filter__time-row">
              <Checkbox
                type="circle"
                size="small"
                name="after"
                label="After"
                checked={period === 'after'}
                onChange={() => this.changePeriod('after')}
              />

              <TimeInput
                defaultValue={values.after}
                disabled={period !== 'after'}
                onChange={this.updateTime('after')}
              />
            </div>

            <div className="sign-time-filter__time-row">
              <Checkbox
                type="circle"
                size="small"
                label="Missed"
                checked={period === 'never'}
                onChange={() => this.changePeriod('never')}
              />
            </div>
          </Dropdown>

          {useRoom && (
            <SelectGroup
              type="radio"
              title="Select Room"
              className="sign-time-filter__room"
              checked={this.state.sectionId}
              onChange={this.changeSection}
              disabled={disabled}
            >
              <SelectGroup.Item key="any-room" value="" label="Any Room" />
              {rooms.map(section => (
                <SelectGroup.Item key={section.id} value={section.id} label={section.name} />
              ))}
            </SelectGroup>
          )}
        </div>
      </div>
    );
  }
}

export default SignTimeFilter;
