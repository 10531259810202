import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cx from 'classnames';
import { CounterInput, Checkbox, Icon } from 'components';
import withLaunchDarkly from 'hocs/withLaunchDarkly';

class MessageStep extends Component {
  @bind
  onChange(field) {
    return val => {
      this.props.onUpdateForm({ [field]: val });
    };
  }

  @bind
  onUpdateSisoMessage() {
    this.props.onUpdateForm({
      sisoMessage: !this.props.sisoMessage
    });
  }

  @bind
  onUpdateSendSMS() {
    if (!Helpers.checkFeatureAccess('send-sms')) {
      this.props.onClose();
      Helpers.showPremiumModal(true);
      this.props.onUpdateForm({
        sendSMS: false
      });
      return;
    }

    this.props.onUpdateForm({
      sendSMS: !this.props.sendSMS
    });
  }

  renderSMSLabel() {
    const { maxMonthlySms } = this.props;

    return (
      <div className="new-message__form__sms__label">
        <span>
          Send as Emergency SMS Alert (300 characters)
          <br />
          <small>
            Maximum <b>{maxMonthlySms}</b> SMS/month for your plan, contact us for upgrades
          </small>
        </span>
        {!Helpers.checkFeatureAccess('send-sms') && <Icon name="star" size={18} color="#b079c4" />}
      </div>
    );
  }

  render() {
    const { flags, message, sisoMessage, sendSMS, isMessageValid } = this.props;

    return (
      <div className="modal__container new-message__form">
        <div className="form__row">
          <div className={cx('form-input', { 'form-input--invalid': !isMessageValid })}>
            <div className="form-input__field">
              <CounterInput
                multiline
                value={message}
                onChange={this.onChange('message')}
                className="new-message__form__textarea"
                placeholder="Write a message"
                showCounter={flags['online-parent-messaging-counter-rollout'] && sendSMS}
                counter={message.length}
                limit={300}
              />
            </div>
            {!isMessageValid && <div className="form-input__validation-text">Cannot be empty</div>}
          </div>
        </div>

        <div className="form__row new-message__form__sms">
          <Checkbox onChange={this.onUpdateSendSMS} checked={sendSMS} label={this.renderSMSLabel()} />
        </div>

        <div className="form__row">
          <Checkbox
            onChange={this.onUpdateSisoMessage}
            checked={sisoMessage}
            label="Show at Sign In-Out Kiosk (Will prompt parent when students are Signed In-Out)"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  maxMonthlySms: state.currentUser.data.current_school.max_monthly_sms
});

const enhance = compose(withLaunchDarkly, connect(mapStateToProps));
export default enhance(MessageStep);
