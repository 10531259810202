import React from 'react';
import NapCheckTypeform from './NapCheck';

const CheckTypeForm = ({ checkType, value, constants }) => {
  switch (checkType) {
    case 'Nap Check':
      return <NapCheckTypeform value={value} constants={constants} />;
    default:
      return null;
  }
};

export default CheckTypeForm;
