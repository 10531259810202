import React, { Component } from 'react';
import { connect } from 'react-redux';
import Steps from '../ModalController/Steps';
import SelectKids from '../common/SelectKids';
import AmountDueStep from './AmountDueStep';
import './style.scss';

class AddSubsidyInvoice extends Component {
  constructor() {
    super();
    this.state = {
      kids: [],
      amountValues: {},
      isSaving: false
    };
  }

  componentDidMount() {
    req.students();
  }

  @bind
  nextStep() {
    this.steps.goToStep(1);
  }

  @bind
  bindSteps(node) {
    this.steps = node;
  }

  @bind
  updateKids(kids) {
    this.setState({ kids });
  }

  @bind
  updateAmount(kidId, value) {
    this.setState({
      amountValues: {
        ...this.state.amountValues,
        [kidId]: value
      }
    });
  }

  @bind
  async onSave() {
    this.setState({ isSaving: true });
    const {
      data: { subsidy },
      onClose
    } = this.props;
    const { amountValues, kids } = this.state;

    try {
      const invoice = await req.addSubsidyInvoice({
        ledger_id: subsidy.id,
        parent_invoices: kids.map(kidId => ({
          price: Number(amountValues[kidId]),
          kid_id: kidId
        }))
      });
      Actions.showFlash('Invoice successfully created');
      onClose(invoice);
    } catch (error) {
      this.setState({ isSaving: false });
      Actions.reportError('Something went wrong', error);
    }
  }

  render() {
    const { students } = this.props;
    const { kids, amountValues } = this.state;

    return (
      <div className="add-subsidy-invoice">
        <Steps ref={this.bindSteps}>
          <Steps.Item>
            <SelectKids
              onSubmit={this.nextStep}
              onUpdate={this.updateKids}
              students={students}
              defaultSelected={this.state.kids}
            />
          </Steps.Item>

          <Steps.Item>
            <AmountDueStep
              students={students}
              selectedKids={kids}
              values={amountValues}
              onUpdate={this.updateAmount}
              onSave={this.onSave}
              isSaving={this.state.isSaving}
            />
          </Steps.Item>
        </Steps>
      </div>
    );
  }
}

const mapStateToProps = ({ students }) => ({
  students: students.data
});

export default connect(mapStateToProps)(AddSubsidyInvoice);
