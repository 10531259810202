import { getSubscriptionAccessLevel } from 'lib/utils';
import { FORBIDDEN_FEATURES } from 'constants/subscriptionAccess';

// check access on feature level (page level access is handled on route)
const checkFeatureAccess = store => (feature, checkTrial = true) => {
  const state = store.getState();
  const currentSchool = state.currentUser ? state.currentUser.data.current_school : null;

  if (!currentSchool) {
    return true;
  }

  const acc = getSubscriptionAccessLevel(currentSchool, checkTrial);
  return (FORBIDDEN_FEATURES[acc] || []).indexOf(feature) === -1;
};

export default checkFeatureAccess;
