import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import currency from 'lib/currency';
import './style.scss';

function Balance({ value, className, ...rest }) {
  const balanceCN = cx(className, 'balance', {
    'balance--positive': value > 0
  });

  return (
    <div className={balanceCN} {...rest}>
      {currency.getPrice(value)}
    </div>
  );
}

Balance.propTypes = {
  value: PropTypes.number,
  className: PropTypes.string
};

export default Balance;
