import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Header, OrderControls } from 'components';
import EmptyStatePlaceholder from 'components/EmptyStatePlaceholder';
import Row from './Row';
import './style.scss';
import withQuery from 'hocs/withQuery';

function ProgramTable({
  programs,
  includeSchedule = false,
  includeWeekend,
  renderContextMenu,
  querySortData,
  onSortQueryChange
}) {
  const [sort, setSort] = useState({ field: querySortData.field, direction: querySortData.direction });

  const compareFn = (a, b) => (typeof a === 'string' ? a.localeCompare(b) > 0 : a > b);
  const sortFn = (p1, p2) => {
    let a, b;

    switch (sort.field) {
      case 'name':
        a = p1.name;
        b = p2.name;
        break;
      case 'date':
        a = moment(p1.starts_at || '1000-01-01');
        b = moment(p2.starts_at || '1000-01-01');
        break;
      default:
        return 0;
    }

    if (sort.direction === 'asc') {
      [a, b] = [b, a];
    }

    if (compareFn(a, b)) {
      return -1;
    } else {
      return 1;
    }
  };

  const sortedPrograms = React.useMemo(() => {
    if (sort.field) {
      programs.sort(sortFn);
    }
    return programs;
  }, [sort.field, sort.direction]);

  const handleSort = field => {
    const direction = field === sort.field ? (sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';

    onSortQueryChange({ field, direction });
    setSort({ field, direction });
  };

  const getSortDir = field => {
    return field === sort.field ? sort.direction : null;
  };

  if (!programs || !programs.length) {
    return (
      <div className="content-container">
        <EmptyStatePlaceholder
          title="No Programs Available"
          description="Assigning Programs to rooms helps centers recognize which Programs are in the highest demand and what openings are available for enrollment."
          steps={[
            {
              iconName: 'colored-school',
              text: 'Programs allow for more control over enrollment opportunities and tracking at your center.'
            },
            {
              iconName: 'colored-programs',
              text: 'Use Programs to manage offerings, while rooms are used to manage physical locations.'
            },
            {
              iconName: 'colored-rooms',
              text: 'Programs can be assigned to multiple rooms, while rooms can accommodate multiple Programs.'
            }
          ]}
        />
      </div>
    );
  }

  return (
    <div className="content-container">
      <Header className="header--small" title={`Showing ${sortedPrograms.length} results`} />

      <div className="table">
        <div className="table__header">
          <div className="table__cell cursor-pointer" onClick={() => handleSort('name')}>
            Program
            <OrderControls value={getSortDir('name')} />
          </div>

          <div className="table__cell table__cell--s cursor-pointer" onClick={() => handleSort('date')}>
            Dates
            <OrderControls value={getSortDir('date')} />
          </div>

          {includeSchedule && <div className="table__cell table__cell--schedule">Schedule</div>}

          <div className="table__cell table__cell--xxs table__cell--actions" />
        </div>

        <div className="table__body">
          {sortedPrograms.map(p => (
            <Row
              key={p.id}
              program={p}
              includeSchedule={includeSchedule}
              includeWeekend={includeWeekend}
              renderContextMenu={renderContextMenu}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

ProgramTable.propTypes = {
  programs: PropTypes.array,
  includeSchedule: PropTypes.bool,
  includeWeekend: PropTypes.bool,
  renderContextMenu: PropTypes.func
};

export default withQuery('sort', {})(ProgramTable);
