import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tab from './Tab';
import TabViewHeader from './TabViewHeader';
import './style.scss';

class TabView extends Component {
  static Tab = Tab;

  static propTypes = {
    activeTabIndex: PropTypes.number,
    showIndices: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func, // Should return a next tab's index
    'data-cy': PropTypes.string
  };

  static defaultProps = {
    children: []
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTabIndex: 0
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeTabIndex !== prevProps.activeTabIndex) {
      this.setState({ activeTabIndex: this.props.activeTabIndex });
    }
  }

  getTabs() {
    return React.Children.toArray(this.props.children);
  }

  getTabIndex(index) {
    const tabs = React.Children.toArray(this.props.children);

    if (tabs.length === 0) {
      return -1;
    }

    return tabs[index].id;
  }

  getTabContent() {
    return React.Children.toArray(this.props.children)[this.state.activeTabIndex];
  }

  @bind
  handleTabChange(tabIndex) {
    const nextTabIndex = typeof tabIndex === 'string' ? this.getTabIndex(tabIndex) : tabIndex;

    if (this.props.onChange) {
      this.props.onChange(tabIndex);
      return;
    }

    if (
      nextTabIndex === -1 ||
      (nextTabIndex !== this.props.activeTabIndex && this.props.activeTabIndex !== undefined)
    ) {
      return;
    }

    this.setState({ activeTabIndex: nextTabIndex });
  }

  render() {
    const { disabled, showIndices, 'data-cy': dataCY } = this.props;
    const { activeTabIndex } = this.state;

    const className = classNames('tab-view', this.props.className, {
      'tab-view--disable-nav': disabled
    });

    return (
      <div className={className} data-cy={dataCY}>
        <TabViewHeader
          tabs={this.getTabs()}
          activeTabIndex={activeTabIndex}
          showIndices={showIndices}
          disabled={disabled}
          onTabChange={this.handleTabChange}
        />

        <div className="tab-view__tab">{this.getTabContent()}</div>
      </div>
    );
  }
}

export default TabView;
