import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import cx from 'classnames';
import { ButtonV2, Form, InvoiceItemList, Checkbox, ActionButton } from 'components';
import AttendanceItemList from '../AttendanceItems';
import { capitalize } from 'lib/utils';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

const isNotEmpty = value => {
  if (Array.isArray(value)) {
    return value.length > 0 && value.every(Boolean);
  }
  if (typeof value === 'string') {
    return value.trim() !== '';
  }
  return value !== undefined && value !== null;
};

class InvoiceDetails extends Component {
  static propTypes = {
    actionType: PropTypes.oneOf(['create', 'edit']),
    plan: PropTypes.object,
    kind: PropTypes.string,
    billingPlanTemplates: PropTypes.array,
    onUpdate: PropTypes.func,
    onTemplateChange: PropTypes.func,
    kid: PropTypes.object,
    onClose: PropTypes.func
  };

  static defaultProps = {
    actionType: 'create',
    plan: {},
    billingPlanTemplates: []
  };

  constructor(props) {
    super(props);
    this.state = {
      plan: {
        ...props.plan,
        start_date: Array.isArray(props.plan.start_date) ? props.plan.start_date[0] : props.plan.start_date,
        end_date: Array.isArray(props.plan.end_date) ? props.plan.end_date[0] : props.plan.end_date
      },
      info: null,
      selectedBillingCycle: '',
      isSplitFamily: props.kid && props.kid.sub_families
    };
  }

  UNSAFE_componentWillMount() {
    this.updateBillingInfo(this.state.plan);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.templateId !== this.props.templateId || prevProps.kind !== this.props.kind) {
      this.setState({ plan: { ...this.props.plan } });
      this.updateBillingInfo(this.props.plan);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.templateId !== this.props.templateId || nextProps.kind !== this.props.kind) {
      this.setState({ plan: { ...nextProps.plan } });
      this.updateBillingInfo(nextProps.plan);
    }
  }

  @bind
  saveAsTemplate() {
    this.props.onUpdate(this.state.plan, () => this.props.onSubmit(true));
  }

  @bind
  save() {
    if (this.props.isTemplate) {
      this.props.onUpdate(this.state.plan, () => this.props.onSubmit(true));
      return;
    }

    if (this.props.actionType === 'create') {
      this.props.onUpdate(this.state.plan);
      this.props.context.nextStep();
    } else if (this.props.actionType === 'edit') {
      this.props.onUpdate(this.state.plan, this.props.onSubmit);
    }
  }

  @bind
  update(values) {
    const newPlan = { ...this.state.plan, ...values };
    const invoiceDateChanged = values.invoice_date !== this.state.plan.invoice_date;

    if (values.template_id !== this.state.plan.template_id) {
      this.props.onTemplateChange(values.template_id);
    }

    if (values.cycle !== this.state.plan.cycle) {
      if (values.cycle === 'monthly') {
        newPlan.start_date = moment()
          .subtract(1, 'month')
          .startOf('month')
          .toDate();
        newPlan.end_date = undefined;
      } else {
        newPlan.start_date = moment()
          .subtract(1, 'month')
          .startOf('month')
          .toDate();
        newPlan.end_date = undefined;
      }
    }

    this.setState({ plan: newPlan }, () => {
      this.props.onUpdate(this.state.plan);

      if (invoiceDateChanged) {
        this.form.updateField('due_date', newPlan.invoice_date);
      }
    });

    this.updateBillingInfo(newPlan);
  }

  @bind
  @debounce({ delay: 500 })
  updateBillingInfo(plan) {
    if (
      plan.cycle &&
      (plan.due_date || plan.due_date === 0) &&
      plan.start_date[0] &&
      (plan.invoice_date || plan.invoice_date === 0)
    ) {
      const startDate = plan.start_date[0];
      const endDate = plan.end_date ? plan.end_date[plan.end_date.length - 1] : undefined;

      req
        .recurringPlanInfo({
          billing_plan: {
            name: plan.name || 'any name',
            kind: plan.kind,
            cycle: plan.cycle,
            due_date: plan.due_date,
            invoice_date: plan.invoice_date,
            invoice_cycle: plan.invoice_cycle || 'previous',
            start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
            end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined
          }
        })
        .then(({ billing_plan: info }) => {
          this.setState({ info });
        })
        .catch(() => {
          // This handles error 422 which appears if not fields are filled
          this.setState({ info: null });
        });
    } else {
      this.setState({ info: null });
    }
  }

  @bind
  updateInvoiceItems(items) {
    this.setState(
      prevState => ({
        plan: {
          ...prevState.plan,
          plan_invoice_items_attributes: items
        }
      }),
      () => {
        this.props.onUpdate(this.state.plan);
      }
    );
  }

  @bind
  updateChargeItems(items) {
    this.setState(
      prevState => ({
        plan: {
          ...prevState.plan,
          plan_charge_items_attributes: items
        }
      }),
      () => {
        this.props.onUpdate(this.state.plan);
      }
    );
  }

  @bind
  onUpdateAutoPost(value) {
    this.setState(prevState => ({
      plan: {
        ...prevState.plan,
        auto_post: value
      }
    }));
  }

  renderDueDateItems(fromDate, tillDate, cycle) {
    const items = [];

    for (let i = fromDate; i <= tillDate; i++) {
      let label;

      if (cycle !== 'monthly') {
        label = moment.weekdays(i);
      } else {
        label = i === -1 ? 'Last day' : moment(i, 'D').format('Do') + (i < 0 ? ' last day' : ' day');
      }

      items.push(<Form.Select.Item key={`${cycle}-item-${i}`} value={i > 0 ? i : i + 1} label={label} />);
    }

    return items;
  }

  getInvoiceDateItems() {
    const { cycle } = this.state.plan;

    switch (cycle) {
      case 'weekly':
      case 'biweekly':
        return this.renderDueDateItems(1, 7, cycle);

      case 'monthly':
        return [
          ...this.renderDueDateItems(1, 10, cycle),
          ...this.renderDueDateItems(15, 15, cycle),
          ...this.renderDueDateItems(-10, -1, cycle)
        ];

      default:
        return [];
    }
  }

  getDueDateItems() {
    const { cycle, invoice_date } = this.state.plan;
    const dueArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1];
    const dueIndex = dueArray.indexOf(invoice_date);

    switch (cycle) {
      case 'weekly':
      case 'biweekly':
        return this.renderDueDateItems(1, 7, cycle).slice(dueIndex);

      case 'monthly':
        return [
          ...this.renderDueDateItems(1, 10, cycle),
          ...this.renderDueDateItems(15, 15, cycle),
          ...this.renderDueDateItems(-10, -1, cycle)
        ].slice(dueIndex);

      default:
        return [];
    }
  }

  isValid() {
    const { plan, isSplitFamily } = this.state;
    const { kind } = this.props;

    const itemArrayName = `plan_${kind === 'tuition' ? 'invoice' : 'charge'}_items_attributes`;
    const isItemsNotEmpty = plan[itemArrayName] && plan[itemArrayName].filter(item => !item._destroy).length > 0;
    const allFieldsFilled = Object.keys(plan).every(
      key => key === 'end_date' || (key === 'sub_family_id' && !isSplitFamily) || isNotEmpty(plan[key])
    );
    const allItemsFilled =
      kind === 'attendance' ||
      plan.plan_invoice_items_attributes.every(item => item.description && (item.price || item.price === 0));
    return isItemsNotEmpty && allFieldsFilled && allItemsFilled;
  }

  getDefaultInvoiceDate() {
    const { cycle, invoice_date } = this.state.plan;

    if (cycle !== 'monthly' && (invoice_date < 1 || invoice_date > 7)) {
      return 1;
    }

    return invoice_date === null || invoice_date === undefined || invoice_date === '' ? 1 : invoice_date;
  }

  disableStartDate(day, endDate) {
    const momentDay = moment(day);
    return endDate instanceof Array && endDate[0] ? momentDay.isAfter(endDate[0]) : false;
  }

  disableEndDate(day, startDate) {
    const momentDay = moment(day);
    return startDate instanceof Array && startDate[1] ? momentDay.isBefore(startDate[1]) : false;
  }

  getDateString(date) {
    return moment(date).format('MMM D, YYYY');
  }

  render() {
    const {
      actionType,
      billingPlanTemplates,
      templateId,
      step,
      totalSteps,
      isTemplate,
      isAssignTemplate,
      groupEntity,
      franchiseBilling,
      kid
    } = this.props;
    const {
      name,
      due_date,
      start_date,
      end_date,
      invoice_cycle,
      auto_post,
      cycle,
      plan_invoice_items_attributes,
      plan_charge_items_attributes,
      sub_family_id
    } = this.state.plan;
    const { info, isSplitFamily } = this.state;

    const kind = this.props.plan.kind || this.props.kind;
    const templateGroupEntity = this.props.plan.group_entity;
    const templates = billingPlanTemplates.filter(template => template.kind === kind);
    const disableForEditing = isTemplate ? false : actionType === 'edit';
    const UPDATE_PLAN_WARNING =
      'Updating plan template does not automatically update recurring plans for already assigned students. Will need to be reassigned to students.';
    let buttonLabel = '';

    if (isTemplate) {
      buttonLabel = actionType === 'edit' ? 'Save Template' : 'Create Template';
    } else {
      buttonLabel = actionType === 'create' ? 'Continue' : 'Save';
    }

    const planItems = templates.map(template => (
      <Form.Select.Item key={template.id} value={template.id} label={template.name} />
    ));

    if (!franchiseBilling) {
      planItems.unshift(
        <Form.Select.Item
          value="other"
          label="New plan"
          className="select-group__item--accent"
          icon="add-blue"
          iconType="svg"
          iconSize={14}
        >
          Create New Plan
        </Form.Select.Item>
      );
    }

    const planDefaultValue = franchiseBilling ? templateId : templateId || 'other';
    const planFieldDisabled = (!templateId && !isTemplate) || templateGroupEntity || franchiseBilling;

    return (
      <div className="modal__wrapper create-billing-plan__details">
        <div className="modal__header">
          {actionType !== 'edit' && (
            <ActionButton
              iconName="back"
              className="create-billing-plan__back-button"
              onClick={this.props.context.prevStep}
            />
          )}

          <div className="modal__header-title">
            {capitalize(isAssignTemplate ? 'assign' : actionType)} {capitalize(kind)} {isTemplate ? 'Template' : 'Plan'}{' '}
            {kid && kid.name ? `- ${kid.name}` : null}
          </div>

          {actionType === 'create' && !isTemplate && (
            <div className="modal__header-steps">
              Step {step} / {totalSteps}
            </div>
          )}
        </div>

        <Form
          className="modal__container create-billing-plan__form"
          onInit={this.update}
          onChange={this.update}
          key={this.props.templateId}
          ref={node => (this.form = node)}
        >
          {isSplitFamily && (
            <div className="form__row create-billing-plan__choose-subfamily">
              <Form.Select
                className="create-billing-plan__choose-subfamily-select"
                name="sub_family_id"
                title="Choose Family"
                defaultValue={sub_family_id}
                label="Choose Family"
                required
              >
                {kid.sub_families.map(sf => (
                  <Form.Select.Item key={sf.id} value={sf.id} label={sf.name} />
                ))}
              </Form.Select>
            </div>
          )}
          <div className="form__row form__row--justified">
            {!isTemplate && (
              <Form.Select
                name="template_id"
                label={franchiseBilling ? 'Pick Template' : 'Create New or Pick Template'}
                title={franchiseBilling ? 'Pick Template' : 'Create New or Pick Template'}
                type="radio"
                className={cx('create-billing-plan__template', {
                  'create-billing-plan__template--other': templateId || templateId === 'other'
                })}
                defaultValue={planDefaultValue}
                disabled={disableForEditing}
              >
                {planItems}
              </Form.Select>
            )}

            <Form.Input
              className="create-billing-plan__name"
              name="name"
              defaultValue={name}
              label="Plan Name"
              placeholder="Enter Plan Name"
              disabled={planFieldDisabled}
            />
          </div>

          <div className="form__row create-billing-plan__dates">
            <Form.Select
              name="cycle"
              label="Plan Cycle"
              title="Plan Cycle"
              className="create-billing-plan__plan-cycle"
              defaultValue={cycle}
              disabled={disableForEditing || planFieldDisabled}
            >
              <Form.Select.Item value="weekly" label="Weekly" />
              <Form.Select.Item value="biweekly" label="Bi-Weekly" />
              <Form.Select.Item value="monthly" label="Monthly" />
            </Form.Select>

            {cycle === 'monthly' ? (
              <Form.MonthPicker
                name="start_date"
                label="Plan Start"
                defaultValue={start_date}
                allowUnmount={false}
                required
              />
            ) : (
              <Form.DatePicker
                label="Plan Start"
                name="start_date"
                key={`${cycle}_start_date`}
                type={cycle || 'weekly'}
                defaultValue={start_date}
                disableDays={day => this.disableStartDate(day, end_date)}
                allowUnmount={false}
                required
              />
            )}

            {cycle === 'monthly' ? (
              <Form.MonthPicker
                name="end_date"
                label="Plan End (optional)"
                defaultValue={end_date}
                allowUnmount={false}
                clearable
              />
            ) : (
              <Form.DatePicker
                label="Plan End (optional)"
                key={`${cycle}_end_date`}
                name="end_date"
                type={cycle || 'weekly'}
                defaultValue={end_date}
                disableDays={day => this.disableEndDate(day, start_date)}
                allowUnmount={false}
                clearable
              />
            )}
          </div>

          <div className="row">
            <span>Generate Invoice on</span>

            <Form.Select
              key={cycle}
              className="side-margin"
              name="invoice_date"
              title="Invoice date"
              defaultValue={this.getDefaultInvoiceDate()}
              disabled={planFieldDisabled || due_date === undefined || disableForEditing}
              allowUnmount={false}
            >
              {this.getInvoiceDateItems()}
            </Form.Select>

            <span>, due on</span>

            <Form.Select
              name="due_date"
              className="side-margin"
              defaultValue={due_date === undefined ? 1 : due_date}
              title="Due Date"
              disabled={disableForEditing || (!templateId && !isTemplate)}
            >
              {this.getDueDateItems()}
            </Form.Select>

            <span>for</span>

            {kind === 'attendance' ? (
              <span>&nbsp;previous&nbsp;</span>
            ) : (
              <Form.Select
                name="invoice_cycle"
                defaultValue={invoice_cycle || 'previous'}
                className="create-billing-plan__cycle side-margin"
                disabled={disableForEditing || templateGroupEntity || franchiseBilling}
              >
                <Form.Select.Item value="current" label="Current" />
                <Form.Select.Item value="previous" label="Previous" />
                <Form.Select.Item value="next" label="Next" />
              </Form.Select>
            )}

            <span>billing cycle.</span>
          </div>

          {info && (
            <div className="create-billing-plan__info">
              Your first invoice will be generated on <b>{this.getDateString(info.next_invoice_date)}</b> due on{' '}
              <b>{this.getDateString(info.next_due_date)}</b> for the period of{' '}
              <b>{this.getDateString(info.cycle_start_date)}</b> to <b>{this.getDateString(info.cycle_end_date)}</b>.
            </div>
          )}
        </Form>

        <div className="create-billing-plan__details-items">
          {kind === 'tuition' ? (
            <div className="create-billing-plan__details-inner">
              <div className="create-billing-plan__items-header">Invoice Details</div>

              <InvoiceItemList
                type={actionType}
                data={plan_invoice_items_attributes}
                templateGroupEntity={templateGroupEntity}
                onUpdate={this.updateInvoiceItems}
                onManagePresets={this.props.onManagePresets}
                onClose={this.props.onClose}
              />
            </div>
          ) : (
            <div className="create-billing-plan__details-inner">
              <AttendanceItemList
                actionType={actionType}
                data={plan_charge_items_attributes}
                onUpdate={this.updateChargeItems}
              />
            </div>
          )}

          {!isTemplate && actionType !== 'create' && (
            <div className="create-billing-plan__auto-post">
              <Checkbox
                label="Send Invoice to parent automatically on each billing cycle"
                checked={auto_post}
                type="circle"
                onChange={this.onUpdateAutoPost}
              />
            </div>
          )}
        </div>

        {isTemplate && actionType === 'edit' && (
          <div className="create-billing-plan__warning">{UPDATE_PLAN_WARNING}</div>
        )}

        <div className="modal__controls">
          {!isTemplate && actionType === 'create' && !groupEntity && !franchiseBilling && (
            <ButtonV2
              secondary
              label="Save As Template"
              onClick={this.saveAsTemplate}
              disabled={!this.isValid()}
              isLoading={this.props.isLoading}
            />
          )}

          {(!groupEntity || buttonLabel === 'Continue') && (
            <ButtonV2
              label={buttonLabel}
              onClick={this.save}
              disabled={!this.isValid()}
              isLoading={this.props.isLoading}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  billingPlanTemplates: state.billingPlanTemplates.data,
  franchiseBilling: state.currentUser.data.current_school.franchise_billing_enabled
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(InvoiceDetails);
