import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PreviewForm, ButtonV2 } from 'components';
import './style.scss';

class ReceivePayment extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  static defaultProps = {
    data: {
      rowsA: [],
      rowsB: []
    }
  };

  constructor() {
    super();

    this.state = {
      formValues: {}
    };
  }

  @bind
  handlechange(values) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        values
      }
    });
  }

  @bind
  onClose() {
    this.props.onClose();
  }

  render() {
    const { rowsA, rowsB } = this.props.data;

    return (
      <div className="preview-form-modal">
        <div className="modal__header">
          <div className="subheader">Preview Form</div>
        </div>
        <PreviewForm rowsA={rowsA} rowsB={rowsB} onChange={this.handleChange} className="modal__container">
          <div className="form__row form__row--actions">
            <ButtonV2 label="Close" onClick={this.onClose} />
          </div>
        </PreviewForm>
      </div>
    );
  }
}

export default ReceivePayment;
