import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, ActionButton } from 'components';

class Item extends Component {
  static propTypes = {
    item: PropTypes.object,
    onAdd: PropTypes.func,
    onChange: PropTypes.func,
    onDelete: PropTypes.func
  };
  static defaultProps = {
    item: {}
  };

  @bind
  handleAdd() {
    if (this.props.onAdd) {
      this.props.onAdd();
    }
  }

  @bind
  handleChange(label) {
    const { item, onChange } = this.props;

    if (onChange) {
      onChange(item.value, label);
    }
  }

  @bind
  handleDelete() {
    if (this.props.onDelete) {
      this.props.onDelete(this.props.item.value);
    }
  }

  render() {
    const { item, isLast, disabled } = this.props;
    const isAddDisabled = !item.label;

    return (
      <div className="form-field__item-list__item">
        <TextInput placeholder="Choice name" value={item.label} onChange={this.handleChange} disabled={disabled} />
        {isLast ? (
          <ActionButton
            className="form-field__item-list__add-btn"
            iconName="add-blue"
            onClick={this.handleAdd}
            disabled={isAddDisabled || disabled}
          />
        ) : (
          <ActionButton
            key={isLast}
            iconName="close-circle"
            size={16}
            className="form-field__item-list__remove-btn"
            onClick={this.handleDelete}
            disabled={disabled}
          />
        )}
      </div>
    );
  }
}

export default Item;
