import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, ActionButton } from 'components';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

export class Step2Ach extends Component {
  static propTypes = {
    onSubmit: PropTypes.func
  };

  constructor(...args) {
    super(...args);
    this.state = {
      isLoading: false
    };
  }

  @bind
  handleSubmit(data) {
    const payload = {
      PAYMENT_TYPE: 'ACH',
      ACH_ROUTING: data.ach_routing,
      ACH_ACCOUNT: data.ach_account,
      NAME1: data.first_name,
      NAME2: data.last_name
    };
    const { getBluepayToken, onSubmit, autopayEnabled } = this.props;

    this.setState({ isLoading: true });
    return getBluepayToken(payload)
      .then(token => {
        if (onSubmit) {
          return onSubmit({
            token,
            token_type: 'bank_account',
            last4: payload.ACH_ACCOUNT.substr(payload.ACH_ACCOUNT.length - 4)
          });
        }

        return req
          .addPaymentMethod({
            token,
            token_type: 'bank_account',
            last4: payload.ACH_ACCOUNT.substr(payload.ACH_ACCOUNT.length - 4),
            auto_pay: autopayEnabled === true
          })
          .then(() => {
            Actions.showFlash('Ach added successfully');
            this.setState({ isLoading: false });
            this.props.goToList();
          });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        Actions.reportError('There was problem with adding payment method', err);
      });
  }

  render() {
    const { prevStep } = this.props.context;
    const { isLoading } = this.state;

    return (
      <Form className="table payments-panel" role="form" onSubmit={this.handleSubmit} isLoading={isLoading}>
        <div className="table__header payments-panel__header">
          <ActionButton iconName="back" size={20} onClick={prevStep} />
          <div className="payments-panel__title">Add Ach Account</div>
        </div>

        <div className="table__body">
          <div className="table__row payments-panel__body">
            <div className="payments-panel__content">
              <div className="form__row form__row--justified">
                <Form.Input name="first_name" placeholder="First Name" label="First Name" required />

                <Form.Input name="last_name" placeholder="Last Name" label="Last Name" required />
              </div>

              <div className="form__row form__row--justified">
                <Form.Input placeholder="Account Number" label="Account Number" name="ach_account" required />
                <Form.Input label="Routing Number" placeholder="Routing Number" name="ach_routing" required />
              </div>
            </div>
          </div>

          <div className="table__row table__row--footer payments-panel__footer">
            <Form.Submit className="button--large" label="Add Ach Account" />
          </div>
        </div>
      </Form>
    );
  }
}

export default withContext(ModalControllerStepsContext)(Step2Ach);
