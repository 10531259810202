import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextInput, SelectGroup } from 'components';

class SchoolFilter extends PureComponent {
  static propTypes = {
    filters: PropTypes.shape({
      search: PropTypes.string,
      selectedStates: PropTypes.arrayOf(PropTypes.string)
    }),
    stateList: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func
  };

  handleUpdate(partialUpdateObj) {
    if (this.props.onChange) {
      this.props.onChange({ ...this.props.filters, ...partialUpdateObj });
    }
  }

  @bind
  updateSearch(search) {
    this.handleUpdate({ search });
  }

  @bind
  updateStates(selectedStates) {
    this.handleUpdate({ selectedStates });
  }

  render() {
    const { stateList } = this.props;
    const { search, selectedStates } = this.props.filters;

    return (
      <div className="form--inline">
        <div className="w-1/2 input--search">
          <div className="form-input__field">
            <TextInput placeholder="Search Schools" value={search} onChange={this.updateSearch} />
          </div>
        </div>

        {stateList && (
          <SelectGroup
            type="checkbox"
            title="All States"
            className="w-1/4"
            multipleSelectionText="states selected"
            checked={selectedStates}
            onChange={this.updateStates}
          >
            {stateList.sort().map(state => (
              <SelectGroup.Item key={state} className="school-filter__select-group-item" value={state} label={state} />
            ))}
          </SelectGroup>
        )}
      </div>
    );
  }
}

export default SchoolFilter;
