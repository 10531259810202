import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form } from 'components';

class EditStaffClockIn extends Component {
  static propTypes = {
    staff: PropTypes.array,
    rooms: PropTypes.array,
    onClose: PropTypes.func,
    isLoading: PropTypes.bool,
    data: PropTypes.shape({
      teacher: PropTypes.object,
      sisoData: PropTypes.object,
      // reducer name whose `loading` param disables submit button & which updated on .then()
      action: PropTypes.oneOf(['sisoTeachersSignins', 'sisoTeachersDaily']),
      // params for action
      params: PropTypes.object
    })
  };

  constructor(...args) {
    super(...args);
    this.state = {
      isLoading: false
    };
  }

  @bind
  getMessage(msg) {
    const { gusto } = this.props;

    if (gusto.enabled) {
      return `${msg} and synced to Gusto`;
    }

    return msg;
  }

  @bind
  submit(values) {
    const { data } = this.props;
    const date = moment(values.date).format('YYYY-MM-DD');

    const params = {
      siso_teacher: {
        teacher_id: values.staffId,
        section_id: values.roomId,
        sign_in_time: `${date} ${values.sign_in_time}`,
        sign_out_time: values.sign_out_time ? `${date} ${values.sign_out_time}` : undefined,
        note: values.note
      }
    };

    this.setState({ isLoading: true });
    if (!data.teacher) {
      // create clock-in
      req
        .createClockIn(params)
        .then(() => {
          this.setState({ isLoading: false });
          Actions.showFlash(this.getMessage('Staff clock-in created'));
          this.onClose(values.date);
        })
        .catch(err => {
          Actions.reportError('Unable to create staff clock-in', err);
          this.setState({ isLoading: false });
        });
    } else {
      // edit clock-in (on Daily student attendance / staff timecard and Individual attendance / staff timecard)
      params.id = data.sisoData.id;
      req
        .editStaffClockIn(params)
        .then(() => {
          this.setState({ isLoading: false });
          Actions.showFlash(this.getMessage('Staff clock-in updated'));
          return this.onClose(data.teacher.id);
        })
        .catch(err => {
          Actions.reportError('Unable to update staff clock-in', err);
          this.setState({ isLoading: false });
        });
    }
  }

  @bind
  onClose(data) {
    const { onClose } = this.props;
    onClose(data);
  }

  render() {
    const { data, staff, rooms, isLoading } = this.props;
    const clockInDate = data.teacher
      ? data.sisoData && moment(data.sisoData.sign_in_time).toDate()
      : data.params
      ? moment(data.params.report_date).toDate()
      : new Date();
    const signInTime = data.teacher
      ? data.sisoData && Helpers.formatTime(data.sisoData.sign_in_time)
      : Helpers.formatTime(new Date());
    const signOutTime = data.teacher ? data.sisoData && Helpers.formatTime(data.sisoData.sign_out_time) : null;

    return (
      <div>
        <div className="modal__header">
          <span className="modal__header-title">{data.teacher ? 'Edit' : 'Create'} Clock-In</span>
        </div>

        <div className="modal__container">
          <Form validateOn="submit" onSubmit={this.submit} isLoading={this.state.isLoading}>
            <div className="form__row">
              <Form.Select
                name="staffId"
                title="Select Staff"
                defaultValue={(data.teacher && data.teacher.id) || null}
                disabled={Boolean(data.teacher)}
                required
                data-cy="staffId"
              >
                {staff.map(r => (
                  <Form.Select.Item key={r.id} label={r.name} value={r.id} />
                ))}
              </Form.Select>
            </div>

            <div className="form__row">
              <Form.Select name="roomId" title="Select Room" defaultValue={data.sisoData && data.sisoData.section_id}>
                {rooms.map(room => (
                  <Form.Select.Item key={room.id} value={room.id} label={room.name} />
                ))}
              </Form.Select>
            </div>

            <div className="form__row">
              <Form.DatePicker
                name="date"
                disabled={Boolean(data.teacher)}
                defaultValue={clockInDate}
                disableFuture
                required
              />
            </div>

            <div className="form__row form__row--justified">
              <Form.TimeInput name="sign_in_time" defaultValue={signInTime} required autoPeriod />
              <span className="form__row-gap">to</span>
              <div style={{ textAlign: 'right', flexBasis: '50%' }}>
                <Form.TimeInput
                  name="sign_out_time"
                  defaultValue={signOutTime}
                  placeholder="12:00"
                  isAfter="sign_in_time"
                  autoPeriod
                />
              </div>
            </div>

            <div className="form__row">
              <Form.Textarea name="note" defaultValue={data.sisoData && data.sisoData.note} placeholder="Note" />
            </div>

            <div className="form__row form__row--actions">
              <Form.Submit data-cy="submit" label="Save changes" disabled={isLoading} />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  gusto: state.gusto.data,
  staff: state.staff.data,
  rooms: state.rooms.data,
  isLoading: state.sisoTeachersDaily.loading || state.sisoTeachersSignins.loading
});

export default connect(mapStateToProps)(EditStaffClockIn);
