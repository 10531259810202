import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { getOverlappingEvents, getEventOrder } from './utils';
import './EventItem.scss';

const SLOT_SIZE = 16;
const MINUTE_SIZE = (SLOT_SIZE * 4) / 60;

function EventItem({ event, allEvents, onClick }) {
  const { starts_at: startDate, ends_at: endDate, kind, title } = event;

  const overlaps = getOverlappingEvents(event, allEvents);
  const order = getEventOrder(event, overlaps);
  const startOfDay = moment(startDate).startOf('day');

  const width = 100 / overlaps.length;
  const height = moment(endDate).diff(startDate, 'minute') * MINUTE_SIZE;
  const diff = moment(startDate).diff(startOfDay, 'minute');
  const top = MINUTE_SIZE * diff;
  const className = cx('weekly-calendar__event', `weekly-calendar__event--${kind}`, {
    'weekly-calendar__event--last': overlaps.length - 1 === order,
    'weekly-calendar__event--inactive': event.inactive
  });

  const style = {
    width: `${width}%`,
    left: `${order * width}%`,
    top,
    height
  };

  return (
    <div className={className} style={style} onClick={onClick}>
      {title}
    </div>
  );
}

EventItem.propTypes = {
  onClick: PropTypes.func,
  event: PropTypes.object,
  allEvents: PropTypes.array
};

export default EventItem;
