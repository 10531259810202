import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { batchActions } from 'redux-batched-actions';
import Scrollbars from 'react-custom-scrollbars';
import debounce from 'lodash/debounce';
import { TextInput, Icon, Preloader } from 'components';
import Row from './Row';
import './style.scss';

class SelectSchool extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      schools: [],
      changingId: null,
      loading: false
    };

    this.debouncedSearch = debounce(this.searchSchools, 500);
  }

  componentDidMount() {
    this.searchSchools();
  }

  @bind
  updateSearch(search) {
    this.setState({ search }, this.debouncedSearch);
  }

  @bind
  async searchSchools() {
    const { search } = this.state;
    const {
      currentRole,
      data: { isSwitch }
    } = this.props;

    this.setState({ loading: true });

    try {
      const resp = await (isSwitch
        ? req.listSchools({ filters: { school: { name: search || undefined } } })
        : req.groupSchools({ filters: { school: { name: search || undefined } } }));
      this.setState({
        loading: false,
        schools: isSwitch ? resp[`${currentRole}_schools`] : resp.group_schools
      });
    } catch (e) {
      this.setState({ loading: false });
      Actions.reportError('There was problem loading schools', e);
    }
  }

  @bind
  selectSchool(school) {
    const { changingId } = this.state;
    const {
      data: { isSwitch },
      onClose
    } = this.props;

    if (changingId) return;

    if (isSwitch) {
      this.switchSchool(school.id);
    } else {
      onClose(school);
    }
  }

  @bind
  async switchSchool(schoolId) {
    const { currentRole, replaceUser, onClose } = this.props;

    this.setState({ changingId: schoolId });

    try {
      const res = await req.changeSchool({ auth_for: currentRole, school_id: schoolId });
      this.props.history.push('/dashboard');
      replaceUser(res);
      onClose();
    } catch (e) {
      Actions.reportError('Unable to switch school', e);
      this.setState({ changingId: false });
    }
  }

  renderRows() {
    const {
      data: { currentSchool }
    } = this.props;
    const { schools, changingId } = this.state;

    return schools.map(s => (
      <Row
        key={s.id}
        school={s}
        onClick={this.selectSchool}
        isCurrent={currentSchool.id === s.id}
        loading={changingId === s.id}
      />
    ));
  }

  render() {
    const { loading } = this.state;
    const {
      data: { title }
    } = this.props;
    return (
      <div className="select-school">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">{title || 'Select School'}</div>
        </div>

        <div className="select-school__search">
          <TextInput className="select-school__search-input" placeholder="Search School" onChange={this.updateSearch} />
          <Icon name="search" className="select-school__search-icon" />
        </div>

        <Scrollbars autoHeight autoHeightMax={320}>
          <div className="select-school__list">
            {loading ? <Preloader className="select-school__loading" /> : this.renderRows()}
          </div>
        </Scrollbars>
      </div>
    );
  }
}

const mapStateToProps = ({ currentUser }) => ({
  currentRole: currentUser.data.current_role
});

const mapDispatchToProps = dispatch => ({
  replaceUser: user =>
    dispatch(
      batchActions([
        { type: 'REDUX_STORE_RESET', payload: { exclude: ['CONSTANTS', 'CURRENT_USER'] } },
        { type: 'CURRENT_USER_SET', payload: user }
      ])
    )
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectSchool));
