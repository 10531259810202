export default {
  state_unpaid: {
    text: 'Unpaid',
    icon: 'warning'
  },
  state_paid: {
    text: 'Paid',
    icon: 'success'
  },
  state_cancelled: {
    text: 'Cancelled',
    icon: 'cancel'
  },
  state_under_process: {
    text: 'In Process',
    icon: 'pending'
  },
  state_success: {
    text: 'Success',
    icon: 'success'
  },
  state_failed: {
    text: 'Failed',
    icon: 'alert'
  },
  'Not Sent': {
    icon: 'warning'
  },
  Sent: {
    icon: 'success'
  },
  Cancelled: {
    icon: 'cancel'
  }
};
