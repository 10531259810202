import React, { Component } from 'react';
import ScrollBars from 'react-custom-scrollbars';
import moment from 'moment';
import currency from 'lib/currency';
import { ActionButton } from 'components';
import { getDuration } from 'lib/utils';
import './style.scss';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class Step2 extends Component {
  @bind
  renderBreakdown() {
    const { time_breakdown: timeBreakdown } = this.props.breakdown.attendance;
    const rows = [];

    Object.keys(timeBreakdown).forEach(day => {
      timeBreakdown[day].forEach((breakdown, index) => {
        rows.push(
          <div className="table__row" key={`price-breakdown-${day}-${index}`}>
            <div className="table__cell table__cell--xs show-invoice__breakdown-date">
              {moment(day).format('MMM D, YYYY (ddd)')}
            </div>
            <div className="table__cell table__cell--xs">{Helpers.formatTime(breakdown.in)}</div>
            <div className="table__cell table__cell--xs">{Helpers.formatTime(breakdown.out)}</div>
            <div className="table__cell table__cell--xs show-invoice__breakdown-duration">
              {getDuration(breakdown.attended)}
            </div>
            <div className="table__cell table__cell--xs show-invoice__breakdown-duration">
              {getDuration(breakdown.chargeable)}
            </div>
          </div>
        );
      });
    });

    return rows;
  }

  render() {
    const { breakdown } = this.props;
    const rateType = breakdown.rate_type === 'hourly' ? 'hr' : 'day';

    return (
      <div className="show-invoice">
        <div className="modal__header">
          <ActionButton iconName="back" size={20} onClick={this.props.context.prevStep} />
          <span className="modal__header-title">
            {breakdown.quantity} {rateType}
            {breakdown.quantity > 1 ? 's' : ''} @ {currency.getSymbol()}
            {breakdown.price}/{rateType}
          </span>
        </div>

        <div className="modal__container">
          <div className="table__body show-invoice__breakdown">
            <div className="table__row show-invoice__breakdown-headrow">
              <div className="table__cell table__cell--xs">Date</div>

              <div className="table__cell table__cell--xs">Sign-In</div>

              <div className="table__cell table__cell--xs">Sign-Out</div>

              <div className="table__cell table__cell--xs">Attended Hrs</div>

              <div className="table__cell table__cell--xs">Charged Hrs</div>
            </div>
            <ScrollBars autoHeight autoHeightMin={250} autoHeightMax={350}>
              {this.renderBreakdown()}
            </ScrollBars>
          </div>
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(Step2);
