import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import './style.scss';

function Timestamp({ timestamp, showDate = true, alwaysShowYear = false, className, displayType }) {
  const date = moment(timestamp);
  const today = moment();
  let timeString = Helpers.formatTime(timestamp);
  let dateString = '';

  switch (true) {
    case !alwaysShowYear && today.diff(date) < 0: // today
      dateString = 'Today';
      break;
    case !alwaysShowYear && today.startOf('year').diff(date) < 0: // this year
      dateString = date.format('MMM D');
      break;
    default:
      dateString = date.format('MMM D, YYYY');
  }

  if (displayType === 'cell') {
    dateString = date.format('MMM D, YYYY');
  }

  const timestampCN = cx('timestamp', className, {
    [`timestamp--${displayType}`]: !!displayType
  });

  return (
    <div className={timestampCN}>
      {showDate && <div className="timestamp__date">{dateString}</div>}
      <div className="timestamp__time">{timeString}</div>
    </div>
  );
}

Timestamp.propTypes = {
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  displayType: PropTypes.string,
  className: PropTypes.string,
  showDate: PropTypes.bool,
  alwaysShowYear: PropTypes.bool
};

export default Timestamp;
