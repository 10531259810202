import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';

class ProgressItem extends PureComponent {
  static propTypes = {
    item: PropTypes.object,
    isEdit: PropTypes.bool,
    onChange: PropTypes.func
  };

  static defaultProps = {
    item: {}
  };

  renderEdit() {
    const { item, onChange } = this.props;

    return (
      <Form onChange={onChange}>
        <Form.Input name="name" placeholder="Progress name" defaultValue={item.name} />
      </Form>
    );
  }

  renderShow() {
    return this.props.item.name;
  }

  render() {
    return <div className="manage-progress-next-item">{this.props.isEdit ? this.renderEdit() : this.renderShow()}</div>;
  }
}

export default ProgressItem;
