import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { identifyLDSchool } from 'lib/launchDarkly';

let localFeatureFlags = {};
let LAUNCH_DARKLY_OFFLINE = false;

if (process.env.NODE_ENV === 'development' && process.env.LAUNCH_DARKLY_OFFLINE === 'true') {
  try {
    localFeatureFlags = require('/featureFlags.json');
    LAUNCH_DARKLY_OFFLINE = Object.keys(localFeatureFlags).length > 0;
  } catch (error) {
    console.warn('Local feature flags file not found or invalid. Falling back to LaunchDarkly.');
  }
}

const useLaunchDarkly = () => {
  const dispatch = useDispatch();
  const LDClient = useLDClient();
  const currentUser = useSelector(state => state.currentUser.data);
  const ldSchoolId = useSelector(state => state.launchDarkly.currentSchoolId);
  const currentSchoolId = currentUser?.current_school?.id;

  if (currentSchoolId !== ldSchoolId) {
    identifyLDSchool(LDClient, currentUser);
    const flags = useFlags();
    dispatch({ type: 'LAUNCHDARKLY_SET', payload: currentSchoolId, flags });
    return flags;
  }

  return LAUNCH_DARKLY_OFFLINE ? localFeatureFlags : useFlags();
};

export default useLaunchDarkly;
