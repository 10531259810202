import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from '../Checkbox';
import Icon from '../Icon';
import { DropdownContext } from 'hocs/withContext';

const Item = ({
  type = 'checkbox',
  checked,
  label,
  icon,
  iconType,
  onClick,
  className,
  disabled,
  children,
  highlighted,
  value = '',
  'data-cy': dataCY
}) => {
  const context = useContext(DropdownContext);
  const itemCN = classNames(
    {
      'select-group__item': true,
      'select-group__item--checked': checked,
      'select-group__item--disabled': disabled,
      'select-group__item--highlighted': highlighted,
      [className]: Boolean(className)
    },
    'select-group__item-custom-' +
      `${value}`
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-')
  );

  const handleClick = e => {
    if (disabled) return;

    if (onClick) {
      onClick(e);
    }
    if (context.toggle && type === 'radio') {
      context.toggle();
    }
  };

  return (
    <div className={itemCN} onClick={handleClick} data-cy={dataCY}>
      {type === 'checkbox' && <Checkbox checked={checked} />}

      {icon && (
        <div className="select-group__item-icon">{iconType === 'svg' ? <Icon name={icon} /> : <img src={icon} />}</div>
      )}

      <div className="select-group__item-label">{children || label}</div>
    </div>
  );
};

Item.propTypes = {
  type: PropTypes.oneOf(['checkbox', 'radio']),
  label: PropTypes.node,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Item;
