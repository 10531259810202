import React, { useState } from 'react';
import { ButtonV2, Avatar, TextInput } from 'components';
import cx from 'classnames';
import currency from 'lib/currency';

import './style.scss';

const RECEIVE_PAYMENT = 'receive';
const WRITE_OFF = 'writeoff';

const config = {
  [RECEIVE_PAYMENT]: {
    title: 'Receive Payment',
    columnLabel: 'Received',
    route: 'addSubsidyPayment',
    field: 'parent_payments'
  },
  [WRITE_OFF]: {
    title: 'Write-off Balance',
    columnLabel: 'Write-off amount',
    route: 'addSubsidyCredit',
    field: 'parent_credits'
  }
};

function SubsidyKidTransaction({ data, onClose }) {
  const [note, setNote] = useState('');
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { student, type, subsidy_id } = data;
  const cfg = config[type];

  const handleSubmit = async () => {
    if (value == null || value.length <= 0) {
      return setError('Should be specified');
    }
    if (Number(value) <= 0) {
      return setError('Should be greater than 0');
    }

    try {
      setIsLoading(true);
      await req[cfg.route]({
        ledger_id: subsidy_id,
        [cfg.field]: [
          {
            amount: Number(value),
            kid_id: student.id,
            notes: note
          }
        ]
      });
      Actions.showFlash('Transaction successfully created');
      onClose(true);
    } catch (err) {
      setIsLoading(false);
      Actions.reportError('Unable to submit', err);
    }
  };

  const handleChangeValue = val => {
    if (!/^\d{0,7}(\.\d{0,2})?$/.test(val)) {
      return;
    }

    setError(null);
    setValue(val);
  };

  return (
    <div className="modal__wrapper subsidy-kid-transaction">
      <div className="modal__header  modal__header--bordered">
        <span className="modal__header-title">{cfg.title}</span>
        <div className="subsidy-kid-transaction__kid">
          <Avatar
            name={student.first_name + ' ' + student.last_name}
            url={student.profile_pic_url}
            tooltipText={student.name}
            color={student.color}
            initials={student.initials}
          />
          <div className="subsidy-kid-transaction__kid-name">
            <div>{student.first_name + ' ' + student.last_name}</div>
          </div>
        </div>
      </div>
      <div className="modal__container">
        <div className="subsidy-kid-transaction__values">
          <div className="subsidy-kid-transaction__values-item subsidy-kid-transaction__invoiced">
            <div className="subsidy-kid-transaction__values-item-label">Balance</div>
            <div className="subsidy-kid-transaction__values-item-amount">
              {currency.getPrice((student.billing_stat && student.billing_stat.balance) || 0)}
            </div>
          </div>
          <div className="subsidy-kid-transaction__values-item">
            <div className="subsidy-kid-transaction__values-item-label">{cfg.columnLabel}</div>
            <div>
              <div
                className={cx('subsidy-kid-transaction__amount', {
                  'form-input--invalid': Boolean(error)
                })}
              >
                <span className="subsidy-kid-transaction__price-sym">{currency.getSymbol()}</span>
                <TextInput data-cy="value" value={value} onChange={handleChangeValue} placeholder="0" />
              </div>
              {error && <div className="form-input__validation-text">{error}</div>}
            </div>
          </div>
        </div>
        <div className="subsidy-kid-transaction__note">
          <TextInput
            placeholder="Add optional internal note"
            placeholder="Optional internal note e.g., ACH/Check Number"
            name="notes"
            value={note}
            onChange={setNote}
          />
        </div>
      </div>
      <div className="modal__controls">
        <ButtonV2 data-cy="submit" disabled={!value || isLoading} onClick={handleSubmit} label="Submit" />
      </div>
    </div>
  );
}

export default SubsidyKidTransaction;
