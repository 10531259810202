import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FILTER_TYPE } from 'lib/marcoPolo';
import { Form, Icon } from 'components';
import TreeSelect from 'components/TreeSelect';
import TreeSelectItem from 'components/TreeSelect/Item';

/**
 * Filter component that provides a search box, topic, and category selector.
 *  This component is UI only and expects relevant context and filter configuration to be passed in.
 * @param {Array} categories - Array of categories to display in the category selector. Categories are multi-level and rendered in a tree view.
 * @param {Array} topics - Array of topics to display in the topic selector.
 * @param {Object} filters - Object containing the current filter configuration.
 * @param {Function} onFilterChanged - Callback function to call when the filter configuration changes.
 * @param {Function} onFilterReset - Callback function to call when the filter configuration is reset.
 */
function Filter({ categories, topics, filters, onFilterChanged, onFilterReset }) {
  const formRef = React.createRef();

  // Delay time to wait for user input before triggering a filter change.
  const DELAY_TIME = 500;
  const [userInputTimeout, setUserInputTimeout] = useState(null);

  useEffect(() => {
    // Current form component does not update UI based on defaultValue changes. Forcing update based on filter configuration changes.
    formRef.current.updateField('filterType', filters.filterType);
    formRef.current.updateField('searchTerm', filters.searchTerm);
  }, [filters]);

  const onFilterTypeChanged = ({ filterType, searchTerm, filterTopic }) => {
    // Handling user input for search term so we can delay the filter change until the user has stopped typing.
    if (filterType === FILTER_TYPE.ALL) {
      if (userInputTimeout) {
        clearTimeout(userInputTimeout);
      }

      setUserInputTimeout(
        setTimeout(() => {
          onFilterChanged({
            filterType,
            searchTerm
          });
        }, DELAY_TIME)
      );
      return;
    }

    // All other filter type updates are handled here.
    onFilterChanged({
      filterType,
      searchTerm: '', // Resetting search term when filter type changes. When user switches back to ALL, the search term will be reset.
      topic: filterType === FILTER_TYPE.TOPIC && filterTopic ? filterTopic?.key : '',
      topicAppliedLabel: filterType === FILTER_TYPE.TOPIC && filterTopic ? filterTopic?.label : ''
    });
  };

  const onCurriculumSelected = currciculum => {
    onFilterChanged({
      filterType: FILTER_TYPE.CURRICULUM,
      searchTerm: '',
      path: currciculum.path,
      itemIds: currciculum.items
    });
  };

  const onSubjectSelected = subject => {
    onFilterChanged({
      filterType: FILTER_TYPE.SUBJECT,
      searchTerm: '',
      path: subject.path,
      itemIds: subject.items
    });
  };

  const renderCategoryItems = items => {
    return items.map(item => (
      <TreeSelectItem key={item.title} label={item.title} value={item}>
        {item?.children?.length && renderCategoryItems(item.children)}
      </TreeSelectItem>
    ));
  };

  const renderTopicItems = topics => {
    const retVal = [];
    topics.map(topic => {
      retVal.push(<Form.Select.Item key={topic.key} value={topic} label={topic.label} />);
    });
    return retVal;
  };

  const renderAppliedItem = () => {
    let appliedItem = '';
    switch (filters.filterType) {
      case FILTER_TYPE.ALL:
        appliedItem = filters.searchTerm;
        break;
      case FILTER_TYPE.TOPIC:
        appliedItem = filters.topicAppliedLabel;
        break;
      case FILTER_TYPE.CURRICULUM:
      case FILTER_TYPE.SUBJECT:
        appliedItem = filters.path;
        break;
    }

    return appliedItem ? (
      <div className="add-material__applied-item applied-filters">
        <span className="applied-item applied-filters__item">
          {appliedItem}
          <Icon className="applied-item-reset" name="close" size="10" onClick={onFilterReset} />
        </span>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <Form ref={formRef} onChange={onFilterTypeChanged}>
      <div className="add-material__filter">
        <div className="add-material__filter__container">
          <div className="add-material__filter__filter-type">
            <label>Filter Type</label>
            <Form.Select key="filterType" name="filterType" type="radio" defaultValue={filters.filterType}>
              <Form.Select.Item key="filterTypeAll" value={FILTER_TYPE.ALL} label="All" />
              {topics.length && <Form.Select.Item key="filter_type_all" value={FILTER_TYPE.TOPIC} label="Topic" />}
              {categories?.subject?.length && (
                <Form.Select.Item key="filterTypeSubject" value={FILTER_TYPE.SUBJECT} label="Subject Area" />
              )}
              {categories?.curriculum?.length && (
                <Form.Select.Item key="filterTypeCurriculum" value={FILTER_TYPE.CURRICULUM} label="Curriculum" />
              )}
            </Form.Select>
          </div>
          <div className="add-material__filter__search-term">
            <label>Search Category</label>
            {filters.filterType === FILTER_TYPE.ALL && (
              <div className="form-input__field">
                <Form.Input name="searchTerm" placeholder="What are you looking for?" />
              </div>
            )}
            {filters.filterType === FILTER_TYPE.CURRICULUM && (
              <TreeSelect className="add-material__filter__category" onItemSelected={onCurriculumSelected}>
                {categories && renderCategoryItems(categories.curriculum)}
              </TreeSelect>
            )}
            {filters.filterType === FILTER_TYPE.SUBJECT && (
              <TreeSelect className="add-material__filter__category" onItemSelected={onSubjectSelected}>
                {categories && renderCategoryItems(categories.subject)}
              </TreeSelect>
            )}
            {filters.filterType === FILTER_TYPE.TOPIC && (
              <Form.Select name="filterTopic" type="radio">
                {topics && renderTopicItems(topics)}
              </Form.Select>
            )}
          </div>
        </div>
      </div>
      {renderAppliedItem()}
    </Form>
  );
}

Filter.propTypes = {
  filters: PropTypes.object.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
  onFilterReset: PropTypes.func.isRequired
};

export default Filter;
