import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Form } from 'components';
import './style.scss';

class EditCarer extends Component {
  static propTypes = {
    // A function that closes the modal window
    onClose: PropTypes.func,

    // Additional data
    data: PropTypes.object
  };

  constructor() {
    super();

    this.state = {
      pin: '',
      isPinLoading: false,
      isLoading: false,
      showWarning: false
    };
  }

  UNSAFE_componentWillMount() {
    const { isNew, fromParent } = this.props.data;
    if (isNew) {
      this.setState({ isPinLoading: true });
      const promise = fromParent ? req.parentGetCarerPin() : req.getCarerPin();
      promise
        .then(res => {
          this.setState({ pin: res.pin, isPinLoading: false });
        })
        .catch(err => {
          console.log(err.response);
        });
    }
  }

  @bind
  formatError(error) {
    return error.response && error.response.data && error.response.data.errors
      ? error.response.data.errors[0]
      : 'An unexpected error occurred. Please try again later.';
  }

  @bind
  submit(values) {
    const { onClose } = this.props;
    const { studentId, isNew, fromParent } = this.props.data;

    if (isNew) {
      const requestData = {
        carer: {
          ...values,
          kid_id: studentId,
          relation: 'family'
        }
      };

      this.setState({ isLoading: true });
      if (fromParent) {
        req
          .parentAddCarer(requestData)
          .then(() => {
            this.setState({ isLoading: false });
            onClose(true);
          })
          .catch(error => {
            const errorMessage = this.formatError(error);
            this.setState({ isLoading: false });
            Actions.reportError(errorMessage);
          });
      } else {
        req
          .addCarer(requestData)
          .then(() => {
            this.setState({ isLoading: false });
            onClose(true);
          })
          .catch(() => {
            this.setState({ isLoading: false });
          });
      }
    } else {
      const { carer } = this.props.data;
      const requestData = {
        id: carer.id,
        carer: {
          ...values
        }
      };

      if (fromParent) {
        req
          .parentUpdateCarer(requestData)
          .then(() => {
            this.setState({ isLoading: false });
            onClose(true);
          })
          .catch(error => {
            const errorMessage = this.formatError(error);
            this.setState({ isLoading: false });
            Actions.reportError(errorMessage);
          });
      } else {
        req
          .updateCarer(requestData)
          .then(() => {
            this.setState({ isLoading: false });
            onClose(true);
          })
          .catch(error => {
            const errorMessage = this.formatError(error);
            this.setState({ isLoading: false });
            Actions.reportError(errorMessage);
          });
      }
    }
  }

  @bind
  onChange(values) {
    const currentFirstName = get(this.props.data, 'carer.first_name', null);
    const currentLastName = get(this.props.data, 'carer.last_name', null);
    const { first_name, last_name } = values;
    const parents = get(this.props.student, 'parents', []);
    const carers = get(this.props.student, 'carers', []);
    const hasSameNameParent = !!parents.find(
      p =>
        `${p.first_name}`.toLowerCase() === `${first_name}`.toLowerCase() &&
        `${p.last_name}`.toLowerCase() === `${last_name}`.toLowerCase()
    );
    const hasSameNameCarer = !!carers.find(
      p =>
        `${p.first_name}`.toLowerCase() === `${first_name}`.toLowerCase() &&
        `${p.last_name}`.toLowerCase() === `${last_name}`.toLowerCase() &&
        p.first_name !== currentFirstName &&
        p.last_name !== currentLastName
    );

    this.setState({ showWarning: hasSameNameParent || hasSameNameCarer });
  }

  render() {
    const { isLoading } = this.state;
    const { isNew, type, carer } = this.props.data;

    const title = isNew ? 'Add Authorized Caregiver' : 'Edit Authorized Caregiver';

    return (
      <div className="edit-carer">
        <div className="modal__header">
          <span className="modal__header-title">{title}</span>
        </div>

        <div className="modal__container">
          <Form onSubmit={this.submit} onChange={this.onChange} validateOn="submit" isLoading={isLoading}>
            <div className="form__row form__row--justified">
              <Form.Input
                label="First Name"
                defaultValue={carer && carer.first_name}
                placeholder="First Name (required)"
                name="first_name"
                required
              />

              <Form.Input
                label="Last Name"
                defaultValue={carer && carer.last_name}
                placeholder="Last Name"
                name="last_name"
              />
            </div>

            <div className="form__row form__row--justified">
              <Form.Input
                label="Phone"
                defaultValue={carer && carer.mobile_phone}
                name="mobile_phone"
                placeholder="Phone Number"
              />

              <Form.Input
                label="Relation"
                name="actual_relation"
                defaultValue={carer && carer.actual_relation}
                placeholder="Relation"
              />
            </div>

            {(isNew || type !== 'carpool') && (
              <div className="form__row edit-carer__emergency-contact edit-carer__last-row">
                <span className="edit-carer__emergency-contact-label">Is Emergency Contact?</span>
                <Form.Checkbox type="slider" name="emergency_contact" defaultValue={carer && carer.emergency_contact} />
              </div>
            )}

            {!isNew && carer.pin && (
              <div className="edit-carer__pin">
                <div className="form__label">Sign-In Pin</div>

                <div className="edit-carer__pin-container">
                  <div className="edit-carer__pin-note">
                    Parents and authorized individuals can use this code for Sign In-Out
                  </div>

                  <div className="edit-carer__pin-code">{carer.pin}</div>
                </div>
              </div>
            )}

            {this.state.showWarning && (
              <div className="edit-carer__warning">We found another parent/pickup with same name for the student.</div>
            )}

            <div className="edit-carer__footer form__row--actions">
              <Form.Submit label="Save" />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  student: state.student.data
});

export default connect(mapStateToProps)(EditCarer);
