import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon, Avatar } from 'components';

class Row extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    selected: PropTypes.bool,
    onClick: PropTypes.func
  };

  @bind
  handleClick() {
    const { onClick, data } = this.props;

    if (onClick) {
      onClick(data);
    }
  }

  render() {
    const { data, selected } = this.props;

    const rowClassName = cx('incident-report__row', {
      'incident-report__row--current': selected
    });

    return (
      <div className={rowClassName} onClick={this.handleClick}>
        <div className="incident-report__row-image">
          <Avatar
            name={data.name}
            url={data.profile_pic_url}
            tooltipText={data.name}
            color={data.color}
            initials={data.initials}
          />
        </div>

        <div className="incident-report__row-name">{data.name}</div>

        <Icon className="incident-report__row-change" name="tick-1" />
      </div>
    );
  }
}

export default Row;
