import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActionButton, TooltipTrigger } from 'components';
import currency from 'lib/currency';

const RATE_TYPE = {
  hourly: 'hr',
  daily: 'day',
  per_minute: 'min'
};

class InvoicePreviewItem extends Component {
  static propTypes = {
    item: PropTypes.any,
    isAttendancePreview: PropTypes.bool
  };

  render() {
    const { item, isAttendancePreview } = this.props;
    const rateType = RATE_TYPE[item.rate_type];

    return (
      <div className="table__row">
        <div className="table__cell">
          <span className="invoice-preview-card__description">{item.description}</span>
        </div>

        {(item.kind === 'attendance' || item.kind === 'late_fee') && (
          <div className="table__cell table__cell--xs">
            {item.quantity} {rateType}
            {item.quantity > 1 ? 's' : ''} @ {currency.getPrice(item.price)}/{rateType}
            {item.kind === 'attendance' && (
              <TooltipTrigger tooltip="Click to View Breakdown" side="center">
                <ActionButton
                  className="invoice-preview-card__breakdown"
                  iconName="expand"
                  size={16}
                  onClick={() => this.props.showBreakdown(item)}
                />
              </TooltipTrigger>
            )}
          </div>
        )}

        <div className="table__cell table__cell--xxs table__cell--actions">
          {currency.getPrice(item.amount === undefined ? item.price : item.amount)}
          {isAttendancePreview ? `/${rateType}` : ''}
        </div>
      </div>
    );
  }
}

export default InvoicePreviewItem;
