import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Badge, LabeledCheckbox } from 'components';
import BadgeHeightList from 'screens/teacher/Learning/Lessons/BadgeHeightList';

class Item extends PureComponent {
  static propTypes = {
    lesson: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    selected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired
  };

  static defaultProps = {
    lesson: {}
  };

  @bind
  handleSelect(e) {
    e.stopPropagation();
    this.props.onSelect(this.props.lesson.id);
  }

  renderLabels() {
    const { labels } = this.props.lesson;

    if (labels.length === 0) {
      return <div className="add-lesson__item-labels-empty">No labels</div>;
    }

    return (
      <BadgeHeightList lines={2}>
        {labels.map(l => (
          <Badge key={l.id} description={l.name} color={l.color} />
        ))}
      </BadgeHeightList>
    );
  }

  render() {
    const { lesson, selected, labels, enableRowSelect } = this.props;

    return (
      <div
        className={cx('add-lesson__item', {
          'add-lesson__item--selected': Boolean(selected)
        })}
        onClick={enableRowSelect ? this.handleSelect : undefined}
      >
        <div className="add-lesson__item-content">
          <div className="add-lesson__item-header">
            <div className="table__cell table__cell--s add-lesson__name-cell">Title</div>

            <div className="table__cell table__cell--l">Description</div>

            <div className="table__cell table__cell--s">Labels</div>
          </div>

          <div className="add-lesson__item-body">
            <div className="table__cell table__cell--s add-lesson__name-cell">
              <div className="add-lesson__item-name">{lesson.name}</div>

              <div className="add-lesson__item-category">{lesson.lesson_category && lesson.lesson_category.name}</div>
            </div>

            <div className="table__cell table__cell--l add-lesson__item-description">{lesson.description}</div>

            <div className="table__cell table__cell--s">{this.renderLabels(labels)}</div>
          </div>
        </div>

        <LabeledCheckbox data-cy="select" checked={selected} onChange={this.handleSelect} />
      </div>
    );
  }
}

export default Item;
