import React, { Component } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { Preloader } from 'components';
import Activity from './Activity';
import ActivityV2 from './Activity/indexV2';
import ActivityDate from './ActivityDate';
import withLaunchDarkly from 'hocs/withLaunchDarkly';

class ActivityList extends Component {
  static propTypes = {
    loadMore: PropTypes.func,
    approve: PropTypes.func,
    updateActivityData: PropTypes.func,
    remove: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    activities: PropTypes.any,
    kids: PropTypes.any,
    staff: PropTypes.any,
    isFiltered: PropTypes.bool,
    allStaff: PropTypes.any,
    isAdmin: PropTypes.bool.isRequired,
    isStaff: PropTypes.bool.isRequired,
    hideDelete: PropTypes.bool
  };

  static defaultProps = {
    remove: () => undefined,
    isStaff: true
  };

  scrollingElement = null; // used because some element has height 100%

  constructor(...args) {
    super(...args);

    this.onBrowserScroll = throttle(this.onBrowserScroll.bind(this), 300);
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('scroll', this.onBrowserScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onBrowserScroll, true);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.activities.length !== this.props.activities.length &&
      this.props.activities.length === 10 &&
      this.scrollingElement
    ) {
      this.scrollingElement.scrollTop = 0;
    }
  }

  @bind
  renderActivity(activity) {
    const {
      kids,
      staff,
      allStaff,
      approve,
      approveAll,
      updateActivityData,
      remove,
      isAdmin,
      isStaff,
      firstId,
      isFiltered,
      hideDelete,
      flags
    } = this.props;

    if (flags['activities-feed']) {
      return (
        <ActivityV2
          key={activity.id}
          isAdmin={isAdmin}
          isStaff={isStaff}
          firstId={firstId}
          activity={activity}
          allStudents={kids}
          staff={staff}
          allStaff={allStaff}
          approve={approve}
          approveAll={approveAll}
          updateActivityData={updateActivityData}
          remove={remove}
          isFiltered={isFiltered}
          hideDelte={hideDelete}
        />
      );
    } else {
      return (
        <Activity
          key={activity.id}
          isAdmin={isAdmin}
          isStaff={isStaff}
          firstId={firstId}
          activity={activity}
          allStudents={kids}
          staff={staff}
          allStaff={allStaff}
          approve={approve}
          approveAll={approveAll}
          updateActivityData={updateActivityData}
          remove={remove}
          isFiltered={isFiltered}
          hideDelte={hideDelete}
        />
      );
    }
  }

  onBrowserScroll(e) {
    const elem = e.target;

    if (e.target.classList && !e.target.classList.contains('section')) {
      return;
    } else if (e.target.className && e.target.className.split(' ').indexOf('section') === -1) {
      return;
    }

    const { loadMore, isLoading } = this.props;
    this.scrollingElement = elem;

    if (elem.scrollHeight <= elem.offsetHeight + elem.scrollTop && !isLoading && loadMore) {
      loadMore();
    }
  }

  @bind
  renderActivities(activities) {
    let lastDate = null;
    return activities.map(activity => {
      const renderingComponents = [this.renderActivity(activity)];
      if (lastDate !== activity.activity_date) {
        renderingComponents.unshift(<ActivityDate key={activity.activity_date} date={activity.activity_date} />);
        lastDate = activity.activity_date;
      }

      return renderingComponents;
    });
  }

  render() {
    const { activities, isLoading } = this.props;

    return (
      <div className="activity-list" data-cy="activity-list">
        {this.renderActivities(activities)}

        {isLoading && <Preloader center />}
      </div>
    );
  }
}

export default withLaunchDarkly(ActivityList);
