import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';

export const defaultStatusMap = {
  none: 'Uploaded', // TODO: remove if deprecated
  uploaded: 'Uploaded',
  request_sent: 'Unread', // Request Sent
  opened: 'Read', // Request Sent
  awaiting_approval: 'Awaiting Approval', // Approvals
  expired: 'Expired', // Completed
  completed: 'Approved', // Completed
  canceled: 'Canceled', // Completed
  rejected: 'Rejected' // Completed
};

const DocumentSendStatus = ({ label, data, className, customStatusMap = {} }) => {
  const statusMap = { ...defaultStatusMap, ...customStatusMap };

  if (label != null) {
    return (
      <div className={classNames('document-send-status', className)}>
        <div className="document-send-status__label">{label}</div>
      </div>
    );
  } else if (
    data != null &&
    data.status &&
    typeof data.status === 'string' &&
    Object.keys(statusMap).includes(data.status)
  ) {
    return (
      <div className={classNames('document-send-status', className)}>
        <div className="document-send-status__label">{statusMap[data.status]}</div>
      </div>
    );
  } else {
    return null;
  }
};

DocumentSendStatus.propTypes = {
  label: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.oneOf(Object.keys(defaultStatusMap))
  }),
  className: PropTypes.string
};

export default DocumentSendStatus;
