import React, { Component } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import get from 'lodash/get';

import { capitalize } from 'lib/utils';
import { Placeholder, ActionButton, Icon, ButtonV2, Link } from 'components';
import { connect } from 'react-redux';
import { getCurrentSchool } from 'lib/utils/selectors';
import { compose } from 'redux';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class Step2CC extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    onConnectUri: PropTypes.func
  };

  constructor(...args) {
    super(...args);
    this.state = {
      subKind: args[0].subKind,
      verifiedSubKind: args[0].subKind,
      surchargeWarningAcknowledged: false,
      isLoading: false,
      teraSetting: null,
      teraLoading: true,
      isVerified: false
    };
  }

  componentDidMount() {
    const { onConnectUri } = this.props;
    this.setState({ teraLoading: true });
    onConnectUri()
      .then(teraSetting => {
        this.setState({ teraLoading: false, teraSetting }, () => {
          this.initializeTera();
        });
      })
      .catch(e => {
        this.setState({ teraLoading: false, teraSetting: null });
        Actions.reportError('There was problem getting Tuition Express token', e);
      });
  }

  @bind
  onTeraLoaded() {
    this.setState({ teraLoading: false });
  }

  @bind
  onBinLookupResponse(stringResponse) {
    const response = JSON.parse(stringResponse);

    const { subKind } = this.state;
    let verifiedSubKind = subKind;

    switch (response.lookupResult.IsSurchargeAllowed) {
      case 1:
        verifiedSubKind = 'credit';
        break;
      case 2:
        verifiedSubKind = 'debit';
        break;
    }

    this.setState({
      isVerified: true,
      verifiedSubKind
    });
  }

  @bind
  initializeTera() {
    const { TeraUrl, Token, ProfileID } = this.state.teraSetting;
    this.tera = new window.tera({
      Target: 'payment_container',
      TeraUrl,
      Token,
      ProfileID,
      Style: `${process.env.PUBLIC_PATH}/assets/tera.css`,
      Height: 300,
      HideSubmit: true,
      OnLoaded: this.onTeraLoaded,
      OnSuccess: this.handleSubmit,
      OnResponse: noop,
      OnBinLookupResponse: this.onBinLookupResponse,
      OnDecline: console.log,
      OnError: console.log,
      OnValidation: console.log,
      SaveOnly: true
    });
    this.tera.Initialize(9.99);
  }

  @bind
  notifyMobile() {
    if (window.mobileJsInterface?.parentPaymentMethodAdded) {
      window.mobileJsInterface.parentPaymentMethodAdded();
    } else if (window.webkit?.messageHandlers?.mobileJsInterface) {
      window.webkit.messageHandlers.mobileJsInterface.postMessage('parentPaymentMethodAdded');
    }
  }

  @bind
  handleSubmit(data) {
    const token = data;
    const { onSubmit, autopayEnabled } = this.props;
    const { verifiedSubKind } = this.state;

    this.setState({ isLoading: true });
    if (onSubmit) {
      return onSubmit({
        token,
        token_type: 'card'
      });
    }

    return req
      .addPaymentMethod({
        token,
        token_type: 'card',
        sub_kind: verifiedSubKind,
        verified_sub_kind: verifiedSubKind,
        auto_pay: autopayEnabled === true
      })
      .then(() => {
        this.notifyMobile();
        Actions.showFlash('Card added successfully');
        this.setState({ isLoading: false });
        this.props.goToList(true);
      })
      .catch(err => {
        this.setState({ isLoading: false });
        const errObj = get(err, 'response.data.form_errors');
        if (errObj) {
          Actions.reportError(`${errObj.error_message} (${errObj.retref})`, err);
        } else {
          Actions.reportError('There was problem with adding payment method', err);
        }
      });
  }

  @bind
  renderCardVerification() {
    const { isVerified, verifiedSubKind } = this.state;
    return (
      isVerified && (
        <>
          <Icon name="verify" /> Verified {capitalize(verifiedSubKind)} Card
        </>
      )
    );
  }

  @bind
  showSurchargeInformation() {
    Actions.showModal('surchargeInformation');
  }

  @bind
  renderSurchargeWarning() {
    const { feeBreakdown, currentSchool } = this.props;
    const { subKind, verifiedSubKind, surchargeWarningAcknowledged } = this.state;
    const hasFee = currentSchool.parent_payment_fee_paid_by === 'parent_payment_fee_paid_by_parent';

    if (!hasFee || surchargeWarningAcknowledged || !subKind || subKind === 'credit' || subKind === verifiedSubKind) {
      return null;
    }

    return (
      <div className="payments-panel__warning">
        <Icon name="alert" size="24" className="alert" />
        <div>
          The payment type entered does not match the selected payment type. Please note: A surcharge of{' '}
          {feeBreakdown.cc} will be added to all credit card transactions.
          <div>
            <Link onClick={this.showSurchargeInformation}>What is a surcharge?</Link>
          </div>
        </div>
        <Icon
          name="close"
          size="16"
          className="close"
          onClick={() => this.setState({ surchargeWarningAcknowledged: true })}
        />
      </div>
    );
  }

  render() {
    const { onSubmit } = this.props;
    const { prevStep } = this.props.context;
    const { teraSetting, teraLoading, verifiedSubKind } = this.state;

    return (
      <div className="table payments-panel">
        <div className="table__header payments-panel__header">
          {!onSubmit && <ActionButton iconName="back" size={20} onClick={prevStep} />}
          <div className="payments-panel__title">
            {onSubmit ? 'Submit Registration' : `Set Up ${verifiedSubKind && capitalize(verifiedSubKind)} Card`}
          </div>
        </div>

        <div className="table__body">
          <div className="table__row payments-panel__body">
            <div className="payments-panel__content">
              <div id="payment_container" className="payments_panel__container" />

              {(!teraSetting || teraLoading) && (
                <Placeholder.NoResult isLoading image="" className="te-payments__placeholder">
                  <div className="no-payments__title">There was problem getting Tuition Express token.</div>
                </Placeholder.NoResult>
              )}
              <div className="payments-panel__verification">{this.renderCardVerification()}</div>
            </div>
          </div>
          {this.renderSurchargeWarning()}
          <div className="payments-panel__footer">
            <ButtonV2
              primary
              onClick={() => this.tera.SubmitOk()}
              label={onSubmit ? 'Pay and Register' : `Save ${verifiedSubKind && capitalize(verifiedSubKind)} Card`}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentSchool: getCurrentSchool(state),
  feeBreakdown: state.feeBreakdown.data
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(Step2CC);
