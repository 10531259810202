import React, { Component } from 'react';
import { ActionButton, StudentCard, Form } from 'components';
import { Scrollbars } from 'react-custom-scrollbars';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class MarkAbsentSaveStep extends Component {
  @bind
  deleteStudent(id) {
    return () => {
      const { selectedStudents, updateStudents } = this.props;
      updateStudents(selectedStudents.filter(sId => sId !== id));
    };
  }

  render() {
    const { isSaving, onSubmit, students, selectedStudents } = this.props;

    return (
      <div className="modal__wrapper">
        <div className="modal__header modal__header--bordered">
          <ActionButton iconName="back" className="modal__header-back" onClick={() => this.props.context.prevStep()} />
          <span className="modal__header-title">Mark Absent</span>
          <span className="modal__header-steps">
            <span>Step 2</span> / 2
          </span>
        </div>

        <div className="modal__container">
          <Form onSubmit={onSubmit} validateOn="submit" isLoading={isSaving}>
            <div className="form__row form__row--justified mark-absent__body">
              <div className="mark-absent__form">
                <div className="form__row form__row--justified mark-absent__form-date-range">
                  <Form.DatePicker
                    name="from_date"
                    placeholder="Select Date"
                    defaultValue={new Date()}
                    label="Select Date Range"
                  />
                  <Form.DatePicker
                    className="mark-absent__to-date"
                    name="to_date"
                    placeholder="Select Date"
                    defaultValue={new Date()}
                    rangeStart="from_date"
                    maxRangeMonth={1}
                  />
                </div>

                <div className="form__row">
                  <Form.Input label="Note" name="note" multiline />
                </div>
              </div>
              <div className="mark-absent__student-list">
                <div className="mark-absent__student-list__title">
                  <span>{selectedStudents.length}</span> selected
                </div>
                <div className="mark-absent__student-list__content">
                  <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={285}>
                    {students
                      .filter(s => {
                        return selectedStudents.indexOf(s.id) > -1;
                      })
                      .map((student, index, filteredStudents) => (
                        <StudentCard
                          checkable={false}
                          action={filteredStudents.length > 1 ? this.deleteStudent(student.id) : undefined}
                          actionIcon="delete"
                          key={`student-${index}`}
                          student={student}
                        />
                      ))}
                  </Scrollbars>
                </div>
              </div>
            </div>

            <div className="form__row form__row--actions">
              <Form.Submit label="Submit" data-cy="submit" />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(MarkAbsentSaveStep);
