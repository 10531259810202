import React, { useState } from 'react';
import { Form } from 'components';

import './style.scss';

function AddCarpool({ data, onClose }) {
  const [isLoading, setIsLoading] = useState(false);

  const submit = async values => {
    const { studentId, fromParent } = data;

    const requestData = {
      kid_id: studentId,
      carer_id: values.carer
    };

    try {
      setIsLoading(true);
      await (fromParent ? req.parentAddCarpoolCarer(requestData) : req.addCarpoolCarer(requestData));
      setIsLoading(false);
    } catch (err) {
      Actions.reportError('There was an error adding a carpool.');
    }
    setIsLoading(false);
    onClose(true);
  };

  const loadCarers = async query => {
    if (query.length < 3) {
      return [];
    }

    const { studentId, fromParent } = data;
    const requestData = { kid_id: studentId, name: query };

    const result = await (fromParent ? req.parentCarpoolCarers(requestData) : req.carpoolCarers(requestData));
    const carpools = result.carpool_carers.map(c => ({
      id: c.id,
      label: c.name
    }));

    return carpools;
  };

  return (
    <div className="add-carpool">
      <div className="modal__header">
        <span className="modal__header-title">Add Authorized Carpool Pickup</span>
      </div>

      <div className="modal__container">
        <Form onSubmit={submit} isLoading={isLoading}>
          <div className="add-carpool__form">
            <div className="form__row">
              <Form.Typeahead
                required
                name="carer"
                placeholder="Search Carer (min 3 letters)"
                minCharacters={3}
                loadOptions={loadCarers}
              />
            </div>
          </div>

          <div className="add-carpool__footer form__row--actions">
            <Form.Submit label="Save" />
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddCarpool;
