// Reference: src/modals/AddAutomationAction/index.js
import React, { useRef, useState } from 'react';
import Steps from 'modals/ModalController/Steps';
import CheckboxStep from './CheckboxStep';
import SelectStudentsStep from './SelectStudentsStep';

function SendAssessment({ onClose }) {
  const stepsRef = useRef(null);

  const payloadShape = {
    withProgressOnly: true,
    studentIds: [],
    filters: {}
  };
  const [data, setData] = useState(payloadShape);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleInclude = () => {
    setData(state => ({ ...state, withProgressOnly: !state.withProgressOnly }));
  };

  const setStudents = ids => {
    setData(state => ({ ...state, studentIds: ids }));
  };

  const setFilters = filters => {
    setData(state => ({ ...state, filters }));
  };

  const goToCheckbox = () => {
    stepsRef.current.goToStep('checkbox');
  };

  const goToStudents = () => {
    stepsRef.current.goToStep('students');
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      await req.assesmentsShare({
        with_progress_only: data.withProgressOnly,
        kid_ids: data.studentIds
      });
      onClose();
      Actions.showFlash('Portfolios sent');
    } catch (err) {
      Actions.reportError('Unable to send the portfolio', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Steps ref={stepsRef} startStep={0}>
      <Steps.Item name="checkbox">
        <CheckboxStep nextStep={goToStudents} data={data} onToggle={toggleInclude} />
      </Steps.Item>

      <Steps.Item name="students">
        <SelectStudentsStep
          onBack={goToCheckbox}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          selectedIds={data.studentIds}
          onSelect={setStudents}
          filters={data.filters}
          onFiltersUpdate={setFilters}
          buttonText="Send Portfolio"
          StepSlot={() => (
            <span>
              <strong>Step 2</strong> / 2
            </span>
          )}
        />
      </Steps.Item>
    </Steps>
  );
}

export default SendAssessment;
