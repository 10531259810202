import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'components';

const FilterMilestoneCategories = ({
  milestoneCategories = [],
  name,
  defaultValue,
  label = 'Age Group / Category'
}) => (
  <div className="form__row">
    <Form.Select
      name={name}
      title={label}
      type="checkbox"
      label={label}
      defaultValue={defaultValue}
      enableClear
      data-cy={name}
      multipleSelectionText="Categories Applied"
    >
      {milestoneCategories.map(category => (
        <Form.Select.Item key={category.id} value={category.id} label={category.name} />
      ))}
    </Form.Select>
  </div>
);

FilterMilestoneCategories.propTypes = {
  label: PropTypes.string,
  milestoneCategories: PropTypes.array,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.array
};

const mapStateToProps = state => ({
  milestoneCategories: state.milestoneCategories.data
});

export default connect(mapStateToProps, null, null, { pure: false })(FilterMilestoneCategories);
