import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getErrorText, capitalize } from 'lib/utils';
import { LoadingPlaceholder } from 'components';
import ManageController from 'modals/common/ManageItems/ManageController';
import ManageWindow from 'modals/common/ManageItems/ManageWindow';
import ManageTable from 'modals/common/ManageItems/ManageTable';
import CategoryItem from './CategoryItem';
import './style.scss';

class ManageMilestoneCategories extends PureComponent {
  static propTypes = {
    categories: PropTypes.array,
    categoriesLoading: PropTypes.bool,
    sections: PropTypes.array
  };

  static defaultProps = {
    categories: [],
    sections: []
  };

  componentDidMount() {
    req.milestoneCategories();
    req.rooms();
  }

  @bind
  async addCategory(category) {
    try {
      await req.addMilestoneCategory({
        category: {
          name: category.name,
          category_sections_attributes: category.sections.map(sectionId => ({
            section_id: sectionId
          }))
        }
      });
      Actions.showFlash('Category has been added');
      return Promise.resolve();
    } catch (e) {
      Actions.reportError(capitalize(getErrorText(e.response.data)), e);
      return Promise.reject();
    }
  }

  @bind
  async updateCategory(id, category) {
    const sectionsToAdd = category.sections
      .filter(s => !category.category_sections.some(cs => cs.section_id === s))
      .map(cs => ({ section_id: cs }));

    const sectionsToDelete = category.category_sections
      .filter(cs => !category.sections.some(s => s === cs.section_id))
      .map(cs => ({ id: cs.id, section_id: cs.section_id, _destroy: true }));

    const category_sections_attributes = [...sectionsToAdd, ...sectionsToDelete].flat();

    try {
      await req.updateMilestoneCategory({
        id,
        category: {
          name: category.name,
          category_sections_attributes
        }
      });
      Actions.showFlash('Category has been updated');
      return Promise.resolve();
    } catch (e) {
      Actions.reportError(capitalize(getErrorText(e.response.data, { name: ' ' })), e);
      return Promise.reject();
    }
  }

  @bind
  async deleteCategory(id) {
    const shouldBeDeleted = await this.confirmDeletion();
    if (!shouldBeDeleted) {
      return;
    }

    const params = { id };

    try {
      await req.deleteMilestoneCategory(params, params);
      Actions.showFlash('Category has been deleted');
      return Promise.resolve();
    } catch (e) {
      Actions.reportError('Unable to delete category', e);
      return Promise.reject();
    }
  }

  async confirmDeletion() {
    return Actions.showModal('Confirmation', {
      title: 'Delete Category',
      description: 'Are you sure you want to delete this category?',
      noButton: 'No',
      yesButton: 'Yes, Delete',
      yesButtonProps: { danger: true, secondary: false },
      noButtonProps: { secondary: true }
    });
  }

  @bind
  renderHeader() {
    return (
      <div className="manage-categories__header">
        <div className="manage-categories__header__name">Age Group</div>
        <div className="manage-categories__header__rooms">Rooms</div>
      </div>
    );
  }

  render() {
    const { categories, categoriesLoading, sections } = this.props;

    return (
      <div className="manage-categories">
        <ManageController
          items={categories}
          onAdd={this.addCategory}
          onUpdate={this.updateCategory}
          onDelete={this.deleteCategory}
        >
          {props => (
            <ManageWindow
              className="manage-categories__window"
              title="Manage Age Groups"
              addIcon="add"
              addLabel="Add Age Group"
              onAdd={props.onNew}
              data-cy="add-category"
            >
              {categories.length === 0 && !props.isNew ? (
                <LoadingPlaceholder
                  icon="milestone"
                  loading={categoriesLoading}
                  title="No age groups added"
                  text="Click on Add Age Group to add age groups"
                  className="manage-list__placeholder"
                />
              ) : (
                <ManageTable
                  {...props}
                  renderCustom
                  ItemComponent={CategoryItem}
                  renderHeader={this.renderHeader}
                  itemProps={{ sections }}
                />
              )}
            </ManageWindow>
          )}
        </ManageController>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.milestoneCategories.data,
  categoriesLoading: state.milestoneCategories.loading,
  sections: state.rooms.data
});

export default connect(mapStateToProps)(ManageMilestoneCategories);
