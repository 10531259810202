import React, { Component } from 'react';
import { ButtonV2, Checkbox, TooltipTrigger } from 'components';
import './style.scss';

class EmailSigninCode extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isSending: false,
      sendIndividual: false
    };
  }

  @bind
  emailParents() {
    const { data } = this.props;
    const { sendIndividual } = this.state;

    this.setState({ isSending: true });
    req
      .sendPins({ kid_ids: [data.studentId], send_individual: sendIndividual })
      .then(() => {
        Actions.showFlash('Sign-In Pins are emailed to the parents');
        this.props.onClose();
        this.setState({ isSending: false });
      })
      .catch(err => {
        this.setState({ isSending: false });
        Actions.reportError("Sign-In Pins couldn't be sent , try again", err);
      });
  }

  @bind
  updateSendIndividual(value) {
    this.setState({
      sendIndividual: value
    });
  }

  renderSendIndividualTooltip() {
    return <div>Parents of family will be sent separate emails with only their pin</div>;
  }

  render() {
    const { onClose } = this.props;
    const { isSending, sendIndividual } = this.state;

    return (
      <div className="modal__wrapper email-signin-code">
        <div className="modal__header">
          <div className="subheader">Email Sign In-Out Pin</div>
          <span className="modal__header-note">
            An email will be sent to parents/guardians with Sign In-Out code of all the authorized pickups.
            <br />
            Do you want to send it?
          </span>
        </div>

        <div className="modal__container">
          <div className="email-signin-code__individual-pin">
            <Checkbox
              label="Send the parents pin in separate email"
              checked={sendIndividual}
              type="circle"
              onChange={this.updateSendIndividual}
            />
            <TooltipTrigger tooltip={this.renderSendIndividualTooltip()}>
              <ButtonV2 icon iconName="tooltip" iconSize={18} />
            </TooltipTrigger>
          </div>
        </div>

        <div className="modal__controls">
          <ButtonV2 onClick={this.emailParents} secondary label="Yes, send" isLoading={isSending} />
          <ButtonV2 onClick={onClose} label="No" />
        </div>
      </div>
    );
  }
}

export default EmailSigninCode;
