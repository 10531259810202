import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import history from 'lib/history';

function UnsavedChangesConfirmation({ isBlocking }) {
  const [nextLocation, setNextLocation] = useState(null);

  useEffect(() => {
    if (nextLocation) {
      history.push(nextLocation);
    }
  }, [nextLocation]);

  const handleRouteChange = next => {
    (async () => {
      const answer = await Actions.showModal('Confirmation', {
        title: 'Unsaved Changes',
        description: 'You have unsaved changes. By continuing, all changes will be lost. Do you want to continue?',
        yesButton: 'Yes, Continue'
      });

      if (answer) {
        // necessary to defer history.push until re-render so Prompt doesn't block it
        setNextLocation(next);
      }
    })();
    return false;
  };

  return <Prompt when={isBlocking && !nextLocation} message={handleRouteChange} />;
}

export default UnsavedChangesConfirmation;
