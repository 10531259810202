import { API_TARGET_AUTH } from 'lib/auth';

const authRoutes = {
  createAuthSession: {
    apiTarget: API_TARGET_AUTH,
    url: '/sessions',
    method: 'post',
    reducer: {
      name: 'tenant',
      type: 'updateTenant',
      initialState: {}
    }
  },

  deleteAuthSession: {
    apiTarget: API_TARGET_AUTH,
    url: '/sessions',
    method: 'delete',
    reducer: {
      name: 'tenant',
      type: 'updateTenant',
      initialState: {}
    }
  }
};

export default authRoutes;
