import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';
import Item from './Item';
import './style.scss';

const KeyCode = {
  ArrowUp: 'ArrowUp',
  ArrowDown: 'ArrowDown',
  Space: 'Space',
  Enter: 'Enter'
};

class SelectList extends Component {
  static propTypes = {
    items: PropTypes.array,
    className: PropTypes.string,
    renderItem: PropTypes.func.isRequired,
    onSelect: PropTypes.func
  };

  static defaultProps = {
    items: []
  };

  _scroll = null;

  constructor(props) {
    super(props);

    this.state = {
      selectedItemIndex: 0
    };
  }

  componentDidMount() {
    this.addEventListeners();
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  componentDidUpdate() {
    const { selectedItemIndex } = this.state;
    const { items } = this.props;

    if (selectedItemIndex < 0) {
      this.setState({ selectedItemIndex: 0 });
    }

    if (selectedItemIndex >= items.length) {
      this.setState({ selectedItemIndex: items.length - 1 });
    }
  }

  @bind
  addEventListeners() {
    document.addEventListener('keydown', this.listenArrows);
    document.addEventListener('keydown', this.listenSubmitKeys);
  }

  @bind
  removeEventListeners() {
    document.removeEventListener('keydown', this.listenArrows);
    document.removeEventListener('keydown', this.listenSubmitKeys);
  }

  @bind
  bindScroll(node) {
    this._scroll = node;
  }

  @bind
  listenArrows(e) {
    const noScrollBound = this._scroll === null;
    const noArrowPressed = e.code !== KeyCode.ArrowDown && e.code !== KeyCode.ArrowUp;

    if (noScrollBound || noArrowPressed) {
      return;
    }

    e.preventDefault();

    const { selectedItemIndex } = this.state;
    const renderedItems = this._scroll.container.getElementsByClassName('select-list__item');
    const len = renderedItems.length;

    let nextIndex = selectedItemIndex;

    if (e.code === KeyCode.ArrowDown) {
      nextIndex = nextIndex + 1 >= len ? 0 : nextIndex + 1;
    }

    if (e.code === KeyCode.ArrowUp) {
      nextIndex = nextIndex - 1 < 0 ? len - 1 : nextIndex - 1;
    }

    const containerHeight = this._scroll.container.clientHeight;
    const nextHighlightedElement = renderedItems[nextIndex];
    const itemHeight = nextHighlightedElement.clientHeight;
    const itemOffset = nextHighlightedElement.offsetTop;
    const containerItemsDiff = itemHeight - Math.floor(containerHeight / itemHeight);

    let scrollDiff = 0;
    if (containerHeight <= itemOffset + itemHeight) {
      scrollDiff = itemOffset - containerHeight + containerItemsDiff * 2;
    }

    this._scroll.scrollTop(scrollDiff);
    this.setState({ selectedItemIndex: nextIndex });
  }

  @bind
  listenSubmitKeys(e) {
    const noScrollBound = this._scroll === null;
    const noSubmitKeyPressed = e.code !== KeyCode.Enter;
    const noTagSelected = this.state.selectedItemIndex === -1;

    if (noScrollBound || noSubmitKeyPressed || noTagSelected) {
      return;
    }

    e.preventDefault();
    return this.selectItem(this.props.items[this.state.selectedItemIndex]);
  }

  @bind
  selectItem(data) {
    if (this.props.onSelect) {
      this.props.onSelect(data);
    }
  }

  @bind
  focusItem(index) {
    this.setState({ selectedItemIndex: index });
  }

  renderItems() {
    const { selectedItemIndex } = this.state;
    const { items } = this.props;

    return items.map((i, index) => (
      <Item
        key={i.id || i.value || i.name}
        index={index}
        data={i}
        selected={index === selectedItemIndex}
        renderItem={this.props.renderItem}
        onSelect={this.selectItem}
        onFocus={this.focusItem}
      />
    ));
  }

  render() {
    const className = classNames('select-list', this.props.className);

    return (
      <div className={className}>
        <Scrollbars className="select-list__scroll" ref={this.bindScroll}>
          {this.renderItems()}
        </Scrollbars>
      </div>
    );
  }
}

export default SelectList;
