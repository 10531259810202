import getSymbolFromCurrency from 'currency-symbol-map';

class Currency {
  constructor(currency = 'usd') {
    this.setCurrency(currency);
  }

  setCurrency(currency = 'usd') {
    this._currency = currency;
  }

  getSymbol() {
    return getSymbolFromCurrency(this._currency);
  }

  getMonetaryNumber(val) {
    const price = Number(`${val}`.replace(/,/g, ''));
    var result = Math.round(price) === price ? price : price.toFixed(2);
    return result === '-0.00' ? Math.abs(result).toFixed(2) : result;
  }

  getPrice(val) {
    if (val === '' || val === undefined || val === null || typeof val === 'object') {
      return undefined;
    }

    return `${this.getSymbol()}${this.getMonetaryNumber(val)}`;
  }
}

export default new Currency();
