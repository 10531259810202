import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { listenClick } from 'lib/utils';
import './style.scss';
import Portal from 'components/Portal';
import withPortalPosition from 'hocs/withPortalPosition';
import { compose } from 'redux';
import withIsVisible from 'hocs/withIsVisible';
import nextId from 'react-id-generator';

export const Tooltip = ({ items }) => (
  <ul className="tooltip-portal-content__list-tooltip">
    {items.map(item => (
      <li key={item.id}>{item.name}</li>
    ))}
  </ul>
);

class TooltipPortalTrigger extends Component {
  static propTypes = {
    className: PropTypes.string,

    // A function that returns a JSX element that represents the tooltip content.
    // Receives `onClose` param - a method that hides the tooltip.
    // Will override `tooltip` if provided.
    renderTooltip: PropTypes.func,

    // A JSX element or an array of JSX elements that represents the tooltip content.
    // Ignored if `renderTooltip` is provided.
    tooltip: PropTypes.any,

    // When to display the tooltip
    // Default is 'hover'
    triggerOn: PropTypes.oneOf(['hover', 'click']),

    // Where to display the tooltip arrow
    // Default is 'left'
    side: PropTypes.oneOf(['left', 'right', 'center']),

    // Prevents tooltip opening if `true`
    disabled: PropTypes.bool,

    // Do not display arrow
    noArrow: PropTypes.bool,

    // White theme with box-shadow
    white: PropTypes.bool,

    // A class name for the tooltip content
    tooltipClassName: PropTypes.string,

    // indicates if tooltip trigger has scroll
    maxHeight: PropTypes.number,

    // Close tooltip on click in content
    closeOnInsideClick: PropTypes.bool,

    // Don't hide content if disabled=true
    keepOpenDisabled: PropTypes.bool,

    onOpen: PropTypes.func,

    onClose: PropTypes.func,

    onVisibleChange: PropTypes.func,

    // controlled from outside
    visible: PropTypes.bool,

    'data-cy': PropTypes.string
  };

  static defaultProps = {
    triggerOn: 'hover',
    side: 'left',
    closeOnInsideClick: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isTooltipVisible: false
    };
    this.triggerRef = createRef();

    // Used to traverse DOM from portal to container
    this.id = nextId();
  }

  componentDidMount() {
    if (this.props.triggerOn === 'click') {
      setTimeout(() => document.addEventListener('click', this.listenClick, true), 250);
    }
  }

  componentDidUpdate(prevProps) {
    // Handle prop changes for visibility, etc.
    if (prevProps.visible !== this.props.visible) {
      this.toggleTooltip(this.props.visible);
    }

    // Re-bind or remove event listener based on triggerOn prop change
    if (prevProps.triggerOn !== this.props.triggerOn) {
      if (this.props.triggerOn === 'click') {
        setTimeout(() => document.addEventListener('click', this.listenClick, true), 250);
      } else {
        document.removeEventListener('click', this.listenClick, true);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.listenClick, true);
  }

  /**
   * Hide the tooltip if clicked outside.
   *
   * @param {object} e - event object
   */
  @bind
  listenClick(e) {
    if (this.props.triggerOn !== 'click' || !this.state.isTooltipVisible) {
      return;
    }

    if (this.triggerRef.current && this.triggerRef.current.contains(e.target)) {
      return;
    }

    if (this.props.closeOnInsideClick) {
      this.toggleTooltip(false);
    } else {
      listenClick(e, this.containerEl, () => this.toggleTooltip(false), true);
    }
  }

  @bind
  handleMouseLeave() {
    if (this.props.triggerOn === 'click') {
      return;
    }

    // Timeout allows tooltip to stay open when moving between trigger and content
    this.timeoutCloseTooltip = setTimeout(() => {
      this.toggleTooltip(false);
      this.timeoutCloseTooltip = null;
    }, 100);
  }

  @bind
  handleMouseEnter() {
    if (this.props.triggerOn === 'click') {
      return;
    }

    if (this.timeoutCloseTooltip) {
      clearTimeout(this.timeoutCloseTooltip);
      this.timeoutCloseTooltip = null;
    } else {
      this.toggleTooltip(true);
    }
  }

  @bind
  handleClick(e) {
    e.stopPropagation();
    if (this.props.triggerOn !== 'click') {
      return;
    }

    this.toggleTooltip(null);
  }

  @bind
  toggleTooltip(isVisible) {
    if (this.props.disabled) {
      return;
    }

    const nextIsVisible = typeof isVisible === 'boolean' ? isVisible : !this.state.isTooltipVisible;

    if (nextIsVisible && this.props.triggerOn === 'click') {
      setTimeout(() => document.addEventListener('click', this.listenClick), 250);
    } else if (!nextIsVisible && this.props.triggerOn === 'click') {
      document.removeEventListener('click', this.listenClick);
    }

    if (nextIsVisible && typeof this.props.onOpen === 'function') {
      this.props.onOpen();
    }

    if (!nextIsVisible && typeof this.props.onClose === 'function') {
      this.props.onClose();
    }

    this.props.setGetNextPortalPosition(nextIsVisible ? this.setPortalPosition : null);

    this.setState(
      {
        isTooltipVisible: nextIsVisible
      },
      () => {
        if (this.props.onVisibleChange) {
          this.props.onVisibleChange(nextIsVisible);
        }
      }
    );
  }

  @bind
  setPortalPosition() {
    if (!this.triggerRef.current) {
      return;
    }

    const rect = this.triggerRef.current.getBoundingClientRect();
    const position = {};

    position.top = rect.y + rect.height + window.scrollY;

    switch (this.props.side) {
      case 'right':
        position.right = window.innerWidth - rect.right;
        break;
      case 'center':
        position.left = rect.x + rect.width / 2 + window.scrollX;
        break;
      default:
        position.left = rect.x + window.scrollX;
        break;
    }

    if (!this.props.isVisible) {
      position.display = 'none';
    }

    return position;
  }

  renderThumbVertical(props) {
    return <div {...props} className="tooltip-portal-content__thumb-vertical" />;
  }

  renderTooltipContent() {
    const { tooltip, renderTooltip } = this.props;

    if (renderTooltip) {
      return renderTooltip(() => this.toggleTooltip(false));
    } else if (tooltip) {
      return tooltip;
    } else {
      return null;
    }
  }

  render() {
    const {
      className,
      portalClassName,
      side,
      noArrow,
      disabled,
      white,
      children,
      maxHeight,
      keepOpenDisabled,
      tooltipTriggerClassName,
      portalPosition,
      innerRef,
      'data-cy': dataCY
    } = this.props;

    const { isTooltipVisible } = this.state;

    const tooltipCN = classNames({
      tooltip: true,
      'tooltip-portal--visible': isTooltipVisible && (keepOpenDisabled || !disabled),
      'tooltip-portal--disabled': disabled,
      [className]: Boolean(className)
    });

    const portalCN = classNames({
      'tooltip-portal-content': true,
      'tooltip-portal-content--left': side === 'left',
      'tooltip-portal-content--right': side === 'right',
      'tooltip-portal-content--center': side === 'center',
      'tooltip-portal-content--no-arrow': noArrow,
      'tooltip-portal-content--white': white,
      [portalClassName]: Boolean(portalClassName)
    });

    const tooltipContent = this.renderTooltipContent();

    return (
      <div
        id={this.id}
        className={tooltipCN}
        data-cy={dataCY}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div
          className={classNames('tooltip-portal-trigger', tooltipTriggerClassName)}
          onClick={this.handleClick}
          ref={el => {
            this.triggerRef.current = el;
            innerRef.current = el;
          }}
        >
          {children}
        </div>

        {isTooltipVisible && (keepOpenDisabled || !disabled) && (
          <Portal>
            <div
              className={portalCN}
              style={{ ...portalPosition }}
              ref={node => (this.containerEl = node)}
              data-portal-for={this.id}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
            >
              {!maxHeight ? (
                tooltipContent
              ) : (
                <Scrollbars
                  autoHeight
                  autoHeightMin={0}
                  autoHeightMax={maxHeight}
                  thumbSize={30}
                  renderThumbVertical={this.renderThumbVertical}
                >
                  {tooltipContent}
                </Scrollbars>
              )}
            </div>
          </Portal>
        )}
      </div>
    );
  }
}

const enhance = compose(withPortalPosition, withIsVisible);

export default enhance(TooltipPortalTrigger);
