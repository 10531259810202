const createAddMultipleReducer = (reducerName, initialState) => (state = { ...initialState }, action) => {
  switch (action.type) {
    case `${reducerName}_ADD_MULTIPLE`:
      return { ...state, loading: true };

    case `${reducerName}_ADD_MULTIPLE_SUCCESS`:
      return { loading: false, data: [...action.payload, ...state.data] };

    case `${reducerName}_ADD_MULTIPLE_FAILURE`:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default createAddMultipleReducer;
