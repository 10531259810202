import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonV2 } from 'components';
import './style.scss';
import scheduleToProgramSessions from '../../transforms/scheduleToProgramSessions';
import moment from 'moment';

class CreateProgram extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    data: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  constructor(...args) {
    super(...args);
    this.state = {
      errors: {},
      isLoading: false,
      formValues: []
    };
  }

  @bind
  async submit(values) {
    const { onClose } = this.props;
    const { name, starts_at, ends_at, schedule, ratio_numerator, enabled } = values;
    const { invalidDateRange, anyScheduled } = this.state;

    // silently exiting from submission when there are custom errors
    if (invalidDateRange || !anyScheduled) {
      return null;
    }

    const payload = {
      program: {
        name,
        starts_at,
        ends_at,
        enabled,
        ratio_numerator: Number(ratio_numerator) || undefined,
        program_sessions_attributes: scheduleToProgramSessions(schedule)
      }
    };

    try {
      this.setState({ isLoading: true });
      const result = await req.createProgram(payload);
      this.setState({ isLoading: false });
      onClose(result?.program);
    } catch (err) {
      const newState = { isLoading: false };
      const formErrors = err.response?.data?.form_errors;

      if (formErrors) {
        newState.errors = formErrors;
      } else {
        Actions.reportError('There was problem creating a program', err);
      }

      this.setState(newState);
    }
  }

  @bind
  updateFormValues(values) {
    const invalidDateRange = values.starts_at && values.ends_at && moment(values.starts_at) > moment(values.ends_at);

    const anyScheduled = Object.entries(values.schedule || {}).find(([, ampm]) => ampm.am || ampm.pm)?.length > 0;
    const newState = { formValues: values, invalidDateRange, anyScheduled };

    newState.errors = {
      ...this.state.errors,
      starts_at: invalidDateRange ? ['Start date must be before end date'] : null,
      ends_at: invalidDateRange ? ['End date must be after start date'] : null,
      schedule: anyScheduled ? null : ['Schedule is required']
    };

    this.setState(newState);
  }

  render() {
    const { errors, isLoading, formValues } = this.state;
    const { onClose } = this.props;
    let defaultSchedule = {};
    ['MON', 'TUE', 'WED', 'THU', 'FRI'].forEach(day => {
      defaultSchedule[day] = { am: true, pm: true };
    });

    return (
      <div className="modal__wrapper create-program">
        <div className="modal__header">
          <span className="modal__header-title">Create Program</span>
        </div>

        <div className="modal__container">
          <Form
            validateOn="submit"
            onSubmit={this.submit}
            errors={errors}
            isLoading={isLoading}
            onInit={this.updateFormValues}
            onChange={this.updateFormValues}
          >
            <div className="form__row">
              <Form.Input name="name" label="Program Name" placeholder="Enter Unique Program Name" required asterisk />
            </div>

            <div className="form__row">
              <Form.DatePicker
                label="Start Date"
                name="starts_at"
                className="create-program__date"
                required={Boolean(formValues.ends_at)}
                asterisk={Boolean(formValues.ends_at)}
              />
              <Form.DatePicker label="End Date" name="ends_at" className="create-program__date" />
            </div>

            <div className="form__row">
              <Form.ScheduleSelect
                name="schedule"
                defaultValue={defaultSchedule}
                layout="inline"
                required
                asterisk
                label="Weekly Schedule"
              />
            </div>

            <div className="form__row">
              <div>
                <div className="form__label">Student : Staff Ratio</div>
                <div>
                  Number of students
                  <Form.Input
                    name="ratio_numerator"
                    data-cy="ratio-numerator"
                    placeholder="ex: 6"
                    type="number"
                    className="ratio"
                  />
                  per staff
                </div>
              </div>
            </div>

            <div className="form__row">
              <div>
                <label className="form__label" htmlFor="enabled">
                  Status
                </label>
                <div>
                  <Form.Checkbox className="mr-40" type="slider" name="enabled" label="Active" defaultValue={true} />
                </div>
              </div>
            </div>

            <div className="form__row form__row--actions">
              <ButtonV2 data-cy="cancel" secondary label="Cancel" onClick={() => onClose(false)} />
              <Form.Submit data-cy="submit" label="Create" disabled={isLoading} />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default CreateProgram;
