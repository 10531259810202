import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ButtonV2 } from 'components';
import { INTEGRATOR_SLUG } from 'constants/integratorTypes';
import './style.scss';

function ConnectIntellikids({ onClose, data }) {
  const [currentStep, setCurrentStep] = useState(data.viewOnly ? 'integrationStep' : 'introStep');
  const [integratorMetadata, setIntegratorMetadata] = useState({
    required_permissions: [],
    callback_url: '',
    display_callback_url: '',
    callback_url_embeddable_allowlist: []
  });
  const currentUser = useSelector(state => state.currentUser.data);

  useEffect(() => {
    req
      .integratorFetchMetadata({ id: INTEGRATOR_SLUG.Intellikids })
      .then(result => {
        if (result.integrator) {
          const formattedSiteId = formatSiteId();
          const encodedFormattedSiteId = encodeURIComponent(formattedSiteId);
          const formattedCallbackUrl = result.integrator.callback_url.replace('<SITE_ID>', encodedFormattedSiteId);
          const displayCallbackUrl = result.integrator.callback_url.replace('<SITE_ID>', formattedSiteId);
          setIntegratorMetadata({
            ...result.integrator,
            callback_url: formattedCallbackUrl,
            display_callback_url: displayCallbackUrl
          });
        }
      })
      .catch(() =>
        Actions.showFlash(
          'Error fetching integrator metadata. Please try again or contact support if the problem persists.',
          'alert'
        )
      );
  }, [currentUser]);

  const gotoIntegrationStep = async () => {
    try {
      const schoolId = currentUser?.current_school?.id;
      if (!schoolId) {
        throw new Error('School ID is not available.');
      }

      const payload = {
        school_id: schoolId,
        integrator_id: data.integrator_id
      };

      await req.integratorGrantPermissions(payload);

      setCurrentStep('integrationStep');
      Actions.showFlash('Integration permissions successfully granted.');
    } catch (error) {
      Actions.showFlash(
        'Error granting permissions. Please try again or contact support if the problem persists.',
        'alert'
      );
    }
  };

  const formatSiteId = () => {
    const schoolId = currentUser?.current_school?.id;
    return schoolId ? `PCO:SITE:${schoolId}` : 'Not Available';
  };

  const copyToClipboard = () => {
    const formattedSiteId = formatSiteId();
    if (formattedSiteId !== 'Not Available') {
      navigator.clipboard.writeText(formattedSiteId).then(() => {
        Actions.showFlash('Identifier copied to clipboard');
      });
    } else {
      Actions.showFlash('School Identifier is not available', 'alert');
    }
  };

  const IntroStep = () => (
    <Fragment>
      <div className="modal__header">
        <span className="modal__header-title">Integration Permission Request</span>
      </div>
      <div className="modal__container">
        <p className="instructions">
          IntelliKid System would like to access your center’s data for the following information:
        </p>
        <ul className="permissions-list">
          {integratorMetadata.required_permissions.map((permission, index) => (
            <li key={index}>{permission}</li>
          ))}
        </ul>
        <div className="form__row form__row--actions">
          <ButtonV2 data-cy="deny" secondary label="Deny Permission" onClick={() => onClose(false)} />
          <ButtonV2 data-cy="grant" label="Grant Permission" onClick={gotoIntegrationStep} />
        </div>
      </div>
    </Fragment>
  );

  const IntegrationStep = () => (
    <Fragment>
      <div className="modal__header">
        <span className="modal__header-title">IntelliKid Systems Integration</span>
      </div>
      <div className="modal__container">
        <p className="instructions">Click the link below to login to your custom portal.</p>
        {integratorMetadata.display_callback_url && (
          <div className="form__row">
            <a
              href={integratorMetadata.callback_url}
              target="_blank"
              rel="noopener noreferrer"
              className="integration-link"
            >
              {integratorMetadata.display_callback_url}
            </a>
          </div>
        )}
        <p className="instructions">
          Or, copy and paste your School Identifier code into IntelliKid Systems when prompted.
        </p>
        <div className="identifier-label">School Identifier</div>
        <div className="identifier-section">
          <div className="identifier" onClick={copyToClipboard}>
            {formatSiteId()}
          </div>
          <button onClick={copyToClipboard} className="copy-button">
            Copy
          </button>
        </div>
        <div className="form__row form__row--actions">
          <ButtonV2 label="Close" onClick={() => onClose(false)} />
        </div>
      </div>
    </Fragment>
  );

  return (
    <div className="modal__wrapper connect-intellikids">
      {currentStep === 'introStep' ? <IntroStep /> : <IntegrationStep />}
    </div>
  );
}

export default ConnectIntellikids;
