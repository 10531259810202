import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import AvatarEditor from 'react-avatar-editor';
import Slider from 'vendor/react-rangeslider';
import { ActionButton, Icon } from 'components';
import { v4 } from 'uuid';
import './toBlobPolyfill';

import './style.scss';

class ImageCropper extends Component {
  static propTypes = {
    className: PropTypes.string,

    renderTitle: PropTypes.func,
    renderControls: PropTypes.func,

    imgDataURL: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    border: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    round: PropTypes.bool,
    scaled: PropTypes.bool,
    maxScale: PropTypes.number,
    minScale: PropTypes.number,

    blob: PropTypes.bool,

    onSave: PropTypes.func
  };

  static defaultProps = {
    renderTitle: () => {
      return null;
    },
    renderControls: () => {
      return null;
    },
    round: false,
    scaled: false,
    maxScale: 4,
    minScale: 1,
    border: 100
  };

  editor = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      scale: props.minScale + (props.maxScale - props.minScale) / 2,
      rotate: 0,
      x: 0.5,
      y: 0.5
    };
  }

  get borderRadius() {
    const { border, round } = this.props;

    if (!round) {
      return 0;
    }

    if (Array.isArray(border)) {
      return Math.max(...border);
    }

    return border;
  }

  @bind
  handleScaleChange(scale) {
    this.setState({ scale });
  }

  @bind
  handlePositionChange({ x, y }) {
    this.setState({ x, y });
  }

  @bind
  handleRotateChange(rotate) {
    this.setState({ rotate });
  }

  @bind
  handleSave() {
    const { scaled } = this.props;
    if (this.editor) {
      const image = scaled ? this.editor.current.getImageScaledToCanvas() : this.editor.current.getImage();

      if (this.props.blob) {
        image.toBlob(blob => {
          blob.name = v4();
          this.props.onSave(blob);
        });
      } else {
        this.props.onSave(image.toDataURL());
      }
    }
  }

  render() {
    const {
      renderTitle,
      renderControls,
      className,
      width,
      height,
      minScale,
      maxScale,
      border,
      imgDataURL
    } = this.props;

    const { scale, rotate, x, y } = this.state;

    return (
      <div className={cx('image-cropper', className)}>
        {renderTitle({ width, height })}

        <div className="image-cropper__body">
          <AvatarEditor
            className="image-cropper__editor"
            ref={this.editor}
            image={imgDataURL}
            crossOrigin="anonymous"
            width={rotate % 180 ? height : width}
            height={rotate % 180 ? width : height}
            border={border}
            borderRadius={this.borderRadius}
            color={[0, 0, 0, 0.3]}
            scale={scale}
            rotate={rotate}
            onPositionChange={this.handlePositionChange}
            position={{ x, y }}
          />
          <div className="image-cropper__controls">
            <div className="image-cropper__controls-zoom">
              <Icon name="picture" size={14} color="#666" className="image-cropper__controls-zoom-small" />
              <Slider
                min={minScale}
                max={maxScale}
                step={0.01}
                value={scale}
                tooltip={false}
                onChange={this.handleScaleChange}
              />
              <Icon name="picture" size={20} color="#666" className="image-cropper__controls-zoom-large" />
            </div>
            <div className="image-cropper__controls-rotate">
              <ActionButton
                iconName="rotate-left"
                className="image-cropper__controls-rotate-left"
                square
                size={12}
                onClick={() => this.handleRotateChange(rotate - 90)}
              />
              <ActionButton
                iconName="rotate-right"
                className="image-cropper__controls-rotate-right"
                square
                size={12}
                onClick={() => this.handleRotateChange(rotate + 90)}
              />
            </div>
          </div>
        </div>

        {renderControls({
          onSave: this.handleSave
        })}
      </div>
    );
  }
}

export default ImageCropper;
