import React, { Component } from 'react';
import { connect } from 'react-redux';
import Steps from 'modals/ModalController/Steps';
import MessageTypeStep from './MessageTypeStep';
import MessageStep from './MessageStep';
import SelectStudentStep from './SelectStudentStep';
import './style.scss';

class NewMessage extends Component {
  static initialState = {
    filters: {
      search: '',
      roomId: '',
      tagIds: []
    },
    isNew: true,
    messageType: 'general',
    selectedStudents: [],
    selectedStaff: [],
    message: '',
    subject: '',
    sisoMessage: false,
    sendSMS: false,
    isSaving: false,
    isSavingDraft: false
  };

  constructor(...args) {
    super(...args);
    this.state = {
      ...NewMessage.initialState,
      messageType: this.props.data.type || 'general'
    };
  }

  UNSAFE_componentWillMount() {
    // when the modal is loaded, let's reset the form
    if (this.props.data.isNew) {
      this.setState({ ...NewMessage.initialState, messageType: this.props.data.type || 'general' });
    } else if (this.props.data.message) {
      const { message } = this.props.data;
      this.setState({
        isNew: false,
        id: message.id,
        messageType: message.message_type,
        message: message.message,
        sisoMessage: message.siso_message,
        sendSMS: message.send_as_sms,
        selectedCount: message.kids.length,
        subject: message.subject,
        selectedIds: message.kids.reduce((map, kid) => {
          map[kid.id] = true;
          return map;
        }, {})
      });
    }

    req.students();
    req.rooms();
    req.tags();
  }

  @bind
  onSelectType(messageType) {
    this.setState({ messageType });
  }

  @bind
  updateForm(data) {
    this.setState(data);
  }

  @bind
  updateFilters(filters) {
    this.setState({
      filters: { ...this.state.filters, ...filters },
      selectedCount: 0,
      selectedIds: {}
    });
  }

  @bind
  sendMessage() {
    const { subject, message, sisoMessage, selectedStudents, messageType, sendSMS } = this.state;
    const { onClose } = this.props;

    this.setState({ isSaving: true });

    const promise =
      messageType === 'newsletter'
        ? req.newSchoolMessage({
            draft: false,
            message_type: messageType,
            siso_message: sisoMessage,
            send_as_sms: sendSMS,
            kid_ids: selectedStudents,
            subject,
            message
          })
        : req.sendMessage({
            message: {
              siso_message: sisoMessage,
              send_as_sms: sendSMS,
              kid_ids: selectedStudents,
              text: message
            }
          });

    return promise
      .then(() => {
        this.setState({ isSaving: false });
        onClose('sent');
      })
      .catch(err => {
        Actions.reportError('Something went wrong', err);
        this.setState({ isSaving: false });
      });
  }

  @bind
  saveDraft() {
    const { subject, message, sisoMessage, selectedStudents, messageType, sendSMS, isNew, id } = this.state;
    const { onClose } = this.props;
    const reqType = isNew ? 'newSchoolDraftMessage' : 'updateSchoolDraftMessage';
    const payload = {
      draft: true,
      message_type: messageType,
      siso_message: sisoMessage,
      send_as_sms: sendSMS,
      kid_ids: selectedStudents,
      subject: messageType === 'newsletter' ? subject : '',
      message
    };

    if (!isNew) {
      payload.id = id;
    }

    this.setState({ isSavingDraft: true });
    req[reqType](payload)
      .then(() => {
        this.setState({ isSavingDraft: false });
        onClose('draft');
      })
      .catch(err => {
        console.log(err);
        this.setState({ isSavingDraft: false });
      });
  }

  @bind
  selectStudents(studentIds) {
    this.setState({ selectedStudents: studentIds });
  }

  render() {
    const { rooms, students, tags, onClose } = this.props;
    const { message, subject, sisoMessage, sendSMS, selectedStudents, filters, messageType } = this.state;

    return (
      <div className="new-message">
        <Steps startStep={1}>
          <Steps.Item>
            <MessageTypeStep onSelectType={this.onSelectType} />
          </Steps.Item>

          <Steps.Item>
            <MessageStep
              message={message}
              messageType={messageType}
              subject={subject}
              sisoMessage={sisoMessage}
              sendSMS={sendSMS}
              onClose={onClose}
              onUpdateForm={this.updateForm}
              saveDraft={this.saveDraft}
              isSaving={this.state.isSavingDraft}
            />
          </Steps.Item>

          <Steps.Item>
            <SelectStudentStep
              rooms={rooms}
              students={students}
              tags={tags}
              filters={filters}
              messageType={messageType}
              onSelect={this.selectStudents}
              selectedStudents={selectedStudents}
              updateFilters={this.updateFilters}
              saveDraft={this.saveDraft}
              sendMessage={this.sendMessage}
              isSaving={this.state.isSaving}
              isSavingDraft={this.state.isSavingDraft}
              sendSMS={sendSMS}
            />
          </Steps.Item>
        </Steps>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data,
  students: state.students.data,
  tags: state.tags.data
});

export default connect(mapStateToProps)(NewMessage);
