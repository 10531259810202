import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonV2, Preloader } from 'components';
import { CSSTransition } from 'react-transition-group';
import { listenClick } from 'lib/utils';
import './style.scss';

class EditPopup extends Component {
  static propTypes = {
    className: PropTypes.string,
    onSubmit: PropTypes.func, // Must return a promise
    onCancel: PropTypes.func,
    visible: PropTypes.bool,
    loading: PropTypes.bool
  };

  container = null;

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      setTimeout(() => document.addEventListener('click', this.listenClick), 250);
    }

    if (prevProps.visible && !this.props.visible) {
      document.removeEventListener('click', this.listenClick);
    }
  }

  @bind
  bindContainer(node) {
    this.container = node;
  }

  @bind
  listenClick(e) {
    listenClick(e, this.container, this.props.onSubmit);
  }

  render() {
    const { visible, loading, children, onCancel, onSubmit } = this.props;
    const className = classNames('edit-popup', this.props.className);

    return (
      <CSSTransition in={visible} timeout={300} classNames="edit-popup-transition" unmountOnExit>
        {() => (
          <div ref={this.bindContainer} className={className}>
            <div className="edit-popup__content">{children}</div>

            <div className="edit-popup__actions">
              <div className="edit-popup__submit">
                {loading ? (
                  <Preloader small className="edit-popup__loading" />
                ) : (
                  <ButtonV2 icon iconName="tick" iconSize={21} onClick={onSubmit} />
                )}
              </div>

              <div className="edit-popup__cancel">
                <ButtonV2 icon iconName="close-circle" iconSize={21} onClick={onCancel} />
              </div>
            </div>
          </div>
        )}
      </CSSTransition>
    );
  }
}

export default EditPopup;
