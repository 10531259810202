import { capitalize } from 'lib/utils';

export default function initializeModals() {
  const ctx = require.context('modals', true, /\.js$/);
  // An object where we store modal component modules
  const ModalComponents = {};

  // Initialize all components
  for (const k of ctx.keys()) {
    // Avoid circular dependency
    if (k.startsWith('./ModalController')) {
      continue;
    }
    ModalComponents[k] = ctx(k).default;
  }

  // The resulting function is used to "require" modals instead of direct require
  return modalName => ModalComponents[`./${capitalize(modalName)}/index.js`];
}
