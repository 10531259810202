import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children, id }) => {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    const mount = document.getElementById('root-portal');
    mount.appendChild(el.current);
    return () => mount.removeChild(el.current);
  }, []);

  return createPortal(children, el.current, id);
};

export default Portal;
