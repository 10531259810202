import React from 'react';
import get from 'lodash/get';
import Mapping from './mapping';

function ReportFields({ data }) {
  return data.form_data.map(formInput => {
    const Input = Mapping[formInput];

    if (!Input) {
      console.error(`Report field for ${formInput} not found`);
      return null;
    }

    return <Input key={formInput} name={formInput} formOptions={get(data, `form_options.${formInput}`)} />;
  });
}

export default ReportFields;
