import React, { useEffect, useState } from 'react';

import { FILTER_TYPE, queryCategories, queryLessons, queryTopics } from 'lib/marcoPolo';
import { ButtonV2, InfiniteScroll, Preloader } from 'components';
import Accordion from './Accordion';
import AccordionItem from './AccordionItem';
import Filter from './Filter';
import Lesson from './Lesson';

import './style.scss';

function AddMarcoPoloMaterial({ onClose }) {
  // Monitor the differences between a full page load with categories and topics vs a page load with just lessons.
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [serviceAvailable, setServiceAvailable] = useState(true);

  const [page, setPage] = useState(0);
  const [noMoreResults, setNoMoreResults] = useState(false);

  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [lessons, setLessons] = useState([]);

  const defaultFilter = {
    filterType: FILTER_TYPE.ALL,
    searchTerm: '',
    topic: '',
    itemIds: []
  };

  const [filters, setFilters] = useState(defaultFilter);

  /** Selected material is an array of objects that represent the material that the user has selected to add to the lesson.
   const material = [
    {
      lessonId: 1,
      title: 'Lesson 1',
      type: 'video',
      url: 'https://www.youtube.com/watch?v=1jXZd8cZ2Y0'
    }
   ]
   */
  const [selectedMaterial, setSelectedMaterial] = useState([]);

  useEffect(() => {
    loadCategories();
    loadTopics();
  }, []);

  useEffect(() => {
    loadLessons();
  }, [filters]);

  const loadCategories = async () => setCategories(await queryCategories());

  const loadTopics = async () => setTopics(await queryTopics());

  const onFilterChanged = filters => {
    setFilters(filters);

    // If filters have changed, reset tracking pages and more results until the lesson query runs again.
    setPage(0);
    setNoMoreResults(false);
  };

  const onFilterReset = () => {
    setFilters(defaultFilter);
  };

  const loadLessons = async () => {
    if (
      (filters.filterType === FILTER_TYPE.TOPIC && !filters.topic) ||
      (filters.filterType === FILTER_TYPE.CURRICULUM && !filters.itemIds?.length) ||
      (filters.filterType === FILTER_TYPE.SUBJECT && !filters.itemIds?.length)
    ) {
      return;
    }

    setLoading(true);
    const result = await queryLessons(filters?.searchTerm, filters?.topic, filters?.itemIds);
    if (!result.success) {
      setServiceAvailable(false);
      return;
    }

    setLessons(result.lessons);
    setLoading(false);
  };

  const hasLessons = () => lessons?.length > 0;

  const onLessonNextPage = async () => {
    // Infinite scroll will continue to fire when scrolling to the end of a page, even if there are no more results.
    //  This prevents additional queries after we've received zero results.
    if (noMoreResults) {
      return;
    }

    setLoadingPage(true);
    const result = await queryLessons(filters?.searchTerm, filters?.topic, filters?.itemIds, page + 1);
    if (!result.success) {
      setServiceAvailable(false);
      return;
    }

    if (result.lessons.length === 0) {
      setNoMoreResults(true);
    }

    setLessons(lessons.concat(result.lessons));
    setPage(page + 1);
    setLoadingPage(false);
  };

  const addMaterial = material => {
    setSelectedMaterial([...selectedMaterial, material]);
  };

  const removeMaterial = material => {
    // Selected material should be unique by url alone, but just in-case there's duplicates across lessons, we'll filter by lessonId as well.
    setSelectedMaterial(selectedMaterial.filter(m => m.lessonId !== material.lessonId || m.url !== material.url));
  };

  const close = () => {
    onClose(selectedMaterial);
  };

  const renderServiceUnavailable = () => (
    <div className="add-material__service-unavailable">
      <div className="add-material__service-unavailable__title">
        Oops! Looks like MarcoPolo is currently unavailable.
      </div>
      <div className="add-material__service-unavailable__info">
        Please close the modal and try again. If the issue continues please contact your administrator to report the
        issue.
      </div>
      <img src="/assets/images/service-unavailable.png" />
    </div>
  );

  const renderNoresults = () => {
    if (!filters.searchTerm || loading || hasLessons()) {
      return;
    }

    return (
      <div className="add-material__results__empty">
        <div className="add-material__results__empty__title">No lessons found.</div>
        <div className="add-material__results__empty__info">Please refine your search and try again.</div>
        <img src="/assets/images/service-unavailable.png" />
      </div>
    );
  };

  const renderFilter = () => (
    <>
      <div className="add-material__title">Filter by Topic, Subject, or Curriculum to Add Learning Materials</div>
      <div className="add-material__filter">
        <Filter
          filters={filters}
          categories={categories}
          topics={topics}
          onFilterChanged={onFilterChanged}
          onFilterReset={onFilterReset}
        />
      </div>
    </>
  );

  return (
    <div className="modal__wrapper add-material">
      <div className="modal__header add-material__header">
        <img className="integration-item__image" src="/assets/images/marketplace/MarcoPolo.png" />
      </div>
      <div className="modal__container">
        {!serviceAvailable && renderServiceUnavailable()}
        {serviceAvailable && (
          <>
            {renderFilter()}
            <div className="add-material__results">
              {renderNoresults()}
              {loading && <Preloader center />}
              {hasLessons() && (
                <>
                  <h3>Videos</h3>
                  <Accordion>
                    <InfiniteScroll
                      className="add-material__results__scroll"
                      loading={loading}
                      onEndReach={onLessonNextPage}
                      threshold={500}
                    >
                      {lessons &&
                        lessons.map(lesson => {
                          return (
                            <AccordionItem key={lesson.id} title={lesson.title} thumbnail={lesson.thumbnail}>
                              <Lesson
                                lesson={lesson}
                                selectedMaterial={selectedMaterial.filter(lm => lm.lessonId === lesson.id)}
                                addMaterial={addMaterial}
                                removeMaterial={removeMaterial}
                              />
                            </AccordionItem>
                          );
                        })}
                      {loadingPage && <Preloader small center />}
                      {noMoreResults && <div className="add-material__results__no-more">No more results</div>}
                    </InfiniteScroll>
                  </Accordion>
                </>
              )}
            </div>
          </>
        )}
      </div>

      <div className="modal__controls">
        <ButtonV2 secondary label="Cancel" onClick={onClose} />
        <ButtonV2 label="Add To Lesson" onClick={close} disabled={selectedMaterial.length === 0} />
      </div>
    </div>
  );
}

export default AddMarcoPoloMaterial;
