import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon, Avatar, Preloader } from 'components';
import './style.scss';

class AvatarForm extends Component {
  static propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    type: PropTypes.oneOf(['avatar', 'logo']),
    onUpdate: PropTypes.func,
    isSaving: PropTypes.bool,
    color: PropTypes.string,
    name: PropTypes.string
  };

  static defaultProps = {
    size: 'medium',
    type: 'avatar',
    isSaving: false
  };

  @bind
  getSize(type) {
    return {
      width: type === 'logo' ? 300 : 200,
      height: type === 'logo' ? 100 : 200
    };
  }

  @bind
  onStartCrop(imgDataURL) {
    const { onUpdate, type } = this.props;

    Actions.showModal('AvatarCrop', {
      imgDataURL,
      aspect: type === 'logo' ? 3 : 1,
      ...this.getSize(type)
    })
      .then(croppedImage => {
        onUpdate(croppedImage);
      })
      .catch(); // do nothing on reject
  }

  /**
   * Update value of a field
   *
   * @param {object} e - event object
   */
  @bind
  handleChange(e) {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      this.onStartCrop(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  render() {
    const { className, size, type, value, color, name, isSaving, onlineStatus } = this.props;
    const avatarSize = `avatar--${size}`;
    const avatarUploaderCN = classNames({
      'avatar-form': true,
      [className]: Boolean(className)
    });
    const { width, height } = this.getSize(type);

    return (
      <div className={avatarUploaderCN}>
        <Avatar
          className={avatarSize}
          url={value}
          type={type}
          size={size}
          color={color}
          name={name}
          onlineStatus={onlineStatus ? 'online' : 'offline'}
        />

        {isSaving && (
          <div className="avatar-form__loading">
            <Preloader />
          </div>
        )}

        <div className="avatar-form__action">
          <Icon name="camera" size={28} />
          <span className="avatar-form__action-text">
            Upload Photo <br />({width}x{height})
          </span>
          <input
            className="avatar-form__field"
            type="file"
            value=""
            onChange={this.handleChange}
            accept="image/x-png,image/jpeg"
          />
        </div>
      </div>
    );
  }
}

export default AvatarForm;
