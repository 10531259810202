export const ZIP_NAME = {
  US: 'Zip',
  GB: 'Postcode',
  AU: 'Postcode',
  CA: 'Postal'
};

export const EIN_NAME = {
  US: 'EIN (TAX ID)',
  GB: 'Company Number',
  AU: 'Company ACN/ABN',
  CA: 'Business Number (Tax ID)'
};

export const ROUTING_NUMBER_NAME = {
  US: 'Routing Number',
  GB: 'Sort Code',
  AU: 'BSB',
  CA: 'Transit Number'
};
