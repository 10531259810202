import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TooltipTrigger, ButtonV2 } from 'components';
import ListEditor from './ListEditor';
import BooleanEditor from './BooleanEditor';
import StringEditor from './StringEditor';
import DateEditor from './DateEditor';
import IntegerEditor from './IntegerEditor';
import DecimalEditor from './DecimalEditor';
import ValueRenderer from './ValueRenderer';
import './style.scss';

const EDITORS = {
  string: StringEditor,
  list: ListEditor,
  integer: IntegerEditor,
  decimal: DecimalEditor,
  date: DateEditor,
  boolean: BooleanEditor
};

function CustomAttribute({
  type,
  name,
  description,
  onChange,
  value,
  extra,
  disabled,
  readOnly,
  className,
  'data-cy': dataCY
}) {
  const Editor = EDITORS[type];

  if (!Editor) {
    console.error(`Unknown custom field type - ${type}`);
    return null;
  }

  return (
    <div className={cx('info-list__item custom-attribute', className)} data-cy={dataCY}>
      <span>{name}</span>
      <div className="custom-attribute__content">
        <ValueRenderer type={type} value={value} extra={extra} />
        {(!readOnly || type === 'boolean') && (
          <Editor
            defaultValue={value}
            onChange={onChange}
            extra={extra}
            disabled={disabled || readOnly}
            locked={disabled}
          />
        )}
      </div>
      {description && (
        <TooltipTrigger tooltip={description} side="right" className="custom-attribute__tooltip">
          <ButtonV2 icon iconName="tooltip" iconSize={18} />
        </TooltipTrigger>
      )}
    </div>
  );
}

CustomAttribute.propTypes = {
  type: PropTypes.oneOf(['string', 'list', 'integer', 'decimal', 'date', 'boolean']).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  extra: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

export default CustomAttribute;
