import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import ManageTableItem from './ManageTableItem';
import ManageTableNew from './ManageTableNew';
import './style.scss';

class ManageTable extends PureComponent {
  static propTypes = {
    // An array of items
    items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),

    // Is new item form rendered
    isNew: PropTypes.bool,

    // Id of edited item
    editItemId: PropTypes.string,

    // Create new item using data from new form
    onNewCreate: PropTypes.func,

    // Cancel new item creation, close form
    onNewCancel: PropTypes.func,

    // Display edit form of an item
    onEditStart: PropTypes.func,

    // Hide edit form of an item
    onEditEnd: PropTypes.func,

    // Write changes of item
    onUpdate: PropTypes.func,

    // Write changes of all items
    onReplace: PropTypes.func,

    // Delete an item
    onDelete: PropTypes.func,

    // Returns JSX for custom header
    renderHeader: PropTypes.func,

    // Component type for item content
    ItemComponent: PropTypes.func,

    // Custom menu items
    menu: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.string,
        value: PropTypes.any,
        onClick: PropTypes.func
      })
    ),

    // Do not wrap and render ItemComponent directly
    renderCustom: PropTypes.bool,

    // Additional props passed from client
    itemProps: PropTypes.object
  };

  // updateId and deleteId are used to display loading/disabled state for items
  state = {
    updateId: undefined,
    deleteId: undefined
  };

  scrollbarsRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (!prevProps.isNew && this.props.isNew) {
      this.scrollbarsRef.current.scrollToTop();
    }
  }

  @bind
  async handleUpdate(updateId, item) {
    try {
      await this.props.onUpdate(updateId, item);
    } finally {
      this.setState({ updateId: undefined });
    }
  }

  @bind
  handleDelete(deleteId) {
    const deleteResult = this.props.onDelete(deleteId);

    if (deleteResult instanceof Promise) {
      this.setState({ deleteId });
      deleteResult.then(() => this.setState({ deleteId: undefined }));
    }
  }

  renderNewForm() {
    const { ItemComponent, onNewCreate, onNewCancel, renderCustom, itemProps } = this.props;
    return (
      <ManageTableNew
        renderCustom={renderCustom}
        ItemComponent={ItemComponent}
        onCreate={onNewCreate}
        onCancel={onNewCancel}
        itemProps={itemProps}
      />
    );
  }

  renderItems() {
    const { items, ItemComponent, editItemId, onEditStart, onEditEnd, menu, itemProps, renderCustom } = this.props;
    const { updateId, deleteId } = this.state;

    return items.map(i => (
      <ManageTableItem
        key={i.id}
        item={i}
        menu={menu}
        renderCustom={renderCustom}
        ItemComponent={ItemComponent}
        isEdit={i.id === editItemId}
        onEditStart={onEditStart}
        onEditEnd={onEditEnd}
        onUpdate={this.handleUpdate}
        onDelete={this.handleDelete}
        isUpdateLoading={i.id === updateId}
        isDeleteLoading={i.id === deleteId}
        itemProps={itemProps}
      />
    ));
  }

  render() {
    const { isNew, renderHeader } = this.props;

    return (
      <div className="table manage-table">
        {renderHeader && (
          <div className="table__header">
            {renderHeader()}
            <div className="table__cell table__cell--xs table__cell--actions" />
          </div>
        )}
        <Scrollbars ref={this.scrollbarsRef} className="manage-table__scroll">
          <div className="table__body">
            <div className="manage-table__items">
              {isNew && this.renderNewForm()}
              {this.renderItems()}
            </div>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default ManageTable;
