import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';

const FilterItemSelect = ({
  name,
  options = {},
  icons,
  layout = {},
  onChange,
  disableDropdown,
  disabledOptions,
  ...otherProps
}) => {
  const isDisabled = option => disabledOptions.includes(option);

  return (
    <Form.Row layout={layout}>
      <Form.Select
        data-cy={name}
        name={name}
        {...otherProps}
        onChange={(name, value) => onChange(name, value)}
        disableDropdown={disableDropdown}
      >
        {Object.keys(options).map(s => (
          <Form.Select.Item
            key={s}
            value={s}
            label={options[s]}
            icon={icons ? icons[s] : undefined}
            disabled={isDisabled(s)}
          />
        ))}
      </Form.Select>
    </Form.Row>
  );
};

FilterItemSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.object,
  layout: PropTypes.object
};

export default FilterItemSelect;
