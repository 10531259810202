import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InvoicePreviewCard, ButtonV2 } from 'components';
import './style.scss';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class Step1 extends Component {
  static propTypes = {
    invoice: PropTypes.object,
    title: PropTypes.string,
    onClose: PropTypes.func,
    showPrint: PropTypes.bool
  };

  static defaultProps = {
    invoice: {},
    showPrint: true
  };

  UNSAFE_componentWillMount() {
    req.school();
  }

  @bind
  showBreakdown(breakdown) {
    this.props.showBreakdown(breakdown);
    this.props.context.nextStep();
  }

  printInvoice() {
    // stop loading background scripts like hmr or intercom to allow window.print() run. Need to discuss.
    window.stop();
    window.print();
  }

  render() {
    const { invoice, students, title, showPrint, onClose, sendInvoice, hasSendInvoice, isSending } = this.props;

    return (
      <div className="show-invoice">
        <div className="modal__header">
          <div className="modal__header-title">{hasSendInvoice ? 'Send Invoice to Family' : title}</div>
        </div>

        <div className="modal__container">
          <InvoicePreviewCard
            data={{ parent_invoice: invoice }}
            items={invoice.parent_invoice_items}
            students={students}
            showBreakdown={this.showBreakdown}
            noScroll
          />
        </div>

        <div className="modal__controls">
          {showPrint && <ButtonV2 tertiary iconName="print" onClick={this.printInvoice} label="Print" />}
          <ButtonV2 label="Close" secondary={hasSendInvoice} onClick={onClose} />
          {hasSendInvoice && <ButtonV2 isLoading={isSending} label="Send Invoice" onClick={sendInvoice} />}
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(Step1);
