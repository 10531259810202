import React, { useState } from 'react';
import { Button, Form } from 'components';
import { buildSignatureContext } from 'lib/signableActivity';
import Signature from 'modals/common/ActivityForms/Signature';
import './style.scss';

/**
 * Modal to request and create digital signatures on incident activities.
 */
const IncidentReportSignature = props => {
  const signatureContext = buildSignatureContext(props.data.activity);
  const adminSignature = signatureContext.adminSignature;
  const staffSignature = signatureContext.staffSignature || adminSignature;

  const [formValues, setFormValues] = useState({
    requireAdminSignature: false,
    useStaffSignature: true,
    requestParentSignature: false
  });

  const close = result => {
    props.onClose(result);
  };

  const onFormChange = values => {
    setFormValues(values);
  };

  const generateReport = () => {
    const {
      requireAdminSignature,
      signature,
      signatureDataUrl,
      useStaffSignature,
      requestParentSignature
    } = formValues;

    const { activity } = props.data;

    if (requireAdminSignature && !adminSignature) {
      if (!signature) {
        Actions.showFlash('Admin signature is required.', 'alert');
        return;
      }
      if (signature.length < 3) {
        Actions.showFlash('Admin signature requires at least 3 characters.', 'alert');
        return;
      }
    }

    const adminSigPayload = {
      attached: requireAdminSignature
    };

    if (adminSignature) {
      adminSigPayload.signature_id = adminSignature.id;
    } else if (requireAdminSignature) {
      adminSigPayload.signature_file = signatureDataUrl;
    }

    const teacherSigPayload = {
      attached: useStaffSignature,
      signature_id: staffSignature.id
    };

    req
      .updateIncidentSignatures({
        incidentId: activity.activiable.id,
        kidId: activity.kid_ids[0],
        request_digital: requestParentSignature,
        signatures: {
          admin: adminSigPayload,
          teacher: teacherSigPayload
        }
      })
      .then(response => {
        Actions.showFlash('Incident report created successfully');
        close({
          signatures: response?.kid_incident?.signatures,
          requestParentSignature
        });
      })
      .catch(error => {
        Actions.reportError('There was a problem creating the incident report', error);
      });
  };

  return (
    <div className="incident-report-signature">
      <div className="modal__header modal__header--bordered">
        <div className="modal__header-title">Incident Report </div>
      </div>

      <div className="incident-report-signature modal__container">
        <div className="form__row">
          <Form className="form__body" onChange={onFormChange}>
            <div className="form__row">
              <Form.Checkbox
                name="requireAdminSignature"
                label="Admin Signature"
                defaultValue={formValues.requireAdminSignature}
              />
            </div>
            {adminSignature ? (
              <div className="form__row">
                <img src={adminSignature.url} className="incident-report-signature__signature-preview" />
              </div>
            ) : (
              <Signature hideHeader={true} />
            )}
            {staffSignature && (
              <div className="form__row">
                <Form.Checkbox
                  name="useStaffSignature"
                  label="Use Staff Signature from app"
                  defaultValue={formValues.useStaffSignature}
                />
              </div>
            )}
            {staffSignature && (
              <div className="form__row">
                <img src={staffSignature?.url} className="incident-report-signature__signature-preview" />
              </div>
            )}
            <div className="form__row">
              <Form.Checkbox
                name="requestParentSignature"
                label="Request a Digital Signature from Parents"
                defaultValue={formValues.requestParentSignature}
              />
            </div>
            <div className="form__row">
              <div className="incident-report-signature__note">
                <label>Important:</label>
                <br />
                <ul>
                  <li>This report will be shared with all parents on the account.</li>
                  <li>Only 1 parent signature is required.</li>
                  <li>By sending the report, you are authorizing admin approval.</li>
                  <li>Once the report has been sent, you will be unable to edit it.</li>
                </ul>
              </div>
            </div>
          </Form>
        </div>
        <div className="form__row form__row--actions incident-report-signature--actions">
          <Button
            className="button"
            onClick={generateReport}
            label={formValues.requestParentSignature ? 'Send Report' : 'Generate Report'}
          />
        </div>
      </div>
    </div>
  );
};

export default IncidentReportSignature;
