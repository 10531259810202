import React, { PureComponent } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { ButtonV2, TextInput, Icon } from 'components';
import { PDFActions } from 'screens/teacher/Documents/common';
import Row from './Row';
import './style.scss';

class IncidentReport extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      selecting: false,
      selectedKid: null
    };
  }

  get students() {
    const { kids } = this.props;
    const { kid_ids } = this.props.data.activity;
    const { search } = this.state;

    return kid_ids
      .map(id => kids.find(k => k.id === id))
      .filter(k => Boolean(k) && k.name.toLowerCase().includes(search.toLowerCase()));
  }

  @bind
  async handleSubmit() {
    const { activity } = this.props.data;
    const { selectedKid } = this.state;

    try {
      this.setState({ loading: true });

      const pdfData = await req.reportIncident({
        id: activity.activiable.id,
        kid_id: selectedKid
      });
      PDFActions.open(pdfData);
      this.setState({ loading: false });
      this.props.onClose();
    } catch (e) {
      Actions.reportError('There was problem generating report', e);
      this.setState({ loading: false });
    }
  }

  @bind
  async selectKid(kid) {
    this.setState({ selectedKid: kid.id });
  }

  renderRows() {
    const { selectedKid } = this.state;

    return this.students.map(s => <Row key={s.id} data={s} onClick={this.selectKid} selected={selectedKid === s.id} />);
  }

  render() {
    const { loading, selectedKid } = this.state;
    const { onClose } = this.props;

    return (
      <div className="incident-report">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Incident Report</div>
        </div>

        <div className="modal__container">
          <div className="incident-report__search">
            <TextInput
              className="incident-report__search-input"
              placeholder="Filter Students"
              onChange={s => this.setState({ search: s })}
            />
            <Icon name="search" className="incident-report__search-icon" />
          </div>
          <div className="incident-report__list-container">
            <Scrollbars autoHeight autoHeightMax={320}>
              <div className="incident-report__list">{this.renderRows()}</div>
            </Scrollbars>
          </div>
        </div>

        <div className="modal__controls">
          <ButtonV2 label="Cancel" secondary onClick={onClose} disabled={loading} />
          <ButtonV2 label="Generate" onClick={this.handleSubmit} disabled={!selectedKid} isLoading={loading} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  kids: state.students.data
});

export default connect(mapStateToProps)(IncidentReport);
