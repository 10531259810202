import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, ActionButton } from 'components';
import { connect } from 'react-redux';
import { isAdmin } from 'lib/utils/selectors';
import ActivityForms from '../common/ActivityForms';
import { CREATABLE_ACTIVITY_TYPES } from 'constants/activityTypes';
import EditActivityStudentList from './EditActivityStudentList';
import withContext, { CreateActivityContext, ModalControllerStepsContext } from 'hocs/withContext';
import { compose } from 'redux';

class NewActivity extends Component {
  static propTypes = {
    FormComponent: PropTypes.func,
    constants: PropTypes.object,
    activity: PropTypes.object,
    isSaving: PropTypes.bool,
    kidIds: PropTypes.array,
    onUpdate: PropTypes.func,
    onUpdateKids: PropTypes.func,
    onSave: PropTypes.func
  };

  static defaultProps = {};

  render() {
    const {
      type,
      constants,
      onSave,
      isSaving,
      kidIds,
      onUpdate,
      activity,
      staffOnlyActivities,
      isAdmin,
      onUpdateKids
    } = this.props;
    const { currentStep } = this.props.context;
    const { uploadedPhotos } = this.props.createActivityContext || {};
    const FormComponent = ActivityForms[type];
    const activityType = CREATABLE_ACTIVITY_TYPES.find(t => t.id === type);

    const isDisabled =
      type === 'photo_activity' &&
      activity.data.photo_ids &&
      activity.data.photo_ids.length === 0 &&
      uploadedPhotos.length === 0;
    const forceStaff = !isAdmin && staffOnlyActivities.includes(type);

    if (isAdmin && staffOnlyActivities.includes(type)) {
      activity.is_staff_only = true;
    }

    return (
      <div className="create-activity__new-activity">
        <div className="modal__header create-activity__header">
          <div className="create-activity__header-title">
            <ActionButton iconName="back" className="modal__back" onClick={this.props.context.prevStep} />
            <span className="modal__header-title">{activityType && activityType.label} Activity</span>

            <div className="modal__header-steps">
              <span>Step {currentStep}</span> / 2
            </div>
          </div>
        </div>

        <Form className="create-activity__form" key={type} onSubmit={onSave} onChange={onUpdate} isLoading={isSaving}>
          <div className="create-activity__form-container">
            <FormComponent forceStaff={forceStaff} constants={constants} activity={activity} onChange={onUpdate} />
            <EditActivityStudentList value={kidIds} onChange={onUpdateKids} isEditing={!!activity.id} />
          </div>
          <div className="form__row--actions create-activity__actions">
            {/* <div className="create-activity__helper">
              Push notifications will not be sent to parents for activities created on the website.
            </div> */}
            <Form.Submit label="Save" data-cy="continue" disabled={isDisabled} />
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: isAdmin(state),
  staffOnlyActivities: state.currentUser.data.current_school.staff_only_activities
});

const enhance = compose(
  withContext(ModalControllerStepsContext),
  withContext(CreateActivityContext, 'createActivityContext'),
  connect(mapStateToProps)
);

export default enhance(NewActivity);
