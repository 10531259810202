import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Steps from '../ModalController/Steps';
import EditActivityForm from './EditActivityForm';
import EditGroupedActivityForm from './EditGroupedActivityForm';
import ManageLearningActivities from '../ManageLearningActivities';

class EditActivity extends PureComponent {
  static propTypes = {
    data: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  render() {
    const { data, onClose } = this.props;
    const activity_type = data.activity.activity_type;

    return (
      <Steps>
        <Steps.Item>
          {activity_type !== 'incident_activity' ? (
            <EditGroupedActivityForm data={data} onClose={onClose} />
          ) : (
            <EditActivityForm data={data} onClose={onClose} />
          )}
        </Steps.Item>
      </Steps>
    );
  }
}

export default EditActivity;
