/* eslint-disable no-undef */
import React, { Component } from 'react';
import cx from 'classnames';
import { RichEditor, ButtonV2, TextInput } from 'components';
import './style.scss';

const froalaConfig = {
  height: 245,
  toolbarSticky: false,

  toolbarButtons: {
    moreRich: {
      buttons: ['insertLink']
    },
    moreMisc: {
      buttons: ['undo', 'redo']
    }
  }
};

class SendRegEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: '',
      content: '',
      isSubjectValid: true,
      isContentValid: true,
      isLoading: false
    };
  }

  @bind
  handleTextChange(content) {
    this.setState({ content });
  }

  @bind
  handleSubjectChange(subject) {
    this.setState({ subject });
  }

  @bind
  submit() {
    const { subject, content } = this.state;
    const { registration_ids } = this.props.data;

    if (!subject) {
      this.setState({ isSubjectValid: false });
      return;
    } else if (!content) {
      this.setState({ isContentValid: false });
      return;
    }

    this.setState({ isSubjectValid: true, isContentValid: true, isLoading: true });

    return req
      .sendEnrollmentEmail({
        registration_ids,
        subject,
        content
      })
      .then(() => {
        this.setState({ isLoading: false });
        Actions.showFlash('Emails has been sent');
        this.props.onClose();
      })
      .catch(err => {
        Actions.reportError('There was error sending emails', err);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { subject, content, isSubjectValid, isContentValid, isLoading } = this.state;
    const { kidName } = this.props.data;

    return (
      <div className="send-reg-email">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">Email Parents of "{kidName}"</div>
        </div>

        <div className="modal__container">
          <div className="form__row">
            <div className={cx('form-input', { 'form-input--invalid': !isSubjectValid })}>
              <div className="form-input__field">
                <TextInput name="subject" placeholder="Subject" value={subject} onChange={this.handleSubjectChange} />
              </div>

              {!validations.name && <div className="form-input__validation-text">{validations.name}</div>}
            </div>
          </div>

          <div className="form__row">
            <div className={cx('form-input', { 'form-input--invalid': !isContentValid })}>
              <div className="form-input__field">
                <RichEditor
                  name="content"
                  placeholder="Write a message"
                  value={content}
                  config={froalaConfig}
                  onChange={this.handleTextChange}
                />
              </div>
              {!isContentValid && <div className="form-input__validation-text">Cannot be empty</div>}
            </div>
          </div>
        </div>

        <div className="modal__controls">
          <ButtonV2 isLoading={isLoading} label="Send Email" onClick={this.submit} />
        </div>
      </div>
    );
  }
}

export default SendRegEmail;
