import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, ActionButton } from 'components';
import moment from 'moment';
import './style.scss';

class WeekPicker extends PureComponent {
  static propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func
  };

  @bind
  onWeekBack() {
    const week = this.props.value;

    const prevWeek = [moment(week[0]).subtract(1, 'week'), moment(week[1]).subtract(1, 'week')];

    this.props.onChange(prevWeek);
  }

  @bind
  onWeekForward() {
    const week = this.props.value;

    const nextWeek = [moment(week[0]).add(1, 'week'), moment(week[1]).add(1, 'week')];

    this.props.onChange(nextWeek);
  }

  render() {
    const { value, onChange } = this.props;

    return (
      <div className="lp-week-picker">
        <ActionButton
          className="lp-week-picker__arrow-left"
          iconName="chevron-down"
          size={16}
          onClick={this.onWeekBack}
        />
        <DatePicker type="weekly" className="lp-week-picker__picker" value={value} onChange={onChange} side="center" />
        <ActionButton
          className="lp-week-picker__arrow-right"
          iconName="chevron-down"
          size={16}
          onClick={this.onWeekForward}
        />
      </div>
    );
  }
}

export default WeekPicker;
