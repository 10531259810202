import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Avatar, TooltipPortalTrigger } from 'components';
import './style.scss';

const getPersonName = (person, suffix) => {
  if (!person) {
    return null;
  }
  const name = person.name || `${person.first_name} ${person.last_name}`;
  if (suffix) {
    return `${name} (${suffix})`;
  }

  return name;
};

const Recipient = ({ data, suffix }) => {
  if (data === undefined) {
    return null;
  }

  return (
    <div className="documents-recipient">
      <TooltipPortalTrigger
        tooltipClassName="documents-recipient__tooltip"
        tooltip={getPersonName(data, suffix)}
        allowPropagation={true}
      >
        <div className="documents-recipient__inner">
          <Avatar
            className="mr-8"
            initials={get(data, 'initials')}
            name={getPersonName(data)}
            color={get(data, 'color')}
            url={get(data, 'profile_pic_url')}
            type={get(data, 'profile_pic_url') ? 'avatar' : undefined}
          />
          <p className="documents-recipient__text">{getPersonName(data, suffix)}</p>
        </div>
      </TooltipPortalTrigger>
    </div>
  );
};

Recipient.propTypes = {
  data: PropTypes.object,
  suffix: PropTypes.bool
};

export default Recipient;
