import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Preloader } from 'components';
import './style.scss';

class Loading extends Component {
  static propTypes = {
    children: PropTypes.node
  };

  static defaultProps = {
    children: 'Loading...'
  };

  render() {
    return (
      <div className="loading-screen">
        <Preloader large />
        <div className="loading-screen__text">{this.props.children}</div>
      </div>
    );
  }
}

export default Loading;
