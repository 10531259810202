import React, { Fragment, useRef, useState } from 'react';
import * as htmlToImage from 'html-to-image';
import { Form } from 'components';

const Signature = ({ signatureUrl, hideHeader, label = 'Staff Signature' }) => {
  const previewRef = useRef(null);
  const fileRef = useRef(null);
  const [signature, setSignature] = useState(null);

  const handleSignatureChange = text => {
    setSignature(text);
    htmlToImage.toPng(previewRef.current).then(dataUrl => {
      fileRef.current.setData(dataUrl);
    });
  };

  return (
    <Fragment>
      <div className="form__row signature">
        <div className="signature__form">
          {!hideHeader && <label className="form__label">{label}</label>}
          {signatureUrl ? (
            <img src={signatureUrl} />
          ) : (
            <Form.Input name="signature" required onType={handleSignatureChange} />
          )}
        </div>
      </div>
      {!signatureUrl && (
        <div className="form__row">
          <div className="signature__data-container">
            <div id="sigRef" ref={previewRef} className="signature__data">
              {signature}
            </div>
          </div>
          <Form.Hidden ref={fileRef} name="signatureDataUrl" defaultValue={''} />
        </div>
      )}
    </Fragment>
  );
};

export default Signature;
