import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollBars from 'react-custom-scrollbars';
import moment from 'moment';
import InvoicePreviewItem from './InvoicePreviewItem';
import InvoicePreviewHeader from './InvoicePreviewHeader';
import InvoicePreviewContent from './InvoicePreviewContent';
import './style.scss';

class InvoicePreviewCard extends Component {
  static propTypes = {
    data: PropTypes.object,
    items: PropTypes.array,
    students: PropTypes.array,
    type: PropTypes.oneOf(['parent_invoice', 'billing_plan']),
    isAttendancePreview: PropTypes.bool,
    noScroll: PropTypes.bool,
    showBreakdown: PropTypes.func
  };

  static defaultProps = {
    data: {},
    items: [],
    students: [],
    noScroll: true,
    type: 'parent_invoice',
    isAttendancePreview: false,
    showBreakdown: () => undefined
  };

  @bind
  getDateRange() {
    const { data, type } = this.props;
    const { invoice_start_date: startDate, invoice_end_date: endDate } = data[type];

    if (!startDate || !endDate) {
      return '';
    }

    return [startDate, endDate]
      .map(d => moment(d))
      .sort((a, b) => a.isAfter(b))
      .map(d => d.format('MMM DD, YYYY'))
      .join(' - ');
  }

  render() {
    const { items, noScroll, students, type, data, showBreakdown, isAttendancePreview } = this.props;
    const invoiceData = data[type];
    const invoiceRange = this.getDateRange();
    const dueDate = invoiceData.due_date && moment(invoiceData.due_date).format('MMM D, YYYY');

    return (
      <div className="invoice-preview-card">
        <InvoicePreviewHeader receiptNumber={invoiceData.receipt_number} students={students} />

        <div className="invoice-preview-card__content">
          <div className="invoice-preview-card__due-date">
            Due date:
            <span className="invoice-preview-card__due-date-value">{dueDate}</span>
          </div>

          {invoiceRange && (
            <div className="invoice-preview-card__due-date">
              Invoice period:
              <span className="invoice-preview-card__due-date-value">{invoiceRange}</span>
            </div>
          )}

          {items.length > 0 && (
            <div className="invoice-preview-card__invoice-table table">
              {noScroll ? (
                <InvoicePreviewContent
                  items={items}
                  showBreakdown={showBreakdown}
                  isAttendancePreview={isAttendancePreview}
                />
              ) : (
                <ScrollBars autoHeight autoHeightMin={50} autoHeightMax={150}>
                  <InvoicePreviewContent
                    items={items}
                    showBreakdown={showBreakdown}
                    isAttendancePreview={isAttendancePreview}
                  />
                </ScrollBars>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export { InvoicePreviewHeader, InvoicePreviewContent, InvoicePreviewItem };

export default InvoicePreviewCard;
