import React, { Component } from 'react';
import cx from 'classnames';
import { ActionButton, ButtonV2, TextInput, Avatar, PageNav } from 'components';
import { Scrollbars } from 'react-custom-scrollbars';
import currency from 'lib/currency';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

const PER_PAGE = 30;

class AmountDueStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      errors: {}
    };
  }

  @bind
  selectedStudents() {
    const { page } = this.state;
    const { selectedKids, students } = this.props;
    const selectedStudents = students
      .filter(student => selectedKids.includes(student.id))
      .slice(PER_PAGE * (page - 1), PER_PAGE * page);
    return selectedStudents;
  }

  @bind
  updateAmount(kidId) {
    return value => {
      const { onUpdate } = this.props;

      if (!/^\d{0,7}(\.\d{0,2})?$/.test(value)) {
        return;
      }

      onUpdate(kidId, value);
    };
  }

  @bind
  changePage(nextPage) {
    this.setState({
      page: nextPage
    });
  }

  @bind
  validateAmounts(afterValidationFn) {
    let errors = {};
    const { values } = this.props;

    const selectedStudentIds = this.selectedStudents().map(s => s.id);
    selectedStudentIds.forEach(id => {
      let error;
      if (values[id] == null || values[id].length <= 0) {
        error = 'Should be specified';
      } else if (Number(values[id]) <= 0) {
        error = 'Should be greater than 0';
      } else {
        error = null;
      }
      errors[id] = error;
    });

    return this.setState(
      prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          ...errors
        }
      }),
      afterValidationFn
    );
  }

  @bind
  handleSave() {
    this.validateAmounts(() => {
      const { errors } = this.state;
      const { onSave } = this.props;
      const isAnyErrors = Object.values(errors).filter(Boolean).length > 0;

      if (!isAnyErrors && typeof onSave === 'function') {
        onSave();
      }
    });
  }

  render() {
    const { selectedKids, isSaving, values } = this.props;
    const { page, errors } = this.state;

    return (
      <div className="modal__wrapper amount-due-step">
        <div className="modal__header modal__header--bordered">
          <ActionButton iconName="back" className="modal__header-back" onClick={() => this.props.context.prevStep()} />
          <span className="modal__header-title">Amount Due</span>
          <span className="modal__header-steps">
            <span>Step 2</span> / 2
          </span>
        </div>

        <div className="modal__container">
          <div className="amount-due-step__header">
            <div>Showing {selectedKids.length} results</div>

            <PageNav
              page={page}
              total={selectedKids.length}
              perPage={PER_PAGE}
              loading={false}
              onPrev={() => this.changePage(page - 1)}
              onNext={() => this.changePage(page + 1)}
            />
          </div>
          <div className="table">
            <div className="table__header">
              <div className="table__cell table__cell--l">Student</div>
              <div className="table__cell table__cell--xs">Amount</div>
            </div>
            <div className="table__body">
              <Scrollbars autoHeight autoHeightMin={50} autoHeightMax={300}>
                {this.selectedStudents().map(student => {
                  return (
                    <div className="table__row" key={student.id}>
                      <div className="table__cell table__cell--l">
                        <Avatar
                          name={student.name || student.first_name + ' ' + student.last_name}
                          url={student.profile_pic_url}
                          tooltipText={student.name}
                          color={student.color}
                          initials={student.initials}
                        />
                        <div className="amount-due-step__name">
                          <div>{student.name || student.first_name + ' ' + student.last_name}</div>
                        </div>
                      </div>
                      <div className="table__cell table__cell--xs">
                        <div className="amount-due-step__amount">
                          <div
                            className={cx({
                              'form-input--invalid': Boolean(errors[student.id])
                            })}
                          >
                            <span className="amount-due-step__price-sym">{currency.getSymbol()}</span>
                            <TextInput
                              value={values[student.id] || ''}
                              onChange={this.updateAmount(student.id)}
                              placeholder="0"
                              data-cy={`amount-${student.id}`}
                            />
                          </div>
                          {errors[student.id] && (
                            <div className="form-input__validation-text">{errors[student.id]}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Scrollbars>
            </div>
          </div>
        </div>
        <div className="modal__controls">
          <ButtonV2 label="Save" data-cy={'submit'} onClick={this.handleSave} disabled={isSaving} />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(AmountDueStep);
