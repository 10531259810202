import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { TextInput, RichEditor } from 'components';
import { getTenantApiUrlFromState } from 'lib/auth';

class MessageStep extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      awsLoaded: false
    };
  }

  UNSAFE_componentWillMount() {
    req.awsSignature({ key: 'newsletters' }).then(() => {
      this.setState({ awsLoaded: true });
    });
  }

  @bind
  onChange(field) {
    return val => {
      this.props.onUpdateForm({ [field]: val });
    };
  }

  render() {
    const { subject, message, isMessageValid, isSubjectValid, awsSignature, currentUser } = this.props;
    const { apiUrl, awsLoaded } = this.state;
    const s3Config = {
      bucket: awsSignature.bucket,
      region: awsSignature.region,
      keyStart: awsSignature.key_start,
      params: {
        acl: awsSignature.acl,
        AWSAccessKeyId: awsSignature.access_key_id,
        policy: awsSignature.policy,
        signature: awsSignature.signature
      }
    };
    const froalaConfig = {
      imageUploadToS3: s3Config,
      fileUploadToS3: s3Config,
      videoUploadToS3: s3Config,
      imageMaxSize: 1024 * 1024 * 100, // 100MB
      fileMaxSize: 1024 * 1024 * 100, // 100MB
      videoMaxSize: 1024 * 1024 * 100, // 100MB
      imageManagerLoadURL: `${apiUrl}/school/photos`,
      imageManagerLoadParams: { token: currentUser.auth_token }
    };

    return (
      <div className="modal__container new-message__form">
        <div className="form__row">
          <div className={cx('form-input', { 'form-input--invalid': !isSubjectValid })}>
            <div className="form-input__field">
              <TextInput name="subject" placeholder="Add Title" value={subject} onChange={this.onChange('subject')} />
            </div>
            {!isSubjectValid && <div className="form-input__validation-text">Cannot be empty</div>}
          </div>
        </div>

        <div className="form__row">
          <div className={cx('form-input', { 'form-input--invalid': !isMessageValid })}>
            <div className="form-input__field">
              <RichEditor
                name="message"
                placeholder="Write a message"
                value={message}
                config={froalaConfig}
                loading={!awsLoaded}
                onChange={this.onChange('message')}
              />
            </div>
            {!isMessageValid && <div className="form-input__validation-text">Cannot be empty</div>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  awsSignature: state.awsSignature.data,
  currentUser: state.currentUser.data,
  apiUrl: getTenantApiUrlFromState(state)
});

export default connect(mapStateToProps)(MessageStep);
