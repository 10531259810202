import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { VERIFICATION_STATUSES } from 'constants/activateStatuses';
import { Icon, ButtonV2, ActionButton } from 'components';
import './style.scss';
class ActivateBar extends Component {
  constructor() {
    super();
    this.state = {
      hideBar: false
    };
  }
  UNSAFE_componentWillMount() {
    const { billingAccountStatus } = this.props;
    if (billingAccountStatus === 'unverified') {
      window.localStorage.removeItem('verificationClosed');
    }
    if (billingAccountStatus === 'pending' || billingAccountStatus === 'unverified') {
      req.verificationStatus().then(() => req.profile());
    } else {
      req.profile();
    }
  }
  @bind
  showActivateModal(params) {
    const { billingAccountType, billingAccountStatus, history, billingGateway } = this.props;
    if (billingGateway === 'stripe' && billingAccountType === 'standard') {
      req
        .connectUri()
        .then(data => {
          history.push('/billing/redirecting');
          window.location.href = data.url;
        })
        .catch(() => {
          Actions.showFlash('There was problem getting stripe account connect uri', 'error');
        });
    } else if (VERIFICATION_STATUSES.includes(billingAccountStatus)) {
      Actions.showModal('Confirmation', {
        title: 'Account Verification',
        description:
          'You will be redirected to account verification page to collect additional information about your business. Please confirm.',
        yesButton: 'Yes',
        noButton: 'No',
        yesButtonProps: {
          secondary: false
        },
        noButtonProps: {
          secondary: true
        }
      }).then(result => {
        if (result) {
          return req
            .connectUri({
              return_url: `${process.env.PUBLIC_PATH}/mobile/school/activate/result`,
              refresh_url: `${process.env.PUBLIC_PATH}/family/billing`
            })
            .then(data => {
              history.push('/billing/redirecting');
              window.location.href = data.url;
            })
            .catch(err => {
              Actions.reportError('There was problem getting account connect uri', err);
            });
        }
      });
    } else {
      Actions.showModal('ActivateSchool', params);
    }
  }
  @bind
  closeBar() {
    window.localStorage.setItem('verificationClosed', true);
    this.setState({ hideBar: true });
  }
  render() {
    const { billingAccountStatus, billingStatusMessage, renderButton } = this.props;

    if (renderButton) {
      return renderButton({
        billingAccountStatus,
        onClick: this.showActivateModal
      });
    }
    if (
      this.state.hideBar ||
      window.localStorage.getItem('verificationClosed') ||
      billingAccountStatus === 'verified'
    ) {
      return null;
    }
    let buttonText = '';
    let closeVisible = false;
    if (billingAccountStatus === 'new_account') {
      buttonText = 'Complete Stripe Setup';
    } else if (billingAccountStatus === 'unverified') {
      buttonText = 'Update Account';
    } else if (billingAccountStatus === 'verified') {
      closeVisible = true;
    }
    return (
      <div className="activate-bar__container">
        <div className="activate-bar">
          <div className="activate-bar__text">
            <Icon size={26} name="email" />
            {billingStatusMessage}
          </div>
          {buttonText && <ButtonV2 secondary label={buttonText} onClick={this.showActivateModal} />}
          {closeVisible && (
            <ActionButton className="activate-bar__close" iconName="close" size={14} onClick={this.closeBar} />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  billingAccountStatus: state.currentUser.data.current_school.billing_account_status,
  billingGateway: state.currentUser.data.current_school.billing_gateway,
  billingAccountType: state.currentUser.data.current_school.billing_account_type,
  billingStatusMessage: state.currentUser.data.current_school.billing_status_message
});
export default withRouter(connect(mapStateToProps)(ActivateBar));
