import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import { InfiniteScroll, Preloader } from 'components';
import Item from './Item';

class List extends PureComponent {
  static propTypes = {
    lessons: PropTypes.array,
    selected: PropTypes.object,
    loading: PropTypes.bool,
    onAdd: PropTypes.func,
    onSelect: PropTypes.func,
    onNextPage: PropTypes.func,
    hideCreateLink: PropTypes.bool,
    enableRowSelect: PropTypes.bool
  };

  static defaultProps = {
    hideCreateLink: false,
    lessons: [],
    selected: {},
    loading: false
  };

  @bind
  selectLesson(lessonId) {
    const { onSelect, selected } = this.props;
    const nextSelected = { ...selected };

    if (onSelect === undefined) {
      return;
    }

    if (nextSelected[lessonId]) {
      delete nextSelected[lessonId];
    } else {
      nextSelected[lessonId] = true;
    }

    onSelect(nextSelected);
  }

  renderItems() {
    const { lessons, selected, enableRowSelect } = this.props;

    return lessons.map(l => (
      <Item
        key={l.id}
        lesson={l}
        selected={selected[l.id]}
        onSelect={this.selectLesson}
        enableRowSelect={enableRowSelect}
      />
    ));
  }

  render() {
    const { loading, onNextPage, onAdd, hideCreateLink } = this.props;

    return (
      <Scrollbars className="add-lesson__list-scroll">
        <InfiniteScroll className="add-lesson__list" loading={loading} onEndReach={onNextPage} threshold={50}>
          {this.renderItems()}

          {!hideCreateLink && (
            <div className="add-lesson__list-bottom-msg">
              Didn't find a lesson?{' '}
              <span onClick={onAdd} className="add-lesson__list-link" to="/learning/plans/lessons/create">
                Create new lesson
              </span>
            </div>
          )}

          <div className="add-lesson__list-preloader">
            <Preloader />
          </div>
        </InfiniteScroll>
      </Scrollbars>
    );
  }
}

export default List;
