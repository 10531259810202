import moment from 'moment';

const timeFormat = 'h:mm A';

const formatTime = time => {
  if (!time) {
    return '';
  }

  if (typeof time === 'string' && time.match(/\s[1-9]*[0-9]:([0-9]{2})\s(AM|PM)$/gi)) {
    const arr = time.split(' ');
    arr.shift();
    return arr.join(' ');
  }

  return moment(time).format(timeFormat);
};

export default formatTime;
