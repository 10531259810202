import React, { Component } from 'react';
import PhotoThumb from '../PhotoThumb';

export default class BottleActivity extends Component {
  render() {
    const { activity } = this.props;
    return (
      <div className="activity__bottle">
        <div className="activity__paragraph">{`${activity.data.amount} oz. ${activity.data.desc}`}</div>
        <PhotoThumb photoUrl={activity.photo_url} />
      </div>
    );
  }
}
