export default {
  'candidate.created': { label: 'created', category: 'candidate', id: 'created' },
  'candidate.engaged': { label: 'engaged', category: 'candidate', id: 'engaged' },
  'invitation.created': { label: 'invitation sent', category: 'invitation', id: 'created' },
  'invitation.completed': { label: 'invitation completed', category: 'invitation', id: 'completed' },
  'invitation.expired': { label: 'invitation expired', category: 'invitation', id: 'expired' },
  'invitation.deleted': { label: 'invitation cancelled', category: 'invitation', id: 'deleted' },
  'verification.created': { label: 'verification created', category: 'verification', id: 'created' },
  'verification.completed': { label: 'verification completed', category: 'verification', id: 'completed' },
  'verification.processed': { label: 'verification processed', category: 'verification', id: 'processed' },
  'report.created': {
    label: 'pending',
    category: 'report',
    id: 'created'
  },
  'report.updated': {
    label: 'pending',
    category: 'report',
    id: 'updated'
  },
  'report.upgraded': {
    label: 'pending',
    category: 'report',
    id: 'upgraded'
  },
  'report.completed': {
    label: 'complete',
    highlight: true,
    category: 'report',
    id: 'completed'
  },
  'report.suspended': {
    label: 'suspended',
    category: 'report',
    id: 'suspended'
  },
  'report.resumed': {
    label: 'pending',
    category: 'report',
    id: 'resumed'
  },
  'report.assessed': {
    label: 'complete',
    highlight: true,
    category: 'report',
    id: 'assessed'
  },
  'report.disputed': {
    category: 'report',
    label: 'dispute',
    id: 'disputed'
  },
  'report.pre_adverse_action': {
    label: 'complete',
    highlight: true,
    category: 'report',
    id: 'pre_adverse_action'
  },
  'report.post_adverse_action': {
    label: 'complete',
    highlight: true,
    category: 'report',
    id: 'post_adverse_action'
  }
};

export const eventCategories = ['candidate', 'verification', 'invitation', 'report'];

export const eventCategoriesMap = eventCategories.reduce(
  (prev, curr) => ({
    ...prev,
    [curr]: curr
  }),
  {}
);
