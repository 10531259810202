const validationText = {
  isEmail: 'Invalid email',
  isNotEmpty: 'Cannot be empty',
  isNaturalNumber: 'Should be a positive integer value',
  isPasswordsEqual: 'Confirm password does not match',
  minLength: length => `Should be at least ${length} characters`,
  maxRangeMonth: maxRange => `Please select a date range less than ${maxRange} month${maxRange > 1 ? 's' : ''}`,
  maxRangeMonthFilter: maxRange => `Please select a date range less than ${maxRange} month${maxRange > 1 ? 's' : ''}`,
  isGreaterThanStart: 'Should be later than start',
  isLesserThanEnd: 'Should be earlier than end',
  default: 'Check this field',
  isAfterTime: 'Should be after start time',
  isTime: 'Wrong formatted time',
  isUrl: 'Invalid URL',
  pattern: pattern => (pattern === '0[0-9]{3}' ? 'Pin must be between 0000 - 0999' : 'Does not match pattern')
};

export default validationText;
