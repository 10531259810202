import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Link } from 'components';

const HelpLink = ({ url, children, title = 'Learn More', hideBar = false }) => {
  return (
    <span className="help-link">
      {!hideBar && <span className="help-link__bar">|</span>}
      <span className="help-link__content">{children}</span>
      <Link to={url} target="_blank" className="help-link__anchor">
        {title}
      </Link>
    </span>
  );
};

HelpLink.propTypes = {
  url: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  hideBar: PropTypes.bool
};

export default HelpLink;
