import React from 'react';
import { TooltipTrigger } from 'components';
import './style.scss';

const Tooltip = ({ programs }) => (
  <ul className="program-list__tooltip">
    {programs.map(p => (
      <li key={p.id}>{p.name}</li>
    ))}
  </ul>
);

const ProgramList = ({ programs }) => {
  if (!programs || !programs.length) {
    return '-';
  }

  const otherPrograms = programs.slice(1);

  return (
    <div className="program-list">
      {programs[0].name}

      {otherPrograms.length > 0 && (
        <TooltipTrigger tooltip={<Tooltip programs={otherPrograms} />} maxHeight={110}>
          <div className="plus-x">+{otherPrograms.length}</div>
        </TooltipTrigger>
      )}
    </div>
  );
};

export default ProgramList;
