import React, { Component } from 'react';
import { Form, ButtonV2 } from 'components';
import FieldForms from './FieldForms';
import './style.scss';

class EditFormField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: {
        fieldType: 'TextInput',
        ...props.data.field
      }
    };
  }

  @bind
  onChange(formValues) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        ...formValues
      }
    });
  }

  @bind
  submit() {
    this.props.onClose(this.state.formValues);
  }

  render() {
    const { formValues } = this.state;
    const { onClose } = this.props;
    const AdditionalForm = FieldForms[formValues.fieldType];

    return (
      <div className="edit-form-field">
        <div className="modal__header">
          <span className="modal__header-title">Edit Field</span>
        </div>

        <Form className="modal__container" onSubmit={this.submit} onChange={this.onChange} validateOn="submit">
          <div className="form__body">
            <div className="form__row">
              <Form.Input
                name="id"
                defaultValue={formValues.id}
                label="Field ID"
                placeholder="ex. first_name"
                required
              />
            </div>

            <div className="form__row">
              <Form.Input
                name="label"
                defaultValue={formValues.label}
                label="Label"
                placeholder="ex. First Name"
                required
              />
            </div>
          </div>

          {AdditionalForm && <AdditionalForm field={formValues} onChange={this.onChange} />}

          <div className="form__row--actions">
            <ButtonV2 secondary onClick={() => onClose(null)} label="Cancel" />
            <Form.Submit label="Save" />
          </div>
        </Form>
      </div>
    );
  }
}

export default EditFormField;
