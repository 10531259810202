import { mergePermissions } from 'lib/utils';

export default response => {
  const { user } = response;

  const staffPermissions = user.permissions || {};
  const schoolPermissions = user.current_school ? user.current_school.permissions : {};
  const featureFlags = user.current_school || {};

  user.mergedPermissions = mergePermissions(schoolPermissions, staffPermissions, featureFlags);

  return user;
};
