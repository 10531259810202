import { useEffect } from 'react';
import get from 'lodash/get';
import moment from 'moment';

const VISITOR_ROLES = {
  CARER: 'carer',
  ADMIN: 'admin',
  NON_ADMIN: 'non-admin'
};

function Pendo({ currentUser }) {
  const pendo = window.pendo;

  useEffect(() => {
    if (currentUser && pendo && get(currentUser, 'current_school.id', false)) {
      const { id, email, name, current_school: school, current_role } = currentUser;

      const isCarer = current_role === 'carer';
      const isAdmin = get(currentUser, 'teacher.is_admin', false);

      const visitorRole = isCarer ? VISITOR_ROLES.CARER : isAdmin ? VISITOR_ROLES.ADMIN : VISITOR_ROLES.NON_ADMIN;

      const config = {
        visitor: {
          id: id,
          email: email,
          full_name: name,
          role: visitorRole
        },

        account: {
          id: school.id,
          name: school.name,
          group_id: school.group_id,
          creationDate: moment(school.created_at).valueOf(),
          'school-type': school.facility_type,
          enrollment: school.enrollment,
          gusto_enabled: school.gusto_enabled,
          marcopolo_enabled: school.marcopolo_enabled,
          procare_desktop_enabled: school.pe_enabled,
          subscription_category: school.subscription_category,
          trial_period_end_date: school.trial_period_end_date,
          subscription_is_halted: school.subscription?.is_halted,
          subscription_is_active: school.subscription?.is_active,
          subscription_started_at: school.subscription?.started_at,
          subscription_ended_at: school.subscription?.ended_at,
          online_subscription: school.online_subscription,
          subscription_plan: school.subscription_plan,
          billing_account_status: school.billing_account_status,
          billing_account_type: school.billing_account_type,
          parent_payment_fee_paid_by: school.parent_payment_fee_paid_by,
          auto_pay_enabled: school.auto_pay_enabled,
          pe_enabled: school.pe_enabled,
          procare_sync_enabled: school.procare_sync_enabled
        }
      };

      if (school.billing_account_status === 'verified') {
        config.account.billing_gateway = school.billing_gateway;
      }

      if (school.subscription === 'premium') {
        config.account['is_paying'] = true;
      }

      pendo.initialize(config);
    }
  }, []);

  return null;
}

export default Pendo;
