import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormFieldType from 'constants/FormFieldType';
import { TextInput, Checkbox, SelectGroup, DatePicker, UploadFile } from 'components';
import Divider from '../../Divider';
import TextBox from '../../TextBox';
import Overlay from './Overlay';
import './style.scss';

class FormConstructorField extends Component {
  static propTypes = {
    id: PropTypes.string,

    type: PropTypes.oneOf([
      FormFieldType.TextInput,
      FormFieldType.Checkbox,
      FormFieldType.Select,
      FormFieldType.DatePicker,
      FormFieldType.TextBox,
      FormFieldType.Divider,
      FormFieldType.FileUpload
    ]),

    removable: PropTypes.bool,

    options: PropTypes.object,

    onEdit: PropTypes.func,

    onDelete: PropTypes.func
  };

  static defaultProps = {
    options: {}
  };

  getComponent(type) {
    switch (type) {
      case FormFieldType.TextInput:
        return TextInput;

      case FormFieldType.Checkbox:
        return Checkbox;

      case FormFieldType.Select:
        return SelectGroup;

      case FormFieldType.DatePicker:
        return DatePicker;

      case FormFieldType.TextBox:
        return TextBox;

      case FormFieldType.Divider:
        return Divider;

      case FormFieldType.FileUpload:
        return UploadFile;
    }
  }

  getComponentProps(type, options) {
    switch (type) {
      case FormFieldType.TextInput:
        return { placeholder: options.placeholder, value: options.defaultValue, multiline: options.multiline };

      case FormFieldType.Checkbox:
        return { label: options.label, checked: options.defaultValue, required: options.required };

      case FormFieldType.Select:
        return { title: options.title };

      case FormFieldType.DatePicker:
        return { defaultValue: options.defaultValue, placeholder: options.placeholder || 'Select date' };

      case FormFieldType.TextBox:
        return { value: options.value, hasCheckbox: options.hasCheckbox, checkboxLabel: options.checkboxLabel };

      case FormFieldType.FileUpload:
        return { value: options.value, title: options.title, required: options.required };
    }
  }

  renderLabel(type, options) {
    if (type === FormFieldType.Divider || type === FormFieldType.Checkbox) {
      return null;
    }

    return (
      <div className="form-constructor-field__label">
        <span className="form-constructor-field__label-text">{options.label || 'Unnamed Field'}</span>

        {options.required && <span className="form-constructor-field__label-required">*</span>}
      </div>
    );
  }

  renderComponent(type, options) {
    const props = this.getComponentProps(type, options);
    const Component = this.getComponent(type);

    return <div className="form-constructor-field__component">{Component && <Component {...props} />}</div>;
  }

  @bind
  handleEdit() {
    if (this.props.onEdit) {
      this.props.onEdit(this.props.id);
    }
  }

  @bind
  handleDelete() {
    if (this.props.onDelete) {
      this.props.onDelete(this.props.id);
    }
  }

  render() {
    const { type, removable, options, id } = this.props;
    const className = classNames('form-constructor-field', {
      'form-constructor-field--full-width': options.fullWidth || type === FormFieldType.Checkbox
    });

    const isEditable = type !== FormFieldType.Divider;
    const isOverlayHidden = removable === false && type === FormFieldType.Divider;

    return (
      <div className={className} data-cy={`form-constructor-field-${id}`}>
        <div className="form-constructor-field__inner">
          {this.renderLabel(type, options)}

          <div className="form-constructor-field__component-wrapper">
            {this.renderComponent(type, options)}

            <Overlay
              hidden={isOverlayHidden}
              editable={isEditable}
              removable={removable}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default FormConstructorField;
