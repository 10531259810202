import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollBars from 'react-custom-scrollbars';
import PersonCard from './PersonCard';
import differenceWith from 'lodash/differenceWith';
import { Checkbox, TooltipTrigger } from 'components';
import { Tooltip } from 'components/StudentList';
import take from 'lodash/take';
import './style.scss';

class SelectPersonList extends Component {
  static propTypes = {
    persons: PropTypes.array,
    allPersons: PropTypes.array,
    selectedIds: PropTypes.array,
    onSelect: PropTypes.func,
    selectType: PropTypes.oneOf(['checkbox', 'radio']),
    type: PropTypes.oneOf(['kid', 'teacher', 'lead']),
    validations: PropTypes.object,
    disableInvalid: PropTypes.bool,
    noResultText: PropTypes.string,
    listHeight: PropTypes.number, // Passed to Scrollbars
    renderPersonCard: PropTypes.func
  };

  static defaultProps = {
    persons: [],
    allPersons: [],
    selectedIds: [],
    type: 'kid',
    listHeight: 285,
    selectType: 'checkbox',
    disableInvalid: true
  };

  selectedPersonIds = {};
  enabledPersons = [];

  static filteredPersonsSelected(filteredPersons, selectedIds) {
    if (filteredPersons.length !== selectedIds.length) return false;

    const diff = differenceWith(
      filteredPersons,
      selectedIds,
      ({ id: filteredId } = {}, selectedId) => filteredId === selectedId
    );
    return diff.length === 0;
  }

  constructor(props) {
    super(props);

    this.selectedPersonIds = this.buildPersonsHash(props.persons);
    this.enabledPersons = this.getEnabledPersons(props.persons, props.validations);

    props.selectedIds.forEach(id => {
      this.selectedPersonIds[id] = true;
    });
  }

  get allSelected() {
    const { selectedIds, persons, allPersons } = this.props;
    return (
      selectedIds.length !== 0 &&
      (selectedIds.length === allPersons.length || SelectPersonList.filteredPersonsSelected(persons, selectedIds))
    );
  }

  get allFilteredSelected() {
    const { selectedIds, persons } = this.props;
    const selectedPersons = persons.filter(p => selectedIds.find(id => id === p.id));
    return selectedPersons.length === persons.length && selectedPersons.length !== 0;
  }

  buildPersonsHash(persons) {
    const hash = {};

    for (let person of persons) {
      hash[person.id] = false;
    }

    return hash;
  }

  buildSelectedArray() {
    return Object.keys(this.selectedPersonIds).filter(id => this.selectedPersonIds[id]);
  }

  @bind
  togglePerson(personId) {
    const { selectType, persons } = this.props;
    const val = this.selectedPersonIds[personId];
    if (selectType === 'radio') {
      this.selectedPersonIds = this.buildPersonsHash(persons);
    }
    this.selectedPersonIds[personId] = !val;
    this.handleUpdate();
  }

  @bind
  toggleAll() {
    const { persons, validations } = this.props;
    this.enabledPersons = this.getEnabledPersons(persons, validations);

    if (this.enabledPersons.length === 0) {
      return;
    }

    if (!this.allFilteredSelected) {
      this.enabledPersons.forEach(p => {
        this.selectedPersonIds[p.id] = true;
      });
    } else {
      this.selectedPersonIds = this.buildPersonsHash(persons);
    }

    this.handleUpdate();
  }

  getEnabledPersons(persons, validations) {
    if (!this.props.disableInvalid || !validations) {
      return persons;
    }

    return persons.filter(p => {
      return Object.keys(validations).filter(rule => validations[rule](p)).length === 0;
    });
  }

  handleUpdate() {
    const idsArray = this.buildSelectedArray();

    if (this.props.onSelect) {
      this.props.onSelect(idsArray);
    }
  }

  getDisableMessage(person, validations = {}) {
    const rulesNames = Object.keys(validations);

    if (rulesNames.length === 0) {
      return undefined;
    }

    const violatedRules = rulesNames.filter(rule => validations[rule](person));
    return violatedRules.length > 0 ? violatedRules.join(', ') : undefined;
  }

  renderPersons() {
    const { persons, validations, type, disableInvalid, renderPersonCard } = this.props;

    // TODO: use react-window to render huge(thousands) items list
    return take(persons, 100).map(p => {
      const disableMessage = this.getDisableMessage(p, validations);

      return typeof renderPersonCard === 'function' ? (
        renderPersonCard({
          person: p,
          type,
          onSelect: this.togglePerson,
          selected: this.selectedPersonIds[p.id],
          validationError: disableMessage,
          disableInvalid
        })
      ) : (
        <PersonCard
          key={p.id}
          id={p.id}
          name={p.name}
          color={p.color}
          type={type}
          status={p.is_admin ? 'admin' : undefined}
          picURL={p.profile_pic_url}
          onSelect={this.togglePerson}
          selected={this.selectedPersonIds[p.id]}
          validationError={disableMessage}
          disableInvalid={disableInvalid}
        />
      );
    });
  }

  getSelectedMessage() {
    const { type, selectedIds } = this.props;
    const count = selectedIds.length > 0 ? selectedIds.length : 'No';

    switch (type) {
      case 'kid':
        return `${count} STUDENT${selectedIds.length !== 1 ? 'S' : ''} SELECTED`;

      case 'teacher':
        return `${count} STAFF SELECTED`;

      case 'lead':
        return `${count} LEAD${selectedIds.length !== 1 ? 'S' : ''} SELECTED`;
    }
  }

  render() {
    const { selectedIds, selectType, listHeight, type, noResultText, allPersons, persons } = this.props;
    const count = persons.length;
    const selectedPersons = selectedIds
      .map(id => allPersons.find(person => person.id === id))
      .filter(p => p !== undefined);

    return (
      <div className="select-person-list">
        {selectType === 'checkbox' && (
          <div className="select-person-list__header">
            <Checkbox
              className="select-person-list__select-all"
              checked={this.allSelected}
              onChange={this.toggleAll}
              disabled={count === 0}
              label="Select All"
            />

            <div className="select-person-list__selected">
              {selectedIds.length ? (
                <TooltipTrigger tooltip={<Tooltip students={selectedPersons} />} maxHeight={110}>
                  {this.getSelectedMessage()}
                </TooltipTrigger>
              ) : (
                this.getSelectedMessage()
              )}
            </div>
          </div>
        )}

        <ScrollBars autoHeight autoHeightMin={listHeight}>
          <div className="select-person-list__cards">
            {count > 0 ? (
              this.renderPersons()
            ) : (
              <div className="select-person-list__no-results">
                {noResultText || `No ${type === 'kid' ? 'student' : 'staff'} found by filter.`}
              </div>
            )}
          </div>
        </ScrollBars>
      </div>
    );
  }
}

export default SelectPersonList;
