import { ButtonV2, Form } from 'components';
import { times } from 'lodash';
import React, { Fragment } from 'react';
import { MAX_EFFECTIVE_DOSES } from '.';
import classNames from 'classnames';
import moment from 'moment';

function Dose({ id, number, defaults, formValues, numAvailableDoses, onChangeEffectiveDoses, onDelete }) {
  const changeDose = (type, times) => {
    // Because the frequency dropdown is inside a label for a radio button,
    // the radio beforeChange event fires more often than we want... ignore it
    // unless there's been an actual change
    if (!times && type === formValues[`${id}_type`]) {
      return;
    }

    let numEffectiveDoses = 1;

    switch (type) {
      case 'fixed':
        numEffectiveDoses = MAX_EFFECTIVE_DOSES;
        break;
      case 'repeats':
        numEffectiveDoses = Number(times ?? formValues[`${id}_repeats_times`]);
        break;
    }

    if (numEffectiveDoses > numAvailableDoses) {
      return false;
    }

    onChangeEffectiveDoses(numEffectiveDoses);
    return true;
  };

  const ageLabel = (
    <span>
      <Form.Input name={`${id}_age`} defaultValue={String(defaults?.age ?? '')} type="number" placeholder="0" min={0} />
      <div>months</div>
    </span>
  );

  const withinLabel = (
    <span>
      <Form.Input
        name={`${id}_within`}
        defaultValue={String(defaults?.within ?? '')}
        placeholder="1"
        type="number"
        min={1}
      />
      <div>
        days of
        <br />
        enrollment
      </div>
    </span>
  );

  const fixedLabel = disabled => (
    <span>
      <Form.DatePicker
        name={`${id}_fixed`}
        defaultValue={defaults?.fixed || ''}
        placeholder={moment().format('MMM DD, YYYY')}
        className="static"
        tabIndex={0}
        disabled={disabled}
      />
    </span>
  );

  const inLabel = defaults => (
    <span>
      <Form.Input name={`${id}_in`} defaultValue={String(defaults?.in ?? '')} placeholder="1" type="number" min={1} />
      <Form.Select name={`${id}_in_type`} defaultValue={defaults?.inType ?? 'Days'} tabIndex={0}>
        {['Days', 'Weeks', 'Months', 'Years'].map(value => (
          <Form.Select.Item key={value} label={value} value={value} />
        ))}
      </Form.Select>
    </span>
  );

  const repeatsLabel = (defaults, disabled) => (
    <span>
      <Form.Input
        name={`${id}_repeats_in`}
        defaultValue={String(defaults?.in ?? '')}
        placeholder="1"
        type="number"
        min={1}
        disabled={disabled}
      />
      <Form.Select
        name={`${id}_repeats_in_type`}
        defaultValue={defaults?.inType ?? 'Days'}
        tabIndex={0}
        disabled={disabled}
      >
        {['Days', 'Weeks', 'Months', 'Years'].map(value => (
          <Form.Select.Item key={value} label={value} value={value} />
        ))}
      </Form.Select>
      <div>X</div>
      <Form.Select
        name={`${id}_repeats_times`}
        defaultValue={String(defaults?.numTimes ?? 2)}
        className="add-vaccine-dose__frequency"
        onChange={(_, times) => changeDose('repeats', times)}
        tabIndex={0}
        disabled={disabled}
      >
        {times(numAvailableDoses - 1, i => String(i + 2)).map(value => (
          <Form.Select.Item key={value} label={value} value={value} />
        ))}
      </Form.Select>
    </span>
  );

  const renderFirstDoseTypes = () => (
    <Form.Radio name={`${id}_type`} type="circle" defaultValue={defaults.type} beforeChange={changeDose}>
      {renderRadioOption => (
        <Fragment>
          <div className="add-vaccine-dose__dose-type add-vaccine-dose__dose-type--age">
            <header>Due at age</header>
            <div>{renderRadioOption({ value: 'age', label: ageLabel })}</div>
          </div>
          <div className="add-vaccine-dose__dose-type add-vaccine-dose__dose-type--within">
            <header>Due within</header>
            <div>{renderRadioOption({ value: 'within', label: withinLabel })}</div>
          </div>
          <div
            className={classNames('add-vaccine-dose__dose-type', 'add-vaccine-dose__dose-type--fixed', 'static', {
              'add-vaccine-dose__dose-type--disabled': numAvailableDoses < MAX_EFFECTIVE_DOSES
            })}
          >
            <header>Fixed every year</header>
            <div className="static">
              {renderRadioOption({ value: 'fixed', label: fixedLabel(numAvailableDoses < MAX_EFFECTIVE_DOSES) })}
            </div>
          </div>
        </Fragment>
      )}
    </Form.Radio>
  );

  const renderSubsequentDoseTypes = () => (
    <Form.Radio name={`${id}_type`} type="circle" defaultValue={defaults.type} beforeChange={changeDose}>
      {renderRadioOption => (
        <Fragment>
          <div className="add-vaccine-dose__dose-type add-vaccine-dose__dose-type--in">
            <header>Due in</header>
            <div className="static">
              {renderRadioOption({ value: 'in', label: inLabel(defaults.type === 'in' ? defaults : undefined) })}
            </div>
          </div>
          <div
            className={classNames('add-vaccine-dose__dose-type', 'add-vaccine-dose__dose-type--repeats', {
              'add-vaccine-dose__dose-type--disabled': numAvailableDoses < 2
            })}
          >
            <header>Repeats every</header>
            <div className="static">
              {renderRadioOption({
                value: 'repeats',
                label: repeatsLabel(defaults.type === 'repeats' ? defaults : undefined, numAvailableDoses < 2)
              })}
            </div>
          </div>
          <div className="add-vaccine-dose__delete">
            <ButtonV2 icon iconName="delete" size={16} onClick={onDelete} />
          </div>
        </Fragment>
      )}
    </Form.Radio>
  );

  return (
    <div className="add-vaccine-dose static">
      <div className="static">
        <header>Dose</header>
        <div className="add-vaccine-dose__dose-number">{number}</div>
      </div>
      {number === 1 ? renderFirstDoseTypes() : renderSubsequentDoseTypes()}
    </div>
  );
}

export default Dose;
