import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import range from 'lodash/range';
import { Form } from 'components';
import './style.scss';

class DownloadTaxStatement extends Component {
  static propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func
  };

  static defaultProps = {
    data: {
      family_id: undefined,
      sub_family_id: undefined,
      subFamilies: [],
      type: undefined // "parent" | "teacher"
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      formValues: {},
      loading: false
    };
  }

  componentDidMount() {
    const { type } = this.props.data;

    if (type === 'teacher') req.subFamilies({ family_id: this.props.data.family_id });
  }

  @bind
  submit({ year, recipients, sub_family_id }) {
    const { family_id, type = 'teacher' } = this.props.data;

    let action = null;
    switch (type) {
      case 'teacher':
        action = req.downloadTaxStatementTeacher;
        break;

      case 'parent':
        action = req.downloadTaxStatementParent;
        break;
    }

    this.setState({ loading: true });
    const subFamilyId = sub_family_id || this.props.data.sub_family_id;
    action({
      year: year.toString(),
      family_id,
      recipients: type === 'parent' ? 'parents' : recipients,
      sub_family_id: subFamilyId || undefined
    })
      .then(() => {
        Actions.showFlash('Tax Statement successfully sent');
        this.props.onClose();
      })
      .catch(err => {
        Actions.reportError('There was problem sending tax statement', err);
      });
  }

  @bind
  handleFormUpdate(values) {
    this.setState({ formValues: values });
  }

  getSubFamilies() {
    const { type } = this.props.data;

    return type === 'teacher' ? this.props.subFamilies : this.props.data.subFamilies;
  }

  renderSubFamilies() {
    const { type } = this.props.data;
    const subFamilies = this.getSubFamilies();

    if (subFamilies.length === 0 || (type === 'parent' && subFamilies.length === 1)) return null;

    const selectItems = [];
    let defaultSubFamilyId = null;
    if (type === 'teacher') {
      selectItems.push(<Form.Select.Item value={null} label="All Families" />);
    } else {
      defaultSubFamilyId = subFamilies[0].id;
    }
    subFamilies.forEach(sf => {
      selectItems.push(<Form.Select.Item key={sf.id} value={sf.id} label={sf.name} />);
    });

    return (
      <div className="form__row download-tax-statement__subfamily-row">
        <Form.Select
          className="download-tax-statement__subfamily-select"
          name="sub_family_id"
          required={type === 'parent'}
          defaultValue={defaultSubFamilyId}
          label="Family"
        >
          {selectItems.map(selectItem => selectItem)}
        </Form.Select>
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    const {
      data: { type }
    } = this.props;

    return (
      <Form
        className="modal__wrapper download-tax-statement"
        onInit={this.handleFormUpdate}
        onChange={this.handleFormUpdate}
        onSubmit={this.submit}
      >
        <div className="modal__header">
          <div className="subheader download-tax-statement__title">Tax Statement</div>
        </div>

        <div className="modal__container">
          <div className="download-tax-statement__form">
            <div className="download-tax-statement__description">
              The tax statement will display a summary of charges and payments for the selected calendar year. Click
              Send Email to send a tax statement to all parent email addresses for each family.
              <br />
            </div>
            {this.renderSubFamilies()}
            <div className="form__row ">
              <Form.Select required name="year" defaultValue={2018} label="Select Year">
                {range(2018, parseInt(moment().format('YYYY'))).map(year => (
                  <Form.Select.Item value={year} label={year} />
                ))}
              </Form.Select>
            </div>
            {type === 'teacher' && (
              <div className="form__row">
                <Form.Select required name="recipients" defaultValue={'parents'} label="Recipients">
                  <Form.Select.Item value="parents" label="Parents" />
                  <Form.Select.Item value="parents_and_current_user" label="Parents and Sender" />
                  <Form.Select.Item value="current_user" label="Sender" />
                </Form.Select>
              </div>
            )}
          </div>
          <br />
          Note: Sender is user currently logged in.
        </div>
        <div className="modal__controls">
          <Form.Submit isLoading={loading} label="Send Email" />
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  subFamilies: state.subFamilies.data
});

export default connect(mapStateToProps)(DownloadTaxStatement);
