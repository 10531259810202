import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonV2, Checkbox } from 'components';
import './style.scss';
import classNames from 'classnames';

function Confirmation({ onClose, data = {} }) {
  const { checkboxText } = data;
  const [checked, setChecked] = useState(false);

  const modalCN = classNames({
    modal__wrapper: true,
    'confirmation-modal': true,
    'confirmation-modal__wide': data.wideModal
  });

  return (
    <div className={modalCN}>
      <div className="modal__header">
        <div className="subheader">{data.title}</div>
        <span className="modal__header-note confirmation-modal__description">{data.description}</span>
        {checkboxText && (
          <div className="mt-8">
            <Checkbox onChange={setChecked} checked={checked} label={checkboxText} />
          </div>
        )}
      </div>

      <div className="modal__controls">
        <ButtonV2
          onClick={() => onClose(false)}
          secondary={data.yesPrimary}
          label={data.noButton || 'No'}
          data-cy="no-button"
          {...data.noButtonProps}
        />
        <ButtonV2
          onClick={() => onClose(checkboxText ? { checked } : true)}
          secondary={!data.yesPrimary}
          label={data.yesButton || 'Yes'}
          data-cy="yes-button"
          {...data.yesButtonProps}
        />
      </div>
    </div>
  );
}

Confirmation.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func
};

export default Confirmation;
