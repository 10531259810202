import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PhotoActivityThumb from 'modals/PhotoActivityGallery/PhotoActivityThumb';
import './style.scss';

class PhotoCollection extends Component {
  static propTypes = {
    photos: PropTypes.array.isRequired,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    multiple: PropTypes.bool,
    onSelect: PropTypes.func.isRequired
  };

  static defaultProps = {
    photos: []
  };

  getPhotoIdHash(photoIds = []) {
    const hash = {};
    const length = photoIds.length;

    for (let i = 0; i < length; i++) {
      hash[photoIds[i]] = true;
    }

    return hash;
  }

  @bind
  selectPhoto(id) {
    if (!this.props.multiple) {
      this.props.onSelect(
        id,
        this.props.photos.find(p => p.id === id)
      );
      return;
    }

    const selectedPhotos = this.getPhotoIdHash(this.props.selected);
    if (selectedPhotos[id]) {
      delete selectedPhotos[id];
    } else {
      selectedPhotos[id] = true;
    }

    const photos = this.props.photos.filter(p => selectedPhotos[p.id]);
    this.props.onSelect(Object.keys(selectedPhotos), photos);
  }

  @bind
  renderPhotos(photos, selected) {
    const selectedPhotos = typeof selected === 'string' ? { [selected]: true } : this.getPhotoIdHash(selected);

    return photos.map(p => (
      <PhotoActivityThumb
        key={p.id}
        id={p.id}
        url={p.thumb_url}
        selected={selectedPhotos[p.id]}
        onSelect={this.selectPhoto}
      />
    ));
  }

  render() {
    const { photos, selected } = this.props;

    return <div className="photo-collection">{this.renderPhotos(photos, selected)}</div>;
  }
}

export default PhotoCollection;
