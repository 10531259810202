import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, ButtonV2 } from 'components';

class Item extends Component {
  static propTypes = {
    item: PropTypes.object,
    onAdd: PropTypes.func,
    onChange: PropTypes.func,
    onDelete: PropTypes.func
  };
  static defaultProps = {
    item: {}
  };

  @bind
  handleAdd() {
    if (this.props.onAdd) {
      this.props.onAdd();
    }
  }

  @bind
  handleChange(label) {
    const { item, onChange } = this.props;

    if (onChange) {
      onChange(item._id, label);
    }
  }

  @bind
  handleDelete() {
    if (this.props.onDelete) {
      this.props.onDelete(this.props.item._id);
    }
  }

  @bind
  renderAction() {
    const { item, isLast, disabled } = this.props;
    const isAddDisabled = !item.label;

    return isLast ? (
      <ButtonV2
        className="form-field__item-list-v2__add-btn"
        icon
        iconName="add-blue"
        onClick={this.handleAdd}
        disabled={isAddDisabled || disabled}
      />
    ) : (
      <ButtonV2
        key={isLast}
        icon
        iconName="close-circle"
        className="form-field__item-list-v2__remove-btn"
        onClick={this.handleDelete}
        disabled={disabled}
      />
    );
  }

  render() {
    const { item, disabled } = this.props;

    return (
      <div className="form-field__item-list-v2__item">
        <TextInput placeholder="Choice name" value={item.label} onChange={this.handleChange} disabled={disabled} />
        {!disabled && this.renderAction()}
      </div>
    );
  }
}

export default Item;
