import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import moment from 'moment';
import { DatePicker, MonthPicker, SelectGroup } from 'components';
import './style.scss';

const DateRangeType = {
  Monthly: 'monthly',
  Weekly: 'weekly',
  Biweekly: 'biweekly',
  Daily: 'daily',
  Custom: 'range'
};

export const getDateRange = (date, type) => {
  if (!values(DateRangeType).includes(type)) {
    return null;
  }

  const dateRange = [];
  const rangeType = type === DateRangeType.Monthly ? 'month' : type === 'daily' ? 'day' : 'isoWeek';

  const endDate =
    type === DateRangeType.Biweekly
      ? moment(date)
          .add(1, 'week')
          .toDate()
      : date;

  dateRange.push(
    moment(date)
      .startOf(rangeType)
      .toDate(),
    moment(endDate)
      .endOf(rangeType)
      .toDate()
  );

  return dateRange;
};

class DateFilter extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    disableFuture: PropTypes.bool,
    disableDaily: PropTypes.bool,
    disableBiweekly: PropTypes.bool,
    displayDateRange: PropTypes.bool,
    disableMonthly: PropTypes.bool,
    onChange: PropTypes.func,
    hideSelectGroup: PropTypes.bool
  };

  static defaultProps = {
    disabled: false,
    disableFuture: false,
    disableDaily: true,
    displayDateRange: false,
    disableMonthly: false,
    hideSelectGroup: false
  };

  constructor(props) {
    super(props);

    const type = (props.defaultValue && props.defaultValue.type) || 'monthly';
    const date = this.getDateFromProps();

    this.state = {
      // Possible states are "monthly", "weekly", "biweekly", "daily"
      type,
      date: moment(date).toDate(),
      dateRange: getDateRange(date, type)
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  componentDidUpdate(prevProps, prevState) {
    const prevValue = this.getValue(prevState);
    const nextValue = this.getValue();

    if (!isEqual(prevValue, nextValue)) {
      this.handleChange();
    }
  }

  getDateFromProps() {
    const { defaultValue } = this.props;
    return defaultValue?.date || defaultValue?.dateRange?.[0] || new Date();
  }

  getValue(_state) {
    const state = _state || this.state;

    return {
      type: state.type,
      dateRange: [moment(state.dateRange[0]).format('YYYY-MM-DD'), moment(state.dateRange[1]).format('YYYY-MM-DD')]
    };
  }

  @bind
  handleChange() {
    if (this.props.onChange) {
      this.props.onChange(this.getValue());
    }
  }

  @bind
  changeDateRange(dateRange) {
    if (Array.isArray(dateRange)) {
      this.setState({ dateRange });
    } else {
      this.setState({ dateRange: [dateRange, dateRange] });
    }
  }

  // workaround to set value to month picker.
  // @TODO remove after make monthpicker input value state controlled
  @bind
  setMonthPickerValue(date) {
    const month = parseInt(moment(date).format('MM'), 10);
    const year = parseInt(moment(date).format('YYYY'), 10);
    this.monthPickerRef.setValue(month - 1, year); // -1 is for UTC month (0-11)
  }

  @bind
  changeMonth(month) {
    this.changeDateRange(month);
  }

  @bind
  changeMonthPicker(dateRange) {
    this.setState({ dateRange });
  }

  @bind
  changeType(type) {
    this.setState({ type, dateRange: getDateRange(this.state.date, type) });
  }

  render() {
    const { type, date, dateRange } = this.state;
    const {
      disabled,
      disableFuture,
      disableBiweekly,
      disableDaily,
      hasCustom,
      disableMonthly,
      displayDateRange,
      hideSelectGroup
    } = this.props;

    const items = [];

    if (!disableDaily) {
      items.push(<SelectGroup.Item key="daily" value="daily" label="Daily" />);
    }

    items.push(<SelectGroup.Item key="weekly" value="weekly" label="Weekly" />);

    if (!disableBiweekly) {
      items.push(<SelectGroup.Item key="biweekly" value="biweekly" label="Bi-Weekly" />);
    }

    if (!disableMonthly) {
      items.push(<SelectGroup.Item key="monthly" value="monthly" label="Monthly" />);
    }

    if (hasCustom) {
      items.push(<SelectGroup.Item key="range" value="range" label="Custom" />);
    }

    return (
      <div className="date-filter">
        {!hideSelectGroup && (
          <SelectGroup
            type="radio"
            className="date-filter__type"
            onChange={this.changeType}
            checked={type}
            disabled={disabled}
            data-cy="date-filter"
          >
            {items}
          </SelectGroup>
        )}

        <div className="date-filter__date">
          {type === 'monthly' ? (
            <MonthPicker
              data-cy="date-filter-month-picker"
              onChange={this.changeMonthPicker}
              defaultValue={date}
              disabled={disabled}
              disableFuture={disableFuture}
              displayDateRange={displayDateRange}
              ref={node => (this.monthPickerRef = node)}
            />
          ) : (
            <DatePicker
              data-cy="date-filter-date-picker"
              type={type}
              value={type === 'daily' ? dateRange[0] : dateRange}
              onChange={this.changeDateRange}
              disabled={disabled}
              disableFuture={disableFuture}
            />
          )}
        </div>
      </div>
    );
  }
}

export default DateFilter;
