import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Icon, ButtonV2 } from 'components';
import PREMIUM_FEATURES from 'constants/premiumFeatures';
import { IntercomAPI } from 'react-intercom';
import './style.scss';

class Premium extends Component {
  static propTypes = {};
  static defaultProps = {};

  shouldRedirectToDashboard = true;

  componentWillUnmount() {
    if (this.props.data.shouldStay) {
      return;
    }

    if (this.shouldRedirectToDashboard) {
      this.props.history.push('/');
    }
  }

  @bind
  handleUpgrade() {
    const { currentSchool, history, onClose } = this.props;

    if (currentSchool.online_subscription) {
      this.shouldRedirectToDashboard = false;

      history.push('/dashboard/subscription');
    } else {
      Actions.showFlash('Please contact our support to upgrade', 'alert');
    }

    onClose();
  }

  @bind
  handleContact() {
    IntercomAPI('showNewMessage', 'I want to learn more about adding LeadCRM');
  }

  renderFeatures() {
    const { accessLevel } = this.props.data;
    const features = PREMIUM_FEATURES[accessLevel];

    if (!features) return null;

    const rows = [];

    if (features.length < 2) return null;

    for (let i = 0, j = 0; i < features.length; i += 2, j++) {
      const rowItems = features.slice(i, i + 2).map(item => (
        <td className="premium__feature-name" key={`premium-item-(${item})`}>
          {item}
        </td>
      ));

      rows.push(<tr key={`premium-row-${j}`}>{rowItems}</tr>);
    }

    return rows;
  }

  render() {
    const { accessLevel } = this.props.data;

    return (
      <div className="premium">
        <div className="premium__background">
          <div className="premium__background-top" />
          <div className="premium__background-bottom" />
        </div>

        <div className="premium__inner">
          <div className="premium__header">
            <div className="premium__star-icon">
              <Icon name="star" color="#FFF" size="64" />
            </div>

            <div className="premium__title">{accessLevel === 'center' ? 'Add Leads' : 'Essentials Feature'}</div>
          </div>

          <div className="premium__content">
            {accessLevel === 'center' ? (
              <div className="premium__message">
                LeadCRM provides fully integrated tools for managing and automating communications with your prospective
                families. Gain insights into your conversions and streamline your lead management processes.
              </div>
            ) : (
              <div className="premium__message">
                To use this feature, you will need to upgrade to our{' '}
                <span className="premium__premium-text">Essentials</span> plan.
              </div>
            )}

            <table className="premium__features">
              <tbody>{this.renderFeatures()}</tbody>
            </table>

            <div className="premium__upgrade-button-wrapper">
              {accessLevel === 'center' ? (
                <ButtonV2 className="premium__upgrade-button" label="Contact Support" onClick={this.handleContact} />
              ) : (
                <ButtonV2 className="premium__upgrade-button" label="Upgrade now" onClick={this.handleUpgrade} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentSchool: state.currentUser.data.current_school
});

export default withRouter(connect(mapStateToProps)(Premium));
