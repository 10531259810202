export default {
  teacher: 'Staff',
  kid: 'Active Student',
  carer: 'Parent',
  enrollment: 'Lead Student',
  enrollment_carer: 'Lead Parent'
};

export const KEEPER_TRANSFERS = {
  enrollment: 'kid',
  enrollment_carer: 'carer'
};
