import moment from 'moment';

export default (data, activity) => {
  const date = moment(data.date || undefined).format('YYYY-MM-DD');

  return {
    id: activity ? activity.id : undefined,

    daily_activity: {
      activity_time: `${date} ${data.time}`,
      data: {
        title: data.title,
        desc: data.description,
        sleep_position: data.sleep_position || undefined
      },
      is_staff_only: true
    }
  };
};
