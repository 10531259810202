import React, { Component } from 'react';
import { ActionButton, ButtonV2, InvoicePreviewCard, StudentSideList, Checkbox } from 'components';
import DAY_CODES from 'constants/dayCodes';
import { capitalize } from 'lib/utils';
import moment from 'moment';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class SubmitPlan extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      info: null
    };
  }

  UNSAFE_componentWillMount() {
    const { billing_plan: plan } = this.props.data;
    req
      .recurringPlanInfo({
        billing_plan: {
          name: plan.name || 'any name',
          kind: plan.kind,
          cycle: plan.cycle,
          due_date: plan.due_date,
          invoice_date: plan.invoice_date,
          invoice_cycle: plan.invoice_cycle || 'previous',
          start_date: moment(plan.start_date[0]).format('YYYY-MM-DD'),
          end_date: plan.end_date ? moment(plan.end_date[plan.end_date.length - 1]).format('YYYY-MM-DD') : undefined
        }
      })
      .then(({ billing_plan: info }) => {
        this.setState({ info });
      });
  }

  @bind
  getSelectedStudents() {
    const { students, data } = this.props;
    const kidIds = data.kid_ids;

    return students.filter(student => kidIds.includes(student.id));
  }

  @bind
  handleRemove(kidId) {
    const { removeKid } = this.props;

    removeKid(kidId);
  }

  getInvoiceItems(plan) {
    return plan.plan_charge_items_attributes.map(item => ({
      description: `${item.days.map(d => DAY_CODES[d]).join(', ')} - ${capitalize(item.rate_type)}`,
      amount: item.rate,
      rate_type: item.rate_type
    }));
  }

  @bind
  getData() {
    const { data } = this.props;
    const { info } = this.state;
    return {
      ...data,
      billing_plan: {
        ...data.billing_plan,
        due_date: info && info.next_due_date,
        invoice_start_date: info && info.cycle_start_date,
        invoice_end_date: info && info.cycle_end_date
      }
    };
  }

  @bind
  onSubmit() {
    // used to pass over no param
    this.props.onSubmit();
  }

  render() {
    const { data, kind, templateId, onTemplateUpdate, step, totalSteps } = this.props;
    const saveAsTemplate = data.billing_plan.template;

    const invoiceItems =
      kind === 'attendance' ? this.getInvoiceItems(data.billing_plan) : data.billing_plan.plan_invoice_items_attributes;

    return (
      <div className="modal__wrapper">
        <div className="modal__header">
          <ActionButton
            iconName="back"
            className="create-billing-plan__back-button"
            onClick={this.props.context.prevStep}
          />

          <div className="modal__header-title">Submit Plan</div>

          <div className="modal__header-steps">
            Step {step} / {totalSteps}
          </div>
        </div>

        <div className="create-billing-plan__container">
          <div className="label create-billing-plan__preview-label">Invoice Preview</div>

          <div className="create-billing-plan__submit">
            <InvoicePreviewCard
              data={this.getData()}
              students={this.getSelectedStudents()}
              items={invoiceItems}
              type="billing_plan"
              isAttendancePreview={kind === 'attendance'}
            />
            <StudentSideList students={this.getSelectedStudents()} onRemove={this.handleRemove} />
          </div>

          <Checkbox
            label="Send Invoice to parent automatically on each billing cycle"
            checked={data.billing_plan.auto_post}
            type="circle"
            onChange={this.props.onUpdateAutoPost}
          />
        </div>

        <div className="modal__controls create-billing-plan__submit-controls">
          {templateId === 'other' && (
            <Checkbox
              reversed
              type="circle"
              checked={saveAsTemplate}
              onChange={onTemplateUpdate}
              className="create-billing-plan__template-checkbox"
              label="Do you want to save this plan as a template?"
            />
          )}
          <ButtonV2
            label="Create Plan"
            onClick={this.onSubmit}
            isLoading={this.props.isLoading}
            disabled={!data.kid_ids.length}
          />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(SubmitPlan);
