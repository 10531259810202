import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'components';
import ICON_SET from './iconList';

const CardIcon = ({ kind = 'bank', brand = '', className }) => {
  const cardIconCN = classNames('card-icon', className);
  let icon;

  if (kind === 'bank_account') {
    icon = 'ach';
  } else {
    icon = ICON_SET[brand?.toLowerCase()] || 'ach';
  }

  return <Icon className={cardIconCN} name={icon} width={33} height={22} />;
};

CardIcon.propTypes = {
  kind: PropTypes.string,
  brand: PropTypes.string,
  className: PropTypes.string
};

export default CardIcon;
