import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem';

class List extends Component {
  static propTypes = {
    items: PropTypes.array,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func
  };

  static defaultProps = {
    items: []
  };

  render() {
    const { items, onEdit, onRemove } = this.props;
    let i = 1;

    return (
      <div className="attendance-items__list">
        {items.map((item, index) => (
          <React.Fragment key={`attendance-item-${index}`}>
            {!item._destroy && (
              <ListItem index={i++} item={item} onEdit={() => onEdit(index)} onRemove={() => onRemove(index)} />
            )}
          </React.Fragment>
        ))}
      </div>
    );
  }
}

export default List;
