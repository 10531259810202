import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import { getStripePubKey } from 'lib/utils/selectors';
import AddPaymentMethod from 'screens/common/Billing/Stripe/AddPaymentMethod';
import './style.scss';

class SchoolPaymentMethod extends Component {
  render() {
    const { onClose, stripePubKey } = this.props;

    return (
      <div className="school-payment-method">
        <StripeProvider apiKey={stripePubKey}>
          <AddPaymentMethod onClose={onClose} isSchool />
        </StripeProvider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  stripePubKey: getStripePubKey(state)
});

export default connect(mapStateToProps)(SchoolPaymentMethod);
