import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Avatar } from 'components';

class SchoolCard extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    picURL: PropTypes.string,
    selected: PropTypes.bool,
    onSelect: PropTypes.func
  };

  static defaultProps = {
    selected: false
  };

  shouldComponentUpdate(nextProps) {
    return this.props.selected !== nextProps.selected;
  }

  @bind
  handleSelect() {
    if (this.props.onSelect) {
      this.props.onSelect(this.props.id);
    }
  }

  render() {
    const { name, picURL, selected, id } = this.props;

    return (
      <div className="school-card" data-cy={`school-card-${id}`}>
        <Checkbox className="school-card__checkbox" checked={selected} onChange={this.handleSelect} />

        <div className="school-card__info" onClick={this.handleSelect}>
          <Avatar className="school-card__avatar" url={picURL} type="select-logo" name={name} />

          <div className="school-card__text">
            <div className="school-card__name">{name}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default SchoolCard;
