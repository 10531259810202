import React from 'react';
import cx from 'classnames';
import { StepProgressBar } from 'components';
import './style.scss';

const steps = [
  {
    name: 'add',
    label: 'Add'
  },
  {
    name: 'edit',
    label: 'Edit'
  },
  {
    name: 'send',
    label: 'Send'
  }
];

export default function DocumentsStepProgressBar({ currentStep, className }) {
  return (
    <StepProgressBar
      steps={steps}
      currentStep={currentStep}
      className={cx(className, 'documents__step-progress-bar')}
    />
  );
}
