import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon, Preloader } from 'components';
import './style.scss';

class LoadingPlaceholder extends PureComponent {
  static propTypes = {
    icon: PropTypes.string, // An SVG icon name from assets/icons
    iconSize: PropTypes.number,
    image: PropTypes.string, // An image name from assets/images. Ignored if `icon` is passed
    title: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
    loading: PropTypes.bool
  };

  static defaultProps = {
    loading: false,
    iconSize: 64
  };

  renderIcon() {
    const { icon, iconSize, image } = this.props;

    if (icon) {
      return <Icon name={icon} size={iconSize} className="loading-placeholder__icon" />;
    }

    if (image) {
      const style = {
        width: iconSize,
        height: iconSize,
        backgroundImage: `url(/assets/images/${image})`
      };

      return <div className="loading-placeholder__image" style={style} />;
    }

    return null;
  }

  renderPlaceholder() {
    const { title, text } = this.props;

    return (
      <div className="loading-placeholder__placeholder">
        {this.renderIcon()}

        {title && <div className="loading-placeholder__title">{title}</div>}

        {text && <div className="loading-placeholder__text">{text}</div>}
      </div>
    );
  }

  renderPreloader() {
    return (
      <div className="loading-placeholder__preloader">
        <Preloader large />
      </div>
    );
  }

  getClassName(propsCN) {
    return typeof propsCN !== 'string' ? 'loading-placeholder' : 'loading-placeholder ' + propsCN;
  }

  render() {
    const { loading, className } = this.props;

    return (
      <div className={this.getClassName(className)}>{loading ? this.renderPreloader() : this.renderPlaceholder()}</div>
    );
  }
}

export default LoadingPlaceholder;
