import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';
import GenericEditor from '../GenericEditor';
import './style.scss';

class TextInputEditor extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    locked: PropTypes.bool,
    decimal: PropTypes.bool
  };

  static defaultProps = {
    onChange: () => undefined,
    placeholder: '',
    type: 'text',
    disabled: false,
    locked: false
  };

  @bind
  submit(value) {
    return Promise.resolve(this.props.onChange(value));
  }

  render() {
    const { defaultValue, placeholder, disabled, mask, pattern, type, locked, min, max, label, decimal } = this.props;

    return (
      <GenericEditor
        className="text-field-editor"
        onSubmit={this.submit}
        defaultValue={defaultValue}
        locked={locked}
        disabled={disabled}
      >
        {({ value, onChange }) => (
          <Form className={label ? 'text-field-editor__form' : ''} onChange={values => onChange(values.text)}>
            <Form.Input
              autoFocus
              name="text"
              className="text-field-editor__input"
              placeholder={placeholder}
              defaultValue={value}
              mask={mask}
              pattern={pattern}
              type={type}
              min={min}
              max={max}
              decimal={decimal}
            />
            {label && <div className="text-field-editor__label">{label}</div>}
          </Form>
        )}
      </GenericEditor>
    );
  }
}

export default TextInputEditor;
