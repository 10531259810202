import React, { Component } from 'react';
import { Form } from 'components';
import { SUBSIDY_TYPE } from 'constants/ledgerTypes';
import './style.scss';

class EditSubsidy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isEdit: Boolean(props.data.subsidy)
    };
  }

  @bind
  async submit(values) {
    this.setState({ isLoading: true });

    const { isEdit } = this.state;

    const route = !isEdit ? 'createLedger' : 'updateLedger';
    const payload = {
      ledger: {
        ...values
      }
    };

    if (!isEdit) {
      payload.ledger.kind = SUBSIDY_TYPE;
    } else {
      payload.id = this.props.data.subsidy.id;
    }

    try {
      await req[route](payload);
      this.props.onClose(true);
      Actions.showFlash(`Subsidy successfully ${isEdit ? 'updated' : 'created'}`);
    } catch (err) {
      Actions.reportError(`Unable to ${isEdit ? 'create' : 'update'} subsidy`, err);
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { isLoading, isEdit } = this.state;
    const { data } = this.props;
    const { subsidy } = data;

    return (
      <div className="modal__wrapper create-subsidy">
        <div className="modal__header">
          <span className="modal__header-title">{isEdit ? 'Edit' : 'Create'} Subsidy</span>
        </div>

        <div className="modal__container">
          <Form validateOn="submit" onSubmit={this.submit} isLoading={isLoading}>
            <div className="form__row form__row--justified">
              <Form.Input
                data-cy={'name'}
                label="agency name"
                required
                asterisk
                name="name"
                placeholder="Enter Subsidy Name"
                defaultValue={isEdit ? subsidy.name : ''}
              />
              <Form.Input
                data-cy={'id'}
                label="agency id"
                name="agency_id"
                placeholder="Enter Agency ID"
                defaultValue={isEdit ? subsidy.agency_id : ''}
              />
            </div>

            <div className="form__row">
              <Form.Textarea
                label="description"
                title="Description"
                name="description"
                placeholder="Add optional internal note"
                defaultValue={isEdit ? subsidy.description : ''}
              />
            </div>

            <div className="form__row form__row--actions">
              <Form.Submit data-cy="submit" label="Save Subsidy" disabled={isLoading} />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default EditSubsidy;
