import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActionButton } from 'components';

class Overlay extends Component {
  static propTypes = {
    hidden: PropTypes.bool,
    editable: PropTypes.bool,
    removable: PropTypes.bool,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
  };

  render() {
    const { hidden, editable, removable, onEdit, onDelete } = this.props;

    if (hidden) {
      return null;
    }

    return (
      <div className="form-constructor-field__overlay">
        {editable && <ActionButton data-cy="edit" iconName="edit" onClick={onEdit} />}

        {removable !== false && <ActionButton data-cy="delete" iconName="delete" onClick={onDelete} />}
      </div>
    );
  }
}

export default Overlay;
