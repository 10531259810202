import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import cx from 'classnames';
import logger from 'lib/utils/logger';
import Icon from '../Icon';
import './style.scss';
import ButtonV2 from 'components/ButtonV2';

class Typeahead extends Component {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    ),
    resetOnBlur: PropTypes.bool,
    action: PropTypes.object,
    loadOptions: PropTypes.func,
    minCharacters: PropTypes.number,
    hideNoMatch: PropTypes.bool,
    hideClear: PropTypes.bool,
    disableFilter: PropTypes.bool,
    defaultInputValue: PropTypes.string,
    tabIndex: PropTypes.number,
    'data-cy': PropTypes.string
  };

  static defaultProps = {
    minCharacters: 2,
    placeholder: '',
    resetOnBlur: false,
    options: []
  };

  containerEl = null;
  inputEl = null;
  scrollEl = React.createRef();

  constructor(...args) {
    super(...args);

    this.state = {
      isOpen: false,
      typeInput: this.props.defaultInputValue || '',
      isLoading: false,
      selectedValue: false,
      highlightIndex: 0,
      options: []
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      const selectedItemIndex = this.props.options.findIndex(opt => opt.id === this.props.value);
      if (selectedItemIndex !== -1) {
        this.setState({
          selectedValue: true,
          highlightIndex: selectedItemIndex,
          typeInput: this.props.options[selectedItemIndex].label
        });
      }
    }
  }

  componentDidMount() {
    const { loadOptions, options: defaultOptions } = this.props;

    if (loadOptions) {
      const initialLoad = true;
      loadOptions('', initialLoad).then(options => {
        logger(options);
        let nextState = {
          options
        };
        if (this.props.value && defaultOptions.length === 0) {
          const selectedItemIndex = options.findIndex(opt => opt.id === this.props.value);
          if (selectedItemIndex !== -1) {
            nextState = Object.assign(nextState, {
              selectedValue: true,
              highlightIndex: selectedItemIndex,
              typeInput: options[selectedItemIndex].label
            });
          }
        }
        this.setState(nextState);
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (!this.state.isOpen && nextState.isOpen) {
      setTimeout(() => document.addEventListener('click', this.listenClick), 250);
    }

    if (this.state.isOpen && !nextState.isOpen) {
      document.removeEventListener('click', this.listenClick);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.listenClick);
  }

  @bind
  setTypeInput(typeInput) {
    this.setState({ typeInput });
  }

  @bind
  onUpdateValue(option) {
    if (!option) {
      return;
    }

    const { onChange } = this.props;

    if (onChange) {
      onChange(option.id, this.state.typeInput, option);
    }

    this.setState({
      typeInput: option.label,
      isOpen: false,
      selectedValue: true,
      highlightIndex: 0
    });
  }

  @bind
  onClear() {
    const { onChange } = this.props;

    if (onChange) {
      onChange('');
    }

    this.setState({
      typeInput: '',
      isOpen: false,
      selectedValue: false,
      highlightIndex: 0
    });

    this.setFocus();
  }

  @bind
  onType(e) {
    const { loadOptions, minCharacters } = this.props;

    if (loadOptions) {
      this.setState({
        isLoading: true,
        isOpen: true,
        highlightIndex: 0,
        typeInput: e.target.value,
        options: []
      });

      if (e.target.value.length >= minCharacters) {
        loadOptions(e.target.value)
          .then(options => {
            logger(options);
            this.setState({ options, isLoading: false });
          })
          .catch(err => {
            logger(err);
            this.setState({ isLoading: false });
          });
      } else {
        this.setState({ isLoading: false });
      }
    } else {
      this.setState(
        {
          isOpen: true,
          typeInput: e.target.value,
          selectedValue: Boolean(e.target.value),
          highlightIndex: 0
        },
        () => this.props.onChange('', this.state.typeInput)
      );
    }
  }

  @bind
  onFocus() {
    this.setState({ isOpen: true });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  @bind
  onBlur(e) {
    if (e.relatedTarget === null) {
      return;
    }

    this.setState({ isOpen: false, typeInput: this.props.resetOnBlur ? '' : this.state.typeInput });

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  scrollListToActiveElement(index = 0) {
    const view = this.scrollEl.current.view;

    if (!view || !(view.children instanceof HTMLCollection)) return;

    const activeElement = view.children[index];
    if (!activeElement) return;

    const scrollTop = this.scrollEl.current.getScrollTop();
    const clientHeight = this.scrollEl.current.getClientHeight();
    const offsetActiveElement = activeElement.offsetTop + activeElement.offsetHeight;

    if (scrollTop + clientHeight < offsetActiveElement) {
      // below
      this.scrollEl.current.scrollTop(offsetActiveElement - clientHeight);
    } else if (scrollTop > activeElement.offsetTop) {
      // above
      this.scrollEl.current.scrollTop(activeElement.offsetTop);
    }
  }

  @bind
  onKeyDown(e) {
    const { highlightIndex } = this.state;
    const options = this.getFilteredOptions();

    if (e.keyCode === 38) {
      // arrow up
      const nextHighLight = highlightIndex - 1 >= 0 ? highlightIndex - 1 : 0;
      this.setState({
        highlightIndex: nextHighLight,
        isOpen: true
      });
      this.scrollListToActiveElement(nextHighLight);
      e.nativeEvent.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
    } else if (e.keyCode === 40) {
      // arrow down
      const nextHighLight = highlightIndex + 1 >= options.length ? options.length - 1 : highlightIndex + 1;
      this.setState({
        highlightIndex: nextHighLight,
        isOpen: true
      });
      this.scrollListToActiveElement(nextHighLight);
      e.nativeEvent.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
    } else if (e.keyCode === 13) {
      // enter
      const highlight = options[highlightIndex % options.length];
      this.onUpdateValue(highlight);
      this.setFocus();
      e.nativeEvent.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
    }
  }

  @bind
  setFocus() {
    this.inputEl.focus();
  }

  @bind
  getOptions() {
    const { loadOptions, options } = this.props;
    const { options: stateOptions } = this.state;

    if (loadOptions) {
      return stateOptions;
    }

    return options;
  }

  @bind
  getFilteredOptions() {
    const { minCharacters, loadOptions, options } = this.props;
    const { typeInput, options: stateOptions } = this.state;

    if (loadOptions) {
      return stateOptions;
    }

    if (typeInput.length >= minCharacters) {
      return options.filter(option => option.label.toLowerCase().indexOf(typeInput.toLowerCase()) > -1);
    }

    return options;
  }

  listenClick = e => {
    let { target } = e;

    while (target !== null && target !== this.containerEl && target !== document.body) {
      target = target.parentNode;
    }

    if (target !== this.containerEl) {
      this.setState({ isOpen: false, typeInput: this.props.resetOnBlur ? '' : this.state.typeInput });
    }
  };

  toggleDropdown = e => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false, typeInput: this.props.resetOnBlur ? '' : this.state.typeInput });
      e.stopPropagation();
    }
  };

  renderHorizontalTrack(props) {
    return <div {...props} className="typeahead__horizontal-scroll" />;
  }

  renderOptions() {
    const { disableFilter } = this.props;
    const { highlightIndex, typeInput } = this.state;
    const filteredOptions = disableFilter ? this.getOptions() : this.getFilteredOptions();

    if (!filteredOptions.length && !this.props.hideNoMatch && typeInput.length >= this.props.minCharacters) {
      return <div className="typeahead__no-result">No result</div>;
    }

    return filteredOptions.map((opt, index) => {
      const itemCN = cx('typeahead__item', {
        'typeahead__item--active': highlightIndex % filteredOptions.length === index
      });

      return (
        <div
          key={opt.id + String(index)}
          className={itemCN}
          onClick={() => this.onUpdateValue(opt)}
          onMouseDown={e => e.preventDefault()}
        >
          {opt.label}
        </div>
      );
    });
  }

  render() {
    const {
      placeholder,
      className,
      disabled,
      action,
      hideNoMatch,
      hideClear,
      tabIndex,
      'data-cy': dataCY
    } = this.props;
    const { typeInput, selectedValue, isOpen, isLoading } = this.state;
    const typeaheadCN = cx('typeahead', 'dropdown', className, {
      'dropdown--disabled': disabled,
      'dropdown--open': isOpen,
      'typeahead__selected-value': selectedValue
    });

    return (
      <div className={typeaheadCN} ref={node => (this.containerEl = node)} data-cy={dataCY}>
        <div className="dropdown__header" onClick={this.setFocus}>
          <input
            type="text"
            className="typeahead__input input"
            ref={node => (this.inputEl = node)}
            placeholder={placeholder}
            value={typeInput}
            onChange={this.onType}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            disabled={disabled}
            onKeyDown={this.onKeyDown}
            tabIndex={tabIndex}
          />

          <Icon className="dropdown__header-arrow" name="chevron-down" size={14} onClick={this.toggleDropdown} />
          {selectedValue && !hideClear && (
            <ButtonV2
              data-cy={dataCY ? `${dataCY}-clear` : undefined}
              className="typeahead__icon-clear"
              icon
              iconName="close-circle"
              iconSize={14}
              onClick={this.onClear}
            />
          )}
        </div>
        <div className="dropdown__body">
          <Scrollbars
            ref={this.scrollEl}
            autoHeight
            autoHeightMin={hideNoMatch ? 0 : 40}
            autoHeightMax={220}
            renderTrackHorizontal={this.renderHorizontalTrack}
          >
            {isLoading && <div className="typeahead__loading">Loading...</div>}
            {!isLoading && this.renderOptions()}
          </Scrollbars>

          {action && <div className="typeahead__action">{action}</div>}
        </div>
      </div>
    );
  }
}

export default Typeahead;
