import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// import cx from 'classnames';
import { ButtonV2, TooltipTrigger } from 'components';

// Source: src/screens/teacher/Learning/CreateLesson/AttachmentsUpload/Item.js
class Item extends PureComponent {
  static propTypes = {
    attachment: PropTypes.object,
    onDelete: PropTypes.func,
    onToggle: PropTypes.func
  };

  @bind
  handleDelete() {
    this.props.onDelete(this.props.attachment.id);
  }

  @bind
  toggleShare() {
    this.props.onToggle(this.props.attachment.id);
  }

  render() {
    const { attachment } = this.props;
    const fileName = attachment.name || attachment.title;

    return (
      <div className="attachments-upload__item" data-cy="attachment">
        <div>
          <TooltipTrigger
            // className="attachments-upload__item-share"
            tooltipClassName="attachments-upload__item-share-tooltip"
            tooltip={'Share with parents'}
          >
            {/* <ActionButton
              iconName="share"
              className={cx(
                'attachments-upload__item-share-icon',
                `attachments-upload__item-share-icon--${attachment.sharing_enabled ? 'enabled' : 'disabled'}`
              )}
              onClick={this.toggleShare}
            /> */}
          </TooltipTrigger>
          <span className="attachments-upload__item-name">{fileName}</span>
        </div>

        {/* <ActionButton
          size={12}
          iconName="close"
          className="attachments-upload__item-delete-btn"
          // onClick={this.handleDelete}
        /> */}
      </div>
    );
  }
}

const Attachments = ({ attachments }) => {
  return attachments.map((a, index) =>
    a._destroy ? null : (
      <Item
        key={a.name + String(index) + String(a.sharing_enabled)}
        attachment={a}
        // onToggle={this.props.onToggleSharing}
        // onDelete={this.handleDelete}
      />
    )
  );
};

const Attachment = ({ attachments, onEdit }) => {
  return (
    <div className="attachments-upload">
      <div className="attachments-upload__title">Document</div>

      <div className="attachments-upload__items">
        {attachments.filter(a => !a._destroy).length === 0 ? (
          // ? this.renderPlaceholder()
          <span>Document placeholder</span>
        ) : (
          <Attachments attachments={attachments} />
        )}
      </div>

      <div className="attachments-upload__add">
        <ButtonV2
          tertiary
          className="attachments-upload__add-btn"
          data-cy="edit-btn"
          label="Edit document"
          onClick={() => {
            typeof onEdit === 'function' && onEdit();
          }}
        />
      </div>
    </div>
  );
};

export default withRouter(Attachment);
