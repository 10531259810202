import React, { Component } from 'react';
import { ActionButton, ButtonV2, Avatar } from 'components';
import { Scrollbars } from 'react-custom-scrollbars';
import currency from 'lib/currency';
import { plural } from 'lib/utils';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class SummaryStep extends Component {
  render() {
    const { selectedKids, students, values, isSaving, onSave, fundsSum } = this.props;

    return (
      <div className="modal__wrapper receive-funds-summary">
        <div className="modal__header modal__header--bordered">
          <ActionButton iconName="back" className="modal__header-back" onClick={() => this.props.context.prevStep()} />
          <span className="modal__header-title">Receive Funds</span>
          <span className="modal__header-steps">
            <span>Step 3</span> / 3
          </span>
        </div>

        <div className="modal__container">
          <div className="receive-funds-summary__students-count">{plural(selectedKids.length, 'student')} selected</div>
          <div className="table">
            <div className="table__header">
              <div className="table__cell table__cell--l">Student</div>
              <div className="table__cell table__cell--s">Amount</div>
            </div>
            <div className="table__body">
              <Scrollbars autoHeight autoHeightMin={50} autoHeightMax={400}>
                {students
                  .filter(student => selectedKids.includes(student.id))
                  .map(student => {
                    return (
                      <div className="table__row" key={student.id}>
                        <div className="table__cell table__cell--l">
                          <Avatar
                            name={student.name || student.first_name + ' ' + student.last_name}
                            url={student.profile_pic_url}
                            tooltipText={student.name}
                            color={student.color}
                            initials={student.initials}
                          />
                          <div className="receive-funds-summary__name">
                            <div>{student.name || student.first_name + ' ' + student.last_name}</div>
                          </div>
                        </div>
                        <div className="table__cell table__cell--s">{currency.getPrice(values[student.id])}</div>
                      </div>
                    );
                  })}
              </Scrollbars>
            </div>
          </div>
          <div className="receive-funds-summary__total">Total: {currency.getPrice(fundsSum)}</div>
        </div>
        <div className="modal__controls">
          <ButtonV2
            data-cy={'submit'}
            label="Receive Payment"
            disabled={isSaving || !selectedKids.length}
            onClick={onSave}
          />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(SummaryStep);
