const replaceItem = (collection, updatedItem) => {
  const itemIndex = collection.indexOf(collection.filter(item => item.id === updatedItem.id)[0]);
  const newData = [...collection.slice(0, itemIndex), updatedItem, ...collection.slice(itemIndex + 1)];
  return newData;
};

const createPatchItemReducer = (reducerName, initialState) => (state = { ...initialState }, action) => {
  switch (action.type) {
    case `${reducerName}_PATCH_ITEM`:
      return { ...state, loading: true };

    case `${reducerName}_PATCH_ITEM_SUCCESS`: {
      if (action.options && action.options.collectionName) {
        const collectionName = action.options.collectionName;
        return {
          loading: false,
          data: {
            ...state.data,
            [collectionName]: replaceItem(state.data[collectionName], action.payload)
          }
        };
      }
      return { loading: false, data: replaceItem(state.data, action.payload) };
    }

    case `${reducerName}_PATCH_ITEM_FAILURE`:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default createPatchItemReducer;
