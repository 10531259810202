import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'components';
import './style.scss';

class EditPhysician extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    data: PropTypes.object
  };

  constructor(...args) {
    super(...args);

    this.state = {
      isLoading: false
    };
  }

  @bind
  submit(values) {
    const { onClose } = this.props;
    const { studentId, isNew, fromParent } = this.props.data;

    let requestData = null;
    let action = null;
    let message = '';

    if (isNew) {
      action = fromParent ? req.parentAddCarer : req.addCarer;
      message = 'Physician successfully added';
      requestData = {
        carer: {
          relation: 'physician',
          kid_id: studentId,
          ...values
        }
      };
    } else {
      action = fromParent ? req.parentUpdateCarer : req.updateCarer;
      message = 'Physician successfully updated.';

      const { physician } = this.props.data;
      requestData = {
        id: physician.id,
        carer: {
          ...values
        }
      };
    }

    this.setState({ isLoading: true });

    action(requestData)
      .then(() => {
        Actions.showFlash(message);
        this.setState({ isLoading: false });
        onClose(true);
      })
      .catch(err => {
        this.setState({ isLoading: false });

        Actions.reportError(`Cannot ${isNew ? 'add' : 'update'} physician`, err);
      });
  }

  render() {
    const { isLoading } = this.state;
    const { isNew, physician } = this.props.data;

    const buttonLabel = isNew ? 'Add Physician' : 'Save Changes';
    const title = isNew ? 'Add Physician' : 'Edit Physician';

    return (
      <div className="edit-physician">
        <div className="modal__header">
          <span className="modal__header-title">{title}</span>
        </div>

        <div className="modal__container">
          <Form onSubmit={this.submit} validateOn="submit" isLoading={isLoading}>
            <div className="form__row form__row--justified">
              <Form.Input
                label="First name"
                defaultValue={physician && physician.first_name}
                placeholder="First Name (required)"
                name="first_name"
                tabIndex={1}
                required
              />

              <Form.Input
                label="Last name"
                defaultValue={physician && physician.last_name}
                placeholder="Last Name"
                name="last_name"
                tabIndex={2}
              />
            </div>

            <div className="form__row form__row--justified">
              <Form.Input
                label="Email"
                type="email"
                name="email"
                defaultValue={physician && physician.email}
                placeholder="Email Address"
                tabIndex={3}
              />

              <Form.Input
                label="Mobile Phone"
                defaultValue={physician && physician.mobile_phone}
                name="mobile_phone"
                placeholder="Phone Number"
                tabIndex={4}
              />
            </div>

            <div className="form__row form__row--justified">
              <Form.Input
                name="actual_relation"
                label="Type"
                placeholder="eg : Primary Care or Dentist"
                defaultValue={physician && physician.actual_relation}
                tabIndex={5}
              />
            </div>

            <div className="form__row">
              <Form.Textarea
                name="notes"
                defaultValue={physician && physician.notes}
                placeholder="Additional Notes"
                label="Additional Notes"
                tabIndex={6}
              />
            </div>

            <div className="edit-physician__footer form__row--actions">
              <Form.Submit label={buttonLabel} tabIndex={7} />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  student: state.student.data
});

export default connect(mapStateToProps)(EditPhysician);
