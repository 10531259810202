import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { Form } from 'components';
import LearningItem from './LearningItem';
import './style.scss';
import { compose } from 'redux';
import get from 'lodash/get';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class ManageActivities extends Component {
  static propTypes = {
    names: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.oneOf(['name', 'category'])
  };

  static defaultProps = {
    type: 'name',
    names: [],
    categories: []
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      type: get(props, 'type', {})
    };
  }

  componentDidMount() {
    const { type } = this.props.data;

    switch (type) {
      case 'name':
        req.learningActivityNames();
        break;

      case 'category':
        req.learningActivityCategories();
        break;
    }
  }

  @bind
  goBack() {
    this.props.context.prevStep();
  }

  @bind
  deleteItem(id) {
    const { type } = this.props.data;
    const action = type === 'name' ? req.deleteLearningActivityName : req.deleteLearningActivityCategory;

    action({ id }, { id }).then(() => {
      Actions.showFlash(`The ${type === 'name' ? 'activity' : 'category'} has been deleted.`);
    });
  }

  @bind
  addItem(values, clearForm) {
    const { type } = this.props.data;
    const action = type === 'name' ? req.addLearningActivityName : req.addLearningActivityCategory;
    const fieldName = type === 'name' ? 'learning_activity_name' : 'learning_activity_category';

    if (!values.value) {
      return;
    }

    this.setState({ loading: true });

    action({ [fieldName]: { value: values.value } })
      .then(() => {
        clearForm();
        Actions.showFlash(`A new ${type === 'name' ? 'activity' : 'category'} is added.`);
        this.setState({ loading: false });
      })
      .catch(err => {
        if (err.response.data.form_errors && err.response.data.form_errors.value) {
          Actions.showFlash(`This name ${err.response.data.form_errors.value}`, 'alert');
        } else {
          Actions.reportError(`Unable to add ${type === 'name' ? 'activity' : 'category'}`, err);
        }

        this.setState({ loading: false });
      });
  }

  @bind
  renderItems() {
    const { type } = this.props.data;
    const { names, categories } = this.props;
    const rawItems = type === 'name' ? names : categories;

    return rawItems.map(i => <LearningItem key={i.id} item={i} onDelete={this.deleteItem} />);
  }

  render() {
    const { type } = this.props.data;
    const title = `Manage Activity ${type === 'name' ? 'Names' : 'Categories'}`;

    return (
      <div className="manage-activities">
        <div className="modal__header modal__header--bordered">
          <div className="modal__header-title">{title}</div>
        </div>

        <Form
          className="manage-activities__new-item form__row form__row--justified"
          onSubmit={this.addItem}
          errors={this.state.errors}
        >
          <Form.Input name="value" label="New Activity" placeholder="Enter new activity name" />
          <Form.Submit label="Add" isLoading={this.state.loading} />
        </Form>

        <div className="manage-activities__container">
          <Scrollbars className="manage-activities__scroll">
            <div className="manage-activities__list">{this.renderItems()}</div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ learningActivityNames, learningActivityCategories }) => ({
  names: learningActivityNames.data,
  categories: learningActivityCategories.data
});

const enhance = compose(withContext(ModalControllerStepsContext), connect(mapStateToProps));

export default enhance(ManageActivities);
