import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'components';

class ScheduleItem extends PureComponent {
  static propTypes = {
    day: PropTypes.string,
    periods: PropTypes.arrayOf(PropTypes.oneOf(['am', 'pm']))
  };

  render() {
    const { day, periods } = this.props;

    return (
      <Badge className="schedule-item">
        {day}

        {periods.length === 1 && <Badge className="schedule-item-period">{periods[0]}</Badge>}
      </Badge>
    );
  }
}

export default ScheduleItem;
