import React, { useEffect, useState } from 'react';

const withPortalPosition = WrappedComponent => {
  return props => {
    const [portalPosition, setPortalPosition] = useState({});
    const [getNextPortalPosition, setGetNextPortalPosition] = useState(null);

    useEffect(() => {
      if (!getNextPortalPosition) {
        return;
      }

      const updatePortalPosition = () => {
        if (getNextPortalPosition) {
          setPortalPosition(getNextPortalPosition());
        }
      };

      updatePortalPosition();

      document.addEventListener('scroll', updatePortalPosition, true);
      window.addEventListener('resize', updatePortalPosition);

      return () => {
        document.removeEventListener('scroll', updatePortalPosition, true);
        window.removeEventListener('resize', updatePortalPosition);
      };
    }, [getNextPortalPosition]);

    return (
      <WrappedComponent
        portalPosition={portalPosition}
        setGetNextPortalPosition={func => setGetNextPortalPosition(() => func)}
        {...props}
      />
    );
  };
};

export default withPortalPosition;
