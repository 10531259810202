import React, { Component, createRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DropdownPortal from '../DropdownPortal';
import Icon from '../Icon';
import Item from './Item';
import './style.scss';

const KeyCode = {
  ArrowUp: 'ArrowUp',
  ArrowDown: 'ArrowDown',
  Space: 'Space',
  Enter: 'Enter'
};

class SelectGroup extends Component {
  static Item = Item;

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
    checked: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    multipleSelectionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    type: PropTypes.oneOf(['radio', 'checkbox']),
    disabled: PropTypes.bool,
    disableDropdown: PropTypes.bool,
    tapable: PropTypes.bool,
    iconSize: PropTypes.number,
    onClear: PropTypes.func,
    tabIndex: PropTypes.number,
    action: PropTypes.any,
    isActionSticky: PropTypes.bool,
    onClick: PropTypes.func,
    allSelectionItemId: PropTypes.string,
    'data-cy': PropTypes.string
  };

  static defaultProps = {
    multipleSelectionText: 'items selected'
  };

  scroll = null;

  constructor(props) {
    super(props);

    this.state = {
      selectedItemIndex: undefined,
      optionsMaxHeight: 220
    };

    this.dropdownContainerRef = createRef();
  }

  componentDidMount() {
    // if rendering inside a modal, use a smaller scroll container if the default wouldn't fit
    if (this.dropdownContainerRef.current?.closest('.modal')) {
      const rect = this.dropdownContainerRef.current.getBoundingClientRect();
      const bottomOffset = window.innerHeight - rect.bottom;

      if (bottomOffset < this.state.optionsMaxHeight + 5) {
        this.setState({ optionsMaxHeight: 105 });
      }
    }
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  @bind
  addEventListeners() {
    document.addEventListener('keydown', this.listenArrows);
    document.addEventListener('keydown', this.listenSubmitKeys);
  }

  @bind
  removeEventListeners() {
    document.removeEventListener('keydown', this.listenArrows);
    document.removeEventListener('keydown', this.listenSubmitKeys);
  }

  @bind
  listenArrows(e) {
    if (!this.scroll) {
      return;
    }

    if ((e.code !== KeyCode.ArrowDown && e.code !== KeyCode.ArrowUp) || !this.scroll) {
      return;
    }

    e.preventDefault();

    const { selectedItemIndex } = this.state;
    const { children } = this.props;
    const itemsCount = React.Children.count(children);

    let nextIndex = selectedItemIndex || 0;

    if (e.code === KeyCode.ArrowDown) {
      nextIndex = nextIndex + 1 >= itemsCount ? 0 : nextIndex + 1;
    }

    if (e.code === KeyCode.ArrowUp) {
      nextIndex = nextIndex - 1 < 0 ? itemsCount - 1 : nextIndex - 1;
    }

    const renderedElements = this.scroll.container.getElementsByClassName('select-group__item');
    const nextHighlightedElement = renderedElements[nextIndex];

    let scrollDiff = 0;

    const containerHeight = this.scroll.container.clientHeight;
    const itemHeight = nextHighlightedElement.clientHeight;
    const itemOffset = nextHighlightedElement.offsetTop;
    const containerItemsDiff = itemHeight - Math.floor(containerHeight / itemHeight);

    if (containerHeight <= itemOffset + itemHeight) {
      scrollDiff = itemOffset - containerHeight + containerItemsDiff * 2;
    }

    this.scroll.scrollTop(scrollDiff);
    this.setState({ selectedItemIndex: nextIndex });
  }

  @bind
  listenSubmitKeys(e) {
    if (e.code !== KeyCode.Enter && e.code !== KeyCode.Space) {
      return;
    }

    e.preventDefault();

    const { selectedItemIndex } = this.state;
    const { children, type } = this.props;
    const selectedChild = React.Children.toArray(children)[selectedItemIndex];
    if (!selectedChild) {
      return;
    }

    const { value } = selectedChild.props;

    if (type === 'radio') {
      this.handleChange(value);
      document.body.click();
    }

    if (type === 'checkbox') {
      if (e.code === KeyCode.Enter) {
        document.body.click();
        return;
      }

      this.handleChange(value);
    }
  }

  handleChange(value, index) {
    const { onChange, checked, allSelectionItemId, type } = this.props;

    if (index !== undefined) {
      this.setState({ selectedItemIndex: index });
    }

    if (type === 'radio') {
      onChange(value);
      return;
    }

    if (type === 'checkbox') {
      if (!checked) {
        onChange(value);
        return;
      }

      if (allSelectionItemId && value === allSelectionItemId) {
        onChange([value]); // if "all" is selected, unselect all
        return;
      }

      const index = checked.indexOf(value);
      let newArr;

      if (index >= 0) {
        newArr = [...checked.slice(0, index), ...checked.slice(index + 1)];
      } else {
        newArr = [...checked, value];
      }

      // if "all" id is defined and another one is selected, unselect "all"
      if (allSelectionItemId) {
        newArr = newArr.filter(item => item !== allSelectionItemId);
      }

      onChange(newArr);
    }
  }

  renderHorizontalTrack(props) {
    return <div {...props} className="select-group__horizontal-scroll" />;
  }

  renderTitle(title, icon, iconType) {
    return (
      <React.Fragment>
        {icon && (
          <div className="select-group__header-icon">
            {iconType === 'svg' ? <Icon name={icon} /> : <img src={icon} />}
          </div>
        )}

        <div className="select-group__header-title">{title}</div>
      </React.Fragment>
    );
  }

  render() {
    const {
      className,
      children,
      checked,
      multipleSelectionText,
      type,
      disabled,
      disableDropdown,
      tapable,
      onClear,
      tabIndex,
      action,
      isActionSticky,
      iconSize,
      onClick,
      blue,
      'data-cy': dataCY
    } = this.props;
    const { optionsMaxHeight } = this.state;
    let title = this.props.title;
    let icon = null;
    let iconType = null;

    const items = React.Children.map(children, (c, index) => {
      if (!c) {
        return;
      }

      let isChecked = false;

      switch (type) {
        case 'radio':
          isChecked = c.props.value === checked;
          break;

        case 'checkbox':
          isChecked = checked && checked.includes(c.props.value);
          break;
      }

      if (isChecked || (c.props.isDefault && (!checked || !checked.length))) {
        title = c.props.label;
        icon = c.props.icon;
        iconType = c.props.iconType;
      }

      return (
        <c.type
          {...c.props}
          key={c.props.value}
          type={tapable ? 'checkbox' : type}
          tapable={tapable}
          value={c.props.value}
          onClick={e => {
            onClick && onClick(e);
            this.handleChange(c.props.value, index);
          }}
          highlighted={index === this.state.selectedItemIndex}
          checked={isChecked || (c.props.isDefault && (!checked || !checked.length))}
          data-cy={c.props['data-cy'] || `${dataCY}-${c.props.value}`}
        />
      );
    });

    if (type === 'checkbox' && checked && checked.length > 1) {
      const isAllChecked = checked.length === items.length;
      multipleSelectionText instanceof Function
        ? (title = multipleSelectionText(checked))
        : (title = (isAllChecked ? 'All' : checked.length) + ' ' + multipleSelectionText);
    }

    let isChecked = Boolean(checked instanceof Array ? checked.length > 0 : checked !== undefined);

    const groupCN = classNames({
      'select-group': true,
      // transmit to title real values and checked_empty_values (i.e. All rooms with value==='', etc)
      'select-group--checked': isChecked && !disabled,
      'select-group--tapable': Boolean(tapable),
      'select-group--enable-clear': onClear && !tapable,
      [className]: className
    });

    const portalCN = classNames({
      'select-group-dropdown-portal': true,
      'select-group-dropdown-portal--tapable': Boolean(tapable),
      'select-group-dropdown-portal--blue': Boolean(blue),
      'select-group-dropdown-portal--sticky-action': isActionSticky
    });

    const isClearButtonVisible = onClear && (checked instanceof Array ? checked.length > 0 : Boolean(checked));

    return tapable ? (
      <div className={groupCN}>{items}</div>
    ) : (
      <DropdownPortal
        title={this.renderTitle(title, icon, iconType)}
        iconSize={iconSize}
        className={groupCN}
        portalClassName={portalCN}
        disabled={disabled}
        disableDropdown={disableDropdown}
        onClear={isClearButtonVisible ? onClear : undefined}
        tabIndex={tabIndex}
        onOpen={this.addEventListeners}
        onClose={this.removeEventListeners}
        onClick={onClick}
        ref={this.dropdownContainerRef}
        data-cy={dataCY}
      >
        <Scrollbars
          autoHeight
          autoHeightMin={0}
          autoHeightMax={optionsMaxHeight}
          ref={node => (this.scroll = node)}
          renderTrackHorizontal={this.renderHorizontalTrack}
        >
          {items}

          <div className="select-group__action" onClick={() => document.body.click()}>
            {action}
          </div>
        </Scrollbars>
      </DropdownPortal>
    );
  }
}

export default SelectGroup;
