import React from 'react';
import { ActionButton, TooltipTrigger } from 'components';
import './style.scss';

const CorporateSettingIcon = ({ ...props }) => {
  return (
    <TooltipTrigger className="corporate-detail-icon" side="center" tooltip="Corporate Linked" {...props}>
      <ActionButton iconName="corp-setting" size={14} />
    </TooltipTrigger>
  );
};

export default CorporateSettingIcon;
