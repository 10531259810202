import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import PlainCheckbox from '../../Checkbox';
import withContext, { FormContext } from 'hocs/withContext';

class RadioGroup extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      })
    ),
    beforeChange: PropTypes.func
  };

  static defaultProps = {
    options: []
  };

  componentDidMount() {
    const initialValue = this.props.defaultValue || null;
    const validations = {};

    this.props.context.init(this.props.name, initialValue, validations);
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disabled !== this.props.disabled && this.props.disabled === true) {
      this.props.context.update(this.props.name, null);
    }
  }

  @bind
  handleChange(selectedValue) {
    const { name, beforeChange } = this.props;

    if (!beforeChange || beforeChange(selectedValue)) {
      this.props.context.update(name, selectedValue);
    }
  }

  render() {
    const { values } = this.props.context;
    const {
      className,
      name,
      options,
      type,
      size,
      disabled,
      reversed,
      tabIndex,
      required,
      'data-cy': dataCY
    } = this.props;

    return (
      <div className={cx('form-checkbox__group', className)}>
        {options.map(option => (
          <PlainCheckbox
            key={option.value}
            checked={values[name] === option.value}
            label={option.label}
            type={type}
            reversed={reversed}
            size={size}
            disabled={disabled}
            onChange={() => this.handleChange(option.value)}
            tabIndex={tabIndex}
            required={required}
            data-cy={dataCY}
          />
        ))}
      </div>
    );
  }
}

export default withContext(FormContext)(RadioGroup);
