import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import { formatNumber } from 'lib/utils';
import currency from 'lib/currency';
import cx from 'classnames';
import './style.scss';

class BillingStats extends Component {
  static propTypes = {
    paid: PropTypes.number,
    process: PropTypes.number,
    unpaid: PropTypes.number,
    paynowLink: PropTypes.string,
    type: PropTypes.oneOf(['parent', 'teacher']),
    toggle: PropTypes.oneOf(['paid', 'under_process', 'unpaid']),
    onToggle: PropTypes.func
  };

  static defaultProps = {
    paid: 0,
    process: 0,
    unpaid: 0,
    type: 'teacher'
  };

  @bind
  togglePaid() {
    this.toggleStatus('paid');
  }

  @bind
  toggleUnderProcess() {
    this.toggleStatus('under_process');
  }

  @bind
  toggleUnpaid() {
    this.toggleStatus('unpaid');
  }

  toggleStatus(status) {
    const { toggle, onToggle } = this.props;

    if (!onToggle) {
      return;
    }

    onToggle(toggle === status ? undefined : status);
  }

  getClassName() {
    const { type, toggle, onToggle } = this.props;

    return cx('billing-stats', `billing-stats--${type}`, {
      'billing-stats--enable-toggle': onToggle !== undefined,
      [`billing-stats--toggle-${toggle}`]: toggle !== undefined
    });
  }

  render() {
    const { paid, process: processingAmount, unpaid, type, paynowLink } = this.props;
    const className = this.getClassName();

    return (
      <div className={className}>
        <div className="billing-stats__block billing-stats__block--paid" onClick={this.togglePaid}>
          <div className="billing-stats__block-value">
            {currency.getSymbol()}
            {formatNumber(paid, type === 'teacher')}
          </div>

          <div className="billing-stats__block-description">Amount paid</div>
        </div>

        <div className="billing-stats__block billing-stats__block--under_process" onClick={this.toggleUnderProcess}>
          <div className="billing-stats__block-value">
            {currency.getSymbol()}
            {formatNumber(processingAmount)}
          </div>

          <div className="billing-stats__block-description">Amount in process</div>
        </div>

        {type === 'teacher' ? (
          <div className="billing-stats__block billing-stats__block--unpaid" onClick={this.toggleUnpaid}>
            <div className="billing-stats__block-value">
              {currency.getSymbol()}
              {formatNumber(unpaid)}
            </div>

            <div className="billing-stats__block-description">Amount unpaid</div>
          </div>
        ) : (
          <div className="billing-stats__block billing-stats__block--unpaid billing-stats__block--unpaid-parent">
            <div className="billing-stats__block-data">
              <div className="billing-stats__block-value">
                {currency.getSymbol()}
                {formatNumber(unpaid, false)}
              </div>

              <div className="billing-stats__block-description">Amount unpaid</div>
            </div>

            <Link className="button billing-stats__pay-now-button" to={paynowLink}>
              Pay Now
            </Link>
          </div>
        )}
      </div>
    );
  }
}

export default BillingStats;
