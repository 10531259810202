import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActionButton, Avatar } from 'components';

class Row extends Component {
  static propTypes = {
    room: PropTypes.object,
    kid: PropTypes.object,
    onRemove: PropTypes.func
  };

  static defaultProps = {
    room: {},
    kid: {}
  };

  @bind
  remove() {
    this.props.onRemove(this.props.kid.id);
  }

  render() {
    const { room, kid } = this.props;

    return (
      <div className="student-side-list__row" key={kid.id}>
        <div className="student-side-list__row-inner">
          <Avatar name={kid.name} initials={kid.initials} url={kid.profile_pic_url} color={kid.color} />

          <div>
            <div className="student-side-list__row-name">{kid.name}</div>

            <div className="student-side-list__row-room">{room ? room.name : 'No Room'}</div>
          </div>
        </div>

        <div className="student-side-list__row-remove">
          <ActionButton iconName="delete" size={14} onClick={this.remove} className="student-side-list__remove" />
        </div>
      </div>
    );
  }
}

export default Row;
