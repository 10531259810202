import FormFieldType from 'constants/FormFieldType';

export default {
  [FormFieldType.TextInput]:
    'Parent will type an answer to your question provided in "Field Label". If empty, the field will display your placeholder text.',
  [FormFieldType.Checkbox]:
    'Enter your question in the Field Label below; parents will have the option to mark a checkbox if they agree.',
  [FormFieldType.Select]: 'Dropdown field will allow parents to select their response from pre-existing choices.',
  [FormFieldType.DatePicker]: 'Parents will answer your question by picking a date.',
  [FormFieldType.TextBox]:
    'This field will display any text that you have entered for the parents to read. There is nothing here for the parents to click on or type into.',
  [FormFieldType.Divider]: 'This option will place a dividing line to help organize your form into sections.',
  [FormFieldType.FileUpload]:
    'Enter label below to describe the file upload. Parents will be able to upload one or more files (pdf, doc, xls, csv or image types supported)'
};
