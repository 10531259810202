import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'components';
import './style.scss';

class ToggleButton extends Component {
  static propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    iconName: PropTypes.string,
    iconColor: PropTypes.string,
    iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    toggle: PropTypes.oneOf([true, false, 'by-tooltip']),
    flat: PropTypes.bool, // If true, ToggleButton looks like ActionButton, otherwise - like Button with shadow,
    secondary: PropTypes.bool, // Inverted colors: blue icon, borders & text, white background
    hidden: PropTypes.bool,
    disabled: PropTypes.bool,
    'data-cy': PropTypes.string
  };

  static defaultProps = {
    iconSize: 18
  };

  @bind
  handleClick(e) {
    if (this.props.disabled) {
      return;
    }

    const { onClick } = this.props;

    if (!onClick) {
      return;
    }

    onClick(e);
  }

  getClassName() {
    const { className, toggle, secondary, disabled, flat, small } = this.props;

    return classNames('toggle-button', {
      'toggle-button--flat': flat,
      'toggle-button--small': small,
      'toggle-button--raised': !flat,
      'toggle-button--disabled': disabled,
      'toggle-button--toggled': toggle === true,
      'toggle-button--secondary': secondary === true,
      'toggle-button--by-tooltip': toggle === 'by-tooltip',
      [className]: Boolean(className)
    });
  }

  render() {
    const { label, iconName, iconSize, fontSize, hidden, 'data-cy': dataCY } = this.props;

    if (hidden) {
      return null;
    }

    const className = this.getClassName();

    return (
      <div className={className} onClick={this.handleClick} data-cy={dataCY}>
        {iconName && <Icon className="toggle-button__icon" name={iconName} size={iconSize} />}
        {label && (
          <div className="toggle-button__label" style={{ fontSize }}>
            {label}
          </div>
        )}
      </div>
    );
  }
}

export default ToggleButton;
