import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AgeFilterComponent from '../../AgeFilter';
import withContext, { FormContext } from 'hocs/withContext';

class AgeFilter extends Component {
  static propTypes = {
    name: PropTypes.string,
    defaultValue: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    const { name } = this.props;
    this.props.context.init(name, this.props.defaultValue, {});
  }

  componentWillUnmount() {
    this.props.context.unmount(this.props.name);
  }

  @bind
  handleChange(value) {
    this.props.context.update(this.props.name, value);
  }

  render() {
    const { defaultValue, name } = this.props;

    return <AgeFilterComponent name={name} onChange={this.handleChange} defaultValue={defaultValue} />;
  }
}

export default withContext(FormContext)(AgeFilter);
