import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ActionButton } from 'components';
import SendEmailForm from '../CommunicationSendMsg/SendEmailForm';
import SelectWhen from './SelectWhen';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

function SendEmail({ data, template, onClose, context: { goToStep } }) {
  const [saving, setSaving] = useState(false);
  const [days, setDays] = useState(get(data, 'action.days', 0));
  const message = {
    subject: get(data, 'action.data.message.subject', ''),
    content: get(data, 'action.data.message.content', ''),
    replyTo: get(data, 'action.data.message.reply_to', '')
  };

  const handleSubmit = async values => {
    setSaving(true);
    const id = get(data, 'action.id');
    const payload = {
      ruleId: data.ruleId,
      automation_action: {
        kind: 'send_email',
        days: parseInt(days, 10),
        data: {
          message: {
            subject: values.subject,
            content: values.content,
            reply_to: values.replyTo
          }
        }
      }
    };

    try {
      if (id) {
        await req.updateAutomationAction({
          id,
          ...payload
        });
      } else {
        await req.createAutomationAction(payload);
      }
      setSaving(false);
      Actions.showFlash('Action saved');

      onClose();
    } catch (e) {
      setSaving(false);
      Actions.reportError('Unable to save action', e);
    }
  };

  return (
    <div className="modal__wrapper communication-send-msg__edit-msg">
      <div className="modal__header modal__header--bordered">
        <span className="modal__header-title">
          <ActionButton iconName="back" className="modal__header-back" onClick={() => goToStep('choose-type')} />
          Send Email
        </span>
        <span className="modal__header-steps">
          <span>Step 2</span> / 2
        </span>

        <ActionButton
          iconName="template"
          className="communication-send-msg__edit-msg-template-btn"
          title="Choose From Template"
          onClick={() => goToStep('msg-template')}
        />
      </div>

      <SendEmailForm fromAutomation message={template || message} isSaving={saving} onSubmit={handleSubmit}>
        <div className="form__row  form__row--justified">
          <SelectWhen text="Email will be sent between 7 - 9 AM on the day" days={days} onChange={setDays} />
        </div>
      </SendEmailForm>
    </div>
  );
}

SendEmail.propTypes = {
  data: PropTypes.object,
  template: PropTypes.object,
  onClose: PropTypes.func
};

export default withContext(ModalControllerStepsContext)(SendEmail);
