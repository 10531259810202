import React, { Component } from 'react';
import { Elements } from 'react-stripe-elements';
import CreditCardForm from './CreditCardForm';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class Step2 extends Component {
  render() {
    const { goToList, isSchool, onSubmit, autopayEnabled, subKind } = this.props;
    return (
      <Elements>
        <CreditCardForm
          onPrevStep={this.props.context.prevStep}
          goToList={goToList}
          isSchool={isSchool}
          onSubmit={onSubmit}
          subKind={subKind}
          autopayEnabled={autopayEnabled}
        />
      </Elements>
    );
  }
}

export default withContext(ModalControllerStepsContext)(Step2);
