import React, { useState } from 'react';
import Steps from 'modals/ModalController/Steps';
import Step1 from './Step1';
import Step2 from './Step2';
import './style.scss';
import { useSelector } from 'react-redux';

const ReceivePaymentModal = ({ data, onClose }) => {
  // Only used for forwarding state to step 2 in the case of TE Card Present transactions
  const [cardPresentState, setCardPresentState] = useState(null);
  const subFamilies = useSelector(state => state.subFamilies.data);

  // Everything below was lifted from step 1 for the sake of keeping the form populated when going back
  const getSubFamilies = () => (data.fromFamilies ? subFamilies : data.subFamilies) ?? [];
  const getDefaultSubFamily = () => data.subFamilyId || getSubFamilies()[0]?.id;
  const [formValues, setFormValues] = useState({
    mode: data ? data.paymentMode : undefined,
    sub_family_id: (data.subFamilies || []).length ? getDefaultSubFamily() : undefined
  });

  return (
    <div className="receive-payment">
      <Steps>
        <Steps.Item>
          <Step1
            data={data}
            onClose={onClose}
            formValues={formValues}
            setFormValues={setFormValues}
            setCardPresentState={setCardPresentState}
            getSubFamilies={getSubFamilies}
            getDefaultSubFamily={getDefaultSubFamily}
          />
        </Steps.Item>

        <Steps.Item>
          <Step2 onClose={onClose} cardPresentState={cardPresentState} />
        </Steps.Item>
      </Steps>
    </div>
  );
};

export default ReceivePaymentModal;
