import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';
import { connect } from 'react-redux';
import { capitalize } from 'lib/utils';
import moment from 'moment';
import './style.scss';

class CopyWeeklyPlan extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      week: PropTypes.arrayOf(PropTypes.object),
      roomId: PropTypes.string,
      type: PropTypes.oneOf(['meal', 'lesson'])
    })
  };

  static defaultProps = {
    data: {
      week: []
    }
  };

  state = {
    loading: false
  };

  @bind
  async handleSubmit(values) {
    const [fromWeek, toWeek] = [
      values.from_week.map(d => moment(d).format('YYYY-MM-DD')),
      values.to_week.map(d => moment(d).format('YYYY-MM-DD'))
    ];

    const [fromSectionId, toSectionId] = [values.from_section_id, values.to_section_id].map(i =>
      i === 'all' ? undefined : i
    );

    const payload = {
      from_week: {
        date_from: fromWeek[0],
        date_to: fromWeek[1],
        section_id: fromSectionId
      },

      to_week: {
        date_from: toWeek[0],
        date_to: toWeek[1],
        section_id: toSectionId
      }
    };

    this.setState({ loading: true });

    const { type } = this.props.data;
    const action = type === 'lesson' ? 'copyLessonPlan' : 'copyMealPlan';

    try {
      await req[action](payload);
      Actions.showFlash('Successfully copied');
      this.props.onClose({ roomId: toSectionId, week: values.to_week });
    } catch (e) {
      Actions.reportError(`Unable to copy ${type} plan`, e);
    }

    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    const { rooms, data } = this.props;

    const roomItems = rooms.map(r => <Form.Select.Item key={r.id} value={r.id} label={r.name} />);
    const capitalizedType = capitalize(data.type);

    return (
      <div className="copy-weekly-plan">
        <div className="modal__header copy-weekly-plan__header">
          <div className="modal__header-title">{`Copy Weekly ${capitalizedType} Plan`}</div>

          <div className="modal__header-description">
            {`${capitalizedType} plans will be copied from one week to another`}
          </div>
        </div>

        <Form className="copy-weekly-plan__form" onSubmit={this.handleSubmit}>
          <div className="form__row form__row--justified">
            <Form.DatePicker type="weekly" name="from_week" label="From" required defaultValue={data.week[0]} />

            <Form.Select name="from_section_id" label="Room" required defaultValue={data.roomId || 'all'}>
              <Form.Select.Item value="all" label="All Rooms" />
              {roomItems}
            </Form.Select>
          </div>

          <div className="form__row form__row--justified">
            <Form.DatePicker type="weekly" name="to_week" label="To" required />

            <Form.Select name="to_section_id" label="Room" required title="Select Room">
              <Form.Select.Item value="all" label="All Rooms" />
              {roomItems}
            </Form.Select>
          </div>

          <div className="modal__controls">
            <Form.Submit label="Submit" isLoading={loading} />
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.rooms.data
});

export default connect(mapStateToProps)(CopyWeeklyPlan);
