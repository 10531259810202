import React, { Component } from 'react';
import { ButtonV2, Avatar } from 'components';
import './style.scss';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class Step1 extends Component {
  @bind
  scheduleEnroll() {
    this.props.setSchedule(true);
    this.props.context.nextStep();
  }

  @bind
  enrollNow() {
    this.props.setSchedule(false);
    this.props.context.nextStep();
  }

  render() {
    const { kid } = this.props;
    const kidName = kid.name || `${kid.first_name} ${kid.last_name}`;

    return (
      <div className="modal__wrapper enroll-reg">
        <div className="modal__container">
          <div className="enroll-reg__kid">
            <Avatar
              name={kidName}
              url={kid.profile_pic_url}
              tooltipText={kidName}
              color={kid.color}
              initials={kid.initials}
            />
            <div className="enroll-reg__kid-name">{kidName}</div>
          </div>
          <div className="enroll-reg__title">Enroll & Activate Student</div>
          <div className="enroll-reg__message">
            The student and family details will be copied to active student roster. Do you want to "Enroll & Activate"
            this student?
          </div>
        </div>
        <div className="modal__controls">
          <ButtonV2 onClick={this.scheduleEnroll} secondary label="Schedule Enroll" />
          <ButtonV2 onClick={this.enrollNow} label="Enroll Now" data-cy="enroll-now" />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(Step1);
