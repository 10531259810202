import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { applyFilters } from 'lib/utils';
import { ActionButton, ButtonV2, StudentCard } from 'components';
import StudentFilter from './StudentFilter';
import SelectPersonList from 'modals/common/SelectPersonList';
import withContext, { ModalControllerStepsContext } from 'hocs/withContext';

class SelectStudentStep extends Component {
  static propTypes = {
    sendSMS: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.validations = {
      'No Email': kid => {
        return kid.parents.filter(p => p.email).length === 0;
      }
    };

    if (this.props.sendSMS) {
      this.validations['No Mobile'] = kid => {
        return kid.parents.filter(p => p.mobile_phone).length === 0;
      };
    }
  }

  @bind
  renderStudent(student) {
    const { selectedIds, selectStudent } = this.props;

    return (
      <StudentCard
        key={`student-${student.id}`}
        className="new-message__student-card"
        student={student}
        onClick={selectStudent(student.id)}
        checked={selectedIds[student.id]}
      />
    );
  }

  render() {
    const {
      filters,
      rooms,
      students,
      tags,
      selectedStudents,
      onSelect,
      sendMessage,
      isSaving,
      isSavingDraft,
      messageType,
      updateFilters
    } = this.props;

    const filteredStudents = applyFilters(students, filters);

    return (
      <div className="modal__wrapper select-students-step">
        <div className="modal__header modal__header--bordered">
          <ActionButton iconName="back" size={20} onClick={this.props.context.prevStep} />
          <span className="modal__header-title">Select Students</span>
          <span className="modal__header-steps">
            <span>Step 2</span> / 2
          </span>

          <div className="new-message__student-filter">
            <StudentFilter rooms={rooms} tags={tags} filters={filters} onUpdate={updateFilters} />
          </div>
        </div>

        <div className="modal__container">
          <SelectPersonList
            persons={filteredStudents}
            allPersons={students}
            selectedIds={selectedStudents}
            onSelect={onSelect}
            listHeight={378}
            type="kid"
            validations={this.validations}
            disableInvalid={false}
          />
        </div>

        <div className="modal__controls">
          {messageType === 'newsletter' && (
            <ButtonV2
              secondary
              label="Choose Staff & Send"
              onClick={this.props.context.nextStep}
              disabled={isSaving || selectedStudents.length === 0}
              isLoading={isSavingDraft}
            />
          )}
          <ButtonV2
            data-cy="send"
            label="Send Message"
            onClick={sendMessage}
            isLoading={isSaving}
            disabled={isSaving || selectedStudents.length === 0}
          />
        </div>
      </div>
    );
  }
}

export default withContext(ModalControllerStepsContext)(SelectStudentStep);
