import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, StudentList } from 'components';
import './style.scss';

class IssueRefund extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  static defaultProps = {
    data: {
      paymentId: null,
      kidNames: '',
      isPaidOnline: false,
      paymentMode: null,
      filters: null
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      formValues: {
        mode: props.data.paymentMode || undefined
      }
    };
  }

  @bind
  submit(values) {
    const method = this.props.data.isRegistration ? 'issueRegistrationRefund' : 'issueRefund';
    const data = {
      parent_payment_id: this.props.data.paymentId,

      parent_refund: {
        mode: values.mode,
        amount: Number(values.amount),
        description: values.description,
        notes: values.notes
      }
    };

    if (values.mode === 'Check') {
      data.parent_refund.check_number = values.check_number;
    }

    this.setState({ isLoading: true });
    req[method](data)
      .then(() => {
        this.setState({ isLoading: false });
        Actions.showFlash('Refund is added');
        this.props.onClose(true);
      })
      .catch(err => {
        Actions.reportError('There was problem while refunding', err);
        this.setState({ isLoading: false });
      });
  }

  @bind
  updateFormValues(values) {
    this.setState({ formValues: values });
  }

  render() {
    const { isLoading, formValues } = this.state;
    const {
      students,
      paymentMode,
      paymentMethod,
      isPaidOnline,
      amount,
      subFamily,
      isAutoPay,
      isCardPresentTransaction,
      paymentModeDescription
    } = this.props.data;

    const refundDescriptor = paymentMethod.toLowerCase().endsWith('card') ? 'the original card' : paymentMethod;

    return (
      <Form
        className="modal__wrapper issue-refund"
        onSubmit={this.submit}
        onChange={this.updateFormValues}
        isLoading={isLoading}
      >
        <div className="modal__header">
          <div className="subheader">Issue Refund{subFamily ? `- ${subFamily.name}` : ''}</div>
          <span className="modal__header-note">
            <StudentList students={students} />
          </span>
        </div>

        <div className="modal__container">
          <div className="form__row form__row--justified">
            <Form.Input
              label="Amount"
              placeholder="0"
              type="amount"
              className="issue-refund__amount"
              name="amount"
              required
              amount
              defaultValue={amount}
              disabled
            />

            <Form.Select
              label="Payment Type"
              name="mode"
              title="Select payment type"
              defaultValue={paymentMode}
              disabled={isPaidOnline || isCardPresentTransaction}
            >
              {isCardPresentTransaction ? (
                <Form.Select.Item value="Card" label={`${paymentModeDescription}`} />
              ) : isPaidOnline ? (
                <Form.Select.Item value="Online" label={`${paymentMethod} (${isAutoPay ? 'AutoPay' : 'Online'})`} />
              ) : (
                [
                  <Form.Select.Item key="mode-cash" value="Cash" label="Cash" />,
                  <Form.Select.Item key="mode-check" value="Check" label="Check" />,
                  <Form.Select.Item key="mode-other" value="Other" label="Other" />
                ]
              )}
            </Form.Select>
          </div>

          {(formValues.mode === 'Card' || formValues.mode === 'Online') && (
            <div className="issue-refund__note">NOTE: Refund will go to {refundDescriptor} used</div>
          )}

          {formValues.mode === 'Check' && (
            <div className="form__row">
              <Form.Input
                name="check_number"
                label="Check Number"
                placeholder="Enter Check Number"
                asterisk={true}
                required
                naturalNumber
                maxLength={6}
              />
            </div>
          )}

          <div className="form__row">
            <Form.Textarea
              label="Description"
              placeholder="Add Description"
              name="description"
              asterisk={true}
              required
            />
          </div>

          <div className="form__row">
            <Form.Input placeholder="Add optional internal note" name="notes" label="Staff Only Note" />
          </div>

          <div className="form__row form__row--actions">
            <Form.Submit label="Make refund" />
          </div>
        </div>
      </Form>
    );
  }
}

export default IssueRefund;
