import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';

const FilterItemDateFilter = ({ name, ...otherProps }) => (
  <div className="form__row">
    <div style={{ width: '100%' }}>
      <div className="form__row">
        <Form.DateFilter data-cy={name} name={name} {...otherProps} />
      </div>
    </div>
  </div>
);

FilterItemDateFilter.propTypes = {
  name: PropTypes.string.isRequired
};

export default FilterItemDateFilter;
