import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'components';
import ItemList from '../ItemList';

class SelectOptions extends Component {
  static propTypes = {
    options: PropTypes.object,
    onChange: PropTypes.func
  };

  render() {
    const { options, onChange, customAttributeId, disabled } = this.props;

    return (
      <React.Fragment>
        <Form.Select
          name="type"
          label="Type"
          title="Select type"
          data-cy="select-type"
          required
          defaultValue={options.type || 'radio'}
          disabled={!!customAttributeId || disabled}
        >
          <Form.Select.Item value="radio" label="Allow One Answer Only" />
          <Form.Select.Item value="checkbox" label="Multiple Answers Possible" />
        </Form.Select>
        <Form.Input
          name="title"
          placeholder="Placeholder text"
          label="Placeholder Text"
          data-cy="placeholder"
          defaultValue={options.title || options.label}
        />
        <ItemList
          key={customAttributeId || ''}
          items={options.items}
          onChange={onChange}
          disabled={!!customAttributeId || disabled}
        />
      </React.Fragment>
    );
  }
}

export default SelectOptions;
